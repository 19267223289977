define("dashboard/helpers/truncate", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncate = truncate;

  function truncate(_ref) {
    let [text = '', length = 1, truncationMarker = ''] = _ref;
    let output = text.slice(0, length - 1);

    if (output.length < text.length) {
      output += truncationMarker;
    }

    return output;
  }

  var _default = (0, _helper.helper)(truncate);

  _exports.default = _default;
});