define("dashboard/components/metrics/charts/line-chart-elements/area-graph", ["exports", "@ember/object", "dashboard/components/metrics/charts/line-chart-elements/graph", "d3", "dashboard/templates/components/metrics/charts/line-chart-elements/area-graph"], function (_exports, _object, _graph, _d, _areaGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a graph that draws a line and area based on passed in `data` array of [x,y] pairs
   *
   * should not be used directly
   *
   */
  var _default = _graph.default.extend({
    layout: _areaGraph.default,
    classNames: 'metrics__graph-area',
    lineClass: (0, _object.computed)('color', function () {
      return `metrics__color--${this.color}--stroke`;
    }),
    areaClass: (0, _object.computed)('color', function () {
      return `metrics__color--${this.color}--fill`;
    }),

    /**
     * prepares the the area graph. We manually watch the `valueScale`
     * and `timeScale` due to callback natured functionality of d3
     */
    dArea: (0, _object.computed)('_extendedData.[]', 'timeScale', 'valueScale', function () {
      const d3Area = (0, _d.area)().x(this.xFn.bind(this)).y0(this.areaBottomYFn.bind(this)).y1(this.yFn.bind(this)).curve(_d.curveStepAfter);
      const data = this._extendedData;
      return d3Area(data);
    }),

    /**
     * callback the determines the x-value in pixels of the graph
     * at a given datum ([x,y])
     */
    xFn(datum) {
      const time = datum[0];
      return Math.round(this.timeScale(time));
    },

    /**
     * the y-value at a given time
     *
     */
    yValueAtTime(time) {
      const datum = this._extendedData.findBy('0', time);

      if (datum) {
        return this.yFn(datum);
      } else {
        // there is no y-value for given time in data set
        return null;
      }
    }

  });

  _exports.default = _default;
});