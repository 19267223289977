define("dashboard/components/dyno-tier-sizes", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    bool,
    gt,
    not
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['dyno-tier-sizes'],
    sizes: alias('currentTier.sizes'),
    hasKnownTier: bool('currentTier'),
    hasMultipleSizes: gt('sizes.length', 1),
    cannotChangeSizes: not('canScaleFormations'),
    sizeObject: (0, _object.computed)('currentTier', 'currentSize', function () {
      return this.currentTier.findSize(this.currentSize);
    }),
    sizeIconName: (0, _object.computed)('hasKnownTier', 'sizeObject.size', function () {
      if (this.hasKnownTier) {
        return this.get('sizeObject.size');
      } else {
        return 'placeholder';
      }
    }),
    showHelpUrl: bool('isEnterpriseOrg'),
    helpUrl: 'https://devcenter.heroku.com/articles/heroku-enterprise-dyno-types-and-usage-reporting',
    buttonClassNames: (0, _object.computed)('isDirty', function () {
      return this.isDirty ? 'dirty' : '';
    }),
    actions: {
      dynoSizeChange(dynoSize) {
        this.dynoSizeChangeAction(dynoSize);
      }

    }
  });

  _exports.default = _default;
});