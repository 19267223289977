define("dashboard/components/space/settings/transfer-ownership", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    api: (0, _service.inject)(),
    router: (0, _service.inject)(),
    space: null,
    currentTeam: null,
    selectedTeam: (0, _object.computed)(function () {
      return this.currentTeam;
    }),
    enterpriseAccount: (0, _object.computed)('currentTeam.enterprise', function () {
      return this.get('currentTeam.enterprise');
    }),
    disabled: (0, _object.computed)('selectedTeam.name', 'currentTeam.name', function () {
      return this.get('selectedTeam.name') === this.get('currentTeam.name');
    }),
    teams: (0, _object.computed)('enterpriseAccount.teams.[]', function () {
      return this.isDestroying ? [] : this.get('enterpriseAccount.teams').filterBy('canTransferSpaces', true);
    }),
    actions: {
      transferSpace() {
        const teamName = this.get('selectedTeam.name');
        const spaceName = this.get('space.name');
        const path = `/spaces/${spaceName}/transfer`;
        this.api.request(path, {
          type: 'POST',
          contentType: 'application/json; charset=utf-8',
          data: JSON.stringify({
            new_owner: teamName
          })
        }).then(() => {
          this.get('router').transitionTo('team.space.settings', teamName, spaceName);
        });
      }

    }
  });

  _exports.default = _default;
});