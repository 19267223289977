define("dashboard/components/metrics/chart-controls", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'metrics-chart-controls',
    actions: {
      closePanel() {
        this.set('showingSlidePanel', false);
      }

    }
  });

  _exports.default = _default;
});