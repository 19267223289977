define("dashboard/components/metrics/charts/visualizations/node-eventloop-usage", ["exports", "dashboard/components/metrics/charts/visualizations/aggregate-chart", "@ember/object/computed"], function (_exports, _aggregateChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aggregateChart.default.extend({
    tagName: 'metrics-node-eventloop-usage-chart',
    'data-test-target': 'metrics-node-chart',
    title: 'Node.js: Event Loop Usage',
    devCenterName: 'language-runtime-metrics-nodejs#event-loop-usage',
    unit: 'percentage',
    isBeta: true,
    testTarget: 'node-eventloop-usage',
    valueFormat: '0.00',
    boundaryMultiplier: 1,
    smallNumberFormat: '< 0.01',
    smallNumberThreshold: 0.01,
    maxData: (0, _computed.alias)('data.eventloopUsagePercentMax'),
    avgData: (0, _computed.alias)('data.eventloopUsagePercentAvg'),
    minData: (0, _computed.alias)('data.eventloopUsagePercentMin')
  });

  _exports.default = _default;
});