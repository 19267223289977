define("dashboard/services/clock", ["exports", "@ember/service", "@ember/object", "@ember/runloop", "dashboard/config/environment", "moment"], function (_exports, _service, _object, _runloop, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The clock services provides observable attributes that updates periodically.
   * Use it for current time based computed properties.
   * @module service:clock
   */
  var _default = _service.default.extend({
    interval: _environment.default.clockInterval,
    ticks: 0,

    init() {
      this._super(...arguments);

      this.set('ticker', setInterval(() => this.scheduleTick(), this.interval));
    },

    willDestroy() {
      clearInterval(this.ticker);
    },

    scheduleTick() {
      return _runloop.run.next(this, function () {
        if (this.isDestroyed) {
          return;
        }

        this.incrementProperty('ticks');
      });
    },

    moment: (0, _object.computed)('ticks', function () {
      return (0, _moment.default)();
    }),
    unix: (0, _object.computed)('moment', function () {
      return this.moment.unix();
    })
  });

  _exports.default = _default;
});