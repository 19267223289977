define("dashboard/adapters/enterprise-account-preference-mfa", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.enterprise-accounts',
    updateMethod: 'PATCH',

    urlForUpdateRecord(id, modelName, snapshot) {
      const eaId = snapshot.belongsTo('enterpriseAccount', {
        id: true
      });
      return `${this.host}/enterprise-accounts/${eaId}/preferences`;
    }

  });

  _exports.default = _default;
});