define("dashboard/templates/components/metrics/charts/line-chart-elements/line-graph", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zkIfASGE",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[10,\"path\"],[15,\"d\",[29,[[30,0,[\"dLine\"]]]]],[15,0,[29,[\"metrics__line \",[30,0,[\"lineClass\"]]]]],[12],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"selectedTimeChartX\",\"graph\"],[[30,0,[\"selectedTimeChartX\"]],[30,0]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"metrics/charts/line-chart-elements/selected-time-graph-point\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/line-chart-elements/line-graph.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});