define("dashboard/routes/basic-app", ["exports", "@ember/routing/route", "@ember/service", "dashboard/mixins/document-title"], function (_exports, _route, _service, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    parentRoute: 'app',
    loadingState: (0, _service.inject)(),

    get appModel() {
      return this.modelFor('app').app;
    },

    setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('appName', this.get('appModel.name'));
    },

    get documentTitle() {
      return this.get('appModel.name');
    },

    /**
     * ensure that the nav loading state is never active
     * once any of these routes have been hit
     *
     */
    activate() {
      this.set('loadingState.showNavLoading', false);
    },

    /*
     *  e.g. this._routeBase(transition) where transition's targetName is 'app.deploy'
     *       returns 'app'
     *
     *  @params transition {Transition}
     *  @return {String}
     */
    _routeBase(transition, opts) {
      if (opts === undefined) {
        opts = {};
      }

      if (opts.levels === undefined) {
        opts.levels = 1;
      }

      return transition.targetName.split('.').slice(0, -opts.levels).join('.');
    }

  });

  _exports.default = _default;
});