define("dashboard/templates/pipelines/pipeline/index/review-apps", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JfV2IHAq",
    "block": "[[[1,[28,[35,0],null,[[\"pipeline\",\"appJSON\",\"isLoadingAppJSON\",\"onClose\"],[[30,0,[\"model\",\"pipeline\"]],[30,0,[\"appJSON\"]],[30,0,[\"isLoadingAppJSON\"]],[28,[37,1],[\"close\"],null]]]]],[1,\"\\n\"]],[],false,[\"pipeline/review-apps\",\"route-action\"]]",
    "moduleName": "dashboard/templates/pipelines/pipeline/index/review-apps.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});