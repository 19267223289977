define("dashboard/templates/team/switchable/billing/pay-balance", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "103H1SPM",
    "block": "[[[41,[30,0,[\"declinedInvoices\"]],[[[1,\"  \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"The \"],[10,\"b\"],[12],[1,[30,0,[\"team\",\"name\"]]],[13],[1,\" team has outstanding invoices.\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      The following invoices have not been paid. Please review the credit card\\n      information and pay outstanding balances. If you are having problems with\\n      payment \"],[10,3],[14,6,\"https://help.heroku.com/search?utf8=%E2%9C%93&q=billing\"],[14,0,\"hk-link\"],[12],[1,\"please contact support\"],[13],[1,\"\\n      or speak with your bank regarding the charges.\\n    \"],[13],[1,\"\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"invoices\",\"organization\",\"onVerified\"],[[30,0,[\"declinedInvoices\"]],[30,0,[\"team\"]],[28,[37,2],[\"accountVerified\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"The \"],[1,[30,0,[\"team\",\"name\"]]],[1,\" team has no outstanding invoices.\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      The \"],[1,[30,0,[\"team\",\"name\"]]],[1,\" team has no outstanding balance and no\\n      payment is needed at this time. For team information, previous\\n      invoices and payment history view your\\n      \"],[6,[39,3],null,[[\"route\",\"model\"],[\"team.switchable.billing\",[30,0,[\"team\",\"name\"]]]],[[\"default\"],[[[[1,\"billing page\"]],[]]]]],[1,\".\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"account/billing/declined-invoices-form\",\"route-action\",\"link-to\"]]",
    "moduleName": "dashboard/templates/team/switchable/billing/pay-balance.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});