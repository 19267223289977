define("dashboard/routes/pipelines/pipeline/index", ["exports", "@ember/routing/route", "rsvp", "@ember/object/evented", "@ember/service", "dashboard/utils/push-deletion", "dashboard/mixins/fetch-github-branches", "dashboard/utils/realtime/context"], function (_exports, _route, _rsvp, _evented, _service, _pushDeletion, _fetchGithubBranches, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_context.default, _fetchGithubBranches.default, {
    session: (0, _service.inject)(),
    realTimeUpdater: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    model() {
      const protectedModel = this.modelFor('protected');
      const pipePipeModel = this.modelFor('pipelines.pipeline');
      return _rsvp.default.hash({
        githubToken: pipePipeModel.githubToken,
        releases: this.fetchReleases(pipePipeModel.pipeline),
        builds: pipePipeModel.pipeline.get('latestBuilds'),
        pipeline: pipePipeModel.pipeline,
        githubAppLinks: pipePipeModel.pipeline.hasMany('githubAppLinks').reload().catch(() => []),
        githubRepository: pipePipeModel.pipeline.get('githubRepository').catch(() => {}),
        userPreferences: protectedModel.userPreferences,
        testRuns: pipePipeModel.pipeline.get('testRuns'),
        reviewAppConfig: pipePipeModel.pipeline.get('reviewAppConfig')
      });
    },

    afterModel(_ref) {
      let {
        pipeline
      } = _ref;
      this.subscribeToReviewApps(pipeline);
      pipeline.on('didAddCoupling', this, this.didAddCoupling);
      this.on('deactivate', () => {
        try {
          pipeline.off('didAddCoupling', this, this.didAddCoupling);
        } catch (e) {// catches cases where `off` was already called for pipeline
        }
      });
      pipeline.get('apps').toArray().forEach(app => {
        app = this.store.peekRecord('app', app.get('id'));

        if (app) {
          this.subscribeToBuilds(app);
          this.subscribeToReleases(app);
        }
      });
    },

    subscribeToGithubRepos: (0, _evented.on)('activate', function () {
      const {
        pipeline
      } = this.modelFor(this.routeName);
      const repo = pipeline.repo;

      if (repo) {
        this.realtimeContext.onGithubPush(repo, 'create', event => this.handleGithubPushCreate(event));
        this.realtimeContext.onGithubCommitStatus(repo, 'update', event => this.handleGithubCommitUpdate(event));
        this.realtimeContext.onGithubPullRequest(repo, 'create', this.handleGithubPrCreate.bind(this));
        this.realtimeContext.onGithubPullRequest(repo, 'update', this.handleGithubPrUpdate.bind(this));
      }
    }),

    /*
     * Fetches the last release and deployment for each app in the pipeline.
     * A deployment is only a special case of a release where code was released
     * successfully. We inject both the deployment and release into relevant
     * apps' `releases` property, because we need them in the same collection
     * when real-time updates for both come in via our 'releases' stream.
     */
    fetchReleases(pipeline) {
      return _rsvp.default.hash({
        releases: pipeline.hasMany('latestReleases').reload(),
        deployments: pipeline.hasMany('latestDeployments').reload()
      }).then(function (_ref2) {
        let {
          releases,
          deployments
        } = _ref2;
        const combinedReleases = releases.toArray().concat(deployments.toArray());
        return combinedReleases;
      });
    },

    // Kolkrabbi returns 403s for some requests with assumed identities, but
    // we don't want these to invalidate the assumed session.
    catchAssumed(error) {
      const isAssumed = this.get('session.isAssumed');
      const isForbidden = error.status === 403;

      if (isAssumed && isForbidden) {
        return null;
      } else {
        throw error;
      }
    },

    // Responds to event triggered when a coupling is added to a pipeline
    // TODO: check what to do with new review apps
    didAddCoupling(pipeline, coupling) {
      if (coupling.isTest) {
        // We don't need to subscribe to anything for CI apps.
        return;
      }

      if (coupling.get('isReview') && pipeline.get('hasReviewAppParent')) {
        pipeline.fetchGithubReviewApps();
      } // refetch branches to allow auto deploy modal to populate


      if (pipeline.get('isConnectedToRepository')) {
        pipeline.hasMany('githubAppLinks').reload().then(() => {
          if (!pipeline.get('isGithubAppEnabled')) {
            this.fetchBranches(pipeline.get('repositoryName'));
          }
        });
      } // Subscribe to WS events for the new app of this coupling


      const app = this.store.peekRecord('app', coupling.get('app.id'));

      if (!app) {
        return;
      }

      this.subscribeToApp(app);
      this.subscribeToBuilds(app);
      this.subscribeToReleases(app); // Grab the latest release via the app relationship, and push it onto the
      // pipelines current releases.
      // Let's us know if the app can be promoted.

      this.store.query('release', {
        app: app.get('id'),
        latest: true
      }).then(latestRelease => {
        pipeline.get('latestReleases').pushObject(latestRelease.get('firstObject'));
      });
    },

    subscribeToReviewApps(pipeline) {
      if (pipeline.get('hasNewReviewAppsEnabled')) {
        if (pipeline.get('team.role') === 'collaborator') {
          // this is going to look strange
          // collaborator will get realtime updates for new review apps, but we don't really want them
          // we still subscribe to updates in order to be alerted that a review app was deleted
          // realtime updates are the same code for both update and create, we can't just ignore create as the update code will still end up inserting an object
          // the noDefault option isn't valid since we then wouldn't have enough info to pushDeletion for a valid delete
          // instead, we are treating any update as if it's a deleted status so the UI doesn't display to these new review apps being created by other users
          // collaborators can't build new review apps, so this works out
          const unloadReviewApps = reviewApp => {
            (0, _pushDeletion.default)(this.store, 'review-app', reviewApp.get('id'));
          };

          this.realTimeUpdater.subscribeToModel(pipeline, 'review-apps', {
            type: 'update'
          }, unloadReviewApps);
        } else {
          const reviewAppUpdateCallback = _ref3 => {
            let {
              appSetup,
              hasAppSetup,
              id,
              isCompleted,
              isDeleted
            } = _ref3;

            if (isDeleted) {
              (0, _pushDeletion.default)(this.store, 'review-app', id);
            }

            if (isCompleted && hasAppSetup && appSetup.content) {
              appSetup.content.reload();
            }
          };

          this.realTimeUpdater.subscribeToModel(pipeline, 'review-apps', {
            type: 'create'
          });
          this.realTimeUpdater.subscribeToModel(pipeline, 'review-apps', {
            type: 'update'
          }, reviewAppUpdateCallback);
        }
      }
    },

    subscribeToApp(app) {
      this.realTimeUpdater.subscribeToModelDefaults(app);
    },

    subscribeToBuilds(app) {
      if (app.get('isLoadingBuilds')) {
        return app.one('didLoadBuilds', () => this.subscribeToBuilds(app));
      }

      const updateBuilds = build => {
        const latestBuilds = this.modelFor(this.routeName).pipeline.get('latestBuilds');
        latestBuilds.pushObject(build);
      };

      this.realTimeUpdater.subscribeToModel(app, 'builds', {
        type: 'create'
      }, updateBuilds);
      this.realTimeUpdater.subscribeToModel(app, 'builds', {
        type: 'update'
      }, updateBuilds);
    },

    subscribeToReleases(app) {
      const updateReleases = release => {
        const pipeline = this.modelFor(this.routeName).pipeline;
        const latestReleases = pipeline.get('latestReleases');
        const found = app.get('releases').findBy('id', release.get('id'));
        latestReleases.pushObject(release);

        if (!found) {
          app.get('releases').pushObject(release);
        }
      };

      this.realTimeUpdater.subscribeToModel(app, 'releases', {
        type: 'update'
      }, updateReleases);
      this.realTimeUpdater.subscribeToModel(app, 'releases', {
        type: 'create'
      }, updateReleases);
    },

    handleGithubPushCreate(_ref4) {
      let {
        data
      } = _ref4;
      const match = data.ref.match(/refs\/heads\/(.+)/);

      if (match) {
        const pipeline = this.modelFor(this.routeName).pipeline;
        const repositoryName = pipeline.repositoryName;
        const branchName = match[1];
        const branchId = `${repositoryName}/branches/${branchName}`;
        const branchModelName = pipeline.get('isGithubAppEnabled') ? 'repositories-api-github-branch' : 'github-branch';
        const branch = this.store.peekRecord(branchModelName, branchId);

        if (branch && data.deleted) {
          return (0, _pushDeletion.default)(this.store, branchModelName, branch.id);
        }

        const commit = {
          sha: data.head_commit.id,
          url: data.head_commit.url
        };

        if (branch) {
          branch.set('commit', commit);
        } else if (data.created) {
          this.store.pushPayload(branchModelName, {
            [branchModelName]: {
              id: branchId,
              commit,
              name: branchName,
              repositoryName
            }
          });
        }
      }
    },

    handleGithubCommitUpdate(_ref5) {
      let {
        data
      } = _ref5;
      const statusProperties = {
        id: data.id,
        context: data.context,
        state: data.state,
        updatedAt: data.updated_at,
        description: data.description,
        repo: data.repository.full_name,
        sha: data.sha,
        targetUrl: data.target_url
      };
      const pipeline = this.modelFor(this.routeName).pipeline;
      const statusModelName = pipeline.get('isGithubAppEnabled') ? 'repositories-api-commit-status' : 'github-commit-status';
      this.store.pushPayload(statusModelName, {
        [statusModelName]: [statusProperties]
      });
    },

    handleGithubPrCreate(_ref6) {
      let {
        data
      } = _ref6;
      const pipeline = this.modelFor(this.routeName).pipeline;

      if (!pipeline.hasReviewAppsEnabled) {
        return;
      } // We can see duplicate events here so guard against them showing in the UI


      if (pipeline.get('pullRequests').isAny('id', data.id.toString())) {
        return;
      }

      const prModelName = pipeline.get('isGithubAppEnabled') ? 'repositories-api-pull-request' : 'github-pr';
      const store = this.store;
      const normalized = store.normalize(prModelName, data);
      const githubPR = store.push(normalized);
      pipeline.get('pullRequests').unshiftObject(githubPR);
    },

    handleGithubPrUpdate(_ref7) {
      let {
        data
      } = _ref7;
      const pipeline = this.modelFor(this.routeName).pipeline;

      if (!pipeline.get('hasReviewAppsEnabled')) {
        return;
      }

      const prModelName = pipeline.get('isGithubAppEnabled') ? 'repositories-api-pull-request' : 'github-pr';
      const store = this.store;
      const pullRequestId = data.id.toString();
      let pullRequest = store.peekRecord(prModelName, pullRequestId);

      if (pullRequest && data.state !== 'open') {
        pipeline.pullRequests.removeObject(pullRequest);
        (0, _pushDeletion.default)(store, prModelName, pullRequestId);
        return;
      }

      const normalized = store.normalize(prModelName, data);
      pullRequest = store.push(normalized);
      const existingPr = (pipeline.pullRequests || []).findBy('id', pullRequestId);

      if (!existingPr) {
        pipeline.pullRequests.unshiftObject(pullRequest);
      }
    },

    subscribe(model, subResource) {
      return model.subscribeToUpdates(subResource);
    },

    unsubscribe: (0, _evented.on)('deactivate', function () {
      const pipeline = this.modelFor(this.routeName).pipeline;
      this.realTimeUpdater.unsubscribeFromModel(pipeline, 'review-apps');
      const pipelineApps = pipeline.isDestroyed ? [] : pipeline.apps;
      pipelineApps.forEach(app => {
        app = app.get('content');

        if (!app) {
          return;
        }

        this.realTimeUpdater.unsubscribeFromModel(app, 'builds');
        this.realTimeUpdater.unsubscribeFromModel(app, 'releases');
        this.realTimeUpdater.unsubscribeFromModel(app, 'github-pull-requests');
      });
    }),
    actions: {
      fetchCommitStatus(githubAppLink) {
        const {
          branch,
          repo
        } = githubAppLink;
        let ref = githubAppLink.get('branch');
        ref = ref || branch;
        return this.store.query('github-commit-status', {
          repo,
          refs: [ref]
        }).catch(() => {
          /* Allow this to fail silently */
        });
      },

      // this.get('router').transitionTo() doesn't currently work from components.
      // This is a workaround to pass transitions as actions to hk-buttons.
      // Follow along here: https://github.com/emberjs/ember.js/pull/15613
      gotoRoute() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.transitionTo.apply(this, args);
      },

      transitionToPipeline() {
        this.transitionTo('pipelines.pipeline.index');
      }

    }
  });

  _exports.default = _default;
});