define("dashboard/utils/metrics/has-any-measured-data", ["exports", "@ember/utils", "@ember/object"], function (_exports, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasAnyMeasuredData;

  function hasAnyMeasuredData(testDataKeys) {
    return (0, _object.computed)(`${testDataKeys}.[]`, function () {
      let hasData = false;
      const dataKeys = this.get(testDataKeys) || [];

      for (const dataKey of dataKeys) {
        const data = this.get(dataKey) || [];
        const nonNullData = data.filter(datum => datum[1] !== null);

        if ((0, _utils.isPresent)(nonNullData)) {
          hasData = true;
          break;
        }
      }

      return hasData;
    });
  }
});