define("dashboard/routes/app/resources/new", ["exports", "rsvp", "@ember/utils", "@ember/routing/route", "dashboard/utils/addon-services-fetcher"], function (_exports, _rsvp, _utils, _route, _addonServicesFetcher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    beforeModel(transition) {
      // Don't continue if addonService query param is missing
      if ((0, _utils.isNone)(transition.to.queryParams.addonService)) {
        this.transitionTo('app.resources');
      }
    },

    model(params) {
      const addonServiceName = params.addonService;
      const addonPlanName = params.addonPlan;
      const {
        app
      } = this.modelFor('app');
      const team = app.get('team');
      return _addonServicesFetcher.default.fetch({
        store: this.store,
        app,
        team
      }).then(decoratedAddonServices => {
        const decoratedAddonService = decoratedAddonServices.findBy('name', addonServiceName); // don't bother if the addon could not be found in the list of servics

        let attachmentPromise;

        if (decoratedAddonService) {
          attachmentPromise = this.createNewAttachment(app, decoratedAddonService, addonPlanName);
        }

        return (0, _rsvp.hash)({
          attachment: attachmentPromise,
          app
        });
      });
    },

    createNewAttachment(app, addonService, addonPlanName) {
      const selectedPlanFullName = this._determineSelectedPlanFullName(addonService.get('name'), addonPlanName);

      return this._determineAddonServicePlan(addonService, selectedPlanFullName, app.get('isPrivateSpaceApp')).then(addonServicePlan => {
        return this._createAttachment(app, addonService, addonServicePlan);
      });
    },

    _createAttachment(app, addonService, addonServicePlan) {
      const addon = this.store.createRecord('addon', {
        app,
        addonService,
        addonServicePlan
      });
      return this.store.createRecord('attachment', {
        addon,
        app,
        name: ''
      });
    },

    _determineSelectedPlanFullName(addonServiceName, addonPlanName) {
      if (addonPlanName !== 'null' && addonPlanName !== undefined) {
        return `${addonServiceName}:${addonPlanName}`;
      } else {
        return null;
      }
    },

    _determineAddonServicePlan(addonService, selectedPlanFullName, isPrivateSpaceApp) {
      return addonService.fetchServicePlans(this.store).then(() => {
        let addonServicePlan = addonService.get('addonServicePlans').findBy('name', selectedPlanFullName);

        if (!addonServicePlan) {
          if (isPrivateSpaceApp) {
            addonServicePlan = addonService.get('defaultPrivateSpaceServicePlan');
          } else {
            addonServicePlan = addonService.get('defaultServicePlan');
          }
        }

        return addonServicePlan;
      });
    }

  });

  _exports.default = _default;
});