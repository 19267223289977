define("dashboard/components/app/rollback-confirmation", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    releasesToBeRolledBack: (0, _object.computed)('release', 'releases', function () {
      const release = this.release;
      const releaseVersion = release.get('version');
      const releases = this.releases;
      const releaseIndex = releases.findIndex(item => {
        return item.get('version') === releaseVersion;
      });
      return releases.slice(0, releaseIndex);
    })
  });

  _exports.default = _default;
});