define("dashboard/templates/components/permission-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "haB2r1PD",
    "block": "[[[1,[28,[35,0],null,[[\"name\",\"value\",\"isToggleable\",\"isLocked\",\"helpText\",\"toggleAction\"],[[30,0,[\"name\"]],[30,0,[\"value\"]],[30,0,[\"isToggleable\"]],[30,0,[\"locked\"]],[30,0,[\"helpText\"]],[28,[37,1],[[30,0],\"toggle\"],null]]]]],[1,\"\\n\"]],[],false,[\"tag-toggle\",\"action\"]]",
    "moduleName": "dashboard/templates/components/permission-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});