define("dashboard/models/collaborator", ["exports", "@ember/object/computed", "@ember/object", "ember-cp-validations", "dashboard/mixins/ds-error-monitoring-model", "@ember-data/model"], function (_exports, _computed, _object, _emberCpValidations, _dsErrorMonitoringModel, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    match,
    equal,
    filterBy
  } = _object.computed;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, _dsErrorMonitoringModel.default, {
    app: (0, _model.belongsTo)('app', {
      inverse: 'collaborators'
    }),
    user: (0, _model.attr)(),
    role: (0, _model.attr)('string'),
    permissions: (0, _model.attr)(),
    editingPermissions: (0, _computed.oneWay)('permissions'),
    email: (0, _object.computed)('user', function () {
      return this.get('user.email');
    }),
    isOwner: equal('role', 'owner'),
    isOrgManager: match('email', /@herokumanager\.com$/),
    errorTitleSubject: (0, _object.computed)('email', function () {
      return `Collaborator "${this.email}"`;
    }),
    // Merge model permissions and availablePermissions together with a
    // `selected` boolean attribute for use in downstream PermissionToggle.
    toggleablePermissions: (0, _object.computed)('editingPermissions.[]', 'availablePermissions.[]', function () {
      const permissions = this.availablePermissions || [];
      return permissions.map(perm => {
        const isSelected = this.editingPermissions.isAny('name', perm.get('name'));
        const newPerm = perm.copy();
        newPerm.set('selected', isSelected);
        return newPerm;
      });
    }),

    rollbackPermissions() {
      this.set('editingPermissions', this.permissions);
    },

    permits: (0, _object.computed)('permissions.[]', 'app.permissionNames.[]', function () {
      const permissionNames = this.get('app.permissionNames') || [];
      return permissionNames.map(name => {
        return _object.default.create({
          name,
          enabled: this.hasPermission(name),
          disabled: name === 'view'
        });
      });
    }),
    enabledPermits: filterBy('permits', 'enabled', true),

    hasPermission(name) {
      return this.permissions.mapBy('name').includes(name);
    }

  });

  _exports.default = _default;
});