define("dashboard/serializers/application", ["exports", "@ember-data/serializer/json", "dashboard/mixins/serializers/decamelized-keys"], function (_exports, _json, _decamelizedKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _json.default.extend(_decamelizedKeys.default, {});

  _exports.default = _default;
});