define("dashboard/routes/app/deploy", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app", "@ember/object"], function (_exports, _rsvp, _service, _basicApp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    current: (0, _service.inject)(),

    async model() {
      const {
        app,
        appPermissions
      } = this.modelFor('app');
      const coupling = app.get('pipelineCoupling');

      if (app.get('team.isEnterpriseTeam')) {
        try {
          await app.get('team.enterprise.githubAppSettings');
        } catch (e) {// It's okay to 404 here
        }
      }

      let githubAppInstallation, reposApiToken;

      if (app.get('team.isGithubAppEnabled')) {
        githubAppInstallation = app.get('team.enterprise.githubAppInstallation');
      }

      if (githubAppInstallation) {
        githubAppInstallation = githubAppInstallation.catch(() => {});
        reposApiToken = this.current.get('account').belongsTo('reposApiGithubToken').reload();
      }

      return (0, _rsvp.hash)({
        app,
        coupling,
        githubAuth: this.githubAuth(),
        githubAppInstallation,
        reposApiToken,
        appPermissions
      });
    },

    afterModel() {
      this.analytics.logEvent('App Deploy Page', 'Viewed');
    },

    githubAuth() {
      return this.store.queryRecord('github-auth', {
        endpoint: 'api.github.com'
      }).catch(() => {
        return _object.default.create({
          isNotLinked: true
        });
      });
    },

    actions: {
      refresh() {
        this.refresh();
      },

      unlinkGitHubRepo(githubAppLink) {
        this.analytics.logEvent('GitHub App', 'Unlinked');
        return githubAppLink.destroyRecord();
      },

      coupled(pipelineId) {
        this.transitionTo('pipelines.pipeline', pipelineId);
      }

    }
  });

  _exports.default = _default;
});