define("dashboard/mixins/save-buildpack-installations", ["exports", "@ember/runloop", "@ember/object/mixin", "jquery", "rsvp", "dashboard/config/environment", "dashboard/utils/push-deletion"], function (_exports, _runloop, _mixin, _jquery, _rsvp, _environment, _pushDeletion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    updateBuildpackInstallations(buildpackInstallations, toBeDeleted) {
      const store = this.store;
      const appId = this.id; // List of current buildpacks with IDs

      let buildpacksPayload = buildpackInstallations.map(buildpackInstallation => {
        return buildpackInstallation.getProperties('name', 'id', 'ordinal');
      }); // If deleting buildpack, remove that buildpack from list

      if (toBeDeleted) {
        buildpacksPayload = buildpacksPayload.filter(buildpack => {
          return buildpack.id !== toBeDeleted.get('id');
        });
      } // Hammer buildpacksPayload into right shape for request


      buildpacksPayload = buildpacksPayload.map(buildpackInstallation => {
        return {
          ordinal: buildpackInstallation.ordinal,
          buildpack: buildpackInstallation.name
        };
      }); // Build final data

      const data = {
        updates: buildpacksPayload
      }; // Not sure why async-button doesn't like jquery ajax promises...

      return new _rsvp.default.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: `${_environment.default.platformApiUrl}/apps/${appId}/buildpack-installations`,
          method: 'PUT',
          contentType: 'application/json',
          headers: {
            Accept: 'application/vnd.heroku+json; version=3'
          },
          withHerokuAuthorization: true,
          data: JSON.stringify(data)
        }).then(payload => {
          (0, _runloop.run)(() => {
            if (toBeDeleted) {
              (0, _pushDeletion.default)(store, 'buildpack-installation', toBeDeleted.id);
            } else {
              // Need the `toArray` because the live array would update with each unload
              buildpackInstallations.toArray().forEach(buildpackInstallation => {
                if (buildpackInstallation.id) {
                  (0, _pushDeletion.default)(store, 'buildpack-installation', buildpackInstallation.id);
                } else {
                  // can't pushDeletion without an ID
                  buildpackInstallation.unloadRecord();
                }
              });
              const modelClass = store.modelFor('buildpack-installation');
              const serializer = store.serializerFor('buildpack-installation');
              const normalized = serializer.normalizeResponse(store, modelClass, payload, null, 'findHasMany'); // Push into the app's buildpackInstallations relationship so that
              // the new buildpacks get associated with the app properly.

              normalized.data.forEach(bp => {
                bp.relationships = {
                  app: {
                    data: {
                      id: this.id,
                      type: 'app'
                    }
                  }
                };
                this.store.push({
                  data: bp
                });
              });
            }

            resolve();
          });
        }, error => {
          reject(error);
        });
      });
    }

  });

  _exports.default = _default;
});