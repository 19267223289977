define("dashboard/models/repositories-api-pull-request", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/object"], function (_exports, _model, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('repositories-api-github-user'),
    pipeline: (0, _model.belongsTo)('pipeline'),
    state: (0, _model.attr)('string'),
    number: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    htmlUrl: (0, _model.attr)('string'),
    head: (0, _model.attr)(),
    base: (0, _model.attr)(),
    prNumber: (0, _computed.readOnly)('number'),
    prNumberSortKey: (0, _computed.readOnly)('prNumber'),
    branch: (0, _computed.readOnly)('head.ref'),
    sha: (0, _computed.readOnly)('head.sha'),
    commitRepoId: (0, _computed.readOnly)('head.repo.id'),
    prRepoId: (0, _computed.readOnly)('base.repo.id'),
    forkRepoId: (0, _object.computed)('prRepoId', 'commitRepoId', function () {
      return this.prRepoId !== this.commitRepoId ? this.commitRepoId : null;
    }),
    isOpen: (0, _computed.equal)('state', 'open'),
    isPullRequest: true
  });

  _exports.default = _default;
});