define("dashboard/serializers/identity-provider-certificate", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      createdAt: {
        serialize: false
      },
      expiresAt: {
        serialize: false
      },
      identityProvider: {
        serialize: false
      }
    },

    serialize(snapshot) {
      const serializedData = this._super(...arguments);

      if (!snapshot.record.isNew) {
        // Don't send body on update.
        delete serializedData['body'];
      }

      return serializedData;
    }

  });

  _exports.default = _default;
});