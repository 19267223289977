define("dashboard/models/shogun/postgres-backup", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PostgresBackup = (_dec = (0, _relationships.belongsTo)('shogun/postgres-datastore', {
    inverse: 'backups'
  }), _dec2 = (0, _attr.default)('string'), _dec3 = (0, _attr.default)('date'), _dec4 = (0, _attr.default)('date'), _dec5 = (0, _attr.default)('date'), _dec6 = (0, _attr.default)('date'), _dec7 = (0, _attr.default)('date'), _dec8 = (0, _attr.default)('date'), _dec9 = (0, _attr.default)('date'), _dec10 = (0, _attr.default)('number'), _dec11 = (0, _attr.default)('boolean'), _dec12 = (0, _attr.default)('number'), _dec13 = (0, _attr.default)('number'), _dec14 = (0, _attr.default)('string'), _dec15 = (0, _attr.default)('string'), _dec16 = (0, _attr.default)('string'), _dec17 = (0, _attr.default)('string'), _dec18 = (0, _attr.default)('string'), _dec19 = (0, _attr.default)('string'), (_class = class PostgresBackup extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "datastore", _descriptor, this);

      _initializerDefineProperty(this, "uuid", _descriptor2, this);

      _initializerDefineProperty(this, "createdAt", _descriptor3, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor4, this);

      _initializerDefineProperty(this, "startedAt", _descriptor5, this);

      _initializerDefineProperty(this, "finishedAt", _descriptor6, this);

      _initializerDefineProperty(this, "purgedAt", _descriptor7, this);

      _initializerDefineProperty(this, "deletedAt", _descriptor8, this);

      _initializerDefineProperty(this, "canceledAt", _descriptor9, this);

      _initializerDefineProperty(this, "num", _descriptor10, this);

      _initializerDefineProperty(this, "succeeded", _descriptor11, this);

      _initializerDefineProperty(this, "processedBytes", _descriptor12, this);

      _initializerDefineProperty(this, "sourceBytes", _descriptor13, this);

      _initializerDefineProperty(this, "fromName", _descriptor14, this);

      _initializerDefineProperty(this, "fromType", _descriptor15, this);

      _initializerDefineProperty(this, "toName", _descriptor16, this);

      _initializerDefineProperty(this, "toType", _descriptor17, this);

      _initializerDefineProperty(this, "toUrl", _descriptor18, this);

      _initializerDefineProperty(this, "schedule", _descriptor19, this);
    }

    get prefix() {
      if (this.fromType === 'pg_dump') {
        if (this.toType === 'pg_restore') {
          return 'c';
        } else {
          return this.schedule ? 'a' : 'b';
        }
      } else {
        if (this.toType === 'pg_restore') {
          return 'r';
        } else {
          return 'b';
        }
      }
    }

    get name() {
      return `${this.prefix}${this.num}`;
    }

    async generateDownloadUrl() {
      const addonId = this.get('datastore.addon.id');
      const appId = this.get('datastore.addon.app.id');
      const backupId = this.id;
      const adapter = this.store.adapterFor('shogun/postgres-backup');
      const {
        url
      } = await adapter.generateDownloadUrl(appId, addonId, backupId);
      return url;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "datastore", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "startedAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "finishedAt", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "purgedAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "deletedAt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canceledAt", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "num", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "succeeded", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "processedBytes", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sourceBytes", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "fromName", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "fromType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "toName", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "toType", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "toUrl", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "schedule", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PostgresBackup;
});