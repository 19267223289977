define("dashboard/validations/identity-provider", ["exports", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validator = _exports.default = void 0;
  const Validations = {
    entityId: (0, _validators.validatePresence)({
      presence: true,
      message: ''
    }),
    ssoTargetUrl: (0, _validators.validatePresence)({
      presence: true,
      message: ''
    })
  };
  _exports.default = Validations;
  const validator = (0, _emberChangesetValidations.default)(Validations);
  _exports.validator = validator;
});