define("dashboard/controllers/new", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/controller", "@ember/utils", "dashboard/mixins/new-app", "@ember/string"], function (_exports, _service, _object, _computed, _controller, _utils, _newApp, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_newApp.default, {
    overrideConfigVars: {},
    errorIsVisible: false,
    accountFeatures: (0, _service.inject)(),
    template: (0, _computed.alias)('model.template'),
    githubRepo: (0, _computed.alias)('model.githubRepo'),
    attachments: (0, _computed.alias)('model.attachments'),
    appSetup: (0, _computed.alias)('model.appSetup'),
    githubAuth: (0, _computed.alias)('model.githubAuth'),
    githubUser: (0, _computed.alias)('model.githubUser'),
    configVars: (0, _computed.map)('template.configVars', function (confVar) {
      const newVars = this.overrideConfigVars;

      if (newVars[confVar.key]) {
        confVar.set('value', newVars[confVar.key]);
      }

      return confVar;
    }),
    showProgress: false,
    isShowingBuild: (0, _computed.not)('appSetup.isBuilt'),
    // Override mixin properties
    isSaving: (0, _computed.or)('app.isSaving', 'appSetup.isDeploying'),
    isDisabledInput: (0, _computed.or)('isSaving', 'appPersisted', 'appSetup.isDeployed'),
    isNotAuthedToGithub: (0, _computed.or)('isNotLinkedToGithub', 'isGithubLinkDeleted'),
    isNotLinkedToGithub: (0, _computed.not)('model.githubAuth.isLinked'),
    isGithubLinkDeleted: (0, _computed.bool)('model.githubAuth.isDeleted'),
    hasTemplateErrors: (0, _computed.or)('template.hasErrors', 'githubRepo.hasErrors'),

    /*
     * Since this page doesn't use the edit pattern, we need to
     * manually wipe errors once any change to the model occurs
     */
    errorObserver: (0, _object.observer)('app.name', function () {
      if (this.get('app.name')) {
        this.errorMonitor.wipe();
      }
    }),
    errorMonitor: (0, _service.inject)(),
    // This builds the `sourceApp` object for the app-setup model
    // See this controller's deploy() function & app-setup model's save() function
    // for how this is used.
    appSetupApp: (0, _object.computed)('app', 'app.name', 'selectedSpace', 'selectedRegion', 'spaceNameParam', 'selectedOrg', function () {
      const appSetupAppProps = {};
      const appName = this.get('app.name');

      if ((0, _utils.isPresent)(appName)) {
        appSetupAppProps['name'] = appName;
      }

      if (this.selectedOrg) {
        appSetupAppProps['team'] = this.get('selectedOrg.name');
      }

      if (this.selectedSpace && this.spaceNameParam) {
        appSetupAppProps['space'] = this.get('selectedSpace.name');

        if (this.get('app.internalRouting')) {
          appSetupAppProps['internal_routing'] = this.get('app.internalRouting');
        }
      } else {
        appSetupAppProps['region'] = this.get('selectedRegion.name');
      }

      return _object.default.create(appSetupAppProps);
    }),
    actions: {
      /**
       * deploys a template "heroku button" application
       */
      deploy() {
        const template = this.template;
        const appSetup = this.appSetup;
        const appSetupApp = this.appSetupApp;
        const pipeline = this.selectedPipelineModel;

        if (!template.get('configVarsAreValid')) {
          this.set('showConfigVarErrors', true);
          return;
        }

        this.set('showProgress', true);
        this.analytics.logEvent('Heroku Button', 'Deployed');
        appSetup.wipeState();
        appSetup.setProperties({
          sourceApp: appSetupApp,
          template
        });
        appSetup.loadTemplateSourceBlob().then(() => {
          appSetup.save().then(a => {
            this._savePipeline(pipeline).then(pl => {
              return this._coupleAppToPipeline(pl, a);
            }).then(() => {
              this.store.unloadRecord(this.app);
            });
          }, reason => {
            let needsVerification, needsVerificationViaAppSetup;

            if (reason.original && reason.original.status === 422) {
              needsVerification = /verify your account/i.test(reason.message);
            } else {
              needsVerificationViaAppSetup = appSetup.get('errors').any(function (error) {
                return /verify your account/i.test(error);
              });
            }

            appSetup.reset();

            if (needsVerificationViaAppSetup || needsVerification) {
              this.requestVerification();
            }
          });
        }).catch(() => {
          const errorContainer = appSetup.get('errorContainer');
          errorContainer.set('title', 'Invalid template repository:');
          errorContainer.addObject('The source could not be retrieved.');
          appSetup.reset();
          this.set('showProgress', false);
        });
      },

      showLog(name) {
        this.toggleProperty(`isShowing${(0, _string.capitalize)(name)}`);
      },

      disconnectGithub() {
        return this.githubAuth.destroyRecord();
      }

    }
  });

  _exports.default = _default;
});