define("dashboard/templates/components/metrics/process-monitor-configuration/section", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "x75HVWv/",
    "block": "[[[10,\"section\"],[14,0,\"form-group dark-gray mt2 mb5\"],[12],[1,\"\\n  \"],[10,\"strong\"],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"u-margin-Bxs gray\"],[12],[1,\"\\n    \"],[1,[30,0,[\"helpText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "dashboard/templates/components/metrics/process-monitor-configuration/section.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});