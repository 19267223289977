define("dashboard/models/favorite", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    type: (0, _model.attr)('string'),
    resourceId: (0, _model.attr)('string'),
    resourceName: (0, _model.attr)('string'),
    isApp: (0, _computed.equal)('type', 'app'),
    isPipeline: (0, _computed.equal)('type', 'pipeline')
  });

  _exports.default = _default;
});