define("dashboard/components/team/settings/manage-third-party-connection", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    githubAppInstallation: (0, _computed.readOnly)('team.githubAppInstallation'),
    hasGithubAppInstalled: (0, _computed.notEmpty)('githubAppInstallation.content'),
    hasAdminAccess: (0, _computed.readOnly)('team.currentPermissions.canManageGitProviders')
  });

  _exports.default = _default;
});