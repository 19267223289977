define("dashboard/components/metrics/charts/chart-header/alert-summary", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "numeral"], function (_exports, _computed, _component, _object, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    filterBy,
    notEmpty
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: 'metrics-alert-summary',
    classNames: ['metrics__alert-summary', 'pl4'],
    statusIconClass: (0, _object.computed)('monitor.isActive', function () {
      return this.get('monitor.isActive') ? 'enabled malibu-fill-gradient-blue anim-pulse' : 'disabled fill-silver';
    }),
    monitoringText: (0, _object.computed)('monitor.isActive', 'activeAlertPresent', function () {
      return this.activeAlertPresent ? 'Active Alert' : this.get('monitor.isActive') ? 'Monitoring Enabled' : ' Monitoring Available';
    }),
    alertAction: (0, _object.computed)('monitor.isActive', function () {
      return this.get('monitor.isActive') ? 'Edit' : 'Add';
    }),
    _alertingTypeMonitors: filterBy('webProcess.monitors', 'isAlertingType', true),
    monitor: (0, _object.computed)('_alertingTypeMonitors', 'isLatency', function () {
      const alertingTypeMonitors = this._alertingTypeMonitors || [];
      const prop = this.isLatency ? 'isLatency' : 'isErrorRate';
      return alertingTypeMonitors.findBy(prop, true);
    }),
    isLatency: (0, _computed.equal)('type', 'latency'),
    activeAlerts: filterBy('monitor.alerts', 'isActive', true),
    activeAlertPresent: notEmpty('activeAlerts'),
    description: (0, _object.computed)('isLatency', 'monitor.isActive', function () {
      const monitor = this.monitor;
      const isLatency = this.isLatency;

      if (monitor && monitor.get('isActive')) {
        let subject;
        const period = monitor.get('period');
        const value = monitor.get('value');

        if (isLatency) {
          subject = `p95 response times greater than ${value}ms`;
        } else {
          subject = `Error rate greater than ${(0, _numeral.default)(value).format('0[.]0%')}`;
        }

        return `${subject} for ${period}m or more.`;
      } else if (isLatency) {
        return 'Monitor response times for slow requests';
      } else {
        return 'Monitor traffic for failed requests';
      }
    }),
    actions: {
      openAlertingConfig() {
        this.openAlertingConfig(this.type);
      }

    }
  });

  _exports.default = _default;
});