define("dashboard/routes/pipelines/pipeline/tests/test-run", ["exports", "@ember/utils", "dashboard/models/test-run", "@ember/object", "@ember/routing/route", "rsvp", "@ember/runloop", "@ember/service", "@ember/object/evented"], function (_exports, _utils, _testRun, _object, _route, _rsvp, _runloop, _service, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FAVICONS = {
    [_testRun.STATES.PENDING]: 'favicon-pending',
    [_testRun.STATES.CREATING]: 'favicon-pending',
    [_testRun.STATES.BUILDING]: 'favicon-pending',
    [_testRun.STATES.RUNNING]: 'favicon-pending',
    [_testRun.STATES.SUCCEEDED]: 'favicon-succeeded',
    [_testRun.STATES.FAILED]: 'favicon-failed',
    [_testRun.STATES.ERRORED]: 'favicon-errored'
  };

  var _default = _route.default.extend({
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    githubToken: (0, _service.inject)('github-token'),
    flashMessages: (0, _service.inject)(),
    favicon: (0, _service.inject)(),
    webSocket: (0, _service.inject)(),

    connectWebSocket() {
      this.channel = this.webSocket.join(this.getRoom()).channel;
      this.onCreateRef = this.channel.on('create', this.dataHandler);
      this.onUpdateRef = this.channel.on('update', this.dataHandler);
    },

    disconnectWebSocket: (0, _evented.on)('deactivate', function () {
      this.channel.off('create', this.onCreateRef);
      this.channel.off('update', this.onUpdateRef);
      this.channel.leave();
      this.channel = null;
    }),

    getRoom() {
      let testRunId;
      const model = this.modelFor(this.routeName);

      if (model) {
        testRunId = (0, _object.get)(model, 'testRun.id');
      }

      if (testRunId) {
        return `test-runs/${testRunId}/test-nodes`;
      }
    },

    dataHandler: (0, _object.computed)(function () {
      return _ref => {
        let {
          resource,
          data
        } = _ref;

        if (resource !== 'test-node') {
          return;
        }

        const found = this.store.peekRecord('test-node', data.id);

        if (found) {
          found.setProperties(data);
        } else {
          this.store.pushPayload('test-node', data);
        }
      };
    }),

    model(params) {
      const {
        pipeline
      } = this.modelFor('pipelines.pipeline');
      const testRun = this.store.queryRecord('test-run', {
        pipeline: pipeline.get('id'),
        number: params['test_run_number']
      });
      return _rsvp.default.hash({
        testRun,
        pipeline
      });
    },

    afterModel(_ref2) {
      let {
        testRun
      } = _ref2;
      // Subscribe to node events before loading the data. This way we can't get
      // caught by a race condition with data updating
      this.connectWebSocket();
      this.store.query('test-case', {
        testRunId: testRun.id
      }); // Load testNodes before the route resolves to prevent jumpy content

      return testRun.get('testNodes');
    },

    setupController(controller, model) {
      this.findPR(model.testRun.get('commitBranch')).then(pr => {
        (0, _runloop.run)(() => {
          controller.set('pullRequest', pr);
        });
      });

      const statusObserver = () => {
        const favicon = FAVICONS[this.get('controller.model.testRun.status')];
        this.favicon.set('icon', favicon);
      };

      this.addObserver('controller.model.testRun.status', this, statusObserver);
      this.on('deactivate', () => {
        if (this.hasObserverFor('controller.model.testRun.status')) {
          this.removeObserver('controller.model.testRun.status', this, statusObserver);
        }

        this.favicon.reset();
      });
      return this._super(...arguments);
    },

    findPR(branch) {
      const {
        pipeline
      } = this.currentModel;
      const prs = pipeline.get('pullRequests');
      const repo = pipeline.get('repositoryName'); // Don't fetch the PR when we don't know the repo; Prevents errors
      // with missing GitHub repo access or asssuming identity.

      if ((0, _utils.isBlank)(repo)) {
        return _rsvp.default.resolve([]);
      }

      const findPR = pullRequests => pullRequests.findBy('head.ref', branch);

      if (prs.length) {
        return _rsvp.default.resolve(findPR(prs));
      } else {
        return pipeline.fetchPullRequests(repo).then(() => {
          return findPR(pipeline.get('pullRequests'));
        });
      }
    },

    actions: {
      cancelTestRun(testRun, eventDescription) {
        const email = this.get('current.account.email');

        if ((0, _utils.isPresent)(eventDescription)) {
          // TODO refactor ?
          this.analytics.logEvent(eventDescription, '');
        }

        testRun.setProperties({
          status: 'cancelled',
          message: `${email} cancelled this run via Heroku Dashboard`
        });
        return testRun.save().catch(() => {
          testRun.rollbackAttributes();
        });
      }

    }
  });

  _exports.default = _default;
});