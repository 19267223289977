define("dashboard/models/test-case-group", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    testCases: (0, _object.computed)(() => []),
    status: (0, _object.computed)('testCases.@each.status', function () {
      const testCases = this.testCases;

      if (testCases.isAny('failed')) {
        return 'failed';
      } else if (testCases.isAny('pending')) {
        return 'pending';
      } else {
        return 'passed';
      }
    })
  });

  _exports.default = _default;
});