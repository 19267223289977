define("dashboard/models/team/feature", ["exports", "dashboard/models/feature", "ember-data/relationships"], function (_exports, _feature, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({
    team: (0, _relationships.belongsTo)('team')
  });

  _exports.default = _default;
});