define("dashboard/serializers/pipeline/collaborator", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      if (snapshot.record.get('isNew')) {
        return {
          permissions: snapshot.record.get('permissions'),
          user: snapshot.record.get('user'),
          pipeline: snapshot.record.get('pipeline.id')
        };
      } else {
        return {
          permissions: snapshot.record.get('permissions')
        };
      }
    },

    extractAttributes(modelClass, payload) {
      const attributes = this._super(...arguments);

      attributes.permissions = payload.permissions.mapBy('name');
      return attributes;
    },

    extractRelationships(modelClass, resourceHash) {
      const relationships = this._super(...arguments);

      relationships.pipeline = {
        data: {
          type: 'pipeline',
          id: resourceHash['pipeline']['id']
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});