define("dashboard/templates/components/net-promoter-score/rating", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "g6yOyDBi",
    "block": "[[[10,0],[14,0,\"flex justify-between mb2\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"ratings\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,2],null,[[\"onClick\",\"buttonType\",\"default\",\"class\"],[[28,[37,3],[[30,0],\"selectRating\",[30,1]],null],\"secondary\",[30,1],[52,[28,[37,5],[[30,0,[\"selectedRating\"]],[30,1]],null],\"selected\"]]]]],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"flex pv1 br1 mb2 gray\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"b\"],[12],[1,\"\\n    Not likely at all\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n  \"],[10,1],[14,0,\"b\"],[12],[1,\"\\n    Extremely likely\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"rating\"],false,[\"each\",\"-track-array\",\"hk-button\",\"action\",\"if\",\"eq\"]]",
    "moduleName": "dashboard/templates/components/net-promoter-score/rating.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});