define("dashboard/templates/components/app/developer-experience-banners-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MVc5N541",
    "block": "[[[41,[30,0,[\"showPipelineBanner\"]],[[[6,[39,1],null,[[\"isInBlockForm\",\"bannerTestTarget\",\"bannerStyle\",\"bannerName\",\"iconName\",\"primaryActionText\",\"transitionConfig\",\"userPreferencesKey\",\"bannerDismissalValue\"],[true,\"pipeline-banner\",\"app\",\"Pipeline Banner\",\"pipeline-16\",\"Create a Heroku Pipeline\",[28,[37,2],null,[[\"transitionRoute\"],[\"pipelines.new\"]]],\"developerExperienceBannerDismissals\",\"pipelineBanner\"]],[[\"default\"],[[[[1,\"    Get a complete visualization of your app in a team-based continuous delivery environment with\\n    \"],[8,[39,3],[[24,6,\"https://devcenter.heroku.com/articles/pipelines\"]],[[\"@withPrelude\"],[false]],[[\"default\"],[[[[1,\"\\n      Heroku Pipelines.\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[]],null],[41,[30,0,[\"showTeamBanner\"]],[[[6,[39,1],null,[[\"isInBlockForm\",\"bannerTestTarget\",\"bannerStyle\",\"bannerName\",\"iconName\",\"primaryActionText\",\"transitionConfig\",\"userPreferencesKey\",\"bannerDismissalValue\"],[true,\"team-banner\",\"app\",\"Team Banner\",\"team-16\",\"Create a Team\",[28,[37,2],null,[[\"transitionRoute\"],[\"new-team\"]]],\"developerExperienceBannerDismissals\",\"teamBanner\"]],[[\"default\"],[[[[1,\"    Unify collaboration, administration, and billing with fine-grained control for all your apps with\\n    \"],[8,[39,3],[[24,6,\"https://devcenter.heroku.com/articles/heroku-teams\"]],[[\"@withPrelude\"],[false]],[[\"default\"],[[[[1,\"\\n      Heroku Teams.\\n    \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[],false,[\"if\",\"general-banner\",\"hash\",\"dev-center-link\"]]",
    "moduleName": "dashboard/templates/components/app/developer-experience-banners-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});