define("dashboard/models/identity-provider-certificate", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * https://github.com/heroku/api/blob/master/schema/variants/3.identity-providers/platform-api-reference.md#certificates-2
   */
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    body: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    expiresAt: (0, _model.attr)('date'),
    identityProvider: (0, _model.belongsTo)('identity-provider'),
    isExpired: (0, _object.computed)('expiresAt', function () {
      const now = new Date();
      return this.expiresAt <= now;
    })
  });

  _exports.default = _default;
});