define("dashboard/components/metrics/charts/legend/chart-legend-item", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/component"], function (_exports, _computed, _object, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An item in the legend the corresponds to a specific object in a chart
   */
  var _default = _component.default.extend({
    tagName: 'chart-legend-item',
    classNameBindings: [':metrics__legend__item', 'isLastOrdered:last-ordered', 'seriesHidden:series-hidden', 'canToggle:series-toggle', 'lastActiveToggle:last-toggle', 'showSecondaryValue:has-secondary-value'],
    showSecondaryValue: (0, _computed.readOnly)('legendItem.hasSecondaryValues'),
    graphIsVisible: (0, _computed.readOnly)('legendItem.isVisible'),
    notVisible: (0, _computed.not)('graphIsVisible'),
    analytics: (0, _service.inject)(),
    colorClass: (0, _object.computed)('legendItem.color', function () {
      const color = this.get('legendItem.color');
      return `metrics__color--${color}--legend metrics__color--${color}--legend--border`;
    }),
    // Only supply a key for toggle-able legend items
    canToggle: (0, _computed.notEmpty)('legendItem.key'),
    seriesHidden: (0, _computed.and)('notVisible', 'canToggle'),
    hasVisibleCheckmark: (0, _computed.and)('graphIsVisible', 'canToggle'),
    lastActiveToggle: (0, _computed.and)('graphIsVisible', 'chartHasOneActiveToggle'),

    click() {
      if (this.canToggle) {
        const key = this.get('legendItem.key');
        this.toggleVisibility(key);
        this.analytics.logEvent('Chart Series Visibility', 'Toggled', {
          key
        });
      }
    }

  });

  _exports.default = _default;
});