define("dashboard/components/pipeline/build-modal", ["exports", "@ember/component", "dashboard/helpers/lazy-property", "@ember/service"], function (_exports, _component, _lazyProperty, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // No wrapper tag
    tagName: '',
    store: (0, _service.inject)(),

    /*
     * Some builds don't include output_stream_url. So we need to explicitly fetch the build we need
     */
    build: (0, _lazyProperty.default)(function () {
      return this.store.queryRecord('build', {
        app: this.get('app.id'),
        buildId: this.get('currentBuild.id')
      });
    }),
    actions: {
      hideBuildModal() {
        this.hideBuildModal();
      }

    }
  });

  _exports.default = _default;
});