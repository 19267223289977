define("dashboard/components/identity-provider", ["exports", "@ember/component", "ember-concurrency", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/debug"], function (_exports, _component, _emberConcurrency, _service, _object, _computed, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: off */
  const STATE_LOADING = 'loading';
  const STATE_NOT_INSTALLED = 'not installed';
  const STATE_CAN_MIGRATE_FROM_TEAM = 'can migrate from team';
  const STATE_CONFIGURED = 'configured';

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Element
    tagName: '',
    // --------------------------------------------------------------------------
    // Services
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    errorMonitor: (0, _service.inject)(),
    api: (0, _service.inject)(),
    current: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Args

    /**
     * @type {IdentityProviderOwner}
     */
    owner: null,

    /**
     * @type {boolean}
     */
    renderModalInPlace: false,

    /**
     * Exposed as an arg to allow query params to control its presence
     * @type {boolean}
     */
    isConfiguring: false,
    // --------------------------------------------------------------------------
    // State
    hasConfirmedDisable: false,
    // --------------------------------------------------------------------------
    // Computed Properties
    identityProvider: (0, _computed.readOnly)('owner.identityProvider.content'),
    identityProviderIsLoading: (0, _computed.readOnly)('owner.identityProvider.isPending'),
    teams: (0, _object.computed)('owner.teams', function () {
      return this.get('owner.teams') || [];
    }),
    teamsAreLoading: (0, _computed.readOnly)('owner.teams.isPending'),
    teamIdentityProviderPromises: (0, _computed.mapBy)('teams', 'identityProvider'),
    pendingTeamIdentityProviderPromises: (0, _computed.filterBy)('teamIdentityProviderPromises', 'isPending'),
    teamIdentityProviders: (0, _object.computed)('teamIdentityProviderPromises.@each.content', function () {
      return this.teamIdentityProviderPromises.mapBy('content').compact().filterBy('hasRequiredAttrs', true);
    }),
    teamIdentityProvidersAreLoading: (0, _computed.or)('teamsAreLoading', 'pendingTeamIdentityProviderPromises.length'),
    account: (0, _computed.readOnly)('owner.enterprise'),
    accountIdentityProvider: (0, _computed.readOnly)('account.identityProvider.content'),
    accountIdentityProviderIsLoading: (0, _computed.or)('account.isPending', 'account.identityProvider.isPending'),
    isLoading: (0, _computed.or)('identityProviderIsLoading', 'teamIdentityProvidersAreLoading', 'accountIdentityProviderIsLoading'),
    showConfigPanel: (0, _computed.and)('isConfiguring', 'beginSetup.isIdle'),
    state: (0, _object.computed)('isLoading', 'identityProvider.isNew', 'teamIdentityProviders.[]', 'hasRequiredAttrs', function () {
      const isLoading = this.isLoading;

      if (isLoading) {
        return STATE_LOADING;
      }

      const identityProvider = this.identityProvider;
      const teamIdentityProviders = this.teamIdentityProviders;

      if (!identityProvider && teamIdentityProviders.length) {
        return STATE_CAN_MIGRATE_FROM_TEAM;
      }

      if (!identityProvider) {
        return STATE_NOT_INSTALLED;
      }

      if (identityProvider.get('isNew')) {
        return STATE_NOT_INSTALLED;
      }

      if (!this.hasRequiredAttrs) {
        return STATE_NOT_INSTALLED;
      }

      return STATE_CONFIGURED;
    }),
    hasRequiredAttrs: (0, _computed.readOnly)('identityProvider.hasRequiredAttrs'),
    enterpriseAccountMember: (0, _object.computed)('current.account.id', 'account.members.@each.userId', function () {
      const id = this.get('current.account.id');
      const members = this.get('account.members') || [];
      return members.findBy('userId', id);
    }),

    // --------------------------------------------------------------------------
    // Lifecycle
    init() {
      this._super(...arguments);

      if (this.isConfiguring) {
        // If this is passed into the component with a value of true, it means a
        // query param is being set to open the panel. In that case, we need
        // to make sure the `beginSetup` task is run since this is probably happening
        // on an initial page load/reload and not via a user action.
        this.beginSetup.perform();
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      (false && !(this.owner) && (0, _debug.assert)('owner must be passed to {{identity-provider ...}}', this.owner));
    },

    // --------------------------------------------------------------------------
    // Tasks
    beginSetup: (0, _emberConcurrency.task)(function* () {
      yield this.owner.get('identityProvider');
      this.set('isConfiguring', true);
    }),
    migrateIdentityProviderToAccount: (0, _emberConcurrency.task)(function* (identityProvider) {
      this.analytics.logEvent('Enterprise Account SSO', 'Migrated from team');
      const id = identityProvider.get('id');
      const api = this.api;
      const store = this.store;
      const url = `/identity-providers/${id}/migrate`;
      const adapter = store.adapterFor('identity-provider');
      const method = 'POST';
      const payload = yield api.request(url, {
        adapter,
        method
      });
      const normalized = store.normalize('identity-provider', payload);
      store.push(normalized);
    })
  });

  _exports.default = _default;
});