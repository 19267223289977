define("dashboard/components/addon-action-item", ["exports", "@ember/object", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _object, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    tagName: 'li',
    classNames: ['addon-action-item'],
    // --------------------------------------------------------------------------
    // Arguments
    addonAction: undefined,
    addon: undefined,
    webUrlForApp: undefined,
    // --------------------------------------------------------------------------
    // Computed Props
    label: (0, _computed.readOnly)('addonAction.label'),
    url: (0, _object.computed)('addonActionUrl', 'addonWebUrl', function () {
      return this.addonActionUrl || this.addonWebUrl;
    }),
    params: (0, _object.computed)('addonAction', function () {
      return [{
        name: 'resource_uuid',
        value: this.get('addon.id')
      }, {
        name: 'resource_name',
        value: this.get('addon.name')
      }, {
        name: 'resource_id',
        value: this.get('addon.hid')
      }, {
        name: 'heroku_action',
        value: this.get('addonAction.action')
      }].reject(function (item) {
        return !item.value;
      });
    }),
    addonActionUrl: (0, _object.computed)('addonAction.url', 'params', function () {
      const startingUrlValue = this.get('addonAction.url') || '';
      return this.params.reduce(function (url, pair) {
        return url.replace(`:${pair.name}`, encodeURIComponent(pair.value));
      }, startingUrlValue);
    }),
    addonWebUrl: (0, _object.computed)('webUrlForApp', 'params', function () {
      const url = this.get('webUrlForApp');
      const params = $.param(this.params);
      return url && params ? `${url}?${params}` : url;
    }),
    // --------------------------------------------------------------------------
    // Actions
    actions: {
      visitLink() {
        this.analytics.logEvent('Add-on Action', 'Clicked');
        window.open(this.url, this.webUrlTarget);
        return false;
      }

    }
  });

  _exports.default = _default;
});