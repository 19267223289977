define("dashboard/routes/team/space/apps", ["exports", "rsvp", "@ember/service", "@ember/routing/route", "dashboard/utils/infinite-query", "dashboard/mixins/document-title"], function (_exports, _rsvp, _service, _route, _infiniteQuery, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),

    get documentTitle() {
      return this.modelFor('team').team.get('name');
    },

    model() {
      const store = this.store;
      const {
        team
      } = this.modelFor('team');
      const space = this.modelFor('team/space');
      const apps = (0, _infiniteQuery.default)(store, 'app', {
        team: team.get('id')
      }, {}, this.flashMessages);
      return (0, _rsvp.hash)({
        team,
        space,
        apps
      });
    },

    afterModel() {
      this.analytics.logEvent('Space Apps List Page', 'Viewed');
    },

    renderTemplate() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});