define("dashboard/components/pipeline/transfer-pipeline-panel", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/utils/pluralize"], function (_exports, _component, _object, _computed, _service, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    accountFeatures: (0, _service.inject)(),
    apps: (0, _object.computed)('pipeline.apps.@each.isTest', function () {
      return this.get('pipeline.apps').rejectBy('isTest');
    }),
    appsToTransfer: (0, _object.computed)('newOwner.id', 'app.@each.owner', 'app.@each.name', function () {
      const newOwnerID = this.get('newOwner.id');
      return this.apps.rejectBy('owner.id', newOwnerID).sortBy('name');
    }),
    appCount: (0, _computed.readOnly)('appsToTransfer.length'),
    productionAppsToTransfer: (0, _computed.filterBy)('appsToTransfer', 'isProduction'),
    stagingAppsToTransfer: (0, _computed.filterBy)('appsToTransfer', 'isStaging'),
    developmentAppsToTransfer: (0, _computed.filterBy)('appsToTransfer', 'isDevelopment'),
    reviewAppsToTransfer: (0, _computed.filterBy)('appsToTransfer', 'isReview'),
    currentOwnerIsTeam: (0, _computed.bool)('pipeline.ownerTeamType'),
    currentOwnerIsUser: (0, _computed.or)('pipeline.doesNotHaveOwner', 'pipeline.ownerUserType'),
    curentOwnerIsEnterpriseTeam: (0, _computed.and)('currentOwnerIsTeam', 'pipeline.team.isEnterpriseTeam'),
    recipientIsTeam: (0, _computed.equal)('newOwner.type', 'team'),
    recipientIsEnterpriseTeam: (0, _computed.bool)('newOwner.isEnterpriseTeam'),
    showEnterpriseUpgradeWarning: (0, _object.computed)('recipientIsEnterpriseTeam', 'curentOwnerIsEnterpriseTeam', function () {
      // When personal or team pipelines are transferred to an enterprise team.
      return this.recipientIsEnterpriseTeam && !this.curentOwnerIsEnterpriseTeam;
    }),
    showTeamUpgradeWarning: (0, _object.computed)('currentOwnerIsUser', 'recipientIsTeam', 'recipientIsEnterpriseTeam', function () {
      // When personal pipelines are transferred to a credit-card team.
      return this.currentOwnerIsUser && this.recipientIsTeam && !this.recipientIsEnterpriseTeam;
    }),
    buttonText: (0, _object.computed)('appCount', function () {
      const appCount = this.appCount;

      if (appCount > 0) {
        return `Transfer Pipeline and ${appCount} ${(0, _pluralize.default)(appCount, 'App', 'Apps')}`;
      } else {
        return `Transfer Pipeline`;
      }
    }),
    actions: {
      updateOwner() {
        return this.attrs.updateOwner().then(() => {
          this.set('transfersComplete', true);
        });
      }

    }
  });

  _exports.default = _default;
});