define("dashboard/templates/components/datastore/private-link/connection", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aHkKg5Ho",
    "block": "[[[10,0],[14,0,\"flex flex-column flex-row-m flex-row-l items-center-ns mv2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mr2 w-25-ns mb2\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"iconName\"]],[30,0,[\"iconClass\"]],16]],null],[1,\"\\n    \"],[10,1],[14,0,\"ml1\"],[15,\"className\",[30,0,[\"textColor\"]]],[12],[1,[34,1]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"dark-gray w-40-ns mr2 mb2\"],[12],[1,[30,0,[\"endpointId\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"dark-gray\"],[12],[1,\"\\n\"],[41,[30,0,[\"isShowingCredentials\"]],[[[1,\"      \"],[10,\"input\"],[14,\"readonly\",\"true\"],[14,0,\"hk-input--read-only w-100\"],[15,2,[29,[[30,0,[\"endpointUrl\"]]]]],[14,4,\"text\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,3],null,[[\"default\",\"buttonType\",\"onClick\"],[\"Reveal Credentials\",\"secondary\",[28,[37,4],[[30,0],\"showCredentials\"],null]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"malibu-icon\",\"status\",\"if\",\"hk-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/datastore/private-link/connection.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});