define("dashboard/components/team-list", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/utils/custom-computed"], function (_exports, _component, _object, _computed, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'team-list',
    // --------------------------------------------------------------------------
    // Arguments
    teams: null,
    page: 1,
    pageSize: 40,
    filter: '',
    // --------------------------------------------------------------------------
    // Computed Props
    totalPages: (0, _object.computed)('filteredTeams.length', 'pageSize', function () {
      return Math.ceil(this.filteredTeams.length / this.pageSize);
    }),
    filteredTeams: (0, _customComputed.fuzzyFilterBy)('teams', 'name', 'filter'),
    filterObserver: (0, _object.observer)('filter', function () {
      // reset the page when the filter query changes
      this.set('page', 1);
    }),
    sortOrder: ['name:asc'],
    sortedFilteredTeams: (0, _computed.sort)('filteredTeams', 'sortOrder'),
    sortedFilteredPaginatedTeams: (0, _object.computed)('sortedFilteredTeams.[]', 'page', 'pageSize', function () {
      const {
        page,
        pageSize,
        sortedFilteredTeams
      } = this;
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      return sortedFilteredTeams.slice(startIndex, endIndex);
    })
  });

  _exports.default = _default;
});