define("dashboard/adapters/ssl-endpoint", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.ssl_cert',

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/ssl-endpoints`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/ssl-endpoints/${id}`;
    },

    urlForUpdateRecord() {
      return this.urlForDeleteRecord(...arguments);
    }

  });

  _exports.default = _default;
});