define("dashboard/components/pipeline/app-deployment", ["exports", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    isRollback: (0, _computed.readOnly)('deployment.isRollback'),
    releasedAt: (0, _computed.or)('deployment.updatedAt', 'deployment.createdAt'),
    deploymentIsLoaded: (0, _computed.notEmpty)('deployment.id'),
    isNotReviewApp: (0, _computed.not)('app.isReview'),
    isGithubIntegrationUser: (0, _computed.equal)('deployment.user.email', 'github+integration@heroku.com'),
    isNotGithubIntegrationUser: (0, _computed.not)('isGithubIntegrationUser'),
    showAvatar: (0, _computed.and)('deploymentIsLoaded', 'isNotReviewApp', 'isNotGithubIntegrationUser'),
    rollbackVersion: (0, _object.computed)('deployment.description', function () {
      if (this.isRollback) {
        return this.get('deployment.description').split(' ').splice(-1);
      }
    })
  });

  _exports.default = _default;
});