define("dashboard/controllers/account/applications", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _controller, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    persistedClients: (0, _object.computed)('model.clients.@each.{id,isNew}', function () {
      return this.get('model.clients').filter(client => {
        return !((0, _utils.isEmpty)(client.get('id')) || client.get('isNew'));
      });
    })
  });

  _exports.default = _default;
});