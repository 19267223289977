define("dashboard/models/test-node", ["exports", "@ember-data/model", "@ember/object/computed", "dashboard/models/test-run", "dashboard/mixins/ds-error-monitoring-model"], function (_exports, _model, _computed, _testRun, _dsErrorMonitoringModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_dsErrorMonitoringModel.default, {
    exitCode: (0, _model.attr)('number'),
    errorStatus: (0, _model.attr)('string'),
    index: (0, _model.attr)('number'),
    message: (0, _model.attr)('string'),
    outputStreamUrl: (0, _model.attr)('string'),
    setupStreamUrl: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    testRun: (0, _model.belongsTo)(),
    failedSetup: (0, _computed.or)('failedInPending', 'failedInCreating', 'failedInBuilding'),
    isBuilding: (0, _computed.equal)('status', _testRun.STATES.BUILDING),
    isCancelled: (0, _computed.equal)('status', _testRun.STATES.CANCELLED),
    isCreating: (0, _computed.equal)('status', _testRun.STATES.CREATING),
    isErrored: (0, _computed.equal)('status', _testRun.STATES.ERRORED),
    isFailed: (0, _computed.equal)('status', _testRun.STATES.FAILED),
    isPending: (0, _computed.equal)('status', _testRun.STATES.PENDING),
    isRunning: (0, _computed.equal)('status', _testRun.STATES.RUNNING),
    isSucceeded: (0, _computed.equal)('status', _testRun.STATES.SUCCEEDED),
    failedInPending: (0, _computed.equal)('errorStatus', _testRun.STATES.PENDING),
    failedInCreating: (0, _computed.equal)('errorStatus', _testRun.STATES.CREATING),
    failedInBuilding: (0, _computed.equal)('errorStatus', _testRun.STATES.BUILDING),
    failedInRunning: (0, _computed.equal)('errorStatus', _testRun.STATES.RUNNING),
    isFinished: (0, _computed.or)('isSucceeded', 'isFailed', 'isErrored', 'isCancelled'),
    isNotFinished: (0, _computed.not)('isFinished'),
    hasMessage: (0, _computed.bool)('message')
  });

  _exports.default = _default;
});