define("dashboard/components/app/settings/maintenance-mode", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    classNames: ['maintenance-mode'],
    maintenanceValue: (0, _computed.alias)('app.maintenance'),
    modalDisplayed: false,
    modalButtonText: (0, _object.computed)('maintenanceValue', function () {
      return `${this.maintenanceValue ? 'Enable' : 'Disable'} Maintenance Mode`;
    }),
    actions: {
      hideModal() {
        this.set('modalDisplayed', false);
      },

      revertMaintenance() {
        this.toggleProperty('maintenanceValue');
        this.send('hideModal');
      },

      persistMaintenanceModeToggle() {
        const isEnabled = this.get('app.maintenance');
        const pendingSave = this.app.save();

        if (pendingSave) {
          return pendingSave.then(() => {
            this.analytics.logEvent('Maintenance Mode', 'Toggled', {
              enabled: isEnabled
            });
            this.send('hideModal');
          });
        }
      }

    }
  });

  _exports.default = _default;
});