define("dashboard/templates/components/github-app-placement-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rGcM2RJu",
    "block": "[[[10,0],[14,0,\"v-mid\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"placementType\"]],\"user\"],null],[[[1,\"    \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"personal-28\",[30,0,[\"iconClass\"]],24]],null],[1,\"\\n    \"],[10,1],[15,0,[30,0,[\"colorClass\"]]],[12],[1,\"Personal Account\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"name\",\"type\",\"colorGradient\"],[[30,0,[\"placement\",\"name\"]],[30,0,[\"iconType\"]],[30,0,[\"iconClass\"]]]]]],[1,\"\\n    \"],[10,1],[15,0,[29,[\"flex-auto truncate ml2 \",[30,0,[\"labelColorClass\"]]]]],[12],[1,\"\\n      \"],[1,[30,0,[\"placement\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"placement\",\"githubAppInstallation\",\"isPending\"]],[30,0,[\"currentUserPermissionsIsLoading\"]]],null],[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"pl3 pr3 hk-button-sm--disabled-primary\"],[12],[1,[28,[35,5],null,[[\"size\",\"class\"],[\"small\",\"nudge-down--1\"]]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[[30,0,[\"lacksInstallPermission\"]],[30,0,[\"isAlreadyInstalled\"]]],null],[[[6,[39,6],null,[[\"title\"],[[30,0,[\"lacksPermissionText\"]]]],[[\"default\"],[[[[1,\"      \"],[10,\"button\"],[14,0,\"hk-button-sm--disabled-primary\"],[12],[1,[30,0,[\"buttonText\"]]],[13],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"    \"],[1,[28,[35,7],null,[[\"herokuResourceType\",\"herokuResourceId\",\"afterAuthorizeGithub\"],[[30,0,[\"herokuResourceType\"]],[30,0,[\"placement\",\"id\"]],[28,[37,8],[[30,0],\"createGithubAppInstallation\"],null]]]]],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"eq\",\"malibu-icon\",\"x-identicon\",\"or\",\"loading-spinner\",\"x-tooltip\",\"install-github-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/github-app-placement-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});