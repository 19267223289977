define("dashboard/templates/components/outlet", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EAhy7jR7",
    "block": "[[[11,0],[16,1,[30,0,[\"elementId\"]]],[17,1],[4,[38,0],[[30,0,[\"register\"]]],null],[4,[38,1],[[30,0,[\"reregister\"]],[30,2]],null],[4,[38,2],[[30,0,[\"unregister\"]]],null],[12],[13],[1,\"\\n\"]],[\"&attrs\",\"@name\"],false,[\"did-insert\",\"did-update\",\"will-destroy\"]]",
    "moduleName": "dashboard/templates/components/outlet.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});