define("dashboard/components/context-switcher-list", ["exports", "@ember/runloop", "@ember/component"], function (_exports, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['context-switcher__list', 'overflow-y-auto', 'no-select'],
    'data-test-target': 'context-switcher-list',

    _clickElsewhereHandler(e) {
      const element = this.element;
      const $target = $(e.target);
      const thisIsElement = $target.closest(element).length === 1;

      if (!thisIsElement) {
        this._onClickElsewhwere();
      }
    },

    _onClickElsewhwere() {
      this.attrs.toggleDropdown();
    },

    didInsertElement() {
      this._super(...arguments);

      $(window).on('click.contextSwitcher', (0, _runloop.bind)(this, this._clickElsewhereHandler));
    },

    willDestroyElement() {
      $(window).off('click.contextSwitcher');

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});