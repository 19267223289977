define("dashboard/components/account/quota-usage", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['account-quota-usage'],
    showEcoSubscribeBanner: (0, _object.computed)('subscribedToEco', 'cancelEcoInProgress', function () {
      return !this.cancelEcoInProgress && !this.subscribedToEco;
    }),
    showQuota: (0, _computed.readOnly)('subscribedToEco'),
    actions: {
      startSubscribeFlow() {
        if (this.cardPresent || this.isOhana) {
          this.set('showEcoSubscribeModal', true);
        } else {
          this.set('showAddCreditCard', true);
        }
      },

      nextStep() {
        this.set('showAddCreditCard', false);
        this.set('showEcoSubscribeModal', true);
      },

      async confirmEcoSubscription() {
        this.set('showEcoSubscribeModal', false);
        await this.subscribeToEco();
      },

      async confirmEcoCancellation() {
        this.set('showCancelEcoModal', false);
        await this.cancelEcoSubscription();
      }

    }
  });

  _exports.default = _default;
});