define("dashboard/templates/components/app/settings/config-vars", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7XaFNcFq",
    "block": "[[[41,[30,0,[\"listIsVisible\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"class\",\"onClick\",\"buttonType\",\"text\"],[\"fr\",[28,[37,2],[[30,0],\"hideConfigVars\"],null],\"secondary\",\"Hide Config Vars\"]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"configVars\",\"app\",\"buildpackInstallations\"],[[30,0,[\"sortedConfigVars\"]],[30,0,[\"app\"]],[30,0,[\"buildpackInstallations\"]]]]]],[1,\"\\n\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"onClick\",\"default\",\"buttonType\",\"disableWhen\",\"reset\"],[[28,[37,2],[[30,0],\"revealConfigVars\"],null],\"Reveal Config Vars\",\"secondary\",[30,0,[\"cannotRevealConfigVars\"]],true]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"hk-button\",\"action\",\"app/settings/config-vars-list\"]]",
    "moduleName": "dashboard/templates/components/app/settings/config-vars.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});