define("dashboard/mixins/app-setup", ["exports", "@ember/object/mixin", "@ember/object", "dashboard/mixins/web-socket-model"], function (_exports, _mixin, _object, _webSocketModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    bool,
    equal,
    notEmpty,
    readOnly,
    not,
    or
  } = _object.computed;

  var _default = _mixin.default.create(_webSocketModel.default, {
    socketIdentifier: (0, _object.computed)('id', function () {
      return `app-setups/${this.id}`;
    }),
    app: {},
    build: {},
    saveCompleted: false,
    // ----------- state -----------------------
    isPending: equal('status', 'pending'),
    isFailed: equal('status', 'failed'),
    isDone: equal('status', 'succeeded'),
    isDoneOrFailed: or('isFailed', 'isDone'),
    isCreating: bool('isSaving'),
    isCreated: and('saveCompleted', 'creatingSucceeded'),
    creatingSucceeded: not('saveFailed'),
    creatingFailed: readOnly('saveFailed'),
    willConfigure: not('willNotConfigure'),
    willNotConfigure: or('isConfiguring', 'isConfigured', 'configuringFailed'),
    isConfiguring: (0, _object.computed)('isPending', 'build.status', function () {
      return this.isPending && this.build === null;
    }),
    configuringFailed: (0, _object.computed)('isFailed', 'build.status', function () {
      return this.isFailed && this.build === null;
    }),
    isConfigured: or('isBuilding', 'isBuilt', 'buildingFailed'),
    willNotBuild: or('isBuilding', 'isBuilt', 'buildingFailed'),
    willBuild: not('willNotBuild'),
    isBuilding: (0, _object.computed)('status', 'build.status', function () {
      return this.isPending && this.get('build.status') === 'pending';
    }),
    isBuilt: or('isScaling', 'isScaled', 'scalingFailed'),
    buildingFailed: equal('build.status', 'failed'),
    // equivalent to the state of post deploy scripts
    willScale: not('willNotScale'),
    willNotScale: or('isScaling', 'isScaled', 'scalingFailed'),
    isScaling: (0, _object.computed)('isPending', 'build.status', function () {
      return this.isPending && this.get('build.status') === 'succeeded';
    }),
    isScaled: equal('status', 'succeeded'),
    scalingFailed: (0, _object.computed)('isFailed', 'postdeploy', function () {
      return this.isFailed && this.get('build.status') === 'succeeded';
    }),
    willDeploy: not('isScaled'),
    isDeploying: or('isCreating', 'isConfiguring', 'isBuilding', 'isScaling'),
    isDeployed: readOnly('isScaled'),
    // ----------------------------------
    hasPostDeployOutput: notEmpty('postdeploy.output'),
    postDeployOutput: (0, _object.computed)('postdeploy.output', function () {
      const output = this.get('postdeploy.output');

      if (output) {
        return output.trim();
      }
    })
  });

  _exports.default = _default;
});