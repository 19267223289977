define("dashboard/components/metrics/charts/row-chart-elements/indicator-duration-line", ["exports", "@ember/object/computed", "@ember/object", "moment", "@ember/component"], function (_exports, _computed, _object, _moment, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    _baseClass: 'metrics__graph-row-stacked__duration',
    _logLevelClass: (0, _object.computed)('indicatorLevel', '_baseClass', function () {
      return `${this._baseClass}--${this.indicatorLevel}`;
    }),
    classNameBindings: ['_baseClass', '_logLevelClass'],
    tagName: 'line',
    attributeBindings: ['x1', 'x2', 'y1', 'y2'],
    x1: (0, _object.computed)('indicatorTime', 'pixelsPerMs', '_internalStart', function () {
      const offset = this._internalStart - this.indicatorTime;
      const x1 = this.pixelsPerMs * offset;
      return !isNaN(x1) && isFinite(x1) ? x1 : 0;
    }),
    x2: (0, _object.computed)('_internalEnd', 'indicatorTime', 'pixelsPerMs', function () {
      const offset = this._internalEnd - this.indicatorTime;
      const x2 = this.pixelsPerMs * offset;
      return !isNaN(x2) && isFinite(x2) ? x2 : 0;
    }),
    // static y value that will be shared for both y1 and y2
    y: 5,
    y1: (0, _computed.readOnly)('y'),
    y2: (0, _computed.readOnly)('y'),

    /**
     * the internally tracked "end time" of the duration
     * which is forced within the current metrics timeframe
     */
    _internalEnd: (0, _object.computed)('end', 'maxTimeExtended', function () {
      const maxEndTime = this.get('maxTimeExtended');
      const providedEnd = this.end;

      if (providedEnd) {
        return providedEnd > maxEndTime ? maxEndTime : providedEnd;
      } else {
        return maxEndTime;
      }
    }),

    /**
     * the internally tracked "start time" of the duration
     * which is forced within the current metrics timeframe
     */
    _internalStart: (0, _object.computed)('start', 'minTime', function () {
      const providedStart = this.start;
      const metricsStartTime = this.get('minTime');
      return (0, _moment.default)(providedStart).isBefore(metricsStartTime) ? metricsStartTime : providedStart;
    })
  });

  _exports.default = _default;
});