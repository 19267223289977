define("dashboard/routes/app/build", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app"], function (_exports, _rsvp, _service, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model(params) {
      const {
        app
      } = this.modelFor('app');
      return (0, _rsvp.hash)({
        app,
        build: this.store.queryRecord('build', {
          app: app.get('id'),
          buildId: params['build_id']
        })
      });
    },

    afterModel() {
      this.analytics.logEvent('App Build Log Page', 'Viewed');
    }

  });

  _exports.default = _default;
});