define("dashboard/components/metrics/charts/chart-header/header", ["exports", "@ember/object/computed", "@ember/component", "@ember/object"], function (_exports, _computed, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'header',
    classNames: ['metrics__chart__header'],
    expandIconClasses: (0, _object.computed)('chartIsExpanded', function () {
      const iconClasses = 'malibu-fill-gradient-dark-gray mr1 pointer nudge-up--2';
      return this.chartIsExpanded ? `${iconClasses} rot90` : iconClasses;
    }),
    chartIsExpanded: (0, _computed.not)('chartIsCollapsed'),
    canHaveAlerting: (0, _computed.notEmpty)('alertSummaryType'),
    isShowingAlertSummary: (0, _computed.and)('app.isEligibleForAlerting', 'canHaveAlerting'),
    webProcess: (0, _object.computed)('app.formations.[]', function () {
      const formations = this.get('app.formations') || [];
      return formations.findBy('type', 'web');
    }),
    actions: {
      toggleCollapseChart() {
        if (this.canCollapseChart) {
          this.toggleCollapseChart();
        }
      }

    }
  });

  _exports.default = _default;
});