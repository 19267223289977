define("dashboard/components/x-identicon", ["exports", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    type: 'team',
    // or 'account'
    tagName: '',
    isTeam: (0, _computed.equal)('type', 'team'),
    colorGradient: 'bg-gradient-primary',
    name: (0, _object.computed)(() => {
      return null;
    })
  });

  _exports.default = _default;
});