define("dashboard/components/net-promoter-score/why", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'nps-survey-why',
    isHighRating: null,
    reasons: (0, _object.computed)('isHighRating', function () {
      if (this.isHighRating) {
        return [{
          icon: 'favorite-16',
          value: 'Ease of use',
          isSelected: false
        }, {
          icon: 'features-16',
          value: 'Richness of features',
          isSelected: false
        }, {
          icon: 'user-16',
          value: 'Quality of support',
          isSelected: false
        }, {
          icon: 'metrics-16',
          value: 'Performance',
          isSelected: false
        }];
      } else {
        return [{
          icon: 'favorite-remove-16',
          value: 'Hard to use',
          isSelected: false
        }, {
          icon: 'features-16',
          value: 'Lack of features',
          isSelected: false
        }, {
          icon: 'user-16',
          value: 'Inadequate support',
          isSelected: false
        }, {
          icon: 'metrics-16',
          value: 'Performance',
          isSelected: false
        }];
      }
    }),
    actions: {
      setReasons(reasons) {
        this.set('npsSurvey.whyMultiple', reasons);
      },

      toggleFutureOptOut() {
        this.toggleProperty('isFutureOptedOut');
      }

    }
  });

  _exports.default = _default;
});