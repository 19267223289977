define("dashboard/templates/pipelines/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "et3noLgN",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"top-nav\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"new-header text-center flex-grow-1\"],[12],[1,\"\\n    Create New Pipeline\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@githubIntegrationDisabled\"],[[30,0,[\"githubIntegrationDisabled\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"onSubmit\",\"onGithubAuth\",\"githubUser\",\"githubOrgs\",\"orgs\",\"selectedOrgParam\",\"githubIntegrationDisabled\"],[[28,[37,3],[\"save\"],null],[28,[37,3],[\"onGithubAuth\"],null],[30,0,[\"model\",\"githubUser\"]],[30,0,[\"model\",\"githubOrgs\"]],[30,0,[\"model\",\"herokuOrgs\"]],[30,0,[\"model\",\"params\",\"orgNameParam\"]],[30,0,[\"githubIntegrationDisabled\"]]]]]],[1,\"\\n\"]],[],false,[\"send-to-outlet\",\"github-integration-disabled-banner\",\"pipeline/new-form\",\"route-action\"]]",
    "moduleName": "dashboard/templates/pipelines/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});