define("dashboard/templates/components/x-flash-messages", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uyt+QxBX",
    "block": "[[[10,\"ul\"],[14,0,\"list-unstyled\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"flashMessages\",\"queue\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,2],null,[[\"flash\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"flash\"],false,[\"each\",\"-track-array\",\"x-flash-message\"]]",
    "moduleName": "dashboard/templates/components/x-flash-messages.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});