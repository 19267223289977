define("dashboard/components/pipeline/github-app-banner", ["exports", "dashboard/components/github-app-banner", "@ember/object", "@ember/object/computed"], function (_exports, _githubAppBanner, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _githubAppBanner.default.extend({
    // params
    isNewPipeline: null,
    pipelineOwner: null,
    isPersonalPipeline: null,
    // properties
    isPersonal: (0, _computed.or)('isPersonalPipeline', 'isPersonalApp', 'isPipelineOwnerUser'),
    isPersonalApp: (0, _object.computed)('app.ownerId', 'current.account.id', function () {
      return this.get('app.ownerId') === this.current.account.id;
    }),
    isPipelineOwnerUser: (0, _object.computed)('pipelineOwner', 'isNewPipeline', function () {
      return this.isNewPipeline && !this.pipelineOwner;
    }),
    teamName: (0, _computed.or)('pipeline.team.name', 'app.team.name', 'team.name', 'pipelineOwner.name'),
    enterpriseAccountName: (0, _computed.or)('pipeline.team.enterprise.name', 'app.team.enterprise.name', 'enterpriseAccount.name', 'pipelineOwner.enterprise.name'),
    accountSettingsUrl: (0, _object.computed)('teamName', 'enterpriseAccountName', 'herokuResourceType', function () {
      if (this.herokuResourceType === 'enterprise-account') {
        return {
          page: 'enterprise-account.overview.settings',
          team: this.enterpriseAccountName
        };
      } else if (this.herokuResourceType === 'team') {
        return {
          page: 'team.switchable.settings',
          team: this.teamName
        };
      }
    })
  });

  _exports.default = _default;
});