define("dashboard/models/metrics/router-latency", ["exports", "dashboard/models/metrics", "dashboard/utils/metrics/metrics-data-operators", "dashboard/utils/metrics/data-without-zeros", "dashboard/utils/metrics/aggregate-values"], function (_exports, _metrics, _metricsDataOperators, _dataWithoutZeros, _aggregateValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;

  var _default = _metrics.default.extend({
    _service50DataRaw: rawDataWithDefault('latency.ms.p50'),
    _service95DataRaw: rawDataWithDefault('latency.ms.p95'),
    _service99DataRaw: rawDataWithDefault('latency.ms.p99'),
    _serviceMaxDataRaw: rawDataWithDefault('latency.ms.max'),
    service50Data: (0, _metricsDataOperators.formatData)('_service50DataRaw'),
    service95Data: (0, _metricsDataOperators.formatData)('_service95DataRaw'),
    service99Data: (0, _metricsDataOperators.formatData)('_service99DataRaw'),
    serviceMaxData: (0, _metricsDataOperators.formatData)('_serviceMaxDataRaw'),
    service50DataWithoutZeros: (0, _dataWithoutZeros.default)('service50Data'),
    service95DataWithoutZeros: (0, _dataWithoutZeros.default)('service95Data'),
    medianP50: (0, _aggregateValues.medianValue)('service50DataWithoutZeros'),
    medianP95: (0, _aggregateValues.medianValue)('service95DataWithoutZeros'),
    minP95: (0, _aggregateValues.minValue)('service95DataWithoutZeros'),
    maxP95: (0, _aggregateValues.maxValue)('service95DataWithoutZeros')
  });

  _exports.default = _default;
});