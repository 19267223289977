define("dashboard/templates/components/animated-confetti", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CjNu6AsU",
    "block": "[[[10,0],[14,0,\"absolute w-100 h-100\"],[12],[1,\"\\n  \"],[10,\"canvas\"],[14,0,\"absolute w-100 h-100 z-1\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"absolute left-0 bottom-0 right-0 h4 fade-to-white--down z-2 \"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"relative flex flex-column items-center justify-center w-100 h-100 z-2\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "dashboard/templates/components/animated-confetti.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});