define("dashboard/components/new/runtime-select-controls", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['runtime-selection'],
    runtimeCheckedState: (0, _object.computed)('spaceName', function () {
      if (this.spaceName) {
        return 'private';
      } else {
        return 'common';
      }
    }),
    actions: {
      checkCommonRuntimes() {
        this.set('spaceName', null);
        this.set('privateCheckedState', null);
        this.set('commonCheckedState', true);
      },

      checkPrivateSpaces() {
        if (this.canManageSpaces) {
          this.set('region', null);
          this.set('privateCheckedState', true);
          this.set('commonCheckedState', null);
        }
      }

    }
  });

  _exports.default = _default;
});