define("dashboard/templates/app/resources/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gBet18vN",
    "block": "[[[41,[30,0,[\"hasAttachmentAndIsProvisionable\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"onSave\",\"onClose\",\"addon\",\"app\",\"isNew\"],[[28,[37,2],[[30,0],\"saveAddon\"],null],[28,[37,2],[[30,0],\"closeAddonModal\"],null],[28,[37,3],[[30,0,[\"addon\"]]],null],[30,0,[\"app\"]],true]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasAttachment\"]],[[[6,[39,4],null,[[\"onClose\",\"hasCancelButton\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"containerClass\"],[[28,[37,2],[[30,0],\"closeAddonModal\"],null],false,[28,[37,2],[[30,0],\"closeAddonModal\"],null],\"Close\",\"btn btn-default\",\"addon-provision-modal\"]],[[\"default\"],[[[[1,\"   \"],[1,[28,[35,5],null,[[\"app\",\"addonService\"],[[30,0,[\"app\"]],[30,0,[\"attachment\",\"addonService\"]]]]]],[1,\"\\n\"]],[]]]]]],[]],[[[6,[39,4],null,[[\"onClose\",\"hasCancelButton\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"containerClass\"],[[28,[37,2],[[30,0],\"closeAddonModal\"],null],false,[28,[37,2],[[30,0],\"closeAddonModal\"],null],\"Close\",\"btn btn-default\",\"addon-provision-modal\"]],[[\"default\"],[[[[1,\"    \"],[1,[34,6]],[1,\"\\n\"]],[]]]]]],[]]]],[]]]],[],false,[\"if\",\"app/addons-provision\",\"action\",\"await\",\"hk-modal\",\"app/addons-provision-non-provisionable\",\"app/addons-provision-error\"]]",
    "moduleName": "dashboard/templates/app/resources/new.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});