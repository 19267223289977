define("dashboard/templates/protected/assume", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LYdJ9y1G",
    "block": "[[[6,[39,0],null,[[\"onClose\",\"title\",\"hasCancelButton\",\"cancelButtonClassNames\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\"],[[28,[37,1],[[30,0],\"closeAssumeIdentity\"],null],\"Assume Identity\",true,\"btn btn-default cancel\",[28,[37,1],[[30,0],\"assumeIdentity\"],null],\"Assume Identity\",\"btn btn-danger\"]],[[\"default\"],[[[[41,[30,0,[\"hasUnpopulatedError\"]],[[[1,\"    \"],[10,2],[14,0,\"flash-message alert alert-danger\"],[12],[1,\"All three fields are required\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"apiErrorMessage\"]],[[[1,\"    \"],[10,2],[14,0,\"flash-message alert alert-danger\"],[12],[1,[30,0,[\"apiErrorMessage\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"email\",\"code\",\"reason\"],[[30,0,[\"email\"]],[30,0,[\"code\"]],[30,0,[\"reason\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"hk-modal\",\"action\",\"if\",\"assume-identity\"]]",
    "moduleName": "dashboard/templates/protected/assume.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});