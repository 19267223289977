define("dashboard/templates/components/drop-down-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HzMIhVId",
    "block": "[[[11,\"button\"],[16,0,[29,[[30,0,[\"buttonClassNames\"]],\" \",[52,[30,0,[\"hasCaret\"]],\"pr1\"]]]],[16,\"disabled\",[30,0,[\"buttonDisabled\"]]],[16,\"aria-label\",[52,[30,0,[\"buttonExpanded\"]],[30,0,[\"buttonExpandedLabel\"]],[30,0,[\"buttonLabel\"]]]],[16,\"aria-expanded\",[52,[30,0,[\"buttonExpanded\"]],\"true\",\"false\"]],[24,4,\"button\"],[4,[38,1],[[30,0],\"toggle\"],[[\"bubbles\"],[false]]],[12],[1,\"\\n\"],[41,[30,0,[\"buttonComponent\"]],[[[1,\"    \"],[46,[30,0,[\"buttonComponent\"]],null,[[\"options\"],[[30,0,[\"buttonComponentOptions\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"buttonContentHTML\"]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"hasCaret\"]],[[[1,\"    \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"caret-16\",16,[28,[37,4],[[30,0,[\"caretClass\"]],\" \",[28,[37,4],[\"fill-\",[28,[37,5],[[30,0,[\"caretColor\"]],\"purple\"],null]],null]],null]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"action\",\"component\",\"malibu-icon\",\"concat\",\"or\"]]",
    "moduleName": "dashboard/templates/components/drop-down-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});