define("dashboard/models/feature", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    description: (0, _model.attr)('string'),
    docUrl: (0, _model.attr)('string'),
    enabled: (0, _model.attr)('boolean'),
    name: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    displayName: (0, _model.attr)('string'),
    feedbackEmail: (0, _model.attr)('string')
  });

  _exports.default = _default;
});