define("dashboard/templates/components/toggle-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YCk8zjcw",
    "block": "[[[1,[28,[35,0],null,[[\"type\",\"name\",\"checked\",\"disabled\",\"id\",\"class\"],[\"checkbox\",[30,0,[\"name\"]],[30,0,[\"checked\"]],[30,0,[\"disabled\"]],[30,0,[\"checkBoxId\"]],\"cmn-toggle cmn-toggle-round-flat\"]]]],[1,\"\\n\\n\"],[10,\"label\"],[15,\"for\",[29,[[30,0,[\"checkBoxId\"]]]]],[12],[13],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "dashboard/templates/components/toggle-switch.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});