define("dashboard/templates/components/pipeline/github-status-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "b6xNhmdn",
    "block": "[[[41,[30,0,[\"isShowing\"]],[[[1,\"  \"],[10,0],[14,0,\"hk-banner--warning tc ph2 ph4-ns\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"limit-width center flex-l items-center pv1\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"github-status-warning\"],[[\"class\"],[\"h2 w2 mr2\"]]]],[1,\"\\n      \"],[10,0],[14,0,\"mr3\"],[12],[1,\"\\n        GitHub is experiencing service issues. Pipeline activity may be interrupted until the issue is resolved.\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"message-banner-actions\"],[12],[1,\"\\n        \"],[10,3],[14,6,\"https://githubstatus.com\"],[14,\"target\",\"_blank\"],[14,0,\"hk-button-sm--warning mv1\"],[12],[1,\"View GitHub Status\"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"noun\"],[\"GitHub Service Warning\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"svg-jar\",\"log-event\"]]",
    "moduleName": "dashboard/templates/components/pipeline/github-status-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});