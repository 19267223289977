define("dashboard/router", ["exports", "@ember/routing/router", "dashboard/config/environment", "@ember/service"], function (_exports, _router, _environment, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Once Ember Router Service RFC is fully implemented,
  // we can use the public properties on the RouteInfo objects and
  // delete this function/usage of internal Ember API
  // https://github.com/emberjs/rfcs/blob/master/text/0095-router-service.md#routeinfo-type
  function routeName(routerJSHandlerInfos) {
    return _router.default._routePath(routerJSHandlerInfos);
  }

  const Router = _router.default.extend({
    reporter: (0, _service.inject)(),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    didTransition() {
      this._super(...arguments);

      window.scrollTo(0, 0);
    },

    // TODO: when the Ember Router Service RFC is fully implemented,
    // we should use the new routing events instead of looking at the handlerInfos
    // directly: https://github.com/emberjs/rfcs/blob/master/text/0095-router-service.md#new-events-routewillchange--routedidchange
    willTransition(oldInfos, newInfos, transition) {
      const oldRouteName = oldInfos.length === 0 ? '<initial page load>' : routeName(oldInfos);
      const newRouteName = routeName(newInfos);
      this.get('reporter').trackTransition(transition, oldRouteName, newRouteName);
      return this._super(...arguments);
    }

  }); // eslint-disable-next-line array-callback-return


  Router.map(function () {
    this.route('login');
    this.route('login/callback', {
      path: '/auth/heroku/callback'
    });
    this.route('logout');
    this.route('protected', {
      path: '/'
    }, function () {
      this.route('unexpected-error', {
        path: '500'
      });
      this.route('not-found', {
        path: '*path'
      });
      this.route('apps', {
        resetNamespace: true
      }, function () {});
      this.route('new-app', {
        resetNamespace: true
      });
      this.route('notifications', {
        resetNamespace: true
      });
      this.route('new', {
        resetNamespace: true
      });
      this.route('provision-addon', {
        resetNamespace: true
      });
      this.route('new-org', {
        path: '/orgs/new',
        resetNamespace: true
      });
      this.route('new-team', {
        path: '/teams/new',
        resetNamespace: true
      });
      this.route('assume');
      this.route('release');
      this.route('rapid-upgrade', {
        path: '/upgrade'
      });
      this.route('install-github-app');
      this.route('terms-of-service');
      this.route('account', {
        resetNamespace: true
      }, function () {
        this.route('applications', function () {
          this.route('new-authorization', {
            path: '/authorizations/new'
          });
          this.route('edit-authorization', {
            path: '/authorizations/:authorization_id/edit'
          });
          this.route('new-client', {
            path: 'clients/new'
          });
          this.route('edit-client', {
            path: 'clients/:id/edit'
          });
        });
        this.route('billing', function () {
          this.route('remove-credit-card');
          this.route('confirm-credit-card-removal');
        });
        this.route('pay-balance');
        this.route('billing.pay-balance', {
          path: 'billing/pay-balance'
        });
        this.route('verify', {
          path: 'billing/card'
        });
        this.route('two-factor');
      });
      this.route('invoice', {
        path: '/invoices/:year/:month',
        resetNamespace: true
      });
      this.route('org-invoice', {
        path: '/orgs/:org/invoices/:year/:month',
        resetNamespace: true
      });
      this.route('app', {
        path: '/apps/:name',
        resetNamespace: true
      }, function () {
        this.route('access');
        this.route('activity');
        this.route('build', {
          path: 'activity/builds/:build_id'
        });
        this.route('release', {
          path: 'activity/releases/:release_id'
        });
        this.route('logs');
        this.route('join');
        this.route('locked');
        this.route('metrics', function () {
          this.route('type', {
            path: ':type'
          });
        });
        this.route('webhooks', function () {
          this.route('new');
          this.route('edit', {
            path: ':webhook_name/edit'
          });
        });
        this.route('webhook-deliveries', {
          path: 'webhooks/:webhook_name/deliveries'
        });
        this.route('overview');
        this.route('resources', function () {
          this.route('new');
          this.route('attachments', {
            path: ':addon_id/attachments'
          });
        });
        this.route('scheduler');
        this.route('settings');
        this.route('app-json');
        this.route('deploy', function () {
          this.route('heroku-git');
          this.route('github');
          this.route('heroku-container');
        }); // Code routes only exist to redirect to deploy/*

        this.route('code', function () {
          this.route('heroku-git');
          this.route('github');
          this.route('heroku-container');
        });
        this.route('datastore', {
          path: '/datastores/:datastore_id'
        }, function () {
          this.route('settings'); // Postgres routes

          this.route('credentials');
          this.route('diagnose');
          this.route('durability');
          this.route('dataclips');
        });
      });
      this.route('pipelines', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('pipeline', {
          path: ':id'
        }, function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('app-setup', {
              path: '/app-setup/:app_id'
            });
            this.route('review-app', {
              path: '/review-app/:review_app_id'
            });
            this.route('review-apps');
          });
          this.route('access');
          this.route('settings');
          this.route('tests', function () {
            this.route('test-run', {
              path: ':test_run_number'
            });
          });
        });
      });
      this.route('orgs-redirect', {
        path: 'orgs/*wildcard'
      });
      this.route('team', {
        path: '/teams/:team_name',
        resetNamespace: true
      }, function () {
        this.route('switchable', {
          path: '/'
        }, function () {
          this.route('access');
          this.route('datastores');
          this.route('apps');
          this.route('billing');
          this.route('billing.pay-balance', {
            path: 'billing/pay-balance'
          });
          this.route('overview');
          this.route('resources');
          this.route('settings');
          this.route('settings.pay-balance', {
            path: 'settings/pay-balance'
          });
          this.route('rapid-upgrade', {
            path: '/upgrade'
          });
          this.route('usage');
          this.route('spaces', function () {
            this.route('new');
            this.route('index', {
              path: '/'
            });
          });
        });
        this.route('space', {
          path: 'spaces/:name'
        }, function () {
          this.route('apps');
          this.route('settings');
          this.route('access');
          this.route('network');
        });
      });
      this.route('enterprise-account', {
        path: '/enterprise/:name',
        resetNamespace: true
      }, function () {
        this.route('overview', {
          path: '/'
        }, function () {
          this.route('usage');
          this.route('access');
          this.route('settings');
        });
      });
      this.route('buildpack-registry', {
        resetNamespace: true
      }, function () {
        this.route('connect-github');
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});