define("dashboard/templates/components/time-ago", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SgE+8dhi",
    "block": "[[[1,[30,0,[\"displayTimeString\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/time-ago.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});