define("dashboard/templates/components/pie-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "M36+hoX4",
    "block": "[[[10,\"svg\"],[14,0,\"pie-chart\"],[14,\"viewBox\",\"0 0 32 32\"],[12],[1,\"\\n  \"],[10,\"circle\"],[15,5,[30,0,[\"style\"]]],[14,\"r\",\"16\"],[14,\"cx\",\"16\"],[14,\"cy\",\"16\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/pie-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});