define("dashboard/adapters/kolkrabbi/app", ["exports", "dashboard/adapters/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.kolkrabbiUrl,

    urlForCreateRecord(modelName, snapshot) {
      const parentAppId = snapshot.record.get('parentGithubAppLink.app.id') || snapshot.attr('parentAppId');
      return `${this.host}/apps/${parentAppId}/github/pull-requests`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      return this.urlForCreateRecord(modelName, snapshot);
    },

    urlForFindRecord(id, modelName, snapshot) {
      const parentAppId = snapshot.record.get('parentGithubAppLink.app.id') || snapshot.attr('parentAppId');
      return `${this.host}/apps/${parentAppId}/github/pull-requests/${id}`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.attr('appId');
      return `${this.host}/review-apps/${appId}`;
    }

  });

  _exports.default = _default;
});