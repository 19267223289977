define("dashboard/templates/components/confirm-disable-review-apps-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8j5o/n6i",
    "block": "[[[6,[39,0],null,[[\"title\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"hasCancelButton\",\"onClose\",\"renderInPlace\"],[\"Disable Review Apps\",[30,1],\"Disable Review Apps\",\"btn btn-danger\",true,[30,2],[30,0,[\"renderInPlace\"]]]],[[\"default\"],[[[[1,\"  All existing review apps\\n  (\"],[1,[30,0,[\"reviewAppCount\"]]],[1,\" \"],[1,[28,[35,1],[[30,0,[\"reviewAppCount\"]],\"app\",\"apps\"],null]],[1,\")\\n  will be \"],[10,\"b\"],[12],[1,\"deleted\"],[13],[1,\", but you can re-create them upon enabling review apps again.\\n\"]],[]]]]]],[\"@disableReviewApps\",\"@toggleModal\"],false,[\"hk-modal\",\"pluralize-word\"]]",
    "moduleName": "dashboard/templates/components/confirm-disable-review-apps-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});