define("dashboard/components/app/settings/ssl-setup-new", ["exports", "@ember/component", "@ember/service", "@ember/object", "dashboard/utils/pluralize", "@ember/string", "dashboard/mixins/multi-step-flow"], function (_exports, _component, _service, _object, _pluralize, _string, _multiStepFlow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    filterBy,
    gt
  } = _object.computed;

  var _default = _component.default.extend(_multiStepFlow.default, {
    classNames: ['ssl-setup'],
    analytics: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    store: (0, _service.inject)(),
    showSlidePanel: false,
    endpoints: alias('app.endpoints'),
    endpoint: null,
    savedEndpoints: filterBy('endpoints', 'isNew', false),
    savedEndpoint: alias('savedEndpoints.firstObject'),
    hasFailingDomains: gt('failedOrFailingDomains.length', 0),
    acmStatusDescription: (0, _object.computed)('app.acm', 'customDomains.@each.acmStatus', 'failedOrFailingDomains', function () {
      const customDomains = this.customDomains || [];
      const failedOrFailingDomains = this.failedOrFailingDomains;

      if (customDomains.length === 0) {
        return '. Add a custom domain to your app.';
      } else if (failedOrFailingDomains.length > 0) {
        return (0, _string.htmlSafe)(` but <span class='red b'> ${failedOrFailingDomains.length} ${(0, _pluralize.default)(failedOrFailingDomains.length, 'domain', 'domains')} failed validation.</span>`);
      } else {
        return '.';
      }
    }),
    actions: {
      closeSlidePanel() {
        this.setProperties({
          endpoint: null,
          showSlidePanel: false
        });
      },

      openSlidePanel() {
        let step = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
        let endpoint = arguments.length > 1 ? arguments[1] : undefined;
        this.analytics.logEvent('SSL Configuration Modal', 'Opened');

        if (step === 2 && !endpoint) {
          endpoint = this.store.createRecord('sni-endpoint', {
            app: this.app
          });
        }

        this.setProperties({
          endpoint,
          currentStep: step,
          showSlidePanel: true
        });
      }

    }
  });

  _exports.default = _default;
});