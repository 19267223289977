define("dashboard/models/metrics/monitor", ["exports", "@ember/object/computed", "ember-data/attr", "ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _computed, _attr, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    playbookUrl: (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      message: 'Must be a valid URL',
      regex: /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
      type: 'url'
    })
  });

  var _default = _model.default.extend(Validations, {
    formation: (0, _relationships.belongsTo)('formation'),
    checkId: (0, _attr.default)(),
    appId: (0, _attr.default)(),
    processType: (0, _attr.default)('string'),
    actionType: (0, _attr.default)('string'),
    minQuantity: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    maxQuantity: (0, _attr.default)('number', {
      defaultValue: 10
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue(model) {
        return model.get('isScalingType');
      }

    }),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    notificationPeriod: (0, _attr.default)('number', {
      defaultValue(model) {
        if (model.get('isScalingType')) {
          return 60 * 24;
        } else {
          return 60;
        }
      }

    }),
    name: (0, _attr.default)('string'),
    op: (0, _attr.default)('string'),
    period: (0, _attr.default)('number', {
      defaultValue: 1
    }),
    isErrorRate: (0, _computed.equal)('name', 'ERROR_RATE'),
    isLatencyLegacy: (0, _computed.equal)('name', 'LATENCY'),
    isLatencyScale: (0, _computed.equal)('name', 'LATENCY_SCALE'),
    isLatency: (0, _computed.or)('isLatencyScale', 'isLatencyLegacy'),
    alerts: (0, _relationships.hasMany)('metrics/alert'),
    isAlertingType: (0, _computed.equal)('actionType', 'alert'),
    isScalingType: (0, _computed.equal)('actionType', 'scale'),
    value: (0, _attr.default)('number', {
      defaultValue(model) {
        if (model.get('isErrorRate')) {
          return 0.05;
        } else {
          return 1000;
        }
      }

    }),
    hasAppChannel: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    hasEmailChannel: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    playbookUrl: (0, _attr.default)('string'),
    // These properties are relayed to metrics-api so that they can handle updating
    // the formation. We can not do this via dashboard as updating both the formation
    // and autoscale monitor may cause a race condition.
    dynoSize: (0, _computed.alias)('formation.size'),
    quantity: (0, _computed.alias)('formation.quantity')
  });

  _exports.default = _default;
});