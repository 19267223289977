define("dashboard/templates/team/switchable/resources", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QikGNCCr",
    "block": "[[[1,[28,[35,0],null,[[\"organization\"],[[30,0,[\"team\"]]]]]],[1,\"\\n\"],[41,[30,0,[\"team\",\"resourceItems\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"organization\"],[[30,0,[\"team\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"org/resources-table\",\"if\",\"org/resources-totals\"]]",
    "moduleName": "dashboard/templates/team/switchable/resources.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});