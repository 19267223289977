define("dashboard/components/metrics/charts/zone-elements/missing-data-zones-timeframe", ["exports", "@ember/string", "@ember/object", "@ember/component", "@ember/object/computed"], function (_exports, _string, _object, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'g',
    classNames: 'metrics__timeseries-chart__dead-zones__timeframe',
    attributeBindings: ['transform'],
    start: (0, _computed.readOnly)('timeframe.0'),
    end: (0, _computed.readOnly)('timeframe.1'),
    left: (0, _object.computed)('timeScale', 'start', function () {
      return this.get('timeScale')(this.start);
    }),
    right: (0, _object.computed)('timeScale', 'end', function () {
      return this.get('timeScale')(this.end);
    }),
    transform: (0, _object.computed)('left', function () {
      return `translate(${this.left}, 0)`;
    }),
    width: (0, _object.computed)('left', 'right', function () {
      return this.right - this.left;
    }),
    isLagging: (0, _object.computed)('isEdgeOfTime', 'maxTimeExtended', 'end', function () {
      const endsAtEndTime = this.maxTimeExtended === this.end;
      return endsAtEndTime && this.isEdgeOfTime;
    }),
    // we need to access the window url because of ember-cli's current use
    // of <base> tag in our index.html
    // this should be fixed in ember-cli 2.7 but there is a non-trivial upgrade path
    // see: http://emberjs.com/blog/2016/04/28/baseURL.html
    //
    // we watch the startTime to ensure that changes to timeframe will also cause
    // this url to update because query parameters will affect what this url looks like
    fillUrl: (0, _object.computed)('minTime', function () {
      const url = window.location.href;
      return (0, _string.htmlSafe)(`url(${url}#missing-data-zone-pattern)`);
    })
  });

  _exports.default = _default;
});