define("dashboard/mixins/metrics/timeframe-options", ["exports", "@ember/object/mixin", "@ember/object/computed", "dashboard/utils/custom-computed"], function (_exports, _mixin, _computed, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeframeOptions = _exports.default = void 0;
  const timeframeOptions = {
    allOptions: [{
      name: 'past2Hours',
      tier: 'premium',
      beforeHours: 0,
      afterHours: 2,
      label: 'Past 2hrs',
      desc: '1 min resolution',
      step: 1,
      order: 1
    }, {
      name: 'past24Hours',
      isDefault: true,
      tier: 'standard',
      beforeHours: 0,
      afterHours: 24,
      label: 'Past 24hrs',
      desc: '10 min resolution',
      step: 10,
      order: 2
    }, {
      name: '48To24HoursAgo',
      tier: 'premium',
      beforeHours: 24,
      afterHours: 48,
      label: '48hrs-24hrs ago',
      desc: '10 min resolution',
      step: 10,
      order: 3
    }, {
      name: '72To48HoursAgo',
      tier: 'premium',
      beforeHours: 48,
      afterHours: 72,
      label: '72hrs-48hrs ago',
      desc: '10 min resolution',
      step: 10,
      order: 4
    }, {
      name: 'past72Hours',
      tier: 'premium',
      beforeHours: 0,
      afterHours: 72,
      label: 'Past 72hrs',
      desc: '1 hr resolution',
      step: 60,
      order: 5
    }, {
      name: 'past7days',
      tier: 'premium',
      beforeHours: 0,
      afterHours: 168,
      label: 'Past 7 days',
      desc: '2 hr resolution',
      step: 120,
      order: 6
    }],
    allDatastoreOptions: [{
      name: 'past2Hours',
      beforeHours: 0,
      afterHours: 2,
      label: 'Past 2hrs',
      desc: '',
      step: 10,
      isDefault: true,
      order: 1
    }, {
      name: 'past24Hours',
      beforeHours: 0,
      afterHours: 24,
      label: 'Past 24hrs',
      desc: '',
      step: 10,
      order: 2
    }, {
      name: 'past72Hours',
      beforeHours: 0,
      afterHours: 72,
      label: 'Past 72hrs',
      desc: '',
      step: 60,
      order: 3
    }, {
      name: 'past7days',
      beforeHours: 0,
      afterHours: 168,
      label: 'Past 7 days',
      desc: '',
      step: 120,
      order: 4
    }],
    standardOptions: (0, _computed.filterBy)('allOptions', 'tier', 'standard'),
    premiumOptions: (0, _computed.filterBy)('allOptions', 'tier', 'premium'),
    standardAndPremiumOptions: (0, _computed.union)('premiumOptions', 'standardOptions'),
    optionsSortKey: ['order'],
    customerFacingOptions: (0, _computed.sort)('standardAndPremiumOptions', 'optionsSortKey'),
    defaultValue: true,
    defaultTimeframe: (0, _customComputed.findBy)('allOptions', 'isDefault', 'defaultValue'),
    datastoreOptions: (0, _computed.sort)('allDatastoreOptions', 'optionsSortKey')
  };
  _exports.timeframeOptions = timeframeOptions;

  var _default = _mixin.default.create(timeframeOptions);

  _exports.default = _default;
});