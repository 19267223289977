define("dashboard/components/identity-provider/state-configured", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    showProviderDetails: false,
    herokuEntityId: (0, _computed.readOnly)('identityProvider.herokuEntityId'),
    herokuStartUrl: (0, _computed.readOnly)('identityProvider.herokuStartUrl'),
    herokuAcsUrl: (0, _computed.readOnly)('identityProvider.herokuAcsUrl'),
    herokuNameIdFormat: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress'
  });

  _exports.default = _default;
});