define("dashboard/components/ci/tests-menu", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/helpers/array-group-by", "@ember/utils", "dashboard/mixins/fetch-github-branches", "dashboard/helpers/lazy-property"], function (_exports, _component, _object, _computed, _service, _arrayGroupBy, _utils, _fetchGithubBranches, _lazyProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_fetchGithubBranches.default, {
    classNames: ['ci-menu'],
    isShowingNewTestRun: false,
    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    branches: (0, _lazyProperty.default)(function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.get('pipeline.connectedBranches');
      } else {
        return this.fetchBranches(this.get('pipeline.githubRepository.name'));
      }
    }),
    selectedBranch: (0, _object.computed)('allBranches.[]', 'selectedFilterBranch', function () {
      const allBranches = this.allBranches;

      if ((0, _utils.isEmpty)(allBranches)) {
        return 'Loading branches...';
      }

      const selectedBranch = this.selectedFilterBranch;

      if (allBranches.includes(selectedBranch)) {
        return selectedBranch;
      }

      if (allBranches.includes('master')) {
        return 'master';
      }

      return allBranches.get('firstObject');
    }),
    selectedNewTestRunBranch: (0, _computed.oneWay)('selectedBranch'),
    branchSorting: ['name'],
    sortedBranches: (0, _computed.sort)('branches', 'branchSorting'),
    allBranches: (0, _computed.mapBy)('sortedBranches', 'name'),
    testRunSorting: ['createdAt:desc'],
    sortedTestRuns: (0, _computed.sort)('testRuns', 'testRunSorting'),
    isConnectedToRepository: (0, _computed.reads)('pipeline.isConnectedToRepository'),
    automaticTestRunsEnabled: (0, _computed.bool)('pipeline.isCiEnabled'),
    allTestedBranches: (0, _computed.mapBy)('testRuns', 'commitBranch'),
    testedBranches: (0, _object.computed)('allTestedBranches', function () {
      return this.allTestedBranches.uniq().sort();
    }),
    filteredTestRuns: (0, _object.computed)('selectedFilterBranch', 'sortedTestRuns.@each.commitBranch', function () {
      const selectedFilterBranch = this.selectedFilterBranch;
      const testRuns = this.sortedTestRuns;

      if ((0, _utils.isPresent)(selectedFilterBranch)) {
        return testRuns.filterBy('commitBranch', selectedFilterBranch);
      }

      return testRuns;
    }),
    groupedSortedTestRuns: (0, _object.computed)('filteredTestRuns.@each.createdDate', function () {
      return (0, _arrayGroupBy.default)(this.filteredTestRuns, 'createdDate');
    }),
    actions: {
      createTestRun(attrs, eventDescription) {
        return this.createTestRun(attrs, eventDescription).then(() => {
          this.set('selectedFilterBranch', null);
        });
      },

      toggleNewTestRun() {
        this.toggleProperty('isShowingNewTestRun');
        const action = this.isShowingNewTestRun ? 'Revealed' : 'Hidden';
        this.analytics.logEvent('Test Run Panel', action);
      },

      setNewTestRunBranch(branch) {
        this.set('selectedNewTestRunBranch', branch);
      }

    }
  });

  _exports.default = _default;
});