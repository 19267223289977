define("dashboard/routes/app/metrics", ["exports", "@ember/service", "@ember/utils", "rsvp", "dashboard/routes/basic-app"], function (_exports, _service, _utils, _rsvp, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    model() {
      return (0, _rsvp.hash)({
        app: this.modelFor('app'),
        paymentMethod: this.store.queryRecord('payment-method', {})
      });
    },

    analytics: (0, _service.inject)(),

    afterModel() {
      this.analytics.logMetricsEvent('App Metrics', 'Accessed');
    },

    actions: {
      transitionToType(formations) {
        const webType = formations.find(formation => {
          return formation.get('type') === 'web' && formation.get('quantity') > 0;
        });
        let typeName = 'web';

        if ((0, _utils.isBlank)(webType)) {
          typeName = formations.sortBy('quantity').get('lastObject.type');
        }

        if (typeName) {
          this.transitionTo('app.metrics.type', typeName);
        }
      }

    }
  });

  _exports.default = _default;
});