define("dashboard/routes/app/deploy/github", ["exports", "rsvp", "@ember/utils", "@ember/application", "dashboard/routes/basic-app", "@ember/service", "ember-data"], function (_exports, _rsvp, _utils, _application, _basicApp, _service, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    AdapterError
  } = _emberData.default;

  var _default = _basicApp.default.extend({
    current: (0, _service.inject)(),

    model() {
      const {
        app
      } = this.modelFor('app');
      const {
        githubAuth
      } = this.modelFor('app.deploy');
      return (0, _rsvp.hash)({
        app,
        githubAuth,
        githubOrgs: this.githubOrgs(githubAuth, app.get('githubAppLink.content')),
        githubUser: this.githubUser(githubAuth, app.get('githubAppLink.content'))
      });
    },

    githubOrgs(auth, link) {
      if (this.isAuthedButNotLinked(auth, link)) {
        return this.store.query('kolkrabbi/github-org', {}).catch(reason => {
          return {
            hasAuthError: reason instanceof AdapterError
          };
        });
      } else {
        return [];
      }
    },

    githubUser(auth, link) {
      if (this.isAuthedButNotLinked(auth, link)) {
        // If we get to here and throw an error it means that the user revoked
        // their GitHub token on github.com. We quietly catch the error and move
        // on to show the reconnect page.
        return this.get('current.account').belongsTo('githubUser').reload().catch(reason => {
          return {
            hasAuthError: reason instanceof AdapterError
          };
        });
      }

      return (0, _rsvp.resolve)();
    },

    isAuthedButNotLinked(auth, link) {
      return (0, _utils.isNone)(link) && auth.get('status') !== 404;
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller._loadDeployInfo();
    },

    actions: {
      completeGithubConnection() {
        const parentRoute = (0, _application.getOwner)(this).lookup('route:app.deploy');
        parentRoute.refresh();
      },

      refreshGithubDeploy() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});