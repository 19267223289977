define("dashboard/components/pipeline-app", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "dashboard/config/environment", "dashboard/mixins/metrics/app-state-path", "moment"], function (_exports, _service, _computed, _component, _object, _utils, _environment, _appStatePath, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    gt,
    and,
    or,
    not,
    readOnly,
    bool,
    mapBy
  } = _object.computed;
  const STAGES = ['development', 'staging', 'production'];

  var _default = _component.default.extend(_appStatePath.default, {
    tagName: 'li',
    classNames: ['apps-item', 'pipeline-app', 'pt1 pb1 ph1'],
    classNameBindings: ['couplingIsUpdating:is-saving'],
    session: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),
    stage: readOnly('app.pipelineCoupling.stage'),
    isPromoting: false,
    canManage: true,
    promotionTargets: (0, _object.computed)(() => []),
    hasPromotionTargets: gt('allowedPromotionTargets.length', 0),
    canPromote: and('hasPromotionTargets', 'currentRelease'),
    cannotPromote: not('canPromote'),
    canShowPromotionStatus: bool('currentPromotion'),
    promoteButtonIsDisabled: or('cannotPromote', 'currentPromotion.isPending', 'hasSameCommit'),
    couplingIsUpdating: or('app.pipelineCoupling.isSaving', 'app.pipelineCoupling.isDeleting'),
    githubAppLink: readOnly('app.githubAppLink'),
    pipelineRepository: readOnly('app.pipeline.githubRepository.repository'),
    connectedRepository: readOnly('app.pipeline.connectedRepository.content'),
    isConnectedToRepository: (0, _object.computed)('githubAppLink', 'pipelineRepository', 'connectedRepository', function () {
      const hasRepo = this.get('app.team.isGithubAppEnabled') ? this.connectedRepository : this.pipelineRepository;
      return hasRepo || this.githubAppLink;
    }),
    hasActions: or('canPromote', 'isMismatchedPipelineApp'),
    ownerIds: (0, _object.computed)('pipeline.owner.id', 'app.owner.id', function () {
      return [this.get('pipeline.owner.id'), this.get('app.owner.id')].compact().uniq();
    }),
    isMismatchedPipelineApp: gt('ownerIds.length', 1),
    isShowingDeleteCouplingModal: false,
    renderModalsInPlace: _environment.default.environment === 'test',
    repo: (0, _object.computed)('githubAppLink.repo', 'pipelineRepository.source.id', 'connectedRepository.name', 'pipeline.isGithubAppEnabled', function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.get('connectedRepository.name') || this.get('githubAppLink.repo');
      } else {
        return this.get('pipelineRepository.repository.name') || this.get('githubAppLink.repo');
      }
    }),
    canManageOrPromote: or('canManage', 'canPromote'),
    promotionTargetDeployments: mapBy('allowedPromotionTargets', 'currentDeployment'),
    promotionTargetCommits: (0, _object.computed)('promotionTargetDeployments.@each.commit', function () {
      const deployments = this.promotionTargetDeployments;
      const result = [];
      deployments.forEach(deployment => {
        if (!deployment) {
          return;
          /* on to the next */
        } // we've observed users with commitless deployments


        const commit = deployment.get('commit');

        if (commit) {
          result.push(commit);
        }
      });
      return result;
    }),
    hasDifferentCommit: (0, _object.computed)('currentDeployment.commit', 'promotionTargetCommits', 'promotionTargetDeployments.length', function () {
      const commit = this.get('currentDeployment.commit');
      const targetCommits = this.promotionTargetCommits;

      if ((0, _utils.isEmpty)(targetCommits)) {
        return true;
      }

      if (this.get('promotionTargetDeployments.length') !== targetCommits.length) {
        return true;
      }

      const targetIsPromotable = targetCommits.some(targetCommit => {
        return commit !== targetCommit;
      });
      return targetIsPromotable;
    }),
    hasSameCommit: not('hasDifferentCommit'),
    otherStages: (0, _object.computed)('stage', function () {
      const currentStage = this.stage;
      return STAGES.reject(stage => stage === currentStage);
    }),
    nextStage: readOnly('allowedPromotionTargets.firstObject.pipelineCoupling.stage'),
    currentDeployment: readOnly('app.currentDeployment'),
    currentRelease: readOnly('app.currentRelease'),
    currentBuild: readOnly('app.currentBuild'),
    currentPromotion: readOnly('app.currentPromotion'),

    /*
     * It no longer makes sense to show the failed build notification when the
     * latest build failed but we have had successful deployments since
     */
    isHidingBuildStatus: (0, _object.computed)('currentDeployment.createdAt', 'currentDeployment.updatedAt', 'currentBuild.isFailed', 'currentBuild.createdAt', 'currentBuild.updatedAt', function () {
      const buildModifiedAt = this.get('currentBuild.updatedAt') || this.get('currentBuild.createdAt');
      const deploymentModifiedAt = this.get('currentDeployment.updatedAt') || this.get('currentDeployment.createdAt');
      return this.get('currentBuild.isFailed') && (0, _moment.default)(deploymentModifiedAt).isAfter(buildModifiedAt);
    }),
    isShowingBuildStatus: not('isHidingBuildStatus'),
    branch: (0, _object.computed)('githubAppLink.branch', 'branches.@each.name', function () {
      const branches = this.branches || [];
      return branches.findBy('name', this.get('githubAppLink.branch'));
    }),
    sha: readOnly('branch.commit.sha'),
    name: (0, _computed.alias)('app.name'),
    actions: {
      delete(coupling) {
        this.delete(coupling.get('content'));
      },

      moveTo(otherStage) {
        this.move(this.get('app.pipelineCoupling'), otherStage);
      },

      onPromotion(promotion) {
        return promotion.subscribeToPromotionTargets();
      },

      authorizedGitHub() {
        this.authorizedGitHub();
      },

      togglePromoteModal() {
        this.toggleProperty('isPromoting');
      },

      toggleDeployModal() {
        this.toggleProperty('isDeploying');
      },

      toggleDeleteCouplingModal() {
        this.toggleProperty('isShowingDeleteCouplingModal');
      },

      showBuildModal() {
        this.set('isShowingBuildModal', true);
      },

      hideBuildModal() {
        this.set('isShowingBuildModal', false);
      },

      showAutoDeployModal() {
        this.set('isShowingAutoDeployModal', true);
      },

      hideAutoDeployModal() {
        this.set('isShowingAutoDeployModal', false);
      }

    }
  });

  _exports.default = _default;
});