define("dashboard/adapters/pipeline-promotion", ["exports", "dashboard/adapters/application", "@ember/polyfills", "@ember/object"], function (_exports, _application, _polyfills, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    additionalHeaders: null,
    headers: (0, _object.computed)('additionalHeaders', function () {
      const headers = this._super();

      const additionalHeaders = this.additionalHeaders;
      return (0, _polyfills.merge)(headers, additionalHeaders);
    })
  });

  _exports.default = _default;
});