define("dashboard/serializers/team/addon", ["exports", "dashboard/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      addonService: {
        deserialize: 'records'
      }
    },

    normalize(modelClass, resourceHash) {
      resourceHash.embedded_addon_service_plan = resourceHash.plan;
      resourceHash.addonServicePlan = resourceHash.plan.id;
      resourceHash.plan = resourceHash.plan.name;
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});