define("dashboard/adapters/application", ["exports", "@ember/service", "@ember/polyfills", "@ember-data/adapter/rest", "@ember/object", "dashboard/mixins/adapters/heroku-data-adapter-mixin", "dashboard/mixins/adapters/session-invalidating-mixin", "dashboard/mixins/adapters/platform-api"], function (_exports, _service, _polyfills, _rest, _object, _herokuDataAdapterMixin, _sessionInvalidatingMixin, _platformApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Heroku Platform API Adapter
  var _default = _rest.default.extend(_herokuDataAdapterMixin.default, _sessionInvalidatingMixin.default, _platformApi.default, {
    reporter: (0, _service.inject)(),
    headers: (0, _object.computed)('range', 'rangeHeaders', 'version', function () {
      const rangeHeaders = (0, _polyfills.assign)({}, this.rangeHeaders);
      const platformHeaders = {
        Accept: `application/vnd.heroku+json; version=${this.version}`,
        'X-Origin': location.origin
      };
      return (0, _polyfills.assign)(rangeHeaders, platformHeaders);
    }),
    rangeHeaders: (0, _object.computed)('range', function () {
      const range = this.range;

      if (typeof range === 'string') {
        return {
          Range: range
        };
      }

      if (range) {
        const order = range.ascending === false ? 'desc' : 'asc';
        let header = `${range.attribute} ..; order=${order}`;

        if (range.max) {
          header += `, max=${range.max}`;
        }

        return {
          Range: header
        };
      } else {
        return {};
      }
    }),

    findHasMany(store, snapshot, url, relationship) {
      const id = snapshot.id;
      const type = snapshot.modelName;
      const adapter = store.adapterFor(relationship.type);
      const path = this.buildURL(type, id, snapshot, 'findHasMany');
      const withHost = adapter.urlPrefix(url, path);
      return adapter.ajax(withHost, 'GET');
    },

    findBelongsTo(store, snapshot, url, relationship) {
      const id = snapshot.id;
      const type = snapshot.modelName;
      const adapter = store.adapterFor(relationship.type);
      const path = this.buildURL(type, id, snapshot, 'findBelongsTo');
      const withHost = adapter.urlPrefix(url, path);
      return adapter.ajax(withHost, 'GET');
    },

    normalizeErrorResponse(status, headers, payload) {
      return [{
        status: `${status}`,
        title: 'The backend responded with an error',
        id: payload.id,
        detail: payload.message
      }];
    },

    handleResponse(status, headers, payload, requestData) {
      if (status === 206) {
        const url = requestData.url; // Filter out .../releases and .../builds as those usually 206.

        if (!url.match(/\/(releases|builds)$/)) {
          this.get('reporter.rollbar').info('EmberData GET returned a 206 status code', {
            url
          });
        }
      }

      return this._super(status, headers, payload, requestData);
    },

    version: 3,

    // Headers are downcased in newer version of Chrome and Safari, but not in
    // Firefox and others. This should take the original form of the `headerName`,
    // like `Next-Range`, but it will also look for the downcased version.
    // See: https://github.com/emberjs/data/issues/4952
    getHeaderValue(headerName, headers) {
      return headers[headerName] || headers[headerName.toLowerCase()];
    }

  });

  _exports.default = _default;
});