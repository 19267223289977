define("dashboard/components/pipeline/review-app-modal", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _component, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    not,
    notEmpty,
    bool
  } = _object.computed;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    isShowingBuild: not('appSetup.isBuilt'),
    isShowingPostDeploy: bool('appSetup.scalingFailed'),
    manifestErrors: (0, _object.computed)('appSetup.manifest_errors', function () {
      const errors = this.get('appSetup.manifest_errors');

      if ((0, _utils.isPresent)(errors)) {
        return errors.join(', ');
      }
    }),
    prNumberInTitle: notEmpty('pullRequestNumber'),
    pullRequestNumber: (0, _object.computed)('appSetup.app.name', function () {
      return (/\-(\d+)$/.exec(this.get('appSetup.app.name')) || [])[1];
    }),
    failureMessage: (0, _object.computed)('appSetup.failureMessage', 'appSetup.failure_message', function () {
      const appSetup = this.get('appSetup');

      if (appSetup.get('failureMessage')) {
        return appSetup.get('failureMessage');
      }

      if (appSetup.get('failure_message')) {
        return appSetup.get('failure_message');
      }
    }),
    stackOrFailureMessage: (0, _object.computed)('failureMessage', 'appSetup.postDeployOutput', function () {
      const output = this.get('appSetup.postDeployOutput');

      if (output) {
        return output.split('\n')[0];
      }

      return this.get('failureMessage');
    }),
    hasStackOutputOrFailureMessage: notEmpty('stackOrFailureMessage'),
    actions: {
      showLog(name) {
        this.toggleProperty(`isShowing${name.capitalize()}`);
      },

      close() {
        this.hideAppSetupModal();
      }

    }
  });

  _exports.default = _default;
});