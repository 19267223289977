define("dashboard/components/enterprise-account/usage-export-options", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Arguments
    enterpriseAccount: null,
    team: null,
    // --------------------------------------------------------------------------
    // State
    mode: 'monthly',
    // --------------------------------------------------------------------------
    // Computed Props
    isTeamExport: (0, _computed.notEmpty)('team')
  });

  _exports.default = _default;
});