define("dashboard/adapters/repositories-api-github-repo", ["exports", "dashboard/adapters/application", "dashboard/config/environment", "@ember/service", "jquery", "rsvp"], function (_exports, _application, _environment, _service, _jquery, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.repositories-api',
    accountFeatures: (0, _service.inject)('account-features'),
    updateMethod: 'POST',

    handleResponse(status, headers, payload, requestData) {
      // A 404 on this endpoint just means the pipeline isn't connected to GitHub.
      // This override prevents ember-data from raising an error in the console
      // and failing the test suite.
      if (status === 404) {
        status = 204;
        payload = null;
      }

      return this._super(status, headers, payload, requestData);
    },

    async queryRecord(store, modelClass, query) {
      const {
        repo,
        owner,
        apiOrigin,
        isGithubAppEnabled
      } = query; // need to save _super because it gets lost when regenerator does its thing
      // to compile async/await.

      const _super = this._super;
      let payload;

      if (isGithubAppEnabled) {
        payload = await _super.call(this, store, modelClass, {
          repo,
          owner
        });
      } else {
        try {
          payload = await this.publicFetchFromGithub(owner, repo, apiOrigin);
        } catch (_error) {
          payload = await _super.call(this, store, modelClass, {
            repo,
            owner
          });
        }
      }

      return payload;
    },

    publicFetchFromGithub(owner, repo, apiOrigin) {
      return this._legacyAjax({
        url: `${apiOrigin}/repos/${owner}/${repo}`,
        dataType: 'json',

        beforeSend(xhr) {
          xhr.suppressErrors = true;
          xhr.global = false;
        }

      });
    },

    _legacyAjax(options) {
      const defaults = {
        dataType: 'text',

        beforeSend(xhr) {
          xhr.suppressErrors = true;
          xhr.global = false;
        }

      };
      const overrides = Object.assign({}, defaults, options);
      return new _rsvp.Promise((resolve, reject) => {
        _jquery.default.ajax(overrides).then(resolve).fail((jqXHR, status, text) => {
          const rejection = this.handleResponse(jqXHR.status, jqXHR.getAllResponseHeaders(), this.parseErrorResponse(jqXHR.responseText) || text, {
            url: overrides.url,
            method: options.type || 'GET'
          });
          reject(rejection);
        });
      });
    },

    urlForQuery(params) {
      const {
        enterpriseId,
        isGithubAppEnabled
      } = params;

      if (enterpriseId && isGithubAppEnabled) {
        delete params.enterpriseId;
        delete params.isGithubAppEnabled;
        return `${this.host}/enterprise-accounts/${enterpriseId}/repos/search`;
      } else {
        return `${_environment.default.kolkrabbiUrl}/github/search/repositories`;
      }
    },

    urlForQueryRecord(_ref) {
      let {
        repo,
        owner,
        isGithubAppEnabled
      } = _ref;

      if (isGithubAppEnabled) {
        return `${this.host}/repos/${owner}/${repo}`;
      } else {
        return `${_environment.default.kolkrabbiUrl}/github/repos/${owner}/${repo}`;
      }
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      if (snapshot.adapterOptions && snapshot.adapterOptions.linkedResourceType) {
        const linkedResourceType = snapshot.adapterOptions.linkedResourceType;
        let resourceId;

        if (snapshot.belongsTo(linkedResourceType, {
          id: true
        })) {
          resourceId = snapshot.belongsTo(linkedResourceType, {
            id: true
          });
        } else {
          resourceId = snapshot.record.disconnectedResourceId;
        }

        if (snapshot.record.herokuLegacyLink && !snapshot.adapterOptions.migrating) {
          const kolkrabbiUrlOption = linkedResourceType === 'app' ? 'github' : 'repository';
          return `${_environment.default.kolkrabbiUrl}/${linkedResourceType}s/${resourceId}/${kolkrabbiUrlOption}`;
        } else {
          return `${this.host}/${linkedResourceType}s/${resourceId}/repo`;
        }
      }
    },

    updateRecord(store, type, snapshot) {
      if (snapshot.record.disconnectedResourceId) {
        return this.deleteRecord(store, type, snapshot).then(() => {
          snapshot.record.disconnectedResourceId = null;
        });
      } else {
        return this._super(...arguments).then(data => {
          data.disconnectedResourceId = data.id;
          data.id = snapshot.record.id;
        });
      }
    },

    urlForDeleteRecord() {
      return this.urlForUpdateRecord(...arguments);
    }

  });

  _exports.default = _default;
});