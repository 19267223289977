define("dashboard/components/enterprise-account/member/button", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    // --- Args
    isPristine: null,
    isPending: null,
    validations: null,
    onClick: () => {}
  });

  _exports.default = _default;
});