define("dashboard/routes/app/resources/attachments", ["exports", "rsvp", "dashboard/routes/basic-app"], function (_exports, _rsvp, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    model(params) {
      const addon = this.store.findRecord('addon', params.addon_id);
      const {
        app
      } = this.modelFor('app');
      return (0, _rsvp.hash)({
        addon,
        app
      });
    }

  });

  _exports.default = _default;
});