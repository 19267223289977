define("dashboard/models/config-var", ["exports", "@ember/object", "dashboard/models/app-json-config-var", "ember-cp-validations"], function (_exports, _object, _appJsonConfigVar, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    key: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _object.default.extend(Validations, {
    key: null,
    value: null,

    toAppJSONConfigVar() {
      return _appJsonConfigVar.default.create({
        parent: this,
        key: this.key,
        type: 'inherit'
      });
    }

  });

  _exports.default = _default;
});