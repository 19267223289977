define("dashboard/components/github-app-placement-picker", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _service, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['w-100', 'mw6', 'bw1', 'br3', 'b--solid', 'b--light-silver', 'pt1', 'pb2', 'mb5'],
    'data-test-target': 'github-app-placement-picker',
    current: (0, _service.inject)(),
    onlineTeams: (0, _computed.filter)('teams', function (team) {
      return team.isEnterpriseOrg === false && (0, _utils.isEmpty)(team.enterpriseAccount);
    })
  });

  _exports.default = _default;
});