define("dashboard/components/metrics/charts/chart-header/latency-summary", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/chart-header/chart-summary", "@ember/object"], function (_exports, _computed, _chartSummary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _chartSummary.default.extend({
    numberFormat: '0,0',
    primaryUnit: 'ms',
    hasSufficientData: (0, _computed.and)('data.medianP50', 'data.minP95', 'data.maxP95'),
    isVisible: (0, _computed.alias)('hasSufficientData'),
    median50thLatency: (0, _object.computed)('data.medianP50', function () {
      return _object.default.create({
        title: 'The median of the 50th percentile latencies over this time period.',
        label: 'median 50th',
        primaryValue: this.get('data.medianP50'),
        testTarget: 'median-p50-average'
      });
    }),
    minimum95thLatency: (0, _object.computed)('data.minP95', function () {
      return _object.default.create({
        title: 'The minimum 95th percentile latency over this time period.',
        label: 'minimum 95th',
        primaryValue: this.get('data.minP95'),
        testTarget: 'min-p95-average'
      });
    }),
    maximum95thLatency: (0, _object.computed)('data.maxP95', function () {
      return _object.default.create({
        title: 'The maximum 95th percentile latency over this time period.',
        label: 'maximum 95th',
        primaryValue: this.get('data.maxP95'),
        testTarget: 'max-p95-average'
      });
    }),
    chartSummaryItems: (0, _computed.collect)('median50thLatency', 'minimum95thLatency', 'maximum95thLatency')
  });

  _exports.default = _default;
});