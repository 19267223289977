define("dashboard/models/repositories-api-github-repo", ["exports", "@ember-data/model", "@ember/object/computed"], function (_exports, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ci: (0, _model.attr)('boolean'),
    fullName: (0, _model.attr)('string'),
    htmlUrl: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    repository: (0, _model.attr)('number'),
    owner: (0, _model.attr)(),
    private: (0, _model.attr)('boolean'),
    ownerName: (0, _computed.alias)('owner.login'),
    herokuLegacyLink: (0, _model.attr)('boolean'),
    defaultBranch: (0, _model.attr)('string'),
    pipeline: (0, _model.belongsTo)('pipeline'),
    app: (0, _model.belongsTo)('app')
  });

  _exports.default = _default;
});