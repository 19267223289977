define("dashboard/components/pipeline/review-apps", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    accountFeatures: (0, _service.inject)(),
    apps: (0, _computed.readOnly)('pipeline.apps'),
    connectedRepo: (0, _computed.reads)('parentAppLink.connectedRepository.content'),
    hasConnectedRepoAndFlagEnabled: (0, _computed.and)('pipeline.isGithubAppEnabled', 'connectedRepo'),
    parentApp: (0, _object.computed)('hasConnectedRepoAndFlagEnabled', 'parentAppLink.app', function () {
      return this.hasConnectedRepoAndFlagEnabled ? this.get('parentAppLink') : this.get('parentAppLink.app');
    }),
    repoName: (0, _object.computed)('hasConnectedRepoAndFlagEnabled', 'connectedRepo.name', 'parentAppLink.repo_name', function () {
      return this.hasConnectedRepoAndFlagEnabled ? this.get('connectedRepo.name') : this.get('parentAppLink.repo_name');
    }),
    parentAppIsInSpace: (0, _computed.readOnly)('parentApp.space'),
    hasNoAppJSON: (0, _computed.not)('appJSON.isLoaded'),
    // Default value only. This will only be applied if the parent app belongs to
    // a private space.
    createInPrivateSpace: true,
    nonReviewApps: (0, _computed.filterBy)('apps', 'isReview', false),
    directConnectedNonReviewApps: (0, _computed.filterBy)('nonReviewApps', 'isConnectedToGithub'),
    unsortedGithubConnectedApps: (0, _object.computed)('directConnectedNonReviewApps', 'nonReviewApps', 'pipeline.isGithubAppEnabled', function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.nonReviewApps;
      } else {
        return this.directConnectedNonReviewApps;
      }
    }),
    githubAppSorting: ['pipelineCoupling.stageSorting:asc'],
    githubConnectedApps: (0, _computed.sort)('unsortedGithubConnectedApps', 'githubAppSorting'),
    autoDeploy: false,
    autoDestroy: false,
    autoDestroyDays: 5,
    currentPath: (0, _object.computed)(function () {
      return window.location.pathname;
    }),
    actions: {
      save() {
        const parentAppLink = this.parentAppLink;
        let spaceID = null;

        if (this.createInPrivateSpace) {
          spaceID = this.get('parentApp.space.id');
        }

        parentAppLink.set('pullRequests', {
          auto_deploy: this.autoDeploy,
          auto_destroy: this.autoDestroy,
          stale_days: this.autoDestroyDays,
          enabled: true,
          space_id: spaceID
        });
        const promise = parentAppLink.get('content').save().then(() => this.onClose());
        return promise.catch(() => parentAppLink.get('content').rollbackAttributes());
      }

    }
  });

  _exports.default = _default;
});