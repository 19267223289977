define("dashboard/templates/components/enterprise-usage/current-usage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AEK345Mr",
    "block": "[[[18,3,null],[1,\"\\n\"],[41,[30,0,[\"hasLicenses\"]],[[[1,\"  \"],[10,0],[14,0,\"f5 gray mb2 mb0-ns fr-ns flex items-center\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"time-16\",\"fill-gray mr1\",14]],null],[1,\"\\n    Updated within the past 24 hours\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"licenses\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,5],null,[[\"license\",\"class\"],[[30,1],[52,[28,[37,6],[[30,2],0],null],\"mt5\"]]]]],[1,\"\\n\"]],[1,2]],[[[1,\"  \"],[10,2],[12],[1,\"\\n    Current usage data is not available at this time.\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"license\",\"index\",\"&default\"],false,[\"yield\",\"if\",\"malibu-icon\",\"each\",\"-track-array\",\"enterprise-usage/progress\",\"gt\"]]",
    "moduleName": "dashboard/templates/components/enterprise-usage/current-usage.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});