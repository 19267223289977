define("dashboard/models/shogun/private-link", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/service"], function (_exports, _model, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    api: (0, _service.inject)(),
    addon: (0, _model.attr)(),
    status: (0, _model.attr)('string'),
    serviceName: (0, _model.attr)('string'),
    allowedAccounts: (0, _model.attr)(),
    connections: (0, _model.attr)(),
    isProvisioning: (0, _computed.equal)('status', 'Provisioning'),
    isOperational: (0, _computed.equal)('status', 'Operational'),
    isAvailable: (0, _computed.equal)('status', 'Available'),
    isFailed: (0, _computed.equal)('status', 'Failed'),
    isExpired: (0, _computed.equal)('status', 'Expired'),
    isDeprovisioned: (0, _computed.equal)('status', 'Deprovisioned'),

    addAllowedAccount(account) {
      const adapter = this.store.adapterFor('shogun/private-link');
      const addonId = this.addon.uuid;
      const data = {
        allowed_accounts: [account]
      };
      return this.api.request(`/private-link/v0/databases/${addonId}/allowed_accounts`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'PUT',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    },

    removeAllowedAccount(account) {
      const adapter = this.store.adapterFor('shogun/private-link');
      const addonId = this.addon.uuid;
      const data = {
        allowed_accounts: [account]
      };
      return this.api.request(`/private-link/v0/databases/${addonId}/allowed_accounts`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'PATCH',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    }

  });

  _exports.default = _default;
});