define("dashboard/adapters/app-transfer", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindAll() {
      return `${this.host}/account/app-transfers`;
    },

    urlForUpdateRecord(id) {
      return `${this.host}/account/app-transfers/${id}`;
    },

    urlForDeleteRecord(id) {
      return this.urlForUpdateRecord(id);
    },

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/actions/transfer`;
    }

  });

  _exports.default = _default;
});