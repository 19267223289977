define("dashboard/templates/components/account/profile-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Lh5soKGN",
    "block": "[[[1,[28,[35,0],null,[[\"label\",\"value\",\"placeholder\",\"inputId\",\"emptyText\",\"onSave\"],[\"Name (Optional)\",[30,0,[\"name\"]],\"Your name\",\"name\",\"Click to add a name\",[28,[37,1],[[30,0],\"save\"],null]]]]],[1,\"\\n\"]],[],false,[\"hk-inline-edit\",\"action\"]]",
    "moduleName": "dashboard/templates/components/account/profile-name.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});