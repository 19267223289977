define("dashboard/serializers/test-run", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      hash.links = {
        testNodes: 'test-nodes'
      };

      if (!hash.pipeline) {
        hash.pipeline = {};
      }

      if (hash.dyno) {
        hash.dyno_size = hash.dyno.size;
      }

      hash.pipeline.type = 'pipeline';
      return this._super.apply(this, arguments);
    },

    serialize(snapshot, options) {
      const serialized = this._super(snapshot, options);

      serialized.pipeline = serialized.pipeline_id;
      delete serialized.pipeline_id;
      delete serialized.testCases;
      delete serialized.testNodes;
      return serialized;
    }

  });

  _exports.default = _default;
});