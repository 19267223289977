define("dashboard/templates/notifications", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gKsj+oee",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"header-actions\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@controller\",\"@dismissNotifications\"],[[30,0],[28,[37,2],[[30,0],\"dismissNotifications\"],null]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"list-group notification-list limit-width\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"sortedNotifications\"]]],null]],null],null,[[[1,\"    \"],[10,0],[15,0,[29,[\"list-group-item notification-item \",[52,[30,1,[\"read\"]],\"read\",\"unread\"]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-md-12 clearfix\"],[12],[1,\"\\n        \"],[10,\"time\"],[14,0,\"notification-time\"],[12],[1,\"\\n          \"],[1,[28,[35,6],null,[[\"datetime\"],[[30,1,[\"createdAt\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"notification-title\"],[12],[1,\"\\n          \"],[10,\"strong\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"notification-message col-md-12\"],[12],[1,\"\\n        \"],[2,[30,1,[\"body\"]]],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"followup\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"notification-followup col-md-11 col-mid-offset-1\"],[12],[1,\"\\n            \"],[10,\"time\"],[14,0,\"notification-time\"],[12],[1,\"\\n              \"],[10,\"small\"],[12],[1,\"\\n                \"],[1,[28,[35,6],null,[[\"datetime\"],[[30,2,[\"created_at\"]]]]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n            \"],[2,[30,2,[\"body\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n\\n    \"],[13],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,2],[12],[1,\" You have no notifications at this time. \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"notification\",\"fu\"],false,[\"send-to-outlet\",\"route-outlets/notifications/actions\",\"action\",\"each\",\"-track-array\",\"if\",\"time-ago\"]]",
    "moduleName": "dashboard/templates/notifications.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});