define("dashboard/components/invoice-graph", ["exports", "@ember/component", "@ember/object", "moment", "dashboard/models/invoice-graph-point"], function (_exports, _component, _object, _moment, _invoiceGraphPoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    plotIsCurrency: true,
    maxYValue: (0, _object.computed)('data.[]', function () {
      const quotaAttribute = this.quotaLimitAttribute;
      const amountAttribute = this.attributeToPlot;
      const amounts = amountAttribute ? this.data.mapBy(amountAttribute) : [];
      const quotas = quotaAttribute ? this.data.mapBy(quotaAttribute) : [];
      const values = amounts.concat(quotas);
      return Math.max(...values);
    }),
    points: (0, _object.computed)('_pastYearData', function () {
      const maxYValue = this.maxYValue;

      const points = this._pastYearData.map(item => {
        return _invoiceGraphPoint.default.create({
          maxYValue,
          model: item,
          attributeToPlot: this.attributeToPlot,
          plotIsCurrency: this.plotIsCurrency,
          quotaLimitAttribute: this.quotaLimitAttribute
        });
      });

      return this._pad(points);
    }),
    _pastYearData: (0, _object.computed)('data.[]', function () {
      return this.data.sortBy('periodStart').slice(-12);
    }),

    // Pad the array with zero InvoiceGraphPoints if < 12
    _pad(points) {
      while (points.length < 12) {
        const prevDate = points[0] ? (0, _moment.default)(points[0].get('date')) : (0, _moment.default)();
        points.unshift(_invoiceGraphPoint.default.create({
          date: prevDate.clone().subtract(1, 'month'),
          total: 0,
          quotaLimit: 0,
          quota: 0,
          isInterpolated: true,
          attributeToPlot: this.attributeToPlot,
          plotIsCurrency: this.plotIsCurrency,
          quotaLimitAttribute: this.quotaLimitAttribute
        }));
      }

      return points;
    }

  });

  _exports.default = _default;
});