define("dashboard/adapters/github-app-installation", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.repositories-api',

    handleResponse(status, headers, payload, requestData) {
      // A 404 on this endpoint just means the pipeline isn't connected to GitHub.
      // This override prevents ember-data from raising an error in the console
      // and failing the test suite.
      if (status === 404) {
        status = 204;
        payload = null;
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForCreateRecord(_, snapshot) {
      const accountIdentifier = snapshot.record.accountType === 'user' ? 'account' : `${snapshot.record.herokuResourceType}s/${snapshot.record.herokuResourceId}`;
      return `${this.host}/${accountIdentifier}/github/installation`;
    }

  });

  _exports.default = _default;
});