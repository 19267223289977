define("dashboard/adapters/review-app-config", ["exports", "dashboard/adapters/application", "@ember-data/adapter/error", "@ember/service"], function (_exports, _application, _error, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.review-apps',
    dsErrorMonitor: (0, _service.inject)(),

    // hijacks AJAX call
    handleResponse(status, headers, payload, requestData) {
      if (status === 404) {
        status = 204;
        payload = null;
        requestData.jqXHR.errorIsHandled = true;
      }

      if (status === 422) {
        payload = this.normalizeErrorResponse(status, headers, payload);
        return new _error.InvalidError(payload.errors);
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForCreateRecord(_, _ref) {
      let {
        record
      } = _ref;
      const pipelineId = record.get('pipeline.id');
      return this._baseUrl({
        pipeline_id: pipelineId
      });
    },

    urlForUpdateRecord(pipelineId) {
      return this._baseUrl({
        pipeline_id: pipelineId
      });
    },

    urlForDeleteRecord(pipelineId) {
      return this._baseUrl({
        pipeline_id: pipelineId
      });
    },

    _baseUrl(_ref2) {
      let {
        pipeline_id
      } = _ref2;
      const baseUrl = this.buildURL();
      return `${baseUrl}/pipelines/${pipeline_id}/review-app-config`;
    }

  });

  _exports.default = _default;
});