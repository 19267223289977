define("dashboard/components/animated-confetti", ["exports", "@ember/component", "@ember/object", "jquery"], function (_exports, _component, _object, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['relative', 'h-100', 'w-100'],
    height: (0, _object.computed)(function () {
      return (0, _jquery.default)(this.element).innerHeight();
    }),
    width: (0, _object.computed)(function () {
      return (0, _jquery.default)(this.element).innerWidth();
    }),
    angle: (0, _object.computed)(function () {
      return 0;
    }),
    tiltAngle: (0, _object.computed)(function () {
      return 0;
    }),
    particles: (0, _object.computed)(function () {
      return [];
    }),
    maxParticles: (0, _object.computed)(function () {
      return 75;
    }),
    context2d: (0, _object.computed)(function () {
      return (0, _jquery.default)(this.element).find('canvas')[0].getContext('2d');
    }),
    // purple3 heroku colors
    colorOptions: ['#BDECFF', // red
    '#2AB9F2', // orange
    '#9966D2', // purple
    '#BA96E6', // green
    '#FFE3BD' // blue
    ],
    colorIncrementer: 0,
    colorIndex: 0,

    nextColor() {
      const colorOptions = this.colorOptions;
      let colorIndex = this.colorIndex;
      const colorOption = colorOptions[colorIndex];
      colorIndex += 1;

      if (colorIndex >= colorOptions.length) {
        this.set('colorIndex', 0);
      } else {
        this.set('colorIndex', colorIndex);
      }

      return colorOption;
    },

    createParticle(color) {
      const maxParticles = this.maxParticles;
      const ctx = this.context2d;
      const H = this.height;
      const W = this.width;
      const [from, to] = [3, 10];
      const randomRadius = Math.floor(Math.random() * (to - from + 1) + from);

      function Particle() {
        this.x = Math.random() * W; // x-coordinate

        this.y = Math.random() * H - H; // y-coordinate

        this.r = randomRadius; // radius;

        this.d = Math.random() * maxParticles + 10; // density;

        this.color = color;
        this.tilt = Math.floor(Math.random() * 10) - 10;
        this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
        this.tiltAngle = 0;

        this.draw = function () {
          const lineWidth = this.r / 2;
          const moveX = this.x + this.tilt + this.r / 4;
          const moveY = this.y;
          const lineX = this.x + this.tilt;
          const lineY = this.y + this.tilt + this.r / 4; // fingerpaint

          ctx.beginPath();
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = this.color;
          ctx.moveTo(moveX, moveY);
          ctx.lineTo(lineX, lineY);
          ctx.stroke();
        };
      }

      return new Particle();
    },

    checkForReposition(particle, index) {
      const angle = this.angle;
      const W = this.width;
      const H = this.height;
      const repositionParticle = this.repositionParticle;

      if (particle.x > W + 20 || particle.x < -20 || particle.y > H
      /* && confettiActive*/
      ) {
        if (index % 5 > 0 || index % 2 === 0) {
          // 66.67% of the flakes
          repositionParticle(particle, Math.random() * W, -10, Math.floor(Math.random() * 10) - 20);
        } else {
          if (Math.sin(angle) > 0) {
            // Enter from the left
            repositionParticle(particle, -20, Math.random() * H, Math.floor(Math.random() * 10) - 20);
          } else {
            // Enter from the right
            repositionParticle(particle, W + 20, Math.random() * H, Math.floor(Math.random() * 10) - 20);
          }
        }
      }
    },

    repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
      particle.x = xCoordinate;
      particle.y = yCoordinate;
      particle.tilt = tilt;
    },

    stepParticle(particle, particleIndex) {
      const angle = this.angle;
      particle.tiltAngle += particle.tiltAngleIncremental;
      particle.y += (Math.cos(angle + particle.d) + 1 + particle.r / 2) / 2;
      particle.x += Math.sin(angle);
      particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 12;
    },

    update() {
      const maxParticles = this.maxParticles;
      const particles = this.particles;
      const tiltAngle = this.tiltAngle;
      const angle = this.angle;
      const H = this.height;
      const checkForReposition = this.checkForReposition.bind(this);
      const stepParticle = this.stepParticle.bind(this);
      this.set('angle', angle + 0.01);
      this.set('tiltAngle', tiltAngle + 0.1);

      for (let i = 0; i < maxParticles; i++) {
        const particle = particles[i];

        if (particle.y < -15) {
          particle.y = H + 50;
          continue;
        }

        stepParticle(particle, i);
        checkForReposition(particle, i);
      }
    },

    draw() {
      const particles = this.particles;
      const maxParticles = this.maxParticles;
      const ctx = this.context2d;
      const H = this.height;
      const W = this.width;
      ctx.clearRect(0, 0, W, H + 14);

      for (let i = 0; i < maxParticles; i++) {
        const particle = particles[i];
        particle.draw();
      }

      this.update();
    },

    requestAnimationFrame() {
      return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
        return window.setTimeout(callback, 1000 / 60);
      };
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('animationComplete', false);
      const particles = this.particles;
      const maxParticles = this.maxParticles;

      for (let i = 0; i < maxParticles; i++) {
        const particleColor = this.nextColor();
        particles.push(this.createParticle(particleColor));
      }

      const component = this;
      const draw = component.draw.bind(component);
      const requestAnimationFrame = component.requestAnimationFrame();

      (function confettiLoop() {
        if (component.get('animationComplete')) {
          return;
        }

        const animationHandler = requestAnimationFrame(confettiLoop);
        component.set('animationHandler', animationHandler);
        draw();
      })();
    },

    willDestroyElement() {
      clearTimeout(this.animationHandler);
      this.set('animationHandler', null);
      this.set('animationComplete', true);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});