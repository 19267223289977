define("dashboard/controllers/app/resources", ["exports", "@ember/controller", "@ember/object", "dashboard/mixins/formations-sort", "dashboard/utils/custom-computed", "dashboard/mixins/formation-totals", "dashboard/mixins/addons-totals"], function (_exports, _controller, _object, _formationsSort, _customComputed, _formationTotals, _addonsTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    and,
    filterBy,
    not,
    notEmpty,
    or,
    readOnly
  } = _object.computed;
  /**
   * Controller for an app's resources page.
   *
   * @class Dashboard.AppResourcesController
   * @extends Ember.Controller
   */

  var _default = _controller.default.extend(_formationsSort.default, _formationTotals.default, _addonsTotals.default, {
    queryParams: ['justInstalledAddonServiceId'],
    justInstalledAddonServiceId: null,
    addons: alias('model.addons'),
    app: alias('model.app'),
    appPermissions: alias('model.appPermissions'),
    attachments: alias('model.attachments'),
    formations: alias('app.formations'),
    justInstalledAddonService: alias('model.justInstalledAddonService'),
    cardPresent: alias('model.paymentMethod.cardPresent'),
    isViewable: readOnly('appPermissions.canViewResources'),
    isEditable: readOnly('appPermissions.canProvisionFreeResources'),
    isOwnerOrAdmin: or('appPermissions.isOwner', 'appPermissions.isAdmin'),
    isOwner: readOnly('appPermissions.isOwner'),
    isCollaborator: alias('appPermissions.isCollaborator'),
    canScaleFormations: readOnly('appPermissions.canScalePs'),
    dirtyProcesses: filterBy('formations', 'hasDirtyAttributes'),
    formationIsDirty: notEmpty('dirtyProcesses'),
    _appIsNotEco: not('app.isEcoTier'),
    showNewCostEstimate: and('formationIsDirty', '_appIsNotEco'),
    attachmentsBilledToThisApp: filterBy('attachments', 'isAttached', false),
    originalTotalPrice: (0, _object.computed)('addonsTotalPrice', 'formationsOriginalTotalPrice', function () {
      return this.addonsTotalPrice + this.formationsOriginalTotalPrice * 100;
    }),
    currentTotalPrice: (0, _object.computed)('addonsTotalPrice', 'formationsTotalPrice', function () {
      return this.addonsTotalPrice + this.formationsTotalPrice * 100;
    }),
    dynoLimit: (0, _object.computed)('model.app.dynoLimit', 'canScaleFormations', function () {
      return this.canScaleFormations ? this.get('model.app.dynoLimit') : null;
    }),
    webType: 'web',
    _webProcess: (0, _customComputed.findBy)('formations', 'type', 'webType'),
    autoscaleMonitor: (0, _object.computed)('_webProcess.monitors.[]', 'app.id', '_webProcess.monitors.isPending', function () {
      let monitors = this.get('_webProcess.monitors');

      if (!this.get('_webProcess.monitors') || monitors.isPending) {
        monitors = [];
      }

      return monitors.findBy('isScalingType', true) || this.store.createRecord('metrics/monitor', {
        formation: this._webProcess,
        appId: this.get('app.id'),
        actionType: 'scale',
        name: 'LATENCY_SCALE',
        op: 'GREATER_OR_EQUAL',
        processType: 'web',
        isActive: false
      });
    })
  });

  _exports.default = _default;
});