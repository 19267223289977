define("dashboard/templates/components/metrics/charts/visualizations/wrapped-row-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ahMyIi9a",
    "block": "[[[10,0],[14,0,\"bb b--black-05 w-100\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"devCenterName\",\"legendItems\",\"insufficientDataTitle\",\"title\",\"isCompactLayout\",\"isShowingLoadingState\",\"onToggleCollapseChart\",\"chartIsCollapsed\",\"timezone\",\"timeframe\",\"updateSelectedTime\",\"selectedTime\",\"eventData\",\"chartKey\",\"chartContentComponent\",\"app\",\"chartContentData\",\"summaryData\",\"summaryUnavailable\",\"headerContentComponent\",\"messageContent\",\"isBeta\",\"isDevPreview\"],[[30,0,[\"devCenterName\"]],[30,0,[\"legendItems\"]],[30,0,[\"insufficientDataTitle\"]],[30,0,[\"chartTitle\"]],[30,0,[\"isCompactLayout\"]],[30,0,[\"isShowingLoadingState\"]],[30,0,[\"onToggleCollapseChart\"]],[30,0,[\"chartIsCollapsed\"]],[30,0,[\"timezone\"]],[30,0,[\"timeframe\"]],[30,0,[\"updateSelectedTime\"]],[30,0,[\"selectedTime\"]],[30,0,[\"eventData\"]],[30,0,[\"chartKey\"]],\"metrics/charts/chart-content/row-chart\",[30,0,[\"app\"]],[30,0,[\"chartContentData\"]],[30,0,[\"summaryData\"]],[30,0,[\"summaryUnavailable\"]],[30,0,[\"summaryComponent\"]],[30,0,[\"messageContent\"]],[30,0,[\"isBeta\"]],[30,0,[\"isDevPreview\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"metrics/charts/timeseries-chart\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/visualizations/wrapped-row-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});