define("dashboard/templates/components/confirm/confirmable-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bRoki0ht",
    "block": "[[[41,[30,0,[\"hasLink\"]],[[[1,\"  \"],[11,3],[24,6,\"#\"],[16,0,[29,[\"hk-link \",[30,0,[\"linkClassNames\"]]]]],[16,\"disabled\",[30,0,[\"linkDisabled\"]]],[4,[38,1],[[30,0],\"toggleConfirmModal\"],null],[12],[1,\"\\n    \"],[1,[30,0,[\"linkText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isModalVisible\"]],[[[6,[39,2],null,[[\"onClose\",\"hasCancelButton\",\"title\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"primaryActionDisabled\",\"cancelAction\",\"cancelButtonClassNames\"],[[28,[37,1],[[30,0],\"toggleConfirmModal\"],null],true,[30,0,[\"modalTitle\"]],[30,1],[30,0,[\"modalActionButtonText\"]],[30,0,[\"modalActionButtonClassNames\"]],[30,0,[\"confirmationDoesNotMatch\"]],[30,2],\"btn btn-default\"]],[[\"default\"],[[[[1,\"     \"],[18,3,[[28,[37,1],[[30,0],\"validate\"],null]]],[1,\"\\n\"]],[]]]]]],[]],null]],[\"@onConfirm\",\"@onCancel\",\"&default\"],false,[\"if\",\"action\",\"hk-modal\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/confirm/confirmable-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});