define("dashboard/components/datastore/messages-chart", ["exports", "dashboard/components/datastore/datastore-chart", "@ember/object"], function (_exports, _datastoreChart, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datastoreChart.default.extend({
    classNames: ['metrics__messages-chart'],
    smallNumberFormat: '< 1',
    smallNumberThreshold: 1,
    datastoreTimeSeries: (0, _object.computed)('data', function () {
      return _object.default.create({
        name: this.title,
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/datastore-area-graph',
        index: 0,
        data: this.get('data'),
        isVisible: true,
        key: 'Avg',
        hideLine: false
      });
    }),
    timeSeriesData: (0, _object.computed)('datastoreTimeSeries', function () {
      return [this.datastoreTimeSeries.get('data')];
    }),
    chartContentData: (0, _object.computed)('datastoreTimeSeries', function () {
      return _object.default.create({
        chartTimeSeries: [this.datastoreTimeSeries]
      });
    }),
    summaryData: (0, _object.computed)('datastoreTimeSeries', function () {
      return _object.default.create({
        min: this.get('defaultMinValue'),
        max: this.get('maxMaxValue'),
        unit: this.get('unit'),
        format: this.get('valueFormat')
      });
    })
  });

  _exports.default = _default;
});