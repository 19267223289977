define("dashboard/templates/components/configure-sms-recovery", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UGSPitas",
    "block": "[[[6,[39,0],null,[[\"onClose\",\"containerClass\",\"title\"],[[30,0,[\"onCloseModal\"]],\"two-factor\",\"Set Up Phone Recovery\"]],[[\"default\"],[[[[6,[39,1],null,[[\"action\",\"needsPassword\",\"checkPassword\",\"email\",\"labelText\",\"submitText\",\"onCancel\"],[[28,[37,2],[[30,0],\"setPassword\"],null],[30,0,[\"needsPassword\"]],true,[30,0,[\"current\",\"account\",\"email\"]],\"Enter your current password to configure SMS recovery\",\"Set up SMS Recovery\",[30,0,[\"onCloseModal\"]]]],[[\"default\"],[[[[41,[30,0,[\"showStepOne\"]],[[[6,[39,4],null,[[\"smsNumber\",\"password\",\"onSubmitNumber\"],[[30,0,[\"current\",\"account\",\"smsNumber\"]],[30,0,[\"password\"]],[28,[37,2],[[30,0],\"transitionToStepTwo\"],null]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,5],null,[[\"onClick\",\"buttonType\",\"default\"],[[28,[37,2],[[30,0],\"close\"],null],\"tertiary\",\"Cancel\"]]]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showStepTwo\"]],[[[6,[39,6],null,[[\"smsNumber\",\"password\",\"onConfirmCode\"],[[30,0,[\"smsNumber\"]],[30,0,[\"password\"]],[28,[37,2],[[30,0],\"close\"],null]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,5],null,[[\"onClick\",\"buttonType\",\"default\"],[[28,[37,2],[[30,0],\"transitionToStep\",1],null],\"tertiary\",\"Edit Phone Number\"]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[]]]]]],[]]]]]],[],false,[\"hk-modal-flexible\",\"requires-password\",\"action\",\"if\",\"enter-sms-number\",\"hk-button\",\"confirm-sms-number\"]]",
    "moduleName": "dashboard/templates/components/configure-sms-recovery.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});