define("dashboard/serializers/repositories-api-pull-request", ["exports", "@ember/string", "dashboard/serializers/application"], function (_exports, _string, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function camelizeKeys(object) {
    return Object.entries(object).reduce((memo, _ref) => {
      let [key, value] = _ref;
      memo[(0, _string.camelize)(key)] = value;
      return memo;
    }, {});
  }

  var _default = _application.default.extend({
    normalize(modelClass, payload) {
      const {
        user
      } = payload;

      const normalized = this._super(modelClass, payload);

      if (user) {
        const included = [{
          id: user.id,
          type: 'repositories-api-github-user',
          attributes: camelizeKeys(user)
        }];
        normalized.included = (payload.included || []).concat(included);
      }

      return normalized;
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      const user = hash.user;

      if (user) {
        relationships.user = {
          data: {
            id: user.id,
            type: 'repositories-api-github-user'
          }
        };
      }

      if (hash.pipeline) {
        relationships.pipeline = {
          data: {
            id: hash.pipeline.id,
            type: 'pipeline'
          }
        };
      }

      return relationships;
    }

  });

  _exports.default = _default;
});