define("dashboard/helpers/json-stringify", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.jsonStringify = jsonStringify;

  function jsonStringify(_ref) {
    let [text, length = 4] = _ref;
    return JSON.stringify(text, null, length);
  }

  var _default = (0, _helper.helper)(jsonStringify);

  _exports.default = _default;
});