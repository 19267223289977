define("dashboard/adapters/pipeline-stage-config-var", ["exports", "dashboard/adapters/application", "@ember/utils"], function (_exports, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.pipelines',

    // The endpoints for pipeline config vars aren't JSON API compatible; we can't
    // rely on traditional adapter conventions.
    urlForQuery(query) {
      const {
        pipeline,
        stage
      } = query;

      if ((0, _utils.isNone)(pipeline) || (0, _utils.isNone)(stage)) {
        return this._super(...arguments);
      }

      delete query.pipeline;
      delete query.stage;
      return `${this.host}/pipelines/${pipeline}/stage/${stage}/config-vars`;
    },

    // Pipeline config vars are updated with a patch that includes the keys and
    // values to change. For compatibility with ember data, we only update one
    // config var at a time, though the endpoint supports patching multiple.
    urlForUpdateRecord(id, modelname, snapshot) {
      const pipelineId = snapshot.record.get('pipeline.id');
      const stage = snapshot.record.get('stage');
      return `${this.host}/pipelines/${pipelineId}/stage/${stage}/config-vars`;
    },

    // Pipeline config vars are created with a patch including a key that didn't
    // previously exist with its value.
    createRecord(store, type, snapshot) {
      return this.updateRecord(store, type, snapshot);
    },

    // This method is overridden to allow us to add `stage` as a top-level property on the
    // model by pulling it off the snapshot and adding it back to the Ember Data record once the
    // save operation is complete
    updateRecord(store, type, snapshot) {
      const pipeline = snapshot.belongsTo('pipeline').id;
      const stage = snapshot.attr('stage');
      const key = snapshot.attr('key');

      const result = this._super(store, type, snapshot);

      return result.then(record => {
        return {
          pipeline,
          stage,
          key,
          vars: record
        };
      });
    },

    // `query` is overridden here to support the `stage` property on the model, which is not present
    // in the API response. Instead, we have to pull it out of the query params and add it to the
    // response before it gets handed off to the serializer
    query(store, type, query) {
      const {
        stage,
        pipeline
      } = query;

      const result = this._super(store, type, query);

      return result.then(record => {
        return {
          stage,
          pipeline,
          vars: record
        };
      });
    },

    // Pipeline config vars are deleted by setting patching the value to null.
    deleteRecord(store, type, snapshot) {
      snapshot._attributes.value = null;
      return this.updateRecord(store, type, snapshot);
    }

  });

  _exports.default = _default;
});