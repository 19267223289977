define("dashboard/components/commit-message", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    subject: (0, _computed.readOnly)('testRun.commitMessageSubject'),
    body: (0, _computed.readOnly)('testRun.commitMessageBody'),
    sha: (0, _computed.readOnly)('testRun.commitSha'),
    shortSha: (0, _computed.readOnly)('testRun.shortSha'),
    commitUrl: (0, _object.computed)('repoName', 'sha', function () {
      return `http://github.com/${this.repoName}/commit/${this.sha}`;
    }),
    hasLongSubject: (0, _computed.gt)('subject.length', 50),
    canExpand: (0, _computed.or)('body', 'hasLongSubject'),
    isShowingBody: (0, _computed.and)('body', 'isExpanded'),
    truncationPoint: (0, _object.computed)('isExpanded', 'subject.length', function () {
      return this.isExpanded ? this.get('subject.length') + 1 : 50;
    }),
    actions: {
      toggle() {
        this.toggleProperty('isExpanded');
      }

    }
  });

  _exports.default = _default;
});