define("dashboard/templates/components/enterprise-account/manage-third-party-connection", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JjQm2SFJ",
    "block": "[[[6,[39,0],null,[[\"pending\"],[[28,[37,1],[[30,0,[\"githubAppInstallation\"]]],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,2],null,[[\"hasAdminAccess\",\"hasGithubAppInstalled\",\"githubAppInstallation\",\"enterpriseAccount\",\"connectedGitHubThingName\",\"class\"],[[30,0,[\"hasAdminAccess\"]],[30,0,[\"hasGithubAppInstalled\"]],[30,0,[\"enterpriseAccount\",\"githubAppInstallation\"]],[30,0,[\"enterpriseAccount\"]],[30,0,[\"enterpriseAccount\",\"githubAppInstallation\",\"githubAccountName\"]],\"mb4\"]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"lazy-loading-spinner\",\"is-pending\",\"github-app-banner\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/manage-third-party-connection.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});