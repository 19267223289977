define("dashboard/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AnYNtRLo",
    "block": "[[[8,[39,0],[[24,0,\"fixed left-0\"]],null,null],[1,\"\\n\\n\"],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,0,\"db\"],[14,\"width\",\"0\"],[14,\"height\",\"0\"],[12],[1,\"\\n  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"pattern\"],[14,\"width\",\"4\"],[14,\"height\",\"4\"],[14,1,\"missing-data-zone-pattern\"],[14,\"patternUnits\",\"userSpaceOnUse\"],[14,\"stroke\",\"rgb(231, 231, 236)\"],[12],[1,\"\\n      \"],[10,\"path\"],[14,\"d\",\"M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[6,[39,1],null,[[\"currentPath\",\"account\"],[[30,0,[\"currentPath\"]],[30,0,[\"account\"]]]],[[\"default\"],[[[[1,\"  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"toggletip/boundary\",\"app-wrapper\",\"component\",\"-outlet\"]]",
    "moduleName": "dashboard/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});