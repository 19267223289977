define("dashboard/routes/protected/orgs-redirect", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    /**
     * redirect /orgs/** to /teams/**
     */
    redirect(model, transition) {
      // wildcard looks like app-name/resources
      const wildcard = model.wildcard;
      transition.router.replaceWith(`/teams/${wildcard}`);
    }

  });

  _exports.default = _default;
});