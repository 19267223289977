define("dashboard/components/app/addons-marketplace-link", ["exports", "@ember/service", "@ember/component"], function (_exports, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    analytics: (0, _service.inject)(),
    marketplaceUrl: null,
    whereFrom: null,
    // might look like 'provision modal' or 'actions list',
    actions: {
      trackAndGoToMarketplaceUrl() {
        this.analytics.logEvent('Marketplace Link', 'Clicked', {
          topic: 'addons'
        });
        window.open(this.marketplaceUrl);
      }

    }
  });

  _exports.default = _default;
});