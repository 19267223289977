define("dashboard/components/metrics/charts/row-chart-elements/error-indicator", ["exports", "@ember/object", "dashboard/components/metrics/charts/row-chart-elements/indicator", "moment"], function (_exports, _object, _indicator, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _indicator.default.extend({
    _valuePerMinute: (0, _object.computed)('rollupDuration', 'value', function () {
      const value = this.value;
      const duration = this.get('rollupDuration');

      const minutes = _moment.default.duration(duration).asMinutes();

      return value / minutes;
    }),
    // the lowest opacity we should ever display
    minOpacity: (0, _object.computed)('_level', function () {
      if (this._level === 'activity') {
        return 0.75;
      } else {
        return 0.3;
      }
    }),
    opacity: (0, _object.computed)('_valuePerMinute', 'minOpacity', function () {
      let opacity;
      const value = this._valuePerMinute;
      const min = this.minOpacity; // the max opacity we should ever display

      const max = 1.0; // the value at which the max opacity should be used

      const maxThreshold = 10;
      opacity = value / maxThreshold * (max - min) + min; // reduce opacity if it exceeds max

      if (opacity > max) {
        opacity = max;
      }

      return opacity;
    })
  });

  _exports.default = _default;
});