define("dashboard/components/two-factor/prompt", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    // provided by components/two-factor
    twoFactorRequired: false,
    twoFactorToken: '',
    twoFactorFulfilled: false,
    twoFactorUnfulfilled: true
  });

  _exports.default = _default;
});