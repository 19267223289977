define("dashboard/templates/components/scheduler/empty-state", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EsD46ZY2",
    "block": "[[[10,0],[14,0,\"ph2 pv4 br1 tc flex-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mv2 f4 normal lh-copy dark-gray center b\"],[12],[1,\"\\n    Scheduler is a free add-on for running jobs on your app at scheduled time intervals\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mb4 f5 normal lh-copy gray center\"],[12],[1,\"When you create a new job in this app it will appear here\"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,3],[14,6,\"https://devcenter.heroku.com/articles/scheduler\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-button--secondary mr2\"],[12],[1,\"Learn more\"],[13],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"onClick\",\"default\"],[[30,0,[\"createJob\"]],\"Create job\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"hk-button\"]]",
    "moduleName": "dashboard/templates/components/scheduler/empty-state.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});