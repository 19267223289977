define("dashboard/controllers/account", ["exports", "@ember/object", "@ember/controller"], function (_exports, _object, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    applicationController: (0, _controller.inject)('application'),
    title: 'Account',
    indexActive: (0, _object.computed)('applicationController.currentRouteName', function () {
      const path = this.get('applicationController.currentRouteName');
      return path === 'account.index';
    }),
    applicationsActive: (0, _object.computed)('applicationController.currentRouteName', function () {
      const path = this.get('applicationController.currentRouteName');
      return ['account.applications', 'account.applications.new-client', 'account.applications.edit-client'].includes(path);
    }),
    billingActive: (0, _object.computed)('applicationController.currentRouteName', function () {
      const path = this.get('applicationController.currentRouteName');
      return path.indexOf('account.billing') === 0;
    })
  });

  _exports.default = _default;
});