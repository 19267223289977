define("dashboard/components/pipeline/pr-details-group", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    user: (0, _computed.readOnly)('pullRequest.user'),
    linkTitleAttribute: (0, _object.computed)('pullRequest.number', 'pullRequest.title', function () {
      return `#${this.get('pullRequest.number')} - ${this.get('pullRequest.title')}`;
    })
  });

  _exports.default = _default;
});