define("dashboard/components/metrics/charts/visualizations/events-chart", ["exports", "@ember/utils", "dashboard/components/metrics/charts/visualizations/wrapped-row-chart", "@ember/object", "dashboard/utils/metrics/data-refresh", "dashboard/utils/metrics/time-calculators", "@ember/object/computed"], function (_exports, _utils, _wrappedRowChart, _object, _dataRefresh, _timeCalculators, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedRowChart.default.extend({
    tagName: 'metrics-events-chart',
    chartKey: 'events',
    chartTitle: 'Events',
    insufficientDataTitle: 'No Events',
    devCenterName: 'metrics#events',
    scaleEvents: (0, _computed.filter)('restarts', function (restart) {
      return restart.get('restartType').includes('scale');
    }),
    alertEvents: (0, _computed.filter)('viewableRestarts', function (restart) {
      return restart.get('restartType').includes('alert');
    }),
    _filteredPlatformEvents: (0, _object.computed)('platformEvents.[]', 'app.region.name', 'app.space', 'currentStartTime', 'currentEndTime', function () {
      const region = this.get('app.region.name');
      const appInSpace = (0, _utils.isPresent)(this.get('app.space'));
      const metricsEndTime = this.currentEndTime;
      const metricsStartTime = this.currentStartTime; // eslint-disable-next-line complexity

      return this.platformEvents.filter(function (platformEvent) {
        const tags = platformEvent.get('tags');
        const eventStartedAt = platformEvent.get('startedAt');
        const eventEndedAt = platformEvent.get('endedAt');

        if (tags.includes('Spaces') && !appInSpace && !(tags.includes('EU') || tags.includes('US'))) {
          return false;
        } else if ((tags.includes('US') || tags.includes('EU')) && !tags.includes('Spaces') && appInSpace) {
          return false;
        } else if (tags.includes('EU') && !tags.includes('US') && region === 'us') {
          return false;
        } else if (tags.includes('US') && !tags.includes('EU') && region === 'eu') {
          return false;
        } else if (eventStartedAt > metricsEndTime || eventEndedAt < metricsStartTime) {
          return false;
        } else {
          return true;
        }
      });
    }),
    incidentEvents: (0, _computed.filter)('_filteredPlatformEvents', function (platformEvent) {
      return (0, _utils.isEmpty)(platformEvent.get('updates').filterBy('updateType', 'scheduled'));
    }),
    scheduledEvents: (0, _computed.filter)('_filteredPlatformEvents', function (platformEvent) {
      return (0, _utils.isPresent)(platformEvent.get('updates').filterBy('updateType', 'scheduled'));
    }),
    incidentUpdates: (0, _object.computed)('incidentEvents.[]', function () {
      return this.incidentEvents.reduce((updates, incident) => {
        incident.get('updates').forEach(function (update) {
          updates.pushObject(update);
        });
        return updates;
      }, []);
    }),
    scheduledUpdates: (0, _object.computed)('scheduledEvents.[]', function () {
      return this.scheduledEvents.reduce((updates, incident) => {
        incident.get('updates').forEach(function (update) {
          updates.pushObject(update);
        });
        return updates;
      }, []);
    }),
    alerts: (0, _object.computed)('refreshingAlerts', function () {
      return this.refreshingAlerts;
    }),
    visibleAlerts: (0, _timeCalculators.filterPeriodWithinTimeframe)('alerts', 'startedAt', 'endedAt'),
    alertData: (0, _computed.union)('alertEvents', 'visibleAlerts'),
    formationChangeData: (0, _computed.union)('scaleEvents', 'viewableFormationChanges'),
    incidents: (0, _computed.union)('incidentEvents', 'incidentUpdates'),
    scheduled: (0, _computed.union)('scheduledEvents', 'scheduledUpdates'),
    deploys: (0, _object.computed)('refreshingDeploys', function () {
      return this.refreshingDeploys;
    }),
    viewableDeployReleases: (0, _timeCalculators.filterWithinTimeframe)('deploys', 'createdAt'),
    configChanges: (0, _object.computed)('refreshingConfigChanges', function () {
      return this.refreshingConfigChanges;
    }),
    viewableConfigChanges: (0, _timeCalculators.filterWithinTimeframe)('configChanges', 'createdAt'),
    formationChanges: (0, _object.computed)('refreshingFormationChanges', function () {
      return this.refreshingFormationChanges;
    }),
    viewableFormationChanges: (0, _timeCalculators.filterWithinTimeframe)('formationChanges', 'updatedAt'),
    restarts: (0, _object.computed)('refreshingRestarts', function () {
      return this.refreshingRestarts;
    }),
    viewableRestarts: (0, _timeCalculators.filterWithinTimeframe)('restarts', 'createdAt'),
    restartEvents: (0, _computed.filter)('viewableRestarts', function (restart) {
      const restartType = restart.get('restartType');
      return !restartType.includes('scale') && !restartType.includes('alert');
    }),
    hasDeploys: (0, _computed.notEmpty)('viewableDeployReleases'),
    hasConfigChanges: (0, _computed.notEmpty)('viewableConfigChanges'),
    hasFormationChangeData: (0, _computed.notEmpty)('viewableFormationChanges'),
    hasRestarts: (0, _computed.notEmpty)('restartEvents'),
    isShowingActivity: (0, _computed.or)('hasDeploys', 'hasConfigChanges', 'hasFormationChangeData', 'hasRestarts'),

    /**
     * manual override for events chart usage
     */
    legendItems: (0, _object.computed)('isShowingActivity', function () {
      const isShowingActivity = this.isShowingActivity;
      const baseLegend = [_object.default.create({
        name: 'Critical',
        color: 'crit'
      }), _object.default.create({
        name: 'Warning',
        color: 'warning'
      }), _object.default.create({
        name: 'Info',
        color: 'info'
      })];

      if (isShowingActivity) {
        baseLegend.addObject(_object.default.create({
          name: 'Activity',
          color: 'activity'
        }));
      }

      baseLegend.set('lastObject.isLastOrdered', true);
      return baseLegend;
    }),
    resolvedIncidents: (0, _object.computed)('refreshingResolvedIncidents', function () {
      return this.refreshingResolvedIncidents;
    }),
    unresolvedIncidents: (0, _object.computed)('refreshingUnresolvedIncidents', function () {
      return this.refreshingUnresolvedIncidents;
    }),
    viewableResolvedIncidents: (0, _timeCalculators.filterPeriodWithinTimeframe)('resolvedIncidents', 'startedAt', 'endedAt'),
    resolvedAndUnresolvedIncidents: (0, _computed.union)('viewableResolvedIncidents', 'unresolvedIncidents'),
    platformEvents: (0, _object.computed)('resolvedAndUnresolvedIncidents.[]', function () {
      return this.resolvedAndUnresolvedIncidents.reduce(function (platformEvents, platformEvent) {
        if ((0, _utils.isEmpty)(platformEvents.filterBy('id', platformEvent.get('id')))) {
          platformEvents.pushObject(platformEvent);
        }

        return platformEvents;
      }, []);
    }),
    incidentEventsAreLoading: (0, _computed.or)('refreshingResolvedIncidents.isPending', 'refreshingUnresolvedIncidents.isPending'),
    isLoadingData: (0, _computed.or)('refreshingFormationChanges.isPending', 'refreshingDynoErrors.isPending', 'refreshingRouterErrors.isPending', 'refreshingRestarts.isPending', 'refreshingAlerts.isPending', 'incidentEventsAreLoading', 'refreshingDeploys.isPending', 'refreshingConfigChanges.isPending'),
    dynoErrors: (0, _computed.readOnly)('refreshingDynoErrors'),
    routerErrors: (0, _computed.readOnly)('refreshingRouterErrors'),
    loadRefreshedAlerts: (0, _dataRefresh.loadRefreshedData)('refreshingAlerts', 'alerts'),
    loadRefreshedFormationChanges: (0, _dataRefresh.loadRefreshedData)('refreshingFormationChanges', 'formationChanges'),
    loadRefreshedRestarts: (0, _dataRefresh.loadRefreshedData)('refreshingRestarts', 'restarts'),
    loadRefreshedConfigChanges: (0, _dataRefresh.loadRefreshedData)('refreshingConfigChanges', 'configChanges'),
    loadRefreshedDeploys: (0, _dataRefresh.loadRefreshedData)('refreshingDeploys', 'deploys'),
    loadRefreshedResolvedIncidents: (0, _dataRefresh.loadRefreshedData)('refreshingResolvedIncidents', 'resolvedIncidents'),
    loadRefreshedUnresolvedIncidents: (0, _dataRefresh.loadRefreshedData)('refreshingUnresolvedIncidents', 'unresolvedIncidents'),
    eventData: (0, _computed.collect)('alerts', 'formationChanges', 'dynoErrors', 'restarts', 'configChanges', 'deploys', 'resolvedIncidents', 'unresolvedIncidents'),
    filteredRouterErrors: (0, _object.computed)('isWeb', 'routerErrors', function () {
      return this.get('isWeb') ? this.get('routerErrors') : [];
    }),
    rowEvents: (0, _object.computed)('alerts.[]', 'formationChangeData.[]', 'restartEvents.[]', 'viewableDeployReleases.[]', 'incidents.[]', 'scheduled.[]', 'app.name', function () {
      const rowEvents = [];

      if (!(0, _utils.isEmpty)(this.alerts)) {
        rowEvents.push(_object.default.create({
          name: 'Alerts',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/alert-group',
          description: 'Threshold Alerts',
          level: 'critical',
          indicatorIcon: 'alert-diamond',
          models: this.alerts,
          url: 'https://devcenter.heroku.com/articles/metrics#threshold-alerting'
        }));
      }

      if (!(0, _utils.isEmpty)(this.viewableDeployReleases)) {
        rowEvents.push(_object.default.create({
          name: 'Deploys',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/activity-group',
          description: 'Deployments',
          level: 'activity',
          models: this.viewableDeployReleases,
          url: `/apps/${this.get('app.name')}/activity`
        }));
      }

      if (!(0, _utils.isEmpty)(this.formationChangeData)) {
        rowEvents.push(_object.default.create({
          name: 'Scale',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/formation-group',
          description: 'Formation Changes',
          level: 'activity',
          models: this.formationChangeData,
          url: `/apps/${this.get('app.name')}/resources`
        }));
      }

      if (!(0, _utils.isEmpty)(this.viewableConfigChanges)) {
        rowEvents.push(_object.default.create({
          name: 'Config',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/config-group',
          description: 'Config Var Changes',
          level: 'activity',
          models: this.viewableConfigChanges,
          url: `/apps/${this.get('app.name')}/activity`
        }));
      }

      if (!(0, _utils.isEmpty)(this.restartEvents)) {
        rowEvents.push(_object.default.create({
          name: 'Restart',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/restart-group',
          description: 'Dyno Restarts',
          level: 'activity',
          models: this.restartEvents,
          url: 'https://devcenter.heroku.com/articles/application-metrics-beta#restart-events'
        }));
      }

      if (!(0, _utils.isEmpty)(this.incidents)) {
        rowEvents.push(_object.default.create({
          name: 'Incident',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/incident-group',
          description: 'Heroku Platform Incident',
          level: 'warning',
          models: this.incidents,
          url: 'https://status.heroku.com/'
        }));
      }

      if (!(0, _utils.isEmpty)(this.scheduled)) {
        rowEvents.push(_object.default.create({
          name: 'Scheduled',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/incident-group',
          description: 'Heroku Scheduled Maintenance',
          level: 'info',
          models: this.scheduled,
          url: 'https://status.heroku.com/'
        }));
      }

      rowEvents.forEach((rowEvent, index) => rowEvent.order = index);
      return rowEvents;
    }),
    errorEvents: (0, _object.computed)('dynoErrors.[]', 'filteredRouterErrors.[]', 'rowEvents.[]', function () {
      const errorEvents = [];

      if (this.get('filteredRouterErrors.hasData')) {
        errorEvents.push(_object.default.create({
          errors: this.get('filteredRouterErrors.errorObjects')
        }));
      }

      if (this.get('dynoErrors.hasData')) {
        errorEvents.push(_object.default.create({
          errors: this.get('dynoErrors.errorObjects')
        }));
      }

      let orderOffset = this.get('rowEvents.length') || 0;
      errorEvents.forEach(errorEventGroup => {
        const errors = errorEventGroup.errors || [];
        errors.forEach((errorEvent, index) => errorEvent.order = index + orderOffset);
        orderOffset += errors.length;
      });
      return errorEvents;
    })
  });

  _exports.default = _default;
});