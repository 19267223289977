define("dashboard/models/password-check", ["exports", "@ember/object", "jquery", "dashboard/mixins/password-encoding", "dashboard/mixins/error-monitoring-model", "dashboard/config/environment", "rsvp", "@ember/runloop"], function (_exports, _object, _jquery, _passwordEncoding, _errorMonitoringModel, _environment, _rsvp, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  Check whether a given email and password combo are correct. Works by making
   *  a CORS request directly to https://api.heroku.com/user-identity/~ with http basic
   *  auth headers. If it succeeds the the combo is good, if it fails then it's
   *  not.
   *
   *  @param {String} attributes.email - required to perform the check
   *  @param {String} attributes.password - required to perform the check
   */
  var _default = _object.default.extend(_passwordEncoding.default, _errorMonitoringModel.default, {
    errorTitleVerb: 'completed',
    errorTitleSubject: 'Password check',
    basicAuthHeader: (0, _object.computed)('email', 'password', function () {
      const encoded = btoa(`${this.email}:${this.decodedPassword}`);
      return `Basic ${encoded}`;
    }),

    /*
     *  Performs a check on the email/password combo.
     *
     *  @method check
     *  @async
     *  @return {Ember.RSVP.Promise} - a promise resolved with true if the combo
     *                                 is correct and false if it isn't.
     */
    check() {
      return new _rsvp.default.Promise(resolve => {
        _jquery.default.ajax({
          headers: {
            Authorization: this.basicAuthHeader,
            Accept: 'application/vnd.heroku+json; version=3'
          },
          url: `${_environment.default.platformApiUrl}/user-identity/~`,
          method: 'GET',

          beforeSend(jqXHR) {
            jqXHR.ignoreUnauthorized = true;
          }

        }).then(() => {
          this.set('isCorrect', true);
          resolve(true);
        }, jqXHR => {
          const isCorrect = this.xhrIsOk(jqXHR);
          this.set('isCorrect', isCorrect);
          (0, _runloop.run)(() => {
            this.assignErrors(jqXHR);
          });
          resolve(isCorrect);
        });
      });
    },

    /*
     *  Treat 2FA challenges as ok - we know the password is correct if we get a
     *  2FA challenge.
     */
    xhrIsOk(jqXHR) {
      return jqXHR.status === 403 && jqXHR.responseJSON.id === 'two_factor';
    },

    // Suppress 2FA challenge errors
    assignErrors(jqXHR) {
      if (!this.xhrIsOk(jqXHR)) {
        return this._super.apply(this, arguments);
      }
    }

  });

  _exports.default = _default;
});