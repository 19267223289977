define("dashboard/services/addons-loader", ["exports", "@ember/object/promise-proxy-mixin", "@ember/object/proxy", "@ember/service", "rsvp", "dashboard/utils/addon-data-massaging"], function (_exports, _promiseProxyMixin, _proxy, _service, _rsvp, _addonDataMassaging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    allAttachmentsAddons,
    massageAttachments,
    servicePlansSetup
  } = _addonDataMassaging.default;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),

    _fetchAddons(appId) {
      return this.store.query('addon', {
        appNameOrId: appId
      });
    },

    _fetchAttachments(appId) {
      return this.store.query('attachment', {
        appNameOrId: appId
      });
    },

    fetchAllAddonsAndAttachments(appId, canViewResources) {
      if (!canViewResources) {
        this.set('addonsAndAttachments', {
          addons: [],
          attachments: []
        });
        return (0, _rsvp.resolve)();
      }

      const addons = this._fetchAddons(appId);

      const attachments = this._fetchAttachments(appId);

      const fetchAllPromise = _rsvp.default.hash({
        addons,
        attachments
      }).then(hash => {
        const addonsSetup = allAttachmentsAddons(hash.attachments).then(allAddons => {
          allAddons.invoke('fetchAttachment', this.store);
          massageAttachments(hash.attachments);
          return servicePlansSetup(allAddons, this.store);
        });
        return addonsSetup;
      }).then(() => {
        return {
          addons,
          attachments
        };
      });

      const ObjectPromiseProxy = _proxy.default.extend(_promiseProxyMixin.default);

      const proxy = ObjectPromiseProxy.create({
        promise: fetchAllPromise
      });
      this.set('addonsAndAttachments', proxy);
      return proxy;
    }

  });

  _exports.default = _default;
});