define("dashboard/adapters/payment-method", ["exports", "@ember-data/adapter/error", "@ember/object", "@ember/service", "dashboard/adapters/application"], function (_exports, _error, _object, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    _baseURL: (0, _object.computed)('host', 'namespace', function () {
      const host = this.host;
      const namespace = this.namespace;

      if (namespace) {
        return `${host}/${namespace}`;
      } else {
        return host;
      }
    }),
    dsErrorMonitor: (0, _service.inject)(),
    createMethod: 'PATCH',

    /**
     * This is not an Ember Data method, but an interface
     * for the rest of the PaymentMethodAdapter to call from
     * urlForQueryRecord/etc.
     */
    _paymentMethodSingletonURL() {
      const base = this._baseURL;
      return `${base}/account/payment-method`;
    },

    urlForQueryRecord() {
      return this._paymentMethodSingletonURL();
    },

    urlForUpdateRecord() {
      return this._paymentMethodSingletonURL();
    },

    urlForCreateRecord() {
      return this._paymentMethodSingletonURL();
    },

    urlForDeleteRecord() {
      return this._paymentMethodSingletonURL();
    },

    normalizeErrorResponse(status, headers, payload) {
      if (status === 422 && payload && typeof payload === 'object') {
        const errors = [{
          status,
          title: 'Invalid card',
          detail: payload.message
        }];
        return {
          errors
        };
      }

      return this._super(...arguments);
    },

    // TODO: once we have ember data 3, normalizeErrorResponse should get called
    // correctly. It doesn't seem to to do that in the 2.x series of ember data,
    // e.g. https://github.com/emberjs/data/blob/v2.13.0/addon/adapters/rest.js#L966
    handleResponse(status, headers, payload, requestData) {
      if (status >= 400) {
        requestData.jqXHR.errorIsHandled = true;
      }

      if (status === 422) {
        payload = this.normalizeErrorResponse(status, headers, payload);
        return new _error.InvalidError(payload.errors);
      }

      return this._super(...arguments);
    },

    // PaymentMethod and subclasses are singletons
    deleteRecord() {
      const oldVersion = this.version;
      this.set('version', '3.internal');
      const ajaxPromise = this.ajax(this.urlForDeleteRecord(), 'DELETE');
      this.set('version', oldVersion);
      return ajaxPromise;
    },

    // PaymentMethod and subclasses are singletons
    queryRecord(store, modelName, query) {
      return this.ajax(this.urlForQueryRecord(query, modelName), 'GET');
    },

    getClientToken() {
      const base = this._baseURL;
      return this.ajax(`${base}/account/payment-method/client-token`, 'POST');
    }

  });

  _exports.default = _default;
});