define("dashboard/components/account/authorizations-list", ["exports", "@ember/component", "@ember/utils", "@ember/object", "dashboard/mixins/purple-list"], function (_exports, _component, _utils, _object, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    filter,
    notEmpty
  } = _object.computed;

  var _default = _component.default.extend(_purpleList.default, {
    classNames: ['authorizations-list'],
    authorizedApps: filter('authorizations', authorization => {
      return (0, _utils.isPresent)(authorization.get('client.id'));
    }),
    hasAuthorizedApps: notEmpty('authorizedApps'),
    actions: {
      delete() {
        const authorization = this.item;
        const pendingDelete = authorization.destroyRecord();
        return pendingDelete.then(() => {
          this.send('toggleDeleteConfirmModal');
        });
      }

    }
  });

  _exports.default = _default;
});