define("dashboard/components/enterprise-usage/current-usage", ["exports", "@ember/object/proxy", "@ember/object", "@ember/object/computed", "@ember/component"], function (_exports, _proxy, _object, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // Final sorted/combined/filtered/etc list of licenses used to render
    licenses: (0, _computed.sort)('combined', function (a, b) {
      return a.compare(b);
    }),
    hasLicenses: (0, _computed.notEmpty)('licenses'),
    // Filters out duplicate licenses
    uniq: (0, _object.computed)('currentLicenses.[]', function () {
      const uniqLicenseData = new Map();
      this.currentLicenses.forEach(function (license) {
        const uniqKeys = [];
        ['code', 'qty', 'consumed', 'startDate', 'endDate'].forEach(function (key) {
          uniqKeys.push(license.get(key));
        });
        const key = uniqKeys.join('-');
        uniqLicenseData.set(key, license);
      });
      return Array.from(uniqLicenseData.values());
    }),
    // Filters out inactive and invalid licenses
    visible: (0, _object.computed)('uniq', function () {
      return this.uniq.filterBy('isActive').filterBy('isLicenseValid');
    }),
    // Combines all active and valid licenses of the same type so we can show
    // aggregate data for each.
    // TODO: this shouldn't be necessary because we're telling Vault to combine
    // the data, but we don't know for sure that that's happening in all cases.
    combined: (0, _object.computed)('visible.@each.qty', 'visible.@each.consumed', function () {
      const licenses = this.visible;
      const result = new Map();
      licenses.forEach(license => {
        const code = license.get('normalizedCode');
        let aggregator = result.get(code);

        if (!aggregator) {
          aggregator = Aggregator.create();
          result.set(code, aggregator);
        }

        aggregator.add(license);
      });
      return Array.from(result.values());
    })
  });

  _exports.default = _default;

  const Aggregator = _proxy.default.extend({
    licenses: (0, _object.computed)(function () {
      return [];
    }),
    content: (0, _computed.readOnly)('licenses.firstObject'),
    code: (0, _computed.readOnly)('normalizedCode'),
    qty: (0, _object.computed)('licenses.@each.qty', function () {
      return this.licenses.mapBy('qty').reduce((a, b) => a + b);
    }),
    consumed: (0, _object.computed)('licenses.@each.consumed', function () {
      return this.licenses.mapBy('consumed').reduce((a, b) => a + b);
    }),
    quota: (0, _object.computed)('isCurrency', 'qty', function () {
      const isCurrency = this.get('isCurrency');
      const qty = this.qty;

      if (isCurrency) {
        return qty * 100; // convert to cents
      } else {
        return qty;
      }
    }),
    currentUsage: (0, _object.computed)('isCurrency', 'consumed', function () {
      // default to 0 so this works without consumed being set
      const consumed = this.consumed || 0;

      if (this.get('isCurrency')) {
        return consumed * 100; // convert to cents
      } else {
        return consumed;
      }
    }),

    add(license) {
      this.licenses.addObject(license);
    },

    compare(other) {
      return this.content.compare(other);
    }

  });
});