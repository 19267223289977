define("dashboard/components/payment-method/hosted-field", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _computed, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Check out docs/credit-card-forms.md in the top of this repo for more context.
  var _default = _component.default.extend({
    paymentGateway: (0, _service.inject)(),
    'data-test-target': 'braintree-hosted-field',
    classNames: ['hk-braintree-hosted-fields', 'h3'],
    classNameBindings: ['isFocused:hk-braintree-hosted-fields-focused', 'fieldValid:hk-braintree-hosted-fields-valid', 'showInvalidClasses:hk-braintree-hosted-fields-invalid'],
    fieldValid: (0, _computed.readOnly)('state.isValid'),
    isPotentiallyValid: (0, _computed.readOnly)('state.isPotentiallyValid'),
    wasEntered: (0, _computed.readOnly)('state.wasEntered'),
    isFocused: (0, _computed.readOnly)('state.isFocused'),
    notValid: (0, _computed.not)('fieldValid'),
    notFocused: (0, _computed.not)('isFocused'),
    notPotentiallyValid: (0, _computed.not)('isPotentiallyValid'),
    errorWhileInForm: (0, _computed.and)('isFocused', 'notValid', 'notPotentiallyValid'),
    errorOutOfForm: (0, _computed.and)('wasEntered', 'notFocused', 'isInvalid'),
    showInvalidClasses: (0, _object.computed)('isFocused', 'notValid', 'notPotentiallyValid', 'isInvalid', function () {
      if (this.isFocused) {
        return this.notValid && this.notPotentiallyValid;
      }

      return this.isInvalid;
    }),
    // only sync state to changeset on focus out.
    onFocusOut: (0, _object.observer)('isFocused', 'wasEntered', 'fieldValid', function () {
      if (this.fieldValid || this.wasEntered && !this.isFocused) {
        this.setFieldValue.perform(this.state);
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      this.paymentGateway.didRenderField(this.field);
    },

    setFieldValue: (0, _emberConcurrency.task)(function* (field) {
      yield;
      this.set('value', field);
    }).restartable()
  });

  _exports.default = _default;
});