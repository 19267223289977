define("dashboard/controllers/protected", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/controller", "dashboard/mixins/preferences", "dashboard/config/environment"], function (_exports, _object, _computed, _service, _controller, _preferences, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_preferences.default, {
    queryParams: ['bluelogo'],
    applicationController: (0, _controller.inject)('application'),
    notificationsController: (0, _controller.inject)('notifications'),
    current: (0, _service.inject)(),
    session: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    loadingState: (0, _service.inject)(),
    media: (0, _service.inject)(),
    account: (0, _computed.alias)('model.account'),
    couplings: (0, _computed.alias)('model.couplings'),
    regions: (0, _computed.alias)('model.regions'),
    spaces: (0, _computed.alias)('model.spaces'),
    currentPath: (0, _computed.alias)('applicationController.currentPath'),
    isMobile: (0, _computed.alias)('media.isMobile'),
    isNotMobile: (0, _computed.not)('isMobile'),
    // NPS Properties
    NPSFeature: (0, _object.computed)('accountFeaturesService.all.[]', function () {
      const features = this.get('accountFeaturesService.all');
      const NPSFeature = features.find(feature => {
        const hasNPSFeature = feature.get('name').indexOf('nps-');
        return hasNPSFeature === 0 && feature.get('enabled');
      });
      return NPSFeature;
    }),
    hasNPSFeature: (0, _computed.bool)('NPSFeature'),
    NPSUserType: (0, _object.computed)('NPSFeature', 'hasNPSFeature', function () {
      if (this.hasNPSFeature) {
        return this.NPSFeature.get('name').split('nps-')[1];
      }
    }),
    NPSSurveyId: (0, _object.computed)(function () {
      return _environment.default.NPSSurveyId;
    }),
    isSurveyExternal: (0, _object.computed)('NPSSurveyId', function () {
      return this.NPSSurveyId.indexOf('external') === 0;
    }),
    isSurveyInternal: (0, _computed.not)('isSurveyExternal'),
    hasDismissedSurvey: (0, _object.computed)('dashboardUserPreferences.npsSurveyDismissal', 'NPSSurveyId', function () {
      return this.get('dashboardUserPreferences.npsSurveyDismissal') === this.NPSSurveyId;
    }),
    hasNotDismissedSurvey: (0, _computed.not)('hasDismissedSurvey'),
    hasDismissedSurveyForever: (0, _computed.equal)('dashboardUserPreferences.npsSurveyDismissal', 'NONE'),
    hasNotDismissedSurveyForever: (0, _computed.not)('hasDismissedSurveyForever'),
    hasNPSSurveyId: (0, _computed.bool)('NPSSurveyId'),
    isSurveyActive: (0, _computed.and)('hasNPSSurveyId', 'hasNotDismissedSurvey', 'hasNotDismissedSurveyForever', 'isNotMobile'),
    isShowingSurveyCustomers: (0, _computed.and)('hasNPSFeature', 'isSurveyActive', 'isSurveyExternal'),
    isShowingSurveyHerokai: (0, _computed.and)('current.account.isHerokai', 'isSurveyActive', 'isSurveyInternal'),
    isShowingSurvey: (0, _computed.or)('isShowingSurveyCustomers', 'isShowingSurveyHerokai'),

    setupFreshPage() {
      // allow a document title to be set on next request
      this.set('titleIsSet', false);
    },

    actions: {
      hideNPSSurvey() {
        this.set('isShowingSurvey', false);
      }

    }
  });

  _exports.default = _default;
});