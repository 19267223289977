define("dashboard/models/authorization", ["exports", "@ember/object", "dashboard/utils/base-url", "@ember-data/model"], function (_exports, _object, _baseUrl, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    client: (0, _model.attr)(),
    scope: (0, _model.attr)(),
    accessToken: (0, _model.attr)(),
    description: (0, _model.attr)('string'),
    expiresIn: (0, _model.attr)('number'),
    baseURL: (0, _object.computed)('client.redirect_uri', function () {
      return (0, _baseUrl.default)(this.get('client.redirect_uri'));
    }),

    regenerate() {
      return this.save({
        adapterOptions: {
          regenerate: true,
          updateMethod: 'POST'
        }
      });
    }

  });

  _exports.default = _default;
});