define("dashboard/components/scheduler/job-editor", ["exports", "@ember/component", "ember-changeset", "@ember/object/computed", "ember-concurrency"], function (_exports, _component, _emberChangeset, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Arguments
    app: null,
    job: null,
    save: null,
    close: null,
    // --------------------------------------------------------------------------
    // Computed Properties
    commandIsEmpty: (0, _computed.empty)('changeset.command'),
    submitIsDisabled: (0, _computed.or)('commandIsEmpty', 'doSave.isRunning'),

    // --------------------------------------------------------------------------
    // Lifecycle
    init() {
      this._super(...arguments);

      const model = this.job;
      this.changeset = new _emberChangeset.default(model);
    },

    // --------------------------------------------------------------------------
    // Tasks
    doSave: (0, _emberConcurrency.task)(function* (dismissPanel) {
      const job = this.changeset;
      return yield this.save(job, dismissPanel);
    }).drop()
  });

  _exports.default = _default;
});