define("dashboard/components/buildpack-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    iconName: (0, _object.computed)('language', 'size', function () {
      const languageName = this.language;
      const size = this.size;
      const matches = /clojure|java|node|php|python|ruby|scala|go|gradle/i.exec(languageName);
      const matchedLanguage = matches ? matches[0] : '';

      if (size > 16) {
        if (matchedLanguage !== '') {
          return `language-${matchedLanguage.toLowerCase()}-28`;
        } else {
          return `buildpack-28`;
        }
      } else {
        if (matchedLanguage !== '') {
          return `language-${matchedLanguage.toLowerCase()}-16`;
        } else {
          return `buildpack-16`;
        }
      }
    })
  });

  _exports.default = _default;
});