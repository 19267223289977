define("dashboard/components/datastore/combined-datastore-chart", ["exports", "dashboard/components/datastore/datastore-chart", "@ember/object", "@ember/object/computed", "dashboard/utils/metrics/unit-converters"], function (_exports, _datastoreChart, _object, _computed, _unitConverters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datastoreChart.default.extend({
    classNames: ['metrics__combined-datastore-chart'],
    headerContentComponent: 'datastore/combined-datastore-chart-summary',
    smallNumberFormat: '< 1',
    smallNumberThreshold: 1,
    isShowingIn: true,
    isShowingOut: true,
    inDataConversion: (0, _unitConverters.getScalingBytesConversion)('inData'),
    outDataConversion: (0, _unitConverters.getScalingBytesConversion)('outData'),
    scaledInData: (0, _object.computed)('inDataConversion', function () {
      const conversionUnit = this.inDataConversion['conversion'];
      return this.inData.map(_ref => {
        let [timestamp, bytes] = _ref;
        return [timestamp, bytes / conversionUnit];
      });
    }),
    scaledOutData: (0, _object.computed)('outDataConversion', function () {
      const conversionUnit = this.outDataConversion['conversion'];
      return this.outData.map(_ref2 => {
        let [timestamp, bytes] = _ref2;
        return [timestamp, bytes / conversionUnit];
      });
    }),
    inTimeSeries: (0, _object.computed)('scaledInData', 'isShowingIn', function () {
      return _object.default.create({
        name: 'In/Second',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/datastore-area-graph',
        index: 0,
        data: this.scaledInData,
        isVisible: this.isShowingIn,
        key: 'In',
        hideLine: false,
        extraData: {
          specificUnit: this.inDataConversion['key']
        }
      });
    }),
    outTimeSeries: (0, _object.computed)('scaledOutData', 'isShowingOut', function () {
      return _object.default.create({
        name: 'Out/Second',
        color: 'purple-002',
        type: 'metrics/charts/line-chart-elements/datastore-area-graph',
        index: 1,
        data: this.scaledOutData,
        isVisible: this.isShowingOut,
        key: 'Out',
        hideLine: false,
        extraData: {
          specificUnit: this.outDataConversion['key']
        }
      });
    }),
    timeSeriesData: (0, _object.computed)('datastoreTimeSeries', 'inTimeSeries', 'outTimeSeries', function () {
      return [this.inTimeSeries.get('data'), this.outTimeSeries.get('data')];
    }),
    chartTimeSeries: (0, _computed.collect)('inTimeSeries', 'outTimeSeries'),
    chartContentData: (0, _object.computed)('chartTimeSeries', function () {
      return _object.default.create({
        chartTimeSeries: this.chartTimeSeries,
        component: this
      });
    }),
    summaryData: (0, _object.computed)('inTimeSeries', 'outTimeSeries', function () {
      return _object.default.create({
        inMin: this.minValues[0],
        outMin: this.minValues[1],
        inMax: this.maxValues[0],
        outMax: this.maxValues[1],
        inUnit: this.inDataConversion['key'],
        outUnit: this.outDataConversion['key'],
        format: this.valueFormat
      });
    })
  });

  _exports.default = _default;
});