define("dashboard/templates/components/account/billing/declined-invoices-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wD+m0qrd",
    "block": "[[[11,\"form\"],[24,0,\"pay-now allow-if-delinquent\"],[4,[38,0],[[30,0],\"verifyAccount\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n\\n\"],[41,[30,0,[\"invoicesWithFailedAttempt\"]],[[[1,\"    \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"flash-message alert alert-danger\"],[12],[1,\"\\n        There was an error and we couldn't process the selected invoices below.\\n        Please retry or contact support\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"invoices\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,4],null,[[\"invoice\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n    \"],[10,\"hr\"],[12],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Total to pay now:\\n      \"],[10,\"b\"],[14,0,\"invoice-total\"],[12],[1,[28,[35,5],[[30,0,[\"selectedTotal\"]]],[[\"unit\"],[\"cents\"]]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[15,\"disabled\",[30,0,[\"isSaving\"]]],[12],[1,\"\\n        Review Credit Card and Pay\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showVerify\"]],[[[1,\"  \"],[1,[28,[35,6],null,[[\"onClose\",\"organization\",\"willCharge\",\"onVerified\"],[[28,[37,0],[[30,0],\"closeVerifyModal\"],null],[30,0,[\"organization\"]],true,[30,0,[\"onVerified\"]]]]]],[1,\"\\n\"]],[]],null]],[\"invoice\"],false,[\"action\",\"if\",\"each\",\"-track-array\",\"account/billing/declined-invoice-row\",\"currency-format\",\"verify-account\"]]",
    "moduleName": "dashboard/templates/components/account/billing/declined-invoices-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});