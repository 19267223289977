define("dashboard/components/pipeline/app-setup-status", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex'],
    appSetupId: (0, _object.computed)('reviewApp.appSetup', function () {
      return this.get('reviewApp.appSetup.id');
    }),
    newReviewAppsNotEnabled: (0, _computed.not)('pipeline.hasNewReviewAppsEnabled'),
    isShowingFailedStatus: (0, _computed.and)('reviewApp.isErrored', 'newReviewAppsNotEnabled')
  });

  _exports.default = _default;
});