define("dashboard/components/datastore/private-link/allowed-accounts", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    currentAllowListAccount: null,
    isAddingAllowList: (0, _computed.equal)('currentAllowListAccount', 'add'),
    slidePanelTitle: (0, _object.computed)('isAddingAllowList', function () {
      return this.isAddingAllowList ? 'Add Allowed AWS Account IDs' : 'Remove Allowed AWS Account ID';
    }),
    allowListAccountInput: (0, _object.computed)('currentAllowListAccount', 'isAddingAllowList', {
      get() {
        if (this.isAddingAllowList) {
          return '';
        } else {
          return this.currentAllowListAccount.arn;
        }
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      async addAllowedAccount(account, dismiss) {
        if (this.privateLink.get('content')) {
          await this.privateLink.get('content').addAllowedAccount(account);
        } else {
          await this.privateLink.addAllowedAccount(account);
        } // This just does a reload on the private link -- it will not poll
        // since we know the private link is fully operational


        await this.kickOffPrivateLinkPolling();
        dismiss();
      },

      async removeAllowedAccount(account, dismiss) {
        if (this.privateLink.get('content')) {
          await this.privateLink.get('content').removeAllowedAccount(account);
        } else {
          await this.privateLink.removeAllowedAccount(account);
        } // This just does a reload on the private link -- it will not poll
        // since we know the private link is fully operational


        await this.kickOffPrivateLinkPolling();
        dismiss();
      }

    }
  });

  _exports.default = _default;
});