define("dashboard/components/ci/test-run-actions", ["exports", "@ember/service", "@ember/component"], function (_exports, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    classNames: ['ci-actions'],
    actions: {
      toggleDebugModal() {
        this.toggleProperty('isDebugModalVisible');
        const action = this.isDebugModalVisible ? 'Revealed' : 'Hidden';
        this.analytics.logEvent('Debug Modal', action);
      }

    }
  });

  _exports.default = _default;
});