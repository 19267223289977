define("dashboard/models/production-check/postgres-production", ["exports", "dashboard/mixins/production-check", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _productionCheck, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    accountFeatures: (0, _service.inject)('account-features'),
    devCenterURL: 'https://devcenter.heroku.com/articles/production-check#production-tier-database',
    title: 'Production Postgres Database',
    skippedMessage: 'No postgres database.',
    failedMessage: (0, _object.computed)(function () {
      return `Using an Essential Tier Postgres database. Upgrade to a Standard or Premium Tier database for production-grade availability.`;
    }),
    postgresAttachments: (0, _computed.filterBy)('app.attachments', 'isPostgres', true),
    postgresAddons: (0, _computed.mapBy)('postgresAttachments', 'addon'),
    postgresPlans: (0, _computed.mapBy)('postgresAddons', 'embeddedAddonServicePlan'),
    postgresPlanNames: (0, _computed.mapBy)('postgresPlans', 'name'),
    hasPostgresAddons: (0, _computed.notEmpty)('postgresAddons'),
    hasAnyProductionPostgres: (0, _object.computed)('postgresPlanNames', function () {
      return this.get('postgresPlanNames').some(plan_name => !new RegExp(/hobby|basic|dev/).test(plan_name));
    }),
    status: (0, _object.computed)('hasAnyProductionPostgres', 'hasPostgresAddons', function () {
      if (this.hasAnyProductionPostgres) {
        return 'passed';
      } else if (this.hasPostgresAddons) {
        return 'failed';
      }

      return 'skipped';
    })
  });

  _exports.default = _default;
});