define("dashboard/components/hk-select", ["exports", "@heroku/ember-hk-components/components/hk-select"], function (_exports, _hkSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hkSelect.default;
    }
  });
});