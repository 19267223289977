define("dashboard/mixins/ds-error-monitoring-model", ["exports", "@ember/service", "@ember/object/mixin", "dashboard/utils/anchorify"], function (_exports, _service, _mixin, _anchorify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    dsErrorMonitor: (0, _service.inject)('dsErrorMonitor'),

    becameInvalid() {
      this.dsErrorMonitor.notifyErrorContainer(this);
    },

    errorResponseParser(response) {
      const flatError = response.error || response.message || response['error_message'];

      if (flatError) {
        return [(0, _anchorify.default)(flatError)];
      } else {
        return response.errors && response.errors.map(err => (0, _anchorify.default)(err));
      }
    }

  });

  _exports.default = _default;
});