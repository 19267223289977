define("dashboard/models/build-stream", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "dashboard/models/event-source", "@ember/runloop", "dashboard/utils/ansi-stylize", "@ember/string", "ember"], function (_exports, _object, _computed, _utils, _eventSource, _runloop, _ansiStylize, _string, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _eventSource.default.extend({
    // Output comes in chunks which are not necessarily delimited by newlines.
    // Join it all together and then split on newlines so we can easily render.
    _buffer: (0, _object.computed)(function () {
      return [];
    }),
    _partialLine: (0, _object.computed)(function () {
      return '';
    }),

    updateLines() {
      // YUGE performance gains by wrapping in a run loop; literally 10x
      _runloop.run.next(() => {
        this.lines.pushObjects(this._buffer);
        this.set('_buffer', []);
      });
    },

    messageListener(event) {
      let {
        data: message
      } = event;

      if (event.data === 'Channel is not registered.') {
        return this.close(event.data);
      } // Escape existing html, then stylize ANSI escape codes into stylized HTML


      message = (0, _ansiStylize.ansiStylize)(_ember.default.Handlebars.Utils.escapeExpression(message));
      const strings = message.split('\n');
      const stringCount = strings.length;
      const didNotEndWithNewLine = message[message.length - 1] !== '\n';
      strings.forEach((string, idx) => {
        // carriage return (\r) sends the cursor to the beginning of the same line
        // and overwrites any existing content.
        // We can thus ignore any content that comes before the last carriage return.
        const isLast = idx === stringCount - 1;

        if (isLast && didNotEndWithNewLine) {
          // set aside until more data arrives
          this.set('_partialLine', this._partialLine + string);
        } else {
          const combined = `${this._partialLine}${string}`;
          const afterCarriageReturn = combined.substring(combined.lastIndexOf('\r') + 1);
          const withoutBackspaces = removeBackspaces(afterCarriageReturn);

          if (combined.length > 0) {
            this._buffer.push((0, _string.htmlSafe)(withoutBackspaces));
          }

          this.set('_partialLine', '');
        }
      });
      this.updateLines();
    },

    didFinish: (0, _object.observer)('isFinished', function () {
      // this observer exists to handle the (unlikely?) scenario
      // where the last line lacks a trialing `\n` which
      // would otherwise leave it in the `_partialLine` buffer
      if ((0, _utils.isPresent)(this._partialLine)) {
        this._buffer.push(this._partialLine);

        this.set('_partialLine', '');
      }
    }),
    lines: (0, _object.computed)(function () {
      return [];
    }),
    hasContent: (0, _computed.notEmpty)('lines'),

    maybeOpen() {
      return this.url ? this.open() : this;
    }

  });

  _exports.default = _default;

  function removeBackspaces(string) {
    let buffer = '';
    let backspaceCount = 0;

    for (let i = 0; i < string.length; i++) {
      const char = string.charAt(i);

      if (char === '\b') {
        // don't add backspaces to the final string
        backspaceCount++;
      } else {
        if (backspaceCount > 0) {
          // delete characters from the end of the string equivalent
          // to the number of backspaces we've encountered
          buffer = buffer.slice(0, backspaceCount * -1); // reset the backspace count so we don't delete more than
          // necessary.

          backspaceCount = 0;
        } // add this character to the result.


        buffer += char;
      }
    }

    return buffer;
  }
});