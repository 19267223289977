define("dashboard/templates/components/pipeline/build-modal/title-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KJZnHkWx",
    "block": "[[[41,[30,0,[\"build\",\"isPending\"]],[[[1,\"  Building \"],[10,\"b\"],[12],[1,[30,0,[\"app\",\"name\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"build\",\"isFailed\"]],[[[1,\"  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"failure-ring-28\",24,\"malibu-fill-gradient-dark-gray\"]],null],[1,\"\\n  Failed to build \"],[10,\"b\"],[12],[1,[30,0,[\"app\",\"name\"]]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"build\",\"isSucceded\"]],[[[1,\"  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"success-ring-28\",24,\"malibu-fill-gradient-dark-gray\"]],null],[1,\"\\n  Built \"],[10,\"b\"],[12],[1,[30,0,[\"app\",\"name\"]]],[13],[1,\" successfully\\n\"]],[]],null]],[]]]],[]]]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/build-modal/title-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});