define("dashboard/utils/metrics/unit-converters", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.THOUSAND = _exports.NS_IN_1_MS = _exports.MILLION = _exports.MICRO_IN_1_S = _exports.MICRO_IN_1_MS = _exports.BYTES_IN_1_MB = _exports.BYTES_IN_1_KB = _exports.BYTES_IN_1_GB = void 0;
  _exports.convertRatioToPercentage = convertRatioToPercentage;
  _exports.convertToGB = convertToGB;
  _exports.convertToMB = convertToMB;
  _exports.convertToMs = convertToMs;
  _exports.gb = gb;
  _exports.getScalingBytesConversion = getScalingBytesConversion;
  _exports.mb = mb;
  _exports.ms = ms;
  _exports.numberOfDigits = numberOfDigits;
  _exports.percent = percent;
  _exports.scalingBytes = scalingBytes;
  _exports.scalingInteger = scalingInteger;
  _exports.scalingMicroSeconds = scalingMicroSeconds;
  const {
    map
  } = _object.computed;
  const BYTES_IN_1_KB = Math.pow(2, 10);
  _exports.BYTES_IN_1_KB = BYTES_IN_1_KB;
  const BYTES_IN_1_MB = Math.pow(2, 20);
  _exports.BYTES_IN_1_MB = BYTES_IN_1_MB;
  const BYTES_IN_1_GB = Math.pow(2, 30);
  _exports.BYTES_IN_1_GB = BYTES_IN_1_GB;
  const NS_IN_1_MS = Math.pow(10, 6);
  _exports.NS_IN_1_MS = NS_IN_1_MS;
  const MICRO_IN_1_MS = Math.pow(10, 3);
  _exports.MICRO_IN_1_MS = MICRO_IN_1_MS;
  const MICRO_IN_1_S = Math.pow(10, 6);
  _exports.MICRO_IN_1_S = MICRO_IN_1_S;
  const MILLION = Math.pow(10, 6);
  _exports.MILLION = MILLION;
  const THOUSAND = Math.pow(10, 3);
  _exports.THOUSAND = THOUSAND;

  function mb(_ref) {
    let [timestamp, memoryInBytes] = _ref;
    return [timestamp, memoryInBytes / BYTES_IN_1_MB];
  }

  function gb(_ref2) {
    let [timestamp, memoryInBytes] = _ref2;
    return [timestamp, memoryInBytes / BYTES_IN_1_GB];
  }

  function ms(_ref3) {
    let [timestamp, timeInNs] = _ref3;
    return [timestamp, timeInNs / NS_IN_1_MS];
  }

  function percent(_ref4) {
    let [timestamp, ratio] = _ref4;
    return [timestamp, ratio * 100];
  }

  function convertToMB(datums) {
    return map(datums, mb);
  }

  function convertToGB(datums) {
    return map(datums, gb);
  }

  function convertToMs(datums) {
    return map(datums, ms);
  }

  function convertRatioToPercentage(datums) {
    return map(datums, percent);
  }

  function scalingMicroSeconds(datums) {
    const maxMicroSeconds = Math.max(...datums.map(_ref5 => {
      let [, time] = _ref5;
      return time;
    })); // allow for values slightly above the unit boundary without changing the units

    const boundaryMultiplier = 1.5; // for some reason if you set the unit to 'µs' rather than 'us', the chart
    // component changes it to Ms along the way. We need to fix this somehow.

    if (maxMicroSeconds < MICRO_IN_1_MS * boundaryMultiplier) {
      return {
        key: 'us',
        conversion: 1
      };
    } else if (maxMicroSeconds < MICRO_IN_1_S * boundaryMultiplier) {
      return {
        key: 'ms',
        conversion: MICRO_IN_1_MS
      };
    } else {
      return {
        key: 's',
        conversion: MICRO_IN_1_S
      };
    }
  }

  function scalingInteger(datums) {
    const max = Math.max(...datums.map(_ref6 => {
      let [, int] = _ref6;
      return int;
    }));
    const length = Math.ceil(Math.log10(max));

    if (length <= 4) {
      return {
        key: null,
        conversion: 1
      };
    } else if (length <= 7) {
      return {
        key: 'k',
        conversion: THOUSAND
      };
    } else {
      return {
        key: 'm',
        conversion: MILLION
      };
    }
  }

  function getScalingBytesConversion(datums) {
    return (0, _object.computed)(datums, function () {
      return scalingBytes(this.get(datums));
    });
  }

  function numberOfDigits(number) {
    return Math.log(number) * Math.LOG10E + 1 | 0;
  }

  function scalingBytes(datums) {
    const unitMap = {
      B: {
        frequency: 0,
        key: 'B',
        conversion: 1
      },
      KB: {
        frequency: 0,
        key: 'KB',
        conversion: BYTES_IN_1_KB
      },
      MB: {
        frequency: 0,
        key: 'MB',
        conversion: BYTES_IN_1_MB
      },
      GB: {
        frequency: 0,
        key: 'GB',
        conversion: BYTES_IN_1_GB
      }
    }; // Create frequency object to see what our scale should be

    const unitFrequency = datums.reduce(frequency, unitMap); // Find the conversion unit that makes the most sense (Look for the most frequent in this range)

    const conversionUnitKey = Object.keys(unitFrequency).reduce((acc, currentVal) => {
      if (unitFrequency[acc]['frequency'] > unitFrequency[currentVal]['frequency']) {
        return acc;
      } else {
        return currentVal;
      }
    });
    return unitFrequency[conversionUnitKey];
  }

  function frequency(acc, _ref7) {
    let [, bytes] = _ref7;

    if (bytes < BYTES_IN_1_KB) {
      acc['B']['frequency'] += 1;
      return acc;
    } else if (bytes >= BYTES_IN_1_KB && bytes < BYTES_IN_1_MB) {
      acc['KB']['frequency'] += 1;
      return acc;
    } else if (bytes >= BYTES_IN_1_MB && bytes < BYTES_IN_1_GB) {
      acc['MB']['frequency'] += 1;
      return acc;
    } else {
      acc['GB']['frequency'] += 1;
      return acc;
    }
  }
});