define("dashboard/components/metrics/charts/zone-elements/zone", ["exports", "@ember/utils", "@ember/object", "@ember/component"], function (_exports, _utils, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'g',
    classNames: ['metrics__timeseries-chart__zone'],
    attributeBindings: ['transform'],
    x: (0, _object.computed)('_internalStart', 'timeScale', function () {
      return this.get('timeScale')(this._internalStart);
    }),
    _internalStart: (0, _object.computed)('start', 'minTime', function () {
      return this.start < this.minTime ? this.minTime : this.start;
    }),
    _internalEnd: (0, _object.computed)('end', 'maxTimeExtended', function () {
      const rawEnd = this.end;
      const maxTime = this.maxTimeExtended;

      if ((0, _utils.isBlank)(rawEnd) || rawEnd > maxTime) {
        return maxTime;
      } else {
        return rawEnd;
      }
    }),
    xEnd: (0, _object.computed)('_internalEnd', 'timeScale', function () {
      return this.get('timeScale')(this._internalEnd);
    }),
    transform: (0, _object.computed)('x', function () {
      return `translate(${this.x}, 0)`;
    }),
    iconGroupOffset: (0, _object.computed)('height', function () {
      return this.height + 1;
    }),
    isShowingIcon: (0, _object.computed)('start', 'minTime', function () {
      return this.start >= this.minTime;
    }),
    width: (0, _object.computed)('x', 'xEnd', function () {
      let raw = this.xEnd - this.x;

      if (raw <= 0) {
        // ensure the the overlay is always visible
        raw = 1;
      }

      return raw;
    })
  });

  _exports.default = _default;
});