define("dashboard/controllers/account/applications/new-client", ["exports", "@ember/controller", "ember-changeset", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _emberChangeset, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['success'],
    success: null,
    analytics: (0, _service.inject)(),
    name: (0, _computed.alias)('model.name'),
    redirectUri: (0, _computed.alias)('model.redirectUri'),
    changeset: (0, _object.computed)('model', function () {
      const changeset = new _emberChangeset.default(this.model);
      return changeset;
    }),
    actions: {
      save() {
        this.changeset.save().then(() => {
          this.analytics.logEvent('API Client', 'Created');
          this.set('success', true);
        });
      },

      abort() {
        this.transitionToRoute('account.applications');
      }

    }
  });

  _exports.default = _default;
});