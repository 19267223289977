define("dashboard/utils/metrics/data-refresh", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadRefreshedData = loadRefreshedData;
  _exports.updateTimeCalculations = updateTimeCalculations;

  /*
   *
   * We manually poll data from the metrics page during auto-refresh. In order to
   * present a seamless "update" experience and avoid visual blips while the data
   * is pending during an update, we use an observer to update the data once it is
   * fulfilled.
   *
   */
  function loadRefreshedData(refreshingKey, loadedKey) {
    return (0, _object.observer)(`${refreshingKey}.isFulfilled`, function () {
      if (this.get(`${refreshingKey}.isFulfilled`)) {
        this.set(loadedKey, this.get(refreshingKey));
      }
    });
  } // Manually update the calculated start and end times when new data arrives


  function updateTimeCalculations(dataKey, startKey, endKey) {
    return (0, _object.observer)(dataKey, function () {
      this.notifyPropertyChange(startKey);
      this.notifyPropertyChange(endKey);
    });
  }
});