define("dashboard/models/team-permission", ["exports", "dashboard/mixins/permissions", "@ember/object", "@ember-data/model"], function (_exports, _permissions, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_permissions.default, {
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    // A superficial score indicating the permission's power. Used for sorting.
    permissiveness: (0, _object.computed)('name', function () {
      const order = ['view', 'deploy', 'operate', 'manage'];
      let rank = order.indexOf(this.name); // rank unknown permissions as slightly less than the most permissive.

      if (rank === -1) {
        rank = order.length - 1.5;
      }

      return parseInt((rank + 1) * 10);
    })
  });

  _exports.default = _default;
});