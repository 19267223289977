define("dashboard/components/org/resources-table", ["exports", "@ember/object/computed", "@ember/component", "@ember/object"], function (_exports, _computed, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sort
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['limit-width'],
    sortBy: ['dynoUnits:desc'],
    sortedApps: sort('organization.resourceItems', 'sortBy'),
    filteredApps: (0, _object.computed)('sortedApps', 'searchTerm', function () {
      const regexp = new RegExp(this.searchTerm, 'i');
      return this.sortedApps.filter(app => {
        return regexp.test(app.get('name'));
      });
    }),
    expandedItems: (0, _computed.filterBy)('sortedApps', 'isExpanded'),
    isExpanded: (0, _object.computed)('expandedItems', function () {
      return this.expandedItems.length;
    }),
    toggleButtonText: (0, _object.computed)('isExpanded', function () {
      return this.isExpanded ? 'Collapse all' : 'Expand all';
    }),
    actions: {
      toggleAll() {
        const newValue = !this.isExpanded;
        this.sortedApps.forEach(item => {
          if (item.get('hasSpend')) {
            item.set('isExpanded', newValue);
          }
        });
      }

    }
  });

  _exports.default = _default;
});