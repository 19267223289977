define("dashboard/templates/components/datastore/postgres/reset-database", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4dZl/Tio",
    "block": "[[[10,0],[14,0,\"flex justify-between items-center pv4 ph0 ma0 bb b--light-silver\"],[12],[1,\"\\n  \"],[10,\"header\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"normal purple f3 mt0 mb0\"],[12],[1,\"Reset Database\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"lh-copy dark-gray mv4\"],[12],[1,\"Reset the database to its originally-provisioned state, deleting all data inside it.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],[[24,0,\"hk-button--danger\"]],[[\"@query\"],[[28,[37,1],null,[[\"reset\"],[\"true\"]]]]],[[\"default\"],[[[[1,\"Reset Database...\"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"reset\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@datastore\",\"@closeReset\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]],null]],[\"@datastore\",\"@closeReset\"],false,[\"link-to\",\"hash\",\"if\",\"datastore/postgres/reset-database-slide-panel\"]]",
    "moduleName": "dashboard/templates/components/datastore/postgres/reset-database.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});