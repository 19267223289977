define("dashboard/components/account/non-app-authorizations-list-item", ["exports", "@ember/object", "dashboard/components/purple-list-row"], function (_exports, _object, _purpleListRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _purpleListRow.default.extend({
    item: alias('authorization'),
    attributeBindings: ['data-test-target'],
    displayScope: (0, _object.computed)('authorization.scope', function () {
      return this.get('authorization.scope').join(', ');
    })
  });

  _exports.default = _default;
});