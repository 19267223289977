define("dashboard/components/metrics/app-guidance/performance-degradation", ["exports", "@ember/object", "dashboard/components/metrics/app-guidance/item"], function (_exports, _object, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    tagName: 'metrics-app-guidance-performance-degradation',
    title: 'Response time degraded',
    hasSignificantPerc95Change: (0, _object.computed)('significantPercChangeLimit', 'meanPerc95ChangeRate', function () {
      return this.meanPerc95ChangeRate >= this.significantPercChangeLimit;
    }),
    hasSignificantMedianChange: (0, _object.computed)('significantPercChangeLimit', 'meanMedianChangeRate', function () {
      return this.meanMedianChangeRate >= this.significantPercChangeLimit;
    }),
    description: (0, _object.computed)('meanMedianChangeRate', 'meanPerc95ChangeRate', 'hasSignificantMedianChange', 'hasSignificantPerc95Change', function () {
      const hasSigMedian = this.hasSignificantMedianChange;
      const hasSigPerc95 = this.hasSignificantPerc95Change;
      const median = this.meanMedianChangeRate;
      const p95 = this.meanPerc95ChangeRate;
      let text = 'Compared to this period last week, ';

      if (hasSigMedian) {
        text += `your average median response time has increased ${median}%`;
      }

      if (hasSigMedian && hasSigPerc95) {
        text += ', and ';
      }

      if (hasSigPerc95) {
        text += `your average 95th percentile response time has increased ${p95}%`;
      }

      return text;
    })
  });

  _exports.default = _default;
});