define("dashboard/models/addon-region-capability", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    supportsPrivateNetworking: (0, _model.attr)('boolean'),
    supports_private_networking: (0, _computed.alias)('supportsPrivateNetworking'),
    addonService: (0, _model.belongsTo)('addon-service'),
    region: (0, _model.attr)()
  });

  _exports.default = _default;
});