define("dashboard/components/pipeline/access/collaborator-manager", ["exports", "@ember/component", "ember-concurrency", "@ember/object", "@ember/object/computed"], function (_exports, _component, _emberConcurrency, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    pipeline: null,
    email: '',
    permissions: null,
    page: 1,
    pageSize: 40,
    permissionsArray: (0, _object.computed)('permissions.[]', function () {
      return this.permissions && this.permissions.length && this.permissions.split(',') || [];
    }),
    total: (0, _computed.readOnly)('collaboratorQuery.lastSuccessful.value.total'),
    totalPages: (0, _object.computed)('total', 'pageSize', function () {
      return Math.ceil(this.total / this.pageSize);
    }),
    collaboratorQuery: (0, _emberConcurrency.task)(function* () {
      const {
        pipeline,
        page,
        email,
        pageSize,
        permissionsArray: permissions
      } = this; // TODO: Only rerun this query if the params above have changed OR some kind
      // of "force" flag is passed (for cases where we know the underlying dataset
      // has changed such as member added/removed).

      let collaborators = yield pipeline.collaborators;
      collaborators = collaborators.filterBy('isNew', false);
      collaborators = fuzzyFilterOn(collaborators, 'email', email);
      collaborators = collaborators.sortBy('email');

      if (permissions) {
        collaborators = collaborators.filter(member => permissions.every(p => member.permissions.includes(p)));
      }

      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const items = collaborators.slice(startIndex, endIndex);
      const total = collaborators.length;
      return {
        items,
        page,
        total
      };
    }).restartable().on('didReceiveAttrs'),
    permissionsButtonContent: (0, _object.computed)('permissionsArray.[]', function () {
      if (this.permissionsArray.length === 0) {
        return 'Permissions';
      }

      if (this.permissionsArray.length === 3) {
        return 'All permissions';
      }

      const perms = this.permissionsArray.invoke('capitalize').join(' and ');
      let noun = 'permission';

      if (this.permissionsArray.length > 1) {
        noun += 's';
      }

      return `${perms} ${noun}`;
    }),
    actions: {
      setEmailFilter(email) {
        this.setProperties({
          email,
          page: 1
        });
      },

      togglePermissionsFilter(perm) {
        const permissionsArray = [...this.permissionsArray];

        if (permissionsArray.includes(perm)) {
          permissionsArray.removeObject(perm);
        } else {
          permissionsArray.addObject(perm);
        }

        const permissions = permissionsArray.join(',');
        this.setProperties({
          permissions,
          page: 1
        });
      },

      clearPermissionsFilter() {
        this.setProperties({
          permissions: '',
          page: 1
        });
      },

      clearAllFilters() {
        this.setProperties({
          email: '',
          permissions: '',
          page: 1
        });
      }

    }
  });

  _exports.default = _default;

  function fuzzyFilterOn(array, key, filterText) {
    filterText = filterText.replace(/[^a-z0-9-]+/gi, '');

    if (filterText.length === 0) {
      return array;
    }

    const filter = filterText.split('').join('.*');
    const regex = new RegExp(filter, 'i');
    return array.filter(item => regex.test(item.get(key)));
  }
});