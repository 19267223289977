define("dashboard/components/metrics/charts/visualizations/go-routine-count", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/aggregate-chart"], function (_exports, _computed, _aggregateChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aggregateChart.default.extend({
    tagName: 'metrics-go-routine-count-chart',
    'data-test-target': 'metrics-go-chart',
    title: 'Go: Number of Goroutines',
    devCenterName: 'language-runtime-metrics-go#number-of-goroutines',
    isBeta: true,
    maxData: (0, _computed.alias)('data.routinesMax'),
    avgData: (0, _computed.alias)('data.routinesAvg'),
    minData: (0, _computed.alias)('data.routinesMin')
  });

  _exports.default = _default;
});