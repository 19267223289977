define("dashboard/routes/app/webhooks/new", ["exports", "dashboard/routes/basic-app", "rsvp", "@ember/service"], function (_exports, _basicApp, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model() {
      const {
        app
      } = this.modelFor('app');
      const webhook = this.store.createRecord('app/webhook', {
        app,
        level: 'notify'
      });
      return (0, _rsvp.hash)({
        webhook,
        includeTypes: app.hasMany('webhookIncludes').reload()
      });
    },

    afterModel() {
      this.analytics.logEvent('Webhook Create Page', 'Viewed');
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('success', null);
    }

  });

  _exports.default = _default;
});