define("dashboard/models/kolkrabbi/github-token", ["exports", "@ember/object/computed", "ember-data/attr", "ember-data/model"], function (_exports, _computed, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    github: (0, _attr.default)(),
    token: (0, _computed.readOnly)('github.token')
  });

  _exports.default = _default;
});