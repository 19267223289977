define("dashboard/templates/team/switchable/rapid-upgrade", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "joId2KBy",
    "block": "[[[8,[39,0],null,[[\"@apps\",\"@addons\",\"@couplings\"],[[30,1,[\"apps\"]],[30,1,[\"addons\"]],[30,1,[\"couplings\"]]]],null],[1,\"\\n\"]],[\"@model\"],false,[\"efp-rapid-upgrade-table\"]]",
    "moduleName": "dashboard/templates/team/switchable/rapid-upgrade.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});