define("dashboard/adapters/user-preference", ["exports", "dashboard/adapters/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    urlForQueryRecord() {
      return `${this.host}/preferences`;
    },

    urlForUpdateRecord() {
      return this.urlForQueryRecord();
    },

    urlForCreateRecord() {
      return this.urlForQueryRecord();
    }

  });

  _exports.default = _default;
});