define("dashboard/models/invoice", ["exports", "@ember/object", "moment", "dashboard/config/environment", "@ember-data/model"], function (_exports, _object, _moment, _environment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    chargesTotal: (0, _model.attr)('number'),
    creditsTotal: (0, _model.attr)('number'),
    number: (0, _model.attr)('number'),
    periodEnd: (0, _model.attr)('date'),
    periodStart: (0, _model.attr)('date'),
    state: (0, _model.attr)('number'),
    total: (0, _model.attr)('number'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    secureId: (0, _model.attr)('string'),
    year: (0, _object.computed)('date', function () {
      return this.date.year();
    }),
    month: (0, _object.computed)('date', function () {
      return this.date.month() + 1;
    }),
    payBalanceRoute: 'account.billing.pay-balance',
    url: (0, _object.computed)('number', function () {
      return `${_environment.default.particleboardUrl}/account/invoices/${this.number}`;
    }),
    date: (0, _object.computed)('periodStart', function () {
      return (0, _moment.default)(this.periodStart).utc();
    }),
    title: (0, _object.computed)('date', function () {
      return this.date.format('MMMM YYYY');
    }),
    isDeclined: (0, _object.computed)('stateString', function () {
      return this.stateString === 'Declined';
    }),
    stateClass: (0, _object.computed)('stateString', function () {
      return this.stateString.toLowerCase().dasherize();
    }),
    stateString: (0, _object.computed)('state', function () {
      const state = this.state;
      const total = this.total;

      switch (state) {
        case 1:
        case 2:
          return total === 0 ? 'No Charge' : 'Paid';

        case -1:
          return 'Declined';

        case 3:
          return 'Void';

        default:
          return total === 0 ? 'Paid' : 'Pending';
      }
    })
  });

  _exports.default = _default;
});