define("dashboard/models/pipeline-promotion-target", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal
  } = _object.computed;

  var _default = _model.default.extend({
    status: (0, _model.attr)('string'),
    app: (0, _model.belongsTo)('app'),
    pipelinePromotion: (0, _model.belongsTo)('pipeline-promotion'),
    isPending: equal('status', 'pending'),
    isSucceeded: equal('status', 'succeeded'),
    isFailed: equal('status', 'failed')
  });

  _exports.default = _default;
});