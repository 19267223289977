define("dashboard/templates/components/metrics/alerting-configuration-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "b8B+ZiAa",
    "block": "[[[11,\"button\"],[16,0,[29,[\"\\n    metrics__alerting-configuration__item__header\\n    bg-transparent\\n    block\\n    bw0\\n    pa3 pl2\\n    tl\\n    w-100\\n    \",[52,[30,0,[\"isOpen\"]],\"bg-near-white\"],\"\\n  \"]]],[16,\"aria-expanded\",[52,[30,0,[\"isOpen\"]],\"true\",\"false\"]],[16,\"aria-controls\",[30,0,[\"elementId\"]]],[4,[38,1],[\"click\",[28,[37,2],[[30,0],\"onHeaderClick\"],null]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"icon icon-toggle \",[52,[30,0,[\"isOpen\"]],\"opened\"]]]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[[29,[\"Click to \",[52,[30,0,[\"isOpen\"]],\"collapse\",\"expand\"],\" item\"]],\"nav-forward-16\",\"fill-purple\",16]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"metrics__alerting-configuration__item__title ml1\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isClosed\"]],[[[1,\"    \"],[10,0],[14,0,\"metrics__alerting-configuration__item__state\"],[12],[1,\"\\n      \"],[1,[30,0,[\"activeState\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isOpen\"]],[[[1,\"  \"],[18,1,[[28,[37,2],[[30,0],\"onHeaderClick\"],null]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"on\",\"action\",\"malibu-icon\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/metrics/alerting-configuration-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});