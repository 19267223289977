define("dashboard/mixins/serializers/incident", ["exports", "@ember/object/mixin", "ember-data"], function (_exports, _mixin, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;

  var _default = _mixin.default.create(EmbeddedRecordsMixin, {
    attrs: {
      updates: {
        deserialize: 'records'
      }
    },

    transformIncidentHash(hash) {
      const transformedHash = {
        title: hash.title,
        url: hash.full_url,
        id: hash.id,
        resolved: hash.resolved,
        tags: hash.tags,
        updates: hash.updates
      };
      return transformedHash;
    }

  });

  _exports.default = _default;
});