define("dashboard/services/current", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "dashboard/utils/custom-computed"], function (_exports, _service, _object, _computed, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    account: null,
    accountId: (0, _computed.readOnly)('account.id'),
    routerStateParams: (0, _computed.readOnly)('router._router.targetState.routerJsState.params'),
    teamName: (0, _object.computed)('routerStateParams', function () {
      return this.get('routerStateParams.team.team_name');
    }),
    team: (0, _object.computed)('teamName', function () {
      const teamName = this.teamName;

      if (teamName) {
        return this.store.peekAll('team').findBy('name', teamName);
      }
    }),
    enterpriseAccountName: (0, _object.computed)('routerStateParams', 'team.enterpriseAccount.name', function () {
      // Get the EA from the path if we're under an EA route, or from the team
      // if we're under an ET route.
      return this.get('routerStateParams.enterprise-account.name') || this.get('team.enterpriseAccount.name');
    }),
    enterpriseAccountMembers: (0, _computed.oneWay)('enterpriseAccount.members'),
    enterpriseAccountMember: (0, _customComputed.findBy)('enterpriseAccountMembers', 'userId', 'accountId'),
    enterpriseAccount: (0, _object.computed)('enterpriseAccountName', function () {
      const eaName = this.enterpriseAccountName;
      return this.store.peekAll('enterprise-account').findBy('name', eaName);
    }),
    teamPermissions: (0, _computed.readOnly)('team.currentPermissions'),
    enterprisePermissions: (0, _computed.readOnly)('enterpriseAccount.currentPermissions')
  });

  _exports.default = _default;
});