define("dashboard/routes/team/switchable/billing", ["exports", "rsvp", "@ember/service", "dashboard/routes/team/base", "dashboard/mixins/direct-to-invoice"], function (_exports, _rsvp, _service, _base, _directToInvoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_directToInvoice.default, {
    analytics: (0, _service.inject)(),

    model() {
      const {
        team
      } = this.modelFor('team');
      return (0, _rsvp.hash)({
        team,
        paymentMethod: this.store.queryRecord('team/payment-method', {
          team: team.get('id')
        }),
        invoices: team.get('invoices')
      });
    },

    afterModel(model) {
      const analyticsNoun = model.team.analyticsNoun;

      if (model.team.isEnterpriseTeam) {
        this.analytics.logEvent(`${analyticsNoun} Billing Page`, 'Viewed');
      } else {
        // we cannot use `team.analyticsNoun` here because we need to match
        // an existing event name; check with Marketing before making changes
        this.analytics.logEvent('Team Billing Page', 'Viewed');
      }

      this._super(model);
    },

    actions: {
      accountVerified() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});