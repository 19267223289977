define("dashboard/templates/components/metrics/charts/zone-elements/alert-zones", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XO+EIxiA",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"alerts\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"start\",\"end\",\"height\",\"timeScale\",\"minTime\",\"maxTimeExtended\"],[[30,1,[\"startedAt\"]],[30,1,[\"endedAt\"]],[30,0,[\"chartInnerHeight\"]],[30,0,[\"timeScale\"]],[30,0,[\"minTime\"]],[30,0,[\"maxTimeExtended\"]]]]]],[1,\"\\n\"]],[1]],null]],[\"alert\"],false,[\"each\",\"-track-array\",\"metrics/charts/zone-elements/zone\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/zone-elements/alert-zones.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});