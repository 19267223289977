define("dashboard/components/org/addons-allowlist-item", ["exports", "@ember/component", "@ember/object", "moment"], function (_exports, _component, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: '',
    appsListVisible: false,
    addedBy: alias('item.addedBy.email'),
    addedAt: (0, _object.computed)(function () {
      return (0, _moment.default)(this.get('item.addedAt')).format('LL');
    }),
    actions: {
      toggleInstalls() {
        this.toggleProperty('appsListVisible');
      },

      removeFromAllowlist() {
        this.onDelete(this.item);
      }

    }
  });

  _exports.default = _default;
});