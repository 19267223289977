define("dashboard/routes/login/callback", ["exports", "@ember/service", "@ember/routing/route", "dashboard/utils/localstorage", "dashboard/utils/oauth"], function (_exports, _service, _route, _localstorage, _oauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)(),

    beforeModel(transition) {
      const state = transition.to.queryParams.state;
      const oldState = (0, _oauth.getState)();
      (0, _oauth.resetState)();

      if (state !== oldState) {
        this.transitionTo('login');
      }

      const code = transition.to.queryParams.code;
      const session = this.session;
      session.authenticate('authenticator:oauth2', null, code).then(() => {
        // This reliably triggers the authentication succeeded event every time. Without this,
        // we sometimes see the app get stuck on this route even if auth has succeeded, e.g. when
        // the dashboard is open in multiple tabs and the session expires (and reauthenticates). In
        // this scenario, simple-auth explicitly chooses not to trigger the authenticationSucceeded
        // event, which, in turn, causes the `sessionAuthenticated` method on the application route
        // to never be called. As a result, we never fully complete the auth process by redirecting
        // back to the route where the re-authentication was originally triggered.
        // For more info, see these files in simple auth
        // application-route-mixin.js -> https://github.com/simplabs/ember-simple-auth/blob/79cba0979ce34b656abd43b2ede94674b33a7310/addon/mixins/application-route-mixin.js#L79
        // internal-session.js -> https://github.com/simplabs/ember-simple-auth/blob/5f2c6366b9e0ea4000aa14f34a593c82265fb1e4/addon/internal-session.js#L160
        session.trigger('authenticationSucceeded');
      }).catch(() => {
        // There's an error authing. Let's go back to home so the process
        // gets restarted and the browser doesn't sit on the callback URL
        // because if the user refreshes that page things won't work.
        const route = _localstorage.default.getSessionItem('redirectTo') || '/';
        this.transitionTo(route);
      });
    }

  });

  _exports.default = _default;
});