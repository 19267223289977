define("dashboard/components/dyno-tier-picker-item", ["exports", "@ember/component", "@ember/object", "@ember/debug", "@ember/utils", "@ember/service", "dashboard/mixins/tooltipable", "@ember/object/computed"], function (_exports, _component, _object, _debug, _utils, _service, _tooltipable, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_tooltipable.default, {
    classNameBindings: [':dyno-tier-picker-item', 'isCurrent', 'isSelectable', 'isCompact', 'tierClass', 'isEcoWithoutSubscription:eco-dyno-tier-option__not-subscribed'],
    analytics: (0, _service.inject)(),
    router: (0, _service.inject)(),
    iconClassName: (0, _object.computed)('tier.name', function () {
      return `icon-tier-${this.get('tier.name')}`;
    }),
    tierClass: (0, _object.computed)('tier.name', function () {
      return `tier-${this.get('tier.name')}`;
    }),
    prices: (0, _computed.mapBy)('tier.sizes', 'price'),
    minimumPrice: (0, _computed.min)('prices'),
    maximumPrice: (0, _computed.max)('prices'),
    displayPrice: (0, _object.computed)('prices.[]', 'tier.name', 'isEcoWithSubscription', 'isEcoWithoutSubscription', 'minimumPrice', 'maximumPrice', function () {
      const prices = this.prices;

      if ((0, _utils.isEmpty)(prices)) {
        (0, _debug.debug)('No prices set for tier');
      } else if (prices.get('length') === 1) {
        if (this.get('tier.name') === 'free') {
          return `$${prices.get('firstObject')}/Month`;
        } else if (this.isEcoWithoutSubscription) {
          return 'Subscribe for $5/Month';
        } else if (this.isEcoWithSubscription) {
          return '$5 for 1000 Dyno Hours/Month';
        } else {
          return `$${prices.get('firstObject')}/Dyno/Month`;
        }
      } else {
        return `$${this.minimumPrice}-$${this.maximumPrice}/Dyno/Month`;
      }
    }),
    isCurrent: (0, _object.computed)('currentTier', 'tier', function () {
      return this.currentTier === this.tier;
    }),
    isEco: (0, _object.computed)('tier.name', function () {
      return this.get('tier.name') === 'eco';
    }),
    isEcoWithSubscription: (0, _object.computed)('isEco', 'subscribedToEco', function () {
      return this.isEco && this.subscribedToEco;
    }),
    isEcoWithoutSubscription: (0, _object.computed)('isEco', 'subscribedToEco', function () {
      return this.isEco && !this.subscribedToEco;
    }),
    isSelectable: (0, _object.computed)('canChange', 'isEcoWithoutSubscription', function () {
      if (this.isEcoWithoutSubscription) {
        return false;
      } else {
        return this.canChange;
      }
    }),

    click() {
      if (this.isSelectable) {
        this.selectTier(this.tier);
      }
    },

    tooltipTitle: (0, _computed.readOnly)('tier.tooltipTitle'),
    tooltipOptions: (0, _object.computed)('tooltipTitle', function () {
      return {
        title: this.tooltipTitle,
        delay: {
          show: 50,
          hide: 50
        },
        selector: '.dyno-tier-picker-item-select__link'
      };
    }),
    actions: {
      transitionToAccountBilling() {
        this.router.transitionTo('account.billing');
      }

    }
  });

  _exports.default = _default;
});