define("dashboard/components/log-stream", ["exports", "@ember/utils", "@ember/runloop", "@ember/component", "@ember/application", "@ember/object", "dashboard/models/log-stream", "dashboard/utils/save-to-file", "jquery"], function (_exports, _utils, _runloop, _component, _application, _object, _logStream, _saveToFile, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  Displays the output from streaming logs and mimics the scroll behaviour of
   *  a terminal, i.e. it scrolls down as new output arrives unless the user has
   *  manually scrolled up.
   *
   *    {{log-stream logSession=logSessionInstance}}
   */
  var _default = _component.default.extend({
    // Properties
    classNames: ['build-stream', 'build-stream-full'],
    autoScroll: true,
    // create a new stream anytime `app` or `filterOn` changes
    // but ensure that the existing stream (if any) is closed
    stream: (0, _object.computed)('app', 'filterOn', function () {
      return _logStream.default.create({
        app: this.app,
        container: (0, _application.getOwner)(this),
        filter: this.filterOn
      });
    }),
    killZombieStream: (0, _object.observer)('app', 'filterOn', function () {
      const zombieStream = this.zombieStream;
      this.set('zombieStream', this.stream);

      if (zombieStream && typeof zombieStream.close === 'function') {
        zombieStream.closeWithoutReconnection();
      }
    }),
    isScrolledToBottom: (0, _object.computed)(function () {
      const $container = this.$scrollContainer;
      const scrollPosition = $container.scrollTop() + $container.innerHeight();
      const scrollHeight = $container[0].scrollHeight;
      return scrollPosition >= scrollHeight;
    }),
    $scrollContainer: (0, _object.computed)(function () {
      return (0, _jquery.default)(this.element).find('.build-stream-output');
    }),
    // Observers
    updateScroll: (0, _object.observer)('stream.lines.[]', function () {
      if (this.autoScroll) {
        (0, _runloop.scheduleOnce)('afterRender', this, this.scrollToBottom);
      }
    }),
    scrollOnCheck: (0, _object.observer)('autoScroll', function () {
      if (this.autoScroll) {
        this.scrollToBottom();
      }
    }),

    // Methods
    scrollToBottom() {
      const $container = this.$scrollContainer;

      if (!(0, _utils.isEmpty)($container)) {
        $container.scrollTop($container[0].scrollHeight);
      }
    },

    // Setup/teardown
    didInsertElement() {
      this._super(...arguments);

      this.set('zombieStream', this.stream);
    },

    didRenderElement() {
      const notifyChange = this.notifyPropertyChange.bind(this, 'isScrolledToBottom');
      this.$scrollContainer.on('scroll', notifyChange);
      this.scrollToBottom();
    },

    willDestroyElement() {
      const $container = this.$scrollContainer;

      if ($container) {
        $container.off('scroll');
      }

      const stream = this.stream;

      if (stream && typeof stream.close === 'function') {
        stream.closeWithoutReconnection();
      } // wipe reference to cached $


      this.set('$scrollContainer', null);

      this._super(...arguments);
    },

    actions: {
      saveLogs() {
        const filename = `${this.get('app.name')}-logs-${Date.now()}.txt`;
        const mimeType = 'data:attachment/text';
        const lines = this.get('stream.lines');
        const buffer = lines.map(l => l.raw).join('\r\n');
        (0, _saveToFile.default)(filename, mimeType, buffer);
      }

    }
  });

  _exports.default = _default;
});