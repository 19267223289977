define("dashboard/templates/components/metrics/time-check", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Njgy4zx8",
    "block": "[[[41,[30,0,[\"isOutOfSync\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      It appears that your local computer's current time is more than 5 minutes out of sync with the \"],[10,3],[14,6,\"http://www.timeanddate.com/worldclock/\"],[14,\"target\",\"_blank\"],[14,0,\"hk-link\"],[12],[1,\"actual time\"],[13],[1,\".\\n      Please ensure that your operating system is correctly syncing its time with an NTP server.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "dashboard/templates/components/metrics/time-check.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});