define("dashboard/components/metrics/chart-controls/language-metrics-setup", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    'data-test-target': 'language-metrics-setup-instructions',
    setupDescription: (0, _object.computed)('app.isEligibleForLanguageMetrics', 'justEnabledLanguageMetrics', function () {
      if (this.get('app.isEligibleForLanguageMetrics')) {
        if (this.justEnabledLanguageMetrics) {
          return "It may take a few minutes for your app to begin receiving metrics. If you haven't already, please follow the ";
        } else {
          return "We have not yet received language metrics from your application. If you haven't already, please follow the ";
        }
      } else {
        return 'We currently only support Enhanced Language Metrics for Java, Node.js, Ruby, and Go. For more information, check the';
      }
    }),
    setupLinkText: (0, _object.computed)('app.isEligibleForLanguageMetrics', function () {
      if (this.get('app.isEligibleForLanguageMetrics')) {
        return 'setup instructions';
      } else {
        return 'documentation';
      }
    }),
    setupLink: (0, _object.computed)('app.name', 'app.isEligibleForMultipleLanguages', 'app.isEligibleForJvmMetrics', 'app.isEligibleForGoMetrics', 'app.isEligibleForRubyMetrics', 'app.isEligibleForNodeMetrics', function () {
      if (this.get('app.isEligibleForMultipleLanguages')) {
        return 'https://devcenter.heroku.com/articles/language-runtime-metrics#getting-started';
      } else if (this.get('app.isEligibleForJvmMetrics')) {
        return 'https://devcenter.heroku.com/articles/language-runtime-metrics-jvm#getting-started';
      } else if (this.get('app.isEligibleForGoMetrics')) {
        return 'https://devcenter.heroku.com/articles/language-runtime-metrics-go#getting-started';
      } else if (this.get('app.isEligibleForRubyMetrics')) {
        return 'https://devcenter.heroku.com/articles/language-runtime-metrics-ruby#getting-started';
      } else if (this.get('app.isEligibleForNodeMetrics')) {
        return 'https://devcenter.heroku.com/articles/language-runtime-metrics-nodejs#getting-started';
      } else {
        return 'https://devcenter.heroku.com/articles/language-runtime-metrics';
      }
    })
  });

  _exports.default = _default;
});