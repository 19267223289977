define("dashboard/components/pipeline/create-review-app-from-branch", ["exports", "@ember/component", "dashboard/mixins/fetch-github-branches", "@ember/object/computed", "@ember/object", "@ember/utils", "dashboard/helpers/lazy-property", "@ember/service"], function (_exports, _component, _fetchGithubBranches, _computed, _object, _utils, _lazyProperty, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_fetchGithubBranches.default, {
    tagName: 'li',
    classNames: ['pipeline-app', 'pr-app', 'pt1 pb1 ph1'],
    accountFeatures: (0, _service.inject)(),
    branches: (0, _lazyProperty.default)(function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.get('pipeline.connectedBranches');
      } else {
        return this.fetchBranches(this.get('pipeline.githubRepository.name'));
      }
    }),
    branchesWithoutReviewApps: (0, _object.computed)('branches.[]', 'reviewApps.[]', function () {
      const branches = this.branches;

      if ((0, _utils.isEmpty)(branches)) {
        return [];
      }

      const reviewApps = this.reviewApps;

      if ((0, _utils.isEmpty)(this.reviewApps)) {
        return branches;
      }

      return branches.reject(el => {
        return reviewApps.mapBy('branch').includes(el.get('name'));
      });
    }),
    selectedBranch: (0, _computed.reads)('branchesWithoutReviewApps.firstObject'),
    noValidFeatureBranches: (0, _computed.empty)('branchesWithoutReviewApps'),
    createReviewAppIsDisabled: (0, _computed.or)('noValidFeatureBranches', 'isCreatingReviewApp'),
    actions: {
      createReviewApp() {
        this.set('isCreatingReviewApp', true);
        const branches = this.branches || [];
        const selectedBranch = branches.filterBy('name', this.selectedBranch.name).firstObject;
        const {
          name,
          commit
        } = selectedBranch;

        const reviewApp = _object.default.create({
          branch: name,
          sha: commit.sha
        });

        return this.createReviewApp(reviewApp);
      }

    }
  });

  _exports.default = _default;
});