define("dashboard/components/efp-rapid-upgrade-table-free-addon-row", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfpRapidUpgradeTableFreeAddonRowComponent = (_class = class EfpRapidUpgradeTableFreeAddonRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "upgradePlanName", _descriptor, this);

      _initializerDefineProperty(this, "upgradePlanHourlyCost", _descriptor2, this);

      _initializerDefineProperty(this, "upgradePlanMonthlyCost", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      this.upgradePlanName = 'Mini';

      if (this.args.addon.isPostgres) {
        this.upgradePlanHourlyCost = '~$0.007';
        this.upgradePlanMonthlyCost = '+$5.00';
      } else {
        this.upgradePlanHourlyCost = '~$0.004';
        this.upgradePlanMonthlyCost = '+$3.00';
      }
    }

    get addon() {
      return this.args.addon;
    }

    get addonServiceName() {
      return this.addon.providerName;
    }

    get addonServiceFullName() {
      return `${this.addonServiceName}:${this.upgradePlanName.toLowerCase()}`;
    }

    get iconName() {
      if (this.args.addon.isPostgres) {
        return 'data-postgres-16';
      } else {
        return 'data-redis-16';
      }
    }

    get viewAllPlansLink() {
      if (this.args.addon.isPostgres) {
        return 'https://elements.heroku.com/addons/heroku-postgresql';
      } else {
        return 'https://elements.heroku.com/addons/heroku-redis';
      }
    }

    get choosingRightPlanLink() {
      if (this.args.addon.isPostgres) {
        return 'https://devcenter.heroku.com/articles/heroku-postgres-plans';
      } else {
        return 'https://devcenter.heroku.com/articles/heroku-redis-technical-characterization';
      }
    }

    updateUpgradePlanInformation(planTier) {
      if (this.args.addon.isPostgres) {
        if (planTier === 'Basic') {
          this.upgradePlanName = 'Basic';
          this.upgradePlanHourlyCost = '~$0.013';
          this.upgradePlanMonthlyCost = '+$9.00';
        } else {
          this.upgradePlanName = 'Mini';
          this.upgradePlanHourlyCost = '~$0.007';
          this.upgradePlanMonthlyCost = '+$5.00';
        }
      } else {
        this.upgradePlanName = 'Mini';
        this.upgradePlanHourlyCost = '~$0.004';
        this.upgradePlanMonthlyCost = '+$3.00';
      }
    }

    redirectToResourcesPage() {
      this.router.transitionTo('app.resources', this.args.addon.get('appName'));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "upgradePlanName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "upgradePlanHourlyCost", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "upgradePlanMonthlyCost", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateUpgradePlanInformation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateUpgradePlanInformation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToResourcesPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToResourcesPage"), _class.prototype)), _class);
  _exports.default = EfpRapidUpgradeTableFreeAddonRowComponent;
});