define("dashboard/components/metrics/charts/connected-chart-hover/item/generic", ["exports", "dashboard/components/metrics/charts/connected-chart-hover/item", "@ember/object/computed"], function (_exports, _item, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    title: (0, _computed.reads)('graph.name'),
    description: (0, _computed.reads)('graph.description'),
    swatchColor: (0, _computed.reads)('graph.color')
  });

  _exports.default = _default;
});