define("dashboard/services/location", ["exports", "@ember/service", "dashboard/config/environment"], function (_exports, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A wrapper around window.location.replace and window.location.assign.
   * This gives us the ability to 1) Spy on and test offsite redirects, and
   * 2) Prevent 'full page reload' errors during tests by preventing the browser
   * from executing the redirect.
   *
   * @module Location
   */
  function wrap(name) {
    if (_environment.default.environment === 'test') {
      return function () {};
    } else {
      return function (url) {
        window.location[name](url);
      };
    }
  }

  var _default = _service.default.extend({
    assign: wrap('assign'),
    replace: wrap('replace'),
    reload: wrap('reload')
  });

  _exports.default = _default;
});