define("dashboard/components/account/deauthorize-third-party-modal", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/helpers/array-group-by"], function (_exports, _component, _object, _computed, _arrayGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['text-center'],
    isShowingMore: false,
    isNotShowingMore: (0, _computed.not)('isShowingMore'),
    gtFiveAppLinks: (0, _computed.gt)('filteredAppLinks.length', 5),
    renderShowMoreLink: (0, _computed.and)('gtFiveAppLinks', 'isNotShowingMore'),
    filteredAppLinks: (0, _object.computed)('appLinks.[]', function () {
      const appLinks = this.appLinks;

      if (!appLinks) {
        return;
      }

      return (0, _arrayGroupBy.default)(appLinks, 'repoId').reduce((links, group) => {
        const groupLinks = group.get('items');
        const link = groupLinks.findBy('autoDeploy', true) || groupLinks[0];
        link.set('additionalLinkCount', groupLinks.length - 1);
        link.set('hasAdditionalLinks', groupLinks.length > 1);
        links.pushObject(link);
        return links;
      }, []).sortBy('repo');
    }),
    displayedAppLinks: (0, _object.computed)('isShowingMore', 'filteredAppLinks.[]', function () {
      const links = this.filteredAppLinks;

      if (!links) {
        return;
      }

      if (this.isShowingMore) {
        return links;
      }

      return links.slice(0, 5);
    }),
    actions: {
      showAll() {
        this.set('isShowingMore', true);
      }

    }
  });

  _exports.default = _default;
});