define("dashboard/helpers/trusted-html", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function trustedHtml(_ref) {
    let [string] = _ref;
    return (0, _template.htmlSafe)(string);
  });

  _exports.default = _default;
});