define("dashboard/components/net-promoter-score/survey", ["exports", "@ember/component", "dashboard/mixins/multi-step-flow", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/mixins/preferences", "@ember/utils", "dashboard/utils/localstorage"], function (_exports, _component, _multiStepFlow, _object, _computed, _service, _preferences, _utils, _localstorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_multiStepFlow.default, _preferences.default, {
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    classNames: ['fixed', 'z-2', 'bottom-1', 'right-1'],
    'data-test-target': 'nps-survey',
    selectedRating: null,
    isHighRating: (0, _computed.gt)('selectedRating', 7),
    isMinimized: false,
    isOptedOutForever: false,
    isNotOptedOutForever: (0, _computed.not)('isOptedOutForever'),
    isValidSecondStep: (0, _object.computed)('currentStep', 'selectedRating', 'npsSurvey.{whyMultiple,whyDescription}', function () {
      return (0, _utils.isPresent)(this.get('npsSurvey.whyMultiple')) || (0, _utils.isPresent)(this.get('npsSurvey.whyDescription'));
    }),
    isNotValidSecondStep: (0, _computed.not)('isValidSecondStep'),

    didReceiveAttrs() {
      this.set('isMinimized', _localstorage.default.getItem('hk-isNPSSurveyMinimized') === 'true');
    },

    _maybeOptOutForever() {
      if (this.isOptedOutForever) {
        this.analytics.logEvent('NPS', 'Opt Out Forever');
        this.updateUserPreference('npsSurveyDismissal', 'NONE');
      }
    },

    actions: {
      transitionToNextStep() {
        if (this.currentStep === 1) {
          const customerType = this.isHerokai ? 'internal' : this.userType;
          const npsSurvey = this.store.createRecord('npsSurvey', {
            recommendRating: this.selectedRating,
            customerType,
            surveyId: this.surveyId
          });
          this.set('npsSurvey', npsSurvey);
          this.analytics.logEvent('NPS Survey Question One', 'Responded');
          return npsSurvey.save().then(() => {
            this.transitionToStep(this.currentStep + 1);
          });
        } else {
          this.analytics.logEvent(`NPS Survey Question Two`, 'Responded');
          return this.get('npsSurvey').save().then(() => {
            this._maybeOptOutForever();

            this.transitionToStep(this.currentStep + 1);
            this.analytics.logEvent('NPS Survey Completion', 'Viewed');
          });
        }
      },

      skipStep() {
        this.analytics.logEvent(`NPS Survey Question Two`, 'Skipped');
        this.transitionToStep(this.currentStep + 1);

        this._maybeOptOutForever();
      },

      optOut() {
        this.analytics.logEvent('NPS', 'Opt Out');
        return this.updateUserPreference('npsSurveyDismissal', this.surveyId);
      },

      toggleOptOutForever() {
        this.toggleProperty('isOptedOutForever');
      },

      toggleMinimizeSurvey(isMinimized) {
        if (!isMinimized) {
          this.analytics.logEvent('NPS Survey', 'Minimized');
        }

        this.set('isMinimized', !isMinimized);

        _localstorage.default.setItem('hk-isNPSSurveyMinimized', !isMinimized);
      }

    }
  });

  _exports.default = _default;
});