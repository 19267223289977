define("dashboard/components/ci/test-module", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['ci-view--module', 'test-output-module'],
    isShowingByDefault: (0, _computed.or)('isRunning', 'isFailed'),
    isShowingOutput: (0, _computed.oneWay)('isShowingByDefault'),
    isDisabled: (0, _computed.or)('isWaiting', 'didNotRun'),
    actions: {
      showOutput() {
        this.toggleProperty('isShowingOutput');
      }

    }
  });

  _exports.default = _default;
});