define("dashboard/adapters/shogun/postgres-datastore", ["exports", "dashboard/adapters/shogun", "dashboard/config/environment", "@ember/service"], function (_exports, _shogun, _environment, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shogun.default.extend({
    store: (0, _service.inject)(),
    yobukoHost: _environment.default.yobukoUrl,

    pgHost(addonId) {
      const addon = this.store.peekRecord('addon', addonId);
      return addon.isHobby ? this.yobukoHost : this.host;
    },

    urlForFindRecord(id) {
      return `${this.pgHost(id)}/client/v11/databases/${id}`;
    },

    findBelongsTo(_store, snapshot) {
      return this._super(...arguments).then(response => {
        response.id = snapshot.record.addon.get('id');
        return response;
      });
    },

    findHasMany(_store, snapshot) {
      return this._super(...arguments).then(response => {
        if (Array.isArray(response)) {
          const datastoreId = snapshot.record.get('id');
          response.forEach(item => {
            item.datastore = datastoreId;
          });
        }

        return response;
      });
    }

  });

  _exports.default = _default;
});