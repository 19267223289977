define("dashboard/adapters/metrics/datastores/redis", ["exports", "dashboard/config/environment", "dashboard/adapters/metrics"], function (_exports, _environment, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RedisAdapter extends _metrics.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "host", _environment.default.dataUrl);
    }

    queryRecord(_store, _type, query) {
      // Add the query to the response as it's used by the serializer for
      // formatting
      return super.queryRecord(...arguments).then(response => {
        response.queryParams = query;
        return response;
      });
    }

    urlForQueryRecord(query) {
      const queryString = $.param({
        start: query.startTime.toISOString(),
        end: query.endTime.toISOString(),
        step: query.step,
        filter: 'max'
      });
      return `${this.host}/metrics/${query.addonId}/redis?${queryString}`;
    }

  }

  _exports.default = RedisAdapter;
});