define("dashboard/controllers/app/metrics/type", ["exports", "@ember/object", "@ember/controller", "@ember/service", "@ember/object/computed", "dashboard/mixins/dyno-upgrade", "dashboard/mixins/metrics/data-refresh", "dashboard/mixins/preferences", "dashboard/utils/custom-computed", "dashboard/utils/metrics/receiving-language-data"], function (_exports, _object, _controller, _service, _computed, _dynoUpgrade, _dataRefresh, _preferences, _customComputed, _receivingLanguageData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function parseHours(string) {
    string = string || '';
    const matches = string.match(/(\d+)(?=-hours-ago)/g);

    if (matches) {
      return parseInt(matches[0]);
    }
  }

  var _default = _controller.default.extend(_dynoUpgrade.default, _dataRefresh.default, _preferences.default, {
    queryParams: [{
      starting: {
        scope: 'controller'
      }
    }, {
      ending: {
        scope: 'controller'
      }
    }],
    analytics: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    protectedController: (0, _controller.inject)('protected'),
    platformUserPreferences: (0, _computed.alias)('protectedController.model.userPreferences'),
    app: (0, _computed.alias)('model.app'),
    appPermissions: (0, _computed.alias)('model.appPermissions'),
    space: (0, _computed.alias)('model.space'),
    canConfigureAlerts: (0, _computed.alias)('appPermissions.canManageAppFeatures'),
    process: (0, _customComputed.findBy)('app.formations', 'type', 'model.type'),
    _receivingJvmData: (0, _receivingLanguageData.default)('Java', 'java-metrics-set-up'),
    notReceivingJvmData: (0, _computed.not)('_receivingJvmData'),
    jvmMetricsAreSetUpAndNotWorking: (0, _computed.and)('notReceivingJvmData', 'app.hasRuntimeMetricsEnabled', 'app.isEligibleForJvmMetrics'),
    afterHours: (0, _object.computed)('starting', function () {
      return parseHours(this.starting) || 24;
    }),
    beforeHours: (0, _object.computed)('ending', function () {
      return parseHours(this.ending) || 0;
    }),
    timezone: (0, _object.computed)('platformUserPreferences.timezone', function () {
      return this.get('platformUserPreferences.timezone') || 'UTC';
    }),
    monitors: (0, _computed.alias)('process.monitors'),
    last24hoursSelected: (0, _object.computed)('beforeHours', 'afterHours', function () {
      return this.beforeHours === 0 && this.afterHours === 24;
    }),
    dynoLoad: (0, _computed.readOnly)('metrics.dynoLoad'),
    dynoMemory: (0, _computed.readOnly)('metrics.dynoMemory'),
    throughput: (0, _computed.readOnly)('metrics.throughput'),
    languageMemory: (0, _computed.readOnly)('metrics.languageMemory'),
    languageMemoryTest: (0, _computed.readOnly)('metrics.languageMemoryTest'),
    dynoErrors: (0, _computed.readOnly)('metrics.dynoErrors'),
    routerErrors: (0, _computed.readOnly)('metrics.routerErrors'),
    latency: (0, _computed.readOnly)('metrics.latency'),
    routingHealth: (0, _computed.readOnly)('metrics.routingHealth'),
    currentRoutingHealth: (0, _computed.readOnly)('metrics.currentRoutingHealth'),
    // Before fetching the alerts we need to make sure we have the monitors, due to the way
    // we extract the relationship between alerts and monitors.
    alerts: (0, _object.computed)('monitors.isFulfilled', 'process.alerts.[]', function () {
      return this.get('monitors.isFulfilled') ? this.get('process.alerts') : [];
    }),
    resolvedIncidents: (0, _object.computed)('process', function () {
      return this.store.findAll('status/resolved-incident');
    }),
    unresolvedIncidents: (0, _object.computed)('process', function () {
      return this.store.findAll('status/unresolved-incident');
    }),
    refreshingDatasets: (0, _object.computed)('dynoLoad', 'dynoMemory', 'throughput', 'languageMemory', 'languageMemoryTest', 'process.scaleEvents.[]', 'process.restartEvents.[]', 'dynoErrors', 'routerErrors', 'latency', 'alerts', 'resolvedIncidents', 'unresolvedIncidents', 'routingHealth', function () {
      return _object.default.create({
        dynoLoad: this.dynoLoad,
        dynoMemory: this.dynoMemory,
        throughput: this.throughput,
        languageMemory: this.languageMemory,
        languageMemoryTest: this.languageMemoryTest,
        formationChanges: this.get('process.scaleEvents'),
        restarts: this.get('process.restartEvents'),
        dynoErrors: this.dynoErrors,
        routerErrors: this.routerErrors,
        latency: this.latency,
        alerts: this.alerts,
        resolvedIncidents: this.resolvedIncidents,
        unresolvedIncidents: this.unresolvedIncidents,
        routingHealth: this.routingHealth,
        currentRoutingHealth: this.currentRoutingHealth
      });
    }),
    chartLayout: (0, _computed.alias)('dashboardUserPreferences.chartLayout'),
    throughputResolution: (0, _computed.alias)('dashboardUserPreferences.throughputResolution'),
    isAutoRefreshing: (0, _computed.alias)('dashboardUserPreferences.isAutorefreshingMetrics'),
    autoscaleMonitorType: 'scale',
    autoscaleMonitor: (0, _customComputed.findBy)('monitors', 'actionType', 'autoscaleMonitorType'),
    overrideTimeoutDisable: false,
    alertingConfigIsOpen: false,
    alertingConfigPanelName: '',
    spacesAppUsesTrustedIPs: (0, _computed.readOnly)('metrics.privateSpacesPermissions.spacesAppUsesTrustedIPs'),
    cannotAccessSpacesApp: (0, _computed.readOnly)('metrics.privateSpacesPermissions.cannotAccessSpacesApp'),
    spaceMetricsDependenciesPending: (0, _computed.or)('app.isPending', 'space.inboundRuleset.isPending', 'space.isPending'),
    actions: {
      toggleLayoutConfig(layout) {
        this.updateUserPreference('chartLayout', layout);
        this.analytics.logMetricsEvent('Chart Layout', 'Changed', {
          layout
        });
      },

      toggleThroughputResolution(resolution) {
        this.updateUserPreference('throughputResolution', resolution);
        this.analytics.logMetricsEvent('RPM Resolution', 'Set', {
          resolution
        });
      },

      updateTimeframe(beforeHours, afterHours, name) {
        this.set('isRefreshingData', false);
        const starting = `${afterHours}-hours-ago`;
        const ending = `${beforeHours}-hours-ago`;
        this.setProperties({
          starting,
          ending
        });
        this.metrics.updateTimeframe(this.beforeHours, this.afterHours);
        this.updateUserPreference('latestViewedTimeframeMetrics', name);
        this.analytics.logMetricsEvent('Timeframe', 'Changed', {
          name
        });
      },

      toggleAutoRefresh(isAutoRefreshing) {
        this.updateUserPreference('isAutorefreshingMetrics', isAutoRefreshing).finally(() => {
          if (isAutoRefreshing) {
            this._startTimer();
          } else {
            this._stopTimer();
          }
        });
      },

      openAlertingConfig(type) {
        this.set('alertingConfigIsOpen', true);
        this.set('alertingConfigPanelName', type);
      },

      upgradeToProductionDyno() {
        return this.upgradeDyno('production', 'alerting').then(() => {
          if (this.get('process.isWeb')) {
            this.send('openAlertingConfig');
          }
        });
      },

      updateTimezone(timezone) {
        this.platformUserPreferences.update('timezone', timezone);
      },

      chartDataFulfilled() {
        this.set('isRefreshingData', false);
      },

      setLanguageMetricsSetupNotCompleted(value) {
        this.set('languageMetricsSetupNotCompleted', value);
      }

    }
  });

  _exports.default = _default;
});