define("dashboard/controllers/account/billing/pay-balance", ["exports", "@ember/controller", "rsvp", "@ember/object", "@ember/object/computed", "@ember/service", "moment", "dashboard/models/pay-balance"], function (_exports, _controller, _rsvp, _object, _computed, _service, _moment, _payBalance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    title: 'Pay Balance',
    current: (0, _service.inject)(),
    invoices: (0, _computed.alias)('model.invoices'),
    declinedInvoices: (0, _computed.filterBy)('invoices', 'state', -1),
    selectedInvoices: (0, _computed.filterBy)('invoices', 'isSelected', true),
    noDeclinedInvoices: (0, _computed.empty)('declinedInvoices'),
    isDelinquent: (0, _computed.alias)('current.account.isDelinquent'),
    deletionTime: (0, _computed.alias)('model.paymentMethod.delinquency.deletion_time'),
    invoicesWithFailedAttempt: (0, _computed.filterBy)('invoices', 'attemptedPaymentFailed', true),
    deletionAt: (0, _object.computed)('isDelinquent', 'deletionTime', function () {
      const isDelinquent = this.isDelinquent;
      const deletionTime = this.deletionTime;

      if (isDelinquent && deletionTime) {
        return _moment.default.utc(deletionTime).format('[on] MMMM D, YYYY HH:mm [UTC]');
      } else if (isDelinquent) {
        return '28 days after suspension';
      }
    }),
    invoicePayments: (0, _object.computed)('selectedInvoices', function () {
      return this.selectedInvoices.map(invoice => {
        return _payBalance.default.create({
          invoice
        });
      });
    }),
    actions: {
      pay() {
        this.set('isSaving', true);

        _rsvp.default.all(this.invoicePayments.invoke('pay')).then(() => {
          if (this.noDeclinedInvoices) {
            this.transitionToRoute('account.billing');
          }
        }).finally(() => {
          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});