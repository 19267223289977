define("dashboard/templates/components/account/authorizations-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7LOifplW",
    "block": "[[[10,\"td\"],[14,0,\"application\"],[12],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"authorization\",\"baseURL\"]]],[14,\"target\",\"_blank\"],[14,0,\"hk-link purple no-underline flex\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"truncate\"],[12],[1,[30,0,[\"authorization\",\"client\",\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"permissions\"],[12],[1,\"\\n  \"],[1,[30,0,[\"displayScope\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,0],null,[[\"canEdit\",\"canRemove\",\"model\",\"editAction\",\"confirmDeleteAction\"],[false,true,[30,0,[\"authorization\"]],[28,[37,1],[[30,0],\"edit\"],null],[28,[37,1],[[30,0],\"confirmDelete\"],null]]]]],[1,\"\\n\"]],[],false,[\"purple-list-action-cell\",\"action\"]]",
    "moduleName": "dashboard/templates/components/account/authorizations-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});