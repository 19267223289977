define("dashboard/controllers/app/webhooks/edit", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    analytics: (0, _service.inject)(),
    router: (0, _service.inject)(),
    actions: {
      finishEdit() {
        this.analytics.logEvent('Webhook', 'Updated');
        this.router.transitionTo('app.webhooks');
      },

      cancelEdit() {
        this.router.transitionTo('app.webhooks');
      }

    }
  });

  _exports.default = _default;
});