define("dashboard/models/team/payment-method", ["exports", "dashboard/models/payment-method", "ember-data/attr", "rsvp"], function (_exports, _paymentMethod, _attr, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dashboard.TeamPaymentMethod
   * @extends Dashboard.PaymentMethod
   */
  var _default = _paymentMethod.default.extend({
    // set purely to keep track of team in adapter so
    // URLs can be generated.
    // This attr is not sent to nor received by the server.
    team: (0, _attr.default)(),
    analyticsNoun: 'Online Team Payment Method',

    getClientToken() {
      return new _rsvp.Promise((resolve, reject) => {
        this.store.adapterFor('team/payment-method').getClientToken(this.team).then(_ref => {
          let {
            token
          } = _ref;
          return resolve(token);
        }, reject);
      });
    }

  });

  _exports.default = _default;
});