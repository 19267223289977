define("dashboard/services/ds-error-monitor", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // The purpose of this service is to integrate ember-data models
  // with our current global error-monitor service.
  // It is essentially a wrapper around the error-monitor that allows
  // ember-data models to hook into our global flash messages (red bubbles).
  var _default = _service.default.extend({
    errorMonitor: (0, _service.inject)(),
    xhr: null,

    // This is called by the adapter's handleResponse hook to set the xhr
    captureInvalidModelXHR(xhr) {
      this.set('xhr', xhr);
    },

    // This is called by an ember-data model's becameInvalid hook to trigger the error
    // It is important to note that the adapter's handleResponse hook fires
    // BEFORE the model's becameInvalid hook. This is why captureInvalidModelXHR
    // exists.
    notifyErrorContainer(model) {
      // Lookup an existing error container for this model
      let modelErrorContainer = this.get('errorMonitor.errors').findBy('model', model); // If no existing error container, create one

      if (!modelErrorContainer) {
        modelErrorContainer = this.errorMonitor.createErrorContainer({
          model
        });
      } // Grab the xhr that was set by the model's adapter (captureInvalidModelXHR)
      // and set it on the model's error-container to trigger the error


      modelErrorContainer.set('xhr', this.xhr); // Clear the xhr

      this.set('xhr', null);
    }

  });

  _exports.default = _default;
});