define("dashboard/templates/components/metrics/chart-controls/config-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JH3R6nDn",
    "block": "[[[10,0],[14,0,\"flex flex-row justify-between\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"f5 mr2 w-70\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"items-center dark-gray fw6 mb1\"],[12],[1,\"\\n      \"],[1,[30,0,[\"toggleName\"]]],[1,\"\\n\"],[41,[30,0,[\"isBeta\"]],[[[1,\"        \"],[10,1],[14,0,\"hk-badge--beta ml2\"],[12],[1,\"Beta\"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"gray lh-copy\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"onToggle\",\"checked\",\"class\",\"disabled\"],[[28,[37,3],[[30,0],[30,0,[\"toggleAction\"]]],null],[30,0,[\"toggleChecked\"]],\"nudge-down--2\",[30,0,[\"disabled\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\",\"toggle-switch\",\"action\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/config-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});