define("dashboard/adapters/test-run", ["exports", "dashboard/adapters/application", "@ember/utils"], function (_exports, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord(query) {
      const {
        pipeline,
        number
      } = query;

      if ((0, _utils.isNone)(pipeline)) {
        return this._super(...arguments);
      } // Deleting this key shouldn't be required, but if we don't, ED injects
      // the pipeline query parameter anyway.


      delete query.pipeline;
      delete query.number;
      let url = `${this.host}/pipelines/${pipeline}/test-runs`;

      if ((0, _utils.isPresent)(number)) {
        url += `/${number}`;
      }

      return url;
    }

  });

  _exports.default = _default;
});