define("dashboard/components/space/network/inbound-ruleset-list-item", ["exports", "dashboard/components/purple-list-row", "@ember/object/computed"], function (_exports, _purpleListRow, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purpleListRow.default.extend({
    item: (0, _computed.alias)('rule'),
    classNames: ['inbound-rule-item']
  });

  _exports.default = _default;
});