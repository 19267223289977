define("dashboard/serializers/dyno-limit", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      const maxDynos = hash['max_dynos'] || {};
      Object.keys(maxDynos).forEach(size => hash[`${size}`] = maxDynos[`${size}`]);
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});