define("dashboard/templates/components/metrics/charts/visualizations/wrapped-line-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "o7h4e9St",
    "block": "[[[1,[28,[35,0],null,[[\"app\",\"title\",\"devCenterName\",\"unit\",\"secondaryUnit\",\"insufficientDataTitle\",\"isBeta\",\"isDevPreview\",\"boundaryMultiplier\",\"defaultMaxValue\",\"timeSeriesData\",\"isCompactLayout\",\"isShowingLoadingState\",\"onToggleCollapseChart\",\"chartIsCollapsed\",\"openAlertingConfig\",\"alertSummaryType\",\"hasAlternateAxis\",\"alternateUnit\",\"alternateMaxValue\",\"chartHasOpposingTimeSeries\",\"smallNumberFormat\",\"smallNumberThreshold\",\"valueFormat\",\"secondaryValueFormat\",\"isLogarithmic\",\"timezone\",\"timeframe\",\"updateSelectedTime\",\"selectedTime\",\"chartKey\",\"chartContentData\",\"summaryData\",\"headerContentComponent\"],[[30,0,[\"app\"]],[30,0,[\"title\"]],[30,0,[\"devCenterName\"]],[30,0,[\"unit\"]],[30,0,[\"secondaryUnit\"]],[30,0,[\"insufficientDataTitle\"]],[30,0,[\"isBeta\"]],[30,0,[\"isDevPreview\"]],[30,0,[\"boundaryMultiplier\"]],[30,0,[\"defaultMaxValue\"]],[30,0,[\"timeSeriesData\"]],[30,0,[\"isCompactLayout\"]],[30,0,[\"isShowingLoadingState\"]],[30,0,[\"onToggleCollapseChart\"]],[30,0,[\"chartIsCollapsed\"]],[30,0,[\"openAlertingConfig\"]],[30,0,[\"alertSummaryType\"]],[30,0,[\"hasAlternateAxis\"]],[30,0,[\"alternateUnit\"]],[30,0,[\"alternateMaxValue\"]],[30,0,[\"chartHasOpposingTimeSeries\"]],[30,0,[\"smallNumberFormat\"]],[30,0,[\"smallNumberThreshold\"]],[30,0,[\"valueFormat\"]],[30,0,[\"secondaryValueFormat\"]],[30,0,[\"isLogarithmicChart\"]],[30,0,[\"timezone\"]],[30,0,[\"timeframe\"]],[30,0,[\"updateSelectedTime\"]],[30,0,[\"selectedTime\"]],[30,0,[\"chartKey\"]],[30,0,[\"chartContentData\"]],[30,0,[\"summaryData\"]],[30,0,[\"summaryComponent\"]]]]]],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"metrics/charts/timeseries-chart\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/visualizations/wrapped-line-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});