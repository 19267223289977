define("dashboard/mixins/direct-to-invoice", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/utils", "@ember/application", "@ember/service", "dashboard/utils/load-invoice"], function (_exports, _computed, _mixin, _utils, _application, _service, _loadInvoice) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    session: (0, _service.inject)(),
    invoiceManager: (0, _service.inject)(),
    bearerToken: (0, _computed.readOnly)('session.data.authenticated.access_token'),

    afterModel(model) {
      const invoiceManagerYear = this.get('invoiceManager.year');
      const invoiceManagerMonth = this.get('invoiceManager.month');
      const matchingInvoice = model.invoices.find(invoice => {
        return invoice.get('year') === invoiceManagerYear && invoice.get('month') === invoiceManagerMonth;
      }); // If you remove this code, you will have 50 years bad luck and your kids
      // will have 50 years bad luck. And their kids too.
      // But really, this controls direct to invoice links (/invoices/2017/07)
      // It works by directly replacing the DOM with the invoice response using
      // whats stored in invoiceManager service + loadInvoice util.
      // See those for more info.
      // !Do not remove unless you want to break direct invoice links!

      if ((0, _utils.isPresent)(matchingInvoice)) {
        const url = matchingInvoice.get('url');
        const token = this.bearerToken;
        (0, _loadInvoice.default)((0, _application.getOwner)(this), url, token);
        this.invoiceManager.clear();
      }
    }

  });

  _exports.default = _default;
});