define("dashboard/mixins/deploy-target", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    modelType: (0, _object.computed)(function () {
      return this.constructor.modelName;
    })
  });

  _exports.default = _default;
});