define("dashboard/components/pipeline/deploy-modal", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "rsvp"], function (_exports, _component, _service, _computed, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['deploy-pipeline-app'],
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    githubToken: (0, _service.inject)(),
    branch: (0, _computed.oneWay)('githubAppLink.branch'),
    actions: {
      deploy() {
        const app = this.app;
        const branch = this.branch;
        const githubDeploy = this.store.createRecord('kolkrabbi/github-app-deploy', {
          app,
          branch
        });
        return githubDeploy.save().then(deploy => {
          this.analytics.logEvent('Pipeline App', 'Manual Deploy', {
            status: 'succeeded'
          }); // Act on the the build data now, rather than waiting on WS event
          // This lets us immediately display the build as pending in the UI
          // without an extra request.

          const build = deploy.get('build');
          build.app = {
            id: app.get('id')
          };
          const store = this.store;
          const normalized = store.normalize('build', build);
          const pushedBuild = store.push(normalized);
          this.get('app.pipelineCoupling.pipeline.latestBuilds').pushObject(pushedBuild);
          this.attrs.onClose();
        }, reason => {
          githubDeploy.destroyRecord(); // This is super sketch city.
          // TODO#ERRORS as part of error cleanup, we should reevaluate
          // how our 2fa component handles 2fa errors. We could return a specific adapter
          // error that the component checks for versus this nonsense.

          if (reason.errors && reason.errors[0].id === 'two_factor') {
            return _rsvp.default.reject({
              responseJSON: {
                id: 'two_factor'
              }
            });
          } else {
            return _rsvp.default.reject();
          }
        }).catch(err => {
          this.analytics.logEvent('Pipeline App', 'Manual Deploy', {
            status: 'failed'
          });
          throw err;
        });
      },

      handleUpdateBranch() {
        this.set('isShowingCommits', false);
      }

    }
  });

  _exports.default = _default;
});