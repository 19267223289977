define("dashboard/controllers/pipelines/new", ["exports", "@ember/controller", "dashboard/config/environment"], function (_exports, _controller, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: {
      orgNameParam: 'org'
    },
    orgNameParam: null,
    githubIntegrationDisabled: _environment.default.githubIntegrationDisabled
  });

  _exports.default = _default;
});