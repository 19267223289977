define("dashboard/serializers/metrics/monitor", ["exports", "lodash-es/pick", "@ember/array", "dashboard/serializers/application"], function (_exports, _pick, _array, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      let serialized = this._super(...arguments);

      const monitor = snapshot.record; // properties we always want to keep for serialization

      const properties = ['is_active', 'action_type', 'op', 'period', 'min_quantity', 'max_quantity', 'value', 'notification_period', 'playbook_url']; // these are properties that should only be used for new records

      if (monitor.get('isNew')) {
        properties.addObject('name');
      }

      serialized = (0, _pick.default)(serialized, properties);
      const channels = [];

      if (monitor.get('hasEmailChannel')) {
        channels.push('email');
      }

      if (monitor.get('hasAppChannel')) {
        channels.push('app');
      }

      serialized['notification_channels'] = channels;
      serialized['dyno_size'] = monitor.get('dynoSize');
      serialized['quantity'] = monitor.get('quantity');
      return serialized;
    },

    normalize(typeClass, hash) {
      // enforce that notificationChannels at least always be an empty array
      if (typeof hash === 'string') {
        hash = JSON.parse(hash);
      } else {
        const channels = (0, _array.makeArray)(hash['notification_channels']);
        hash['has_email_channel'] = channels.includes('email');
        hash['has_app_channel'] = channels.includes('app');
      }

      return this._super(typeClass, hash);
    },

    /**
     * we never serialize a HasMany relationship for a monitor
     *
     * (this would try and serialize alerts that are on the model which are read-only)
     */
    serializeHasMany() {
      return;
    }

  });

  _exports.default = _default;
});