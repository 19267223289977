define("dashboard/components/metrics/charts/line-chart-elements/stacked-area-graph", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/charts/line-chart-elements/area-graph"], function (_exports, _computed, _object, _areaGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a {{area-graph}} that stacks on top of the {{stacked-area-graph}}
   * rendered before it
   *
   */
  var _default = _areaGraph.default.extend({
    isStackedGraph: true,
    hideLine: true,
    siblingGraphs: (0, _computed.filterBy)('graphs', 'isStackedGraph'),
    visibleSiblingGraphs: (0, _computed.filterBy)('graphs', 'isVisible'),

    /**
     * the chart that this should stack on top of
     */
    chartToStackOn: (0, _object.computed)('visibleSiblingGraphs', function () {
      const sortedSiblings = this.visibleSiblingGraphs.sortBy('legendIndex');
      return sortedSiblings.find(graph => {
        return graph.get('legendIndex') > this.legendIndex;
      });
    }),

    areaBottomYFn() {
      return this.get('chartInnerHeight');
    },

    areaClass: (0, _object.computed)('color', function () {
      const color = this.color;
      return `metrics__color--${color}--fill`;
    })
  });

  _exports.default = _default;
});