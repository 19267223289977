define("dashboard/controllers/account/index", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    reads
  } = _object.computed;

  var _default = _controller.default.extend({
    location: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    federated: reads('current.account.federated'),
    unfederated: reads('current.account.unfederated'),
    profileDescription: (0, _object.computed)('federated', function () {
      if (this.federated) {
        return "Your email address is controlled by your team's " + 'Identity Provider and is your identity on Heroku.';
      } else {
        return 'Your email address is your identity on Heroku and is used to log in.';
      }
    }),
    passwordDescription: (0, _object.computed)('federated', 'federatedOrgName', function () {
      if (this.federated) {
        return `Your password is controlled by your team's Identity Provider.`;
      } else {
        return 'Changing your password will also reset your API key.';
      }
    }),
    actions: {
      closeAccount() {
        this.location.replace('/goodbye.html');
      }

    }
  });

  _exports.default = _default;
});