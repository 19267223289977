define("dashboard/components/account/non-app-authorizations-list", ["exports", "@ember/component", "@ember/object", "@ember/utils", "dashboard/mixins/purple-list"], function (_exports, _component, _object, _utils, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_purpleList.default, {
    nonAppAuthorizations: (0, _object.computed)('authorizations.[]', 'authorizations.@each.isNew', function () {
      return this.authorizations.filter(authorization => {
        return (0, _utils.isEmpty)(authorization.get('client.id')) && !authorization.get('isNew');
      });
    }),
    actions: {
      showEditNonAppAuthorization(authorization) {
        this.transitionToRoute('account.applications.edit-authorization', authorization);
      }

    }
  });

  _exports.default = _default;
});