define("dashboard/templates/components/metrics/charts/legend/chart-legend-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "S0Ngpg5b",
    "block": "[[[41,[30,0,[\"hasVisibleCheckmark\"]],[[[1,\"  \"],[10,1],[14,0,\"self-center mr1 dn dib-l\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@name\",\"@iconClass\",\"@title\",\"@size\"],[\"edit-accept-16\",\"fill-mid-gray\",\"Selected\",10]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[15,0,[29,[\"metrics__legend__swatch \",[30,0,[\"colorClass\"]]]]],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"metrics__legend__label\"],[12],[1,\"\\n    \"],[1,[30,0,[\"legendItem\",\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"legendItem\",\"key\"]],[[[1,\"    \"],[10,0],[15,0,[29,[\"metrics__legend__value \",[52,[30,0,[\"graph\",\"hasCurrentValue\"]],\"metrics__legend__value--present\"]]]],[12],[1,\"\\n      \"],[1,[30,0,[\"legendItem\",\"displayValue\"]]],[1,\"\\n      \"],[10,1],[14,0,\"metrics__legend__unit\"],[12],[2,[30,0,[\"legendItem\",\"displayUnit\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"showSecondaryValue\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"metrics__legend__value \",[52,[30,0,[\"graph\",\"hasCurrentValue\"]],\"metrics__legend__value--present\"]]]],[12],[1,\"\\n        \"],[1,[30,0,[\"legendItem\",\"secondaryDisplayValue\"]]],[1,\"\\n        \"],[10,1],[14,0,\"metrics__legend__unit\"],[12],[1,[30,0,[\"legendItem\",\"secondaryDisplayUnit\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/legend/chart-legend-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});