define("dashboard/components/metrics/chart-controls/layout-toggle", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: 'metrics-layout-toggle',
    classNames: ['flex'],
    'data-test-target': 'metrics-layout-toggle',
    chartLayout: 'vertical',
    isVerticalLayout: equal('chartLayout', 'vertical'),
    isCompactLayout: equal('chartLayout', 'compact'),
    compactIconClass: (0, _object.computed)('isCompactLayout', function () {
      if (this.isCompactLayout) {
        return `fill-white nudge-up--1 pe-none`;
      } else {
        return `malibu-fill-gradient-purple nudge-up--1 pe-none`;
      }
    }),
    verticalIconClass: (0, _object.computed)('isVerticalLayout', function () {
      if (this.isVerticalLayout) {
        return `fill-white nudge-up--1 pe-none`;
      } else {
        return `malibu-fill-gradient-purple nudge-up--1 pe-none`;
      }
    }),
    actions: {
      setLayout(name) {
        if (name !== this.chartLayout) {
          this.toggleLayoutConfig(name);
        }
      }

    }
  });

  _exports.default = _default;
});