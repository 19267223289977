define("dashboard/utils/errors", ["exports", "@ember-data/adapter/error", "@ember/array"], function (_exports, _error, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.errorStatus = errorStatus;
  _exports.isTwoFactorRequiredError = isTwoFactorRequiredError;
  _exports.toJSONAPIErrors = toJSONAPIErrors;

  // returns the HTTP status code for an ember data error
  // OR a jQuery XHR object
  function errorStatus(error) {
    if (error.status) {
      return error.status;
    } else if (error instanceof _error.default) {
      const errorWithStatus = (0, _array.A)(error.errors).find(err => err.status);
      return parseInt(errorWithStatus && errorWithStatus.status, 10);
    }

    return undefined;
  }

  function isTwoFactorRequiredError(error) {
    if (error) {
      // jquery
      if (error.responseJSON) {
        return error.responseJSON.id === 'two_factor';
      } // ember data


      if (error.errors) {
        return (0, _array.makeArray)(error.errors).findBy('id', 'two_factor');
      }
    }

    return false;
  }

  function toJSONAPIErrors(payload, status) {
    return (0, _array.makeArray)(payload).map(error => {
      return {
        detail: error.message,
        id: error.id,
        source: {
          pointer: 'data'
        },
        status: String(status)
      };
    });
  }
});