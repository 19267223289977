define("dashboard/models/github-pr", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    equal
  } = _object.computed;

  var _default = _model.default.extend({
    state: (0, _model.attr)('string'),
    number: (0, _model.attr)('number'),
    title: (0, _model.attr)('string'),
    htmlUrl: (0, _model.attr)('string'),
    head: (0, _model.attr)(),
    base: (0, _model.attr)(),
    user: (0, _model.belongsTo)('github/user'),
    isDeployed: false,
    isOpen: equal('state', 'open'),
    isPullRequest: true,
    prNumber: readOnly('number'),
    prNumberSortKey: readOnly('prNumber'),
    branch: readOnly('head.ref'),
    sha: readOnly('head.sha'),
    commitRepoId: readOnly('head.repo.id'),
    prRepoId: readOnly('base.repo.id'),
    forkRepoId: (0, _object.computed)('prRepoId', 'commitRepoId', function () {
      return this.prRepoId !== this.commitRepoId ? this.commitRepoId : null;
    }),
    app_setup: (0, _object.computed)(function () {
      return {
        status: 'pending'
      };
    }),
    pull_request: (0, _object.computed)('id', 'number', 'title', 'html_url', function () {
      return {
        id: this.id,
        number: this.number,
        title: this.title,
        url: this.htmlUrl
      };
    })
  });

  _exports.default = _default;
});