define("dashboard/services/account-features", ["exports", "@ember/string", "@ember/object", "@ember/service"], function (_exports, _string, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This service exists to provide a universal approach to detect account
  // features (aka heroku labs)
  // For a feature flag test helper, see tests/helpers/account-features.js
  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    // Start with an empty array until the fetch() resolves
    all: (0, _object.computed)(function () {
      return [];
    }),

    // Load features from API. Should be called by a high level route (protected)
    // so that more specific routes already have it loaded.
    fetch() {
      return this.store.findAll('account/feature').then(features => {
        this.set('all', features);
        return features;
      });
    },

    hasFeature(name) {
      const features = this.all;

      if (features === null) {
        return false;
      }

      return features.any(feature => {
        return feature.get('name') === name && feature.get('enabled');
      });
    },

    hasFeatures(features) {
      if (features === null) {
        return false;
      }

      return features.every(name => this.hasFeature(name));
    },

    // allows templates to use `accountFeaturesService.arbitraryFlagName.propertyName` and have this resolve it
    unknownProperty(name) {
      const normalizedName = (0, _string.dasherize)(name);
      return this.store.peekAll('account/feature').findBy('name', normalizedName);
    }

  });

  _exports.default = _default;
});