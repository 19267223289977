define("dashboard/adapters/addon-service", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord(query) {
      const {
        providerName
      } = query;
      delete query.providerName;
      return `${this.host}/addon-services/${providerName}`;
    }

  });

  _exports.default = _default;
});