define("dashboard/adapters/enterprise-account", ["exports", "dashboard/adapters/application", "ember-concurrency", "@ember/service"], function (_exports, _application, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MEMBER_REQUESTS_MAX = 10;

  var _default = _application.default.extend({
    dsErrorMonitor: (0, _service.inject)(),

    handleResponse(status, headers, payload, requestData) {
      if (status === 403) {
        // Don't render the red bubble error if the user does not have access to
        // the enterprise account, like in the case where they are a collaborator
        // on some of the enterprise team apps, but are not a member of the
        // enterprise account itself.
        requestData.jqXHR.errorIsHandled = true;
      }

      return this._super(...arguments);
    },

    findHasMany(store, snapshot, url, relationship) {
      if (relationship.key === 'auditTrailArchives') {
        const enterpriseAccountId = snapshot.id;
        return this._super(...arguments).then(results => {
          for (const result of results) {
            result.enterprise_account_id = enterpriseAccountId;
          }

          return results;
        });
      } else if (relationship.key === 'members') {
        return this.loadAllMembers.perform(snapshot);
      } else if (relationship.key === 'historicalLicenseUsage') {
        // Inject the EA ID and the item's month into each `license-usage` payload.
        return this._super(...arguments).then(payload => {
          return payload.map(group => {
            const {
              month
            } = group;
            const licenses = group.licenses.map(license => Object.assign({}, license, {
              month,
              enterprise_account_id: snapshot.id
            }));
            return Object.assign({}, group, {
              licenses
            });
          });
        });
      } else {
        return this._super(...arguments);
      }
    },

    loadAllMembers: (0, _emberConcurrency.task)(function* (snapshot) {
      const store = this.store;
      const type = store.modelFor('enterprise-account/member');
      const adapter = store.adapterFor('enterprise-account/member');
      const enterpriseAccount = snapshot.attr('name');
      const results = [];
      let range = 'email ..; max=1000';
      let count = 0;

      while (range && count < MEMBER_REQUESTS_MAX) {
        const query = {
          enterpriseAccount,
          range
        };
        const page = yield adapter.query(store, type, query);
        count += 1;
        range = page.meta.nextRange;
        results.push(...page);
      }

      return results;
    })
  });

  _exports.default = _default;
});