define("dashboard/mixins/purple-list", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    A mixin for implementing the Purple List pattern
    Relies on inheriting component to implement
      - createNewItem():  function that returns instance of new item
      - save(): function that saves the new item
      - delete(): function that deletes the item
  */
  var _default = _mixin.default.create({
    actions: {
      edit(item) {
        this.set('item', item);
        this.send('toggleModal');
      },

      new() {
        const item = this.createNewItem();
        this.set('item', item);
        this.send('toggleModal');
      },

      save() {
        this.set('item.isMostRecent', true);
      },

      toggleModal() {
        const item = this.item; // Revert any unsaved changes

        if (item) {
          if (item.get('isDirty')) {
            item.revert();
          }

          if (item.get('hasDirtyAttributes') && !item.get('isNew')) {
            item.rollbackAttributes();
          }

          this.set('item.errorIsVisible', false);
        }

        this.toggleProperty('isAdding');
      },

      toggleDeleteConfirmModal() {
        this.set('appNameConfirmation', '');
        this.toggleProperty('isDeleting');
      },

      confirmDelete(item) {
        this.set('item', item);
        this.send('toggleDeleteConfirmModal');
      }

    }
  });

  _exports.default = _default;
});