define("dashboard/mixins/redirect-after-auth", ["exports", "@ember/object/mixin", "@ember/object", "dashboard/utils/localstorage"], function (_exports, _mixin, _object, _localstorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    // If any of these get stored we can get into a bit of a redirect loop.
    invalidRedirectPaths: (0, _object.computed)(function () {
      return ['/auth/heroku/callback', '/login', '/logout'];
    }),

    isValidRedirectPath(path) {
      const match = this.invalidRedirectPaths.find(notAllowed => {
        return new RegExp(`^${notAllowed}`).test(path);
      });
      return Boolean(path) && !match;
    },

    setRedirectTo(path) {
      if (this.isValidRedirectPath(path)) {
        _localstorage.default.setSessionItem('redirectTo', path);
      }
    },

    getRedirectTo() {
      return _localstorage.default.getSessionItem('redirectTo') || '/';
    }

  });

  _exports.default = _default;
});