define("dashboard/serializers/pipeline-transfer", ["exports", "dashboard/serializers/application", "@ember/utils"], function (_exports, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      const data = this._super(snapshot);

      data.pipeline = {
        id: data.pipeline
      };
      return data;
    },

    normalizeResponse(store, model, payload, id, requestType) {
      if ((0, _utils.isNone)(id)) {
        const random = Math.floor(Math.random() * 10000);
        id = `${payload.pipeline.id}-${random}`;
        payload.id = id;
      }

      delete payload.pipeline;
      return this._super(store, model, payload, id, requestType);
    }

  });

  _exports.default = _default;
});