define("dashboard/components/org/addons-allowlist-exception-item", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    appsListVisible: false,
    actions: {
      toggleInstalls() {
        this.toggleProperty('appsListVisible');
      },

      addToAllowlist() {
        this.onAdd(this.get('item.id'));
      }

    }
  });

  _exports.default = _default;
});