define("dashboard/utils/metrics/data-fetch", ["exports", "@ember/utils", "@ember/object"], function (_exports, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryProcessData = queryProcessData;

  function queryProcessData(dataName) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let queryKey = arguments.length > 2 ? arguments[2] : undefined;
    // A little odd, but we want the queryKey to be optional, since most of the time
    // we don't need to avoid querying for data
    const shouldQueryKey = typeof queryKey === 'undefined' ? 'true' : queryKey;
    return (0, _object.computed)('process', 'afterHours', 'beforeHours', shouldQueryKey, function () {
      const process = this.process;
      const timeframeEnd = this.beforeHours || 0;
      const timeframeBegin = this.afterHours || 24;
      let data = [];
      const shouldQuery = shouldQueryKey === 'true' ? true : this.get(shouldQueryKey);

      if (shouldQuery && (0, _utils.isPresent)(process)) {
        data = process.queryMetricsData(dataName, timeframeBegin, timeframeEnd, options);
      }

      return data;
    });
  }
});