define("dashboard/controllers/account/billing", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _computed, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    invoicesSorter: ['periodStart'],
    current: (0, _service.inject)(),
    account: (0, _computed.alias)('current.account'),
    accountFeatures: (0, _service.inject)(),
    sortedInvoices: (0, _computed.sort)('model.invoices', 'invoicesSorter'),
    currentInvoice: (0, _object.computed)('sortedInvoices', function () {
      return this.get('sortedInvoices.lastObject');
    }),
    previousInvoices: (0, _object.computed)('sortedInvoices', function () {
      return this.sortedInvoices.removeObject(this.currentInvoice);
    }),
    teamsAdministered: (0, _computed.filterBy)('model.teams', 'isAdmin'),
    isAdminsteringTeams: (0, _computed.notEmpty)('teamsAdministered'),
    subscribedToEco: (0, _computed.alias)('account.subscribedToEco'),
    ecoShutdownDate: (0, _computed.alias)('account.ecoShutdownDate'),
    pendingCancellationToEco: (0, _computed.alias)('account.pendingCancellationToEco'),
    ecoRenewalDate: (0, _computed.alias)('account.ecoRenewalDate'),
    ecoPlanUpsell: (0, _object.computed)('subscribedToEco', 'cancelEcoInProgress', function () {
      if (!this.subscribedToEco && !this.cancelEcoInProgress) {
        return 'Get 1000 Eco dyno hours for $5 per month';
      }
    }),
    cancelEcoTask: (0, _emberConcurrency.task)(function* () {
      this.account.set('ecoDynosEnabled', false);

      try {
        yield this.account.save();
      } catch (error) {
        this.account.set('ecoDynosEnabled', true);
      }
    }),
    cancelEcoInProgress: (0, _computed.alias)('cancelEcoTask.isRunning'),
    actions: {
      async subscribeToEco() {
        this.account.set('ecoDynosEnabled', true);

        try {
          await this.account.save();
        } catch (error) {
          this.account.set('ecoDynosEnabled', false);
        }
      },

      async cancelEcoSubscription() {
        await this.cancelEcoTask.perform();
      }

    }
  });

  _exports.default = _default;
});