define("dashboard/controllers/team/space", ["exports", "@ember/object/computed", "@ember/controller"], function (_exports, _computed, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    currentSpace: (0, _computed.readOnly)('model')
  });

  _exports.default = _default;
});