define("dashboard/components/metrics/charts/line-chart-elements/selected-time-graph-point", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/component"], function (_exports, _computed, _object, _utils, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * an indicator on a chart graph that corresponds to the x and y location
   * of the selected time.
   */
  var _default = _component.default.extend({
    tagName: 'g',
    classNameBindings: [':metrics__graph-point-indicator'],
    circleColorClass: (0, _object.computed)('graph.color', function () {
      const color = this.get('graph.color');
      return `metrics__color--${color}--point`;
    }),
    attributeBindings: ['transform'],
    // don't show if we have an invalid y value
    isVisible: (0, _computed.gt)('y', -1),
    _selectedDatum: (0, _computed.readOnly)('graph.selectedDatum'),
    y: (0, _object.computed)('_selectedDatum', function () {
      const datum = this._selectedDatum;

      if (datum) {
        return this.graph.yFn(datum);
      } else {
        // use -1 to avoid invalid svg transforms
        return -1;
      }
    }),
    x: (0, _object.computed)('selectedTimeChartX', function () {
      const x = this.selectedTimeChartX;

      if ((0, _utils.isPresent)(x) && !isNaN(x)) {
        return x;
      } else {
        // use -1 to avoid invalid svg transforms
        return -1;
      }
    }),
    transform: (0, _object.computed)('x', 'y', function () {
      const x = this.x;
      const y = this.y;
      return `translate(${x}, ${y})`;
    })
  });

  _exports.default = _default;
});