define("dashboard/utils/anchorify", ["exports", "@ember/string", "ember"], function (_exports, _string, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = anchorify;
  const URL_REGEX = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/gi;
  /**
   * Reads a string and replaces any instance of https:// or http://
   * with an anchor tag to that link
   */

  function anchorify(input) {
    if (!input) {
      return;
    }

    let matches = input.match(URL_REGEX);

    if (matches) {
      // de-dupe the list of matches
      matches = matches.filter((item, pos) => {
        return matches.indexOf(item) === pos;
      }); // escape all the things

      input = _ember.default.Handlebars.Utils.escapeExpression(input); // write our urls as links

      matches.forEach(function (m) {
        input = input.split(m).join(`<a href="${m}" target="_blank" rel="noopener noreferrer">${m}</a>`);
      });
      return (0, _string.htmlSafe)(input);
    }

    return input;
  }
});