define("dashboard/components/space/network/vpn-connection", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VPN_STATE_ICONS = {
    active: {
      icon: 'success-ring-16',
      iconClass: 'malibu-fill-gradient-green'
    },
    failed: {
      icon: 'failure-ring-16',
      iconClass: 'malibu-fill-gradient-red'
    },
    pending: {
      icon: 'time-16',
      iconClass: 'fill-gray'
    },
    provisioning: {
      icon: 'time-16',
      iconClass: 'fill-gray'
    },
    deprovisioning: {
      icon: 'time-16',
      iconClass: 'fill-gray'
    }
  };

  var _default = _component.default.extend({
    'data-test-target': 'vpn-connection',
    routableCidrs: (0, _object.computed)('vpn.routableCidrs.[]', function () {
      if (this.get('vpn.routableCidrs.[]')) {
        return this.get('vpn.routableCidrs.[]').join(', ');
      }
    }),
    vpnStatusIcon: (0, _object.computed)('vpn.status', function () {
      return VPN_STATE_ICONS[this.get('vpn.status')];
    }),
    vpnStatusIconName: (0, _computed.reads)('vpnStatusIcon.icon'),
    vpnStatusIconClass: (0, _computed.reads)('vpnStatusIcon.iconClass')
  });

  _exports.default = _default;
});