define("dashboard/components/metrics/charts/line-chart-elements/line-graph", ["exports", "@ember/object", "dashboard/components/metrics/charts/line-chart-elements/graph"], function (_exports, _object, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a simple line (without fill beneath)
   */
  var _default = _graph.default.extend({
    classNames: ['metrics__graph--line'],
    tagName: 'g',
    lineClass: (0, _object.computed)('color', function () {
      const color = this.color;
      return `metrics__color--${color}--stroke`;
    }),

    xFn(datum) {
      const time = datum[0];
      return Math.round(this.timeScale(time));
    }

  });

  _exports.default = _default;
});