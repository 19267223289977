define("dashboard/templates/app/scheduler/header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PZ+Jc/4g",
    "block": "[[[10,\"b\"],[12],[1,\"Heroku Scheduler\"],[13],[1,\" provisioned for\\n\"],[6,[39,0],null,[[\"route\",\"model\"],[\"app\",[30,0,[\"app\",\"name\"]]]],[[\"default\"],[[[[1,\"  \"],[8,[39,1],null,[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[\"app\",\"app-16\",16,\"v-mid malibu-fill-gradient-purple\"]],null],[1,\"\\n  \"],[1,[30,0,[\"app\",\"name\"]]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/app/scheduler/header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});