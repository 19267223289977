define("dashboard/controllers/app/deploy/github", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    and,
    not,
    or,
    reads
  } = _object.computed;

  var _default = _controller.default.extend({
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    api: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    app: alias('model.app'),
    githubAuth: alias('model.githubAuth'),
    githubAppLink: alias('app.githubAppLink'),
    githubBranches: alias('githubAppLink.githubBranches'),
    githubOrgs: alias('model.githubOrgs'),
    parentApp: alias('githubAppLink.parentApp'),
    githubUser: alias('model.githubUser'),
    pipeline: reads('app.pipelineCoupling.pipeline'),
    pipelineRepository: reads('pipeline.githubRepository'),
    githubOwner: reads('githubAppLink.githubOwner'),
    isNotAuthedToGithub: or('githubAuth.isNotLinked', 'githubOrgs.hasAuthError', 'githubUser.hasAuthError'),
    isAuthedToGithub: not('isNotAuthedToGithub'),
    isNotLinkedToGithub: not('githubAppLink.isLinked'),
    isNotAuthedAndLinkedToGithub: or('isNotAuthedToGithub', 'isNotLinkedToGithub', 'isAccessDenied'),
    isAccessDenied: (0, _object.computed)('app.branches', function () {
      return this.get('app.branches.isLoaded') && this.app.get('branches').length === 0;
    }),
    isNotAccessDenied: not('isAccessDenied'),
    repoMatchesPipeline: (0, _object.computed)('pipelineRepository.repository.id', 'githubAppLink.repoId', function () {
      const pipelineRepoId = this.get('pipelineRepository.repository.id');
      return pipelineRepoId && pipelineRepoId === this.get('githubAppLink.repoId');
    }),
    showConnectedViaPipeline: and('repoMatchesPipeline', 'isNotAccessDenied'),
    createLinkTask: (0, _emberConcurrency.task)(function* (identifier) {
      yield this._createLink(identifier);
    }),
    destroyLinkTask: (0, _emberConcurrency.task)(function* (unlinkGithubRepoAction, refreshGithubDeployAction) {
      yield unlinkGithubRepoAction(this.get('githubAppLink.content'));
      refreshGithubDeployAction();
    }),

    _createLink(identifier) {
      this.analytics.logEvent('GitHub App', 'Linked');
      return this.store.createRecord('github-app-link', {
        app: this.app,
        repo: identifier
      }).save().then(() => {
        this._loadDeployInfo();
      });
    },

    _loadDeployInfo() {
      const repoPromise = this.get('app.team.isGithubAppEnabled') ? this.get('app.connectedRepository') : this.get('app.githubAppLink');
      this.app.get('getServicesTask').perform();
      repoPromise.then(() => {
        if (this.get('app.hasConnectedRepository')) {
          this.app.get('getBranchesTask').perform();
        }
      });
    },

    actions: {
      deleteReviewApp() {
        const app = this.app;
        const pipelineId = this.get('pipeline.id'); // This allows us to delete the PR app without having to do a
        // fetch for the pipelines related pull-requests on this deploy page

        const api = this.api;
        const appId = app.get('id');
        const prAppDelete = api.request(`/review-apps/${appId}`, {
          type: 'DELETE',
          adapter: this.store.adapterFor('kolkrabbi')
        });
        return prAppDelete.then(() => {
          this.set('showDeleteReviewAppModal', false);

          if (pipelineId) {
            this.transitionToRoute('pipelines.pipeline', pipelineId);
          } else if (app.get('isOwnedByOrg')) {
            this.transitionToRoute('team.switchable', app.get('team.name'));
          } else {
            this.transitionToRoute('apps');
          }
        });
      },

      onRepoLink(repo) {
        return this.createLinkTask.perform(repo.get('fullName'));
      },

      onRepoUnlink(unlinkGithubRepoAction, refreshGithubDeployAction) {
        this.destroyLinkTask.perform(unlinkGithubRepoAction, refreshGithubDeployAction);
        this.set('showDisconnectConfirmation', false);
      },

      showDisconnectConfirmation() {
        this.set('showDisconnectConfirmation', true);
      },

      showDeleteReviewAppModal() {
        this.set('showDeleteReviewAppModal', true);
      }

    }
  });

  _exports.default = _default;
});