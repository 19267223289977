define("dashboard/routes/team/switchable/datastores", ["exports", "@ember/routing/route", "@ember/service", "dashboard/mixins/document-title", "dashboard/config/environment"], function (_exports, _route, _service, _documentTitle, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HEROKU_DATASTORE_ADDONS = [_environment.default.postgresAddonName, _environment.default.kafkaAddonName, _environment.default.redisAddonName];

  var _default = _route.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    beforeModel() {
      if (!this.accountFeatures.hasFeature('dashboard-data-ux')) {
        return this.transitionTo('team.switchable');
      }
    },

    async model() {
      const {
        team
      } = this.modelFor('team');
      const addons = await this.store.query('addon', {
        teamId: team.name,
        slug: HEROKU_DATASTORE_ADDONS
      }); // Currently the /teams/:id/addons endpoint on the API returns duplicates.
      // This behavior occurs on the standard and heroku-addons-filter variants.
      // It seems to be related to multiple-attachment.
      //
      // We should ultimately propose a patch to heroku/api but, for now, the
      // following line is a low-cost solution that allows us to ship the
      // datastore-listing functionality.
      //
      // TODO:
      // Submit a PR to heroku/api which de-dupes the records in /teams/:id/addons
      //

      const unique = addons.uniq();
      return unique;
    }

  });

  _exports.default = _default;
});