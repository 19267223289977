define("dashboard/routes/app/access", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app"], function (_exports, _rsvp, _service, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model() {
      let permissions, orgCollaborators, appCollaborators;
      const {
        appPermissions,
        app
      } = this.modelFor('app');
      const team = app.get('team');

      if (app.get('isOwnedByOrg')) {
        if (team.get('role') !== 'collaborator') {
          orgCollaborators = app.get('team.members').reload();
        }

        permissions = this.store.findAll('team-permission').then(perms => {
          return perms.sortBy('permissiveness');
        });
        appCollaborators = app.hasMany('teamAppCollaborators').reload();
      }

      return (0, _rsvp.hash)({
        app,
        appPermissions,
        team,
        appCollaborators: appCollaborators || app.get('collaborators'),
        orgCollaborators: orgCollaborators || [],
        permissions: permissions || []
      });
    },

    afterModel(model) {
      this.setCollaboratorPermissions(model.appCollaborators, model.permissions);
      this.analytics.logEvent('App Access Page', 'Viewed');
    },

    // Attach the collection of available permissions to each collaborator so the UI
    // can display the complete of available permissions combined with those already
    // selected for each collaborator
    setCollaboratorPermissions(collaborators, availablePermissions) {
      collaborators.forEach(collaborator => {
        collaborator.set('availablePermissions', availablePermissions);
      });
    }

  });

  _exports.default = _default;
});