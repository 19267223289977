define("dashboard/components/datastore/postgres/nav", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Conditions to show/hide their options are based on existent code in data.heroku.com
  // https://git.io/JUBvy
  var _default = _component.default.extend({
    accountFeatures: (0, _service.inject)('account-features'),
    tagName: '',
    planName: (0, _computed.readOnly)('addon.planName'),
    isHobby: (0, _computed.bool)('addon.isHobby'),
    isNotHobby: (0, _computed.not)('isHobby'),
    isShielded: (0, _object.computed)('planName', function () {
      return this.planName.indexOf('shielded') > -1;
    }),
    isNotShielded: (0, _computed.not)('isShielded'),
    isNotLegacyInfra: (0, _object.computed)('datastore', function () {
      const infrastructureInfo = this.datastore.info.findBy('name', 'Infrastructure');
      return infrastructureInfo ? infrastructureInfo.values.indexOf('Legacy') === -1 : true;
    }),
    isVersionAbove9point6: (0, _object.computed)('datastore', function () {
      return parseFloat(this.datastore.pgVersion) >= 9.6;
    }),
    showCredentials: (0, _computed.and)('isNotHobby', 'isNotLegacyInfra', 'isVersionAbove9point6'),
    showDiagnose: (0, _object.computed)('isShielded', 'accountFeatures', function () {
      return !this.isShielded && this.accountFeatures.hasFeature('diagnose');
    }),
    showDataclips: (0, _computed.bool)('isNotShielded'),
    showSettings: (0, _computed.and)('isNotLegacyInfra', 'isVersionAbove9point6')
  });

  _exports.default = _default;
});