define("dashboard/components/favorite-item", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders a clickable star whose empty or full value is toggled by clicking,
   * and whose value is bound to `isFavorite`.
   *
   *     {{favorite-item item=this}}
   * @class Dashboard.FavoriteItemComponent
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    tagName: 'button',
    classNames: ['nudge-up--1', 'cursor-pointer', 'b--0', 'bg-transparent', 'b--transparent', 'pa1'],
    'data-test-target': 'favorite-item',
    role: 'switch',
    ariaPressed: (0, _object.computed)('isFavorite', function () {
      // ensure always gets rendered as "true" or "false"
      return String(this.isFavorite);
    }),
    attributeBindings: ['aria-label', 'role', 'ariaPressed:aria-checked'],
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    favoritesService: (0, _service.inject)('favorites'),
    type: (0, _computed.readOnly)('item.constructor.modelName'),
    favorite: (0, _object.computed)('type', 'item.id', 'favoritesService.favorites.[]', function () {
      return this.favoritesService.favoriteFor(this.type, this.get('item.id'));
    }),
    isFavorite: (0, _computed.bool)('favorite'),
    'aria-label': (0, _object.computed)('type', 'item.name', function () {
      return `Add ${this.type} ${this.item.name} to favorites`;
    }),

    mouseEnter() {
      this.set('isHovering', true);
    },

    mouseLeave() {
      this.set('isHovering', false);
    },

    click(event) {
      event.preventDefault();

      if (this.isFavorite) {
        this._removeFavorite();
      } else {
        this._addFavorite();
      }

      return false;
    },

    _addFavorite() {
      const store = this.store;
      const favoriteType = this.type;
      const favorite = store.createRecord('favorite', {
        type: favoriteType,
        resourceId: this.get('item.id')
      });
      favorite.save().then(() => {
        const title = favoriteType.classify();
        this.analytics.logEvent(title, 'Favorited');
      }).catch(() => favorite.unloadRecord());
    },

    _removeFavorite() {
      this.favorite.destroyRecord().then(() => {
        const title = this.type.classify();
        this.analytics.logEvent(title, 'Unfavorited');
      });
    }

  });

  _exports.default = _default;
});