define("dashboard/validations/payment-method", ["exports", "ember-changeset-validations/validators", "dashboard/validators/braintree-hosted-credit-card-field", "dashboard/validators/braintree-hosted-field", "dashboard/validators/postal-code-field"], function (_exports, _validators, _braintreeHostedCreditCardField, _braintreeHostedField, _postalCodeField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentMethodModelValidations = _exports.hostedFieldValidations = void 0;

  const validateNonEmptyString = () => (0, _validators.validatePresence)({
    presence: true,
    regex: /\S/,
    allowBlank: false
  });

  const hostedFieldValidations = {
    cardNumber: [(0, _braintreeHostedCreditCardField.default)()],
    cvv: [(0, _braintreeHostedField.default)()],
    expirationDate: [(0, _braintreeHostedField.default)()]
  };
  _exports.hostedFieldValidations = hostedFieldValidations;
  const paymentMethodModelValidations = {
    postal_code: [validateNonEmptyString(), (0, _postalCodeField.default)()],
    first_name: validateNonEmptyString(),
    last_name: validateNonEmptyString(),
    address_1: validateNonEmptyString(),
    city: validateNonEmptyString(),
    state: validateNonEmptyString(),
    country: validateNonEmptyString()
  };
  _exports.paymentMethodModelValidations = paymentMethodModelValidations;
});