define("dashboard/templates/components/app-json-config-var-table", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lTtaBscq",
    "block": "[[[10,\"table\"],[14,0,\"table editable-list config-var-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"configVars\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,2],null,[[\"app\",\"configVar\",\"parentConfigVars\",\"action\"],[[30,0,[\"app\"]],[30,1],[30,0,[\"parentConfigVars\"]],[28,[37,3],[[30,0],\"removeConfigVar\"],null]]]]],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isHidingHelp\"]]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n    \"],[10,\"strong\"],[12],[1,[30,0,[\"keysWarningText\"]]],[1,\" will be committed to the repository\"],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    Make sure that these config vars do not contain secrets.\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"configVar\"],false,[\"each\",\"-track-array\",\"app-json-config-var\",\"action\",\"unless\"]]",
    "moduleName": "dashboard/templates/components/app-json-config-var-table.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});