define("dashboard/mixins/feature-check-route", ["exports", "rsvp", "@ember/object/mixin", "@ember/service"], function (_exports, _rsvp, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    requiredAccountFeatures: [],
    accountFeaturesService: (0, _service.inject)('account-features'),

    beforeModel() {
      const requiredFeatures = this.requiredAccountFeatures;

      if (!this.accountFeaturesService.hasFeatures(requiredFeatures)) {
        return (0, _rsvp.reject)({
          status: 404
        });
      }
    }

  });

  _exports.default = _default;
});