define("dashboard/components/metrics/charts/row-chart-elements/indicator", ["exports", "@ember/object", "@ember/object/computed", "@ember/component", "dashboard/utils/metrics/time-calculators", "dashboard/templates/components/metrics/charts/row-chart-elements/indicator"], function (_exports, _object, _computed, _component, _timeCalculators, _indicator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * an individual indicator in a {{metrics/charts/row-chart-elements/graph-row-stacked}} component
   *
   * contains special optimizations because of the potential frequency of use
   */
  var _default = _component.default.extend({
    layout: _indicator.default,
    tagName: 'g',
    _baseClass: 'metrics__graph-row-stacked__indicator',
    maxTimeExtended: (0, _object.computed)('maxTime', 'rollupDuration', function () {
      return this.maxTime + this.rollupDuration;
    }),
    _logLevelClass: (0, _object.computed)('level', '_baseClass', function () {
      return `${this._baseClass}--${this.level}`;
    }),
    classNameBindings: ['_baseClass', '_logLevelClass'],
    attributeBindings: ['transform'],
    opacity: 1.0,
    height: 10,
    pixelsPerMs: (0, _object.computed)('minTime', 'maxTimeExtended', 'rowWidth', function () {
      const duration = this.maxTimeExtended - this.minTime;
      return this.rowWidth / duration;
    }),

    /**
     * the number of timeframes we expect to be present in the graph
     * given the timeframe and rollup duration
     */
    xUnits: (0, _object.computed)('minTime', 'maxTime', 'rollupDuration', function () {
      const rollupDuration = this.rollupDuration;
      const duration = this.maxTime - this.minTime;
      return duration / rollupDuration;
    }),

    /**
     * the width of each "x unit"
     *
     */
    width: (0, _object.computed)('xUnits', 'rowWidth', function () {
      const units = this.xUnits;
      const rowWidth = this.rowWidth || 0;
      return rowWidth / units;
    }),

    /**
     * the x offset of the indicator
     */
    x: (0, _object.computed)('timeScale', 'time', function () {
      return this.timeScale(this.time);
    }),
    transform: (0, _object.computed)('x', function () {
      return `translate(${this.x}, 0)`;
    }),
    currentStartTime: (0, _computed.readOnly)('minTime'),
    currentEndTime: (0, _computed.readOnly)('maxTime'),
    isShowingIcon: (0, _object.computed)('time', 'currentStartTime', 'currentEndTime', function () {
      return this.time >= this.currentStartTime && this.time <= this.currentEndTime;
    }),
    durations: (0, _timeCalculators.filterPeriodWithinTimeframe)('models', 'startedAt', 'endedAt')
  });

  _exports.default = _default;
});