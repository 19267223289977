define("dashboard/serializers/pipeline", ["exports", "@ember/utils", "dashboard/serializers/application", "dashboard/config/environment"], function (_exports, _utils, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      hash.links = {
        githubAppLinks: `/pipelines/${hash.id}/github`,
        githubRepository: `/pipelines/${hash.id}/repository`,
        connectedRepository: `/pipelines/${hash.id}/repo`,
        latestDeployments: `/pipelines/${hash.id}/latest-deployments`,
        latestReleases: `/pipelines/${hash.id}/latest-releases`,
        latestBuilds: `/pipelines/${hash.id}/latest-builds`,
        reviewAppConfig: `/pipelines/${hash.id}/review-app-config`,
        testRuns: `/pipelines/${hash.id}/test-runs`,
        unsortedReviewApps: `/pipelines/${hash.id}/review-apps?not[status]=deleted`,
        connectedPullRequests: `/pipelines/${hash.id}/repo/pull-requests`,
        connectedBranches: `/pipelines/${hash.id}/repo/branches`,
        connectedServices: `${_environment.default.kolkrabbiUrl}/pipelines/${hash.id}/repository`,
        collaborators: `/pipelines/${hash.id}/ephemeral-app-collaborators`
      };
      return this._super.apply(this, arguments);
    },

    serialize(snapshot) {
      return {
        name: snapshot.record.get('name'),
        owner: {
          id: snapshot.record.get('owner.id'),
          type: snapshot.record.get('owner.type')
        },
        ephemeral_apps: {
          collaborators_enabled: snapshot.record.get('collaboratorsEnabled'),
          collaborator_synchronization: snapshot.record.get('collaboratorSync'),
          collaborator_permissions: snapshot.record.get('collaboratorPermissions')
        }
      };
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      if (hash.owner && hash.owner.type === 'team') {
        relationships.team = {
          data: {
            id: hash.owner.id,
            type: 'team'
          }
        };
      }

      return relationships;
    },

    extractAttributes(modelClass, payload) {
      const attributes = this._super(...arguments);

      if (payload.ephemeral_apps) {
        attributes.collaboratorsEnabled = payload.ephemeral_apps.collaborators_enabled;
        attributes.collaboratorSync = payload.ephemeral_apps.collaborator_synchronization;
        attributes.collaboratorPermissions = payload.ephemeral_apps.collaborator_permissions.mapBy('name');
      }

      return attributes;
    },

    // Altered version of RESTSerializer's pushPayload
    // This allows us to manually push Pipeline payloads from the API into the store
    pushPayload(store, payload) {
      let documentHash = {};
      const type = store.modelFor('pipeline');

      if ((0, _utils.typeOf)(payload) === 'object') {
        documentHash = this.normalize(type, payload);
      } else if (Array.isArray(payload)) {
        const ret = new Array(payload.length);

        for (let i = 0; i < payload.length; i++) {
          const data = payload[i];
          ret[i] = this.normalize(type, data);
        }

        documentHash.data = ret;
      }

      store.push(documentHash);
    }

  });

  _exports.default = _default;
});