define("dashboard/models/app", ["exports", "@ember/debug", "@ember/object/computed", "@ember/utils", "@ember/object", "rsvp", "jquery", "@ember-data/model", "dashboard/config/environment", "ember-concurrency", "dashboard/mixins/metrics/app", "dashboard/mixins/formations", "dashboard/mixins/save-bulk-formation", "dashboard/mixins/save-buildpack-installations", "dashboard/mixins/save-config-vars", "dashboard/mixins/web-socket-model", "ember-cp-validations", "dashboard/models/production-check/stack", "@ember/service", "dashboard/utils/custom-computed", "moment"], function (_exports, _debug, _computed, _utils, _object, _rsvp, _jquery, _model, _environment, _emberConcurrency, _app, _formations, _saveBulkFormation, _saveBuildpackInstallations, _saveConfigVars, _webSocketModel, _emberCpValidations, _stack, _service, _customComputed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    and,
    bool,
    equal,
    filter,
    mapBy,
    notEmpty,
    not,
    readOnly,
    sort,
    sum,
    union
  } = _object.computed;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 30,

      message(type) {
        if (type === 'tooShort') {
          return 'is too short (minimum is 3 characters).';
        }

        if (type === 'tooLong') {
          return 'is too long (maximum is 30 characters).';
        }
      }

    }), // Ordering matters here for which is the dominant error we display
    (0, _emberCpValidations.validator)('format', {
      regex: /^[a-z]/,
      message: 'must start with a lowercase letter.'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[a-z0-9-]*$/,
      message: 'should only contain lowercase letters, numbers, and dashes.'
    })]
  });

  const languageFilter = function (languageRegex) {
    return (0, _object.computed)('buildpackNames', function () {
      return this.buildpackNames.filter(buildpackName => languageRegex.test(buildpackName));
    });
  };

  const isFeatureEnabled = function (featureName) {
    return (0, _object.computed)('features.@each.enabled', function () {
      return this._checkFeatureIsEnabled(featureName);
    });
  };

  var _default = _model.default.extend(_app.default, _formations.default, _saveBulkFormation.default, _saveBuildpackInstallations.default, _saveConfigVars.default, _webSocketModel.default, Validations, {
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    isParanoid: isFeatureEnabled('paranoid'),
    socketIdentifier: (0, _object.computed)('id', function () {
      return `apps/${this.id}`;
    }),
    addons: (0, _model.hasMany)('addon'),
    appProcessTier: (0, _model.attr)('string'),
    // This clobbers the field with the same name from the FormationMixins so that we can immediately access
    // the process tier if the `3.process-tier` variant is used to request the apps.
    processTier: (0, _object.computed)('appProcessTier', 'formations.firstObject.processTier', 'formations.isFulfilled', function () {
      return this.appProcessTier || this.get('formations.firstObject.processTier');
    }),
    attachments: (0, _model.hasMany)('attachment'),
    collaborators: (0, _model.hasMany)('collaborator', {
      inverse: 'app'
    }),
    teamAppCollaborators: (0, _model.hasMany)('team/collaborator', {
      inverse: 'app'
    }),
    domains: (0, _model.hasMany)('domain'),
    region: (0, _model.belongsTo)('region'),
    sniEndpoints: (0, _model.hasMany)('sniEndpoint'),
    sslEndpoints: (0, _model.hasMany)('sslEndpoint'),
    // All apps are MSNI, so we should not check the feature flag anymore. Also no app uses sslEndpoints.
    shouldUseSslEndpoints: (0, _object.computed)('hasMultipleSniEndpointsEnabled', 'isPrivateSpaceApp', function () {
      return false;
    }),
    endpoints: (0, _object.computed)('isPrivateSpaceApp', 'sslEndpoints.[]', 'sniEndpoints.[]', function () {
      if (this.shouldUseSslEndpoints) {
        return this.sslEndpoints;
      }

      return this.sniEndpoints;
    }).readOnly(),
    builds: (0, _model.hasMany)('build', {
      inverse: 'app'
    }),
    releases: (0, _model.hasMany)('release'),
    formations: (0, _model.hasMany)('formation'),
    features: (0, _model.hasMany)('app/feature'),
    recipients: (0, _model.hasMany)('recipient'),
    dynoLimit: (0, _model.belongsTo)('dynoLimit'),
    buildpackInstallations: (0, _model.hasMany)('buildpack-installation'),
    configVars: (0, _model.belongsTo)('configVars'),
    pipelineCoupling: (0, _model.belongsTo)('pipelineCoupling'),
    githubAppLink: (0, _model.belongsTo)('githubAppLink', {
      inverse: 'app'
    }),
    webhooks: (0, _model.hasMany)('app/webhook'),
    webhookIncludes: (0, _model.hasMany)('app/webhook-include'),
    webhookDeliveries: (0, _model.hasMany)('app/webhookDelivery'),
    appState: (0, _model.belongsTo)('app/state'),
    promotionTarget: (0, _model.belongsTo)('pipeline-promotion-target'),
    jobs: (0, _model.hasMany)('job'),
    preference: (0, _model.belongsTo)('app-preference'),
    connectedRepository: (0, _model.belongsTo)('repositories-api-github-repo'),
    connectedBranches: (0, _model.hasMany)('repositories-api-github-branch'),
    connectedServices: (0, _model.belongsTo)('app-services-config'),
    hasImplicitGithubAppLink: null,
    name: (0, _model.attr)('string'),
    slug: alias('name'),
    stack: (0, _model.attr)(),

    /* Any active stack deprecations should be included in this hard-coded array in the following format.
       If there are no active stack deprecations, then the array should be left empty.
        e.g.
       activeStackDeprecations: [
         EmberObject.create({
           name: 'heroku-16',
           eolDate: moment.utc('2021-05-01'),
           buildsDisabledDate: moment.utc('2021-06-01'),
           documentationUrl: 'https://help.heroku.com/0S5P41DC/heroku-16-end-of-life-faq',
         }),
       ],
    */
    activeStackDeprecations: [_object.default.create({
      name: 'cedar-14',
      eolDate: _moment.default.utc('2019-05-01'),
      buildsDisabledDate: _moment.default.utc('2020-11-02'),
      documentationUrl: 'https://help.heroku.com/SMQ1J712/cedar-14-end-of-life-faq'
    }), _object.default.create({
      name: 'heroku-16',
      eolDate: _moment.default.utc('2021-05-01'),
      buildsDisabledDate: _moment.default.utc('2021-06-01'),
      documentationUrl: 'https://help.heroku.com/0S5P41DC/heroku-16-end-of-life-faq'
    }), _object.default.create({
      name: 'heroku-18',
      eolDate: _moment.default.utc('2023-04-30'),
      buildsDisabledDate: _moment.default.utc('2023-05-01'),
      documentationUrl: 'https://help.heroku.com/X5OE6BCA/heroku-18-end-of-life-faq'
    })],
    stackDeprecationObject: (0, _customComputed.findBy)('activeStackDeprecations', 'name', 'stack.name'),
    stackDeprecation: (0, _object.computed)('stackDeprecationObject', function () {
      const stackDeprecation = this.stackDeprecationObject;

      if (stackDeprecation) {
        (false && !(stackDeprecation.eolDate && _moment.default.isMoment(stackDeprecation.eolDate)) && (0, _debug.assert)('a property named eolDate must be provided to the stackDeprecation object as a moment object', stackDeprecation.eolDate && _moment.default.isMoment(stackDeprecation.eolDate)));
        (false && !(stackDeprecation.buildsDisabledDate && _moment.default.isMoment(stackDeprecation.buildsDisabledDate)) && (0, _debug.assert)('a property named buildsDisabledDate must be provided to the stackDeprecation object as a moment object', stackDeprecation.buildsDisabledDate && _moment.default.isMoment(stackDeprecation.buildsDisabledDate)));
        stackDeprecation.set('isEol', stackDeprecation.eolDate < (0, _moment.default)());
        stackDeprecation.set('buildsDisabled', stackDeprecation.buildsDisabledDate < (0, _moment.default)());
        stackDeprecation.set('stackStatus', stackDeprecation.isEol ? 'end-of-life' : 'deprecated');
        return stackDeprecation;
      }
    }),
    stackIsDeprecated: notEmpty('stackDeprecation'),
    showStackDeprecationWarning: (0, _object.computed)('stackIsDeprecated', function () {
      if (this.accountFeatures.hasFeature('disable-stack-deprecation-warnings')) {
        return false;
      } else {
        return this.stackIsDeprecated;
      }
    }),
    buildStack: (0, _model.attr)(),
    isPendingStackUpgrade: (0, _object.computed)('buildStack.id', 'stack.id', function () {
      return this.get('buildStack.id') !== this.get('stack.id');
    }),
    isContainerBuild: (0, _object.computed)('buildStack.name', function () {
      return this.buildStack?.name === 'container';
    }),
    team: (0, _model.belongsTo)('team'),
    space: (0, _model.attr)(),
    buildpackProvidedDescription: (0, _model.attr)('string'),
    gitUrl: (0, _model.attr)('string'),
    repoSize: (0, _model.attr)('number'),
    slugSize: (0, _model.attr)('number'),
    webUrl: (0, _model.attr)('string'),
    acm: (0, _model.attr)('boolean'),
    maintenance: (0, _model.attr)('boolean'),
    owner: (0, _model.attr)(),
    createdAt: (0, _model.attr)('date'),
    locked: (0, _model.attr)('boolean'),
    joined: (0, _model.attr)('boolean'),
    unjoined: not('joined'),
    // TODO: figure out why dates don't work with `time-ago` component
    releasedAt: (0, _model.attr)('string'),
    internalRouting: (0, _model.attr)('boolean'),
    isPrivateSpaceApp: bool('space'),
    isNotPrivateSpaceApp: not('isPrivateSpaceApp'),
    isPrivateSpaceShieldApp: bool('space.shield'),
    spaceName: alias('space.name'),
    ownerId: alias('owner.id'),
    isOwnedByOrg: notEmpty('team.content'),
    isOwnedByTeam: alias('isOwnedByOrg'),
    isGithubAppEnabled: alias('team.isGithubAppEnabled'),
    appBranches: (0, _computed.reads)('getBranchesTask.last.value'),
    branches: (0, _object.computed)('appBranches', 'hasConnectedRepository', function () {
      return this.hasConnectedRepository ? this.appBranches : [];
    }),
    services: (0, _computed.reads)('getServicesTask.last.value'),
    getBranchesTask: (0, _emberConcurrency.task)(function* () {
      let branches;

      if (this.isGithubAppEnabled) {
        branches = this.hasMany('connectedBranches').reload();
      } else {
        branches = this.get('githubAppLink.githubBranches');
      }

      try {
        return yield branches;
      } catch (error) {
        return null;
      }
    }).drop(),
    getServicesTask: (0, _emberConcurrency.task)(function* () {
      let services;

      if (this.isGithubAppEnabled) {
        services = this.belongsTo('connectedServices').reload();
      } else {
        services = this.get('githubAppLink');
      }

      try {
        return yield services;
      } catch (error) {
        return null;
      }
    }).drop(),
    reloadConnectedRepositoryTask: (0, _emberConcurrency.task)(function* () {
      return yield this.belongsTo('connectedRepository').reload();
    }),
    githubAppInstallation: (0, _object.computed)('team.isEnterpriseTeam', 'team.githubAppInstallation', 'team.enterprise.githubAppInstallation', function () {
      if (this.isGithubAppEnabled) {
        if (this.isOwnedByTeam) {
          return this.get('team.isEnterpriseTeam') ? this.get('team.enterprise.githubAppInstallation') : this.get('team.githubAppInstallation');
        } else {
          return this.get('current.account.githubAppInstallation');
        }
      }
    }),
    hasGithubAppLink: notEmpty('githubAppLink.content'),
    isConnectedToGithub: (0, _object.computed)('githubAppLink.content', 'pipelineCoupling.hasGithubAppLink', 'connectedRepository.content', function () {
      if (this.isGithubAppEnabled) {
        return (0, _utils.isPresent)(this.connectedRepository.get('content'));
      } else {
        return (0, _utils.isPresent)(this.githubAppLink.get('content')) || this.pipelineCoupling.get('hasGithubAppLink');
      }
    }),
    hasConnectedRepository: (0, _object.computed)('githubAppLink.isLinked', 'connectedRepository.content', function () {
      if (this.isGithubAppEnabled) {
        return (0, _utils.isPresent)(this.connectedRepository.get('content'));
      } else {
        return this.githubAppLink.get('isLinked');
      }
    }),
    repositoryUrl: (0, _object.computed)('connectedRepository.htmlUrl', 'githubAppLink.webUrl', function () {
      if (this.isGithubAppEnabled) {
        return this.connectedRepository.get('htmlUrl');
      } else {
        return this.githubAppLink.get('webUrl');
      }
    }),
    repositoryName: (0, _object.computed)('connectedRepository.name', 'githubAppLink.repo', function () {
      if (this.isGithubAppEnabled) {
        return this.connectedRepository.get('name');
      } else {
        return this.githubAppLink.get('repo');
      }
    }),
    isInPipeline: notEmpty('pipelineCoupling.content'),
    isTest: equal('pipelineCoupling.stage', 'test'),
    isReview: equal('pipelineCoupling.stage', 'review'),
    isDevelopment: equal('pipelineCoupling.stage', 'development'),
    isStaging: equal('pipelineCoupling.stage', 'staging'),
    isProduction: equal('pipelineCoupling.stage', 'production'),
    prNumber: alias('pipelineCoupling.prNumber'),
    prNumberSortKey: readOnly('prNumber'),
    // The following 'current' prefixed properties are only used for pipeline
    // UI. So we traverse through the pipeline to find them, versus loading
    // release/builds for each app.
    releaseSort: ['version:desc'],
    sortedReleases: sort('currentReleases', 'releaseSort'),
    sortedDeployments: sort('currentDeployments', 'releaseSort'),
    __currentReleaseApps__: mapBy('pipelineCoupling.pipeline.latestReleases', 'app'),
    currentReleases: (0, _object.computed)('pipelineCoupling.pipeline.latestReleases', '__currentReleaseApps__.@each.id', 'id', function () {
      const latestReleases = this.get('pipelineCoupling.pipeline.latestReleases') || [];
      return latestReleases.filter(release => release.get('app.id') === this.id);
    }),
    currentRelease: (0, _object.computed)('sortedReleases.[]', function () {
      return this.get('sortedReleases.firstObject');
    }),
    __pipelineLatestDeployments__: mapBy('pipelineCoupling.pipeline.latestDeployments', 'app'),
    currentDeployments: (0, _object.computed)('pipelineCoupling.pipeline.latestDeployments.[]', '__pipelineLatestDeployments__.@each.id', 'id', function () {
      const latestDeployments = this.get('pipelineCoupling.pipeline.latestDeployments');

      if ((0, _utils.isPresent)(latestDeployments)) {
        return latestDeployments.filterBy('app.id', this.id);
      }
    }),
    deploymentsAndReleases: union('currentDeployments', 'currentReleases'),
    sortedDeploymentsAndReleases: sort('deploymentsAndReleases', 'releaseSort'),
    currentDeployment: (0, _object.computed)('sortedDeploymentsAndReleases.@each.codeWasReleased', function () {
      return (this.sortedDeploymentsAndReleases || []).findBy('codeWasReleased');
    }),
    currentBuild: (0, _object.computed)('pipelineCoupling.pipeline.latestBuilds.[]', 'id', function () {
      const latestBuilds = this.get('pipelineCoupling.pipeline.latestBuilds');

      if ((0, _utils.isPresent)(latestBuilds)) {
        return latestBuilds.filterBy('app.id', this.id).sortBy('createdAt').get('lastObject');
      }
    }),
    isFavorite: bool('favorite'),
    formationDynoCounts: mapBy('formations', 'quantity'),
    // A property that returns the total number of dynos used by this app
    dynoCount: sum('formationDynoCounts'),
    permissionNames: alias('team.appPermissionNames'),
    formattedBuildpackDescription: (0, _object.computed)('buildpackProvidedDescription', function () {
      const description = this.buildpackProvidedDescription || '';
      return description.trim().split(',').join(', ');
    }),
    nameIsValid: alias('validations.attrs.name.isValid'),
    nameIsNotValid: not('nameIsValid'),
    validationMessage: (0, _object.computed)('validations.messages', function () {
      return this.get('validations.messages.firstObject');
    }),
    teamPermissions: filter('team.permissions', function (permission) {
      return permission.get('resourceId') === this.id;
    }),

    checkNameAvailability() {
      if (this.get('nameIsNotValid')) {
        return new _rsvp.default.Promise(resolve => {
          resolve(false);
        });
      } // We always want this promise to resolve


      return new _rsvp.default.Promise(resolve => {
        _jquery.default.ajax({
          method: 'GET',
          url: `${_environment.default.platformApiUrl}/apps/${this.name}`,
          headers: {
            Accept: 'application/vnd.heroku+json; version=3'
          }
        }).then(() => {
          resolve(false);
        }, xhr => {
          // if 404, name IS available
          // otherwise, name is NOT available
          resolve(xhr.status === 404);
        });
      });
    },

    preauth(twoFactorToken) {
      const headers = {
        'Heroku-Two-Factor-Code': twoFactorToken,
        Accept: 'application/vnd.heroku+json; version=3'
      };
      const app = this;
      return new _rsvp.Promise((resolve, reject) => {
        this.store.adapterFor('app').ajax(`${_environment.default.platformApiUrl}/apps/${app.get('id')}/pre-authorizations`, 'PUT', {
          dataType: 'json',
          headers
        }).then(resolve, reject);
      });
    },

    restartDynos() {
      return this.store.adapterFor('app').ajax(`${_environment.default.platformApiUrl}/apps/${this.id}/dynos`, 'DELETE');
    },

    buildpackNames: (0, _object.computed)('buildpackProvidedDescription', function () {
      const buildpackProvidedDescription = this.buildpackProvidedDescription || '';
      return buildpackProvidedDescription.split(/\s*,\s*/g);
    }),
    jvmBuildpackNames: languageFilter(/(clojure|gradle|heroku-deploy|java|jvm|lein|maven|play 2.x|sbt|scala)/i),
    rubyBuildpackNames: languageFilter(/(ruby)/i),
    goBuildpackNames: languageFilter(/^go$/i),
    nodeBuildpacknames: languageFilter(/node.js/i),
    phpBuildpackNames: languageFilter(/php/i),
    runtimeMetricsBuildpackName: languageFilter(/(HerokuRuntimeMetrics)/i),
    isEligibleForJvmMetrics: notEmpty('jvmBuildpackNames'),
    isEligibleForRubyMetrics: notEmpty('rubyBuildpackNames'),
    isEligibleForGoMetrics: notEmpty('goBuildpackNames'),
    isEligibleForNodeMetrics: notEmpty('nodeBuildpacknames'),
    isEligibleForPhpMetrics: notEmpty('phpBuildpackNames'),
    hasRuntimeMetricsBuildpack: notEmpty('runtimeMetricsBuildpackName'),
    isNotEligibleForRubyMetrics: not('isEligibleForRubyMetrics'),
    shouldDisableRuntimeMetricsBuildpack: and('isEligibleForJvmMetrics', 'isNotEligibleForRubyMetrics', 'hasRuntimeMetricsBuildpack'),
    _languageEligibilities: (0, _object.computed)('isEligibleForJvmMetrics', 'isEligibleForRubyMetrics', 'isEligibleForGoMetrics', 'isEligibleForNodeMetrics', function () {
      const languages = [{
        eligible: this.isEligibleForJvmMetrics,
        isGA: true
      }, {
        eligible: this.isEligibleForRubyMetrics,
        isGA: false
      }, {
        eligible: this.isEligibleForGoMetrics,
        isGA: false
      }, {
        eligible: this.isEligibleForNodeMetrics,
        isGA: false
      }];
      return languages.filter(language => {
        return language.eligible;
      });
    }),
    _languageEligibilitiesGA: (0, _computed.filterBy)('_languageEligibilities', 'isGA'),
    isEligibleForLanguageMetrics: (0, _computed.gt)('_languageEligibilities.length', 0),
    isEligibleForMultipleLanguages: (0, _computed.gt)('_languageEligibilities.length', 1),
    isEligibleForLanguageMetricsGA: (0, _computed.gt)('_languageEligibilitiesGA.length', 0),
    isEligibleForMultipleLanguagesGA: (0, _computed.gt)('_languageEligibilitiesGA.length', 1),
    hasRuntimeMetricsEnabled: isFeatureEnabled('runtime-heroku-metrics'),
    hasGoMetricsEnabled: isFeatureEnabled('go-language-metrics'),
    hasRubyMetricsEnabled: isFeatureEnabled('ruby-language-metrics'),
    hasNodeMetricsEnabled: isFeatureEnabled('nodejs-language-metrics'),
    hasMemoryPagingChartEnabled: isFeatureEnabled('metrics-paging-chart'),
    hasMultipleSniEndpointsEnabled: true,
    doesNotHaveNodeMetricsEnabled: not('hasNodeMetricsEnabled'),
    doesNotHaveRubyMetricsEnabled: not('hasRubyMetricsEnabled'),
    hasSlug: (0, _computed.gt)('slugSize', 0),
    hasFreeDynos: and('isFreeTier', 'hasSlug'),
    // This requires the addons to be preloaded
    hasFreeHerokuDataAddons: (0, _object.computed)('addons.[]', function () {
      return this.addons.any(addon => addon.isFreeHerokuData);
    }),

    _checkFeatureIsEnabled(featureName) {
      const feature = this.get('features').isDestroyed ? null : (0, _object.get)(this, 'features').findBy('name', featureName);
      return !!(feature && (0, _object.get)(feature, 'enabled'));
    },

    lock() {
      this.set('locked', true);
      this.save({
        adapterOptions: {
          teamAppUpdate: true
        }
      });
    },

    unlock() {
      this.set('locked', false);
      this.save({
        adapterOptions: {
          teamAppUpdate: true
        }
      });
    },

    // enable ACM for an app
    enableACM() {
      return this.save({
        adapterOptions: {
          acm: true,
          updateMethod: 'POST'
        }
      });
    },

    // disable ACM for an app
    disableACM() {
      return this.save({
        adapterOptions: {
          acm: true,
          updateMethod: 'DELETE'
        }
      });
    },

    // refresh ACM for an app
    refreshACM() {
      return this.save({
        adapterOptions: {
          acm: true,
          updateMethod: 'PATCH'
        }
      });
    },

    stackCheck: (0, _object.computed)(function () {
      return _stack.default.create({
        app: this
      });
    }),

    upgradeToLatestStack() {
      this.set('buildStack', this.get('stackCheck.latestStack'));
      return this.save();
    },

    cancelStackUpgrade() {
      this.set('buildStack', this.stack.name);
      return this.save();
    },

    updateDynoTier(newTier) {
      this.formations.forEach(formation => {
        formation.resizeByTierName(newTier);
      });
      return this.saveBulkFormation();
    },

    // TODO: Move this over to our new single app transfer endpoint
    // and make sure it mirrors functionality
    transferToTeam(newOwner) {
      return new _rsvp.default.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          url: `${_environment.default.platformApiUrl}/teams/apps/${this.id}`,
          data: JSON.stringify({
            owner: newOwner
          }),
          method: 'PATCH',
          contentType: 'application/json',
          headers: {
            Accept: 'application/vnd.heroku+json; version=3'
          },
          withHerokuAuthorization: true
        }).then(function () {
          resolve();
        }, error => {
          reject(error);
        });
      });
    },

    decorateWithDynoData() {
      this.hasMany('formations').reload().then(() => {
        this.set('hasLoadedDynoData', true);
      });
    },

    // appState is set in the app route's afterModel hook
    state: (0, _object.computed)('appState.state', function () {
      return this.get('appState.state') || 'unknown';
    }),

    didRealtimeUpdate() {
      const appStateReference = this.belongsTo('appState');
      const isLoaded = !!appStateReference.value();

      if (isLoaded) {
        appStateReference.reload();
      }
    },

    reloadEndpoints() {
      if (this.shouldUseSslEndpoints) {
        return this.hasMany('sslEndpoints').reload();
      } else {
        return this.hasMany('sniEndpoints').reload();
      }
    }

  });

  _exports.default = _default;
});