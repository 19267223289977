define("dashboard/initializers/user-preferences", ["exports", "dashboard/services/user-preferences"], function (_exports, _userPreferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    const serviceLookupName = 'service:user-preferences';
    const serviceName = 'userPreferencesService';
    application.register(serviceLookupName, _userPreferences.default);
    application.inject('route', serviceName, serviceLookupName);
    application.inject('controller', serviceName, serviceLookupName);
    application.inject('component', serviceName, serviceLookupName);
    application.inject(serviceLookupName, 'application', 'application:main');
  }

  var _default = {
    name: 'user-preferences',
    initialize
  };
  _exports.default = _default;
});