define("dashboard/components/identity-provider/migrate-confirmation", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    selectedIdentityProvider: null,
    hasSingleTeamIdentityProvider: (0, _computed.equal)('teamIdentityProviders.length', 1),
    myTeamIdentityProviders: (0, _computed.filterBy)('teamIdentityProviders', 'ownerRole'),
    otherTeamIdentityProviders: (0, _computed.setDiff)('teamIdentityProviders', 'myTeamIdentityProviders'),
    identityProviderToMigrate: (0, _object.computed)('selectedIdentityProvider', 'hasSingleTeamIdentityProvider', function () {
      if (this.hasSingleTeamIdentityProvider) {
        return this.teamIdentityProviders.firstObject;
      }

      return this.selectedIdentityProvider;
    }),

    selectIdentityProvider(id) {
      const teamIdentityProviders = this.teamIdentityProviders;
      const selected = teamIdentityProviders.findBy('id', id);
      this.set('selectedIdentityProvider', selected);
    }

  });

  _exports.default = _default;
});