define("dashboard/components/toggle-switch", ["exports", "@ember/object", "@ember/runloop", "@ember/component"], function (_exports, _object, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['toggle-switch'],
    classNameBindings: ['disabled'],
    name: null,
    checked: false,
    disabled: false,

    click() {
      if (!this.disabled) {
        // we need to give the native browser behavior of clicking the label in this component
        // the chance to actually run and update the value of the input
        // before we send out this action
        //
        // without this, chrome and safari behave differently
        (0, _runloop.next)(this, function () {
          if (this.onToggle) {
            this.onToggle(this.checked);
          }
        });
      } else {
        return false;
      }
    },

    checkBoxId: (0, _object.computed)(function () {
      return `checker-${this.elementId}`;
    })
  });

  _exports.default = _default;
});