define("dashboard/serializers/github-app-link", ["exports", "dashboard/serializers/application", "dashboard/config/environment", "@ember/utils", "@ember/object"], function (_exports, _application, _environment, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      const appId = hash.app.id;
      hash.links = {
        kolkrabbiApps: `/apps/${appId}/github/pull-requests`
      };
      return this._super(...arguments);
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      relationships.app = {
        data: {
          id: hash.app.id,
          type: 'app'
        }
      };
      relationships.githubBranches = {
        links: {
          related: `${_environment.default.kolkrabbiUrl}/apps/${hash.app.id}/github/branches`
        }
      };

      if (hash.parent) {
        relationships.parentApp = {
          data: {
            id: hash.parent.app_id,
            type: 'app'
          }
        };
      }

      const githubOwnerId = (0, _object.get)(hash, 'owner.github.user_id');

      if ((0, _utils.isPresent)(githubOwnerId)) {
        relationships.githubOwner = {
          data: {
            id: githubOwnerId,
            type: 'github/user'
          }
        };
      }

      return relationships;
    },

    serialize(snapshot) {
      if (snapshot.record.get('isNew')) {
        return {
          repo: snapshot.record.get('repo')
        };
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});