define("dashboard/routes/buildpack-registry/connect-github", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadcrumbs: (0, _service.inject)(),

    renderTemplate() {
      this.set('breadcrumbs.trail', {
        location: 'Buildpack Registration - Connect to GitHub'
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav',
        into: 'protected'
      });

      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});