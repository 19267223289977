define("dashboard/templates/components/org/org-delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Nj7YgRZr",
    "block": "[[[6,[39,0],null,[[\"title\",\"sectionClass\"],[\"Delete Team\",\"danger\"]],[[\"default\"],[[[[41,[30,0,[\"canDelete\"]],[[[1,\"    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"buttonTestTarget\",\"buttonClassNames\",\"buttonText\",\"modalTitle\",\"modalActionButtonText\",\"confirmationTargetValue\",\"onConfirm\"],[\"delete-org-button\",\"hk-button--danger\",\"Delete team...\",\"Delete team\",\"Delete Team\",[30,0,[\"orgName\"]],[28,[37,3],[[30,0],[30,0,[\"onDelete\"]]],null]]],[[\"default\"],[[[[6,[39,4],null,[[\"on-validate\"],[[30,1]]],[[\"default\"],[[[[1,\"            Deleting your team is irreversible. Enter your team's name (\"],[10,\"code\"],[12],[1,[30,0,[\"orgName\"]]],[13],[1,\") below to confirm you want to permanently delete this team:\\n\"]],[]]]]]],[1]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[12],[1,\"\\n      This is an Enterprise Team. Please open a ticket at \"],[10,3],[14,6,\"https://help.heroku.com\"],[14,0,\"hk-link\"],[12],[1,\"https://help.heroku.com\"],[13],[1,\" to have this team deleted.\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[\"validate\"],false,[\"list-group-item-purple2\",\"if\",\"confirm/confirmable-action\",\"action\",\"confirm/with-validation\"]]",
    "moduleName": "dashboard/templates/components/org/org-delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});