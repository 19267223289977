define("dashboard/templates/components/datastore/kafka/settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "T0k9CV+Q",
    "block": "[[[10,\"ul\"],[14,0,\"list-group list-group-lg\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"description\"],[\"Heroku Kafka via PrivateLink\",\"Generate and manage PrivateLink endpoints to secure database access from outside your Private Space.\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@privateLink\",\"@addon\",\"@kickOffPrivateLinkPolling\"],[[30,0,[\"privateLink\"]],[30,0,[\"addon\"]],[28,[37,2],[[30,0],\"kickOffPrivateLinkPolling\"],null]]],null],[1,\"\\n\"]],[]]]]],[13]],[],false,[\"list-group-item-purple2\",\"datastore/private-link/config\",\"action\"]]",
    "moduleName": "dashboard/templates/components/datastore/kafka/settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});