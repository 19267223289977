define("dashboard/templates/components/identity-provider/state-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "48N55a7R",
    "block": "[[[10,0],[14,0,\"h4 flex items-center justify-center\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"title\"],[\"Loading SSO configuration…\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"loading-spinner\"]]",
    "moduleName": "dashboard/templates/components/identity-provider/state-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});