define("dashboard/components/datastore/private-link/config", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    notEmpty,
    mapBy
  } = _object.computed;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    enabled: notEmpty('privateLink'),
    awsAccountInputs: (0, _object.computed)(function () {
      return [_object.default.create({
        value: ''
      })];
    }),
    lastAWSAccountInput: (0, _object.computed)('awsAccountInputs.[]', function () {
      return this.awsAccountInputs.get('lastObject');
    }),
    isLastAWSAccountInputPresent: notEmpty('lastAWSAccountInput.value'),
    allowlistedAccounts: mapBy('awsAccountInputs', 'value'),
    actions: {
      async createPrivateLink(allowlistedAccounts, dismiss) {
        this.analytics.logEvent('PrivateLink Create', 'Clicked');
        await this.store.createRecord('shogun/private-link', {
          addon: this.addon,
          allowedAccounts: allowlistedAccounts
        }).save();
        this.kickOffPrivateLinkPolling();
        dismiss();
      },

      async destroyPrivateLink(privateLink, dismiss) {
        this.analytics.logEvent('PrivateLink Disable', 'Clicked');
        privateLink.set('addon', this.addon);

        if (privateLink.get('content')) {
          // when fetching the privateLink that was created while the page was open, it is wrapped as "content"
          await privateLink.get('content').destroyRecord();
        } else {
          // when fetching the privateLink that was loaded through the route on page load, it is not wrapped
          await privateLink.destroyRecord();
        }

        await this.kickOffPrivateLinkPolling();
        dismiss();
      },

      enablingPrivateLink() {
        this.analytics.logEvent('PrivateLink Enable', 'Clicked');
        this.set('isEnabling', true);
      },

      onDismissSlidePanel() {
        this.set('isEnabling', false);
      },

      addAWSAccountInput() {
        this.awsAccountInputs.pushObject(_object.default.create({
          value: ''
        }));
      },

      removeAWSAccountInput(idx) {
        this.awsAccountInputs.removeAt(idx);
      }

    }
  });

  _exports.default = _default;
});