define("dashboard/templates/app/loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UHFUS2Qd",
    "block": "[[[1,[34,0]],[1,\"\\n\"]],[],false,[\"loading-state\"]]",
    "moduleName": "dashboard/templates/app/loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});