define("dashboard/serializers/build", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships(modelClass, resourceHash) {
      const relationships = this._super(...arguments);

      relationships.app = {
        data: {
          type: 'app',
          id: resourceHash['app']['id']
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});