define("dashboard/adapters/space/peering", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.dogwood',
    updateMethod: 'POST',

    urlForQuery(query) {
      const spaceId = query.spaceId;
      delete query.spaceId;
      return `${this.host}/spaces/${spaceId}/peerings`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const spaceId = snapshot.record.get('space.id');
      return `${this.host}/spaces/${spaceId}/peerings`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const spaceId = snapshot.record.get('space.id');
      const peeringId = snapshot.record.pcxId;
      return `${this.host}/spaces/${spaceId}/peerings/${peeringId}`;
    }

  });

  _exports.default = _default;
});