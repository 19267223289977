define("dashboard/routes/app/join", ["exports", "dashboard/routes/basic-app", "dashboard/mixins/document-title"], function (_exports, _basicApp, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend(_documentTitle.default, {
    redirect() {
      this.transitionTo('app');
    }

  });

  _exports.default = _default;
});