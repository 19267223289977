define("dashboard/controllers/team/switchable/billing/pay-balance", ["exports", "@ember/service", "@ember/controller", "rsvp", "@ember/object", "@ember/object/computed", "dashboard/models/pay-balance", "dashboard/config/environment"], function (_exports, _service, _controller, _rsvp, _object, _computed, _payBalance, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    title: 'Pay Balance',
    team: (0, _computed.alias)('model.team'),
    invoices: (0, _computed.alias)('team.invoices'),
    selectedInvoices: (0, _computed.filterBy)('invoices', 'isSelected', true),
    declinedInvoices: (0, _computed.filterBy)('invoices', 'state', -1),
    noDeclinedInvoices: (0, _computed.empty)('declinedInvoices'),
    invoicesWithFailedAttempt: (0, _computed.filterBy)('invoices', 'attemptedPaymentFailed'),
    invoicePayments: (0, _object.computed)('selectedInvoices', function () {
      const url = `${_environment.default.platformApiUrl}/teams/${this.get('team.id')}/payments`;
      return this.selectedInvoices.map(invoice => {
        return _payBalance.default.create({
          invoice,
          url
        });
      });
    }),
    actions: {
      pay() {
        this.set('isSaving', true);

        _rsvp.default.all(this.invoicePayments.invoke('pay')).then(() => {
          if (this.noDeclinedInvoices) {
            this.transitionToRoute('team.switchable.billing', this.get('team.name'));
          }
        }).finally(() => {
          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});