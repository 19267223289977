define("dashboard/services/phoenix-socket", ["exports", "@ember/service", "phoenix", "rsvp", "@ember/object", "@ember/debug", "dashboard/config/environment", "dashboard/services/web-socket"], function (_exports, _service, _phoenix, _rsvp, _object, _debug, _environment, _webSocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const wss = `${_environment.default.particleboardSocketUrl}/socket`;

  var _default = _service.default.extend({
    session: (0, _service.inject)(),
    // Creating a channel with `socket.channel(topic, params)`, binds the params to
    // `channel.params`, which are sent up on `channel.join()`.
    // Subsequent rejoins will send up the modified params for
    // updating authorization params...
    syncCredentials: (0, _object.computed)('session.data.authenticated.access_token', function () {
      const accessToken = this.get('session.data.authenticated.access_token');
      this.get('socket.channels').forEach(channel => {
        channel.params.token = accessToken;
      });
    }),
    // TODO: This is copied from the web-socket service.
    // We should probably update the Web Console to use the WebSocket service
    // instead of this phoenix-socket service. It looks like we are duplicating
    // effort in both places.
    _socketParams: (0, _object.computed)('session.bearerToken', 'session.isAssumed', 'session.sudoData', function () {
      const params = {
        token: this.session.bearerToken
      };

      if (this.session.isAssumed) {
        const sudoData = this.session.sudoData;
        params.token = sudoData.sudoToken;
        params.heroku_sudo = true;
        params.heroku_sudo_reason = sudoData.sudoReason;
        params.heroku_sudo_user = sudoData.user_id;
      }

      return params;
    }),

    // Returns a promise that resolves when the socket opens
    // or rejects on a socket error
    connect() {
      let socket = this.socket;

      if (socket && socket.isConnected) {
        // console.log('socket.isConnected');
        return _rsvp.Promise.resolve(socket);
      } else {
        // connect the socket
        socket = new _phoenix.Socket(wss, {
          params: this._socketParams,
          heartbeatIntervalMs: 20000,
          reconnectAfterMs: tries => (0, _webSocket.backoffInterval)(tries, _webSocket.reconnectRetryRange)
        });
        this.set('socket', socket);
        return new _rsvp.Promise((resolve, reject) => {
          socket.connect();
          socket.onOpen(() => {
            // console.log("socket.onOpen");
            resolve(socket);
          });
          socket.onError(err => {
            // console.log("socket.onError");
            // console.error(err);
            reject(err);
          });
        });
      }
    },

    // join a channel
    join(channelName) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const socket = this.socket;
      (false && !(socket) && (0, _debug.assert)('must connect to a socket first', socket));
      const accessToken = this.get('session.data.authenticated.access_token');
      const auth = {
        token: accessToken
      };
      const paramsWithAuth = {};
      Object.assign(paramsWithAuth, auth, params);
      const channel = socket.channel(channelName, paramsWithAuth);
      return {
        channel,
        connection: channel.join()
      }; // Pro Tip™: you can setup the following events on this action
      // channel.join()
      //   .receive("ok", ({messages}) => console.log("catching up", messages) )
      //   .receive("error", ({reason}) => console.log("failed join", reason) )
      //   .receive("timeout", () => console.log("Networking issue. Still waiting...") )
    },

    disconnect() {
      if (this.socket) {
        this.socket.disconnect();
      }
    },

    // unjoin a channel
    unjoin(channel) {
      (false && !(channel) && (0, _debug.assert)('must provide a channel', channel));
      channel.leave();
    }

  });

  _exports.default = _default;
});