define("dashboard/components/confirm-transfer-app", ["exports", "@ember/component", "@ember/object", "@ember/string"], function (_exports, _component, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['transfer-request', 'message-banner'],
    'data-test-target': 'transfer-request',
    rejectModalTitle: (0, _object.computed)('transfer.app.name', function () {
      return (0, _string.htmlSafe)(`Reject ownership <small>(${this.get('transfer.app.name')})</small>`);
    }),
    acceptModalTitle: (0, _object.computed)('transfer.app.name', function () {
      return (0, _string.htmlSafe)(`Accept ownership <small>(${this.get('transfer.app.name')})</small>`);
    })
  });

  _exports.default = _default;
});