define("dashboard/templates/components/app/settings/domain-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EugboY5s",
    "block": "[[[10,0],[14,0,\"modal-body u-padding-Bn\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"form-group u-margin-Bn\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"domain-name\"],[12],[1,\"Domain Name\"],[13],[1,\"\\n          \"],[1,[28,[35,0],null,[[\"inputClass\",\"value\",\"placeholder\",\"model\",\"validatingKey\",\"errorIsVisible\"],[\"form-control new-domain-name\",[30,0,[\"item\",\"hostname\"]],\"yourdomain.com\",[30,0,[\"item\"]],\"hostname\",[30,0,[\"item\",\"errorIsVisible\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"cp-validating-input\"]]",
    "moduleName": "dashboard/templates/components/app/settings/domain-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});