define("dashboard/components/enterprise-usage/active-licenses", ["exports", "@ember/component", "@ember/object", "dashboard/models/organization-plan"], function (_exports, _component, _object, _organizationPlan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    uniq: (0, _object.computed)('organizationLicenses.[]', function () {
      const uniqLicenseData = new Map();
      this.organizationLicenses.forEach(function (license) {
        const uniqKeys = [];
        ['code', 'qty', 'consumed', 'startDate', 'endDate'].forEach(function (key) {
          uniqKeys.push(license.get(key));
        });
        const key = uniqKeys.join('-');
        uniqLicenseData.set(key, license);
      });
      return Array.from(uniqLicenseData.values());
    }),
    visible: (0, _object.computed)('uniq', function () {
      return this.uniq.filterBy('isActive').filterBy('isLicenseValid').sortBy('endDate');
    }),
    // Group licenses by validity dates
    plans: (0, _object.computed)('visible.@each.startDate', 'visible.@each.endDate', function () {
      const plans = [];
      const licenses = this.visible;
      licenses.forEach(function (license) {
        const startDate = license.get('startDate');
        const endDate = license.get('endDate');
        const validPlan = plans.find(function (plan) {
          return isEqual(plan.get('startDate'), startDate) && isEqual(plan.get('endDate'), endDate);
        });

        if (!validPlan) {
          // Create a plan with all the licenses with the same start and end dates
          const planLicenses = licenses.filter(function (lic) {
            return isEqual(lic.get('startDate'), startDate) && isEqual(lic.get('endDate'), endDate);
          }).sort((a, b) => {
            return a.compare(b);
          });
          plans.pushObject(_organizationPlan.default.create({
            licenses: planLicenses
          }));
        }
      }, this);
      return plans;
    })
  });

  _exports.default = _default;

  function isEqual(a, b) {
    if (a instanceof Date && b instanceof Date) {
      a = a.valueOf();
      b = b.valueOf();
    }

    return a === b;
  }
});