define("dashboard/templates/app/resources/attachments", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yT0LBHQI",
    "block": "[[[6,[39,0],null,[[\"slideFrom\",\"clickOverlayToClose\",\"close\"],[\"right\",true,[28,[37,1],[[30,0],\"closeSlidePanel\"],null]]],[[\"default\"],[[[[6,[30,1,[\"header\"]],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"flex-auto ml5\"],[12],[1,\"Add-on Attachments\"],[13],[1,\"\\n\"]],[]]]]],[6,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[6,[39,2],null,[[\"addon\",\"app\"],[[30,0,[\"model\",\"addon\"]],[30,0,[\"model\",\"app\"]]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,3],null,[[\"addon\",\"currentApp\",\"deleteAttachmentAction\",\"linkOutUrl\",\"linkOutTarget\"],[[30,0,[\"model\",\"addon\"]],[30,0,[\"model\",\"app\"]],[28,[37,1],[[30,0],\"deleteAttachment\"],null],[30,2,[\"webUrlForApp\"]],[30,2,[\"webUrlTarget\"]]]]]],[1,\"\\n\"]],[2]]]]]],[]]]]],[6,[30,1,[\"confirmableAction\"]],null,[[\"primaryButtonText\",\"isShowingConfirm\",\"onDismiss\",\"onConfirm\"],[\"Remove Attachment\",[30,0,[\"isShowingDeleteAttachmentConfirm\"]],[28,[37,1],[[30,0],[28,[37,4],[[30,0,[\"isShowingDeleteAttachmentConfirm\"]]],null],false],null],[28,[37,1],[[30,0],\"confirmDeleteAttachment\",[30,0,[\"attachment\"]],[30,1,[\"dismiss\"]]],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"bb b--silver pb2 mb1\"],[12],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"tc f2 ph4 pb2 dark-gray lh-copy\"],[12],[1,\"Are you sure you want to remove this attachment?\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"hk-message--generic bg-lightest-silver flex mb2\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"Removing this attachment will not remove the add-on or its associated data.\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[1]]]]]],[\"panel\",\"result\"],false,[\"hk-slide-panel\",\"action\",\"app/addons-sso-link\",\"addons/manage-attachments\",\"mut\"]]",
    "moduleName": "dashboard/templates/app/resources/attachments.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});