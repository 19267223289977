define("dashboard/utils/realtime/context", ["exports", "@ember/application", "@ember/object/mixin"], function (_exports, _application, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RealtimeContext = void 0;

  class ChannelContext {
    constructor(channel) {
      this._channel = channel;
      this._events = [];
    }

    on(eventName, handler) {
      const ref = this._channel.on(eventName, handler);

      this._events.push({
        eventName,
        ref
      });
    }

    unsubscribeAll() {
      this._events.forEach(_ref => {
        let {
          eventName,
          ref
        } = _ref;

        this._channel.off(eventName, ref);
      });

      this._events = [];
    }

    leave() {
      this._channel.leave();

      this._channel = null;
    }

  }

  class RealtimeContext {
    constructor(webSocket, githubToken) {
      this._webSocket = webSocket;
      this._channels = {};
      this._githubToken = githubToken;
    }

    _joinChannel(channelName, params) {
      if (!(channelName in this._channels)) {
        const channel = this._webSocket.join(channelName, params).channel;

        this._channels[channelName] = new ChannelContext(channel);
      }

      return this._channels[channelName];
    }

    _getChannel(channelName) {
      return this._channels[channelName];
    }

    _onEvent(channelName, event, callback) {
      let params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      this._joinChannel(channelName, params);

      this._getChannel(channelName).on(event, callback);
    }

    onGithubPush(repository, event, callback) {
      const channelName = `github-repositories/${repository}/github-pushes`;

      this._onEvent(channelName, event, callback);
    }

    onGithubCommitStatus(repository, event, callback) {
      const channelName = `github-repositories/${repository}/github-commit-statuses`;

      this._onEvent(channelName, event, callback);
    }

    onGithubPullRequest(repo, event, callback) {
      const channelName = `github-repositories/${repo}/github-pull-requests`;

      this._onEvent(channelName, event, callback);
    }

    onCollaborator(user, event, callback) {
      const channelName = `users/${user}/collaborators`;

      this._onEvent(channelName, event, callback);
    }

    onTestRun(pipeline, event, callback) {
      const channelName = `pipelines/${pipeline}/test-runs`;

      this._onEvent(channelName, event, callback);
    } // unsubscribe to all room events and leave all rooms


    destroy() {
      Object.keys(this._channels).forEach(name => {
        const channel = this._channels[name];
        channel.unsubscribeAll();
        channel.leave();
      });
      this._channels = {};
    }

  }

  _exports.RealtimeContext = RealtimeContext;

  var _default = _mixin.default.create({
    init() {
      this._super(...arguments);

      this.on('deactivate', () => {
        this.realtimeContext.destroy();
      });
    },

    activate() {
      const webSocket = (0, _application.getOwner)(this).lookup('service:web-socket');
      const githubToken = (0, _application.getOwner)(this).lookup('service:github-token').get('token.token');
      this.realtimeContext = new RealtimeContext(webSocket, githubToken);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});