define("dashboard/components/account/billing/update-credit-card", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "moment"], function (_exports, _component, _object, _computed, _service, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    accountFeatures: (0, _service.inject)(),
    current: (0, _service.inject)(),
    account: (0, _computed.alias)('current.account'),
    cardType: (0, _computed.alias)('paymentMethod.card_type'),
    showRemoveCreditCard: false,
    nextEligibleSignupPeriod: (0, _object.computed)(function () {
      return (0, _moment.default)().add('1', 'M').startOf('month');
    }),
    actions: {
      closeVerifyModal() {
        if (!this.isDestroying && !this.isDestroyed) {
          this.set('showVerify', false);
        }
      },

      showPaymentMethodModal() {
        this.set('showVerify', true);
      }

    }
  });

  _exports.default = _default;
});