define("dashboard/adapters/test-case", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query) {
      const host = this.host;
      const testRunId = query.testRunId;
      delete query['testRunId'];
      return `${host}/test-runs/${testRunId}/test-cases`;
    }

  });

  _exports.default = _default;
});