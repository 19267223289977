define("dashboard/components/datastore/postgres/overview", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    not
  } = _object.computed;

  var _default = _component.default.extend({
    isHobbyOrBasic: (0, _object.computed)('addon', function () {
      const planName = this.get('addon.planName');
      return planName.match(/hobby|basic|mini/);
    }),
    isNotHobbyOrBasic: not('isHobbyOrBasic')
  });

  _exports.default = _default;
});