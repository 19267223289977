define("dashboard/models/kolkrabbi/app", ["exports", "ember-data", "dashboard/models/kolkrabbi/app-setup", "@ember/utils", "@ember/object", "ember-data/relationships", "@ember/application", "ember-concurrency"], function (_exports, _emberData, _appSetup, _utils, _object, _relationships, _application, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal,
    bool,
    match,
    alias,
    gt
  } = _object.computed;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    appSetup: attr(),
    pullRequest: attr(),
    app: attr(),
    appId: attr(),
    parentAppId: attr(),
    parentGithubAppLink: (0, _relationships.belongsTo)('github-app-link'),
    isDeployed: true,
    url: alias('pullRequest.url'),
    isPending: equal('appSetup.status', 'pending'),
    isFailed: equal('appSetup.status', 'failed'),
    isSucceeded: equal('appSetup.status', 'succeeded'),
    hasAppSetup: bool('appSetup.id'),
    hasNoAppJSON: match('appSetup.failure_message', /^An app.json is required/),
    number: alias('pullRequest.number'),
    postDeployFailed: gt('appSetup.postdeploy.exitCode', 0),
    deployBranch: (0, _object.computed)('appSetup.failure_message', function () {
      const message = this.get('appSetup.failure_message');

      if ((0, _utils.isNone)(message)) {
        return;
      }

      return message.split('An app.json is required on branch ')[1];
    }),
    kolkrabbiAppSetup: (0, _object.computed)('appSetup', function () {
      return _appSetup.default.create((0, _application.getOwner)(this).ownerInjection(), this.appSetup);
    }),
    subscribeToAppSetupTask: (0, _emberConcurrency.task)(function* () {
      const appSetup = this.kolkrabbiAppSetup;
      const appSetupIdTask = this.kickoffKolkrabbiAppSetupPoll(); // Make sure app-setup id exists before we set up real-time subscriptions

      yield appSetupIdTask; // Reload the app once we have an app-setup id.
      // If it succeeded, reload forces the success state (unlikely by this time)
      // If it failed, reload forces the failure state
      // If it's pending, reload populates the attrs

      this.reload(); // Don't set up WS if it instantly succeeded or more likely quickly failed

      if (appSetup.get('isDoneOrFailed')) {
        return;
      }

      if (this.isSubscribedToAppSetup) {
        return;
      }

      this.set('isSubscribedToAppSetup', true); // App setup events are a bit weird because they only emit a create event
      // once the setup is complete. Because of this we treat it as an update
      // for the app setup we already have in memory. We also handle the update
      // event case just in case they event gets fixed in the future.

      const subscription = appSetup.subscribeToUpdates();
      subscription.on('create', _ref => {
        let {
          data
        } = _ref;
        this.set('appSetup', data);

        if (data.status !== 'pending') {
          this.trigger('appSetupUpdated');
        }
      });
      subscription.on('update', _ref2 => {
        let {
          data
        } = _ref2;
        this.set('appSetup', data);

        if (data.status !== 'pending') {
          this.trigger('appSetupUpdated');
        }
      }); // This task completes when the app-setup succeeds or fails

      const appSetupStatusTask = this.get('kolkrabbiAppSetup.fetchStatusTask.last');
      const status = yield appSetupStatusTask;

      if (status !== this.get('appSetup.status')) {
        // If we got here, it means that the app-setup succeeded or failed, but
        // the WS never notified the app, so we reload the app to get the
        // latest status.
        this.reload();
      }
    }),

    kickoffKolkrabbiAppSetupPoll() {
      const kolkrabbiAppSetup = _appSetup.default.create((0, _application.getOwner)(this).ownerInjection(), this.appSetup);

      kolkrabbiAppSetup.set('prAppId', this.id);
      this.set('kolkrabbiAppSetup', kolkrabbiAppSetup);
      const fetchIdTask = kolkrabbiAppSetup.get('fetchIdTask').perform();
      fetchIdTask.then(() => {
        kolkrabbiAppSetup.fetchStatus(5000);
      }, () => {});
      return fetchIdTask;
    },

    subscribeToAppSetup() {
      this.subscribeToAppSetupTask.perform();
    },

    unsubscribeFromAppSetup() {
      this.set('isSubscribedToAppSetup', false);
      this.kolkrabbiAppSetup.unsubscribeFromUpdates();
    }

  });

  _exports.default = _default;
});