define("dashboard/components/app/settings/buildpack-installations-list-item", ["exports", "dashboard/components/purple-list-row", "@ember/object/computed", "@ember/object"], function (_exports, _purpleListRow, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purpleListRow.default.extend({
    item: (0, _computed.alias)('buildpack'),
    classNames: ['buildpack-item', 'dt', 'w-100'],
    isSetViaConfigVar: (0, _object.computed)('buildpackUrlConfigValue', 'hasMultipleBuildpackInstallations', function () {
      // This is only the case when there's only one buildpack and it matches the config var value.
      if (this.get('hasMultipleBuildpackInstallations')) {
        return false;
      }

      return this.get('buildpackUrlConfigValue') === this.get('item.url');
    })
  });

  _exports.default = _default;
});