define("dashboard/templates/components/app-json-addon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HW4thIRe",
    "block": "[[[10,\"td\"],[14,0,\"addon-icon\"],[12],[1,[28,[35,0],null,[[\"name\"],[[30,0,[\"addon\",\"providerName\"]]]]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"addon-title\"],[12],[1,[30,0,[\"addon\",\"providerDescription\"]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"addon-plan\"],[12],[1,[30,0,[\"addon\",\"planDescription\"]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"text-right addon-price\"],[12],[1,\"\\n\"],[41,[30,0,[\"addon\",\"isAttached\"]],[[[1,\"    Attached\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"addon\"]]],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"addon-icon\",\"if\",\"addon-price-format\"]]",
    "moduleName": "dashboard/templates/components/app-json-addon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});