define("dashboard/models/app/webhook-include", ["exports", "ember-data/model", "ember-data/attr", "@ember/object/computed"], function (_exports, _model, _attr, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    isGa: (0, _computed.equal)('state', 'ga')
  });

  _exports.default = _default;
});