define("dashboard/templates/components/scheduler/jobs-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dFILnw9n",
    "block": "[[[10,\"table\"],[14,0,\"w-100 mv2 limit-width\"],[12],[1,\"\\n  \"],[10,\"thead\"],[14,0,\"dark-gray tl f5 fw6 pv2\"],[12],[1,\"\\n    \"],[10,\"th\"],[14,0,\"dtc pb1 sticky top--glostick z-1 db pl2 pv2 bb b--light-gray bg-white\"],[12],[1,\"\\n      Job\\n    \"],[13],[1,\"\\n    \"],[10,\"th\"],[14,0,\"dtc pb1 ttc sticky top--glostick z-1 db pv2 ph2 bb b--light-gray bg-white\"],[12],[1,\"\\n      Dyno size\\n    \"],[13],[1,\"\\n    \"],[10,\"th\"],[14,0,\"dtc pb1 ttc sticky top--glostick z-1 db pv2 ph2 bb b--light-gray bg-white\"],[12],[1,\"\\n      Frequency\\n    \"],[13],[1,\"\\n    \"],[10,\"th\"],[14,0,\"dtc pb1 ttc sticky top--glostick z-1 db pv2 ph2 bb b--light-gray bg-white\"],[12],[1,\"\\n      Last run\\n    \"],[13],[1,\"\\n    \"],[10,\"th\"],[14,0,\"dtc pb1 ttc sticky top--glostick z-1 db pv2 ph2 bb b--light-gray bg-white\"],[12],[1,\"\\n      Next due\\n    \"],[13],[1,\"\\n    \"],[10,\"th\"],[14,0,\"dtc w2 pb1 tc sticky top--glostick z-1 db pv2 ph2 bb b--light-gray bg-white\"],[12],[1,\"\\n       \\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"displayedJobs\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,2],null,[[\"currentTier\",\"jobsLength\",\"job\",\"index\",\"editJob\",\"deleteJob\"],[[30,0,[\"currentTier\"]],[30,0,[\"displayedJobs\",\"length\"]],[30,1],[30,2],[30,0,[\"editJob\"]],[30,0,[\"deleteJob\"]]]]]],[1,\"\\n\"]],[1,2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"job\",\"index\"],false,[\"each\",\"-track-array\",\"scheduler/jobs-list-item\"]]",
    "moduleName": "dashboard/templates/components/scheduler/jobs-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});