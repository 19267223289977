define("dashboard/models/space/peering-info", ["exports", "@ember/object/computed", "ember-data/relationships", "ember-data/attr", "ember-data/model"], function (_exports, _computed, _relationships, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    space: (0, _relationships.belongsTo)('space'),
    awsAccountId: (0, _attr.default)('string'),
    awsRegion: (0, _attr.default)('string'),
    dynoCidrBlocks: (0, _attr.default)(),
    unavailableCidrBlocks: (0, _attr.default)(),
    vpcCidr: (0, _attr.default)('string'),
    vpcId: (0, _attr.default)('string'),
    spaceId: (0, _computed.alias)('space')
  });

  _exports.default = _default;
});