define("dashboard/models/production-check/postgres-high-availability", ["exports", "dashboard/mixins/production-check", "@ember/object", "@ember/object/computed"], function (_exports, _productionCheck, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    devCenterURL: 'https://devcenter.heroku.com/articles/heroku-postgres-ha',
    title: 'Postgres High Availability',
    skippedMessage: 'No postgres database.',
    warningMessage: (0, _object.computed)('hasHobbyPostgres', function () {
      return `Using ${this.hasHobbyPostgres ? 'an Essential' : 'a Standard'} Tier Postgres database. Upgrade to a Premium Tier database for High Availability.`;
    }),
    postgresAttachments: (0, _computed.filterBy)('app.attachments', 'isPostgres', true),
    postgresAddons: (0, _computed.mapBy)('postgresAttachments', 'addon'),
    postgresPlans: (0, _computed.mapBy)('postgresAddons', 'embeddedAddonServicePlan'),
    postgresPlanNames: (0, _computed.mapBy)('postgresPlans', 'name'),
    hasHobbyPostgres: (0, _object.computed)('postgresPlanNames', function () {
      return this.postgresPlanNames.some(plan_name => new RegExp(/hobby|basic|mini/).test(plan_name));
    }),
    hasStandardPostgres: (0, _object.computed)('postgresPlanNames', function () {
      return this.postgresPlanNames.some(plan_name => new RegExp(/standard/).test(plan_name));
    }),
    hasPostgresAddons: (0, _computed.notEmpty)('postgresAddons'),
    hasNonProductionPostgres: (0, _computed.or)('hasHobbyPostgres', 'hasStandardPostgres'),
    hasOnlyProductionPostgres: (0, _object.computed)('hasPostgresAddons', 'hasNonProductionPostgres', function () {
      return this.hasPostgresAddons && !this.hasNonProductionPostgres;
    }),
    hasAnyPremiumPostgres: (0, _object.computed)('postgresPlanNames', function () {
      return this.postgresPlanNames.some(plan_name => new RegExp(/(premium|enterprise|private|shield)/).test(plan_name));
    }),
    status: (0, _object.computed)('hasAnyPremiumPostgres', 'hasNonProductionPostgres', 'hasOnlyProductionPostgres', function () {
      if (this.hasAnyPremiumPostgres) {
        return 'passed';
      } else if (this.hasNonProductionPostgres) {
        return 'warning';
      } else if (this.hasOnlyProductionPostgres) {
        return 'passed';
      }

      return 'skipped';
    })
  });

  _exports.default = _default;
});