define("dashboard/models/github-repository-contents", ["exports", "ember-data-github/models/github-repository-contents"], function (_exports, _githubRepositoryContents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _githubRepositoryContents.default;
    }
  });
});