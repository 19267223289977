define("dashboard/utils/rolledup-array", ["exports", "@ember/object", "moment"], function (_exports, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * a custom computed property that takes in the property name of an array
   * and rolls up all items based on `created_at` and the Metrics
   * static refresh rate interval
   */
  function _default(arrayName, periodKey) {
    return (0, _object.computed)(`${arrayName}.@each.createdAt`, periodKey, function () {
      const array = this.get(arrayName) || [];
      return array.reduce(function (rolledup, item) {
        let interval;
        const createdAtDate = (0, _moment.default)(item.get('createdAt')).utc();
        const createdAtMinutes = createdAtDate.minutes(); // create the interval date object that is valid for this item

        const intervalDate = createdAtDate.clone(); // get the length of this period in minutes

        const periodInMinutes = _moment.default.duration(this.get(periodKey)).asMinutes(); // the minutes for the interval this should be in


        const intervalMinutes = createdAtMinutes - createdAtMinutes % periodInMinutes; // change the minutes to the interval's

        intervalDate.minutes(intervalMinutes); // seconds and milliseconds are always zero for an interval

        intervalDate.seconds(0);
        intervalDate.milliseconds(0); // if the period is two hours, make sure that the interval captures all
        // values for the current hour and the next.

        if (periodInMinutes === 120 && intervalDate.hours() % 2 === 1) {
          intervalDate.hours(intervalDate.hours() - 1);
        } // unix time


        const intervalTime = intervalDate.toDate().getTime();
        const existingInterval = rolledup.findBy('time', intervalTime);

        if (existingInterval) {
          interval = existingInterval;
        } else {
          interval = {
            time: intervalTime,
            value: 0,
            models: []
          };
          rolledup.push(interval);
        } // increment the number of items for this rollup


        interval.value += 1;
        interval.models.push(item);
        return rolledup;
      }.bind(this), []);
    });
  }
});