define("dashboard/templates/components/metrics/chart-controls/layout-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zTzP73nr",
    "block": "[[[10,0],[14,0,\"hk-button-group metrics__view-controls__toggle-buttons\"],[14,\"role\",\"group\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"buttonType\",\"onClick\"],[\"Compact Layout\",[52,[30,0,[\"isCompactLayout\"]],\"primary\",\"secondary\"],[28,[37,2],[[30,0],\"setLayout\",\"compact\"],null]]],[[\"default\"],[[[[1,\"    \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"layout-grid-16\",16,[30,0,[\"compactIconClass\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"buttonType\",\"onClick\"],[\"Vertical Layout\",[52,[30,0,[\"isVerticalLayout\"]],\"primary\",\"secondary\"],[28,[37,2],[[30,0],\"setLayout\",\"vertical\"],null]]],[[\"default\"],[[[[1,\"    \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"layout-list-16\",16,[30,0,[\"verticalIconClass\"]]]],null],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"hk-button\",\"if\",\"action\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/layout-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});