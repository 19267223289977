define("dashboard/models/notification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    body: (0, _model.attr)('string'),
    // This is a string so we can retrofit to our {{time-ago}} component
    createdAt: (0, _model.attr)('string'),
    followup: (0, _model.attr)(),
    read: (0, _model.attr)('boolean'),
    target: (0, _model.attr)(),
    title: (0, _model.attr)('string'),

    markAsRead() {
      this.set('read', true);
      return this.save();
    }

  });

  _exports.default = _default;
});