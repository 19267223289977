define("dashboard/components/pipeline/github-banner", ["exports", "@ember/service", "@ember/array", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "dashboard/mixins/preferences", "dashboard/config/environment"], function (_exports, _service, _array, _component, _object, _computed, _utils, _preferences, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_preferences.default, {
    githubToken: (0, _service.inject)(),
    advertiseCI: false,
    dismissedIds: (0, _computed.reads)('platformUserPreferences.dismissedPipelinesGithubBanners'),
    hasNoGithubAppInstalled: (0, _computed.not)('pipeline.hasGithubAppInstalled'),
    isDismissed: (0, _object.computed)('dismissedIds.[]', 'pipeline.id', function () {
      const ids = this.dismissedIds;

      if ((0, _array.isArray)(ids)) {
        return ids.includes(this.get('pipeline.id'));
      } else {
        return false;
      }
    }),
    isNotDismissed: (0, _computed.not)('isDismissed'),
    hasNoRepository: (0, _object.computed)('pipeline.isGithubAppEnabled', 'pipeline.githubRepository.id', 'pipeline.connectedRepository.id', function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return (0, _utils.isEmpty)(this.get('pipeline.connectedRepository.id'));
      } else {
        return (0, _utils.isEmpty)(this.get('pipeline.githubRepository.id'));
      }
    }),
    isLinked: (0, _object.computed)('pipeline.isGithubAppEnabled', 'githubToken.token', 'pipeline.hasGithubAppInstalled', function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.get('pipeline.hasGithubAppInstalled');
      } else {
        return (0, _utils.isPresent)(this.get('githubToken.token'));
      }
    }),
    isNotLinked: (0, _computed.not)('isLinked'),
    isNotComplete: (0, _computed.or)('hasNoRepository', 'isNotLinked'),

    get githubIntegrationReenabled() {
      return _environment.default.githubIntegrationReenabled;
    },

    shouldAdvertiseGitHub: (0, _object.computed)('githubIntegrationReenabled', 'isNotDismissed', 'isNotComplete', 'pipeline.connectedRepository.isPending', function () {
      let shouldAdvertise = !this.githubIntegrationReenabled && this.isNotDismissed && this.isNotComplete;

      if (this.get('pipeline.isGithubAppEnabled')) {
        shouldAdvertise = shouldAdvertise && !this.get('pipeline.connectedRepository.isPending') && !this.get('pipeline.isConnectedToRepository');
      }

      return shouldAdvertise;
    }),
    shouldNotAdvertiseGitHub: (0, _computed.not)('shouldAdvertiseGitHub'),
    hasEnabledCi: (0, _computed.readOnly)('pipeline.isCiEnabled'),
    hasDismissedCI: (0, _object.computed)('dashboardUserPreferences.pipelineCIAdBannerDismissals', function () {
      const pipelineId = this.get('pipeline.id');
      const dismissals = this.get('dashboardUserPreferences.pipelineCIAdBannerDismissals') || [];
      return dismissals.includes(pipelineId);
    }),
    hasAcknowledgedCI: (0, _computed.or)('hasDismissedCI', 'hasEnabledCi'),
    hasNotAcknowledgedCI: (0, _computed.not)('hasAcknowledgedCI'),
    shouldAdvertiseCI: (0, _object.computed)('hasNotAcknowledgedCI', 'shouldNotAdvertiseGitHub', 'advertiseCI', 'pipeline.connectedServices.isPending', function () {
      let shouldAdvertise = this.hasNotAcknowledgedCI && this.shouldNotAdvertiseGitHub && this.advertiseCI;

      if (this.get('pipeline.isGithubAppEnabled')) {
        shouldAdvertise = shouldAdvertise && !this.get('pipeline.connectedServices.isPending');
      }

      return shouldAdvertise;
    }),
    hasDismissedPipelinePermissionsBanner: (0, _computed.bool)('dashboardUserPreferences.hasDismissedPipelinePermissionsBanner'),
    hasNotDismissedPipelinePermissionsBanner: (0, _computed.not)('hasDismissedPipelinePermissionsBanner'),
    isCollaboratorsEnabled: (0, _computed.readOnly)('pipeline.collaboratorsEnabled'),
    shouldAdvertisePipelinePermissions: (0, _computed.and)('hasNotDismissedPipelinePermissionsBanner', 'isCollaboratorsEnabled'),
    actions: {
      authorizedGitHub() {
        this.authorizedGitHub(...arguments);
        this.set('isShowingConnectModal', false);
      },

      dismiss() {
        const dismissedIds = this.dismissedIds || [];
        dismissedIds.addObject(this.get('pipeline.id'));
        this.platformUserPreferences.update('dismissedPipelinesGithubBanners', dismissedIds);
      },

      dismissPipelinePermissionsBanner() {
        return this.updateUserPreference('hasDismissedPipelinePermissionsBanner', true);
      }

    }
  });

  _exports.default = _default;
});