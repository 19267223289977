define("dashboard/templates/components/eco-button-deploy-subscribe-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hTWfUTtv",
    "block": "[[[11,0],[24,0,\"bg-white ba b--silver shadow-outer-3 br3 overflow-hidden\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ra-enable-banner-header pa2\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"dynomite-eco-16\",31,\"malibu-fill-gradient-purple\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"f5 gray pa2 w-100 flex\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"b--silver lh-copy flex-auto mv1\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"b dark-gray pt--2 pb1 f4\"],[12],[1,\"\\n          To use Eco dynos for this app, subscribe to Eco\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"mb1\"],[12],[1,\"\\n          Get 1000 dyno hours/month for $5. Run apps using your monthly dyno hour pool. To conserve your dyno hours, Eco dynos sleep automatically after a period of inactivity.\\n        \"],[13],[1,\"\\n        \"],[8,[39,1],[[24,6,\"https://devcenter.heroku.com/articles/eco-dyno-hours\"],[24,\"tagName\",\"div\"]],null,[[\"default\"],[[[[1,\"\\n          Eco Dyno Hours\\n        \"]],[]]]]],[1,\"\\n        \"],[10,0],[14,0,\"mv3 bb b--light-silver\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"flex flex-row\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@default\",\"@size\",\"@buttonType\",\"@class\",\"@onClick\"],[\"Subscribe\",\"small\",\"secondary\",\"ma1\",[30,2]]],null],[1,\"\\n          \"],[8,[39,2],null,[[\"@default\",\"@buttonType\",\"@size\",\"@class\",\"@onClick\"],[\"Dismiss\",\"tertiary\",\"small\",\"mt1\",[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@onClickAction\",\"@dismissAction\"],false,[\"malibu-icon\",\"dev-center-link\",\"hk-button\"]]",
    "moduleName": "dashboard/templates/components/eco-button-deploy-subscribe-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});