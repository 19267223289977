define("dashboard/templates/components/app/web-hook/status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "P9GoOlOG",
    "block": "[[[41,[30,0,[\"status\"]],[[[1,\"  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"nudge-up--1 pointer\"],[12],[1,\"\\n\"],[6,[39,1],null,[[\"title\"],[[28,[37,2],[\"Last attempt \",[30,0,[\"status\"]]],null]]],[[\"default\"],[[[[1,\"        \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"iconName\"]],[30,0,[\"iconClass\"]],16]],null],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"dib dn-ns pl1 ttc f5 \",[30,0,[\"textClass\"]]]]],[12],[1,[34,4]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"nudge-up--1\"],[12],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"webhooks-16\",\"fill-gray\",16,\"Unknown status\"]],null],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"x-tooltip\",\"concat\",\"malibu-icon\",\"status\"]]",
    "moduleName": "dashboard/templates/components/app/web-hook/status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});