define("dashboard/components/pipeline/promote-modal-target", ["exports", "@ember/service", "@ember/component", "@ember/object", "ember-concurrency"], function (_exports, _service, _component, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    and,
    or,
    sort
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: '',
    store: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    githubTokenService: (0, _service.inject)('github-token'),
    githubToken: readOnly('githubTokenService.token'),
    githubAppInstallation: readOnly('pipeline.githubAppInstallation'),
    githubConnection: or('githubToken', 'githubAppInstallation'),
    canShowCommits: and('repo', 'sourceCommit', 'targetCommit', 'githubConnection'),
    repo: (0, _object.computed)('sourceApp.pipelineCoupling.githubAppLink.repo', 'targetApp.pipelineCoupling.githubAppLink.repo', 'pipeline.connectedRepository.content', 'pipeline.isGithubAppEnabled', function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.get('pipeline.connectedRepository.fullName');
      } else {
        return this.get('sourceApp.pipelineCoupling.githubAppLink.repo') || this.get('targetApp.pipelineCoupling.githubAppLink.repo');
      }
    }),
    sourceAppCommit: readOnly('sourceApp.currentDeployment.commit'),
    sourceCommit: (0, _object.computed)('sourceAppCommit', 'sourceBranch', function () {
      return this.sourceAppCommit || this.sourceBranch;
    }),
    targetCommit: readOnly('targetApp.currentDeployment.commit'),
    diffLink: (0, _object.computed)('sourceCommit', 'targetCommit', 'repo', function () {
      const sourceCommit = this.sourceCommit;
      const targetCommit = this.targetCommit;
      const repo = this.repo;

      if (!sourceCommit || !targetCommit || !repo) {
        return;
      }

      return `https://github.com/${repo}/compare/${targetCommit}...${sourceCommit}`;
    }),
    commitSort: ['commit.committer.date:desc'],
    sortedCommits: sort('commitsTask.lastSuccessful.value', 'commitSort'),
    commitsTask: (0, _emberConcurrency.task)(function* () {
      const commits = yield this.loadGitCommits();
      return commits;
    }),

    loadGitCommits() {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.store.queryRecord('repositories-api-github-compare', {
          pipeline: this.pipeline.id,
          base: this.targetCommit,
          head: this.sourceCommit
        }).then(comparison => comparison.get('commits'));
      } else {
        return this.store.queryRecord('github-compare', {
          repo: this.repo,
          base: this.targetCommit,
          head: this.sourceCommit
        }).then(comparison => comparison.get('commits'));
      }
    },

    actions: {
      toggleCommits() {
        this.toggleProperty('isShowingCommits');

        if (this.isShowingCommits) {
          this.commitsTask.perform();
        }
      }

    }
  });

  _exports.default = _default;
});