define("dashboard/components/pipeline/new-app-panel", ["exports", "@ember/object/computed", "@ember/service", "@ember/component", "@ember/object"], function (_exports, _computed, _service, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    or,
    bool,
    notEmpty
  } = _object.computed;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    newApp: null,
    appNameIsAvailable: false,
    isSaving: or('app.isSaving'),
    appPersisted: bool('app.id'),
    isDisabledInput: or('isSaving', 'appPersisted'),
    allSpaces: (0, _object.computed)(function () {
      return [];
    }),
    orgSpaces: (0, _object.computed)('allSpaces.[]', 'organization', function () {
      return this.get('organization.spaces') || [];
    }),
    canManageSpaces: notEmpty('orgSpaces'),
    orgSpaceOptions: (0, _object.computed)('orgSpaces.[]', function () {
      return this.orgSpaces.map(function (space) {
        const state = space.get('state');
        const isReady = space.get('isReady');
        const name = `${space.get('name')}, ${space.get('region.locale')}`;
        const id = space.get('name');
        const label = isReady ? name : `${name} (${state})`;
        return _object.default.create({
          name,
          label,
          id,
          disabled: !isReady
        });
      });
    }),
    regionLabel: (0, _object.computed)('canManageSpaces', function () {
      if (this.canManageSpaces) {
        return 'Choose a region or a Private Space';
      } else {
        return 'Choose a region';
      }
    }),
    regionName: (0, _object.computed)(function () {
      return 'us';
    }),
    regions: (0, _object.computed)(function () {
      return this.store.peekAll('region');
    }),
    commonRuntimes: (0, _object.computed)('regions.[]', function () {
      return this.regions.filter(region => {
        const regionName = region.get('name');
        return regionName === 'eu' || regionName === 'us';
      });
    }),
    runtimesSorting: ['name:desc'],
    sortedCommonRuntimes: (0, _computed.sort)('commonRuntimes', 'runtimesSorting'),
    selectedRegion: (0, _object.computed)('commonRuntimes', 'regionName', function () {
      return this.commonRuntimes.findBy('name', this.regionName);
    }),
    selectedSpace: (0, _object.computed)('orgSpaces', 'spaceName', function () {
      return this.orgSpaces.findBy('name', this.spaceName);
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('newApp', this.store.createRecord('app', {
        name: this.potentialAppName
      }));
      this.set('allSpaces', this.store.findAll('space'));
    },

    willDestroyElement() {
      if (this.get('newApp.isNew')) {
        this.newApp.unloadRecord();
        this.set('newApp', null);
      }

      this._super(...arguments);
    },

    _createApp() {
      const app = this.newApp;

      if (this.organization) {
        app.set('team', this.organization);
      } // If theres a space selected, set it, otherwise set the region to the
      // selected region


      if (this.selectedSpace) {
        app.set('space', this.get('selectedSpace.name'));
      } else {
        app.set('region', this.selectedRegion);
      }

      return app.save().then(() => {
        this.analytics.logEvent('App', 'Created');
        return app;
      }, () => {
        // reset relationships on save error
        app.set('team', null);
        app.set('space', null);
        app.set('region', null);
      });
    },

    _coupleApp(app) {
      if (!app) {
        return;
      }

      this.addCoupling(app);
    },

    actions: {
      createAndCoupleApp(dismiss) {
        return this._createApp().then(app => {
          this._coupleApp(app);

          dismiss();
        });
      }

    }
  });

  _exports.default = _default;
});