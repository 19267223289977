define("dashboard/components/space/network/outbound-ruleset-list-item", ["exports", "@ember/object", "dashboard/components/purple-list-row"], function (_exports, _object, _purpleListRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _purpleListRow.default.extend({
    item: alias('rule'),
    classNames: ['outbound-rule-item'],
    protocolUppercase: (0, _object.computed)('rule.protocol', function () {
      return this.get('rule.protocol').toUpperCase();
    })
  });

  _exports.default = _default;
});