define("dashboard/models/app-permissions", ["exports", "@ember/object/evented", "@ember/object", "@ember/object/computed"], function (_exports, _evented, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    // object creation arguments
    app: null,
    team: null,
    teamCollaborator: null,
    account: null,
    roleBasedPermissionMap: (0, _object.computed)(function () {
      return {
        /* eslint-disable prettier/prettier */
        'add_direct_member': ['isOwner', 'isAdmin', 'isCollaborator'],
        'add_to_pipeline': ['isOwner', 'isAdmin', 'isMember'],
        'delete_app': ['isOwner', 'isAdmin'],
        'git_repo': ['isOwner', 'isAdmin', 'isCollaborator'],
        'manage_domains': ['isOwner', 'isAdmin', 'isCollaborator'],
        'manage_app': ['isOwner', 'isAdmin', 'isCollaborator'],
        'manage_app_features': ['isOwner', 'isAdmin', 'isCollaborator'],
        'manage_app_locks': ['isOwner', 'isAdmin'],
        'manage_app_permissions': ['isOwner', 'isAdmin'],
        'manage_releases': ['isOwner', 'isAdmin', 'isCollaborator'],
        'manage_webhooks': ['isOwner', 'isAdmin', 'isCollaborator'],
        'provision_free_resources': ['isOwner', 'isAdmin', 'isCollaborator'],
        'remove_direct_member': ['isOwner', 'isAdmin', 'isCollaborator'],
        'rename_app': ['isOwner', 'isAdmin'],
        'scale_ps': ['isOwner', 'isAdmin', 'isCollaborator'],
        'transfer_app': ['isOwner', 'isAdmin'],
        'upgrade_stack': ['isOwner', 'isAdmin', 'isCollaborator'],
        'view_config_vars': ['isOwner', 'isAdmin', 'isCollaborator'],
        'view_logs': ['isOwner', 'isAdmin', 'isCollaborator'],
        'view_resources': ['isOwner', 'isAdmin', 'isCollaborator'],
        'view_app_console': ['isOwner', 'isAdmin', 'isCollaborator']
        /* eslint-enable prettier/prettier */

      };
    }),
    fgacPermissionMap: (0, _object.computed)(function () {
      return {
        /* eslint-disable prettier/prettier */
        'add_direct_member': ['isAdmin', 'hasManage'],
        'add_to_pipeline': ['isAdmin', 'isMember'],
        'delete_app': ['isAdmin', 'hasManage'],
        'git_repo': ['isAdmin', 'hasDeploy'],
        'manage_domains': ['isAdmin', 'hasManage'],
        'manage_app': ['isAdmin', 'hasManage'],
        'manage_app_features': ['isAdmin', 'hasOperate'],
        'manage_app_locks': ['isAdmin', 'hasManage'],
        'manage_app_permissions': ['isAdmin', 'hasManage'],
        'manage_releases': ['isAdmin', 'hasDeploy', 'hasOperate'],
        'manage_webhooks': ['isAdmin', 'hasOperate'],
        'provision_free_resources': ['isAdmin', 'hasDeploy', 'hasOperate'],
        'remove_direct_member': ['isAdmin', 'hasManage'],
        'rename_app': ['isAdmin', 'hasManage'],
        'scale_ps': ['isAdmin', 'hasOperate'],
        'transfer_app': ['isAdmin', 'hasManage'],
        'upgrade_stack': ['isAdmin', 'hasOperate'],
        'view_config_vars': ['isAdmin', 'hasDeploy', 'hasOperate'],
        'view_logs': ['isAdmin', 'hasOperate'],
        'view_resources': ['isAdmin', 'hasDeploy', 'hasOperate'],
        'view_app_console': ['isAdmin', 'hasDeploy', 'hasOperate']
        /* eslint-enable prettier/prettier */

      };
    }),
    isOrgApp: (0, _computed.bool)('team'),
    // If your role is 'direct', 'collaborator', 'member' or blank
    // you are a collaborator. Pretty much if you get to an app page, you are
    // a collaborator.
    isCollaborator: (0, _object.computed)(function () {
      return true;
    }),
    // You can be an admin by either your org or app role
    isAdmin: (0, _computed.equal)('team.role', 'admin'),
    isMember: (0, _computed.equal)('team.role', 'member'),
    // All apps have an owner, but only personal apps will have an owner that's
    // a real user.
    isOwner: (0, _object.computed)('account.email', 'app.owner.email', function () {
      return this.get('account.email') === this.get('app.owner.email');
    }),
    hasDeploy: (0, _object.computed)('teamCollaboratorPermissions', function () {
      return this.get('teamCollaboratorPermissions').isAny('name', 'deploy');
    }),
    hasOperate: (0, _object.computed)('teamCollaboratorPermissions', function () {
      return this.get('teamCollaboratorPermissions').isAny('name', 'operate');
    }),
    hasManage: (0, _object.computed)('teamCollaboratorPermissions', function () {
      return this.get('teamCollaboratorPermissions').isAny('name', 'manage');
    }),
    teamCollaboratorPermissions: (0, _object.computed)('teamCollaborator.permissions.[]', function () {
      return this.get('teamCollaborator.permissions') || [];
    }),
    roleBasedPermissions: (0, _object.computed)('isOwner', 'isAdmin', 'isCollaborator', function () {
      return this._calcPermissions(this.roleBasedPermissionMap);
    }),
    fgacPermissions: (0, _object.computed)('isAdmin', 'hasManage', 'hasOperate', 'hasDeploy', function () {
      return this._calcPermissions(this.fgacPermissionMap);
    }),

    _calcPermissions(permissionMap) {
      const permissions = [];

      for (const perm in permissionMap) {
        const hasPerm = permissionMap[perm].any(condition => {
          return this.get(condition);
        });

        if (hasPerm) {
          permissions.push(perm);
        }
      }

      return permissions;
    },

    _buildPermissionProperties: (0, _evented.on)('init', function () {
      Object.keys(this.roleBasedPermissionMap).forEach(function (key) {
        const perm = key.classify();
        (0, _object.defineProperty)(this, `can${perm}`, (0, _object.computed)('isOrgApp', function () {
          if (this.isOrgApp) {
            return this.fgacPermissions.includes(key);
          } else {
            return this.roleBasedPermissions.includes(key);
          }
        }));
      }, this);
    })
  });

  _exports.default = _default;
});