define("dashboard/components/org/member-delete-modal", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bool,
    none,
    not,
    readOnly
  } = _object.computed;

  var _default = _component.default.extend({
    deleteButtonText: (0, _object.computed)('isInvitation', function () {
      return this.isInvitation ? 'Revoke Invite' : 'Delete Member';
    }),
    deleteSubmitDisabled: bool('isFederated'),
    email: readOnly('item.email'),
    isFederated: readOnly('item.federated'),
    isInvitation: not('isMember'),
    isMember: none('item.invitedBy'),
    titleForDelete: (0, _object.computed)('email', 'isFederated', function () {
      const email = this.email;
      return this.isFederated ? `Remove from team` : `Delete <small>(${email})</small>`;
    })
  });

  _exports.default = _default;
});