define("dashboard/components/display-duration", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "moment"], function (_exports, _component, _service, _object, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'span',
    classNames: ['display-duration'],
    clock: (0, _service.inject)('clock'),
    to: (0, _computed.reads)('clock.moment'),
    duration: (0, _object.computed)('from', 'to', function () {
      const timeDifference = (0, _moment.default)(this.to).diff(this.from);
      return _moment.default.duration(timeDifference).format('h[h] m[m] s[s]');
    })
  });

  _exports.default = _default;
});