define("dashboard/templates/components/net-promoter-score/why", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CRd6mnrF",
    "block": "[[[1,[28,[35,0],null,[[\"reasons\",\"onSelectReasons\"],[[30,0,[\"reasons\"]],[28,[37,1],[[30,0],\"setReasons\"],null]]]]],[1,\"\\n\"],[1,[28,[35,2],null,[[\"class\",\"rows\",\"value\",\"placeholder\",\"autofocus\"],[\"hk-input w-100 mb3\",\"8\",[30,0,[\"npsSurvey\",\"whyDescription\"]],\"Help us understand your score\",true]]]],[1,\"\\n\"]],[],false,[\"net-promoter-score/reasons-select\",\"action\",\"textarea\"]]",
    "moduleName": "dashboard/templates/components/net-promoter-score/why.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});