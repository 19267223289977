define("dashboard/routes/protected/error", ["exports", "@ember/routing/route", "dashboard/utils/errors"], function (_exports, _route, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */
  var _default = _route.default.extend({
    setupController(controller, err) {
      this._super.apply(this, arguments);

      if (err.stack) {
        console.error(err.stack);
      } else if (err.statusText) {
        console.error(err.statusText, err.status);
      } else {
        console.error(err);
      }

      const status = (0, _errors.errorStatus)(controller.get('model'));

      if (status === 401) {
        this.send('reauthenticate');
      }
    }

  });

  _exports.default = _default;
});