define("dashboard/templates/components/net-promoter-score/reasons-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NvK21Rbp",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"reasons\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"w-49\"],[12],[1,\"\\n    \"],[10,\"label\"],[15,0,[29,[\"w-100 pv2 ph2 ba f4 gray br1 b--silver mt2 \",[52,[30,1,[\"isSelected\"]],\"selected\"]]]],[15,\"for\",[30,1,[\"value\"]]],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"type\",\"id\",\"checked\"],[\"checkbox\",[30,1,[\"value\"]],[30,1,[\"isSelected\"]]]]]],[1,\"\\n      \"],[8,[39,4],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[[30,1,[\"icon\"]],16,\"fill-gray ml1\"]],null],[1,\"\\n      \"],[1,[30,1,[\"value\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"reason\",\"idx\"],false,[\"each\",\"-track-array\",\"if\",\"input\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/net-promoter-score/reasons-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});