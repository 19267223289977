define("dashboard/serializers/pipeline/github-repository", ["exports", "dashboard/serializers/application", "@ember/utils", "@ember/object"], function (_exports, _application, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize() {
      const data = this._super(...arguments);

      if (data.owner) {
        data.owner = data.owner.id;
      }

      if (data.organization) {
        data.organization = data.organization.id;
      }

      return data;
    },

    extractAttributes(modelClass, hash) {
      const data = this._super(...arguments); // `ci` is a hidden boolean attribute, `true` is on, missing is off


      if ((0, _utils.isEmpty)(data.ci)) {
        data.ci = false;
      }

      data.name = hash.repository.name;
      data.type = hash.repository.type;
      return data;
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      relationships.pipeline = {
        data: {
          id: hash.pipeline.id,
          type: 'pipeline'
        }
      };
      const githubOwnerId = (0, _object.get)(hash, 'owner.github.user_id');

      if ((0, _utils.isPresent)(githubOwnerId)) {
        relationships.githubOwner = {
          data: {
            id: githubOwnerId,
            type: 'github/user'
          }
        };
      }

      return relationships;
    }

  });

  _exports.default = _default;
});