define("dashboard/components/metrics/charts/visualizations/go-heap-objects", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/aggregate-chart"], function (_exports, _computed, _aggregateChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aggregateChart.default.extend({
    tagName: 'metrics-go-heap-chart',
    'data-test-target': 'metrics-go-chart',
    title: 'Go: Heap Objects',
    devCenterName: 'language-runtime-metrics-go#heap-objects',
    isBeta: true,
    maxData: (0, _computed.alias)('data.heapObjectsMax'),
    avgData: (0, _computed.alias)('data.heapObjectsAvg'),
    minData: (0, _computed.alias)('data.heapObjectsMin')
  });

  _exports.default = _default;
});