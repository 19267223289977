define("dashboard/adapters/repositories-api-github-oauth-token", ["exports", "dashboard/adapters/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.repositories-api',

    handleResponse(status, headers, payload, requestData) {
      // A 404 on this endpoint just means there is no github oauth token in repos-api.
      // This override prevents ember-data from raising an error in the console
      // and failing the test suite.
      if (status === 404) {
        status = 204;
        payload = null;
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForCreateRecord() {
      return `${_environment.default.particleboardUrl}/github/auth/authorize`;
    }

  });

  _exports.default = _default;
});