define("dashboard/templates/components/test-node-overflow-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kYFRNmmN",
    "block": "[[[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n\"],[41,[30,0,[\"options\",\"nodeSelectedInDropdown\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"testNode\",\"renderAsDropdownButton\"],[[30,0,[\"options\",\"testNode\"]],true]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[15,0,[29,[\"code f4 \",[52,[30,0,[\"options\",\"nodeSelectedInDropdown\"]],\"purple\",\"gray\"]]]],[12],[1,[30,0,[\"options\",\"dropdownLabel\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"options\",\"showDropdownBadge\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@title\",\"@size\",\"@iconClass\",\"@name\"],[\"some of these test nodes have failed\",16,\"malibu-fill-gradient-dark-gray ml1 nudge-up--1\",\"failure-ring-16\"]],null],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n  \"],[8,[39,2],null,[[\"@title\",\"@size\",\"@iconClass\",\"@name\"],[\"Click to show more test nodes\",16,[30,0,[\"iconFill\"]],\"caret-16\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"ci/test-node-tab\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/test-node-overflow-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});