define("dashboard/components/identity-provider/certificate-form", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-changeset", "ember-concurrency", "dashboard/validations/identity-provider-certificate"], function (_exports, _component, _object, _computed, _emberChangeset, _emberConcurrency, _identityProviderCertificate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    isConfirmingDelete: false,
    onCancel: null,
    onDelete: null,
    onSave: null,
    certificate: null,
    changeset: (0, _object.computed)('certificate', function () {
      return new _emberChangeset.default(this.certificate, _identityProviderCertificate.validator, _identityProviderCertificate.default);
    }),
    isEditingCertificate: (0, _computed.not)('certificate.isNew'),
    headerLabel: (0, _object.computed)('isEditingCertificate', function () {
      if (this.isEditingCertificate) {
        return 'Certificate';
      } else {
        return 'Add certificate';
      }
    }),
    submitButtonLabel: (0, _object.computed)('isEditingCertificate', function () {
      if (this.isEditingCertificate) {
        return 'Save changes';
      } else {
        return 'Add certificate';
      }
    }),

    willDestroyElement() {
      this._super(...arguments);

      const certificate = this.certificate;

      if (certificate && certificate.hasDirtyAttributes) {
        certificate.rollbackAttributes();
      }
    },

    cancel() {
      const action = this.onCancel;

      if (action) {
        action();
      }
    },

    saveCertificate: (0, _emberConcurrency.task)(function* () {
      const certificate = this.certificate;
      const changeset = this.changeset;

      try {
        yield changeset.save();
      } catch (error) {
        certificate.errors.forEach(_ref => {
          let {
            attribute,
            message
          } = _ref;

          if (attribute && message) {
            changeset.pushErrors(attribute, message);
          }
        });
        throw error;
      }

      const action = this.onSave;

      if (action) {
        action();
      }
    }),
    deleteCertificate: (0, _emberConcurrency.task)(function* () {
      yield this.certificate.destroyRecord();
      this.set('isConfirmingDelete', false);
      const action = this.onDelete;

      if (action) {
        action();
      }
    })
  });

  _exports.default = _default;
});