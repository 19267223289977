define("dashboard/components/pipeline/github-error-banner", ["exports", "@ember/service", "@ember/component", "@ember/object/computed", "dashboard/utils/custom-computed", "@ember/utils", "@ember/object"], function (_exports, _service, _component, _computed, _customComputed, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    githubToken: (0, _service.inject)(),
    api: (0, _service.inject)(),
    store: (0, _service.inject)(),
    pipelineRepository: (0, _computed.readOnly)('pipeline.githubRepository'),
    pipelineStatuses: (0, _computed.readOnly)('pipelineRepository.statuses.[]'),
    gitHubTokenStatus: (0, _customComputed.findBy)('pipelineStatuses', 'token', 'status'),
    isGitHubTokenValid: (0, _object.computed)('gitHubTokenStatus.status', function () {
      if ((0, _utils.isPresent)(this.get('gitHubTokenStatus.status'))) {
        return this.get('gitHubTokenStatus.status');
      }

      return true; // do not flag the token as invalid when in doubt
    }),
    actions: {
      disconnectedGitHub(buttonCallback) {
        const path = '/github/account/integration';
        const promise = this.api.request(path, {
          type: 'DELETE',
          adapter: this.store.adapterFor('github-auth')
        }).then(() => {
          return this.githubToken.fetch();
        });
        buttonCallback(promise);
      },

      authorizedGitHub() {
        this.authorizedGitHub(...arguments);
      }

    }
  });

  _exports.default = _default;
});