define("dashboard/adapters/pipeline", ["exports", "dashboard/adapters/application", "dashboard/mixins/adapters/refresh-quick-jump-hooks", "dashboard/mixins/load-more-records"], function (_exports, _application, _refreshQuickJumpHooks, _loadMoreRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_refreshQuickJumpHooks.default, {
    version: '3.pipeline-collaborators',

    findBelongsTo(store, snapshot, url, relationship) {
      if (relationship.type === 'review-app-config') {
        // rappari currently returns 404s for no configuration
        // we'll follow up and change this to better fit the model
        return this._super(...arguments).catch(() => null);
      }

      return this._super(...arguments);
    },

    findHasMany(store, snapshot, url, relationship) {
      return this._super(...arguments).then(payload => {
        if (relationship.key === 'connectedPullRequests') {
          if (payload.hasMore) {
            this.loadMorePullRequests.perform(snapshot);
          }

          payload = payload.data;
        } else if (relationship.key === 'connectedBranches') {
          if (payload.hasMore) {
            this.loadMoreBranches.perform(snapshot);
          }

          payload = payload.data;
        }

        return payload;
      });
    },

    loadMorePullRequests: (0, _loadMoreRecords.loadMoreRelatedRecords)('repositories-api-pull-request', 'pipelineId'),
    loadMoreBranches: (0, _loadMoreRecords.loadMoreRelatedRecords)('repositories-api-github-branch', 'pipelineId')
  });

  _exports.default = _default;
});