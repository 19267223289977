define("dashboard/components/context-switcher", ["exports", "@ember/component", "dashboard/utils/custom-computed", "@ember/object/computed", "@ember/object", "@ember/service"], function (_exports, _component, _customComputed, _computed, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    current: (0, _service.inject)('current'),
    router: (0, _service.inject)(),
    toggleComponent: null,
    routerStateParams: (0, _computed.readOnly)('router._router.targetState.routerJsState.params'),
    enterpriseAccounts: (0, _object.computed)(function () {
      return this.store.peekAll('enterprise-account');
    }),
    allTeams: (0, _object.computed)(function () {
      return this.store.peekAll('team');
    }),
    teamSortAttribute: ['name'],
    teams: (0, _computed.filterBy)('allTeams', 'isEnterpriseOrg', false),
    sortedTeams: (0, _computed.sort)('teams', 'teamSortAttribute'),
    enterpriseTeams: (0, _computed.filterBy)('allTeams', 'isEnterpriseOrg', true),
    sortedEnterpriseTeams: (0, _computed.sort)('enterpriseTeams', 'teamSortAttribute'),
    teamsWithNoEnterpriseAccount: (0, _computed.filterBy)('teams', 'enterpriseAccount', null),
    sortedTeamsWithNoEnterpriseAccount: (0, _computed.sort)('teamsWithNoEnterpriseAccount', 'teamSortAttribute'),
    enterpriseTeamsWithNoAccount: (0, _object.computed)('enterpriseTeams.@each.enterprise', function () {
      // We want all teams that don't have the EA belongsTo relationship loaded,
      // which can happen when the ET does have an EA but the user doesn't have
      // permission to load it (like a team collab who's not a member on the EA).
      return this.enterpriseTeams.rejectBy('enterprise.id');
    }),
    sortedEnterpriseTeamsWithNoAccount: (0, _computed.sort)('enterpriseTeamsWithNoAccount', 'teamSortAttribute'),
    team: (0, _customComputed.findBy)('allTeams', 'name', 'routerStateParams.team.team_name'),
    enterpriseAccount: (0, _object.computed)('routerStateParams.enterprise-account.id', 'enterpriseAccounts.@each.name', 'team.name', function () {
      const id = this.get('team.enterpriseAccount.name') || this.get('routerStateParams.enterprise-account.name');
      return id && this.enterpriseAccounts.findBy('name', id);
    }),
    classNames: ['context-switcher'],
    'data-test-target': 'context-switcher',
    actions: {
      toggleDropdown() {
        this.toggleProperty('isShowingDropdown');
      }

    }
  });

  _exports.default = _default;
});