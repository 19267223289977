define("dashboard/components/app/formations-list", ["exports", "@ember/component", "@ember/object", "@ember/service", "dashboard/mixins/formations", "dashboard/mixins/formation-totals", "dashboard/mixins/set-available-free-hours"], function (_exports, _component, _object, _service, _formations, _formationTotals, _setAvailableFreeHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    and,
    equal,
    filterBy,
    not,
    notEmpty,
    or,
    reads
  } = _object.computed;

  var _default = _component.default.extend(_formations.default, _formationTotals.default, _setAvailableFreeHours.default, {
    current: (0, _service.inject)(),
    account: alias('current.account'),
    // Account Features service + Restrict Free Dynos + Basic Plan Available feature flags needed for FormationsMixin
    accountFeatures: (0, _service.inject)('account-features'),
    subscribedToEco: reads('account.subscribedToEco'),
    notSubscribedToEco: not('subscribedToEco'),
    currentTierIsFree: equal('currentTier.name', 'free'),
    currentTierIsEco: equal('currentTier.name', 'eco'),
    currentTierIsFreeOrEco: or('currentTierIsFree', 'currentTierIsEco'),
    router: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    isTeamApp: notEmpty('team'),
    isNotTeamApp: not('isTeamApp'),
    appCanUseNewTiers: or('isNotTeamApp', 'isNotEnterpriseOrg'),
    canSeeTiers: and('hasAnyDynos', 'appCanUseNewTiers', 'hasKnownTier', 'isNotPrivateTier', 'isNotShieldTier'),
    isOwnerOrAdminOrCollaborator: or('isOwnerOrAdmin', 'isCollaborator'),
    canChangeTier: and('canSeeTiers', 'isOwnerOrAdminOrCollaborator', 'isNotPrivateTier', 'isNotShieldTier'),
    currentTierHasNotChanged: (0, _object.computed)('currentTier.name', 'currentlyEditingTier.name', function () {
      return this.get('currentTier.name') === this.get('currentlyEditingTier.name');
    }),
    hideEcoBanner: false,
    isNotOwner: not('isOwner'),
    ecoOrFreeAppOwnerIsNotSubscribedToEco: and('isOwner', 'notSubscribedToEco', 'currentTierIsFreeOrEco'),
    collaboratorOnFreeApp: and('isNotOwner', 'currentTierIsFree'),
    showEcoSubscribeBanner: (0, _object.computed)('hideEcoBanner', 'ecoOrFreeAppOwnerIsNotSubscribedToEco', 'collaboratorOnFreeApp', function () {
      return !this.hideEcoBanner && (this.collaboratorOnFreeApp || this.ecoOrFreeAppOwnerIsNotSubscribedToEco);
    }),
    showEcoSubscribeModal: false,
    isFormationsList: true,
    dirtyProcesses: filterBy('formations', 'hasDirtyAttributes'),
    isDirty: notEmpty('dirtyProcesses'),
    _webProcesses: filterBy('formations', 'type', 'web'),
    _webProcess: alias('_webProcesses.firstObject'),
    _webProcessCanAccessAutoscaling: alias('_webProcess.sizeObject.canAutoscale'),
    _webProcessCannotAccessAutoscaling: not('_webProcessCanAccessAutoscaling'),
    _webProcessIsNotDirty: equal('_webProcess.hasDirtyAttributes', false),
    showDynoUpgradeButton: and('_webProcessCannotAccessAutoscaling', '_webProcessIsNotDirty'),
    isShowingDynoUpgrade: false,

    _resetAppModel() {
      const app = this.app;

      if (app.get('isDirty')) {
        app.revert();
      }
    },

    _autoscalingDevCenterURL: 'https://devcenter.heroku.com/articles/scaling#autoscaling',
    actions: {
      save() {
        const formations = this.formations;
        const tierSize = this.get('currentlyEditingTier.defaultSize');
        formations.forEach(formation => {
          // when migrating between formation tiers we need to account
          // for migrating from a production tier with multiple dynos
          // since eco/basic can only have 1 dyno at a time
          const newQuantity = formation.get('quantity') >= 1 ? 1 : 0;
          formation.set('quantity', newQuantity);
          formation.set('size', tierSize);
        }); // App model's saveBulkFormation will use the app's formations
        // to make a bulk update of each formation's new size

        return this.app.saveBulkFormation().then(() => {
          this.analytics.logEvent('App Tier', 'Changed');
          this.send('closeTierPickerModal');
        }).catch(err => {
          // revert the app model in the event of a failure so that the data underlying
          // the modal doesn't show the changed app's state
          formations.forEach(formationToRevert => {
            formationToRevert.rollbackAttributes();
          });
          throw err;
        });
      },

      closeTierPickerModal() {
        this._resetAppModel();

        this.set('showTierPickerModal', false);
      },

      showTierPickerModal() {
        // create a copy of the current tier for use in the modal
        this.set('currentlyEditingTier', this.currentTier);
        this.set('showTierPickerModal', true);
      },

      upgradeToPerformanceMDyno() {
        const process = this._webProcess;
        process.set('size', 'Performance-M');
        return process.save().then(() => {
          this.analytics.logMetricsEvent('Process', 'Upgraded', {
            via: 'autoscaling'
          });
          this.toggleProperty('isShowingDynoUpgrade');
        });
      },

      openAutoscalingDevCenter() {
        window.open(this._autoscalingDevCenterURL, '_blank');
      },

      toggleAutoscaleDynoUpgrade() {
        this.toggleProperty('isShowingDynoUpgrade');
      },

      ecoSubscribeBannerAction() {
        if (this.account.isOhana || this.cardPresent) {
          this.set('showEcoSubscribeModal', true);
        } else {
          this.router.transitionTo('account.billing');
        }
      },

      async subscribeToEco() {
        this.account.set('ecoDynosEnabled', true);

        try {
          await this.account.save();
        } catch (error) {
          this.reporter.error(error);
          this.account.set('ecoDynosEnabled', false);
        } finally {
          this.set('showEcoSubscribeModal', false);
        }
      }

    }
  });

  _exports.default = _default;
});