define("dashboard/templates/components/metrics/charts/connected-chart-hover/group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7lhIKUDP",
    "block": "[[[1,[28,[35,0],null,[[\"graph\"],[[30,0,[\"graph\"]]]]]],[1,\"\\n\"]],[],false,[\"metrics/charts/connected-chart-hover/item/generic\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/connected-chart-hover/group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});