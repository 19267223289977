define("dashboard/components/metrics/charts/visualizations/go-gc-collections", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/garbage-collections-chart"], function (_exports, _computed, _garbageCollectionsChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _garbageCollectionsChart.default.extend({
    tagName: 'metrics-go-gc-collections-chart',
    'data-test-target': 'metrics-go-chart',
    language: 'go',
    devCenterName: 'language-runtime-metrics-go#aggregate-garbage-collections',
    isBeta: true,
    hasSplitGenerations: false,
    collectionsData: (0, _computed.alias)('data.goGcCollections')
  });

  _exports.default = _default;
});