define("dashboard/validators/postal-code-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validatePostalCode;

  function validatePostalCode() {
    return (key, newValue) => {
      const postalCode = newValue || '';

      if (hasInvalidCharacters(postalCode)) {
        return 'Postal Code can only contain letters, numbers, spaces, and hyphens.';
      } else if (hasTooManyCharacters(postalCode)) {
        return 'Postal Code may contain no more than 9 letter or number characters.';
      }

      return true;
    };
  }

  function hasInvalidCharacters(postalCode) {
    // Only letters, numbers, spaces, and hyphens allowed.
    // https://developers.braintreepayments.com/reference/general/validation-errors/all/ruby#code-81813
    return !postalCode.match(/^[A-Za-z0-9 -]*$/);
  }

  function hasTooManyCharacters(postalCode) {
    // Can only have 9 letters and numbers (other chars are not counted).
    // https://developers.braintreepayments.com/reference/general/validation-errors/all/ruby#code-81809
    return postalCode.replace(/[^A-Za-z0-9]/g, '').length > 9;
  }
});