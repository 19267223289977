define("dashboard/validators/computed-exclusion", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This works just like `exclusion` but instead of supplying a static array to
  // the `in` property, you can a computed key on your model.
  // `message` and `in` are both required.
  //
  // Example:
  // const Validations = buildValidations({
  //   email: [
  //     validator('computed-exclusion', {
  //       in: 'memberEmails',
  //       message: 'Already added'
  //     })
  //   ]
  // });
  //
  // Where your model has a computed property of `memberEmails` that returns an
  // array. This validation will assert the email you're entering doesn't not
  // already exist.
  const ComputedExclusion = _base.default.extend({
    validate(value, options, model) {
      if (model.get(options.in).includes(value)) {
        return options.message;
      }

      return true;
    }

  });

  var _default = ComputedExclusion;
  _exports.default = _default;
});