define("dashboard/routes/pipelines/pipeline/index/review-apps", ["exports", "rsvp", "@ember/routing/route"], function (_exports, _rsvp, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      const {
        pipeline
      } = this.modelFor('pipelines.pipeline');
      return pipeline.hasMany('githubAppLinks').reload().then(() => {
        const repo = pipeline.get('repo');
        const githubAppLinks = pipeline.get('githubAppLinks');
        const appJSON = this.store.queryRecord('github-repository-contents', {
          repo,
          file: 'app.json'
        }).catch(() => null);
        return (0, _rsvp.hash)({
          appJSON,
          githubAppLinks,
          pipeline
        });
      });
    },

    actions: {
      close() {
        this.transitionTo('pipelines.pipeline');
      }

    }
  });

  _exports.default = _default;
});