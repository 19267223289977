define("dashboard/components/net-promoter-score/rate-your-experience", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'nps-modal-rate-your-experience'
  });

  _exports.default = _default;
});