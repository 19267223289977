define("dashboard/routes/protected/terms-of-service", ["exports", "@ember/routing/route", "dashboard/mixins/document-title", "@ember/service"], function (_exports, _route, _documentTitle, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    queryParams: {
      showItalianTerms: {
        replace: true
      }
    },
    accountFeatures: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),
    documentTitle: 'Terms of Service',

    get fullDocumentTitle() {
      return `${this.documentTitle} | Heroku`;
    },

    renderTemplate() {
      this.set('breadcrumbs.trail', {
        location: 'Terms of Service'
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav'
      });

      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});