define("dashboard/mixins/metrics/data-refresh", ["exports", "@ember/runloop", "@ember/object", "@ember/object/mixin", "dashboard/config/environment", "moment"], function (_exports, _runloop, _object, _mixin, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    refreshInterval: (0, _object.computed)(function () {
      return _moment.default.duration(30, 'seconds').valueOf();
    }),

    _startTimer() {
      // there is complex logic here to automatically disable this from happening
      // in our acceptance environment, but to override that disablement for integration
      // unit tests
      const overrideTimeoutDisable = this.overrideTimeoutDisable;
      const isAllowable = !_environment.default.DISABLE_TIMEOUTS || overrideTimeoutDisable;
      const shouldStart = isAllowable && this.isAutoRefreshing;

      if (shouldStart) {
        const timeout = this.refreshInterval;
        const timer = (0, _runloop.later)(this, function () {
          // this is a hack to force the charts to update (for now, it's
          // better than updating the start and end times)
          this.refresh(this.process);

          this._startTimer();
        }, timeout);
        this.set('_timer', timer);
      }
    },

    _stopTimer() {
      (0, _runloop.cancel)(this._timer);
      this.set('isRefreshing', false);
    },

    refresh(formation) {
      const app = this.app;
      this.set('isRefreshingData', true); // We intentionally stagger the data refresh slightly. We'll make a request
      // to refresh the monitors to see if we've lost authentication, and only
      // refresh all other data if that succeeds

      return formation.get('monitors').reload().then(() => {
        this.notifyPropertyChange('process');
        this.notifyPropertyChange('resolvedIncidents');
        this.notifyPropertyChange('unresolvedIncidents');
        app.get('releases').reload();
        formation.get('scaleEvents').reload();
        formation.get('alerts').reload();
        formation.get('restartEvents').reload();
      }).catch(() => {
        this._stopTimer();
      });
    }

  });

  _exports.default = _default;
});