define("dashboard/adapters/kolkrabbi/github-user", ["exports", "dashboard/adapters/application", "dashboard/config/environment", "@ember/service", "ember-data"], function (_exports, _application, _environment, _service, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    AdapterError
  } = _emberData.default;

  var _default = _application.default.extend({
    host: _environment.default.kolkrabbiUrl,
    dsErrorMonitor: (0, _service.inject)(),

    handleResponse(status, headers, payload, requestData) {
      if (status === 401 || status === 403) {
        // suppress flash errors
        requestData.jqXHR.errorIsHandled = true;
        return new AdapterError();
      } // A 404 on this endpoint just means the user isn't authed with GitHub.
      // We send a DELETE to a different URL that returns a different payload so
      // it is safe to discard the content.


      if (status === 404 || status === 200 && requestData.method === 'DELETE') {
        status = 204;
        payload = null;
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForDeleteRecord() {
      return `${this.host}/account/github`;
    }

  });

  _exports.default = _default;
});