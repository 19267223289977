define("dashboard/models/github/user", ["exports", "ember-data", "@ember/object/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    avatarUrl: _emberData.default.attr('string'),
    htmlUrl: _emberData.default.attr('string'),
    login: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    username: (0, _computed.readOnly)('login')
  });

  _exports.default = _default;
});