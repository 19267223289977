define("dashboard/templates/components/app/addons-empty-state-no-addons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LIQ6RV/w",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,1,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"    There are no add-ons for this app\\n\"]],[]]]]],[6,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"    You can add add-ons to this app and they will show here. \"],[10,3],[14,6,\"https://devcenter.heroku.com/articles/managing-add-ons\"],[14,0,\"hk-link\"],[14,\"target\",\"_blank\"],[12],[1,\"Learn more\"],[13],[1,\"\\n\"]],[]]]]]],[1]]]]]],[\"well\"],false,[\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/app/addons-empty-state-no-addons.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});