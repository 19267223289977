define("dashboard/templates/components/app-item-favorite-tray/metrics/favorite-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AvYPCw3F",
    "block": "[[[10,0],[14,0,\"metrics__summary-row__label\"],[12],[1,\"\\n  \"],[2,[30,0,[\"label\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"metric-wrapper flex items-end\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"metrics__summary-row__value metrics__summary-row__value--large\"],[12],[1,\"\\n    \"],[1,[30,0,[\"displayLatestValue\"]]],[10,1],[14,0,\"metrics__summary-row__unit\"],[12],[1,[30,0,[\"displayUnit\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-grow-1\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"title\",\"timeSeriesData\",\"class\",\"chartContentData\"],[[30,0,[\"chartTitle\"]],[30,0,[\"timeSeriesData\"]],\"flex-grow-1\",[28,[37,1],null,[[\"chartTimeSeries\"],[[30,0,[\"chartTimeSeries\"]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"favorite-chart\",\"hash\"]]",
    "moduleName": "dashboard/templates/components/app-item-favorite-tray/metrics/favorite-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});