define("dashboard/templates/components/re-authenticate", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "b5UEWnxW",
    "block": "[[[6,[39,0],null,[[\"clickOverlayToClose\",\"title\",\"primaryAction\",\"primaryActionTitle\",\"containerClass\"],[false,\"Your session has expired\",[28,[37,1],[[30,0],\"confirmReauthenticate\"],null],\"Reauthenticate\",\"allow-if-delinquent\"]],[[\"default\"],[[[[1,\"   \"],[10,2],[12],[1,\"Your current session has expired or become inactive and has been\\n   terminated.\"],[13],[1,\"\\n\\n   \"],[10,2],[12],[1,\"Please log in again to continue using Dashboard.\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"hk-modal\",\"action\"]]",
    "moduleName": "dashboard/templates/components/re-authenticate.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});