define("dashboard/models/app-template", ["exports", "@ember/object/computed", "@ember/object/evented", "@ember/object", "@ember/utils", "dashboard/models/app-template-config-var", "dashboard/mixins/error-monitoring-model", "@ember/service", "rsvp"], function (_exports, _computed, _evented, _object, _utils, _appTemplateConfigVar, _errorMonitoringModel, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    not,
    match,
    notEmpty,
    or
  } = _object.computed;

  var _default = _object.default.extend(_errorMonitoringModel.default, {
    store: (0, _service.inject)(),
    url: '',
    configVarsAreValid: (0, _object.computed)('configVars.@each.isValid', function () {
      return this.configVars.isEvery('isValid');
    }),
    rawEnv: (0, _object.computed)(function () {
      return {};
    }),
    addons: (0, _object.computed)(function () {
      return [];
    }),
    invalidTemplate: false,
    isRateLimited: false,
    missingAppJSON: false,
    unknownError: false,
    invalidSourceBlob: false,
    notAuthorized: false,
    invalidAppJSON: notEmpty('appJSONErrors'),
    hasErrors: or('unknownError', 'invalidTemplate', 'isRateLimited', 'notAuthorized', 'missingAppJSON', 'invalidAppJSON', 'invalidSourceBlob'),
    hasNoErrors: not('hasErrors'),
    repoIsPrivate: false,
    repoIsPublic: not('repoIsPrivate'),
    isGitHubCom: match('url', /github\.com/),

    /**
     * fetch an app.json from GitHub url
     * allows for failing fetches and sets
     * proper errors states on to model along the way
     */
    fetch(store) {
      if (this.isGitHubCom) {
        const manifestQuery = {
          owner: this.get('github.user'),
          repoName: this.get('github.repo'),
          ref: this.get('github.branch'),
          apiOrigin: this.apiOrigin,
          endpoint: this.endpoint
        };
        return store.queryRecord('repositories-api-app-manifest', manifestQuery).then(data => {
          return this.updateFromTemplate(data);
        }, response => {
          // fail and set errors states but actually *resolve* in order
          // to allow errors to be rendered rather than
          // passing on the failure to downlevel promises
          if (typeof response === 'object' && response.message === 'Unable to parse manifest') {
            this.set('invalidAppJSON', true);
          } else {
            const error = response.errors[0];

            if (error.status === '403') {
              this.set('isRateLimited', true);
            } else if (error.status === '404') {
              this.set('missingAppJSON', true);
            } else if (error.status === '401') {
              this.set('notAuthorized', true);
            } else {
              this.set('unknownError', true);
            }
          }
        });
      } else {
        this.set('invalidTemplate', true);
      }
    },

    /**
     * use App library to parse raw app.json contents
     * retrieved from GitHub, but transform the env
     * into a rawEnv
     */
    updateFromTemplate(data) {
      this.setProperties({
        addons: data.get('addons') || [],
        description: data.get('description'),
        logo: data.get('logo'),
        isDefaultLogo: !(0, _utils.isPresent)(data.get('logo')),
        name: data.get('name') || this.displayUserRepo,
        rawEnv: data.get('env') || {},
        repository: data.get('repository'),
        website: data.get('website'),
        appJSONErrors: data.get('errors'),
        formations: data.get('formation') || {}
      });
    },

    sourceBlob(store) {
      // TODO - inject store so we don't have to pass it in for sourceBlob
      const repoNameWithOwner = this.displayUserRepo;
      const ref = encodeURIComponent(this.get('github.branch'));

      if (this.repoIsPublic) {
        return (0, _rsvp.resolve)(_object.default.create({
          archive_link: this.get('github.tarball_url')
        }));
      } else {
        return store.queryRecord('repositories-api-archive-link', {
          repoNameWithOwner,
          ref
        }).catch(() => {
          this.set('invalidSourceBlob', true);
        });
      }
    },

    /**
     * use github-url-to-object to parse
     * url and set error if not valid
     */
    setGithub: (0, _evented.on)('init', (0, _object.observer)('url', function () {
      const ghObject = this.gh(this.url);

      if (ghObject) {
        // The `gh` library does not make any distinction between a repo URL with *no* branch specified
        // and a repo URL with `/tree/master` explicitly specified. This ends up being confusing
        // when loading github templates because we later have to determine whether or not `master`
        // really is the default branch for the repo, and also whether or not they actually intend
        // to fall back to the default branch, or are explictly requesting to use the master branch
        ghObject.isExplicitlyMaster = /\/tree\/master$/.test(this.url);

        if (ghObject.isExplicitlyMaster) {
          ghObject.https_url = `${ghObject.https_url}/tree/master`;
        }

        this.set('github', _object.default.create(ghObject));
      } else {
        this.set('invalidTemplate', true);
      }
    })),

    get env() {
      const vars = this.configVars;
      return vars.reduce((hash, item) => {
        const value = item.get('value');

        if (!(0, _utils.isBlank)(value)) {
          hash[item.get('key')] = item.get('value');
        }

        return hash;
      }, {});
    },

    /**
     * Transform the rawEnv (renamed Env from app.json.js)
     * into a more manageable array of objects
     */
    configVars: (0, _object.computed)('rawEnv', function () {
      const rawEnv = this.rawEnv;
      const keys = Object.keys(rawEnv);
      return keys.map(key => {
        return _appTemplateConfigVar.default.create({
          key,
          rawValue: rawEnv[key]
        });
      });
    }),
    displayUserRepo: (0, _object.computed)('github', 'github.repo', function () {
      return `${this.get('github.user')}/${this.get('github.repo')}`;
    }),
    displayUserRepoBranch: (0, _object.computed)('displayUserRepo', 'displayBranch', function () {
      return this.displayUserRepo + this.displayBranch;
    }),
    displayBranch: (0, _object.computed)('github.branch', function () {
      return `#${this.get('github.branch')}`;
    }),
    isDefaultBranch: (0, _object.computed)('github.branch', 'github.defaultBranch', function () {
      return this.get('github.branch') === (this.get('github.defaultBranch') || 'master');
    }),
    displayFullGitHubUrl: (0, _computed.alias)('github.https_url'),
    endpoint: (0, _object.computed)('github.apiHost', function () {
      const host = this.get('github.apiHost') || '';
      return host.split('/')[0];
    }),
    endpointUrl: (0, _object.computed)('endpoint', function () {
      const endpoint = this.endpoint;
      return `https://${endpoint}`;
    }),
    apiOrigin: (0, _object.computed)('github.apiHost', function () {
      const host = this.get('github.apiHost');

      if ((0, _utils.isPresent)(host)) {
        return `https://${host}`;
      } else {
        return '';
      }
    }),
    deployType: (0, _object.computed)('repoIsPrivate', function () {
      return this.repoIsPrivate ? 'Private' : 'Public';
    })
  });

  _exports.default = _default;
});