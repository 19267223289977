define("dashboard/routes/team/base", ["exports", "@ember/routing/route", "dashboard/mixins/document-title"], function (_exports, _route, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Basic route for org routes.
   *
   * @class Dashboard.TeamBaseRoute
   * @extends Ember.Route
   */
  var _default = _route.default.extend(_documentTitle.default, {
    renderTemplate() {
      this._super.apply(this, arguments);
    },

    get documentTitle() {
      return this.modelFor('team').team.get('name');
    }

  });

  _exports.default = _default;
});