define("dashboard/components/remove-credit-card", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "moment"], function (_exports, _component, _service, _object, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),
    nextEligibleSignupPeriod: (0, _object.computed)(function () {
      return (0, _moment.default)().add('1', 'M').startOf('month');
    }),
    ecoDynosEnabled: (0, _computed.reads)('current.account.ecoDynosEnabled'),
    submitButtonDisabled: (0, _object.computed)('ecoDynosEnabled', 'paidApps.[]', 'isCCTermsAccepted', function () {
      return !this.isCCTermsAccepted || this.ecoDynosEnabled || this.paidApps.length > 0;
    }),

    _removeCreditCard() {
      return this.paymentMethod.destroyRecord();
    },

    actions: {
      removeCreditCard() {
        return this._removeCreditCard().then(() => this.onPaymentMethodRemoved());
      }

    }
  });

  _exports.default = _default;
});