define("dashboard/templates/components/async-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PXmDzFV3",
    "block": "[[[11,\"button\"],[24,4,\"submit\"],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[17,1],[4,[38,0],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[30,0]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showSpinner\"]],[[[1,\"      \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[2,[30,0,[\"buttonText\"]]],[1,\"\\n\"]],[]]]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"on\",\"if\",\"has-block\",\"yield\",\"loading-spinner\"]]",
    "moduleName": "dashboard/templates/components/async-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});