define("dashboard/serializers/attachment", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash['app'] = resourceHash['app']['id'];
      resourceHash['embedded_addon'] = resourceHash['addon'];
      resourceHash['addon'] = resourceHash['addon']['id'];
      return this._super.apply(this, arguments);
    },

    serialize(snapshot) {
      const data = {
        addon: snapshot.belongsTo('addon').id,
        app: snapshot.belongsTo('app').id
      };

      if (snapshot.record.get('namespace')) {
        data.namespace = snapshot.record.get('namespace');
      }

      if (snapshot.record.get('name')) {
        data.name = snapshot.record.get('name');
      }

      if (snapshot.record.get('confirm')) {
        data.confirm = snapshot.record.get('confirm');
      }

      return data;
    }

  });

  _exports.default = _default;
});