define("dashboard/templates/team/space", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jTnz7Q1D",
    "block": "[[[6,[39,0],null,[[\"class\",\"aria-label\"],[\"space-sub-nav\",\"Space Navigation Menu\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"link\",\"model\",\"name\",\"iconName\"],[\"team.space.apps\",[30,0,[\"model\"]],\"Apps\",\"apps-28\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"link\",\"model\",\"name\",\"iconName\"],[\"team.space.access\",[30,0,[\"model\"]],\"Access\",\"team-28\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"link\",\"model\",\"name\",\"iconName\"],[\"team.space.network\",[30,0,[\"model\"]],\"Network\",\"network-28\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"link\",\"model\",\"name\",\"iconName\"],[\"team.space.settings\",[30,0,[\"model\"]],\"Settings\",\"settings-28\"]]]],[1,\"\\n\"]],[]]]]],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"sub-nav\",\"sub-nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "dashboard/templates/team/space.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});