define("dashboard/templates/context-switcher", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "C/1Ij2va",
    "block": "[[[10,0],[14,0,\"relative context-switcher--container\"],[12],[1,\"\\n  \"],[1,[34,0]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"context-switcher\"]]",
    "moduleName": "dashboard/templates/context-switcher.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});