define("dashboard/templates/components/empty/billing-historical", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Fv7NtGSt",
    "block": "[[[1,[28,[35,0],null,[[\"headline\",\"body\"],[\"There is no historical usage data to show yet\",\"Once you've been using Heroku for more than a month we'll be able to show you your monthly usage and invoice information.\"]]]],[1,\"\\n\"]],[],false,[\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/empty/billing-historical.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});