define("dashboard/mixins/models/metrics/java-memory", ["exports", "@ember/object/mixin", "@ember/object", "dashboard/models/metrics", "dashboard/utils/metrics/aggregate-values", "dashboard/utils/metrics/latest-non-zero-value", "dashboard/utils/metrics/unit-converters", "dashboard/utils/metrics/has-any-measured-data", "dashboard/utils/metrics/metrics-data-operators"], function (_exports, _mixin, _object, _metrics, _aggregateValues, _latestNonZeroValue, _unitConverters, _hasAnyMeasuredData, _metricsDataOperators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;

  var _default = _mixin.default.create({
    testDataKeysJava: (0, _object.computed)(function () {
      return ['jvmPauseTimeOldInNs', 'jvmPauseTimeYoungInNs', 'jvmCollectionsOld', 'jvmCollectionsYoung', 'nonheapMaxBytes', 'heapMaxBytesJava', 'bufferPoolDirectBytes', 'bufferPoolMappedBytes'];
    }),
    hasAnyMeasuredDataJava: (0, _hasAnyMeasuredData.default)('testDataKeysJava'),
    jvmPauseTimeOldInNs: rawDataWithDefault('c:jvm.gc.pause.old.ns'),
    jvmPauseTimeOld: (0, _unitConverters.convertToMs)('jvmPauseTimeOldInNs'),
    jvmPauseTimeOldRatio: (0, _metricsDataOperators.dataRatio)('jvmPauseTimeOldInNs', 'stepInNs'),
    jvmPauseTimeYoungInNs: rawDataWithDefault('c:jvm.gc.pause.young.ns'),
    jvmPauseTimeYoung: (0, _unitConverters.convertToMs)('jvmPauseTimeYoungInNs'),
    jvmPauseTimeYoungRatio: (0, _metricsDataOperators.dataRatio)('jvmPauseTimeYoungInNs', 'stepInNs'),
    jvmPauseTimeTotal: (0, _metricsDataOperators.dataSum)('jvmPauseTimeYoung', 'jvmPauseTimeOld'),
    jvmPauseTimeTotalRatio: (0, _metricsDataOperators.dataAverage)('jvmPauseTimeYoungRatio', 'jvmPauseTimeOldRatio'),
    jvmCollectionsOld: rawDataWithDefault('c:jvm.gc.collections.old'),
    jvmCollectionsYoung: rawDataWithDefault('c:jvm.gc.collections.young'),
    jvmCollectionsTotal: (0, _metricsDataOperators.dataSum)('jvmCollectionsOld', 'jvmCollectionsYoung'),
    nonheapMaxBytes: rawDataWithDefault('g:jvm.memory.area.nonheap.bytes_max'),
    heapMaxBytesJava: rawDataWithDefault('g:jvm.memory.area.heap.bytes_max'),
    bufferPoolDirectBytes: rawDataWithDefault('g:jvm.bufferpool.direct.bytes_max'),
    bufferPoolMappedBytes: rawDataWithDefault('g:jvm.bufferpool.mapped.bytes_max'),
    heapMaxJava: (0, _unitConverters.convertToMB)('heapMaxBytesJava'),
    nonheapMax: (0, _unitConverters.convertToMB)('nonheapMaxBytes'),
    bufferPoolDirect: (0, _unitConverters.convertToMB)('bufferPoolDirectBytes'),
    bufferPoolMapped: (0, _unitConverters.convertToMB)('bufferPoolMappedBytes'),
    meanHeapMaxValue: (0, _aggregateValues.meanValue)('heapMaxJava'),
    meanNonheapMaxValue: (0, _aggregateValues.meanValue)('nonheapMax'),
    maxHeapUsed: (0, _aggregateValues.maxValue)('heapMaxJava'),
    maxNonheapUsed: (0, _aggregateValues.maxValue)('nonheapMax'),
    currentHeapTotal: (0, _latestNonZeroValue.default)('heapMaxJava', false),
    currentNonheapTotal: (0, _latestNonZeroValue.default)('nonheapMax', false)
  });

  _exports.default = _default;
});