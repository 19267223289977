define("dashboard/templates/components/metrics/chart-controls/process-switcher", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XJAo59mx",
    "block": "[[[41,[30,0,[\"formations\",\"isPending\"]],[[[1,\"  \"],[1,[34,1]],[1,\"\\n\"]],[]],[[[6,[39,2],null,[[\"buttonComponent\",\"buttonComponentOptions\",\"menuClass\",\"buttonClass\"],[\"metrics/chart-controls/process-option\",[28,[37,3],null,[[\"app\",\"process\",\"showIcon\",\"selectable\"],[[30,0,[\"app\"]],[30,0,[\"selectedProcess\"]],[30,0,[\"menuAvailable\"]],[30,0,[\"menuAvailable\"]]]]],[30,0,[\"menuClassNames\"]],[30,0,[\"buttonClassNames\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"allProcesses\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,6],null,[[\"tagName\",\"options\"],[\"li\",[28,[37,3],null,[[\"app\",\"process\",\"selectable\"],[[30,0,[\"app\"]],[30,1],true]]]]]]],[1,\"\\n\"]],[1]],null]],[]]]]]],[]]]],[\"process\"],false,[\"if\",\"stencil/app/process-switcher\",\"drop-down\",\"hash\",\"each\",\"-track-array\",\"metrics/chart-controls/process-option\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/process-switcher.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});