define("dashboard/serializers/team/permission", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // Make a team/permission act like a org/app/collaborator
    // That's where it's sending
    serialize(snapshot) {
      return {
        user: snapshot.record.get('user.email'),
        permissions: snapshot.record.get('permissions')
      };
    },

    normalizeCreateRecordResponse() {
      return this.normalizeUpdateRecordResponse(...arguments);
    },

    normalizeDeleteRecordResponse() {
      return this.normalizeUpdateRecordResponse(...arguments);
    },

    normalizeUpdateRecordResponse(store, primaryModelClass, _ref) {
      let {
        user,
        app,
        permissions
      } = _ref;
      // Take a response as if it's a team/app/collaborator and convert it to a
      // team/permission
      const teamId = store.peekRecord('app', app.id).get('team.id');
      return {
        data: {
          type: 'team/permission',
          id: `resourceId-${app.id}|userId-${user.id}`,
          attributes: {
            resourceId: app.id,
            name: app.name,
            type: 'app',
            user: {
              id: user.id,
              email: user.email
            },
            permissions: permissions.mapBy('name')
          },
          relationships: {
            team: {
              data: {
                id: teamId,
                type: 'team'
              }
            },
            app: {
              data: {
                id: user.id,
                type: 'app'
              }
            }
          }
        }
      };
    },

    normalizeFindHasManyResponse(store, primaryModelClass, payload) {
      // Convert a permission response that has many users on one permisison to
      // a 1 permission that has 1 user each
      const newPayload = payload.reduce(function (acc, permission) {
        permission.users.forEach(function (user) {
          const data = {
            type: 'team/permission',
            id: `resourceId-${permission.id}|userId-${user.id}`,
            attributes: {
              resourceId: permission.id,
              name: permission.name,
              type: permission.type,
              user: {
                id: user.id,
                email: user.email
              },
              permissions: user.permissions
            },
            relationships: {
              team: {
                data: {
                  id: permission.team_id,
                  type: 'team'
                }
              }
            }
          };

          if (permission.type === 'app') {
            data.relationships.app = {
              data: {
                id: permission.id,
                type: 'app'
              }
            };
          }

          acc.push(data);
        });
        return acc;
      }, []);
      return {
        data: newPayload
      };
    }

  });

  _exports.default = _default;
});