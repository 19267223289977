define("dashboard/components/app/banner-feature-flow-container", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "moment", "dashboard/mixins/preferences", "dashboard/utils/banner-not-dismissed", "dashboard/config/environment"], function (_exports, _component, _service, _object, _computed, _moment, _preferences, _bannerNotDismissed, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_preferences.default, {
    current: (0, _service.inject)(),

    init() {
      this._super(...arguments);

      this._setOriginalRenderValues();
    },

    developerExperienceBanners: ['teamBanner'],
    addonsBanners: ['introToElementsBanner', 'postgresBanner', 'loggingBanner', 'securityBanner'],
    canEnterAddonsFlow: (0, _computed.alias)('dashboardUserPreferences.isEligibleForAddonsBannerFlow'),
    cannotEnterAddonsFlow: (0, _computed.not)('canEnterAddonsFlow'),
    currentDevelopmentBannerDismissals: (0, _computed.alias)('dashboardUserPreferences.developerExperienceBannerDismissals'),
    currentAddonBannerDismissals: (0, _computed.alias)('dashboardUserPreferences.addonBannerDismissals'),
    remainingDevelopmentBanners: (0, _computed.setDiff)('developerExperienceBanners', 'currentDevelopmentBannerDismissals'),
    remainingAddonBanners: (0, _computed.setDiff)('addonsBanners', 'currentAddonBannerDismissals'),
    hasDismissedAllDeveloperBanners: (0, _computed.empty)('remainingDevelopmentBanners'),
    hasDismissedAllAddonBanners: (0, _computed.empty)('remainingAddonBanners'),
    hasNotDismissedAllDeveloperBanners: (0, _computed.not)('hasDismissedAllDeveloperBanners'),
    hasNotDismissedAllAddonBanners: (0, _computed.not)('hasDismissedAllAddonBanners'),
    appDeployments: (0, _computed.filterBy)('releases', 'isDeploy', true),
    appHasDeploys: (0, _computed.notEmpty)('appDeployments'),
    hasBeenOnPlatformForAtLeastThreeMonths: (0, _object.computed)('current.account.createdAt', function () {
      const accountCreationDate = this.current.account.createdAt;
      const requiredElapsedTimeUnit = 'months';
      const requiredElapsedTimeNum = 3;
      const elapsedTime = (0, _moment.default)().diff(accountCreationDate, requiredElapsedTimeUnit);
      return elapsedTime >= requiredElapsedTimeNum;
    }),
    hasNotDismissedPipelineBanner: (0, _bannerNotDismissed.default)('developerExperienceBannerDismissals', 'pipelineBanner'),
    hasDismissedPipelineBanner: (0, _computed.not)('hasNotDismissedPipelineBanner'),
    startBannerFlow: (0, _computed.and)('hasNotDismissedAllDeveloperBanners', 'hasNotDismissedPipelineBanner'),
    finishBannerFlow: (0, _computed.and)('hasDismissedAllAddonBanners', 'hasDismissedPipelineBanner', 'hasNotDismissedAllDeveloperBanners'),
    onlyEligibleForDevFlow: (0, _computed.and)('hasNotDismissedAllDeveloperBanners', 'cannotEnterAddonsFlow'),
    shouldRenderDevContainer: (0, _computed.or)('startBannerFlow', 'finishBannerFlow', 'onlyEligibleForDevFlow'),
    pipelineRejectedFlow: (0, _computed.and)('hasDismissedPipelineBanner', 'hasNotDismissedAllAddonBanners', 'canEnterAddonsFlow'),
    remainingAddonsBannersFlow: (0, _computed.and)('hasDismissedAllDeveloperBanners', 'hasNotDismissedPipelineBanner', 'hasNotDismissedAllAddonBanners', 'canEnterAddonsFlow'),
    shouldRenderAddonContainer: (0, _computed.or)('pipelineRejectedFlow', 'remainingAddonsBannersFlow'),
    containersDidNotChange: (0, _object.computed)('shouldRenderDevContainer', 'shouldRenderAddonContainer', function () {
      let valuesDidNotChange = true;

      if (this.originalShouldRenderDevContainer !== this.shouldRenderDevContainer) {
        valuesDidNotChange = false;
      } else if (this.originalShouldRenderAddonContainer !== this.shouldRenderAddonContainer) {
        valuesDidNotChange = false;
      }

      return valuesDidNotChange;
    }),
    bannerFeatureIsEnabled: (0, _object.computed)(function () {
      return _environment.default.bannerFeatureIsEnabled === 'true';
    }),
    canEnterBannerFeature: (0, _computed.and)('bannerFeatureIsEnabled', 'containersDidNotChange'),

    _setOriginalRenderValues() {
      this.set('originalShouldRenderDevContainer', this.shouldRenderDevContainer);
      this.set('originalShouldRenderAddonContainer', this.shouldRenderAddonContainer);
    }

  });

  _exports.default = _default;
});