define("dashboard/helpers/callback-cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let __callbackCounter__ = 0;

  class CallbackCache {
    constructor() {
      this._cache = {};
    }

    add(room, eventName, callback) {
      let ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      let handlers = this._cache[room];

      if (!handlers) {
        handlers = this._cache[room] = {};
      }

      if (!handlers[eventName]) {
        handlers[eventName] = [];
      }

      if (!ref) {
        ref = ++__callbackCounter__;
      }

      handlers = handlers[eventName];
      handlers.push({
        ref,
        callback
      });
      return ref;
    }

    remove(room, eventName, ref) {
      let handlers = this._cache[room];

      if (handlers) {
        handlers = handlers[eventName];
      }

      if (handlers) {
        for (let i = 0; i < handlers.length; i++) {
          const handler = handlers[i];

          if (handler.ref === ref) {
            handlers.splice(i, 1);
            return handler.callback;
          }
        }
      }
    }

  }

  _exports.default = CallbackCache;
});