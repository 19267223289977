define("dashboard/components/remote-terminal", ["exports", "@ember/component", "@ember/service", "@ember/runloop", "rsvp", "jquery"], function (_exports, _component, _service, _runloop, _rsvp, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // https://github.com/chalk/ansi-regex
  // eslint-disable-next-line no-control-regex
  const ANSI_REGEX = /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-PRZcf-nqry=><]/g;

  var _default = _component.default.extend({
    displayProcessDidExcite: false,
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    phoenixSocket: (0, _service.inject)(),

    didInsertElement() {
      this._super(...arguments);

      this._setFocusOnCommandInput(); // get one reference to resize function to use for binding/unbinding


      this.set('resizeHandler', this.onResize.bind(this));
    },

    willDestroyElement() {
      this._resetComponent();

      this._super(...arguments);
    },

    _resetComponent() {
      this._destroyTerminal.bind(this)();

      this._leaveChannel.bind(this)();

      $(window).off('resize', this.resizeHandler);
      (0, _runloop.run)(() => {
        this.set('displayProcessDidExcite', false);
        this.set('hasDyno', false);
      });
    },

    _leaveChannel() {
      const channel = this.channel;
      const phxSocket = this.phoenixSocket;

      if (channel) {
        phxSocket.unjoin(channel);
        (0, _runloop.run)(() => {
          this.set('channel', undefined);
        });
      }
    },

    _destroyTerminal() {
      if (this.fitAddon) {
        this.fitAddon.dispose();
        (0, _runloop.run)(() => {
          this.set('fitAddon', undefined);
        });
      }

      if (this.terminal) {
        this.terminal.dispose();
        (0, _runloop.run)(() => {
          this.set('terminal', undefined);
        });
      }
    },

    _setFocusOnCommandInput() {
      _runloop.run.scheduleOnce('afterRender', this, function () {
        (0, _jquery.default)(this.element).find('.hk-input').focus();
      });
    },

    _didConnectToRendezvous() {
      this.terminal.reset();
    },

    establishDynoSession() {
      const component = this;
      const channel = this.channel;
      const dyno = this.store.createRecord('dyno', {
        app: this.app
      });
      (0, _runloop.run)(() => {
        dyno.set('command', this.command);
        this.set('command', '');
      });
      return dyno.save().then(() => {
        channel.push('attach', {
          url: dyno.get('attachUrl'),
          command: dyno.get('command')
        }).receive('error', () => {
          const terminal = component.get('terminal');
          terminal.reset();
          terminal.write('\x1b[31mUnable to connect to dyno.\x1b[m');
          component.get('_leaveChannel').bind(component)();
        });
      });
    },

    // resize the terminal's columns/lines based on the parent div's size
    // which at the time of writing is `div#terminal-place-holder`
    fit() {
      this.fitAddon.fit();
    },

    async _loadXterm() {
      const [{
        Terminal
      }, {
        FitAddon
      }] = await _rsvp.Promise.all([emberAutoImportDynamic("xterm"), emberAutoImportDynamic("xterm-addon-fit")]);
      return {
        Terminal,
        FitAddon
      };
    },

    // resize the terminal when the screen size changes
    onResize() {
      _runloop.run.debounce(this, this.fit.bind(this), 300);
    },

    captureConsoleSession() {
      const data = this.history.join('');
      const name = `${this.get('app.name')}-console-${Date.now()}.txt`;
      const type = 'data:attachment/text';

      if (data !== null && navigator.msSaveBlob) {
        return navigator.msSaveBlob(new Blob([data], {
          type
        }), name);
      }

      const url = window.URL.createObjectURL(new Blob([data], {
        type
      }));
      this.set('consoleOutputBlob', url);
      this.set('filename', name);
    },

    actions: {
      setCommand(command) {
        (0, _runloop.run)(() => {
          this.set('command', command);
        });
      },

      // We need to click the button instead of invoking the action directly
      // so that the async button functionality works properly
      enter() {
        _runloop.run.next(function () {
          $('#run-console-button').click();
        });
      },

      submit() {
        return new _rsvp.Promise((resolve, reject) => {
          // create element to become the Terminal
          const placeHolder = document.getElementById('terminal-place-holder');
          const phxSocket = this.phoenixSocket;
          const component = this;
          phxSocket.connect().then(() => {
            // join `console:#{user.id + something_random}`
            // something_random to handle when a user has
            // multiple tabs/console sessions simultaneously
            const accountId = this.get('current.accountId');
            const randomValue = Math.random();
            const {
              channel,
              connection
            } = phxSocket.join(`console:${accountId};${randomValue}`);
            this.set('channel', channel); // upon successful connection..

            connection.receive('ok', () => {
              channel.on('didConnectToRendezvous', () => {
                component.get('_didConnectToRendezvous').bind(component)();
              });
              channel.on('didDisconnectFromRendezvous', () => {
                component.get('_leaveChannel').bind(component)();
              }); // this is called if the websocket connection suddenly dies

              channel.onError(() => {
                component.get('_leaveChannel').bind(component)();
              }); // this is called when the rendezvous session ends; think bash session and you enter `exit`
              // but this is _also_ called if the websocket connection suddenly dies
              // -- so be mindful of duplicating actions in `onError`

              channel.onClose(() => {
                (0, _runloop.run)(() => {
                  // prompt the user to issue another command
                  component.set('displayProcessDidExcite', true);
                  this.captureConsoleSession();
                });
              });
              setupTerminalSession(channel);
            });
          }).catch(err => {
            component.get('_leaveChannel').bind(component)();
            reject(err);
          });

          async function setupTerminalSession(channel) {
            const {
              Terminal,
              FitAddon
            } = await component._loadXterm();
            const theme = {
              fontWeight: 'normal',
              background: 'white',
              // $dark-gray in scss
              foreground: '#596981',
              cursor: '#4C84DC',
              // #4a82d8, but in rgb with a bit of transparency so text
              // is visible in the selection
              selection: 'rgb(76, 132, 220, 0.3)',
              red: '#4a82d8',
              brightGreen: '#4a82d8',
              brightBlue: '#4a82d8'
            };
            const terminal = new Terminal({
              useStyle: true,
              screenKeys: false,
              cursorBlink: true,
              rendererType: 'dom',
              fontSize: '13px',
              theme
            });
            const fitAddon = new FitAddon();
            terminal.loadAddon(fitAddon);
            (0, _runloop.run)(() => {
              component.set('terminal', terminal);
              component.set('fitAddon', fitAddon);
              component.set('history', []);
            });
            channel.on('data', _ref => {
              let {
                data
              } = _ref;
              terminal.write(data);
              const detokenized = data.replace(ANSI_REGEX, '');
              (0, _runloop.run)(() => {
                component.get('history').push(detokenized);
              });
            });
            terminal.onData(data => channel.push('data', {
              data
            })); // component.get('establishDynoSession').bind(component)()

            component.establishDynoSession().then(() => {
              terminal.open(placeHolder); // render the terminal

              component.get('fit').bind(component)();
              terminal.write('\x1b[31mAttaching to dyno...\x1b[m');
              (0, _runloop.run)(() => {
                component.set('hasDyno', true); // can't call bind here because it would yield a new
                // function which would leave us unable to `.off(...)` later

                $(window).on('resize', component.get('resizeHandler'));
              });
              resolve();
            }).catch(err => {
              // this error is completely lost otherwise:
              console.error(err); // eslint-disable-line no-console

              reject();
            });
          }
        });
      },

      resetComponent() {
        this._resetComponent();

        this._setFocusOnCommandInput();
      }

    }
  });

  _exports.default = _default;
});