define("dashboard/components/space/access/member-modal", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isEditing: (0, _computed.not)('item.isNew'),

    dehumanize(perm) {
      if (perm.name === 'app creation') {
        perm.set('name', 'create_apps');
      }
    },

    actions: {
      selectPermission(permToSelect) {
        this.dehumanize(permToSelect);
        this.selectPermission(permToSelect);
      },

      deselectPermission(permToRemove) {
        this.dehumanize(permToRemove);
        this.deselectPermission(permToRemove);
      }

    }
  });

  _exports.default = _default;
});