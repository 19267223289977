define("dashboard/templates/components/space-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dkaNldBK",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[[30,0,[\"name\"]],[30,0,[\"size\"]],[28,[37,1],[[30,0,[\"iconClass\"]],\" \",[30,0,[\"class\"]]],null],[30,0,[\"title\"]]]],null],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"concat\"]]",
    "moduleName": "dashboard/templates/components/space-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});