define("dashboard/components/metrics/charts/connected-chart-hover/item/horizontal-scale", ["exports", "dashboard/components/metrics/charts/connected-chart-hover/item", "@ember/object", "@ember/object/computed", "dashboard/utils/pluralize"], function (_exports, _item, _object, _computed, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    swatchColor: 'activity',
    userEmail: (0, _computed.readOnly)('model.userEmail'),
    description: (0, _object.computed)('userEmail', 'model.isAutoscaleEvent', function () {
      return this.get('model.isAutoscaleEvent') ? 'Dyno Autoscaling' : this.userEmail;
    }),
    title: (0, _object.computed)('model.quantity', 'model.scaleDirection', function () {
      const noun = (0, _pluralize.default)(this.get('model.quantity'), 'dyno', 'dynos');
      return `Scaled formation ${this.get('model.scaleDirection')} to ${this.get('model.quantity')} ${noun}`;
    })
  });

  _exports.default = _default;
});