define("dashboard/templates/components/context-switcher/show-more", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ArJBikV8",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"limitedCollection\"]]],null]],null],null,[[[1,\"  \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"collection\",\"length\"]],[30,0,[\"limit\"]]],null],[[[41,[28,[37,5],[[30,0,[\"limitProxy\"]],[28,[37,4],[[30,0,[\"remaining\"]],0],null]],null],[[[1,\"    \"],[11,3],[24,0,\"hk-link inline-flex dib items-center f5 blue hover-blue ph3 pv1 nudge-right--2 cursor-pointer\"],[4,[38,6],[[30,0],[28,[37,7],[[30,0,[\"limitProxy\"]]],null],null],[[\"bubbles\"],[false]]],[12],[1,\"\\n      View \"],[1,[30,0,[\"remaining\"]]],[1,\" more\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,3],[24,0,\"hk-link inline-flex dib items-center f5 blue hover-blue ph3 pv1 nudge-right--2 cursor-pointer\"],[4,[38,6],[[30,0],[28,[37,7],[[30,0,[\"limitProxy\"]]],null],[30,0,[\"limit\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n      View less\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"item\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"if\",\"gt\",\"and\",\"action\",\"mut\"]]",
    "moduleName": "dashboard/templates/components/context-switcher/show-more.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});