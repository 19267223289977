define("dashboard/models/production-check/logging", ["exports", "dashboard/mixins/production-check", "@ember/object", "@ember/object/computed"], function (_exports, _productionCheck, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    devCenterURL: 'https://devcenter.heroku.com/articles/production-check#log-monitoring"',
    title: 'Log monitoring',
    failedMessage: 'No logging add-on found. Install a <a class="hk-link" href="https://elements.heroku.com/addons#logging">logging add-on</a> to monitor and search your logs.',
    loggingAddons: (0, _computed.filterBy)('app.attachments', 'isLogging', true),
    status: (0, _object.computed)('loggingAddons.[]', function () {
      return this.loggingAddons.length > 0 ? 'passed' : 'failed';
    })
  });

  _exports.default = _default;
});