define("dashboard/templates/install-github-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kHOPTI+5",
    "block": "[[[10,0],[14,0,\"new-header text-center flex-grow-1\"],[12],[1,\"\\n  Install GitHub\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/install-github-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});