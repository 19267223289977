define("dashboard/adapters/app/webhook-delivery", ["exports", "dashboard/adapters/application", "@ember/object", "@ember/polyfills"], function (_exports, _application, _object, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.webhooks',
    additionalHeaders: null,
    headers: (0, _object.computed)('additionalHeaders', function () {
      const headers = this._super();

      const additionalHeaders = this.additionalHeaders;
      return (0, _polyfills.merge)(headers, additionalHeaders);
    }),
    range: {
      attribute: 'seq',
      ascending: false
    },

    urlForQuery(query) {
      this.set('additionalHeaders', null);
      let url = `${this.host}/apps/${query.appName}/webhook-deliveries?eq[webhook[name]]=${query.webhookName}`;

      if (query.filterBy) {
        url += `&eq[status]=${query.filterBy}`;
      }

      delete query.filterBy;
      delete query.appName;
      delete query.webhookName;
      return url;
    },

    urlForFindRecord(id, modelName, snapshot) {
      this.set('additionalHeaders', {
        'Accept-Inclusion': 'last_attempt:request,last_attempt:response'
      });
      const webhook = snapshot.belongsTo('webhook');
      const app = webhook.belongsTo('app');
      return `${this.host}/apps/${app.id}/webhook-deliveries/${id}`;
    }

  });

  _exports.default = _default;
});