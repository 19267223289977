define("dashboard/models/log-session", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _model.attr)('date'),
    logplexUrl: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    dyno: null,
    // dyno (process) to limit results to, e.g. 'web.1'
    lines: 10,
    // number of log lines to stream at once
    source: null,
    // app, router, ... (doesn't appear to work...)
    tail: true // whether to stream ongoing logs

  });

  _exports.default = _default;
});