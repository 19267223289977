define("dashboard/mixins/permissions", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    copy() {
      return _object.default.create({
        name: this.name,
        description: this.description
      });
    }

  });

  _exports.default = _default;
});