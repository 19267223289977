define("dashboard/models/pipeline-promotion", ["exports", "@ember-data/model", "@ember/object", "@ember/service", "dashboard/mixins/web-socket-model"], function (_exports, _model, _object, _service, _webSocketModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal
  } = _object.computed;
  const {
    mapBy,
    filterBy,
    readOnly
  } = _object.computed;
  const PENDING = 'pending';
  const FAILED = 'failed';
  const SUCCEEDED = 'succeeded';

  var _default = _model.default.extend(_webSocketModel.default, {
    realTimeUpdater: (0, _service.inject)(),
    socketIdentifier: (0, _object.computed)('id', function () {
      return `pipeline-promotions/${this.id}`;
    }),
    status: (0, _model.attr)('string'),
    pipeline: (0, _model.belongsTo)('pipeline'),
    source: (0, _model.belongsTo)('app'),
    // pipeline-promotion-target models
    // These hold status and a join to the target app model
    promotionTargets: (0, _model.hasMany)('pipeline-promotion-target'),
    // pipeline-promotion-target's app models
    // These are the apps themselves that are joined to pipeline-promotion-target
    promotionTargetApps: mapBy('promotionTargets', 'app'),
    targets: mapBy('promotionTargetApps', 'content'),
    isPending: equal('aggregateStatus', PENDING),
    isFailed: equal('aggregateStatus', FAILED),
    isSucceeded: equal('aggregateStatus', SUCCEEDED),
    failedTargets: filterBy('promotionTargets', 'isFailed', true),
    failedCount: readOnly('failedTargets.length'),
    aggregateStatus: (0, _object.computed)('status', 'promotionTargets.@each.status', function () {
      const promotionTargets = this.promotionTargets;

      if (promotionTargets.get('length') === 0) {
        return this.status;
      }

      if (promotionTargets.isAny('isFailed')) {
        return FAILED;
      }

      if (promotionTargets.isAny('isPending')) {
        return PENDING;
      }

      return SUCCEEDED;
    }),

    addTarget(promotionTarget) {
      this.promotionTargets.pushObject(promotionTarget);
    },

    removeTarget(promotionTarget) {
      // TODO: Ember Data 3 investigation, we should able to use unloadRecord here
      // We only get away with using destroyRecord because we know these records
      // will always be in the 'new' state
      promotionTarget.destroyRecord();
    },

    subscribeToPromotionTargets() {
      this.realTimeUpdater.subscribeToModelDefaults(this, 'pipeline-promotion-targets');
    }

  });

  _exports.default = _default;
});