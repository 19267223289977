define("dashboard/helpers/currency-format", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currency = currency;
  _exports.default = void 0;

  /**
   * A helper for displaying currency values.
   *
   * - Render a simple currency value (renders "$1,000.00"):
   *
   *   ```handlebars
   *   {{currency-format 1000}}
   *   ```
   *
   * - Render a value passed in in cents (renders "$1.00"):
   *
   *   ```handlebars
   *   {{currency-format 100 unit='cents'}}
   *   ```
   *
   * - Display "Free" if the price is (renders "Free"):
   *
   *   ```handlebars
   *   {{currency-format 0 showFree=true}}
   *   ```
   *
   * - Append a suffix onto the price (will not display for Free items if
   *   `showFree` is true.
   *
   *   ```handlebars
   *   {{currency-format 5 suffix='/month'}}
   *   ```
   *
   * @class Ember.Handlebars.helpers.currency-format
   */
  function currency(value, options) {
    // depending on how this helper is invoked value with either be an array or a number
    value = value instanceof Array ? value[0] : value;
    value = value ? value : 0;
    const unit = options.unit || 'dollars';
    const showFree = options.showFree || false;
    const suffix = options.suffix || '';

    if (unit === 'cents') {
      value /= 100;
    }

    if (showFree && value === 0) {
      return 'Free';
    } else {
      return `$${insertCommas(value.toFixed(2))}${suffix}`;
    }

    function insertCommas(commalessValue) {
      commalessValue = String(commalessValue);
      const parts = commalessValue.split('.');
      let preDecimal = parts[0];
      const postDecimal = parts.length > 1 ? `.${parts[1]}` : '';
      const regex = /(\d+)(\d{3})/;

      while (regex.test(preDecimal)) {
        preDecimal = preDecimal.replace(regex, '$1' + ',' + '$2');
      }

      return preDecimal + postDecimal;
    }
  }

  var _default = (0, _helper.helper)(currency);

  _exports.default = _default;
});