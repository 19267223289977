define("dashboard/routes/account/billing/confirm-credit-card-removal", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    afterModel(model) {
      const {
        unverifyPaymentMethodRequest
      } = model;

      if (unverifyPaymentMethodRequest && unverifyPaymentMethodRequest.get('waitingForUserInput')) {// keep going to show them the confirm ui
      } else {
        this.transitionTo('account.billing');
      }
    },

    actions: {
      finalizeCreditCardRemoval(unverifyPaymentMethodRequest) {
        return unverifyPaymentMethodRequest.confirm().then(() => {
          this.send('backToBilling');
        });
      }

    }
  });

  _exports.default = _default;
});