define("dashboard/templates/app/scheduler", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rFcWOTea",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"header-meta\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasPersistedJobs\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"onClick\",\"default\"],[[28,[37,3],[[30,0],\"createJob\"],null],\"Add Job\"]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"limit-width flex flex-column flex-row-ns\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto mv4 \"],[12],[1,\"\\n\"],[41,[30,0,[\"hasPersistedJobs\"]],[[[1,\"      \"],[10,0],[14,0,\"relative\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"currentTier\",\"jobs\",\"createJob\",\"editJob\",\"deleteJob\"],[[30,0,[\"app\",\"currentTier\"]],[30,0,[\"persistedJobs\"]],[28,[37,3],[[30,0],\"createJob\"],null],[28,[37,3],[[30,0],\"editJob\"],null],[28,[37,3],[[30,0],\"deleteJob\"],null]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,5],null,[[\"createJob\"],[[28,[37,3],[[30,0],\"createJob\"],null]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isShowingJobEditor\"]],[[[1,\"  \"],[1,[28,[35,6],null,[[\"app\",\"job\",\"save\",\"close\"],[[30,0,[\"app\"]],[30,0,[\"job\"]],[28,[37,3],[[30,0],\"save\"],null],[28,[37,3],[[30,0],\"close\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"send-to-outlet\",\"if\",\"hk-button\",\"action\",\"scheduler/jobs-list\",\"scheduler/empty-state\",\"scheduler/job-editor\"]]",
    "moduleName": "dashboard/templates/app/scheduler.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});