define("dashboard/models/scale-event", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "moment"], function (_exports, _model, _object, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    formation: (0, _model.belongsTo)('formation'),
    isAutoscaleEvent: (0, _model.attr)('boolean'),
    previousQuantity: (0, _model.attr)('number'),
    previousSize: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    size: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    userEmail: (0, _model.attr)('string'),
    createdAt: (0, _computed.readOnly)('updatedAt'),
    scaleDirection: (0, _object.computed)('quantity', 'previousQuantity', function () {
      let direction = '';

      if (this.quantity > this.previousQuantity) {
        direction = 'up';
      } else if (this.previousQuantity > this.quantity) {
        direction = 'down';
      }

      return direction;
    }),
    isHorizontalScale: (0, _computed.notEmpty)('previousQuantity'),
    isVerticalScale: (0, _computed.notEmpty)('previousSize'),
    time: (0, _object.computed)('updatedAt', function () {
      return (0, _moment.default)(this.updatedAt).valueOf();
    })
  });

  _exports.default = _default;
});