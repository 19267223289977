define("dashboard/serializers/kolkrabbi/github-branch", ["exports", "dashboard/serializers/application", "ember"], function (_exports, _application, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeFindHasManyResponse(store, primaryModelClass, payload, id, requestType) {
      const extractedPayload = payload.map(name => {
        const guid = _ember.default.generateGuid();

        return {
          id: guid,
          name
        };
      });
      return this._super(store, primaryModelClass, extractedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});