define("dashboard/templates/components/errors/access-denied", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pwa+POXR",
    "block": "[[[10,0],[14,0,\"huge-text\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/images/error-access-denied.png\"],[14,\"alt\",\"Error\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"error-text\"],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Access denied.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    This Heroku account does not have the required permissions to access this page.\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/errors/access-denied.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});