define("dashboard/components/new/template-addons", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['template-addons'],
    accountFeatures: (0, _service.inject)(),
    current: (0, _service.inject)(),
    router: (0, _service.inject)(),
    // Args
    addons: [],
    formations: {},
    team: null,
    hideEcoBanner: false,
    isSubscribedToEco: (0, _computed.bool)('current.account.ecoDynosEnabled'),
    isEnterpriseTeamApp: (0, _computed.bool)('team.isEnterpriseTeam'),
    isPersonalApp: (0, _computed.not)('team'),
    defaultFormation: (0, _object.computed)('isSubscribedToEco', 'isEnterpriseTeamApp', function () {
      let defaultSize;

      if (this.isEnterpriseTeamApp) {
        defaultSize = 'standard-1x';
      } else if (this.isSubscribedToEco) {
        defaultSize = 'eco';
      } else {
        defaultSize = 'basic';
      }

      return {
        type: 'web',
        size: defaultSize,
        quantity: 1
      };
    }),
    definedFormationList: (0, _object.computed)('defaultFormation', 'formations.[]', function () {
      if (Object.keys(this.formations).length === 0) {
        // No formation defined, return our default
        return [this.defaultFormation];
      }

      const formationList = [];

      for (const [key, value] of Object.entries(this.formations)) {
        // Merge the default and the current formation in case any properties are undefined
        const formation = Object.assign({}, this.defaultFormation, {
          type: key,
          ...value
        });
        formationList.push(formation);
      }

      return formationList;
    }),
    hasEcoFormation: (0, _object.computed)('definedFormationList.[]', function () {
      return this.definedFormationList.some(formation => formation.size.match(/^eco$/i));
    }),
    showSubscribeToEcoBanner: (0, _object.computed)('hideEcoBanner', 'isSubscribedToEco', 'hasEcoFormation', 'isPersonalApp', function () {
      if (this.isSubscribedToEco || this.hideEcoBanner) {
        return false;
      }

      if (this.hasEcoFormation || this.isPersonalApp) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      transitionToAccountBilling() {
        this.router.transitionTo('account.billing');
      }

    }
  });

  _exports.default = _default;
});