define("dashboard/services/invoice-manager", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    year: null,
    month: null,

    populate(year, month) {
      this.set('year', parseInt(year));
      this.set('month', parseInt(month));
    },

    clear() {
      this.set('year', null);
      this.set('month', null);
    }

  });

  _exports.default = _default;
});