define("dashboard/adapters/metrics/language-specific-memory", ["exports", "dashboard/adapters/metrics"], function (_exports, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend({
    namespace: 'apps',
    resource: 'formation/web/metrics/lang'
  });

  _exports.default = _default;
});