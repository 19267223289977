define("dashboard/serializers/enterprise-account/member", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      enterpriseAccountName: {
        serialize: false
      },
      enterpriseAccount: {
        serialize: false
      },
      userId: {
        serialize: false
      },
      userEmail: {
        serialize: false
      },
      twoFactorAuthentication: {
        serialize: false
      },
      identityProvider: {
        serialize: false
      }
    },

    extractId(modelClass, payload) {
      return `${payload.enterprise_account.id}/${payload.id}`;
    },

    extractRelationships(typeClass, payload) {
      const relationships = this._super(...arguments);

      const enterpriseAccountID = payload.enterprise_account.id;
      relationships.enterpriseAccount = {
        data: {
          id: enterpriseAccountID,
          type: 'enterprise-account'
        }
      };
      return relationships;
    },

    extractAttributes(modelClass, payload) {
      const attributes = this._super(...arguments);

      attributes.enterpriseAccountName = payload.enterprise_account.name;
      attributes.userId = payload.user.id;
      attributes.userEmail = payload.user.email;
      attributes.permissions = payload.permissions.mapBy('name'); // user POJO is only used for create??
      // don't include it.

      delete attributes.user;
      return attributes;
    },

    serializeAttribute(snapshot, json, key) {
      if (key === 'user' && snapshot.id) {
        return;
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});