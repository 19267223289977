define("dashboard/models/metrics/datastores/kafka", ["exports", "dashboard/models/metrics", "@ember/object"], function (_exports, _metrics, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PER_TOPIC_MESSAGE_PREFIX = _exports.PER_TOPIC_DATA_SIZE_PREFIX = _exports.PER_TOPIC_BYTES_OUT_PREFIX = _exports.PER_TOPIC_BYTES_IN_PREFIX = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;
  const PER_TOPIC_MESSAGE_PREFIX = 'per_topic_messages_in_per_second';
  _exports.PER_TOPIC_MESSAGE_PREFIX = PER_TOPIC_MESSAGE_PREFIX;
  const PER_TOPIC_DATA_SIZE_PREFIX = 'per_topic_replicated_log_size';
  _exports.PER_TOPIC_DATA_SIZE_PREFIX = PER_TOPIC_DATA_SIZE_PREFIX;
  const PER_TOPIC_BYTES_IN_PREFIX = 'per_topic_bytes_in_per_second';
  _exports.PER_TOPIC_BYTES_IN_PREFIX = PER_TOPIC_BYTES_IN_PREFIX;
  const PER_TOPIC_BYTES_OUT_PREFIX = 'per_topic_bytes_out_per_second';
  _exports.PER_TOPIC_BYTES_OUT_PREFIX = PER_TOPIC_BYTES_OUT_PREFIX;

  var _default = _metrics.default.extend({
    ioDataSizeBytes: rawDataWithDefault('__cluster_log_size'),
    ioMessages: rawDataWithDefault('__cluster_messages'),
    ioBytesInBytes: rawDataWithDefault('__cluster_bytes_in'),
    ioBytesOutBytes: rawDataWithDefault('__cluster_bytes_out'),
    perTopicKeys: (0, _object.computed)('rawData', function () {
      return Object.keys(this.rawData).filter(key => {
        return key.indexOf('per_topic') === 0;
      });
    }),
    perTopicBytesInKeys: (0, _object.computed)('perTopicKeys', function () {
      return this.perTopicKeys.filter(key => {
        return key.indexOf(PER_TOPIC_BYTES_IN_PREFIX) === 0;
      });
    }),
    perTopicBytesOutKeys: (0, _object.computed)('perTopicKeys', function () {
      return this.perTopicKeys.filter(key => {
        return key.indexOf(PER_TOPIC_BYTES_OUT_PREFIX) === 0;
      });
    }),
    perTopicMessagesKeys: (0, _object.computed)('perTopicKeys', function () {
      return this.perTopicKeys.filter(key => {
        return key.indexOf(PER_TOPIC_MESSAGE_PREFIX) === 0;
      });
    }),
    perTopicDataSizeKeys: (0, _object.computed)('perTopicKeys', function () {
      return this.perTopicKeys.filter(key => {
        return key.indexOf(PER_TOPIC_DATA_SIZE_PREFIX) === 0;
      });
    }),
    dynamicTopicData: (0, _object.computed)('rawData', 'perTopicDataSizeKeys', 'perTopicBytesInKeys', 'perTopicBytesOutKeys', function () {
      const data = _object.default.create({
        rawData: this.get('rawData')
      });

      const allRelevantKeys = [...this.perTopicDataSizeKeys, ...this.perTopicBytesInKeys, ...this.perTopicBytesOutKeys, ...this.perTopicMessagesKeys];
      allRelevantKeys.forEach(key => {
        (0, _object.defineProperty)(data, key, rawDataWithDefault(key));
      });
      return data;
    })
  });

  _exports.default = _default;
});