define("dashboard/models/pipeline/github-repository", ["exports", "ember-data/attr", "ember-data/model", "@ember/object", "ember-data/relationships"], function (_exports, _attr, _model, _object, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // A true value for this property indicates Review Apps 2.0
    reviewApps: (0, _attr.default)('boolean'),
    automaticReviewApps: (0, _attr.default)('boolean'),
    creator: (0, _attr.default)(),
    ci: (0, _attr.default)('boolean'),
    createdAt: (0, _attr.default)('date'),
    githubOwner: (0, _relationships.belongsTo)('github/user'),
    name: (0, _attr.default)(),
    organization: (0, _attr.default)(),
    owner: (0, _attr.default)(),
    pipeline: (0, _relationships.belongsTo)('pipeline'),
    repository: (0, _attr.default)(),
    type: (0, _attr.default)(),
    updatedAt: (0, _attr.default)('date'),
    statuses: (0, _attr.default)(),
    webUrl: (0, _object.computed)('name', function () {
      return `https://github.com/${this.name}`;
    })
  });

  _exports.default = _default;
});