define("dashboard/helpers/add", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;

  /**
   * This helper can be used to increment a number from a handelbars template
   * Usage
   * {{add initialValue valueToIncrementBy}}
   */
  function add(params) {
    const formattedStartValue = Number(params[0]);
    const formattedIncrement = Number(params[1]);
    return formattedStartValue + formattedIncrement;
  }

  var _default = (0, _helper.helper)(add);

  _exports.default = _default;
});