define("dashboard/components/deploy/pipeline-section", ["exports", "@ember/object", "@ember/service", "@ember/component", "@ember/object/computed", "dashboard/helpers/lazy-property"], function (_exports, _object, _service, _component, _computed, _lazyProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    pipeline: (0, _computed.readOnly)('coupling.pipeline'),
    isConnected: (0, _computed.notEmpty)('coupling'),
    isLoadingPipelines: true,
    didLoadPipelines: (0, _object.observer)('pipelines', function () {
      this.set('isLoadingPipelines', false);
    }),
    createButtonDisabled: (0, _computed.or)('newPipeline.isSaving', 'coupling.isSaving', 'newPipeline.nameIsInvalid', 'newPipeline.nameIsEmpty'),
    title: (0, _object.computed)('isConnected', function () {
      return this.isConnected ? 'Connected to a pipeline' : 'Add this app to a pipeline';
    }),
    description: (0, _object.computed)('isConnected', function () {
      return this.isConnected ? '' : 'Create a new pipeline or choose an existing one and add this app to a stage in it.';
    }),
    unsortedPipelines: (0, _lazyProperty.default)(() => (void 0).store.findAll('pipeline')),
    pipelineSorting: ['name:asc'],
    pipelines: (0, _computed.sort)('unsortedPipelines', 'pipelineSorting')
  });

  _exports.default = _default;
});