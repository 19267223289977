define("dashboard/components/datastore/timeframe-selector", ["exports", "dashboard/components/metrics/chart-controls/timeframe-selector", "@ember/object", "@ember/object/computed", "dashboard/templates/components/metrics/chart-controls/timeframe-selector"], function (_exports, _timeframeSelector, _object, _computed, _timeframeSelector2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _timeframeSelector.default.extend({
    layout: _timeframeSelector2.default,
    timeframeOptions: (0, _computed.map)('datastoreOptions', opt => _object.default.create(opt))
  });

  _exports.default = _default;
});