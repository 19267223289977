define("dashboard/serializers/payment-method/app", ["exports", "dashboard/serializers/app"], function (_exports, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _app.default.extend({});

  _exports.default = _default;
});