define("dashboard/controllers/team", ["exports", "@ember/object", "@ember/controller", "@ember/service"], function (_exports, _object, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    alias,
    not,
    or,
    readOnly
  } = _object.computed;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    queryParams: ['bulkAppTransfer'],
    bulkAppTransfer: null,
    applicationController: (0, _controller.inject)('application'),
    team: alias('model.team'),
    teamPermissions: readOnly('current.teamPermissions'),
    isEnterpriseTeam: alias('team.isEnterpriseTeam'),
    isNotEnterpriseTeam: not('isEnterpriseTeam'),
    hasBillingPermissions: or('teamPermissions.canViewInvoices', 'teamPermissions.canViewPayments', 'teamPermissions.canManagePayments'),
    hasUsagePermissions: or('teamPermissions.canViewLicenses', 'teamPermissions.canViewUsage'),
    showAccessTab: readOnly('teamPermissions.canViewUsers'),
    showDatastoresTab: readOnly('accountFeatures.dashboardDataUx.enabled'),
    showBillingTab: and('isNotEnterpriseTeam', 'hasBillingPermissions'),
    showResourcesTab: and('isEnterpriseTeam', 'teamPermissions.canViewResources'),
    showSpacesTab: readOnly('team.canManageSpaces'),
    // this checks a team feature flag, not a permission
    showUsageTab: and('isEnterpriseTeam', 'hasUsagePermissions'),
    showSettingsTab: readOnly('teamPermissions.canViewSettingsTab'),
    currentPath: readOnly('applicationController.currentPath'),
    hideSubNav: (0, _object.computed)('currentPath', function () {
      return /team\.space.*/.test(this.currentPath);
    }),
    shouldRenderTeamCrumb: alias('team.enterpriseAccount')
  });

  _exports.default = _default;
});