define("dashboard/controllers/enterprise-account/overview", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed", "@ember/object"], function (_exports, _controller, _service, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    permitCreateTeam: (0, _computed.alias)('current.enterprisePermissions.canCreateTeams'),
    feedbackUrl: (0, _object.computed)('current.enterpriseAccountMember.userEmail', function () {
      const email = this.get('current.enterpriseAccountMember.userEmail') || '';
      return `https://docs.google.com/forms/d/e/1FAIpQLSeMjit0F75x-qke1nSvW9JH4HfcvNUSxJTIpbBoWApKpnOzeA/viewform?usp=pp_url&entry.37565529=${email}&entry.940207171&entry.999918422`;
    })
  });

  _exports.default = _default;
});