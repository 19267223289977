define("dashboard/components/metrics/charts/connected-chart-hover/activity-group", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/charts/connected-chart-hover/group"], function (_exports, _computed, _object, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a special charts/connected-chart-hover/group that expects an array of rolled up
   * models to be provided to it
   *
   */
  var _default = _group.default.extend({
    _rolledupModels: (0, _computed.readOnly)('graph.rolledupModels'),

    /**
     * property that represents the appropriate rollup containing the list of models for the current time
     */
    modelsAtSelectedTime: (0, _object.computed)('_rolledupModels.[]', 'selectedRollupTime', function () {
      const time = this.selectedRollupTime;
      const models = this._rolledupModels;
      let modelsAtSelectedTime = [];

      if (models) {
        const timeRollup = models.findBy('time', time);

        if (timeRollup) {
          modelsAtSelectedTime = (0, _object.get)(timeRollup, 'models');
        }
      }

      return modelsAtSelectedTime;
    }),
    isVisible: (0, _computed.gt)('modelsAtSelectedTime.length', 0)
  });

  _exports.default = _default;
});