define("dashboard/helpers/seconds-as-hours", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.secondsAsHours = secondsAsHours;

  /**
    Converts seconds to hours to two decimal places
  **/
  function secondsAsHours(params
  /* , hash*/
  ) {
    const seconds = params || 0;
    const hours = seconds / 3600;
    return hours.toFixed(2);
  }

  var _default = (0, _helper.helper)(secondsAsHours);

  _exports.default = _default;
});