define("dashboard/utils/metrics/data-at-given-time", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.datumAtGivenTime = datumAtGivenTime;
  _exports.valueAtGivenTime = valueAtGivenTime;

  function valueAtGivenTime(data, time) {
    return (0, _object.computed)(time, `${data}.[]`, function () {
      const datum = getDatum(this.get(data), this.get(time));
      return datum ? datum[1] : null;
    });
  }

  function datumAtGivenTime(data, time) {
    return (0, _object.computed)(time, `${data}.[]`, function () {
      return getDatum(this.get(data), this.get(time));
    });
  }

  const getDatum = function (data, time) {
    if (data && time) {
      const datum = data.findBy('0', time);

      if (datum) {
        return datum;
      }
    }
  };
});