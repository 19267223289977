define("dashboard/serializers/app", ["exports", "@ember/utils", "dashboard/serializers/application", "dashboard/config/environment"], function (_exports, _utils, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      const appId = hash.id;

      if (hash.team) {
        hash.team.type = 'team';
      } // `processTier` is already a computed field on the app model, so we need to rename this property


      if (hash.process_tier) {
        hash.app_process_tier = hash.process_tier;
      }

      hash.links = {
        attachments: `/apps/${appId}/addon-attachments`,
        collaborators: `/apps/${appId}/collaborators`,
        jobs: `${_environment.default.particleboardUrl}/apps/${appId}/jobs`,
        teamAppCollaborators: `/teams/apps/${appId}/collaborators`,
        domains: `/apps/${appId}/domains`,
        sniEndpoints: `/apps/${appId}/sni-endpoints`,
        sslEndpoints: `/apps/${appId}/ssl-endpoints`,
        builds: `/apps/${appId}/builds`,
        releases: `/apps/${appId}/releases`,
        formations: `/apps/${appId}/formation`,
        features: `/apps/${appId}/features`,
        dynoLimit: `${_environment.default.metricsApiUrl}/_limits/${hash.name}`,
        buildpackInstallations: `/apps/${appId}/buildpack-installations`,
        configVars: `/apps/${appId}/config-vars`,
        recipients: `${_environment.default.telexUrl}/apps/${appId}/recipients`,
        webhooks: `/apps/${appId}/webhooks`,
        webhookIncludes: `/apps/${appId}/webhook-includes`,
        webhookDeliveries: `/apps/${appId}/webhook-deliveries`,
        preference: `${_environment.default.particleboardUrl}/apps/${appId}/preferences`,
        connectedRepository: `/apps/${appId}/repo`,
        connectedBranches: `/apps/${appId}/repo/branches`,
        connectedServices: `${_environment.default.kolkrabbiUrl}/apps/${appId}/github`
      };
      hash.appState = {
        id: hash.name,
        type: 'app/state'
      };
      return this._super.apply(this, arguments);
    },

    serialize(snapshot) {
      const payload = {};

      if (snapshot.record.get('isNew')) {
        if (snapshot.record.get('team')) {
          payload['team'] = snapshot.record.get('team.name');
        }

        if (snapshot.record.get('space')) {
          payload['space'] = snapshot.record.get('space');
        }

        if (snapshot.record.get('region')) {
          payload['region'] = snapshot.record.get('region.name');
        }

        const name = snapshot.record.get('name');

        if ((0, _utils.isPresent)(name)) {
          payload['name'] = name;
        }

        payload['internal_routing'] = snapshot.record.get('internalRouting');
      } else {
        const newName = snapshot.record.get('newName');

        if (newName && name !== newName) {
          payload['name'] = newName;
        }
      }

      if (typeof snapshot.record.get('buildStack') === 'string') {
        payload['build_stack'] = snapshot.record.get('buildStack');
      }

      if (snapshot.changedAttributes()['locked']) {
        payload['locked'] = snapshot.record.get('locked');
      }

      payload['maintenance'] = snapshot.record.get('maintenance');
      return payload;
    },

    extractRelationships(modelClass, hash) {
      if (hash.region) {
        hash.region.type = 'region';
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});