define("dashboard/templates/components/new/org-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zP6Ge4XL",
    "block": "[[[10,0],[14,0,\"f5 b dark-gray mb2\"],[12],[1,\"App owner\"],[13],[1,\"\\n\"],[6,[39,0],null,[[\"value\",\"disabled\",\"class\",\"onChange\"],[[30,0,[\"orgName\"]],[30,0,[\"isDisabledInput\"]],\"hk-select w-100 mb3 new-org\",[30,1]]],[[\"default\"],[[[[1,\"  \"],[10,\"optgroup\"],[14,\"label\",\"Personal\"],[12],[1,\"\\n    \"],[6,[30,2,[\"option\"]],null,[[\"value\"],[null]],[[\"default\"],[[[[1,[30,0,[\"userName\"]]]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"optgroup\"],[14,\"label\",\"Teams\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"teams\"]]],null]],null],null,[[[6,[30,2,[\"option\"]],null,[[\"value\"],[[30,3,[\"name\"]]]],[[\"default\"],[[[[1,\"        \"],[1,[30,3,[\"name\"]]],[1,\"\\n\"]],[]]]]]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"]],[2]]]]]],[\"@onSelectOrg\",\"xs\",\"option\"],false,[\"x-select\",\"each\",\"-track-array\"]]",
    "moduleName": "dashboard/templates/components/new/org-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});