define("dashboard/models/github-app-installation", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/object"], function (_exports, _model, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    accountId: (0, _model.attr)('string'),
    accountType: (0, _model.attr)('string'),
    installationId: (0, _model.attr)('number'),
    htmlUrl: (0, _model.attr)('string'),
    githubAccountName: (0, _model.attr)('string'),
    githubAccountType: (0, _model.attr)('string'),
    enterpriseAccount: (0, _model.belongsTo)('enterprise-account'),
    team: (0, _model.belongsTo)('team'),
    currentUserAccount: (0, _model.belongsTo)('account'),
    herokuResourceId: (0, _computed.alias)('accountId'),
    herokuResourceType: (0, _computed.alias)('accountType'),
    githubAccountUrl: (0, _object.computed)('githubAccountName', function () {
      return `https://github.com/${this.githubAccountName}`;
    })
  });

  _exports.default = _default;
});