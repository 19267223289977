define("dashboard/services/analytics", ["exports", "@heroku/ember-backboard/services/analytics", "dashboard/utils/localstorage"], function (_exports, _analytics, _localstorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _analytics.default.extend({
    logEvent(noun, action) {
      let properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      const utmProps = _localstorage.default.getSessionItem('utm');

      if (utmProps) {
        const parsedUtmProps = JSON.parse(utmProps);
        properties = Object.assign(properties, parsedUtmProps);
      }

      this._super(noun, action, properties);
    },

    logMetricsEvent(noun, action) {
      let properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      this.logEvent(`Metrics ${noun}`, action, properties);
    }

  });

  _exports.default = _default;
});