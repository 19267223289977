define("dashboard/routes/enterprise-account", ["exports", "@ember/object", "rsvp", "@ember/routing/route", "@ember/service"], function (_exports, _object, _rsvp, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    accountFeatures: (0, _service.inject)(),
    store: (0, _service.inject)(),
    enterpriseAccounts: (0, _object.computed)(function () {
      return this.store.peekAll('enterprise-account');
    }),

    model(params) {
      const enterpriseAccounts = this.enterpriseAccounts;
      const enterpriseAccount = enterpriseAccounts.findBy('name', params.name);

      if (!enterpriseAccount) {
        return (0, _rsvp.reject)({
          status: 404
        });
      }

      const members = (0, _object.get)(enterpriseAccount, 'members') || [];
      const teams = enterpriseAccount.get('teams');
      return (0, _rsvp.hash)({
        enterpriseAccount,
        members,
        teams
      });
    },

    actions: {
      willTransition() {
        this.controllerFor('enterprise-account').send('hideCreateTeam');
      }

    }
  });

  _exports.default = _default;
});