define("dashboard/helpers/iso-date", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isoDate = isoDate;

  function isoDate(_ref) {
    let [date] = _ref;
    return date.toISOString();
  }

  var _default = (0, _helper.helper)(isoDate);

  _exports.default = _default;
});