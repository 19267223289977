define("dashboard/components/recovery-codes", ["exports", "@ember/utils", "@ember/object", "@ember/object/computed", "@ember/component", "@ember/service"], function (_exports, _utils, _object, _computed, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    hasPassword: (0, _computed.bool)('password'),
    needsPassword: (0, _computed.not)('password'),
    analytics: (0, _service.inject)(),

    /*
     *  Lazily fetch the codes if they're requested and we don't yet have them.
     *  The call to #setCodes will overwrite this property once the request is
     *  complete, in the mean time we return nothing and render a loading spinner
     *  in the template.
     */
    codes: (0, _object.computed)(function () {
      this.setCodes(this.password);
    }),
    codeListCol1: (0, _object.computed)('codes', function () {
      return [0, 1, 2, 3, 4].map(i => {
        return this.get(`codes.${i}`);
      }).join('\n');
    }),
    codeListCol2: (0, _object.computed)('codes', function () {
      return [5, 6, 7, 8, 9].map(i => {
        return this.get(`codes.${i}`);
      }).join('\n');
    }),
    codeList: (0, _object.computed)('codes.[]', function () {
      return Array(10).fill().map((_, i) => {
        return this.get(`codes.${i}`);
      }).join('\n');
    }),

    setCodes(password) {
      const recovery = this.store.createRecord('recovery-code');
      return recovery.saveWithPassword(password).then(model => {
        this.analytics.logEvent('2FA Recovery Codes', 'Downloaded');
        this.set('codes', model.get('codes'));
        this.set('password', password);
      });
    },

    downloadCodesUrl: (0, _object.computed)('codes', function () {
      let codes = this.codes;

      if ((0, _utils.isPresent)(codes)) {
        codes = [this.codeListCol1, this.codeListCol2].join('\n');
        return `data:text/plain;charset=utf-8,${encodeURIComponent(codes)}`;
      }
    }),
    doneButtonDisabled: true,
    showCopyText: false,
    actions: {
      setCodes(password) {
        return this.setCodes(password);
      },

      onCopy() {
        this.set('doneButtonDisabled', false);
        this.set('showCopyText', true);
      },

      printCodes() {
        let codes = this.codes;

        if ((0, _utils.isPresent)(codes)) {
          codes = codes.join('<br />');
          const windowPrinter = window.open('', 'print codes');
          windowPrinter.document.write('<html><head><title>Recovery Codes</title>');
          windowPrinter.document.write('</head><body >');
          windowPrinter.document.write(codes);
          windowPrinter.document.write('</body></html>');
          windowPrinter.document.close();
          windowPrinter.focus();
          windowPrinter.print();
          windowPrinter.close();
        }
      },

      close() {
        this.attrs.onCloseModal();
      }

    }
  });

  _exports.default = _default;
});