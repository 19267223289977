define("dashboard/components/metrics/charts/visualizations/gc-time-chart", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart", "@ember/object/computed", "dashboard/utils/metrics/has-non-zero-values"], function (_exports, _object, _wrappedLineChart, _computed, _hasNonZeroValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    chartTitle: 'Aggregate Time Spent in Garbage Collection',
    insufficientDataTitle: 'Insufficient Data',
    unit: 'time',
    valueFormat: '0,0',
    secondaryUnit: 'percentage',
    secondaryValueFormat: '0.[00]',
    boundaryMultiplier: 1.1,
    smallNumberFormat: '< 0.01',
    smallNumberThreshold: 0.01,
    isShowingGC: true,
    isShowingYoung: true,
    isShowingOld: true,
    hasYoungGenData: (0, _hasNonZeroValues.default)('lengthDataYoung'),
    hasOldGenData: (0, _hasNonZeroValues.default)('lengthDataOld'),
    hasBothYoungAndOld: (0, _computed.and)('hasYoungGenData', 'hasOldGenData'),
    timeSeriesGC: (0, _object.computed)('lengthData', 'ratioData', 'isShowingGC', function () {
      return _object.default.create({
        name: 'GC Time',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 0,
        data: this.lengthData,
        extraData: this.ratioData,
        isVisible: this.isShowingGC,
        key: 'GC'
      });
    }),
    timeSeriesTotal: (0, _object.computed)('lengthDataTotal', 'ratioDataTotal', function () {
      return _object.default.create({
        name: 'Total',
        type: 'metrics/charts/line-chart-elements/line-graph',
        index: 0,
        data: this.get('lengthDataTotal'),
        extraData: this.get('ratioDataTotal'),
        hideLine: true
      });
    }),
    timeSeriesYoung: (0, _object.computed)('lengthDataYoung', 'ratioDataYoung', 'isShowingYoung', function () {
      return _object.default.create({
        name: 'Young Gen',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 1,
        data: this.lengthDataYoung,
        extraData: this.ratioDataYoung,
        isVisible: this.isShowingYoung,
        key: 'Young'
      });
    }),
    timeSeriesOld: (0, _object.computed)('lengthDataOld', 'ratioDataOld', 'isShowingOld', function () {
      return _object.default.create({
        name: 'Old Gen',
        color: 'purple-003',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 2,
        data: this.lengthDataOld,
        extraData: this.ratioDataOld,
        isVisible: this.isShowingOld,
        key: 'Old'
      });
    }),
    chartTimeSeries: (0, _object.computed)('hasSplitGenerations', 'timeSeriesGC', 'timeSeriesYoung', 'timeSeriesOld', 'hasBothYoungAndOld', 'timeSeriesTotal', function () {
      if (this.hasSplitGenerations) {
        const timeSeries = [this.timeSeriesYoung, this.timeSeriesOld];

        if (this.hasBothYoungAndOld) {
          timeSeries.push(this.timeSeriesTotal);
        }

        return timeSeries;
      } else {
        return [this.timeSeriesGC];
      }
    })
  });

  _exports.default = _default;
});