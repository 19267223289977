define("dashboard/models/repositories-api-github-compare", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    aheadBy: (0, _model.attr)('number'),
    behindBy: (0, _model.attr)('number'),
    status: (0, _model.attr)('string'),
    totalCommits: (0, _model.attr)('number'),
    diffUrl: (0, _model.attr)('string'),
    htmlUrl: (0, _model.attr)('string'),
    patchUrl: (0, _model.attr)('string'),
    permalinkUrl: (0, _model.attr)('string'),
    commits: (0, _model.attr)()
  });

  _exports.default = _default;
});