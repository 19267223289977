define("dashboard/components/account/default-organization", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    defaultOrganizationName: (0, _object.computed)('userPreferences.defaultOrganization', function () {
      return this.get('userPreferences.defaultOrganization');
    }),
    isSaving: (0, _computed.alias)('userPreferences.isSaving'),
    actions: {
      save(organizationName) {
        const userPreferences = this.userPreferences;
        userPreferences.set('defaultOrganization', organizationName);
        userPreferences.save().then(() => {
          this.analytics.logEvent('Default Team', 'Changed');
        });
      }

    }
  });

  _exports.default = _default;
});