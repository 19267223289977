define("dashboard/components/loading-spinner", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagname: 'span',
    classNameBindings: [':loading-spinner', 'size'],
    attributeBindings: ['role', 'title'],
    role: 'status'
  });

  _exports.default = _default;
});