define("dashboard/components/gravatar-icon", ["exports", "@ember/component", "@ember/object", "@ember/string", "dashboard/mixins/tooltipable", "md5"], function (_exports, _component, _object, _string, _tooltipable, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    oneWay
  } = _object.computed;
  /**
   * Renders a Gravatar image.
   *
   *     {{gravatar-icon email='user@example.com'}}
   *
   * @class Dashboard.GravatarIcon
   * @extends Ember.Component
   *
   * @param {boolean} tooltip - set to true to show a tooltip using the title attribute
   */

  var _default = _component.default.extend(_tooltipable.default, {
    tagName: 'span',
    classNames: ['gravatar-icon', 'br-100'],
    attributeBindings: ['style', 'title', 'data-original-title'],
    height: (0, _object.computed)('size', function () {
      return this.size;
    }),
    width: (0, _object.computed)('size', function () {
      return this.size;
    }),
    style: (0, _object.computed)(function () {
      return (0, _string.htmlSafe)(`width:${this.width}px; height:${this.height}px`);
    }),
    size: 28,
    src: (0, _object.computed)('email', function () {
      return `https://gravatar.com/avatar/${(0, _md.default)(this.email)}?s=96&d=https://dashboard.heroku.com%2Fimages%2Fstatic%2Fninja-avatar-48x48.png`;
    }),
    alt: oneWay('email'),
    title: (0, _object.computed)('email', 'tooltip', function () {
      return this.tooltip ? this.email : null;
    }),
    // This is the property that bootstrap tooltip reads for content
    'data-original-title': oneWay('title'),
    tooltipOptions: (0, _object.computed)(function () {
      // setting the container to body makes the tooltip
      // more resilient when in special layouts
      return {
        container: 'body'
      };
    })
  });

  _exports.default = _default;
});