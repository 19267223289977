define("dashboard/components/validating-textarea", ["exports", "@ember/component", "dashboard/mixins/validating-field"], function (_exports, _component, _validatingField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_validatingField.default, {});

  _exports.default = _default;
});