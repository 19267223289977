define("dashboard/components/metrics/chart-controls/preferences", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    justEnabledLanguageMetrics: false,
    runtimeMetricsFlag: (0, _object.computed)('app.features', function () {
      const features = this.get('app.features') || [];
      return features.findBy('name', 'runtime-heroku-metrics');
    }),
    goMetricsFlag: (0, _object.computed)('app.features', function () {
      const features = this.get('app.features') || [];
      return features.findBy('name', 'go-language-metrics');
    }),
    rubyMetricsFlag: (0, _object.computed)('app.features', function () {
      const features = this.get('app.features') || [];
      return features.findBy('name', 'ruby-language-metrics');
    }),
    nodeMetricsFlag: (0, _object.computed)('app.features', function () {
      const features = this.get('app.features') || [];
      return features.findBy('name', 'nodejs-language-metrics');
    }),
    actions: {
      toggleRuntimeMetrics() {
        const flag = this.runtimeMetricsFlag;
        flag.save();
        this.analytics.logMetricsEvent('Language Metrics', 'Toggled', {
          setting: flag.get('enabled')
        });
        this.set('justEnabledLanguageMetrics', true);
      },

      toggleGoMetrics() {
        const flag = this.goMetricsFlag;
        flag.save();
        this.analytics.logEvent('Labs Feature', 'Toggled', {
          feature: this.get('goMetricsFlag.name'),
          enabled: flag.get('enabled'),
          via: 'metrics-preferences'
        });
      },

      toggleRubyMetrics() {
        const flag = this.rubyMetricsFlag;
        flag.save();
        this.analytics.logEvent('Labs Feature', 'Toggled', {
          feature: this.get('rubyMetricsFlag.name'),
          enabled: flag.get('enabled'),
          via: 'metrics-preferences'
        });
      },

      toggleLanguageBeta(languageName) {
        const flag = this.get(`${languageName}MetricsFlag`);
        flag.save();
        this.analytics.logEvent('Labs Feature', 'Toggled', {
          feature: flag.get('name'),
          enabled: flag.get('enabled'),
          via: 'metrics-preferences'
        });
      }

    }
  });

  _exports.default = _default;
});