define("dashboard/templates/components/pipeline/review-app-or-pr", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "o4ZJfMx8",
    "block": "[[[41,[30,0,[\"appOrPr\",\"isPullRequest\"]],[[[41,[30,0,[\"pipeline\",\"hasNewReviewAppsEnabled\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"pullRequest\",\"pipeline\",\"isDuplicate\",\"createReviewApp\"],[[30,0,[\"appOrPr\"]],[30,0,[\"pipeline\"]],[30,0,[\"isDuplicate\"]],[28,[37,2],[[30,0],[30,0,[\"createReviewApp\"]]],null]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"pullRequest\",\"pipeline\"],[[30,0,[\"appOrPr\"]],[30,0,[\"pipeline\"]]]]]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"isNewReviewApp\"]],[[[1,\"  \"],[1,[28,[35,4],null,[[\"reviewApp\",\"pipeline\",\"commitStatuses\",\"onClickRetryBuild\"],[[30,0,[\"appOrPr\"]],[30,0,[\"pipeline\"]],[30,0,[\"commitStatuses\"]],[30,0,[\"onClickRetryBuild\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"app\",\"pipeline\",\"commitStatuses\"],[[30,0,[\"appOrPr\"]],[30,0,[\"pipeline\"]],[30,0,[\"commitStatuses\"]]]]]],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"pipeline/pull-request\",\"action\",\"pipeline/kolkrabbi-pull-request\",\"pipeline/review-app\",\"pipeline/kolkrabbi-review-app\"]]",
    "moduleName": "dashboard/templates/components/pipeline/review-app-or-pr.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});