define("dashboard/templates/components/app/formation-range-slider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xeqr+q7X",
    "block": "[[[41,[30,0,[\"showAutoscaleEditor\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"valueMin\",\"valueMax\",\"persistedValue\",\"disabled\",\"onSelectedValueMin\",\"onSelectedValueMax\",\"onSelectedValue\",\"max\"],[[30,0,[\"valueMin\"]],[30,0,[\"valueMax\"]],[30,0,[\"persistedValue\"]],[30,0,[\"disabled\"]],[28,[37,2],[[30,0],\"selectedValueMin\"],null],[28,[37,2],[[30,0],\"selectedValueMax\"],null],[28,[37,2],[[30,0],\"selectedValue\"],null],[30,0,[\"max\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"value\",\"persistedValue\",\"disabled\",\"onSelectedValueMin\",\"onSelectedValueMax\",\"onSelectedValue\",\"max\"],[[30,0,[\"process\",\"quantity\"]],[30,0,[\"persistedValue\"]],[30,0,[\"disabled\"]],[28,[37,2],[[30,0],\"selectedValueMin\"],null],[28,[37,2],[[30,0],\"selectedValueMax\"],null],[28,[37,2],[[30,0],\"selectedValue\"],null],[30,0,[\"max\"]]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"range-slider\",\"action\"]]",
    "moduleName": "dashboard/templates/components/app/formation-range-slider.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});