define("dashboard/templates/components/payment-method/required-indicator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XOZLJSlo",
    "block": "[[[6,[39,0],null,[[\"class\",\"title\"],[\"red\",\"Required\"]],[[\"default\"],[[[[1,\"*\"]],[]]]]]],[],false,[\"x-tooltip\"]]",
    "moduleName": "dashboard/templates/components/payment-method/required-indicator.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});