define("dashboard/routes/account/billing", ["exports", "@ember/object", "rsvp", "@ember/object/computed", "@ember/utils", "@ember/service", "dashboard/routes/account/base", "dashboard/mixins/direct-to-invoice", "dashboard/utils/infinite-query"], function (_exports, _object, _rsvp, _computed, _utils, _service, _base, _directToInvoice, _infiniteQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_directToInvoice.default, {
    accountFeatures: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),

    model() {
      const accountId = this.get('current.account.id');
      return (0, _rsvp.hash)({
        paymentMethod: this.store.queryRecord('payment-method', {}),
        unverifyPaymentMethodRequest: this.store.queryRecord('unverify-payment-method-request', {}),
        invoices: this.store.findAll('invoice'),
        credits: this.store.findAll('credit').catch(() => []),
        teams: this.store.findAll('team').catch(() => []),
        invoiceAddress: this.store.queryRecord('invoice-address', {}),
        accountQuota: this.store.queryRecord('account-quota', {
          accountId
        }).then(quota => {
          // this guards against an 'account not found' payload
          // which doesn't include an id (ember-data can't process this)
          return quota.get('id') ? quota : null;
        }).catch(() => {
          return null;
        })
      });
    },

    afterModel(model) {
      this._super(...arguments);

      this.analytics.logEvent('Account Billing Page', 'Viewed');

      this._fetchQuotaApps(model.accountQuota);
    },

    /**
     The account quota endpoint contains a list of app ids with no names (tableflip)
     This function takes those ids, does an Apps lookup on them all to get a list of
     apps back (that includes names) so that we can associate a name with each quota app.
    **/
    _fetchQuotaApps(accountQuota) {
      if (accountQuota) {
        const quotaApps = accountQuota.get('apps'); // pull out all the app ids from the account quota's apps

        const appIds = quotaApps.map(app => {
          return app['app_uuid'];
        });

        if ((0, _utils.isPresent)(appIds)) {
          // hit the apps endpoints with all the ids
          (0, _infiniteQuery.default)(this.store, 'app', {
            personalOnly: true
          }, {}, this.flashMessages).then(apps => {
            quotaApps.forEach(qa => {
              // look for each account quota app in the list of apps we get back
              const match = apps.findBy('id', qa['app_uuid']); // if a match is found, set the name of the app onto the account quota's app object

              if (match) {
                (0, _object.set)(qa, 'name', match.get('name'));
              }
            });
          });
        }
      }
    },

    actions: {
      backToBilling() {
        this.refresh().then(() => {
          this.transitionTo('account.billing');
        });
      },

      verifyAccount() {
        this.controllerFor('account/billing').set('showVerify', true);
      },

      accountVerified() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});