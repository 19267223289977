define("dashboard/components/pipeline/manage-third-party-connection", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "@ember/object", "ember-concurrency", "dashboard/mixins/pipeline-ownership"], function (_exports, _component, _service, _computed, _object, _emberConcurrency, _pipelineOwnership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_pipelineOwnership.default, {
    tagName: '',
    analytics: (0, _service.inject)(),
    torii: (0, _service.inject)(),
    errorMonitor: (0, _service.inject)(),
    store: (0, _service.inject)(),
    reporter: (0, _service.inject)(),
    router: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    reposApiGithubToken: (0, _computed.readOnly)('current.account.reposApiGithubToken'),
    githubUserLogin: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.login'),
    githubUserName: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.name'),
    githubUserLink: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.htmlUrl'),
    connectedRepository: (0, _computed.alias)('pipeline.connectedRepository'),
    pipelineHasLegacyOauthConnection: (0, _computed.readOnly)('connectedRepository.herokuLegacyLink'),
    isShowingLegacyConnectionBanner: (0, _object.computed)('pipelineHasLegacyOauthConnection', 'pipeline.hasGithubAppInstalled', 'noPipelineOwner', function () {
      if (this.pipelineHasLegacyOauthConnection) {
        return this.get('pipeline.hasGithubAppInstalled') || this.noPipelineOwner;
      }
    }),
    hasNoPipelineOwner: (0, _computed.readOnly)('noPipelineOwner'),
    isNewPipeline: (0, _computed.readOnly)('pipeline.isNew'),
    _enterpriseAccountMember: (0, _object.computed)('pipeline.team.enterprise.members.@each.userId', 'current.accountId', function () {
      const members = this.get('pipeline.team.enterprise.members') || [];
      return members.findBy('userId', this.current.accountId);
    }),
    hasAdminAccess: (0, _object.computed)('_enterpriseAccountMember', 'isNewPipeline', function () {
      return this.isNewPipeline || this.get('_enterpriseAccountMember.canManage');
    }),
    hasNoAdminAccess: (0, _computed.not)('hasAdminAccess'),
    teamContext: (0, _object.computed)('team', 'pipeline.team', 'pipeline.team.id', function () {
      return this.get('pipeline.team.id') ? this.get('pipeline.team') : this.get('team');
    }),
    enterpriseAccountContext: (0, _object.computed)('pipeline.team.enterprise', 'team.enterprise', 'pipeline.team.id', function () {
      return this.get('pipeline.team.id') ? this.get('pipeline.team.enterprise') : this.get('team.enterprise');
    }),
    herokuResourceId: (0, _object.computed)('isPersonalPipeline', 'current.accountId', 'teamContext', 'enterpriseAccountContext', function () {
      if (this.isPersonalPipeline) {
        return this.current.accountId;
      } else {
        return this.get('teamContext.isEnterpriseTeam') ? this.get('enterpriseAccountContext.id') : this.get('teamContext.id');
      }
    }),
    pipelineBenefits: [{
      iconName: 'app-review-16',
      description: 'Review apps can be enabled for this pipeline'
    }, {
      iconName: 'git-commit-16',
      description: 'Releases in app activity feeds link to GitHub to view commit diffs'
    }, {
      iconName: 'auto-16',
      description: 'Automatic deploys are available to apps in this pipeline'
    }, {
      iconName: 'ci-16',
      description: 'You can run tests on this pipeline with Heroku CI'
    }],
    searchQuery: (0, _object.computed)('searchTerm', 'herokuResourceId', function () {
      const query = {
        q: `fork:true ${this.searchTerm}`,
        enterpriseId: this.herokuResourceId
      };

      if (this.get('pipeline.isGithubAppEnabled')) {
        query.isGithubAppEnabled = true;
      }

      return query;
    }),
    autoDeployAppLinks: (0, _computed.filter)('pipeline.githubAppLinks', function (appLink) {
      const isAutoDeployApp = appLink.get('autoDeploy');
      const isNotReviewApp = appLink.get('app.pipelineCoupling.stage') !== 'review';
      return isAutoDeployApp && isNotReviewApp;
    }),
    hasAutoDeployApps: (0, _computed.notEmpty)('autoDeployAppLinks'),
    hasReviewAppParents: (0, _computed.notEmpty)('pipeline.reviewAppParents'),
    hasReviewApps: (0, _computed.notEmpty)('pipeline.kolkrabbiReviewApps'),
    showWarnings: (0, _computed.or)('hasAutoDeployApps', 'hasReviewAppParents', 'hasReviewApps'),
    reviewAppCount: (0, _computed.readOnly)('pipeline.kolkrabbiReviewApps.length'),
    autoDeployAppNames: (0, _object.computed)('autoDeployAppLinks.@each.app', function () {
      return this.autoDeployAppLinks.map(autoApp => {
        return autoApp.get('app.name');
      }).join(', ');
    }),
    reconnectRepositoryTask: (0, _emberConcurrency.task)(function* (repo) {
      repo.set('pipeline', this.pipeline);

      try {
        yield repo.save({
          adapterOptions: {
            linkedResourceType: 'pipeline',
            migrating: true
          }
        });
        return yield this.pipeline.belongsTo('connectedRepository').reload();
      } catch (e) {
        this.get('reporter.rollbar').info('Failed to reconnect pipeline', {
          error: e
        });
      }
    }),
    actions: {
      search() {
        const searchQuery = Object.assign({}, this.get('searchQuery'));
        this.set('repos', []);
        this.set('lastSearchTerm', null);
        return this.store.query('repositories-api-github-repo', searchQuery).then(repos => {
          this.setProperties({
            lastSearchTerm: this.searchTerm,
            repos
          });
        });
      },

      reconnectRepository(repo) {
        this.analytics.logEvent('Pipeline to Github Apps', 'Migrated', {
          pipelineId: this.get('pipeline.id')
        });
        return this.get('reconnectRepositoryTask').perform(repo);
      },

      connectRepository(repo) {
        repo.set('pipeline', this.pipeline);
        return repo.save({
          adapterOptions: {
            linkedResourceType: 'pipeline'
          }
        }).then(() => {
          this.set('repos', []);
          this.set('lastSearchTerm', null);
          this.set('searchTerm', null);
          this.analytics.logEvent('Github Apps Pipeline', 'Connected', {
            pipelineId: this.get('pipeline.id')
          });
        }).catch(e => {
          repo.set('pipeline', null);
          this.get('reporter.rollbar').info('Failed to connect pipeline', {
            error: e
          });
        });
      },

      disconnectRepository() {
        const repo = this.get('connectedRepository.content');
        repo.set('disconnectedResourceId', this.get('pipeline.id'));
        repo.set('pipeline', null);
        this.analytics.logEvent('Github Apps Pipeline', 'Disconnected', {
          pipelineId: this.get('pipeline.id')
        });
        return repo.save({
          adapterOptions: {
            linkedResourceType: 'pipeline'
          }
        });
      },

      logEvent(noun, action) {
        this.analytics.logEvent(noun, action);
      }

    }
  });

  _exports.default = _default;
});