define("dashboard/components/org/set-default-org-role", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    roleIsDirty: (0, _object.computed)('preference.defaultPermission', 'role', function () {
      return this.get('preference.defaultPermission') !== this.role;
    }),
    hasSelectedRole: (0, _computed.bool)('role'),
    buttonIsEnabled: (0, _computed.and)('roleIsDirty', 'hasSelectedRole'),
    buttonIsDisabled: (0, _computed.not)('buttonIsEnabled'),
    role: (0, _computed.oneWay)('preference.defaultPermission'),
    actions: {
      saveDefaultRole() {
        const preference = this.preference;
        const role = this.role;
        preference.set('defaultPermission', role);
        return preference.save();
      }

    }
  });

  _exports.default = _default;
});