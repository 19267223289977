define("dashboard/models/shogun/postgres-config", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "ember-cp-validations"], function (_exports, _attr, _model, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    logMinDurationStatement: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    datastore: (0, _relationships.belongsTo)('shogun/postgres-datastore'),
    logConnections: (0, _attr.default)('boolean'),
    logLockWaits: (0, _attr.default)('boolean'),
    logMinDurationStatement: (0, _attr.default)('number'),
    logStatement: (0, _attr.default)('string')
  });

  _exports.default = _default;
});