define("dashboard/components/app/activity-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['relative dib'],
    badgeIconClass: (0, _object.computed)('badgeColor', function () {
      return `malibu-fill-gradient-${this.badgeColor}`;
    }),
    nameMappings: {
      'config|update.*by': 'configure-28',
      'attach|detach|add-on': 'element-28',
      build: 'build-28',
      deploy: 'deploy-28',
      'release|enable logplex': 'setup-28',
      rollback: 'rollback-28',
      '': 'app-dead-28'
    },
    name: (0, _object.computed)('itemDescription', function () {
      const description = this.itemDescription;
      const mappings = this.nameMappings;
      const iconType = Object.keys(mappings).find(key => {
        return new RegExp(key, 'gi').test(description);
      });
      return mappings[iconType];
    }),
    // Keep these in order so that the less greedy ones toward the top have a chance to match before the others.
    // For example, when setting a config var that contains the words 'failed', we don't want the failed badge added.
    badgeNameMappings: {
      'Set \\w* config var': undefined,
      'detach|remove': 'remove-badge-16',
      failed: 'error-badge-16',
      succeed: 'success-badge-16',
      attach: 'add-badge-16'
    },
    badgeName: (0, _object.computed)('itemDescription', function () {
      const description = this.itemDescription;
      const mappings = this.badgeNameMappings;
      const iconType = Object.keys(mappings).find(key => {
        return new RegExp(key, 'gi').test(description);
      });
      return mappings[iconType];
    }),
    badgeColor: (0, _object.computed)('itemDescription', function () {
      return new RegExp('detach|remove|failed', 'gi').test(this.itemDescription) ? 'red' : 'green';
    })
  });

  _exports.default = _default;
});