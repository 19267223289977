define("dashboard/adapters/shogun/private-link", ["exports", "dashboard/adapters/shogun"], function (_exports, _shogun) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shogun.default.extend({
    urlForQueryRecord(query) {
      const addonId = query.addonId;
      delete query.addonId;
      return `${this.host}/private-link/v0/databases/${addonId}`;
    },

    urlForCreateRecord(_modelName, snapshot) {
      const addonId = snapshot.attr('addon').id;
      return `${this.host}/private-link/v0/databases/${addonId}`;
    },

    urlForDeleteRecord(_id, modelName, snapshot) {
      return this.urlForCreateRecord(modelName, snapshot);
    }

  });

  _exports.default = _default;
});