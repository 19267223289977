define("dashboard/components/pipeline/kolkrabbi-app-build-status", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    currentBuild: (0, _computed.readOnly)('app.currentBuild'),
    hasNoCurrentRelease: (0, _computed.not)('app.currentRelease'),
    newReviewAppsNotEnabled: (0, _computed.not)('pipeline.hasNewReviewAppsEnabled'),
    isShowingFailedStatus: (0, _computed.and)('currentBuild.isFailed', 'newReviewAppsNotEnabled'),
    actions: {
      showBuildModal() {
        this.set('isShowingBuildModal', true);
      },

      hideBuildModal() {
        this.set('isShowingBuildModal', false);
      }

    }
  });

  _exports.default = _default;
});