define("dashboard/models/github-auth", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    notEmpty,
    not
  } = _object.computed;

  var _default = _model.default.extend({
    github: (0, _model.attr)(),
    heroku: (0, _model.attr)(),
    createdAt: (0, _model.attr)(),
    updatedAt: (0, _model.attr)(),
    isLinked: notEmpty('createdAt'),
    isNotLinked: not('isLinked')
  });

  _exports.default = _default;
});