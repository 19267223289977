define("dashboard/components/account/bounce-notice", ["exports", "@ember/component", "@ember/object/computed", "@ember/application", "ember-concurrency", "jquery", "@ember/service"], function (_exports, _component, _computed, _application, _emberConcurrency, _jquery, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    session: (0, _service.inject)(),
    email: null,
    bounces: (0, _computed.reads)('loadBounces.lastSuccessful.value'),

    /**
     * We perform this task on didReceiveAttrs because, although the `email`
     * property isn’t used directly within the task, we do want to re-fetch
     * bounces if and when the email changes. When a user changes their email,
     * the new value will be passed in and trigger a re-fetch.
     */
    loadBounces: (0, _emberConcurrency.task)(function* () {
      return yield this.request('GET');
    }).restartable().on('didReceiveAttrs'),
    removeBounces: (0, _emberConcurrency.task)(function* () {
      yield this.request('DELETE');
      yield this.loadBounces.perform();
    }),

    request() {
      let method = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'GET';
      const owner = (0, _application.getOwner)(this);
      const adapter = owner.lookup('adapter:particleboard');
      const host = adapter.get('host');
      const url = `${host}/bounces`;
      const normalHeaders = adapter.get('headers');
      const authHeaders = adapter.get('authorizationHeaders');
      const headers = Object.assign({}, normalHeaders, authHeaders);
      return _jquery.default.ajax(url, {
        method,
        headers
      });
    }

  });

  _exports.default = _default;
});