define("dashboard/utils/debounce-promise", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = debouncePromise;

  /*
   * Debounce a promise returning function to keep returning the same promise
   * until it resolves. Useful to avoid concurrent identical ajax requests.
   *
   * @param {function} promiseMaker - a function which returns a promise
   *
   */
  function debouncePromise(promiseMaker) {
    let currentPromise;
    return function () {
      if ((0, _utils.isNone)(currentPromise)) {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        currentPromise = promiseMaker.apply(this, args);
        currentPromise.finally(() => currentPromise = null);
      }

      return currentPromise;
    };
  }
});