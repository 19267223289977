define("dashboard/mixins/models/metrics/ruby-memory", ["exports", "@ember/object/mixin", "dashboard/models/metrics", "dashboard/utils/metrics/metrics-data-operators", "dashboard/utils/metrics/has-any-measured-data", "@ember/object/computed", "dashboard/utils/metrics/has-non-zero-values"], function (_exports, _mixin, _metrics, _metricsDataOperators, _hasAnyMeasuredData, _computed, _hasNonZeroValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;

  var _default = _mixin.default.create({
    testDataKeysRuby: ['freeSlotsMin', 'freeSlotsMax', 'freeSlotsCount', 'freeSlotsSum', 'allocatedObjectsMax', 'allocatedObjectsCount', 'allocatedObjectsSum', 'freedObjectsMax', 'freedObjectsCount', 'freedObjectsSum', 'usingPumaData'],
    testDataKeysRubyPoolUsage: ['pumaPoolCapacityMin', 'pumaPoolCapacityMax', 'pumaPoolCapacityCount', 'pumaPoolCapacitySum', 'pumaPoolThreadsMax', 'pumaPoolThreadsSpawned'],
    hasAnyMeasuredDataRubyDefault: (0, _hasAnyMeasuredData.default)('testDataKeysRuby'),
    hasAnyMeasuredDataRubyPoolUsage: (0, _hasAnyMeasuredData.default)('testDataKeysRubyPoolUsage'),
    hasAnyMeasuredDataRuby: (0, _computed.or)('hasAnyMeasuredDataRubyDefault', 'hasAnyMeasuredDataRubyPoolUsage'),
    noMeasuredPumaPoolUsageData: (0, _computed.not)('hasAnyMeasuredDataRubyPoolUsage'),
    usingPumaData: rawDataWithDefault('g:ruby.web.using.puma_max'),
    usingPumaDuringTimeframe: (0, _hasNonZeroValues.default)('usingPumaData'),
    usingPumaButNoMeasuredData: (0, _computed.and)('usingPumaDuringTimeframe', 'noMeasuredPumaPoolUsageData'),
    freeSlotsMin: rawDataWithDefault('g:ruby.gc.heap.free.slots_min'),
    freeSlotsMax: rawDataWithDefault('g:ruby.gc.heap.free.slots_max'),
    freeSlotsCount: rawDataWithDefault('g:ruby.gc.heap.free.slots_count'),
    freeSlotsSum: rawDataWithDefault('g:ruby.gc.heap.free.slots_sum'),
    freeSlotsAvg: (0, _metricsDataOperators.dataQuotient)('freeSlotsSum', 'freeSlotsCount'),
    pumaPoolThreadsMax: rawDataWithDefault('g:ruby.web.threads.max_max'),
    pumaPoolThreadsSpawned: rawDataWithDefault('g:ruby.web.threads.spawned_max'),
    pumaPoolThreadsSpawnedPerc: (0, _metricsDataOperators.dataPercentage)('pumaPoolThreadsSpawned', 'pumaPoolThreadsMax'),
    pumaPoolCapacityMin: rawDataWithDefault('g:ruby.web.pool.capacity_min'),
    pumaPoolCapacityMax: rawDataWithDefault('g:ruby.web.pool.capacity_max'),
    pumaPoolCapacityCount: rawDataWithDefault('g:ruby.web.pool.capacity_count'),
    pumaPoolCapacitySum: rawDataWithDefault('g:ruby.web.pool.capacity_sum'),
    pumaPoolCapacityAvg: (0, _metricsDataOperators.dataQuotient)('pumaPoolCapacitySum', 'pumaPoolCapacityCount'),
    pumaPoolUsageMin: (0, _metricsDataOperators.dataDifference)('pumaPoolThreadsMax', 'pumaPoolCapacityMax'),
    pumaPoolUsageMax: (0, _metricsDataOperators.dataDifference)('pumaPoolThreadsMax', 'pumaPoolCapacityMin'),
    pumaPoolUsageAvg: (0, _metricsDataOperators.dataDifference)('pumaPoolThreadsMax', 'pumaPoolCapacityAvg'),
    pumaPoolUsageMinPerc: (0, _metricsDataOperators.dataPercentage)('pumaPoolUsageMin', 'pumaPoolThreadsMax'),
    pumaPoolUsageMaxPerc: (0, _metricsDataOperators.dataPercentage)('pumaPoolUsageMax', 'pumaPoolThreadsMax'),
    pumaPoolUsageAvgPerc: (0, _metricsDataOperators.dataPercentage)('pumaPoolUsageAvg', 'pumaPoolThreadsMax'),
    allocatedObjectsMax: rawDataWithDefault('g:ruby.gc.allocated.objects_max'),
    allocatedObjectsCount: rawDataWithDefault('g:ruby.gc.allocated.objects_count'),
    allocatedObjectsSum: rawDataWithDefault('g:ruby.gc.allocated.objects_sum'),
    allocatedObjectsAvg: (0, _metricsDataOperators.dataQuotient)('allocatedObjectsSum', 'allocatedObjectsCount'),
    allocatedObjectsMaxLog: (0, _metricsDataOperators.dataLogarithm)('allocatedObjectsMax'),
    allocatedObjectsAvgLog: (0, _metricsDataOperators.dataLogarithm)('allocatedObjectsAvg'),
    freedObjectsMax: rawDataWithDefault('g:ruby.gc.freed.objects_max'),
    freedObjectsCount: rawDataWithDefault('g:ruby.gc.freed.objects_count'),
    freedObjectsSum: rawDataWithDefault('g:ruby.gc.freed.objects_sum'),
    freedObjectsAvg: (0, _metricsDataOperators.dataQuotient)('freedObjectsSum', 'freedObjectsCount'),
    freedObjectsMaxLog: (0, _metricsDataOperators.dataLogarithm)('freedObjectsMax'),
    freedObjectsAvgLog: (0, _metricsDataOperators.dataLogarithm)('freedObjectsAvg')
  });

  _exports.default = _default;
});