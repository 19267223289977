define("dashboard/components/metrics/charts/visualizations/jvm-heap-memory", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/memory-chart", "@ember/object/computed"], function (_exports, _object, _memoryChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _memoryChart.default.extend({
    'data-test-target': 'metrics-java-chart',
    summaryComponent: 'metrics/charts/chart-header/java-memory-summary',
    title: 'JVM: Heap Memory Usage',
    devCenterName: 'language-runtime-metrics-jvm#heap-memory-usage',
    testTarget: 'heap-memory-usage',
    summaryData: (0, _object.computed)('data.currentHeapTotal', 'data.maxHeapUsed', 'data.meanHeapMaxValue', function () {
      return _object.default.create({
        memoryType: 'heap',
        currentTotal: this.get('data.currentHeapTotal'),
        maxUsed: this.get('data.maxHeapUsed'),
        averageUsed: this.get('data.meanHeapMaxValue')
      });
    }),
    isShowingMax: true,
    timeSeriesMax: (0, _object.computed)('data.heapMaxJava', 'isShowingMax', function () {
      return _object.default.create({
        name: 'Max Heap',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 0,
        data: this.get('data.heapMaxJava'),
        isVisible: this.isShowingMax,
        key: 'Max'
      });
    }),
    chartTimeSeries: (0, _computed.collect)('timeSeriesQuota', 'timeSeriesMax')
  });

  _exports.default = _default;
});