define("dashboard/components/app/settings/domains-list", ["exports", "rsvp", "@ember/component", "@ember/object", "@ember/utils", "dashboard/mixins/purple-list", "@ember/service", "@ember/string", "dashboard/utils/endpoint-match-domains"], function (_exports, _rsvp, _component, _object, _utils, _purpleList, _service, _string, _endpointMatchDomains) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    empty,
    filter,
    filterBy
  } = _object.computed;
  const NO_SNI_ENDPOINT = 'No SNI Endpoint';

  var _default = _component.default.extend(_purpleList.default, {
    init() {
      this._super(...arguments);

      this.loadAllDomains.perform();
    },

    domainsAreLoading: alias('loadAllDomains.isRunning'),
    classNames: ['domains-list'],
    analytics: (0, _service.inject)(),
    errorMonitor: (0, _service.inject)(),
    store: (0, _service.inject)(),
    isNotSpaceApp: empty('app.space'),
    selectedStatusFilter: 'All',
    selectedCertificate: '',
    statusFilterOptions: ['All', 'Failing', 'Pending'],
    customDomains: filterBy('domains', 'isCustom'),
    failedOrFailingCustomDomains: filter('customDomains', function (domain) {
      return domain.get('acmStatus') === 'failed' || domain.get('acmStatus') === 'failing';
    }),
    pendingCustomDomains: filter('customDomains', function (domain) {
      return domain.get('acmStatus') === null || domain.get('acmStatus') === 'in-progress';
    }),
    wildcardDomains: filterBy('domains', 'isWildcard', true),
    savedCustomDomains: filterBy('customDomains', 'isNew', false),
    newDomainCertificateMatches: (0, _object.computed)('item.hostname', 'app.endpoints.[]', function () {
      const endpoints = this.app.get('endpoints'); // In the rare case where a user is manually managing their certs but only has a single cert,
      // we want to show that one cert in the dropdown no matter what, while also giving them the
      // option to not assign a cert if they don't want to. This configuration allows us to keep as
      // close as possible to the original behavior while also supporting new API behavior around
      // SNI endpoint where an SNI endpoint must always be passed

      if (endpoints.length === 1) {
        return [endpoints.get('firstObject'), NO_SNI_ENDPOINT];
      }

      const selections = (0, _endpointMatchDomains.findMatchingCerts)(this.item.hostname, endpoints);
      return [NO_SNI_ENDPOINT, ...selections];
    }),
    canManuallyManageMultipleCerts: (0, _object.computed)('app.{hasMultipleSniEndpointsEnabled,acm}', function () {
      return this.app.hasMultipleSniEndpointsEnabled && !this.app.acm;
    }),
    filteredDomains: (0, _object.computed)('selectedStatusFilter', 'selectedCertificate', 'savedCustomDomains', 'failedOrFailingCustomDomains', 'pendingCustomDomains', 'filterKey', function () {
      let domains;

      switch (this.selectedStatusFilter) {
        case 'All':
          domains = this.savedCustomDomains;
          break;

        case 'Failing':
          domains = this.failedOrFailingCustomDomains;
          break;

        case 'Pending':
          domains = this.pendingCustomDomains;
          break;
      }

      if (this.selectedCertificate && this.selectedCertificate !== '') {
        domains = this.selectedCertificate.domains;
      }

      const attributes = ['hostname', 'cname'];
      const regex = new RegExp(this.filterKey, 'i');
      return this.filterKey !== '' ? domains.filter(domain => {
        return attributes.find(attribute => regex.test(domain.get(attribute)));
      }) : domains;
    }),
    displayedDomains: alias('filteredDomains'),
    noDisplayedDomainsMessage: (0, _object.computed)('filterKey', 'selectedStatusFilter', function () {
      const statusFilterString = this.selectedStatusFilter !== 'All' ? `${this.selectedStatusFilter.toLowerCase()} ` : '';
      const textFilterString = this.filterKey ? `matching '${this.filterKey}' ` : '';
      return `No ${statusFilterString}domains ${textFilterString}found`;
    }),
    pageSize: 10,
    page: 1,
    totalPages: (0, _object.computed)('displayedDomains.length', 'pageSize', function () {
      const total = this.get('displayedDomains.length');
      return Math.ceil(total / this.pageSize);
    }),
    currentPageDomains: (0, _object.computed)('page', 'pageSize', 'totalPages', 'displayedDomains', function () {
      const page = this.page;
      const pageSize = this.pageSize;
      const domains = this.displayedDomains;
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      return domains.slice(startIndex, endIndex);
    }),
    firstSavedCustomDomainHost: (0, _object.computed)('savedCustomDomains.[]', 'savedCustomDomains.firstObject.acmStatus', function () {
      const firstSavedCustomDomain = this.get('savedCustomDomains.firstObject');
      const acmStatus = firstSavedCustomDomain.get('acmStatus');
      const acmStatusIsOk = acmStatus === 'ok' || acmStatus === 'cert issued';
      const proto = acmStatusIsOk ? 'https://' : 'http://';
      const hostname = firstSavedCustomDomain.get('hostname');
      return (0, _string.htmlSafe)(`${proto}${hostname}`);
    }),
    sniEndpointSelection: (0, _object.computed)('item.sniEndpoint', function () {
      // If there is an *actual* sni endpoint model associated with the domain, then we want to return that,
      // but otherwise we should force the power select to show the no sni endpoint string
      if (this.get('item').belongsTo('sniEndpoint').id()) {
        return this.get('item.sniEndpoint');
      }

      return NO_SNI_ENDPOINT;
    }),
    submitButtonDisabled: (0, _object.computed)('shouldShowCertSelect', 'item.hostname', function () {
      if (this.get('canManuallyManageMultipleCerts')) {
        return (0, _utils.isEmpty)(this.get('item.hostname'));
      }

      return false;
    }),

    createNewItem() {
      const endpoints = this.app.get('endpoints');
      const domain = this.store.createRecord('domain', {
        kind: 'custom',
        app: this.app,
        hostname: ''
      }); // In the rare case where an app is not using ACM *and* has multiple SNI endpoints enabled
      // *and* the user only has 1 cert, we want to go ahead and auto-populate the one cert they have
      // on the domain when it's created so they don't have to manually select it for each domain
      // they create.

      if (endpoints.length === 1 && this.canManuallyManageMultipleCerts) {
        domain.set('sniEndpoint', endpoints.get('firstObject'));
      }

      return domain;
    },

    titleForDelete: (0, _object.computed)('item.hostname', function () {
      const name = this.get('item.hostname');
      return `Delete <small>(${name})</small>`;
    }),
    actions: {
      edit(domain) {
        this.setProperties({
          showDomainEditPanel: true,
          item: domain
        });
      },

      closeEditPanel() {
        this.setProperties({
          showDomainEditPanel: false,
          item: null
        });
      },

      selectSniEndpoint(selection) {
        if (selection.name) {
          this.set('item.sniEndpoint', selection);
        } else {
          this.set('item.sniEndpoint', null);
        }
      },

      save() {
        const domain = this.item;

        this._super(...arguments);

        if (domain.get('validations.isValid')) {
          return domain.save().then(() => {
            this.analytics.logEvent('Domain', 'Created');
            this.toggleProperty('isSuccessful');
            this.loadAllDomains.perform();
            this.set('page', 1);
          }).catch(() => {// Catch unsuccessful save, do nothing, let errorMonitor handle
          });
        } else {
          this.set('item.errorIsVisible', true);
          return (0, _rsvp.reject)();
        }
      },

      delete() {
        const domain = this.item;
        const pendingDelete = domain.destroyRecord();
        return pendingDelete.then(() => {
          this.analytics.logEvent('Domain', 'Deleted');
          this.loadAllDomains.perform();
          this.set('page', 1);
        });
      },

      rollback() {
        this.item.unloadRecord();
        this.errorMonitor.wipe();
      },

      refreshACM() {
        // only actually "refresh" acm itself if domains have failed
        // this avoids making unnecessary requests upstream to cedar-acm that
        // won't actually do anything
        this.analytics.logEvent('ACM Status', 'Refreshed');

        if (this.get('failedOrFailingDomains.length') > 0) {
          return this.app.refreshACM().then(() => {
            this.loadAllDomains.perform();
            this.app.hasMany('sniEndpoints').reload();
            (0, _object.get)(this, 'flashMessages').success('It may take some time before you see the status updated', {
              title: 'Refresh requested'
            });
          });
        } else {
          return (0, _rsvp.all)([this.loadAllDomains.perform(), this.app.hasMany('sniEndpoints').reload()]).then(() => {
            (0, _object.get)(this, 'flashMessages').success('Certificate status refreshed');
          });
        }
      },

      updatePage(page) {
        this.set('page', page);
      },

      filterChanged(value) {
        this.set('filterKey', value);
        this.set('page', 1);
      },

      urlForPage() {
        return '';
      },

      closePanel() {
        this.set('item', null);
        this.set('isSuccessful', null);
        this.set('isAdding', null);
      },

      updateStatusFilter(status) {
        this.set('selectedStatusFilter', status);
        this.set('page', 1);
      }

    }
  });

  _exports.default = _default;
});