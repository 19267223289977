define("dashboard/adapters/app-preference", ["exports", "dashboard/adapters/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    urlForUpdateRecord(id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${this.host}/apps/${appId}/preferences`;
    }

  });

  _exports.default = _default;
});