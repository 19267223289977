define("dashboard/models/repositories-api-app-manifest", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    addons: (0, _model.attr)(),
    buildpacks: (0, _model.attr)(),
    description: (0, _model.attr)('string'),
    env: (0, _model.attr)(),
    formation: (0, _model.attr)(),
    logo: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    scripts: (0, _model.attr)(),
    stack: (0, _model.attr)('string'),
    website: (0, _model.attr)('string')
  });

  _exports.default = _default;
});