define("dashboard/components/payment-method/credit-card-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'credit-card-icon',
    isSmall: false,
    cardTypeClass: (0, _object.computed)('cardType', 'isSmall', function () {
      const cardType = this.cardType;
      const isSmall = this.isSmall;
      let type;

      switch (true) {
        case /American|amex/i.test(cardType):
          type = 'amex';
          break;

        case /discover/i.test(cardType):
          type = 'discover';
          break;

        case /master/i.test(cardType):
          type = 'mastercard';
          break;

        case /visa/i.test(cardType):
          type = 'visa';
          break;

        case /herok(u|ai)/i.test(cardType):
          type = 'heroku';
          break;

        default:
          type = 'generic';
      }

      if (isSmall) {
        return `cc-${type}-small`;
      } else {
        return `cc-${type}`;
      }
    })
  });

  _exports.default = _default;
});