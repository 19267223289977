define("dashboard/serializers/repositories-api-github-oauth-token", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RepositoriesApiGithubOauthTokenSerializer extends _application.default {
    normalize(_typeClass, hash) {
      const githubUserId = hash?.github_user_id;
      hash.links = {
        repositoriesApiGithubUser: `/github/users/${githubUserId}`
      };
      return super.normalize.apply(this, arguments);
    }

  }

  _exports.default = RepositoriesApiGithubOauthTokenSerializer;
});