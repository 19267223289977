define("dashboard/helpers/includes", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isIn = isIn;

  function isIn(_ref) {
    let [collection, item] = _ref;
    return collection.includes(item);
  }

  var _default = (0, _helper.helper)(isIn);

  _exports.default = _default;
});