define("dashboard/components/dev-center-link", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    withPrelude: true,
    analytics: (0, _service.inject)(),
    actions: {
      trackDevCenterClick(event) {
        const {
          target
        } = event;
        const label = target.textContent.replace(/\s+/g, ' ').trim();
        const url = target.href;
        this.analytics.logEvent(`${label} Dev Center Link`, 'Clicked', {
          devCenterUrl: url
        });
      }

    }
  });

  _exports.default = _default;
});