define("dashboard/utils/computed-find-by", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(collectionName, key) {
    let value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return (0, _object.computed)(`${collectionName}.@each.${key}`, function () {
      const collection = this.get(collectionName) || [];
      return collection.findBy(key, value);
    });
  }
});