define("dashboard/templates/components/app/collaborators-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XmTWQfmc",
    "block": "[[[10,\"td\"],[14,0,\"bb b--light-silver pa2 dtc w--28\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"email\",\"style\"],[[30,0,[\"collaborator\",\"email\"]],\"width: 28px; height: 28px\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"w-40 bb b--light-silver pv2 pr1\"],[12],[1,[30,0,[\"collaborator\",\"email\"]]],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"bb b--light-silver pv2 pr1\"],[12],[1,[30,0,[\"role\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isOrgApp\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"memberPermissions\"]]],null]],null],null,[[[1,\"    \"],[10,\"td\"],[14,0,\"bb b--light-silver tc ph2\"],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"model\"],[[30,1]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"\\n\"],[41,[30,0,[\"collaborator\",\"isOwner\"]],[[[1,\"  \"],[10,\"td\"],[14,0,\"bb b--light-silver tc ph2\"],[12],[13],[1,\"\\n\"]],[]],[[[1,[28,[35,5],null,[[\"canEdit\",\"canRemove\",\"isSlidePanel\",\"model\",\"editAction\",\"class\",\"confirmDeleteAction\"],[[30,0,[\"canEdit\"]],[30,0,[\"canRemove\"]],true,[30,0,[\"collaborator\"]],[28,[37,6],[[30,0],\"edit\"],null],\"bb b--light-silver tr\",[28,[37,6],[[30,0],\"confirmDelete\"],null]]]]],[1,\"\\n\"]],[]]]],[\"permission\"],false,[\"gravatar-icon\",\"if\",\"each\",\"-track-array\",\"permission-icon\",\"purple-list-action-cell\",\"action\"]]",
    "moduleName": "dashboard/templates/components/app/collaborators-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});