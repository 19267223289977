define("dashboard/templates/components/range-slider/handle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "H7w78Dkx",
    "block": "[[[18,1,null],[1,\"\\n\\n\"],[10,0],[14,0,\"tooltip fade top\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"tooltip-arrow\"],[12],[1,\" \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"tooltip-inner\"],[12],[1,\"\\n    \"],[1,[30,0,[\"value\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "dashboard/templates/components/range-slider/handle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});