define("dashboard/utils/metrics/calculate-deltas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = calculateDeltas;

  function calculateDeltas(data) {
    // map the measurement datums into objects
    const measurements = data.map(datum => {
      return {
        time: datum[0],
        value: datum[1]
      };
    });
    let max = 0;
    data = measurements.map((m, i) => {
      let prev;
      let curr; // since with n elements, we only have n - 1 deltas,
      // we account for that here by duping the first delta

      if (i === 0) {
        curr = {
          time: measurements[0].time,
          value: measurements[1].value
        };
        prev = measurements[0];
      } else {
        // the rest of the deltas can be determined based on the last data point
        curr = m;
        prev = measurements[i - 1];
      } // keep track of the max and use it in the event that the aggregate
      // resets to 0 during the timeframe


      max = Math.max(max, prev.value);
      let delta;

      if (prev.value === 0 || curr.value === 0) {
        delta = 0;
      } else if (curr.value >= prev.value) {
        delta = curr.value - prev.value;
      } else {
        delta = curr.value + max - prev.value;
      } // return as a datum, the form the charts expect


      return [curr.time, delta];
    }); // set last data point to null (to create a dead zone), as the data i s incomplete

    data[data.length - 1][1] = null;
    return data;
  }
});