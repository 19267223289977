define("dashboard/components/pipeline/config-vars-list", ["exports", "@ember/component", "dashboard/mixins/purple-list", "@ember/object", "@ember/service"], function (_exports, _component, _purpleList, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_purpleList.default, {
    analytics: (0, _service.inject)(),
    classNames: ['config-vars-list'],
    title: (0, _object.computed)('stage', function () {
      switch (this.stage) {
        case 'test':
          return 'Test run config vars';

        case 'review':
          return 'Review app config vars';

        default:
          return 'Config vars';
      }
    }),
    titleForDelete: (0, _object.computed)('item.key', function () {
      const name = this.get('item.key');
      return `Delete ${name}`;
    }),
    actions: {
      reveal() {
        this.analytics.logEvent('Pipeline Config Var', 'Revealed');
        return this.attrs.fetchConfigVars(this.stage).then(() => {
          this.set('isRevealed', true);
        });
      },

      conceal() {
        this.set('isRevealed', false);
        this.analytics.logEvent('Pipeline Config Var', 'Concealed');
      },

      create() {
        this.set('isSavingNewConfigVar', true);
        const promise = this.attrs.onCreate({
          key: this.newConfigVarKey,
          value: this.newConfigVarValue,
          stage: this.stage
        });
        return promise.then(() => {
          this.setProperties({
            newConfigVarKey: null,
            newConfigVarValue: null
          });
          this.analytics.logEvent('Pipeline Config Var', 'Created');
        }).finally(() => {
          this.set('isSavingNewConfigVar', false);
        });
      },

      update() {
        return this.onUpdate(this.item).then(() => {
          this.analytics.logEvent('Pipeline Config Var', 'Updated');
          this.send('toggleModal');
        });
      },

      delete() {
        return this.onDelete(this.item).then(() => {
          this.analytics.logEvent('Pipeline Config Var', 'Deleted');
          this.send('toggleDeleteConfirmModal');
        });
      }

    }
  });

  _exports.default = _default;
});