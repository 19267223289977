define("dashboard/templates/components/dyno-size-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7ykx4RoN",
    "block": "[[[10,\"object\"],[15,\"data\",[30,0,[\"svgName\"]]],[14,\"width\",\"38px\"],[14,\"height\",\"48px\"],[14,0,\"pe-none\"],[14,\"role\",\"presentation\"],[14,4,\"image/svg+xml\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/dyno-size-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});