define("dashboard/components/apps-list-item", ["exports", "@ember/component", "@ember/object/computed", "dashboard/mixins/metrics/app-state-path"], function (_exports, _component, _computed, _appStatePath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appStatePath.default, {
    'data-test-target': 'apps-list-item',
    classNames: ['apps-list-item', 'flex', 'flex-column', 'flex-auto', 'b--light-silver', 'bg-white', 'pv2', 'ph4'],
    classNameBindings: ['isFavorite::bb', 'isFavorite::hover-bg-near-white'],
    favoritable: true,
    // shows the favorite toggle icon
    name: (0, _computed.alias)('app.name'),
    locked: (0, _computed.alias)('app.locked'),
    isSpaceApp: (0, _computed.alias)('app.space'),
    spaceName: (0, _computed.alias)('app.spaceName'),
    showAppMetaData: (0, _computed.not)('isFavorite'),
    appIsAlerting: (0, _computed.oneWay)('app.appState.alerting')
  });

  _exports.default = _default;
});