define("dashboard/templates/app/locked", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "L/CV1N1X",
    "block": "[[[10,0],[14,0,\"huge-text\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/images/error-locked.png\"],[14,\"alt\",\"Error\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"error-text\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"text-danger\"],[12],[1,\"\\n    You do not have access to view this app.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You can request access from an admin user.\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/app/locked.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});