define("dashboard/serializers/formation", ["exports", "dashboard/serializers/application", "dashboard/config/environment", "moment"], function (_exports, _application, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      createdAt: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      },
      app: {
        serialize: false
      },
      command: {
        serialize: false
      },
      monitors: {
        serialize: false
      },
      alerts: {
        serialize: false
      },
      restartEvents: {
        serialize: false
      },
      scaleEvents: {
        serialize: false
      }
    },

    normalizeFindHasManyResponse(store, primaryModelClass, payload, id, requestType) {
      // This is used to exclude 'release', 'rake' & 'console' entries from Formations
      // Ember-data will not even load those records
      // Should we ever need them, we will have to revisit this.
      payload = payload.filter(function (formation) {
        return ['rake', 'console', 'release'].indexOf(formation.type) === -1;
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    extractRelationships(modelClass, payload) {
      const {
        app,
        type
      } = payload;

      const relationships = this._super(...arguments);

      if (app) {
        const startTime = (0, _moment.default)().subtract(167, 'hours').utc().format();
        const appId = app.id;
        relationships.app = {
          data: {
            id: app.id,
            type: 'app'
          }
        };
        const monitorsUrl = `${_environment.default.metricsApiUrl}/apps/${appId}/formation/${type}/monitors`;
        relationships.monitors = {
          links: {
            related: monitorsUrl
          }
        };
        const alertsUrl = `${_environment.default.metricsApiUrl}/events/${appId}/alerts?process_type=${type}&start_time=${startTime}`;
        relationships.alerts = {
          links: {
            related: alertsUrl
          }
        };
        const restartEventsUrl = `${_environment.default.particleboardUrl}/apps/${appId}/${type}/restart_events`;
        relationships.restartEvents = {
          links: {
            related: restartEventsUrl
          }
        };
        const scaleEventsUrl = `${_environment.default.particleboardUrl}/apps/${appId}/${type}/scale_events`;
        relationships.scaleEvents = {
          links: {
            related: scaleEventsUrl
          }
        };
      }

      return relationships;
    }

  });

  _exports.default = _default;
});