define("dashboard/templates/components/crumb-trail/pipeline-apps-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+KQZDvfX",
    "block": "[[[10,1],[14,0,\"inline-flex pv--2 mr--2\"],[12],[1,[28,[35,0],null,[[\"app\",\"iconSize\"],[[30,0,[\"app\"]],\"24\"]]]],[13],[1,\"\\n\"],[10,1],[14,0,\"ml1 purple\"],[12],[1,\"\\n  \"],[1,[30,0,[\"app\",\"name\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"app-state-icon\"]]",
    "moduleName": "dashboard/templates/components/crumb-trail/pipeline-apps-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});