define("dashboard/adapters/repositories-api-github-user", ["exports", "dashboard/adapters/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.repositories-api',
    accountFeatures: (0, _service.inject)('account-features'),
    reporter: (0, _service.inject)(),

    urlForFindRecord(id) {
      return `${this.host}/github/users/${id}`;
    }

  });

  _exports.default = _default;
});