define("dashboard/routes/account/two-factor", ["exports", "rsvp", "@ember/service", "dashboard/routes/account/base"], function (_exports, _rsvp, _service, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dashboard.AccountTwoFactorRoute
   * @extends Ember.Route
   */
  var _default = _base.default.extend({
    current: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),

    beforeModel() {
      if (this.get('current.account.mfaEnabled')) {
        this.flashMessages.danger('Two Factor Auth could not be modified', {
          title: 'Two-factor is already enabled. Please disable it first'
        });
        this.transitionTo('account');
      }
    },

    /**
     * TODO: This should not have to be re-fetched if we're transitioning from
     * the account.index route.
     *
     * @method model
     */
    model() {
      const account = this.get('current.account');

      if (account.get('mfaEnabled')) {
        this.transitionTo('account');
      }

      return (0, _rsvp.hash)({
        otpSecret: this.store.createRecord('otp-secret')
      });
    }

  });

  _exports.default = _default;
});