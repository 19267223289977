define("dashboard/serializers/release", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // Delete slug key and it's nested attributes;
    // provide a link instead to convince EmberData to fetch it.
    normalize(modelClass, hash) {
      const appId = hash.app.id;

      if (hash.slug) {
        // has_slug allows us to determine whether this release has a slug
        // without making an extra request to actually get the slug
        const slugId = hash.slug.id;
        hash.has_slug = true;
        delete hash.slug;
        hash.links = {
          slug: `/apps/${appId}/slugs/${slugId}`
        };
      } else {
        hash.has_slug = false;
      }

      return this._super(modelClass, hash);
    },

    serialize(snapshot) {
      return {
        slug: snapshot.record.get('slug.id'),
        description: snapshot.attr('description')
      };
    },

    extractRelationships(modelClass, resourceHash) {
      const relationships = this._super(...arguments);

      relationships.app = {
        data: {
          type: 'app',
          id: resourceHash['app']['id']
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});