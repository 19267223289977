define("dashboard/models/log-stream", ["exports", "@ember/object", "@ember/service", "@ember/utils", "dashboard/models/event-source", "dashboard/models/log-line"], function (_exports, _object, _service, _utils, _eventSource, _logLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _eventSource.default.extend({
    hasChunked: false,
    logSession: null,
    maxLogLines: 10000,
    reconnect: true,
    store: (0, _service.inject)(),

    init() {
      this.initiateStreaming();
    },

    initiateStreaming() {
      if (this.get('app.id')) {
        this.store.createRecord('log-session', {
          appId: this.get('app.id'),
          dyno: this.filter
        }).save().then(logSession => {
          this.set('logSession', logSession);
          this.maybeOpen();
        }).catch(() => {
          const raw = 'Connection to log stream failed. Please try again later.';
          this.storeLogLine(_logLine.default.create({
            raw
          }));
          this.close();
        });
      }
    },

    // The `logDisconnects` observer will reconnect when closed unless we
    // explicitly tell it not to.
    closeWithoutReconnection() {
      this.set('reconnect', false);
      this.close();
    },

    lines: (0, _object.computed)(function () {
      return [];
    }),

    maybeOpen() {
      return this.url ? this.open() : this;
    },

    url: alias('logSession.logplexUrl'),

    messageListener(e) {
      const logLines = this.extractLogLines(e.data);
      logLines.forEach(line => {
        const logLine = _logLine.default.create({
          raw: line
        }); // Discard the disconnected message


        if (logLine.get('isDisconnected')) {
          this.set('isOpen', false);
          return;
        }

        this.storeLogLine(logLine);
      });
    },

    // Each SSE message can contain many actual log lines. This converts the raw
    // message data into an Array of log lines.
    extractLogLines(rawData) {
      return rawData.split('\n').filter(line => (0, _utils.isPresent)(line));
    },

    /**
     * Store the log line in the buffer. If we have too many, drop the oldest one
     * off.
     *
     * If we don't do this the array will continually get larger and may cause
     * the browser to stop responding.
     */
    storeLogLine(logLine) {
      const lines = this.lines;
      lines.pushObject(logLine);

      if (lines.length > this.maxLogLines) {
        lines.shiftObject();
      }
    },

    logDisconnects: (0, _object.observer)('isOpen', function () {
      if (this.reconnect && !this.isOpen) {
        const raw = 'Disconnected from log stream. There may be events happening that you do not see here! Attempting to reconnect...';
        this.storeLogLine(_logLine.default.create({
          raw
        }));
        this.close();
        this.initiateStreaming();
      }
    })
  });

  _exports.default = _default;
});