define("dashboard/models/team/collaborator", ["exports", "ember-data/relationships", "ember-data/attr", "dashboard/models/collaborator"], function (_exports, _relationships, _attr, _collaborator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _collaborator.default.extend({
    silent: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    app: (0, _relationships.belongsTo)('app', {
      inverse: 'teamAppCollaborators'
    }),
    appName: null
  });

  _exports.default = _default;
});