define("dashboard/components/git-branch-picker", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['relative', 'branch-selection'],
    hasAccessError: (0, _computed.empty)('branches'),
    hasMore: (0, _computed.gte)('branches.length', 100),
    hideDropdown: (0, _computed.or)('hasAccessError', 'hasMore'),
    onUpdateBranch: () => {},
    actions: {
      selectBranch(branch) {
        this.set('value', branch);
        this.onUpdateBranch();
      }

    }
  });

  _exports.default = _default;
});