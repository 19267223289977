define("dashboard/adapters/space", ["exports", "dashboard/adapters/application", "dashboard/mixins/adapters/refresh-quick-jump-hooks", "@ember/service"], function (_exports, _application, _refreshQuickJumpHooks, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_refreshQuickJumpHooks.default, {
    dsErrorMonitor: (0, _service.inject)(),

    urlForQueryRecord(query, modelName) {
      const name = query.name;
      delete query['name'];
      return this.urlForFindRecord(name, modelName);
    },

    handleResponse(status, headers, payload, requestData) {
      if (status === 403) {
        // don't flash error
        requestData.jqXHR.errorIsHandled = true;
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});