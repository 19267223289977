define("dashboard/services/payment-gateway/input-styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // What the heck??? Is this CSS in Javascript?
  // It is! We have to pass the stylings for the Braintree Hosted Field inputs through
  // javascript, because they are in an iframe.
  // https://developers.braintreepayments.com/guides/hosted-fields/styling/javascript/v3
  var _default = {
    'input,.hk-input-base': {
      'line-height': '20px',
      transition: 'background .1s ease,border-color .1s ease'
    },
    input: {
      'font-size': '13px',
      outline: '0'
    },
    'input::-webkit-input-placeholder': {
      color: '#96a3b6',
      opacity: '.8'
    },
    'input:-ms-input-placeholder': {
      color: '#96a3b6',
      opacity: '.8'
    },
    'input::placeholder': {
      color: '#96a3b6',
      opacity: '.8'
    },
    'input:focus': {
      outline: 'none'
    },
    'input--read-only': {
      color: '#596981',
      'font-size': '13px',
      'line-height': '20px',
      outline: '0',
      transition: 'background .1s ease,border-color .1s ease'
    },
    'input:-moz-read-only': {
      color: '#596981'
    },
    'input:read-only': {
      color: '#596981'
    },
    'input--read-only:focus': {
      outline: 'none'
    },
    'input:-moz-read-only:focus': {
      outline: 'none'
    },
    'input:read-only:focus': {
      outline: 'none'
    },
    'input--disabled': {
      'font-size': '13px',
      'line-height': '20px',
      outline: '0',
      transition: 'background .1s ease,border-color .1s ease'
    },
    'input--disabled,.hk-input:disabled': {
      color: '#96a3b6'
    }
  };
  _exports.default = _default;
});