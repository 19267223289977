define("dashboard/components/github-commit-status", ["exports", "@ember/component", "dashboard/helpers/array-group-by", "dashboard/utils/pluralize", "@ember/object", "@ember/object/computed"], function (_exports, _component, _arrayGroupBy, _pluralize, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATE_PRECIDENCE = {
    error: 0,
    failure: 1,
    pending: 2,
    success: 3
  };

  var _default = _component.default.extend({
    classNames: ['ci-status-component'],
    isVisible: (0, _computed.notEmpty)('applicableCommitStatuses'),
    hasStatuses: (0, _computed.notEmpty)('commitStatuses'),
    applicableCommitStatuses: (0, _object.computed)('ref', 'commitStatuses.@each.sha', function () {
      return (this.commitStatuses || []).filter(commitStatus => {
        const sha = commitStatus.sha || '';
        return sha.startsWith(this.ref);
      });
    }),
    // Returns the most recent status per context type
    latestCommitStatuses: (0, _object.computed)('applicableCommitStatuses.@each.updatedAt', 'applicableCommitStatuses.@each.context', function () {
      return (0, _arrayGroupBy.default)(this.applicableCommitStatuses, 'context').reduce((memo, group) => {
        const latest = group.items.sortBy('updatedAt').get('lastObject');
        memo.push(latest);
        return memo;
      }, []);
    }),
    compositeStatus: (0, _object.computed)('latestCommitStatuses.@each.state', function () {
      return this.latestCommitStatuses.mapBy('state').uniq().sort((a, b) => {
        return STATE_PRECIDENCE[a] > STATE_PRECIDENCE[b];
      }).shift();
    }),
    erroredStatuses: (0, _computed.filterBy)('latestCommitStatuses', 'state', 'error'),
    failedStatuses: (0, _computed.filterBy)('latestCommitStatuses', 'state', 'failure'),
    pendingStatuses: (0, _computed.filterBy)('latestCommitStatuses', 'state', 'pending'),
    succeededStatuses: (0, _computed.filterBy)('latestCommitStatuses', 'state', 'success'),
    countErrored: (0, _computed.readOnly)('erroredStatuses.length'),
    countFailed: (0, _computed.readOnly)('failedStatuses.length'),
    countPending: (0, _computed.readOnly)('pendingStatuses.length'),
    countSucceeded: (0, _computed.readOnly)('succeededStatuses.length'),
    summarySubHeading: (0, _object.computed)('countErrored', 'countFailed', 'countPending', 'countSucceeded', function () {
      return [['errored', this.countErrored], ['failed', this.countFailed], ['pending', this.countPending], ['successful', this.countSucceeded]].filter(_ref => {
        let [, count] = _ref;
        return count > 0;
      }).map((_ref2, index) => {
        let [status, count] = _ref2;
        return `${count} ${index === 0 ? (0, _pluralize.default)(count, 'check', 'checks') : ''} ${status}`;
      }).join(', ');
    })
  });

  _exports.default = _default;
});