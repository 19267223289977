define("dashboard/utils/metrics/timeframe-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultTimeframe = _exports.datastoreOptions = _exports.customerFacingOptions = _exports.allOptions = _exports.allDatastoreOptions = void 0;
  _exports.findTimeframe = findTimeframe;
  _exports.standardOptions = _exports.standardAndPremiumOptions = _exports.premiumOptions = void 0;
  const allOptions = [{
    name: 'past2Hours',
    tier: 'premium',
    beforeHours: 0,
    afterHours: 2,
    label: 'Past 2hrs',
    desc: '1 min resolution',
    step: 1,
    order: 1
  }, {
    name: 'past24Hours',
    isDefault: true,
    tier: 'standard',
    beforeHours: 0,
    afterHours: 24,
    label: 'Past 24hrs',
    desc: '10 min resolution',
    step: 10,
    order: 2
  }, {
    name: '48To24HoursAgo',
    tier: 'premium',
    beforeHours: 24,
    afterHours: 48,
    label: '48hrs-24hrs ago',
    desc: '10 min resolution',
    step: 10,
    order: 3
  }, {
    name: '72To48HoursAgo',
    tier: 'premium',
    beforeHours: 48,
    afterHours: 72,
    label: '72hrs-48hrs ago',
    desc: '10 min resolution',
    step: 10,
    order: 4
  }, {
    name: 'past72Hours',
    tier: 'premium',
    beforeHours: 0,
    afterHours: 72,
    label: 'Past 72hrs',
    desc: '1 hr resolution',
    step: 60,
    order: 5
  }, {
    name: 'past7days',
    tier: 'premium',
    beforeHours: 0,
    afterHours: 168,
    label: 'Past 7 days',
    desc: '2 hr resolution',
    step: 120,
    order: 6
  }];
  _exports.allOptions = allOptions;
  const allDatastoreOptions = [{
    name: 'past2Hours',
    beforeHours: 0,
    afterHours: 2,
    label: 'Past 2hrs',
    desc: '',
    step: 10,
    isDefault: true,
    order: 1
  }, {
    name: 'past24Hours',
    beforeHours: 0,
    afterHours: 24,
    label: 'Past 24hrs',
    desc: '',
    step: 10,
    order: 2
  }, {
    name: 'past72Hours',
    beforeHours: 0,
    afterHours: 72,
    label: 'Past 72hrs',
    desc: '',
    step: 60,
    order: 3
  }, {
    name: 'past7days',
    beforeHours: 0,
    afterHours: 168,
    label: 'Past 7 days',
    desc: '',
    step: 120,
    order: 4
  }];
  _exports.allDatastoreOptions = allDatastoreOptions;
  const standardOptions = allOptions.filterBy('tier', 'standard');
  _exports.standardOptions = standardOptions;
  const premiumOptions = allOptions.filterBy('tier', 'premium');
  _exports.premiumOptions = premiumOptions;
  const standardAndPremiumOptions = [...premiumOptions, ...standardOptions].unique;
  _exports.standardAndPremiumOptions = standardAndPremiumOptions;
  const customerFacingOptions = [...premiumOptions, ...standardOptions].sortBy('order');
  _exports.customerFacingOptions = customerFacingOptions;
  const defaultTimeframe = allOptions.findBy('isDefault', true);
  _exports.defaultTimeframe = defaultTimeframe;
  const datastoreOptions = allDatastoreOptions.sortBy('order');
  _exports.datastoreOptions = datastoreOptions;

  function findTimeframe(before, after) {
    return allDatastoreOptions.find(function (_ref) {
      let {
        beforeHours,
        afterHours
      } = _ref;
      return beforeHours === before && afterHours === after;
    });
  }
});