define("dashboard/components/cp-validating-input", ["exports", "@ember/object/computed", "@ember/service", "@ember/component", "@ember/object"], function (_exports, _computed, _service, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    errorMonitor: (0, _service.inject)(),
    classNameBindings: [':form-group', 'showError:has-error'],
    type: 'text',
    hasError: (0, _object.computed)('model.validations.isValid', 'validatingKey', 'secondaryValidatingKey', function () {
      const primaryKey = this.validatingKey;
      const secondaryKey = this.secondaryValidatingKey;

      if (primaryKey || secondaryKey) {
        return this.get(`model.validations.attrs.${primaryKey}.isInvalid`) || this.get(`model.validations.attrs.${secondaryKey}.isInvalid`);
      } else {
        return this.get('model.validations.isInvalid');
      }
    }),
    showError: (0, _computed.and)('errorIsVisible', 'hasError'),
    autoSetErrorIsVisible: true,
    // Make errors visible once someone keypresses in the field.
    actions: {
      setErrorIsVisible() {
        if (this.autoSetErrorIsVisible) {
          this.set('errorIsVisible', true);
        } // Clear any flash messages since they should only appear after the input is submitted


        this.errorMonitor.wipe();
      }

    }
  });

  _exports.default = _default;
});