define("dashboard/templates/app/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tbGBE6H0",
    "block": "[[[1,[28,[35,0],null,[[\"app\",\"appPermissions\",\"team\",\"addons\",\"attachments\",\"dynoTiers\",\"formations\",\"releases\",\"builds\",\"alerts\",\"onAddonLoadError\"],[[30,0,[\"model\",\"app\"]],[30,0,[\"model\",\"appPermissions\"]],[30,0,[\"model\",\"team\"]],[30,0,[\"model\",\"addons\"]],[30,0,[\"model\",\"attachments\"]],[30,0,[\"model\",\"dynoTiers\"]],[30,0,[\"model\",\"app\",\"formations\"]],[30,0,[\"model\",\"releases\"]],[30,0,[\"model\",\"builds\"]],[30,0,[\"model\",\"alerts\"]],[28,[37,1],[\"onAddonLoadError\"],null]]]]],[1,\"\\n\"]],[],false,[\"app/x-overview\",\"route-action\"]]",
    "moduleName": "dashboard/templates/app/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});