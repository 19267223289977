define("dashboard/components/app/overview-addons", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency", "dashboard/config/environment", "dashboard/mixins/addons-totals"], function (_exports, _component, _object, _service, _emberConcurrency, _environment, _addonsTotals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sort,
    filterBy,
    alias
  } = _object.computed;

  var _default = _component.default.extend(_addonsTotals.default, {
    addonsLoader: (0, _service.inject)(),
    session: (0, _service.inject)(),
    addonsAndAttachments: alias('addonsLoader.addonsAndAttachments'),
    checkStateInterval: _environment.default.environment === 'test' ? 10 : 1000,

    didReceiveAttrs() {
      const appId = this.get('app.id');
      const canView = this.canView;
      this.addonsLoader.fetchAllAddonsAndAttachments(appId, canView).then(() => {
        // Kick-off the check state process but don’t return it — we don’t want
        // to handle errors in this promise chain.
        this.checkState.perform();
      }).catch(e => {
        const protectedError = e.errors && e.errors.findBy('id', 'forbidden');
        const skipProtectedError = !!protectedError;
        this.set('canView', false);
        this.onAddonLoadError(e, skipProtectedError);
      });
    },

    classNames: ['add-ons'],
    sorting: ['addon_service.name:asc'],
    sortedAddons: sort('addonsAndAttachments.addons', 'sorting'),
    provisioningAddons: filterBy('addonsAndAttachments.addons', 'state', 'provisioning'),
    attachmentsBilledToThisApp: filterBy('addonsAndAttachments.attachments', 'isAttached', false),
    checkState: (0, _emberConcurrency.task)(function* () {
      const interval = this.checkStateInterval;

      while (this.get('provisioningAddons.length')) {
        yield (0, _emberConcurrency.timeout)(interval);
        yield (0, _emberConcurrency.all)(this.provisioningAddons.invoke('reload'));
      }
    }).restartable()
  });

  _exports.default = _default;
});