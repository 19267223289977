define("dashboard/routes/protected", ["exports", "rsvp", "@ember/routing/route", "@ember/service", "dashboard/mixins/resettable", "ember-simple-auth/mixins/authenticated-route-mixin", "dashboard/mixins/redirect-after-auth", "dashboard/config/environment", "dashboard/utils/localstorage", "dashboard/utils/errors"], function (_exports, _rsvp, _route, _service, _resettable, _authenticatedRouteMixin, _redirectAfterAuth, _environment, _localstorage, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, _resettable.default, _redirectAfterAuth.default, {
    webSocket: (0, _service.inject)(),
    phoenixSocket: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    reporter: (0, _service.inject)(),
    location: (0, _service.inject)(),
    session: (0, _service.inject)(),
    ajaxMonitor: (0, _service.inject)(),
    glostick: (0, _service.inject)(),
    browserTime: (0, _service.inject)(),
    notifications: (0, _service.inject)(),
    transitionMonitor: (0, _service.inject)(),
    ignoreDelinquent: true,

    model() {
      // TODO: remove `.catch` calls once account.billing.pay-balance route is not
      // nested underneath the protected route
      const requests = (0, _rsvp.hash)({
        _account: this.store.queryRecord('account', {}),
        accountFeatures: this.accountFeaturesService.fetch().catch(() => []),
        enterpriseAccounts: this.store.findAll('enterprise-account').catch(() => []),
        teams: this.store.findAll('team').catch(() => []),
        regions: this.store.findAll('region').then(regions => regions.sortBy('country', 'locale')).catch(() => []),
        notifications: this.notifications.fetchUnreadCount().catch(() => 0),
        userPreferences: this.store.find('user-preferences', '~').catch(() => {}),
        _browserOffset: this.browserTime.findBrowserOffset(),
        dashboardUserPreferences: this.userPreferencesService.fetch().catch(() => {})
      });
      return requests.then(data => {
        this.current.set('account', data._account);
        return (0, _rsvp.hash)(data);
      });
    },

    beforeModel(transition) {
      if (transition.to.queryParams.utm_campaign) {
        const utm = {
          utm_campaign: transition.to.queryParams.utm_campaign,
          utm_medium: transition.to.queryParams.utm_medium,
          utm_source: transition.to.queryParams.utm_source,
          utm_content: transition.to.queryParams.utm_content,
          utm_term: transition.to.queryParams.utm_term
        };

        _localstorage.default.setSessionItem('utm', JSON.stringify(utm));
      }

      if (!this.get('session.isAuthenticated')) {
        this.setRedirectTo(this.transitionMonitor.intendedPath);
      }

      return this._super(transition);
    },

    afterModel() {
      const account = this.get('current.account'); // Initialize Glostick as soon as model is known

      if (account) {
        this._initGlostick(account);
      } // Reject Non-Herokai when `config.admitNonHerokai` is false


      const isAssumed = this.get('session.isAssumed');
      const isHerokai = this.get('current.account.isHerokai') || isAssumed;
      const isNotHerokai = !isHerokai;
      const rejectNonHerokai = !_environment.default.admitNonHerokai;

      if (rejectNonHerokai && isNotHerokai) {
        this.location.assign('https://dashboard.heroku.com');
        return;
      }

      if (account) {
        this.analytics.trackUser(account);
        this.reporter.trackUser(account);
      }
    },

    redirect(_model, transition) {
      this.ensureAcceptedTerms(transition);
    },

    /**
     * removes background loading dots
     */
    _removeLoadingIndicator() {
      $('#app-loader').remove();
    },

    _hasSFDCBrandingQueryParam() {
      const params = this.paramsFor('protected') || {};
      return params['bluelogo'];
    },

    _initGlostick(accountModel) {
      const accountEmail = accountModel.get('email');
      const accountName = accountModel.get('name');
      const isAssumed = this.get('session.isAssumed');
      const isHerokai = this.get('current.account.isHerokai') || isAssumed;
      const hasUnreadNotifications = this.notifications.hasUnreadNotifications;
      const notificationsLink = {
        indicator: hasUnreadNotifications
      };

      const sfdcBranding = this.accountFeaturesService.hasFeature('sfdc-branded') || this._hasSFDCBrandingQueryParam();

      let assumeLink = isHerokai ? [{
        href: '/assume',
        label: 'Assume identity...',
        icon: 'assumeIdentity'
      }] : [];

      if (isAssumed) {
        assumeLink = [{
          href: '/release',
          label: 'Release identity',
          icon: 'assumeIdentity'
        }];
      }

      const glostickOptions = {
        accountEmail,
        accountName,
        assumeLink,
        notificationsLink,
        sfdcBranding,
        helmet: _environment.default.glostick.helmet
      };
      this.glostick.initGlostick(glostickOptions);
    },

    activate() {
      this._removeLoadingIndicator(); // TODO ??? this.get('ajaxMonitor').off('unauthorized')
      // It is necessary to `get('ajaxMonitor')` here regardless of other
      // functionality just to initialize the service.  This should be done after
      // initialization to prevent red bubbles from appearing to unauthenticated
      // users.


      this.ajaxMonitor.on('unauthorized', function () {
        this.reauthenticate();
      }.bind(this));
    },

    reauthenticate() {
      this.webSocket.disconnect();
      this.phoenixSocket.disconnect();
      this.controllerFor('protected').set('showReauthenticateModal', true);
    },

    ensureAcceptedTerms(transition) {
      if (transition.targetName === 'protected.terms-of-service' || this.current.account?.acknowledgedMsa || this.current.account?.isDelinquent || this.get('session.isAssumed')) {
        return true;
      }

      this.transitionTo('protected.terms-of-service');
    },

    actions: {
      didTransition() {
        this.controllerFor('protected').setupFreshPage();
      },

      willTransition(transition) {
        this.ensureAcceptedTerms(transition);
      },

      accountVerified() {},

      assume() {
        if (this.get('current.account.isHerokai')) {
          this.transitionTo('protected.assume');
        }
      },

      // Release the assumed session by dropping it from the store and reloading.
      release() {
        this.session.clearSudoData();
        this.send('reset');
      },

      releasePreauth() {
        this.session.clearPreauthData();
        this.send('reset');
      },

      reset() {
        this.hardReset();
        this.location.assign('/');
      },

      reauthenticate() {
        this.reauthenticate();
      },

      confirmReauthenticate() {
        _localstorage.default.clear();

        _localstorage.default.clearSession();

        this.setRedirectTo(this.transitionMonitor.intendedPath);
        this.transitionTo('login');
      },

      pipelineRenamed(pipeline) {
        const couplings = this.get('controller.model.couplings') || [];
        couplings.forEach(coupling => {
          if (coupling.get('pipeline.id') === pipeline.get('id')) {
            coupling.set('pipeline.name', pipeline.get('name'));
          }
        });
      },

      pipelineDestroyed(pipeline) {
        const couplings = this.get('controller.model.couplings') || [];
        const remainingCouplings = couplings.rejectBy('pipeline.id', pipeline.get('id'));
        this.set('controller.model.couplings', remainingCouplings);
      },

      error(error, transition) {
        if ((0, _errors.isTwoFactorRequiredError)(error)) {
          // for some reason, the `model` for the `app` route gets set to the
          // rejected XHR upon calling transition.retry() in the protected error
          // route unless we call transition.abort(); here. ¯\_(ツ)_/¯
          transition.abort(); // Store the app so the two-factor component can render it.

          error.app = this.currentModel && this.currentModel.app;
          error.twoFactorRequired = true; // Store the transition on the error so we can retry it later.

          error.twoFactorNeededTransition = transition;
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});