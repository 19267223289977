define("dashboard/services/quick-jump-router", ["exports", "@ember/service", "@ember/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A service that exposes a router interface that's used by glostick to route
   * quick jump selections to the correct place.
   */
  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),

    init() {
      this._super(...arguments);

      this.set('_router', (0, _application.getOwner)(this).lookup('router:main'));
    },

    // returns the impl of the router interface expected by glostick
    router() {
      return {
        navigateTo: selection => this.handle(selection)
      };
    },

    handle(selection) {
      const handler = this.handlers[selection.modelType] || this.handlers['home'];
      return handler.call(this, selection);
    },

    logJumpTo(jumpType) {
      this.analytics.logEvent('Quick Jump To', jumpType);
    },

    handlers: {
      app(quickJumpSelection) {
        this.logJumpTo('App');
        const appId = quickJumpSelection.name || quickJumpSelection.id;

        this._router.transitionTo('app', appId);
      },

      pipeline(quickJumpSelection) {
        this.logJumpTo('Pipeline');

        this._router.transitionTo('pipelines.pipeline', quickJumpSelection.id);
      },

      org(quickJumpSelection) {
        this.logJumpTo('Organization');

        this._router.transitionTo('team', quickJumpSelection.name);
      },

      team(quickJumpSelection) {
        this.logJumpTo('Team');

        this._router.transitionTo('team', quickJumpSelection.name);
      },

      space(quickJumpSelection) {
        this.logJumpTo('Space'); // the quickJumpSelection does not have the org info needed for the transition

        this.store.findRecord('space', quickJumpSelection.id).then(space => {
          this._router.transitionTo('team.space', space.get('team.name'), space.get('name'));
        });
      },

      home() {
        this.logJumpTo('Default');

        this._router.transitionTo('apps');
      }

    }
  });

  _exports.default = _default;
});