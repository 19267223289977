define("dashboard/mixins/metrics/private-spaces-charts", ["exports", "@ember/object/mixin", "@ember/object/computed"], function (_exports, _mixin, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    showPrivateSpacesCharts: (0, _computed.and)('app.isPrivateSpaceApp', 'process.isWeb')
  });

  _exports.default = _default;
});