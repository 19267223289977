define("dashboard/models/pipeline-coupling", ["exports", "@ember/object", "@ember/utils", "@ember-data/model", "@ember/object/computed"], function (_exports, _object, _utils, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REVIEW = 'review';
  const DEVELOPMENT = 'development';
  const STAGING = 'staging';
  const PRODUCTION = 'production';
  const STAGE_ORDER = [REVIEW, DEVELOPMENT, STAGING, PRODUCTION];

  var _default = _model.default.extend({
    pipeline: (0, _model.belongsTo)('pipeline'),
    app: (0, _model.belongsTo)('app'),
    stage: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    isReview: (0, _computed.equal)('stage', 'review'),
    isNotReview: (0, _computed.not)('isReview'),
    isDevelopment: (0, _computed.equal)('stage', 'development'),
    isTest: (0, _computed.equal)('stage', 'test'),
    isStaging: (0, _computed.equal)('stage', 'staging'),
    isProduction: (0, _computed.equal)('stage', 'production'),
    isEphemeral: (0, _computed.or)('isReview', 'isTest'),
    isDurable: (0, _computed.not)('isEphemeral'),
    stageSorting: (0, _object.computed)(function () {
      return STAGE_ORDER.indexOf(this.stage);
    }),
    hasGithubAppLink: (0, _computed.notEmpty)('githubAppLink.content'),
    pipelineName: (0, _computed.readOnly)('pipeline.name'),
    prNumber: (0, _object.computed)('githubPullRequest.number', 'pull_request.number', 'guessedPRNumber', function () {
      return this.get('githubPullRequest.number') || this.get('pull_request.number') || this.guessedPRNumber;
    }),
    guessedPRNumber: (0, _object.computed)('app.name', function () {
      const number = (this.get('app.name') || '').split('-pr-').get('lastObject');

      if ((0, _utils.isPresent)(number)) {
        return parseInt(number, 10);
      }
    }),
    githubAppLink: (0, _computed.alias)('app.githubAppLink'),
    githubPullRequest: (0, _object.computed)('pipeline.pullRequests.[]', 'pull_request.id', 'guessedPRNumber', function () {
      const prId = this.get('pull_request.id');
      const guessedPRNumber = this.guessedPRNumber;
      return (this.get('pipeline.pullRequests') || []).find(pr => {
        return pr.get('id') === prId || pr.get('number') === guessedPRNumber;
      });
    }),
    githubReviewApp: (0, _object.computed)('pipeline.kolkrabbiReviewApps.[]', 'guessedPRNumber', function () {
      return this.get('pipeline.kolkrabbiReviewApps').findBy('pullRequest.number', this.guessedPRNumber);
    })
  });

  _exports.default = _default;
});