define("dashboard/adapters/account-quota", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.account-quotas',

    urlForQueryRecord(query) {
      const path = `accounts/${query.accountId}/actions/get-quota`;
      delete query['accountId'];
      return `${this.host}/${path}`;
    }

  });

  _exports.default = _default;
});