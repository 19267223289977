define("dashboard/components/two-factor-catch-all", ["exports", "@ember-data/adapter/error", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "dashboard/utils/errors"], function (_exports, _error, _component, _service, _object, _computed, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    current: (0, _service.inject)(),
    showTwoFactorPrompt: true,
    showConfirmation: (0, _computed.not)('model.twoFactorNeededTransition'),
    jsonAPIError: (0, _object.computed)('model', function () {
      const model = this.model;

      if (model instanceof _error.default) {
        return model;
      } else if (model.responseJSON) {
        return new _error.default((0, _errors.toJSONAPIErrors)(model.responseJSON, model.status));
      }
    }),
    firstJSONAPIError: (0, _object.computed)('jsonAPIError.errors.[]', function () {
      return this.get('jsonAPIError.errors')[0];
    }),

    didInsertElement() {
      this._super(...arguments); // prevents scrolling the page


      document.body.classList.add('overflow-hidden');
      document.body.classList.add('two-factor-catch-all-is-open');
    },

    willDestroyElement() {
      // allow scrolling the page
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('two-factor-catch-all-is-open');

      this._super(...arguments);
    },

    close() {
      this.current.set('twoFactorRequiredError', null);
    },

    actions: {
      close() {
        this.close();
      },

      twoFactorDone() {
        this.set('showTwoFactorPrompt', false);

        if (this.showConfirmation) {// do nothing, `close` action should be called by `Close` button by user in next step
        } else {
          this.close();
          this.model.twoFactorNeededTransition.retry();
        }
      }

    }
  });

  _exports.default = _default;
});