define("dashboard/components/metrics/charts/registered-chart-element", ["exports", "@ember/object", "@ember/component", "dashboard/mixins/metrics/selected-time-data"], function (_exports, _object, _component, _selectedTimeData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the base {{registered-chart-element}} object which must be rendered inside a {{timeseries-chart}}
   *
   * this component should not be used directly and should be inherited from
   *
   */
  var _default = _component.default.extend(_selectedTimeData.default, {
    tagName: 'g',
    sortedGraphs: (0, _object.computed)('graphs.[]', function () {
      const graphs = this.graphs || [];
      return graphs.sortBy('order');
    }),

    /**
     * the previous graph rendered in the same chart
     */
    previousSibling: (0, _object.computed)('sortedGraphs.[]', function () {
      return this.previousSiblingFromCollection(this.sortedGraphs);
    }),

    /**
     * helper for determining the object located directly before `this` object
     * in a collection
     */
    previousSiblingFromCollection(collection) {
      const currentIndex = collection.indexOf(this);
      return collection.objectAt(currentIndex - 1);
    },

    /**
     * inform the chart of the presence of this graph
     */
    didRenderElement() {
      this.registerChartElement(this);
    },

    willDestroy() {
      this.unregisterChartElement(this);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});