define("dashboard/models/app-setup.eo", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "rsvp", "@ember/runloop", "@ember/service", "dashboard/mixins/error-monitoring-model", "dashboard/mixins/app-setup"], function (_exports, _object, _computed, _utils, _rsvp, _runloop, _service, _errorMonitoringModel, _appSetup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_appSetup.default, _errorMonitoringModel.default, {
    store: (0, _service.inject)(),
    api: (0, _service.inject)(),
    resolvedSuccessUrl: (0, _computed.alias)('resolved_success_url'),
    pollInterval: 1000,

    /**
     * binds the appSetup post response's
     * app object name to the appSource
     */
    bindAppSourceName: (0, _object.observer)('app.name', function () {
      this.set('sourceApp.name', this.get('app.name'));
    }),

    fetch() {
      const api = this.api;
      const id = this.id;

      const beforeSend = jqXHR => {
        jqXHR.setRequestHeader('heroku-deploy-type', 'dashboard-button');
      };

      return api.request(`/app-setups/${id}`, {
        type: 'GET',
        beforeSend
      }).then(response => {
        Object.keys(response).forEach(property => {
          this.set(property, response[property]);
        });
      });
    },

    save() {
      const api = this.api;
      const sourceApp = this.sourceApp;
      const data = {};
      const appData = {};
      Object.keys(sourceApp).forEach(key => {
        appData[key] = sourceApp.get(key);
      });
      data.app = appData;
      data['source_blob'] = {
        url: this.sourceBlobLocation
      };
      data.overrides = {
        env: this.get('template.env')
      };

      const beforeSend = jqXHR => {
        jqXHR.setRequestHeader('heroku-deploy-type', 'dashboard-button');
        jqXHR.setRequestHeader('heroku-deploy-source', data['source_blob']['url']);
      };

      return api.request('/app-setups', {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data),
        beforeSend
      }).then(response => {
        this.set('id', response.id);
      }).then(this.waitUntilComplete.bind(this)).catch(reason => {
        this.set('saveFailed', true);
        return (0, _rsvp.reject)(reason);
      });
    },

    loadTemplateSourceBlob() {
      const template = this.template;

      if ((0, _utils.isPresent)(template)) {
        return template.sourceBlob(this.store).then(sourceBlob => {
          this.set('sourceBlobLocation', sourceBlob.get('archive_link'));
        });
      } else {
        return _rsvp.default.resolve();
      }
    },

    /**
     * this wipes all mid-way states of the appSetup
     * flow such as "app created but build failed"
     */
    wipeState() {
      this.set('status', '');
      this.set('saveFailed', false);
      this.set('saveCompleted', false);
      this.set('errors', []);
      this.set('build', null);
    },

    reset() {
      this.set('id', null);
      this.set('created_at', null);
      this.set('updated_at', null);
    },

    waitUntilComplete() {
      this.set('saveCompleted', true);
      return new _rsvp.default.Promise((resolve, reject) => {
        this.pollStatus(resolve, reject);
      });
    },

    pollStatus(resolve, reject) {
      this.set('errorContainer.flashable', false);
      this.fetch().then(() => {
        const status = this.status;

        if (status === 'pending') {
          _runloop.run.later(this, () => {
            this.pollStatus(resolve, reject); // output_steam_url is null when failed
            // and must be retrieved before that happens
            // see https://github.com/heroku/nyata/issues/172

            this.set('outputStreamURL', this.get('build.output_stream_url'));
          }, this.pollInterval);
        } else if (status === 'failed') {
          this.set('errors', [this.failure_message]);
          this.errors.addObjects(this.manifest_errors);
          reject({
            status
          });
        } else {
          const store = this.store;
          const normalized = store.normalize('app', {
            id: this.get('app.id'),
            name: this.get('app.name')
          });
          const app = store.push(normalized);
          resolve(app);
        }
      });
    }

  });

  _exports.default = _default;
});