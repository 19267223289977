define("dashboard/components/app/settings/domains-list-item", ["exports", "@ember/object", "dashboard/components/purple-list-row"], function (_exports, _object, _purpleListRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _purpleListRow.default.extend({
    item: alias('domain'),
    classNames: ['domain-item'],
    acmStatusIcon: (0, _object.computed)('domain.acmStatus', function () {
      switch (this.get('domain.acmStatus')) {
        case 'ok':
        case 'cert issued':
          return 'success-badge-16';

        case 'in-progress':
        case 'verified': // leaving this status out of an abundance of caution -- https://github.com/heroku/cedar/blob/a48981a1e3ce03c9dab484839b450f5dbf5c8c02/acm/task/executor.go#L35-L40

        case 'dns-verified':
          return 'time-16';

        case 'failing':
        case 'failed':
          return 'failure-badge-16';

        default:
          return 'time-16';
      }
    }),
    acmStatusIconClass: (0, _object.computed)('domain.acmStatus', function () {
      switch (this.get('domain.acmStatus')) {
        case 'ok':
        case 'cert issued':
          return 'malibu-fill-gradient-green';

        case 'in-progress':
        case 'verified':
        case 'dns-verified':
          return 'malibu-fill-gradient-dark-gray';

        case 'failing':
        case 'failed':
          return 'malibu-fill-gradient-red';

        default:
          return 'malibu-fill-gradient-dark-gray';
      }
    }),
    acmStatusClass: (0, _object.computed)('domain.acmStatus', function () {
      switch (this.get('domain.acmStatus')) {
        case 'ok':
        case 'cert issued':
        case 'in-progress':
        case 'verified':
        case 'dns-verified':
          return 'dark-gray';

        case 'failing':
        case 'failed':
          return 'red';

        default:
          return 'dark-gray';
      }
    })
  });

  _exports.default = _default;
});