define("dashboard/components/efp-addons-list-item-plan-description", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EfpAddonsListItemPlanDescriptionComponent extends _component.default {
    get shouldDisplayHobbyDevWarnings() {
      return !this.args.app?.get('team.isEnterpriseTeam');
    }

    get tooltipTitle() {
      return this.args.app?.get('isOwnedByOrg') || this.args.isOwner || this.args.isWebHooks ? 'To retain your data, upgrade to paid resources now' : 'To retain data, the app owner must upgrade to paid resources now';
    }

    get tooltipDataTestTarget() {
      if (this.args.app?.get('isOwnedByOrg') || this.args.isOwner) {
        return 'targeted-Hobby Dev';
      } else {
        return 'targeted-collaborator-Hobby Dev';
      }
    }

  }

  _exports.default = EfpAddonsListItemPlanDescriptionComponent;
});