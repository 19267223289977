define("dashboard/templates/components/pipeline/destroyed-notice", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uJjM0uok",
    "block": "[[[10,0],[14,0,\"huge-text\"],[12],[1,\"\\n \"],[10,\"img\"],[14,\"src\",\"/images/error-deleted.png\"],[14,\"alt\",\"Pipeline Deleted\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"error-text\"],[12],[1,\"\\n \"],[10,2],[12],[1,\"\\n   This pipeline has been deleted.\\n \"],[13],[1,\"\\n \"],[10,2],[12],[1,\"\\n   Return to the \"],[8,[39,0],null,[[\"@route\"],[\"protected\"]],[[\"default\"],[[[[1,\"apps list\"]],[]]]]],[1,\".\\n \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\"]]",
    "moduleName": "dashboard/templates/components/pipeline/destroyed-notice.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});