define("dashboard/components/log-event", ["exports", "@ember/component", "@ember/utils", "@ember/service"], function (_exports, _component, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * {{log-event noun="Deploy Button"}}
   *
   * Use this to track an event when a certain part of a template is rendered.
   * Just drop it into the template in the correct place.
   *
   * @param {string} event - the event name to track
   * @param {object} [props] - optional extra properties to pass with the event
   */
  var _default = _component.default.extend({
    tagName: '',
    analytics: (0, _service.inject)(),

    didInsertElement() {
      this._super(...arguments);

      const noun = this.noun;

      if ((0, _utils.isPresent)(noun)) {
        this.analytics.logEvent(noun, 'Displayed');
      }
    }

  });

  _exports.default = _default;
});