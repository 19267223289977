define("dashboard/helpers/capitalize", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;

  function capitalize(_ref) {
    let [word] = _ref;
    return word.capitalize();
  }

  var _default = (0, _helper.helper)(capitalize);

  _exports.default = _default;
});