define("dashboard/templates/components/app/settings/ssl-domain-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xtQ73uki",
    "block": "[[[10,0],[14,0,\"flex flex-column items-center\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"cert-loading\"],[[\"class\"],[\"h--48 w--48\"]]]],[1,\"\\n  \"],[10,0],[14,0,\"f4 purple mv3\"],[12],[1,\"\\n    Almost done!\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"f5 pb3 lh-copy\"],[12],[1,\"\\n    Which of these already configured domains would you like to associate this SSL certificate with? Please note you can add more domains in the future.\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex items-center w-100\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dark-gray f5 b\"],[12],[1,\"App domains\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"hk-button-group\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"hk-button-sm--secondary\"],[15,\"onClick\",[28,[37,1],[[30,0],\"selectAll\"],null]],[14,4,\"button\"],[12],[1,\"Select all\"],[13],[1,\"\\n      \"],[10,\"button\"],[14,0,\"hk-button-sm--secondary\"],[15,\"onClick\",[28,[37,1],[[30,0],\"deselectAll\"],null]],[14,4,\"button\"],[12],[1,\"None\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"pl0 mv2 w-100 bt b--silver\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"matchingDomains\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"flex items-center pa2 bb b--silver\"],[12],[1,\"\\n        \"],[10,\"input\"],[15,\"checked\",[30,1,[\"checked\"]]],[15,\"onClick\",[28,[37,1],[[30,0],\"addDomainToCert\",[30,1,[\"domain\"]]],null]],[14,0,\"nudge-up--2\"],[14,4,\"checkbox\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"flex-auto pl2\"],[12],[1,[30,1,[\"domain\",\"hostname\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"match\"],false,[\"svg-jar\",\"action\",\"each\",\"-track-array\"]]",
    "moduleName": "dashboard/templates/components/app/settings/ssl-domain-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});