define("dashboard/adapters/shogun/kafka-datastore", ["exports", "dashboard/adapters/shogun"], function (_exports, _shogun) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shogun.default.extend({
    urlForFindRecord(id) {
      return `${this.host}/data/kafka/v0/clusters/${id}`;
    }

  });

  _exports.default = _default;
});