define("dashboard/serializers/repositories-api-commit-status", ["exports", "dashboard/serializers/application", "@ember/utils"], function (_exports, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeQueryResponse(store, model, payload, id, requestType) {
      const statusesPayload = payload.statuses || [];
      const statuses = statusesPayload.map(status => {
        return {
          id: status.id,
          state: status.state.toLowerCase(),
          target_url: status.url,
          description: status.description,
          context: status.context,
          sha: status.sha,
          repo: payload.repo_name_with_owner,
          updated_at: status.created_at
        };
      });
      return this._super(store, model, statuses, id, requestType);
    },

    pushPayload(store, payload) {
      let documentHash = {};
      const type = store.modelFor('repositories-api-commit-status');
      const payloadData = payload['repositories-api-commit-status'];

      if ((0, _utils.typeOf)(payloadData) === 'object') {
        documentHash = this.normalize(type, payloadData);
      } else if (Array.isArray(payloadData)) {
        const ret = new Array(payloadData.length);

        for (let i = 0; i < payloadData.length; i++) {
          const data = payloadData[i];
          ret[i] = this.normalize(type, data).data;
        }

        documentHash.data = ret;
      }

      store.push(documentHash);
    }

  });

  _exports.default = _default;
});