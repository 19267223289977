define("dashboard/serializers/metrics", ["exports", "dashboard/serializers/application", "moment"], function (_exports, _application, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the base metrics-api serializer
   *
   */
  var _default = _application.default.extend({
    normalizeResponse(store, primaryModelClass, payload) {
      this._pairData(payload);

      payload['raw_data'] = payload.data; // data is a reservered attribute in ember-data

      delete payload.data;
      return this._super(...arguments);
    },

    /**
     * converts raw `value` from the flat array of `data`
     * into [`time`, `value`] pairs that makes it a bit easier
     * for our application to consume
     */
    _pairData(payload) {
      const startTime = (0, _moment.default)(payload['start_time']);
      const stepMinutes = payload.step;
      Object.keys(payload.data).forEach(function (key) {
        const data = this.payload.data;
        data[key] = data[key].map(function (datum, index) {
          const time = this.startTime + _moment.default.duration(stepMinutes * index, 'm');

          return [time, datum];
        }, this);
      }, {
        payload,
        startTime,
        stepMinutes
      });
    }

  });

  _exports.default = _default;
});