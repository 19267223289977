define("dashboard/components/production-check-item", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: [':production-check-item', 'check.status'],
    status: (0, _object.computed)('check.status', function () {
      const status = this.get('check.status') || '';
      return status.toUpperCase();
    }),
    labelType: (0, _object.computed)('check.status', function () {
      const status = this.get('check.status');

      switch (status) {
        case 'passed':
          return 'success';

        case 'failed':
          return 'danger';

        case 'warning':
          return 'warning';

        default:
          return 'default';
      }
    }),
    labelClass: (0, _object.computed)('labelType', function () {
      return `label-${this.get('labelType')}`;
    }),
    titleClass: (0, _object.computed)('labelType', function () {
      return `title-${this.get('labelType')}`;
    })
  });

  _exports.default = _default;
});