define("dashboard/mixins/metrics/ruby-charts", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed", "dashboard/utils/metrics/receiving-language-data", "dashboard/utils/metrics/generate-chart-properties"], function (_exports, _mixin, _object, _computed, _receivingLanguageData, _generateChartProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    _receivingRubyData: (0, _receivingLanguageData.default)('Ruby', 'ruby-metrics-set-up'),
    _cannotFetchRubyMetrics: (0, _computed.not)('_receivingRubyData'),
    _hasRubyMetricsEnabled: (0, _computed.and)('app.hasRuntimeMetricsEnabled', 'app.hasRubyMetricsEnabled'),
    needsToSetupRuby: (0, _computed.and)('app.isEligibleForRubyMetrics', '_hasRubyMetricsEnabled', '_cannotFetchRubyMetrics'),
    rubyMetricsAreSetUp: (0, _computed.and)('_hasRubyMetricsEnabled', '_receivingRubyData'),
    rubyHeapObjectsChart: (0, _generateChartProperties.default)('ruby-heap-objects', 'languageMemory'),
    rubyFreeSlotsChart: (0, _generateChartProperties.default)('ruby-free-slots', 'languageMemory'),
    rubyPumaPoolUsageChart: (0, _generateChartProperties.default)('ruby-pool-usage', 'languageMemory'),
    rubyChartsToShow: (0, _object.computed)('rubyFreeSlotsChart', 'rubyHeapObjectsChart', 'rubyPumaPoolUsageChart', 'languageMemory.hasAnyMeasuredDataRubyPoolUsage', function () {
      const charts = [this.rubyHeapObjectsChart, this.rubyFreeSlotsChart];

      if (this.get('languageMemory.hasAnyMeasuredDataRubyPoolUsage')) {
        charts.push(this.rubyPumaPoolUsageChart);
      }

      return charts;
    })
  });

  _exports.default = _default;
});