define("dashboard/routes/team/space", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadcrumbs: (0, _service.inject)(),

    model(params) {
      return this.store.queryRecord('space', {
        name: params.name
      });
    },

    afterModel(model) {
      const {
        team
      } = this.modelFor('team');
      const spaceTeamName = model.get('team.name'); // It's possible to reach this point if the route was manipulated in such a
      // way that the user is trying to load the space apps for a team that does
      // not match the team for the space in the URL but they have access to both teams

      if (spaceTeamName !== team.name) {
        this.replaceWith('team.space.apps', spaceTeamName, model.name);
      }
    },

    renderTemplate(controller, model) {
      this.set('breadcrumbs.trail', {
        space: model,
        team: model.get('team'),
        enterpriseAccount: model.get('team.enterprise')
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav',
        into: 'protected'
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});