define("dashboard/utils/error-container", ["exports", "@ember/array/proxy", "@ember/object", "@ember/object/evented", "dashboard/utils/anchorify"], function (_exports, _proxy, _object, _evented, _anchorify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    gt
  } = _object.computed;
  /* An intelligent container of errors that may or may not be
   * associated with a specific model.
   *
   * this object should never be directly created/initialized
   * and should always be created via the singleton application-wide
   * ErrorMonitor
   */

  var _default = _proxy.default.extend({
    displayError(flashMessageService) {
      // TODO: allow additional message options?
      flashMessageService.danger(this.content, {
        title: this.title
      });
    },

    content: (0, _object.computed)(function () {
      return [];
    }),
    flashable: true,
    notEmpty: gt('length', 0),

    /* The full title for the group of error messages
     * can be directly set via a model's `errorTitle` attribute,
     * inferred from the xhr object, or will fallback to a generic message
     */
    title: (0, _object.computed)('model.errorTitle', 'xhr', 'titleSubject', function () {
      const model = this.model;
      const xhr = this.xhr;

      if (model && model.get('errorTitle')) {
        return model.get('errorTitle');
      } else if (xhr) {
        return this.xhrTitle();
      } else {
        return 'Something went wrong:';
      }
    }),

    /* the "subject" or noun of the error title */
    titleSubject: (0, _object.computed)('model.errorTitleSubject', 'xhr', function () {
      const xhr = this.xhr;
      return this.get('model.errorTitleSubject') || xhr && xhr.errorTitleSubject || 'Item';
    }),

    /* internal method to parse an xhr response and infer
     * a readable error message
     */
    xhrTitle() {
      const xhr = this.xhr;

      if (xhr.errorTitle) {
        return xhr.errorTitle;
      } else {
        const subject = this.titleSubject;
        const verb = this.titleVerb;
        return `${subject} could not be ${verb}:`;
      }
    },

    /* converts HTTP methods into passive verbs */
    titleVerb: (0, _object.computed)('xhr', function () {
      if (this.get('model.errorTitleVerb')) {
        return this.get('model.errorTitleVerb');
      }

      let verb;

      switch (this.get('xhr.method')) {
        case 'GET':
          verb = 'retrieved';
          break;

        case 'POST':
          verb = 'created';
          break;

        case 'PATCH':
        case 'PUT':
          verb = 'updated';
          break;

        case 'DELETE':
          verb = 'removed';
          break;

        default:
          verb = 'modified';
      }

      return verb;
    }),

    /* Once an xhr object is attached, we analyze its contents
     * for errors. Once an xhr object is attached to an instance,
     * all current errors will be wiped and replaced
     */
    xhrWatcher: (0, _evented.on)('init', (0, _object.observer)('xhr', function () {
      const xhr = this.xhr;

      if (!xhr) {
        return;
      }

      let errors = [];
      const response = this.get('xhr.responseJSON');

      if (response) {
        errors = this.parseResponseJSON(response);
      } else {
        errors = this.buildGenericErrors();
      }

      this.set('content', errors);
    })),

    /** the status code of the xhr response
     */
    statusCode: (0, _object.computed)('xhr', function () {
      const xhr = this.xhr;

      if (xhr) {
        return xhr.status;
      }
    }),
    requestId: (0, _object.computed)('xhr', function () {
      const xhr = this.xhr;

      if (xhr && typeof xhr.getResponseHeader === 'function') {
        return xhr.getResponseHeader('Request-Id') || xhr.getResponseHeader('X-Request-Id');
      }
    }),

    /* parses a json-like response looking for the correct "message"
     * to be displayed to the user. If a model is provided, it will call
     * the model's `errorResponseParser` method. This method is useful for
     * models whose error responses are non-standard.
     */
    parseResponseJSON(response) {
      const model = this.model;

      if (model) {
        return model.errorResponseParser(response);
      } else if (response) {
        const flatError = response.message || response.error || response['error_message'];

        if (flatError) {
          return [(0, _anchorify.default)(flatError)];
        } else {
          return response.errors && response.errors.map(err => {
            if (typeof err === 'object' && err.detail) {
              // JSON-API error
              return (0, _anchorify.default)(err.detail);
            } else if (typeof err === 'string') {
              // Regular string error
              return (0, _anchorify.default)(err);
            } else {
              // Unknown format generic error
              return this.buildGenericErrors()[0];
            }
          });
        }
      }
    },

    /*
     * Returns a generic error message including a request ID if available.
     */
    buildGenericErrors() {
      const requestId = this.requestId;
      let message = 'Unknown Error. Please contact support';

      if (requestId) {
        message = `${message} quoting request ID ${requestId}`;
      }

      return [`${message}.`];
    }

  });

  _exports.default = _default;
});