define("dashboard/templates/components/metrics/chart-controls/timezone-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "J0HoyYgk",
    "block": "[[[6,[39,0],null,[[\"value\",\"class\",\"onChange\"],[[30,0,[\"timezone\"]],\"metrics__zone-picker hk-select mb1 w-100\",[28,[37,1],[[30,0],\"changeTimezone\"],null]]],[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"timezones\"]]],null]],null],null,[[[6,[30,1,[\"option\"]],null,[[\"value\"],[[30,2]]],[[\"default\"],[[[[1,\"      \"],[1,[30,2]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]]],[\"xs\",\"tzOption\"],false,[\"x-select\",\"action\",\"each\",\"-track-array\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/timezone-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});