define("dashboard/initializers/custom-inflectors", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function
    /* application */
  initialize() {
    const inflector = _emberInflector.default.inflector;
    inflector.uncountable('user-preferences');
    inflector.uncountable('account-quota');
  }

  var _default = {
    name: 'custom-inflector-rules',
    before: 'ember-cli-mirage',
    initialize
  };
  _exports.default = _default;
});