define("dashboard/templates/components/new/template-config-vars", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GGL5qJpg",
    "block": "[[[10,0],[14,0,\"f5 b dark-gray mb3\"],[12],[1,\"Config Vars\"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"sortedConfigVars\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"mb2\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"f5 b dark-gray mb1\"],[15,\"title\",[30,1,[\"key\"]]],[12],[1,\"\\n      \"],[1,[30,1,[\"key\"]]],[1,\"\\n      \"],[41,[30,1,[\"required\"]],[[[10,1],[14,0,\"br1 ph1 ml1 bg-gray f6 white\"],[12],[1,\"Required\"],[13]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"      \"],[10,0],[14,0,\"f5 gray mb2\"],[12],[1,[30,1,[\"description\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[28,[35,3],null,[[\"model\",\"value\",\"inputClass\",\"readonly\",\"errorIsVisible\",\"placeholder\",\"validatingKey\",\"disabled\"],[[30,1],[30,1,[\"value\"]],\"hk-input w-100\",[30,1,[\"readonly\"]],[30,0,[\"showErrors\"]],[30,1,[\"placeholder\"]],\"value\",[30,0,[\"isDisabledInput\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[10,0],[14,0,\"mv4 bb b--light-silver\"],[12],[13],[1,\"\\n\"]],[\"confVar\"],false,[\"each\",\"-track-array\",\"if\",\"validating-textarea\"]]",
    "moduleName": "dashboard/templates/components/new/template-config-vars.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});