define("dashboard/templates/components/metrics/language-metrics-setup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZV8jHDPu",
    "block": "[[[10,0],[14,0,\"bt b--black-05\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"isBetaChart\",\"devCenterUrl\",\"canCollapseGraph\"],[[30,0,[\"title\"]],[30,0,[\"isBeta\"]],[30,0,[\"devCenterUrl\"]],false]],[[\"default\"],[[[[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"devCenterUrl\"]]],[14,0,\"hk-link f5 flex items-start ma2\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@size\",\"@iconClass\",\"@name\",\"@title\"],[16,\"malibu-fill-gradient-purple mr1\",\"docs-16\",\"DevCenter Documentation\"]],null],[1,\"\\n    \"],[1,[30,0,[\"setupText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"f5 gray mh3 pb2\"],[12],[1,\"\\n    \"],[1,[30,0,[\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"metrics/charts/chart-header/header\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/metrics/language-metrics-setup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});