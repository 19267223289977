define("dashboard/components/build-stream", ["exports", "@ember/utils", "@ember/runloop", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service", "dashboard/models/build-stream", "jquery"], function (_exports, _utils, _runloop, _computed, _component, _object, _service, _buildStream, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  Displays the output from a streaming or completed Build and mimics the
   *  scroll behaviour of a terminal, i.e. it scrolls down as new output arrives
   *  unless the user has manually scrolled up.
   *
   *    {{build-stream streamUrl=aStreamUrl app=anAppInstance build=aBuildInstance}}
   *
   *  @class Dashboard.BuildStreamComponent
   *  @extends Ember.Component
   *  @param {String} streamUrl - the url of a build stream
   *  @param {App} app - an optional app which the stream belongs to
   *  @param {Build} build - an optional build which the stream belongs to
   *  @param {String} size - the component defaults to 160px tall, if this param is 'full' the component will be full height.
   */
  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    classNameBindings: [':build-stream', 'stream.canStream::unsupported-build-stream', 'isFullSize:build-stream-full', 'buildIsExpanded:build-stream-expanded'],
    autoScroll: true,
    isFullSize: (0, _computed.equal)('size', 'full'),
    isNotFullSize: (0, _computed.not)('isFullSize'),
    renderLink: (0, _computed.and)('isNotFullSize', 'app', 'build'),
    isFinished: (0, _computed.alias)('stream.isFinished'),
    isNotFinished: (0, _computed.not)('isFinished'),
    isOpen: (0, _computed.alias)('stream.isOpen'),
    isClosed: (0, _computed.not)('isOpen'),
    isLoading: (0, _computed.and)('isNotFinished', 'isClosed'),
    streamType: 'Build',
    stream: (0, _object.computed)('streamUrl', function () {
      return _buildStream.default.create({
        url: this.streamUrl
      }).maybeOpen();
    }),
    $scrollContainer: (0, _object.computed)(function () {
      return (0, _jquery.default)(this.element).find('.build-stream-output');
    }),
    buildFinishedLabel: (0, _object.computed)('streamType', 'stream.hasContent', function () {
      const streamType = this.streamType;
      return this.get('stream.hasContent') ? `${streamType} finished` : `Unable to display ${streamType.toLowerCase()} output`;
    }),
    // Observers
    updateScroll: (0, _object.observer)('stream.lines.[]', 'autoScroll', function () {
      if (this.autoScroll) {
        (0, _runloop.scheduleOnce)('afterRender', this, this.scrollToBottom);
      }
    }),

    // Methods
    scrollToBottom() {
      const $container = this.$scrollContainer;

      if (!(0, _utils.isEmpty)($container)) {
        $container.scrollTop($container[0].scrollHeight);
      }
    },

    // Setup/teardown
    didInsertElement() {
      this._super(...arguments);

      if (this.toggleExpand) {
        this.analytics.logEvent('Build Modal', 'Opened');
      }
    },

    _previousScrollPosition: 0,

    didRenderElement() {
      this.$scrollContainer.on('scroll', () => {
        const container = this.$scrollContainer[0];
        const lastPosition = this._previousScrollPosition;
        const position = container.scrollTop + container.clientHeight;
        const height = container.scrollHeight;
        this.set('_previousScrollPosition', position);

        if (position < lastPosition) {
          this.set('autoScroll', false);
        } else if (position === height) {
          this.set('autoScroll', true);
        }
      });
    },

    willDestroyElement() {
      const stream = this.stream;

      if (stream && typeof stream.close === 'function') {
        stream.close();
      }

      const $container = this.$scrollContainer;

      if ($container) {
        $container.off('scroll');
      } // wipe reference to cached $


      this.set('$scrollContainer', null);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});