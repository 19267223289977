define("dashboard/templates/components/account/billing/invoice-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Pafpasbf",
    "block": "[[[10,\"td\"],[14,0,\"invoice-title\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"invoice\",\"label\"],[[30,0,[\"invoice\"]],[30,0,[\"displayTitle\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"invoice-total\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"invoice\",\"total\"]]],[[\"unit\"],[\"cents\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"invoice-pay\"],[12],[1,\"\\n\"],[41,[30,0,[\"invoice\",\"isDeclined\"]],[[[1,\"    \"],[6,[39,3],null,[[\"route\"],[[30,0,[\"invoice\",\"payBalanceRoute\"]]]],[[\"default\"],[[[[1,\"Pay Now\"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,\"td\"],[15,0,[29,[\"text-right invoice-state \",[30,0,[\"invoice\",\"stateClass\"]]]]],[12],[1,\"\\n  \"],[1,[30,0,[\"invoice\",\"stateString\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"invoice-link\",\"currency-format\",\"if\",\"link-to\"]]",
    "moduleName": "dashboard/templates/components/account/billing/invoice-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});