define("dashboard/components/account/password-update", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "ember-concurrency"], function (_exports, _component, _service, _object, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    account: (0, _computed.alias)('current.account'),
    password: '',
    newPassword: '',
    newPasswordConfirmation: '',
    clearSuccess: (0, _object.observer)('password', 'newPassword', 'newPasswordConfirmation', function () {
      this.set('passwordDidChange', false);
    }),
    passwordsMatch: (0, _object.computed)('newPassword', 'newPasswordConfirmation', function () {
      return this.newPassword === this.newPasswordConfirmation;
    }),
    hasNewPassword: (0, _computed.notEmpty)('newPassword'),
    hasNewPasswordConfirmation: (0, _computed.notEmpty)('newPasswordConfirmation'),
    passwordsDoNotMatch: (0, _computed.not)('passwordsMatch'),
    showPasswordsDoNotMatch: (0, _computed.and)('passwordsDoNotMatch', 'hasNewPassword', 'hasNewPasswordConfirmation'),
    disabled: (0, _object.computed)('password', 'newPassword', 'newPasswordConfirmation', function () {
      return !this.password || !this.passwordsMatch || !this.newPassword;
    }),
    saveTask: (0, _emberConcurrency.task)(function* (event) {
      // handle form submission
      if (event) {
        event.preventDefault();
      }

      this.set('enteredCompromisedPasswordMessage', null);
      const account = this.account; // attach the password and new password to the model

      account.set('password', this.password);
      account.set('newPassword', this.newPassword);

      try {
        yield account.save();
        this.analytics.logEvent('Account Password', 'Changed');
        this.set('passwordDidChange', true);
      } catch (error) {
        const enteredCompromisedPasswordError = error.errors.find(_ref => {
          let {
            id
          } = _ref;
          return id === 'password_leaked';
        });

        if (enteredCompromisedPasswordError) {
          this.set('enteredCompromisedPasswordMessage', enteredCompromisedPasswordError.detail);
        } else {
          throw error;
        }
      } finally {
        // reset hack on account model so that other errors can come through the
        // dsErrorMonitor if they are not password leak errors.
        account.set('didLeakPassword', false); // remove the new password and password from the model
        // so they don't leak into other requests.

        account.setProperties({
          newPassword: '',
          password: ''
        });
      }
    }).drop()
  });

  _exports.default = _default;
});