define("dashboard/templates/components/account/deauthorize-third-party-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "atOv6sT6",
    "block": "[[[10,\"h5\"],[12],[1,\"Revoke Heroku's access to your GitHub account\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"displayedAppLinks\"]],[[[1,\"  \"],[10,2],[12],[1,\"Any apps that are connected to the GitHub repos below will also stop syncing.\"],[13],[1,\"\\n  \"],[10,\"ul\"],[14,1,\"app-links-table\"],[14,0,\"center-block\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"displayedAppLinks\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"app-repo-name\"],[12],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"repo-16\",\"fill-near-black\",16,\"Repository\"]],null],[1,\" \"],[1,[30,1,[\"repo\"]]],[13],[1,\"\\n\\n        \"],[10,1],[14,0,\"app-syncing-status\"],[12],[1,\"\\n\"],[41,[30,1,[\"autoDeploy\"]],[[[1,\"            auto-deploys \"],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"git-branch-16\",\"fill-black o-60 ml1\",16,\"Branch\"]],null],[1,\" \"],[1,[30,1,[\"branch\"]]],[1,\"\\n\"],[41,[30,1,[\"hasAdditionalLinks\"]],[[[1,\"              and \"],[1,[30,1,[\"additionalLinkCount\"]]],[1,\" \"],[1,[28,[35,4],[[30,1,[\"additionalLinkCount\"]],\"other\",\"others\"],null]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"            manual deploy\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[41,[30,0,[\"renderShowMoreLink\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"show-more\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link\"],[4,[38,5],[[30,0],\"showAll\"],null],[12],[1,\"\\n          Show all \"],[1,[30,0,[\"filteredAppLinks\",\"length\"]]],[1,\" repos\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,2],[12],[1,\"You will be unable to deploy apps using GitHub if you continue.\"],[13],[1,\"\\n\"]],[]]]],[\"appLink\"],false,[\"if\",\"each\",\"-track-array\",\"malibu-icon\",\"pluralize-word\",\"action\"]]",
    "moduleName": "dashboard/templates/components/account/deauthorize-third-party-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});