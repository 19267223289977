define("dashboard/templates/components/space/network/inbound-ruleset-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EQQ5aXOn",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"class\"],[[30,0,[\"rule\",\"source\"]],\"inbound-ruleset-source monospace\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"canEdit\",\"canRemove\",\"model\",\"confirmDeleteAction\"],[false,true,[30,0,[\"rule\"]],[28,[37,2],[[30,0],\"confirmDelete\"],null]]]]],[1,\"\\n\"]],[],false,[\"copyable-input\",\"purple-list-action-cell\",\"action\"]]",
    "moduleName": "dashboard/templates/components/space/network/inbound-ruleset-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});