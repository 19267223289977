define("dashboard/utils/metrics/latest-non-zero-value", ["exports", "@ember/object", "numeral"], function (_exports, _object, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = latestNonZeroValue;

  function latestNonZeroValue(key) {
    let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0,0a';
    let smallNumberFormat = arguments.length > 2 ? arguments[2] : undefined;
    return (0, _object.computed)(`${key}.[]`, function () {
      let data = this.get(key) || [];
      data = [...data].reverseObjects(); // eslint-disable-next-line no-unused-vars

      const latestValidDatum = data.find(_ref => {
        let [time, value] = _ref;
        return value > 0;
      });

      if (latestValidDatum) {
        const value = latestValidDatum[1];

        if (smallNumberFormat && value > 0 && value < 1) {
          return smallNumberFormat;
        } else {
          return format ? (0, _numeral.default)(value).format(format) : value;
        }
      }
    });
  }
});