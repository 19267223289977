define("dashboard/components/flash-status", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['alert', 'flash-status-component'],
    ariaRole: 'alert',
    classNameBindings: ['alertTypeClass'],
    isDismissable: false,
    alertType: 'info',
    alertTypeClass: (0, _object.computed)('alertType', function () {
      return `alert-${this.alertType}`;
    }),

    dismissAction() {}

  });

  _exports.default = _default;
});