define("dashboard/models/formation", ["exports", "@ember/object/computed", "@ember/service", "@ember/object", "@ember-data/model", "dashboard/models/dyno-tier", "dashboard/mixins/formations", "dashboard/mixins/metrics/timeframe-options", "dashboard/utils/metrics/time-calculators", "moment"], function (_exports, _computed, _service, _object, _model, _dynoTier, _formations, _timeframeOptions, _timeCalculators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_formations.default, _timeframeOptions.default, {
    command: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('string'),
    quantity: (0, _model.attr)('number'),
    size: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('string'),
    app: (0, _model.belongsTo)('app'),
    monitors: (0, _model.hasMany)('metrics/monitor'),
    alerts: (0, _model.hasMany)('metrics/alerts'),
    restartEvents: (0, _model.hasMany)('restart-event'),
    scaleEvents: (0, _model.hasMany)('scale-event'),
    browserTime: (0, _service.inject)(),
    isWeb: (0, _computed.equal)('type', 'web'),
    dynoTiers: (0, _object.computed)(function () {
      return _dynoTier.default.all();
    }),
    dynoCanScale: (0, _object.computed)('sizeObject.scale', function () {
      return this.get('sizeObject.scale') !== 1;
    }),
    processTier: (0, _object.computed)('size', function () {
      const tier = _dynoTier.default.findTierBySize(this.size);

      return tier.name;
    }),
    dynoSizes: (0, _object.computed)('dynoTiers.@each.sizes', function () {
      // flattening
      const sizeArrays = this.dynoTiers.mapBy('sizes');
      return sizeArrays.reduce((prev, item) => {
        return prev.concat(item);
      }, []);
    }),

    /**
     * The Size object from the tier mode
     * `size` in the process model is solely the *name* of the size
     */
    sizeObject: (0, _object.computed)('dynoSizes.@each.name', 'size', function () {
      return this.dynoSizes.findBy('size', this.size) || this.dynoSizes.findBy('alias', this.size);
    }),
    originalSizeObject: (0, _object.computed)('size', 'hasDirtyAttributes', function () {
      const hasDirtyAttributes = this.hasDirtyAttributes;
      const originalFormationAttributes = this.originalFormationAttributes;

      if (hasDirtyAttributes && originalFormationAttributes['size']) {
        const originalSize = originalFormationAttributes['size'][0];
        return this.dynoSizes.findBy('size', originalSize) || this.dynoSizes.findBy('alias', originalSize);
      } else {
        return this.sizeObject;
      }
    }),

    /**
     * New licensed org pricing unit.
     * @return Integer
     */
    dynoUnits: (0, _object.computed)('sizeObject.hourMultiplier', 'quantity', function () {
      return this.get('sizeObject.hourMultiplier') * this.quantity;
    }),
    originalDynoUnits: (0, _object.computed)('dynoUnits', 'hasDirtyAttributes', function () {
      const hasDirtyAttributes = this.hasDirtyAttributes;

      if (hasDirtyAttributes) {
        return this.get('originalSizeObject.hourMultiplier') * this.originalQuantity;
      } else {
        return this.dynoUnits;
      }
    }),

    /**
     * The total discounted amount of dyno hours
     * could potentially dip into negative if free hours exceed rawDynoHours.
     */
    dynoHours: (0, _object.computed)('rawDynoHours', 'availableFreeHours', function () {
      const hours = this.rawDynoHours - this.availableFreeHours;
      return hours < 0 ? 0 : hours;
    }),
    price: (0, _object.computed)('sizeObject.price', 'quantity', function () {
      return this.get('sizeObject.price') * this.quantity;
    }),

    get originalFormationAttributes() {
      return this.changedAttributes();
    },

    originalQuantity: (0, _object.computed)('quantity', 'hasDirtyAttributes', function () {
      const hasDirtyAttributes = this.hasDirtyAttributes;
      const originalFormationAttributes = this.originalFormationAttributes;

      if (hasDirtyAttributes && originalFormationAttributes['quantity']) {
        return this.originalFormationAttributes['quantity'].get('firstObject');
      } else {
        return this.quantity;
      }
    }),
    originalPrice: (0, _object.computed)('price', 'hasDirtyAttributes', function () {
      const hasDirtyAttributes = this.hasDirtyAttributes;

      if (hasDirtyAttributes) {
        return this.get('originalSizeObject.price') * this.originalQuantity;
      } else {
        return this.price;
      }
    }),

    // take in a tier name (Eco, Basic, Production) and resize the formation to the
    // default size for that tier
    resizeByTierName(tierName) {
      const tier = _dynoTier.default.find(tierName);

      const size = tier.get('sizes.firstObject.size');
      this.set('size', size);
    },

    queryMetricsData(modelName, timeframeBegin, timeframeEnd) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      const specifiedTimeframe = this.allOptions.find(option => {
        return option.beforeHours === timeframeEnd && option.afterHours === timeframeBegin;
      });
      const timeframe = specifiedTimeframe || this.allOptions.findBy('isDefault', true);
      let step = options.step || timeframe.step;
      let startTime;
      let endTime;

      if (options.round === false) {
        startTime = (0, _moment.default)().subtract(timeframe.afterHours, 'h').utc().format();
        endTime = (0, _moment.default)().utc().format();
      } else {
        startTime = (0, _timeCalculators.calculateStartTime)(this.get('browserTime.currentTime'), timeframe.afterHours, step);
        endTime = (0, _timeCalculators.calculateEndTime)(this.get('browserTime.currentTime'), timeframe.beforeHours, step);
      }

      step = `${step}m`;

      if (options.isPreviousWeek) {
        startTime = (0, _moment.default)(startTime).subtract(7, 'd').valueOf();
        endTime = (0, _moment.default)(endTime).subtract(7, 'd').valueOf();
        step = '120m';
      }

      const query = {
        processType: this.type,
        appId: this.get('app.id'),
        startTime,
        endTime,
        step,
        region: options.region
      };
      let data; // formation-change and restarts use some legacy adapter/serialization that require
      // a query instead of queryRecord. we should resolve this discrepency at some point

      if (options.isLegacy) {
        data = this.store.query(modelName, query);
      } else {
        data = this.store.queryRecord(modelName, query);
      }

      return data;
    }

  });

  _exports.default = _default;
});