define("dashboard/serializers/ssl-endpoint", ["exports", "dashboard/serializers/sni-endpoint"], function (_exports, _sniEndpoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sniEndpoint.default.extend({
    serialize() {
      const payload = this._super(...arguments);

      delete payload.display_name;
      return payload;
    }

  });

  _exports.default = _default;
});