define("dashboard/templates/components/datastore/chart-switcher", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qB/6aGdI",
    "block": "[[[6,[39,0],null,[[\"buttonComponent\",\"buttonComponentOptions\",\"menuClass\",\"buttonClass\"],[\"datastore/chart-switcher-option\",[28,[37,1],null,[[\"name\",\"index\",\"showIcon\"],[[30,0,[\"selectedOption\",\"name\"]],[30,0,[\"index\"]],true]]],[30,0,[\"menuClassNames\"]],[30,0,[\"buttonClassNames\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"chartOptions\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,4],null,[[\"tagName\",\"options\"],[\"li\",[28,[37,1],null,[[\"name\",\"index\"],[[30,1,[\"name\"]],[30,2]]]]]]]],[1,\"\\n\"]],[1,2]],null]],[]]]]]],[\"chartOption\",\"idx\"],false,[\"drop-down\",\"hash\",\"each\",\"-track-array\",\"datastore/chart-switcher-option\"]]",
    "moduleName": "dashboard/templates/components/datastore/chart-switcher.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});