define("dashboard/helpers/pluralize-word", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A helper for pluralizing a value.
   *
   *     {{pluralize-word numericalValue 'foo' 'foos'}}
   *
   * @class Ember.Handlebars.helpers.pluralize-word
   */
  var _default = (0, _helper.helper)(function (_ref) {
    let [value, single, plural] = _ref;
    value = Number(value);
    plural = typeof plural === 'string' ? plural : `${single}s`;

    if (value === 1) {
      return single;
    } else {
      return plural;
    }
  });

  _exports.default = _default;
});