define("dashboard/serializers/buildpack-installation", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash.id = `${resourceHash.buildpack.name}-${resourceHash.ordinal}`;
      resourceHash.name = resourceHash.buildpack.name;
      resourceHash.url = resourceHash.buildpack.url;
      delete resourceHash.buildpack;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});