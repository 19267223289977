define("dashboard/components/identity-provider/certificate-expiry-date", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "moment"], function (_exports, _component, _object, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'span',
    classNameBindings: ['isExpired:red'],
    includeFromNow: false,
    expiresAt: (0, _computed.readOnly)('certificate.expiresAt'),
    isExpired: (0, _computed.readOnly)('certificate.isExpired'),
    expireLabel: (0, _object.computed)('isExpired', function () {
      if (this.isExpired) {
        return 'Expired';
      } else {
        return 'Expires';
      }
    }),
    fromNow: (0, _object.computed)('expiresAt', function () {
      return (0, _moment.default)(this.expiresAt).fromNow();
    })
  });

  _exports.default = _default;
});