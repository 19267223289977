define("dashboard/mixins/adapters/platform-api", ["exports", "rsvp", "@ember/utils", "@ember/object", "@ember/string", "@ember/object/mixin", "ember-inflector", "dashboard/config/environment", "ember-data/adapters/errors"], function (_exports, _rsvp, _utils, _object, _string, _mixin, _emberInflector, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    host: _environment.default.platformApiUrl,

    pathForType(modelName) {
      const dasherized = (0, _string.dasherize)(modelName);
      return (0, _emberInflector.pluralize)(dasherized);
    },

    updateMethod: 'PATCH',

    // This is to make updating records PATCH requests vs PUT.
    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord'); // we support a one-off specification of the `updateMethod` via the
      // built-in `adapterOptions` functionality from ember-data
      // If it's not defined, we fallback to the adapter-wide method

      const updateMethod = (0, _object.get)(snapshot, 'adapterOptions.updateMethod') || this.updateMethod;
      return this.ajax(url, updateMethod, {
        data
      });
    },

    createMethod: 'POST',

    // Allow override of createRecord HTTP verb
    createRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      const url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      serializer.serializeIntoHash(data, type, snapshot, {
        includeId: true
      });
      const createMethod = (0, _object.get)(snapshot, 'adapterOptions.createMethod') || this.createMethod;
      return this.ajax(url, createMethod, {
        data
      });
    },

    // This looks scary. It's not.
    // This is the default implementation from the ED RESTAdapter.
    // 99% of this is unchanged. See comment below for only change.
    ajax(url, type, options) {
      const adapter = this;
      const requestData = {
        url,
        method: type
      };
      const textData = (0, _utils.isPresent)(options) && options.dataType === 'text';
      const hash = adapter.ajaxOptions(url, type, options);

      if (textData) {
        hash.dataType = 'text';
      }

      return new _rsvp.Promise(function (resolve, reject) {
        hash.success = function (payload, textStatus, jqXHR) {
          const response = ajaxSuccessHandler(adapter, payload, jqXHR, requestData);
          resolve(response);
        };

        hash.error = function (jqXHR, textStatus, errorThrown) {
          const error = ajaxErrorHandler(adapter, jqXHR, errorThrown, requestData, this.dsErrorMonitor);
          reject(error);
        };

        adapter._ajax(hash);
      }, `DS: RESTAdapter#ajax ${type} to ${url}`);
    },

    // On invalid response, if there is a dsErrorMonitor,
    // tell the global error-monitor that the model will handle the error,
    // and then capture the xhr on the dsErrorMonitor.
    handleResponse(status, headers, payload, requestData) {
      if (this.isInvalid(status, headers, payload) && this.dsErrorMonitor) {
        requestData.jqXHR.errorIsHandled = true;
        this.dsErrorMonitor.captureInvalidModelXHR(requestData.jqXHR);
      }

      return this._super(...arguments);
    }

  });
  /* eslint-disable prefer-const */
  // copied from https://github.com/emberjs/data/blob/f360683474a9068f4731fb64fac7b5abfe6a1eaf/addon/-private/utils/parse-response-headers.js


  _exports.default = _default;
  const CLRF = '\u000d\u000a';

  function parseResponseHeaders(headersString) {
    let headers = Object.create(null);

    if (!headersString) {
      return headers;
    }

    let headerPairs = headersString.split(CLRF);

    for (let i = 0; i < headerPairs.length; i++) {
      let header = headerPairs[i];
      let j = 0;
      let foundSep = false;

      for (; j < header.length; j++) {
        if (header.charCodeAt(j) === 58
        /* ':' */
        ) {
          foundSep = true;
          break;
        }
      }

      if (foundSep === false) {
        continue;
      }

      let field = header.substring(0, j).trim();
      let value = header.substring(j + 1, header.length).trim();

      if (value) {
        headers[field] = value;
      }
    }

    return headers;
  }

  function ajaxSuccess(adapter, payload, requestData, responseData) {
    let response;

    try {
      response = adapter.handleResponse(responseData.status, responseData.headers, payload, requestData);
    } catch (error) {
      return _rsvp.Promise.reject(error);
    }

    if (response && response.isAdapterError) {
      return _rsvp.Promise.reject(response);
    } else {
      return response;
    }
  }

  function ajaxError(adapter, payload, requestData, responseData) {
    // TODO - log to rollbar?
    // if (DEBUG) {
    //   let message = `The server returned an empty string for ${requestData.method} ${requestData.url}, which cannot be parsed into a valid JSON. Return either null or {}.`;
    //   let validJSONString = !(responseData.textStatus === "parsererror" && payload === "");
    //   warn(message, validJSONString, {
    //     id: 'ds.adapter.returned-empty-string-as-JSON'
    //   });
    // }
    let error;

    if (responseData.errorThrown instanceof Error) {
      error = responseData.errorThrown;
    } else if (responseData.textStatus === 'timeout') {
      error = new _errors.TimeoutError();
    } else if (responseData.textStatus === 'abort' || responseData.status === 0) {
      error = new _errors.AbortError();
    } else {
      try {
        error = adapter.handleResponse(responseData.status, responseData.headers, payload || responseData.errorThrown, requestData);
      } catch (e) {
        error = e;
      }
    }

    return error;
  }

  function ajaxSuccessHandler(adapter, payload, jqXHR, requestData) {
    let responseData = ajaxResponseData(jqXHR);
    return ajaxSuccess(adapter, payload, requestData, responseData);
  }

  function ajaxErrorHandler(adapter, jqXHR, errorThrown, requestData, dsErrorMonitor) {
    let responseData = ajaxResponseData(jqXHR);
    responseData.errorThrown = errorThrown;
    let payload = adapter.parseErrorResponse(jqXHR.responseText); // CHANGE
    // Attach xhr to requestData and continue, passing the XHR object
    // to the handleResponse hook if a dsErrorMonitor is present

    if (dsErrorMonitor) {
      requestData.jqXHR = jqXHR;
    } // END CHANGE


    return ajaxError(adapter, payload, requestData, responseData);
  }

  function ajaxResponseData(jqXHR) {
    return {
      status: jqXHR.status,
      textStatus: jqXHR.textStatus,
      headers: parseResponseHeaders(jqXHR.getAllResponseHeaders())
    };
  }
});