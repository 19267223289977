define("dashboard/mixins/metrics/charts", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/object", "dashboard/mixins/metrics/language-charts", "dashboard/mixins/metrics/private-spaces-charts", "dashboard/utils/metrics/generate-chart-properties", "dashboard/mixins/preferences"], function (_exports, _computed, _mixin, _object, _languageCharts, _privateSpacesCharts, _generateChartProperties, _preferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_languageCharts.default, _privateSpacesCharts.default, _preferences.default, {
    defaultChartOrder: ['memory-usage', 'response-time', 'request-throughput', 'dyno-load', 'memory-paging', 'jvm-heap-memory', 'jvm-nonheap-memory', 'jvm-gc', 'jvm-gc-collections', 'ruby-heap', 'ruby-free-slots', 'go-heap-memory', 'go-stack-memory', 'go-heap-objects', 'go-gc-pause', 'go-gc-collections', 'go-routine-count', 'node-gc-pause', 'node-gc-collections', 'node-eventloop-latency', 'node-eventloop-usage'],
    savedChartOrder: (0, _object.computed)('appPreferences.metricsChartOrder.[]', function () {
      return this.get('appPreferences.metricsChartOrder') || [];
    }),
    chartOrder: (0, _object.computed)('defaultChartOrder.[]', 'savedChartOrder.[]', function () {
      const unsavedOrder = this.get('defaultChartOrder').reject(ele => this.get('savedChartOrder').includes(ele));
      return this.get('savedChartOrder').concat(unsavedOrder);
    }),
    collapsedCharts: (0, _object.computed)('appPreferences.collapsedMetricsCharts.[]', function () {
      return this.get('appPreferences.collapsedMetricsCharts') || [];
    }),
    chartCount: (0, _computed.alias)('chartsToShow.length'),
    oddNumberOfCharts: (0, _object.computed)('chartCount', function () {
      return this.get('chartCount') % 2 === 1;
    }),
    showPlaceholderChart: (0, _computed.and)('oddNumberOfCharts', 'isCompactLayout'),
    numberOfCharts: (0, _object.computed)('chartCount', 'showPlaceholderChart', function () {
      const numCharts = this.get('chartCount');
      return this.get('showPlaceholderChart') ? numCharts + 1 : numCharts;
    }),
    memoryChart: (0, _object.computed)('chartOrder.[]', 'collapsedCharts.[]', 'dynoMemory', 'isShowingLanguageDiscovery', function () {
      return _object.default.create({
        chartKey: 'memory-usage',
        type: 'metrics/charts/visualizations/dyno-memory',
        data: this.get('dynoMemory'),
        order: this.findChartOrder('memory-usage'),
        isCollapsed: this.findChartCollapsedState('memory-usage'),
        shouldShowLanguageMetricsDiscovery: this.get('isShowingLanguageDiscovery')
      });
    }),
    responseTimeChart: (0, _generateChartProperties.default)('response-time', 'latency'),
    throughputChart: (0, _generateChartProperties.default)('request-throughput', 'throughput'),
    dynoLoadChart: (0, _generateChartProperties.default)('dyno-load', 'dynoLoad'),
    memoryPagingChart: (0, _generateChartProperties.default)('memory-paging', 'dynoMemory'),
    chartsToShow: (0, _object.computed)('memoryChart', 'responseTimeChart', 'throughputChart', 'dynoLoadChart', 'memoryPagingChart', 'app.hasMemoryPagingChartEnabled', 'languageChartsToShow', 'languageMetricsAreSetUp', 'process.isWeb', function () {
      const charts = [this.get('memoryChart'), this.get('dynoLoadChart')];

      if (this.get('process.isWeb')) {
        charts.pushObjects([this.get('responseTimeChart'), this.get('throughputChart')]);
      }

      if (this.get('languageMetricsAreSetUp')) {
        charts.pushObjects(this.get('languageChartsToShow'));
      }

      if (this.get('app.hasMemoryPagingChartEnabled')) {
        charts.push(this.get('memoryPagingChart'));
      }

      return charts;
    }),
    chartSortingKey: ['order'],
    orderedChartsToShow: (0, _computed.sort)('chartsToShow', 'chartSortingKey'),

    findChartOrder(chartKey) {
      return this.get('chartOrder').indexOf(chartKey) + 1;
    },

    findChartCollapsedState(chartKey) {
      return this.get('collapsedCharts').includes(chartKey);
    }

  });

  _exports.default = _default;
});