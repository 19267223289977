define("dashboard/adapters/identity-provider", ["exports", "dashboard/adapters/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractErrorsFromMessage = extractErrorsFromMessage;

  var _default = _application.default.extend({
    dsErrorMonitor: (0, _service.inject)(),
    version: '3.identity-providers',

    handleResponse(status, headers, payload, requestData) {
      if (this.isInvalid(status, headers, payload)) {
        const errors = extractErrorsFromMessage(payload.message);
        payload = {
          errors
        };
      }

      const result = this._super(status, headers, payload, requestData);

      const {
        jqXHR
      } = requestData;

      if (jqXHR) {
        jqXHR.errorTitle = 'SSO setup failed';
      }

      return result;
    }

  });

  _exports.default = _default;

  function extractErrorsFromMessage(message) {
    const keys = FIELD_MAP.mapBy('api');
    const m1 = keys.join('|');
    const m2 = keys.map(k => ` ${k}`).join('|');
    const regex = new RegExp(`(${m1}) .+?(?=${m2}|$)`, 'g');
    const messages = message.match(regex) || [];
    const result = messages.map(wrapErrorMessage);
    return result;
  }

  function wrapErrorMessage(message) {
    const keys = FIELD_MAP.mapBy('api');
    const m1 = keys.join('|');
    const regexp = new RegExp(`^${m1}`);
    const [key] = message.match(regexp);
    const field = FIELD_MAP.findBy('api', key);
    const pointer = `/data/attributes/${field.model}`;
    const detail = message.replace(regexp, field.ui);
    return {
      status: '422',
      source: {
        pointer
      },
      attribute: field.model,
      detail
    };
  }

  const FIELD_MAP = [{
    ui: 'Name',
    model: 'name',
    api: 'Name'
  }, {
    ui: 'Slug',
    model: 'slug',
    api: 'Slug'
  }, {
    ui: 'Issuer URL',
    model: 'entityId',
    api: 'Entity'
  }, {
    ui: 'Redirect URL',
    model: 'ssoTargetUrl',
    api: 'Sso target url'
  }, {
    ui: 'Logout URL',
    model: 'sloTargetUrl',
    api: 'Slo target url'
  }, {
    ui: 'Certificate',
    model: 'certificates',
    api: 'Certificate'
  }, {
    ui: 'Enabled',
    model: 'enabled',
    api: 'Enabled'
  }, {
    ui: 'Owner',
    model: 'owner',
    api: 'Owner'
  }];
});