define("dashboard/models/enterprise-account/audit-trail-archive", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "@ember/object", "moment"], function (_exports, _attr, _model, _relationships, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    month: (0, _attr.default)('string'),
    year: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    enterpriseAccount: (0, _relationships.belongsTo)('enterprise-account'),
    displayName: (0, _object.computed)('month', 'year', function () {
      const mmyyyyNow = (0, _moment.default)().format('MMYYYY');
      const mmyyyy = `${this.month}${this.year}`;
      const current = mmyyyy === mmyyyyNow ? ' (Current)' : '';
      return `${(0, _moment.default)(mmyyyy, 'MMYYYY').format('MMMM YYYY')}${current}`;
    })
  });

  _exports.default = _default;
});