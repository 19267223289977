define("dashboard/components/metrics/charts/visualizations/node-gc-collections", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/garbage-collections-chart"], function (_exports, _computed, _garbageCollectionsChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _garbageCollectionsChart.default.extend({
    tagName: 'metrics-node-gc-collections-chart',
    'data-test-target': 'metrics-node-chart',
    language: 'Node.js',
    devCenterName: 'language-runtime-metrics-nodejs#aggregate-garbage-collections',
    testTarget: 'node-gc-collections',
    isBeta: true,
    hasSplitGenerations: true,
    collectionsDataOld: (0, _computed.alias)('data.nodeCollectionsOld'),
    collectionsDataYoung: (0, _computed.alias)('data.nodeCollectionsYoung'),
    collectionsDataTotal: (0, _computed.alias)('data.nodeCollectionsTotal')
  });

  _exports.default = _default;
});