define("dashboard/utils/metrics/time-calculators", ["exports", "@ember/object", "@ember/array", "@ember/utils", "moment"], function (_exports, _object, _array, _utils, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateEndTime = calculateEndTime;
  _exports.calculateNumberOfSteps = calculateNumberOfSteps;
  _exports.calculateStartTime = calculateStartTime;
  _exports.closestRollupTime = closestRollupTime;
  _exports.filterPeriodWithinTimeframe = filterPeriodWithinTimeframe;
  _exports.filterWithinTimeframe = filterWithinTimeframe;

  function closestRollupTime(time) {
    return (0, _object.computed)(time, 'currentStepDuration', function () {
      const rawTime = this.get(time);

      if (rawTime) {
        const rollupDuration = this.currentStepDuration; // the appropriate rollup interval time for the raw time

        const timeInRollup = rawTime - rawTime % rollupDuration;
        return timeInRollup;
      }
    });
  }

  function filterWithinTimeframe(arrayKey, timeKey) {
    return (0, _object.computed)(`${arrayKey}.[]`, 'currentStartTime', 'currentEndTime', function () {
      const array = this.get(arrayKey);
      let filteredArray = array;

      if ((0, _array.isArray)(array) && array.length !== 0) {
        filteredArray = array.filter(element => {
          const time = (0, _moment.default)(element.get(timeKey));
          return (0, _moment.default)(time).isAfter(this.currentStartTime) && (0, _moment.default)(time).isBefore(this.currentEndTime);
        });
      }

      return filteredArray;
    });
  }

  function filterPeriodWithinTimeframe(arrayKey, startedKey, endedKey) {
    return (0, _object.computed)(`${arrayKey}.[]`, 'currentStartTime', 'currentEndTime', function () {
      const items = this.get(arrayKey) || [];
      return items.reject(item => {
        const startsAfterChartEnd = (0, _moment.default)(item.get(startedKey)).isAfter(this.currentEndTime);
        const endsBeforeChartBeginning = (0, _moment.default)(item.get(endedKey)).isBefore(this.currentStartTime);
        const hasEnded = (0, _utils.isPresent)(item.get(endedKey));
        return hasEnded && endsBeforeChartBeginning || startsAfterChartEnd;
      });
    });
  }

  function calculateStartTime(currentTime, timeframeBeginHour) {
    let step = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    const rawTime = currentTime.subtract(timeframeBeginHour, 'hours');

    const stepDuration = _moment.default.duration(step, 'm');

    const offsetFromLastStep = rawTime % stepDuration; // round up on start time

    const nextStepTime = rawTime.subtract(offsetFromLastStep).add(stepDuration);
    return nextStepTime;
  }

  function calculateEndTime(currentTime, timeframeEndHour) {
    let step = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    const rawEndTime = currentTime.subtract(timeframeEndHour, 'hours');

    const stepDuration = _moment.default.duration(step, 'm');

    const offsetFromClosestStep = rawEndTime % stepDuration;
    const closestStep = rawEndTime.subtract(offsetFromClosestStep);
    const isIncompleteStep = closestStep.minutes() === (0, _moment.default)().minutes();
    return isIncompleteStep ? closestStep.subtract(stepDuration) : closestStep;
  }

  function calculateNumberOfSteps(stepDuration, startTime, endTime) {
    const timeDuration = _moment.default.duration((0, _moment.default)(endTime) - (0, _moment.default)(startTime));

    return Math.floor(timeDuration / stepDuration);
  }
});