define("dashboard/routes/pipelines/new", ["exports", "@ember/routing/route", "@ember/service", "rsvp", "dashboard/mixins/document-title", "dashboard/mixins/new-app-route"], function (_exports, _route, _service, _rsvp, _documentTitle, _newAppRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, _newAppRoute.default, {
    fullDocumentTitle: 'Create New Pipeline | Heroku',
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    renderTemplate() {
      this.render('pipelines/new', {
        into: 'protected'
      });
    },

    model(params) {
      const githubUser = this.get('current.account.githubUser').catch(() => null);
      const githubOrgs = this.store.query('kolkrabbi/github-org', {}).catch(() => []);
      const herokuOrgs = this.store.peekAll('team');
      return (0, _rsvp.hash)({
        githubUser,
        githubOrgs,
        herokuOrgs,
        params
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      const orgNameParam = model.params.orgNameParam;
      this.setupControllerQueryParams(orgNameParam);
    },

    activate() {
      this.analytics.logEvent('New Pipeline Page', 'Viewed');
    },

    deactivate() {
      if (!this.didCreate) {
        this.analytics.logEvent('New Pipeline Page', 'Abandoned');
      }

      this.set('didCreate', null);
    },

    actions: {
      onGithubAuth() {
        this.refresh();
      },

      save(pipeline, githubRepository, isGithubAppEnabled) {
        const isPersisted = pipeline.get('isPersisted');
        const promise = isPersisted ? _rsvp.default.resolve(pipeline) : pipeline.save();
        promise.then(resolvedPipeline => resolvedPipeline.saveGithubRepository(githubRepository, isGithubAppEnabled)).then(() => {
          this.set('didCreate', true);
          this.analytics.logEvent('Pipeline', 'Created', {
            status: 'succeeded'
          });
          this.transitionTo('pipelines.pipeline', pipeline.get('id'));
        }).catch(err => {
          this.analytics.logEvent('Pipeline', 'Created', {
            status: 'failed'
          });
          throw err;
        });
      }

    }
  });

  _exports.default = _default;
});