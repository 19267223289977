define("dashboard/templates/components/space/network/peerings-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "K65QaC3s",
    "block": "[[[10,\"h5\"],[12],[1,\"Peerings\"],[13],[1,\"\\n\"],[10,\"table\"],[14,0,\"table table-hover\"],[12],[1,\"\\n  \"],[10,\"thead\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"PCX ID\"],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"VPC ID\"],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"AWS Region\"],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"AWS Acct ID\"],[13],[1,\"\\n      \"],[10,\"th\"],[12],[1,\"CIDR Block\"],[13],[1,\"\\n      \"],[10,\"th\"],[14,0,\"hidden-xs hidden-sm\"],[12],[1,\"Status\"],[13],[1,\"\\n      \"],[10,\"th\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"peerings\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,2],null,[[\"peering\",\"space\"],[[30,1],[30,0,[\"space\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"peering\"],false,[\"each\",\"-track-array\",\"space/network/peerings-list-item\"]]",
    "moduleName": "dashboard/templates/components/space/network/peerings-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});