define("dashboard/components/app/addons-list-item", ["exports", "@ember/object/computed", "@ember/object", "@ember/component", "ember-concurrency", "@ember/service", "dashboard/config/environment"], function (_exports, _computed, _object, _component, _emberConcurrency, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    session: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    item: (0, _computed.alias)('addon'),
    currentApp: (0, _computed.alias)('app'),
    addonServiceName: (0, _computed.alias)('addon.addonService.name'),
    addonServiceDescription: (0, _computed.alias)('addon.addonService.humanName'),
    addonServicePlanDescription: (0, _computed.alias)('addon.addonServicePlan.humanName'),
    planIsEditable: (0, _computed.and)('isEditable', 'addon.planIsEditable'),
    isFreeHerokuData: (0, _computed.alias)('addon.isFreeHerokuData'),
    shouldShowActionsList: true,
    showAddonServiceAndPrice: (0, _computed.reads)('isAddonBilledToCurrentApp'),
    showAttachments: true,
    checkStateInterval: _environment.default.environment === 'test' ? 10 : 1000,
    checkStateDecay: _environment.default.environment === 'test' ? 10 : 5000,
    isAddonBilledToCurrentApp: (0, _object.computed)('app.id', 'addon.billingEntityId', function () {
      if (this.app && this.app.id) {
        return this.app.id === this.addon.billingEntityId || this.app.id === this.addon.get('app.id');
      } else {
        return false;
      }
    }),
    marketplaceUrl: (0, _computed.alias)('addon.addonService.marketplaceUrl'),
    devcenterUrl: (0, _computed.alias)('addon.addonService.devcenterUrl'),
    isDeprecated: (0, _computed.alias)('addon.addonService.isDeprecated'),
    deprecationText: (0, _computed.alias)('addon.addonService.deprecationText'),
    sortedAttachments: (0, _object.computed)('addon.attachments.@each.name', function () {
      return this.addon.attachments.sortBy('name');
    }),
    checkState: (0, _emberConcurrency.task)(function* () {
      const addon = yield this.get('addon');
      const interval = this.checkStateInterval;
      const decay = this.checkStateDecay;

      if (!addon || addon.get('state') !== 'provisioning') {
        return;
      }

      while (addon.get('state') === 'provisioning') {
        yield (0, _emberConcurrency.timeout)(interval);
        yield addon.reload();
      }

      yield (0, _emberConcurrency.timeout)(decay);
    }).restartable().on('didReceiveAttrs')
  });

  _exports.default = _default;
});