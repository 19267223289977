define("dashboard/routes/team/switchable/billing/pay-balance", ["exports", "rsvp", "dashboard/routes/team/base"], function (_exports, _rsvp, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    model() {
      const {
        team
      } = this.modelFor('team');
      return _rsvp.default.hash({
        team
      });
    },

    actions: {
      accountVerified() {
        this.controller.send('pay');
      }

    }
  });

  _exports.default = _default;
});