define("dashboard/services/real-time-updater", ["exports", "@ember/service", "@ember/object", "ember-inflector"], function (_exports, _service, _object, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),

    subscribeToModelUpdate(model) {
      let subResource = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let opts = arguments.length > 2 ? arguments[2] : undefined;
      let customUpdate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      const modelName = subResource ? (0, _emberInflector.singularize)(subResource) : model.constructor.modelName;
      let subscribed = model.get('subscriptions').findBy('subResource', subResource);

      if (!subscribed) {
        subscribed = model.subscribeToUpdates(subResource);
      }

      customUpdate = customUpdate || function () {};

      let defaultUpdate = event => {
        const store = this.store;
        const normalized = store.normalize(modelName, (0, _object.get)(event, 'data'));
        return store.push(normalized);
      };

      if (opts.noDefault) {
        defaultUpdate = function () {};
      }

      const updateFn = function (event) {
        const pushedModel = defaultUpdate(event);

        if (pushedModel && pushedModel.didRealtimeUpdate) {
          pushedModel.didRealtimeUpdate();
        }

        customUpdate(pushedModel);
      };

      subscribed.on(opts.type, updateFn);
    },

    subscribeToModelDestroy(model) {
      let subResource = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let opts = arguments.length > 2 ? arguments[2] : undefined;
      let customDestroy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      let subscribed = model.get('subscriptions').findBy('subResource', subResource);

      if (!subscribed) {
        subscribed = model.subscribeToUpdates(subResource);
      }

      customDestroy = customDestroy || function () {};

      let defaultDelete = event => {
        if (!subResource) {
          model.unloadRecord();
        } else {
          const store = this.store;
          const modelName = (0, _emberInflector.singularize)(subResource);
          const normalized = store.normalize(modelName, (0, _object.get)(event, 'data'));
          const subModel = store.peekRecord(modelName, normalized.data.id);

          if (subModel) {
            subModel.unloadRecord();
          }
        }
      };

      if (opts.noDefault) {
        defaultDelete = function () {};
      }

      const deleteFn = function (event) {
        defaultDelete(event);
        customDestroy();
      };

      subscribed.on(opts.type || 'destroy', deleteFn);
    },

    subscribeToModel(model, subResource, opts, customFn) {
      const subscribeFns = {
        create: this.subscribeToModelUpdate.bind(this),
        update: this.subscribeToModelUpdate.bind(this),
        destroy: this.subscribeToModelDestroy.bind(this)
      };
      const subscribe = subscribeFns[opts.type];
      subscribe(model, subResource, opts, customFn);
    },

    subscribeToModelDefaults(model, subResource) {
      this.subscribeToModel(model, subResource, {
        type: 'update'
      });
      this.subscribeToModel(model, subResource, {
        type: 'destroy'
      });

      if (subResource) {
        this.subscribeToModel(model, subResource, {
          type: 'create'
        });
      }
    },

    unsubscribeFromModel(model, subResource) {
      model.unsubscribeFromUpdates(subResource);
    }

  });

  _exports.default = _default;
});