define("dashboard/utils/metrics/has-non-zero-values", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasNonZeroValues;

  function hasNonZeroValues(rawData) {
    return (0, _object.computed)(`${rawData}.[]`, function () {
      const data = this.get(rawData) || [];
      const values = data.mapBy('1');
      const max = Math.max(...values);
      return max > 0.0;
    });
  }
});