define("dashboard/models/app-setup", ["exports", "@ember-data/model", "dashboard/mixins/app-setup", "@ember/object/computed"], function (_exports, _model, _appSetup, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_appSetup.default, {
    status: (0, _model.attr)('string'),
    failureMessage: (0, _model.attr)('string'),
    postdeploy: (0, _model.attr)(),
    resolvedSuccessUrl: (0, _model.attr)('string'),
    hasPendingStatus: (0, _computed.equal)('status', 'pending')
  });

  _exports.default = _default;
});