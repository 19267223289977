define("dashboard/templates/components/restart-dynos", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qDy7aKZY",
    "block": "[[[6,[39,0],null,[[\"modalTitle\",\"modalActionButtonText\",\"isModalVisible\",\"onConfirm\"],[\"Restart Dynos\",\"Restart all dynos\",[30,0,[\"isRestartConfirmationModalVisible\"]],[28,[37,1],[[30,0],\"restartDynos\"],null]]],[[\"default\"],[[[[1,\"    \"],[10,2],[12],[1,\"This will restart all dyno processes for this application.\"],[13],[1,\"\\n    \"],[10,2],[12],[10,\"b\"],[12],[1,\"This may result in a brief downtime for your application.\"],[13],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"confirm/confirmable-action\",\"action\"]]",
    "moduleName": "dashboard/templates/components/restart-dynos.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});