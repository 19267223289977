define("dashboard/templates/components/stencil/app/activity-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "nyt8AE1c",
    "block": "[[[10,0],[14,0,\"flex items-center mv3\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"stencil\"],[12],[1,\"lorem ipsum lorem\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[100]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[90]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[80]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[70]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[60]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[50]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[40]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[30]]]],[1,\"\\n\"]],[],false,[\"stencil/app/activity-item\"]]",
    "moduleName": "dashboard/templates/components/stencil/app/activity-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});