define("dashboard/validators/braintree-hosted-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateBraintreeHostedField;

  function validateBraintreeHostedField() {
    return (key, newValue) => {
      if (newValue) {
        if (newValue.isValid) {
          return true;
        } else if (newValue.isEmpty) {
          return "can't be blank";
        } else if (!newValue.isValid) {
          return 'Invalid card';
        }
      }

      return "can't be blank";
    };
  }
});