define("dashboard/models/formation-change", ["exports", "@ember/object", "moment"], function (_exports, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A model for the hyperion-based FormationChange event
   *
   * it cannot currently be fetched directly and is instead instantiated
   * from the MetricsAll model
   *
   */
  var _default = _object.default.extend({
    /**
     * parse the time string into integer value
     */
    time: (0, _object.computed)('created_at', function () {
      return (0, _moment.default)(this.created_at).valueOf();
    })
  });

  _exports.default = _default;
});