define("dashboard/components/metrics/charts/visualizations/jvm-gc", ["exports", "dashboard/components/metrics/charts/visualizations/gc-time-chart", "@ember/object/computed"], function (_exports, _gcTimeChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _gcTimeChart.default.extend({
    tagName: 'metrics-jvm-gc-chart',
    'data-test-target': 'metrics-java-chart',
    language: 'JVM',
    devCenterName: 'language-runtime-metrics-jvm#aggregate-time-spent-in-garbage-collection',
    testTarget: 'time-spent-in-gc',
    hasSplitGenerations: true,
    lengthDataYoung: (0, _computed.alias)('data.jvmPauseTimeYoung'),
    lengthDataOld: (0, _computed.alias)('data.jvmPauseTimeOld'),
    lengthDataTotal: (0, _computed.alias)('data.jvmPauseTimeTotal'),
    ratioDataYoung: (0, _computed.alias)('data.jvmPauseTimeYoungRatio'),
    ratioDataOld: (0, _computed.alias)('data.jvmPauseTimeOldRatio'),
    ratioDataTotal: (0, _computed.alias)('data.jvmPauseTimeTotalRatio')
  });

  _exports.default = _default;
});