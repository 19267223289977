define("dashboard/templates/components/assume-identity", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HkHOTB+X",
    "block": "[[[10,0],[14,0,\"form-group\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"id\",\"placeholder\",\"class\"],[[30,0,[\"email\"]],\"email\",\"User's email\",\"form-control\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"id\",\"placeholder\",\"class\"],[[30,0,[\"reason\"]],\"reason\",\"Reason for assuming identity\",\"form-control\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"id\",\"placeholder\",\"class\"],[[30,0,[\"code\"]],\"code\",\"Two-factor code\",\"form-control\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "dashboard/templates/components/assume-identity.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});