define("dashboard/utils/push-deletion", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pushDeletion;

  // notifying the store that a record has been remotely deleted and should be fully removed.
  // taken from https://gist.github.com/runspired/96618af26fb1c687a74eb30bf15e58b6
  function pushDeletion(store, type, id) {
    const record = store.peekRecord(type, id);

    if (record !== null) {
      const relationships = {};
      let hasRelationships = false; // remove the record from all of its relationships.

      record.eachRelationship((name, _ref) => {
        let {
          kind
        } = _ref;
        hasRelationships = true;
        relationships[name] = {
          data: kind === 'hasMany' ? [] : null
        };
      });

      if (hasRelationships) {
        store.push({
          data: {
            type,
            id,
            relationships
          }
        });
      } // finally, unload the record


      (0, _runloop.run)(() => {
        record.unloadRecord();
      });
    }
  }
});