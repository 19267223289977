define("dashboard/templates/components/overview/collaborator-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FSDBfDSU",
    "block": "[[[10,0],[14,0,\"mv2 flex items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"avatar mr2\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"email\",\"size\"],[[30,0,[\"collaborator\",\"value\"]],28]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"user-info\"],[12],[1,\"\\n    \"],[1,[30,0,[\"collaborator\",\"value\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"deploy-16\",16,\"malibu-fill-gradient-gray nudge-up--1 mr1\"]],null],[1,\"\\n    \"],[10,0],[14,0,\"b mr1\"],[12],[1,[30,0,[\"collaborator\",\"items\",\"length\"]]],[1,\" \"],[13],[1,[28,[35,2],[[30,0,[\"collaborator\",\"items\",\"length\"]],\"deploy\",\"deploys\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"gravatar-icon\",\"malibu-icon\",\"pluralize-word\"]]",
    "moduleName": "dashboard/templates/components/overview/collaborator-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});