define("dashboard/templates/components/invoice-graph", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FK4f0nOd",
    "block": "[[[18,2,null],[1,\"\\n\"],[10,0],[14,0,\"bar-chart flex items-end mb5 overflow-hidden\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"points\"]]],null]],null],null,[[[6,[39,3],null,[[\"title\",\"class\"],[[30,1,[\"usageLabel\"]],\"bar-chart__col flex flex-column mr2 items-center flex-auto relative\"]],[[\"default\"],[[[[1,\"        \"],[10,0],[15,0,[29,[\"bar-chart__col__chart bg-gray bg-animate self-stretch br1 br--top mt1 \",[52,[30,1,[\"isOverLimit\"]],\"bar-chart__col__quota--over-limit\"]]]],[15,5,[30,1,[\"calcHeight\"]]],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"f7 cursor-default gray mt2 self-stretch\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[[30,1,[\"date\"]],\"MMM\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,1,[\"quotaLimit\"]],[[[1,\"          \"],[10,0],[15,0,[29,[\"bar-chart__col__quota absolute \",[52,[30,1,[\"isOverLimit\"]],\"bar-chart__col__quota--over-limit\"]]]],[15,5,[30,1,[\"quotaCalcHeight\"]]],[12],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[1]],null],[13],[1,\"\\n\"]],[\"p\",\"&default\"],false,[\"yield\",\"each\",\"-track-array\",\"x-tooltip\",\"if\",\"moment-format\"]]",
    "moduleName": "dashboard/templates/components/invoice-graph.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});