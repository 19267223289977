define("dashboard/templates/components/efp-addons-list-item-plan-description", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8ahpdV/K",
    "block": "[[[41,[30,0,[\"shouldDisplayHobbyDevWarnings\"]],[[[1,\"  \"],[10,1],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@title\"],[[30,0,[\"tooltipTitle\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"warning-badge-16\",16,\"malibu-fill-gradient-orange nudge-up--2\"]],null],[1,\"\\n      \"],[10,1],[14,0,\"fw7 orange ml1 f4\"],[12],[1,\"Hobby Dev\"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[12],[1,\"Hobby Dev\"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[],false,[\"if\",\"x-tooltip\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/efp-addons-list-item-plan-description.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});