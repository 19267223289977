define("dashboard/controllers/app/scheduler", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: {
      jobId: 'job'
    },
    jobId: null,
    job: (0, _object.computed)('jobId', function () {
      switch (this.jobId) {
        case null:
          return null;

        case 'new':
          return this._newJob();

        default:
          return this.jobs.findBy('id', this.jobId);
      }
    }),
    app: (0, _computed.readOnly)('model.app'),
    formations: (0, _computed.readOnly)('model.formations'),
    jobs: (0, _computed.reads)('model.jobs'),
    persistedJobs: (0, _object.computed)('jobs.@each.{id,isDeleted}', function () {
      return this.jobs.filterBy('id').rejectBy('isDeleted');
    }),
    defaultSize: (0, _computed.alias)('formations.firstObject.currentTier.sizes.firstObject.size'),
    state: null,
    // Do not update the list based on this, but real jobs created
    hasJobs: (0, _computed.gt)('app.jobs.length', 0),
    hasPersistedJobs: (0, _computed.notEmpty)('persistedJobs'),
    // `job` represents the job that being edited; if empty, nothing is being edited.
    isShowingJobEditor: (0, _computed.notEmpty)('job'),

    _newJob() {
      const defaultSize = this.get('app.currentTier.sizes.firstObject.size');
      return this.store.createRecord('job', {
        at: 0,
        every: 10,
        dynoSize: defaultSize,
        command: '',
        active: true,
        app: this.get('app')
      });
    },

    actions: {
      createJob() {
        this.set('jobId', 'new');
      },

      editJob(job) {
        this.set('state', 'edit');
        this.set('jobId', job.id);
      },

      deleteJob(job) {
        return job.destroyRecord().catch(() => job.rollbackAttributes());
      },

      async save(job, dismissPanel) {
        await job.save();
        dismissPanel();
      },

      close() {
        this.job.rollbackAttributes();
        this.set('state', null);
        this.set('jobId', null);
      }

    }
  });

  _exports.default = _default;
});