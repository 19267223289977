define("dashboard/components/app/settings/ssl-slide-panel", ["exports", "@ember/component", "@ember/service", "@ember/object", "dashboard/mixins/multi-step-flow"], function (_exports, _component, _service, _object, _multiStepFlow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Step 1. Configure ACM or Manual
  // Step 2. Remove SSL
  // Step 3. Upload SSL Cert
  // Step 4. Assign domains
  // Step 5. Setup complete
  // Step 6. Info screen
  var _default = _component.default.extend(_multiStepFlow.default, {
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    configChoice: 'le',
    // cert deletion UI state
    isShowingConfirm: false,
    confirmText: '',
    primaryDisabled: (0, _object.computed)('confirmText', function () {
      return this.app.name !== this.confirmText;
    }),
    // cert/key upload UI state
    showCertDrop: true,
    showKeyDrop: true,
    shouldShowKeyBox: false,
    filesReceived: false,
    // intermediate state when editing
    tempDisplayName: null,

    init() {
      this._super(...arguments);

      const acm = this.app.acm;

      if (acm) {
        this.configChoice = 'le';
      } else {
        this.configChoice = 'sni';
      }

      this.selectedDomains = [];

      if (this.endpoint) {
        this.tempDisplayName = this.endpoint.displayName || '';
      }
    },

    createEndpoint() {
      const app = this.app;
      let endpoint;

      if (app.shouldUseSslEndpoints) {
        endpoint = this.store.createRecord('ssl-endpoint', {
          app
        });
      } else {
        endpoint = this.store.createRecord('sni-endpoint', {
          app
        });
      }

      this.set('endpoint', endpoint);
    },

    deleteValidationDoesNotMatch: (0, _object.computed)('appName', 'app.name', function () {
      return this.appName !== this.get('app.name');
    }),
    canContinueToNextStep: (0, _object.computed)('currentStep', 'filesReceived', function () {
      // If we're on the cert/key upload page, we want to disable the Next button until the user has
      // actually provided both files
      if (this.currentStep === 2) {
        if (this.filesReceived) {
          return true;
        }

        return false;
      }

      return true;
    }),

    selectConfigOption() {
      switch (this.configChoice) {
        case 'le':
          return this.app.enableACM().then(() => {
            this.analytics.logEvent('ACM', 'Enabled');
            this.loadAllDomains.perform();
            this.app.reloadEndpoints();
            this.closePanel();
          });

        case 'sni':
          if (!this.endpoint) {
            // an existing endpoint was not already passed in
            this.createEndpoint();
          }

          this.transitionToStep(2);
          break;

        case 'disable':
          this.set('isShowingConfirm', true);
          break;
      }
    },

    saveSNI() {
      const endpoint = this.endpoint;
      endpoint.set('displayName', this.tempDisplayName);
      return endpoint.save().then(() => {
        endpoint.logEvent('Saved');
        this.loadAllDomains.perform();

        if (this.app.hasMultipleSniEndpointsEnabled) {
          this.transitionToStep(3);
        } else {
          this.transitionToStep(4);
        }
      }).catch(() => {
        this.endpoint.setProperties({
          certificateChain: null,
          privateKey: null
        });
        this.setProperties({
          filesReceived: false,
          showCertDrop: true,
          showKeyDrop: false,
          shouldShowKeyBox: false
        });
      });
    },

    actions: {
      applyChanges() {
        this.endpoint.set('displayName', this.tempDisplayName);
        return this.endpoint.save().then(() => {
          this.endpoint.logEvent('Saved');
          this.loadAllDomains.perform();
          this.closePanel();
        });
      },

      close() {
        // revert any unsaved changes and/or unload the in-flight record since it wasn't saved
        const endpoint = this.endpoint;

        if (endpoint) {
          if (endpoint.get('isNew')) {
            endpoint.unloadRecord();
          } else {
            endpoint.rollbackAttributes();
          }
        }

        this.set('endpoint', null);
        this.closePanel();
      },

      nextStep() {
        if (this.currentStep === 1) {
          this.selectConfigOption();
          return;
        }

        if (this.currentStep === 2) {
          this.saveSNI();
          return;
        }

        if (this.currentStep === 3) {
          Promise.all(this.selectedDomains.map(domain => domain.save())).then(() => {
            this.set('selectedDomains', []);
            this.transitionToStep(4);
          });
          return;
        }
      },

      showConfirmable() {
        this.set('isShowingConfirm', true);
      },

      dismissConfirm() {
        this.set('isShowingConfirm', false);
      },

      validateDeleteName(value) {
        this.set('appName', value);
      },

      deleteSSL() {
        // if this app has ACM then remove
        if (this.get('app.acm')) {
          return this.app.disableACM().then(() => {
            this.analytics.logEvent('ACM', 'Disabled');
            this.loadAllDomains.perform();
            this.app.reloadEndpoints();
            this.closePanel();
          });
        } else {
          // otherwise remove the SNI endpoint
          const endpoint = this.endpoint;
          endpoint.logEvent('Removed');
          return endpoint.destroyRecord().then(() => {
            this.set('endpoint', null);
            this.loadAllDomains.perform();
            this.closePanel();
          });
        }
      }

    }
  });

  _exports.default = _default;
});