define("dashboard/components/x-pipeline", ["exports", "@ember/component", "@ember/object/computed", "@ember/utils", "@ember/polyfills", "@ember/object", "@ember/service", "@ember/array", "rsvp", "@ember/runloop", "jquery"], function (_exports, _component, _computed, _utils, _polyfills, _object, _service, _array, _rsvp, _runloop, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isTouch
  } = window.Dashboard || {};

  var _default = _component.default.extend({
    classNameBindings: ['hasOverflow:has-overflow'],
    analytics: (0, _service.inject)(),
    githubToken: (0, _service.inject)('github-token'),
    store: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),
    isEditing: false,
    reviewCouplingApps: (0, _computed.readOnly)('pipeline.reviewCouplingApps'),
    developmentCouplingApps: (0, _computed.readOnly)('pipeline.developmentCouplingApps'),
    stagingCouplingApps: (0, _computed.readOnly)('pipeline.stagingCouplingApps'),
    productionCouplingApps: (0, _computed.readOnly)('pipeline.productionCouplingApps'),
    kolkrabbiPullRequests: (0, _computed.readOnly)('pipeline.openPullRequests'),
    openPullRequests: (0, _computed.filterBy)('pipeline.connectedPullRequests', 'isOpen', true),
    pullRequests: (0, _object.computed)('kolkrabbiPullRequests.[]', 'openPullRequests.[]', 'pipeline.isGithubAppEnabled', function () {
      return this.get('pipeline.isGithubAppEnabled') ? this.openPullRequests : this.kolkrabbiPullRequests;
    }),
    isFetchingPullRequests: (0, _computed.readOnly)('pipeline.isFetchingPullRequests'),
    reviewAppParent: (0, _computed.readOnly)('pipeline.reviewAppParent'),
    hasReviewAppsEnabled: (0, _computed.readOnly)('pipeline.hasReviewAppsEnabled'),
    hasAnyReviewApp: (0, _computed.notEmpty)('reviewApps'),
    hasAnyPullRequest: (0, _computed.notEmpty)('prsWithoutReviewApps'),
    hasAnyReviewAppOrPrToShow: (0, _computed.or)('hasAnyReviewApp', 'hasAnyPullRequest'),
    hasGitHubRepo: (0, _computed.bool)('pipeline.isConnectedToRepository'),
    reviewAppsAndPrs: (0, _computed.union)('reviewApps', 'prsWithoutReviewApps'),
    hasMorePullRequests: (0, _object.computed)('pipeline.pullRequests.meta.next', 'pipeline.isGithubAppEnabled', function () {
      return !this.get('pipeline.isGithubAppEnabled') && this.get('pipeline.pullRequests.meta.next');
    }),
    reviewApps: (0, _object.computed)('pipeline.hasNewReviewAppsEnabled', 'reviewCouplingApps.[]', 'pipeline.reviewApps.[]', function () {
      return this.get('pipeline.hasNewReviewAppsEnabled') ? this.get('pipeline.reviewApps') : this.reviewCouplingApps;
    }),
    __pipelineAppsConnectedRepositories__: (0, _computed.mapBy)('pipeline.apps', 'connectedRepository'),
    hasAnyGitHubLink: (0, _object.computed)('pipeline.apps.@each.hasGithubAppLink', 'pipeline.apps.@each.hasImplicitGithubAppLink', '__pipelineAppsConnectedRepositories__.@each.id', function () {
      const pipelineApps = this.get('pipeline.apps') || [];
      const hasGithubAppLink = pipelineApps.any(app => app.get('hasGithubAppLink') || app.get('hasImplicitGithubAppLink') || app.get('connectedRepository.id'));
      return hasGithubAppLink;
    }),
    isShowingReviewColumn: (0, _computed.or)('hasAnyReviewAppOrPrToShow', 'hasAnyGitHubLink', 'hasGitHubRepo'),
    reviewAppBranches: (0, _computed.mapBy)('reviewApps', 'branch'),
    reviewAppNumbers: (0, _computed.mapBy)('reviewApps', 'prNumber'),
    duplicatePrs: (0, _computed.filter)('prsWithoutReviewApps', function (pr) {
      // We are defining a duplicate PR as one that has the same commit sha as another PR
      // and that is *not* the most recent PR.
      // We check to see which PR is the most recent by sorting by PR number and removing the last
      // element of the array.
      const currentDuplicates = this.prsWithoutReviewApps.filterBy('sha', pr.sha).sortBy('prNumberSortKey');
      currentDuplicates.pop();
      return currentDuplicates.includes(pr);
    }),
    prsWithoutReviewApps: (0, _object.computed)('reviewAppNumbers.[]', 'pullRequests.@each.number', function () {
      return this.pullRequests.reject(pr => {
        return this.reviewAppNumbers.includes(pr.get('number'));
      });
    }),

    updateOverflow() {
      const el = this.element;

      if (el && !this.isDestroying && !this.isDestroyed) {
        this.set('hasOverflow', el.offsetWidth < el.scrollWidth);
      }
    },

    loadPullRequests() {
      // `isDestroying` to protect against late observers firing on pipeline model
      if (this.pipeline?.isDestroying) {
        return;
      }

      const pullRequests = this.pullRequests;
      const pipeline = this.pipeline; // if RAs are disabled, unload the pull requests

      if (pipeline.get('hasReviewAppsDisabled')) {
        pipeline.set('pullRequests', []);
      } // Fetch pull requests only when they aren't loaded and review apps are on


      if ((0, _utils.isPresent)(pullRequests) || pipeline.get('hasReviewAppsDisabled')) {
        return;
      }

      const repoName = pipeline.get('reviewAppParent.repo') || pipeline.get('repositoryName');
      pipeline.fetchPullRequests(repoName).catch(() => {});

      if (pipeline.get('hasKolkrabbiReviewAppsEnabled')) {
        pipeline.fetchGithubReviewApps().catch(() => {});
      }
    },

    _reviewAppObserver: (0, _object.observer)('reviewAppParent', 'hasReviewAppsEnabled', function () {
      this.loadPullRequests();
    }),

    _getArchiveLink(ref) {
      const repoNameWithOwner = this.get('pipeline.githubRepository.name');
      const pipelineId = this.get('pipeline.id');
      const hasKolkrabbiGithubRepoLink = !this.get('pipeline.connectedRepository.id') && !!this.get('pipeline.githubRepository.id');
      const isGithubAppEnabled = this.get('pipeline.isGithubAppEnabled');
      const queryPromise = this.store.queryRecord('repositories-api-archive-link', {
        ref,
        repoNameWithOwner,
        pipelineId,
        hasKolkrabbiGithubRepoLink,
        isGithubAppEnabled
      });
      return queryPromise.then(_ref => {
        let {
          archive_link
        } = _ref;
        return archive_link;
      });
    },

    init() {
      this._super(...arguments);

      this.loadPullRequests();
      this.loadBranches();

      if (!this.get('pipeline.isGithubAppEnabled')) {
        this.loadCommitStatuses();
      }
    },

    loadBranches() {
      // `isDestroying` to protect against late observers firing on pipeline model
      if (this.pipeline?.isDestroying) {
        return;
      }

      if (this.get('pipeline.isConnectedToRepository') && this.hasGithubConnection) {
        if (this.get('pipeline.isGithubAppEnabled')) {
          this.set('allBranches', this.get('pipeline.connectedBranches'));
        } else {
          this.fetchBranches(this.get('pipeline.repositoryName')).then(() => this.set('allBranches', this.store.peekAll('github-branch')));
        }
      }
    },

    loadCommitStatuses() {
      if (this.get('pipeline.isConnectedToRepository') && this.hasGithubConnection) {
        this.fetchCommitStatuses(this.get('pipeline')).then(() => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }

          this.set('legacyCommitStatuses', this.store.peekAll('github-commit-status'));
        });
      }
    },

    // Trello style drag to scroll for when the list extends too far horizonatally
    didInsertElement() {
      this._super(...arguments);

      if (isTouch) {
        return;
      }

      const el = (0, _jquery.default)(this.element)[0];
      let lastX;
      let mouseIsDown = false;

      const onMousedown = event => {
        const $el = (0, _jquery.default)(event.target);

        if ($el.is('input') || $el.is('button') || $el.is('a') || $el.is('li') || $el.parents().is('li')) {
          return;
        }

        mouseIsDown = true;
        lastX = event.clientX;
        event.preventDefault();
        event.stopPropagation();
      };

      const onMouseup = () => {
        mouseIsDown = false;
      };

      const onMousemove = event => {
        if (!mouseIsDown) {
          return;
        }

        const {
          clientX
        } = event;
        el.scrollLeft += lastX - clientX;
        lastX = clientX;
      };

      el.addEventListener('mousedown', onMousedown);
      window.addEventListener('mouseup', onMouseup);
      window.addEventListener('mousemove', onMousemove);
      this.on('willDestroyElement', () => {
        el.removeEventListener('mousedown', onMousedown);
        window.removeEventListener('mouseup', onMouseup);
        window.removeEventListener('mousemove', onMousemove);
      });
    },

    didRenderElement() {
      this.updateOverflow();
      const update = this.updateOverflow.bind(this);
      window.addEventListener('resize', update);
      this.on('willDestroyElement', () => {
        window.removeEventListener('resize', update);
      });
    },

    branches: (0, _object.computed)('allBranches.[]', 'pipeline.repo', function () {
      const branches = (0, _array.isArray)(this.allBranches) ? this.allBranches : [];
      return branches.filterBy('repo', this.get('pipeline.repo'));
    }),
    __pipelineAppsDeployments__: (0, _computed.mapBy)('pipeline.apps', 'currentDeployment'),
    pipelineAppCommits: (0, _object.computed)('__pipelineAppsDeployments__.@each.commit', function () {
      return this.__pipelineAppsDeployments__.filter(deployment => (0, _utils.isPresent)(deployment)).map(deployment => deployment.commit);
    }),
    allCommitStatuses: (0, _object.computed)('legacyCommitStatuses.[]', 'reposApiCommitStatuses.[]', 'pipeline.isGithubAppEnabled', function () {
      return this.get('pipeline.isGithubAppEnabled') ? this.reposApiCommitStatuses : this.legacyCommitStatuses;
    }),
    reposApiCommitStatuses: (0, _object.computed)('pipelineAppCommits.[]', function () {
      const pipeline = this.pipeline;
      const commits = this.pipelineAppCommits || [];
      const refs = commits.uniq().compact();

      if ((0, _utils.isPresent)(refs)) {
        return this.store.query('repositories-api-commit-status', {
          pipelineId: pipeline.id,
          refs: refs.join(',')
        });
      } else {
        return new _rsvp.default.Promise(() => {});
      }
    }),
    commitStatuses: (0, _object.computed)('allCommitStatuses.@each.repo', 'pipeline.repo', 'allCommitStatuses.[]', function () {
      const commitStatuses = (0, _array.isArray)(this.allCommitStatuses) ? this.allCommitStatuses : [];
      return commitStatuses.filterBy('repo', this.get('pipeline.repo'));
    }),
    hasGithubConnection: (0, _object.computed)('githubToken.hasToken', 'pipeline.hasGithubAppInstalled', 'pipeline.isGithubAppEnabled', function () {
      if (this.get('pipeline.isGithubAppEnabled')) {
        return this.get('pipeline.hasGithubAppInstalled') || this.get('githubToken.hasToken');
      } else {
        return this.get('githubToken.hasToken');
      }
    }),
    updateBranches: (0, _object.observer)('pipeline.repositoryName', 'pipeline.isConnectedToRepository', 'hasGithubConnection', function () {
      (0, _runloop.once)(this, 'loadBranches');
    }),
    updateCommitStatuses: (0, _object.observer)('pipeline.isConnectedToRepository', 'hasGithubConnection', 'pipeline.apps.[]', 'pipeline.isGithubAppEnabled', function () {
      if (!this.get('pipeline.isGithubAppEnabled')) {
        (0, _runloop.once)(this, 'loadCommitStatuses');
      }
    }),

    // Fetch all branches, making muliple paginated requests if necessary
    fetchBranches(repo) {
      let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      let per_page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
      return this.store.query('github-branch', {
        repo,
        per_page,
        page
      }).then(branches => {
        const next = branches.get('meta.next');

        if (next) {
          return this.fetchBranches(repo, next.page, next.per_page);
        }

        return branches;
      }).catch(() => {// This case happens when we have a token but it has been revoked on
        // GitHub. We can't prevent this happening so quietly catch the error and
        // move on.
      });
    },

    fetchCommitStatuses(pipeline) {
      const repo = pipeline.get('repositoryName');
      const commits = this.pipelineAppCommits || [];
      const appLinks = pipeline.get('githubAppLinks') || [];
      const branches = appLinks.mapBy('branch');
      const refs = branches.concat(commits).uniq().compact();
      return this.store.query('github-commit-status', {
        repo,
        refs
      }).catch(() => {// This case happens when we have a token but it has been revoked on
        // GitHub. We can't prevent this happening so quietly catch the error and
        // move on.
      });
    },

    actions: {
      deleteCoupling(coupling) {
        const pipeline = this.pipeline;
        const couplingLink = coupling.get('githubAppLink.content');
        coupling.destroyRecord().then(() => {
          this.analytics.logEvent('Pipeline Coupling', 'Deleted', {
            status: 'succeeded'
          });

          if (pipeline.get('reviewAppParent') === couplingLink) {
            couplingLink.unloadRecord();
          }
        }).catch(err => {
          this.analytics.logEvent('Pipeline Coupling', 'Deleted', {
            status: 'failed'
          });
          throw err;
        });
      },

      moveCoupling(coupling, newStage) {
        coupling.set('stage', newStage);
        coupling.get('content').save().then(() => {
          this.analytics.logEvent('Pipeline Coupling', 'Changed', {
            status: 'succeeded'
          });
        }).catch(() => {
          coupling.get('content').rollbackAttributes();
          this.analytics.logEvent('Pipeline Coupling', 'Changed', {
            status: 'failed'
          });
        }).finally(() => this.updateOverflow());
      },

      authorizedGitHub() {
        this.authorizedGitHub(...arguments);
      },

      loadMorePullRequests() {
        this.get('pipeline.pullRequests').fetchPage();
      },

      createReviewApp(reviewAppOrPr) {
        const pipeline = this.pipeline;
        const params = reviewAppOrPr.getProperties(['branch', 'sha', 'forkRepoId', 'prNumber']);

        if (!params.sha) {
          params.sha = reviewAppOrPr.get('pullRequest.sha');
        }

        return this._getArchiveLink(params.sha).then(sourceBlobUrl => {
          (0, _polyfills.merge)(params, {
            pipeline,
            sourceBlobUrl
          });
          const reviewApp = this.store.createRecord('review-app', params);
          return reviewApp.save().catch(() => {
            if (reviewApp.get('isError')) {
              reviewApp.destroyRecord();
              return;
            }

            return true;
          });
        });
      }

    }
  });

  _exports.default = _default;
});