define("dashboard/models/log-line", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal,
    alias,
    bool
  } = _object.computed;

  var _default = _object.default.extend({
    isDisconnected: equal('raw', 'Not found'),
    successfullyParsed: bool('lineParts.3'),
    lineParts: (0, _object.computed)('raw', function () {
      return new RegExp(/^([0-9\-T\+\:\.]+) (\S+): (.*)/).exec(this.raw) || [];
    }),
    timestamp: alias('lineParts.1'),
    processIdentifier: alias('lineParts.2'),
    message: alias('lineParts.3'),
    colorClass: (0, _object.computed)('processIdentifier', function () {
      const processIdentifier = this.processIdentifier;

      if (processIdentifier) {
        return this.constructor.colors[this.constructor.colorIndex(processIdentifier)];
      }
    })
  }).reopenClass({
    colors: ['blue', 'orange', 'purple', 'green', 'red', 'yellow'],
    knownProcessIdentifiers: [],

    /**
     * Calculates the index of the color in the color array for the given process identifier.
     */
    colorIndex(processIdentifier) {
      // Try to look up the processIdentifier
      let knownIndex = this.knownProcessIdentifiers.indexOf(processIdentifier); // If it doesn't exist in the array, add it.

      if (knownIndex < 0) {
        this.knownProcessIdentifiers.push(processIdentifier);
        knownIndex = this.knownProcessIdentifiers.length - 1;
      } // Return the array position of the color for this processIdentifier


      return knownIndex % this.colors.length;
    }

  });

  _exports.default = _default;
});