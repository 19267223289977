define("dashboard/routes/team/switchable/settings/pay-balance", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    /*
     * The old dashboard path was /orgs/:name/settings/pay-balance so this
     * redirect keeps that path working.
     */
    beforeModel() {
      this.transitionTo('team.switchable.billing.pay-balance');
    }

  });

  _exports.default = _default;
});