define("dashboard/templates/components/pipeline/review-apps-dropdown/button-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+Z5wL+br",
    "block": "[[[1,\"Review Apps\\n\"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"caret-16\",16,\"fill-gray nudge-up--2\"]],null]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/review-apps-dropdown/button-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});