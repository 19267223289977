define("dashboard/routes/app/deploy/index", ["exports", "dashboard/routes/basic-app", "@ember/utils"], function (_exports, _basicApp, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    model(params, transition) {
      return this.modelFor(this._routeBase(transition));
    },

    redirect(models, transition) {
      const githubIsLinked = (0, _utils.isPresent)(models.githubAppInstallation) || models.app.get('githubAppLink.isLinked');

      const routeBase = this._routeBase(transition);

      if (githubIsLinked) {
        return this.transitionTo(`${routeBase}.github`);
      }

      this.transitionTo(`${routeBase}.heroku-git`);
    },

    _isLinked(auth, link) {
      return auth.get('isLinked') && link.get('isLinked');
    }

  });

  _exports.default = _default;
});