define("dashboard/mixins/metrics/java-charts", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed", "dashboard/utils/metrics/receiving-language-data", "dashboard/utils/metrics/generate-chart-properties"], function (_exports, _mixin, _object, _computed, _receivingLanguageData, _generateChartProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    hasJvmData: (0, _receivingLanguageData.default)('Java', 'java-metrics-set-up'),
    doesNotHaveJvmData: (0, _computed.not)('hasJvmData'),
    jvmMetricsAreSetUp: (0, _computed.and)('hasJvmData', 'app.hasRuntimeMetricsEnabled'),
    jvmMetricsAreSetUpAndNotWorking: (0, _computed.and)('doesNotHaveJvmData', 'app.hasRuntimeMetricsEnabled', 'app.isEligibleForJvmMetrics'),
    jvmHeapMemoryChart: (0, _generateChartProperties.default)('jvm-heap-memory', 'languageMemory'),
    jvmNonheapMemoryChart: (0, _generateChartProperties.default)('jvm-nonheap-memory', 'languageMemory'),
    jvmGcChart: (0, _generateChartProperties.default)('jvm-gc', 'languageMemory'),
    jvmGcCollectionsChart: (0, _generateChartProperties.default)('jvm-gc-collections', 'languageMemory'),
    jvmChartsToShow: (0, _object.computed)('jvmHeapMemoryChart', 'jvmNonheapMemoryChart', 'jvmGcChart', 'jvmGcCollectionsChart', 'jvmMetricsAreSetUp', function () {
      return [this.jvmHeapMemoryChart, this.jvmNonheapMemoryChart, this.jvmGcChart, this.jvmGcCollectionsChart];
    })
  });

  _exports.default = _default;
});