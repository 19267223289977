define("dashboard/adapters/addon-service-plan", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord(id) {
      return `${this.host}/plans/${id}`;
    },

    urlForQuery(query) {
      const path = `${this.host}/addon-services/${query.addonServiceNameOrId}/plans`;
      delete query.addonServiceNameOrId;
      return path;
    }

  });

  _exports.default = _default;
});