define("dashboard/controllers/notifications", ["exports", "@ember/object/computed", "@ember/controller", "@ember/service"], function (_exports, _computed, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    sortingRules: ['createdAt:desc'],
    sortedNotifications: (0, _computed.sort)('model', 'sortingRules'),
    unreadNotifications: (0, _computed.filterBy)('model', 'read', false),
    notifications: (0, _service.inject)(),
    actions: {
      dismissNotifications() {
        this.notifications.markAllUnreadNotificationsAsRead();
      }

    }
  });

  _exports.default = _default;
});