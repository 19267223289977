define("dashboard/models/production-check/dyno-size", ["exports", "dashboard/mixins/production-check", "@ember/object", "@ember/object/computed"], function (_exports, _productionCheck, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    currentTier: (0, _computed.alias)('app.currentTier'),
    productionTierInUse: (0, _computed.or)('currentTier.isProduction', 'currentTier.isPrivate', 'currentTier.isShield'),
    tierName: (0, _object.computed)('currentTier.name', function () {
      return this.get('currentTier.name').capitalize();
    }),
    title: (0, _object.computed)('tierName', function () {
      return `${this.tierName} Dynos`;
    }),
    devCenterURL: 'https://devcenter.heroku.com/articles/dyno-types#available-dyno-types',
    skippedMessage: 'No running dynos.',
    failedMessage: (0, _object.computed)('tierName', 'status', function () {
      return `You're running on ${this.tierName} dynos. Upgrading to Standard tier dynos will provide a higher level of performance and access to important features such as metrics and faster builds. Upgrading to Performance will provide single tenant isolation for highest performance. Visit your app's resources tab to upgrade.`;
    }),
    formationQuantityCounts: _object.computed.mapBy('app.formations', 'quantity'),
    formationQuantity: _object.computed.sum('formationQuantityCounts'),
    status: (0, _object.computed)('formationQuantity', 'productionTierInUse', function () {
      if (this.get('formationQuantity') === 0) {
        return 'skipped';
      } else if (this.get('productionTierInUse')) {
        return 'passed';
      }

      return 'failed';
    })
  });

  _exports.default = _default;
});