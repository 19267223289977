define("dashboard/models/shogun/redis-datastore", ["exports", "@ember-data/model", "@ember/service", "dashboard/mixins/metrics/timeframe-options", "dashboard/utils/metrics/time-calculators"], function (_exports, _model, _service, _timeframeOptions, _timeCalculators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RedisDatastore = (_dec = (0, _model.attr)('date'), (_class = class RedisDatastore extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "browserTime", _descriptor, this);

      _initializerDefineProperty(this, "addonId", _descriptor2, this);

      _initializerDefineProperty(this, "createdAt", _descriptor3, this);

      _initializerDefineProperty(this, "formation", _descriptor4, this);

      _initializerDefineProperty(this, "info", _descriptor5, this);

      _initializerDefineProperty(this, "metaasSource", _descriptor6, this);

      _initializerDefineProperty(this, "name", _descriptor7, this);

      _initializerDefineProperty(this, "plan", _descriptor8, this);

      _initializerDefineProperty(this, "port", _descriptor9, this);

      _initializerDefineProperty(this, "preferNativeTls", _descriptor10, this);

      _initializerDefineProperty(this, "resourceUrl", _descriptor11, this);

      _initializerDefineProperty(this, "version", _descriptor12, this);

      Object.assign(this, _timeframeOptions.timeframeOptions);
    }

    get healthy() {
      return ['available', 'preparing (fork in progress)', 'preparing (upgrade in progress)', 'preparing'].includes(this.info?.status);
    }

    get waiting() {
      return ['preparing (fork in progress)', 'preparing (upgrade in progress)', 'preparing', 'unknown', null, undefined].includes(this.info?.status);
    }

    queryMetricsData(modelName, timeframeBegin, timeframeEnd) {
      const specifiedTimeframe = this.allDatastoreOptions.find(option => {
        return option.beforeHours === timeframeEnd && option.afterHours === timeframeBegin;
      });
      const timeframe = specifiedTimeframe || this.allDatastoreOptions.findBy('isDefault', true);
      const startTime = (0, _timeCalculators.calculateStartTime)(this.get('browserTime.currentTime'), timeframe.afterHours, timeframe.step);
      const endTime = (0, _timeCalculators.calculateEndTime)(this.get('browserTime.currentTime'), timeframe.beforeHours, timeframe.step);
      let step; // 72 Hour and 7 Day timeframe need hour conversions to make metaas happy

      if (timeframe.name === 'past72Hours' || timeframe.name === 'past7days') {
        step = `${timeframe.step / 60}h`;
      } else {
        step = `${timeframe.step}m`;
      }

      const query = {
        startTime,
        endTime,
        step,
        addonId: this.addonId
      };
      return this.store.queryRecord(modelName, query);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "browserTime", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "addonId", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "formation", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "info", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "metaasSource", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "name", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "plan", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "port", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "preferNativeTls", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "resourceUrl", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "version", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RedisDatastore;
});