define("dashboard/templates/components/space/access/member-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GchIjvVa",
    "block": "[[[10,0],[14,0,\"collaborator-edit\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"e-mail-address\"],[12],[1,\"Email Address\"],[13],[1,\"\\n        \"],[1,[28,[35,0],null,[[\"type\",\"inputClass\",\"value\",\"placeholder\",\"model\",\"disabled\",\"validatingKey\",\"errorIsVisible\"],[\"email\",\"form-control new-collaborator-email\",[30,0,[\"item\",\"user\",\"email\"]],\"user@domain.com\",[30,0,[\"item\"]],true,\"email\",[30,0,[\"item\",\"errorIsVisible\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-12\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n          \"],[10,\"label\"],[14,\"for\",\"permissions\"],[12],[1,\"Permissions\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"permissions tag-toggle-list\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"item\",\"toggleablePermissions\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,3],null,[[\"model\",\"isToggleable\",\"selectPermission\",\"deselectPermission\"],[[30,1],true,[28,[37,4],[[30,0],\"selectPermission\"],null],[28,[37,4],[[30,0],\"deselectPermission\"],null]]]]],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"permission\"],false,[\"validating-input\",\"each\",\"-track-array\",\"permission-toggle\",\"action\"]]",
    "moduleName": "dashboard/templates/components/space/access/member-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});