define("dashboard/components/metrics/charts/row-chart-elements/error-indicator-rows", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a wrapper around a group of "heroku errors" e.g. H12, R14, etc.
   * that are to be displayed in the EventsChart
   *
   */
  var _default = _component.default.extend({
    tagName: 'g',
    classNames: ['metrics__events-chart__heroku-errors'],
    errorsWithUrls: (0, _object.computed)(`errors.[]`, function () {
      const errors = this.errors;
      return errors.map(function (error) {
        const anchor = `${error.name.toLowerCase()}-${error.title.dasherize()}`;
        (0, _object.set)(error, 'url', `https://devcenter.heroku.com/articles/error-codes#${anchor}`);
        return error;
      });
    })
  });

  _exports.default = _default;
});