define("dashboard/templates/components/team/usage/export-usage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4kLZhyWu",
    "block": "[[[41,[30,0,[\"canExportUsage\"]],[[[6,[39,1],null,[[\"title\",\"description\"],[\"Download team usage report\",[30,0,[\"teamUsageExportDescription\"]]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"enterpriseAccount\",\"team\"],[[30,0,[\"enterpriseAccount\"]],[30,0,[\"team\"]]]]]],[1,\"\\n\\n    \"],[1,[34,3]],[1,\"\\n\"]],[]]]]]],[]],null]],[],false,[\"if\",\"list-group-item-purple2\",\"enterprise-account/usage-export-options\",\"enterprise-account/usage-disclaimer\"]]",
    "moduleName": "dashboard/templates/components/team/usage/export-usage.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});