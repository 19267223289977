define("dashboard/components/invoice-link", ["exports", "@ember/object/computed", "@ember/component", "@ember/service"], function (_exports, _computed, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    session: (0, _service.inject)(),
    bearerToken: (0, _computed.readOnly)('session.data.authenticated.access_token'),
    tagName: '',
    displayTitle: (0, _computed.or)('label', 'title'),
    invoice: null,
    title: (0, _computed.readOnly)('invoice.title'),
    url: (0, _computed.alias)('invoice.url')
  });

  _exports.default = _default;
});