define("dashboard/models/repositories-api-github-branch", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    pipeline: (0, _model.belongsTo)('pipeline'),
    name: (0, _model.attr)('string'),
    commit: (0, _model.attr)(),
    repo: (0, _object.computed)('commit.url', function () {
      const url = this.get('commit.url');
      return extractRepoFromGithubApiUrl(url) || extractRepoFromGithubPush(url);
    })
  });

  _exports.default = _default;

  function extractRepoFromGithubApiUrl(url) {
    const matches = /api.github.com\/repos\/(.*)\/commits/.exec(url);
    return matches ? matches[1] : null;
  } // for when data gets updated over the websocket
  // events for PushEvent do not use api.github.com and the path is different
  // https://developer.github.com/v3/activity/events/types/#pushevent


  function extractRepoFromGithubPush(url) {
    const matches = /github.com\/(.*)\/(.*)\/commit/.exec(url);

    if (matches) {
      const [, user, repoName] = matches;
      return `${user}/${repoName}`;
    }

    return null;
  }
});