define("dashboard/components/deploy/pipeline-actions", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _component, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    not
  } = _object.computed;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    selectedPipeline: null,
    selectedPipelineStage: 'staging',
    selectedPipelineModel: (0, _object.computed)('selectedPipeline', function () {
      const selectedPipeline = this.selectedPipeline;

      if (!selectedPipeline) {
        return null;
      }

      if (selectedPipeline === 'create') {
        return this.store.createRecord('pipeline');
      } else {
        return selectedPipeline;
      }
    }),
    hasValidPipelineSelection: (0, _object.computed)('selectedPipelineModel', 'selectedPipelineModel.name', function () {
      const selectedPipeline = this.selectedPipeline;
      const selectedPipelineModel = this.selectedPipelineModel;

      if (selectedPipeline && selectedPipeline === 'create') {
        return selectedPipelineModel.get('nameIsValid') && (0, _utils.isPresent)(selectedPipelineModel.get('name'));
      } else {
        return true;
      }
    }),
    hasInvalidPipelineSelection: not('hasValidPipelineSelection'),
    pipelineValidationMessage: (0, _object.computed)('selectedPipelineModel.name', function () {
      return this.get('selectedPipelineModel.validationMessage');
    }),
    selectedPipelineDisplayName: (0, _object.computed)('selectedPipelineModel', 'selectedPipelineModel.name', function () {
      const selectedPipelineModel = this.selectedPipelineModel;

      if ((0, _utils.isPresent)(selectedPipelineModel) && (0, _utils.isPresent)(selectedPipelineModel.get('name')) && selectedPipelineModel.get('nameIsValid')) {
        return selectedPipelineModel.get('name');
      }
    }),
    ownerId: (0, _object.computed)('app.owner.id', function () {
      const app = this.app;
      return app.get('owner.id');
    }),
    ownerType: (0, _object.computed)('ownerId', 'app.isOwnedByOrg', function () {
      return this.get('app.isOwnedByOrg') ? 'team' : 'user';
    }),

    willDestroy() {
      const pipeline = this.selectedPipelineModel;

      if (pipeline && pipeline.get('isNew')) {
        pipeline.unloadRecord();
      }

      this._super(...arguments);
    },

    actions: {
      createPipeline(pipeline) {
        const stage = this.selectedPipelineStage;
        const ownerId = this.ownerId;
        const ownerType = this.ownerType;
        const app = this.app;
        const coupling = this.store.createRecord('pipelineCoupling', {
          pipeline,
          stage,
          app
        });
        pipeline.set('owner', {
          id: ownerId,
          type: ownerType
        });
        pipeline.set('collaboratorsEnabled', true);
        return pipeline.save().then(() => {
          this.analytics.logEvent('Pipeline', 'Created');
          return coupling.save().then(() => {
            this.analytics.logEvent('Pipeline Coupling', 'Created', {
              status: 'succeeded'
            });
            this.coupled(pipeline.get('id'));
          }).catch(err => {
            this.analytics.logEvent('Pipeline Coupling', 'Created', {
              status: 'failed'
            });
            coupling.unloadRecord();
            throw err;
          });
        }).catch(err => {
          this.analytics.logEvent('Pipeline Coupling', 'Created', {
            status: 'failed'
          });
          throw err;
        });
      },

      addToPipeline(pipeline) {
        const app = this.app;
        const stage = this.selectedPipelineStage;
        const coupling = this.store.createRecord('pipelineCoupling', {
          pipeline,
          stage,
          app
        });
        return coupling.save().then(() => {
          this.analytics.logEvent('Pipeline Coupling', 'Created', {
            status: 'succeeded'
          });
          this.coupled(pipeline.get('id'));
        }).catch(err => {
          this.analytics.logEvent('Pipeline Coupling', 'Created', {
            status: 'failed'
          });
          coupling.unloadRecord();
          throw err;
        });
      }

    }
  });

  _exports.default = _default;
});