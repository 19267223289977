define("dashboard/templates/components/invoice-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tak6EcvB",
    "block": "[[[10,\"form\"],[15,\"action\",[29,[[30,0,[\"url\"]]]]],[14,\"method\",\"post\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,3,\"token\"],[15,2,[30,0,[\"bearerToken\"]]],[14,4,\"hidden\"],[12],[13],[1,\"\\n  \"],[10,\"input\"],[15,2,[29,[[30,0,[\"displayTitle\"]]]]],[14,0,\"bn bg-none hk-link underline\"],[14,4,\"submit\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/invoice-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});