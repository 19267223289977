define("dashboard/serializers/shogun/redis-datastore", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RedisDatastoreSerializer extends _application.default {
    extractId(_modelClass, _ref) {
      let {
        addon_id,
        name
      } = _ref;
      return `${addon_id}:${name}`;
    }

    extractAttributes(_modelClass, hash) {
      const data = super.extractAttributes(...arguments);
      const info = hash.info.reduce(toInfoObject, {});
      data.info = info;
      return data;
    }

  }

  _exports.default = RedisDatastoreSerializer;

  function toInfoObject(acc, item) {
    const key = item.name.underscore().camelize();
    acc[key] = item.values[0];
    return acc;
  }
});