define("dashboard/templates/components/app/formations-empty-state-no-processes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "O9LVzTb8",
    "block": "[[[6,[39,0],null,[[\"class\"],[\"mb5\"]],[[\"default\"],[[[[6,[30,1,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"    This app has no process types yet\\n\"]],[]]]]],[6,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"    Add a Procfile to your app in order to define its process types. \"],[10,3],[14,6,\"https://devcenter.heroku.com/articles/procfile\"],[14,0,\"hk-link\"],[14,\"target\",\"_blank\"],[12],[1,\"Learn more\"],[13],[1,\"\\n\"]],[]]]]]],[1]]]]]],[\"well\"],false,[\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/app/formations-empty-state-no-processes.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});