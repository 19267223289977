define("dashboard/controllers/app/activity", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    accountFeatures: (0, _service.inject)(),
    githubLink: (0, _computed.alias)('model.app.githubAppLink.content'),
    hasGithubLink: (0, _computed.or)('model.app.hasConnectedRepository', 'githubLink'),
    hasGithubAppInstalled: (0, _computed.bool)('model.app.githubAppInstallation.content'),
    isBannerVisible: (0, _computed.not)('isBannerHidden'),
    isBannerHidden: (0, _computed.readOnly)('hasGithubAppInstalled'),
    actions: {
      dismiss() {
        this.set('isBannerHidden', true);
      }

    }
  });

  _exports.default = _default;
});