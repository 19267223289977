define("dashboard/serializers/addon-service", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      const id = hash.id;
      hash.links = {
        addonServicePlans: `/addon-services/${id}/plans`
      };
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});