define("dashboard/components/addon-actions", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders action links for a given add-on.
   *
   *     {{addon-actions addon=addon attachment=attachment isOwnerOrAdmin=isOwnerOrAdmin}}
   *
   * Renders:
   *
   *        <li class='addon-action-item'>
   *          <a class='addon-action-link' href="#">Fork</a>
   *        </li>
   *
   *        <li class='addon-action-item'>
   *          <a class='addon-action-link' href="#">Follow</a>
   *        </li>
   *
   * @class Dashboard.AddonActionsComponent
   * @extends Ember.Component
   */
  const {
    notEmpty
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: '',
    hasActions: notEmpty('addonActions'),
    addonActions: (0, _object.computed)('addon.actions[]', 'isOwnerOrAdmin', function () {
      return this.getWithDefault('addon.actions', []).filter(action => {
        return this.isOwnerOrAdmin || !action['requires_owner'];
      });
    })
  });

  _exports.default = _default;
});