define("dashboard/components/metrics/charts/axes-elements/axis-element", ["exports", "@ember/object", "d3", "@ember/component", "jquery"], function (_exports, _object, _d, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the base {{axis-element}} object which should not be used directly, but contains
   * value logic shared between all other types of {{axis-element}}
   *
   */
  var _default = _component.default.extend({
    tagName: 'g',
    classNames: 'axis',
    ticks: 4,
    tickPadding: 3,
    outerTickSize: 6,
    innerTickSize: 6,
    orient: 'top',
    x: 0,
    y: 0,
    attributeBindings: ['transform'],
    transform: (0, _object.computed)('x', 'y', function () {
      const x = this.x;
      const y = this.y;
      return `translate(${x}, ${y})`;
    }),
    duration: (0, _object.computed)('minTime', 'maxTime', function () {
      return this.maxTime - this.minTime;
    }),

    /**
     * a d3 axis object that passes in various attributes
     *
     * see: https://github.com/mbostock/d3/wiki/SVG-Axes
     */
    axisObject: (0, _object.computed)('orient', 'scale', 'numTicks', 'tickValues', 'tickPadding', 'innerTickSize', 'outerTickSize', 'rollupDuration', function () {
      let axisObject;

      switch (this.orient) {
        case 'left':
          axisObject = (0, _d.axisLeft)(this.scale);
          break;

        case 'right':
          axisObject = (0, _d.axisRight)(this.scale);
          break;

        case 'bottom':
          axisObject = (0, _d.axisBottom)(this.scale);
          break;

        default:
          axisObject = (0, _d.axisTop)(this.scale);
      }

      return axisObject.ticks(this.numTicks).tickValues(this.tickValues).tickPadding(this.tickPadding).tickSizeInner(this.innerTickSize).tickSizeOuter(this.outerTickSize).tickFormat(this.formatFn.bind(this));
    }),
    _axisObserver: (0, _object.observer)('axisObject', function () {
      const axis = this.axisObject;
      (0, _d.select)((0, _jquery.default)(this.element)[0]).call(axis);
    }),

    /**
     * calls the d3 axisObject function when needed
     */
    didRenderElement() {
      this.notifyPropertyChange('axisObject'); // trigger observer
    }

  });

  _exports.default = _default;
});