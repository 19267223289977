define("dashboard/templates/components/dyno-tier-picker-item-size", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CPk8J15R",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"dyno-tier-picker-item-header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"dyno-tier-picker-item-header-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dyno-tier-picker-item-size-name\"],[12],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"name\"],[[30,0,[\"size\",\"imgName\"]]]]]],[1,\"\\n      \"],[10,0],[14,0,\"dyno-tier-picker-item-size-name__title\"],[12],[1,[30,0,[\"size\",\"name\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"dyno-tier-picker-item-size-description\"],[12],[1,\"\\n  \"],[1,[30,0,[\"size\",\"description\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isEcoWithoutSubscription\"]],[[[1,\"  \"],[10,0],[14,0,\"dyno-tier-picker-item-size-dev-center-link\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,6,\"https://devcenter.heroku.com/articles/eco-dyno-hours\"],[24,\"tagName\",\"div\"]],null,[[\"default\"],[[[[1,\"\\n      Eco Dyno Hours\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"dyno-tier-icon\",\"if\",\"dev-center-link\"]]",
    "moduleName": "dashboard/templates/components/dyno-tier-picker-item-size.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});