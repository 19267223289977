define("dashboard/templates/components/app/settings/maintenance-mode", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JBPj7/Sl",
    "block": "[[[10,0],[14,0,\"switch-mode items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"switch-mode__toggle\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"onToggle\",\"checked\"],[[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"modalDisplayed\"]]],null],true],null],[30,0,[\"maintenanceValue\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"switch-mode__status\"],[12],[1,\"\\n\"],[41,[30,0,[\"maintenanceValue\"]],[[[1,\"      \"],[10,2],[14,0,\"switch-mode__status__text pt1 blue\"],[12],[1,\"\\n        Maintenance mode is on\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,2],[14,0,\"switch-mode__status__text pt1 gray\"],[12],[1,\"\\n        Maintenance mode is off\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"\\n\"],[6,[39,4],null,[[\"modalTitle\",\"modalActionButtonText\",\"buttonDisabled\",\"isModalVisible\",\"onCancel\",\"onConfirm\"],[\"Maintenance Mode\",[30,0,[\"modalButtonText\"]],false,[30,0,[\"modalDisplayed\"]],[28,[37,1],[[30,0],\"revertMaintenance\"],null],[28,[37,1],[[30,0],\"persistMaintenanceModeToggle\"],null]]],[[\"default\"],[[[[41,[30,0,[\"maintenanceValue\"]],[[[1,\"        Enabling maintenance mode will make your app unavailable.\\n\"]],[]],[[[1,\"        Disabling maintenance mode will make your app available for requests again.\\n\"]],[]]],[1,\"      Are you sure?\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"toggle-switch\",\"action\",\"mut\",\"if\",\"confirm/confirmable-action\"]]",
    "moduleName": "dashboard/templates/components/app/settings/maintenance-mode.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});