define("dashboard/models/event-source", ["exports", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    url: '',
    isOpen: false,
    isClosed: (0, _computed.not)('isOpen'),
    isFinished: false,
    canStream: (0, _computed.notEmpty)('streamingMethod'),
    didInitiateConnection: false,
    connectionDidOpen: false,
    connectionHasNotOpenedYet: (0, _computed.not)('connectionDidOpen'),
    connectionIsPending: (0, _computed.and)('didInitiateConnection', 'connectionHasNotOpenedYet'),
    messages: (0, _object.computed)(function () {
      return [];
    }),

    messageListener(event) {
      this.messages.pushObject({
        id: event.lastEventId,
        message: event.data
      });
    },

    openListener() {
      this.set('isOpen', true); // isOpen can toggle on/off due to network connectivity

      this.set('connectionDidOpen', true); // connectionDidOpen is explicitly the event of initially requesting a connection
    },

    errorListener(event) {
      switch (event.target.readyState) {
        case window.EventSource.CLOSED:
          this.close();
          break;

        case window.EventSource.CONNECTING:
          this.set('isOpen', false);
          break;
      }
    },

    streamingMethod: (0, _object.computed)('hasSSE', 'hasChunked', function () {
      if (this.hasSSE) {
        return this.sse;
      } else if (this.hasChunked) {
        return this.chunked;
      }
    }),

    open() {
      this.set('didInitiateConnection', true);

      if (this.isOpen) {
        return this;
      }

      if (this.isFinished) {
        this.toggleProperty('isFinished');
      }

      if (this.canStream) {
        const source = this.streamingMethod.open.call(this, this.url);
        this.set('source', source);
      }

      return this;
    },

    close(message) {
      this.set('isOpen', false);
      this.set('isFinished', true);
      this.set('closeMessage', message);
      const source = this.source;
      this.set('source', null);

      if (source && this.canStream) {
        this.streamingMethod.close(source);
      }

      return this;
    },

    hasSSE: (0, _object.computed)(function () {
      return (0, _utils.isPresent)(window.EventSource);
    }),
    sse: {
      name: 'Server-Sent Events',

      open(url) {
        const source = new window.EventSource(url);
        source.addEventListener('message', this.messageListener.bind(this));
        source.addEventListener('open', this.openListener.bind(this));
        source.addEventListener('error', this.errorListener.bind(this));
        source.addEventListener('complete', this.close.bind(this));
        return source;
      },

      close(source) {
        source.close();
      }

    },
    hasChunked: (0, _object.computed)(function () {
      return (0, _utils.isPresent)(window.XMLHttpRequest);
    }),
    chunked: {
      name: 'Chunked Encoding',

      open(url) {
        const self = this;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);

        xhr.onprogress = function () {
          self.messageListener({
            data: xhr.responseText
          });
        };

        xhr.send();
      },

      close(source) {
        source.abort();
      }

    }
  });

  _exports.default = _default;
});