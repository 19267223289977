define("dashboard/templates/components/purple-typeahead", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MeofYWuT",
    "block": "[[[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"search-16\",16,\"fill-gray absolute ml2 mt1 nudge-down--3\"]],null],[1,\"\\n\"],[1,[28,[35,1],null,[[\"id\",\"class\",\"placeholder\",\"value\",\"spellcheck\",\"autocomplete\",\"autocorrect\",\"autocapitalize\",\"input\",\"aria-label\"],[[30,0,[\"inputId\"]],\"hk-search-input w-100 filter tt-input\",[30,0,[\"placeholder\"]],[30,0,[\"query\"]],false,\"off\",false,false,[28,[37,2],[[30,0],\"filterResults\"],null],[28,[37,3],[[30,0,[\"label\"]],[30,0,[\"placeholder\"]]],null]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"displayResults\"]],[[[1,\"  \"],[10,0],[14,0,\"tt-menu visible mb4\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tt-dataset\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"matchingItems\"]]],null]],null],null,[[[1,\"        \"],[18,2,[[30,1],[28,[37,2],[[30,0],\"add\"],null]]],[1,\"\\n\"]],[1]],[[[1,\"        \"],[18,3,[[30,0,[\"query\"]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"matchingItem\",\"&default\",\"&else\"],false,[\"malibu-icon\",\"input\",\"action\",\"or\",\"if\",\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/purple-typeahead.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});