define("dashboard/components/app/x-overview", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    hasMetrics: (0, _computed.readOnly)('app.metricsEnabled'),
    tagName: ''
  });

  _exports.default = _default;
});