define("dashboard/adapters/domain", ["exports", "@ember/service", "dashboard/adapters/application", "dashboard/mixins/adapters/range-aware-adapter"], function (_exports, _service, _application, _rangeAwareAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rangeAwareAdapter.default, {
    version: 3,
    range: {
      attribute: 'hostname',
      max: 1000
    },
    dsErrorMonitor: (0, _service.inject)(),

    urlForQuery(query) {
      const appId = query.appId;
      delete query.appId;
      return `${this.host}/apps/${appId}/domains`;
    },

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/domains`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      const domainId = snapshot.record.get('id');
      return `${this.host}/apps/${appId}/domains/${domainId}`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      const domainId = snapshot.record.get('id');
      return `${this.host}/apps/${appId}/domains/${domainId}`;
    }

  });

  _exports.default = _default;
});