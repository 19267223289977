define("dashboard/templates/components/ci/test-module", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xZcIgSDs",
    "block": "[[[10,\"h6\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn-link toggle-output flex items-center outline-0\"],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[4,[38,0],[[30,0],\"showOutput\"],null],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"toggle-16\",16,[28,[37,2],[\"malibu-fill-gradient-dark-gray mr1 \",[52,[51,[30,0,[\"isShowingOutput\"]]],\"rot270\"]],null]]],null],[1,\"\\n    \"],[41,[51,[28,[37,4],[[30,0,[\"index\"]],[27]],null]],[[[10,1],[14,0,\"code\"],[12],[1,[30,0,[\"index\"]]],[1,\":\"],[13],[1,\" \"]],[]],null],[1,[30,0,[\"title\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"isWaiting\"]],[[[1,\"      \"],[1,[30,0,[\"waitingStatus\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isRunning\"]],[[[1,\"      \"],[1,[30,0,[\"runningStatus\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isCancelled\"]],[[[1,\"      \"],[1,[30,0,[\"cancelledStatus\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isSucceeded\"]],[[[1,\"      \"],[1,[30,0,[\"succeededStatus\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isFailed\"]],[[[1,\"      \"],[1,[30,0,[\"failedStatus\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"didNotRun\"]],[[[1,\"      \"],[1,[30,0,[\"didNotRunStatus\"]]],[1,\"\\n    \"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isShowingOutput\"]],[[[1,\"  \"],[1,[28,[35,6],null,[[\"streamUrl\",\"size\",\"streamType\"],[[30,0,[\"streamUrl\"]],[30,0,[\"outputSize\"]],[30,0,[\"title\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"action\",\"malibu-icon\",\"concat\",\"unless\",\"eq\",\"if\",\"build-stream\"]]",
    "moduleName": "dashboard/templates/components/ci/test-module.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});