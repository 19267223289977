define("dashboard/mixins/set-available-free-hours", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/evented"], function (_exports, _mixin, _object, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    setAvailableFreeHours: (0, _evented.on)('init', (0, _object.observer)('formations.@each.dynoUnits', function () {
      let availableFreeHours = this.isEnterpriseOrg ? 0 : 750;
      this.formations.forEach(formation => {
        /**
         * The total pre-discounted amount of dyno hours.
         */
        const rawDynoHours = formation.get('dynoUnits') * this.hoursInMonth;
        formation.set('rawDynoHours', rawDynoHours);
        formation.set('availableFreeHours', availableFreeHours);

        if (availableFreeHours > 0 && rawDynoHours > 0) {
          availableFreeHours -= rawDynoHours;

          if (availableFreeHours < 0) {
            availableFreeHours = 0;
          }
        }
      });
    }))
  });

  _exports.default = _default;
});