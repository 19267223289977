define("dashboard/serializers/test-case", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (hash.test_node) {
        hash.testNode = hash.test_node;
        hash.testNode.type = 'test-node';
      }

      return this._super(typeClass, hash);
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      relationships.testRun = {
        data: {
          id: hash.test_run.id,
          type: 'test-run'
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});