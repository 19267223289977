define("dashboard/templates/team/switchable/billing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9esQrH6v",
    "block": "[[[10,\"ul\"],[14,0,\"list-group list-group-lg\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"description\"],[\"Billing Information\",\"All billable resources used by this team will be charged to this credit card.\"]],[[\"default\"],[[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"paymentMethod\",\"organization\",\"onVerified\"],[[30,0,[\"model\",\"paymentMethod\"]],[30,0,[\"model\",\"team\"]],[28,[37,2],[\"accountVerified\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"model\",\"team\",\"isEnterpriseOrg\"]]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info mt2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"alert-body\"],[12],[1,\"\\n          If you wish to transfer this Team to an Enterprise contract,\\n          \"],[10,3],[14,6,\"https://www.heroku.com/form/contact-sales\"],[14,\"target\",\"_blank\"],[14,0,\"hk-link\"],[12],[1,\"contact our Enterprise Sales team\"],[13],[1,\".\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"currentInvoice\"],[[30,0,[\"currentInvoice\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"id\",\"title\"],[\"history\",\"Invoices\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,5],null,[[\"invoices\"],[[30,0,[\"previousInvoices\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\" \"],[13],[1,\"\\n\"]],[],false,[\"list-group-item-purple2\",\"account/billing/update-credit-card\",\"route-action\",\"unless\",\"account/billing-usage\",\"account/billing-history\"]]",
    "moduleName": "dashboard/templates/team/switchable/billing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});