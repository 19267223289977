define("dashboard/components/enterprise-account/member-manager/toolbar", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    // --------------------------------------------------------------------------
    // Arguments
    current: null,
    enterpriseAccount: null,
    email: null,
    sso: null,
    mfa: null,
    permissionsArray: null,
    setEmailFilter: () => {},
    togglePermissionsFilter: () => {},
    clearPermissionsFilter: () => {},
    setSSOFilter: () => {},
    setMFAFilter: () => {},
    clearAllFilters: () => {},
    // --------------------------------------------------------------------------
    // Computed Properties
    hasFilter: (0, _computed.or)('email', 'permissionsArray.length', 'sso', 'mfa'),
    permissionsButtonContent: (0, _object.computed)('permissionsArray.[]', function () {
      if (this.permissionsArray.length === 0) {
        return 'Permissions';
      }

      if (this.permissionsArray.length === 3) {
        return 'All permissions';
      }

      const perms = this.permissionsArray.invoke('capitalize').join(' and ');
      let noun = 'permission';

      if (this.permissionsArray.length > 1) {
        noun += 's';
      }

      return `${perms} ${noun}`;
    })
  });

  _exports.default = _default;
});