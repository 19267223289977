define("dashboard/components/app/settings/ssl-cert-upload", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // no-op in case this doesn't get passed in
    onFilesReceived() {},

    actions: {
      dropped(property, payload) {
        const reader = new FileReader();
        const isCertificateChain = property === 'certificateChain';

        reader.onload = () => {
          const endpoint = this.endpoint; // set contents of file to property on the sniEndpoint object
          // that was passed as part of the action

          endpoint.set(property, reader.result);
          this.set(isCertificateChain ? 'showCertDrop' : 'showKeyDrop', false);
        };

        try {
          reader.readAsText(payload.files[0]);

          if (isCertificateChain) {
            this.set('shouldShowKeyBox', true);
          } else {
            this.onFilesReceived();
          }
        } catch (e) {
          this.flashMessages.danger('Please paste the contents instead', {
            title: 'File could not be read'
          });
          this.set(isCertificateChain ? 'showCertDrop' : 'showKeyDrop', false);
        }
      },

      pasted(property, e) {
        const payload = e.target.value;
        const isCertificateChain = property === 'certificateChain';
        this.endpoint.set(property, payload);

        if (isCertificateChain) {
          this.set('shouldShowKeyBox', true);
        } else {
          this.onFilesReceived();
        }
      }

    }
  });

  _exports.default = _default;
});