define("dashboard/mixins/tooltipable", ["exports", "@ember/object/mixin", "@ember/object", "jquery"], function (_exports, _mixin, _object, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    /**
     * Options passed to bootstrap's $().tooltip(). Override in
     * consuming classes.
     *
     * @property TooltipOptions
     * @return Object
     */
    tooltipOptions: (0, _object.computed)(function () {
      return {};
    }),
    _dynamicTitleUpdate: (0, _object.observer)('title', function () {
      if (!this.isDestroying || !this.isDestroyed) {
        (0, _jquery.default)(this.element).attr('data-original-title', this.title);
      }
    }),

    /**
     * Setup a bootstrap tooltip when this item is rendered.
     *
     * @method setupTooltip
     */
    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).tooltip(this.tooltipOptions);
    },

    /**
     * Setup a bootstrap tooltip when this item is rendered.
     *
     * @method destroyTooltip
     */
    willDestroyElement() {
      (0, _jquery.default)(this.element).tooltip('destroy');

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});