define("dashboard/templates/components/dyno-upgrade", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "p4NLuPfS",
    "block": "[[[41,[51,[30,0,[\"hideButton\"]]],[[[1,\"  \"],[11,\"button\"],[16,0,[30,0,[\"buttonClassNames\"]]],[16,\"title\",[30,0,[\"buttonTitle\"]]],[24,4,\"button\"],[4,[38,1],[[30,0],\"toggleModal\"],null],[12],[1,\"\\n    \"],[1,[30,0,[\"buttonText\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isOpened\"]],[[[1,\"\\n\"],[6,[39,3],null,[[\"onClose\",\"cancelButtonText\",\"showHeader\",\"hasCancelButton\",\"cancelButtonClassNames\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"containerClass\"],[[28,[37,1],[[30,0],\"toggleModal\"],null],\"No, thanks\",false,true,\"btn btn-default\",[28,[37,1],[[30,0],\"upgradeDyno\"],null],[30,0,[\"primaryActionTitle\"]],\"btn btn-primary\",\"dyno-upgrade-modal\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,[28,[35,4],[[30,0,[\"svgFileName\"]]],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"mv2 dark-gray\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"f3 b\"],[12],[1,[30,0,[\"name\"]]],[13],[1,\"\\n      \"],[10,0],[12],[1,[30,0,[\"summary\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"gray\"],[12],[1,\"\\n      \"],[1,[30,0,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"gray\"],[12],[1,\"\\n      Learn more about\\n      \"],[10,3],[14,\"target\",\"_blank\"],[14,6,\"https://www.heroku.com/pricing\"],[14,0,\"hk-link\"],[12],[1,\"Dyno Pricing\"],[13],[1,\" and\\n      \"],[10,3],[14,\"target\",\"_blank\"],[15,6,[30,0,[\"devCenterUrl\"]]],[14,0,\"hk-link\"],[12],[1,[30,0,[\"devCenterName\"]]],[13],[1,\".\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"action\",\"if\",\"hk-modal\",\"svg-jar\"]]",
    "moduleName": "dashboard/templates/components/dyno-upgrade.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});