define("dashboard/controllers/app/deploy", ["exports", "@ember/object", "@ember/controller", "@ember/object/computed", "@ember/utils", "@ember/service"], function (_exports, _object, _controller, _computed, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    accountFeatures: (0, _service.inject)(),
    app: (0, _computed.alias)('model.app'),
    coupling: (0, _computed.alias)('model.coupling'),
    githubAuth: (0, _computed.alias)('model.githubAuth'),
    githubAppLink: (0, _computed.alias)('app.githubAppLink'),
    isLinkedToGithub: (0, _object.computed)('githubAuth.isLinked', 'githubAppLink.isLinked', 'app.connectedRepository.content', function () {
      if (this.get('model.app.team.isGithubAppEnabled')) {
        return (0, _utils.isPresent)(this.app.get('connectedRepository.content'));
      } else {
        return this.githubAuth.get('isLinked') && this.githubAppLink.get('isLinked');
      }
    }),
    githubSubText: (0, _object.computed)('isLinkedToGithub', 'isLinkedToGithubViaInstallation', function () {
      return this.isLinkedToGithub ? 'Connected' : 'Connect to GitHub';
    }),
    canAddAppToPipeline: (0, _computed.alias)('model.appPermissions.canAddToPipeline')
  });

  _exports.default = _default;
});