define("dashboard/templates/components/metrics/charts/chart-header/chart-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DLeIxIBD",
    "block": "[[[41,[30,0,[\"hasSufficientData\"]],[[[1,\"  \"],[10,0],[14,0,\"metrics__summary-row\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"chartSummaryItems\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"title\",\"label\",\"primaryValue\",\"primaryUnit\",\"secondaryValue\",\"secondaryUnit\",\"warningLevel\",\"criticalLevel\",\"numberFormat\",\"testTarget\",\"routingHealth\",\"summaryUnavailable\"],[[30,1,[\"title\"]],[30,1,[\"label\"]],[30,1,[\"primaryValue\"]],[30,0,[\"primaryUnit\"]],[30,1,[\"secondaryValue\"]],[30,0,[\"secondaryUnit\"]],[30,0,[\"warningLevel\"]],[30,0,[\"criticalLevel\"]],[30,0,[\"numberFormat\"]],[30,1,[\"testTarget\"]],[30,1,[\"health\"]],[30,0,[\"summaryUnavailable\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"item\"],false,[\"if\",\"each\",\"-track-array\",\"metrics/charts/chart-header/chart-summary-row-item\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/chart-header/chart-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});