define("dashboard/components/app/settings/config-vars", ["exports", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['config-vars'],
    analytics: (0, _service.inject)(),
    cannotRevealConfigVars: (0, _computed.not)('canRevealConfigVars'),
    listIsVisible: false,
    sortedConfigVars: (0, _computed.alias)('configVars.listOfVariables'),
    actions: {
      revealConfigVars() {
        this.analytics.logEvent('Config Vars', 'Revealed');
        return this.loadConfigVars().then(() => {
          this.set('listIsVisible', true);
        });
      },

      hideConfigVars() {
        this.analytics.logEvent('Config Vars', 'Hidden');
        this.set('listIsVisible', false);
      }

    }
  });

  _exports.default = _default;
});