define("dashboard/routes/app/datastore/durability", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _route.default {
    async model() {
      const {
        datastore
      } = this.modelFor('app.datastore');
      return {
        datastore
      };
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.get('reloadBackups').perform();
    }

    deactivate() {
      const controller = this.controllerFor('app.datastore.durability');
      controller.reset();
    }

  }

  _exports.default = _default;
});