define("dashboard/components/app/web-hook-deliveries/list", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'webhook-deliveries-list',
    classNames: ['web-hook-deliveries-list']
  });

  _exports.default = _default;
});