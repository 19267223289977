define("dashboard/templates/components/account/default-organization", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rA0XwdrE",
    "block": "[[[10,\"form\"],[14,0,\"form edit-first flex items-center\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"value\",\"class\",\"disabled\",\"onChange\"],[[30,0,[\"defaultOrganizationName\"]],\"form-control pull-left\",[30,0,[\"isSaving\"]],[28,[37,1],[[30,0],\"save\"],null]]],[[\"default\"],[[[[1,\"    \"],[6,[30,1,[\"option\"]],null,[[\"value\"],[null]],[[\"default\"],[[[[1,\"Personal apps\"]],[]]]]],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"organizations\"]]],null]],null],null,[[[6,[30,1,[\"option\"]],null,[[\"value\"],[[30,2,[\"name\"]]]],[[\"default\"],[[[[1,\"        \"],[1,[30,2,[\"name\"]]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]],[41,[30,0,[\"isSaving\"]],[[[1,\"    \"],[1,[28,[35,5],null,[[\"class\"],[\"ml4\"]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"xs\",\"organization\"],false,[\"x-select\",\"action\",\"each\",\"-track-array\",\"if\",\"malibu-loading-spinner\"]]",
    "moduleName": "dashboard/templates/components/account/default-organization.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});