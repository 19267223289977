define("dashboard/controllers/app/webhook-deliveries", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    filterBy: null,
    deliveries: (0, _computed.alias)('model.deliveries'),
    wehookStatuses: ['pending', 'scheduled', 'retrying', 'failed', 'succeeded'],
    filterToggleText: (0, _object.computed)('filterBy', 'wehookStatuses.[]', function () {
      // relies on wehookStatuses.[] && filterBy
      if (this._isValidFilter()) {
        return this.filterBy;
      } else {
        return 'All Statuses';
      }
    }),
    filteredDeliveries: (0, _object.computed)('deliveries.[]', 'filterBy', 'wehookStatuses.[]', function () {
      const deliveries = this.deliveries;
      const filterBy = this.filterBy; // relies on wehookStatuses.[] && filterBy

      if (this._isValidFilter()) {
        return deliveries.filter(delivery => {
          // if the delivery has a status, filter based on that
          if (delivery.get('status') !== null) {
            return delivery.get('status') === filterBy;
          } else {
            // otherwise return it if we are filtering on items with no status
            return filterBy === 'none';
          }
        });
      } else {
        return deliveries;
      }
    }),

    _isValidFilter() {
      const filterBy = this.filterBy;
      const wehookStatuses = this.wehookStatuses;
      return filterBy && wehookStatuses.includes(filterBy);
    }

  });

  _exports.default = _default;
});