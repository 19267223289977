define("dashboard/components/app-list-pipeline-item", ["exports", "@ember/routing/link-component", "@ember/object", "dashboard/helpers/array-group-by", "dashboard/utils/pluralize"], function (_exports, _linkComponent, _object, _arrayGroupBy, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal,
    filter,
    not
  } = _object.computed;

  var _default = _linkComponent.default.extend({
    'data-test-target': 'app-list-pipeline-item',
    classNames: ['link', 'flex', 'ph4', 'bb', 'b--light-silver', 'cursor-hand', 'hover-bg-near-white', 'dark-gray', 'bg-white'],
    showFavoriteItem: true,
    appsWithDeprecatedStack: filter('pipeline.apps', function (app) {
      return app.get('showStackDeprecationWarning');
    }),
    appsWithOldestDeprecatedStack: (0, _object.computed)('appsWithDeprecatedStack.@each.stack.name', function () {
      // The groups are sorted by stack name, so that older stacks are listed first.
      return (0, _arrayGroupBy.default)(this.appsWithDeprecatedStack, 'stack.name').sortBy('value')[0].items;
    }),
    hasNoAppsWithDeprecatedStack: equal('appsWithDeprecatedStack.length', 0),
    hasAppsWithDeprecatedStack: not('hasNoAppsWithDeprecatedStack'),
    oldestStackDeprecation: (0, _object.computed)('appsWithOldestDeprecatedStack.[]', function () {
      return this.appsWithOldestDeprecatedStack[0].get('stackDeprecation');
    }),
    tooltipTitle: (0, _object.computed)('appsWithOldestDeprecatedStack.length', 'oldestStackDeprecation', function () {
      const count = this.appsWithOldestDeprecatedStack.length;
      return `This pipeline has ${count} ${(0, _pluralize.default)(count, 'app', 'apps')} using the ${this.oldestStackDeprecation.stackStatus} ${this.oldestStackDeprecation.name} stack`;
    }),
    hasAppsWithFreeDynos: (0, _object.computed)('pipeline.apps.[]', function () {
      return this.pipeline.apps.any(app => app.get('hasFreeDynos'));
    }),
    hasAppsWithFreeData: (0, _object.computed)('pipeline.apps.[]', function () {
      return this.pipeline.apps.any(app => app.get('hasFreeHerokuDataAddons'));
    }),
    isEnterpriseTeam: (0, _object.computed)('pipeline.apps.[]', function () {
      return this.pipeline.apps.any(app => app.get('team.isEnterpriseTeam'));
    }),

    // set the link-to params so they don't need to be passed in with {{apps-list-item}}
    didReceiveAttrs() {
      this.set('params', [null, 'pipelines.pipeline', this.get('pipeline.id')]);

      this._super();
    }

  });

  _exports.default = _default;
});