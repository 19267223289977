define("dashboard/components/loading-state", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    loadingState: (0, _service.inject)()
  });

  _exports.default = _default;
});