define("dashboard/templates/components/account/billing-history", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "b3qgb2Yy",
    "block": "[[[41,[30,0,[\"invoices\"]],[[[1,\"\\n  \"],[1,[28,[35,1],null,[[\"data\",\"attributeToPlot\"],[[30,0,[\"invoices\"]],\"total\"]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"group-header\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Invoices\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"table-responsive invoices\"],[12],[1,\"\\n    \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"firstGroup\"]]],null]],null],null,[[[1,\"          \"],[1,[28,[35,4],null,[[\"invoice\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"allShown\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"remainingGroup\"]]],null]],null],null,[[[1,\"            \"],[1,[28,[35,4],null,[[\"invoice\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"truncated\"]],[[[41,[30,0,[\"allShown\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-default btn-xs show-less\"],[4,[38,5],[[30,0],\"showLess\"],null],[12],[1,\"Show less\"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-default btn-xs show-more\"],[4,[38,5],[[30,0],\"showAll\"],null],[12],[1,\"Show more\"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"  \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]]]],[\"invoice\",\"invoice\"],false,[\"if\",\"invoice-graph\",\"each\",\"-track-array\",\"account/billing/invoice-row\",\"action\",\"empty/billing-historical\"]]",
    "moduleName": "dashboard/templates/components/account/billing-history.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});