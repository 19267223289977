define("dashboard/models/review-app-config", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _model, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    automaticReviewApps: (0, _model.attr)('boolean'),
    destroyStaleApps: (0, _model.attr)('boolean'),
    repoName: (0, _model.attr)('string'),
    staleDays: (0, _model.attr)('number'),
    waitForCi: (0, _model.attr)('boolean'),
    baseName: (0, _model.attr)('string'),
    // if empty/null/falsy: random, specified value == "predictable" value
    deployTarget: (0, _model.belongsTo)('deploy-target', {
      polymorphic: true,
      inverse: null
    }),
    pipeline: (0, _model.belongsTo)('pipeline'),
    hasAutomaticReviewApps: (0, _computed.alias)('automaticReviewApps'),
    targetIsSpace: (0, _computed.equal)('deployTarget.modelType', 'space'),
    targetIsRegion: (0, _computed.equal)('deployTarget.modelType', 'region'),
    region: (0, _object.computed)('deployTarget', 'targetIsRegion', function () {
      if (!this.get('targetIsRegion')) {
        return;
      }

      return this.get('deployTarget');
    }),
    space: (0, _object.computed)('deployTarget', 'targetIsSpace', function () {
      if (!this.get('targetIsSpace')) {
        return;
      }

      return this.get('deployTarget');
    }),
    pullRequestPattern: (0, _object.computed)('baseName', function () {
      return (0, _utils.isPresent)(this.baseName) ? 'predictable' : 'random';
    })
  });

  _exports.default = _default;
});