define("dashboard/components/github-app-placement-option", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/utils/custom-computed"], function (_exports, _component, _object, _computed, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['pt2', 'pb1', 'flex', 'justify-between', 'items-center', 'v-mid'],
    'data-test-target': 'github-app-placement-option',
    isDisabled: (0, _computed.or)('lacksInstallPermission', 'isAlreadyInstalled'),
    iconClass: (0, _object.computed)('iconColorClass', 'herokuResourceType', function () {
      return this.herokuResourceType === 'user' ? `mr2 malibu-fill-gradient-${this.iconColorClass}` : `bg-gradient-${this.iconColorClass}`;
    }),
    iconColorClass: (0, _object.computed)('isDisabled', 'herokuResourceType', function () {
      let color;

      if (this.isDisabled) {
        color = 'gray';
      } else {
        color = this.herokuResourceType === 'user' ? 'purple' : 'primary';
      }

      return color;
    }),
    iconType: (0, _object.computed)('herokuResourceType', function () {
      return this.herokuResourceType === 'enterprise-account' ? 'account' : '';
    }),
    labelColorClass: (0, _object.computed)('isDisabled', function () {
      return this.isDisabled ? 'gray' : 'purple';
    }),
    isAlreadyInstalled: (0, _computed.bool)('placement.githubAppInstallation.isLoaded'),
    currentUserMember: (0, _customComputed.findBy)('placement.members', 'userEmail', 'currentUserEmail'),
    currentUserPermissionsIsEmpty: (0, _computed.empty)('currentUserMember.permissions'),
    currentUserPermissionsIsLoading: (0, _computed.and)('placement.members.isPending', 'currentUserPermissionsIsEmpty'),
    hasInstallPermission: (0, _object.computed)('currentUserMember.permissions.[]', 'placement.isAdmin', 'herokuResourceType', function () {
      let hasInstallPermission;

      switch (this.herokuResourceType) {
        case 'enterprise-account':
          {
            const userPermissions = this.get('currentUserMember.permissions') || [];
            hasInstallPermission = userPermissions.includes('manage');
            break;
          }

        case 'team':
          {
            hasInstallPermission = this.get('placement.isAdmin');
            break;
          }

        case 'user':
          {
            hasInstallPermission = true;
            break;
          }
      }

      return hasInstallPermission;
    }),
    lacksInstallPermission: (0, _computed.not)('hasInstallPermission'),
    lacksPermissionText: (0, _object.computed)('herokuResourceType', function () {
      let lacksPermissionText;

      if (this.isAlreadyInstalled) {
        lacksPermissionText = '';
      } else if (this.herokuResourceType === 'enterprise-account') {
        lacksPermissionText = "The 'manage' permission is required to install GitHub for this Enterprise Account";
      } else if (this.herokuResourceType === 'team') {
        lacksPermissionText = 'You must be an admin to install the GitHub App for this Team';
      }

      return lacksPermissionText;
    }),
    buttonText: (0, _object.computed)('isAlreadyInstalled', function () {
      return this.isAlreadyInstalled ? 'Already installed' : 'Install';
    }),
    redirectPath: (0, _object.computed)('herokuResourceType', 'placement.name', function () {
      let redirectPath;

      switch (this.herokuResourceType) {
        case 'enterprise-account':
          redirectPath = `/enterprise/${this.get('placement.name')}/settings`;
          break;

        case 'team':
          redirectPath = `/teams/${this.get('placement.name')}/settings`;
          break;

        case 'user':
          redirectPath = '/account/applications';
          break;
      }

      return redirectPath;
    }),
    installationOwnerPropertyName: (0, _object.computed)('herokuResourceType', function () {
      let installationOwnerPropertyName;

      switch (this.herokuResourceType) {
        case 'enterprise-account':
          installationOwnerPropertyName = 'enterpriseAccount';
          break;

        case 'team':
          installationOwnerPropertyName = 'team';
          break;

        case 'user':
          installationOwnerPropertyName = 'currentUserAccount';
          break;
      }

      return installationOwnerPropertyName;
    }),
    actions: {
      createGithubAppInstallation(herokuResourceType, herokuResourceId) {
        return this.createGithubAppInstallation(this.redirectPath, {
          herokuResourceId,
          herokuResourceType,
          installationId: this.installationId,
          [this.installationOwnerPropertyName]: this.placement
        });
      }

    }
  });

  _exports.default = _default;
});