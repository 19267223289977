define("dashboard/models/shogun/postgres-credentials", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "@ember/service"], function (_exports, _attr, _model, _relationships, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    credentialsList: (0, _attr.default)(),
    datastore: (0, _relationships.belongsTo)('shogun/postgres-datastore'),
    api: (0, _service.inject)(),

    rotateCredential(isForced, credentialName) {
      const addonId = this.get('datastore.addon.id');
      const adapter = this.store.adapterFor('shogun/postgres-credentials');
      const data = {
        forced: isForced
      };
      return this.api.request(`/postgres/v0/databases/${addonId}/credentials/${encodeURIComponent(credentialName)}/credentials_rotation`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    },

    rotateAllCredentials(isForced) {
      const addonId = this.get('datastore.addon.id');
      const adapter = this.store.adapterFor('shogun/postgres-credentials');
      const data = {
        forced: isForced
      };
      return this.api.request(`/postgres/v0/databases/${addonId}/credentials_rotation`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    },

    destroyCredential(credentialName) {
      const addonId = this.get('datastore.addon.id');
      const adapter = this.store.adapterFor('shogun/postgres-credentials');
      return this.api.request(`/postgres/v0/databases/${addonId}/credentials/${encodeURIComponent(credentialName)}`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'DELETE',
        contentType: 'application/json; charset=utf-8'
      });
    }

  });

  _exports.default = _default;
});