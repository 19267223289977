define("dashboard/components/efp-app-list-free-resources-warning", ["exports", "@glimmer/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfpAppListFreeResourcesWarningComponent = (_class = class EfpAppListFreeResourcesWarningComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "accountFeatures", _descriptor, this);
    }

    get showWarning() {
      return !this.isEnterpriseTeam && this.hasAnyFreeResources;
    }

    get hasFreeDynos() {
      return this.args.hasFreeDynos;
    }

    get hasFreeData() {
      return this.args.hasFreeData;
    }

    get hasAnyFreeResources() {
      return this.hasFreeDynos || this.hasFreeData;
    }

    get isEnterpriseTeam() {
      return this.args.isEnterpriseTeam;
    }

    get warningMessage() {
      if (this.hasFreeDynos && this.hasFreeData) {
        return 'Free Dynos & Data';
      } else if (this.hasFreeDynos) {
        return 'Free Dynos';
      } else if (this.hasFreeData) {
        return 'Free Data';
      }

      return null;
    }

    get tooltipMessage() {
      if (this.hasFreeDynos && this.hasFreeData) {
        return `To keep your app running and retain your data, upgrade to paid resources now`;
      } else if (this.hasFreeDynos) {
        return `To keep your app running, upgrade to paid dynos now`;
      } else if (this.hasFreeData) {
        return `To retain your data, upgrade to paid resources now`;
      }

      return null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "accountFeatures", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = EfpAppListFreeResourcesWarningComponent;
});