define("dashboard/templates/components/datastore/postgres/settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "61+bH/C1",
    "block": "[[[8,[39,0],null,[[\"@datastore\",\"@addon\",\"@config\",\"@configParam\",\"@closeUpdateConfig\"],[[30,1],[30,2],[30,1,[\"config\"]],[30,3],[30,4]]],null],[1,\"\\n\"],[8,[39,1],null,[[\"@datastore\",\"@reset\",\"@closeReset\"],[[30,1],[30,5],[30,6]]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@datastore\",\"@plans\",\"@fork\",\"@closeFork\"],[[30,1],[30,7],[30,8],[30,9]]],null],[1,\"\\n\"],[8,[39,3],null,[[\"@datastore\",\"@destroy\",\"@closeDestroy\"],[[30,1],[30,10],[30,11]]],null]],[\"@datastore\",\"@addon\",\"@configParam\",\"@closeUpdateConfig\",\"@reset\",\"@closeReset\",\"@plans\",\"@fork\",\"@closeFork\",\"@destroy\",\"@closeDestroy\"],false,[\"datastore/postgres/config\",\"datastore/postgres/reset-database\",\"datastore/postgres/fork-database\",\"datastore/postgres/destroy-database\"]]",
    "moduleName": "dashboard/templates/components/datastore/postgres/settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});