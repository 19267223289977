define("dashboard/serializers/recipient", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize() {
      const base = { ...this._super(...arguments)
      }; // never send verified since we have no direct control
      // over the value of that object

      delete base.verified;

      if (base.token === null) {
        delete base.token;
      }

      if (base.refresh !== true) {
        delete base.refresh;
      }

      return base;
    },

    normalizeDeleteRecordResponse() {
      const base = this._super(...arguments);

      delete base.data.id;
      return base;
    }

  });

  _exports.default = _default;
});