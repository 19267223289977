define("dashboard/templates/components/ci/test-node-tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CyW3fpWD",
    "block": "[[[41,[30,0,[\"renderAsDropdownButton\"]],[[[1,\"  \"],[10,0],[14,0,\"flex items-center tl overflow-tab pr2 mr1 br b--silver\"],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[\"code flex-auto \",[52,[30,0,[\"isInDropdown\"]],\"f5\",\"f4\"]]]],[12],[1,[30,0,[\"index\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"testNode\",\"isNotFinished\"]],[[[1,\"      \"],[1,[28,[35,1],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],null,[[\"@title\",\"@size\",\"@iconClass\",\"@name\"],[[30,0,[\"testNode\",\"status\"]],16,[30,0,[\"statusIconClass\"]],[30,0,[\"statusIconName\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"li\"],[15,0,[29,[\"node-tab nowrap \",[52,[51,[30,0,[\"isInDropdown\"]]],\"flex flex-auto br b--silver\"],\" \"]]],[12],[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[\"tl \",[30,0,[\"buttonClass\"]]]]],[4,[38,4],[[30,0],\"selectTestNode\"],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n        \"],[10,1],[15,0,[29,[\"code flex-auto \",[52,[30,0,[\"isInDropdown\"]],\"f5\",\"f4\"]]]],[12],[1,[30,0,[\"index\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"testNode\",\"isNotFinished\"]],[[[1,\"          \"],[1,[28,[35,1],null,[[\"size\",\"class\"],[\"small\",\"nudge-up--1\"]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,2],null,[[\"@size\",\"@iconClass\",\"@name\",\"@title\"],[16,[30,0,[\"statusIconClass\"]],[30,0,[\"statusIconName\"]],[30,0,[\"statusIconTitle\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"malibu-loading-spinner\",\"malibu-icon\",\"unless\",\"action\"]]",
    "moduleName": "dashboard/templates/components/ci/test-node-tab.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});