define("dashboard/templates/components/stencil/app/activity-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "emwmcGfX",
    "block": "[[[10,0],[15,0,[29,[\"flex items-center center bb b--light-silver pv3 o-\",[30,0,[\"opacity\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"br-100 bg-light-silver\"],[14,5,\"width:28px;height:28px;\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"br-100 bg-light-silver ml2\"],[14,5,\"width:28px;height:28px;\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"ml3 flex flex-column items-start\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dib stencil mb5\"],[12],[1,\"lorem ipsum lorem ipsum lorem ipsum lorem ipsum\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"dib stencil\"],[12],[1,\"lorem ipsum lorem ipsum\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/stencil/app/activity-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});