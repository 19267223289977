define("dashboard/components/space/network/peerings-list-item", ["exports", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'tr',
    store: (0, _service.inject)(),
    isCustomerManaged: (0, _computed.equal)('peering.type', 'customer-managed'),
    canDeletePeering: (0, _computed.and)('peering.isActive', 'isCustomerManaged'),
    actions: {
      updatePeering() {
        const peering = this.peering;
        peering.space = this.space;
        peering.save().then(() => {
          this.set('peering.status', 'activating');
          this.set('resetAsyncBtn', true);
        });
      },

      deletePeering() {
        const peering = this.peering;
        peering.space = this.space;
        peering.set('status', 'deleting');
        peering.destroyRecord();
      }

    }
  });

  _exports.default = _default;
});