define("dashboard/routes/notifications", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "dashboard/mixins/document-title"], function (_exports, _object, _route, _service, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),
    notifications: (0, _service.inject)(),
    ignoreDelinquent: true,

    model() {
      return this.notifications.fetchAll();
    },

    afterModel() {
      this.analytics.logEvent('Notifications Page', 'Viewed');
    },

    renderTemplate() {
      this.set('breadcrumbs.trail', {
        location: 'Notifications'
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav'
      });

      this._super(...arguments);
    },

    documentTitle: (0, _object.computed)(function () {
      const email = this.get('current.account.email');
      const separator = this.documentTitleSeparator;
      return `${email}${separator}Notifications`;
    })
  });

  _exports.default = _default;
});