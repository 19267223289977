define("dashboard/adapters/team/feature", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForUpdateRecord(id, modelName, snapshot) {
      // unmunge the composite id we created when serializing this record
      const featureId = id.split('/')[0];
      const teamId = snapshot.belongsTo('team').id;
      return `${this.host}/teams/${teamId}/features/${featureId}`;
    }

  });

  _exports.default = _default;
});