define("dashboard/utils/infinite-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = infiniteQuery;

  function infiniteQuery(store, modelName) {
    let query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    let range = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    let flashMessagesService = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    const queryWithRange = Object.assign({}, query, range);
    return store.query(modelName, queryWithRange).then(response => {
      const nextRange = response.get('meta.nextRange');
      const statusCode = response.get('meta.status'); // If Range is being stripped via user's network, this prevents
      // infinite requests
      // Still not ideal as the user cannot get all of their apps without that
      // header

      const lastRange = range.range;

      if (lastRange === nextRange && statusCode === 206) {
        if (flashMessagesService) {
          const message = 'It appears a browser extension or your network is blocking headers needed to return all of your data.';
          const title = 'Degraded Experience';
          flashMessagesService.danger(message, {
            title
          });
        }

        return response.toArray();
      }

      if (nextRange) {
        return infiniteQuery(store, modelName, query, {
          range: nextRange
        }).then(nextPage => [...response.toArray(), ...nextPage]);
      } else {
        return response.toArray();
      }
    });
  }
});