define("dashboard/templates/protected/error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j4w0qjj4",
    "block": "[[[41,[28,[37,1],[[30,0,[\"errorStatus\"]],403],null],[[[41,[30,0,[\"model\",\"twoFactorRequired\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]]]],[]],[[[41,[28,[37,1],[[30,0,[\"errorStatus\"]],404],null],[[[1,\"  \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"eq\",\"errors/two-factor-required\",\"errors/access-denied\",\"errors/not-found\",\"errors/unexpected-error\"]]",
    "moduleName": "dashboard/templates/protected/error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});