define("dashboard/components/metrics/charts/legend/chart-legend", ["exports", "@ember/object/computed", "@ember/component"], function (_exports, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * container for all chart-legend-item's
   */
  var _default = _component.default.extend({
    classNameBindings: [':flex', ':metrics__legend', 'isShowingLatestValues:showing-latest-values'],
    hasNotSelectedTime: (0, _computed.not)('hasSelectedTime'),
    isNotRowChart: (0, _computed.not)('isRowChart'),
    showingLatestValues: (0, _computed.and)('hasNotSelectedTime', 'isNotRowChart'),
    activeToggles: (0, _computed.filter)('legendItems', function (item) {
      return item.get('key') && item.get('isVisible');
    }),
    chartHasOneActiveToggle: (0, _computed.equal)('activeToggles.length', 1),
    legendItemsSorting: ['legendIndex:asc'],
    orderedLegendItems: (0, _computed.sort)('legendItems', 'legendItemsSorting')
  });

  _exports.default = _default;
});