define("dashboard/templates/components/enterprise-account/member-manager/loading-stencil", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AtYt5IJF",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[1,2,3],null]],null]],null],null,[[[1,\"  \"],[10,\"tr\"],[14,0,\"o-70\"],[12],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pa2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex items-center w-100\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"https://dashboard.heroku.com/images/static/ninja-avatar-48x48.png\"],[14,5,\"width: 28px; height: 28px\"],[14,0,\"gravatar-icon br-100\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"hk-stencil ml2 flex-auto\"],[12],[1,\"-\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pa2 tc\"],[12],[10,\"input\"],[14,\"checked\",\"\"],[14,\"disabled\",\"\"],[14,4,\"checkbox\"],[12],[13],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pa2 tc\"],[12],[10,\"input\"],[14,\"disabled\",\"\"],[14,4,\"checkbox\"],[12],[13],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pa2 tc\"],[12],[10,\"input\"],[14,\"disabled\",\"\"],[14,4,\"checkbox\"],[12],[13],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pa2 tc\"],[12],[10,\"input\"],[14,\"disabled\",\"\"],[14,4,\"checkbox\"],[12],[13],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pa2\"],[12],[1,\" \"],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pa2\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"f7 green nowrap\"],[12],[1,\"\\n        \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"success-mark-16\",\"malibu-fill-gradient-green nudge-up--1\",16]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"pa2\"],[14,\"colspan\",\"6\"],[12],[1,\" \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"n\"],false,[\"each\",\"-track-array\",\"array\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/member-manager/loading-stencil.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});