define("dashboard/routes/app/locked", ["exports", "dashboard/routes/basic-app"], function (_exports, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    beforeModel() {
      const {
        app
      } = this.modelFor('app'); // this user should not be seeing this page because they have joined the app
      // joined flag is unreliable, so if we have an app id, we know the api
      // gave us back a successful response with a valid app payload

      if (app && (app.get('joined') || app.get('id'))) {
        this.replaceWith('app', app.get('name'));
      }
    }

  });

  _exports.default = _default;
});