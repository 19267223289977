define("dashboard/components/app/datastore/credential-status-badge", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get statusText() {
      if (this.args.status === 'rotating') {
        return 'Rotating';
      } else if (this.args.status === 'revoking') {
        return 'Revoking';
      } else if (this.args.status === 'wait_for_provisioning' || this.args.status === 'provisioning') {
        return 'Creating';
      } else {
        return null;
      }
    }

    get badgeFillClass() {
      if (this.statusText === 'Revoking') {
        return 'bg-red';
      } else {
        return 'bg-orange';
      }
    }

  }

  _exports.default = _default;
});