define("dashboard/templates/components/metrics/charts/selected-time-hover", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9w9qmUDf",
    "block": "[[[10,0],[14,0,\"metrics__chart-time__date\"],[12],[1,\"\\n  \"],[1,[30,0,[\"displayDate\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"metrics__chart-time__hour-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"metrics__chart-time__hour\"],[12],[1,\"\\n    \"],[1,[30,0,[\"displayTime\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"metrics__chart-time__zone\"],[12],[1,\"\\n    \"],[1,[30,0,[\"displayTimezone\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/metrics/charts/selected-time-hover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});