define("dashboard/templates/components/metrics/chart-controls/timeframe-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Pm8E47Zy",
    "block": "[[[10,3],[14,0,\"hk-link ember-view\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"metrics__time-picker__menu-item-label\"],[12],[1,\"\\n    \"],[1,[30,0,[\"value\",\"label\"]]],[1,\" \"],[41,[30,0,[\"value\",\"badge\"]],[[[1,\"(\"],[1,[30,0,[\"value\",\"badge\"]]],[1,\")\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"metrics__time-picker__menu-item-desc\"],[12],[1,\"\\n    \"],[1,[30,0,[\"value\",\"desc\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/timeframe-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});