define("dashboard/templates/components/tag-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vmrPqLkr",
    "block": "[[[41,[30,0,[\"showIcon\"]],[[[1,\"  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"lock-locked-16\",12,[30,0,[\"iconColor\"]]]],null],[1,\"\\n\"]],[]],null],[10,1],[14,0,\"tag-toggle-name\"],[12],[1,\"\\n  \"],[1,[30,0,[\"name\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/tag-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});