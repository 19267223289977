define("dashboard/validators/braintree-hosted-credit-card-field", ["exports", "dashboard/services/payment-gateway"], function (_exports, _paymentGateway) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateBraintreeHostedField;

  function validateBraintreeHostedField() {
    return (key, newValue) => {
      if (newValue) {
        if (newValue.isValid) {
          return true;
        } else if (newValue.isEmpty) {
          return 'Invalid card number';
        } else if (!isValidCardType(newValue)) {
          return `${friendlyCardType(newValue)} is not a supported card type`;
        } else if (!newValue.isValid) {
          return 'Invalid card number';
        }
      }

      return 'Invalid card number';
    };
  }

  function isValidCardType(newValue) {
    if (!newValue.cardType) {
      // If we don't know the card type yet, fall through to the next validation.
      return true;
    }

    return _paymentGateway.BraintreeSupportedCardTypes.includes(newValue.cardType);
  }

  function friendlyCardType(newValue) {
    return newValue.friendlyCardType || newValue.cardType;
  }
});