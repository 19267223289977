define("dashboard/adapters/space/log-drain", ["exports", "@ember/service", "dashboard/adapters/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    dsErrorMonitor: (0, _service.inject)(),
    createMethod: 'PUT',
    version: '3.dogwood',

    urlForQueryRecord(query) {
      const spaceId = query.spaceId;
      delete query.spaceId;
      return `${this.host}/spaces/${spaceId}/log-drain`;
    },

    urlForCreateRecord(modelName, snapshot) {
      const spaceId = snapshot.record.spaceId;
      return `${this.host}/spaces/${spaceId}/log-drain`;
    },

    handleResponse(status, headers, payload, requestData) {
      if (status === 403) {
        // don't flash error
        requestData.jqXHR.errorIsHandled = true;
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});