define("dashboard/serializers/space/nat", ["exports", "dashboard/serializers/application", "ember"], function (_exports, _application, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      hash.id = _ember.default.generateGuid();
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});