define("dashboard/routes/account/applications/edit-authorization", ["exports", "dashboard/routes/account/base", "dashboard/mixins/document-title", "@ember/service"], function (_exports, _base, _documentTitle, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),

    model(_ref) {
      let {
        authorization_id
      } = _ref;
      return this.store.findRecord('authorization', authorization_id);
    },

    afterModel() {
      this.analytics.logEvent('Authorization Edit Page', 'Viewed');
    }

  });

  _exports.default = _default;
});