define("dashboard/components/app/addons-quickadd-item", ["exports", "@ember/object/computed", "dashboard/components/purple-typeahead-item"], function (_exports, _computed, _purpleTypeaheadItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purpleTypeaheadItem.default.extend({
    classNames: ['addon-typeahead-cell'],
    classNameBindings: ['isNotProvisionable:disabled'],
    'data-test-target': 'addons-quickadd-item',
    isNotProvisionable: (0, _computed.alias)('item.isNotProvisionable')
  });

  _exports.default = _default;
});