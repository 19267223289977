define("dashboard/templates/app/metrics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hwx5ZM0M",
    "block": "[[[10,0],[14,0,\"limit-width\"],[12],[1,\"\\n\"],[41,[30,0,[\"formations\",\"isPending\"]],[[[1,\"    \"],[1,[34,1]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"app\",\"metricsEnabled\"]],[[[1,\"    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"app\",\"formations\",\"allowUpgrade\",\"transitionToType\"],[[30,0,[\"app\"]],[30,0,[\"formations\"]],[30,0,[\"allowUpgrade\"]],[28,[37,5],[\"transitionToType\"],null]]]]],[1,\"\\n  \"]],[]]]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"loading-state\",\"component\",\"-outlet\",\"metrics/pay-wall\",\"route-action\"]]",
    "moduleName": "dashboard/templates/app/metrics.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});