define("dashboard/serializers/app-services-config", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      relationships.app = {
        data: {
          id: hash.app.id,
          type: 'app'
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});