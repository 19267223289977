define("dashboard/components/metrics/charts/chart-header/routing-health-summary", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/chart-header/chart-summary", "@ember/object"], function (_exports, _computed, _chartSummary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _chartSummary.default.extend({
    hasSufficientData: true,
    dnsHealth: (0, _object.computed)('data.currentDnsHealth', function () {
      return _object.default.create({
        title: 'The status of DNS Lookup in the last minute.',
        label: 'dns',
        health: this.get('data.currentDnsHealth'),
        testTarget: 'dns-health-summary'
      });
    }),
    tcpHealth: (0, _object.computed)('data.currentTcpHealth', function () {
      return _object.default.create({
        title: 'The status of TCP Dial in the last minute.',
        label: 'tcp',
        health: this.get('data.currentTcpHealth'),
        testTarget: 'tcp-health-summary'
      });
    }),
    tlsHealth: (0, _object.computed)('data.currentTlsHealth', function () {
      return _object.default.create({
        title: 'The status of TLS Handshake in the last minute.',
        label: 'tls',
        health: this.get('data.currentTlsHealth'),
        testTarget: 'tls-health-summary'
      });
    }),
    httpHealth: (0, _object.computed)('data.currentHttpHealth', function () {
      return _object.default.create({
        title: 'The status of HTTP Roundtrip in the last minute.',
        label: 'http',
        health: this.get('data.currentHttpHealth'),
        testTarget: 'http-health-summary'
      });
    }),
    defaultSummaryItems: (0, _computed.collect)('dnsHealth', 'tcpHealth', 'tlsHealth', 'httpHealth'),
    chartSummaryItems: (0, _object.computed)('summaryUnavailable', 'defaultSummaryItems', 'unavailableSummaryItems', function () {
      return this.summaryUnavailable ? [] : this.defaultSummaryItems;
    })
  });

  _exports.default = _default;
});