define("dashboard/services/pipeline-owner", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _service, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    _routePipeline: (0, _object.computed)('router.currentURL', function () {
      const currentURL = this.get('router.currentURL');

      if (!currentURL) {
        return;
      }

      const splitUrl = currentURL.split('/');

      if (splitUrl[1] !== 'pipelines') {
        return;
      }

      return this.store.peekRecord('pipeline', splitUrl[2]);
    }),
    routePipeline: (0, _computed.readOnly)('_routePipeline'),
    isTeamPipeline: (0, _computed.readOnly)('routePipeline.isTeamPipeline'),
    pipelineApps: (0, _computed.readOnly)('routePipeline.apps'),
    pipelineOwnerId: (0, _computed.readOnly)('routePipeline.ownerID'),
    ownerIdFromPipeline: (0, _object.computed)('isTeamPipeline', 'routePipeline.ownerID', function () {
      return this.get('isTeamPipeline') ? this.get('routePipeline.ownerID') : null;
    }),
    ownerFromApp: (0, _object.computed)('pipelineApps.@each.isOwnedByOrg', 'pipelineApps.@each.team', function () {
      if (!this.get('pipelineApps')) {
        return;
      }

      const orgApp = this.get('pipelineApps').findBy('isOwnedByOrg');
      return (0, _utils.isNone)(orgApp) ? null : orgApp.get('team');
    }),
    organizationId: (0, _object.computed)('routePipeline.hasOwner', 'ownerIdFromPipeline', 'ownerFromApp.id', function () {
      if (this.get('routePipeline.hasOwner')) {
        return this.get('ownerIdFromPipeline');
      }

      return this.get('ownerFromApp.id');
    }),
    organization: (0, _object.computed)('organizationId', function () {
      const orgId = this.get('organizationId');
      return orgId ? this.store.peekRecord('team', orgId) : null;
    }),
    canModifyReviewAppSettings: (0, _computed.readOnly)('routePipeline.hasNewReviewAppsEnabled')
  });

  _exports.default = _default;
});