define("dashboard/components/team/member-panel", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "ember-cp-validations", "ember-concurrency", "dashboard/utils/push-deletion", "dashboard/utils/prompt-task"], function (_exports, _component, _service, _object, _computed, _emberCpValidations, _emberConcurrency, _pushDeletion, _promptTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('format', {
      type: 'email',
      debounce: 500
    }), (0, _emberCpValidations.validator)((value, options, model) => {
      if (model.get('member')) {
        return true;
      }

      if (!model.get('memberEmails').includes(value)) {
        return true;
      }

      return 'Already added';
    })],
    selectedApps: [(0, _emberCpValidations.validator)((value, options, model) => {
      if (model.get('role') !== 'collaborator') {
        return true;
      }

      if ((0, _object.get)(value, 'length') > 0) {
        return true;
      }

      return 'Must select at least one app';
    }, {
      dependentKeys: ['model.selectedApps', 'model.role']
    })]
  });

  var _default = _component.default.extend(Validations, {
    // --------------------------------------------------------------------------
    // Element
    tagName: '',
    // --------------------------------------------------------------------------
    // Services
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Args
    team: null,
    member: null,
    initialRole: null,
    onSave: () => {},
    onRemove: () => {},
    // --------------------------------------------------------------------------
    // State
    selectedApps: (0, _object.computed)('member.{role,apps}', function () {
      if (this.get('member.role') === 'collaborator') {
        return this.get('member.apps');
      } else {
        return [];
      }
    }),
    isConfirmingDelete: false,
    // --------------------------------------------------------------------------
    // Computed Properties
    email: (0, _computed.oneWay)('member.email'),
    membersAndInvitations: (0, _computed.union)('team.members', 'team.invitations'),
    memberEmails: (0, _computed.mapBy)('membersAndInvitations', 'email'),
    teamApps: (0, _object.computed)('team.apps.[]', 'team.apps.isFulfilled', function () {
      return this.get('team.apps');
    }),
    availableApps: (0, _computed.setDiff)('teamApps', 'selectedApps'),
    availableRoles: (0, _object.computed)('initialRole', 'team.roles', function () {
      const initialRole = this.initialRole;

      if (initialRole) {
        return [initialRole];
      } else {
        return this.get('team.roles');
      }
    }),
    role: (0, _computed.or)('initialRole', 'member.role', 'defaultRole', 'fallbackRole'),
    defaultRole: (0, _computed.readOnly)('team.preference.defaultPermission'),
    fallbackRole: 'member',
    isPristine: (0, _object.computed)('{role,selectedApps}', 'member.{role,apps}', function () {
      if (!this.member) {
        return false;
      }

      if (this.role !== this.get('member.role')) {
        return false;
      }

      if (this.role !== 'collaborator') {
        return true;
      }

      const selectedApps = this.selectedApps.toArray();
      const memberApps = this.get('member.apps').toArray();

      if (selectedApps.some(app => !memberApps.includes(app))) {
        return false;
      }

      if (memberApps.some(app => !selectedApps.includes(app))) {
        return false;
      }

      return true;
    }),
    oldRole: (0, _computed.readOnly)('member.role'),
    newRole: (0, _computed.readOnly)('role'),
    wasCollaborator: (0, _computed.equal)('oldRole', 'collaborator'),
    wasNotCollaborator: (0, _computed.not)('wasCollaborator'),
    willBeCollaborator: (0, _computed.equal)('newRole', 'collaborator'),
    willNotBeCollaborator: (0, _computed.not)('willBeCollaborator'),
    willUpgradeFromCollaborator: (0, _computed.and)('oldRole', 'wasCollaborator', 'willNotBeCollaborator'),
    willDowngradeToCollaborator: (0, _computed.and)('oldRole', 'wasNotCollaborator', 'willBeCollaborator'),
    limitsApply: (0, _object.computed)('team.isEnterpriseOrg', 'willUpgradeFromCollaborator', 'willBeCollaborator', function () {
      if (this.team.isEnterpriseOrg) {
        return false;
      }

      if (this.member && !this.willUpgradeFromCollaborator) {
        return false;
      }

      if (this.willBeCollaborator) {
        return false;
      }

      return true;
    }),
    countedMembers: (0, _object.computed)('team.members.@each.{isCollaborator,isNew}', function () {
      return this.team.members.rejectBy('isCollaborator').rejectBy('isNew');
    }),
    countedUsers: (0, _computed.union)('countedMembers', 'team.invitations'),
    willRequireEnterprise: (0, _object.computed)('limitsApply', 'countedUsers.length', 'team.membershipLimit', function () {
      const limit = this.team.membershipLimit || 500;
      return this.limitsApply && this.countedUsers.length >= limit;
    }),
    paymentMethod: (0, _computed.readOnly)('loadPaymentMethod.lastSuccessful.value'),
    // --------------------------------------------------------------------------
    // Tasks
    confirmPaidTeam: (0, _promptTask.default)().drop(),
    promptEnterprise: (0, _promptTask.default)().drop(),
    loadPaymentMethod: (0, _emberConcurrency.task)(function* () {
      const team = this.get('team.id');

      if (!team) {
        return;
      }

      return yield this.store.queryRecord('team/payment-method', {
        team
      });
    }).on('didReceiveAttrs').restartable(),
    save: (0, _emberConcurrency.task)(function* (event) {
      if (event) {
        event.preventDefault();
      }

      if (this.willRequireEnterprise) {
        const confirmed = yield this.promptEnterprise.perform();

        if (confirmed) {
          window.open('https://www.heroku.com/contact?c=7013A000000mKrsQAE#sales', '_blank', 'noopener');
        }

        return;
      }

      const newRole = this.role;
      const willUpgradeFromCollaborator = this.willUpgradeFromCollaborator;

      if (this.willDowngradeToCollaborator) {
        yield this.demoteToCollaborator.perform();
      } else if (willUpgradeFromCollaborator) {
        yield this.promoteCollaborator.perform();
      } else if (newRole === 'collaborator') {
        yield this.saveCollaborator.perform();
      } else {
        yield this.saveMember.perform();
      }
    }).drop(),
    saveCollaborator: (0, _emberConcurrency.task)(function* () {
      let member = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.member;
      let memberEmail = arguments.length > 1 ? arguments[1] : undefined;
      const store = this.store;
      const analytics = this.analytics;
      const team = this.team;
      const email = memberEmail || this.email;
      const orgApps = this.get('teamApps');
      const selectedApps = this.selectedApps;
      const trackingEvent = this.member ? 'Updated' : 'Added'; // The API creates the collaborator member record automatically but it’s
      // useful to create a temporary one here as it has all the knowledge of how
      // to reconcile (create/delete) collaborators for apps.

      if (!member) {
        const user = {
          email
        };
        const role = 'collaborator';
        member = store.createRecord('team/member', {
          team,
          email,
          user,
          role
        });
      }

      member.set('selectedApps', selectedApps);
      yield member.updateCollaborators(orgApps); // If we created a temporary member above then now it’s time to throw it
      // away and replace it with the real thing.

      if (member.get('isNew')) {
        yield team.hasMany('members').reload();
      }

      analytics.logEvent(`${team.analyticsNoun} Collaborator`, trackingEvent);
      this.onSave(member);
    }).drop(),
    promoteCollaborator: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const analytics = this.analytics;
      const team = this.team;
      const email = this.email;
      const role = this.role;
      const mustInviteNewMembers = this.get('team.canManuallyInviteNewMembers');
      const type = mustInviteNewMembers ? 'team/invitation' : 'team/member';
      const record = store.createRecord(type, {
        team,
        email,
        role
      });
      yield record.save(); // Litte bit of cleanup. The API is going to delete a bunch of records for
      // us so we can delete any records from Ember Data’s store that we know
      // won’t exist anymore.

      (0, _pushDeletion.default)(store, 'team/member', this.member.id);
      store.peekAll('collaborator').filterBy('email', email).filterBy('app.team.id', team.id).forEach(_ref => {
        let {
          id
        } = _ref;
        return (0, _pushDeletion.default)(store, 'collaborator', id);
      });
      analytics.logEvent('Team Collaborator', 'Promoted');
      this.onSave(record);
    }).drop(),
    demoteToCollaborator: (0, _emberConcurrency.task)(function* () {
      const memberEmail = this.get('member.email');
      yield this.member.destroyRecord();
      yield this.saveCollaborator.perform(null, memberEmail);
    }).drop(),
    saveMember: (0, _emberConcurrency.task)(function* () {
      if (this.member) {
        yield this.updateMember.perform();
      } else if (this.get('team.canManuallyInviteNewMembers')) {
        yield this.inviteMember.perform();
      } else {
        yield this.addMember.perform();
      }
    }).drop(),
    updateMember: (0, _emberConcurrency.task)(function* () {
      const analytics = this.analytics;
      const member = this.member;
      const role = this.role;
      member.set('role', role);

      try {
        yield member.save();
        analytics.logEvent('Teammate', 'Updated');
        this.onSave(member);
      } catch (e) {
        member.rollbackAttributes();
        throw e;
      }
    }).drop(),
    addMember: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const analytics = this.analytics;
      const email = this.email;
      const team = this.team;
      const role = this.role;
      const member = store.createRecord('team/member', {
        email,
        team,
        role
      });
      yield member.save();
      analytics.logEvent('Teammate', 'Added');
      this.onSave(member);
    }).drop(),
    inviteMember: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const analytics = this.analytics;
      const email = this.email;
      const team = this.team;
      const role = this.role;
      const invitation = store.createRecord('team/invitation', {
        email,
        team,
        role
      });
      yield invitation.save();
      analytics.logEvent(`${team.analyticsNoun} Member`, 'Invited');
      this.onSave(invitation);
    }).drop(),
    delete: (0, _emberConcurrency.task)(function* () {
      const memberEmail = this.member?.email;

      if (this.get('member.isCollaborator')) {
        yield this.deleteCollaborator.perform();
        this.analytics.logEvent('Team Collaborator', 'Removed');
      } else {
        yield this.deleteMember.perform();
        this.analytics.logEvent('Teammate', 'Removed');
      }

      this.onDelete(memberEmail);
    }).drop(),
    deleteCollaborator: (0, _emberConcurrency.task)(function* () {
      const teamApps = yield this.get('teamApps');
      const member = this.member;
      member.set('selectedApps', []);
      yield member.updateCollaborators(teamApps);
      (0, _pushDeletion.default)(this.store, 'team/member', member.id);
    }).drop(),
    deleteMember: (0, _emberConcurrency.task)(function* () {
      const member = this.member;

      try {
        yield member.destroyRecord();
      } catch (error) {
        member.rollbackAttributes();
        throw error;
      }
    }).drop(),

    // --------------------------------------------------------------------------
    // Actions
    selectApp(app) {
      const oldValue = this.selectedApps.toArray();
      const newValue = [...oldValue].addObject(app);
      this.set('selectedApps', newValue);
    },

    removeApp(app) {
      const oldValue = this.selectedApps;
      const newValue = oldValue.without(app);
      this.set('selectedApps', newValue);
    },

    startDelete() {
      this.set('isConfirmingDelete', true);
    },

    cancelDelete() {
      this.set('isConfirmingDelete', false);
    },

    sendMfaRequest() {
      this.sendMfaRequestToken.perform();
    }

  });

  _exports.default = _default;
});