define("dashboard/templates/components/github-integration-disabled-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZP9JAST1",
    "block": "[[[41,[30,0,[\"githubIntegrationDisabled\"]],[[[1,\"  \"],[10,0],[14,0,\"message-banner warning clearfix\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"limit-width center\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Warning\",\"warning-badge-28\",\"malibu-fill-gradient-orange\",22]],null],[1,\"\\n      \"],[10,2],[14,0,\"message-banner-text\"],[12],[1,\"\\n        The Github Integration is currently unavailable due to an \"],[10,3],[14,6,\"https://status.heroku.com/incidents/2413\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link fw6 warning\"],[12],[1,\"ongoing incident investigation\"],[13],[1,\".\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/github-integration-disabled-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});