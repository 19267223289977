define("dashboard/components/time-ago", ["exports", "@ember/component", "@ember/object", "moment", "ember-concurrency", "dashboard/config/environment"], function (_exports, _component, _object, _moment, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A component for displaying times in terms of time-ago.
   *
   * @class Dashboard.TimeAgoComponent
   * @extends Ember.Component
   */
  _moment.default.updateLocale('en', {
    calendar: {
      lastDay: '[yesterday at] LT',
      sameDay: '[today at] LT',
      lastWeek: 'MMM D [at] LT',

      sameElse(now) {
        if (this.year() !== now.year()) {
          return 'MMM D, YYYY [at] LT';
        } else {
          return 'MMM D [at] LT';
        }
      }

    }
  });

  const JUST_NOW_STRING = 'just now';

  const TimeAgo = _component.default.extend({
    tagName: 'time',
    classNames: 'timeago',
    attributeBindings: ['datetime', 'title'],
    datetime: null,
    capitalize: true,
    timePollerInterval: 1000,
    autoPoll: true,
    title: (0, _object.computed)('datetime', function () {
      return (0, _moment.default)(this.datetime).utc().format('YYYY-MM-DD HH:mm:ss UTC');
    }),
    displayTime: (0, _object.computed)('datetime', function () {
      if ((0, _moment.default)().diff(this.datetime, 'seconds') < 60) {
        return JUST_NOW_STRING;
      } else {
        return (0, _moment.default)(this.datetime).calendar();
      }
    }),
    displayTimeString: (0, _object.computed)('displayTime', function () {
      const displayTime = this.displayTime;

      if (this.capitalize) {
        return displayTime.capitalize();
      } else {
        return displayTime;
      }
    }),
    timePollerTask: (0, _emberConcurrency.task)(function* () {
      const displayTime = this.displayTime;
      const justNow = displayTime === JUST_NOW_STRING;

      while (justNow) {
        // this is for tests so ember concurrency doesn't poll forever
        if (!this.autoPoll) {
          return;
        }

        this.notifyPropertyChange('datetime');

        if (this.displayTime !== JUST_NOW_STRING) {
          return;
        }

        yield (0, _emberConcurrency.timeout)(this.timePollerInterval);
      }
    }).restartable(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.timePollerTask.perform();
    }

  });

  if (false
  /* DEBUG */
  && _environment.default.environment === 'test') {
    TimeAgo.reopen({
      autoPoll: false
    });
  }

  var _default = TimeAgo;
  _exports.default = _default;
});