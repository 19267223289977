define("dashboard/mixins/metrics/node-charts", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed", "dashboard/utils/metrics/receiving-language-data", "dashboard/utils/metrics/generate-chart-properties"], function (_exports, _mixin, _object, _computed, _receivingLanguageData, _generateChartProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    _receivingNodeData: (0, _receivingLanguageData.default)('Node', 'node-metrics-set-up'),
    _cannotFetchNodeMetrics: (0, _computed.not)('_receivingNodeData'),
    _hasNodeMetricsEnabled: (0, _computed.and)('app.hasRuntimeMetricsEnabled', 'app.hasNodeMetricsEnabled'),
    needsToSetupNode: (0, _computed.and)('app.isEligibleForNodeMetrics', '_hasNodeMetricsEnabled', '_cannotFetchNodeMetrics'),
    nodeMetricsAreSetUp: (0, _computed.and)('_hasNodeMetricsEnabled', '_receivingNodeData'),
    nodeGcPauseChart: (0, _generateChartProperties.default)('node-gc-pause', 'languageMemory'),
    nodeGcCollectionsChart: (0, _generateChartProperties.default)('node-gc-collections', 'languageMemory'),
    nodeEventloopUsageChart: (0, _generateChartProperties.default)('node-eventloop-usage', 'languageMemory'),
    nodeEventloopLatencyChart: (0, _generateChartProperties.default)('node-eventloop-latency', 'languageMemory'),
    nodeChartsToShow: (0, _object.computed)('nodeGcPauseChart', 'nodeEventloopUsageChart', 'nodeGcCollectionsChart', 'nodeEventloopLatencyChart', function () {
      return [this.nodeGcPauseChart, this.nodeGcCollectionsChart, this.nodeEventloopUsageChart, this.nodeEventloopLatencyChart];
    })
  });

  _exports.default = _default;
});