define("dashboard/templates/components/pipeline/create-review-app-from-branch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2UsmRlWN",
    "block": "[[[10,0],[14,0,\"relative cursor-auto mb2 br3 bg-lightest-silver shadow-outer-3 ba b--dashed b--light-gray\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"pv2 ph3 lh-copy\"],[12],[1,\"\\n    \"],[10,\"small\"],[14,0,\"db gray pb2\"],[12],[10,\"b\"],[12],[1,\"Create a review app from a branch\"],[13],[13],[1,\"\\n\"],[6,[39,0],null,[[\"branches\",\"classNames\",\"value\",\"hideDropdown\",\"creatingReviewAppFromBranch\"],[[30,0,[\"branchesWithoutReviewApps\"]],\"flex items-center review-app-creation mb1\",[30,0,[\"selectedBranch\"]],false,true]],[[\"default\"],[[[[1,\"      \"],[11,\"button\"],[24,0,\"hk-button--secondary ml2\"],[16,\"disabled\",[30,0,[\"createReviewAppIsDisabled\"]]],[4,[38,1],[[30,0],\"createReviewApp\"],null],[12],[1,\"\\n        Create\\n      \"],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"git-branch-picker\",\"action\"]]",
    "moduleName": "dashboard/templates/components/pipeline/create-review-app-from-branch.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});