define("dashboard/templates/components/app/datastore/credential-status-badge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3zhtKloC",
    "block": "[[[41,[30,0,[\"statusText\"]],[[[1,\"  \"],[10,1],[15,0,[29,[\"hk-badge \",[30,0,[\"badgeFillClass\"]]]]],[12],[1,\"\\n    \"],[1,[30,0,[\"statusText\"]]],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"loading-16\",12,\"fill-white\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/app/datastore/credential-status-badge.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});