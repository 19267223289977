define("dashboard/adapters/github-auth", ["exports", "dashboard/adapters/particleboard"], function (_exports, _particleboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _particleboard.default.extend({
    urlForQueryRecord(query) {
      const path = `github/account/integration/${query.endpoint}`;
      delete query.endpoint;
      return `${this.host}/${path}`;
    },

    urlForDeleteRecord() {
      return `${this.host}/github/account/integration`;
    }

  });

  _exports.default = _default;
});