define("dashboard/models/status/incident", ["exports", "@ember/utils", "@ember/object", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _utils, _object, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    createdAt: (0, _object.computed)('updates.[]', function () {
      const updates = this.updates.sortBy('createdAt');
      return new Date(updates.get('firstObject.createdAt'));
    }),

    /*
     * The time at which the incident or scheduled maintenance begins.
     * Maintenance events should start at the "maintenance" update.
     */
    startedAt: (0, _object.computed)('createdAt', 'updates.[]', function () {
      const updates = this.updates;
      let startedAt = this.createdAt;

      if ((0, _utils.isPresent)(updates.filterBy('updateType', 'scheduled')) && (0, _utils.isPresent)(updates.filterBy('updateType', 'maintenance'))) {
        startedAt = new Date(updates.findBy('updateType', 'maintenance').get('createdAt'));
      }

      return startedAt;
    }),
    endedAt: (0, _object.computed)('createdAt', 'updates.[]', function () {
      const updates = this.updates;

      if ((0, _utils.isPresent)(updates.filterBy('updateType', 'resolved'))) {
        return new Date(updates.findBy('updateType', 'resolved').get('createdAt'));
      }
    }),
    resolved: (0, _attr.default)('boolean'),
    url: (0, _attr.default)('string'),
    tags: (0, _attr.default)(),
    updates: (0, _relationships.hasMany)('status/update')
  });

  _exports.default = _default;
});