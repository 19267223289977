define("dashboard/mixins/adapters/range-aware-adapter", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    ajaxOptions() {
      const result = this._super(...arguments);

      if (result.data && result.data.range) {
        const {
          data: {
            range
          },
          beforeSend
        } = result;
        delete result.data.range;

        result.beforeSend = xhr => {
          beforeSend(xhr);
          xhr.setRequestHeader('Range', range);
        };
      }

      return result;
    },

    handleResponse(status, headers, payload) {
      const nextRange = this.getHeaderValue('Next-Range', headers);
      payload = payload || {};
      payload.meta = {
        nextRange,
        status
      };
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});