define("dashboard/utils/oauth", ["exports", "dashboard/utils/localstorage"], function (_exports, _localstorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.genRandomString = genRandomString;
  _exports.getState = getState;
  _exports.resetState = resetState;
  const Crypto = window.crypto || window.msCrypto;

  function genRandomString(byteSize) {
    const array = new Uint8Array(byteSize);
    Crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, '0');
  }

  const STATE_NAME = 'heroku-oauth-state';

  function getState() {
    if (!_localstorage.default.getSessionItem(STATE_NAME)) {
      _localstorage.default.setSessionItem(STATE_NAME, genRandomString(32));
    }

    return _localstorage.default.getSessionItem(STATE_NAME);
  }

  function resetState() {
    _localstorage.default.removeSessionItem(STATE_NAME);
  }
});