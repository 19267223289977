define("dashboard/components/metrics/charts/visualizations/dyno-memory", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/charts/visualizations/memory-chart"], function (_exports, _computed, _object, _memoryChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _memoryChart.default.extend({
    tagName: 'metrics-chart-dyno-memory',
    summaryComponent: 'metrics/charts/chart-header/memory-summary',
    summaryData: (0, _computed.alias)('data'),
    title: 'Memory Usage',
    devCenterName: 'metrics#memory-usage',
    isShowingRSS: true,
    isShowingSwap: true,
    isShowingMax: true,
    isShowingAvg: true,
    timeSeriesRSS: (0, _object.computed)('data.rssMemoryMax', 'isShowingRSS', function () {
      return _object.default.create({
        name: 'Max RSS',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 4,
        data: this.get('data.rssMemoryMax'),
        isVisible: this.isShowingRSS,
        key: 'RSS',
        hideLine: true
      });
    }),
    timeSeriesSwap: (0, _object.computed)('data.swapMemoryMax', 'isShowingSwap', function () {
      return _object.default.create({
        name: 'Max Swap',
        color: 'purple-002',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 3,
        data: this.get('data.swapMemoryMax'),
        isVisible: this.isShowingSwap,
        key: 'Swap',
        hideLine: true
      });
    }),
    timeSeriesAvg: (0, _object.computed)('data.memoryTotalMean', 'isShowingAvg', function () {
      return _object.default.create({
        name: 'Avg. Total',
        color: 'trend',
        type: 'metrics/charts/line-chart-elements/line-graph',
        index: 2,
        data: this.get('data.memoryTotalMean'),
        isVisible: this.isShowingAvg,
        key: 'Avg',
        hideLine: false
      });
    }),
    timeSeriesMax: (0, _object.computed)('data.memoryTotalMax', 'isShowingMax', function () {
      return _object.default.create({
        name: 'Max Total',
        color: 'max',
        type: 'metrics/charts/line-chart-elements/line-graph',
        index: 1,
        data: this.get('data.memoryTotalMax'),
        isVisible: this.isShowingMax,
        key: 'Max',
        hideLine: false
      });
    }),
    chartTimeSeries: (0, _computed.collect)('timeSeriesQuota', 'timeSeriesRSS', 'timeSeriesSwap', 'timeSeriesAvg', 'timeSeriesMax')
  });

  _exports.default = _default;
});