define("dashboard/components/metrics/chart-controls/timeframe-selector", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/runloop", "dashboard/mixins/metrics/timeframe-options"], function (_exports, _component, _computed, _object, _service, _runloop, _timeframeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_timeframeOptions.default, {
    tagName: '',
    analytics: (0, _service.inject)(),

    /**
     * infer selected timeframe from metrics timeframe status
     */
    selectedTimeframe: (0, _object.computed)('beforeHours', 'afterHours', 'timeframeOptions.@each.beforeHours', 'timeframeOptions.@each.afterHours', function () {
      const beforeHours = this.beforeHours || 0;
      const afterHours = this.afterHours || 24;
      return this.timeframeOptions.find(function (opt) {
        const isSameBefore = opt.get('beforeHours') === beforeHours;
        const isSameAfter = opt.get('afterHours') === afterHours;
        return isSameBefore && isSameAfter && !opt.disabled;
      });
    }),
    _isInvalidTimeframe: (0, _computed.none)('selectedTimeframe'),

    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', this, this._forceValidTimeframe);
    },

    enabledTimeframeOptions: (0, _computed.filterBy)('timeframeOptions', 'disabled', false),

    /**
     * this will force a selected timeframe to be selected if the user has
     * passed in parsable but invalid timeframe query parameters
     *
     */
    _forceValidTimeframe: (0, _object.observer)('_isInvalidTimeframe', 'enabledTimeframeOptions', function () {
      const isInvalidTimeframe = this._isInvalidTimeframe;

      if (isInvalidTimeframe) {
        const defaultOption = this.enabledTimeframeOptions.get('firstObject');
        this.send('selected', defaultOption);
      }
    }),
    _isBasicTier: (0, _computed.equal)('app.processTier', 'basic'),
    _isHobbyTier: (0, _computed.equal)('app.processTier', 'hobby'),
    premiumOptionsDisabled: (0, _computed.or)('_isBasicTier', '_isHobbyTier'),
    timeframeOptions: (0, _object.computed)('customerFacingOptions', 'premiumOptionsDisabled', function () {
      const premiumOptionsDisabled = this.premiumOptionsDisabled;
      return this.customerFacingOptions.map(function (option) {
        option.disabled = option.tier === 'premium' && premiumOptionsDisabled;
        return _object.default.create(option);
      });
    }),
    actions: {
      selected(option) {
        const beforeHours = option.get('beforeHours');
        const afterHours = option.get('afterHours');
        const name = option.get('name');
        this.set('selectedTimeframe', option);
        this.updateTimeframe(beforeHours, afterHours, name);
      }

    }
  });

  _exports.default = _default;
});