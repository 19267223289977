define("dashboard/components/two-factor", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "rsvp", "dashboard/utils/create-preauth-token", "dashboard/utils/errors"], function (_exports, _service, _computed, _component, _object, _utils, _rsvp, _createPreauthToken, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    @class TwoFactorComponent
  
    This is a container component. This means that it's here to take some data in, and yield
    it out in Handlebars. It doesn't have any DOM elements for a few reasons:
  
   * We're going to use two factor in a lot of places. It can be hard to predict where
   the text field for the two factor input should go. Its placement will likely be
   specific to whoever calls the preauth action here.
   * This component doesn't have a `tagName` because it wouldn't offer any useful
   DOM abstractions.
   * There are so many types of actions that will require 2FA. It's impossible for this component
   to know all of them, but we can handle the two factor part really well and call your action
   when we need to.
  
   Some reading from the Ember commmunity:
  
   http://frontside.io/blog/2016/01/22/functional-templating-in-ember.html
  
   Some reading from the React community:
  
   * https://medium.com/@learnreact/container-components-c0e67432e005#.4ghni3ozg
   * https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0#.ykyblaukl
  
   Ok, so if this component doesn't have any DOM, how am I supposed to render data on the screen?
   There's probably at least a few use cases that are pretty common and we could share across the app, right?
  
   This component will yield several things to you in your template that you can compose together:
  
   ```handlebars
   {{#two-factor app=app as |twoFactor|}}
     {{! A boolean indicating whether two factor is required.
       Initial render - false
       When the `preauth` action fails - true
       When the `preauth` action succeeds - false
     }}
     {{twoFactor.twoFactorRequired}}
  
     {{! A string that represents the user's two factor token that they would send to
     the server to authorize a request.
  
     Initial render - ''
     When the `preauth` action fails` - ''
     When the `preauth` action succeeds - ''
  
     This is reset when the preauth action runs.
     }}
  
     {{twoFactor.twoFactorToken}}
  
     {{! A boolean indicating that both:
     * Two Factor authentication is required (`preauth` failed)
     * There is enough data in in `twoFactorToken` to try sending the token
       to the server in `preauth` again.
  
       You typically want to use this to disable/enable a button until a user
       puts in their 2FA token.
     }}
  
     {{twoFactor.twoFactorFulfilled}}
  
     {{! A boolean indicating that both:
     * Two Factor authentication is required (`preauth` failed)
     * There is **NOT** enough data in in `twoFactorToken` to try sending the token
       to the server in `preauth` again.
  
       You typically want to use this to disable/enable a button until a user
       puts in their 2FA token.
     }}
  
     {{twoFactor.twoFactorUnfulfilled}}
  
     {{! A text input with common stylings and error states for two factor
     input. This will almost always be the input you'd want to use in your template. }}
  
      {{#if twoFactor.twoFactorRequired}}
        Two-Factor Token: {{twoFactor.prompt twoFactorToken=twoFactor.twoFactorToken}}
      {{/if}}
   {{/two-factor}}
   ```
  
   With these properties, you can implement a variety of UIs. While most UIs will be pretty similar,
   you may find yourself in a situation where you're required to be a little more creative.
  
   ## Using The Preauth Action
  
   You should use ember's built-in `(action)` helper with your own action and our preauth action.
  
   An example:
  
   ```handlebars
    {{#two-factor as |twoFactor|}}
      {{#modal-button onclick=(action twoFactor.preauth (action "myAction"))
    {{/two-factor}}
    ```
  
    In this example, we'll try to call your action. If that fails, we'll set some error states.
    The next time the button is clicked, this component will attempt to preauth. If that succeeds,
    we'll call your action. If not, we'll reset the data in this component so the user can try
    again.
  */
  const NO_OP = () => {};

  var _default = _component.default.extend({
    tagName: '',
    session: (0, _service.inject)(),
    api: (0, _service.inject)(),
    twoFactorRequired: false,
    twoFactorToken: '',
    retryable: NO_OP,
    twoFactorFulfilled: (0, _object.computed)('twoFactorToken', 'twoFactorRequired', function () {
      return this.twoFactorRequired === false || (0, _utils.isEmpty)(this.twoFactorToken) === false;
    }),
    twoFactorUnfulfilled: (0, _computed.not)('twoFactorFulfilled'),

    _requireTwoFactor() {
      this._setUnlessDestroyed('twoFactorRequired', true);
    },

    _resetTwoFactorToken() {
      this._setUnlessDestroyed('twoFactorToken', '');
    },

    _removeTwoFactorRequirement() {
      this._setUnlessDestroyed('twoFactorRequired', false);
    },

    _resetRetryable() {
      this._setUnlessDestroyed('retryable', NO_OP);
    },

    _setUnlessDestroyed(key, value) {
      if (!this.isDestroying && !this.isDestroyed) {
        this.set(key, value);
      }
    },

    async preauth(actionThatMayNeedTwoFactor) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      const {
        app,
        twoFactorToken
      } = this;
      const {
        twoFactorRequired
      } = this;
      let promise;
      this.set('retryable', () => {
        return actionThatMayNeedTwoFactor(...args);
      });

      if (this.preflight && !twoFactorRequired) {
        const {
          resource,
          capability
        } = this.preflight;
        const checks = {
          capabilities: [{
            // todo: we only support app right now because that's what api supports.
            // once api supports more resource types, we could look up the type on model.constructor.modelName
            // https://github.com/heroku/api/blob/b07a5e823094c385fbe03b723b38c8d097b0ff03/lib/api/endpoints/api_v3/capabilities.rb#L83
            resource_type: 'app',
            resource_id: resource.id,
            capability
          }]
        };
        const response = await this.api.put('/users/~/capabilities', checks, {
          headers: {
            Accept: 'application/vnd.heroku+json; version=3.capabilities'
          }
        });

        if (response.capabilities[0].requires_second_factor) {
          this._requireTwoFactor();

          return;
        }
      }

      if (twoFactorRequired && twoFactorToken) {
        // preauthing an app
        if (app) {
          promise = app.preauth(twoFactorToken);
        } else {
          // preauthing at the account level
          const description = `dashboard 2fa preauth at ${window.location.href}`;
          promise = (0, _createPreauthToken.default)(description, twoFactorToken);
        }
      } else {
        promise = (0, _rsvp.resolve)();
      }

      return promise.then(token => {
        // if not preauthing an app and the response includes an access token, preauth the session
        if (!app && token) {
          this.session.setPreauthData(token);
        }

        return (0, _rsvp.resolve)(actionThatMayNeedTwoFactor(...args));
      }).then(() => {
        this._removeTwoFactorRequirement();
      }).catch(err => {
        if ((0, _errors.isTwoFactorRequiredError)(err)) {
          this._requireTwoFactor();

          err.handledByTwoFactorComponent = true;
        }

        throw err;
      }).finally(() => {
        this._resetTwoFactorToken();
      });
    },

    actions: {
      preauth() {
        return this.preauth(...arguments);
      },

      retry(event) {
        if (event && event.preventDefault) {
          event.preventDefault();
        }

        return this.preauth(this.get('retryable'));
      },

      reset() {
        this._resetTwoFactorToken();

        this._removeTwoFactorRequirement();

        this._resetRetryable();
      }

    }
  });

  _exports.default = _default;
});