define("dashboard/components/metrics/charts/line-chart-elements/activity-event-marker", ["exports", "@ember/object/computed", "@ember/object", "dashboard/utils/custom-computed", "@ember/component", "jquery"], function (_exports, _computed, _object, _customComputed, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'g',
    time: (0, _computed.readOnly)('event.time'),
    value: (0, _computed.readOnly)('event.value'),
    label: (0, _computed.readOnly)('event.label'),
    height: (0, _computed.readOnly)('chartInnerHeight'),
    classNameBindings: [':metrics__chart__event-indicator', 'isSelected'],
    attributeBindings: ['transform'],
    isSelected: (0, _customComputed.equalTo)('selectedRollupTime', 'time'),
    transform: (0, _object.computed)('x', function () {
      return `translate(${this.x}, 0)`;
    }),
    textGroupTransform: (0, _object.computed)('height', function () {
      const yOffset = this.height + 1;
      return `translate(0, ${yOffset})`;
    }),
    x: (0, _object.computed)('timeScale', 'time', 'area', function () {
      const left = Math.round(this.timeScale(this.time));
      const area = this.area;
      return left + Math.floor(area / 2);
    }),
    area: (0, _object.computed)('rollupDuration', 'timeScale', 'minTime', function () {
      const time = this.minTime + this.get('rollupDuration');
      return this.timeScale(time);
    }),
    padding: 12,
    paddingY: 2,
    rectWidth: (0, _object.computed)('padding', 'textBox.width', function () {
      return this.padding + this.get('textBox.width');
    }),
    rectHeight: (0, _object.computed)('paddingY', 'textBox.height', function () {
      return this.paddingY + this.get('textBox.height');
    }),
    rectX: (0, _object.computed)('textBox.x', 'padding', function () {
      return this.get('textBox.x') - this.padding / 2;
    }),
    isShowingRect: (0, _computed.notEmpty)('textBox'),

    didRenderElement() {
      const textBox = (0, _jquery.default)(this.element).find('text')[0].getBBox();
      this.set('textBox', _object.default.create({
        width: textBox.width,
        height: textBox.height,
        x: textBox.x,
        y: textBox.y
      }));
    }

  });

  _exports.default = _default;
});