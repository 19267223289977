define("dashboard/components/purple-typeahead-item", ["exports", "@ember/object/computed", "@ember/component"], function (_exports, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['tt-suggestion', 'tt-selected'],
    classNameBindings: ['highlighted:tt-cursor'],
    highlighted: (0, _computed.alias)('item.highlighted'),

    click() {
      this.attrs.onSelect(this.item);
    }

  });

  _exports.default = _default;
});