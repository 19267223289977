define("dashboard/components/pipeline/review-apps-dropdown", ["exports", "@ember/component", "@ember/object", "dashboard/mixins/pipeline-ownership"], function (_exports, _component, _object, _pipelineOwnership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    not,
    empty,
    equal
  } = _object.computed;

  var _default = _component.default.extend(_pipelineOwnership.default, {
    tagName: '',
    noPipelineOwner: empty('pipelineOwner'),
    pipelineOwnerIsTeam: equal('pipelineOwner.type', 'team'),
    isReviewAppSettingsEnabled: (0, _object.computed)('pipelineOwner', function () {
      const role = this.get('pipelineOwner.role');
      const isCurrentUserOwner = this.get('pipelineOwner.id') === this.get('current.account.id');
      const isCurrentUserTeamOwner = role === 'admin';
      return isCurrentUserOwner || isCurrentUserTeamOwner;
    }),
    isReviewAppSettingsDisabled: not('isReviewAppSettingsEnabled'),
    staleAppsTooltip: (0, _object.computed)('noPipelineOwner', 'pipelineOwnerIsTeam', 'isReviewAppSettingsDisabled', function () {
      if (this.isReviewAppSettingsDisabled) {
        if (this.noPipelineOwner) {
          return `This pipeline needs an owner to change this setting`;
        } else if (this.pipelineOwnerIsTeam) {
          return `Only the pipeline owner and team admins can change this setting`;
        } else {
          return `Only the pipeline owner can change this setting`;
        }
      }
    }),
    actions: {
      togglePRProperty(property) {
        const link = this.reviewAppParent;
        link.toggleProperty(`pullRequests.${property}`);
        link.save();
      },

      toggleDisableReviewAppsModal() {
        this.toggleProperty('isShowingDisableReviewAppsModal', true);
      },

      disableReviewApps() {
        const link = this.reviewAppParent;
        link.set('pullRequests.enabled', false);
        const promise = link.save();
        return promise.catch(() => {
          this.set('reviewAppParent.pullRequests.enabled', true);
        });
      },

      selectValue(value) {
        this.set('reviewAppParent.stale_days', value);
        this.set('reviewAppParent.pullRequests.stale_days', value);
        this.reviewAppParent.save();
        return false;
      }

    }
  });

  _exports.default = _default;
});