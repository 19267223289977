define("dashboard/serializers/team", ["exports", "@ember/utils", "dashboard/serializers/application"], function (_exports, _utils, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      identityProvider: {
        serialize: false
      }
    },

    normalize(typeClass, hash) {
      const teamId = hash.id;
      hash.links = {
        apps: `/teams/${teamId}/apps`,
        features: `/teams/${teamId}/features`,
        identityProviders: `/teams/${teamId}/identity-providers`,
        invitations: `/teams/${teamId}/invitations`,
        invoices: `/teams/${teamId}/invoices`,
        members: `/teams/${teamId}/members`,
        allowedAddons: `/teams/${teamId}/allowed-addon-services`,
        spaces: '/spaces',
        preference: `/teams/${teamId}/preferences`,
        resourceItems: `/organizations/${teamId}/resources`,
        permissions: `/teams/${teamId}/permissions`,
        licenses: `/teams/${teamId}/licenses`,
        combinedLicenses: `/teams/${teamId}/licenses?combined=true`,
        groupedLicenses: `/teams/${teamId}/license-collections`,
        githubAppInstallation: `/teams/${teamId}/github/installation`
      };
      return this._super.apply(this, arguments);
    },

    serialize(snapshot, options) {
      const paymentMethodNonce = snapshot.record.get('paymentMethodNonce');
      const creditCard = snapshot.record.get('creditCard');
      const isNew = snapshot.record.get('isNew');
      const name = snapshot.record.get('name'); // The presence of a paymentMethodNonce indicates that the Stripe form is being used
      // otherwise, the old credit card form (with braintree impl) is being used

      if ((0, _utils.isPresent)(paymentMethodNonce) && isNew) {
        return {
          name: snapshot.attr('name'),
          nonce: snapshot.record.get('paymentMethodNonce')
        };
      } else if ((0, _utils.isPresent)(creditCard) && isNew) {
        // TODO clean up when we remove the old credit card form
        const serializedTeam = snapshot.record.get('creditCard').serialize();
        serializedTeam['name'] = snapshot.record.get('name');
        return serializedTeam;
      } else {
        const payload = this._super(snapshot, options);

        const enterpriseAccountId = snapshot.record.get('enterpriseAccount.id');

        if (enterpriseAccountId) {
          payload['enterprise_account'] = enterpriseAccountId;
        }

        const newName = snapshot.record.get('newName');

        if (newName && name !== newName) {
          payload['name'] = newName;
        }

        return payload;
      }
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      if (hash.enterprise_account) {
        relationships.enterprise = {
          data: {
            id: hash.enterprise_account.id,
            type: 'enterprise-account'
          }
        };
      }

      if (hash.identity_provider) {
        relationships.identityProvider = {
          data: {
            id: hash.identity_provider.id,
            type: 'identity-provider'
          }
        };
      }

      return relationships;
    }

  });

  _exports.default = _default;
});