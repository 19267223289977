define("dashboard/services/github-token", ["exports", "rsvp", "@ember/service", "dashboard/utils/debounce-promise", "@ember/object/computed"], function (_exports, _rsvp, _service, _debouncePromise, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    githubSession: (0, _service.inject)('github-session'),
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    token: null,
    user: null,
    hasToken: (0, _computed.bool)('token.github.token'),
    hasUser: (0, _computed.bool)('user.id'),
    hasNoUser: (0, _computed.not)('hasUser'),
    isNotPresent: (0, _computed.and)('hasToken', 'hasNoUser'),
    isPresent: (0, _computed.and)('hasToken', 'hasUser'),
    fetch: (0, _debouncePromise.default)(function () {
      return this.store.queryRecord('kolkrabbi/github-token', {}).then(token => {
        return this.fetchUser().then(user => {
          this.set('user', user);
          this.set('token', token);
          this.set('githubSession.githubAccessToken', token.get('token'));
          return token;
        });
      }).catch(() => {
        this.set('token', null);
        this.set('user', null);
        return null;
      });
    }),

    maybeFetch() {
      if (this.isPresent) {
        return _rsvp.default.resolve(this.token);
      } else {
        return this.fetch();
      }
    },

    fetchUser() {
      return this.get('current.account').belongsTo('githubUser').reload().catch(() => null);
    }

  });

  _exports.default = _default;
});