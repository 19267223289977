define("dashboard/models/config-vars", ["exports", "@ember/application", "@ember/object", "@ember-data/model", "dashboard/models/config-var"], function (_exports, _application, _object, _model, _configVar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    variables: (0, _model.attr)(),
    listOfVariables: (0, _object.computed)('variables', function () {
      const configVarsObj = this.variables;
      return Object.keys(configVarsObj).map(key => {
        return _configVar.default.create((0, _application.getOwner)(this).ownerInjection(), {
          key,
          value: configVarsObj[key]
        });
      });
    })
  });

  _exports.default = _default;
});