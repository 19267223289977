define("dashboard/templates/components/route-outlets/notifications/actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FnUxtofu",
    "block": "[[[1,[28,[35,0],null,[[\"disabled\",\"onClick\",\"buttonType\",\"default\"],[[28,[37,1],[[30,1,[\"unreadNotifications\",\"length\"]],0],null],[30,0,[\"dismissNotifications\"]],\"secondary\",\"Mark all as read\"]]]],[1,\"\\n\"]],[\"@controller\"],false,[\"hk-button\",\"eq\"]]",
    "moduleName": "dashboard/templates/components/route-outlets/notifications/actions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});