define("dashboard/components/datastore/chart-switcher", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['metrics__process-switcher', 'metrics__datastore-chart-switcher', 'flex', 'flex-grow-1'],
    'data-test-target': 'datastore-chart-switcher',
    chartOptions: (0, _object.computed)('topics', function () {
      const ioOption = {
        name: 'I/O'
      };
      const topics = this.topics.map(topic => {
        return {
          name: topic.name
        };
      });
      return [ioOption, ...topics];
    }),
    selectedOption: (0, _object.computed)('chartOptions', 'index', function () {
      return this.chartOptions[this.index];
    }),
    menuAvailable: (0, _computed.gte)('chartOptions.length', 1),
    menuScrollable: (0, _computed.gte)('chartOptions.length', 5),
    menuClassNames: (0, _object.computed)('menuAvailable', 'menuScrollable', function () {
      const menuAvailable = this.menuAvailable;
      const menuScrollable = this.menuScrollable;
      let classNames = 'dropdown-menu metrics__process-switcher__menu';

      if (!menuAvailable) {
        classNames += ' hidden';
      } else if (menuScrollable) {
        classNames += ' scrollable';
      }

      return classNames;
    }),
    buttonClassNames: (0, _object.computed)('menuAvailable', function () {
      const menuAvailable = this.menuAvailable;
      let classNames = 'btn btn-default metrics__process-switcher__btn';

      if (!menuAvailable) {
        classNames += ' is-only-process';
      }

      return classNames;
    })
  });

  _exports.default = _default;
});