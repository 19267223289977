define("dashboard/models/enterprise-account", ["exports", "dashboard/models/identity-provider-owner", "@ember-data/model", "@ember/object", "@ember/object/computed", "dashboard/models/enterprise-permissions", "dashboard/mixins/ds-error-monitoring-model"], function (_exports, _identityProviderOwner, _model, _object, _computed, _enterprisePermissions, _dsErrorMonitoringModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _identityProviderOwner.default.extend(_dsErrorMonitoringModel.default, {
    name: (0, _model.attr)(),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    permissions: (0, _model.attr)(),
    // The permissions of the user making the request.
    trial: (0, _model.attr)('boolean'),
    auditTrailArchives: (0, _model.hasMany)('enterprise-account/audit-trail-archive'),
    members: (0, _model.hasMany)('enterprise-account/member'),
    teams: (0, _model.hasMany)('team', {
      inverse: 'enterprise'
    }),
    githubAppInstallation: (0, _model.belongsTo)('github-app-installation'),
    preference: (0, _model.belongsTo)('enterprise-account-preference'),
    mfaRequired: (0, _model.belongsTo)('enterprise-account-preference-mfa'),
    githubAppSettings: (0, _model.belongsTo)('repositories-api-enterprise-account-settings'),
    currentLicenses: (0, _model.hasMany)('enterprise-account/license'),
    historicalLicenseUsage: (0, _model.hasMany)('enterprise-account/license-usage'),
    permissionNames: (0, _object.computed)(function () {
      return Object.keys(this.permissionDescriptions);
    }),
    permissionDescriptions: {
      view: 'Minimum account visibility',
      billing: 'Access to usage reports',
      manage: 'Control of members, Teams, and more',
      create: 'Create, rename, and delete Teams'
    },
    teamSort: ['name'],
    sortedTeams: (0, _computed.sort)('teams', 'teamSort'),
    accessibleTeams: (0, _computed.filterBy)('teams', 'role'),
    sortedAccessibleTeams: (0, _computed.sort)('accessibleTeams', 'teamSort'),
    currentPermissions: (0, _object.computed)('permissions.[]', function () {
      const enterpriseAccount = this;
      return _enterprisePermissions.default.create({
        enterpriseAccount
      });
    }),
    hasManage: (0, _object.computed)('permissions.[]', function () {
      return this.hasPermission('manage');
    }),
    hasView: (0, _object.computed)('permissions.[]', function () {
      return this.hasPermission('view');
    }),
    hasCreate: (0, _object.computed)('permissions.[]', function () {
      return this.hasPermission('create');
    }),
    hasBilling: (0, _object.computed)('permissions.[]', function () {
      return this.hasPermission('billing');
    }),

    hasPermission(name) {
      const permissions = this.permissions || [];
      return permissions.includes(name);
    }

  });

  _exports.default = _default;
});