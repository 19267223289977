define("dashboard/controllers/app/datastore/credentials", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "ember-concurrency", "dashboard/config/environment"], function (_exports, _controller, _tracking, _object, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('app', {});
  }).restartable(), _dec2 = (0, _emberConcurrency.task)(function* () {
    while (true) {
      yield this.model.datastore.belongsTo('credentials').reload();

      if (this.model.datastore.get('credentials.credentialsList').isEvery('state', 'active') || _environment.default.environment === 'test') {
        return;
      }

      yield (0, _emberConcurrency.timeout)(20000);
    }
  }).drop(), (_class2 = class _class2 extends _controller.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ['new', 'credential']);

      _initializerDefineProperty(this, "credential", _descriptor, this);

      _initializerDefineProperty(this, "new", _descriptor2, this);

      _initializerDefineProperty(this, "newCredentialSaveSuccessful", _descriptor3, this);

      _initializerDefineProperty(this, "isRotatingCredentials", _descriptor4, this);

      _initializerDefineProperty(this, "loadAvailableApps", _descriptor5, this);

      _initializerDefineProperty(this, "pollCredentialsTask", _descriptor6, this);
    }

    get currentCredential() {
      const credentialName = this.credential;
      const credentials = this.model.credentials.get('credentialsList');
      const currentCredential = credentials.findBy('name', credentialName);
      return currentCredential;
    }

    get existingCredentialNames() {
      return this.model.credentials.get('credentialsList').mapBy('name');
    }

    get apps() {
      const availableApps = this.loadAvailableApps?.lastSuccessful?.value || [];
      return availableApps.toArray();
    }

    dismissSlidePanel() {
      this.new = null;
      this.credential = null;
    }

    viewCredential(credentialName) {
      this.credential = credentialName;
    }

    async rotateAllCredentials(isForced, dismiss) {
      await this.model.credentials.rotateAllCredentials(isForced);
      this.pollCredentialsTask.perform();
      dismiss();
    }

    async rotateCredential(isForced, credentialName, dismiss) {
      await this.model.credentials.rotateCredential(isForced, credentialName);
      this.pollCredentialsTask.perform();
      dismiss();
    }

    async attachCredential(credentialName, app) {
      const addon = this.model.addon;
      const appId = app.get('id');
      const namespace = `credential:${credentialName}`;
      const attachmentName = credentialName.toUpperCase().replace(/-/g, '_').replace(/^([0-9])/, 'X$1');
      await this.store.createRecord('attachment', {
        addon,
        app,
        namespace,
        name: attachmentName,
        confirm: appId
      }).save();
    }

    async detachCredential(attachment) {
      await attachment.destroyRecord();
      return await this.loadAvailableApps.perform();
    }

    async createCredential(name, dismiss) {
      await this.model.datastore.createCredential(name);
      this.new = null;
      this.newCredentialSaveSuccessful = true;
      this.pollCredentialsTask.perform();
      dismiss();
    }

    async destroyCredential(name, dismiss) {
      await this.model.credentials.destroyCredential(name);
      this.pollCredentialsTask.perform();
      dismiss();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "credential", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "new", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "newCredentialSaveSuccessful", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "isRotatingCredentials", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "loadAvailableApps", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "pollCredentialsTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "dismissSlidePanel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "dismissSlidePanel"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "viewCredential", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "viewCredential"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rotateAllCredentials", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "rotateAllCredentials"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "rotateCredential", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "rotateCredential"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "attachCredential", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "attachCredential"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "detachCredential", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "detachCredential"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createCredential", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "createCredential"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destroyCredential", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "destroyCredential"), _class2.prototype)), _class2));

  _exports.default = _class;
});