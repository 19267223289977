define("dashboard/templates/app/webhooks/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uWU9Z1Jt",
    "block": "[[[1,[28,[35,0],null,[[\"panelTitle\",\"submitText\",\"webhook\",\"includeTypes\",\"onSuccess\",\"onCancel\"],[\"Edit Webhook\",\"Update\",[30,0,[\"model\",\"webhook\"]],[30,0,[\"model\",\"includeTypes\"]],[28,[37,1],[[30,0],\"finishEdit\"],null],[28,[37,1],[[30,0],\"cancelEdit\"],null]]]]],[1,\"\\n\"]],[],false,[\"app/web-hooks/form\",\"action\"]]",
    "moduleName": "dashboard/templates/app/webhooks/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});