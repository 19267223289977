define("dashboard/templates/components/pipeline/kolkrabbi-app-build-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eVux/m0Y",
    "block": "[[[41,[30,0,[\"currentBuild\",\"isPending\"]],[[[1,\"  \"],[10,0],[14,0,\"pa2 bt b--silver\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"h--16 nudge-up--2\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"size\",\"class\"],[\"small\",\"ml--4 mr1\"]]]],[1,\"\\n      \"],[10,1],[14,0,\"f6 gray ml1 pl--2\"],[12],[1,\"\\n        Building app •\\n        \"],[11,3],[24,6,\"#\"],[24,0,\"hk-link no-underline underline-hover\"],[4,[38,2],[[30,0],\"showBuildModal\"],null],[12],[1,\"\\n          View log\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"currentBuild\",\"isFailed\"]],[[[1,\"  \"],[10,0],[14,0,\"ma2 mt0 pa2 bg-lightest-red br1 relative z-1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center h--16\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"failure-badge-16\",\"fill-red mr1\",16]],null],[1,\"\\n      \"],[10,1],[14,0,\"flex-auto f6 red pl--1 b nudge-up--1\"],[12],[1,\"Latest build failed •\\n        \"],[11,3],[24,6,\"#\"],[24,0,\"hk-link red\"],[4,[38,2],[[30,0],\"showBuildModal\"],null],[12],[1,\"\\n          View log\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isShowingBuildModal\"]],[[[1,\"  \"],[1,[28,[35,4],null,[[\"app\",\"currentBuild\",\"hideBuildModal\"],[[30,0,[\"app\"]],[30,0,[\"currentBuild\"]],[28,[37,2],[[30,0],\"hideBuildModal\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-loading-spinner\",\"action\",\"malibu-icon\",\"pipeline/build-modal\"]]",
    "moduleName": "dashboard/templates/components/pipeline/kolkrabbi-app-build-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});