define("dashboard/adapters/enterprise-account/usage", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'enterprise-accounts',

    urlForQuery(query) {
      const host = this.host;
      const id = query.enterpriseAccountId;
      const namespace = this.namespace;
      delete query['enterpriseAccountId'];
      return `${host}/${namespace}/${id}/usage`;
    }

  });

  _exports.default = _default;
});