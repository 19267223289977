define("dashboard/utils/create-preauth-token", ["exports", "rsvp", "dashboard/config/environment", "jquery"], function (_exports, _rsvp, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createPreauthToken;

  function createPreauthToken(description, code) {
    const promise = new _rsvp.Promise((resolve, reject) => {
      return _jquery.default.ajax({
        method: 'post',
        withHerokuAuthorization: true,
        url: `${_environment.default.platformApiUrl}/oauth/authorizations`,
        headers: {
          'X-Origin': window.location.origin,
          'Heroku-Two-Factor-Code': code,
          Accept: 'application/vnd.heroku+json; version=3'
        },
        contentType: 'application/json',
        data: JSON.stringify({
          description,
          skip_two_factor: true
        })
      }).then(response => {
        return resolve(response['access_token'].token);
      }).fail(reject);
    });
    return promise;
  }
});