define("dashboard/templates/components/dyno-tier-picker-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PZosYSMx",
    "block": "[[[10,0],[14,0,\"dyno-tier-picker-header\"],[12],[1,\"\\n  Professional\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"dyno-tier-picker-header-placeholder\"],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"dyno-tier-picker-item-sizes\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"tier\",\"cells\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,2],null,[[\"size\",\"isEcoWithoutSubscription\"],[[30,1],[30,0,[\"isEcoWithoutSubscription\"]]]]]],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"dyno-tier-picker-item-select-btn\"],[12],[1,\"\\n\"],[41,[30,0,[\"isEcoWithoutSubscription\"]],[[[1,\"    \"],[11,0],[24,0,\"hk-button-sm--primary dyno-tier-picker-item-select__link\"],[24,\"role\",\"button\"],[4,[38,4],[[30,0],\"transitionToAccountBilling\"],null],[12],[1,[30,0,[\"displayPrice\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"dyno-tier-price-label dyno-tier-picker-item-select__link\"],[12],[1,[30,0,[\"displayPrice\"]]],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"cell\"],false,[\"each\",\"-track-array\",\"dyno-tier-picker-item-size\",\"if\",\"action\"]]",
    "moduleName": "dashboard/templates/components/dyno-tier-picker-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});