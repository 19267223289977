define("dashboard/routes/apps/index", ["exports", "rsvp", "@ember/routing/route", "@ember/service", "@ember/utils", "dashboard/mixins/document-title", "dashboard/utils/infinite-query"], function (_exports, _rsvp, _route, _service, _utils, _documentTitle, _infiniteQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    accountFeatures: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    loadingState: (0, _service.inject)(),
    favoritesService: (0, _service.inject)('favorites'),
    realTimeUpdater: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),

    model() {
      const {
        teams
      } = this.modelFor('protected');
      const store = this.store;
      const personalAppsQuery = {
        personalOnly: true
      };
      return (0, _rsvp.hash)({
        apps: (0, _infiniteQuery.default)(store, 'app', personalAppsQuery, {}, this.flashMessages),
        addons: (0, _infiniteQuery.default)(store, 'addon', {
          personalOnly: true,
          slug: ['heroku-postgresql', 'heroku-redis']
        }, {}, this.flashMessages),
        paymentMethod: this.store.queryRecord('payment-method', {}).catch(() => []),
        couplings: (0, _infiniteQuery.default)(store, 'pipeline-coupling', personalAppsQuery, {}, this.flashMessages),
        favorites: this.get('favoritesService.favorites').catch(() => []),
        teams
      });
    },

    afterModel(model) {
      this._super(...arguments);

      this.analytics.logEvent('Apps List Page', 'Viewed'); // This sets up relationships from app => pipelineCoupling. Since we have
      // already loaded pipelineCouplings, we set those as a relationships,
      // versus ember-data trying to fetch them from the server with what we
      // define in `links`

      model.apps.forEach(app => {
        const pipelineCouplingRel = app.belongsTo('pipelineCoupling');
        const pipelineCouplingRelId = pipelineCouplingRel.id();

        if ((0, _utils.isPresent)(pipelineCouplingRelId)) {
          const pipelineCoupling = this.store.peekRecord('pipelineCoupling', pipelineCouplingRelId);

          if ((0, _utils.isPresent)(pipelineCoupling)) {
            app.set('pipelineCoupling', pipelineCoupling);
          }
        } else {
          app.set('pipelineCoupling', null);
        }
      });
    },

    renderTemplate() {
      this.set('breadcrumbs.trail', {});
      this.render('breadcrumbs', {
        outlet: 'header-nav',
        into: 'protected'
      });

      this._super(...arguments);
    },

    documentTitle: 'Personal apps',

    // Disabled - Currently not called from anywhere
    subscribeToAppUpdates() {
      const {
        apps
      } = this.modelFor(this.routeName);
      const rtu = this.realTimeUpdater;
      apps.forEach(app => {
        rtu.subscribeToModel(app, null, {
          type: 'update'
        });
      });
    },

    // Disabled - Currently not called from anywhere
    unsubscribeFromAppUpdates() {
      const {
        apps
      } = this.modelFor(this.routeName);
      const rtu = this.realTimeUpdater;
      apps.forEach(app => {
        rtu.unsubscribeFromModel(app);
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          page: 1,
          filter: ''
        });
      }
    },

    actions: {
      loading() {
        this.set('loadingState.showNavLoading', true);

        this._router.one('didTransition', () => {
          this.set('loadingState.showNavLoading', false);
        });

        return true;
      }

    }
  });

  _exports.default = _default;
});