define("dashboard/components/metrics/charts/visualizations/go-stack-memory", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/memory-chart", "@ember/object/computed"], function (_exports, _object, _memoryChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _memoryChart.default.extend({
    'data-test-target': 'metrics-go-chart',
    title: 'Go: Stack Memory Usage',
    devCenterName: 'language-runtime-metrics-go#stack-memory-usage',
    isBeta: true,
    isShowingMax: true,
    isShowingAvg: true,
    timeSeriesMax: (0, _object.computed)('data.stackMaxGo', 'isShowingMax', function () {
      return _object.default.create({
        name: 'Stack Max',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 1,
        data: this.get('data.stackMaxGo'),
        isVisible: this.isShowingMax,
        key: 'Max'
      });
    }),
    timeSeriesAvg: (0, _object.computed)('data.stackAvgGo', 'isShowingAvg', function () {
      return _object.default.create({
        name: 'Stack Avg',
        color: 'purple-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 2,
        data: this.get('data.stackAvgGo'),
        isVisible: this.isShowingAvg,
        key: 'Avg'
      });
    }),
    chartTimeSeries: (0, _computed.collect)('timeSeriesMax', 'timeSeriesAvg', 'timeSeriesQuota')
  });

  _exports.default = _default;
});