define("dashboard/components/scheduler/jobs-list-item", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/utils/custom-computed"], function (_exports, _component, _object, _computed, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'tr',
    classNames: ['dark-gray', 'hover-bg-lightest-silver', 'f5'],
    'data-test-target': 'jobs-list-item',
    isFirstJob: (0, _computed.equal)('index', 0),
    isNotFirstJob: (0, _computed.not)('isFirstJob'),
    isLastJob: (0, _object.computed)('jobsLength', 'index', function () {
      return this.jobsLength - 1 === this.index;
    }),
    sizes: (0, _computed.alias)('currentTier.sizes'),
    selectedSizeName: (0, _computed.alias)('job.dynoSize'),
    selectedSize: (0, _customComputed.findBy)('sizes', 'size', 'selectedSizeName'),
    dynoIconName: (0, _object.computed)('selectedSize.dynomiteIconName', function () {
      return this.get('selectedSize.dynomiteIconName') || 'app-16';
    }),
    actions: {
      handleDeleteConfirmation(confirmed) {
        this.set('isShowingDeleteConfirmation', false);

        if (confirmed) {
          const job = this.job;
          this.deleteJob(job);
        }
      }

    }
  });

  _exports.default = _default;
});