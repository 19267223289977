define("dashboard/components/app/settings/buildpack-modal", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['u-padding-Bn', 'modal-body'],

    init() {
      this._super(...arguments);

      const officialBuildpackLanguageNames = ['nodejs', 'python', 'php', 'ruby', 'java', 'go', 'gradle', 'scala', 'clojure'];
      const officialBuildpacks = officialBuildpackLanguageNames.map(buildpackLanguageName => {
        return _object.default.create({
          isSelected: false,
          languageName: buildpackLanguageName,
          shorthandName: `heroku/${buildpackLanguageName}`,
          url: `https://github.com/heroku/heroku-buildpack-${buildpackLanguageName}`
        });
      });
      this.set('officialBuildpacks', officialBuildpacks);
    },

    buildpackNameChanged: (0, _object.observer)('item.name', function () {
      this.highlightBuildpackButton(this.get('item.name'));
    }),

    clearBuildpackSelections() {
      this.officialBuildpacks.invoke('set', 'isSelected', false);
    },

    highlightBuildpackButton(buildpackShorthandNameOrUrl) {
      this.clearBuildpackSelections();
      const buildpackByFullName = this.officialBuildpacks.findBy('shorthandName', buildpackShorthandNameOrUrl);
      const buildpackByUrl = this.officialBuildpacks.findBy('url', buildpackShorthandNameOrUrl);

      if (buildpackByFullName) {
        buildpackByFullName.set('isSelected', true);
      } else if (buildpackByUrl) {
        buildpackByUrl.set('isSelected', true);
      }
    },

    actions: {
      buildpackButtonClicked(buildpackNameShorthand) {
        this.set('item.name', buildpackNameShorthand);
      }

    }
  });

  _exports.default = _default;
});