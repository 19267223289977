define("dashboard/templates/components/loading-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3vXYi4/q",
    "block": "[[[10,1],[14,0,\"dots\"],[12],[1,\"\\n  \"],[10,\"i\"],[14,0,\"spinner__dot spinner__dot--one\"],[12],[13],[1,\"\\n  \"],[10,\"i\"],[14,0,\"spinner__dot spinner__dot--two\"],[12],[13],[1,\"\\n  \"],[10,\"i\"],[14,0,\"spinner__dot spinner__dot--three\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/loading-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});