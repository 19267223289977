define("dashboard/components/pop-over-button", ["exports", "@ember/string", "@ember/object", "@ember/object/computed", "@ember/component"], function (_exports, _string, _object, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    hasCaret: false,
    buttonContent: null,
    buttonLabel: null,
    buttonExpandedLabel: (0, _computed.reads)('label'),
    buttonComponent: null,
    buttonComponentOptions: null,
    buttonExpanded: false,
    buttonDisabled: false,
    caretClass: 'fill-purple',
    buttonClassNames: (0, _object.computed)('buttonClass', 'overrideDefaultClass', function () {
      if (this.get('overrideDefaultClass') === true) {
        return `pop-over-toggle ${this.buttonClass}`;
      } else {
        return `pop-over-toggle hk-button--secondary ${this.buttonClass}`;
      }
    }),
    buttonContentHTML: (0, _object.computed)('buttonContent', function () {
      return (0, _string.htmlSafe)(this.buttonContent);
    }),
    actions: {
      toggle() {
        this.attrs.toggle();
      }

    }
  });

  _exports.default = _default;
});