define("dashboard/serializers/space", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (hash.region) {
        hash.region.type = 'region';
      }

      if (hash.team) {
        hash.team.type = 'team';
      }

      hash.links = {
        members: 'members',
        nat: 'nat',
        inboundRuleset: 'inbound-ruleset',
        outboundRuleset: 'outbound-ruleset',
        vpnConnections: 'vpn-connections'
      };
      return this._super.apply(this, arguments);
    },

    serialize(snapshot) {
      const space = snapshot.record;
      const payload = {
        name: space.get('name')
      };

      if (space.get('isNew')) {
        payload.team = space.get('team.id');
        payload.region = space.get('region.id');
        payload.shield = space.get('shield');

        if (space.get('logDrainURL')) {
          payload.log_drain_url = space.get('logDrainURL');
        }
      } else {
        // if we are renaming the space
        const newName = snapshot.record.get('newName');

        if (newName && name !== newName) {
          payload['name'] = newName;
        }
      }

      return payload;
    }

  });

  _exports.default = _default;
});