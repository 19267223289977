define("dashboard/components/metrics/charts/visualizations/dyno-load", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart", "@ember/object/computed"], function (_exports, _object, _wrappedLineChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    tagName: 'metrics-load-chart',
    title: 'Dyno Load',
    devCenterName: 'metrics#dyno-load',
    insufficientDataTitle: 'Insufficient Data',
    isShowingMax: true,
    isShowingAvg: true,
    timeSeriesMax: (0, _object.computed)('data.maxData', 'isShowingMax', function () {
      return _object.default.create({
        name: '1M Load Max',
        color: 'blue-001',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 0,
        data: this.get('data.maxData'),
        isVisible: this.isShowingMax,
        key: 'Max'
      });
    }),
    timeSeriesAvg: (0, _object.computed)('data.meanData', 'isShowingAvg', function () {
      return _object.default.create({
        name: '1M Load Avg.',
        color: 'blue-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 1,
        data: this.get('data.meanData'),
        isVisible: this.isShowingAvg,
        key: 'Avg'
      });
    }),
    chartTimeSeries: (0, _computed.collect)('timeSeriesAvg', 'timeSeriesMax'),
    valueFormat: '0,0.00',
    defaultMaxValue: (0, _object.computed)('isShowingMax', function () {
      return this.isShowingMax ? 1 : 0.5;
    })
  });

  _exports.default = _default;
});