define("dashboard/serializers/job", ["exports", "@ember-data/serializer/json-api"], function (_exports, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    attrs: {
      app: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});