define("dashboard/components/metrics/charts/selected-time-line", ["exports", "@ember/object", "@ember/object/computed", "@ember/component"], function (_exports, _object, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A verical line that corresponds to the parent chart's selected time
   *
   */
  var _default = _component.default.extend({
    classNames: ['metrics__chart-time__line'],
    tagName: 'line',
    attributeBindings: ['x1', 'x2', 'y1', 'y2', 'transform'],
    isVisible: (0, _computed.notEmpty)('selectedTimeX'),
    x: (0, _object.computed)('selectedTimeX', function () {
      const origX = !isNaN(this.selectedTimeX) && isFinite(this.selectedTimeX) ? this.selectedTimeX : 0;
      return origX + 0.5;
    }),
    x1: (0, _computed.readOnly)('x'),
    x2: (0, _computed.readOnly)('x'),
    y1: 0,
    y2: (0, _computed.readOnly)('chartDimensions.innerHeight'),
    transform: (0, _object.computed)('chartDimensions.padding.top', function () {
      return `translate(0,${this.get('chartDimensions.padding.top')})`;
    })
  });

  _exports.default = _default;
});