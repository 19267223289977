define("dashboard/adapters/favorite", ["exports", "dashboard/adapters/particleboard", "dashboard/mixins/adapters/refresh-quick-jump-hooks"], function (_exports, _particleboard, _refreshQuickJumpHooks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _particleboard.default.extend(_refreshQuickJumpHooks.default, {});

  _exports.default = _default;
});