define("dashboard/components/production-check", ["exports", "@ember/component", "@ember/object", "ember-concurrency", "rsvp", "dashboard/models/production-check/stack", "dashboard/models/production-check/dyno-size", "dashboard/models/production-check/dyno-redundancy", "dashboard/models/production-check/postgres-production", "dashboard/models/production-check/postgres-high-availability", "dashboard/models/production-check/monitor", "dashboard/models/production-check/logging", "dashboard/models/production-check/custom-maintenance-page", "dashboard/models/production-check/ssl"], function (_exports, _component, _object, _emberConcurrency, _rsvp, _stack, _dynoSize, _dynoRedundancy, _postgresProduction, _postgresHighAvailability, _monitor, _logging, _customMaintenancePage, _ssl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    localChecks: (0, _object.computed)('app', function () {
      return [_stack.default, _dynoSize.default, _dynoRedundancy.default, _postgresProduction.default, _postgresHighAvailability.default, _monitor.default, _logging.default, _customMaintenancePage.default, _ssl.default].map(Check => Check.create({
        app: this.get('app')
      }));
    }),
    fetchProductionCheckData: (0, _emberConcurrency.task)(function* () {
      // Use this hash to preload any data that is required for the production
      // check before rendering.
      yield (0, _rsvp.hash)({
        attachments: this.app.hasMany('attachments').reload().then(attachments => {
          return (0, _rsvp.all)(attachments.map(attachment => attachment.belongsTo('addon').reload()));
        }),
        formations: this.app.hasMany('formations').reload(),
        configVars: this.app.belongsTo('configVars').reload(),
        sniEndpoints: this.app.hasMany('sniEndpoints').reload()
      });
    }).on('didReceiveAttrs')
  });

  _exports.default = _default;
});