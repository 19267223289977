define("dashboard/components/app/deploy/git-hub", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    connectedServices: (0, _computed.readOnly)('app.services')
  });

  _exports.default = _default;
});