define("dashboard/components/app-manifest-editor", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'form',
    classNames: ['list-group', 'list-group-lg'],
    ignoredAddons: [],
    hasConnectedRepository: (0, _computed.reads)('app.hasConnectedRepository'),
    connectedRepositoryUrl: (0, _computed.reads)('app.repositoryUrl'),
    connectedRepositoryName: (0, _computed.reads)('app.repositoryName'),
    appJSONDescription: (0, _object.computed)('hasConnectedRepository', function () {
      if (this.hasConnectedRepository) {
        return 'This file will be committed to your project as app.json';
      } else {
        return 'Name this file app.json and add it to the root of your project';
      }
    }),
    formation: (0, _object.computed)('app.formations.@each.type', 'app.formations.@each.size', 'app.formations.@each.quantity', function () {
      let formations = this.get('app.formations');

      if (formations) {
        formations = this.downsizeFormations(formations);
        return formations.reduce((memo, formation) => {
          const type = (0, _object.get)(formation, 'type'); // do not serialize the formation's type

          delete formation.type;
          memo[type] = formation;
          return memo;
        }, {});
      }
    }),

    /*
     * Always decrease to the cheapest formation allowed.
     * - With 1 or 2 processes, exclude the size and use the default. This
     *   allows that personal apps to use Free dynos and org apps to use
     *   Standard-1X
     * - With > 2 processes, specify the size as Standard-1X explicitly. This will
     *   avoid personal apps erroring with "Cannot run more than 2 Free size dynos"
     */
    downsizeFormations(formations) {
      const baseAttrs = {
        quantity: 1
      };

      if (formations.length > 2) {
        baseAttrs.size = 'Standard-1X';
      }

      return formations.map(formation => {
        return Object.assign({
          type: (0, _object.get)(formation, 'type')
        }, baseAttrs);
      });
    },

    filteredAddons: (0, _computed.filter)('addons', function (addon) {
      return !this.ignoredAddons.includes(addon.get('providerName'));
    }),
    addonNames: (0, _computed.mapBy)('filteredAddons', 'providerName'),
    name: (0, _computed.or)('predefinedAppName', 'githubAppLink.repoName', 'connectedRepositoryName'),
    scripts: (0, _object.computed)('postDeployScript', 'preDestroyScript', function () {
      const postDeploy = this.postDeployScript;
      const preDestroy = this.preDestroyScript;
      const scripts = {};

      if (postDeploy) {
        scripts.postdeploy = postDeploy;
      }

      if (preDestroy) {
        scripts['pr-predestroy'] = preDestroy;
      }

      return scripts;
    }),
    // A flattened array of all config var names used by addons
    addonsConfigVars: (0, _object.computed)('filteredAddons.config_vars.[]', function () {
      return [].concat.apply([], this.filteredAddons.mapBy('config_vars'));
    }),
    postgresAddons: (0, _computed.filterBy)('filteredAddons', 'isPostgres', true),
    hasPostgresAddon: (0, _computed.notEmpty)('postgresAddons'),
    disallowedConfigVars: (0, _object.computed)('addonsConfigVars', 'hasPostgresAddon', function () {
      let keys = this.addonsConfigVars;

      if (this.hasPostgresAddon) {
        keys = ['DATABASE_URL'].concat(keys);
      }

      return keys;
    }),
    allConfigVars: (0, _computed.map)('parentConfigVars', parentConfigVar => parentConfigVar.toAppJSONConfigVar()),
    configVarSorting: ['key'],
    allSortedConfigVars: (0, _computed.sort)('allConfigVars', 'configVarSorting'),
    // Filtered config vars, without add-ons config
    configVars: (0, _object.computed)('allSortedConfigVars', 'disallowedConfigVars', function () {
      const allConfigVars = this.allSortedConfigVars;
      const disallowedConfigVars = this.disallowedConfigVars;
      return allConfigVars.filter(configVar => {
        return !disallowedConfigVars.includes(configVar.get('key'));
      });
    }),
    env: (0, _object.computed)('configVars.@each.key', 'configVars.@each.serialized', function () {
      // Convert each AppJSONConfigVar to a key:value object and merge them together
      const vars = this.configVars.filterBy('isNotEmpty').mapBy('serialized');
      const args = [{}].concat(vars);
      return args.length > 1 ? $.extend.apply($, args) : {};
    }),
    filteredBuildpacks: (0, _computed.filterBy)('app.buildpackInstallations', 'name'),
    buildpackSorting: ['ordinal'],
    sortedBuildpacks: (0, _computed.sort)('filteredBuildpacks', 'buildpackSorting'),
    buildpackUrls: (0, _computed.map)('sortedBuildpacks', buildpack => {
      return {
        url: buildpack.get('name')
      };
    }),
    selectedStack: (0, _object.computed)('app.stack.name', function () {
      return this.get('app.stack.name');
    }),
    manifestAttributes: (0, _object.computed)('addonNames.[]', 'buildpackUrls.[]', 'description', 'env', 'formation', 'name', 'scripts', 'selectedStack', function () {
      return {
        name: this.name,
        description: this.description,
        scripts: this.scripts,
        env: this.env,
        formation: this.formation,
        addons: this.addonNames,
        buildpacks: this.buildpackUrls,
        stack: this.selectedStack
      };
    }),
    actions: {
      save() {
        this.onSave(this.manifestAttributes);
      }

    }
  });

  _exports.default = _default;
});