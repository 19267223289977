define("dashboard/templates/components/datastore/postgres/fork-database", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ddQu8vHe",
    "block": "[[[10,0],[14,0,\"flex justify-between items-center pv4 ph0 ma0 bb b--light-silver\"],[12],[1,\"\\n  \"],[10,\"header\"],[12],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"normal purple f3 mt0 mb0\"],[12],[1,\"Fork Database\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"lh-copy dark-gray mv4\"],[12],[1,\"Clone this database, creating an independent, unrelated copy.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],[[24,0,\"hk-button--secondary\"]],[[\"@query\"],[[28,[37,1],null,[[\"fork\"],[\"true\"]]]]],[[\"default\"],[[[[1,\"Fork Database...\"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"  \"],[8,[39,3],null,[[\"@datastore\",\"@plans\",\"@closeFork\"],[[30,2],[30,3],[30,4]]],null],[1,\"\\n\"]],[]],null]],[\"@fork\",\"@datastore\",\"@plans\",\"@closeFork\"],false,[\"link-to\",\"hash\",\"if\",\"datastore/postgres/fork-database-slide-panel\"]]",
    "moduleName": "dashboard/templates/components/datastore/postgres/fork-database.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});