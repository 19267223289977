define("dashboard/components/identity-provider/certificate-list", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "@ember/debug", "dashboard/config/environment"], function (_exports, _component, _computed, _service, _debug, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    store: (0, _service.inject)(),
    isEditingCertificate: false,
    selectedCertificate: null,
    sortingKeys: ['expiresAt', 'name'],
    atMaxCertificates: (0, _computed.gte)('persistedCertificates.length', _environment.default.ssoMaxCertificates),
    persistedCertificates: (0, _computed.readOnly)('identityProvider.persistedCertificates'),
    sortedCertificates: (0, _computed.sort)('persistedCertificates', 'sortingKeys'),
    activeCertificates: (0, _computed.filterBy)('sortedCertificates', 'isExpired', false),
    expiredCertificates: (0, _computed.filterBy)('sortedCertificates', 'isExpired', true),

    didReceiveAttrs() {
      this._super(...arguments);

      (false && !(this.identityProvider) && (0, _debug.assert)('identityProvider must be passed to {{identity-provider/certificate-list ...}}', this.identityProvider));
    },

    actions: {
      createCertificate() {
        const identityProvider = this.identityProvider;
        const certificate = this.store.createRecord('identity-provider-certificate', {
          identityProvider
        });
        this.set('selectedCertificate', certificate);
        this.set('isEditingCertificate', true);
      },

      editCertificate(certificate) {
        this.set('selectedCertificate', certificate);
        this.set('isEditingCertificate', true);
      }

    }
  });

  _exports.default = _default;
});