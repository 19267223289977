define("dashboard/templates/components/metrics/chart-controls/language-metrics-setup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/tF7cMDn",
    "block": "[[[10,0],[14,0,\"mh3 mv1 f5 alert alert-warning\"],[12],[1,\"\\n  \"],[10,2],[14,0,\"anim-fade-in\"],[12],[1,\"\\n    \"],[1,[30,0,[\"setupDescription\"]]],[1,\"\\n    \"],[10,3],[15,6,[30,0,[\"setupLink\"]]],[14,\"target\",\"_blank\"],[14,0,\"hk-link\"],[12],[1,[30,0,[\"setupLinkText\"]]],[13],[1,\".\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/language-metrics-setup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});