define("dashboard/models/enterprise-account/license-usage", ["exports", "ember-data/model", "ember-data/attr", "@ember/object", "@ember/object/computed", "ember-data/relationships", "moment", "dashboard/mixins/models/enterprise-license"], function (_exports, _model, _attr, _object, _computed, _relationships, _moment, _enterpriseLicense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_enterpriseLicense.default, {
    // --------------------------------------------------------------------------
    // Attributes
    licenseType: (0, _attr.default)('string'),
    licenseQuantity: (0, _attr.default)('number'),
    usage: (0, _attr.default)('number'),
    month: (0, _attr.default)('string'),
    enterpriseAccount: (0, _relationships.belongsTo)('enterprise-account'),
    // Aliases for backwards compatibility (Enterprise Teams predated Enterprise Accounts)
    code: (0, _computed.alias)('licenseType'),
    normalizedCode: (0, _computed.alias)('licenseType'),
    qty: (0, _computed.alias)('licenseQuantity'),
    consumed: (0, _computed.alias)('usage'),
    period: (0, _computed.alias)('month'),
    startDate: (0, _computed.alias)('month'),
    endDate: (0, _computed.alias)('month'),
    isActive: true,
    // --------------------------------------------------------------------------
    // Properties
    date: (0, _object.computed)('month', function () {
      const month = this.month;

      if (month) {
        const date = `${month}-01`;
        return (0, _moment.default)(date).toDate();
      }
    }),
    currentUsage: (0, _object.computed)('isCurrency', 'usage', function () {
      const usage = this.usage || 0;

      if (this.isCurrency) {
        return usage * 100; // convert dollars to cents
      } else {
        return usage;
      }
    })
  });

  _exports.default = _default;
});