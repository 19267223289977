define("dashboard/components/account/mfa-status", ["exports", "@ember/component", "dashboard/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HerokuIdentity = _environment.default['heroku-identity'];

  var _default = _component.default.extend({
    vaasUrl: HerokuIdentity.mfaUrl
  });

  _exports.default = _default;
});