define("dashboard/templates/components/sub-nav-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZHPj5lQE",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@route\",\"@models\",\"@query\",\"@current-when\"],[[30,2],[30,1],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[[30,0,[\"iconName\"]],24,[30,0,[\"malibuIconClass\"]],[30,0,[\"name\"]]]],null],[1,\"\\n    \"],[10,1],[14,0,\"sub-nav-item-name gray\"],[12],[1,[30,0,[\"name\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"isBeta\"]],[[[1,\"      \"],[10,1],[14,0,\"hk-badge--beta\"],[12],[1,\"Beta\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"  \"],[8,[39,1],null,[[\"@route\",\"@model\",\"@current-when\"],[[30,2],[30,5],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[[30,0,[\"iconName\"]],24,[30,0,[\"malibuIconClass\"]],[30,0,[\"name\"]]]],null],[1,\"\\n    \"],[10,1],[14,0,\"sub-nav-item-name gray\"],[12],[1,[30,0,[\"name\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"isBeta\"]],[[[1,\"      \"],[10,1],[14,0,\"hk-badge--beta\"],[12],[1,\"Beta\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\",\"@current-when\"],[[30,2],[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[[30,0,[\"iconName\"]],24,[30,0,[\"malibuIconClass\"]],[30,0,[\"name\"]]]],null],[1,\"\\n    \"],[10,1],[14,0,\"sub-nav-item-name gray\"],[12],[1,[30,0,[\"name\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"isBeta\"]],[[[1,\"      \"],[10,1],[14,0,\"hk-badge--beta\"],[12],[1,\"Beta\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]]],[\"@models\",\"@link\",\"@query\",\"@current-when\",\"@model\"],false,[\"if\",\"link-to\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/sub-nav-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});