define("dashboard/models/team/license", ["exports", "ember-data/model", "ember-data/attr", "@ember/object", "@ember/object/computed", "moment"], function (_exports, _model, _attr, _object, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SPACE_CODE = _exports.PARTNER_ADDON_CODE = _exports.LICENSE_TYPES = _exports.DYNO_UNIT_CODE = _exports.DYNO_CODE = _exports.DATA_ADDON_CODE = _exports.CONNECT_CODE = _exports.CODE_PRIORITY = _exports.ADDON_CODE = void 0;
  const DYNO_CODE = 'dyn';
  _exports.DYNO_CODE = DYNO_CODE;
  const DYNO_UNIT_CODE = 'dynunit';
  _exports.DYNO_UNIT_CODE = DYNO_UNIT_CODE;
  const ADDON_CODE = 'add';
  _exports.ADDON_CODE = ADDON_CODE;
  const PARTNER_ADDON_CODE = 'partner';
  _exports.PARTNER_ADDON_CODE = PARTNER_ADDON_CODE;
  const DATA_ADDON_CODE = 'data';
  _exports.DATA_ADDON_CODE = DATA_ADDON_CODE;
  const SPACE_CODE = 'space';
  _exports.SPACE_CODE = SPACE_CODE;
  const CONNECT_CODE = 'connect';
  _exports.CONNECT_CODE = CONNECT_CODE;
  const LICENSE_TYPES = {
    DYNO_CODE,
    DYNO_UNIT_CODE,
    ADDON_CODE,
    PARTNER_ADDON_CODE,
    DATA_ADDON_CODE,
    SPACE_CODE,
    CONNECT_CODE
  };
  _exports.LICENSE_TYPES = LICENSE_TYPES;
  const CODE_PRIORITY = [DYNO_CODE, DYNO_UNIT_CODE, SPACE_CODE, CONNECT_CODE, DATA_ADDON_CODE, PARTNER_ADDON_CODE, ADDON_CODE];
  /**
   * @class License
   * @extends ember-data/model
   */

  _exports.CODE_PRIORITY = CODE_PRIORITY;

  var _default = _model.default.extend({
    // --------------------------------------------------------------------------
    // Attributes
    code: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    qty: (0, _attr.default)('number'),
    consumed: (0, _attr.default)('number'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    period: (0, _attr.default)('string'),
    // --------------------------------------------------------------------------
    // Properties
    isDynoUnit: (0, _computed.equal)('code', DYNO_UNIT_CODE),
    isMonthlyDyno: (0, _computed.equal)('code', DYNO_CODE),
    isDyno: (0, _computed.or)('isDynoUnit', 'isMonthlyDyno'),
    isAddon: (0, _computed.equal)('code', ADDON_CODE),
    isPartnerAddon: (0, _computed.equal)('code', PARTNER_ADDON_CODE),
    isDataAddon: (0, _computed.equal)('code', DATA_ADDON_CODE),
    isSpace: (0, _computed.equal)('code', SPACE_CODE),
    isConnect: (0, _computed.equal)('code', CONNECT_CODE),
    hasQuota: (0, _computed.gt)('qty', 0),

    /**
     * Returns a meaningful label dependent upon the license type.
     *
     * @property label
     * @type {String}
     */
    label: (0, _object.computed)('isDyno', 'isAddon', 'isPartnerAddon', 'isDataAddon', 'isSpace', 'isConnect', function () {
      if (this.isDyno) {
        return 'Dyno units';
      } else if (this.isAddon) {
        return 'General Add-on credits';
      } else if (this.isPartnerAddon) {
        return 'Partner Add-on credits';
      } else if (this.isDataAddon) {
        return 'Heroku Data Add-on credits';
      } else if (this.isSpace) {
        return 'Heroku Private Spaces';
      } else if (this.isConnect) {
        return 'Heroku Connect rows';
      }
    }),

    /**
     * Returns label with the appropriate verb appended.
     *
     * @property labelForUsage
     * @type {String}
     */
    labelForUsage: (0, _object.computed)('isConnect', 'label', function () {
      const desc = this.isConnect ? 'synced' : 'used';
      return `${this.label} ${desc}`;
    }),

    /**
     * The periods as a date object.
     *
     * @property periodAsDate
     * @type {Date|null}
     */
    periodAsDate: (0, _object.computed)('periodStart', function () {
      const periodStart = this.periodStart;

      if (periodStart) {
        return (0, _moment.default)(periodStart).toDate();
      }
    }),

    /**
     * Returns periodAsDate or startDate
     *
     * @property date
     * @type {String}
     */
    date: (0, _computed.or)('periodAsDate', 'startDate'),

    /**
     * This is here for backwards compatibility as the invoices use
     * this, but we're not dealing with those in this round.
     *
     * Returns converted dynoUnits for dynhour dyn and dynunit
     * licenses.
     *
     * @property dynoUnits
     * @type {Number|null}
     */
    dynoUnits: (0, _object.computed)('isDyno', 'quota', function () {
      if (this.isDyno) {
        return this.quota;
      }
    }),

    /**
     * This is here for backwards compatibility as the invoices use
     * this, but we're not dealing with those in this round.
     *
     * Returns addon credits for addon licenses.
     *
     * @property addonUnits
     * @type {Number|null}
     */
    addonUnits: (0, _object.computed)('isAddon', 'quota', function () {
      if (this.isAddon) {
        return this.quota;
      }
    }),

    /**
     * Returns the constant DYNO_UNIT_CODE for dynos, otherwise the license’s `code`.
     *
     * @property normalizedCode
     * @type {String}
     */
    normalizedCode: (0, _object.computed)('isDyno', 'code', function () {
      if (this.isDyno) {
        return DYNO_UNIT_CODE;
      } else {
        return this.code;
      }
    }),

    /**
     * True if this license’s `startDate` is in the past and its `endDate` is in
     * the future.
     *
     * @property isActive
     * @type {Boolean}
     */
    isActive: (0, _object.computed)('startDate', 'endDate', function () {
      const now = (0, _moment.default)();
      return (0, _moment.default)(this.startDate).isBefore(now) && (0, _moment.default)(this.endDate).isAfter(now);
    }),

    /**
     * True if the license has a code and qty greater than 0.
     *
     * @property isLicenseValid
     * @type {Boolean}
     */
    isLicenseValid: (0, _object.computed)('code', 'qty', function () {
      return !!this.code && this.qty > 0;
    }),

    /**
     * True if this is a license that deals with currency for `qty` and
     * `consumed`.
     *
     * @property isCurrency
     * @type {Boolean}
     */
    isCurrency: (0, _computed.or)('isAddon', 'isPartnerAddon', 'isDataAddon'),

    /**
     * The total quota for this license.
     *
     * @property quota
     * @type {Number}
     */
    quota: (0, _object.computed)('isCurrency', 'qty', function () {
      const q = this.qty;

      if (this.isCurrency) {
        return q * 100; // convert to cents
      } else {
        return q;
      }
    }),

    /**
     * The total usage for this license.
     *
     * @property currentUsage
     * @type {Number}
     */
    currentUsage: (0, _object.computed)('isCurrency', 'consumed', function () {
      // default to 0 so this works without consumed being set
      const c = this.consumed || 0;

      if (this.isCurrency) {
        return c * 100; // convert to cents
      } else {
        return c;
      }
    }),

    /**
     * @property periodStart
     * @type {String}
     */
    periodStart: (0, _object.computed)('period', function () {
      const period = this.period;

      if (period) {
        return `${period}-01`;
      }
    }),

    // --------------------------------------------------------------------------
    // Methods

    /**
     * Compares priority of this license with `other` and returns a value
     * suitable for use with Array.prototype.sort.
     *
     * @method
     * @param {team/license} other
     * @return {Number} -1, 0, 1
     */
    compare(other) {
      // license type ordering priority from high to low
      const idx1 = CODE_PRIORITY.indexOf(this.code);
      const idx2 = CODE_PRIORITY.indexOf(other.get('code'));

      if (idx1 < idx2) {
        return -1;
      } else if (idx1 > idx2) {
        return 1;
      } else {
        return 0;
      }
    }

  });

  _exports.default = _default;
});