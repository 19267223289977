define("dashboard/adapters/app/webhook", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.webhooks',

    urlForQueryRecord(query) {
      const {
        appName,
        webHookName
      } = query;
      delete query.appName;
      delete query.webHookName;
      return `${this.host}/apps/${appName}/webhooks/${webHookName}`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/webhooks/${id}`;
    },

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/webhooks`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/webhooks/${id}`;
    }

  });

  _exports.default = _default;
});