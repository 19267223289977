define("dashboard/templates/components/pipeline/confirm-delete-review-app-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FS+fLuut",
    "block": "[[[6,[39,0],null,[[\"cancelAction\",\"title\",\"hasCancelButton\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"renderInPlace\"],[[30,1],\"Delete review app\",true,[30,2],\"Delete review app\",\"btn btn-danger\",[30,0,[\"renderInPlace\"]]]],[[\"default\"],[[[[1,\"  This will delete the \"],[10,\"code\"],[12],[1,[30,0,[\"reviewAppName\"]]],[13],[1,\" review app. Any data\\n  belonging to it will be lost.\\n\"]],[]]]]]],[\"@toggleModal\",\"@deleteReviewApp\"],false,[\"hk-modal\"]]",
    "moduleName": "dashboard/templates/components/pipeline/confirm-delete-review-app-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});