define("dashboard/components/metrics/charts/visualizations/go-gc-pause", ["exports", "dashboard/components/metrics/charts/visualizations/gc-time-chart", "@ember/object/computed"], function (_exports, _gcTimeChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _gcTimeChart.default.extend({
    tagName: 'metrics-go-gc-chart',
    'data-test-target': 'metrics-go-chart',
    language: 'Go',
    chartTitle: 'Aggregate Stop the World GC Time',
    devCenterName: 'language-runtime-metrics-go#aggregate-stop-the-world-gc-time',
    isBeta: true,
    testTarget: 'go-time-spent-in-gc',
    hasSplitGenerations: false,
    lengthData: (0, _computed.alias)('data.goGcPauseTimeLength'),
    ratioData: (0, _computed.alias)('data.goGcPauseTimeRatio')
  });

  _exports.default = _default;
});