define("dashboard/components/app/settings/ssl-domain-select", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/utils/endpoint-match-domains"], function (_exports, _component, _object, _computed, _endpointMatchDomains) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    domains: (0, _computed.alias)('endpoint.domains'),

    didRender() {
      this._super(...arguments);

      const matches = this.findMatchingDomains(); // If there are no matches, we want to trigger the next step in the process as soon as this step
      // is entered

      if (!matches.length) {
        this.onEmptyMatch();
      }
    },

    findMatchingDomains() {
      const certDomains = this.endpoint.sslCert.cert_domains;
      const appDomains = this.app.domains.map(domain => domain.hostname);
      const matchedDomains = (0, _endpointMatchDomains.default)(certDomains, appDomains);
      return matchedDomains.map(domainName => {
        const domain = this.app.domains.findBy('hostname', domainName);
        return {
          domain,
          checked: this.endpoint.domains.includes(domain)
        };
      });
    },

    matchingDomains: (0, _object.computed)('app.domains.[]', 'endpoint.domains.[]', 'endpoint.sslCert.cert_domains', function () {
      return this.findMatchingDomains();
    }),
    actions: {
      addDomainToCert(domain, checked) {
        if (checked) {
          domain.set('sniEndpoint', this.endpoint);
          this.selectedDomains.pushObject(domain);
        } else {
          domain.set('sniEndpoint', null);
          this.selectedDomains.removeObject(domain);
        }
      },

      selectAll() {
        this.matchingDomains.forEach(_ref => {
          let {
            domain
          } = _ref;
          domain.set('sniEndpoint', this.endpoint);
          this.selectedDomains.pushObject(domain);
        });
      },

      deselectAll() {
        this.matchingDomains.forEach(_ref2 => {
          let {
            domain
          } = _ref2;
          domain.set('sniEndpoint', null);
          this.selectedDomains.removeObject(domain);
        });
      }

    }
  });

  _exports.default = _default;
});