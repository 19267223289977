define("dashboard/templates/team/space/apps", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oMJ5wHlV",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"header-actions\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@controller\"],[[30,0]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"sortedApps\"]],[[[41,[30,0,[\"appFavorites\"]],[[[1,\"    \"],[10,0],[14,0,\"list-group app-list\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"bg-lightest-silver pt4 pb2 bb b--light-silver\"],[14,1,\"favorites-container\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"appFavorites\"]]],null]],null],null,[[[1,\"          \"],[10,0],[14,0,\"mb2 shadow-outer-1\"],[12],[1,\"\\n            \"],[1,[28,[35,5],null,[[\"app\",\"isFavorite\"],[[30,1],true]]]],[1,\"\\n            \"],[1,[28,[35,6],null,[[\"app\",\"class\"],[[30,1],\"dn db-l\"]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"app-list pv4 hk-hide-bb-last-row\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"appNonFavorites\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,5],null,[[\"app\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,7],null,[[\"team\",\"space\"],[[30,0,[\"team\"]],[30,0,[\"currentSpace\"]]]]]],[1,\"\\n\"]],[]]]],[\"app\",\"app\"],false,[\"send-to-outlet\",\"route-outlets/apps/new-dropdown\",\"if\",\"each\",\"-track-array\",\"apps-list-item\",\"app-item-favorite-tray\",\"apps-empty-state\"]]",
    "moduleName": "dashboard/templates/team/space/apps.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});