define("dashboard/components/enterprise-usage/progress", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "dashboard/helpers/currency-format", "@ember/object/computed", "moment", "numeral"], function (_exports, _component, _object, _runloop, _currencyFormat, _computed, _moment, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Computed Properties
    title: (0, _computed.readOnly)('license.label'),
    max: (0, _computed.readOnly)('license.quota'),
    value: (0, _computed.reads)('license.currentUsage'),
    hasMax: (0, _computed.readOnly)('license.hasQuota'),
    isCurrency: (0, _computed.bool)('license.isCurrency'),
    isConnect: (0, _computed.bool)('license.isConnect'),
    usageVerb: (0, _object.computed)('isConnect', function () {
      return this.isConnect ? 'synced' : 'used';
    }),
    isOverQuota: (0, _object.computed)('value', 'max', function () {
      return this.value > this.max;
    }),
    daysInMonth: (0, _object.computed)(function () {
      return (0, _moment.default)().daysInMonth();
    }),
    currentDayOfTheMonth: (0, _object.computed)(function () {
      return (0, _moment.default)().date();
    }),
    remainingDaysInMonth: (0, _object.computed)('daysInMonth', 'currentDayOfTheMonth', function () {
      return this.daysInMonth - this.currentDayOfTheMonth;
    }),
    fractionUsed: (0, _object.computed)('value', 'max', function () {
      return this.value / this.max;
    }),
    fractionOfMonthPassed: (0, _object.computed)('currentDayOfTheMonth', 'daysInMonth', function () {
      return this.currentDayOfTheMonth / this.daysInMonth;
    }),
    aboveOrBelow: (0, _object.computed)('fractionUsed', 'fractionOfMonthPassed', function () {
      // Within 5% is considered on track with estimate
      const threshold = 0.05;
      const diff = this.fractionUsed - this.fractionOfMonthPassed;

      if (diff > threshold) {
        return 'above';
      }

      if (diff < -threshold) {
        return 'below';
      }

      if (diff >= -threshold && diff <= threshold) {
        return 'about in line with';
      }
    }),
    formattedValue: (0, _object.computed)('value', 'isCurrency', function () {
      if (this.isCurrency) {
        return (0, _currencyFormat.currency)(this.value, {
          unit: 'cents'
        });
      } else {
        return (0, _numeral.default)(this.value).format('0,0.[00]');
      }
    }),
    formattedMax: (0, _object.computed)('max', 'isCurrency', function () {
      if (this.isCurrency) {
        return (0, _currencyFormat.currency)(this.max, {
          unit: 'cents'
        });
      } else {
        return (0, _numeral.default)(this.max).format('0,0');
      }
    }),

    // --------------------------------------------------------------------------
    // Lifecycle
    didInsertElement() {
      this._super(...arguments); // Set the values to 0 and then immediately back to their actual value. This
      // works with a CSS transition to animate the appearance of the line


      const value = this.value;
      const currentDayOfTheMonth = this.currentDayOfTheMonth;

      _runloop.run.scheduleOnce('afterRender', this, this._resetValues);

      _runloop.run.next(this, function () {
        this.set('value', value);
        this.set('currentDayOfTheMonth', currentDayOfTheMonth);
      });
    },

    // --------------------------------------------------------------------------
    // Helpers
    _resetValues() {
      this.set('value', 0);
      this.set('currentDayOfTheMonth', 0);
    }

  });

  _exports.default = _default;
});