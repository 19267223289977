define("dashboard/mixins/metrics/controllable-chart", ["exports", "@ember/object/computed", "@ember/object/mixin", "jquery"], function (_exports, _computed, _mixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a mixin for charts that allows certain pointer event actions
   * to control the `cursorPosition` values
   *
   */
  var _default = _mixin.default.create({
    isFocused: (0, _computed.notEmpty)('cursorChartXLocal'),

    // Handle touch/hover X-motion
    touchStart(event) {
      this._pageXChange(event.originalEvent.changedTouches[0].pageX);
    },

    touchEnd() {
      this._left();
    },

    touchMove(event) {
      this._pageXChange(event.originalEvent.changedTouches[0].pageX);
    },

    mouseMove(event) {
      this._pageXChange(event.pageX);
    },

    mouseLeave() {
      this._left();
    },

    isNotSelectable: (0, _computed.notEmpty)('messageContent'),
    isSelectable: (0, _computed.not)('isNotSelectable'),

    _left() {
      if (this.isSelectable) {
        this._pageXLeave();
      }
    },

    /**
     * determine the cursorPosition's on a chart given the
     * pageX value
     */
    _pageXChange(pageX) {
      if (this.isSelectable) {
        const width = this.width;
        const containerLeft = (0, _jquery.default)(this.element).find('.metrics__chart__image').offset().left;
        const paddingLeft = this.get('padding.left');
        const x = Math.floor(pageX - containerLeft);
        const chartX = x - paddingLeft;
        const isTooFarLeft = x <= paddingLeft;
        const isTooFarRight = x >= width - this.get('padding.right');
        const isOutsideOfChart = isTooFarLeft || isTooFarRight;

        if (isOutsideOfChart) {
          this._pageXLeave();
        } else {
          this.set('cursorXLocal', x);
          this.set('cursorChartXLocal', chartX);
          this.send('selectedTime');
        }
      }
    },

    /**
     * unsets the cursor position
     */
    _pageXLeave() {
      if (this.cursorXLocal && this.cursorChartXLocal) {
        this.set('cursorXLocal', null);
        this.set('cursorChartXLocal', null);
        this.send('blurred');
      }
    },

    actions: {
      selectedTime() {
        const time = this.cursorTimeLocal;
        this.updateSelectedTime(time);
      },

      blurred() {
        this.updateSelectedTime(null);
      }

    }
  });

  _exports.default = _default;
});