define("dashboard/templates/components/app/deploy/github/wait-for-ci-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PH1zZQ/e",
    "block": "[[[10,0],[14,0,\"flex\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"normal f5 mr2\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,\"checked\",[30,0,[\"githubAppLink\",\"waitForCi\"]]],[16,\"disabled\",[30,0,[\"isSavingWaitForCi\"]]],[24,0,\"nudge-up--1\"],[24,4,\"checkbox\"],[4,[38,0],[[30,0],\"toggleWaitForCi\"],[[\"on\"],[\"change\"]]],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"pl1\"],[12],[1,\"Wait for CI to pass before deploy\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isSavingWaitForCi\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"f5 gray\"],[12],[1,\"\\n  Only enable this option if you have a Continuous Integration service configured on your repo.\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"if\",\"malibu-loading-spinner\"]]",
    "moduleName": "dashboard/templates/components/app/deploy/github/wait-for-ci-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});