define("dashboard/routes/team/space/settings", ["exports", "rsvp", "@ember/routing/route"], function (_exports, _rsvp, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      const space = this.modelFor('team.space');
      const logDrain = this.store.queryRecord('space/log-drain', {
        spaceId: space.get('id')
      }).catch(() => []);
      return (0, _rsvp.hash)({
        space,
        logDrain
      });
    }

  });

  _exports.default = _default;
});