define("dashboard/templates/components/pipeline/app-list-favorite", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UD+6traV",
    "block": "[[[1,[28,[35,0],null,[[\"pipeline\",\"spaceName\"],[[30,0,[\"pipeline\"]],[30,0,[\"spaceName\"]]]]]],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"appsToDisplay\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"bg-white bb b--light-silver\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"app\",\"isFavorite\"],[[30,1],true]]]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"app\"],[[30,1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"app\"],false,[\"app-list-pipeline-item\",\"each\",\"-track-array\",\"apps-list-item\",\"app-item-favorite-tray\"]]",
    "moduleName": "dashboard/templates/components/pipeline/app-list-favorite.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});