define("dashboard/mixins/new-app-route", ["exports", "@ember/runloop", "@ember/object/mixin"], function (_exports, _runloop, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('selectedRegionName', 'us');
      }
    },

    setupControllerQueryParams(orgNameParam) {
      if (orgNameParam === 'personal-apps') {
        (0, _runloop.run)(() => {
          // The run loop is comin' and you gotta take of it.
          // The only way to get replaceWith to work here is to schedule it
          // on a subsequent run loop after setupController.
          (0, _runloop.next)(this, () => {
            return this.replaceWith({
              queryParams: {
                org: null
              }
            });
          });
        });
      } else {
        (0, _runloop.run)(() => {
          (0, _runloop.next)(this, () => {
            return this.replaceWith({
              queryParams: {
                org: this.getValidOrgName(orgNameParam)
              }
            });
          });
        });
      }
    },

    // Return the org name if it's valid, or the default org if it's valid, or null
    getValidOrgName(name) {
      // if the 'org' we are creating an app for is personal apps then short circuit
      // this function an return null which will force the template to load with
      // personal apps pre-selected
      if (name === 'personal-apps') {
        return null;
      } else if (this.isValidOrg(name)) {
        return name;
      } else {
        return this.validDefaultOrgName();
      }
    },

    validDefaultOrgName() {
      const protectedModel = this.modelFor('protected');
      const defaultOrgName = protectedModel.userPreferences.get('defaultOrganization');

      if (this.isValidOrg(defaultOrgName)) {
        return defaultOrgName;
      } else {
        return null;
      }
    },

    isValidOrg(name) {
      const team = this.modelFor('protected').teams.rejectBy('role', 'collaborator').findBy('name', name);
      return !!team;
    }

  });

  _exports.default = _default;
});