define("dashboard/components/app/datastore/backup-row", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "dashboard/utils/metrics/unit-converters", "ember-concurrency"], function (_exports, _component, _object, _tracking, _unitConverters, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "deletePromise", _descriptor, this);

      _initializerDefineProperty(this, "downloadPromise", _descriptor2, this);
    }

    async onDownload() {
      this.downloadPromise = this.args.onDownload(this.args.backup);

      try {
        await this.downloadPromise;
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }
    }

    async onDelete() {
      this.deletePromise = this.args.onDelete(this.args.backup);

      try {
        await this.deletePromise;
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }
    }

    get isUnfinished() {
      return !this.isFinished;
    }

    get isFinished() {
      return Boolean(this.args.backup.finishedAt);
    }

    get isSucceeded() {
      return Boolean(this.args.backup.succeeded);
    }

    get isRunning() {
      return this.isUnfinished;
    }

    get isFailed() {
      return this.isFinished && !this.isSucceeded;
    }

    get showButtons() {
      return this.isFinished && this.isSucceeded;
    }

    get size() {
      const bytes = this.args.backup.processedBytes;

      if (!bytes) {
        return '';
      }

      const {
        key,
        conversion
      } = (0, _unitConverters.scalingBytes)([[null, bytes]]);
      return `${(bytes / conversion).toFixed(1)} ${key}`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "deletePromise", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "downloadPromise", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "onDownload", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDownload"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDelete"), _class2.prototype)), _class2);

  _exports.default = _class;
});