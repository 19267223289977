define("dashboard/components/net-promoter-score/reasons-select", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex', 'flex-wrap', 'justify-between', 'mb2'],

    get selectedReasons() {
      return this.get('reasons').filterBy('isSelected').mapBy('value');
    },

    reasonsObserver: (0, _object.observer)('reasons.@each.isSelected', function () {
      this.get('onSelectReasons')(this.selectedReasons);
    }),
    onSelectReasons: null
  });

  _exports.default = _default;
});