define("dashboard/components/app/addons-list", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "rsvp", "dashboard/mixins/purple-list", "dashboard/utils/addon-services-fetcher", "dashboard/utils/addon-data-massaging", "dashboard/mixins/addons-totals", "jquery"], function (_exports, _component, _object, _computed, _service, _rsvp, _purpleList, _addonServicesFetcher, _addonDataMassaging, _addonsTotals, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    allAttachmentsAddons,
    massageAttachments,
    servicePlansSetup
  } = _addonDataMassaging.default;

  var _default = _component.default.extend(_purpleList.default, _addonsTotals.default, {
    classNames: ['addons-list', 'limit-width'],
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    addonsUrl: 'https://elements.heroku.com/addons',
    addonServicesArrayIsLoaded: (0, _computed.bool)('addonServicesArray'),
    attachmentsBilledToThisApp: (0, _computed.filterBy)('attachments', 'isAttached', false),
    sortedAddons: (0, _object.computed)('addons.@each.providerDescription', function () {
      return this.addons.sortBy('providerDescription');
    }),
    appNameConfirmationDoesNotMatch: (0, _object.computed)('appNameConfirmation', function () {
      return this.appNameConfirmation !== this.get('app.name');
    }),

    // TODO: Look into whether we need this at all
    didReceiveAttrs() {
      if (this.get('addonsSetupIsReady')) {
        return;
      }

      this.set('addonsSetupIsReady', false);

      this._super(...arguments);

      this.addonsSetup().then(() => {
        this.set('addonsSetupIsReady', true);
      });
    },

    addonsSetup() {
      return allAttachmentsAddons(this.attachments).then(allAddons => {
        allAddons.invoke('fetchAttachments', this.store);
        massageAttachments(this.attachments);
        this.fetchDecoratedAddonServices(this.app, this.team).then(addonServices => {
          this.assignDecoratedServicesToAddons(allAddons, addonServices);
          this.set('addonServicesArray', addonServices.toArray());
        });
        return servicePlansSetup(allAddons, this.store);
      });
    },

    // If a user does not have appropriate permissions this will fail and prevent page access
    fetchDecoratedAddonServices(app, team) {
      if (this.isViewable) {
        return _addonServicesFetcher.default.fetch({
          store: this.store,
          app,
          team
        });
      } else {
        return new _rsvp.default.Promise(function (resolve) {
          resolve([]);
        });
      }
    },

    assignDecoratedServicesToAddons(addons, addonServices) {
      addons.forEach(addon => {
        const addonService = addonServices.findBy('id', addon.get('addon_service.id'));
        addon.set('addon_service', addonService);
      });
    },

    tryToDeleteIt(addon) {
      // First, confirm that they entered the correct app name confirmation
      const appNameConfirmation = this.appNameConfirmation;
      const appName = this.get('app.name');

      if (appNameConfirmation !== appName) {
        // show error
        const errorContainer = this.get('item.errorContainer');
        errorContainer.set('title', 'Could not delete add-on:');
        errorContainer.addObject('Given name does not match the app name.'); // Return a rejected promise so that the async button returns to the correct state

        return (0, _rsvp.reject)();
      } else {
        return addon.destroyRecord();
      }
    },

    actions: {
      showProvisionModal(addon) {
        this.analytics.logEvent('Add-on Search Result', 'Selected');
        this.transitionToProvisionModal(addon.get('name'));
      },

      refocusAddonsList() {
        (0, _jquery.default)(this.element).find('.addons-table-container').removeClass('unfocused');
      },

      unfocusAddonsList() {
        this.analytics.logEvent('Add-ons', 'Searched');
        (0, _jquery.default)(this.element).find('.addons-table-container').addClass('unfocused');
      },

      // this save is only for edit, not for create
      save() {
        const attachment = this.item;

        this._super(...arguments);

        return attachment.get('addon').then(addon => {
          const app = addon.get('app');

          if (!this.get('item.addon.isValid')) {
            return (0, _rsvp.reject)();
          }

          return addon.save().then(() => {
            this.analytics.logEvent('Add-on', 'Updated');
            const attachments = this.attachments;
            addon.set('app', app); // re-attach the full app object that was clobbered during save

            addon.set('confirmNotAutoNetworked', false); // clear out this value

            attachments.addObject(attachment);
            this.toggleProperty('isAdding');
            this.refreshResourcesRoute();
          }).catch(() => {
            addon.reload();
          });
        });
      },

      deleteAddon() {
        const addon = this.item;
        return this.tryToDeleteIt(addon).then(() => {
          this.analytics.logEvent('Add-on', 'Deleted');
          this.send('toggleDeleteConfirmModal');
        });
      },

      manageAttachmentDetails(addon) {
        this.set('item', addon);
        this.analytics.logEvent('Add-on Attachment', 'Viewed');
        this.router.transitionTo('app.resources.attachments', addon.id);
      },

      clickElementsLink() {
        this.analytics.logEvent('Marketplace Link', 'Clicked', {
          topic: 'addons'
        });
        window.open(this.addonsUrl);
      }

    }
  });

  _exports.default = _default;
});