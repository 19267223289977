define("dashboard/routes/account/verify", ["exports", "dashboard/routes/account/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dashboard.AccountVerifyRoute
   * @extends Dashboard.AccountBaseRoute
   *
   * Redirect to /account/billing and triggers the Credit Card Modal
   */
  var _default = _base.default.extend({
    redirect() {
      this.transitionTo('account.billing').then(transition => {
        transition.send('verifyAccount');
      });
    }

  });

  _exports.default = _default;
});