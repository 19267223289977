define("dashboard/components/did-render-element-emitter", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.reopen({
    /**
     * custom shortcut for function and events that should
     * only run once the component has been completely rendered
     */
    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', this, this.triggerDidRenderElement);
    },

    triggerDidRenderElement() {
      this.trigger('didRenderElement');
    }

  });

  _exports.default = _default;
});