define("dashboard/templates/components/space/settings/log-drain-url", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sAGwjeE/",
    "block": "[[[1,[28,[35,0],null,[[\"label\",\"value\",\"placeholder\",\"inputId\",\"isEditing\",\"onSave\",\"onEdit\",\"allowBlank\"],[\"Log Drain URL\",[30,0,[\"model\",\"logDrain\",\"url\"]],\"e.g. https://your-log-drain.com\",\"space-direct-logging-input\",[30,0,[\"isEditingLogDrainUrl\"]],[28,[37,1],[[30,0],\"save\"],null],[28,[37,1],[[30,0],\"edit\"],null],false]]]],[1,\"\\n\\n\"],[41,[30,0,[\"URLDidChange\"]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"alertType\",\"title\",\"message\",\"classNames\"],[\"success\",\"Success! Direct logging is updated.\",\"Your application logs will drain to this new log drain URL.\",\"form-group\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"hk-inline-edit\",\"action\",\"if\",\"flash-status\"]]",
    "moduleName": "dashboard/templates/components/space/settings/log-drain-url.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});