define("dashboard/components/metrics/process-monitor-configuration", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/string", "@ember/component", "@ember/object", "@ember/service", "numeral"], function (_exports, _computed, _runloop, _string, _component, _object, _service, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: [':metrics__process-monitor-configuration'],
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    sensitivityOptions: (0, _object.computed)(function () {
      return [{
        label: '1 min. (High)',
        value: 1
      }, {
        label: '5 min. (Medium)',
        value: 5
      }, {
        label: '10 min. (Low)',
        value: 10
      }];
    }),
    period: (0, _computed.alias)('model.period'),
    notificationPeriod: (0, _computed.alias)('model.notificationPeriod'),
    notificationPeriodOptions: (0, _object.computed)(function () {
      return [{
        label: 'At most every 5 min.',
        value: 5
      }, {
        label: 'At most every hour',
        value: 60
      }, {
        label: 'At most every day',
        value: 60 * 24
      }];
    }),
    step: (0, _object.computed)('isPercentage', function () {
      if (this.isPercentage) {
        return 0.5;
      } else {
        return 50;
      }
    }),
    shouldShowPreview: (0, _object.computed)('model.name', function () {
      return ['ERROR_RATE', 'LATENCY'].includes(this.get('model.name'));
    }),
    shouldShowPlaybookUrl: (0, _computed.readOnly)('app.team.allowPlaybookUrlInThresholdAlerts'),
    displayMetricValue: (0, _object.computed)('model.value', function () {
      const value = this.get('model.value');

      if (this.isPercentage) {
        // force one decimal place for display purposes
        return (value * 100).toFixed(1);
      } else {
        return value;
      }
    }),
    isNotifying: (0, _computed.or)('model.hasAppChannel', 'model.hasEmailChannel'),
    _monitorHasNonZeroValue: (0, _computed.gt)('model.value', 0),
    _monitorIsNewAndActive: (0, _computed.and)('model.isNew', 'model.isActive', '_monitorHasNonZeroValue'),
    _monitorIsNotNew: (0, _computed.not)('model.isNew'),
    _monitorIsNotNewAndHasChanged: (0, _computed.and)('_monitorIsNotNew', 'model.hasDirtyAttributes', '_monitorHasNonZeroValue'),
    isSavable: (0, _computed.or)('_monitorIsNewAndActive', '_monitorIsNotNewAndHasChanged'),
    isNotSavable: (0, _computed.not)('isSavable'),
    isPercentage: (0, _computed.equal)('unit', '%'),
    max: (0, _object.computed)('isPercentage', function () {
      if (this.isPercentage) {
        return 100;
      } else {
        return 30000;
      }
    }),
    min: (0, _object.computed)('isPercentage', function () {
      if (this.isPercentage) {
        return 0.5;
      } else {
        return 50;
      }
    }),
    isShowingEmailSetup: false,
    persistedRecipients: (0, _computed.filterBy)('recipients', 'isNew', false),
    _isNotAtMaxRecipients: (0, _computed.lte)('persistedRecipients.length', 5),
    _unverifiedRecipients: (0, _computed.filterBy)('persistedRecipients', 'verified', false),
    _hasNoUnverifiedRecipients: (0, _computed.empty)('_unverifiedRecipients'),
    _isNotShowingEmailSetup: (0, _computed.not)('isShowingEmailSetup'),
    isShowingAddNewRecipient: (0, _computed.and)('_hasNoUnverifiedRecipients', '_isNotAtMaxRecipients', '_isNotShowingEmailSetup'),
    notificationHelpText: (0, _object.computed)(function () {
      const pagerDutyHref = 'https://devcenter.heroku.com/articles/metrics#pagerduty-and-additional-email-configuration-optional';
      return (0, _string.htmlSafe)(`Receive email notifications when an alert is triggered. You can also add up to five email address, and use one to <a target='_blank' href='${pagerDutyHref}'>integrate alert notifications with PagerDuty</a>.`);
    }),
    newEmail: '',
    activeEmailRecipients: (0, _computed.filterBy)('persistedRecipients', 'isActive'),
    hasActiveRecipients: (0, _computed.notEmpty)('activeEmailRecipients'),
    actions: {
      async save() {
        const monitor = this.model;

        if (monitor.get('isNew')) {
          monitor.set('formation', this.process);
        }

        const type = this.get('model.name');
        const action = this.get('model.isActive') ? 'enable' : 'disable';
        await monitor.save();
        this.analytics.logMetricsEvent('Monitor', 'Updated', {
          type,
          action
        });

        if (this.onCloseAlertingSection) {
          this.onCloseAlertingSection();
        }
      },

      cancel() {
        this.onCloseAlertingSection();
      },

      toggleShowEmailSetup() {
        this.toggleProperty('isShowingEmailSetup');
      },

      hideEmailSetup() {
        this.set('isShowingEmailSetup', false);
      },

      valueChanged(event) {
        const max = this.max;
        const min = this.min;
        let value = parseFloat(event.target.value); // forces values exceeding max to change to max

        value = value > max ? max : value; // forces values below min to change to min

        value = value <= min ? min : value;

        if (this.isPercentage) {
          // floating point math assistance from Numeral.js
          value = (0, _numeral.default)(value).divide(100).value();
        }

        const model = this.model;
        (0, _runloop.scheduleOnce)('actions', () => {
          model.set('value', value);
          event.target.value = this.displayMetricValue;
        });
      },

      changedNotificationPreference(channel, event) {
        const property = channel === 'email' ? 'hasEmailChannel' : 'hasAppChannel';
        this.set(`model.${property}`, event.target.checked);
      },

      async saveNewEmailRecipient() {
        const recipient = this.store.createRecord('recipient', {
          app: this.app,
          email: this.newEmail,
          template: 'alerting'
        });

        try {
          await recipient.save();
          this.set('justSentConfirmationEmail', true);
          this.toggleProperty('isShowingEmailSetup');
        } catch (e) {
          recipient.rollbackAttributes();
          recipient.unloadRecord();
        }
      },

      removeEmailRecipient(recipient) {
        this.persistedRecipients.removeObject(recipient);
      }

    }
  });

  _exports.default = _default;
});