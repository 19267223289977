define("dashboard/templates/team/switchable/apps-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gvIRZ3Mi",
    "block": "[[[1,[34,0]],[1,\"\\n\"]],[],false,[\"stencil/apps/apps-list\"]]",
    "moduleName": "dashboard/templates/team/switchable/apps-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});