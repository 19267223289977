define("dashboard/templates/components/account/mfa-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MSgAAId/",
    "block": "[[[41,[30,0,[\"mfaEnabled\"]],[[[1,\"  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"success-ring-28\",\"malibu-fill-gradient-green mr2\",24]],null],[1,\"\\n    \"],[10,1],[12],[1,\"Multi-Factor Authentication is \"],[10,\"b\"],[12],[1,\"enabled\"],[13],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-auto justify-end\"],[12],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,0,[\"vaasUrl\"]]]]],[14,0,\"hk-button--secondary\"],[12],[1,\"Manage Multi-Factor Authentication\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"flex flex-column items-center justify-center shadow-outer-1 br1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"pv6 flex flex-column items-center justify-center\"],[12],[1,\"\\n      \"],[10,0],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"mfa-shield\"],[[\"width\"],[\"48px\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"f5 dark-gray pt3 pb1 mh6 tc\"],[12],[1,\"\\n        Multi-Factor Authentication protects your account against common threats like phishing attacks, credential stuffing, and account takeovers. It adds another layer of security to your login process by requiring users to enter two or more pieces of evidence — or factors — in addition to password to prove they’re who they say they are.\\n      \"],[13],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,0,[\"vaasUrl\"]]]]],[14,0,\"hk-button--primary\"],[12],[1,\"Setup Multi-Factor Authentication\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"malibu-icon\",\"svg-jar\"]]",
    "moduleName": "dashboard/templates/components/account/mfa-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});