define("dashboard/templates/components/app/settings/sni-endpoints-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ytcIAkUK",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"sortedEndpoints\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"endpoint\",\"openDetailPanel\"],[[30,1],[28,[37,3],[[30,0],[30,0,[\"openDetailPanel\"]]],null]]]]],[1,\"\\n\"]],[1]],null]],[\"endpoint\"],false,[\"each\",\"-track-array\",\"app/settings/sni-endpoints-list-item\",\"action\"]]",
    "moduleName": "dashboard/templates/components/app/settings/sni-endpoints-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});