define("dashboard/templates/components/log-stream", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HM+DDUBU",
    "block": "[[[10,0],[14,0,\"build-stream-output\"],[12],[1,\"\\n\"],[41,[30,0,[\"stream\",\"connectionIsPending\"]],[[[1,\"    \"],[1,[34,1]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"stream\",\"canStream\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"stream\",\"lines\"]]],null]],null],null,[[[41,[30,1,[\"successfullyParsed\"]],[[[1,\"        \"],[10,\"pre\"],[14,0,\"build-stream-text\"],[12],[10,1],[15,0,[29,[\"log-color log-color--\",[30,1,[\"colorClass\"]]]]],[12],[1,[30,1,[\"timestamp\"]]],[1,\" \"],[1,[30,1,[\"processIdentifier\"]]],[13],[1,\": \"],[1,[30,1,[\"message\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"pre\"],[14,0,\"build-stream-text\"],[12],[1,[30,1,[\"raw\"]]],[13],[1,\"\\n\"]],[]]]],[1]],null]],[]],[[[1,\"    Your browser does not support this feature.\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,\"footer\"],[14,0,\"build-stream-footer\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"autoScroll\"]]]]]],[1,\" Autoscroll with output\\n  \"],[13],[1,\"\\n\\n  \"],[11,3],[24,0,\"hk-link\"],[24,6,\"#\"],[4,[38,5],[[30,0],\"saveLogs\"],null],[12],[1,\"Save\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"line\"],false,[\"if\",\"loading-spinner\",\"each\",\"-track-array\",\"input\",\"action\"]]",
    "moduleName": "dashboard/templates/components/log-stream.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});