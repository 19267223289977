define("dashboard/routes/account/applications/new-authorization", ["exports", "dashboard/routes/account/base", "dashboard/mixins/document-title", "@ember/service"], function (_exports, _base, _documentTitle, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    queryParams: {
      success: {
        replace: true
      }
    },

    model() {
      return this.store.createRecord('authorization', {});
    },

    afterModel() {
      this.analytics.logEvent('Authorizations Create Page', 'Viewed');
    },

    deactivate() {
      const model = this.get('controller.model');

      if (model.get('isNew')) {
        model.unloadRecord();
      }
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('success', null);
    }

  });

  _exports.default = _default;
});