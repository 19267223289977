define("dashboard/components/soft-notification", ["exports", "@ember/object/computed", "@ember/object", "@ember/component", "dashboard/utils/localstorage"], function (_exports, _computed, _object, _component, _localstorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A dismissable flash notification with a primary action. Notification dismissal
   * status is persisted in localStorage.
   *
   *     {{#soft-notification doAction="someActionName" actionText="Some button text"}}
   *       Notification content
   *     {{/soft-notification}}
   *
   * @class Dashboard.SoftNotificationComponent
   * @extends Ember.Component
   * @param {Action} Action on the parent controller to invoke when the button is clicked
   * @param {String} Text for the action button
   * @param {Block}  HTML content for the notification banner
   */
  var _default = _component.default.extend({
    classNames: ['soft-notification hk-message--generic flex items-center'],
    localStorageKey: (0, _object.computed)('actionText', function () {
      return `hide-notification-${this.actionText.dasherize()}-${window.location.pathname}`;
    }),
    isVisible: (0, _computed.not)('isHidden'),
    isHidden: (0, _object.computed)('localStorageKey', function () {
      return JSON.parse(_localstorage.default.getItem(this.localStorageKey));
    }),
    showActionButton: true,
    actions: {
      hide() {
        _localstorage.default.setItem(this.localStorageKey, true);

        this.set('isHidden', true);
      }

    }
  });

  _exports.default = _default;
});