define("dashboard/components/metrics/charts/connected-chart-hover/alert-group", ["exports", "@ember/object/computed", "@ember/utils", "@ember/object", "dashboard/components/metrics/charts/connected-chart-hover/activity-group"], function (_exports, _computed, _utils, _object, _activityGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activityGroup.default.extend({
    classNames: ['metrics__magic-chart-context__group--alerts'],
    models: (0, _computed.reads)('graph.models'),
    _eventsAtSelectedTime: (0, _computed.filter)('modelsAtSelectedTime', function (model) {
      return (0, _utils.isPresent)(model.get('restartType'));
    }),
    _alertsAtSelectedTime: (0, _object.computed)('models.[]', 'maxTime', 'selectedRollupTime', 'rollupDuration', function () {
      const selectedTime = this.selectedRollupTime;
      let alerts = [];

      if (selectedTime) {
        const defaultEndTime = this.maxTime;
        const rollupDuration = this.rollupDuration;
        alerts = this.models.filter(function (model) {
          const start = model.get('startedAt') - rollupDuration;
          const end = model.get('endedAt') || defaultEndTime;
          return selectedTime >= start && selectedTime <= end;
        });
      }

      return alerts;
    }),
    _latencyAlertsAtSelectedTime: (0, _object.computed)('_alertsAtSelectedTime.@each.monitor', function () {
      return this._alertsAtSelectedTime.filterBy('monitor.isLatency', true);
    }),
    _errorRateAlertsAtSelectedTime: (0, _object.computed)('_alertsAtSelectedTime.@each.monitor', function () {
      return this._alertsAtSelectedTime.filterBy('monitor.isErrorRate', true);
    }),
    alertItems: (0, _object.computed)('_errorRateAlertsAtSelectedTime.length', '_latencyAlertsAtSelectedTime.length', function () {
      const errorRateAlerts = this.get('_errorRateAlertsAtSelectedTime.length');
      const latencyAlerts = this.get('_latencyAlertsAtSelectedTime.length');
      const items = [];

      if (errorRateAlerts > 0) {
        let title = 'Alert';
        const description = 'Error Rate';

        if (errorRateAlerts > 1) {
          title += ` x${errorRateAlerts}`;
        }

        items.push(_object.default.create({
          title,
          description
        }));
      }

      if (latencyAlerts > 0) {
        let title = 'Alert';
        const description = 'Resp. Time';

        if (latencyAlerts > 1) {
          title += ` x${latencyAlerts}`;
        }

        items.push(_object.default.create({
          title,
          description
        }));
      }

      return items;
    }),
    eventItems: (0, _computed.map)('_eventsAtSelectedTime', function (model) {
      const restartType = model.get('restartType');
      const count = model.get('count');
      const description = 'User Initiated';
      let title;

      switch (restartType) {
        case 'alert-created':
          title = 'Alert Configured';
          break;

        case 'alert-updated':
          title = 'Alert Settings Updated';
          break;

        case 'alert-enabled':
          title = 'Alert Configured';
          break;

        case 'alert-disabled':
          title = 'Alert Disabled';
          break;
      }

      if (count > 1) {
        title += ` x${count}`;
      }

      return _object.default.create({
        title,
        description
      });
    }),
    items: (0, _computed.union)('alertItems', 'eventItems'),
    isVisible: (0, _computed.gt)('items.length', 0)
  });

  _exports.default = _default;
});