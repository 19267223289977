define("dashboard/models/test-case", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed"], function (_exports, _model, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    testNode: (0, _model.belongsTo)(),
    testRun: (0, _model.belongsTo)(),
    description: (0, _model.attr)('string'),
    diagnostic: (0, _model.attr)('string'),
    directive: (0, _model.attr)('string'),
    passed: (0, _model.attr)('boolean'),
    number: (0, _model.attr)('number'),
    failed: (0, _computed.not)('passed'),
    status: (0, _object.computed)('passed', 'failed', function () {
      if (this.passed) {
        return 'passed';
      } else if (this.failed) {
        return 'failed';
      } else {
        return 'pending';
      }
    })
  });

  _exports.default = _default;
});