define("dashboard/routes/new", ["exports", "jquery", "@ember/routing/route", "rsvp", "@ember/utils", "@ember/service", "@ember/application", "dashboard/mixins/document-title", "dashboard/mixins/new-app-route", "dashboard/models/app-template", "dashboard/models/app-setup.eo", "@ember/object/computed"], function (_exports, _jquery, _route, _rsvp, _utils, _service, _application, _documentTitle, _newAppRoute, _appTemplate, _appSetup, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, _newAppRoute.default, {
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    isReposApiFlagEnabled: (0, _computed.readOnly)('accountFeatures.dashboardRepositoriesApi.enabled'),
    documentTitle: 'Create New App',

    // parse config vars in query params (eg: ?env[foo]=bar)
    beforeModel(transition) {
      // ensure `code` is not a query param
      if (transition.to.queryParams.code) {
        // hide query params from the logo image provider
        // (it can leak via the Referer header)
        delete transition.to.queryParams.code;

        const qs = _jquery.default.param(transition.to.queryParams);

        history.pushState({}, 'uhhhh ooohhh! somersault JUMP!', `/new?${qs}`);
      }

      const overrideConfigVars = {};
      const nestedConfigRegexp = /env\[(.*)\]/;

      for (const key in transition.to.queryParams) {
        const match = key.match(nestedConfigRegexp);

        if (match && match[1]) {
          overrideConfigVars[match[1]] = transition.to.queryParams[key];
        }
      }

      this.set('overrideConfigVars', overrideConfigVars);
    },

    model(params) {
      const newApp = this.store.createRecord('app'); // Bounce to new-app if user is not using template params

      if (params.templateURL) {
        return this.modelWithTemplate(newApp, params);
      } else {
        return this.replaceWith('new-app');
      }
    },

    async _loadAppJson() {
      const [{
        default: AppJSON
      }, {
        default: gh
      }] = await Promise.all([emberAutoImportDynamic("app.json"), emberAutoImportDynamic("github-url-to-object")]);
      return {
        AppJSON,
        gh
      };
    },

    async modelWithTemplate(app, params) {
      const templateURL = params.templateURL;
      const buttonURL = params.buttonURL;
      let githubAuth, githubUser, githubRepo;
      const {
        AppJSON,
        gh
      } = await this._loadAppJson();

      const template = _appTemplate.default.create((0, _application.getOwner)(this).ownerInjection(), {
        gh,
        AppJSON,
        url: templateURL
      });

      this.analytics.logEvent('Heroku Button', 'Viewed');
      return new _rsvp.default.Promise(resolve => {
        this.queryGithubAuth(template.get('endpoint')).then(auth => {
          githubAuth = auth;
        }, () => {
          /*
           * We aren't authenticated with GitHub.
           */
        }).finally(() => {
          githubRepo = this.store.queryRecord('repositories-api-github-repo', {
            repo: template.get('github.repo'),
            owner: template.get('github.user'),
            apiOrigin: template.apiOrigin
          }).catch(response => {
            // fail and set errors states but actually *resolve* in order
            // to allow errors to be rendered rather than
            // passing on the failure to downlevel promises
            if (!response) {
              this.set('missingRepository', true);
            } else if (response.errors[0].status === '403') {
              this.set('isRateLimited', true);
            } else {
              this.set('unknownError', true);
            }
          });
          githubRepo.then(repo => {
            // If there is no default branch value, or the repo lookup failed, we currently default
            // to `master` in order to stay closer to the original behavior.
            const defaultBranch = repo && repo.get('defaultBranch') || 'master';
            template.set('github.defaultBranch', defaultBranch); // The github URL parser that we use to contruct the repo URL that gets fetched when we call
            // `template.fetch` assumes that the default branch is *always* master. Given that we now
            // frequently use `main` as the default branch, we need to check the repo's default branch
            // value *before* actually fetching the app template so that we can intervene and change
            // it from `master` to `main` before making the HTTP request

            if (template.get('github.branch') === 'master' && !template.get('github.isExplicitlyMaster')) {
              // We only do this when the branch has defaulted to "master" because it's possible that an app
              // template does *actually* use a custom branch (rather than the default) and in that case
              // we don't want to override it. Note that we also skip this step if they explicitly specify
              // that they want to use the master branch by including `/tree/master` in the template param
              template.set('github.branch', defaultBranch);
              const tarballUrl = `https://${template.github.apiHost}/repos/${template.github.user}/${template.github.repo}/tarball/${template.github.branch}`;
              template.set('github.tarball_url', tarballUrl);
            }

            const promises = [template.fetch(this.store)];

            if (githubAuth) {
              promises.push(this.get('current.account.githubUser'));
            }

            _rsvp.default.allSettled(promises).then(_ref => {
              let [, accountGithubUser] = _ref;

              if (accountGithubUser && accountGithubUser.state === 'fulfilled') {
                githubUser = accountGithubUser.value;
              }

              const repoIsPrivate = !repo || repo.get('private');
              template.set('repoIsPrivate', repoIsPrivate);
            }).finally(() => {
              resolve(_rsvp.default.hash({
                app,
                appSetup: _appSetup.default.create((0, _application.getOwner)(this).ownerInjection()),
                buttonURL,
                template,
                githubRepo,
                githubRepoErrors: {
                  isRateLimited: this.isRateLimited,
                  missingRepository: this.missingRepository,
                  unknownError: this.unknownError
                },
                githubRepoOwner: {
                  owner: template.get('github.user'),
                  repo: template.get('github.repo')
                },
                githubAuth,
                githubUser,
                spaces: this.store.findAll('space'),
                params,
                paymentMethod: this.store.queryRecord('payment-method', {})
              }));
            });
          });
        });
      });
    },

    afterModel(model) {
      this._super.apply(this, arguments);

      if (!model.template) {
        return;
      } // Iterate over the template addons to make sure they are valid


      return _rsvp.default.all(model.template.get('addons').map(addon => {
        const addonName = addon.plan || addon;
        const nameParts = addonName.split(':');
        const providerName = nameParts[0];
        const planName = nameParts[1];
        return _rsvp.default.hash({
          // Obtain the provider and plan definitions from the platform API
          addonService: this.store.queryRecord('addon-service', {
            providerName
          }),
          addonServicePlans: this.store.query('addon-service-plan', {
            addonServiceNameOrId: providerName
          })
        }).then(response => {
          // Use the specified plan or fallback to the default plan for this provider
          let addonServicePlan = response.addonServicePlans.findBy('name', addonName); // This section of code that determines the default plan is reproduced in the addon model.
          // This code is relevant for the error messages it creates, but the addonServicePlan is overridden
          // later in the addon model anyway.

          if (!(0, _utils.isPresent)(addonServicePlan)) {
            // if a plan isn't specified, try to find the default
            let specifiedDefault; // if the app is in a space, try to find the default plan for a space

            if ((0, _utils.isPresent)(model.params.spaceNameParam)) {
              specifiedDefault = response.addonServicePlans.findBy('spaceDefault', true);
            } // if we weren't in a space or a default space plan was not found, look at the base default


            if (!(0, _utils.isPresent)(specifiedDefault)) {
              specifiedDefault = response.addonServicePlans.findBy('default', true);
            }

            addonServicePlan = specifiedDefault;
          } // Display an error of the addon plan could not be resolved


          if (!addonServicePlan) {
            const message = `The plan "${planName}" for the addon "${providerName}" does not exist. Please ensure that the plan is spelled correctly.`;
            model.template.get('errorContainer.content').addObject(message);
          } else {
            // All is well. Create the attachment and addon using the data from the platform API
            return this.store.createRecord('addon', {
              plan: addonServicePlan.get('name'),
              addonService: response.addonService,
              addonServicePlan,
              billedPrice: {
                cents: addonServicePlan.get('price.cents')
              }
            });
          }
        }, () => {
          // Display an error if the addon provider could not be resolved
          const message = `The addon "${providerName}" does not exist. Please ensure that the addon is spelled correctly.`;
          model.template.get('errorContainer.content').addObject(message);
        });
      })).then(addons => {
        model.addons = addons.compact();
      });
    },

    renderTemplate() {
      this.render('new', {
        into: 'protected'
      });
    },

    // redefine setup to set the overrideConfigVars (see beforeModel)
    setupController(controller, model) {
      controller.set('model', model);
      controller.set('overrideConfigVars', this.overrideConfigVars);
      const orgNameParam = model.params.orgNameParam;
      this.setupControllerQueryParams(orgNameParam);
    },

    async queryGithubAuth(endpoint) {
      if (!this.isReposApiFlagEnabled) {
        return await this.store.queryRecord('github-auth', {
          endpoint
        });
      } else {
        return;
      }
    },

    actions: {
      completeGithubConnection() {
        const parentRoute = (0, _application.getOwner)(this).lookup('route:new');
        parentRoute.refresh();
      }

    }
  });

  _exports.default = _default;
});