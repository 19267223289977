define("dashboard/components/ci/test-node-list", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "dashboard/models/test-run", "jquery"], function (_exports, _component, _object, _computed, _runloop, _testRun, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIN_TAB_WIDTH = void 0;
  const MIN_TAB_WIDTH = 70; // px

  _exports.MIN_TAB_WIDTH = MIN_TAB_WIDTH;
  const STATUS_SORTING = {
    [_testRun.STATES.ERRORED]: 0,
    [_testRun.STATES.FAILED]: 1
  };

  function statusOrder(testNode) {
    const statusSort = STATUS_SORTING[testNode.get('status')];
    return statusSort === undefined ? Infinity : statusSort;
  }

  var _default = _component.default.extend({
    hasMultipleTestNodes: (0, _computed.gt)('testNodes.length', 1),
    nodeSelectedInDropdown: (0, _object.computed)('currentTestNode', 'testNodesInDropDown', function () {
      return this.testNodesInDropDown.includes(this.currentTestNode);
    }),
    dropdownLabel: (0, _object.computed)('testNodesInTabs', 'testNodesInDropDown', 'nodeSelectedInDropdown', 'indexSelectedTab', function () {
      const firstIndexInDropdown = this.get('testNodesInDropDown.firstObject.index');
      const lastIndexInDropdown = this.get('testNodesInDropDown.lastObject.index');
      const selectedIndexInDropdown = this.indexSelectedTab;

      if (this.nodeSelectedInDropdown) {
        return `${selectedIndexInDropdown}`;
      } else {
        return `${firstIndexInDropdown}…${lastIndexInDropdown}`;
      }
    }),
    numberOfTabs: (0, _object.computed)('tabBarWidth', 'testNodes.length', function () {
      const tabCount = Math.floor(this.tabBarWidth / MIN_TAB_WIDTH);
      const nodeCount = this.get('testNodes.length'); // Avoid having a dropdown with just one item in it, instead let the
      // dropdown space be taken by the last tab

      return nodeCount - tabCount === 1 ? nodeCount : tabCount;
    }),
    tabBarWidthObserver: (0, _object.observer)('testNodes.length', function () {
      this.set('tabBarWidth', (0, _jquery.default)(this.element).find('.test-nodes--nav > ul').width());
    }),
    sortedTestNodes: (0, _computed.sort)('testNodes.@each.{status,index}', function (a, b) {
      if (statusOrder(a) < statusOrder(b)) {
        return -1;
      }

      if (statusOrder(a) > statusOrder(b)) {
        return 1;
      }

      if (a.get('index') > b.get('index')) {
        return 1;
      }

      if (a.get('index') < b.get('index')) {
        return -1;
      }

      return 0;
    }),
    testNodesInTabs: (0, _object.computed)('sortedTestNodes', 'numberOfTabs', function () {
      return this.sortedTestNodes.slice(0, this.numberOfTabs);
    }),
    dropdownClasses: (0, _object.computed)('nodeSelectedInDropdown', function () {
      const DEFAULT_CLASS_NAMES = 'flex-auto nowrap pv0 ph3 bg-light-silver b--silver bl bb bt-0 br-0 br1 br--top br--right';
      const ACTIVE_CLASS_NAMES = DEFAULT_CLASS_NAMES.concat(' ').concat('bg-white purple b--white active');
      return this.nodeSelectedInDropdown ? ACTIVE_CLASS_NAMES : DEFAULT_CLASS_NAMES;
    }),
    testNodesInDropDown: (0, _object.computed)('sortedTestNodes', 'numberOfTabs', function () {
      const numberOfTestNodes = this.get('testNodes.length');
      const numberOfTabs = this.numberOfTabs;
      return this.sortedTestNodes.slice(numberOfTabs, numberOfTestNodes);
    }),
    hasTestNodesInDropDown: (0, _computed.notEmpty)('testNodesInDropDown'),
    currentTestNode: (0, _object.computed)('indexSelectedTab', 'sortedTestNodes.@each.index', function () {
      const currentIndex = this.indexSelectedTab;
      return this.sortedTestNodes.findBy('index', currentIndex) || this.get('sortedTestNodes.firstObject');
    }),
    erroredTestNodesInDropDown: (0, _computed.filterBy)('testNodesInDropDown', 'status', 'errored'),
    failedTestNodesInDropDown: (0, _computed.filterBy)('testNodesInDropDown', 'status', 'failed'),
    hasErroredTestNodesInDropDown: (0, _computed.notEmpty)('erroredTestNodesInDropDown'),
    hasFailedTestNodesInDropDown: (0, _computed.notEmpty)('failedTestNodesInDropDown'),
    showDropdownBadge: (0, _computed.or)('hasErroredTestNodesInDropDown', 'hasFailedTestNodesInDropDown'),

    // todo: shuffle errored nodes to the start
    didInsertElement() {
      const $window = (0, _jquery.default)(this.element).find(window);
      const $tabBar = (0, _jquery.default)(this.element).find('.test-nodes--nav > ul');
      let scheduledUpdate;

      const updateTabBarSize = () => {
        scheduledUpdate = (0, _runloop.throttle)(this, 'set', 'tabBarWidth', $tabBar.width(), 50, false);
      };

      updateTabBarSize();
      $window.on('resize', updateTabBarSize);
      this.on('willDestroyElement', () => {
        (0, _runloop.cancel)(scheduledUpdate);
        $window.off('resize', updateTabBarSize);
      });
    },

    actions: {
      updateSelectedNode(testNode) {
        this.set('currentTestNode', testNode);
        this.set('indexSelectedTab', testNode.get('index'));
      }

    }
  });

  _exports.default = _default;
});