define("dashboard/templates/components/space/network/peering-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iW5U4Cwv",
    "block": "[[[10,\"dl\"],[14,0,\"dl-horizontal space-peering-info\"],[12],[1,\"\\n  \"],[10,\"dt\"],[12],[1,\"AWS Account\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[30,0,[\"peeringInfo\",\"awsAccountId\"]]],[13],[1,\"\\n\\n  \"],[10,\"dt\"],[12],[1,\"AWS Region\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[30,0,[\"peeringInfo\",\"awsRegion\"]]],[13],[1,\"\\n\\n  \"],[10,\"dt\"],[12],[1,\"AWS VPC ID\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[30,0,[\"peeringInfo\",\"vpcId\"]]],[13],[1,\"\\n\\n  \"],[10,\"dt\"],[12],[1,\"AWS VPC CIDR\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[30,0,[\"peeringInfo\",\"vpcCidr\"]]],[13],[1,\"\\n\\n  \"],[10,\"dt\"],[12],[1,\"Dyno CIDRs\"],[13],[1,\"\\n  \"],[10,\"dd\"],[12],[1,[30,0,[\"dynoCidrBlocks\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/space/network/peering-info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});