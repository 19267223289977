define("dashboard/templates/components/enterprise-usage/history", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eT4RiCJC",
    "block": "[[[41,[30,0,[\"groupedLicenses\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"groupedLicenses\"]]],null]],null],null,[[[6,[39,3],null,[[\"data\",\"attributeToPlot\",\"plotIsCurrency\",\"linkToInvoice\",\"quotaLimitAttribute\"],[[30,1,[\"sortedLicenses\"]],\"currentUsage\",[30,1,[\"isCurrency\"]],false,\"quota\"]],[[\"default\"],[[[[1,\"      \"],[10,0],[14,0,\"f4 mid-gray b mb1\"],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n\"]],[]]]]]],[1]],null]],[]],[[[1,\"  \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]]]],[\"group\"],false,[\"if\",\"each\",\"-track-array\",\"invoice-graph\",\"empty/billing-historical\"]]",
    "moduleName": "dashboard/templates/components/enterprise-usage/history.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});