define("dashboard/models/team/allowed-addon", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    team: (0, _relationships.belongsTo)('team'),
    addonService: (0, _relationships.belongsTo)('addon-service'),
    addedAt: (0, _attr.default)('date'),
    addedBy: (0, _attr.default)()
  });

  _exports.default = _default;
});