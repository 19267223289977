define("dashboard/components/account/two-factor-status", ["exports", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['edit-two-factor'],
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    account: (0, _computed.reads)('current.account'),
    password: null,
    needsPassword: (0, _computed.empty)('password'),

    init() {
      this._super(...arguments); // create a local copy of the account's mfaEnabled so that we can toggle it later on
      // current.account without causing the component's state to change


      this.set('isMfaEnabled', this.get('current.account.mfaEnabled'));
    },

    actions: {
      disableTwoFactor() {
        const account = this.get('current.account');
        account.set('mfaEnabled', false);
        return account.save().then(() => {
          this.set('isMfaEnabled', false);
        }).catch(() => {
          account.set('mfaEnabled', true);
          this.set('isMfaEnabled', true);
        });
      },

      deleteSmsNumber() {
        const account = this.account;
        account.set('smsNumber', null);
        account.set('password', this.password);
        const pendingSave = account.save();
        return pendingSave.then(() => {
          // clear this out again since it comes back as part of the API response
          account.reload();
          this.set('showDeleteSmsNumber', false);
        }).catch(() => {
          // Revert the UI if the request failed
          account.rollbackAttributes();
        });
      },

      hideDeleteSmsNumber() {
        this.set('showDeleteSmsNumber', false);
        this.set('password', null);
      },

      setPassword(password) {
        this.set('password', password);
      }

    }
  });

  _exports.default = _default;
});