define("dashboard/serializers/authorization", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      const data = {};
      data.description = snapshot.attr('description');
      const expiresIn = snapshot.attr('expiresIn');

      if (expiresIn) {
        const serializedExpiredIn = expiresIn === '0' ? null : expiresIn;
        data.expires_in = serializedExpiredIn;
      } else {
        data.expires_in = null;
      }

      if (snapshot.record.get('isNew')) {
        data.scope = ['global'];
      } else {
        delete data.expires_in;
      }

      return data;
    }

  });

  _exports.default = _default;
});