define("dashboard/helpers/human-size", ["exports", "@ember/component/helper", "numeral"], function (_exports, _helper, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A helper for displaying a number as a human-readable size (e.g. in MB, GB).
   *
   * @class Ember.Helper.helper.human-size
   */
  var _default = (0, _helper.helper)(function (_ref) {
    let [value] = _ref;
    return (0, _numeral.default)(value).format('0,0.0 ib');
  });

  _exports.default = _default;
});