define("dashboard/routes/app/metrics/index", ["exports", "@ember/routing/route", "@ember/utils"], function (_exports, _route, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    /**
     * redirect to web or first type
     */
    beforeModel() {
      const app = this.modelFor('app').app;
      return app.hasMany('formations').reload().then(formations => {
        const webType = formations.find(formation => {
          return formation.get('type') === 'web' && formation.get('quantity') > 0;
        });
        let typeName = 'web';

        if ((0, _utils.isBlank)(webType)) {
          typeName = formations.sortBy('quantity').get('lastObject.type');
        }

        if (app.get('metricsEnabled') && typeName) {
          this.replaceWith('app.metrics.type', typeName);
        }
      });
    }

  });

  _exports.default = _default;
});