define("dashboard/adapters/unverify-payment-method-request", ["exports", "@ember/array", "@ember/service", "@ember/object", "dashboard/adapters/application"], function (_exports, _array, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    dsErrorMonitor: (0, _service.inject)(),
    version: '3.internal',
    _baseURL: (0, _object.computed)('host', 'namespace', function () {
      const host = this.host;
      const namespace = this.namespace;

      if (namespace) {
        return `${host}/${namespace}`;
      } else {
        return host;
      }
    }),

    handleResponse(status, headers, payload, requestData) {
      if (status === 403 || status === 404) {
        // don't flash error
        requestData.jqXHR.errorIsHandled = true;
      }

      return this._super(status, headers, payload, requestData);
    },

    queryRecord() {
      return this._super(...arguments).then(payload => {
        // ember data requires ID and this is a singleton anyway.
        payload.id = 'fake-unverify-payment-method-request-id';
        return payload;
      }).catch(error => {
        const errors = (0, _array.A)(error.errors);
        const isAllowedFailure = errors.any(e => e.status === '403' || e.status === '404');

        if (isAllowedFailure) {
          // this might not exist, because the user either:
          // - does not have a payment method unverify request (they've never cancelled)
          // - they do not have this feature flag
          // resolve as null instead
          return null;
        } // throw errors as usual


        throw error;
      });
    },

    urlForQueryRecord() {
      const base = this._baseURL;
      return `${base}/account/payment-method/unverify-request`;
    },

    confirm() {
      const base = this._baseURL;
      const url = `${base}/account/payment-method/unverify-request/actions/confirm`;
      return this.ajax(url, 'PATCH');
    }

  });

  _exports.default = _default;
});