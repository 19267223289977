define("dashboard/components/metrics/charts/chart-header/chart-summary", ["exports", "@ember/component", "dashboard/templates/components/metrics/charts/chart-header/chart-summary"], function (_exports, _component, _chartSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _chartSummary.default,
    tagName: 'chart-summary'
  });

  _exports.default = _default;
});