define("dashboard/components/enterprise-usage/history", ["exports", "@ember/service", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _service, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    showConnectUsage: false,
    // Combined and sorted by type
    groupedLicenses: (0, _computed.sort)('groupedByType', function (a, b) {
      return a.compare(b);
    }),
    // Group licenses by type
    groupedByType: (0, _object.computed)('displayable', function () {
      const licenses = this.displayable;
      const combinedLicenses = new Map();
      licenses.forEach(function (license) {
        const code = license.get('normalizedCode');

        if (combinedLicenses.has(code)) {
          combinedLicenses.get(code).get('licenses').push(license);
        } else {
          const invoice = TeamLicenseUsagePeriods.create({
            licenses: [license]
          });
          combinedLicenses.set(code, invoice);
        }
      });
      return Array.from(combinedLicenses.values());
    }),
    // Filter out ones that should not be displayed
    displayable: (0, _object.computed)('monthlyLicenses.[]', function () {
      const licenses = this.monthlyLicenses.filterBy('isLicenseValid');

      if (!this.showConnectUsage) {
        // We might not want to display the usage graph for connect rows
        // because we don't have monthly usage snapshots.
        return licenses.filterBy('isConnect', false);
      }

      return licenses;
    })
  });

  _exports.default = _default;

  const TeamLicenseUsagePeriods = _object.default.extend({
    licenses: [],
    licenseSortKey: ['date'],
    sortedLicenses: (0, _computed.sort)('licenses', 'licenseSortKey'),
    // This represents licenses of the same type, so this is used for high-level
    // license info.
    repLicense: (0, _computed.alias)('licenses.0'),
    label: (0, _computed.readOnly)('repLicense.label'),
    isCurrency: (0, _computed.bool)('repLicense.isCurrency'),

    // Comparison function to order licenses by type
    compare(compareTo) {
      return this.repLicense.compare(compareTo.get('repLicense'));
    }

  });
});