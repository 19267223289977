define("dashboard/models/enterprise-account/license", ["exports", "ember-data/model", "ember-data/attr", "@ember/object", "@ember/object/computed", "ember-data/relationships", "moment", "dashboard/mixins/models/enterprise-license"], function (_exports, _model, _attr, _object, _computed, _relationships, _moment, _enterpriseLicense) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_enterpriseLicense.default, {
    // --------------------------------------------------------------------------
    // Attributes
    licenseType: (0, _attr.default)('string'),
    licenseQuantity: (0, _attr.default)('number'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    enterpriseAccount: (0, _relationships.belongsTo)('enterprise-account'),
    // Aliases for backwards compatibility (Enterprise Teams predated Enterprise Accounts)
    code: (0, _computed.alias)('licenseType'),
    qty: (0, _computed.alias)('licenseQuantity'),
    // --------------------------------------------------------------------------
    // Properties
    date: (0, _computed.alias)('startDate'),
    isActive: (0, _object.computed)('startDate', 'endDate', function () {
      const now = (0, _moment.default)();
      return (0, _moment.default)(this.startDate).isBefore(now) && (0, _moment.default)(this.endDate).isAfter(now);
    })
  });

  _exports.default = _default;
});