define("dashboard/templates/components/metrics/charts/connected-chart-hover/activity-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yds+Vt2k",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"modelsAtSelectedTime\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"userEmail\",\"title\",\"description\",\"isVisible\"],[[30,1,[\"user\",\"email\"]],[30,1,[\"descriptionWithVersion\"]],[30,1,[\"user\",\"email\"]],true]]]],[1,\"\\n\"]],[1]],null]],[\"model\"],false,[\"each\",\"-track-array\",\"metrics/charts/connected-chart-hover/item\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/connected-chart-hover/activity-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});