define("dashboard/components/third-party-connection-list", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['w-100', 'ph3', 'pt2', 'pb1'],
    sortAttribute: ['name'],
    sortedConnectionItems: (0, _computed.sort)('connectionItems', 'sortAttribute')
  });

  _exports.default = _default;
});