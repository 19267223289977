define("dashboard/components/metrics/charts/axes-elements/axis-time", ["exports", "@ember/object", "dashboard/components/metrics/charts/axes-elements/axis-element", "moment"], function (_exports, _object, _axisElement, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a  diagnostic chart axis that displays the time of day
   *
   * must be rendered in a {{axes-container}} component
   *
   */
  var _default = _axisElement.default.extend({
    classNames: 'time',
    tickValues: (0, _object.computed)('step', 'minTime', 'maxTime', 'timezone', 'ticksInterval', 'chartExtension', function () {
      const chartTimeUnit = this.get('step') < 60 ? 'minutes' : 'hours';
      const extension = this.get('chartExtension'); // add an extra tick at the end of the chart

      const timezone = this.get('timezone') || 'UTC'; // time zone

      const minLocal = (0, _moment.default)(this.get('minTime')).tz(timezone); // localized version of the chart's min time

      const maxLocalExtended = (0, _moment.default)(this.get('maxTime')).tz(timezone).add(extension, chartTimeUnit); // localized version of the chart's max time

      const increment = this.get('ticksInterval'); // the increment between ticks

      const usingTwoHourIncrements = increment === 2 && chartTimeUnit === 'hours';
      const dates = [];
      let date = minLocal.clone(); // must make sure that the first tick lines up with the intervals, before we start incrementing the ticks.

      if (chartTimeUnit === 'minutes') {
        // when the ticks represent less than an hour, we add minutes until minutes % increment === 0.
        while (date.minutes() % increment !== 0) {
          date.add('1', 'minute');
        }
      } else {
        // must account for the timezones that don't start on the hour
        date.subtract(date._offset % 60, 'minutes'); // when the ticks represent an hour or more, we add hours until hours % increment === 0.

        while (date.hours() % increment !== 0) {
          date.add('1', 'hour');
        }
      }

      let lastDateWasDST;

      while (date.toDate() <= maxLocalExtended.toDate()) {
        // Keep track of whether this date is in DST to compare with the next one.
        lastDateWasDST = date.isDST(); // Add the current date to the array of dates and create the next date object.

        dates.push(date);
        date = date.clone().add(increment, chartTimeUnit); // If these two values are different, a shift in DST has occurred.

        const shiftInDSTHasOccurred = lastDateWasDST !== date.isDST(); // For two hour increments, when DST occurs, add an hour between ticks
        // to enforce the ticks landing on even numbered hours.

        if (usingTwoHourIncrements && shiftInDSTHasOccurred) {
          date = date.clone().add(1, 'hour');
        }
      }

      return dates;
    }),
    ticksInterval: (0, _object.computed)('step', 'useCompactTicks', 'timeframeTicksInterval', function () {
      let ticksInterval = this.get('timeframeTicksInterval');

      if (this.get('step') >= 60 && this.get('useCompactTicks')) {
        ticksInterval *= 2;
      }

      return ticksInterval;
    }),
    timeLabelInterval: (0, _object.computed)('step', 'useCompactTicks', 'timeframeTimeLabelInterval', function () {
      let timeLabelInterval = this.get('timeframeTimeLabelInterval');

      if (this.get('step') > 60 && this.get('useCompactTicks')) {
        timeLabelInterval *= 2;
      }

      return timeLabelInterval;
    }),

    formatFn(d) {
      const tz = this.get('timezone') || 'UTC';

      const m = _moment.default.utc(d).tz(tz);

      const interval = this.get('timeLabelInterval'); // hours with minutes as a decimal value

      const hours = m.hours() + m.minutes() / 60;

      if (hours % interval === 0) {
        if (m.minutes()) {
          return m.format('h:mm A');
        } else {
          return m.format('h A');
        }
      }
    }

  });

  _exports.default = _default;
});