define("dashboard/components/cp-validating-gravatar-input", ["exports", "@ember/runloop", "@ember/object", "@ember/object/computed", "dashboard/components/cp-validating-input"], function (_exports, _runloop, _object, _computed, _cpValidatingInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cpValidatingInput.default.extend({
    classNames: ['cp-validating-gravatar-input'],
    rawValue: (0, _object.computed)({
      set(key, value) {
        this.set('value', value.trim());
        return value;
      },

      get() {
        return this.value;
      }

    }),
    // initial value necessary for when a value is already persisted
    debouncedValue: (0, _computed.oneWay)('value'),

    setDebouncedValue() {
      if (!this.isDestroyed) {
        this.set('debouncedValue', this.value);
      }
    },

    valueDidChange: (0, _object.observer)('value', function () {
      (0, _runloop.debounce)(this, this.setDebouncedValue, 300);
    })
  });

  _exports.default = _default;
});