define("dashboard/components/range-slider/fill", ["exports", "@ember/string", "@ember/component", "@ember/object"], function (_exports, _string, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'range-slider-fill',
    attributeBindings: ['style', 'valueStart', 'valueEnd'],
    classNameBindings: ['startsAtMin', 'isEmpty', 'endsAtMax'],
    startsAtMin: (0, _object.computed)('min', 'valueStart', function () {
      return this.min === this.valueStart;
    }),
    endsAtMax: (0, _object.computed)('max', 'valueEnd', function () {
      return this.max === this.valueEnd;
    }),
    isEmpty: (0, _object.computed)('valueStart', 'valueEnd', function () {
      return this.valueEnd - this.valueStart <= 0;
    }),
    style: (0, _object.computed)('valueEnd', 'valueStart', 'pixelRatio', function () {
      const xStart = this.valueStart * this.pixelRatio;
      const xEnd = this.valueEnd * this.pixelRatio;
      let width = xEnd - xStart;

      if (width < 0) {
        width = 0;
      }

      return (0, _string.htmlSafe)(`width: ${width}px; transform: translateX(${xStart}px)`);
    })
  });

  _exports.default = _default;
});