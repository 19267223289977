define("dashboard/components/pipeline/github-button-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    iconClass: (0, _object.computed)('isDisabled', function () {
      return this.isDisabled ? 'fill-light-gray tab-icon' : 'fill-white tab-icon';
    })
  });

  _exports.default = _default;
});