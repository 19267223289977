define("dashboard/components/app-typeahead-item", ["exports", "dashboard/components/purple-typeahead-item"], function (_exports, _purpleTypeaheadItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purpleTypeaheadItem.default.extend({
    classNames: ['app-typeahead-item']
  });

  _exports.default = _default;
});