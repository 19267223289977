define("dashboard/mixins/uses-config-var-description", ["exports", "@ember/object/mixin", "@ember/object", "@ember/string", "ember"], function (_exports, _mixin, _object, _string, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a mixin that can be included in an object that contains a `configVarsByGroup` property
   *
   * this mixin will generate an HTML representation of the verbage for the
   * `configVarByGroup` change
   *
   */
  var _default = _mixin.default.create({
    // configVarDescription
    //
    // e.g. output: Set FOO BAR BAZ config vars; Remove SOMETHING_ELSE config var
    configVarDescription: (0, _object.computed)('configVarsByGroup', function () {
      const groups = this.configVarsByGroup; // input:  `[{ set: '  BAR config var'}]`
      // output: `"Set <code>BAR</code> config var"`

      const description = Object.keys(groups).map(key => {
        const actionKey = key.capitalize();
        const variables = groups[key].replace(/config( vars)?\.?/g, '').split(',').map(this.wrapInCodeBlocks);
        let variableString = variables.join(', ');
        const hasManyVariables = variables.length > 1; // append `config var(s)`

        if (hasManyVariables) {
          variableString += ' config vars';
        } else {
          variableString += ' config var';
        }

        return `${actionKey} ${variableString}`;
      }).join('; ');
      return (0, _string.htmlSafe)(description);
    }),

    // wrapInCodeBlocks
    //
    // input:  `some blob of text`
    // output: `<code>some blob of text</code>`
    wrapInCodeBlocks(str) {
      return `<code>${_ember.default.Handlebars.Utils.escapeExpression(str.trim())}</code>`;
    }

  });

  _exports.default = _default;
});