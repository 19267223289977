define("dashboard/templates/components/x-flash-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MqUF9MZV",
    "block": "[[[8,[39,0],[[24,0,\"flash-message-close mt1 mr1 o-70 top-0 right-0 absolute\"],[24,4,\"button\"],[4,[38,1],[[30,0],\"close\"],null]],[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"delete-16\",\"fill-white\",12,\"Close message\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"title\"]],[[[1,\"  \"],[10,1],[14,0,\"title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"ul\"],[14,0,\"content\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"messages\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"msg\"],false,[\"malibu-icon-button\",\"action\",\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "dashboard/templates/components/x-flash-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});