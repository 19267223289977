define("dashboard/components/re-authenticate", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      confirmReauthenticate() {
        this.attrs.onConfirmReauthenticate();
      }

    }
  });

  _exports.default = _default;
});