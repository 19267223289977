define("dashboard/mixins/metrics/app", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/object"], function (_exports, _computed, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * "App" Model metrics logic isolation
   */
  var _default = _mixin.default.create({
    /**
     * A computed property that:
     * + ensures the app has a sufficient number of dynos [defined by business requirements]
     *
     * @property metricsEnabled
     */
    metricsEnabled: (0, _object.computed)('processTier', function () {
      const tier = this.processTier;

      if (tier === 'production' || tier === 'private' || tier === 'hobby' || tier === 'basic' || tier === 'shield') {
        return true;
      } else {
        return false;
      }
    }),
    isEligibleForAlerting: (0, _computed.or)('currentTier.isProduction', 'currentTier.isPrivate', 'currentTier.isShield')
  });

  _exports.default = _default;
});