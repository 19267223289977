define("dashboard/components/pipeline/disconnect-confirmation", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    filter,
    notEmpty,
    reads,
    or
  } = _object.computed;

  var _default = _component.default.extend({
    autoDeployAppLinks: filter('pipeline.githubAppLinks', function (appLink) {
      const isAutoDeployApp = appLink.get('autoDeploy');
      const isNotReviewApp = appLink.get('app.pipelineCoupling.stage') !== 'review';
      return isAutoDeployApp && isNotReviewApp;
    }),
    hasAutoDeployApps: notEmpty('autoDeployAppLinks'),
    hasReviewAppParents: notEmpty('pipeline.reviewAppParents'),
    hasReviewApps: notEmpty('pipeline.kolkrabbiReviewApps'),
    showWarnings: or('hasAutoDeployApps', 'hasReviewAppParents', 'hasReviewApps'),
    reviewAppCount: reads('pipeline.kolkrabbiReviewApps.length'),
    autoDeployAppNames: (0, _object.computed)('autoDeployAppLinks.@each.app', function () {
      return this.autoDeployAppLinks.map(autoApp => {
        return autoApp.get('app.name');
      }).join(', ');
    })
  });

  _exports.default = _default;
});