define("dashboard/serializers/app/feature", ["exports", "dashboard/serializers/feature"], function (_exports, _feature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _feature.default.extend({});

  _exports.default = _default;
});