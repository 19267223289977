define("dashboard/templates/components/account/ssh-keys-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "54obRLzs",
    "block": "[[[10,\"td\"],[14,0,\"ssh-key-local\"],[15,\"title\",[30,0,[\"key\",\"local\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"local\"],[12],[1,[30,0,[\"key\",\"comment\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"ssh-key-full-key\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\"],[[30,0,[\"key\",\"publicKey\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"canEdit\",\"canRemove\",\"model\",\"editAction\",\"confirmDeleteAction\"],[false,true,[30,0,[\"key\"]],[28,[37,2],[[30,0],\"edit\"],null],[28,[37,2],[[30,0],\"confirmDelete\"],null]]]]],[1,\"\\n\"]],[],false,[\"copyable-input\",\"purple-list-action-cell\",\"action\"]]",
    "moduleName": "dashboard/templates/components/account/ssh-keys-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});