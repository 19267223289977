define("dashboard/components/app/overview-collaborators", ["exports", "@ember/component", "@ember/object", "dashboard/helpers/array-group-by"], function (_exports, _component, _object, _arrayGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    filterBy,
    sort
  } = _object.computed;

  var _default = _component.default.extend({
    isShowingAllCollaborators: false,
    codeReleases: filterBy('releases', 'codeWasReleased', true),
    groupedByCollaborators: (0, _object.computed)('codeReleases.[]', function () {
      return (0, _arrayGroupBy.default)(this.codeReleases, 'user.email');
    }),
    collaboratorSorting: ['items.length:desc'],
    collaborators: sort('groupedByCollaborators', 'collaboratorSorting'),
    activityMetric: (0, _object.computed)('codeReleases', function () {
      return `Showing the last ${this.get('codeReleases.length')} releases`;
    }),
    topCollaborators: (0, _object.computed)('collaborators.[]', function () {
      return this.collaborators.slice(0, 3);
    }),
    tailCollaborators: (0, _object.computed)('collaborators.[]', function () {
      return this.collaborators.slice(3);
    }),
    actions: {
      toggleMore() {
        this.toggleProperty('isShowingAllCollaborators');
      }

    }
  });

  _exports.default = _default;
});