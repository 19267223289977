define("dashboard/components/ci/test-menu-list", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bool,
    and,
    reads
  } = _object.computed;

  var _default = _component.default.extend({
    isConnected: reads('pipeline.isConnectedToRepository'),
    hasBranch: bool('selectedBranch'),
    connectedWithBranch: and('isConnected', 'hasBranch')
  });

  _exports.default = _default;
});