define("dashboard/adapters/repositories-api-app-manifest", ["exports", "dashboard/adapters/application", "dashboard/config/environment", "ember", "jquery", "@ember/service"], function (_exports, _application, _environment, _ember, _jquery, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.repositories-api',
    accountFeatures: (0, _service.inject)('account-features'),

    urlForQueryRecord(query) {
      const owner = query.owner;
      delete query.owner;
      const repoName = query.repoName;
      delete query.repoName;
      delete query.apiOrigin;
      delete query.ref;
      delete query.endpoint;
      return `${this.host}/repos/${owner}/${repoName}/manifest`;
    },

    async queryRecord(_store, _modelClass, query) {
      let payload;

      if (query.isGithubAppEnabled) {
        payload = await this._super(...arguments);
      } else {
        const {
          apiOrigin,
          owner,
          repoName,
          ref,
          endpoint
        } = query;
        let content;

        try {
          content = await this._fetchFromPublicGithub(owner, repoName, apiOrigin, ref);
        } catch (e) {
          content = await this._fetchFromPrivateGithub(owner, repoName, ref, endpoint);
        }

        payload = {
          app_json: content
        };
      }

      payload.id = _ember.default.generateGuid();
      return payload;
    },

    _legacyAjax(options) {
      const defaults = {
        dataType: 'text',

        beforeSend(xhr) {
          xhr.suppressErrors = true;
          xhr.global = false;
        }

      };
      const overrides = Object.assign({}, defaults, options);
      return new Promise((resolve, reject) => {
        _jquery.default.ajax(overrides).then(resolve).fail((jqXHR, status, text) => {
          const rejection = this.handleResponse(jqXHR.status, jqXHR.getAllResponseHeaders(), this.parseErrorResponse(jqXHR.responseText) || text, {
            url: overrides.url,
            method: options.type || 'GET'
          });
          reject(rejection);
        });
      });
    },

    _fetchFromPublicGithub(owner, repoName, apiOrigin, ref) {
      const publicUrl = `${apiOrigin}/repos/${owner}/${repoName}/contents/app.json`;
      return this._legacyAjax({
        url: publicUrl,
        data: {
          ref
        },

        beforeSend(xhr) {
          xhr.suppressErrors = true;
          xhr.global = false;
        },

        headers: {
          accept: 'application/vnd.github.v3.raw'
        }
      });
    },

    _fetchFromPrivateGithub(owner, repoName, ref, endpoint) {
      const escapedRef = encodeURIComponent(ref);
      const apiUrl = `${_environment.default.particleboardUrl}/github/${owner}/${repoName}/${escapedRef}/app.json?endpoint=${endpoint}`;
      return this._legacyAjax({
        url: apiUrl,
        withHerokuAuthorization: true,

        beforeSend(xhr) {
          xhr.suppressErrors = true; // Disable automated log out if we get a 401

          xhr.global = false;
        }

      });
    },

    urlForCreateRecord(_modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${_environment.default.kolkrabbiUrl}/apps/${appId}/github/app-json`;
    },

    createRecord() {
      return this._super(...arguments).then(result => {
        result.id = _ember.default.generateGuid();
        return result;
      });
    }

  });

  _exports.default = _default;
});