define("dashboard/models/job", ["exports", "@ember-data/model", "@ember/object", "moment"], function (_exports, _model, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // e.g.: March 17, 2019 8:30 PM UTC
  const DATE_FORMAT = 'LLL UTC';

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    // `at` is the position in the frequency to run the job - it must be less than every
    // i.e.: offset within interval 🤯
    at: (0, _model.attr)('job-frequency'),
    every: (0, _model.attr)('number'),
    // frequency of the job in minutes - values of [1..1440]
    ranAt: (0, _model.attr)('date'),
    createAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    lastRun: (0, _object.computed)('ranAt', function () {
      if (!this.ranAt) {
        return 'Never';
      }

      return (0, _moment.default)(this.ranAt).utc().format(DATE_FORMAT);
    }),
    nextDue: (0, _object.computed)('every', 'at', 'ranAt', 'createdAt', function () {
      const dm = (0, _moment.default)(this.every).add(this.at, 'minutes').utc();
      let next;

      if (this.every === 10) {
        const prev = this.ranAt || this.createdAt; // Heroku Scheduler assumes the job will be run immediately so next defaults to the next 10min period

        next = (0, _moment.default)(prev).add(this.every, 'minutes');
      } else if (this.every === 60) {
        // We need to get HH:MM (day and month will be EPOCH based)
        next = _moment.default.utc().minutes(dm.format('m'));

        if (next <= (0, _moment.default)()) {
          next.add('1', 'hour');
        }
      } else if (this.every === 1440) {
        // We need to get HH:MM (day and month will be EPOCH based)
        next = _moment.default.utc().hours(dm.format('k')).minutes(dm.format('m'));

        if (next <= (0, _moment.default)()) {
          next.add('1', 'day');
        }
      }

      return (0, _moment.default)(next).utc().format(DATE_FORMAT);
    }),
    command: (0, _model.attr)('string'),
    dynoSize: (0, _model.attr)('string'),
    displayFrequency: (0, _object.computed)('every', 'at', function () {
      switch (this.every) {
        case 10:
          return 'Every 10 minutes';

        case 60:
          return `Hourly at :${this.at}`;

        case 1440:
          return `Daily at ${(0, _moment.default)(this.every).add(this.at, 'minutes').utc().format('LT UTC')}`;

        default:
          return `Every ${this.every} minutes`;
      }
    })
  });

  _exports.default = _default;
});