define("dashboard/templates/components/errors/unexpected-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mPH8H8+Y",
    "block": "[[[10,0],[14,0,\"flex justify-center flex-column flex-auto ph2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-column items-center error-text\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"failure-ring-28\",\"fill-dark-gray mb3\",60]],null],[1,\"\\n    \"],[10,0],[14,0,\"f3 dark-gray mb2 fw6\"],[12],[1,\"Something went wrong\"],[13],[1,\"\\n    \"],[10,2],[14,0,\"lh-copy dark-gray measure tc\"],[12],[1,\"If you're \"],[10,1],[14,0,\"fw6\"],[12],[1,\"using an ad-blocker\"],[13],[1,\",\\n      please try disabling it. You can also check the \"],[10,3],[14,6,\"https://status.heroku.com\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link dark-gray fw6\"],[12],[1,\"\\n        Heroku Status site\"],[13],[1,\" to see if there are any incidents impacting our service.\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"https://help.heroku.com/\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"no-hover-underline flex items-center hk-button--secondary\"],[12],[1,\"\\n        Visit Heroku Help\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/errors/unexpected-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});