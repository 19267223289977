define("dashboard/helpers/md5", ["exports", "@ember/component/helper", "md5"], function (_exports, _helper, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mdfive = mdfive;

  function mdfive(params
  /* , hash*/
  ) {
    return (0, _md.default)(params);
  }

  var _default = (0, _helper.helper)(mdfive);

  _exports.default = _default;
});