define("dashboard/models/ssl-endpoint", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/service"], function (_exports, _model, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    certificateChain: (0, _model.attr)('string'),
    privateKey: (0, _model.attr)('string'),
    cname: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    sslCert: (0, _model.attr)(),
    commonName: (0, _computed.readOnly)('sslCert.cert_domains.firstObject'),
    analytics: (0, _service.inject)(),

    logEvent(eventName) {
      this.analytics.logEvent('SSL Cert', eventName);
    }

  });

  _exports.default = _default;
});