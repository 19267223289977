define("dashboard/components/metrics/charts/chart-header/memory-summary", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/chart-header/chart-summary", "@ember/object"], function (_exports, _computed, _chartSummary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _chartSummary.default.extend({
    hasSufficientData: (0, _computed.and)('data.maxMemoryTotalMaxValuePercentage', 'data.meanPercentageUsedMeanValue'),
    numberFormat: '0,0.0',
    primaryUnit: '%',
    secondaryUnit: 'MB',
    warningLevel: 80,
    criticalLevel: 100,
    latestMemoryUsage: (0, _object.computed)('data.currentPercentage', 'data.currentTotal', function () {
      return _object.default.create({
        title: 'The most recent memory usage.',
        label: 'latest',
        primaryValue: this.get('data.currentPercentage'),
        secondaryValue: this.get('data.currentTotal')
      });
    }),
    maxMemoryUsage: (0, _object.computed)('data.maxMemoryTotalMaxValuePercentage', 'data.maxMemoryTotalMaxValue', function () {
      return _object.default.create({
        title: 'The maximum amount of memory used in this time period.',
        label: 'max',
        primaryValue: this.get('data.maxMemoryTotalMaxValuePercentage'),
        secondaryValue: this.get('data.maxMemoryTotalMaxValue')
      });
    }),
    averageMemoryUsage: (0, _object.computed)('data.meanPercentageUsedMeanValue', 'data.meanMemoryTotalMeanValue', function () {
      return _object.default.create({
        title: 'The average amount of memory used in this time period.',
        label: 'average',
        primaryValue: this.get('data.meanPercentageUsedMeanValue'),
        secondaryValue: this.get('data.meanMemoryTotalMeanValue')
      });
    }),
    chartSummaryItems: (0, _computed.collect)('latestMemoryUsage', 'maxMemoryUsage', 'averageMemoryUsage')
  });

  _exports.default = _default;
});