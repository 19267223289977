define("dashboard/serializers/pipeline-coupling", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      const data = this._super.apply(this, arguments);

      const store = this.store;
      const pipelineModelClass = store.modelFor('pipeline');
      const pipelineNormalizedResponse = store.serializerFor('pipeline').normalizeResponse(store, pipelineModelClass, hash.pipeline, hash.pipeline.id, 'findRecord');
      data.included = [pipelineNormalizedResponse.data];
      return data;
    },

    serialize(snapshot) {
      return {
        app: snapshot.record.get('app.id'),
        pipeline: snapshot.record.get('pipeline.id'),
        stage: snapshot.record.get('stage')
      };
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      relationships.app = {
        data: {
          id: hash.app.id,
          type: 'app'
        }
      };
      relationships.pipeline = {
        data: {
          id: hash.pipeline.id,
          type: 'pipeline'
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});