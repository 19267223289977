define("dashboard/models/space/nat", ["exports", "@ember/object/computed", "ember-data/attr", "ember-data/model"], function (_exports, _computed, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    sources: (0, _attr.default)(),
    state: (0, _attr.default)('string'),
    enabled: (0, _computed.equal)('state', 'enabled')
  });

  _exports.default = _default;
});