define("dashboard/components/github-app-banner", ["exports", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex flex-column br2 br--top'],
    // services
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    // params
    hasAdminAccess: null,
    hasGithubAppInstalled: null,
    githubAppInstallation: null,
    enterpriseAccount: null,
    connectedGitHubThingName: null,
    // properties
    hasNoAdminAccess: (0, _computed.not)('hasAdminAccess'),
    hasNoGithubAppInstallation: (0, _computed.not)('hasGithubAppInstalled'),
    canInstallGithubApp: (0, _computed.and)('hasAdminAccess', 'hasNoGithubAppInstallation'),
    installationUrl: (0, _computed.readOnly)('githubAppInstallation.htmlUrl'),
    actions: {
      openManageGithubPage() {
        this.analytics.logEvent('Manage Github App Page', 'Opened');
        window.open(this.installationUrl);
      }

    }
  });

  _exports.default = _default;
});