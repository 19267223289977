define("dashboard/components/pop-over", ["exports", "@ember/runloop", "@ember/component", "@ember/utils", "@ember/service", "@ember/object", "@ember/object/computed", "jquery"], function (_exports, _runloop, _component, _utils, _service, _object, _computed, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const POPOVER_CLICK_EVENT = 'popover:click';

  var _default = _component.default.extend({
    classNames: ['inline-flex', 'relative'],
    hasCaret: true,
    isShowingPopover: false,
    tooltipTitle: null,
    tooltipOptions: (0, _object.computed)(function () {
      return {
        trigger: 'hover',
        container: 'body'
      };
    }),
    analytics: (0, _service.inject)(),
    buttonDisabled: false,
    buttonContent: '',
    buttonLabel: (0, _computed.reads)('tooltipTitle'),
    buttonExpandedLabel: (0, _computed.reads)('buttonLabel'),
    caretClass: 'fill-purple',
    menuAlignmentClass: (0, _object.computed)('menuAlignment', function () {
      const menuAlignment = this.get('menuAlignment');

      if (menuAlignment === 'right') {
        return `hk-dropdown--right`;
      } else if (menuAlignment === 'center') {
        return `hk-dropdown hk-dropdown--center`;
      } else {
        return `hk-dropdown`;
      }
    }),
    menuClassNames: (0, _object.computed)('menuClass', function () {
      return `${this.menuClass} ${this.menuAlignmentClass} z-5`;
    }),
    closeFunction: (0, _object.computed)(function () {
      return ev => {
        // Wrap this in a run loop to avoid issues with async actions happening while the popover menu is trying to close
        (0, _runloop.next)(this, () => {
          const stillExists = !this.isDestroyed && !this.isDestroying;
          const targetIsNotThis = ev.target !== this.button;
          const isShowingPopover = this.isShowingPopover;
          const formElements = ['INPUT', 'SELECT', 'LABEL'];
          const isFormElement = formElements.includes(ev.target.tagName);
          const isWithinComponent = this.element && this.element.contains(ev.target); // If the click is inside this dropdown AND it's a form element,
          // then keep the dropdown open

          const isNotContainedFormElement = !(isFormElement && isWithinComponent);

          if (stillExists && targetIsNotThis && isShowingPopover && isNotContainedFormElement) {
            this.set('isShowingPopover', false);
          }
        });
      };
    }),
    button: (0, _object.computed)(function () {
      return (0, _jquery.default)(this.element).find('.pop-over-toggle')[0];
    }),

    didInsertElement() {
      this._super(...arguments);

      const $body = (0, _jquery.default)(document.body);
      const close = this.closeFunction;

      const wrappedClose = (ev, data) => close(data);

      $body.on('click', close);
      $body.on(POPOVER_CLICK_EVENT, wrappedClose);
      this.on('willDestroyElement', () => {
        $body.off('click', close);
        $body.off(POPOVER_CLICK_EVENT, wrappedClose);
      });
    },

    /**
     * Called after the menu is rendered. The default implementation is a no-op.
     * This method is here purely so it can be overridden.
     * @method didRenderMenu
     * @public
     */
    didRenderMenu() {// This method intentionally left blank.
    },

    willDestroyElement() {
      this.set('button', null);

      this._super(...arguments);
    },

    actions: {
      toggle() {
        (0, _jquery.default)(document.body).trigger(POPOVER_CLICK_EVENT, {
          target: this.button
        });
        this.toggleProperty('isShowingPopover');
        const eventDescription = this.analyticsEventDescription;
        const popoverAction = this.isShowingPopover ? 'Revealed' : 'Hidden';

        if ((0, _utils.isPresent)(eventDescription)) {
          this.analytics.logEvent(`${eventDescription} Dropdown`, popoverAction);
        }

        if (this.isShowingPopover) {
          (0, _runloop.schedule)('afterRender', this, 'didRenderMenu');
        }
      }

    }
  });

  _exports.default = _default;
});