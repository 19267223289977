define("dashboard/serializers/invoice", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      // if the response is from api V2
      if (hash.attrs) {
        return this._super(typeClass, hash.attrs);
      }

      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});