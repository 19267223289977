define("dashboard/templates/components/metrics/chart-controls/refresh-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2O19ezOt",
    "block": "[[[10,0],[14,0,\"hk-button-group metrics__view-controls__toggle-buttons\"],[14,\"role\",\"group\"],[15,\"title\",[30,0,[\"title\"]]],[12],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"buttonType\",\"onClick\",\"default\"],[[30,0,[\"pauseTitle\"]],[52,[30,0,[\"pauseButtonDisabled\"]],\"primary\",\"secondary\"],[28,[37,2],[[30,0],\"pauseRefresh\"],null],\"RPS\"]],[[\"default\"],[[[[1,\"    \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"pause-16\",16,[30,0,[\"pauseIconClass\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"buttonType\",\"onClick\",\"default\"],[[30,0,[\"refreshTitle\"]],[52,[30,0,[\"refreshButtonDisabled\"]],\"primary\",\"secondary\"],[28,[37,2],[[30,0],\"startRefresh\"],null],\"RPS\"]],[[\"default\"],[[[[1,\"    \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"play-16\",16,[30,0,[\"playIconClass\"]]]],null],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"hk-button\",\"if\",\"action\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/refresh-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});