define("dashboard/utils/metrics/generate-chart-properties", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateChartProperties;

  function generateChartProperties(chartKey, dataKey) {
    return (0, _object.computed)('chartOrder.[]', 'collapsedCharts.[]', dataKey, function () {
      return _object.default.create({
        chartKey,
        type: `metrics/charts/visualizations/${chartKey}`,
        data: this.get(dataKey),
        order: this.findChartOrder(chartKey),
        isCollapsed: this.findChartCollapsedState(chartKey)
      });
    });
  }
});