define("dashboard/templates/components/ci/new-test-run", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KAzNN3rp",
    "block": "[[[10,0],[15,0,[29,[\"ci-menu--new \",[52,[30,0,[\"isShowing\"]],\"reveal\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"ci-menu--item\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ci-meta\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"commit\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"git-commit-16\",\"fill-gray\",16,\"Commit\"]],null],[1,\"\\n\"],[41,[30,0,[\"fetchBranch\",\"isRunning\"]],[[[1,\"          Loading commit...\\n\"]],[]],[[[1,\"          \"],[1,[30,0,[\"selectedGitHubBranch\",\"commit\",\"commit\",\"message\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\\n      \"],[1,[28,[35,2],null,[[\"buttonComponent\",\"menuClass\",\"buttonClass\",\"classNames\",\"branches\",\"selectedBranch\",\"analyticsEventDescription\",\"isNewTestRun\"],[[50,\"ci/new-test-run/drop-down-button\",0,null,[[\"content\"],[[30,0,[\"selectedBranch\"]]]]],\"dropdown-menu dropdown-right branch-dropdown\",\"btn-link\",\"select-branch\",[30,0,[\"allBranches\"]],[30,0,[\"selectedBranch\"]],\"filter branches for a new test run\",true]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ci-action\"],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"onClick\",\"default\",\"size\",\"buttonType\",\"reset\",\"disabled\"],[[28,[37,5],[[30,0],[30,0,[\"createTestRun\"]],[28,[37,6],null,[[\"pipelineId\",\"commitMessage\",\"commitSha\",\"commitBranch\",\"organization\"],[[30,0,[\"pipeline\",\"id\"]],[30,0,[\"selectedGitHubBranch\",\"commit\",\"commit\",\"message\"]],[30,0,[\"selectedGitHubBranch\",\"commit\",\"sha\"]],[30,0,[\"selectedGitHubBranch\",\"name\"]],[30,0,[\"pipeline\",\"githubRepository\",\"organization\",\"id\"]]]]],\"Click on start new test\"],null],\"Start Test Run\",\"small\",\"secondary\",\"true\",[30,0,[\"invalidBranch\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"malibu-icon\",\"ci/filter-branches-dropdown\",\"component\",\"hk-button\",\"action\",\"hash\"]]",
    "moduleName": "dashboard/templates/components/ci/new-test-run.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});