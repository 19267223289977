define("dashboard/components/x-flash-message", ["exports", "@ember/object", "ember-cli-flash/components/flash-message"], function (_exports, _object, _flashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _flashMessage.default.extend({
    tagName: 'li',
    classNames: ['flash-message'],
    title: alias('flash.title'),
    messages: (0, _object.computed)('flash.message', function () {
      let msg = this.get('flash.message');

      if (!Array.isArray(msg)) {
        msg = Array(msg);
      }

      return msg;
    })
  });

  _exports.default = _default;
});