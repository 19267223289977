define("dashboard/templates/components/app/web-hook-deliveries/meta", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YqQl55Kk",
    "block": "[[[41,[30,0,[\"loadPayload\",\"isRunning\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"class\"],[\"flex justify-center\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"dark-gray f5 mb3\"],[12],[1,\"Payload sent\"],[13],[1,\"\\n  \"],[10,\"pre\"],[14,0,\"overflow-scroll mb5\"],[12],[1,\"    \"],[1,[28,[35,2],[[30,0,[\"delivery\",\"lastAttempt\",\"request\",\"body\"]]],null]],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"delivery\",\"lastAttempt\",\"error_class\"]],[[[1,\"    \"],[10,0],[14,0,\"dark-gray f5 mb3\"],[12],[1,\"Error\"],[13],[1,\"\\n    \"],[10,\"pre\"],[14,0,\"overflow-scroll mb5\"],[12],[1,\"      \"],[1,[30,0,[\"delivery\",\"lastAttempt\",\"error_class\"]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"delivery\",\"lastAttempt\",\"response\",\"body\"]],[[[1,\"    \"],[10,0],[14,0,\"dark-gray f5 mb3\"],[12],[1,\"Response\"],[13],[1,\"\\n    \"],[10,\"pre\"],[14,0,\"overflow-scroll mb5\"],[12],[1,\"      \"],[1,[30,0,[\"delivery\",\"lastAttempt\",\"response\",\"body\"]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"malibu-loading-spinner\",\"json-stringify\"]]",
    "moduleName": "dashboard/templates/components/app/web-hook-deliveries/meta.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});