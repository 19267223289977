define("dashboard/routes/team/switchable/settings", ["exports", "rsvp", "@ember/service", "dashboard/utils/infinite-query", "dashboard/routes/team/base", "ember-concurrency"], function (_exports, _rsvp, _service, _infiniteQuery, _base, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    current: (0, _service.inject)(),

    beforeModel() {
      const isUnauthorized = !this.current.teamPermissions.canViewSettingsTab;

      if (isUnauthorized) {
        this.transitionTo('/404');
      }
    },

    model() {
      const {
        team
      } = this.modelFor('team');
      const hasAllowlisting = team.get('canManageAddonControls');
      const isEnterpriseOrg = team.get('isEnterpriseOrg');
      const addonServicesRequest = hasAllowlisting ? this.store.findAll('addon-service') : (0, _rsvp.resolve)();
      const teamPreferencesRequest = isEnterpriseOrg ? team.belongsTo('preference').reload() : (0, _rsvp.resolve)();
      return (0, _rsvp.hash)({
        team,
        teamPreference: teamPreferencesRequest,
        addonServices: addonServicesRequest
      });
    },

    afterModel(model) {
      const analyticsNoun = model.team.analyticsNoun;
      this.analytics.logEvent(`${analyticsNoun} Settings Page`, 'Viewed'); // Guarantee freshest allowed addons list
      // Eventually, Async this, and offload to a component/service

      if (model.team && model.team.get('isEnterpriseOrg')) {
        return model.team.hasMany('allowedAddons').reload().then(() => {
          const toReload = model.addonServices.reduce((acc, addonService) => {
            if (!addonService.humanName) {
              // The addonService has only been loaded though the allowlistedAddon
              // response and therefore does not have all its info. We do make a
              // request to load all addonServices, but that only returns active ones.
              // So, if an app is using an old plan, it might not be fully loaded,
              // so we'll load it here.
              acc.push(addonService.reload());
            }

            return acc;
          }, []);
          return (0, _rsvp.all)(toReload);
        });
      }
    },

    setupController(controller, model) {
      this._super.apply(this, arguments);

      this.loadTeamAddonsTask.perform(model.team);
      controller.set('loadTeamAddonsTask', this.loadTeamAddonsTask);
    },

    loadTeamAddonsTask: (0, _emberConcurrency.task)(function* (team) {
      const hasAllowlisting = team.get('canManageAddonControls');

      if (hasAllowlisting) {
        return yield (0, _infiniteQuery.default)(this.store, 'team/addon', {
          team: team.get('id')
        });
      } else {
        return null;
      }
    }).restartable()
  });

  _exports.default = _default;
});