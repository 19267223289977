define("dashboard/adapters/status/unresolved-incident", ["exports", "ember-data", "dashboard/mixins/adapters/status-api"], function (_exports, _emberData, _statusApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_statusApi.default, {
    urlForFindAll() {
      return `${this.host}/${this.namespace}/current-status`;
    }

  });

  _exports.default = _default;
});