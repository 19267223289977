define("dashboard/models/metrics/space-degradation", ["exports", "@ember/object/computed", "@ember/object", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _computed, _object, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    availability: (0, _relationships.belongsTo)('metrics/space-availability'),
    startedAt: (0, _attr.default)('date'),
    endedAt: (0, _attr.default)('date'),
    createdAt: (0, _computed.alias)('startedAt'),
    type: (0, _attr.default)(),
    isActive: (0, _computed.empty)('endedAt'),
    precedesTimeframe: (0, _computed.empty)('startedAt'),
    typeLabel: (0, _object.computed)('type', function () {
      switch (this.type) {
        case 'dns-availability':
          return 'DNS';

        case 'tls-handshake-availability':
          return 'TLS';

        case 'http-roundtrip-availability':
          return 'HTTP';

        case 'tcp-availability':
          return 'TCP';
      }
    })
  });

  _exports.default = _default;
});