define("dashboard/utils/app-feature-state", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = appFeatureState;

  function appFeatureState(featureName) {
    return (0, _object.computed)('app.features.@each.enabled', function () {
      const appFeatures = this.get('app.features') || [];
      const feature = appFeatures.findBy('name', featureName);
      return feature ? (0, _object.get)(feature, 'enabled') : false;
    });
  }
});