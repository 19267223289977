define("dashboard/models/pay-balance", ["exports", "jquery", "rsvp", "@ember/object", "dashboard/config/environment"], function (_exports, _jquery, _rsvp, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    url: `${_environment.default.platformApiUrl}/account/payments`,
    data: (0, _object.computed)('invoice', function () {
      return {
        invoice_id: this.get('invoice.number').toString(),
        uuid: this.get('invoice.id'),
        amount: this.get('invoice.total')
      };
    }),

    pay() {
      return new _rsvp.Promise((resolve, reject) => {
        return _jquery.default.ajax({
          url: this.url,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Accept: 'application/vnd.heroku+json; version=3'
          },
          withHerokuAuthorization: true,
          data: JSON.stringify(this.data)
        }).then(resolve).fail(reject);
      }).then(() => {
        this.set('invoice.state', 1);
      }, error => {
        this.handlePaymentError(error);
      });
    },

    handlePaymentError(error) {
      if (error.status === 409) {
        // invoice is already paid
        this.set('invoice.state', 1);
      } else {
        this.set('invoice.attemptedPaymentFailed', true);
      }
    }

  });

  _exports.default = _default;
});