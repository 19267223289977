define("dashboard/services/error-monitor", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/object/evented", "@ember/service", "@ember/object", "dashboard/utils/error-container"], function (_exports, _computed, _runloop, _evented, _service, _object, _errorContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    flashMessages: (0, _service.inject)(),
    errors: (0, _object.computed)(function () {
      return [];
    }),
    errorsWithContent: (0, _computed.filterBy)('errors', 'notEmpty'),
    flashErrors: (0, _computed.filterBy)('errorsWithContent', 'flashable'),
    flashWatcher: (0, _evented.on)('init', (0, _object.observer)('flashErrors.[]', function () {
      (0, _runloop.once)(this, 'displayFlashErrors');
    })),

    displayFlashErrors() {
      // displays the flashable messages from the computed property and then
      // clears them from the base errors array so they can't be flashed again
      this.flashErrors.toArray().forEach(error => {
        error.displayError(this.flashMessages);
        this.removeError(error);
      });
    },

    wipe() {
      this.errors.clear();
      this.flashMessages.clearMessages();
    },

    removeError(error) {
      this.errors.removeObject(error);
    },

    /* called for potentially unhandled errors.
     * will no-op if the XHR object indicates that the response
     * has been handled, or if it was marked as suppressed
     */
    parseXHR(xhr) {
      if (xhr.errorIsHandled || xhr.suppressErrors) {
        return false;
      } else if (xhr.model) {
        xhr.model.set('errorContainer.xhr', xhr);
      } else {
        this.createErrorContainer({
          xhr
        });
      }
    },

    /* the only proper way to create an ErrorContainer object */
    createErrorContainer(options) {
      const errors = this.errors;

      if (!errors) {
        return;
      } // odd situation that can happen in testing


      options = options || {};

      const container = _errorContainer.default.create(options);

      errors.addObject(container);
      return container;
    }

  });

  _exports.default = _default;
});