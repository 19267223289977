define("dashboard/templates/components/account/close-account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Bo/HvNNi",
    "block": "[[[6,[39,0],null,[[\"buttonClassNames\",\"buttonText\",\"modalTitle\",\"modalActionButtonText\",\"modalActionButtonClassNames\",\"buttonTestTarget\",\"onConfirm\"],[\"hk-button--danger\",\"Close this account...\",[30,0,[\"modalTitle\"]],\"Close Account\",\"btn btn-primary\",\"close-account\",[28,[37,1],[[30,0],\"closeAccount\"],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,2],null,[[\"prompt\",\"password\"],[\"Closing your account is irreversible. Enter your Heroku password to confirm you want to permanently close this account:\",[30,0,[\"account\",\"password\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"confirm/confirmable-action\",\"action\",\"confirm/with-password\"]]",
    "moduleName": "dashboard/templates/components/account/close-account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});