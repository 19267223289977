define("dashboard/adapters/github-commit-status", ["exports", "ember-data-github/adapters/github", "rsvp"], function (_exports, _github, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CHUNK_SIZE = 20;

  function buildQuery(_ref) {
    let {
      repo,
      refs
    } = _ref;
    const [owner, name] = repo.split('/');
    return `
    fragment commitStatus on Commit {
      oid
      status {
        contexts {
          id
          context
          description
          state
          targetUrl
        }
      }
    }

    {
      repository(owner: "${owner}", name: "${name}") {
        nameWithOwner
        ${refs.map((ref, index) => `
          ref__${index}: object(expression: "${ref}") {
            ...commitStatus
          }
        `).join('\n')}
      }
    }
  `;
  }

  function makeBatches(collection) {
    let size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CHUNK_SIZE;
    const copy = collection.slice(0);
    const batches = [];

    while (copy.length) {
      batches.push(copy.splice(0, size));
    }

    return batches;
  }

  var _default = _github.default.extend({
    query(store, type, query) {
      const refBatches = makeBatches(query.refs);
      const url = `${this.host}/graphql`;
      return _rsvp.default.all(refBatches.map(refs => {
        return this.ajax(url, 'POST', {
          data: {
            query: buildQuery({
              refs,
              repo: query.repo
            })
          }
        });
      }));
    }

  });

  _exports.default = _default;
});