define("dashboard/controllers/team/space/settings", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    analytics: (0, _service.inject)(),
    title: 'Space Settings',
    space: (0, _computed.alias)('model.space'),
    logDrain: (0, _computed.alias)('model.logDrain'),
    teamPermissions: (0, _computed.readOnly)('space.team.currentPermissions'),
    canManageSpaces: (0, _computed.readOnly)('teamPermissions.canManageSpaces'),
    team: (0, _computed.readOnly)('space.team'),
    shouldShowSpaceTransfer: (0, _computed.bool)('team.hasEnterpriseAccount'),
    actions: {
      saveRename(newName) {
        const space = this.space;
        space.set('newName', newName);
        return space.save().then(() => {
          this.analytics.logEvent('Space', 'Renamed');
          this.transitionToRoute('team.space.settings', this.get('space.name'));
        }).catch(() => {
          space.rollbackAttributes();
        });
      },

      delete() {
        const teamName = this.get('space.team.name');
        this.space.destroyRecord().then(() => {
          this.transitionToRoute('team.switchable.spaces', teamName);
        });
      }

    }
  });

  _exports.default = _default;
});