define("dashboard/serializers/pipeline-promotion", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      const targets = snapshot.record.get('targets').map(target => {
        return {
          app: {
            id: target.get('id')
          }
        };
      });
      return {
        pipeline: {
          id: snapshot.record.get('pipeline.id')
        },
        source: {
          app: {
            id: snapshot.record.get('source.id')
          }
        },
        targets
      };
    },

    extractRelationships(modelClass, hash) {
      const relationships = {};
      relationships.source = {
        data: {
          id: hash.source.app.id,
          type: 'app'
        }
      };
      relationships.pipeline = {
        data: {
          id: hash.pipeline.id,
          type: 'pipeline'
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});