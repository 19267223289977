define("dashboard/routes/app/code", ["exports", "dashboard/routes/basic-app"], function (_exports, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    beforeModel(transition) {
      this._super.apply(this, arguments);

      const redirectRoute = transition.targetName.replace('code', 'deploy');
      this.transitionTo(redirectRoute);
    }

  });

  _exports.default = _default;
});