define("dashboard/mixins/metrics/language-charts", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed", "dashboard/mixins/metrics/java-charts", "dashboard/mixins/metrics/go-charts", "dashboard/mixins/metrics/ruby-charts", "dashboard/mixins/metrics/node-charts"], function (_exports, _mixin, _object, _computed, _javaCharts, _goCharts, _rubyCharts, _nodeCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_javaCharts.default, _goCharts.default, _rubyCharts.default, _nodeCharts.default, {
    languageMetricsAreSetUp: (0, _computed.or)('goMetricsAreSetUp', 'jvmMetricsAreSetUp', 'rubyMetricsAreSetUp', 'nodeMetricsAreSetUp'),
    languageMetricsNotSetUp: (0, _computed.not)('languageMetricsAreSetUp'),
    observeLanguageMetricsSetupState: (0, _object.observer)('languageMetricsAreSetUp', 'app.hasRuntimeMetricsEnabled', 'languageMetricsNotSetUp', function () {
      const languageMetricsSetupNotCompleted = this.get('app.hasRuntimeMetricsEnabled') && this.languageMetricsNotSetUp;
      this.setLanguageMetricsSetupNotCompleted(languageMetricsSetupNotCompleted);
    }),
    // Used for GA language metrics (do not include Beta languages here)
    languageMetricsAvailableTitle: (0, _object.computed)('app.isEligibleForJvmMetrics', function () {
      let language = '';

      if (this.get('app.isEligibleForJvmMetrics')) {
        language = 'JVM Runtime';
      }

      return `${language} Metrics Available`;
    }),
    isShowingLanguageDiscovery: (0, _object.computed)('languageMetricsNotSetUp', 'dashboardUserPreferences.hasDismissedLanguageDiscovery', 'app.isEligibleForLanguageMetricsGA', 'jvmMetricsAreSetUpAndNotWorking', function () {
      // We should only be showing the discovery component for JVM metrics if the user has not toggled then
      // enhanced metrics feature. If it is toggled but has no metrics, then there is something broken
      // and there is no action for the user to take. We will show an app guidance item instead.
      if (this.jvmMetricsAreSetUpAndNotWorking) {
        return false;
      }

      const isEligibleForButNotShowingCharts = this.get('app.isEligibleForLanguageMetricsGA') && this.languageMetricsNotSetUp;
      return this.get('dashboardUserPreferences.hasDismissedLanguageDiscovery') ? false : isEligibleForButNotShowingCharts;
    }),
    languageDiscoveryActionText: (0, _object.computed)('app.hasRuntimeMetricsEnabled', 'languageMetricsNotSetUp', function () {
      const languageMetricsSetupNotCompleted = this.get('app.hasRuntimeMetricsEnabled') && this.languageMetricsNotSetUp;
      return languageMetricsSetupNotCompleted ? 'Complete Setup' : 'Learn More';
    }),
    languageChartsToShow: (0, _object.computed)('jvmChartsToShow', 'goChartsToShow', 'rubyChartsToShow', 'nodeChartsToShow', 'jvmMetricsAreSetUp', 'rubyMetricsAreSetUp', 'goMetricsAreSetUp', 'nodeMetricsAreSetUp', function () {
      const charts = [];

      if (this.jvmMetricsAreSetUp) {
        charts.pushObjects(this.jvmChartsToShow);
      }

      if (this.rubyMetricsAreSetUp) {
        charts.pushObjects(this.rubyChartsToShow);
      }

      if (this.goMetricsAreSetUp) {
        charts.pushObjects(this.goChartsToShow);
      }

      if (this.nodeMetricsAreSetUp) {
        charts.pushObjects(this.nodeChartsToShow);
      }

      return charts;
    })
  });

  _exports.default = _default;
});