define("dashboard/components/app-item-favorite-tray/metrics/throughput-chart", ["exports", "dashboard/components/metrics/charts/visualizations/request-throughput", "dashboard/utils/metrics/latest-non-zero-value", "dashboard/templates/components/app-item-favorite-tray/metrics/favorite-chart"], function (_exports, _requestThroughput, _latestNonZeroValue, _favoriteChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _requestThroughput.default.extend({
    layout: _favoriteChart.default,
    classNames: ['metrics__summary-row__item'],
    attributeBindings: ['title'],
    title: 'Latest web requests per second',
    label: 'throughput',
    displayUnit: 'rps',
    chartTitle: 'Throughput over past 24hrs',
    displayLatestValue: (0, _latestNonZeroValue.default)('data.rpsAll', '0,0a', '< 1'),

    click() {
      this.logClick('throughput');
    }

  });

  _exports.default = _default;
});