define("dashboard/adapters/enterprise-account/license-usage", ["exports", "dashboard/adapters/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // This endpoint is not being made public.
    version: '3.enterprise-accounts',
    dsErrorMonitor: (0, _service.inject)(),

    handleResponse(status, headers, payload, requestData) {
      // this new license service returns a 422 when the
      // account lacks a tenant id / active licenses
      if (status === 422) {
        // prevent the red error bubble
        requestData.jqXHR.errorIsHandled = true;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});