define("dashboard/components/app/deploy/github/manage-third-party-connection", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed"], function (_exports, _component, _service, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    analytics: (0, _service.inject)(),
    torii: (0, _service.inject)(),
    errorMonitor: (0, _service.inject)(),
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    reposApiGithubToken: (0, _computed.readOnly)('current.account.reposApiGithubToken'),
    githubUserLogin: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.login'),
    githubUserName: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.name'),
    githubUserLink: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.htmlUrl'),
    isLegacyAndHasGithubApp: (0, _computed.and)('app.connectedRepository.herokuLegacyLink', 'hasGithubAppInstalled'),
    _enterpriseAccountMember: (0, _object.computed)('app.team.enterprise.members.@each.userId', 'current.accountId', function () {
      const members = this.get('app.team.enterprise.members') || [];
      return members.findBy('userId', this.current.accountId);
    }),
    hasAdminAccess: (0, _object.computed)('herokuResourceType', 'app.team.role', '_enterpriseAccountMember', function () {
      if (this.herokuResourceType === 'enterprise-account') {
        return this.get('_enterpriseAccountMember.canManage');
      } else if (this.herokuResourceType === 'team') {
        return this.get('app.team.role') === 'admin';
      } else {
        return true;
      }
    }),
    githubAppInstallation: (0, _object.computed)('app', function () {
      if (this.app.isOwnedByTeam) {
        if (this.app.get('team.isEnterpriseTeam')) {
          return this.app.get('team.enterprise.githubAppInstallation');
        } else {
          return this.app.get('team.githubAppInstallation');
        }
      } else {
        return this.current.get('account.githubAppInstallation');
      }
    }),
    hasGithubAppInstalled: (0, _computed.notEmpty)('githubAppInstallation.content'),
    herokuResourceType: (0, _object.computed)('app', function () {
      if (this.app.isOwnedByTeam) {
        if (this.app.get('team.isEnterpriseTeam')) {
          return 'enterprise-account';
        } else {
          return 'team';
        }
      } else {
        return 'user';
      }
    }),
    herokuResourceId: (0, _object.computed)('herokuResourceType', function () {
      if (this.herokuResourceType === 'enterprise-account') {
        return this.app.get('team.enterprise.id');
      } else if (this.herokuResourceType === 'team') {
        return this.app.get('team.id');
      } else {
        return this.current.accountId;
      }
    }),
    searchQuery: (0, _object.computed)('searchTerm', 'lastSearchTerm', 'herokuResourceType', 'herokuResourceId', function () {
      const query = {
        q: `fork:true ${this.searchTerm}`
      };

      switch (this.herokuResourceType) {
        case 'enterprise-account':
          query.enterpriseId = this.herokuResourceId;

          if (this.app.get('team.isGithubAppEnabled')) {
            query.isGithubAppEnabled = true;
          }

          break;

        case 'team':
          query.teamId = this.herokuResourceId;
          break;

        case 'user':
          query.userId = this.herokuResourceId;
          break;
      }

      return query;
    }),
    appPipeline: (0, _computed.readOnly)('app.pipelineCoupling.pipeline'),
    actions: {
      search() {
        const searchQuery = Object.assign({}, this.get('searchQuery'));
        this.set('repos', []);
        this.set('lastSearchTerm', null);
        return this.store.query('repositories-api-github-repo', searchQuery).then(repos => {
          this.setProperties({
            lastSearchTerm: this.searchTerm,
            repos
          });
        });
      },

      reconnectRepository(repo) {
        repo.set('app', this.app);
        return repo.save({
          adapterOptions: {
            linkedResourceType: 'app',
            migrating: true
          }
        }).then(() => {
          this.analytics.logEvent('App to Github Apps', 'Migrated', {
            appId: this.get('app.id')
          });
          return this.app.get('reloadConnectedRepositoryTask').perform().then(() => {
            this.app.get('getServicesTask').perform();
            this.app.get('getBranchesTask').perform();
          });
        }).catch(e => {
          this.get('reporter.rollbar').info('Failed to reconnect app', {
            error: e
          });
        });
      },

      connectRepository(repo) {
        repo.set('app', this.app);
        return repo.save({
          adapterOptions: {
            linkedResourceType: 'app'
          }
        }).then(() => {
          this.set('repos', []);
          this.set('lastSearchTerm', null);
          this.set('searchTerm', null);
          this.app.get('getServicesTask').perform();
          this.app.get('getBranchesTask').perform();
          this.analytics.logEvent('Github Apps App', 'Connected', {
            appId: this.get('app.id')
          });
        }).catch(e => {
          repo.set('app', null);
          this.get('reporter.rollbar').info('Failed to connect app', {
            error: e
          });
        });
      },

      disconnectRepository() {
        const repo = this.get('app.connectedRepository.content');
        repo.set('disconnectedResourceId', this.get('app.id'));
        repo.set('app', null);
        this.analytics.logEvent('Github Apps App', 'Disconnected', {
          appId: this.get('app.id')
        });
        return repo.save({
          adapterOptions: {
            linkedResourceType: 'app'
          }
        });
      },

      logEvent(noun, action) {
        this.analytics.logEvent(noun, action);
      }

    }
  });

  _exports.default = _default;
});