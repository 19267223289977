define("dashboard/controllers/new-team", ["exports", "@ember/array", "@ember/string", "@ember/controller", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/service", "dashboard/config/environment", "dashboard/utils/errors", "dashboard/helpers/stripe-form-enablement"], function (_exports, _array, _string, _controller, _computed, _object, _utils, _service, _environment, _errors, _stripeFormEnablement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    not,
    or
  } = _object.computed;

  var _default = _controller.default.extend({
    errorMonitor: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    isEditing: false,
    isShowingTeamsLimitReachedModal: false,
    orgNameIsAvailable: false,
    orgNameIsValidAndAvailable: and('model.nameIsValid', 'orgNameIsAvailable'),
    orgNameIsNotValidAndAvailable: not('orgNameIsValidAndAvailable'),
    creditCardIsValid: false,
    creditCardIsNotValid: not('creditCardIsValid'),
    buttonIsDisabled: or('creditCardIsNotValid', 'orgNameIsNotValidAndAvailable'),
    creditCardAction: (0, _object.computed)('cardLastFour', function () {
      return (0, _utils.isEmpty)(this.get('cardLastFour')) ? 'Add' : 'Change';
    }),
    cardTypeClass: (0, _object.computed)('paymentMethod.card_type', function () {
      const paymentMethodCardType = this.get('paymentMethod.card_type');

      if (paymentMethodCardType) {
        const type = paymentMethodCardType.toLowerCase();
        return `icon-cc-${type}`;
      } else {
        return 'hidden';
      }
    }),
    cardLastFour: (0, _computed.readOnly)('paymentMethod.card_last4'),
    cardType: (0, _object.computed)('paymentMethod.card_type', function () {
      const paymentMethodCardType = this.get('paymentMethod.card_type');

      if (paymentMethodCardType) {
        return (0, _string.capitalize)(paymentMethodCardType);
      }
    }),
    // TODO clean up when we remove the old form
    accountFeatures: (0, _service.inject)(),
    stripePaymentIsEnabled: (0, _computed.readOnly)('accountFeatures.stripePayment.enabled'),
    isUsingStripeForm: (0, _object.computed)('config.stripeIntegrationEnabled', 'config.stripeIntegrationEnabledForTeams', 'stripePaymentIsEnabled', function () {
      return (0, _stripeFormEnablement.canShowStripeForm)(true, _environment.default.stripeIntegrationEnabled, _environment.default.stripeIntegrationEnabledForTeams, this.get('stripePaymentIsEnabled'));
    }),
    actions: {
      openEditModal() {
        this.analytics.logEvent(`${this.model.analyticsNoun} Add Credit Card Button`, 'Clicked');
        this.set('isEditing', true);
      },

      savePaymentMethod(paymentMethod) {
        this.set('creditCardIsValid', true);
        this.set('paymentMethod', paymentMethod);
        this.toggleProperty('isEditing');
      },

      // TODO clean up when we remove the old form
      saveCreditCard() {
        this.set('creditCardIsValid', true);
        this.toggleProperty('isEditing');
      },

      cancelTeamCreation() {
        this.transitionToRoute('protected');
        const analyticsNoun = this.model.analyticsNoun;
        this.analytics.logEvent(`Create ${analyticsNoun}`, 'Canceled');
      },

      linkToSales() {
        window.open('https://www.heroku.com/contact?c=7013A000000mKrxQAE#sales', '_blank');
      },

      closeLimitModal() {
        this.toggleProperty('isShowingTeamsLimitReachedModal');
      },

      save() {
        const team = this.model;
        const analyticsNoun = team.analyticsNoun;
        this.analytics.logEvent(`Create ${analyticsNoun}`, 'Confirmed');

        if (this.get('isUsingStripeForm')) {
          team.set('paymentMethodNonce', this.paymentMethod.get('nonce'));
        } else {
          // TODO clean up when we remove the old form
          team.set('creditCard', this.paymentMethod);
        }

        return team.save().then(() => {
          this.analytics.logEvent(`Create ${analyticsNoun}`, 'Completed');
          this.transitionToRoute('team.switchable', team.get('name'));
        }).catch(error => {
          this.analytics.logEvent(`Create ${analyticsNoun}`, 'Failed');
          const status = (0, _errors.errorStatus)(error);
          const errorWithStatus = (0, _array.A)(error.errors).find(() => status);
          const msg = errorWithStatus.detail;

          if (status === 422 && new RegExp('teams for an account').test(msg)) {
            this.errorMonitor.wipe();
            this.set('isShowingTeamsLimitReachedModal', true);
          }
        });
      }

    }
  });

  _exports.default = _default;
});