define("dashboard/serializers/metrics/datastores/redis", ["exports", "dashboard/serializers/metrics/datastores/kafka"], function (_exports, _kafka) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RedisSerializer extends _kafka.default {}

  _exports.default = RedisSerializer;
});