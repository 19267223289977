define("dashboard/templates/components/dyno-tier-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gH0nCnnY",
    "block": "[[[1,[28,[35,0],[[30,0,[\"iconName\"]]],null]],[1,\"\\n\"]],[],false,[\"svg-jar\"]]",
    "moduleName": "dashboard/templates/components/dyno-tier-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});