define("dashboard/templates/components/app-json-buildpack-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Bm84QJU0",
    "block": "[[[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"buildpacks\"]]],null]],null],null,[[[1,\"    \"],[10,\"tr\"],[14,0,\"editable-item\"],[12],[1,\"\\n      \"],[10,\"td\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[35,2],null,[[\"value\",\"key-up\",\"class\",\"placeholder\"],[[30,1,[\"name\"]],[28,[37,3],[[30,0],\"checkBuildpacks\"],null],\"form-control monospace\",\"e.g. heroku/nodejs or https://github.com/heroku/heroku-buildpack-ruby\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,\"td\"],[14,0,\"action-cell text-right\"],[12],[1,\"\\n\"],[41,[30,1,[\"name\"]],[[[1,\"          \"],[8,[39,5],[[24,0,\"remove-item\"],[24,4,\"button\"],[4,[38,3],[[30,0],\"removeBuildpack\",[30,1]],null]],[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[\"remove-ring-16\",16,\"fill-red mt1\",[29,[\"Remove buildpack \",[30,1,[\"name\"]]]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"buildpack\"],false,[\"each\",\"-track-array\",\"input\",\"action\",\"if\",\"malibu-icon-button\"]]",
    "moduleName": "dashboard/templates/components/app-json-buildpack-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});