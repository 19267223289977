define("dashboard/components/metrics/charts/visualizations/aggregate-chart", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart"], function (_exports, _object, _wrappedLineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    unit: 'integer',
    insufficientDataTitle: 'Insufficient Data',
    boundaryMultiplier: 1.1,
    valueFormat: '0,0',
    isShowingMax: true,
    isShowingAvg: true,
    isShowingMin: true,
    isShowingMaxNegated: true,
    isShowingAvgNegated: true,
    timeSeriesName: '',
    timeSeriesNameNegated: '',
    hasMinTimeSeries: _object.computed.notEmpty('minData'),
    hasNegatedTimeSeries: _object.computed.or('maxDataNegated', 'avgDataNegated'),
    timeSeriesMax: (0, _object.computed)('maxData', 'isShowingMax', 'timeSeriesName', 'hasExtraData', 'maxDataExtra', function () {
      return _object.default.create({
        name: `Max ${this.timeSeriesName}`,
        color: 'blue-001',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 0,
        data: this.maxData,
        extraData: this.hasExtraData ? this.maxDataExtra : null,
        isVisible: this.isShowingMax,
        key: 'Max'
      });
    }),
    timeSeriesAvg: (0, _object.computed)('avgData', 'isShowingAvg', 'timeSeriesName', 'hasNegatedTimeSeries', 'hasExtraData', 'avgDataExtra', function () {
      return _object.default.create({
        name: `Avg ${this.timeSeriesName}`,
        color: this.hasNegatedTimeSeries ? 'blue-003' : 'blue-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 1,
        data: this.avgData,
        extraData: this.hasExtraData ? this.avgDataExtra : null,
        isVisible: this.isShowingAvg,
        key: 'Avg'
      });
    }),
    timeSeriesMin: (0, _object.computed)('minData', 'isShowingMin', 'timeSeriesName', 'hasExtraData', 'minDataExtra', function () {
      return _object.default.create({
        name: `Min ${this.timeSeriesName}`,
        color: 'blue-003',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 2,
        data: this.minData,
        extraData: this.hasExtraData ? this.minDataExtra : null,
        isVisible: this.isShowingMin,
        key: 'Min',
        hideLine: false
      });
    }),
    timeSeriesMaxNegated: (0, _object.computed)('maxDataNegated', 'isShowingMaxNegated', 'timeSeriesNameNegated', function () {
      return _object.default.create({
        name: `Max ${this.timeSeriesNameNegated}`,
        color: 'blue-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 2,
        data: this.maxDataNegated,
        isVisible: this.isShowingMaxNegated,
        key: 'MaxNegated',
        negated: true
      });
    }),
    timeSeriesAvgNegated: (0, _object.computed)('avgDataNegated', 'isShowingAvgNegated', 'timeSeriesNameNegated', function () {
      return _object.default.create({
        name: `Avg ${this.timeSeriesNameNegated}`,
        color: 'blue-004',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 3,
        data: this.avgDataNegated,
        isVisible: this.isShowingAvgNegated,
        key: 'AvgNegated',
        negated: true
      });
    }),
    chartTimeSeries: (0, _object.computed)('timeSeriesMax', 'timeSeriesAvg', 'timeSeriesMin', 'timeSeriesMaxNegated', 'timeSeriesAvgNegated', 'hasMinTimeSeries', 'hasNegatedTimeSeries', function () {
      const timeSeries = [this.timeSeriesMax, this.timeSeriesAvg];

      if (this.hasMinTimeSeries) {
        timeSeries.push(this.timeSeriesMin);
      }

      if (this.hasNegatedTimeSeries) {
        timeSeries.pushObjects([this.timeSeriesMaxNegated, this.timeSeriesAvgNegated]);
      }

      return timeSeries;
    })
  });

  _exports.default = _default;
});