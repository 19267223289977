define("dashboard/components/copyable-textarea", ["exports", "@ember/runloop", "dashboard/components/autogrow-textarea", "jquery"], function (_exports, _runloop, _autogrowTextarea, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autogrowTextarea.default.extend({
    classNames: ['form-control'],
    readonly: true,

    focusIn() {
      // This needs to run a bit later in order to work in safari:
      // https://stackoverflow.com/a/6201757
      _runloop.run.next(() => {
        if (this.element === document.activeElement) {
          (0, _jquery.default)(this.element).select();
        }
      });
    }

  });

  _exports.default = _default;
});