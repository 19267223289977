define("dashboard/templates/components/enterprise-account/usage/export-usage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3DKXqAyP",
    "block": "[[[1,[28,[35,0],null,[[\"enterpriseAccount\"],[[30,0,[\"enterpriseAccount\"]]]]]],[1,\"\\n\\n\"],[1,[34,1]],[1,\"\\n\"]],[],false,[\"enterprise-account/usage-export-options\",\"enterprise-account/usage-disclaimer\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/usage/export-usage.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});