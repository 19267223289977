define("dashboard/components/new/template-config-vars", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['template-env'],
    keyAscending: ['key:asc'],
    sortedConfigVars: (0, _computed.sort)('configVars', 'keyAscending')
  });

  _exports.default = _default;
});