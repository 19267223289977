define("dashboard/controllers/enterprise-account", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    enterpriseAccount: (0, _computed.alias)('model.enterpriseAccount'),
    queryParams: ['create'],
    create: null,
    createTeam: (0, _computed.equal)('create', 'team'),
    actions: {
      hideCreateTeam() {
        this.set('create', null);
      }

    }
  });

  _exports.default = _default;
});