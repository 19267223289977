define("dashboard/models/github-status", ["exports", "jquery", "@ember/object", "@ember/object/computed", "dashboard/config/environment"], function (_exports, _jquery, _object, _computed, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    isNone: (0, _computed.equal)('status', 'none'),
    isMinor: (0, _computed.equal)('status', 'minor'),
    isMajor: (0, _computed.equal)('status', 'major'),
    isCritical: (0, _computed.equal)('status', 'critical'),
    isIncident: (0, _computed.or)('isMinor', 'isMajor', 'isCritical')
  }).reopenClass({
    fetch() {
      return _jquery.default.ajax(_environment.default.githubStatusApiUrl).then(response => {
        return this.create({
          status: response.status.indicator
        });
      }).fail(jqXHR => {
        jqXHR.errorIsHandled = true;
      });
    }

  });

  _exports.default = _default;
});