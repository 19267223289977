define("dashboard/utils/load-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadInvoice;

  function loadInvoice(owner, url, token) {
    const headers = {
      'X-Origin': window.location.origin,
      Accept: 'text/html'
    };
    /**
     * Add necessary headers when assuming identity.
     */

    const session = owner.lookup('session:main');

    if (session.get('sudoReason')) {
      headers['X-Heroku-Sudo'] = true;
      headers['X-Heroku-Sudo-User'] = session.get('user_id');
      headers['X-Heroku-Sudo-Reason'] = session.get('sudoReason');
    }

    $.ajax({
      withHerokuAuthorization: true,
      method: 'POST',
      url,
      data: {
        token
      },
      headers
    }).then(response => {
      document.open();
      document.write(response);
      document.close();
    });
  }
});