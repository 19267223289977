define("dashboard/routes/app/webhook-deliveries", ["exports", "@ember/service", "rsvp", "dashboard/routes/basic-app"], function (_exports, _service, _rsvp, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    queryParams: {
      filterBy: {
        refreshModel: true
      }
    },
    analytics: (0, _service.inject)(),

    model(params) {
      const {
        app
      } = this.modelFor('app');
      const {
        webhook_name
      } = params;
      return (0, _rsvp.hash)({
        app,
        webhook: this.store.queryRecord('app/webhook', {
          appName: app.get('name'),
          webHookName: webhook_name
        }),
        deliveries: this.store.query('app/webhookDelivery', {
          webhookName: webhook_name,
          appName: app.get('name'),
          filterBy: params.filterBy
        })
      });
    },

    afterModel() {
      this.analytics.logEvent('Webhooks Deliveries', 'Viewed');
    }

  });

  _exports.default = _default;
});