define("dashboard/templates/components/gravatar-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5+avbwZO",
    "block": "[[[10,\"img\"],[15,\"src\",[29,[[30,0,[\"src\"]]]]],[15,\"alt\",[30,0,[\"alt\"]]],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/gravatar-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});