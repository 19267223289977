define("dashboard/serializers/app/webhook-delivery", ["exports", "dashboard/serializers/application", "ember-data"], function (_exports, _application, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      webhook: {
        serialize: false,
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});