define("dashboard/components/datastore/redis/chart/component", ["exports", "dashboard/components/datastore/datastore-chart", "@ember/object"], function (_exports, _datastoreChart, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datastoreChart.default.extend({
    boundaryMultiplier: 1,
    classNames: ['metrics__data-size-chart ttc'],
    timeseriesColor: (0, _object.computed)('title', function () {
      switch (this.title) {
        case 'calls':
          return 'purple-002';

        case 'time spent':
          return 'purple-001';

        case 'time spent per call':
          return 'blue-001';

        case 'memory':
          return 'purple-001';

        case 'keys':
          return 'blue-001';

        case 'clients':
          return 'purple-002';
      }
    }),
    datastoreTimeSeries: (0, _object.computed)('data', 'timeseriesColor', function () {
      return _object.default.create({
        name: this.title,
        color: this.timeseriesColor,
        type: 'metrics/charts/line-chart-elements/datastore-area-graph',
        index: 0,
        data: this.data,
        isVisible: true,
        key: 'Avg',
        hideLine: false
      });
    }),
    timeSeriesData: (0, _object.computed)('datastoreTimeSeries', function () {
      return [this.datastoreTimeSeries.data];
    }),
    chartContentData: (0, _object.computed)('datastoreTimeSeries', function () {
      return _object.default.create({
        chartTimeSeries: [this.datastoreTimeSeries]
      });
    }),
    summaryData: (0, _object.computed)('datastoreTimeSeries', function () {
      return _object.default.create({
        min: this.summaryMinValue,
        max: this.maxMaxValue,
        unit: this.unit,
        format: this.valueFormat
      });
    })
  });

  _exports.default = _default;
});