define("dashboard/utils/metrics/data-without-zeros", ["exports", "@ember/object/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = dataWithoutZeros;

  function dataWithoutZeros(rawData) {
    return (0, _computed.filter)(rawData, function (data) {
      return data[1] !== 0;
    });
  }
});