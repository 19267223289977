define("dashboard/serializers/team/member", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships(_modelClass, data) {
      const relationships = this._super(...arguments);

      const {
        team
      } = data;

      if (team.id) {
        relationships.team = {
          data: {
            id: team.id,
            type: 'team'
          }
        };
        relationships.apps = {
          links: {
            related: `/teams/${team.id}/members/${data.id}/apps`
          }
        };
      }

      return relationships;
    }

  });

  _exports.default = _default;
});