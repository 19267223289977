define("dashboard/utils/external-link-handler", ["exports", "@ember/runloop", "@ember/utils", "jquery", "@ember/object", "@ember/application"], function (_exports, _runloop, _utils, _jquery, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    init() {
      this._super(...arguments);

      this._router = (0, _application.getOwner)(this).lookup('router:main');
      this._clickHandlerAdded = false;
      this.setupClickHandler();
    },

    _getNormalisedRootUrl(router) {
      let rootURL = router.rootURL;

      if (rootURL.slice(-1) !== '/') {
        rootURL = `${rootURL}/`;
      }

      return rootURL;
    },

    _clickHandler(e) {
      const $target = (0, _jquery.default)(e.currentTarget);
      const handleClick = !e.ctrlKey && !e.metaKey;

      if (handleClick && !$target.hasClass('ember-view') && (0, _utils.isNone)($target.attr('data-ember-action'))) {
        const router = this._router;

        const rootURL = this._getNormalisedRootUrl(router);

        let url = $target.attr('href');

        if (url && url.indexOf(rootURL) === 0) {
          url = url.substr(rootURL.length - 1);

          if (router._routerMicrolib.recognizer.recognize(url)) {
            router.handleURL(url);

            router._routerMicrolib.updateURL(url); // This line is for propagating a click to glostick


            (0, _jquery.default)(document).trigger('click');
            return false;
          }
        }
      }

      return true;
    },

    setupClickHandler() {
      if (this._clickHandlerAdded) {
        return;
      }

      this._boundClickHandler = (0, _runloop.bind)(this, this._clickHandler);
      (0, _jquery.default)(document.body).on('click', 'a', this._boundClickHandler);
      this._clickHandlerAdded = true;
    },

    willDestroy() {
      (0, _jquery.default)(document.body).off('click', 'a', this._boundClickHandler);
    }

  });

  _exports.default = _default;
});