define("dashboard/templates/components/account/api-key", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BIYBFdoZ",
    "block": "[[[6,[39,0],null,[[\"buttonType\",\"class\",\"onClick\"],[\"secondary\",\"fr\",[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"tokenInputType\"]]],null],[52,[28,[37,4],[[30,0,[\"tokenInputType\"]],\"text\"],null],\"password\",\"text\"]],null]]],[[\"default\"],[[[[1,\"  \"],[1,[52,[28,[37,4],[[30,0,[\"tokenInputType\"]],\"text\"],null],\"Hide\",\"Reveal\"]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,2],[15,0,[29,[\"api-key \",[30,0,[\"codeUpdatedClass\"]]]]],[12],[1,\"\\n  \"],[1,[28,[35,5],null,[[\"value\",\"type\"],[[30,0,[\"token\"]],[28,[37,6],[[30,0,[\"tokenInputType\"]],\"password\"],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[6,[39,7],null,[[\"isModalVisible\",\"buttonTestTarget\",\"buttonClassNames\",\"buttonText\",\"modalTitle\",\"modalActionButtonText\",\"modalActionButtonClassNames\",\"onConfirm\"],[[30,0,[\"showRegenerateConfirmation\"]],\"prompt-regenerate-api-key\",\"hk-button--primary\",\"Regenerate API Key...\",\"Regenerate API Key\",\"Regenerate API Key\",\"btn btn-primary\",[28,[37,1],[[30,0],\"regenerateKey\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    \"],[10,\"b\"],[12],[1,\"Regenerating your API key will cause any clients using this token to lose access.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Are you sure you want to regenerate your API key?\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"hk-button\",\"action\",\"mut\",\"if\",\"eq\",\"copyable-input\",\"or\",\"confirm/confirmable-action\"]]",
    "moduleName": "dashboard/templates/components/account/api-key.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});