define("dashboard/templates/components/flash-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Cw1xpuay",
    "block": "[[[41,[30,0,[\"isDismissable\"]],[[[1,\"  \"],[11,\"button\"],[24,0,\"close\"],[24,\"data-dismiss\",\"alert\"],[24,\"aria-label\",\"Close\"],[24,4,\"button\"],[4,[38,1],[[30,0],[30,0,[\"dismissAction\"]]],null],[12],[1,\"\\n    \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"title\"]],[[[1,\"  \"],[10,0],[14,0,\"alert-title\"],[12],[2,[30,0,[\"title\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"message\"]],[[[1,\"  \"],[10,0],[14,0,\"alert-body\"],[12],[2,[30,0,[\"message\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"alert-body\"],[12],[18,1,null],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"action\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/flash-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});