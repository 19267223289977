define("dashboard/components/pipeline/promotion-status", ["exports", "@ember/runloop", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _runloop, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    promotion: (0, _computed.readOnly)('app.currentPromotion'),
    observeStatus: (0, _object.observer)('promotion.aggregateStatus', function () {
      if (this.get('promotion.isSucceeded')) {
        this.set('recentlySucceeded', true);
        const timer = (0, _runloop.later)(() => {
          this.set('recentlySucceeded', false);
        }, 5000);
        this.on('willDestroyElement', () => (0, _runloop.cancel)(timer));
      }
    })
  });

  _exports.default = _default;
});