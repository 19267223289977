define("dashboard/templates/components/app/addons-devcenter-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WYDCeYqL",
    "block": "[[[11,3],[16,6,[29,[[30,0,[\"devcenterUrl\"]]]]],[16,0,[29,[\"hk-link addon-details \",[30,0,[\"class\"]]]]],[4,[38,0],[[30,0],\"trackAndGoToDevcenterUrl\"],null],[12],[18,1,null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/app/addons-devcenter-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});