define("dashboard/templates/components/pipeline/review-app-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DE5DC32a",
    "block": "[[[10,0],[14,0,\"flex items-center mb1\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nudge-left--1 nudge-up--1 red\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"failure-badge-16\",\"flex-shrink-0 fill-red mr1\",16]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto f6 red\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"b\"],[12],[1,\"\\n\"],[41,[30,0,[\"reviewApp\",\"isErrored\"]],[[[1,\"        Latest build failed\\n\"]],[]],[[[1,\"        Release \"],[10,1],[14,0,\"hk-badge bg-red ttn\"],[12],[1,\"v\"],[1,[30,0,[\"currentRelease\",\"version\"]]],[13],[1,\" failed\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"mt--2\"],[12],[1,\"\\n\"],[41,[30,0,[\"reviewApp\",\"isErrored\"]],[[[1,\"    \"],[1,[30,0,[\"reviewApp\",\"errorMessage\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"code\"],[12],[1,[30,0,[\"currentRelease\",\"slug\",\"processTypes\",\"release\"]]],[13],[1,\" exited with an error.\\n\"]],[]]],[1,\"  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"malibu-icon\",\"if\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/pipeline/review-app-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});