define("dashboard/adapters/team/addon", ["exports", "dashboard/adapters/application", "dashboard/mixins/adapters/range-aware-adapter"], function (_exports, _application, _rangeAwareAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rangeAwareAdapter.default, {
    range: {
      attribute: 'name',
      max: 1000
    },

    urlForQuery(query) {
      const path = `${this.host}/teams/${query.team}/addons`;
      delete query.team;
      return path;
    }

  });

  _exports.default = _default;
});