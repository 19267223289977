define("dashboard/components/account/close-account", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    account: (0, _computed.alias)('current.account'),
    modalTitle: (0, _object.computed)('current.account.email', function () {
      return `Close Account (${this.get('current.account.email')})`;
    }),
    actions: {
      closeAccount() {
        const account = this.account;
        const pendingClose = account.destroyRecord();
        return pendingClose.then(() => {
          this.analytics.logEvent('Account', 'Closed');
          this.attrs.onClose();
        }).catch(() => {
          account.rollbackAttributes();
        });
      }

    }
  });

  _exports.default = _default;
});