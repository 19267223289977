define("dashboard/utils/error-manifest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a manifest of heroku error codes and their meanings
   *
   */
  var _default = [{
    name: 'H10',
    title: 'App Crashed',
    level: 'critical'
  }, {
    name: 'H11',
    title: 'Backlog too deep',
    level: 'critical'
  }, {
    name: 'H12',
    title: 'Request Timeout',
    level: 'critical'
  }, {
    name: 'H13',
    title: 'Connection closed without response',
    level: 'critical'
  }, {
    name: 'H14',
    title: 'No web dynos running',
    level: 'critical'
  }, {
    name: 'H15',
    title: 'Idle connection',
    level: 'warning'
  }, {
    name: 'H16',
    title: 'Redirect to herokuapp.com',
    level: 'warning'
  }, {
    name: 'H17',
    title: 'Poorly formatted HTTP response',
    level: 'warning'
  }, {
    name: 'H18',
    title: 'Server Request Interrupted',
    level: 'critical'
  }, {
    name: 'H19',
    title: 'Backend connection timeout',
    level: 'critical'
  }, {
    name: 'H20',
    title: 'App boot timeout',
    level: 'critical'
  }, {
    name: 'H21',
    title: 'Backend connection refused',
    level: 'critical'
  }, {
    name: 'H22',
    title: 'Connection limit reached',
    level: 'critical'
  }, {
    name: 'H23',
    title: 'Endpoint misconfigured',
    level: 'critical'
  }, {
    name: 'H24',
    title: 'Forced close',
    level: 'critical'
  }, {
    name: 'H25',
    title: 'HTTP Restriction',
    level: 'critical'
  }, {
    name: 'H26',
    title: 'Request Error',
    level: 'critical'
  }, {
    name: 'H27',
    title: 'Client Request Interrupted',
    level: 'info'
  }, {
    name: 'H28',
    title: 'Client Connection Idle',
    level: 'warning'
  }, {
    name: 'H29',
    title: 'Client IP appears on DenyList',
    level: 'info'
  }, {
    name: 'H30',
    title: 'Client IP exceeded rate limit',
    level: 'warning'
  }, {
    name: 'H31',
    title: 'Misdirected Request',
    level: 'critical'
  }, {
    name: 'H80',
    title: 'Maintenance Mode',
    level: 'warning'
  }, {
    name: 'H81',
    title: 'Blank app',
    level: 'info'
  }, {
    name: 'H82',
    title: 'Free dyno quota exhausted',
    level: 'info'
  }, {
    name: 'H99',
    title: 'Platform error',
    level: 'critical'
  }, {
    name: 'R10',
    title: 'Boot timeout',
    level: 'critical'
  }, {
    name: 'R12',
    title: 'Exit timeout',
    level: 'warning'
  }, {
    name: 'R13',
    title: 'Attach error',
    level: 'warning'
  }, {
    name: 'R14',
    title: 'Memory quota exceeded',
    level: 'warning'
  }, {
    name: 'R15',
    title: 'Memory quota vastly exceeded',
    level: 'critical'
  }, {
    name: 'R151',
    title: 'Memory quota vastly exceeded',
    level: 'warning'
  }, {
    name: 'R16',
    title: 'Detached',
    level: 'warning'
  }, {
    name: 'R99',
    title: 'Platform error',
    level: 'warning'
  }, {
    name: 'L10',
    title: 'Drain buffer overflow',
    level: 'warning'
  }, {
    name: 'L11',
    title: 'Tail buffer overflow',
    level: 'warning'
  }, {
    name: 'L12',
    title: 'Local buffer overflow',
    level: 'warning'
  }, {
    name: 'L13',
    title: 'Local delivery error',
    level: 'critical'
  }];
  _exports.default = _default;
});