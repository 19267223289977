define("dashboard/components/metrics/charts/visualizations/routing-health-chart", ["exports", "dashboard/components/metrics/charts/visualizations/wrapped-row-chart", "@ember/object", "@ember/object/computed"], function (_exports, _wrappedRowChart, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedRowChart.default.extend({
    tagName: 'metrics-routing-health-chart',
    isDevPreview: true,
    chartKey: 'routing-health',
    chartTitle: 'Routing Health',
    insufficientDataTitle: 'No Degradations',
    devCenterName: 'routing-health-metrics-for-private-space-apps',
    summaryUnavailable: (0, _computed.or)('cannotAccessSpacesApp', 'spacesAppUsesTrustedIPs'),
    summaryComponent: 'metrics/charts/chart-header/routing-health-summary',
    summaryData: (0, _computed.alias)('refreshingCurrentRoutingHealth'),
    legendItems: (0, _object.computed)(function () {
      const baseLegend = [_object.default.create({
        name: 'Degraded',
        color: 'crit'
      })];
      return baseLegend;
    }),
    routingHealth: (0, _computed.readOnly)('refreshingRoutingHealth'),
    isLoadingData: (0, _computed.or)('isRefreshingData', 'spaceMetricsDependenciesPending'),
    messageContent: (0, _object.computed)('cannotAccessSpacesApp', 'spacesAppUsesTrustedIPs', function () {
      if (this.cannotAccessSpacesApp) {
        return {
          title: 'No Private Space Access',
          description: 'Without access to the private space, we cannot grant you access to Routing Health metrics for this application. Ask the application owner for private space status in order to view this data.',
          devCenterUrl: 'https://devcenter.heroku.com/articles/private-spaces',
          devCenterTitle: 'Private Spaces'
        };
      } else if (this.spacesAppUsesTrustedIPs) {
        return {
          title: 'Trusted IP range restriction',
          description: 'Your Private Space has trusted IP ranges enabled that prevent data sharing with our metrics servers. Currently only Private Spaces with the default trusted IP range of 0.0.0.0/0 allow the access required for Routing Health metrics to be shown.',
          devCenterUrl: 'https://devcenter.heroku.com/articles/private-spaces#trusted-ip-ranges',
          devCenterTitle: 'Trusted IP ranges'
        };
      }
    }),
    degradations: (0, _computed.alias)('routingHealth.degradations'),
    hasDegradations: (0, _computed.notEmpty)('degradations'),
    rowEvents: (0, _object.computed)('degradations.[]', 'hasDegradations', function () {
      const rowEvents = [];

      if (this.hasDegradations) {
        rowEvents.push(_object.default.create({
          name: 'Health',
          chartContextGroupComponentName: 'metrics/charts/connected-chart-hover/routing-health-group',
          description: 'Routing Health',
          level: 'critical',
          models: this.degradations,
          url: 'https://devcenter.heroku.com/articles/routing-health-metrics-for-private-space-apps'
        }));
      }

      return rowEvents;
    }),
    eventData: (0, _computed.collect)('routingHealth')
  });

  _exports.default = _default;
});