define("dashboard/services/glostick", ["exports", "@ember/service", "@ember/application", "@ember/object/computed", "dashboard/config/environment", "dashboard/mixins/adapters/authorization-headers-mixin"], function (_exports, _service, _application, _computed, _environment, _authorizationHeadersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_authorizationHeadersMixin.default, {
    session: (0, _service.inject)(),
    quickJumpRouter: (0, _service.inject)(),

    init() {
      this._super(...arguments);

      this.set('glostickInitialized', false);
    },

    initGlostick(options) {
      // Business as usual if Glostick isn't available
      if (!window.Glostick || _environment.default.environment === 'test') {
        return;
      }

      if (this.glostickInitialized) {
        return;
      }

      const owner = (0, _application.getOwner)(this);
      const application = owner.lookup('application:main');
      const router = this.quickJumpRouter.router();
      window.Glostick.init({
        userEmail: options.accountEmail,
        userName: options.accountName,
        targetSelector: application.rootElement,
        accountUrl: '/account',
        dashboardUrl: '/',
        accountLinks: options.assumeLink,
        searchLink: false,
        logoutUrl: `${_environment.default.appHost}/logout`,
        notificationsLink: options.notificationsLink,
        sfdcBranding: options.sfdcBranding,
        bearerToken: this.bearerToken,
        quickJumpRouter: router,
        particleboardUrl: _environment.default.particleboardUrl,
        longboardUrl: _environment.default.particleboardUrl,
        sudoHeaders: this.sudoHeaders,
        helmet: options.helmet
      });
      this.set('glostickInitialized', true);
      owner.lookup('util:external-link-handler');
    },

    bearerToken: (0, _computed.readOnly)('session.bearerToken'),

    refreshQuickJump() {
      if (!window.Glostick || _environment.default.environment === 'test') {
        return;
      }

      window.Glostick.refreshQuickJump();
    }

  });

  _exports.default = _default;
});