define("dashboard/templates/components/app/web-hooks/list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZlTV5yla",
    "block": "[[[41,[30,0,[\"savedWebhooks\"]],[[[1,\"  \"],[10,0],[14,0,\"db dt-ns mv6 w-100 hk-hide-bb-last-row dark-gray f5-m\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dn db-ns dt-row-ns bb b--silver\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"dtc w4 f5 normal pa2 b dark-gray\"],[12],[1,\"Status\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"dtc-ns f5 normal pa2 b dark-gray\"],[12],[1,\"Webhook Name\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"dtc-ns f5 normal pa2 b dark-gray tc\"],[12],[1,\"Events\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"dtc-ns f5 normal pa2 b dark-gray\"],[12],[1,\"URL\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"dtc-ns f5 normal pa2 b dark-gray w2 tc\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"savedWebhooks\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"wh\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"wh\"],false,[\"if\",\"each\",\"-track-array\",\"app/web-hooks/list-item\"]]",
    "moduleName": "dashboard/templates/components/app/web-hooks/list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});