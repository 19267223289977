define("dashboard/templates/components/enterprise-account/member-manager/search-filter-clear-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jntM9uYc",
    "block": "[[[10,\"button\"],[14,0,\"hk-dropdown-item w-100 pr0 tl\"],[15,\"onclick\",[28,[37,0],[[30,0],[30,0,[\"onClick\"]]],null]],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex w-100 relative items-center\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"delete-16\",\"absolute fill-dark-gray\",14]],null],[1,\"\\n    \"],[10,1],[14,0,\"ml4\"],[12],[18,1,null],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"malibu-icon\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/member-manager/search-filter-clear-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});