define("dashboard/templates/enterprise-account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0udpQ3v6",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\\n\"],[41,[30,0,[\"createTeam\"]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"enterpriseAccount\",\"onClose\"],[[30,0,[\"enterpriseAccount\"]],[28,[37,4],[[30,0],\"hideCreateTeam\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\",\"if\",\"enterprise-account/create-team\",\"action\"]]",
    "moduleName": "dashboard/templates/enterprise-account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});