define("dashboard/components/github-integration-reenabled-banner", ["exports", "@ember/component", "@ember/object/computed", "dashboard/utils/custom-computed", "@ember/utils", "@ember/object"], function (_exports, _component, _computed, _customComputed, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    pipelineRepository: (0, _computed.readOnly)('pipeline.githubRepository'),
    pipelineStatuses: (0, _computed.readOnly)('pipelineRepository.statuses.[]'),
    gitHubTokenStatus: (0, _customComputed.findBy)('pipelineStatuses', 'token', 'status'),
    isGitHubTokenValid: (0, _object.computed)('gitHubTokenStatus.status', function () {
      if ((0, _utils.isPresent)(this.get('gitHubTokenStatus.status'))) {
        return this.get('gitHubTokenStatus.status');
      } // mark a token that does not exist as valid so that we do not
      // show the banner on pipelines who have never had the integration enabled


      return true;
    }),
    isGitHubTokenInvalid: (0, _computed.not)('isGitHubTokenValid'),
    showBanner: (0, _computed.and)('isGitHubTokenInvalid', 'githubIntegrationReenabled')
  });

  _exports.default = _default;
});