define("dashboard/components/space/network/outbound-ruleset-list", ["exports", "@ember/component", "@ember/object", "dashboard/mixins/purple-list"], function (_exports, _component, _object, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_purpleList.default, {
    'data-test-target': 'outbound-ruleset-list',
    newRule: (0, _object.computed)(function () {
      return {
        target: '',
        protocol: ''
      };
    }),

    newOutboundRule() {
      return {
        target: '',
        protocol: ''
      };
    },

    protocols: [{
      label: 'Any',
      value: 'any'
    }, {
      label: 'ICMP',
      value: 'icmp'
    }, {
      label: 'TCP',
      value: 'tcp'
    }, {
      label: 'UDP',
      value: 'udp'
    }],
    titleForDelete: (0, _object.computed)('item', function () {
      const name = this.get('item.target');
      return `Delete <small>(${name})</small>`;
    }),
    actions: {
      saveNewOutboundRuleset() {
        const ruleset = this.ruleset;
        ruleset.addRule(this.newRule);
        return ruleset.save().then(() => {
          this.set('newRule', this.newOutboundRule());
          this.set('resetAsyncBtn', true);
        }).catch(() => {
          ruleset.get('rules').removeObject(this.newRule);
        });
      },

      delete() {
        const ruleset = this.ruleset;
        ruleset.get('rules').removeObject(this.item);
        return ruleset.save().then(() => {
          this.send('toggleDeleteConfirmModal');
        }).catch(() => {
          ruleset.rules.addObject(this.item);
        });
      }

    }
  });

  _exports.default = _default;
});