define("dashboard/models/metrics/datastores/redis", ["exports", "dashboard/models/metrics"], function (_exports, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MetricsDatstoreRedis extends _metrics.default {}

  _exports.default = MetricsDatstoreRedis;
});