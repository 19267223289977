define("dashboard/components/app/formations-list-item", ["exports", "@ember/utils", "@ember/component", "@ember/object", "@ember/service", "dashboard/utils/metrics/data-fetch", "@ember/object/computed"], function (_exports, _utils, _component, _object, _service, _dataFetch, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    classNameBindings: [':list-group-item', ':formations-list-item', 'isEditing:formations-list-item--dirty', 'useSlider:use-slider:use-toggle'],
    isNotEditing: (0, _computed.not)('isEditing'),
    useSlider: (0, _computed.readOnly)('process.dynoCanScale'),
    analytics: (0, _service.inject)(),
    canAccessAutoscaling: (0, _computed.and)('process.sizeObject.canAutoscale', 'process.isWeb'),
    hasChangedQuantity: (0, _object.computed)('process.quantity', 'process.hasDirtyAttributes', 'process.originalQuantity', function () {
      return this.get('process.quantity') !== this.get('process.originalQuantity');
    }),
    formationScaleLimit: (0, _object.computed)('process.sizeObject', 'dynoLimit', function () {
      return this.get(`dynoLimit.${this.get('process.sizeObject.size')}`) || this.get('process.sizeObject.scale');
    }),
    valueMin: (0, _computed.readOnly)('autoscaleMonitor.minQuantity'),
    maxes: (0, _computed.collect)('autoscaleMonitor.maxQuantity', 'formationScaleLimit'),
    valueMax: (0, _computed.min)('maxes'),
    latencyValueMin: 50,
    latencyValueMinRecommended: 500,
    latencyValueMax: 25000,
    beforeHours: 0,
    afterHours: 24,
    latency: (0, _dataFetch.queryProcessData)('metrics/router-latency', {}, 'process.isWeb'),
    recAutoscaleLatency: (0, _object.computed)('latency.medianP95', 'autoscaleMonitor.isActive', 'latencyValueMin', function () {
      let recommendation;

      if (this.get('autoscaleMonitor.isActive') && this.get('latency.medianP95') >= this.latencyValueMinRecommended) {
        recommendation = this.get('latency.medianP95');
      } else if (this.get('autoscaleMonitor.isActive') && this.get('latency.isFulfilled')) {
        recommendation = this.latencyValueMinRecommended;
      }

      return recommendation;
    }),

    _shouldSaveMonitor(autoscaleMonitor, process) {
      const isNew = autoscaleMonitor.get('isNew');
      const monitorIsActive = autoscaleMonitor.get('isActive');
      const newMonitorShouldSave = isNew && monitorIsActive;
      const oldMonitorShouldSave = !isNew && autoscaleMonitor.get('hasDirtyAttributes');
      const processSizeChanged = (0, _utils.isPresent)(process.changedAttributes().size);
      const monitorShouldSave = newMonitorShouldSave || oldMonitorShouldSave;
      const activeMonitorWithProcessChange = monitorIsActive && processSizeChanged;
      return monitorShouldSave || activeMonitorWithProcessChange;
    },

    actions: {
      dynoSizeChange(dynoSize) {
        this.set('isEditing', true);
        this.set('process.size', dynoSize);
      },

      disableAutoscaling() {
        this.set('autoscaleMonitor.isActive', false);
        this.analytics.logMetricsEvent('Monitor', 'Updated', {
          type: 'autoscale',
          action: 'disable'
        });
      },

      enableAutoscaling() {
        const monitor = this.autoscaleMonitor;
        const minQuantity = Math.min(monitor.get('minQuantity'), this.get('process.quantity'));
        const maxQuantity = Math.max(monitor.get('maxQuantity'), this.get('process.quantity'));
        monitor.setProperties({
          isActive: true,
          minQuantity,
          maxQuantity
        });
        this.analytics.logMetricsEvent('Monitor', 'Updated', {
          type: 'autoscale',
          action: 'enable'
        });
      },

      toggle(checked) {
        const newQuantity = checked ? 1 : 0;
        this.set('process.quantity', newQuantity);
      },

      edit() {
        this.set('isEditing', true);
      },

      cancel() {
        this.process.rollbackAttributes();
        const autoscaleMonitor = this.autoscaleMonitor;

        if (autoscaleMonitor) {
          if (autoscaleMonitor.get('isNew')) {
            autoscaleMonitor.set('isActive', false);
          } else {
            autoscaleMonitor.rollbackAttributes();
          }
        }

        this.set('isEditing', false);
      },

      save() {
        const process = this.process;
        const autoscaleMonitor = this.autoscaleMonitor;
        this.analytics.logEvent('Process', 'Updated');
        let pendingSave;

        if (process.get('isWeb') && autoscaleMonitor && this._shouldSaveMonitor(autoscaleMonitor, process)) {
          autoscaleMonitor.setProperties({
            maxQuantity: this.valueMax,
            minQuantity: this.valueMin
          });
          pendingSave = autoscaleMonitor.save();
          this.analytics.logMetricsEvent('Monitor', 'Updated', {
            type: 'process',
            action: 'update'
          });
        } else {
          pendingSave = process.save();
        }

        return pendingSave.then(() => {
          /**
           * We need to reset the app here because it gets lost in all the saving.
           * Without it, the user cannot change process tiers more than once without an error.
           */
          this.reloadFormations(this.get('app.id'));
          this.set('isEditing', false);
        });
      }

    }
  });

  _exports.default = _default;
});