define("dashboard/templates/components/datastore/kafka/nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QskD+oQH",
    "block": "[[[41,[30,0,[\"showSettings\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@link\",\"@name\",\"@iconName\"],[\"app.datastore.settings\",\"Settings\",\"settings-28\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"sub-nav-item\"]]",
    "moduleName": "dashboard/templates/components/datastore/kafka/nav.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});