define("dashboard/components/enable-review-apps-form", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    isMigratingFromLeagcyReviewApps: (0, _computed.readOnly)('pipeline.hasReviewAppParent'),
    headerContent: (0, _object.computed)('isMigratingFromLeagcyReviewApps', function () {
      return this.isMigratingFromLeagcyReviewApps ? 'Upgrade Review Apps' : 'Enable Review Apps';
    }),

    saveReviewAppConfig(state, reviewAppConfig) {
      reviewAppConfig.setProperties({
        pipeline: this.pipeline,
        repoName: this.get('pipeline.repositoryFullName')
      });
      this.analytics.logEvent('Review Apps New Config', 'Saved', {
        state,
        waitForCi: reviewAppConfig.waitForCi,
        hasAutomaticDeploys: reviewAppConfig.hasAutomaticReviewApps,
        destroyStaleApps: reviewAppConfig.destroyStaleApps,
        deployTarget: this.get('reviewAppConfig.deployTarget.name')
      });
      return this.reviewAppConfig.save().then(() => {
        if (this.get('pipeline.githubAppLinks.length') > 0) {
          this.get('pipeline.githubAppLinks').reload();
        }

        this.get('pipeline.unsortedReviewApps').reload();
      });
    },

    actions: {
      toggleReviewAppConfig(property) {
        this.reviewAppConfig.toggleProperty(property);
      },

      setReviewAppDeployTarget(deployTarget) {
        this.reviewAppConfig.set('deployTarget', deployTarget);
      },

      enableReviewApps(closeAction) {
        const state = this.isMigratingFromLeagcyReviewApps ? 'Migrating' : 'Newly Creating';
        return this.saveReviewAppConfig(state, this.reviewAppConfig).then(() => {
          closeAction();
        });
      },

      upgradeReviewApps() {
        this.analytics.logEvent('Review Apps New Upgrade', 'Clicked');
        this.set('isShowingConfirm', true);
      },

      cancelConfirmation() {
        this.analytics.logEvent('Review Apps New Upgrade Confirmation', 'Cancelled');
        this.set('isShowingConfirm', false);
      }

    }
  });

  _exports.default = _default;
});