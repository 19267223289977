define("dashboard/routes/provision-addon", ["exports", "@ember/routing/route", "rsvp", "dashboard/utils/infinite-query", "@ember/service"], function (_exports, _route, _rsvp, _infiniteQuery, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    store: (0, _service.inject)(),
    queryParams: {
      addonId: {
        replace: true
      },
      planId: {
        replace: true
      }
    },

    model() {
      const store = this.store; // since user's arrive at this route from an external website (Ecosystems)
      // we're using `reload: false` to prevent multiple narrowly spaced out fetches

      const apps = (0, _infiniteQuery.default)(store, 'app', {
        personalOnly: false,
        reload: false
      });
      return _rsvp.default.hash({
        apps
      });
    }

  });

  _exports.default = _default;
});