define("dashboard/components/app/settings/domain-edit-panel", ["exports", "@ember/component", "@ember/object", "dashboard/utils/endpoint-match-domains"], function (_exports, _component, _object, _endpointMatchDomains) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NO_SNI_ENDPOINT = 'No SNI Endpoint';

  var _default = _component.default.extend({
    originallySelectedCert: null,
    tempSelectedCert: null,
    isDeleting: false,

    init() {
      this._super(...arguments);

      const currentEndpoint = this.domain.sniEndpoint; // we check `id` explicitly here because Ember Data returns a relationship wrapper (which is
      // truthy) even if there is no actual endpoint present

      this.tempSelectedCert = currentEndpoint.get('id') ? currentEndpoint : NO_SNI_ENDPOINT;

      if (currentEndpoint.get('id')) {
        // we use this to determine when the user has actually changed the sni endpoint for the domain
        // so we can enable the apply button
        this.originallySelectedCert = currentEndpoint;
      }
    },

    domainHasChanges: (0, _object.computed)('tempSelectedCert', 'originallySelectedCert', function () {
      return this.get('tempSelectedCert.id') !== this.get('originallySelectedCert.id');
    }),
    validCertMatches: (0, _object.computed)('domain.hostname', 'app.endpoints.[]', function () {
      const selections = (0, _endpointMatchDomains.findMatchingCerts)(this.domain.hostname, this.app.endpoints);
      return [NO_SNI_ENDPOINT, ...selections];
    }),
    actions: {
      selectCertificate(cert) {
        this.set('tempSelectedCert', cert);
      },

      save() {
        if (this.tempSelectedCert === NO_SNI_ENDPOINT) {
          this.domain.set('sniEndpoint', null);
        } else {
          this.domain.set('sniEndpoint', this.tempSelectedCert);
        }

        return this.onSave().then(() => this.onClose());
      },

      delete() {
        this.onDelete().then(() => this.onClose());
      },

      removeDomain() {
        this.set('isDeleting', true);
      }

    }
  });

  _exports.default = _default;
});