define("dashboard/adapters/team/payment-method", ["exports", "dashboard/adapters/payment-method"], function (_exports, _paymentMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paymentMethod.default.extend({
    urlForQueryRecord(query) {
      return this._teamPaymentURL(query.team);
    },

    queryRecord(store, type, query) {
      return this._super(store, type, query).then(payload => {
        payload.team = query.team;
        return payload;
      });
    },

    createRecord(store, type, snapshot) {
      return this._super(store, type, snapshot).then(addTeamToPayload(snapshot));
    },

    updateRecord(store, type, snapshot) {
      return this._super(store, type, snapshot).then(addTeamToPayload(snapshot));
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      return this._teamPaymentURL(snapshot.attr('team'));
    },

    urlForCreateRecord() {
      const base = this._baseURL;
      return `${base}/new-teams/payment-method`;
    },

    _teamPaymentURL(team) {
      const base = this._baseURL;
      return `${base}/teams/${team}/payment-method`;
    },

    getClientToken(team) {
      const base = this._baseURL;

      if (team) {
        return this.ajax(`${base}/teams/${team}/payment-method/client-token`, 'POST');
      }

      return this.ajax(`${base}/teams/payment-method/client-token`, 'POST');
    }

  });

  _exports.default = _default;

  function addTeamToPayload(snapshot) {
    return function () {
      let payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      payload.team = snapshot.attr('team');
      return payload;
    };
  }
});