define("dashboard/controllers/app/datastore/index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "dashboard/mixins/metrics/timeframe-options", "ember-concurrency"], function (_exports, _controller, _object, _computed, _timeframeOptions, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_timeframeOptions.default, {
    queryParams: ['index', 'topic'],
    index: 0,
    topic: null,
    isAdding: (0, _computed.equal)('topic', 'create'),
    protectedController: (0, _controller.inject)('protected'),
    platformUserPreferences: (0, _computed.alias)('protectedController.model.userPreferences'),
    beforeHours: 0,
    afterHours: 2,
    currentTimeframe: (0, _object.computed)('beforeHours', 'afterHours', 'allDatastoreOptions', function () {
      const timeframe = this.get('allDatastoreOptions').find(option => {
        return option.beforeHours === this.get('beforeHours') && option.afterHours === this.get('afterHours');
      });
      return timeframe || this.get('defaultTimeframe');
    }),
    timezone: (0, _object.computed)('platformUserPreferences.timezone', function () {
      return this.get('platformUserPreferences.timezone') || 'UTC';
    }),
    fetchDatastoreMetricsTask: (0, _emberConcurrency.task)(function* (datastoreType) {
      const timeframeEnd = this.beforeHours;
      const timeframeBegin = this.afterHours;
      const metrics = yield this.model.datastore.queryMetricsData(datastoreType, timeframeBegin, timeframeEnd);
      this.set('datastoreMetrics', metrics);
      return metrics;
    }).drop(),
    actions: {
      updateTimeframe(beforeHours, afterHours, datastoreType) {
        this.setProperties({
          beforeHours,
          afterHours
        });
        this.fetchDatastoreMetricsTask.perform(datastoreType);
      },

      setIndex(idx) {
        this.set('index', idx);
      },

      openAddTopic() {
        this.set('topic', 'create');
      },

      onDismissSlidePanel() {
        this.set('topic', null);
      }

    }
  });

  _exports.default = _default;
});