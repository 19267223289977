define("dashboard/templates/components/confirm/with-password", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+tx+QwXO",
    "block": "[[[10,2],[12],[1,[34,0]],[13],[1,\"\\n\\n\"],[1,[28,[35,1],null,[[\"type\",\"value\",\"classNames\",\"autofocus\"],[\"password\",[30,0,[\"password\"]],\"form-control\",true]]]],[1,\"\\n\"]],[],false,[\"prompt\",\"input\"]]",
    "moduleName": "dashboard/templates/components/confirm/with-password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});