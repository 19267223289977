define("dashboard/templates/components/metrics/charts/connected-chart-hover/alert-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+kGz1cW6",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"title\",\"description\",\"icon\",\"isVisible\"],[[30,1,[\"title\"]],[30,1,[\"description\"]],\"alert-diamond\",true]]]],[1,\"\\n\"]],[1]],null]],[\"item\"],false,[\"each\",\"-track-array\",\"metrics/charts/connected-chart-hover/item\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/connected-chart-hover/alert-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});