define("dashboard/components/radio-cell-button", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: ['ifActiveClasses'],
    classNames: ['br2 ba b--light-silver mb2 cursor-pointer overflow-hidden shadow-outer-3'],
    isActive: (0, _object.computed)('selectedValue', 'value', function () {
      return this.value === this.selectedValue;
    }),
    ifActiveClasses: (0, _object.computed)('isActive', function () {
      return this.isActive ? 'b--blue' : 'hover-b--light-blue';
    })
  });

  _exports.default = _default;
});