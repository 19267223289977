define("dashboard/utils/save-to-file", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = saveToFile;

  // https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
  function saveToFile(name, type, data) {
    if (data !== null && navigator.msSaveBlob) {
      return navigator.msSaveBlob(new Blob([data], {
        type
      }), name);
    }

    const temporaryAnchorTag = (0, _jquery.default)('<a data-target="log-stream-save-hack" style="display: none;"/>');
    const url = window.URL.createObjectURL(new Blob([data], {
      type
    }));
    temporaryAnchorTag.attr('href', url);
    temporaryAnchorTag.attr('download', name);
    (0, _jquery.default)('body').append(temporaryAnchorTag);
    temporaryAnchorTag[0].click();
    window.URL.revokeObjectURL(url);
    temporaryAnchorTag.remove();
  }
});