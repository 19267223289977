define("dashboard/models/production-check/monitor", ["exports", "dashboard/mixins/production-check", "@ember/object", "@ember/object/computed", "dashboard/config/environment"], function (_exports, _productionCheck, _object, _computed, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    title: 'App monitoring',
    devCenterURL: 'https://devcenter.heroku.com/articles/production-check#visibility-and-monitoring',
    failedMessage: (0, _object.computed)('status', function () {
      return `Not using an app monitoring add-on. Install a <a href="https://elements.heroku.com/search?q=monitoring" class='hk-link'>monitoring add-on</a> such as <a href="https://elements.heroku.com/addons/newrelic" class='hk-link'>New Relic</a> or <a href="https://elements.heroku.com/addons/librato" class='hk-link'>Librato</a> to monitor your app's performance.`;
    }),
    appAddons: (0, _computed.mapBy)('app.attachments', 'addon'),
    appAddonPlans: (0, _computed.mapBy)('appAddons', 'plan'),
    monitoringAddonNames: (0, _computed.filter)('appAddonPlans', function (plan) {
      const monitoringAddons = _environment.default.monitoringAddons;
      return monitoringAddons.any(monitoringName => {
        return new RegExp(monitoringName.replace(/ /g, '')).test(plan);
      });
    }),
    hasMonitoringAddons: (0, _computed.notEmpty)('monitoringAddonNames'),
    status: (0, _object.computed)('hasMonitoringAddons', function () {
      if (this.hasMonitoringAddons) {
        return 'passed';
      }

      return 'failed';
    })
  });

  _exports.default = _default;
});