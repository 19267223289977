define("dashboard/helpers/string-dasherize", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringDasherize = stringDasherize;

  function stringDasherize(_ref) {
    let [str] = _ref;
    return (0, _string.dasherize)(str);
  }

  var _default = (0, _helper.helper)(stringDasherize);

  _exports.default = _default;
});