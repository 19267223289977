define("dashboard/adapters/enterprise-account/member", ["exports", "dashboard/adapters/application", "dashboard/mixins/adapters/range-aware-adapter"], function (_exports, _application, _rangeAwareAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rangeAwareAdapter.default, {
    namespace: 'enterprise-accounts',
    range: {
      attribute: 'email',
      max: 1000
    },

    urlForQuery(query, modelName) {
      if (modelName !== 'enterprise-account/member') {
        return this._super(...arguments);
      }

      const {
        enterpriseAccount
      } = query;
      delete query.enterpriseAccount;
      return `${this.host}/${this.namespace}/${enterpriseAccount}/members`;
    },

    urlForCreateRecord(modelName, snapshot) {
      const host = this.host;
      const namespace = this.namespace;
      const enterpriseAccountName = snapshot.record.get('enterpriseAccount.name');
      return `${host}/${namespace}/${enterpriseAccountName}/members`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const host = this.host;
      const userId = snapshot.attr('userId');
      const namespace = this.namespace;
      const enterpriseAccountName = snapshot.record.get('enterpriseAccount.name');
      return `${host}/${namespace}/${enterpriseAccountName}/members/${userId}`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      return this.urlForDeleteRecord(id, modelName, snapshot);
    }

  });

  _exports.default = _default;
});