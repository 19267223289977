define("dashboard/controllers/pipelines/pipeline/access", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "dashboard/utils/custom-computed", "@ember/service"], function (_exports, _controller, _object, _computed, _customComputed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    queryParams: ['user', 'email', 'page'],
    user: null,
    email: '',
    page: 1,
    pipeline: (0, _computed.readOnly)('model.pipeline'),
    team: (0, _computed.readOnly)('pipeline.team'),
    isTeamPipeline: (0, _computed.notEmpty)('team.content'),
    isPersonalPipeline: (0, _computed.not)('isTeamPipeline'),
    isPersonalPipelineOwner: (0, _object.computed)('isPersonalPipeline', 'pipeline.owner.id', 'current.accountId', function () {
      const isPersonalPipelineOwner = this.pipeline.get('owner.id') === this.current.get('accountId');
      return this.isPersonalPipeline && isPersonalPipelineOwner;
    }),
    teamMembers: (0, _computed.readOnly)('model.teamMembers'),
    teamAdmins: (0, _computed.filterBy)('teamMembers', 'isAdmin', true),
    hasTeamAdmins: (0, _computed.notEmpty)('teamAdmins'),
    addUser: (0, _computed.equal)('user', 'add'),
    defaultPermissionsParam: (0, _computed.equal)('user', 'default'),
    editDefaultPermissions: (0, _computed.equal)('user', 'default'),
    currentCollaborator: (0, _customComputed.findBy)('collaborators', 'email', 'user'),
    permissionNames: (0, _object.computed)(function () {
      return ['view', 'deploy', 'operate', 'manage'];
    }),
    existingCollaboratorEmail: (0, _object.computed)('addUser', 'currentCollaborator.email', function () {
      return this.get('currentCollaborator.email') || '';
    }),
    newCollaboratorEmail: '',
    permits: (0, _object.computed)('addUser', 'editDefaultPermissions', 'permissionNames.[]', 'pipeline.collaboratorPermissions.[]', function () {
      const permNames = this.permissionNames || [];

      if (this.editDefaultPermissions) {
        const pipeline = this.pipeline;
        return permNames.map(name => {
          return _object.default.create({
            name,
            enabled: pipeline.hasCollaboratorPermission(name) || name === 'view',
            disabled: name === 'view'
          });
        });
      } else {
        const collaborator = this.currentCollaborator;

        const isPermissionUIEnabled = permission => {
          if (this.addUser) {
            return this.pipeline.get('collaboratorPermissions').includes(permission);
          } else {
            return collaborator && collaborator.hasPermission(permission);
          }
        };

        if (this.team.get('isEnterpriseTeam')) {
          return permNames.map(name => {
            return _object.default.create({
              name,
              enabled: isPermissionUIEnabled(name) || name === 'view',
              disabled: name === 'view'
            });
          });
        } else {
          return permNames.map(name => {
            return _object.default.create({
              name,
              enabled: ['view', 'deploy', 'operate'].includes(name),
              disabled: true
            });
          });
        }
      }
    }),
    autoJoinIsEnabled: (0, _computed.readOnly)('pipeline.collaboratorSync'),
    canToggleJoinStatus: true,
    collaborators: (0, _computed.readOnly)('model.collaborators'),
    actions: {
      disableAutoJoin() {
        this.set('pipeline.collaboratorSync', false);
        this.pipeline.save();
      },

      enableAutoJoin() {
        this.set('pipeline.collaboratorSync', true);
        this.pipeline.save();
      },

      onDismissPanel() {
        this.set('user', null);
        this.set('newCollaboratorEmail', '');
      },

      async saveDefaultPermissions(dismiss) {
        if (!this.autoJoinIsEnabled) {
          this.set('pipeline.collaboratorSync', true);
        }

        this.pipeline.set('collaboratorPermissions', this.permits.filterBy('enabled').mapBy('name'));
        await this.pipeline.save();
        await dismiss();
      },

      async saveCollaborator(dismiss) {
        let currentCollaborator;

        try {
          const store = this.store;
          currentCollaborator = this.currentCollaborator || store.createRecord('pipeline/collaborator', {
            user: this.newCollaboratorEmail,
            pipeline: this.pipeline
          });

          if (this.isTeamPipeline && this.team.get('isEnterpriseTeam')) {
            currentCollaborator.set('permissions', this.permits.filterBy('enabled').mapBy('name'));
          } else {
            currentCollaborator.set('permissions', []);
          }

          await currentCollaborator.save();
          this.set('newCollaboratorEmail', '');
          await this.pipeline.get('collaborators').reload();
          await dismiss();
        } catch (e) {
          currentCollaborator.unloadRecord();
        }
      },

      async removeCollaborator(collaborator, dismiss) {
        await collaborator.destroyRecord();
        await this.pipeline.get('collaborators').reload();
        await dismiss();
      }

    }
  });

  _exports.default = _default;
});