define("dashboard/components/datastore/kafka/nav", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    showSettings: (0, _object.computed)('addon', function () {
      const planName = this.get('addon.planName');
      return planName.indexOf('private') > -1 || planName.indexOf('shielded') > -1;
    })
  });

  _exports.default = _default;
});