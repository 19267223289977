define("dashboard/serializers/repositories-api-app-manifest", ["exports", "dashboard/serializers/application", "@ember/utils", "@ember/service", "@ember/polyfills"], function (_exports, _application, _utils, _service, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    reporter: (0, _service.inject)(),
    attrs: {
      app: {
        serialize: false
      }
    },

    normalize(typeClass, hash) {
      let manifest = {
        id: hash.id
      };

      try {
        const attributes = hash['content'] ? JSON.parse(atob(hash['content'])) : JSON.parse(hash['app_json']);
        manifest = (0, _polyfills.merge)(manifest, attributes);
      } catch (e) {
        this.get('reporter.rollbar').info('Unable to parse manifest', {
          error: e
        });
        throw new Error('Unable to parse manifest');
      }

      return this._super(typeClass, manifest);
    },

    serialize() {
      const serializedData = this._super(...arguments);

      Object.keys(serializedData).forEach(key => {
        if ((0, _utils.isNone)(serializedData[key])) {
          delete serializedData[key];
        }
      });
      return {
        app_json: serializedData
      };
    }

  });

  _exports.default = _default;
});