define("dashboard/services/payment-gateway/braintree-gateway", ["exports", "@ember/object", "rsvp", "@ember/service", "ember-concurrency", "@ember/runloop", "@ember/object/evented"], function (_exports, _object, _rsvp, _service, _emberConcurrency, _runloop, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Check out docs/credit-card-forms.md in the top of this repo for more context.
  const BraintreeGateway = _object.default.extend(_evented.default, {
    api: (0, _service.inject)(),
    store: (0, _service.inject)(),
    reporter: (0, _service.inject)(),

    // preload Braintree JavaScript and also fetch the token.
    async preload() {
      return this._preloadTask.perform(...arguments);
    },

    // initialize the Hosted Fields iframes once inputs are rendered and ready.
    async initializeHostedFields(hostedFieldOptions) {
      try {
        await this._initializeHostedFields.perform(hostedFieldOptions);
        return (0, _emberConcurrency.waitForProperty)(this, 'initializingHostedFields', false);
      } catch (e) {
        if ((0, _emberConcurrency.didCancel)(e)) {
          return this.teardownHostedFields();
        }
      }
    },

    // resolve with a nonce if the card is valid, otherwise reject with an error from braintree
    async tokenize(opts) {
      return this._hostedFields.tokenize(opts);
    },

    // get deviceData for Kount
    async deviceData() {
      if (!this._collector) {
        this.set('_collector', await this._braintreeWeb.dataCollector.create({
          client: this.client,
          kount: true
        }));
      }

      return this._collector.deviceData;
    },

    teardownHostedFields() {
      this._initializeHostedFields.cancelAll();

      return this._teardownHostedFields();
    },

    _teardownHostedFields() {
      const hostedFields = this._hostedFields;
      delete this._hostedFields;

      if (hostedFields) {
        return new _rsvp.Promise((resolve, reject) => {
          hostedFields.teardown(err => {
            if (err) {
              return reject(err);
            }

            resolve();
          });
        });
      }
    },

    _preloadTask: (0, _emberConcurrency.task)(function* (useTeamEndpoint) {
      yield (0, _rsvp.all)([this._fetchToken(useTeamEndpoint), this._fetchBraintreeWeb()]);
      yield this._generateClient();
    }).drop(),

    // fetch the token from API so we can initialize the braintree library.
    async _fetchToken(useTeamEndpoint) {
      if (!this._token) {
        const requestUrl = useTeamEndpoint ? '/teams/payment-method/client-token' : '/account/payment-method/client-token';
        const payload = await this.api.request(requestUrl, {
          adapter: this.store.adapterFor('unverify-payment-request'),
          type: 'POST'
        });
        this.set('_token', payload.token);
      }

      return this._token;
    },

    // lazy load the braintree web library
    async _fetchBraintreeWeb() {
      const {
        default: braintreeWeb
      } = await emberAutoImportDynamic("braintree-web");
      this.set('_braintreeWeb', braintreeWeb);
      return this._braintreeWeb;
    },

    // create a braintree client so we can then initialize hosted fields and
    // the data collector
    async _generateClient() {
      if (!this.client) {
        this.set('client', await this._braintreeWeb.client.create({
          authorization: this._token
        }));
      }

      return this.client;
    },

    _initializeHostedFields: (0, _emberConcurrency.task)(function* (hostedFieldOptions) {
      this.set('initializingHostedFields', true);

      try {
        if (!this._hostedFields) {
          this._preloadTask.perform();

          yield (0, _emberConcurrency.waitForProperty)(this, '_token');
          yield (0, _emberConcurrency.waitForProperty)(this, '_braintreeWeb');
          yield (0, _emberConcurrency.waitForProperty)(this, 'client');
          const {
            client
          } = this;
          const opts = Object.assign({
            client
          }, hostedFieldOptions);
          const hostedFields = yield this._braintreeWeb.hostedFields.create(opts);
          const events = ['empty', 'notEmpty', 'validityChange', 'blur', 'focus', 'cardTypeChange'];
          events.forEach(event => {
            hostedFields.on(event, (0, _runloop.bind)(this, function (eventData) {
              this.trigger(event, eventData);
            }));
          });
          this._hostedFields = hostedFields;
        }
      } catch (e) {
        this.reporter.error(e);
        throw e;
      } finally {
        this.set('initializingHostedFields', false);
      }
    }).drop()
  });

  var _default = BraintreeGateway;
  _exports.default = _default;
});