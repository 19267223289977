define("dashboard/models/team-permissions", ["exports", "@ember/object", "@ember/object/computed"], function (_exports, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    // --------------------------------------------------------------------------
    // Args
    team: null,
    enterpriseAccount: null,
    // --------------------------------------------------------------------------
    // Computed props
    // The matrix of permissions that Dashboard should use as the source of truth
    // for what the given user can do.
    //
    // This matrix should mirror these dev center articles, as well as allowing
    // implicit team admins (enterprise account managers) to see things that their
    // actual team role may not allow. Like when they have no role on a team, for example.
    // https://devcenter.heroku.com/articles/enterprise-account-permissions
    // https://devcenter.heroku.com/articles/enterprise-team-permissions
    // https://devcenter.heroku.com/articles/team-permissions

    /* eslint-disable prettier/prettier */
    canCreateApps: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount', 'isMember'),
    canManageSpaces: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canManagePipelines: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount', 'isMember'),
    canViewUsers: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount', 'isMember', 'isViewer'),
    canManageUsers: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canViewResources: (0, _computed.or)('isAdmin'),
    canViewIdp: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount', 'isMember'),
    canManageIdp: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canManageTeam: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canManagePreferences: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canManageAllowedAddonsList: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canManageWebhooks: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canViewInvoices: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canViewLicenses: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canViewUsage: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canViewPayments: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canManagePayments: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),
    canManageGitProviders: (0, _computed.or)('isAdmin', 'hasManageOnEnterpriseAccount'),

    /* eslint-enable prettier/prettier */
    // Some commonly used coarse-grained permissions that are made up of different
    // combinations of the above plus other things.
    canViewSettingsTab: (0, _computed.or)('canManageTeam', 'canManageGitProviders', 'canManageIdp', 'canManagePreferences', 'canManageAllowedAddonsList', 'canCreateApps'),
    isAdmin: (0, _computed.bool)('team.isAdmin'),
    isMember: (0, _computed.bool)('team.isMember'),
    isViewer: (0, _computed.bool)('team.isViewer'),
    hasManageOnEnterpriseAccount: (0, _computed.bool)('enterpriseAccountPermissions.hasManage'),
    enterpriseAccountPermissions: (0, _computed.readOnly)('enterpriseAccount.currentPermissions')
  });

  _exports.default = _default;
});