define("dashboard/initializers/account-features", ["exports", "dashboard/services/account-features"], function (_exports, _accountFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    const serviceLookupName = 'service:account-features';
    const serviceName = 'accountFeaturesService';
    application.register(serviceLookupName, _accountFeatures.default);
    application.inject('route', serviceName, serviceLookupName);
    application.inject('controller', serviceName, serviceLookupName);
    application.inject('component', serviceName, serviceLookupName);
    application.inject(serviceLookupName, 'application', 'application:main');
  }

  var _default = {
    name: 'account-features',
    initialize
  };
  _exports.default = _default;
});