define("dashboard/adapters/team", ["exports", "dashboard/adapters/application", "dashboard/mixins/adapters/refresh-quick-jump-hooks", "dashboard/utils/errors", "ember-concurrency", "dashboard/serializers/team/license"], function (_exports, _application, _refreshQuickJumpHooks, _errors, _emberConcurrency, _license) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MEMBER_REQUESTS_MAX = 10;

  var _default = _application.default.extend(_refreshQuickJumpHooks.default, {
    version: '3.dashboard',

    urlForQueryRecord(query, modelName) {
      const name = query.name;
      delete query['name'];
      return this.urlForFindRecord(name, modelName);
    },

    urlForQuery(query) {
      const host = this.host;
      const id = query.enterpriseAccountId;

      if (id) {
        delete query['enterpriseAccountId'];
        return `${host}/enterprise-accounts/${id}/teams`;
      } else {
        return `${host}/teams`;
      }
    },

    urlForUpdateRecord(id, modelName) {
      return this.urlForFindRecord(id, modelName);
    },

    findHasMany(store, snapshot, url, relationship) {
      if (relationship.key === 'members') {
        return this.loadAllTeamMembers.perform(snapshot, store);
      } // Unfortunately, API returns features that contain non-unique IDs. They are,
      // however, unique within each org, so we are creating a composite key here
      // based on the org ID and the feature ID


      return this._super(...arguments).then(payload => {
        if (/features/.test(url)) {
          (payload || []).forEach(feature => {
            feature.id = `${feature.id}/${snapshot.id}`;
          });
        } else if (/^\/spaces/.test(url)) {
          payload = payload.filter(record => record.team.id === snapshot.id);
        } else if (['licenses', 'combinedLicenses'].includes(relationship.key)) {
          payload = (0, _license.injectTeamIdIntoPayload)(payload, snapshot.id);
        } else if (relationship.key === 'groupedLicenses') {
          payload = (0, _license.massageGroupedLicenses)(payload, snapshot.id);
        } else if (relationship.key === 'invoices') {
          payload = (0, _license.injectTeamIdIntoPayload)(payload, snapshot.id);
        } else if (relationship.key === 'identityProviders') {
          payload = payload.map(idp => Object.assign({}, idp, {
            team: snapshot.id
          }));
        }

        return payload;
      });
    },

    findBelongsTo(store, snapshot, url) {
      return this._super(...arguments).then(payload => {
        // team preferences don't include an id, so
        // we manually set it to the org id
        if (/preferences/.test(url)) {
          payload.id = snapshot.id;
          payload.team = snapshot.id;
        }

        return payload;
      });
    },

    handleResponse(status, headers, payload, requestData) {
      if (this.isInvalid(status, headers, payload)) {
        payload.errors = (0, _errors.toJSONAPIErrors)(payload, status);
      }

      return this._super(status, headers, payload, requestData);
    },

    loadAllTeamMembers: (0, _emberConcurrency.task)(function* (snapshot, store) {
      const type = store.modelFor('team/member');
      const adapter = store.adapterFor('team/member');
      const results = [];
      let range = 'email ..; max=1000';
      let count = 0;

      while (range && count < MEMBER_REQUESTS_MAX) {
        const query = {
          teamId: snapshot.id,
          range
        };
        const page = yield adapter.query(store, type, query);
        range = page.meta.nextRange;
        results.push(...page);
        count += 1;
      }

      return results;
    })
  });

  _exports.default = _default;
});