define("dashboard/components/sub-nav-item", ["exports", "@ember/array", "@ember/component", "@ember/object", "@ember/application", "@ember/service", "jquery"], function (_exports, _array, _component, _object, _application, _service, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['sub-nav-item'],
    attributeBindings: ['aria-current', 'role'],
    role: 'listitem',
    router: (0, _service.inject)(),

    didRenderElement() {
      this.setChildLinkViews();
    },

    // find all the child links of the linkComponent so we can
    // find the one that is active
    setChildLinkViews() {
      const childLinkElements = (0, _jquery.default)(this.element).find('a.ember-view');
      const viewRegistry = (0, _application.getOwner)(this).lookup('-view-registry:main');
      const childLinkViews = childLinkElements.toArray().map(view => {
        return viewRegistry[view.id];
      });
      this.set('childLinkViews', childLinkViews);
    },

    isActive: (0, _object.computed)('childLinkViews.@each.active', function () {
      return (0, _array.A)(this.childLinkViews).isAny('active');
    }),
    malibuIconClass: (0, _object.computed)('isActive', function () {
      return this.isActive ? 'fill-purple' : 'fill-gray';
    }),
    'aria-current': (0, _object.computed)('router.currentRouteName', 'link', function () {
      if (this.router.isActive(this.link)) {
        return 'page';
      }
    })
  });

  _exports.default = _default;
});