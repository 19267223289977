define("dashboard/templates/components/team/addons-allowlist-quickadd-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+ysI8+5n",
    "block": "[[[10,\"img\"],[15,\"src\",[29,[\"https://addons.heroku.com/addons/\",[30,0,[\"item\",\"name\"]],\"/icons/catalog.png\"]]],[14,0,\"addon-icon\"],[14,\"height\",\"28px\"],[14,\"width\",\"28px\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n\"],[10,1],[14,0,\"addon-typeahead-title\"],[12],[1,[30,0,[\"item\",\"humanName\"]]],[13],[1,\"\\n\"],[10,1],[14,0,\"addon-typeahead-description\"],[12],[1,[30,0,[\"item\",\"description\"]]],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/team/addons-allowlist-quickadd-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});