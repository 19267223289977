define("dashboard/validations/identity-provider-certificate", ["exports", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validator = _exports.default = void 0;
  const Validations = {
    body: (0, _validators.validatePresence)({
      presence: true,
      message: 'Certificate body cannot be blank'
    })
  };
  _exports.default = Validations;
  const validator = (0, _emberChangesetValidations.default)(Validations);
  _exports.validator = validator;
});