define("dashboard/routes/team/switchable/apps", ["exports", "rsvp", "@ember/service", "dashboard/routes/apps/index", "dashboard/utils/infinite-query"], function (_exports, _rsvp, _service, _index, _infiniteQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    controllerName: 'apps.index',
    accountFeatures: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    favoritesService: (0, _service.inject)('favorites'),
    flashMessages: (0, _service.inject)(),

    model() {
      const {
        team
      } = this.modelFor('team');
      const store = this.store;
      const teamQuery = {
        team: team.get('id')
      };
      return (0, _rsvp.hash)({
        apps: (0, _infiniteQuery.default)(store, 'app', teamQuery, {}, this.flashMessages),
        // TODO: Remove add-ons query once free resources have been removed completely from Heroku
        addons: (0, _infiniteQuery.default)(store, 'addon', {
          teamId: team.get('id'),
          slug: ['heroku-postgresql', 'heroku-redis']
        }, {}, this.flashMessages),
        couplings: (0, _infiniteQuery.default)(store, 'pipeline-coupling', teamQuery, {}, this.flashMessages),
        team,
        favorites: this.get('favoritesService.favorites').catch(() => [])
      });
    },

    afterModel(model) {
      const analyticsNoun = model.team.analyticsNoun;
      this.analytics.logEvent(`${analyticsNoun} Apps Page`, 'Viewed');
    },

    renderTemplate() {
      this.render('apps/index');
    },

    get documentTitle() {
      return this.modelFor('team').team.get('name');
    }

  });

  _exports.default = _default;
});