define("dashboard/components/dyno-size", ["exports", "@ember/object/computed", "@ember/component", "@ember/object"], function (_exports, _computed, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: ['dynoSizeClassName'],
    'data-test-target': (0, _computed.alias)('dynoSizeClassName'),
    cannotAutoscale: (0, _computed.not)('dynoSize.canAutoscale'),
    disabled: (0, _computed.and)('cannotAutoscale', 'autoscalingIsEnabled', 'processTypeIsWeb'),
    dynoSizeClassName: (0, _object.computed)('dynoSize', function () {
      return `dyno-size-${this.get('dynoSize.size')}`.toLowerCase();
    }),
    isCurrentSize: (0, _object.computed)('dynoSize.size', 'currentSize', function () {
      return this.get('dynoSize.size').toUpperCase() === this.currentSize.toUpperCase();
    }),
    sizeIconName: (0, _computed.alias)('dynoSize.size'),
    actions: {
      updateCurrentDyno() {
        // don't fire the action if the user is not allowed to or they clicked the
        // currently selected value
        if (this.canScaleFormations && !this.isCurrentSize && !this.disabled) {
          this.dynoSizeChangeAction(this.get('dynoSize.size'));
        }
      }

    }
  });

  _exports.default = _default;
});