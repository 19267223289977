define("dashboard/templates/components/app/addons-marketplace-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VH7rtQby",
    "block": "[[[11,3],[16,6,[29,[[30,0,[\"marketplaceUrl\"]]]]],[24,0,\"hk-link\"],[4,[38,0],[[30,0],\"trackAndGoToMarketplaceUrl\"],null],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/app/addons-marketplace-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});