define("dashboard/components/pipeline/mismatched-apps-banner", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/mixins/pipeline-ownership"], function (_exports, _component, _object, _computed, _pipelineOwnership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_pipelineOwnership.default, {
    hasMixedOwnership: (0, _object.computed)('pipeline.owner', 'apps.@each.owner', function () {
      if (this.pipeline && this.apps) {
        return [this.pipeline].concat(this.apps).uniqBy('owner.id').length > 1;
      } else {
        return [];
      }
    }),
    isShowing: (0, _computed.or)('noPipelineOwner', 'hasMixedOwnership'),
    pipelineOwnerLabel: (0, _object.computed)('pipelineOwner.concealed', 'pipelineOwner.label', function () {
      if (this.get('pipelineOwner.concealed')) {
        return 'the pipeline owner';
      } else {
        return this.get('pipelineOwner.label');
      }
    }),
    actions: {
      openDevCenter() {
        window.open('https://devcenter.heroku.com/articles/pipeline-ownership-transition');
      }

    }
  });

  _exports.default = _default;
});