define("dashboard/components/hk-message", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['hk-message'],
    ariaRole: 'alert',
    classNameBindings: ['alertTypeClass'],
    isDismissable: false,
    alertType: 'info',
    iconSize: 28,
    iconName: (0, _object.computed)('iconSize', 'icon', function () {
      return `${this.icon}-${this.iconSize}`;
    }),
    alertTypeClass: (0, _object.computed)('alertType', function () {
      return `hk-message--${this.alertType}`;
    }),

    dismissAction() {}

  });

  _exports.default = _default;
});