define("dashboard/templates/components/github/authorize-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jvtjQHLU",
    "block": "[[[41,[30,0,[\"authorizeAction\"]],[[[1,\"  \"],[11,\"button\"],[16,0,[30,0,[\"buttonClass\"]]],[16,\"disabled\",[30,0,[\"disabledClass\"]]],[24,4,\"button\"],[4,[38,1],[[30,0],[30,0,[\"authorizeAction\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"connect\",\"isIdle\"]],[[[1,\"      \"],[41,[30,0,[\"buttonIcon\"]],[[[1,[30,0,[\"buttonIcon\"]]]],[]],null],[1,[30,0,[\"buttonText\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[34,2]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"button\"],[15,0,[30,0,[\"buttonClass\"]]],[15,\"onclick\",[28,[37,3],[[30,0,[\"connect\"]]],null]],[15,\"disabled\",[30,0,[\"disabledClass\"]]],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[30,0,[\"connect\",\"isIdle\"]],[[[1,\"      \"],[41,[30,0,[\"buttonIcon\"]],[[[1,[30,0,[\"buttonIcon\"]]]],[]],null],[1,[30,0,[\"buttonText\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[34,2]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"action\",\"malibu-loading-spinner\",\"perform\"]]",
    "moduleName": "dashboard/templates/components/github/authorize-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});