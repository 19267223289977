define("dashboard/mixins/adapters/heroku-data-adapter-mixin", ["exports", "@ember/object/mixin", "dashboard/mixins/adapters/authorization-headers-mixin", "ember-simple-auth/mixins/data-adapter-mixin", "@ember/service"], function (_exports, _mixin, _authorizationHeadersMixin, _dataAdapterMixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Extends ember-simple-auth's DataAdapterMixin and should be mixed into any
  // adapters for Heroku services that require authentication.
  var _default = _mixin.default.create(_dataAdapterMixin.default, _authorizationHeadersMixin.default, {
    session: (0, _service.inject)(),

    // Adds authentication and sudo headers to requests.
    authorize(xhr) {
      const authHeaders = this.authorizationHeaders;
      Object.keys(authHeaders).forEach(headerName => {
        xhr.setRequestHeader(headerName, authHeaders[headerName]);
      });
    }

  });

  _exports.default = _default;
});