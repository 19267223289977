define("dashboard/components/pipeline/toggle-ci", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['pipeline-ci-toggle'],
    current: (0, _service.inject)(),
    disabled: (0, _computed.not)('enabled'),
    repo: (0, _computed.reads)('pipeline.githubRepository'),
    pipelineOwnerId: (0, _computed.reads)('pipeline.owner.id'),
    pipelineOwnerType: (0, _computed.reads)('pipeline.owner.type'),
    // TODO: Move this to the PipelineOwnership mixin
    noPipelineOwner: (0, _computed.empty)('pipelineOwnerId'),
    hasPipelineOwnerTeam: (0, _computed.equal)('pipelineOwnerType', 'team'),
    hasPipelineOwnerUser: (0, _computed.equal)('pipelineOwnerType', 'user'),
    pipelineOwnerTeam: (0, _object.computed)('hasPipelineOwnerTeam', 'pipelineOwnerId', 'teams.@each.id', function () {
      if (this.hasPipelineOwnerTeam) {
        return this.teams.findBy('id', this.pipelineOwnerId);
      }
    }),
    pipelineRoleIsAdmin: (0, _computed.equal)('pipelineOwnerTeam.role', 'admin'),
    pipelineRoleIsMember: (0, _computed.equal)('pipelineOwnerTeam.role', 'member'),
    canToggleForTeam: (0, _computed.or)('pipelineRoleIsAdmin', 'pipelineRoleIsMember'),
    cannotToggleForTeam: (0, _computed.not)('canToggleForTeam'),
    canToggleForUser: (0, _object.computed)('hasPipelineOwnerUser', 'pipelineOwnerId', 'current.account.id', function () {
      if (this.hasPipelineOwnerUser) {
        return this.get('current.account.id') === this.pipelineOwnerId;
      } else {
        return false;
      }
    }),
    cannotToggleForUser: (0, _computed.not)('canToggleForUser'),
    canDisable: (0, _computed.or)('noPipelineOwner', 'canToggleForTeam', 'canToggleForUser'),
    cannotDisable: (0, _computed.not)('canDisable'),
    canEnable: (0, _computed.or)('canToggleForTeam', 'canToggleForUser'),
    cannotEnable: (0, _computed.not)('canEnable'),
    pipelineOwnerRequired: (0, _computed.and)('noPipelineOwner', 'disabled'),
    pipelineOwnerTeamRequired: (0, _computed.and)('hasPipelineOwnerTeam', 'cannotToggleForTeam'),
    pipelineOwnerUserRequired: (0, _computed.and)('hasPipelineOwnerUser', 'cannotToggleForUser'),
    actions: {
      toggleStatus(value) {
        return this.onToggleCi(value, this.hasPipelineOwnerTeam, this.get('pipelineOwnerTeam.id'));
      },

      selectBilling(billingTarget) {
        this.set('selectedBillingTarget', billingTarget);
      }

    }
  });

  _exports.default = _default;
});