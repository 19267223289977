define("dashboard/templates/components/confirm-delete-attachment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pat2swF0",
    "block": "[[[10,0],[14,0,\"addons-delete-confirm u-padding-Tsm u-padding-Bn\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"u-margin-Bs\"],[12],[1,\"\\n\"],[41,[30,0,[\"displayRemovalWarning\"]],[[[1,\"      Enter your app's name (\"],[10,\"code\"],[12],[1,[30,0,[\"app\",\"name\"]]],[13],[1,\") to confirm you want to detach \"],[10,\"b\"],[12],[1,[30,0,[\"addonServiceDescription\"]]],[13],[1,\". It will remain on its billing app.\\n\"]],[]],[[[1,\"      Enter your app's name (\"],[10,\"code\"],[12],[1,[30,0,[\"app\",\"name\"]]],[13],[1,\") to confirm you want to permanently delete \"],[10,\"b\"],[12],[1,[30,0,[\"addonServiceDescription\"]]],[13],[1,\" and associated data:\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"addons-delete-confirm__body\"],[12],[1,\"\\n    \"],[10,\"form\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"type\",\"class\",\"value\"],[\"text\",\"form-control center-block confirm-addon-delete-app\",[30,0,[\"appNameConfirmation\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"input\"]]",
    "moduleName": "dashboard/templates/components/confirm-delete-attachment.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});