define("dashboard/serializers/metrics/alert", ["exports", "ember-data", "dashboard/mixins/serializers/decamelized-keys"], function (_exports, _emberData, _decamelizedKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend(_decamelizedKeys.default, {
    extractRelationships(modelClass, resourceHash) {
      const relationships = this._super(...arguments);

      relationships.monitor = {
        data: {
          type: 'metrics/monitor',
          id: resourceHash['monitor_id']
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});