define("dashboard/templates/app/datastore/settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7QeWSPpv",
    "block": "[[[41,[30,0,[\"addon\",\"isKafka\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@datastore\",\"@plans\",\"@addon\",\"@privateLink\"],[[30,0,[\"model\",\"datastore\"]],[30,0,[\"model\",\"plans\"]],[30,0,[\"model\",\"addon\"]],[30,0,[\"model\",\"privateLink\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"addon\",\"isPostgres\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"datastore\",\"plans\",\"addon\",\"configParam\",\"closeUpdateConfig\",\"destroy\",\"closeDestroy\",\"fork\",\"closeFork\",\"reset\",\"closeReset\"],[[30,0,[\"model\",\"datastore\"]],[30,0,[\"model\",\"plans\"]],[30,0,[\"addon\"]],[30,0,[\"config\"]],[28,[37,3],[[30,0],\"closeUpdateConfig\"],null],[30,0,[\"destroy\"]],[28,[37,3],[[30,0],\"closeDestroy\"],null],[30,0,[\"fork\"]],[28,[37,3],[[30,0],\"closeFork\"],null],[30,0,[\"reset\"]],[28,[37,3],[[30,0],\"closeReset\"],null]]]]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"addon\",\"isRedis\"]],[[[1,\"  \"],[8,[39,4],null,[[\"@datastore\",\"@plans\",\"@addon\",\"@privateLink\"],[[30,0,[\"model\",\"datastore\"]],[30,0,[\"model\",\"plans\"]],[30,0,[\"model\",\"addon\"]],[30,0,[\"model\",\"privateLink\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"datastore/kafka/settings\",\"datastore/postgres/settings\",\"action\",\"datastore/redis/settings\"]]",
    "moduleName": "dashboard/templates/app/datastore/settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});