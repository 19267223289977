define("dashboard/mixins/adapters/ajax-options", ["exports", "@ember/runloop", "jquery", "rsvp", "@ember/polyfills", "@ember/object/mixin"], function (_exports, _runloop, _jquery, _rsvp, _polyfills, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    maximumRetries: 0,

    makeRequest(options) {
      options = (0, _polyfills.merge)(this._ajaxOptions(), options);
      return new _rsvp.Promise((resolve, reject) => {
        this._makeAjaxRequest(options, 0, resolve, reject);
      });
    },

    _makeAjaxRequest(options, retries, resolve, reject) {
      return _jquery.default.ajax(options).then(data => {
        (0, _runloop.run)(null, resolve, data);
      }, jqXHR => {
        const wasNot400StatusCode = jqXHR.status < 400 || jqXHR.status >= 500;
        const hasRemainingAttempts = retries < this.maximumRetries;
        const shouldRetry = hasRemainingAttempts && wasNot400StatusCode;

        if (shouldRetry) {
          (0, _runloop.later)(() => {
            retries++;

            this._makeAjaxRequest(options, retries, resolve, reject);
          }, this.retryDelay);
        } else {
          // we allow specific requests to suppress `ErrorMonitor` errors
          // otherwise, we expose the error
          jqXHR.suppressErrors = options.suppressErrors || false;
          jqXHR.then = null; // tame jQuery's ill mannered promises

          (0, _runloop.run)(null, reject, jqXHR);
        }
      });
    },

    /**
     * ajaxOptions callback for the #queryRecord's $.ajax call
     */
    _ajaxOptions() {
      return {
        timeout: this.ajaxTimeout,
        dataType: 'json',
        headers: this.headers,
        beforeSend: xhr => {
          xhr.suppressErrors = true;
          xhr.errorTitleSubject = this.errorTitleSubject; // From the HerokuDataAdapterMixin, in the metrics adapter.

          this.authorize(xhr);
        }
      };
    }

  });

  _exports.default = _default;
});