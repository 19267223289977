define("dashboard/components/install-github-button", ["exports", "dashboard/components/github/authorize-button", "ember-concurrency", "@ember/service", "dashboard/config/environment", "@ember/object", "@ember/object/computed"], function (_exports, _authorizeButton, _emberConcurrency, _service, _environment, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorizeButton.default.extend({
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    router: (0, _service.inject)(),
    buttonClass: 'hk-button-sm--primary',
    buttonText: 'Install',
    hasAuthFromReposApi: (0, _computed.readOnly)('current.account.reposApiGithubToken.hasToken'),
    connect: (0, _object.computed)('hasAuthFromReposApi', function () {
      return this.hasAuthFromReposApi ? this.processAfterAuthorizatonAction : this.authorizeGithub;
    }),
    authorizeGithub: (0, _emberConcurrency.task)(function* (herokuResourceType, herokuResourceId) {
      const torii = this.torii;
      let data;

      try {
        // While we transition from GitHub Personal OAuth tokens to GitHub App
        // tokens, we will manually replace GitHub ID for users flagged into
        // `dashboard-repositories-api` going through this flow
        _environment.default.torii.providers['github-oauth2'].apiKey = _environment.default.reposAPIgithubClientId;
        data = yield torii.open('github-oauth2');
      } catch (e) {
        this.errorMonitor.createErrorContainer({
          title: "Couldn't connect to GitHub",
          content: [e]
        });
        throw e;
      } finally {
        _environment.default.torii.providers['github-oauth2'].apiKey = _environment.default.githubClientId;
      }

      yield this.store.createRecord('repositories-api-github-oauth-token', {
        code: data.authorizationCode
      }).save();
      this.afterAuthorizeGithub(herokuResourceType, herokuResourceId, this.get('router.currentURL'));
    }).drop(),
    processAfterAuthorizatonAction: (0, _emberConcurrency.task)(function* (herokuResourceType, herokuResourceId) {
      yield this.afterAuthorizeGithub(herokuResourceType, herokuResourceId, this.get('router.currentURL'));
    }),

    // By default, after authorization, create a pending installation and redirect to GitHub
    afterAuthorizeGithub(herokuResourceType, herokuResourceId, redirectPath) {
      const params = {
        herokuResourceType,
        herokuResourceId,
        redirectPath
      };
      return this.store.createRecord('pending-github-installation', params).save().then(() => {
        this.analytics.logEvent('Install Github App Page', 'Opened');
        window.location = `https://github.com/apps/${_environment.default.repositoriesApiGitHubApp}/installations/new`;
      });
    },

    actions: {
      performConnectTask(herokuResourceType, herokuResourceId) {
        return this.get('connect').perform(herokuResourceType, herokuResourceId);
      }

    }
  });

  _exports.default = _default;
});