define("dashboard/components/org/overview/bulk-app-transfer", ["exports", "rsvp", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/models/app-permissions", "dashboard/utils/custom-computed"], function (_exports, _rsvp, _component, _object, _computed, _service, _appPermissions, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PERSONAL_APPS_OPTION = 'personal';

  var _default = _component.default.extend({
    tagName: '',
    accountFeatures: (0, _service.inject)(),
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    filterText: '',
    bulkAppTransfer: PERSONAL_APPS_OPTION,
    currentTeam: null,
    teams: null,
    selectedTeam: (0, _customComputed.findBy)('transferrableTeams', 'name', 'bulkAppTransfer'),
    selectedTeamIsEnterprise: (0, _computed.bool)('selectedTeam.isEnterpriseTeam'),
    recipientTeamIsEnterprise: (0, _computed.bool)('currentTeam.isEnterpriseTeam'),
    showEnterpriseUpgradeWarning: (0, _object.computed)('selectedTeamIsEnterprise', 'recipientTeamIsEnterprise', function () {
      // When personal or team apps are transferred to an enterprise team.
      return this.recipientTeamIsEnterprise && !this.selectedTeamIsEnterprise;
    }),
    showTeamUpgradeWarning: (0, _object.computed)('selectedTeam', 'recipientTeamIsEnterprise', function () {
      // When personal apps are transferred to a credit-card team.
      return !this.selectedTeam && !this.recipientTeamIsEnterprise;
    }),
    transferrableTeams: (0, _object.computed)('currentTeam', 'teams.@each.role', function () {
      const currentTeam = this.currentTeam;
      return this.teams.filter(function (team) {
        return team.get('role') === 'admin' && team.get('id') !== currentTeam.get('id');
      });
    }),
    transferIsFromPersonal: (0, _computed.equal)('bulkAppTransfer', PERSONAL_APPS_OPTION),
    transferFrom: (0, _object.computed)('selectedTeam.name', function () {
      return this.get('selectedTeam.name') || 'Personal apps';
    }),
    apps: (0, _object.computed)('selectedTeam', 'transferIsFromPersonal', function () {
      const team = this.selectedTeam;

      if (this.transferIsFromPersonal || !team) {
        return this.store.query('app', {
          personalOnly: true
        });
      } else {
        team.hasMany('apps').reload();
        return team.get('apps');
      }
    }),
    transferrableApps: (0, _computed.filter)('apps', function (app) {
      if (app.get('space')) {
        return false;
      }

      return _appPermissions.default.create({
        app,
        account: this.get('current.account'),
        team: this.selectedTeam
      }).get('canTransferApp');
    }),
    appTransfers: (0, _computed.map)('transferrableApps', function (app) {
      return this.store.createRecord('app-transfer', {
        app,
        recipientTeam: this.get('currentTeam.id')
      });
    }),
    filteredAppTransfers: (0, _customComputed.fuzzyFilterBy)('appTransfers', 'app.name', 'filterText'),
    displayingAppTransfers: (0, _computed.union)('filteredAppTransfers', 'selectedAppTransfers'),
    spaceApps: (0, _computed.filterBy)('apps', 'space'),
    filteredSpaceApps: (0, _customComputed.fuzzyFilterBy)('spaceApps', 'name', 'filterText'),
    completedAppTransfers: (0, _computed.filterBy)('appTransfers', 'transferComplete'),
    selectedAppTransfers: (0, _computed.filterBy)('appTransfers', 'isSelected'),
    noSelectedApps: (0, _computed.empty)('selectedAppTransfers'),
    appsToTransfer: (0, _computed.filterBy)('selectedAppTransfers', 'transferComplete', false),
    transfersComplete: (0, _object.computed)('completedAppTransfers', 'selectedAppTransfers', function () {
      return this.get('completedAppTransfers.length') > 0 && this.get('selectedAppTransfers.length') === this.get('completedAppTransfers.length');
    }),

    willDestroyElement() {
      this._super(...arguments);

      this.store.unloadAll('app-transfer');
    },

    actions: {
      transferApps() {
        return (0, _rsvp.all)(this.appsToTransfer.invoke('save'));
      }

    }
  });

  _exports.default = _default;
});