define("dashboard/components/stack-deprecation-warning", ["exports", "@ember/component", "@ember/object", "@ember/string", "dashboard/utils/pluralize"], function (_exports, _component, _object, _string, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    or,
    notEmpty
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: 'stack-deprecation-warning',
    isApp: (0, _object.computed)('context', function () {
      return this.context === 'app-overview' || this.context === 'app-settings';
    }),
    stackDeprecation: (0, _object.computed)('appsOnDeprecatedStack.0.stackDeprecation', 'app.stackDeprecation', 'isApp', function () {
      if (this.isApp) {
        return this.app.stackDeprecation;
      } else {
        return this.appsOnDeprecatedStack[0].get('stackDeprecation');
      }
    }),
    hasAppsOnDeprecatedStack: notEmpty('appsOnDeprecatedStack'),
    showStackDeprecationWarning: or('hasAppsOnDeprecatedStack', 'app.stackIsDeprecated'),
    contextClasses: (0, _object.computed)('context', function () {
      if (this.context === 'app-settings') {
        return 'pa2 mv2';
      } else if (this.context === 'app-overview') {
        return 'w-100 limit-width center mb3 ph1 pv2';
      } else if (this.context === 'pipeline') {
        return 'w-100 limit-width center mt3 ph1 pv2';
      } else {
        return 'w-100 limit-width center mt3 ph1 pv2';
      }
    }),
    appCount: (0, _object.computed)('appsOnDeprecatedStack.length', 'isApp', function () {
      return this.isApp ? 1 : this.get('appsOnDeprecatedStack.length');
    }),
    noun: (0, _object.computed)('appCount', function () {
      return (0, _pluralize.default)(this.appCount, 'app', 'apps');
    }),
    stackDisplayName: (0, _object.computed)('stackDeprecation.name', function () {
      return this.stackDeprecation.name.capitalize();
    }),
    stackDeprecationTitle: (0, _object.computed)('stackDeprecation.stackStatus', 'stackDisplayName', function () {
      return `The ${this.stackDisplayName} stack is ${this.stackDeprecation.stackStatus}`;
    }),
    contextString: (0, _object.computed)('appCount', 'noun', 'isApp', function () {
      let contextString;

      if (this.isApp) {
        contextString = 'This app is';
      } else {
        contextString = `You have <strong>${this.appCount} `;

        if (this.context === 'team') {
          contextString += `${this.noun}</strong> in this team`;
        } else if (this.context === 'pipeline') {
          contextString += `${this.noun}</strong> in this pipeline`;
        } else {
          contextString += `personal ${this.noun}</strong>`;
        }

        contextString += ` that ${(0, _pluralize.default)(this.appCount, 'is', 'are')}`;
      }

      return contextString;
    }),
    stackDeprecationMessage: (0, _object.computed)('stackDeprecation.buildsDisabled', 'stackDeprecation.buildsDisabledDate', 'stackDeprecation.eolDate', 'stackDeprecation.isEol', 'contextString', 'noun', 'stackDisplayName', function () {
      const eolDate = this.stackDeprecation.eolDate.format('MMMM Do, YYYY');
      const buildsDisabledDate = this.stackDeprecation.buildsDisabledDate.format('MMMM Do, YYYY');
      let messagePartial;

      if (this.stackDeprecation.buildsDisabled) {
        messagePartial = `reached end-of-life on <strong>${eolDate}</strong>. Apps using ${this.stackDisplayName} do not receive security updates and can no longer be built.`;
      } else if (this.stackDeprecation.isEol) {
        messagePartial = `reached end-of-life on <strong>${eolDate}</strong>. Apps using ${this.stackDisplayName} no longer receive security updates, and from <strong>${buildsDisabledDate}</strong>, builds will be disabled.`;
      } else {
        messagePartial = `is deprecated. From <strong>${eolDate}</strong>, ${this.stackDisplayName} will be end-of-life and no longer receive security updates. From <strong>${buildsDisabledDate}</strong>, builds will be disabled.`;
      }

      return (0, _string.htmlSafe)(`${this.contextString} using the ${this.stackDisplayName} stack, which ${messagePartial} Please upgrade your ${this.noun} to a newer Heroku stack.`);
    })
  });

  _exports.default = _default;
});