define("dashboard/components/ci/tests-menu/filter-button-content", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    selectedBranch: null,
    hasSelectedBranch: (0, _computed.notEmpty)('selectedBranch')
  });

  _exports.default = _default;
});