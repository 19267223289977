define("dashboard/serializers/enterprise-account/audit-trail-archive", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      // Create an ID since there isn't one in the response.
      const enterpriseAccountId = resourceHash.enterprise_account_id;
      const month = resourceHash.month;
      const year = resourceHash.year;
      resourceHash['id'] = `${enterpriseAccountId}-${month}-${year}`;
      return this._super(modelClass, resourceHash);
    }

  });

  _exports.default = _default;
});