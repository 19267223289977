define("dashboard/mixins/dyno-upgrade", ["exports", "@ember/service", "@ember/object/mixin", "rsvp"], function (_exports, _service, _mixin, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),

    upgradeDyno(type, source) {
      return new _rsvp.default.Promise(resolve => {
        const pendingSave = this.store.findRecord('app', this.get('app.id')).then(app => {
          return app.updateDynoTier(type);
        });
        return pendingSave.then(() => {
          this.analytics.logMetricsEvent('Process', 'Upgraded', {
            via: source
          });
          resolve();
        });
      });
    }

  });

  _exports.default = _default;
});