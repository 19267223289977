define("dashboard/controllers/app/datastore", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    addon: (0, _computed.alias)('model.addon'),
    iconName: (0, _object.computed)('addon', function () {
      return this.addon.isKafka ? 'data-kafka-28' : 'data-postgres-28';
    }),
    iconTitle: (0, _object.computed)('addon', function () {
      return this.addon.isKafka ? 'Kafka' : 'Postgres';
    })
  });

  _exports.default = _default;
});