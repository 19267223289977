define("dashboard/components/identity-provider/certificate-list-row", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    isExpired: (0, _computed.readOnly)('certificate.isExpired'),
    state: (0, _object.computed)('isExpired', function () {
      if (this.isExpired) {
        return 'expired';
      } else {
        return 'active';
      }
    })
  });

  _exports.default = _default;
});