define("dashboard/templates/app/logs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8R5TP41B",
    "block": "[[[10,0],[14,0,\"build-view limit-width\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex justify-between\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-breadcrumb\"],[12],[1,\"\\n      Application Logs\\n    \"],[13],[1,\"\\n\\n\"],[6,[39,0],null,[[\"buttonContent\",\"hasCaret\",\"caretColor\",\"class\",\"menuClass\"],[[30,0,[\"selectedFormation\",\"type\"]],true,\"gray\",\"actions-button formation-filter-drop-down\",\"dropdown-menu-right\"]],[[\"default\"],[[[[1,\"      \"],[11,\"li\"],[24,0,\"bb b--light-silver\"],[4,[38,1],[[30,0],\"filterOnFormation\",[30,0,[\"allProcesses\"]]],null],[12],[1,\"\\n        \"],[10,3],[14,6,\"#\"],[14,0,\"pa2\"],[12],[1,[30,0,[\"allProcesses\",\"type\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"formations\"]]],null]],null],null,[[[1,\"        \"],[11,\"li\"],[4,[38,1],[[30,0],\"filterOnFormation\",[30,1]],null],[12],[1,\"\\n          \"],[10,3],[14,6,\"#\"],[14,0,\"pa2\"],[12],[1,[30,1,[\"type\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null]],[]]]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,4],null,[[\"app\",\"filterOn\"],[[30,0,[\"app\"]],[30,0,[\"selectedFormation\",\"filter\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"formation\"],false,[\"drop-down\",\"action\",\"each\",\"-track-array\",\"log-stream\"]]",
    "moduleName": "dashboard/templates/app/logs.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});