define("dashboard/mixins/preferences", ["exports", "@ember/object/mixin", "@ember/service", "@ember/object/computed", "rsvp"], function (_exports, _mixin, _service, _computed, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    userPreferences: (0, _service.inject)(),
    store: (0, _service.inject)(),
    reporter: (0, _service.inject)(),
    dashboardUserPreferences: (0, _computed.alias)('userPreferences.userPreferenceRecord', function () {
      const savedPrefs = this.get('userPreferences.userPreferenceRecord');

      if (savedPrefs) {
        return savedPrefs;
      } else {
        this.get('reporter.rollbar').info('Failed to retrieve user preferences, creating default set of prefs');
        return this.store.createRecord('user-preference');
      }
    }),
    appPreferences: (0, _computed.alias)('app.preference.content'),

    updateUserPreference(key, value) {
      const userPreferences = this.dashboardUserPreferences;

      if (userPreferences.get(key) !== value) {
        userPreferences.set(key, value);
        return userPreferences.save();
      } else {
        return new _rsvp.default.Promise(() => {});
      }
    }

  });

  _exports.default = _default;
});