define("dashboard/components/pipeline/runtime-selector", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed"], function (_exports, _component, _service, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributes: ['data-test-target'],
    'data-test-target': 'runtime-selector',
    spaces: (0, _service.inject)(),
    store: (0, _service.inject)(),
    hasNoSpaces: (0, _computed.empty)('spaces.orgSpaceOptions'),
    isSpaceSelected: (0, _computed.equal)('target.modelType', 'space'),
    isRegionSelected: (0, _computed.equal)('target.modelType', 'region'),
    showPrivateSpaces: (0, _computed.readOnly)('spaces.canManageSpaces'),
    firstRegionId: (0, _computed.readOnly)('spaces.sortedCommonRuntimes.firstObject.id'),
    firstSpaceId: (0, _computed.readOnly)('spaces.orgSpaces.firstObject.id'),
    region: (0, _object.computed)('target', 'isRegionSelected', function () {
      if (!this.get('isRegionSelected')) {
        return;
      }

      return this.get('target');
    }),
    space: (0, _object.computed)('target', 'isSpaceSelected', function () {
      if (!this.get('isSpaceSelected')) {
        return;
      }

      return this.get('target');
    }),
    actions: {
      targetChanged(modelName, id) {
        if (this.targetChanged) {
          const target = this.store.peekRecord(modelName, id);
          return this.get('targetChanged')(target);
        }
      }

    }
  });

  _exports.default = _default;
});