define("dashboard/routes/pipelines/pipeline", ["exports", "@ember/runloop", "@ember/routing/route", "rsvp", "@ember/object/evented", "@ember/utils", "@ember/service", "dashboard/mixins/document-title", "dashboard/models/github-status", "@ember/object", "dashboard/utils/push-deletion", "dashboard/utils/realtime/context", "ember-concurrency"], function (_exports, _runloop, _route, _rsvp, _evented, _utils, _service, _documentTitle, _githubStatus, _object, _pushDeletion, _context, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_context.default, _documentTitle.default, {
    analytics: (0, _service.inject)(),
    session: (0, _service.inject)(),
    current: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),
    realTimeUpdater: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    get documentTitle() {
      return this.get('controller.model.pipeline.name');
    },

    renderTemplate(controller, model) {
      this.set('breadcrumbs.trail', {
        pipeline: model.pipeline
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav',
        into: 'protected'
      });

      this._super(...arguments);
    },

    githubToken: (0, _service.inject)('github-token'),

    async model(_ref) {
      let {
        id
      } = _ref;
      // Asyncronously load all couplings and apps to allow Ember Data to associate
      // them all
      const pipeline = await this.store.findRecord('pipeline', id, {
        reload: true
      });
      await pipeline.belongsTo('githubRepository').reload();

      if (pipeline.get('team.isEnterpriseTeam')) {
        try {
          await pipeline.get('team.enterprise.githubAppSettings');
        } catch (e) {// it's okay to 404 here
        }
      }

      let githubAppInstallation, reposApiToken;

      if (pipeline.get('isGithubAppEnabled')) {
        githubAppInstallation = pipeline.get('team.enterprise.githubAppInstallation');
        reposApiToken = this.current.get('account').belongsTo('reposApiGithubToken').reload();
      }

      return _rsvp.default.hash({
        idParam: id,
        githubToken: this.githubToken.fetch(),
        pipeline,
        couplings: this.store.query('pipeline-coupling', {
          pipelineId: id
        }).catch(() => []),
        githubAppInstallation,
        reposApiToken,
        apps: this.store.findAll('app') // TODO: Check if this can be removed

      });
    },

    afterModel(_ref2) {
      let {
        pipeline,
        idParam
      } = _ref2;

      // This allows a user to lookup a pipeline by it's name
      // This is just a bandaid over the fact that we cannot lookup couplings
      // by pipeline name.
      if (idParam === pipeline.get('name')) {
        return this.transitionTo('pipelines.pipeline', pipeline.get('id'));
      }

      pipeline.get('apps').toArray().forEach(app => {
        app = this.store.peekRecord('app', app.get('id'));

        if (app) {
          this.subscribeToApp(app);
        }
      });
      this.subscribeToPipeline(pipeline);
      this.subscribeToCouplings(pipeline);
      this.subscribeToReviewAppConfig(pipeline);
      return pipeline.get('githubAppLinks').catch(() => []);
    },

    subscribeToApp(app) {
      this.get('realTimeUpdater').subscribeToModelDefaults(app);
    },

    setupController(controller) {
      _githubStatus.default.fetch().then(status => {
        (0, _runloop.run)(() => {
          controller.set('githubStatus', status);
        });
      });

      return this._super.apply(this, arguments);
    },

    activate() {
      this._super(...arguments);

      this.analytics.logEvent('Pipeline Page', 'Viewed');
    },

    // Kolkrabbi returns 403s for some requests with assumed identities, but
    // we don't want these to invalidate the assumed session.
    catchAssumed(error) {
      const isAssumed = this.get('session.isAssumed');
      const isForbidden = error.status === 403;

      if (isAssumed && isForbidden) {
        return null;
      } else {
        throw error;
      }
    },

    subscribeToPipeline(pipeline) {
      const subscription = pipeline.subscribeToUpdates();
      subscription.on('update', event => {
        const normalized = this.store.normalize('pipeline', (0, _object.get)(event, 'data'));
        this.store.push(normalized);
      });
      subscription.on('destroy', () => {
        (0, _pushDeletion.default)(this.store, 'pipeline', pipeline.id);
      });
    },

    subscribeToCouplings(pipeline) {
      const subscription = pipeline.subscribeToUpdates('pipeline-couplings');
      subscription.on('create', event => {
        const couplingData = event.data;
        this.store.findRecord('app', event.data.app.id).then(() => {
          const normalized = this.store.normalize('pipelineCoupling', couplingData);
          const coupling = this.store.push(normalized);
          pipeline.addCoupling(coupling);
        }).catch(() => {// App does not exist or user does not have access. Either way, noop.
        });
      });
      subscription.on('update', event => {
        const coupling = pipeline.get('pipelineCouplings').findBy('id', event.data.id);

        if ((0, _utils.isNone)(coupling)) {
          return;
        }

        const normalized = this.store.normalize('pipelineCoupling', event.data);
        this.store.push(normalized);
      });
      subscription.on('destroy', event => {
        const coupling = pipeline.get('pipelineCouplings').findBy('id', event.data.id);
        const store = this.store;

        if ((0, _utils.isPresent)(coupling)) {
          // If there is a coupling, app is just being removed from pipeline,
          // handle unloading of coupling.
          (0, _pushDeletion.default)(store, 'pipeline-coupling', coupling.id);
        } // If no coupling, this was an app destroy, and coupling has already
        // been unloaded by app destroy WS event (see pipelines.pipeline.index route)
        // Trigger hasCouplings check


        this.didDestroyCoupling(pipeline);
      });
    },

    subscribeToReviewAppConfig(pipeline) {
      this.realTimeUpdater.subscribeToModelDefaults(pipeline, 'review-app-configs');
    },

    didDestroyCoupling(pipeline) {
      // No couplings is effectively the same as a destroyed pipeline
      if (!pipeline.get('hasCouplings')) {
        pipeline.set('wasDestroyed', true);
        (0, _pushDeletion.default)(this.store, 'pipeline', pipeline.id);
      }
    },

    subscribeToCollaborations: (0, _evented.on)('activate', function () {
      const {
        pipeline
      } = this.modelFor(this.routeName);
      const userId = this.session.isAssumed ? this.session.sudoData.user_id : this.session.userId;
      this.realtimeContext.onCollaborator(userId, 'create', _ref3 => {
        let {
          data
        } = _ref3;
        this.store.findRecord('app', data.app.id).then(app => {
          this.store.queryRecord('pipelineCoupling', {
            app: app.get('id')
          }).then(coupling => {
            pipeline.addCoupling(coupling);
          });
        }).catch(() => {// App does not exist or user does not have access. Either way, noop.
        });
      });
      this.realtimeContext.onCollaborator(userId, 'destroy', _ref4 => {
        let {
          data
        } = _ref4;
        const coupling = (pipeline.get('pipelineCouplings') || []).findBy('app.id', data.app.id);
        const app = coupling.get('app.content');
        const store = this.store;
        const currentAccountId = this.get('current.account.id');
        const collaboratorUserId = data.user.id; // Only unload the app & coupling if current user is the collaborator
        // who was removed

        if (currentAccountId === collaboratorUserId) {
          if (coupling) {
            (0, _pushDeletion.default)(store, 'pipeline-coupling', coupling.id);
          }

          if (app) {
            (0, _pushDeletion.default)(store, 'app', app.id);
          }
        } // After triggering a collaborator destroy, we reload all the Pipelines
        // couplings to get the pipeline's freshest state.
        // This allows us to unload only the couplings that should be unloaded.


        this.store.query('pipeline-coupling', {
          pipelineId: pipeline.get('id')
        }).catch(() => []).finally(() => {
          this.didDestroyCoupling(pipeline);
        });
      });
    }),
    unsubscribe: (0, _evented.on)('deactivate', function () {
      const pipelineApps = this.get('controller.model.pipeline.apps') || [];
      pipelineApps.forEach(app => {
        app = app.get('content');

        if (!app) {
          return;
        }

        this.realTimeUpdater.unsubscribeFromModel(app);
      });
      this.realTimeUpdater.unsubscribeFromModel(this.get('controller.model.pipeline'));
      this.realTimeUpdater.unsubscribeFromModel(this.get('controller.model.pipeline'), 'pipeline-couplings');
      this.realTimeUpdater.unsubscribeFromModel(this.get('controller.model.pipeline'), 'review-app-configs');
    }),
    updateCiStatusTask: (0, _emberConcurrency.task)(function* (ciStatus, pipelineOwnedByTeam, pipelineOwnerTeamId) {
      let pipelineServicesConfig;

      if (this.get('controller.model.pipeline.isGithubAppEnabled')) {
        pipelineServicesConfig = yield this.get('controller.model.pipeline.connectedServices.content');

        if (!pipelineServicesConfig) {
          pipelineServicesConfig = yield this.store.queryRecord('pipeline-services-config', {
            pipelineId: this.get('controller.model.pipeline.id')
          });
        }
      } else {
        pipelineServicesConfig = yield this.get('controller.model.pipeline.githubRepository.content');
      }

      if (ciStatus && pipelineOwnedByTeam) {
        pipelineServicesConfig.set('organization', {
          id: pipelineOwnerTeamId
        });
      }

      pipelineServicesConfig.set('ci', ciStatus);
      return yield pipelineServicesConfig.save().catch(() => {
        pipelineServicesConfig.rollbackAttributes();
      });
    }),
    actions: {
      refresh() {
        this.refresh();
      },

      pipelineDeleted(pipeline) {
        this.send('pipelineDestroyed', pipeline);
        this.transitionTo('apps');
      },

      pipelineUpdated() {
        this.setDocumentTitle();
      },

      authorizedGitHub() {
        let {
          transitionTarget
        } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
          transitionTarget: null
        };
        this.githubToken.fetch().then(token => {
          this.set('currentModel.githubToken', token);

          if ((0, _utils.isPresent)(transitionTarget)) {
            this.transitionTo(transitionTarget);
          } else {
            this.refresh();
          }
        });
      },

      updateCiStatus(ciStatus, pipelineOwnedByTeam, pipelineOwnerTeamId) {
        this.updateCiStatusTask.perform(ciStatus, pipelineOwnedByTeam, pipelineOwnerTeamId);
      }

    }
  });

  _exports.default = _default;
});