define("dashboard/models/team/invoice", ["exports", "@ember/object", "dashboard/config/environment", "moment", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _object, _environment, _moment, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    addonsTotal: (0, _attr.default)('number'),
    databaseTotal: (0, _attr.default)('number'),
    chargesTotal: (0, _attr.default)('number'),
    created_at: (0, _attr.default)('date'),
    creditsTotal: (0, _attr.default)('number'),
    dynoUnits: (0, _attr.default)('number'),
    number: (0, _attr.default)('number'),
    paymentStatus: (0, _attr.default)(),
    periodEnd: (0, _attr.default)('date'),
    periodStart: (0, _attr.default)('date'),
    platformTotal: (0, _attr.default)('number'),
    state: (0, _attr.default)('number'),
    total: (0, _attr.default)('number'),
    updated_at: (0, _attr.default)('date'),
    weightedDynoHours: (0, _attr.default)('number'),
    team: (0, _relationships.belongsTo)('team'),
    secureId: (0, _attr.default)(),
    payBalanceRoute: 'team.switchable.billing.pay-balance',
    url: (0, _object.computed)('number', function () {
      const teamName = this.get('team.name');
      return `${_environment.default.particleboardUrl}/teams/${teamName}/invoices/${this.number}`;
    }),
    year: (0, _object.computed)('date', function () {
      return this.date.year();
    }),
    month: (0, _object.computed)('date', function () {
      return this.date.month() + 1;
    }),
    title: (0, _object.computed)('date', function () {
      return this.date.format('MMMM YYYY');
    }),
    date: (0, _object.computed)('periodStart', function () {
      return (0, _moment.default)(this.periodStart).utc();
    }),
    isDeclined: (0, _object.computed)('stateString', function () {
      return this.stateString === 'Declined';
    }),
    stateClass: (0, _object.computed)('stateString', function () {
      return this.stateString.toLowerCase();
    }),
    stateString: (0, _object.computed)('state', function () {
      const state = this.state;
      const total = this.total;

      switch (state) {
        case 1:
        case 2:
          return total === 0 ? 'No Charge' : 'Paid';

        case -1:
          return 'Declined';

        case 3:
          return 'Void';

        default:
          return total === 0 ? 'Paid' : 'Pending';
      }
    })
  });

  _exports.default = _default;
});