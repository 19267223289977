define("dashboard/components/trackable-link-to", ["exports", "@ember/routing/link-component", "@ember/service"], function (_exports, _linkComponent, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _linkComponent.default.extend({
    analytics: (0, _service.inject)(),
    analyticsNoun: null,
    analyticsVerb: 'Clicked',

    init() {
      this._super(...arguments);

      this.on('click', this, this.trackClick);
    },

    willDestroyElement() {
      this.off('click', this, this.trackClick);
    },

    trackClick() {
      if (this.analyticsNoun) {
        this.analytics.logEvent(this.analyticsNoun, this.analyticsVerb);
      }
    }

  });

  _exports.default = _default;
});