define("dashboard/components/confirm/with-password", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', this, this._clearPassword);
    },

    _clearPassword() {
      this.set('password', '');
    }

  });

  _exports.default = _default;
});