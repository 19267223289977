define("dashboard/components/validating-input", ["exports", "@ember/object", "@ember/component", "dashboard/mixins/validating-field"], function (_exports, _object, _component, _validatingField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_validatingField.default, {
    type: (0, _object.computed)(function () {
      return 'text';
    })
  });

  _exports.default = _default;
});