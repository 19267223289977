define("dashboard/components/metrics/charts/chart-header/java-memory-summary", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/chart-header/chart-summary", "@ember/object"], function (_exports, _computed, _chartSummary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _chartSummary.default.extend({
    primaryUnit: 'MB',
    numberFormat: '0,0.0',
    hasSufficientData: (0, _computed.and)('data.maxUsed', 'data.averageUsed'),
    latestMemoryUsage: (0, _object.computed)('data.currentTotal', 'data.memoryType', function () {
      return _object.default.create({
        title: `The most recent ${this.get('data.memoryType')} memory usage.`,
        label: 'latest',
        primaryValue: this.get('data.currentTotal')
      });
    }),
    maxMemoryUsage: (0, _object.computed)('data.maxUsed', 'data.memoryType', function () {
      return _object.default.create({
        title: `The maximum ${this.get('data.memoryType')} memory usage during this time period.`,
        label: 'max',
        primaryValue: this.get('data.maxUsed')
      });
    }),
    averageMemoryUsage: (0, _object.computed)('data.averageUsed', 'data.memoryType', function () {
      return _object.default.create({
        title: `The average ${this.get('data.memoryType')} memory usage during this time period.`,
        label: 'average',
        primaryValue: this.get('data.averageUsed')
      });
    }),
    chartSummaryItems: (0, _computed.collect)('latestMemoryUsage', 'maxMemoryUsage', 'averageMemoryUsage')
  });

  _exports.default = _default;
});