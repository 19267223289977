define("dashboard/components/app/deploy/github/automatic-deploys", ["exports", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    'data-test-target': 'github-automatic-deploys',
    githubBranches: (0, _computed.readOnly)('app.branches'),
    connectedServices: (0, _computed.readOnly)('app.services'),
    actions: {
      toggleAutoDeploy(connectedServices) {
        connectedServices.toggleProperty('autoDeploy');
        return connectedServices.save().then(() => {
          this.analytics.logEvent('GitHub Auto Deploy', connectedServices.get('autoDeploy') ? 'Enabled' : 'Disabled');
        });
      }

    }
  });

  _exports.default = _default;
});