define("dashboard/components/space/settings/log-drain-url", ["exports", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    nameHasChanged: (0, _computed.readOnly)('udpateableURL.hasDirtyAttributes'),
    store: (0, _service.inject)(),
    isEditingLogDrainUrl: false,
    actions: {
      edit() {
        this.set('URLDidChange', false);
      },

      save(newUrl) {
        const store = this.store;
        const oldLogDrain = this.get('model.logDrain');
        const newLogDrain = store.createRecord('space/log-drain', {
          url: newUrl,
          spaceId: this.get('model.space.id')
        });
        const pendingSave = newLogDrain.save();
        return pendingSave.then(() => {
          this.set('isEditingLogDrainUrl', false);
          this.set('URLDidChange', true);
          this.set('model.logDrain', newLogDrain);
          oldLogDrain.unloadRecord();
        });
      }

    }
  });

  _exports.default = _default;
});