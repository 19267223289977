define("dashboard/templates/components/pipeline/stale-days-control", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "K72oiOr/",
    "block": "[[[6,[39,0],null,[[\"classNames\",\"value\",\"disabled\",\"onChange\"],[\"hk-select stale-days-control\",[30,0,[\"defaultSelection\"]],[30,0,[\"disabled\"]],[28,[37,1],[[30,0],\"changeSelection\"],null]]],[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"options\"]]],null]],null],null,[[[6,[30,1,[\"option\"]],null,[[\"value\"],[[30,2]]],[[\"default\"],[[[[1,\"      After \"],[1,[30,2]],[1,\" \"],[1,[28,[35,4],[[30,2],\"day\",\"days\"],null]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]],[1,\"\\n\"],[10,\"small\"],[15,0,[29,[\"f5 \",[30,0,[\"smallTextClass\"]],\" \",[52,[30,0,[\"disabled\"]],\"gray\",\"dark-gray\"]]]],[12],[1,\"without any deploys\"],[13],[1,\"\\n\"]],[\"xs\",\"option\"],false,[\"x-select\",\"action\",\"each\",\"-track-array\",\"pluralize-word\",\"if\"]]",
    "moduleName": "dashboard/templates/components/pipeline/stale-days-control.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});