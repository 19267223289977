define("dashboard/models/app-json-config-var", ["exports", "@ember/object/computed", "@ember/object"], function (_exports, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  @class AppJSONConfigVar
   *  @extends Ember.Object
   *
   *  attrs: key, value, type
   */
  var _default = _object.default.extend({
    value: '',
    type: 'literal',
    isLiteral: (0, _computed.equal)('type', 'literal'),
    isInherited: (0, _computed.equal)('type', 'inherit'),
    isCopied: (0, _computed.equal)('type', 'copy'),
    isGenerator: (0, _computed.equal)('type', 'generator'),
    hasLiteralValue: (0, _computed.or)('isLiteral', 'isCopied'),
    isNotEmpty: (0, _computed.not)('isEmpty'),
    isEmpty: (0, _computed.and)('keyIsEmpty', 'typeIsEmpty', 'valueIsEmpty'),
    keyIsEmpty: (0, _computed.empty)('key'),
    hasKey: (0, _computed.not)('keyIsEmpty'),
    typeIsEmpty: (0, _computed.equal)('type', 'literal'),
    valueIsEmpty: (0, _computed.empty)('value'),
    serializedValue: (0, _object.computed)('isLiteral', 'isGenerator', 'isCopied', 'value', function () {
      if (this.isLiteral) {
        return this.value;
      }

      if (this.isCopied) {
        return this.get('parent.value');
      }

      if (this.isInherited) {
        return {
          required: true
        };
      }

      if (this.isGenerator) {
        return {
          generator: 'secret'
        };
      }
    }),
    serialized: (0, _object.computed)('key', 'serializedValue', function () {
      const object = {};
      object[this.key] = this.serializedValue;
      return object;
    })
  });

  _exports.default = _default;
});