define("dashboard/app", ["exports", "@ember/application", "@ember/routing/link-component", "dashboard/components/did-render-element-emitter", "dashboard/routes/route", "dashboard/config/environment", "dashboard/resolver", "ember-load-initializers", "dashboard/utils/ajax-setup", "dashboard/utils/open-telemetry"], function (_exports, _application, _linkComponent, _didRenderElementEmitter, _route, _environment, _resolver, _emberLoadInitializers, _ajaxSetup, _openTelemetry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const App = _application.default.extend({
    Resolver: _resolver.default,
    modulePrefix: _environment.default.modulePrefix
  });

  (0, _ajaxSetup.default)();
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix); // This gives us the ability to use data-test-target on {{#link-to}}

  _linkComponent.default.reopen({
    attributeBindings: ['data-test-target']
  });

  var _default = App;
  _exports.default = _default;
});