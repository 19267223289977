define("dashboard/models/space/outbound-ruleset", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    space: (0, _relationships.belongsTo)(),
    rules: (0, _attr.default)(),

    addRule(rule) {
      rule.from_port = Number(rule.from_port);
      rule.to_port = Number(rule.to_port);

      if (!this.ruleExists(rule)) {
        this.rules.push(rule);
      }
    },

    ruleExists(rule) {
      return this.rules.find(existingRule => {
        return existingRule.target === rule.target && existingRule.protocol === rule.protocol && existingRule.from_port === Number(rule.from_port) && existingRule.to_port === Number(rule.to_port);
      });
    }

  });

  _exports.default = _default;
});