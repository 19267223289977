define("dashboard/models/restart-event", ["exports", "@ember-data/model", "@ember/object", "moment"], function (_exports, _model, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    formation: (0, _model.belongsTo)('formation'),
    restartType: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    time: (0, _object.computed)('createdAt', function () {
      return (0, _moment.default)(this.createdAt).valueOf();
    })
  });

  _exports.default = _default;
});