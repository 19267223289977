define("dashboard/routes/team/switchable/access", ["exports", "rsvp", "@ember/service", "dashboard/routes/team/base"], function (_exports, _rsvp, _service, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A route for managing team membership.
   *
   * @class Dashboard.TeamAccessRoute
   * @extends Dashboard.TeamBaseRoute
   */
  var _default = _base.default.extend({
    templateName: 'team/switchable/access',
    analytics: (0, _service.inject)(),
    queryParams: {
      user: {
        replace: true
      }
    },

    model() {
      const {
        team
      } = this.modelFor('team');
      return (0, _rsvp.hash)({
        team,
        members: team.get('members').reload(),
        invitations: team.get('invitations').reload(),
        apps: team.get('apps'),
        preference: team.get('preference'),
        enterpriseTeamMembers: team.get('enterprise').then(enterprise => {
          if (enterprise) {
            return enterprise.get('members');
          }
        }),
        enterpriseIdentityProvider: team.get('enterprise.identityProvider')
      });
    },

    afterModel(model) {
      const analyticsNoun = model.team.analyticsNoun;
      this.analytics.logEvent(`${analyticsNoun} Access Page`, 'Viewed');
    }

  });

  _exports.default = _default;
});