define("dashboard/routes/account/index", ["exports", "rsvp", "@ember/service", "dashboard/routes/account/base", "dashboard/mixins/document-title"], function (_exports, _rsvp, _service, _base, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dashboard.AccountIndexRoute
   * @extends Dashboard.AccountBaseRoute
   */
  var _default = _base.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),

    model() {
      const {
        teams
      } = this.modelFor('protected');
      const userPreferences = this.modelFor('protected').userPreferences;
      return new _rsvp.Promise((resolve, reject) => {
        (0, _rsvp.hash)({
          keys: this.store.findAll('key'),
          personalAuthorization: this.store.queryRecord('authorization', {
            name: '~'
          }).catch(() => {})
        }).then(result => {
          resolve({
            keys: result.keys,
            personalAuthorization: result.personalAuthorization,
            teams,
            userPreferences
          });
        }, reject);
      });
    },

    afterModel() {
      this.analytics.logEvent('Account Profile Page', 'Viewed');
    }

  });

  _exports.default = _default;
});