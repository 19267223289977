define("dashboard/adapters/recovery-code", ["exports", "@ember/service", "dashboard/adapters/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    dsErrorMonitor: (0, _service.inject)(),
    namespace: 'account',

    urlForCreateRecord(modelName, snapshot) {
      const {
        adapterOptions: {
          password
        }
      } = snapshot;
      const newHeaders = Object.assign(this.headers, {
        'Heroku-Password-Base64': btoa(password)
      });
      this.set('headers', newHeaders);
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});