define("dashboard/templates/components/datastore/kafka/topic-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Zl/5ggvU",
    "block": "[[[10,0],[14,0,\"flex\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mr1\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"topicStatus\"]],\"GREEN\"],null],[[[1,\"        \"],[8,[39,2],null,[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[[30,0,[\"topicStatus\"]],\"success-badge-16\",16,\"malibu-fill-gradient-green\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"topicStatus\"]],\"YELLOW\"],null],[[[1,\"        \"],[8,[39,2],null,[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[[30,0,[\"topicStatus\"]],\"warning-badge-16\",16,\"malibu-fill-gradient-orange\"]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"topicStatus\"]],\"RED\"],null],[[[1,\"        \"],[8,[39,2],null,[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[[30,0,[\"topicStatus\"]],\"error-badge-16\",16,\"malibu-fill-gradient-red\"]],null],[1,\"\\n      \"]],[]],null]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"ttu f6\"],[12],[1,[30,0,[\"topic\",\"status_label\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mr2\"],[12],[1,\"Retention: \"],[10,\"b\"],[12],[1,[30,0,[\"humanizedRetentionTime\"]]],[13],[13],[1,\"\\n    \"],[10,0],[14,0,\"mr2\"],[12],[1,\"Compaction: \"],[10,\"b\"],[12],[1,[52,[30,0,[\"topic\",\"compaction_enabled\"]],\"enabled\",\"disabled\"]],[13],[13],[1,\"\\n    \"],[10,0],[14,0,\"mr2\"],[12],[1,\"Replication: \"],[10,\"b\"],[12],[1,[30,0,[\"topic\",\"replication_factor\"]]],[13],[13],[1,\"\\n    \"],[10,0],[14,0,\"mr2\"],[12],[1,\"Partition: \"],[10,\"b\"],[12],[1,[30,0,[\"topic\",\"partitions\"]]],[13],[13],[1,\"\\n    \"],[10,0],[12],[6,[39,3],null,[[\"class\",\"query\"],[\"hk-link\",[28,[37,4],null,[[\"topic\"],[[30,0,[\"topicIndex\"]]]]]]],[[\"default\"],[[[[1,\"Edit\"]],[]]]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"eq\",\"malibu-icon\",\"link-to\",\"-hash\"]]",
    "moduleName": "dashboard/templates/components/datastore/kafka/topic-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});