define("dashboard/templates/app/build", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bYAdRf19",
    "block": "[[[10,0],[14,0,\"build-view limit-width\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"page-breadcrumb\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\",\"model\"],[\"app.activity\",[30,0,[\"model\",\"app\",\"name\"]]]],[[\"default\"],[[[[1,\"      Activity Feed\\n\"]],[]]]]],[1,\"    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"],[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"nav-forward-16\",\"fill-gray ml1 mb1 mr1\",16]],null],[1,\"\\n    Build Log\\n\\n    \"],[10,0],[14,0,\"build-id pull-right\"],[12],[1,\"\\n      ID \"],[1,[30,0,[\"model\",\"build\",\"id\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,2],null,[[\"streamUrl\",\"size\"],[[30,0,[\"model\",\"build\",\"outputStreamUrl\"]],\"full\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"malibu-icon\",\"build-stream\"]]",
    "moduleName": "dashboard/templates/app/build.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});