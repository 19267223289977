define("dashboard/models/team/invitation", ["exports", "ember-cp-validations", "dashboard/mixins/ds-error-monitoring-model", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _emberCpValidations, _dsErrorMonitoringModel, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    isDuplicated: (0, _emberCpValidations.validator)('exclusion', {
      in: [true],
      message: 'Already added'
    })
  });

  var _default = _model.default.extend(_dsErrorMonitoringModel.default, Validations, {
    team: (0, _relationships.belongsTo)('team'),
    createdAt: (0, _attr.default)('date'),
    email: (0, _attr.default)('string'),
    invitedBy: (0, _attr.default)(),
    name: (0, _attr.default)('string'),
    role: (0, _attr.default)('string')
  });

  _exports.default = _default;
});