define("dashboard/components/pipeline/new-manage-third-party-connection", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "@ember/object", "rsvp"], function (_exports, _component, _service, _computed, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    reposApiGithubToken: (0, _computed.readOnly)('current.account.reposApiGithubToken'),
    githubUserLogin: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.login'),
    githubUserName: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.name'),
    githubUserLink: (0, _computed.readOnly)('reposApiGithubToken.repositoriesApiGithubUser.htmlUrl'),
    connectedRepository: (0, _computed.readOnly)('pipeline.connectedRepository'),
    herokuResourceId: (0, _object.computed)('herokuResourceType', 'current.accountId', 'pipelineOwner.enterprise.id', 'pipelineOwner.id', function () {
      if (this.herokuResourceType === 'user') {
        return this.current.accountId;
      } else {
        return this.get('pipelineOwner.isEnterpriseTeam') ? this.get('pipelineOwner.enterprise.id') : this.get('pipelineOwner.id');
      }
    }),
    herokuResourceType: (0, _object.computed)('pipelineOwner.isEnterpriseTeam', function () {
      if (!this.pipelineOwner) {
        return 'user';
      } else {
        return this.get('pipelineOwner.isEnterpriseTeam') ? 'enterprise-account' : 'team';
      }
    }),
    githubAppInstallation: (0, _object.computed)('pipelineOwner.isEnterpriseTeam', 'pipelineOwner.githubAppInstallation', 'pipelineOwner.enterprise.githubAppInstallation', 'herokuResourceType', 'current.account.githubAppInstallation', function () {
      if (this.herokuResourceType === 'user') {
        return this.get('current.account.githubAppInstallation');
      } else {
        return this.get('pipelineOwner.isEnterpriseTeam') ? this.get('pipelineOwner.enterprise.githubAppInstallation') : this.get('pipelineOwner.githubAppInstallation');
      }
    }),
    hasGithubAppInstalled: (0, _computed.readOnly)('githubAppInstallation.isLoaded'),
    hasAdminAccess: (0, _object.computed)('herokuResourceType', 'pipelineOwner.currentPermissions.canManageGitProviders', 'pipelineowner.currentPermissions.canManageGitProviders;', function () {
      if (this.herokuResourceType === 'enterprise-account') {
        return this.get('pipelineOwner.enterprise.currentPermissions.canManageGitProviders');
      } else if (this.herokuResourceType === 'team') {
        return this.get('pipelineowner.currentPermissions.canManageGitProviders');
      } else {
        return true;
      }
    }),
    searchQuery: (0, _object.computed)('searchTerm', 'herokuResourceId', function () {
      const query = {
        q: `fork:true ${this.searchTerm}`
      };

      if (this.get('pipelineOwner.isGithubAppEnabled')) {
        query.isGithubAppEnabled = true;
      }

      query['enterpriseId'] = this.herokuResourceId;
      return query;
    }),
    pipelineBenefits: [{
      iconName: 'app-review-16',
      description: 'Review apps can be enabled for this pipeline'
    }, {
      iconName: 'git-commit-16',
      description: 'Releases in app activity feeds link to GitHub to view commit diffs'
    }, {
      iconName: 'auto-16',
      description: 'Automatic deploys are available to apps in this pipeline'
    }, {
      iconName: 'ci-16',
      description: 'You can run tests on this pipeline with Heroku CI'
    }],

    didUpdateAttrs() {
      this.set('repos', []);
      this.set('lastSearchTerm', null);
      this.set('searchTerm', null);
    },

    init() {
      this._super(...arguments);

      this.current.get('account').belongsTo('reposApiGithubToken').reload();
    },

    actions: {
      search() {
        const searchQuery = Object.assign({}, this.get('searchQuery'));
        this.set('repos', []);
        this.set('lastSearchTerm', null);
        return this.store.query('repositories-api-github-repo', searchQuery).then(repos => {
          this.setProperties({
            lastSearchTerm: this.searchTerm,
            repos
          });
        });
      },

      connectRepository(repo) {
        _rsvp.default.resolve(this.onRepoLink(repo)).then(() => {
          this.set('repos', []);
          this.set('lastSearchTerm', null);
          this.set('searchTerm', null);
          this.analytics.logEvent('Github Apps Pipeline', 'Connected', {
            pipelineId: this.get('pipeline.id')
          });
        });
      },

      logEvent(noun, action) {
        this.analytics.logEvent(noun, action);
      }

    }
  });

  _exports.default = _default;
});