define("dashboard/mixins/formation-totals", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _mixin.default.create({
    isEnterpriseOrg: readOnly('team.isEnterpriseOrg'),
    isNotEnterpriseOrg: readOnly('team.isNotEnterpriseOrg'),
    formationsTotalPrice: (0, _object.computed)('formations.@each.price', function () {
      return this.formations.reduce((total, item) => {
        return total + item.get('price');
      }, 0);
    }),
    formationsOriginalTotalPrice: (0, _object.computed)('formations.@each.originalPrice', function () {
      return this.formations.reduce((total, item) => {
        return total + item.get('originalPrice');
      }, 0);
    }),
    totalDynoUnits: (0, _object.computed)('formations.@each.dynoUnits', function () {
      return this.formations.reduce((total, item) => {
        return total + item.get('dynoUnits');
      }, 0);
    }),
    originalTotalDynoUnits: (0, _object.computed)('formations.@each.originalDynoUnits', function () {
      return this.formations.reduce((total, item) => {
        return total + item.get('originalDynoUnits');
      }, 0);
    }),
    hoursInMonth: (0, _object.computed)('isEnterpriseOrg', function () {
      return this.isEnterpriseOrg ? 750 : 720;
    })
  });

  _exports.default = _default;
});