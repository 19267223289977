define("dashboard/templates/components/app/web-hook-deliveries/list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "y/1ZtLC+",
    "block": "[[[10,0],[14,0,\"overflow-x-scroll\"],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"w-100 mb6\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[14,0,\"f5 bb b--silver\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,0,\"w3\"],[12],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"dtc w4 pa2 normal b dark-gray\"],[12],[1,\"Status\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"dtc pa2 normal b dark-gray nowrap\"],[12],[1,\"Event type\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"dtc pa2 normal b dark-gray\"],[12],[1,\"Timestamp\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"dtc pa2 normal b dark-gray\"],[12],[1,\"Meta\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[14,0,\"hk-hide-bb-last-row\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"deliveries\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,2],null,[[\"delivery\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"deliveries\"]]],[[[6,[39,4],null,[[\"class\"],[\"mt4 w-100\"]],[[\"default\"],[[[[6,[30,2,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"      There are no deliveries for \"],[1,[30,0,[\"webhookName\"]]],[1,\"\\n\"]],[]]]]]],[2]]]]]],[]],null]],[\"delivery\",\"well\"],false,[\"each\",\"-track-array\",\"app/web-hook-deliveries/list-item\",\"unless\",\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/app/web-hook-deliveries/list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});