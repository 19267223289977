define("dashboard/templates/components/account/authorization-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j4rI0fLq",
    "block": "[[[6,[39,0],null,[[\"changeset\",\"property\"],[[30,0,[\"changeset\"]],\"description\"]],[[\"default\"],[[[[6,[30,1,[\"label\"]],null,null,[[\"default\"],[[[[1,\"    Description\\n    \"],[10,1],[14,0,\"br1 ph1 ml1 bg-gray f6 white\"],[12],[1,\"Required\"],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"],[1,[28,[30,1,[\"field\"]],null,[[\"placeholder\",\"class\",\"autofocus\"],[\"Enter a description\",\"w-100 mb1\",true]]]],[1,\"\\n  \"],[1,[30,1,[\"errorsList\"]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[41,[51,[30,0,[\"isEditing\"]]],[[[6,[39,0],null,[[\"class\",\"changeset\",\"property\"],[\"mt2\",[30,0,[\"changeset\"]],\"expiresIn\"]],[[\"default\"],[[[[1,\"    \"],[6,[30,2,[\"label\"]],null,null,[[\"default\"],[[[[1,\"Expires after (seconds)\"]],[]]]]],[1,\"\\n    \"],[1,[28,[30,2,[\"field\"]],null,[[\"class\"],[\"w-100 mb1\"]]]],[1,\"\\n    \"],[10,0],[14,0,\"mid-gray f5 fw1\"],[12],[1,\"Leave this blank to set no expiry for the token\"],[13],[1,\"\\n    \"],[1,[30,2,[\"errorsList\"]]],[1,\"\\n\"]],[2]]]]]],[]],null]],[\"group\",\"group\"],false,[\"hk-form-group\",\"unless\"]]",
    "moduleName": "dashboard/templates/components/account/authorization-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});