define("dashboard/serializers/status/unresolved-incident", ["exports", "dashboard/serializers/application", "dashboard/mixins/serializers/incident"], function (_exports, _application, _incident) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_incident.default, {
    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      const transformedPayload = payload.incidents.map(incident => {
        return this.transformIncidentHash(incident);
      });
      return this._super(store, primaryModelClass, transformedPayload, id, requestType);
    }

  });

  _exports.default = _default;
});