define("dashboard/components/metrics/charts/visualizations/wrapped-line-chart", ["exports", "@ember/object/computed", "@ember/utils", "@ember/component", "@ember/object", "dashboard/utils/metrics/data-refresh", "dashboard/mixins/metrics/current-timeframe", "dashboard/utils/metrics/time-calculators", "dashboard/utils/rolledup-array", "dashboard/templates/components/metrics/charts/visualizations/wrapped-line-chart"], function (_exports, _computed, _utils, _component, _object, _dataRefresh, _currentTimeframe, _timeCalculators, _rolledupArray, _wrappedLineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    equal,
    filterBy,
    not,
    notEmpty,
    or,
    readOnly
  } = _object.computed;

  var _default = _component.default.extend(_currentTimeframe.default, {
    layout: _wrappedLineChart.default,
    classNameBindings: [':metrics__wrapped-chart', ':b--black-05', ':flex-column', 'isNotLastRow:bb', 'chartOnLeftSide:is-left-chart', 'isSecondToLast:has-bb-when-stacked'],
    title: (0, _object.computed)('chartTitle', 'language', function () {
      if (this.language) {
        return `${this.language.capitalize()}: ${this.chartTitle}`;
      } else {
        return this.chartTitle;
      }
    }),
    currentTimeframe: readOnly('timeframe'),
    isMemoryChart: equal('unit', 'memory'),
    // default max value, can be overridden
    defaultMaxValue: (0, _object.computed)('isMemoryChart', function () {
      return this.isMemoryChart ? 4 : 1;
    }),
    smallNumberThreshold: 1,
    noData: not('data'),
    isLoadingData: or('noData', 'refreshingAlerts.isPending', 'alerts.isPending'),
    isNotRefreshingData: not('isRefreshingData'),
    isShowingLoadingState: and('isLoadingData', 'isNotRefreshingData'),
    isShowingMetricsSummary: notEmpty('summaryComponent'),
    isSecondToLast: (0, _object.computed)('chartOrder', 'numberOfCharts', 'isCompactLayout', function () {
      return this.isCompactLayout ? this.chartOrder === this.numberOfCharts - 2 : false;
    }),
    isNotLastRow: (0, _object.computed)('chartOrder', 'numberOfCharts', 'isCompactLayout', function () {
      const numberOfChartsOnLastRow = this.isCompactLayout ? 2 : 1;
      return this.chartOrder < this.numberOfCharts - numberOfChartsOnLastRow;
    }),
    isEven: (0, _object.computed)('chartOrder', function () {
      return this.chartOrder % 2 === 0;
    }),
    chartOnLeftSide: and('isCompactLayout', 'isEven'),
    primaryAxisTimeSeries: filterBy('chartTimeSeries', 'useAlternateScale', undefined),
    visibleTimeSeries: filterBy('primaryAxisTimeSeries', 'isVisible'),
    stackedTimeSeries: filterBy('visibleTimeSeries', 'type', 'metrics/charts/line-chart-elements/stacked-area-graph'),
    positiveTimeSeries: filterBy('visibleTimeSeries', 'negated', undefined),
    negativeTimeSeries: filterBy('visibleTimeSeries', 'negated'),
    chartHasPositiveTimeSeries: notEmpty('positiveTimeSeries'),
    chartHasNegativeTimeSeries: notEmpty('negativeTimeSeries'),
    chartHasOpposingTimeSeries: and('chartHasPositiveTimeSeries', 'chartHasNegativeTimeSeries'),

    /*
     * Reduces all stacked graph data into one array to be used for graph's max value
     * calculations. Must be placed into an array to allow for union.
     * (currently assuming that all stacked series are positive)
     */
    stackedTotalData: (0, _object.computed)('stackedTimeSeries.@each.data', function () {
      return this.stackedTimeSeries.reduce(function (total, series) {
        if (total) {
          return total.map(function (datum, index) {
            const other = series.get('data')[index];
            const value = other ? other[1] : 0;
            return [datum[0], datum[1] + value];
          });
        } else {
          return series.get('data');
        }
      }, null);
    }),
    visibleSeriesData: (0, _computed.mapBy)('visibleTimeSeries', 'data'),
    timeSeriesData: (0, _object.computed)('visibleTimeSeries.@each.data', 'stackedTotalData', function () {
      const data = this.visibleTimeSeries.mapBy('data');
      data.pushObject(this.stackedTotalData);
      return data.filter(function (dataset) {
        return (0, _utils.isPresent)(dataset);
      });
    }),
    deploys: (0, _object.computed)('refreshingDeploys', function () {
      return this.refreshingDeploys;
    }),
    alerts: (0, _object.computed)('refreshingAlerts', function () {
      this.refreshingAlerts;
    }),
    deploysWithinTimeframe: (0, _timeCalculators.filterWithinTimeframe)('deploys', 'createdAt'),
    loadRefreshedDeploys: (0, _dataRefresh.loadRefreshedData)('refreshingDeploys', 'deploys'),
    loadRefreshedAlerts: (0, _dataRefresh.loadRefreshedData)('refreshingAlerts', 'alerts'),
    _rolledupDeploys: (0, _rolledupArray.default)('deploysWithinTimeframe', 'currentStepDuration'),
    activityEvents: (0, _object.computed)('_rolledupDeploys.[]', function () {
      return this._rolledupDeploys.map(function (deploy) {
        const models = deploy.models;
        let label = models[0].get('commit');

        if (models.length > 1) {
          label = `${label}+${models.length - 1}`;
        }

        return {
          time: deploy.time,
          value: deploy.value,
          label
        };
      });
    }),
    chartContentData: (0, _object.computed)('chartTimeSeries.[]', 'activityEvents.[]', 'filteredAlerts.[]', function () {
      return _object.default.create({
        chartTimeSeries: this.chartTimeSeries,
        activityEvents: this.activityEvents,
        alerts: this.filteredAlerts,
        component: this
      });
    })
  });

  _exports.default = _default;
});