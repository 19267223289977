define("dashboard/routes/protected/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Top-level application route.
   * It redirects to the default organization, if it exists.
   *
   * @class Dashboard.IndexRoute
   *
   */
  var _default = _route.default.extend({
    redirect() {
      const userPreferences = this.modelFor('protected').userPreferences;
      const defaultOrganizationName = userPreferences ? userPreferences.get('defaultOrganization') : null;
      const {
        teams
      } = this.modelFor('protected');

      if (defaultOrganizationName && teams.findBy('name', defaultOrganizationName)) {
        return this.replaceWith('team.switchable', defaultOrganizationName);
      } else {
        return this.replaceWith('apps');
      }
    }

  });

  _exports.default = _default;
});