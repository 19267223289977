define("dashboard/components/verify-account", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    init() {
      this._super(...arguments);

      this.setup();
    },

    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    isOrgVerification: (0, _computed.bool)('organization'),
    PaymentMethodModel: (0, _object.computed)('isOrgVerification', function () {
      return this.isOrgVerification ? 'team/payment-method' : 'payment-method';
    }),
    store: (0, _service.inject)(),

    setup() {
      let paymentMethodFind;
      const store = this.store;

      if (this.organization) {
        paymentMethodFind = store.queryRecord('team/payment-method', {
          team: this.get('organization.id')
        });
      } else {
        paymentMethodFind = store.queryRecord('payment-method', {});
      }

      const promise = paymentMethodFind.then(paymentMethod => {
        const paymentModel = this.PaymentMethodModel;
        paymentMethod = paymentMethod || store.createRecord(paymentModel, {
          team: this.get('organization.id')
        });
        this.set('model', paymentMethod);
      });
      this.set('modelPromise', promise);
    },

    willDestroy() {
      const model = this.model;

      if (model) {
        model.unloadRecord();
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});