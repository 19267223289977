define("dashboard/serializers/pipeline-services-config", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      relationships.pipeline = {
        data: {
          id: hash.pipeline.id,
          type: 'pipeline'
        }
      };
      return relationships;
    },

    serialize() {
      const payload = this._super(...arguments);

      const organization = payload.organization;

      if (organization.id) {
        payload['organization'] = organization.id;
      }

      return payload;
    }

  });

  _exports.default = _default;
});