define("dashboard/serializers/payment-method", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      card_last4: {
        serialize: false
      },
      card_type: {
        serialize: false
      },
      expiration_month: {
        serialize: false
      },
      expiration_year: {
        serialize: false
      }
    },

    // PaymentMethods are singletons, so we use the organization's id.
    normalizeSingleResponse(store, primaryModelClass) {
      const doc = this._super(...arguments);

      doc.data.id = primaryModelClass.modelName;
      return doc;
    }

  });

  _exports.default = _default;
});