define("dashboard/adapters/status/resolved-incident", ["exports", "ember-data", "dashboard/mixins/adapters/status-api", "moment"], function (_exports, _emberData, _statusApi, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_statusApi.default, {
    urlForFindAll() {
      const startTime = (0, _moment.default)().subtract(7, 'days').format('YYYY-MM-DD');
      return `${this.host}/${this.namespace}/incidents?resolved_since=${startTime}`;
    }

  });

  _exports.default = _default;
});