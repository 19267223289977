define("dashboard/components/app/activity-feed", ["exports", "@ember/runloop", "@ember/component", "@ember/service", "@ember/object", "jquery"], function (_exports, _runloop, _component, _service, _object, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    filterBy
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['hk-hide-bb-last-row', 'list-group', 'limit-width'],
    store: (0, _service.inject)(),
    arrangedReleases: filterBy('items', 'isRelease'),
    latestRelease: readOnly('arrangedReleases.firstObject'),
    items: (0, _object.computed)('releases.[]', 'builds.[]', function () {
      const builds = this.builds.toArray();
      const releases = this.releases.toArray();
      return builds.concat(releases).sortBy('createdAt', 'version').reverse();
    }),

    /**
     * whether or not rollback links are allowed
     */
    allowRollbacks: true,

    /**
     * the number of items to render at a time
     */
    batchSize: 15,

    /**
     * determine if we should render older items on scroll
     */
    infiniteScroll: true,

    /**
     * the number of items to show
     * defaults to 0 but is updated in didRenderElement
     */
    numToShow: 0,

    /**
     * items that should be displayed in the view
     */
    visibleItems: (0, _object.computed)(function () {
      return [];
    }),

    /**
     * when new items are added or numToShow updates,
     * we check to see if we can just append to the visibleItems
     * array (which is *much* more performant). If it appears that
     * the items are no longer in sync, we use a failsafe reset
     * that will cause the view to rerender the list
     */
    _itemsObserver: (0, _object.observer)('items.[]', 'numToShow', function () {
      (0, _runloop.once)(this, '_syncVisibleItems');
    }),

    _syncVisibleItems() {
      const lastIndex = this.numToShow;
      const visibleLength = this.visibleItems.length;
      const anticipatedItems = this.items.slice(0, visibleLength); // converting to strings appears to be the only way to check equivalence

      const isInSync = this.visibleItems.join() === anticipatedItems.join();

      if (isInSync) {
        const itemsToShow = this.items.slice(visibleLength, lastIndex);
        this.visibleItems.addObjects(itemsToShow);
      } else {
        this.visibleItems.setObjects(this.items.slice(0, lastIndex));
      }
    },

    /**
     * Attempt to add another batch of elements once we can see
     * the last `paddingElements` child or the last child
     *
     */
    didRenderElement() {
      if (this.infiniteScroll) {
        this._inViewWatcher();
      }

      this._addBatch();
    },

    _inViewWatcher() {
      const paddingElements = Math.round(this.batchSize * 0.5);
      const selector = `.activity-item:nth-last-child(${paddingElements}), .activity-item:last-of-type`;
      (0, _jquery.default)(this.element).on('inview', selector, (event, isInView) => {
        if (isInView) {
          this._addBatch();
        }
      });
    },

    /**
     * add a new batch of content based on batchSize if necessary
     */
    _addBatch() {
      const items = this.items;
      const visibleItems = this.visibleItems;

      if (visibleItems.length < items.length) {
        this.incrementProperty('numToShow', this.batchSize);
      }
    },

    willDestroyElement() {
      this._inViewUnWatcher();

      this._super(...arguments);
    },

    _inViewUnWatcher() {
      (0, _jquery.default)(this.element).off('inview');
    }

  });

  _exports.default = _default;
});