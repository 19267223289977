define("dashboard/routes/protected/install-github-app", ["exports", "@ember/routing/route", "dashboard/mixins/document-title", "@ember/utils", "@ember/object"], function (_exports, _route, _documentTitle, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    queryParams: {
      installation_id: {
        replace: true
      }
    },
    documentTitle: 'Install the GitHub App',

    model(params) {
      const installationId = params.installation_id;

      if ((0, _utils.isEmpty)(installationId)) {
        return this.replaceWith('apps');
      }

      const pendingInstallation = this.store.queryRecord('pending-github-installation', {}).catch(() => {});
      pendingInstallation.then(installation => {
        if (installation && installation.id) {
          const appInstallationProperties = {
            herokuResourceId: installation.herokuResourceId,
            herokuResourceType: installation.herokuResourceType,
            installationId
          };
          return this.createGithubAppInstallation(installation.redirectPath, appInstallationProperties).finally(() => {
            installation.destroyRecord();
          });
        } else {
          return this.replaceWith('apps');
        }
      });
    },

    createGithubAppInstallation(redirectPath, appInstallationProperties) {
      redirectPath = redirectPath || 'apps';
      const githubAppInstallation = this.store.createRecord('github-app-installation', appInstallationProperties);
      return githubAppInstallation.save().then(() => {
        (0, _object.get)(this, 'flashMessages').success('Github App successfully installed', {
          title: 'Github App Installation'
        });
        this.replaceWith(redirectPath);
      }).catch(() => {
        (0, _object.get)(this, 'flashMessages').danger('Something went wrong with the Github App Installation', {
          title: 'Github App Installation'
        });
        githubAppInstallation.destroyRecord();
      });
    }

  });

  _exports.default = _default;
});