define("dashboard/serializers/space/peering", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'pcx_id',

    serialize(snapshot) {
      const payload = {
        pcx_id: snapshot.record.pcxId
      };
      return payload;
    }

  });

  _exports.default = _default;
});