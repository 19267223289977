define("dashboard/models/region", ["exports", "@ember-data/model", "dashboard/mixins/deploy-target"], function (_exports, _model, _deployTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_deployTarget.default, {
    /**
     * Space regions are defined by `private_capable` == true
     * Cedar regions are defined by `private_capable` == false
     */
    privateCapable: (0, _model.attr)('boolean'),
    country: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    description: (0, _model.attr)('string'),
    locale: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    provider: (0, _model.attr)()
  });

  _exports.default = _default;
});