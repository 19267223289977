define("dashboard/components/app/settings/ssl-setup", ["exports", "@ember/component", "@ember/service", "@ember/object", "dashboard/utils/pluralize", "@ember/string", "dashboard/mixins/multi-step-flow"], function (_exports, _component, _service, _object, _pluralize, _string, _multiStepFlow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    filterBy,
    gt
  } = _object.computed;

  var _default = _component.default.extend(_multiStepFlow.default, {
    classNames: ['ssl-setup'],
    analytics: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    showSetupSlidePanel: false,
    endpoints: alias('app.endpoints'),
    savedEndpoints: filterBy('endpoints', 'isNew', false),
    savedEndpoint: alias('savedEndpoints.firstObject'),
    hasFailingDomains: gt('failedOrFailingDomains.length', 0),
    acmStatusDescription: (0, _object.computed)('app.acm', 'customDomains.@each.acmStatus', 'failedOrFailingDomains', function () {
      const customDomains = this.customDomains || [];
      const failedOrFailingDomains = this.failedOrFailingDomains;

      if (customDomains.length === 0) {
        return '. Add a custom domain to your app.';
      } else if (failedOrFailingDomains.length > 0) {
        return (0, _string.htmlSafe)(` but <span class='red b'> ${failedOrFailingDomains.length} ${(0, _pluralize.default)(failedOrFailingDomains.length, 'domain', 'domains')} failed validation.</span>`);
      } else {
        return '.';
      }
    }),
    actions: {
      closeSetupPanel() {
        this.set('showSetupSlidePanel', false);
      },

      configure(endpoint) {
        this.analytics.logEvent('SSL Configuration Modal', 'Opened');
        this.set('endpoint', endpoint);
        this.set('showSetupSlidePanel', true);
      }

    }
  });

  _exports.default = _default;
});