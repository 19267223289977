define("dashboard/utils/addon-data-massaging", ["exports", "@ember/object", "rsvp"], function (_exports, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.reopenClass({
    allAttachmentsAddons(attachments) {
      return _rsvp.default.all(attachments.map(attachment => {
        return attachment.get('addon');
      })).then(allAddons => {
        return allAddons.uniqBy('id');
      });
    },

    gatherRelevantServices() {
      return gatherRelevantServices(...arguments);
    },

    servicePlansSetup(allAddons, store) {
      const relevantServicesPromise = gatherRelevantServices(allAddons);
      return relevantServicesPromise.then(relevantServices => {
        return _rsvp.default.all(relevantServices.invoke('fetchServicePlans', store));
      });
    },

    massageAttachments(attachments) {
      // this must happen after full addonService models have been associated
      markAttachedAddons(attachments);
    },

    markAttachedAddons() {
      markAttachedAddons(...arguments);
    }

  });
  /**
   * If an addon is attached to the same app multiple times, all occurrences after the first are considered "attached"
   */


  _exports.default = _default;

  function markAttachedAddons(attachments) {
    const billedAddonIds = [];
    attachments.forEach(attachment => {
      if (billedAddonIds.indexOf((0, _object.get)(attachment, 'addon.id')) >= 0) {
        (0, _object.set)(attachment, 'isNotFirstInstanceOnThisApp', true);
      } else {
        (0, _object.set)(attachment, 'isNotFirstInstanceOnThisApp', false);
      }

      billedAddonIds.push((0, _object.get)(attachment, 'addon.id'));
    });
  }

  function gatherRelevantServices(addons) {
    return _rsvp.default.all(addons.map(addon => {
      return addon.get('addonService');
    }).uniqBy('id'));
  }
});