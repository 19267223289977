define("dashboard/templates/components/enterprise-account/usage-disclaimer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hqC1Ch3U",
    "block": "[[[10,0],[14,0,\"hk-message--warning flex mt3\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"Note: While we strive to provide the most accurate usage information, the data presented here may not reflect license usage or billing for your account.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/enterprise-account/usage-disclaimer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});