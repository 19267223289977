define("dashboard/templates/components/verify-account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ytUbijID",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,0,[\"modelPromise\"]]],null]],null],[[[41,[28,[37,3],[[30,0,[\"isOrgVerification\"]]],null],[[[1,\"    \"],[8,[39,4],null,[[\"@showSlidePanel\",\"@title\",\"@close\",\"@onVerified\",\"@willCharge\",\"@warningMessage\",\"@paymentMethod\",\"@hasNextStep\"],[true,[30,0,[\"title\"]],[30,0,[\"onClose\"]],[30,0,[\"onVerified\"]],[30,0,[\"willCharge\"]],[30,0,[\"message\"]],[30,0,[\"model\"]],[30,0,[\"nextStep\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,5],null,[[\"showSlidePanel\",\"title\",\"close\",\"onVerified\",\"paymentMethod\",\"willCharge\",\"useTeamEndpoint\",\"organization\",\"warningMessage\",\"hasNextStep\"],[true,[30,0,[\"title\"]],[30,0,[\"onClose\"]],[30,0,[\"onVerified\"]],[30,0,[\"model\"]],[30,0,[\"willCharge\"]],true,[30,0,[\"organization\"]],[30,0,[\"message\"]],[30,0,[\"nextStep\"]]]]]],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"not\",\"is-pending\",\"stripe-form-enablement\",\"payment-method/stripe-form\",\"payment-method/form\"]]",
    "moduleName": "dashboard/templates/components/verify-account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});