define("dashboard/helpers/moment-format", ["exports", "@ember/component/helper", "moment"], function (_exports, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.momentFormat = momentFormat;

  /**
   * This helper is a simple reproduction of what ember-moment providers
   * so that we can avoid importing that entire addon for just this simple function
   * https://github.com/stefanpenner/ember-moment/blob/05e25c851ee5ffe72b9fea82fbd5240f34d436d2/addon/helpers/moment-format.js
   */
  function momentFormat(_ref) {
    let [date, format] = _ref;

    if (format && format.indexOf('UTC') !== -1) {
      return _moment.default.utc(date).format(format);
    }

    return (0, _moment.default)(date).format(format);
  }

  var _default = (0, _helper.helper)(momentFormat);

  _exports.default = _default;
});