define("dashboard/components/metrics/app-guidance", ["exports", "@ember/component", "@ember/object", "moment", "dashboard/utils/metrics/metrics-data-operators", "@ember/object/computed", "dashboard/utils/metrics/data-fetch"], function (_exports, _component, _object, _moment, _metricsDataOperators, _computed, _dataFetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'metrics-app-guidance',
    classNames: ['metrics__app-guidance'],
    isVisible: (0, _computed.or)('isShowingActiveAlerts', 'isShowingTimeoutErrors', 'isShowingPerformanceDegradation', 'isShowingMemoryErrors', 'jvmMetricsAreSetUpAndNotWorking', 'app.shouldDisableRuntimeMetricsBuildpack', 'needsToSetupPumaMetrics'),
    activeAlerts: (0, _computed.filterBy)('alerts', 'isActive', true),
    isShowingActiveAlerts: (0, _computed.notEmpty)('activeAlerts'),
    isShowingTimeoutErrors: (0, _computed.gt)('routerErrors.numberOfTimeouts', 0),
    isShowingPerformanceDegradation: false,
    // Temporarily disabled until we rethink the feature
    isShowingMemoryErrors: (0, _computed.gt)('dynoErrors.memoryErrorCount', 0),
    _isNotNewApp: (0, _object.computed)('app.createdAt', function () {
      const createdAt = (0, _moment.default)(this.get('app.createdAt'));
      const existenceDays = (0, _moment.default)().diff(createdAt, 'd');
      return existenceDays >= 7;
    }),
    _isWebAndTimeframeEligibleForDegradation: (0, _computed.and)('process.isWeb', 'last24hoursSelected'),
    latencyPast: (0, _dataFetch.queryProcessData)('metrics/router-latency', {
      isPreviousWeek: true
    }, '_isWebAndTimeframeEligibleForDegradation'),
    meanMedianChangeRate: (0, _metricsDataOperators.changeRateOfKey)('latency.ms.p50', 'latency.rawData', 'latencyPast.rawData'),
    meanPerc95ChangeRate: (0, _metricsDataOperators.changeRateOfKey)('latency.ms.p95', 'latency.rawData', 'latencyPast.rawData'),
    significantPercChangeLimit: 15,
    hasSignificantPerc95Change: (0, _object.computed)('significantPercChangeLimit', 'meanPerc95ChangeRate', function () {
      return this.meanPerc95ChangeRate >= this.significantPercChangeLimit;
    }),
    hasSignificantMedianChange: (0, _object.computed)('significantPercChangeLimit', 'meanMedianChangeRate', function () {
      return this.meanMedianChangeRate >= this.significantPercChangeLimit;
    }),
    _hasSignificantChange: (0, _computed.or)('hasSignificantMedianChange', 'hasSignificantPerc95Change'),
    dynoErrors: (0, _computed.readOnly)('refreshingDynoErrors'),
    routerErrors: (0, _computed.readOnly)('refreshingRouterErrors'),
    needsToSetupPumaMetrics: (0, _computed.and)('app.hasRubyMetricsEnabled', 'usingPumaButNoMeasuredData'),
    notReceivingJvmDataLinks: (0, _object.computed)(function () {
      return [{
        href: 'https://devcenter.heroku.com/articles/language-runtime-metrics-jvm#getting-started',
        text: 'JVM Metrics Setup',
        isVisible: true
      }, {
        href: 'https://status.heroku.com/',
        text: 'Heroku Status',
        isVisible: true
      }];
    })
  });

  _exports.default = _default;
});