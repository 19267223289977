define("dashboard/components/enterprise-account/audit-trail-archives", ["exports", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    sortingKeys: ['year:desc', 'month:desc'],
    sortedArchives: (0, _computed.sort)('auditTrailArchives', 'sortingKeys'),
    actions: {
      submit() {
        this.analytics.logEvent('Enterprise Account Audit Log', 'Downloaded');
        const selectedArchive = this.selectedArchive;
        return selectedArchive.reload().then(archive => {
          document.getElementById('audit-trail-download').src = archive.get('url');
        });
      }

    }
  });

  _exports.default = _default;
});