define("dashboard/components/team/enterprise-no-account-banner", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed"], function (_exports, _component, _service, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    // --------------------------------------------------------------------------
    // Services
    analytics: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Arguments
    team: null,
    // --------------------------------------------------------------------------
    // Computed Props
    teamIsEnterprise: (0, _computed.readOnly)('team.isEnterpriseTeam'),
    // We aren't using `team.hasEnterpriseAccount` here because that assumes the EA
    // was able to load. But, in the case where a user is a collaborator on the team
    // but is not a member of the account, the EA won't be loaded and that property
    // will be `false`. So we're checking if there is an EA like this.
    teamHasEnterpriseAccount: (0, _computed.bool)('team.enterpriseAccount.id'),
    teamDoesNotHaveEnterpriseAccount: (0, _computed.not)('teamHasEnterpriseAccount'),
    showBanner: (0, _computed.and)('teamIsEnterprise', 'teamDoesNotHaveEnterpriseAccount'),
    learnMoreLink: (0, _object.computed)('showAltBanner', function () {
      return 'https://devcenter.heroku.com/articles/enterprise-accounts-ga#organizations-with-complex-cases';
    }),

    // --------------------------------------------------------------------------
    // Actions
    learnMoreAboutEnterpriseAccounts() {
      this.analytics.logEvent('Enterprise Account Enrollment Banner Learn More Link', 'Clicked');
    }

  });

  _exports.default = _default;
});