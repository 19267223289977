define("dashboard/routes/app/webhooks", ["exports", "@ember/service", "rsvp", "dashboard/routes/basic-app"], function (_exports, _service, _rsvp, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model() {
      const {
        app
      } = this.modelFor('app');
      return (0, _rsvp.hash)({
        app,
        webhooks: app.hasMany('webhooks').reload()
      });
    },

    afterModel() {
      this.analytics.logEvent('Webhooks Page', 'Viewed');
    },

    actions: {
      createWebhook() {
        this.transitionTo('app.webhooks.new');
      }

    }
  });

  _exports.default = _default;
});