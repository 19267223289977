define("dashboard/models/status/unresolved-incident", ["exports", "dashboard/models/status/incident"], function (_exports, _incident) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _incident.default.extend({});

  _exports.default = _default;
});