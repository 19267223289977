define("dashboard/components/account/profile-email", ["exports", "rsvp", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _rsvp, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'profile-email',
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    email: (0, _computed.alias)('current.account.email'),
    password: (0, _computed.alias)('current.account.password'),
    showConfirmationModal: false,
    isEditingEmail: false,
    actions: {
      edit() {
        this.set('emailDidChange', false);
      },

      showConfirm(updatedEmail) {
        this.set('updatedEmail', updatedEmail);
        this.set('showConfirmationModal', true);
        return (0, _rsvp.reject)();
      },

      confirmSave() {
        const model = this.get('current.account');
        const oldEmail = this.get('current.account.email');
        model.set('email', this.updatedEmail);
        return model.save().then(() => {
          this.analytics.logEvent('Account Email', 'Changed');
          this.set('isEditingEmail', false);
          this.set('emailDidChange', true);
          this.set('updatedEmail', null);
          this.set('showConfirmationModal', false);
        }).catch(() => {
          model.set('email', oldEmail);
        });
      }

    }
  });

  _exports.default = _default;
});