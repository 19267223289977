define("dashboard/controllers/account/applications/edit-authorization", ["exports", "@ember/controller", "@ember/object", "ember-changeset", "moment", "dashboard/validations/authorization", "ember-changeset-validations", "@ember/service"], function (_exports, _controller, _object, _emberChangeset, _moment, _authorization, _emberChangesetValidations, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    analytics: (0, _service.inject)(),
    changeset: (0, _object.computed)('model', function () {
      const changeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(_authorization.default), _authorization.default, {
        skipValidate: true
      });
      return changeset;
    }),
    authorizationExpiresAt: (0, _object.computed)('model.accessToken', function () {
      const currentAuthorization = this.model;
      const expiresIn = currentAuthorization.get('accessToken.expires_in');

      if (expiresIn === null) {
        return 'Never';
      } else {
        return (0, _moment.default)().add(expiresIn, 's');
      }
    }),
    actions: {
      hideEditNonAppAuthorization() {
        this.set('isConfirmingDelete', false);
        this.changeset.rollback();
        this.transitionToRoute('account.applications');
      },

      saveAuthorization(dismiss) {
        const changeset = this.changeset;
        return changeset.validate().then(() => {
          if (changeset.get('isValid')) {
            return changeset.save().then(() => {
              this.analytics.logEvent('Authorization', 'Updated');
              dismiss();
            });
          }
        });
      },

      deleteAuthorization(authorization, dismiss) {
        return authorization.destroyRecord().then(() => {
          this.analytics.logEvent('Authorization', 'Deleted');
          this.set('isConfirmingDelete', false);
          dismiss();
        });
      },

      regenerateToken(authorization) {
        return authorization.regenerate().then(() => {
          this.analytics.logEvent('Authorization', 'Rotated');
        });
      }

    }
  });

  _exports.default = _default;
});