define("dashboard/templates/components/soft-notification", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RaNyAMml",
    "block": "[[[10,0],[14,0,\"lh-copy flex items-center\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"hk-button-sm--secondary notification-hide ml2\"],[4,[38,1],[[30,0],\"hide\"],null],[12],[1,\"\\n    Hide\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showActionButton\"]],[[[1,\"    \"],[11,\"button\"],[24,0,\"hk-button-sm--primary notification-action ml2\"],[4,[38,1],[[30,0],[30,0,[\"doAction\"]]],null],[12],[1,\"\\n      \"],[1,[30,0,[\"actionText\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"action\",\"if\"]]",
    "moduleName": "dashboard/templates/components/soft-notification.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});