define("dashboard/adapters/app/feature", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForUpdateRecord(id, modelName, snapshot) {
      // unmunge the composite id we created when serializing this record
      const featureId = id.split('/')[0];
      const appId = snapshot.belongsTo('app').id;
      return `${this.host}/apps/${appId}/features/${featureId}`;
    },

    updateRecord(store, type, snapshot) {
      const {
        id
      } = snapshot;
      return this._super(...arguments).then(payload => {
        payload.id = id;
        return payload;
      });
    }

  });

  _exports.default = _default;
});