define("dashboard/components/app/collaborators-list-item", ["exports", "@ember/object", "@ember/utils", "@ember/service", "dashboard/components/purple-list-row"], function (_exports, _object, _utils, _service, _purpleListRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    or
  } = _object.computed;

  var _default = _purpleListRow.default.extend({
    item: alias('collaborator'),
    classNames: ['collaborator-item'],
    'data-test-target': (0, _object.computed)('collaborator.email', function () {
      const collaboratorEmail = this.get('collaborator.email');
      return `collaborator-${collaboratorEmail}`;
    }),
    current: (0, _service.inject)(),
    canRemove: or('canRemoveDirectMember', 'isThyself'),
    memberPermissions: alias('collaborator.toggleablePermissions'),
    role: (0, _object.computed)('collaborator.role', function () {
      const role = this.get('collaborator.role');
      return (0, _utils.isPresent)(role) ? role : 'collaborator';
    }),
    isThyself: (0, _object.computed)('current.account.email', 'collaborator.user.email', function () {
      return this.get('current.account.email') === this.get('collaborator.user.email');
    }),
    actions: {
      selectPermission(permToSelect) {
        this.selectPermission(permToSelect);
      },

      deselectPermission(permToRemove) {
        this.deselectPermission(permToRemove);
      }

    }
  });

  _exports.default = _default;
});