define("dashboard/components/pipeline/kolkrabbi-app-setup-status", ["exports", "@ember/service", "@ember/component", "@ember/object/computed"], function (_exports, _service, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    currentBuild: (0, _computed.readOnly)('app.currentBuild'),
    hasNoCurrentRelease: (0, _computed.not)('app.currentRelease'),
    store: (0, _service.inject)(),

    subscribeToAppSetup() {
      const model = this.githubReviewApp;
      model.subscribeToAppSetup();
      model.on('appSetupUpdated', this, () => {
        this.fetchGithubReviewApps();
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.subscribeToAppSetup();
    },

    willDestroyElement() {
      this.githubReviewApp.unsubscribeFromAppSetup();

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});