define("dashboard/components/drop-down", ["exports", "@ember/runloop", "@ember/component", "@ember/utils", "@ember/service", "@ember/object", "@ember/object/computed", "jquery"], function (_exports, _runloop, _component, _utils, _service, _object, _computed, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DROPDOWN_CLICK_EVENT = 'dropdown:click';

  var _default = _component.default.extend({
    classNameBindings: [':btn-group', 'isFlexButton:flex'],
    buttonClass: 'btn btn-default',
    menuClass: '',
    isShowingDropdown: false,
    hasCaret: false,
    tooltipTitle: null,
    tooltipOptions: (0, _object.computed)(function () {
      return {
        trigger: 'hover',
        container: 'body'
      };
    }),
    analytics: (0, _service.inject)(),
    buttonClassNames: (0, _object.computed)('buttonClass', function () {
      return `drop-down__toggle ${this.buttonClass}`;
    }),
    buttonDisabled: false,
    buttonContent: '',
    buttonLabel: (0, _computed.reads)('tooltipTitle'),
    buttonExpandedLabel: (0, _computed.reads)('buttonLabel'),
    menuClassNames: (0, _object.computed)('menuClass', function () {
      return `drop-down__menu ${this.menuClass}`;
    }),
    closeFunction: (0, _object.computed)(function () {
      return ev => {
        // Wrap this in a run loop to avoid issues with async actions happening while the dropdown is trying to close
        (0, _runloop.next)(this, () => {
          const stillExists = !this.isDestroyed && !this.isDestroying;
          const targetIsNotThis = ev.target !== this.button;
          const isShowingDropdown = this.isShowingDropdown;
          const formElements = ['INPUT', 'SELECT', 'LABEL'];
          const isFormElement = formElements.includes(ev.target.tagName);
          const isWithinComponent = this.element && this.element.contains(ev.target); // If the click is inside this dropdown AND it's a form element,
          // then keep the dropdown open

          const isNotContainedFormElement = !(isFormElement && isWithinComponent);

          if (stillExists && targetIsNotThis && isShowingDropdown && isNotContainedFormElement) {
            this.set('isShowingDropdown', false);
          }
        });
      };
    }),
    button: (0, _object.computed)(function () {
      return (0, _jquery.default)(this.element).find('.drop-down__toggle')[0];
    }),

    didInsertElement() {
      this._super(...arguments);

      const $body = (0, _jquery.default)(document.body);
      const close = this.closeFunction;

      const wrappedClose = (ev, data) => close(data);

      $body.on('click', close);
      $body.on(DROPDOWN_CLICK_EVENT, wrappedClose);
      this.on('willDestroyElement', () => {
        $body.off('click', close);
        $body.off(DROPDOWN_CLICK_EVENT, wrappedClose);
      });
    },

    /**
     * Called after the menu is rendered. The default implementation is a no-op.
     * This method is here purely so it can be overridden.
     * @method didRenderMenu
     * @public
     */
    didRenderMenu() {// This method intentionally left blank.
    },

    willDestroyElement() {
      this.set('button', null);

      this._super(...arguments);
    },

    actions: {
      toggle() {
        (0, _jquery.default)(document.body).trigger(DROPDOWN_CLICK_EVENT, {
          target: this.button
        });
        this.toggleProperty('isShowingDropdown');
        const eventDescription = this.analyticsEventDescription;
        const dropdownAction = this.isShowingDropdown ? 'Revealed' : 'Hidden';

        if ((0, _utils.isPresent)(eventDescription)) {
          this.analytics.logEvent(`${eventDescription} Dropdown`, dropdownAction);
        }

        if (this.isShowingDropdown) {
          (0, _runloop.schedule)('afterRender', this, 'didRenderMenu');
        }
      }

    }
  });

  _exports.default = _default;
});