define("dashboard/components/account/invoice-address", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/models/country", "dashboard/models/state"], function (_exports, _component, _object, _computed, _service, _country, _state) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    sameAsBilling: (0, _computed.not)('invoiceAddress.useInvoiceAddress'),
    isEditing: false,
    countries: _country.default.all(),
    states: _state.default.all(),
    selectedCountry: (0, _object.computed)('invoiceAddress.country', function () {
      return _country.default.findByAbbrev(this.get('invoiceAddress.country'));
    }),
    countryName: (0, _computed.alias)('selectedCountry.name'),
    countryIsUs: (0, _computed.equal)('invoiceAddress.country', 'US'),
    actions: {
      edit() {
        this.set('invoiceAddress.useInvoiceAddress', true);
        this.set('isEditing', true);
      },

      cancelEdit() {
        this.invoiceAddress.rollbackAttributes();
        this.set('isEditing', false);
      },

      useBillingAddress() {
        this.set('invoiceAddress.useInvoiceAddress', false);
        this.send('save');
      },

      save() {
        return this.invoiceAddress.save().then(() => {
          this.analytics.logEvent('Invoice Address', 'Changed');
          this.set('isEditing', false);
        });
      },

      selectCountry(selectedCountry) {
        // a change to/from US as the country will trigger us showing/hiding
        // the state dropdown, so in those cases we want to clear our the value
        // in that field so that we can prompt the user to pick something
        if (this.get('invoiceAddress.country') === 'US' || selectedCountry === 'US') {
          this.set('invoiceAddress.state', null);
        }

        this.set('invoiceAddress.country', selectedCountry);
      }

    }
  });

  _exports.default = _default;
});