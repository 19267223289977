define("dashboard/helpers/anchorify", ["exports", "@ember/component/helper", "dashboard/utils/anchorify"], function (_exports, _helper, _anchorify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.anchorifyHelper = anchorifyHelper;
  _exports.default = void 0;

  function anchorifyHelper(_ref
  /* , hash*/
  ) {
    let [input] = _ref;
    return (0, _anchorify.default)(input);
  }

  var _default = (0, _helper.helper)(anchorifyHelper);

  _exports.default = _default;
});