define("dashboard/components/pipeline/access/collaborator", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _component, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class2, _descriptor, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class4, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _dec10, _dec11, _dec12, _dec13, _dec14, _class6, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _dec15, _class8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_dec15 = (0, _object.computed)('isAdmin', 'isDefaultPlaceholder', 'team', 'pipeline', 'teamMembers', 'collaborator', 'isPersonalPipeline'), (_class8 = class _class8 extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "supportsDataTestProperties", true);
    }

    get permissionSet() {
      const {
        team,
        pipeline,
        teamMembers,
        collaborator,
        isPersonalPipeline
      } = this;
      let PermissionSetClass;

      if (this.isDefaultPlaceholder) {
        PermissionSetClass = DefaultPlaceholder;
      } else if (this.isAdmin) {
        PermissionSetClass = AdminPermissionSet;
      } else {
        PermissionSetClass = CollaboratorPermissionSet;
      }

      return PermissionSetClass.create({
        collaborator,
        pipeline,
        team,
        teamMembers,
        isPersonalPipeline
      });
    }

  }, (_applyDecoratedDescriptor(_class8.prototype, "permissionSet", [_dec15], Object.getOwnPropertyDescriptor(_class8.prototype, "permissionSet"), _class8.prototype)), _class8));

  _exports.default = _class;
  let AdminPermissionSet = (_dec = (0, _computed.readOnly)('collaborator.email'), (_class2 = class AdminPermissionSet extends _object.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "email", _descriptor, this);

      _defineProperty(this, "role", 'admin');

      _defineProperty(this, "view", true);

      _defineProperty(this, "operate", true);

      _defineProperty(this, "manage", true);

      _defineProperty(this, "deploy", true);

      _defineProperty(this, "edit", true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "email", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2));
  let CollaboratorPermissionSet = (_dec2 = (0, _computed.readOnly)('collaborator.email'), _dec3 = (0, _object.computed)('teamMembers.@each.role', 'teamMembers.@each.email', 'collaborator.email'), _dec4 = (0, _computed.readOnly)('_permissionMap.view'), _dec5 = (0, _computed.readOnly)('_permissionMap.operate'), _dec6 = (0, _computed.readOnly)('_permissionMap.deploy'), _dec7 = (0, _computed.readOnly)('collaborator.permitsByName.manage.enabled'), _dec8 = (0, _computed.or)('team.isAdmin', 'isPersonalPipeline'), _dec9 = (0, _object.computed)('collaborator.permitsByName', 'team.isNotEnterpriseTeam'), (_class4 = class CollaboratorPermissionSet extends _object.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "isDefaultPlaceholder", false);

      _initializerDefineProperty(this, "email", _descriptor2, this);

      _initializerDefineProperty(this, "view", _descriptor3, this);

      _initializerDefineProperty(this, "operate", _descriptor4, this);

      _initializerDefineProperty(this, "deploy", _descriptor5, this);

      _initializerDefineProperty(this, "manage", _descriptor6, this);

      _initializerDefineProperty(this, "edit", _descriptor7, this);
    }

    get role() {
      const teamMembers = this.teamMembers || [];
      const matchedTeamMember = teamMembers.findBy('email', this.collaborator.get('email'));
      return (0, _utils.isPresent)(matchedTeamMember) ? matchedTeamMember.get('role') : 'collaborator';
    }

    get _permissionMap() {
      return Object.keys(this.collaborator.permitsByName).reduce((memo, permission) => {
        memo[permission] = this.get('team.isNotEnterpriseTeam') || this.collaborator.permitsByName[permission].enabled;
        return memo;
      }, {});
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class4.prototype, "email", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class4.prototype, "role", [_dec3], Object.getOwnPropertyDescriptor(_class4.prototype, "role"), _class4.prototype), _descriptor3 = _applyDecoratedDescriptor(_class4.prototype, "view", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class4.prototype, "operate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class4.prototype, "deploy", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class4.prototype, "manage", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class4.prototype, "edit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class4.prototype, "_permissionMap", [_dec9], Object.getOwnPropertyDescriptor(_class4.prototype, "_permissionMap"), _class4.prototype)), _class4));
  let DefaultPlaceholder = (_dec10 = (0, _computed.readOnly)('_permissionMap.operate'), _dec11 = (0, _computed.readOnly)('_permissionMap.deploy'), _dec12 = (0, _computed.readOnly)('pipeline.collaboratorPermissionsByName.manage'), _dec13 = (0, _computed.and)('team.isAdmin', 'team.isEnterpriseTeam'), _dec14 = (0, _object.computed)('pipeline.collaboratorPermissionsByName', 'team.isNotEnterpriseTeam'), (_class6 = class DefaultPlaceholder extends _object.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "isDefaultPlaceholder", true);

      _defineProperty(this, "email", 'default');

      _defineProperty(this, "role", '');

      _defineProperty(this, "view", true);

      _initializerDefineProperty(this, "operate", _descriptor8, this);

      _initializerDefineProperty(this, "deploy", _descriptor9, this);

      _initializerDefineProperty(this, "manage", _descriptor10, this);

      _initializerDefineProperty(this, "edit", _descriptor11, this);
    }

    get _permissionMap() {
      return Object.keys(this.pipeline.collaboratorPermissionsByName).reduce((memo, permission) => {
        memo[permission] = this.get('team.isNotEnterpriseTeam') || this.pipeline.collaboratorPermissionsByName[permission];
        return memo;
      }, {});
    }

  }, (_descriptor8 = _applyDecoratedDescriptor(_class6.prototype, "operate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class6.prototype, "deploy", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class6.prototype, "manage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class6.prototype, "edit", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class6.prototype, "_permissionMap", [_dec14], Object.getOwnPropertyDescriptor(_class6.prototype, "_permissionMap"), _class6.prototype)), _class6));
});