define("dashboard/controllers/pipelines/pipeline/index/review-apps", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@ember/object/computed", "ember-concurrency"], function (_exports, _controller, _service, _object, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    accountFeatures: (0, _service.inject)(),
    appJSON: (0, _computed.readOnly)('loadAppJSON.last.value'),
    isLoadingAppJSON: (0, _object.computed)('loadAppJSON.isRunning', 'model.pipeline.connectedRepository.isPending', function () {
      let isLoading = this.get('loadAppJSON.isRunning');

      if (this.get('model.pipeline.isGithubAppEnabled')) {
        isLoading = isLoading || this.get('model.pipeline.connectedRepository.isPending');
      }

      return isLoading;
    }),
    loadAppJSON: (0, _emberConcurrency.task)(function* () {
      if (this.get('model.pipeline.isGithubAppEnabled')) {
        return yield this.loadAppJSONFromReposApi.perform();
      } else {
        return this.model.appJSON;
      }
    }).restartable(),
    loadAppJSONFromReposApi: (0, _emberConcurrency.task)(function* () {
      const repoName = this.get('model.pipeline.connectedRepository.name');
      const owner = this.get('model.pipeline.connectedRepository.ownerName');

      if (repoName && owner) {
        return yield this.store.queryRecord('repositories-api-app-manifest', {
          repoName,
          owner,
          isGithubAppEnabled: true
        });
      }

      const app = yield this.get('model.pipeline.apps.firstObject');
      const connectedRepoName = app.get('connectedRepository.name');
      const connectedRepoOwner = app.get('connectedRepository.ownerName');

      if (connectedRepoName && connectedRepoOwner) {
        return yield this.store.queryRecord('repositories-api-app-manifest', {
          repoName: connectedRepoName,
          owner: connectedRepoOwner,
          isGithubAppEnabled: true
        });
      }
    }).restartable(),
    appJSONSourceDidChange: (0, _object.observer)('model.appJSON', 'model.pipeline.connectedRepository.{name,ownerName}', 'model.pipeline.apps.firstObject.connectedRepository.{name,ownerName}', function () {
      this.loadAppJSON.perform();
    })
  });

  _exports.default = _default;
});