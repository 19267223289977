define("dashboard/models/metrics/dyno-memory", ["exports", "@ember/object/computed", "@ember/object", "dashboard/models/metrics", "dashboard/utils/metrics/aggregate-values", "dashboard/utils/metrics/unit-converters", "dashboard/utils/metrics/metrics-data-operators", "dashboard/utils/metrics/latest-non-zero-value"], function (_exports, _computed, _object, _metrics, _aggregateValues, _unitConverters, _metricsDataOperators, _latestNonZeroValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;

  var _default = _metrics.default.extend({
    swapMemoryMaxBytes: rawDataWithDefault('memory.swap.bytes.max'),
    rssMemoryMaxBytes: rawDataWithDefault('memory.rss.bytes.max'),
    memoryQuotaMaxBytes: rawDataWithDefault('memory.quota.bytes.max'),
    memoryTotalMaxBytes: rawDataWithDefault('memory.swap-plus-rss.bytes.max'),
    memoryTotalMeanBytes: rawDataWithDefault('memory.swap-plus-rss.bytes.mean'),
    pagesInMax: rawDataWithDefault('memory.pages.in.max'),
    pagesInMean: rawDataWithDefault('memory.pages.in.mean'),
    pagesOutMax: rawDataWithDefault('memory.pages.out.max'),
    pagesOutMean: rawDataWithDefault('memory.pages.out.mean'),
    pageDifferenceMax: (0, _metricsDataOperators.dataDifference)('pagesInMax', 'pagesOutMax'),
    swapMemoryMax: (0, _unitConverters.convertToMB)('swapMemoryMaxBytes'),
    rssMemoryMax: (0, _unitConverters.convertToMB)('rssMemoryMaxBytes'),
    memoryQuotaMax: (0, _unitConverters.convertToMB)('memoryQuotaMaxBytes'),
    memoryTotalMax: (0, _unitConverters.convertToMB)('memoryTotalMaxBytes'),
    memoryTotalMean: (0, _unitConverters.convertToMB)('memoryTotalMeanBytes'),
    maxPercentageUsedData: (0, _metricsDataOperators.dataPercentage)('memoryTotalMax.[]', 'memoryQuotaMax.[]'),
    maxMemoryTotalMaxDatum: (0, _aggregateValues.maxDatum)('memoryTotalMax'),
    maxMemoryTotalMaxTime: (0, _computed.alias)('maxMemoryTotalMaxDatum.0'),
    maxMemoryTotalMaxValue: (0, _computed.alias)('maxMemoryTotalMaxDatum.1'),
    maxMemoryTotalMaxValuePercentage: (0, _object.computed)('maxPercentageUsedData', 'maxMemoryTotalMaxTime', function () {
      const time = this.maxMemoryTotalMaxTime;
      const datum = this.maxPercentageUsedData.findBy('0', time);
      return datum ? datum[1] : null;
    }),
    meanPercentageUsedData: (0, _metricsDataOperators.dataPercentage)('memoryTotalMean.[]', 'memoryQuotaMax.[]'),
    meanPercentageUsedMeanValue: (0, _aggregateValues.meanValue)('meanPercentageUsedData'),
    meanMemoryTotalMeanValue: (0, _aggregateValues.meanValue)('memoryTotalMean'),
    currentPercentage: (0, _latestNonZeroValue.default)('maxPercentageUsedData', false),
    currentTotal: (0, _latestNonZeroValue.default)('memoryTotalMax', false),

    /*
     * Used as a default maximum value for the memory percentage chart
     */
    maxPercentageValue: [[null, 100]]
  });

  _exports.default = _default;
});