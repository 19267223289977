define("dashboard/components/datastore/postgres/health", ["exports", "@ember/component", "@ember/object", "moment"], function (_exports, _component, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    created: (0, _object.computed)('datastore.created', function () {
      return _moment.default.utc(this.datastore.createdAt).fromNow();
    }),
    healthy: (0, _object.computed)('datastore.status', function () {
      return ['available', 'preparing', 'upgrading'].includes(this.datastore.status.toLowerCase());
    }),
    statusClass: (0, _object.computed)('healthy', function () {
      return this.healthy ? 'malibu-fill-gradient-green' : 'malibu-fill-gradient-red';
    }),
    statusIcon: (0, _object.computed)('healthy', function () {
      return this.healthy ? 'success-badge-16' : 'malibu-fill-gradient-red';
    }),
    primaryText: (0, _object.computed)('datastore.role', function () {
      return this.datastore.role === 'leader' ? 'Yes' : 'No';
    })
  });

  _exports.default = _default;
});