define("dashboard/components/metrics/time-check", ["exports", "@ember/service", "@ember/component", "@ember/object", "moment"], function (_exports, _service, _component, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a component that will show a warning message if we determine that the user's
   * system clock is outside our tolerance level.
   *
   * operates completely independently of other metrics data
   *
   */
  var _default = _component.default.extend({
    tagName: 'metrics-time-check',
    browserTime: (0, _service.inject)(),

    /**
     * the duration we will tolerate a user's
     * system clock to be out of sync
     */
    tolerance: (0, _object.computed)(function () {
      return _moment.default.duration(5, 'm');
    }),
    isOutOfSync: (0, _object.computed)('tolerance', 'browserTime.offset', function () {
      const tolerance = this.tolerance;
      const offset = this.get('browserTime.offset');

      if (offset) {
        return Math.abs(offset) >= tolerance;
      }
    })
  });

  _exports.default = _default;
});