define("dashboard/routes/application", ["exports", "@ember/routing/route", "@ember/service", "ember-simple-auth/mixins/application-route-mixin", "dashboard/mixins/resettable", "dashboard/mixins/redirect-after-auth", "dashboard/config/environment", "dashboard/utils/errors"], function (_exports, _route, _service, _applicationRouteMixin, _resettable, _redirectAfterAuth, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HerokuIdentity = _environment.default['heroku-identity'];

  var _default = _route.default.extend(_applicationRouteMixin.default, _resettable.default, _redirectAfterAuth.default, {
    location: (0, _service.inject)(),
    ignoreDelinquent: true,

    get routeAfterAuthentication() {
      return this.getRedirectTo();
    },

    routeIfAlreadyAuthenticated: 'protected',
    analytics: (0, _service.inject)(),
    errorMonitor: (0, _service.inject)(),
    reporter: (0, _service.inject)(),
    session: (0, _service.inject)(),
    loadingState: (0, _service.inject)(),
    current: (0, _service.inject)(),
    transitionMonitor: (0, _service.inject)(),

    init() {
      this._super(...arguments);

      this.transitionMonitor.start();
    },

    // ember-simple-auth delegate
    sessionAuthenticated() {
      // on login
      this._clearSpecialSessions();

      return this._super(); // allow default behavior to execute
    },

    // sessionInvalidated is triggered after the session is
    // invalidated (for instance if the authorization call fails, or we log the
    // user out).
    sessionInvalidated() {
      const userRequestedLogout = this.controllerFor('application').get('userRequestedLogout');
      this.controllerFor('application').set('userRequestedLogout', false);

      this._clearSpecialSessions();

      if (userRequestedLogout) {
        // Redirect to id.heroku.com/logout so their session can be deactivated.
        this.location.assign(HerokuIdentity.logoutUrl);
      } else {
        // Most likely we are here because the attempt to exchange a code
        // for an access_token failed, or another tab going through the auth process
        // clobbered this one since ember-simple-auth uses localStorage which is
        // shared by all tabs. We'll just restart the auth process.
        this.setRedirectTo(this.transitionMonitor.intendedPath);
        this.transitionTo('login');
      }
    },

    _clearSpecialSessions() {
      this.session.clearSudoData();
      this.session.clearPreauthData();
    },

    actions: {
      willTransition() {
        this.errorMonitor.wipe();
      },

      error(error) {
        // Do not show loading indicator when an error has prevented a transition
        this.set('loadingState.showNavLoading', false);
        const status = (0, _errors.errorStatus)(error);

        if (status === 401) {
          // User is unauthorized, have them login. We can't transition here
          // because login in a child of the application route.
          this.setRedirectTo(this.transitionMonitor.intendedPath);
          this.transitionTo('login');
        } else if (status === 402) {
          this.transitionTo('account.billing.pay-balance');
        } else {
          this.reporter.error(error);
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});