define("dashboard/templates/components/app/addons-sso-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "J1J1XY3b",
    "block": "[[[18,1,[[28,[37,1],null,[[\"webUrlForApp\",\"webUrlTarget\"],[[30,0,[\"webUrlForApp\"]],[30,0,[\"webUrlTarget\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "dashboard/templates/components/app/addons-sso-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});