define("dashboard/serializers/kolkrabbi/github-app-deploy", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      return {
        branch: snapshot.attr('branch')
      };
    },

    extractRelationships(modelClass, hash) {
      const relationships = {};
      relationships.build = {
        data: {
          id: hash.build.id,
          type: 'build'
        }
      };
      return relationships;
    },

    extractId(modelClass, resourceHash) {
      return resourceHash.build.id;
    }

  });

  _exports.default = _default;
});