define("dashboard/adapters/metrics", ["exports", "@ember-data/adapter", "@ember/service", "jquery", "@ember/object", "dashboard/config/environment", "dashboard/mixins/adapters/heroku-data-adapter-mixin", "dashboard/mixins/adapters/ajax-options", "moment"], function (_exports, _adapter, _service, _jquery, _object, _environment, _herokuDataAdapterMixin, _ajaxOptions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable ember/no-jquery */

  /* eslint-disable ember/no-mixins */

  /* eslint-disable ember/no-classic-classes */

  /** the base adapter for the new metrics-api
   *
   * makes a limited-set of calls and does not have full DS.Adapter
   * support
   */
  var _default = _adapter.default.extend(_herokuDataAdapterMixin.default, _adapter.BuildURLMixin, _ajaxOptions.default, {
    version: '3.monitoring-events',
    headers: (0, _object.computed)('version', function () {
      return {
        Accept: `application/vnd.heroku+json; version=${this.version}`,
        'X-Origin': location.origin
      };
    }),
    defaultSerializer: 'metrics',
    // timeout for ajax requests in ms
    ajaxTimeout: 9000,
    maximumRetries: 2,
    retryDelay: 200,
    session: (0, _service.inject)(),
    host: _environment.default.metricsApiUrl,
    namespace: 'metrics',
    // the title subject to use for the error monitor
    errorTitleSubject: 'Metrics Item',

    /**
     * converts a passed-in query to an object that is compatible with the host API
     *
     * this is a construct that does not exist in ember-data
     */
    normalizeQuery(query) {
      query = { ...query
      }; // we never want to send appId as a query param

      delete query.appId; // convert start_time and end_time if present

      if (query.startTime) {
        query.startTime = (0, _moment.default)(query.startTime).toISOString();
      }

      if (query.endTime) {
        query.endTime = (0, _moment.default)(query.endTime).toISOString();
      } // convert all keys to underscored


      const underscoredQuery = Object.entries(query).reduce(function (underscored, _ref) {
        let [key, value] = _ref;
        // eslint-disable-next-line ember/no-string-prototype-extensions
        underscored[key.underscore()] = value;
        return underscored;
      }, {});
      return underscoredQuery;
    },

    /**
     * constructs the request URL for a given request
     *
     * overrides DS.Adapter method
     */
    urlForQuery(rawQuery, modelName) {
      const appId = rawQuery.appId;
      const query = this.normalizeQuery(rawQuery); // encoded string of params

      const param = _jquery.default.param(query);

      let resource;

      if (this.resource) {
        resource = this.resource;
      } else {
        // infer resource name by
        // converting metrics/model-name to model/name
        resource = modelName.split('/')[1];
        resource = resource.replace('-', '/');
      }

      return `${this.host}/${this.namespace}/${appId}/${resource}?${param}`;
    },

    urlForQueryRecord() {
      return this.urlForQuery(...arguments);
    },

    /**
     * makes a query that auto-retries an ajax requests
     *
     * returns a Promise
     *
     * overrides DS.Adapter method
     */
    queryRecord(store, type, query) {
      // we allow `adapterOptions` to be passed in via `query` as
      // ember-data does not currently support doing this
      // see: https://github.com/emberjs/data/issues/4497 for updates
      query = { ...query
      };
      const adapterOptions = query.adapterOptions || {};
      delete query.adapterOptions;
      const url = this.buildURL(type.modelName, null, null, 'queryRecord', query);
      return this.makeRequest({
        url,
        suppressErrors: adapterOptions.suppressErrors
      }).then(data => {
        data.id = this._generateIdForQueryRecord(type, query);
        return data;
      });
    },

    query() {
      return this.queryRecord(...arguments);
    },

    _generateIdForQueryRecord(type, query) {
      return `${type.modelName}/${query.appId}/${query.processType}/${query.step}/${query.startTime}/${query.endTime}`;
    }

  });

  _exports.default = _default;
});