define("dashboard/templates/components/metrics/charts/message-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fMHKFdsR",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[30,1,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"    \"],[1,[30,0,[\"content\",\"title\"]]],[1,\"\\n\"]],[]]]]],[6,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"    \"],[10,2],[14,0,\"mh6\"],[12],[1,\"\\n      \"],[1,[30,0,[\"content\",\"description\"]]],[1,\"\\n      \"],[8,[39,1],[[16,6,[30,0,[\"content\",\"devCenterUrl\"]]]],null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"content\",\"devCenterTitle\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[1]]]]]],[\"well\"],false,[\"hk-well\",\"dev-center-link\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/message-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});