define("dashboard/components/metrics/chart-controls/process-switcher", ["exports", "@ember/object/computed", "@ember/component", "@ember/object"], function (_exports, _computed, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['metrics__process-switcher', 'flex', 'flex-grow-1'],
    selectedProcess: (0, _object.computed)('formations.[]', 'type', function () {
      const formations = this.formations || [];
      const selectedType = this.type;
      return formations.findBy('type', selectedType);
    }),
    availableProcesses: (0, _object.computed)('formations.[]', 'selectedProcess', function () {
      const formations = this.formations || [];
      const selectedProcess = this.selectedProcess;
      const processes = formations.reject(function (proc) {
        return proc === selectedProcess;
      });
      return processes.sortBy('type');
    }),
    processesWithDynos: (0, _computed.filter)('availableProcesses.[]', function (formation) {
      return formation.get('quantity') > 0;
    }),
    processesWithoutDynos: (0, _computed.filter)('availableProcesses.[]', function (formation) {
      return formation.get('quantity') === 0;
    }),
    allProcesses: (0, _computed.union)('processesWithDynos', 'processesWithoutDynos'),
    menuAvailable: (0, _computed.gte)('availableProcesses.length', 1),
    menuScrollable: (0, _computed.gte)('availableProcesses.length', 5),
    menuClassNames: (0, _object.computed)('menuAvailable', 'menuScrollable', function () {
      const menuAvailable = this.menuAvailable;
      const menuScrollable = this.menuScrollable;
      let classNames = 'dropdown-menu metrics__process-switcher__menu';

      if (!menuAvailable) {
        classNames += ' hidden';
      } else if (menuScrollable) {
        classNames += ' scrollable';
      }

      return classNames;
    }),
    buttonClassNames: (0, _object.computed)('menuAvailable', function () {
      const menuAvailable = this.menuAvailable;
      let classNames = 'btn btn-default metrics__process-switcher__btn';

      if (!menuAvailable) {
        classNames += ' is-only-process';
      }

      return classNames;
    })
  });

  _exports.default = _default;
});