define("dashboard/components/pipeline/review-app", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/helpers/truncate", "@ember/service"], function (_exports, _component, _object, _computed, _truncate, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    classNames: ['pipeline-app', 'pr-app', 'pt1 pb1 ph1'],
    store: (0, _service.inject)(),
    currentDeployment: (0, _computed.readOnly)('reviewApp.app.currentDeployment'),
    isCreated: (0, _computed.alias)('reviewApp.isCreated'),
    isNotCreated: (0, _computed.alias)('reviewApp.isNotCreated'),
    isNotErrored: (0, _computed.not)('reviewApp.isErrored'),
    isErroredCreating: (0, _computed.alias)('reviewApp.isErroredCreating'),
    hasApp: (0, _computed.alias)('reviewApp.hasApp'),
    hasAppSetupPending: (0, _computed.readOnly)('reviewApp.appSetup.hasPendingStatus'),
    hasBuild: (0, _computed.notEmpty)('reviewApp.currentBuild.id'),
    hasNoApp: (0, _computed.not)('hasApp'),
    hasNoBuild: (0, _computed.not)('hasBuild'),
    hasPullRequestInfo: (0, _computed.notEmpty)('reviewApp.pullRequest'),
    hasPostDeployOutput: (0, _computed.readOnly)('reviewApp.appSetup.hasPostDeployOutput'),
    isNotBuilding: (0, _computed.not)('reviewApp.currentBuild.isPending'),
    isRunningPostDeploy: (0, _computed.and)('hasApp', 'isNotBuilding', 'hasAppSetupPending'),
    showAppSetupItem: (0, _computed.or)('isNotErrored', 'hasApp'),
    hasAppStatus: (0, _computed.or)('isRunningPostDeploy', 'hasPostDeployOutput', 'hasNoApp', 'hasNoBuild'),
    hasNewReviewAppFailedStatus: (0, _computed.and)('hasReviewAppError', 'pipeline.hasNewReviewAppsEnabled'),
    doesNotHaveNewReviewAppFailedStatus: (0, _computed.not)('hasNewReviewAppFailedStatus'),
    showAppSetupStatus: (0, _computed.and)('hasAppStatus', 'doesNotHaveNewReviewAppFailedStatus'),
    showAppReleaseMessage: (0, _computed.and)('reviewApp.currentBuild.hasSucceeded', 'isCreated'),
    showDividerAboveDelete: (0, _computed.or)('hasApp', 'hasBuild'),
    isShowingReviewAppError: (0, _computed.and)('pipeline.hasNewReviewAppsEnabled', 'hasReviewAppError'),
    hasReviewAppError: (0, _computed.or)('reviewApp.isErrored', 'release.isFailed'),
    newReviewAppsNotEnabled: (0, _computed.not)('pipeline.hasNewReviewAppsEnabled'),
    oldReviewAppError: (0, _computed.and)('reviewApp.isErrored', 'newReviewAppsNotEnabled'),
    boxClasses: (0, _object.computed)('hasApp', 'isErroredCreating', function () {
      if (this.hasApp) {
        return 'bg-white shadow-outer-3 ba b--silver';
      } else if (this.isErroredCreating) {
        return 'bg-white shadow-outer-3 ba b--dashed b--light-gray';
      } else {
        return 'bg-near-white review-app-errored shadow-outer-3 ba b--dashed b--light-gray';
      }
    }),
    repository: (0, _computed.readOnly)('pipeline.githubRepository.repository.name'),
    repositoryUrl: (0, _object.computed)('repository', function () {
      return `http://github.com/${this.repository}`;
    }),
    branchUrl: (0, _object.computed)('repositoryUrl', 'reviewApp.branch', function () {
      return `${this.repositoryUrl}/tree/${this.get('reviewApp.branch')}`;
    }),
    statusClasses: (0, _object.computed)('hasApp', function () {
      if (this.hasApp) {
        return 'b--silver';
      } else {
        return 'bn';
      }
    }),
    reviewAppName: (0, _object.computed)('reviewApp.hasApp', function () {
      return this.get('reviewApp.hasApp') ? this.get('reviewApp.app.name') : this.get('reviewApp.branch');
    }),
    iconClass: (0, _object.computed)('isNotCreated', function () {
      const createdStyle = 'malibu-fill-gradient-purple nudge-down--4';
      const notCreatedStyle = 'mr1 malibu-fill-gradient-dark-gray';
      return this.isNotCreated ? notCreatedStyle : createdStyle;
    }),
    boxClass: (0, _object.computed)('isNotCreated', function () {
      return this.isNotCreated ? 'ba b--light-gray b--dashed' : 'shadow-outer-1';
    }),
    showManageDropdown: (0, _computed.or)('reviewApp.isCreated', 'reviewApp.isCreating', 'reviewApp.isErrored'),
    showDeleteAppButton: (0, _object.computed)('reviewApp.isCreated', 'reviewApp.isErrored', 'pipeline.canDeleteReviewApps', function () {
      return (this.get('reviewApp.isCreated') || this.get('reviewApp.isErrored')) && this.get('pipeline.canDeleteReviewApps');
    }),
    showOpenAppButton: (0, _computed.alias)('hasApp'),
    isShowingDeleteReviewAppModal: false,

    toggleDeleteReviewAppModal() {
      this.toggleProperty('isShowingDeleteReviewAppModal');
    },

    async destroyReviewApp() {
      await this.reviewApp.destroyRecord();

      if (!this.isDestroyed && !this.isDestroying) {
        this.set('isShowingDeleteReviewAppModal', false);
      }
    },

    errorType: (0, _object.computed)('reviewApp.isErrored', function () {
      return this.get('reviewApp.isErrored') ? 'build' : 'release';
    }),
    buttonText: (0, _object.computed)('errorType', function () {
      return `Retry ${this.errorType}`;
    }),
    release: (0, _computed.readOnly)('reviewApp.currentRelease'),

    retryReviewAppBuild() {
      this.destroyReviewApp();
      this.onClickRetryBuild(this.reviewApp);
    },

    retryReviewAppRelease() {
      const lastVersion = this.get('release.version');
      const lastDescription = this.get('release.description');
      const truncatedDescription = (0, _truncate.truncate)([lastDescription, 40, '...']);
      return this.store.createRecord('release', {
        app: this.get('release.app'),
        description: `Retry v${lastVersion}: ${truncatedDescription}`,
        slug: this.get('release.slug')
      }).save();
    },

    actions: {
      deleteReviewApp() {
        return this.destroyReviewApp();
      },

      toggleDeleteReviewAppModal() {
        this.toggleDeleteReviewAppModal();
      },

      showBuildModal() {
        this.set('isShowingBuildModal', true);
      },

      hideBuildModal() {
        this.set('isShowingBuildModal', false);
      },

      retryAction() {
        if (this.errorType === 'build') {
          this.retryReviewAppBuild();
        } else {
          this.retryReviewAppRelease();
        }
      }

    }
  });

  _exports.default = _default;
});