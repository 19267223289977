define("dashboard/routes/app/activity", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app", "dashboard/mixins/app-activity-websocket"], function (_exports, _rsvp, _service, _basicApp, _appActivityWebsocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend(_appActivityWebsocket.default, {
    analytics: (0, _service.inject)(),
    session: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    model() {
      const {
        app,
        appPermissions
      } = this.modelFor('app'); // The builds and releases use `store.query` so that we load more than the
      // default of 10 that's set in their adapters.

      return (0, _rsvp.hash)({
        app,
        appPermissions,
        releases: this.store.query('release', {
          app: app.get('id')
        }).then(() => app.get('releases')),
        builds: this.store.query('build', {
          app: app.get('id')
        }).then(() => app.get('builds'))
      });
    },

    afterModel() {
      this.analytics.logEvent('App Activity Page', 'Viewed');
    },

    actions: {
      refresh() {
        this.refresh();
      },

      connectToGithub() {
        const {
          app
        } = this.modelFor('app');
        this.transitionTo('app.deploy.github', app.get('name'));
      }

    }
  });

  _exports.default = _default;
});