define("dashboard/templates/components/app/addons-provision-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SGiFLLyx",
    "block": "[[[10,0],[14,0,\"addons-provision addons-provision-error\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/images/error-warning-small.png\"],[14,\"alt\",\"Error\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"addons-provision-error__header u-margin-Tm u-padding-Bs\"],[12],[1,\"\\n    This add-on could not be provisioned\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"addons-provision addons-provision-error\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"addons-provision-error__body u-margin-Lxl u-margin-Rxl\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"There was a problem getting details about this add-on.  Please try again or\\n    \"],[10,3],[14,6,\"https://help.heroku.com/tickets/new?category=feedback&query=dashboard+feedback\"],[14,0,\"hk-link\"],[14,\"target\",\"_blank\"],[12],[1,\"let us know\"],[13],[1,\" if the problem persists.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/app/addons-provision-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});