define("dashboard/templates/components/pipeline/review-app-modal/title-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IO6bFXP4",
    "block": "[[[41,[30,0,[\"parent\",\"appSetup\",\"willDeploy\"]],[[[1,\"\\n  Creating review app\\n\"],[41,[30,0,[\"parent\",\"prNumberInTitle\"]],[[[1,\"    (#\"],[1,[30,0,[\"parent\",\"pullRequestNumber\"]]],[1,\")\\n\"]],[]],[[[1,\"    (\"],[1,[30,0,[\"parent\",\"appSetup\",\"app\",\"name\"]]],[1,\")\\n\"]],[]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"parent\",\"appSetup\",\"isFailed\"]],[[[1,\"\\n  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"failure-ring-28\",24,\"malibu-fill-gradient-dark-gray\"]],null],[1,\"\\n  Failed to build app\\n\\n\"]],[]],[[[1,\"\\n  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"success-ring-28\",24,\"malibu-fill-gradient-dark-gray\"]],null],[1,\"\\n  Created review app successfully\\n\\n\"]],[]]]],[]]]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/review-app-modal/title-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});