define("dashboard/components/metrics/charts/line-chart-elements/datastore-area-graph", ["exports", "@ember/object", "dashboard/components/metrics/charts/line-chart-elements/area-graph"], function (_exports, _object, _areaGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a {{area-graph}} that stacks on top of the {{stacked-area-graph}}
   * rendered before it
   *
   */
  var _default = _areaGraph.default.extend({
    displayUnit: (0, _object.computed)('extraData.specificUnit', function () {
      const displayUnit = this._super(...arguments);

      if (this.extraData && this.extraData.specificUnit) {
        return this.extraData.specificUnit;
      } else {
        return displayUnit;
      }
    }),
    chartContextGroupComponentName: (0, _object.computed)(function () {
      return 'metrics/charts/connected-chart-hover/group';
    }),

    areaBottomYFn() {
      return this.get('chartInnerHeight');
    }

  });

  _exports.default = _default;
});