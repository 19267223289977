define("dashboard/controllers/team/switchable/spaces/new", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    title: 'Create Space',
    templateName: 'team/switchable/spaces/new',
    team: (0, _computed.alias)('model.space.team'),
    space: (0, _computed.alias)('model.space'),
    regions: (0, _computed.alias)('model.regions'),
    defaultRegion: (0, _computed.alias)('model.defaultRegion'),
    teamPermissions: (0, _computed.readOnly)('team.currentPermissions'),
    canManageSpaces: (0, _computed.readOnly)('teamPermissions.canManageSpaces'),
    isSavable: (0, _computed.or)('_isNotPersisted', 'space.isDirty'),
    canSave: (0, _computed.and)('isSavable', 'canManageSpaces'),
    cannotSave: (0, _computed.not)('canSave'),
    cannotRenameApp: (0, _computed.not)('canManageSpaces'),
    _isNotPersisted: (0, _computed.not)('space.isPersisted'),
    _cannotCreate: (0, _computed.not)('canManageSpaces'),
    isShowingAdminOnlyCreateWarning: (0, _computed.and)('_isNotPersisted', '_cannotCreate'),
    actions: {
      save() {
        this.space.save().then(() => {
          this.transitionToRoute('team.switchable.spaces', this.get('team.name'));
        });
      },

      toggleShield() {
        this.toggleProperty('space.shield');
      }

    }
  });

  _exports.default = _default;
});