define("dashboard/templates/components/stencil/addons/quickadd-entry", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WOaP2m0w",
    "block": "[[[10,0],[14,0,\"tt-suggestion typeahead-empty-state-suggestion\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"flex items-center center o-\",[30,0,[\"opacity\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"br-4 bg-light-silver\"],[14,5,\"width:28px;height:28px;\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"stencil w5 ml4\"],[12],[1,\"Add-on Name\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/stencil/addons/quickadd-entry.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});