define("dashboard/components/app/formation-list-item", ["exports", "@glimmer/component", "dashboard/models/dyno-tier"], function (_exports, _component, _dynoTier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormationListItemComponent extends _component.default {
    get isSubscribedToEco() {
      return this.args.isSubscribedToEco;
    }

    get dynoTier() {
      return _dynoTier.default.findTierBySize(this.args.size);
    }

    get dynoSize() {
      return this.dynoTier?.findSize(this.args.size);
    }

    get isFree() {
      return this.dynoTier.isFree;
    }

    get isEco() {
      return this.dynoTier.isEco;
    }

  }

  _exports.default = FormationListItemComponent;
});