define("dashboard/serializers/github-commit-status", ["exports", "ember-data-github/serializers/github", "@ember/utils", "@ember/object"], function (_exports, _github, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function flatten(items) {
    return items.reduce((flat, item) => {
      if (Array.isArray(item)) {
        flat.push(...flatten(item));
      } else {
        flat.push(item);
      }

      return flat;
    }, []);
  }

  var _default = _github.default.extend({
    // Convert GraphQL response format into ember-data compatible array of objects
    normalizeResponse(store, model, batches, id, requestType) {
      const normalizedBatches = batches.map(batch => {
        const repo = (0, _object.get)(batch, 'data.repository');

        if (repo) {
          const repoName = repo.nameWithOwner;
          delete repo.nameWithOwner;
          const refs = repo;
          return Object.keys(refs).map(key => {
            const target = refs[key];

            if ((0, _utils.isNone)(target) || (0, _utils.isNone)(target.status)) {
              return null;
            }

            return target.status.contexts.map(ctx => {
              return {
                id: ctx.id,
                state: ctx.state.toLowerCase(),
                target_url: ctx.targetUrl,
                description: ctx.description,
                context: ctx.context,
                sha: target.oid,
                repo: repoName
              };
            });
          }).compact();
        } else {
          return null;
        }
      });
      const data = flatten(normalizedBatches.compact());
      return this._super(store, model, data, id, requestType);
    }

  });

  _exports.default = _default;
});