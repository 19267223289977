define("dashboard/controllers/app/logs", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object"], function (_exports, _controller, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    app: (0, _computed.oneWay)('model.app'),
    _formations: (0, _computed.oneWay)('model.formations'),
    formations: (0, _computed.filter)('_formations.@each.quantity', function (f) {
      return f.get('quantity') > 0;
    }),
    selectedFormation: (0, _computed.oneWay)('allProcesses'),
    setFilterOnFormation: (0, _object.observer)('selectedFormation.type', 'allProcesses', function () {
      const selectedFormation = this.selectedFormation;
      const type = this.get('selectedFormation.type'); // ensure `All Processes` isn't used as a filter

      if (selectedFormation !== this.allProcesses) {
        this.set('selectedFormation.filter', type);
      }
    }),
    allProcesses: (0, _object.computed)(function () {
      return {
        type: 'All Processes'
      };
    }),
    actions: {
      filterOnFormation(formation) {
        this.set('selectedFormation', formation);
      }

    }
  });

  _exports.default = _default;
});