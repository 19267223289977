define("dashboard/components/pipeline/app-build-status", ["exports", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isVisible: (0, _computed.and)('hasCurrentBuild', 'currentBuildIsPendingOrFailed'),
    classNameBindings: ['showClasses:classes'],
    classes: (0, _object.computed)('showClasses', function () {
      if (this.showClasses) {
        return 'flex pa2 bt b--silver';
      }
    }),
    showClasses: (0, _computed.or)('currentBuild.isFailed', 'currentBuild.isPending'),
    currentBuild: (0, _computed.readOnly)('app.currentBuild'),
    hasCurrentBuild: (0, _computed.notEmpty)('currentBuild'),
    currentBuildIsPending: (0, _computed.and)('hasCurrentBuild', 'currentBuild.isPending'),
    currentBuildIsPendingOrFailed: (0, _computed.or)('currentBuildIsPending', 'currentBuild.isFailed'),
    newReviewAppsNotEnabled: (0, _computed.not)('pipeline.hasNewReviewAppsEnabled'),
    isShowingFailedStatus: (0, _computed.and)('currentBuild.isFailed', 'newReviewAppsNotEnabled'),
    hasNoCurrentRelease: (0, _computed.not)('app.currentRelease'),
    actions: {
      showBuildModal() {
        this.set('isShowingBuildModal', true);
      },

      hideBuildModal() {
        this.set('isShowingBuildModal', false);
      }

    }
  });

  _exports.default = _default;
});