define("dashboard/routes/protected/unexpected", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    beforeModel(transition) {
      const path = transition.to.queryParams.path;
      window.history.replaceState(null, null, path);
    },

    templateName: 'errors/unexpected-error'
  });

  _exports.default = _default;
});