define("dashboard/models/github-app-link", ["exports", "@ember/object", "@ember-data/model"], function (_exports, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    notEmpty,
    not
  } = _object.computed;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    githubBranches: (0, _model.hasMany)('kolkrabbi/github-branch'),
    kolkrabbiApps: (0, _model.hasMany)('kolkrabbi/app'),
    githubOwner: (0, _model.belongsTo)('github/user'),
    parentApp: (0, _model.belongsTo)('app'),
    autoDeploy: (0, _model.attr)('boolean'),
    branch: (0, _model.attr)('string'),
    pullRequests: (0, _model.attr)(),
    pullRequest: (0, _model.attr)(),
    repo: (0, _model.attr)('string'),
    repoId: (0, _model.attr)('number'),
    staleDays: (0, _model.attr)('number'),
    waitForCi: (0, _model.attr)('boolean'),
    isLinked: true,
    isPullRequest: notEmpty('pullRequest'),
    isNotPullRequest: not('isPullRequest'),
    webUrl: (0, _object.computed)('repo', function () {
      return `https://github.com/${this.repo}`;
    }),
    isParentLink: (0, _object.computed)('pullRequest', 'pullRequests.enabled', function () {
      return this.get('pullRequests.enabled') && !this.pullRequest;
    }),
    repoName: (0, _object.computed)('repo', function () {
      let repo = this.repo || '';
      repo = repo.split('/');
      return repo[repo.length - 1];
    })
  });

  _exports.default = _default;
});