define("dashboard/routes/enterprise-account/overview/access", ["exports", "@ember/service", "@ember/routing/route", "dashboard/mixins/document-title", "@ember/runloop"], function (_exports, _service, _route, _documentTitle, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function fixMFA(mfa) {
    if (mfa && ['enabled', 'disabled'].includes(mfa)) {
      return mfa;
    }

    return null;
  }

  function fixPermissions(permissions) {
    if (typeof permissions === 'string') {
      return permissions.split(',').filter(permission => ['manage', 'billing', 'create'].includes(permission)).join(',');
    }

    return '';
  }

  var _default = _route.default.extend(_documentTitle.default, {
    queryParams: {
      email: {
        replace: true
      },
      mfa: {
        replace: true
      }
    },
    analytics: (0, _service.inject)(),

    get documentTitle() {
      return this.modelFor('enterprise-account').enterpriseAccount.get('name');
    },

    model() {
      return this.modelFor('enterprise-account');
    },

    redirect(_model, transition) {
      const {
        queryParams
      } = transition.to;
      const newQueryParams = { ...queryParams,
        mfa: fixMFA(queryParams.mfa),
        permissions: fixPermissions(queryParams.permissions)
      };
      this.transitionTo({
        queryParams: newQueryParams
      });
    },

    // This resets the `mfa` and `permissions` properties on the controller manually
    // when it is not one of the expected values. This would normally be handled by
    // the `redirect` method but there is a bug in ember, see:
    // https://github.com/emberjs/ember.js/issues/18577
    setupController(controller) {
      this._super(...arguments);

      controller.set('mfa', fixMFA(controller.get('mfa')));
      controller.set('permissions', fixPermissions(controller.get('permissions')));
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          user: null,
          page: 1,
          filter: ''
        });
      }
    },

    actions: {
      didTransition() {
        _runloop.run.next(() => {
          this.analytics.logEvent('Enterprise Account Access Page', 'Viewed');
        });

        return true;
      }

    }
  });

  _exports.default = _default;
});