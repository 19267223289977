define("dashboard/controllers/team/space/network", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    title: 'Space Network',
    space: (0, _computed.alias)('model.space'),
    teamPermissions: (0, _computed.readOnly)('space.team.currentPermissions'),
    canManageSpaces: (0, _computed.readOnly)('teamPermissions.canManageSpaces')
  });

  _exports.default = _default;
});