define("dashboard/templates/components/org/member-delete-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6d1IYt2Y",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"onClose\",\"title\",\"hasCancelButton\",\"cancelButtonClassNames\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"primaryActionDisabled\"],[[30,0,[\"closeAction\"]],[30,0,[\"titleForDelete\"]],true,\"btn btn-default\",[28,[37,2],[[30,0],[30,1,[\"preauth\"]],[30,0,[\"primaryAction\"]]],null],[30,0,[\"deleteButtonText\"]],\"btn btn-danger\",[30,0,[\"deleteSubmitDisabled\"]]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,3],null,[[\"email\",\"isFederated\",\"submitDisabled\",\"isInvitation\"],[[30,0,[\"email\"]],[30,0,[\"isFederated\"]],[30,0,[\"deleteSubmitDisabled\"]],[30,0,[\"isInvitation\"]]]]]],[1,\"\\n\"],[41,[30,1,[\"twoFactorRequired\"]],[[[1,\"      \"],[10,0],[14,0,\"mt2\"],[12],[1,\"\\n        \"],[1,[30,1,[\"prompt\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[1]]]]]],[\"twoFactor\"],false,[\"two-factor\",\"hk-modal\",\"action\",\"org/member-confirm-delete-modal\",\"if\"]]",
    "moduleName": "dashboard/templates/components/org/member-delete-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});