define("dashboard/services/transition-monitor", ["exports", "@ember/service", "dashboard/config/environment", "@opentelemetry/api"], function (_exports, _service, _environment, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransitionMonitor = (_dec = (0, _service.inject)('-routing'), (_class = class TransitionMonitor extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "routing", _descriptor2, this);

      _defineProperty(this, "_intendedPath", null);
    }

    get intendedPath() {
      return this._intendedPath || this.currentPath;
    }

    get currentPath() {
      return this.router.currentURL;
    }

    sendTrace(transition) {
      const tracer = _api.default.trace.getTracer('dashboard');

      const span = tracer.startSpan('route-change', {
        attributes: {
          'route.name': transition.to.name
        }
      });
      span.end();
    }

    start() {
      this.router.on('routeWillChange', transition => {
        if (transition && transition.intent) {
          this._intendedPath = this._intentToPath(transition.intent);

          if (_environment.default.environment !== 'test') {
            this.sendTrace(transition);
          }
        }
      });
    }

    _intentToPath(intent) {
      // The intent URL is populated on initial load
      if (intent.url) {
        return intent.url;
      } // The intent route params are populated on internal transitions


      const {
        name,
        contexts,
        queryParams
      } = intent;

      try {
        // The code below is public API but is brittle and does not
        // handle unknown query params. The private API call to `genetateURL`
        // calls the same router_js#generate function but also handles
        // unknown query params.
        //
        //   const args = [name, ...contexts];
        //   if (Object.keys(queryParams).length) {
        //     args.push(queryParams);
        //   }
        //   return this.router.urlFor.apply(this.router, args);
        //
        return this.routing.generateURL(name, contexts, queryParams);
      } catch (_e) {// This happens if you use transitionTo without
        // the proper dynamic route segments.
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "routing", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TransitionMonitor;
});