define("dashboard/mixins/resettable", ["exports", "@ember/object/mixin", "dashboard/config/environment", "dashboard/utils/localstorage"], function (_exports, _mixin, _environment, _localstorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    // most sure fire way to correctly wipe the application state is to
    // do a full location reload
    hardReset() {
      // preserve some state
      const sessionDataKey = _environment.default['ember-simple-auth']['localStorageKey'];

      const sessionData = _localstorage.default.getItem(sessionDataKey);

      const sudoDataKey = 'hk-sudo-data';

      const sudoDataValue = _localstorage.default.getSessionItem(sudoDataKey); // clear all the things (e.g. user-specific cached data)


      _localstorage.default.clear();

      _localstorage.default.clearSession(); // restore some state


      if (sessionData) {
        _localstorage.default.setItem(sessionDataKey, sessionData);
      }

      if (sudoDataValue) {
        _localstorage.default.setSessionItem(sudoDataKey, sudoDataValue);
      }
    }

  });

  _exports.default = _default;
});