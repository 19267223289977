define("dashboard/mixins/models/enterprise-license", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed"], function (_exports, _mixin, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.LICENSE_TYPES = void 0;
  const DYNO_CODE = 'dynos';
  const ADDON_CODE = 'addons_total';
  const PARTNER_ADDON_CODE = 'partner_addons';
  const DATA_ADDON_CODE = 'data_addons';
  const SPACE_CODE = 'spaces';
  const CONNECT_CODE = 'connect';
  const LICENSE_TYPES = {
    DYNO_CODE,
    ADDON_CODE,
    PARTNER_ADDON_CODE,
    DATA_ADDON_CODE,
    SPACE_CODE,
    CONNECT_CODE
  };
  _exports.LICENSE_TYPES = LICENSE_TYPES;
  const CODE_PRIORITY = [DYNO_CODE, SPACE_CODE, CONNECT_CODE, DATA_ADDON_CODE, PARTNER_ADDON_CODE, ADDON_CODE];

  var _default = _mixin.default.create({
    // --------------------------------------------------------------------------
    // Computed Properties
    isDyno: (0, _computed.equal)('licenseType', DYNO_CODE),
    isAddon: (0, _computed.equal)('licenseType', ADDON_CODE),
    isPartnerAddon: (0, _computed.equal)('licenseType', PARTNER_ADDON_CODE),
    isDataAddon: (0, _computed.equal)('licenseType', DATA_ADDON_CODE),
    isSpace: (0, _computed.equal)('licenseType', SPACE_CODE),
    isConnect: (0, _computed.equal)('licenseType', CONNECT_CODE),
    isCurrency: (0, _computed.or)('isAddon', 'isPartnerAddon', 'isDataAddon'),
    hasQuota: (0, _computed.gt)('licenseQuantity', 0),
    label: (0, _object.computed)('isDyno', 'isAddon', 'isPartnerAddon', 'isDataAddon', 'isSpace', 'isConnect', function () {
      if (this.isDyno) {
        return 'Dyno units';
      } else if (this.isAddon) {
        return 'General Add-on credits';
      } else if (this.isPartnerAddon) {
        return 'Partner Add-on credits';
      } else if (this.isDataAddon) {
        return 'Heroku Data Add-on credits';
      } else if (this.isSpace) {
        return 'Heroku Private Spaces';
      } else if (this.isConnect) {
        return 'Heroku Connect rows';
      }
    }),
    labelForUsage: (0, _object.computed)('isConnect', 'label', function () {
      const desc = this.isConnect ? 'synced' : 'used';
      return `${this.label} ${desc}`;
    }),
    isLicenseValid: (0, _object.computed)('licenseType', 'licenseQuantity', function () {
      return !!this.licenseType && this.licenseQuantity > 0;
    }),
    quota: (0, _object.computed)('isCurrency', 'licenseQuantity', function () {
      const quantity = this.licenseQuantity;

      if (this.isCurrency) {
        return quantity * 100; // convert dollars to cents
      } else {
        return quantity;
      }
    }),

    // --------------------------------------------------------------------------
    // Helpers
    compare(other) {
      // license type ordering priority from high to low
      const idx1 = CODE_PRIORITY.indexOf(this.licenseType);
      const idx2 = CODE_PRIORITY.indexOf(other.get('licenseType'));

      if (idx1 < idx2) {
        return -1;
      } else if (idx1 > idx2) {
        return 1;
      } else {
        return 0;
      }
    }

  });

  _exports.default = _default;
});