define("dashboard/templates/components/ci/test-menu-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "c3NPoQAp",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"groupedSortedTestRuns\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"ci-menu--header\"],[12],[1,\"\\n    \"],[10,\"h6\"],[12],[1,[30,1,[\"value\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"items\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,2],null,[[\"pipeline\",\"testRun\",\"class\",\"selectedBranch\"],[[30,0,[\"pipeline\"]],[30,2],[30,2,[\"status\"]],[30,0,[\"selectedBranch\"]]]]]],[1,\"\\n\"]],[2]],null]],[1]],[[[1,\"  \"],[10,0],[14,0,\"empty-state\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"ci-28\",\"fill-gray\",28]],null],[1,\"\\n\"],[41,[30,0,[\"connectedWithBranch\"]],[[[1,\"      \"],[10,2],[14,0,\"f5 gray\"],[12],[1,\"\\n        \"],[10,\"code\"],[12],[1,[30,0,[\"selectedBranch\"]]],[13],[1,\" does not have any tests.\\n        \"],[10,0],[14,0,\"mt1\"],[12],[6,[39,5],null,[[\"replace\",\"class\",\"query\",\"route\",\"model\"],[true,\"hk-link\",[28,[37,6],null,[[\"selectedFilterBranch\"],[null]]],\"pipelines.pipeline.tests\",[30,0,[\"pipeline\",\"id\"]]]],[[\"default\"],[[[[1,\"See all tests\"]],[]]]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,2],[14,0,\"f5 gray\"],[12],[1,\"Tests run on Heroku when your pipeline is connected to GitHub and code is pushed to a branch.\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"testRunGroup\",\"testRun\"],false,[\"each\",\"-track-array\",\"ci/tests-menu-item\",\"malibu-icon\",\"if\",\"link-to\",\"-hash\"]]",
    "moduleName": "dashboard/templates/components/ci/test-menu-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});