define("dashboard/utils/banner-not-dismissed", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = bannerNotDismissed;

  function bannerNotDismissed(userPreference, dismissalKey) {
    return (0, _object.computed)(`dashboardUserPreferences.${userPreference}.[]`, function () {
      const dismissals = this.get(`dashboardUserPreferences.${userPreference}`) || [];
      return !dismissals.includes(dismissalKey);
    });
  }
});