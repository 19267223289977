define("dashboard/templates/components/metrics/charts/connected-chart-hover", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LiHC8j4f",
    "block": "[[[11,0],[24,0,\"w-100\"],[4,[38,0],[[28,[37,1],[[30,0],\"onResize\"],null]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"graphs\"]]],null]],null],null,[[[1,\"  \"],[46,[30,1,[\"chartContextGroupComponentName\"]],null,[[\"graph\",\"selectedRollupTime\",\"maxTime\",\"rollupDuration\"],[[30,1],[30,0,[\"selectedRollupTime\"]],[30,0,[\"maxTime\"]],[30,0,[\"rollupDuration\"]]]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"graph\"],false,[\"did-resize\",\"action\",\"each\",\"-track-array\",\"component\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/connected-chart-hover.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});