define("dashboard/adapters/addon", ["exports", "dashboard/adapters/application", "@ember/object"], function (_exports, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_RANGE = {
    attribute: 'name',
    max: 1000
  };

  var _default = _application.default.extend({
    version: '3.heroku-addons-filter',
    range: DEFAULT_RANGE,

    urlForQuery(query) {
      if (query.personalOnly) {
        delete query['personalOnly'];
        return `${this.host}/users/~/addons`;
      }

      if (query.teamId) {
        const path = `${this.host}/teams/${query.teamId}/addons`;
        delete query.teamId;
        return path;
      }

      if (query.appNameOrId) {
        const path = `${this.host}/apps/${query.appNameOrId}/addons`;
        delete query.appNameOrId;
        return path;
      }

      return this._super(...arguments);
    },

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${this.host}/apps/${appId}/addons`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${this.host}/apps/${appId}/addons/${id}`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${this.host}/apps/${appId}/addons/${id}`;
    },

    headers: (0, _object.computed)(function () {
      const headers = this._super();

      const additionalHeaders = {
        'Accept-Expansion': 'plan, addon_service'
      };
      return { ...headers,
        ...additionalHeaders
      };
    })
  });

  _exports.default = _default;
});