define("dashboard/components/pipeline/commit-status-icon", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATE_ICONS = {
    error: {
      icon: 'error-mark-16',
      iconClass: 'fill-red'
    },
    failure: {
      icon: 'error-mark-16',
      iconClass: 'fill-red'
    },
    pending: {
      icon: 'pending-16',
      iconClass: 'fill-gold'
    },
    success: {
      icon: 'success-mark-16',
      iconClass: 'fill-green'
    }
  };

  var _default = _component.default.extend({
    tagName: '',
    icon: (0, _object.computed)('options.state', function () {
      return STATE_ICONS[this.get('options.state')];
    }),
    iconName: (0, _computed.reads)('icon.icon'),
    iconClass: (0, _computed.reads)('icon.iconClass')
  });

  _exports.default = _default;
});