define("dashboard/models/build", ["exports", "@ember/object", "@ember/object/computed", "@ember-data/model"], function (_exports, _object, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    isBuild: true,
    user: (0, _model.attr)(),
    status: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    app: (0, _model.belongsTo)('app'),
    description: (0, _object.computed)('status', function () {
      return `Build ${this.status}`;
    }),
    outputStreamUrl: (0, _model.attr)('string'),
    sourceBlob: (0, _model.attr)(),
    isPending: (0, _computed.equal)('status', 'pending'),
    isSucceded: (0, _computed.equal)('status', 'succeeded'),
    isFailed: (0, _computed.equal)('status', 'failed'),
    isCompleted: (0, _computed.equal)('status', 'completed'),
    hasSucceeded: (0, _computed.or)('isSucceded', 'isCompleted'),
    buildStatusIsTerminal: (0, _computed.or)('isFailed', 'isSucceded', 'isCompleted')
  });

  _exports.default = _default;
});