define("dashboard/templates/components/metrics/charts/zone-elements/missing-data-zones-timeframe", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "A9DzL3kF",
    "block": "[[[41,[30,0,[\"isLagging\"]],[[[1,\"  \"],[10,\"title\"],[12],[1,\" Data is still being processed for this timeframe. \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"title\"],[12],[1,\" Data missing for this timeframe. \"],[13],[1,\"\\n\"]],[]]],[10,\"rect\"],[15,\"height\",[29,[[30,0,[\"height\"]]]]],[15,\"width\",[29,[[30,0,[\"width\"]]]]],[14,\"stroke\",\"rgb(231, 231, 236)\"],[14,\"fill\",\"white\"],[12],[13],[1,\"\\n\"],[10,\"rect\"],[15,\"height\",[29,[[30,0,[\"height\"]]]]],[15,\"width\",[29,[[30,0,[\"width\"]]]]],[15,\"fill\",[29,[[30,0,[\"fillUrl\"]]]]],[12],[13],[1,\"\\n\"]],[],false,[\"if\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/zone-elements/missing-data-zones-timeframe.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});