define("dashboard/utils/moment-diff", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = momentDiff;

  function momentDiff(dateA, dateB) {
    let precision = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'days';
    return (0, _moment.default)(dateA).diff(dateB, precision);
  }
});