define("dashboard/templates/components/loading-state", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gIi9/1Cd",
    "block": "[[[41,[51,[30,0,[\"loadingState\",\"showNavLoading\"]]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"class\"],[\"flex justify-center mt4\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"malibu-loading-spinner\"]]",
    "moduleName": "dashboard/templates/components/loading-state.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});