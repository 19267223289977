define("dashboard/templates/components/efp-rapid-upgrade-table-app-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "F0OTHLGV",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"tr\"],[14,0,\"dt-row h--48 bb b--light-gray bg-lightest-silver\"],[12],[1,\"\\n    \"],[10,\"td\"],[14,0,\"dtc near-blac normal f3\"],[14,\"colspan\",\"5\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"b--light-gray bl bb ml4 mr1 pa1 mb2\"],[12],[13],[1,\"\\n        \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"app-16\",16,\"malibu-fill-gradient-purple mr1\"]],null],[1,\"\\n        \"],[1,[30,2]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"tr\"],[14,0,\"dt-row h--48 bb b--light-gray bg-lightest-silver\"],[12],[1,\"\\n    \"],[10,\"td\"],[14,0,\"dtc near-black normal f3\"],[14,\"colspan\",\"5\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"app-16\",16,\"malibu-fill-gradient-purple ml2 mr1\"]],null],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@isInPipeline\",\"@name\"],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/efp-rapid-upgrade-table-app-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});