define("dashboard/helpers/malibu-icon", ["exports", "@heroku/ember-malibu-icon/helpers/malibu-icon"], function (_exports, _malibuIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _malibuIcon.default;
    }
  });
  Object.defineProperty(_exports, "malibuIcon", {
    enumerable: true,
    get: function () {
      return _malibuIcon.malibuIcon;
    }
  });
});