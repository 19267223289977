define("dashboard/mixins/validating-field", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A mixin to be used with form inputs (TextArea, TextField, etc.)
   * That checks the validity of a model's given value.
   *
   * From the template, you must pass in `model` and `errorIsVisible` in
   * additional the normal `input` arguments
   *
   */
  const {
    readOnly,
    or,
    and
  } = _object.computed;

  var _default = _mixin.default.create({
    classNameBindings: ['showError:has-error'],
    isRequiredAndBlank: (0, _object.computed)('model.missingProperties.[]', function () {
      const missingProperties = this.get('model.missingProperties') || [];
      return missingProperties.includes(this.validatingKey);
    }),
    isDuplicated: readOnly('model.isDuplicated'),
    errorMessage: (0, _object.computed)('isRequiredAndBlank', 'isDuplicated', function () {
      if (this.isRequiredAndBlank) {
        return 'Required';
      }

      if (this.isDuplicated) {
        return 'Already added';
      }
    }),
    hasError: or('isRequiredAndBlank', 'isDuplicated'),
    showError: and('errorIsVisible', 'hasError'),
    // Manually return either true or undefined to the readonly property on the input
    // this avoids readonly being defined when we don't want it to be
    isReadOnly: (0, _object.computed)('readonly', function () {
      return this.readonly ? 'true' : undefined;
    }),
    // Make errors visible once someone keypresses in the field.
    actions: {
      setErrorIsVisible() {
        this.set('errorIsVisible', true); // Clear any flash messages since they should only appear after the input is submitted

        const errorMonitor = this.get('model.errorMonitor');
        errorMonitor && errorMonitor.wipe();
      }

    }
  });

  _exports.default = _default;
});