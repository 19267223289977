define("dashboard/templates/components/app-typeahead-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iLS2/0yR",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"app-16\",\"malibu-fill-gradient-purple mr1\",16,\"App\"]],null],[1,\"\\n\"],[10,1],[14,0,\"app-typeahead-title\"],[12],[1,[30,0,[\"item\",\"name\"]]],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/app-typeahead-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});