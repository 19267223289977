define("dashboard/components/pipeline/kolkrabbi-review-app", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _computed, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    notEmpty,
    readOnly,
    bool
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: 'li',
    classNames: ['apps-item', 'pipeline-app', 'pr-app', 'pt1 pb1 ph1'],
    store: (0, _service.inject)(),
    currentRelease: readOnly('app.currentRelease'),
    currentDeployment: readOnly('app.currentDeployment'),
    parent: readOnly('pipeline.reviewAppParent'),
    hasAppSetupFailure: notEmpty('githubReviewApp.appSetup.failure_message'),
    githubReviewApp: (0, _computed.alias)('app.pipelineCoupling.githubReviewApp'),
    githubPullRequest: readOnly('app.pipelineCoupling.githubPullRequest'),
    canDeleteReviewApp: bool('githubReviewApp'),

    toggleDeleteReviewAppModal() {
      this.toggleProperty('isShowingDeleteReviewAppModal');
    },

    actions: {
      showBuildModal() {
        this.set('isShowingBuildModal', true);
      },

      hideBuildModal() {
        this.set('isShowingBuildModal', false);
      },

      toggleDeleteReviewAppModal() {
        this.toggleDeleteReviewAppModal();
      },

      deleteReviewApp(buttonCallback) {
        const pipeline = this.pipeline;
        const reviewApp = this.githubReviewApp;
        const promise = pipeline.deleteGithubReviewApp(reviewApp);
        promise.then(() => {
          reviewApp.get('kolkrabbiAppSetup').cancelPolling();
          this.toggleDeleteReviewAppModal();
        });
        buttonCallback(promise);
      },

      fetchGithubReviewApps() {
        this.pipeline.fetchGithubReviewApps();
      },

      retry() {
        const githubReviewApp = this.githubReviewApp;
        const newGithubReviewApp = this.store.createRecord('kolkrabbi/app', {
          pullRequest: githubReviewApp.get('pullRequest'),
          appSetup: {
            status: 'pending'
          },
          parentAppId: this.get('parent.app.id')
        });
        return newGithubReviewApp.save().then(() => {
          this.get('pipeline.kolkrabbiReviewApps').removeObject(githubReviewApp);
          this.get('pipeline.kolkrabbiReviewApps').pushObject(newGithubReviewApp);
        });
      }

    }
  });

  _exports.default = _default;
});