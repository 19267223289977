define("dashboard/adapters/team/license", ["exports", "dashboard/adapters/application", "@ember/debug", "dashboard/serializers/team/license"], function (_exports, _application, _debug, _license) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.org-licenses',

    urlForQuery(_ref) {
      let {
        team,
        grouped
      } = _ref;
      (false && !(team) && (0, _debug.assert)('You must pass "team" as a query param', team));
      let result = this.urlForFindRecord(team, 'team');

      if (grouped) {
        result += '/license-collections';
      } else {
        result += '/licenses';
      }

      return result;
    },

    sortQueryParams(obj) {
      const copy = Object.assign({}, obj);
      delete copy.team;
      delete copy.grouped;
      return copy;
    },

    query(store, type, _ref2) {
      let {
        team,
        grouped
      } = _ref2;
      return this._super(...arguments).then(payload => {
        if (grouped) {
          payload = (0, _license.massageGroupedLicenses)(payload, team);
        } else {
          payload = (0, _license.injectTeamIdIntoPayload)(payload, team);
        }

        return payload;
      });
    }

  });

  _exports.default = _default;
});