define("dashboard/controllers/provision-addon", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    queryParams: ['addonServiceId', 'planId'],
    addonService: (0, _object.computed)('addonServiceId', function () {
      const store = this.store;
      const addonServiceId = this.addonServiceId;

      if (!addonServiceId) {
        return this.transitionToRoute('/404');
      }

      const addonServicePromise = this.store.findRecord('addon-service', addonServiceId, {
        reload: false
      }); // handle bunk addonServiceId

      addonServicePromise.catch(err => {
        const {
          errors: [{
            status
          }]
        } = err;

        if (status === '404') {
          this.transitionToRoute('/404');
        } else {
          throw err;
        }
      }); // populate `addonServices.addonServicePlans`

      addonServicePromise.then(addonService => addonService.fetchServicePlans(store));
      return addonServicePromise;
    }),
    plan: (0, _object.computed)('planId', function () {
      const planId = this.planId;

      if (planId) {
        return this.store.findRecord('addon-service-plan', planId, {
          reload: false
        });
      }
    }),
    apps: (0, _computed.alias)('model.apps'),
    selectedApp: null,
    canBeProvisioned: (0, _computed.and)('selectedApp', 'plan'),
    publicInternetOnlyPlan: (0, _computed.not)('plan.installableInsidePrivateNetwork'),
    needsPlanConfirmation: (0, _computed.and)('selectedApp.isPrivateSpaceApp', 'publicInternetOnlyPlan'),
    canSubmit: (0, _object.computed)('canBeProvisioned', 'needsPlanConfirmation', 'confirmNotAutoNetworked', function () {
      if (this.canBeProvisioned) {
        return this.needsPlanConfirmation ? this.confirmNotAutoNetworked : true;
      }

      return false;
    }),
    cannotSubmit: (0, _computed.not)('canSubmit'),
    actions: {
      selectedAppDidChange(app) {
        this.set('selectedApp', app); // TODO v2
        // 1. fetch allowed addons list for the selected app
        // 2. present an error if the chosen addon is 👎
      },

      selectedPlanDidChange(planId) {
        if (planId) {
          this.set('planId', planId);
        }
      },

      submit() {
        const app = this.selectedApp;
        return this.addonService.then(addonService => this.plan.then(addonServicePlan => this.store.createRecord('addon', {
          app,
          addonService,
          confirmNotAutoNetworked: this.confirmNotAutoNetworked,
          addonServicePlan
        }).save())).then(() => {
          this.analytics.logEvent('Add-on', 'Provisioned');
          const justInstalledAddonServiceId = this.get('addonService.id');
          this.transitionToRoute('app.resources', app.get('slug'), {
            queryParams: {
              justInstalledAddonServiceId
            }
          });
        }).catch(err => {
          throw err;
        });
      }

    }
  });

  _exports.default = _default;
});