define("dashboard/models/space", ["exports", "@ember/object", "@ember-data/model", "dashboard/mixins/deploy-target", "dashboard/mixins/ds-error-monitoring-model"], function (_exports, _object, _model, _deployTarget, _dsErrorMonitoringModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal
  } = _object.computed;

  var _default = _model.default.extend(_deployTarget.default, _dsErrorMonitoringModel.default, {
    region: (0, _model.belongsTo)('region'),
    team: (0, _model.belongsTo)('team'),
    nat: (0, _model.belongsTo)('space/nat'),
    members: (0, _model.hasMany)('space/member'),
    inboundRuleset: (0, _model.belongsTo)('space/inbound-ruleset'),
    outboundRuleset: (0, _model.belongsTo)('space/outbound-ruleset'),
    vpnConnections: (0, _model.hasMany)('space/vpn-connection'),
    name: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    shield: (0, _model.attr)('boolean'),
    isAllocating: equal('state', 'allocating'),
    isReady: equal('state', 'allocated'),
    // Used by quick jump
    modelType: (0, _object.computed)(function () {
      return this.constructor.modelName;
    })
  });

  _exports.default = _default;
});