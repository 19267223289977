define("dashboard/initializers/modern-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals Modernizr */
  var _default = {
    name: 'modern-browser',

    initialize(app) {
      // wait to initialize until feature check
      app.deferReadiness();
      /**
       * Remove loading indicators, check for a touch interface, and start the application
       */

      app.isTouch = Modernizr.touch;
      app.advanceReadiness();
    }

  };
  _exports.default = _default;
});