define("dashboard/routes/new-app", ["exports", "rsvp", "@ember/routing/route", "dashboard/mixins/document-title", "dashboard/mixins/new-app-route", "@ember/service"], function (_exports, _rsvp, _route, _documentTitle, _newAppRoute, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, _newAppRoute.default, {
    documentTitle: 'Create New App',
    breadcrumbs: (0, _service.inject)(),

    model(params) {
      // Bounce to /new if user is trying to use a template/button
      if (params.templateURL) {
        return this.replaceWith('new');
      }

      const app = this.store.createRecord('app');
      return (0, _rsvp.hash)({
        app,
        spaces: this.store.findAll('space'),
        params,
        paymentMethod: this.store.queryRecord('payment-method', {})
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      const orgNameParam = model.params.orgNameParam;
      this.setupControllerQueryParams(orgNameParam); // Reset pipeline-select state

      controller.set('selectedPipeline', null);
      controller.set('selectedPipelineStage', 'staging');
    },

    renderTemplate() {
      this.set('breadcrumbs.trail', {
        location: 'Create New App'
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav'
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});