define("dashboard/models/client", ["exports", "rsvp", "@ember/object", "@ember/object/computed", "dashboard/utils/base-url", "dashboard/config/environment", "@ember-data/model"], function (_exports, _rsvp, _object, _computed, _baseUrl, _environment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    redirectUri: (0, _model.attr)('string'),
    secret: (0, _model.attr)('string'),
    // represents that the user viewing this client is not its owner
    isPrivate: (0, _computed.empty)('secret'),
    baseURL: (0, _object.computed)('redirectUri', function () {
      return (0, _baseUrl.default)(this.redirectUri);
    }),

    resetToken() {
      return new _rsvp.Promise((resolve, reject) => {
        this.store.adapterFor('client').ajax(`${_environment.default.platformApiUrl}/oauth/clients/${this.id}/actions/rotate-credentials`, 'POST', {
          dataType: 'json'
        }).then(_ref => {
          let {
            secret
          } = _ref;
          this.set('secret', secret);
          this.send('pushedData'); // mark as not dirty

          return resolve(secret);
        }, reject);
      });
    }

  });

  _exports.default = _default;
});