define("dashboard/templates/components/generic-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DG5EGWcM",
    "block": "[[[10,0],[14,0,\"limit-width\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-auto flex-column flex-row-ns items-center-ns\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-auto flex-column flex-row-ns items-center-ns\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"flex-shrink-0 mr2\"],[12],[1,\"\\n        \"],[8,[39,0],[[16,\"aria-hidden\",[52,[30,0,[\"hasIconTitle\"]],\"false\",\"true\"]]],[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[[30,0,[\"iconName\"]],[30,0,[\"iconClass\"]],[30,0,[\"iconSize\"]],[30,0,[\"iconTitle\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"f5 near-black pr2 pv1\"],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"          \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,0,[\"messageBannerText\"]]],[1,\"\\n\"],[41,[30,0,[\"devCenterLink\"]],[[[1,\"            \"],[10,3],[15,6,[30,0,[\"devCenterLink\"]]],[14,\"target\",\"_blank\"],[14,0,\"hk-link\"],[12],[1,\"Learn more\"],[13],[1,\".\\n\"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasDismissButton\"]],[[[1,\"        \"],[1,[28,[35,4],null,[[\"buttonType\",\"size\",\"default\",\"onClick\"],[\"secondary\",\"small\",[30,0,[\"dismissText\"]],[28,[37,5],[[30,0],[30,0,[\"didDismissBanner\"]]],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasPrimaryButton\"]],[[[1,\"        \"],[1,[28,[35,4],null,[[\"buttonType\",\"size\",\"default\",\"onClick\",\"class\"],[\"primary\",\"small\",[30,0,[\"confirmText\"]],[28,[37,5],[[30,0],[30,0,[\"didConfirmBanner\"]]],null],\"ml2\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"malibu-icon\",\"if\",\"has-block\",\"yield\",\"hk-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/generic-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});