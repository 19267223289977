define("dashboard/components/metrics/charts/visualizations/place-holder", ["exports", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart"], function (_exports, _wrappedLineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    tagName: 'metrics-placeholder-chart'
  });

  _exports.default = _default;
});