define("dashboard/components/third-party-authorizations-row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ThirdPartyAuthorizationsRowComponent extends _component.default {
    get githubAppInstallations() {
      return this.args.authorizedUser?.installations.filter(e => e);
    }

    get lastElementIndex() {
      return this.githubAppInstallations.length - 1;
    }

  }

  _exports.default = ThirdPartyAuthorizationsRowComponent;
});