define("dashboard/templates/components/pipeline/commit-status-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/e+Yel12",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"options\",\"state\"]],[30,0,[\"iconName\"]],[30,0,[\"iconClass\"]],12]],null],[1,\"\\n\"]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/commit-status-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});