define("dashboard/components/metrics/charts/connected-chart-hover/item", ["exports", "@ember/component", "dashboard/templates/components/metrics/charts/connected-chart-hover/item"], function (_exports, _component, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _item.default,
    tagName: 'metrics-magic-chart-context-item',
    classNames: ['metrics__magic-chart-context__item']
  });

  _exports.default = _default;
});