define("dashboard/templates/components/app/settings/app-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "y0ATZ32u",
    "block": "[[[41,[30,0,[\"cannotRenameApp\"]],[[[6,[39,1],null,[[\"class\"],[\"mb5\"]],[[\"default\"],[[[[6,[30,1,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"      You do not have permission to rename this app\\n\"]],[]]]]],[6,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"      \"],[1,[30,0,[\"renameLackOfPermissionMessage\"]]],[1,\"\\n\"]],[]]]]]],[1]]]]]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"label\",\"value\",\"id\",\"isDisabled\",\"onValueChanged\",\"onSave\"],[\"App Name\",[30,0,[\"renameableModel\",\"name\"]],\"app-rename-input\",[30,0,[\"cannotRenameApp\"]],[28,[37,3],[[30,0],\"setNameHasChanged\"],null],[28,[37,3],[[30,0],[30,0,[\"saveRename\"]]],null]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"nameHasChanged\"]],[[[1,\"  \"],[1,[28,[35,4],null,[[\"alertType\",\"icon\",\"title\",\"message\"],[\"warning\",\"warning-ring\",\"Warning! Renaming an app via Dashboard can break your git remotes.\",\"After renaming, you may need to <a href='https://devcenter.heroku.com/articles/renaming-apps#updating-git-remotes' target=_'blank' class='hk-link'>update your git remotes</a>.\"]]]],[1,\"\\n\"]],[]],null]],[\"well\"],false,[\"if\",\"hk-well\",\"hk-inline-edit\",\"action\",\"hk-message\"]]",
    "moduleName": "dashboard/templates/components/app/settings/app-name.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});