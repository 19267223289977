define("dashboard/components/malibu-icon-button", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Button:
   * - title
   *
   * Icon:
   * - icon name
   * - icon class
   * - icon size
   */
  var _default = _component.default.extend({
    tagName: ''
  });

  _exports.default = _default;
});