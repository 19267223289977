define("dashboard/components/payment-method/stripe-form", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "lodash-es/sortBy", "@ember/service", "dashboard/models/country", "dashboard/models/state", "ember-changeset", "@ember/string", "ember-changeset-validations", "dashboard/validations/payment-method", "ember-concurrency", "ember-data"], function (_exports, _component, _tracking, _object, _sortBy, _service, _country, _state, _emberChangeset, _string, _emberChangesetValidations, _paymentMethod, _emberConcurrency, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    AdapterError
  } = _emberData.default;
  let StripeFormComponent = (_dec = (0, _service.inject)('stripev3'), (_class = class StripeFormComponent extends _component.default {
    // TODO: Update the `stripe-form` component to use the same confirmation button logic as the
    // `payment-method/form` component. This is required to ensure that the eco subscription flow
    // continues to work correctly, transitioning from the billing form to the eco subscribe form.
    // TODO: Add support for the `title` attribute, similar to the `payment-method/form` component.
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "stripe", _descriptor, this);

      _initializerDefineProperty(this, "api", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "reporter", _descriptor5, this);

      _defineProperty(this, "containerId", 'payment-form-panel');

      _defineProperty(this, "options", {
        classes: {
          base: 'hk-input',
          invalid: 'b--light-red hk-focus-ring--red'
        },
        style: {
          base: {
            '::placeholder': {
              color: '#CFD7E6'
            }
          }
        }
      });

      _defineProperty(this, "stripeElement", null);

      _defineProperty(this, "stripeToken", null);

      _defineProperty(this, "paymentMethodSnapshot", null);

      _initializerDefineProperty(this, "cardComplete", _descriptor6, this);

      _initializerDefineProperty(this, "expirationComplete", _descriptor7, this);

      _initializerDefineProperty(this, "cvvComplete", _descriptor8, this);

      _initializerDefineProperty(this, "genericErrorMessage", _descriptor9, this);

      _initializerDefineProperty(this, "changesetErrors", _descriptor10, this);

      _initializerDefineProperty(this, "didValidate", _descriptor11, this);

      _initializerDefineProperty(this, "acceptedItalianCustomerTerms", _descriptor12, this);

      _initializerDefineProperty(this, "acceptedItalianPartnerTerms", _descriptor13, this);

      const country = args.paymentMethod.country || 'US';
      const changeset = new _emberChangeset.default(args.paymentMethod, (0, _emberChangesetValidations.default)(_paymentMethod.paymentMethodModelValidations), _paymentMethod.paymentMethodModelValidations, {
        skipValidation: true
      });
      changeset.set('country', country);
      this.changeset = changeset;
      this.changesetSnapshot = changeset.snapshot();
      this.prepareForm.perform();
    }

    *prepareForm() {
      yield this.stripe.load();
      yield this.fetchToken();
    }

    async fetchToken() {
      this.stripeToken = await this.args.paymentMethod.getClientToken();
      return this.stripeToken;
    }

    get stripeElementsOptions() {
      return {
        clientSecret: this.stripeToken
      };
    }

    get slidePanelTitle() {
      return this.args.title || 'Enter Your Payment Information';
    }

    get countries() {
      return _country.default.all();
    }

    get vatCountries() {
      return _country.default.vatApprovedCountryCodes();
    }

    get states() {
      return _state.default.all();
    }

    get currentCountry() {
      return this.changeset.get('country');
    }

    get isShowingItalianTerms() {
      return this.currentCountry === 'IT';
    }

    get invalidCardFieldsSorted() {
      return (0, _sortBy.default)(this.changesetErrors, ['key']);
    }

    get invalidCardFieldsMessage() {
      return this.invalidCardFieldsSorted.map(error => {
        return this._displayLabelForChangesetField(error.key);
      }).join(', ');
    }

    _displayLabelForChangesetField(fieldName) {
      return fieldName === 'cvv' ? 'CVV' : fieldName.underscore().split(/_/).map(str => (0, _string.capitalize)(str)).join(' ');
    }

    get cannotSubmit() {
      if (this.prepareForm.isRunning) {
        return true;
      } else if (!(this.cardComplete && this.expirationComplete && this.cvvComplete)) {
        return true;
      } else if (!this.isShowingItalianTerms) {
        return false;
      } else {
        return !this.acceptedItalianCustomerTerms || !this.acceptedItalianPartnerTerms;
      }
    }

    setChangesetValue(key, value) {
      this.changeset.set(key, value);
    }

    setStripeElement(stripeElement) {
      this.stripeElement = stripeElement;
    }

    handleCardChange(stripeElement, event) {
      this.cardComplete = !!event.complete;
    }

    handleExpirationChange(stripeElement, event) {
      this.expirationComplete = !!event.complete;
    }

    handleCvvChange(stripeElement, event) {
      this.cvvComplete = !!event.complete;
    }

    closeSlidePanel() {
      return this.closeForm();
    }

    async submitCard() {
      this.submitCardTask.perform();
      await (0, _emberConcurrency.waitForProperty)(this, 'didValidate', true);
      const currentAccount = this.current.account;
      const italianPartnerTerms = this.acceptedItalianPartnerTerms ? 'affirmatively_accepted' : 'non_applicable';
      const italianCustomerTerms = this.acceptedItalianCustomerTerms ? 'affirmatively_accepted' : 'non_applicable';
      currentAccount.set('italianCustomerTerms', italianCustomerTerms);
      currentAccount.set('italianPartnerTerms', italianPartnerTerms);
    }

    *submitCardTask() {
      this.didValidate = false;
      this.clearErrors();
      const {
        analyticsNoun
      } = this.args.paymentMethod;
      this.analytics.logEvent(`${analyticsNoun} Save Details`, 'Clicked');

      try {
        yield this.changeset.validate();

        if (this.changeset.isValid) {
          try {
            this.changeset.set('nonce', this.stripeToken);
            yield this.changeset.execute(); // We need to snapshot the submitted payment address information
            // because the API will not immediately update the object in Core

            this.paymentMethodSnapshot = this.args.paymentMethod.copy();
            const nonceSnapshot = this.args.paymentMethod.copy_as_nonce(); // 1) Create the Stripe payment method

            const {
              paymentMethod: {
                id: stripePaymentMethodId,
                card
              }
            } = yield this.createStripePaymentMethod();
            nonceSnapshot.set('card_type', card['brand']);
            nonceSnapshot.set('card_last4', card['last4']); // 2) Patch the payment method in Core

            yield this.savePayment(); // 3) Confirm the Stripe setup intent

            yield this.confirmStripeSetup(stripePaymentMethodId);

            if (typeof this.args.onSavePaymentMethod === 'function') {
              // Pass up the payment-method with nonce, card_type, and card_last4
              // to the parent component iff payment-method save was successful
              this.args.onSavePaymentMethod(nonceSnapshot);
            }

            yield this.closeForm(true);
          } catch (e) {
            this.handleErrors(e);
          }
        } else {
          console.log('Validation errors!', this.changeset.errors);
        }
      } finally {
        this.changesetErrors = this.changeset.errors || [];
        this.didValidate = true;

        if (this.changesetErrors && this.changesetErrors.length > 0 || this.genericErrorMessage) {
          document.getElementById(this.containerId).scrollTop = 0;
        }
      }
    }

    async savePayment() {
      const {
        analyticsNoun
      } = this.args.paymentMethod;

      try {
        await this.changeset.save().then(() => {
          this.analytics.logEvent(`${analyticsNoun}`, 'Updated');
        });
      } catch (e) {
        console.log('Saving payment error!', e);
        throw e;
      }
    }

    clearErrors() {
      this.genericErrorMessage = null;
      this.changesetErrors = [];
    }

    handleErrors(error) {
      if (error.error) {
        // Error response from Stripe
        this.genericErrorMessage = `${error.error.message}`;
      } else if (error instanceof AdapterError) {
        // Error response from API
        this.genericErrorMessage = error.errors.firstObject.detail;
        this.reporter.error(error);
      } else {
        this.reporter.error(error);
      }
    }

    async closeForm(wasVerified) {
      this.clearErrors();
      this.didValidate = false;

      if (wasVerified && typeof this.args.onVerified === 'function') {
        await this.args.onVerified();
      }

      if (!wasVerified) {
        this.changeset.restore(this.changesetSnapshot);
      }

      this.args.close();
    }

    async createStripePaymentMethod() {
      const paymentMethodData = {
        type: 'card',
        card: this.stripeElement,
        billing_details: {
          name: `${this.paymentMethodSnapshot.get('first_name')} ${this.paymentMethodSnapshot.get('last_name')}`,
          address: {
            city: this.paymentMethodSnapshot.get('city'),
            country: this.paymentMethodSnapshot.get('country'),
            line1: this.paymentMethodSnapshot.get('address_1'),
            line2: this.paymentMethodSnapshot.get('address_2'),
            postal_code: this.paymentMethodSnapshot.get('postal_code'),
            state: this.paymentMethodSnapshot.get('state')
          }
        }
      };
      const response = await this.stripe.createPaymentMethod(paymentMethodData);

      if (response.error) {
        this.reporter.rollbar.info('Failed to create stripe payment method', {
          error: response.error
        });
        throw response;
      }

      return response;
    }

    async confirmStripeSetup(stripePaymentMethodId) {
      const confirmData = {
        payment_method: stripePaymentMethodId
      };
      const confirmOptions = {
        handleActions: true
      }; // https://stripe.com/docs/js/setup_intents/confirm_card_setup

      const response = await this.stripe.confirmCardSetup(this.stripeToken, confirmData, confirmOptions);

      if (response.error) {
        this.reporter.rollbar.info('Failed to confirm stripe card', {
          error: response.error
        });
        throw response;
      }

      return response;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stripe", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "api", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "reporter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cardComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "expirationComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "cvvComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "genericErrorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "changesetErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "didValidate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "acceptedItalianCustomerTerms", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "acceptedItalianPartnerTerms", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "prepareForm", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "prepareForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setChangesetValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setChangesetValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setStripeElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setStripeElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCardChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCardChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleExpirationChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleExpirationChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCvvChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCvvChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeSlidePanel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeSlidePanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitCard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submitCard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitCardTask", [_emberConcurrency.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "submitCardTask"), _class.prototype)), _class));
  _exports.default = StripeFormComponent;
});