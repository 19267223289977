define("dashboard/mixins/metrics/app-state-path", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/object", "dashboard/mixins/metrics/metrics-query-params"], function (_exports, _computed, _mixin, _object, _metricsQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_metricsQueryParams.default, {
    isAlerting: (0, _computed.alias)('app.appState.alerting'),
    path: (0, _object.computed)('isAlerting', function () {
      const result = this.isAlerting ? 'app.metrics.type' : 'app.index';
      return result;
    }),
    queryParams: (0, _object.computed)('isAlerting', 'metricsQueryParams', function () {
      return this.isAlerting ? this.metricsQueryParams : null;
    }),
    models: (0, _object.computed)('name', 'isAlerting', function () {
      const models = [this.name];

      if (this.isAlerting) {
        models.push('web');
      }

      return models;
    })
  });

  _exports.default = _default;
});