define("dashboard/adapters/pipeline-coupling", ["exports", "dashboard/adapters/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_RANGE = {
    attribute: 'id',
    max: 1000
  };

  var _default = _application.default.extend({
    dsErrorMonitor: (0, _service.inject)(),
    version: '3.pipelines',
    range: DEFAULT_RANGE,

    handleResponse(status, headers, payload, requestData) {
      // A 404 on this endpoint just means the app isn't in a pipeline. This
      // override prevents ember-data from raising an error in the console and
      // failing the test suite.
      if (status === 404) {
        requestData.jqXHR.errorIsHandled = true;
      } // Persist the next-range so we know how to fetch the next page


      if (payload) {
        const nextRange = this.getHeaderValue('Next-Range', headers);
        payload.meta = {
          nextRange,
          status
        };
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForQuery(query) {
      const host = this.host;

      this._queryRange(query);

      if (query.personalOnly) {
        delete query.personalOnly;
        return `${host}/users/~/pipeline-couplings`;
      }

      if (query.team) {
        const team = query.team;
        delete query.team;
        return `${host}/teams/${team}/pipeline-couplings`;
      }

      const id = query.pipelineId;
      delete query['pipelineId'];
      return `${host}/pipelines/${id}/pipeline-couplings`;
    },

    urlForQueryRecord(query) {
      const host = this.host;

      if (query.app) {
        const app = query.app;
        delete query.app;
        return `${host}/apps/${app}/pipeline-couplings`;
      } else {
        return this._super(...arguments);
      }
    },

    findRecord() {
      this.set('range', null);
      return this._super(...arguments);
    },

    queryRecord() {
      this.set('range', null);
      return this._super(...arguments);
    },

    findAll() {
      this.set('range', DEFAULT_RANGE);
      return this._super(...arguments);
    },

    query() {
      this.set('range', DEFAULT_RANGE);
      return this._super(...arguments);
    },

    _queryRange(query) {
      if (query.range) {
        const range = query.range;
        delete query.range;
        this.set('range', range);
      }
    }

  });

  _exports.default = _default;
});