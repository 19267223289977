define("dashboard/serializers/account", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      if (hash) {
        hash.links = {
          githubUser: '/github/user',
          reposApiGithubToken: `/account/github`,
          githubAppInstallation: '/account/github/installation'
        };
      }

      return this._super.apply(this, arguments);
    },

    /**
     * This reduces serialized attributes to only ones that changed when sending
     * data to the platform api. Certain fields impose specific requirements
     * (like password confirmation) and we capture that in the UI for those
     * fields. This is kind of a hack but prevents us from having to have an array
     * of Account models for specific data sets.
     */
    serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    }

  });

  _exports.default = _default;
});