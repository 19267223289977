define("dashboard/serializers/shogun/postgres-credentials", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(_typeClass, hash) {
      const data = {};
      data.id = hash.id;
      data.credentials_list = hash;
      return this._super(_typeClass, data);
    }

  });

  _exports.default = _default;
});