define("dashboard/models/app/webhook", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    level: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    include: (0, _attr.default)({
      defaultValue: () => []
    }),
    lastActiveDelivery: (0, _attr.default)(),
    secret: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    app: (0, _relationships.belongsTo)('app')
  });

  _exports.default = _default;
});