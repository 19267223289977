define("dashboard/templates/components/list-group-item-purple2", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Zfr4uqKZ",
    "block": "[[[10,0],[15,0,[29,[\"panel-section \",[30,0,[\"sectionClass\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"section-description\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"section-title f3 purple\"],[15,\"role\",[52,[30,0,[\"title\"]],\"heading\",\"none\"]],[15,\"aria-level\",[52,[30,0,[\"title\"]],\"3\"]],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n\"],[41,[30,0,[\"beta\"]],[[[1,\"        \"],[10,1],[14,0,\"hk-badge--beta ml1\"],[12],[1,\"Beta\"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"newItem\"]],[[[1,\"        \"],[10,1],[14,0,\"hk-badge--new ml1\"],[12],[1,\"New!\"],[13],[1,\"\\n      \"]],[]],null]],[]]],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"description\"]],[[[1,\"      \"],[10,0],[14,0,\"mt2 f5 lh-copy dark-gray\"],[12],[1,\"\\n        \"],[2,[30,0,[\"description\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showDevCenterLink\"]],[[[1,\"      \"],[8,[39,1],[[16,6,[30,0,[\"devCenterUrl\"]]],[24,0,\"dib mt2\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"devCenterTitle\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"panel-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"enableItem\"]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],[[[6,[39,3],null,null,[[\"default\"],[[[[6,[30,1,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"          \"],[1,[30,0,[\"disabledTitle\"]]],[1,\"\\n\"]],[]]]]],[6,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"          \"],[1,[30,0,[\"disabledMessage\"]]],[1,\"\\n\"]],[]]]]]],[1]]]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"well\",\"&default\"],false,[\"if\",\"dev-center-link\",\"yield\",\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/list-group-item-purple2.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});