define("dashboard/models/invoice-address", ["exports", "@ember/object", "@ember-data/model"], function (_exports, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    useInvoiceAddress: (0, _model.attr)('boolean'),
    address_1: (0, _model.attr)('string'),
    address_2: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    postalCode: (0, _model.attr)('string'),
    other: (0, _model.attr)('string'),
    locality: (0, _object.computed)('_cityState', 'postalCode', function () {
      return $.trim(`${this._cityState} ${this.postalCode || ''}`);
    }),
    _cityState: (0, _object.computed)('city', 'state', function () {
      const [city, state] = [this.city, this.state];
      return city && state ? `${city}, ${state}` : city || state || '';
    })
  });

  _exports.default = _default;
});