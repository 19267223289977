define("dashboard/serializers/kolkrabbi/github-user", ["exports", "dashboard/serializers/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships() {
      const relationships = this._super(...arguments);

      relationships.githubAppLinks = {
        links: {
          related: `${_environment.default.kolkrabbiUrl}/apps`
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});