define("dashboard/components/qr-code", ["exports", "@ember/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Dashboard.QrCodeComponent
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).qrcode({
        text: this.url,
        width: 200,
        height: 200
      });
    },

    willDestroyElement() {
      (0, _jquery.default)(this.element).find('canvas').remove();

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});