define("dashboard/components/pagination-links", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/debug"], function (_exports, _component, _object, _computed, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Services
    media: (0, _service.inject)(),
    router: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Component
    classNames: ['flex'],
    // --------------------------------------------------------------------------
    // Arguments
    currentPage: 1,
    totalPages: 1,
    showPrevious: true,
    showNext: true,
    truncatePages: true,
    // --------------------------------------------------------------------------
    // Computed Properties
    isMobile: (0, _computed.alias)('media.isMobile'),
    // The number of pages to show around the current page, at the beginning, and
    // at the end when truncation is enabled.
    pageWindowSize: (0, _object.computed)('isMobile', function () {
      return this.isMobile ? 1 : 2;
    }),
    // Only truncate when more items than would be inluded when fully expanded,
    // like this example when current page is 10: `1 2 ... 8 9 10 11 12 ... 50 51`
    minPagesForTruncation: (0, _object.computed)('pageWindowSize', function () {
      return this.pageWindowSize * 4 + 3;
    }),
    hasPreviousPage: (0, _computed.gt)('currentPage', 1),
    previousPage: (0, _object.computed)('hasPreviousPage', 'currentPage', function () {
      return this.hasPreviousPage ? this.currentPage - 1 : null;
    }),
    hasNextPage: (0, _object.computed)('currentPage', 'totalPages', function () {
      return this.currentPage < this.totalPages;
    }),
    nextPage: (0, _object.computed)('hasNextPage', 'currentPage', function () {
      return this.hasNextPage ? this.currentPage + 1 : null;
    }),
    pageItems: (0, _object.computed)('currentPage', 'totalPages', 'truncatePages', 'minPagesForTruncation', function () {
      const totalPages = this.totalPages;
      const truncatePages = this.truncatePages;

      if (truncatePages && totalPages > this.minPagesForTruncation) {
        return this.truncatedPageNumberItems();
      } else {
        return this.allPageNumberItems();
      }
    }),
    previousPageItem: (0, _object.computed)('showPrevious', 'previousPage', 'hasPreviousPage', function () {
      if (this.showPrevious) {
        return {
          isPrevious: true,
          exists: this.hasPreviousPage,
          page: this.previousPage,
          url: this._urlForPage(this.previousPage)
        };
      }
    }),
    nextPageItem: (0, _object.computed)('showNext', 'nextPage', 'hasNextPage', function () {
      if (this.showNext) {
        return {
          isNext: true,
          exists: this.hasNextPage,
          page: this.nextPage,
          url: this._urlForPage(this.nextPage)
        };
      }
    }),
    previousText: (0, _object.computed)('isMobile', function () {
      return this.isMobile ? '' : 'Previous';
    }),
    nextText: (0, _object.computed)('isMobile', function () {
      return this.isMobile ? '' : 'Next';
    }),

    // --------------------------------------------------------------------------
    // Lifecycle
    didReceiveAttrs() {
      this._super(...arguments);

      (false && !(typeof this.updatePage !== 'function' || typeof this.urlForPage === 'function') && (0, _debug.assert)('When providing @updatePage to <PaginationLinks> please also provide @urlForPage. ' + '@urlForPage may return an empty string or "#" if no URL if required.', typeof this.updatePage !== 'function' || typeof this.urlForPage === 'function'));
    },

    // --------------------------------------------------------------------------
    // Helpers
    // Returns a list of truncated page items. Includes the window around the
    // current page, plus the beginning and ending windows and any gaps, if necessary.
    truncatedPageNumberItems() {
      const pageWindowSize = this.pageWindowSize;
      const minPagesForTruncation = this.minPagesForTruncation;
      const currentPage = this.currentPage;
      const totalPages = this.totalPages; // Start and end window for currentPage.

      let currentWindowStart = currentPage - pageWindowSize;
      let currentWindowEnd = currentPage + pageWindowSize;

      if (currentWindowStart < 1) {
        const shiftBy = currentWindowStart * -1 + 1;
        currentWindowStart += shiftBy;
        currentWindowEnd += shiftBy;
      } else if (currentWindowEnd > totalPages) {
        const shiftBy = currentWindowEnd - totalPages;
        currentWindowStart -= shiftBy;
        currentWindowEnd -= shiftBy;
      } // We always want to show at least `minPagesForTruncation` items, so pad
      // out beginning or end if we're close enough to either to only show 2
      // windows with 1 gap.


      const largeWindowSize = minPagesForTruncation - pageWindowSize - 1;

      if (currentWindowEnd < largeWindowSize) {
        currentWindowEnd = largeWindowSize;
      } else if (currentWindowStart > totalPages - largeWindowSize + 1) {
        currentWindowStart = totalPages - largeWindowSize + 1;
      }

      const pageNumbers = new Set(); // Add beginning window.

      for (let i = 1; i <= pageWindowSize; i++) {
        pageNumbers.add(i);
      } // Add currentPage window.


      for (let i = currentWindowStart; i <= currentWindowEnd; i++) {
        pageNumbers.add(i);
      } // Add ending window.


      const endWindowStart = totalPages - pageWindowSize + 1;

      for (let i = endWindowStart; i <= totalPages; i++) {
        pageNumbers.add(i);
      } // Convert to objects and add gaps where necessary.


      let lastPage;
      return Array.from(pageNumbers).reduce((pageItems, page) => {
        // Add a gap if we've jumped more than one page.
        if (lastPage && page - lastPage > 1) {
          pageItems.addObject({
            isGap: true
          });
        }

        pageItems.addObject({
          isPage: true,
          page,
          current: page === currentPage,
          url: this._urlForPage(page)
        });
        lastPage = page;
        return pageItems;
      }, []);
    },

    allPageNumberItems() {
      const currentPage = this.currentPage;
      const totalPages = this.totalPages;
      return Array(totalPages).fill().map((_, index) => {
        const page = index + 1;
        return {
          isPage: true,
          page,
          current: page === currentPage,
          url: this._urlForPage(page)
        };
      });
    },

    _urlForPage(page) {
      if (typeof this.urlForPage === 'function') {
        return this.urlForPage(page);
      } else {
        const {
          router
        } = this;
        const routeName = router.currentRouteName;
        const options = {
          queryParams: {
            page
          }
        };
        return router.urlFor(routeName, options);
      }
    },

    actions: {
      updatePage(page) {
        if (this.updatePage) {
          this.updatePage(page);
        } else {
          this.router.transitionTo({
            queryParams: {
              page
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});