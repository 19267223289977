define("dashboard/components/tag-toggle", ["exports", "@ember/object", "@ember/component", "@ember/object/computed", "dashboard/mixins/tooltipable"], function (_exports, _object, _component, _computed, _tooltipable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Toggle is a clickable boolean UI control.
   *
   * @module TagToggle
   * @param name String The text on the toggle.
   * @param value Boolean Is it checked?
   * @param isToggleable Boolean Show icons and allow toggling?
   * @param isLocked Boolean Show lock icon and prevent toggling?
   * @param helpText String Additional contextual help
   * @param action String Name of action to call when a permission is toggled
   */
  var _default = _component.default.extend(_tooltipable.default, {
    tagName: 'span',
    classNameBindings: [':tag-toggle', 'value:checked:unchecked', 'showIcon', 'isLocked:locked', 'isActionable:actionable', 'isActionPrevented:nonactionable'],
    isUnlocked: (0, _computed.not)('isLocked'),
    isActionable: (0, _computed.and)('isToggleable', 'isUnlocked'),
    isActionPrevented: (0, _computed.and)('isToggleable', 'isLocked'),
    isHovering: (0, _computed.and)('isActionable', 'hovering'),
    iconColor: (0, _object.computed)('value', 'isHovering', function () {
      const value = this.value;
      const isHovering = this.isHovering;

      if (value && !isHovering) {
        return 'fill-white';
      }

      if (value === isHovering) {
        return 'fill-gray';
      }

      if (!value && isHovering) {
        return 'fill-purple';
      }
    }),
    showIcon: (0, _computed.and)('isToggleable', 'isLocked'),
    tooltipOptions: (0, _object.computed)('helpText', function () {
      return {
        title: this.helpText,
        delay: {
          show: 750,
          hide: 100
        }
      };
    }),

    mouseEnter() {
      this.set('hovering', true);
    },

    mouseLeave() {
      this.set('hovering', false);
    },

    click() {
      if (this.isActionable) {
        this.toggleAction();
      }
    }

  });

  _exports.default = _default;
});