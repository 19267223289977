define("dashboard/components/metrics/charts/connected-chart-hover/group", ["exports", "@ember/object/computed", "@ember/component"], function (_exports, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'metrics-magic-chart-context-group',
    classNames: ['metrics__magic-chart-context__group'],
    isVisible: (0, _computed.notEmpty)('graph.selectedValue')
  });

  _exports.default = _default;
});