define("dashboard/components/metrics/charts/selected-time-hover", ["exports", "@ember/string", "@ember/object", "@ember/component", "moment"], function (_exports, _string, _object, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the individual time banner for a chart that displays the currently selected
   * time
   */
  var _default = _component.default.extend({
    tagName: 'metrics-chart-time',
    classNameBindings: [':metrics__chart-time', 'chartIsFocused::hidden'],
    attributeBindings: ['style'],

    /**
     * a moment.js time object that corresponds to the selected time of
     * the chart
     */
    _timeObject: (0, _object.computed)('selectedRollupTime', 'timezone', function () {
      const time = this.get('selectedRollupTime');

      if (time) {
        const timeObject = (0, _moment.default)(time).utc().tz(this.timezone);
        return timeObject;
      }
    }),

    /**
     * the formatted hour time to display
     */
    displayTime: (0, _object.computed)('_timeObject', 'rollupDuration', function () {
      const time = this._timeObject;
      const rollupDuration = this.rollupDuration;

      if (time) {
        const startTime = time.format('HH:mm');
        const endTime = time.add(rollupDuration).format('HH:mm');
        return `${startTime} - ${endTime}`;
      }
    }),

    /**
     * the formatted time zone to display
     */
    displayTimezone: (0, _object.computed)('_timeObject', function () {
      const time = this._timeObject;

      if (time) {
        return time.format('z');
      }
    }),

    /**
     * the formatted date to display
     */
    displayDate: (0, _object.computed)('_timeObject', function () {
      const time = this._timeObject;

      if (time) {
        return time.format('ddd Do');
      }
    }),

    /**
     * an observer that implements cross-browser compatible transform
     * on the x offset of the banner
     */
    style: (0, _object.computed)('selectedTimeX', function () {
      const x = this.selectedTimeX || 0;
      const translateX = `translateX(${x}px)`;
      const translateZ = 'translateZ(0)'; // put on gpu for performance improvements

      const style = `transform: ${translateX} ${translateZ}`;
      return (0, _string.htmlSafe)(style);
    })
  });

  _exports.default = _default;
});