define("dashboard/templates/components/commit-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "khl4+cvD",
    "block": "[[[10,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"commit-meta\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[\"Commit\",\"git-commit-16\",16,\"o-80\"]],null],[1,\"\\n    \"],[10,3],[15,6,[29,[[30,0,[\"commitUrl\"]]]]],[14,\"target\",\"_blank\"],[14,0,\"hk-link\"],[12],[10,\"code\"],[14,0,\"commit-sha\"],[12],[1,[30,0,[\"shortSha\"]]],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"commit-message\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"commit-subject\"],[12],[1,[28,[35,1],[[30,0,[\"subject\"]],[30,0,[\"truncationPoint\"]],\"...\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"canExpand\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,3],[[30,0],\"toggle\"],null],[12],[1,\"...\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isShowingBody\"]],[[[1,\"      \"],[10,\"small\"],[14,0,\"commit-description\"],[12],[1,[34,4]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"pullRequest\"]],[[[1,\"    \"],[10,0],[14,0,\"ci-view--pr\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[\"Pull request\",\"git-pr-16\",16,\"mr1\"]],null],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,0,[\"pullRequest\",\"htmlUrl\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link underline\"],[12],[1,\"\\n        #\"],[1,[30,0,[\"pullRequest\",\"number\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"truncate\",\"if\",\"action\",\"body\"]]",
    "moduleName": "dashboard/templates/components/commit-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});