define("dashboard/templates/components/ci/new-test-run/drop-down-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ElhQxT6i",
    "block": "[[[10,1],[14,0,\"branch\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[\"git-branch-16\",16,\"fill-gray\",\"Branch\"]],null],[1,\"\\n\\n  \"],[1,[30,0,[\"content\"]]],[1,\"\\n\\n  \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"caret-16\",16,\"fill-gray\"]],null],[1,\"\\n\"],[13]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/ci/new-test-run/drop-down-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});