define("dashboard/templates/components/app/settings/buildpack-installations-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z6wSGjjm",
    "block": "[[[10,\"td\"],[15,\"title\",[30,0,[\"buildpack\",\"buildpackName\"]]],[14,0,\"flex items-center\"],[12],[1,\"\\n\"],[44,[[30,1]],[[[1,\"      \"],[8,[30,2],null,null,[[\"default\"],[[[[1,\"\\n        \"],[41,[30,0,[\"isReorderable\"]],[[[8,[39,2],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"handle-16\",\"handle fill-light-gray ml1 mr2\",16,\"Reorder buildpack\"]],null]],[]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[2]]],[1,\"  \"],[10,0],[14,0,\"inline-flex flex-shrink-0 items-center justify-center w2 h2 bg-lightest-purple br1 ml1 mr2\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"language\",\"size\",\"iconClass\"],[[30,0,[\"buildpack\",\"name\"]],\"16\",\"malibu-fill-gradient-purple\"]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"value\"],[[30,0,[\"buildpack\",\"name\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isSetViaConfigVar\"]],[[[1,\"  \"],[10,\"td\"],[14,0,\"action-cell\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"gray\"],[12],[1,\"\\n      Set via \"],[10,\"code\"],[12],[1,\"BUILDPACK_URL\"],[13],[1,\" config var\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"canEdit\",\"canRemove\",\"model\",\"editAction\",\"confirmDeleteAction\"],[false,true,[30,0,[\"buildpack\"]],[28,[37,6],[[30,0],\"edit\"],null],[28,[37,6],[[30,0],\"confirmDelete\"],null]]]]],[1,\"\\n\"]],[]]]],[\"@handle\",\"Handle\"],false,[\"let\",\"if\",\"malibu-icon\",\"buildpack-icon\",\"copyable-input\",\"purple-list-action-cell\",\"action\"]]",
    "moduleName": "dashboard/templates/components/app/settings/buildpack-installations-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});