define("dashboard/mixins/new-app", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object/mixin", "rsvp", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _computed, _controller, _mixin, _rsvp, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    bool,
    notEmpty,
    not,
    or
  } = _object.computed;

  var _default = _mixin.default.create({
    current: (0, _service.inject)(),
    account: alias('current.account'),
    accountFeatures: (0, _service.inject)(),
    headerTitle: 'App',
    queryParams: {
      templateURL: 'template',
      buttonURL: 'button-url',
      orgNameParam: 'org',
      spaceNameParam: 'space'
    },
    templateURL: null,
    buttonURL: null,
    orgNameParam: null,
    spaceNameParam: null,
    appNameIsAvailable: false,
    analytics: (0, _service.inject)(),
    protectedController: (0, _controller.inject)('protected'),
    app: alias('model.app'),
    teams: (0, _object.computed)('protectedController.model.teams.@each.role', function () {
      return this.get('protectedController.model.teams').rejectBy('role', 'collaborator').rejectBy('role', 'viewer');
    }),
    regions: alias('protectedController.model.regions'),
    commonRuntimes: (0, _object.computed)('regions', function () {
      return this.regions.filter(region => {
        return region.get('name') === 'eu' || region.get('name') === 'us';
      });
    }),
    runtimesSorting: ['name:desc'],
    sortedCommonRuntimes: (0, _computed.sort)('commonRuntimes', 'runtimesSorting'),
    spaces: alias('model.spaces'),
    _orgSpaces: (0, _object.computed)('spaces.[]', 'orgNameParam', function () {
      return this.spaces.filterBy('team.name', this.orgNameParam);
    }),
    canManageSpaces: notEmpty('_orgSpaces'),
    orgSpaceOptions: (0, _object.computed)('_orgSpaces.[]', function () {
      return this._orgSpaces.map(function (space) {
        const state = space.get('state');
        const isReady = space.get('isReady');
        const name = `${space.get('name')}, ${space.get('region.locale')}`;
        const id = space.get('name');
        const label = isReady ? name : `${name} (${state})`;
        return _object.default.create({
          name,
          label,
          id,
          disabled: !isReady
        });
      });
    }),
    selectedOrg: (0, _object.computed)('teams', 'orgNameParam', function () {
      if ((0, _utils.isPresent)(this.teams)) {
        return this.teams.findBy('name', this.orgNameParam);
      }
    }),
    userName: (0, _object.computed)('account', function () {
      const accountName = this.get('account.name');
      const accountEmail = this.get('account.email');
      return (0, _utils.isPresent)(accountName) ? `${accountName} (${accountEmail})` : accountEmail;
    }),
    selectedRegionName: (0, _object.computed)(function () {
      return 'us';
    }),
    selectedRegion: (0, _object.computed)('commonRuntimes', 'selectedRegionName', function () {
      return this.commonRuntimes.findBy('name', this.selectedRegionName);
    }),
    // Get the selected space model, with assurance that its in the selected org.
    selectedSpace: (0, _object.computed)('_orgSpaces.@each.name', 'spaceNameParam', function () {
      if ((0, _utils.isPresent)(this._orgSpaces) && (0, _utils.isPresent)(this.spaceNameParam)) {
        return this._orgSpaces.findBy('name', this.spaceNameParam) || this.get('_orgSpaces.firstObject');
      }
    }),
    regionLabel: (0, _object.computed)('canManageSpaces', function () {
      if (this.canManageSpaces) {
        return 'Choose a region or a Private Space';
      } else {
        return 'Choose a region';
      }
    }),
    isSaving: or('app.isSaving'),
    appPersisted: bool('app.id'),
    hasPipelineSelection: notEmpty('selectedPipeline'),
    hasValidPipelineSelection: (0, _object.computed)('selectedPipeline', 'selectedPipelineModel', 'selectedPipelineModel.name', function () {
      const selectedPipeline = this.selectedPipeline;
      const selectedPipelineModel = this.selectedPipelineModel;

      if (selectedPipeline) {
        return selectedPipelineModel.get('nameIsValid') && (0, _utils.isPresent)(selectedPipelineModel.get('name'));
      } else {
        return true;
      }
    }),
    hasInvalidPipelineSelection: not('hasValidPipelineSelection'),
    pipelineNameIsInvalid: or('selectedPipelineModel.nameIsInvalid', 'selectedPipelineModel.nameIsEmpty'),
    pipelineNameIsValid: not('pipelineNameIsInvalid'),
    inputClass: (0, _object.computed)('pipelineNameIsValid', 'selectedPipelineModel.nameIsInvalid', function () {
      if (this.get('selectedPipelineModel.nameIsInvalid')) {
        return 'hk-input w-100 br--left z-1 b--red hk-focus-ring--red';
      } else if (this.pipelineNameIsValid) {
        return 'hk-input w-100 br--left z-1 b--green hk-focus-ring--green';
      } else {
        return 'hk-input w-100';
      }
    }),
    isDisabledInput: or('isSaving', 'appPersisted'),
    isNotDisabledInput: not('isDisabledInput'),
    isDisabledCreate: or('isDisabledInput', 'hasInvalidPipelineSelection'),
    _notUsingPersonalAppOwner: notEmpty('orgNameParam'),
    _doesNotNeedCreditCard: or('account.isOhana', 'model.paymentMethod.cardPresent', '_notUsingPersonalAppOwner'),
    mustAddCreditCard: not('_doesNotNeedCreditCard'),

    requestVerification() {
      this.set('showVerify', true);
    },

    selectedPipeline: null,
    selectedPipelineStage: 'staging',
    selectedPipelineModel: (0, _object.computed)('selectedPipeline', 'ownerId', 'ownerType', 'app.name', 'appNameIsAvailable', function () {
      const selectedPipeline = this.selectedPipeline;

      if (!selectedPipeline) {
        return null;
      }

      if (selectedPipeline === 'create') {
        const pipeline = this.store.createRecord('pipeline');
        const appName = this.get('app.name');
        const appNameIsAvailable = this.appNameIsAvailable;
        const ownerId = this.ownerId;
        const ownerType = this.ownerType;
        pipeline.set('owner', {
          id: ownerId,
          type: ownerType
        });

        if ((0, _utils.isPresent)(appName) && appNameIsAvailable) {
          pipeline.set('name', appName);
        }

        return pipeline;
      } else {
        return selectedPipeline;
      }
    }),
    ownerId: (0, _object.computed)('teams', 'orgNameParam', 'account', function () {
      const selectedOrgParam = this.orgNameParam;
      let defaultOwner;

      if (selectedOrgParam) {
        defaultOwner = this.teams.findBy('name', selectedOrgParam);
      } else {
        defaultOwner = this.account;
      }

      return defaultOwner.get('id');
    }),
    // This makes the assumption that the only "user" could be assigned ownership to
    // is the own personal account. This will need to change if we eventually let
    // users create pipelines in behalf other personal accounts.
    ownerType: (0, _object.computed)('ownerId', 'account', function () {
      return this.ownerId === this.get('account.id') ? 'user' : 'team';
    }),
    selectedPipelineDisplayName: (0, _object.computed)('selectedPipelineModel', 'selectedPipelineModel.name', function () {
      const selectedPipelineModel = this.selectedPipelineModel;

      if ((0, _utils.isPresent)(selectedPipelineModel) && (0, _utils.isPresent)(selectedPipelineModel.get('name')) && selectedPipelineModel.get('nameIsValid')) {
        return selectedPipelineModel.get('name');
      }
    }),

    _savePipeline(pipeline) {
      if (pipeline) {
        const isPersisted = pipeline.get('isPersisted');
        pipeline.set('collaboratorsEnabled', true);
        const promise = isPersisted ? _rsvp.default.resolve(pipeline) : pipeline.save();
        return promise;
      } else {
        return _rsvp.default.resolve();
      }
    },

    // This is mostly stolen from pipeline-stage component
    // This logic is a good refactor candidate for a service, since we are now
    // coupling apps from different parts of Dashboard
    _addCoupling(pipeline, app) {
      const stage = this.selectedPipelineStage;
      const coupling = this.store.createRecord('pipelineCoupling', {
        stage,
        pipeline,
        app
      });
      return coupling.save().then(() => {
        this.analytics.logEvent('Pipeline Coupling', 'Created', {
          status: 'succeeded'
        });
        pipeline.addCoupling(coupling);
      }).catch(() => {
        this.analytics.logEvent('Pipeline Coupling', 'Created', {
          status: 'failed'
        });
        return pipeline.removeCoupling(coupling);
      });
    },

    _coupleAppToPipeline(pipeline, app) {
      if (pipeline) {
        return this._addCoupling(pipeline, app);
      } else {
        return (0, _rsvp.resolve)();
      }
    },

    willDestroy() {
      const pipeline = this.selectedPipelineModel;

      if (pipeline && pipeline.get('isNew')) {
        pipeline.unloadRecord();
      }

      this._super(...arguments);
    },

    actions: {
      // Ensure the org query parameter is up-to-date.
      setOrgParam(orgName) {
        this.set('selectedPipeline', null);
        this.set('orgNameParam', orgName);
        this.set('spaceNameParam', this.get('selectedSpace.name') || null);
      },

      closeVerifyModal() {
        this.set('showVerify', false);
      },

      showPipelineConfig() {
        this.set('isShowingPipelineConfig', true);
      },

      hidePipelineConfig() {
        this.set('isShowingPipelineConfig', false);
      }

    }
  });

  _exports.default = _default;
});