define("dashboard/mixins/error-monitoring-model", ["exports", "@ember/object/mixin", "@ember/object", "@ember/application", "dashboard/utils/anchorify"], function (_exports, _mixin, _object, _application, _anchorify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    gt,
    not
  } = _object.computed;

  var _default = _mixin.default.create({
    /**
     * Called internally by the RestModel class when an AJAX call is not
     * successful.
     *
     * @param {jQuery XMLHttpRequest} jqXHR the failed AJAX request
     */
    assignErrors(jqXHR) {
      if (!jqXHR.suppressErrors) {
        jqXHR.errorIsHandled = true;
        this.set('errorContainer.xhr', jqXHR);
      }
    },

    /**
     * workaround for the fact that we cannot use Ember.inject.service()
     * in a plan Ember Object like this
     */
    errorMonitor: (0, _object.computed)(function () {
      const owner = (0, _application.getOwner)(this);

      if (owner && owner.lookup) {
        return owner.lookup('service:errorMonitor');
      } else {
        throw new Error('an ErrorMonitoringModel tried to access the model application instance which does not exist. If you are testing make sure to instantiate models with an owner (`Model.create(this.ownerInjection)`) or via `this.owner.factoryFor(...)`.');
      }
    }),
    errorContainer: (0, _object.computed)('errorMonitor.errors.@each.model', function () {
      let container = this.get('errorMonitor.errors').findBy('model', this);

      if (!container) {
        container = this.errorMonitor.createErrorContainer({
          model: this
        });
      }

      return container;
    }),
    errors: alias('errorContainer.content'),
    hasErrors: gt('errors.length', 0),
    hasNoErrors: not('hasErrors'),
    removeOnDestroy: (0, _object.observer)('isDestroying', function () {
      this.constructor.removeErrors(this);
    }),
    wipeOnSaving: (0, _object.observer)('isSaving', function () {
      if (this.isSaving) {
        this.errors.clear();
      }
    }),
    errorSubjectNoun: (0, _object.computed)(function () {
      return 'Item';
    }),

    errorResponseParser(response) {
      const flatError = response.error || response.message || response['error_message'];

      if (flatError) {
        return [(0, _anchorify.default)(flatError)];
      } else {
        return response.errors && response.errors.map(err => (0, _anchorify.default)(err));
      }
    }

  });

  _exports.default = _default;
});