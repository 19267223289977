define("dashboard/models/metrics/dyno-load", ["exports", "dashboard/models/metrics"], function (_exports, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;
  /**
   * the DynoLoad model
   *
   */

  var _default = _metrics.default.extend({
    meanData: rawDataWithDefault('load.avg.1m.mean'),
    maxData: rawDataWithDefault('load.avg.1m.max')
  });

  _exports.default = _default;
});