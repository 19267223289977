define("dashboard/components/confirm/with-validation", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    actions: {
      validate(confirmDeleteName) {
        this['on-validate'](confirmDeleteName);
      }

    }
  });

  _exports.default = _default;
});