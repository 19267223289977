define("dashboard/templates/components/net-promoter-score/why-textarea", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XqECmqCo",
    "block": "[[[10,2],[14,0,\"f3 gray\"],[12],[1,[30,0,[\"titleText\"]]],[13],[1,\"\\n\"],[1,[28,[35,0],null,[[\"class\",\"rows\",\"value\",\"placeholder\",\"autofocus\"],[\"hk-input w-100 mb3\",\"8\",[30,0,[\"npsSurvey\",\"whyDescription\"]],[30,0,[\"placeholderText\"]],true]]]],[1,\"\\n\\n\"]],[],false,[\"textarea\"]]",
    "moduleName": "dashboard/templates/components/net-promoter-score/why-textarea.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});