define("dashboard/templates/components/metrics/app-guidance/active-alerts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tPtu+c5r",
    "block": "[[[10,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"activeAlertDescriptions\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Alert\",\"alert-badge-16\",\"malibu-fill-gradient-red nudge-up--1\",13]],null],[10,1],[14,0,\"dark-gray\"],[12],[1,[30,0,[\"title\"]]],[1,\":\"],[13],[1,\" \"],[10,1],[14,0,\"gray\"],[12],[1,[30,1,[\"text\"]]],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"hideWebNavigation\"]]],[[[1,\"  \"],[10,\"ul\"],[14,0,\"metrics__app-guidance__action-list\"],[12],[1,\"\\n    \"],[10,\"li\"],[14,0,\"metrics__app-guidance__action-list__item\"],[12],[1,\"\\n\"],[6,[39,4],null,[[\"route\",\"models\"],[\"app.metrics.type\",[28,[37,5],[[30,0,[\"app\",\"name\"]],\"web\"],null]]],[[\"default\"],[[[[1,\"        View alerts\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"alertDescription\"],false,[\"each\",\"-track-array\",\"malibu-icon\",\"unless\",\"link-to\",\"array\"]]",
    "moduleName": "dashboard/templates/components/metrics/app-guidance/active-alerts.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});