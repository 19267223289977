define("dashboard/components/metrics/charts/zone-elements/alert-zones", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'g',
    classNames: ['metrics__timeseries-chart__alert-zones']
  });

  _exports.default = _default;
});