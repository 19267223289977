define("dashboard/serializers/pipeline-stage-config-var", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // Called when serializing a create/update/delete request. This converts
    // typical objects like { 'key': 'SUP', 'value': 'nothin' } to the format
    // the server expects like { 'SUP': 'nothin' }.
    serialize() {
      const {
        key,
        value
      } = this._super(...arguments);

      return {
        [key]: value
      };
    },

    // Called when fetching a pipeline config var collection.
    // This converts wacky collection responses from the server like
    // { 'FOO': 'bar', 'BAZ': 'qux' } to more compatible arrays of objects like
    // [ { key: 'FOO', value: 'bar' }, { 'key': 'BAZ', 'value': 'qux' } ]
    normalizeArrayResponse(store, klass, payload, id, requestType) {
      if (Array.isArray(payload)) {
        return this._super(arguments);
      } else {
        // Here, we pull `stage` and `pipeline` off the hash of config variables for three reasons:
        // 1. So we don't pollute the actual set of environment variables when pushing these records
        // into the ED store
        // 2. So we can add `stage` as a top-level property on the config var model
        // 3. So we can use both the pipeline ID and stage name to construct a unique ID so that ED
        // doesn't get confused when people have config vars with the same name for both review apps
        // and Heroku CI
        const {
          stage,
          pipeline,
          vars
        } = payload;
        const convertedPayload = Object.keys(vars).reduce((items, key) => {
          items.push({
            id: `${pipeline}-${key}-${stage}`,
            key,
            value: vars[key],
            stage
          });
          return items;
        }, []);
        return this._super(store, klass, convertedPayload, id, requestType);
      }
    },

    // Called when evaluating the response after creating/updating/deleting a
    // pipeline config var. This converts wacky single config var responses from
    // the server like { 'KEY': 'val' } to a more compatible object like
    // { 'key': 'KEY', 'value': 'val'}.
    // This method also manually adds `stage` to the response so that the ED model is correctly updated
    // after creating the record in ED and saving it.
    normalizeSingleResponse(store, klass, payload, id, requestType) {
      const {
        stage,
        key,
        vars
      } = payload;

      if (vars && key) {
        payload = {
          id,
          key,
          value: vars[key],
          stage
        };
      }

      return this._super(store, klass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});