define("dashboard/components/metrics/language-metrics-discovery", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex', 'justify-between', 'pa3', 'bt', 'b--light-silver', 'f5'],
    'data-test-target': 'language-metrics-discovery',
    actions: {
      dismissalAction() {
        this.dismissalAction();
      }

    }
  });

  _exports.default = _default;
});