define("dashboard/serializers/build-result", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractId(modelClass, resourceHash) {
      return resourceHash.build.id;
    },

    extractAttributes(modelClass, resourceHash) {
      const attrs = {};
      attrs['status'] = resourceHash.build.status;
      attrs['outputStreamUrl'] = resourceHash.build.output_stream_url;
      return attrs;
    }

  });

  _exports.default = _default;
});