define("dashboard/components/metrics/chart-controls/process-option", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNameBindings: [':metrics__process-switcher__item', ':flex', 'selectable:selectable'],
    app: (0, _computed.readOnly)('options.app'),
    process: (0, _computed.readOnly)('options.process'),
    selectedProcess: (0, _computed.readOnly)('options.selectedProcess'),
    showIcon: (0, _computed.readOnly)('options.showIcon'),
    selectable: (0, _computed.readOnly)('options.selectable')
  });

  _exports.default = _default;
});