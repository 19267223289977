define("dashboard/components/account/api-key", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/runloop", "dashboard/config/environment"], function (_exports, _component, _object, _computed, _service, _runloop, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    token: (0, _computed.alias)('authorization.accessToken.token'),
    showRegenerateConfirmation: false,
    showSuccessMessage: false,
    displaySuccessDuration: 2000,
    codeUpdatedClass: (0, _object.computed)('showSuccessMessage', function () {
      return this.showSuccessMessage ? 'success' : '';
    }),

    temporarilyShowSuccessIndicator() {
      this.set('showSuccessMessage', true); // This significantly slows the acceptance tests

      if (_environment.default.environment !== 'test') {
        // Hide the success indicator after 2 seconds
        (0, _runloop.later)(() => {
          const stillExists = !this.isDestroyed && !this.isDestroying;

          if (stillExists) {
            this.set('showSuccessMessage', false);
          }
        }, this.displaySuccessDuration);
      }
    },

    actions: {
      regenerateKey() {
        const pendingRegenerate = this.authorization.regenerate();
        return pendingRegenerate.then(() => {
          this.set('showRegenerateConfirmation', false);
          this.temporarilyShowSuccessIndicator();
          this.analytics.logEvent('API Key', 'Regenerated');
        });
      }

    }
  });

  _exports.default = _default;
});