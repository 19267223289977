define("dashboard/initializers/github-inflector-rules", ["exports", "ember-data-github/initializers/github-inflector-rules"], function (_exports, _githubInflectorRules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _githubInflectorRules.default;
    }
  });
});