define("dashboard/templates/components/metrics/charts/chart-content/row-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xN1gYAmn",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"chartContentData\",\"rowEvents\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"name\",\"chartContextGroupComponentName\",\"description\",\"level\",\"indicatorIcon\",\"models\",\"url\",\"registerChartElement\",\"unregisterChartElement\",\"timeScale\",\"minTime\",\"maxTime\",\"width\",\"rollupDuration\",\"selectedRollupTime\",\"graphs\"],[[30,1,[\"name\"]],[30,1,[\"chartContextGroupComponentName\"]],[30,1,[\"description\"]],[30,1,[\"level\"]],[30,1,[\"indicatorIcon\"]],[30,1,[\"models\"]],[30,1,[\"url\"]],[30,0,[\"registerChartElement\"]],[30,0,[\"unregisterChartElement\"]],[30,0,[\"timeScale\"]],[30,0,[\"minTime\"]],[30,0,[\"maxTime\"]],[30,0,[\"chartDimensions\",\"innerWidth\"]],[30,0,[\"rollupDuration\"]],[30,0,[\"selectedRollupTime\"]],[30,0,[\"graphs\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"chartContentData\",\"errorEvents\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"errors\",\"registerChartElement\",\"unregisterChartElement\",\"timeScale\",\"minTime\",\"maxTime\",\"rollupDuration\",\"chartInnerWidth\",\"selectedRollupTime\",\"graphs\"],[[30,2,[\"errors\"]],[30,0,[\"registerChartElement\"]],[30,0,[\"unregisterChartElement\"]],[30,0,[\"timeScale\"]],[30,0,[\"minTime\"]],[30,0,[\"maxTime\"]],[30,0,[\"rollupDuration\"]],[30,0,[\"chartDimensions\",\"innerWidth\"]],[30,0,[\"selectedRollupTime\"]],[30,0,[\"graphs\"]]]]]],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[18,3,null],[1,\"\\n\"]],[\"rowEvent\",\"errorEvent\",\"&default\"],false,[\"each\",\"-track-array\",\"metrics/charts/row-chart-elements/event-indicator-row\",\"metrics/charts/row-chart-elements/error-indicator-rows\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/chart-content/row-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});