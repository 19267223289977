define("dashboard/helpers/abbreviation", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.abbreviation = abbreviation;
  _exports.default = void 0;
  const matcher = /([a-z])/i;

  function abbreviation(_ref) {
    let [name] = _ref;

    if (!name) {
      return '';
    }

    const lettersOnly = name.split('').filter(c => matcher.test(c)).join('');
    return lettersOnly.slice(0, 2).toLowerCase().capitalize();
  }

  var _default = (0, _helper.helper)(abbreviation);

  _exports.default = _default;
});