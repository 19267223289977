define("dashboard/components/metrics/alerting-email-configuration", ["exports", "@ember/runloop", "@ember/service", "@ember/component", "@ember/object", "jquery"], function (_exports, _runloop, _service, _component, _object, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    not,
    readOnly,
    empty
  } = _object.computed;

  var _default = _component.default.extend({
    classNameBindings: [':metrics__alerting-email-configuration', ':flex'],
    cannotBeSubmitted: (0, _object.computed)('model.email', function () {
      // simple email regexp
      return !/\S+@\S+\.\S+/.test(this.get('model.email'));
    }),
    isFresh: readOnly('model.isNew'),
    isVerified: readOnly('model.verified'),
    _isUnverified: not('isVerified'),
    _isNotRemoving: not('isRemoving'),
    isUnverifiedAndNotRemoving: and('_isUnverified', '_isNotRemoving'),
    verificationCannotBeSubmitted: empty('model.token'),
    isShowingCheckInboxMessage: and('_isUnverified', 'justSentConfirmationEmail'),
    store: (0, _service.inject)(),
    actions: {
      didChangeEmail(event) {
        this.set('model.email', event.target.value);
      },

      didChangeVerificationCode(event) {
        this.set('model.token', event.target.value);
      },

      close() {
        this.onClose();
      },

      resend() {
        this.set('isShowingCheckInboxMessage', false);
        this.set('codeIsInvalid', false);
        return this.model.refreshToken({
          template: 'alerting'
        }).finally(() => {
          this.set('resendDisabled', true);
        });
      },

      clickResend() {
        // emulates clicking the resend button
        (0, _runloop.run)(this, () => {
          (0, _jquery.default)(this.element).find('.metrics__alerting-email-configuration__resend-btn').click();
        });
      },

      submitCode() {
        const model = this.model;
        this.set('isRemoving', false);
        this.set('isShowingCheckInboxMessage', false);
        model.verify().then(() => {
          this.set('hasSubmittedInvalidCode', false);
        }, error => {
          const status = (0, _object.get)(error, 'errors.firstObject.status');
          const codeIsInvalid = status === '404';

          if (codeIsInvalid) {
            this.setProperties({
              codeIsInvalid
            });
          } else {
            // we don't expect this, throw as normal
            throw error;
          }
        });
      },

      destroyConfirm() {
        this.set('isRemoving', true);
      },

      destroyCancel() {
        this.set('isRemoving', false);
      },

      destroy() {
        const model = this.model;
        return model.destroyRecord().then(() => {
          this.set('isRemoving', false);
          this.removeEmailRecipient(model);
        });
      }

    }
  });

  _exports.default = _default;
});