define("dashboard/templates/buildpack-registry/connect-github", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vajTtRUG",
    "block": "[[[1,\"\\n\\n\"],[10,0],[14,0,\"mt5 tc\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"Connect to GitHub\"],[13],[1,\"\\n\\n  \"],[10,2],[14,0,\"mt3 mb4\"],[12],[1,\"You will need to authenticate with GitHub in order to register a new buildpack.\"],[13],[1,\"\\n\\n  \"],[1,[28,[35,0],null,[[\"authorized\",\"buttonText\",\"buttonClass\"],[[28,[37,1],[[30,0],\"onGithubAuth\"],null],\"Connect to GitHub\",\"hk-button--primary github-authorize-button\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"github/authorize-button\",\"action\"]]",
    "moduleName": "dashboard/templates/buildpack-registry/connect-github.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});