define("dashboard/services/metrics", ["exports", "@ember/service", "ember-concurrency", "@ember/object", "@ember/object/computed", "@glimmer/tracking", "rsvp"], function (_exports, _service, _emberConcurrency, _object, _computed, _tracking, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class3, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const METRICS_PATHS = {
    dynoMemory: 'metrics/dyno-memory',
    dynoLoad: 'metrics/dyno-load',
    throughput: {
      path: 'metrics/router-status',
      condition: 'isWeb'
    },
    latency: {
      path: 'metrics/router-latency',
      condition: 'isWeb'
    },
    dynoErrors: 'metrics/dyno-errors',
    routerErrors: 'metrics/router-errors',
    routingHealth: {
      path: 'metrics/space-availability',
      condition: 'canReceiveSpaceMetrics',
      options: {
        region: 'all'
      }
    },
    currentRoutingHealth: {
      path: 'metrics/space-availability',
      condition: 'canReceiveSpaceMetrics',
      options: {
        region: 'all'
      },
      timeframe: {
        begin: 2,
        end: 0,
        step: 1
      }
    },
    languageMemory: {
      path: 'metrics/language-specific-memory'
    },
    languageMemoryTest: {
      path: 'metrics/language-specific-memory',
      timeframe: {
        begin: 24 * 7,
        end: 0,
        round: false
      }
    }
  };
  let PrivateSpacesPermissions = (_dec = (0, _computed.mapBy)('inboundRuleset.rules', 'source'), _dec2 = (0, _object.computed)('allowedSources'), _dec3 = (0, _computed.not)('allowsAllSources'), _dec4 = (0, _computed.and)('app.isPrivateSpaceApp', 'usesTrustedIPs'), _dec5 = (0, _computed.and)('app.isPrivateSpaceApp', 'space.cannotAccess'), _dec6 = (0, _computed.or)('app.isNotPrivateSpaceApp', 'usesTrustedIPs', 'space.cannotAccess'), _dec7 = (0, _computed.not)('cannotReceiveSpaceMetrics'), (_class = class PrivateSpacesPermissions {
    constructor(app, space, inboundRuleset) {
      _initializerDefineProperty(this, "app", _descriptor, this);

      _initializerDefineProperty(this, "space", _descriptor2, this);

      _initializerDefineProperty(this, "inboundRuleset", _descriptor3, this);

      _initializerDefineProperty(this, "allowedSources", _descriptor4, this);

      _initializerDefineProperty(this, "usesTrustedIPs", _descriptor5, this);

      _initializerDefineProperty(this, "spacesAppUsesTrustedIPs", _descriptor6, this);

      _initializerDefineProperty(this, "cannotAccessSpacesApp", _descriptor7, this);

      _initializerDefineProperty(this, "cannotReceiveSpaceMetrics", _descriptor8, this);

      _initializerDefineProperty(this, "canReceiveSpaceMetrics", _descriptor9, this);

      this.app = app;
      this.space = space;
      this.inboundRuleset = inboundRuleset;
    }

    get allowsAllSources() {
      if (this.allowedSources) {
        return this.allowedSources.includes('0.0.0.0/0');
      }

      return false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "app", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "space", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inboundRuleset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "allowedSources", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "allowsAllSources", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "allowsAllSources"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "usesTrustedIPs", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "spacesAppUsesTrustedIPs", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cannotAccessSpacesApp", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "cannotReceiveSpaceMetrics", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canReceiveSpaceMetrics", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  let Metrics = (_dec8 = (0, _computed.readOnly)('app.formations'), _dec9 = (0, _computed.readOnly)('fetchMetricsTask.lastSuccessful.value'), _dec10 = (0, _computed.readOnly)('process.isWeb'), _dec11 = (0, _computed.readOnly)('privateSpacesPermissions.canReceiveSpaceMetrics'), _dec12 = (0, _computed.readOnly)('lastTaskResult.dynoMemory'), _dec13 = (0, _computed.readOnly)('lastTaskResult.dynoLoad'), _dec14 = (0, _computed.readOnly)('lastTaskResult.throughput'), _dec15 = (0, _computed.readOnly)('lastTaskResult.latency'), _dec16 = (0, _computed.readOnly)('lastTaskResult.dynoErrors'), _dec17 = (0, _computed.readOnly)('lastTaskResult.routerErrors'), _dec18 = (0, _computed.readOnly)('lastTaskResult.routingHealth'), _dec19 = (0, _computed.readOnly)('lastTaskResult.currentRoutingHealth'), _dec20 = (0, _computed.readOnly)('lastTaskResult.languageMemory'), _dec21 = (0, _computed.readOnly)('lastTaskResult.languageMemoryTest'), _dec22 = (0, _object.computed)('formations', 'formationType'), _dec23 = (0, _emberConcurrency.task)(function* () {
    while (true) {
      this.get('fetchMetricsTask').perform();

      if (typeof this.refreshDelay === 'number') {
        yield (0, _emberConcurrency.timeout)(this.refreshDelay);
      } else {
        return;
      }
    }
  }).drop(), _dec24 = (0, _emberConcurrency.task)(function* () {
    if (this.process) {
      const allMetricsPromises = {};

      for (const key in METRICS_PATHS) {
        const {
          condition,
          path,
          timeframeBegin,
          timeframeEnd,
          options
        } = this._getQueryArgs(METRICS_PATHS[key]);

        if (!condition || this.get(condition)) {
          allMetricsPromises[key] = this.process.queryMetricsData(path, timeframeBegin, timeframeEnd, options);
        }
      }

      const allMetrics = yield _rsvp.default.hash(allMetricsPromises);
      return allMetrics;
    }
  }).drop(), (_class3 = class Metrics extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "beforeHours", _descriptor10, this);

      _initializerDefineProperty(this, "afterHours", _descriptor11, this);

      _initializerDefineProperty(this, "app", _descriptor12, this);

      _initializerDefineProperty(this, "privateSpacesPermissions", _descriptor13, this);

      _initializerDefineProperty(this, "formationType", _descriptor14, this);

      _defineProperty(this, "refreshDelay", 30000);

      _initializerDefineProperty(this, "formations", _descriptor15, this);

      _initializerDefineProperty(this, "lastTaskResult", _descriptor16, this);

      _initializerDefineProperty(this, "isWeb", _descriptor17, this);

      _initializerDefineProperty(this, "canReceiveSpaceMetrics", _descriptor18, this);

      _initializerDefineProperty(this, "dynoMemory", _descriptor19, this);

      _initializerDefineProperty(this, "dynoLoad", _descriptor20, this);

      _initializerDefineProperty(this, "throughput", _descriptor21, this);

      _initializerDefineProperty(this, "latency", _descriptor22, this);

      _initializerDefineProperty(this, "dynoErrors", _descriptor23, this);

      _initializerDefineProperty(this, "routerErrors", _descriptor24, this);

      _initializerDefineProperty(this, "routingHealth", _descriptor25, this);

      _initializerDefineProperty(this, "currentRoutingHealth", _descriptor26, this);

      _initializerDefineProperty(this, "languageMemory", _descriptor27, this);

      _initializerDefineProperty(this, "languageMemoryTest", _descriptor28, this);

      _initializerDefineProperty(this, "periodicRefreshTask", _descriptor29, this);

      _initializerDefineProperty(this, "fetchMetricsTask", _descriptor30, this);
    }

    get process() {
      if (this.formations && this.formationType) {
        return this.formations.findBy('type', this.formationType);
      }

      return null;
    }

    async setup(appPromise, spacePromise, formationType) {
      await this.stop();
      const {
        app,
        space
      } = await _rsvp.default.hash({
        app: appPromise,
        space: spacePromise
      });
      this.setProperties({
        app,
        formationType
      });
      let inboundRuleset;

      if (space) {
        inboundRuleset = await space.inboundRuleset;
      }

      this.set('privateSpacesPermissions', new PrivateSpacesPermissions(app, space, inboundRuleset));
    }

    async updateTimeframe(beforeHours, afterHours) {
      let restart = false;

      if (this.get('periodicRefreshTask.isRunning')) {
        restart = true;
        await this.stop();
      }

      this.setProperties({
        beforeHours,
        afterHours
      });

      if (restart) {
        this.start();
      }
    }

    start() {
      this.get('periodicRefreshTask').perform();
    }

    stop() {
      return Promise.all([this.get('periodicRefreshTask').cancelAll({
        resetState: true
      }), this.get('fetchMetricsTask').cancelAll({
        resetState: true
      })]);
    }

    _getQueryArgs(pathOrPathOptions) {
      const {
        path,
        condition,
        options: maybeOptions,
        timeframe: {
          begin: overrideTimeframeBegin,
          end: overrideTimeframeEnd,
          step,
          round
        }
      } = this._getQueryConfig(pathOrPathOptions);

      const timeframeEnd = typeof overrideTimeframeEnd === 'number' ? overrideTimeframeEnd : this.beforeHours;
      const timeframeBegin = typeof overrideTimeframeBegin === 'number' ? overrideTimeframeBegin : this.afterHours;
      let options = maybeOptions;

      if (step) {
        options = options || {};
        options.step = step;
      }

      if (typeof round !== 'undefined') {
        options = options || {};
        options.round = round;
      }

      return {
        condition,
        path,
        timeframeBegin,
        timeframeEnd,
        options
      };
    }

    _getQueryConfig(pathOrPathOptions) {
      if (typeof pathOrPathOptions === 'string') {
        return {
          path: pathOrPathOptions,
          timeframe: {}
        };
      } else if (typeof pathOrPathOptions === 'object') {
        const config = {
          timeframe: {},
          ...pathOrPathOptions
        };
        return config;
      } else {
        throw new Error(`No metrics config found for: ${pathOrPathOptions}`);
      }
    }

  }, (_descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "beforeHours", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "afterHours", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 24;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "app", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "privateSpacesPermissions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "formationType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "formations", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "lastTaskResult", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class3.prototype, "isWeb", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class3.prototype, "canReceiveSpaceMetrics", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class3.prototype, "dynoMemory", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class3.prototype, "dynoLoad", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class3.prototype, "throughput", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class3.prototype, "latency", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class3.prototype, "dynoErrors", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class3.prototype, "routerErrors", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class3.prototype, "routingHealth", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class3.prototype, "currentRoutingHealth", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class3.prototype, "languageMemory", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class3.prototype, "languageMemoryTest", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "process", [_dec22], Object.getOwnPropertyDescriptor(_class3.prototype, "process"), _class3.prototype), _descriptor29 = _applyDecoratedDescriptor(_class3.prototype, "periodicRefreshTask", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class3.prototype, "fetchMetricsTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3));
  _exports.default = Metrics;
});