define("dashboard/serializers/repositories-api-github-branch", ["exports", "dashboard/serializers/application", "@ember/utils"], function (_exports, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      const repo = hash.commit.url.replace('https://api.github.com/repos/', '').replace(/\/commits\/.+/, ''); // Manually set the id since GitHub doesn't send us one.

      hash.id = `${repo}/branches/${hash.name}`;
      return this._super(typeClass, hash);
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      if (hash.pipeline) {
        relationships.pipeline = {
          data: {
            id: hash.pipeline.id,
            type: 'pipeline'
          }
        };
      }

      return relationships;
    },

    pushPayload(store, payload) {
      let documentHash = {};
      const type = store.modelFor('repositories-api-github-branch');
      const payloadData = payload['repositories-api-github-branch'];

      if ((0, _utils.typeOf)(payloadData) === 'object') {
        documentHash = this.normalize(type, payloadData);
      } else if (Array.isArray(payloadData)) {
        const ret = new Array(payloadData.length);

        for (let i = 0; i < payloadData.length; i++) {
          const data = payloadData[i];
          ret[i] = this.normalize(type, data).data;
        }

        documentHash.data = ret;
      }

      store.push(documentHash);
    }

  });

  _exports.default = _default;
});