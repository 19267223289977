define("dashboard/components/general-banner", ["exports", "@ember/component", "@ember/service", "dashboard/mixins/preferences", "@ember/object", "@ember/debug", "@ember/array"], function (_exports, _component, _service, _preferences, _object, _debug, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    notEmpty,
    equal,
    empty
  } = _object.computed;

  var _default = _component.default.extend(_preferences.default, {
    analytics: (0, _service.inject)(),
    router: (0, _service.inject)(),
    attributeBindings: ['bannerTestTarget:data-test-target'],
    showOnlyPrimaryButton: false,
    transitionConfig: undefined,
    onDismissClick: undefined,
    onPrimaryClick: undefined,
    isInBlockForm: false,
    bannerTestTarget: 'general-banner-component',
    iconClass: 'malibu-fill-gradient-dark-gray flex-shrink-0',
    iconSize: '18',
    learnMoreLinkText: '',
    secondaryButtonAccessibilityLabel: 'Hide this suggestion permanently',
    buttonSize: 'small',
    bannerStyles: ['pipeline', 'app'],
    bannerStyle: 'app',
    iconName: '',
    primaryButtonLink: '',
    learnMoreLink: '',
    userPreferencesKey: '',
    bannerDismissalValue: '',
    hasBannerTestTarget: notEmpty('bannerTestTarget'),
    hasIconName: notEmpty('iconName'),
    hasPrimaryButtonLink: notEmpty('primaryButtonLink'),
    hasLearnMoreLink: notEmpty('learnMoreLink'),
    hasLearnMoreLinkText: notEmpty('learnMoreLinkText'),
    hasUserPreferencesKey: notEmpty('userPreferencesKey'),
    hasBannerDismissalValue: notEmpty('bannerDismissalValue'),
    hasNoBannerMessage: empty('bannerMessage'),
    isAppStyle: equal('bannerStyle', 'app'),
    primaryClickTransitionType: (0, _object.computed)('hasPrimaryButtonLink', 'transitionConfig', function () {
      return this.hasPrimaryButtonLink ? 'primaryLink' : 'routeTransition';
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      const isMissingUserUserPreferencesKey = !this.onDismissClick && !this.hasUserPreferencesKey && !this.showOnlyPrimaryButton;
      const isMissingBannerDismissalValue = !this.onDismissClick && this.hasUserPreferencesKey && !this.hasBannerDismissalValue && !this.showOnlyPrimaryButton;
      const hasMultiplePrimaryEvents = this.hasPrimaryButtonLink && this.transitionConfig;
      const hasPassedMessageWhenNecessary = this.bannerMessage && !this.isInBlockForm || this.hasNoBannerMessage && this.isInBlockForm;
      (false && !(hasPassedMessageWhenNecessary) && (0, _debug.assert)('You must pass a banner message into {{general-banner ...}} using the parameter bannerMessage or yield in your own message to render this banner', hasPassedMessageWhenNecessary));
      (false && !(this.bannerName) && (0, _debug.assert)('You must pass a banner name into {{general-banner ...}} using the parameter bannerName to render this banner', this.bannerName));
      (false && !(this.primaryActionText) && (0, _debug.assert)('You must pass primary button text into {{general-banner ...}} using the parameter primaryActionText to render this banner', this.primaryActionText));
      (false && !(this.bannerStyles.includes(this.bannerStyle)) && (0, _debug.assert)('You must pass an acceptable banner style into {{general-banner ...}} using the parameter bannerStyle to render this banner', this.bannerStyles.includes(this.bannerStyle)));
      (false && !(!isMissingUserUserPreferencesKey) && (0, _debug.assert)('userPreferencesKey and hasBannerDismissalValue must be passed into {{general-banner ...}} to use default dismissal', !isMissingUserUserPreferencesKey));
      (false && !(!isMissingBannerDismissalValue) && (0, _debug.assert)('hasBannerDismissalValue must also be passed into {{general-banner ...}} to use default dismissal', !isMissingBannerDismissalValue));
      (false && !(!hasMultiplePrimaryEvents) && (0, _debug.assert)('Both a primary link and route transition cannot be passed in {{general-banner ...}', !hasMultiplePrimaryEvents));
    },

    didInsertElement() {
      this._super(...arguments);

      this._logBannerViewToAnalytics();
    },

    _logBannerViewToAnalytics() {
      this._logGeneralBannerAnalytics('Viewed');
    },

    _onPrimaryClickRouteTransition() {
      if (!this.transitionConfig.transitionArgs) {
        this.get('router').transitionTo(this.transitionConfig.transitionRoute);
      } else {
        this.get('router').transitionTo(this.transitionConfig.transitionRoute, ...this.transitionConfig.transitionArgs);
      }
    },

    _addDismissToUserPreferences() {
      const userPref = this.dashboardUserPreferences;

      if ((0, _array.isArray)(userPref.get(this.userPreferencesKey))) {
        const currentUserPreferencesValue = userPref.get(this.userPreferencesKey);
        this.updateUserPreference(this.userPreferencesKey, [...currentUserPreferencesValue, this.bannerDismissalValue]);
      } else {
        this.updateUserPreference(this.userPreferencesKey, this.bannerDismissalValue);
      }
    },

    _defaultDismissClick() {
      this._addDismissToUserPreferences();
    },

    _defaultPrimaryClick() {
      if (this.transitionConfig) {
        this._onPrimaryClickRouteTransition();
      }
    },

    _logGeneralBannerAnalytics(analyticsVerb) {
      if (this.isAppStyle) {
        this.analytics.logEvent(this.bannerName, analyticsVerb, {
          appID: this.appID,
          appName: this.appName
        });
      } else {
        this.analytics.logEvent(this.bannerName, analyticsVerb, {
          pipelineID: this.pipelineID,
          pipelineName: this.pipelineName
        });
      }
    },

    actions: {
      clickPrimaryButton() {
        this._logGeneralBannerAnalytics('Clicked');

        this._defaultPrimaryClick();

        if (this.onPrimaryClick) {
          this.onPrimaryClick();
        }
      },

      clickDismissButton() {
        this._logGeneralBannerAnalytics('Dismissed');

        if (!this.onDismissClick) {
          this._defaultDismissClick();
        } else {
          this.onDismissClick();
        }
      }

    }
  });

  _exports.default = _default;
});