define("dashboard/models/production-check/dyno-redundancy", ["exports", "dashboard/mixins/production-check", "@ember/object"], function (_exports, _productionCheck, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    devCenterURL: 'https://devcenter.heroku.com/articles/scaling#scaling-from-the-dashboard',
    title: 'Dyno Redundancy',
    skippedMessage: 'No web processes.',
    failedMessage: "You're only running on 1 web dyno. A second dyno will provide instant fallback if one dyno fails for any reason. Scale your dynos to 2 or more on the Resources tab.",
    webFormation: (0, _object.computed)('app.formations', function () {
      return this.get('app.formations').find(formation => formation.get('type') === 'web');
    }),
    status: (0, _object.computed)('webFormation.quantity', function () {
      const webDynoCount = this.get('webFormation.quantity') || 0;

      if (webDynoCount === 0) {
        return 'skipped';
      } else if (webDynoCount === 1) {
        return 'failed';
      }

      return 'passed';
    })
  });

  _exports.default = _default;
});