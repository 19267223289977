define("dashboard/components/datastore/private-link/connection", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATUS_ICONS = {
    Available: {
      icon: 'success-badge-16',
      iconClass: 'gradient-green',
      textClass: 'green'
    },
    Failed: {
      icon: 'error-badge-16',
      iconClass: 'gradient-red',
      textClass: 'red'
    },
    Expired: {
      icon: 'error-badge-16',
      iconClass: 'gradient-red',
      textClass: 'red'
    },
    Default: {
      icon: 'info-ring-16',
      iconClass: 'fill-gray',
      textClass: 'dark-gray'
    }
  };

  var _default = _component.default.extend({
    isShowingCredentials: false,
    status: (0, _computed.readOnly)('connection.status'),
    icon: (0, _object.computed)('status', function () {
      return STATUS_ICONS[this.status] || STATUS_ICONS['Default'];
    }),
    iconName: (0, _computed.reads)('icon.icon'),
    iconClass: (0, _computed.reads)('icon.iconClass'),
    textClass: (0, _computed.reads)('icon.textClass'),
    endpointId: (0, _computed.reads)('connection.endpoint_id'),
    endpointUrl: (0, _object.computed)('connection', function () {
      const hostName = this.connection.hostname ? this.connection.hostname : '';
      return `kafka+ssl://${hostName}`;
    }),
    actions: {
      showCredentials() {
        this.set('isShowingCredentials', true);
      }

    }
  });

  _exports.default = _default;
});