define("dashboard/serializers/space/peering-info", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.id = '~'; // no id on this singular resource...

      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});