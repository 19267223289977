define("dashboard/models/app-template-config-var", ["exports", "@ember/utils", "@ember/object", "dashboard/mixins/validating-model"], function (_exports, _utils, _object, _validatingModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bool,
    not,
    equal,
    alias
  } = _object.computed;

  var _default = _object.default.extend(_validatingModel.default, {
    readonly: bool('rawValue.generator'),
    editable: not('readonly'),
    optional: equal('rawValue.required', false),
    required: (0, _object.computed)('editable', 'optional', function () {
      return this.editable && !this.optional;
    }),
    requiredProperties: (0, _object.computed)('required', function () {
      if (this.required) {
        return ['value'];
      } else {
        return [];
      }
    }),
    description: alias('rawValue.description'),
    placeholder: (0, _object.computed)('readonly', function () {
      if (this.readonly) {
        return 'autogenerated';
      }
    }),
    value: (0, _object.computed)('rawValue', function () {
      const raw = this.rawValue;

      switch ((0, _utils.typeOf)(raw)) {
        case 'string':
          return raw;

        case 'object':
          return raw.value || '';
      }
    })
  });

  _exports.default = _default;
});