define("dashboard/components/scheduler/jobs-list", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _component, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const filterByAttribute = function (attribute) {
    return (0, _object.computed)('filterKey', 'jobs.[]', function () {
      const regex = new RegExp(this.filterKey, 'i');
      return this.jobs.filter(job => regex.test(job.get(attribute)));
    });
  };

  var _default = _component.default.extend({
    tagName: 'header',
    classNames: ['mb3'],
    'data-test-target': 'scheduler-jobs-list',
    filteredByCommand: filterByAttribute('command'),
    filteredByDynoSize: filterByAttribute('dynoSize'),
    filteredByFrequency: filterByAttribute('displayFrequency'),
    filteredJobs: (0, _computed.union)('filteredByCommand', 'filteredByDynoSize', 'filteredByFrequency'),
    displayedJobs: (0, _object.computed)('jobs.[]', 'filteredJobs', 'filterKey', function () {
      return (0, _utils.isPresent)(this.filterKey) ? this.filteredJobs : this.jobs;
    })
  });

  _exports.default = _default;
});