define("dashboard/serializers/shogun/kafka-topic", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractId(_modelClass, resourceHash) {
      return resourceHash.attachment_name;
    }

  });

  _exports.default = _default;
});