define("dashboard/components/app-wrapper", ["exports", "@ember/component", "@ember/object", "@ember/string"], function (_exports, _component, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'main',
    classNames: ['app-wrapper'],
    classNameBindings: ['currentPathClass', 'delinquentClass'],
    attributeBindings: ['aria-label'],
    'aria-label': 'Heroku Dashboard',
    currentPathClass: (0, _object.computed)('currentPath', function () {
      const currentPath = this.currentPath || '';
      return (0, _string.decamelize)(currentPath).split('.').join('-');
    }),
    delinquentClass: (0, _object.computed)('account.isDelinquent', function () {
      return this.get('account.isDelinquent') ? 'is-delinquent' : '';
    })
  });

  _exports.default = _default;
});