define("dashboard/components/app/collaborator-modal", ["exports", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isEditing: (0, _computed.not)('item.isNew'),
    showDisabledCopy: (0, _object.computed)('team.hasOrgAccessControlsFlag', 'team', function () {
      if (this.team && !this.team.hasOrgAccessControlsFlag) {
        // show copy when we're editing and it's a team app and they dont have the orgAccessControlsFlag
        return true;
      }
    }),
    cannotEdit: (0, _computed.not)('canEdit'),
    actions: {
      selectPermission(permToSelect) {
        this.selectPermission(permToSelect);
      },

      deselectPermission(permToRemove) {
        this.deselectPermission(permToRemove);
      }

    }
  });

  _exports.default = _default;
});