define("dashboard/routes/account/base", ["exports", "@ember/routing/route", "dashboard/mixins/document-title"], function (_exports, _route, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    documentTitle: 'Account',
    ignoreDelinquent: true
  });

  _exports.default = _default;
});