define("dashboard/mixins/models/metrics/go-memory", ["exports", "@ember/object/mixin", "@ember/object", "dashboard/models/metrics", "dashboard/utils/metrics/unit-converters", "dashboard/utils/metrics/metrics-data-operators", "dashboard/utils/metrics/has-any-measured-data"], function (_exports, _mixin, _object, _metrics, _unitConverters, _metricsDataOperators, _hasAnyMeasuredData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;

  var _default = _mixin.default.create({
    testDataKeysGo: (0, _object.computed)(function () {
      return ['goGcPauseTimeLengthInNs', 'goGcCollections', 'heapMaxBytesGo', 'stackMaxBytes', 'gcGoalMaxBytes', 'heapObjectsMax', 'heapObjectsCount', 'heapObjectsSum', 'routinesMax', 'routinesCount', 'routinesSum'];
    }),
    hasAnyMeasuredDataGo: (0, _hasAnyMeasuredData.default)('testDataKeysGo'),
    goGcPauseTimeLengthInNs: rawDataWithDefault('c:go.gc.pause.ns'),
    goGcPauseTimeLength: (0, _unitConverters.convertToMs)('goGcPauseTimeLengthInNs'),
    goGcPauseTimeRatio: (0, _metricsDataOperators.dataRatio)('goGcPauseTimeLengthInNs', 'stepInNs'),
    goGcCollections: rawDataWithDefault('c:go.gc.collections'),
    heapMemoryMaxBytesGo: rawDataWithDefault('g:go.memory.heap.bytes_max'),
    heapMemorySumBytesGo: rawDataWithDefault('g:go.memory.heap.bytes_sum'),
    heapMemoryCountBytesGo: rawDataWithDefault('g:go.memory.heap.bytes_count'),
    heapmemoryAvgBytesGo: (0, _metricsDataOperators.dataQuotient)('heapMemorySumBytesGo', 'heapMemoryCountBytesGo'),
    stackMemoryMaxBytesGo: rawDataWithDefault('g:go.memory.stack.bytes_max'),
    stackMemorySumBytesGo: rawDataWithDefault('g:go.memory.stack.bytes_sum'),
    stackMemoryCountBytesGo: rawDataWithDefault('g:go.memory.stack.bytes_count'),
    stackmemoryAvgBytesGo: (0, _metricsDataOperators.dataQuotient)('stackMemorySumBytesGo', 'stackMemoryCountBytesGo'),
    gcGoalMaxBytes: rawDataWithDefault('g:go.gc.goal_max'),
    gcGoalSumBytes: rawDataWithDefault('g:go.gc.goal_sum'),
    gcGoalCountBytes: rawDataWithDefault('g:go.gc.goal_count'),
    gcGoalAvgBytes: (0, _metricsDataOperators.dataQuotient)('gcGoalSumBytes', 'gcGoalCountBytes'),
    heapMaxGo: (0, _unitConverters.convertToMB)('heapMemoryMaxBytesGo'),
    heapAvgGo: (0, _unitConverters.convertToMB)('heapmemoryAvgBytesGo'),
    stackMaxGo: (0, _unitConverters.convertToMB)('stackMemoryMaxBytesGo'),
    stackAvgGo: (0, _unitConverters.convertToMB)('stackmemoryAvgBytesGo'),
    gcGoalMax: (0, _unitConverters.convertToMB)('gcGoalMaxBytes'),
    gcGoalAvg: (0, _unitConverters.convertToMB)('gcGoalAvgBytes'),
    heapObjectsMax: rawDataWithDefault('g:go.memory.heap.objects_max'),
    heapObjectsMin: rawDataWithDefault('g:go.memory.heap.objects_min'),
    heapObjectsCount: rawDataWithDefault('g:go.memory.heap.objects_count'),
    heapObjectsSum: rawDataWithDefault('g:go.memory.heap.objects_sum'),
    heapObjectsAvg: (0, _metricsDataOperators.dataQuotient)('heapObjectsSum', 'heapObjectsCount'),
    routinesMax: rawDataWithDefault('g:go.routines_max'),
    routinesMin: rawDataWithDefault('g:go.routines_min'),
    routinesCount: rawDataWithDefault('g:go.routines_count'),
    routinesSum: rawDataWithDefault('g:go.routines_sum'),
    routinesAvg: (0, _metricsDataOperators.dataQuotient)('routinesSum', 'routinesCount')
  });

  _exports.default = _default;
});