define("dashboard/models/team/addon", ["exports", "dashboard/models/addon", "ember-data/attr"], function (_exports, _addon, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _addon.default.extend({
    // override the base addon's app relationship since for team addons
    // all we need is to pluck the app name out of the payload 🤷🏻‍♂️
    app: (0, _attr.default)()
  });

  _exports.default = _default;
});