define("dashboard/serializers/team/allowed-addon", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      if (Array.isArray(resourceHash)) {
        // when creating an allowed-addon, the server returns an array of one record
        resourceHash = resourceHash[0];
      }

      resourceHash.addonService = resourceHash.addon_service.id;
      delete resourceHash.addon_service;
      arguments[1] = resourceHash;
      return this._super.apply(this, arguments);
    },

    serialize(snapshot) {
      // weirdly, this endpoint does not accept addonService.id
      return {
        addon_service: snapshot.record.get('addonService.name')
      };
    }

  });

  _exports.default = _default;
});