define("dashboard/components/metrics/charts/visualizations/latency-chart", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart", "@ember/object/computed"], function (_exports, _object, _wrappedLineChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    unit: 'time',
    insufficientDataTitle: 'Insufficient Data',
    summaryComponent: 'metrics/charts/chart-header/latency-summary',
    summaryData: _object.computed.alias('data'),
    isShowingP50: true,
    isShowingP95: true,
    isShowingP99: true,
    isShowingMax: true,
    timeSeriesP50: (0, _object.computed)('p50Data', 'isShowingP50', function () {
      return _object.default.create({
        name: '50th perc.',
        color: 'purple-004',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 3,
        data: this.p50Data,
        isVisible: this.isShowingP50,
        key: 'P50'
      });
    }),
    timeSeriesP95: (0, _object.computed)('p95Data', 'isShowingP95', function () {
      return _object.default.create({
        name: '95th perc.',
        color: 'purple-003',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 2,
        data: this.p95Data,
        isVisible: this.isShowingP95,
        key: 'P95'
      });
    }),
    timeSeriesP99: (0, _object.computed)('p99Data', 'isShowingP99', function () {
      return _object.default.create({
        name: '99th perc.',
        color: 'purple-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 1,
        data: this.p99Data,
        isVisible: this.isShowingP99,
        key: 'P99'
      });
    }),
    timeSeriesMax: (0, _object.computed)('maxData', 'isShowingMax', function () {
      return _object.default.create({
        name: 'Max',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 0,
        data: this.maxData,
        isVisible: this.isShowingMax,
        key: 'Max'
      });
    }),
    chartTimeSeries: (0, _computed.collect)('timeSeriesP50', 'timeSeriesP95', 'timeSeriesP99', 'timeSeriesMax')
  });

  _exports.default = _default;
});