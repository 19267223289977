define("dashboard/templates/components/two-factor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4mT1mZtv",
    "block": "[[[18,1,[[28,[37,1],null,[[\"twoFactorRequired\",\"twoFactorToken\",\"twoFactorUnfulfilled\",\"twoFactorFulfilled\",\"updateTwoFactorToken\",\"prompt\",\"retry\",\"preauth\",\"reset\"],[[30,0,[\"twoFactorRequired\"]],[30,0,[\"twoFactorToken\"]],[30,0,[\"twoFactorUnfulfilled\"]],[30,0,[\"twoFactorFulfilled\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"twoFactorToken\"]]],null]],null],[50,\"two-factor/prompt\",0,null,[[\"twoFactorRequired\",\"twoFactorFulfilled\",\"twoFactorUnfulfilled\",\"twoFactorToken\"],[[30,0,[\"twoFactorRequired\"]],[30,0,[\"twoFactorFulfilled\"]],[30,0,[\"twoFactorUnfulfilled\"]],[30,0,[\"twoFactorToken\"]]]]],[28,[37,2],[[30,0],\"retry\"],null],[28,[37,2],[[30,0],\"preauth\"],null],[28,[37,2],[[30,0],\"reset\"],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"action\",\"mut\",\"component\"]]",
    "moduleName": "dashboard/templates/components/two-factor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});