define("dashboard/utils/pluralize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pluralize;

  function pluralize(value, singular, plural) {
    return value === 1 ? singular : plural;
  }
});