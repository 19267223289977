define("dashboard/mixins/metrics/current-timeframe", ["exports", "@ember/object/mixin", "moment", "@ember/object", "dashboard/utils/metrics/time-calculators", "@ember/service"], function (_exports, _mixin, _moment, _object, _timeCalculators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    browserTime: (0, _service.inject)(),
    currentEndTime: (0, _object.computed)('currentTimeframe.step', 'currentTimeframe.beforeHours', function () {
      return (0, _timeCalculators.calculateEndTime)(this.get('browserTime.currentTime'), this.get('currentTimeframe.beforeHours'), this.get('currentTimeframe.step'));
    }),
    currentStartTime: (0, _object.computed)('currentTimeframe.step', 'currentTimeframe.afterHours', function () {
      return (0, _timeCalculators.calculateStartTime)(this.get('browserTime.currentTime'), this.get('currentTimeframe.afterHours'), this.get('currentTimeframe.step'));
    }),
    currentStepDuration: (0, _object.computed)('currentTimeframe.step', function () {
      return _moment.default.duration(this.get('currentTimeframe.step'), 'm');
    }),
    currentLatestRollupTime: (0, _timeCalculators.closestRollupTime)('currentEndTime'),
    currentNextLatestRollupTime: (0, _object.computed)('currentLatestRollupTime', 'currentStepDuration', function () {
      return this.get('currentLatestRollupTime') - this.get('currentStepDuration');
    }),
    currentSelectedRollupTime: (0, _timeCalculators.closestRollupTime)('selectedTime')
  });

  _exports.default = _default;
});