define("dashboard/models/team/permission", ["exports", "@ember/object/computed", "@ember/object", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _computed, _object, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    team: (0, _relationships.belongsTo)('team'),
    app: (0, _relationships.belongsTo)('app'),
    resourceId: (0, _attr.default)(),
    name: (0, _attr.default)(),
    type: (0, _attr.default)(),
    user: (0, _attr.default)(),
    permissions: (0, _attr.default)(),
    permits: (0, _object.computed)('permissions.[]', 'team.appPermissionNames.[]', function () {
      return this.get('team.appPermissionNames').map(name => {
        return _object.default.create({
          name,
          enabled: this.hasPermission(name)
        });
      });
    }),
    enabledPermits: (0, _computed.filterBy)('permits', 'enabled', true),

    hasPermission(name) {
      return this.permissions.includes(name);
    }

  });

  _exports.default = _default;
});