define("dashboard/controllers/enterprise-account/overview/index", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object", "@ember/service"], function (_exports, _controller, _computed, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    queryParams: ['showAll', 'filter', 'page'],
    page: 1,
    filter: '',
    showAll: false,
    enterpriseAccount: (0, _computed.readOnly)('model.enterpriseAccount'),
    enterpriseAccountMember: (0, _computed.readOnly)('current.enterpriseAccountMember'),
    teams: (0, _computed.readOnly)('enterpriseAccount.teams'),
    myTeams: (0, _computed.filterBy)('teams', 'role'),
    canManageTeams: (0, _computed.readOnly)('current.enterprisePermissions.canManageTeams'),
    activelySelectedTeamList: (0, _object.computed)('showAll', 'teams', 'myTeams', 'canManageTeams', function () {
      if (this.canManageTeams && this.showAll) {
        return this.teams;
      } else {
        return this.myTeams;
      }
    }),
    actions: {
      toggleShowAll(newValue) {
        const oldValue = this.showAll;

        if (oldValue === newValue) {
          return;
        }

        this.set('showAll', newValue);
        this.set('page', 1);
      }

    }
  });

  _exports.default = _default;
});