define("dashboard/routes/app", ["exports", "rsvp", "@ember/routing/route", "@ember/utils", "@ember/service", "dashboard/models/app-permissions", "dashboard/utils/errors", "@ember/object"], function (_exports, _rsvp, _route, _utils, _service, _appPermissions, _errors, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      webConsole: {
        replace: true,
        as: 'web-console'
      }
    },
    current: (0, _service.inject)(),
    loadingState: (0, _service.inject)(),
    favoritesService: (0, _service.inject)('favorites'),
    realTimeUpdater: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),

    model(params) {
      return (0, _rsvp.hash)({
        app: this._fetchApp(params.name)
      });
    },

    afterModel(hash) {
      // If the app is locked, dont even bother doing afterModel things
      if (!hash.app) {
        return (0, _rsvp.resolve)(hash);
      }

      hash.app.belongsTo('preference').reload(); // async load the app's feature flags

      hash.app.hasMany('features').reload(); // async fetch the githubAppLink and ignore any errors

      this.store.queryRecord('githubAppLink', {
        app: hash.app.get('id')
      }).catch(() => {});
      this.realTimeUpdater.subscribeToModel(hash.app, null, {
        type: 'update'
      });
      const isOrgApp = hash.app.get('isOwnedByOrg');
      return (0, _rsvp.all)([this.fetchOrgAppPermissions(hash.app), this.fetchCoupling(hash.app), isOrgApp ? hash.app.get('team.features') : null]).then(results => {
        hash.appPermissions = _appPermissions.default.create({
          app: hash.app,
          team: isOrgApp ? hash.app.get('team') : null,
          teamCollaborator: results[0],
          account: this.get('current.account')
        });
      });
    },

    renderTemplate(controller, model) {
      const app = model.app;

      if (app) {
        // The app won't exist in some cases. See `_fetchApp` below.
        const team = app.get('team');
        const enterpriseAccount = app.get('team.enterprise');
        this.set('breadcrumbs.trail', {
          app,
          team,
          enterpriseAccount
        }), this.render('breadcrumbs', {
          outlet: 'header-nav',
          into: 'protected'
        });
      }

      this._super(...arguments);
    },

    // Yes, this is a bit... bad. This function is working around the delicate
    // org app joining functionality in API. Of particular note:
    // - The 'joined' flag API sends back is not trustworthy
    // - Fetching an unjoined app with granular permissions results in a 403
    // - Fetching additional resources on any unjoined app will return 403
    _fetchApp(appName) {
      return this.store.queryRecord('app', {
        appName
      }).then(async app => {
        // We need the team and account before we can determine if we need to join or not.
        const team = await app.get('team');

        if (team) {
          await team.get('enterprise').catch(() => null);
        }

        if (this._needsToJoin(app)) {
          if (app.get('locked')) {
            this._renderLocked();
          } else {
            return this._joinApp(app.get('name')).then(() => {
              app.set('joined', true);
              return app;
            });
          }
        } else {
          return app;
        }
      }).catch(error => {
        const status = (0, _errors.errorStatus)(error);
        const responseMessage = (0, _object.get)(error, 'errors.firstObject.detail');
        const isForbidden = status === 403;
        const isUnjoinedMessage = /you need to have the .+ permission/i.test(responseMessage);

        if (isForbidden && isUnjoinedMessage) {
          // This is an unjoined app with granular permissions. Join it, then re-fetch the app
          return this._joinApp(appName).then(() => {
            return this.store.queryRecord('app', {
              appName
            });
          }).catch(() => {
            // User was unable to join, most likely because it was locked.
            this._renderLocked();
          });
        } else {
          throw error;
        }
      });
    },

    _needsToJoin(app) {
      if (app.get('unjoined') && !this._isRealOrImplicitAdmin(app)) {
        // Users who are not an admin on the team (implicitly or explicitly)
        // need to join the team apps, so we want to auto-join for them.
        return true;
      } else {
        return false;
      }
    },

    _isRealOrImplicitAdmin(app) {
      if (app.get('team.hasEnterpriseAccount') && !app.get('team.hasRole')) {
        // Users with certain permissions on the enterprise account are
        // implicit admins on all teams, even though they won't have a real role
        // on the team until they are explicitly added to it. In the case that
        // they do not have a role on the team, they implicitly have view on the
        // apps, so we will use this to prevent auto-joining. We will also use
        // this to prevent querying for this user's team collaborator record,
        // because one does not exist.
        return true;
      } else if (app.get('team.isAdmin')) {
        // We have a real admin here, folks.
        return true;
      } else {
        return false;
      }
    },

    _joinApp(appName) {
      return this.store.createRecord('team/collaborator', {
        appName,
        email: this.get('current.account.email')
      }).save();
    },

    setupController(controller, model) {
      this._super.apply(this, arguments);

      this.store.findAll('app-transfer').then(function (models) {
        // Welcome to bad code. There's refreshments in the corner.
        // The call to get appTransfers won't block, but its also not very test friendly.
        // Tests do not know when this promise resolves, therefore, the controller
        // may already be destroyed by the test by the time this resolves.
        if (!controller.isDestroyed) {
          controller.set('transferRequests', models);
        }
      });
      const app = model.app; // This does not need to block the page load

      if (app && app.get('isInPipeline')) {
        this.fetchRelatedCouplings(app.get('pipelineCoupling'));
      }
    },

    // Render the locked app screen
    _renderLocked() {
      this.set('loadingState.showNavLoading', false);
      return this.transitionTo('app.locked');
    },

    /**
     * Fetch and set permissions for joined (explicitly or implicitly) apps.
     *
     * @method fetchOrgAppPermissions
     * @param {Dashboard.App} app
     * @return {Promise}
     * @private
     */
    fetchOrgAppPermissions(app) {
      const team = app.get('team');

      if (team.get('content') && !this._isRealOrImplicitAdmin(app)) {
        return this.store.queryRecord('team/collaborator', {
          app: app.get('id'),
          userId: this.get('current.account.id')
        }).then(teamCollaborator => {
          app.set('permissions', teamCollaborator.get('permissions'));
          return teamCollaborator;
        });
      }
    },

    fetchCoupling(app) {
      return this.store.queryRecord('pipelineCoupling', {
        app: app.get('id')
      }).then(pipelineCoupling => {
        return pipelineCoupling;
      }, () => {});
    },

    fetchRelatedCouplings(coupling) {
      const pipeline = coupling.get('pipeline.content');

      if ((0, _utils.isPresent)(pipeline)) {
        this.store.query('pipeline-coupling', {
          pipelineId: pipeline.get('id')
        });
      }
    },

    deactivate() {
      const app = this.get('controller.model.app');

      if (app) {
        this.realTimeUpdater.unsubscribeFromModel(app);
      }
    },

    actions: {
      /**
       * Only show the nav loading state if the inbound route
       * is passing through the app and org app routes.
       *
       * If the route is a down-level app route, then render the
       * loading template as normal.
       *
       */
      loading(transaction, route) {
        if (route.routeName === 'app') {
          this.set('loadingState.showNavLoading', true);
        }

        return true;
      },

      refreshRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});