define("dashboard/components/purple-typeahead", ["exports", "@ember/component", "bloodhound", "@ember/object", "@ember/utils", "@ember/runloop", "jquery"], function (_exports, _component, _bloodhound, _object, _utils, _runloop, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    query: '',
    matchingItems: [],
    autoFocus: false,
    displayResults: false,
    // Options - Passed in from template
    //
    // items
    // match-attribute
    // placeholder
    // action
    // results-limit
    // show-results
    // hide-results
    // autofocus
    // label (for aria-label)
    itemsObserver: (0, _object.observer)('items.[]', function () {
      this.engine.clear();
      this.engine.add(this.items);
      this.filterResults();
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('bodyClickEventHandler', _runloop.run.bind(this, this.bodyClickEvent)); // Bind the body click handler, but not until the next run loop.
      // If we do this current loop, the event that caused this component
      // to render will also trigger the body click.

      _runloop.run.next(this, function () {
        $(window).on('click', this.bodyClickEventHandler);
      });

      if (this.autoFocus) {
        _runloop.run.scheduleOnce('afterRender', this, function () {
          if (!(0, _object.get)(window, 'Dashboard.isTouch')) {
            (0, _jquery.default)(this.element).find('input').focus();
          }
        });
      }
    },

    willDestroyElement() {
      // Ensure we turn off the click event on teardown. Do it on the next run
      // loop to ensure we don't try to remove the click handler before it is
      // added.
      _runloop.run.next(this, function () {
        $(window).off('click', this.bodyClickEventHandler);
      });

      this._super(...arguments);
    },

    bodyClickEvent() {
      if (!this.isDestroyed) {
        this.send('blur');
        this.set('displayResults', false);
      }
    },

    init() {
      this._super(...arguments);

      this.engine = new _bloodhound.default({
        datumTokenizer: datum => {
          return $.trim(datum.get(this['match-attribute'])).toLowerCase().split(/\s+/);
        },
        queryTokenizer: query => {
          return $.trim(query).toLowerCase().split(/\s+/);
        },
        local: this.items
      });
    },

    click() {
      return false; // Don't allow the event to bubble and close the typeahead results
    },

    keyDown(event) {
      switch (event.which) {
        case 38:
          // Up Arrow
          this.highlightPrevItem();
          break;

        case 40:
          // Down Arrow
          this.highlightNextItem();
          break;

        case 27:
          // Escape
          this.send('blur');
          this.set('displayResults', false);
          break;

        case 13:
          // Enter
          if ((0, _utils.isPresent)(this.activeItem)) {
            this.selectItem(this.activeItem);
          } else {
            this.selectItem(this.get('matchingItems.firstObject'));
          }

          return false;
      }
    },

    selectItem(item) {
      if (item && !item.get('isDisabled') && this.displayResults) {
        this.send('add', item);
      }
    },

    highlightPrevItem() {
      const matchingItems = this.matchingItems;
      const activeIndex = this.activeItem ? matchingItems.indexOf(this.activeItem) : 0;
      const prevIndex = activeIndex - 1;
      const lastIndex = this['results-limit'] - 1;
      const prevItem = prevIndex === -1 ? matchingItems[lastIndex] : matchingItems[prevIndex];
      this.highlightItem(prevItem);
    },

    highlightNextItem() {
      const matchingItems = this.matchingItems;
      const activeIndex = matchingItems.indexOf(this.activeItem);
      const nextIndex = activeIndex + 1;
      const nextItem = nextIndex === this['results-limit'] ? matchingItems[0] : matchingItems[nextIndex];
      this.highlightItem(nextItem);
    },

    highlightItem(item) {
      if (item) {
        this.matchingItems.invoke('set', 'highlighted', false);
        item.set('highlighted', true);
        this.set('activeItem', item);
      }
    },

    filterResults() {
      const query = this.query;

      if ((0, _utils.isPresent)(query)) {
        this.engine.search(query, results => {
          this.set('matchingItems', results.slice(0, this['results-limit']));
        });
      } else {
        this.set('matchingItems', []);
      }
    },

    showResults() {
      const shouldShow = (0, _utils.isPresent)(this.query);
      this.set('displayResults', shouldShow);
    },

    focusIn() {
      if (this.attrs.focusInAction) {
        this.attrs.focusInAction();
      }

      this.showResults();
    },

    focusOut() {
      if (this.attrs.focusOutAction) {
        this.attrs.focusOutAction();
      }
    },

    actions: {
      blur() {
        (0, _jquery.default)(this.element).find('input').blur();
      },

      clearInput() {
        this.set('query', '');
        this.set('displayResults', false);
      },

      filterResults() {
        this.filterResults();
        this.showResults();
        return false;
      },

      add(item) {
        this.send('blur');
        this.send('clearInput');
        this.attrs.selectAction(item);
      }

    }
  });

  _exports.default = _default;
});