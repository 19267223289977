define("dashboard/templates/components/enterprise-usage/license-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "He9gp7Cy",
    "block": "[[[10,\"dt\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"b\"],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"dd\"],[14,0,\"mb0\"],[12],[1,\"\\n  \"],[1,[30,0,[\"value\"]]],[1,\" / month\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/enterprise-usage/license-details.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});