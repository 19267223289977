define("dashboard/components/pipeline/app-coupler", ["exports", "@ember/service", "@ember/component", "@ember/utils", "@ember/object", "@ember/runloop", "dashboard/helpers/lazy-property", "ember"], function (_exports, _service, _component, _utils, _object, _runloop, _lazyProperty, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    mapBy
  } = _object.computed;
  const DEVELOPMENT_SUFFIX = /(-dev|-development)$/;
  const STAGING_SUFFIX = /(-stg|-staging)$/;
  const PRODUCTION_SUFFIX = /(-prod|-production)$/;
  const STAGE_REGEXES = {
    development: DEVELOPMENT_SUFFIX,
    staging: STAGING_SUFFIX,
    production: PRODUCTION_SUFFIX
  };

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    classNames: ['typeahead-component'],
    query: '',
    apps: (0, _lazyProperty.default)(function () {
      return this.store.findAll('app');
    }),
    appIds: mapBy('app.id', 'id'),
    pipelineApps: alias('pipeline.apps'),
    pipelineAppIds: mapBy('pipelineApps', 'id'),
    availableApps: (0, _object.computed)('apps.@each.{id,isNew,isInPipeline,ownerId}', 'pipelineAppIds.[]', 'pipeline.owner.id', function () {
      const apps = this.apps;

      if ((0, _utils.isNone)(apps)) {
        return [];
      }

      const pipelineAppIds = this.pipelineAppIds || [];
      const pipelineOwnerId = this.get('pipeline.owner.id');
      let result = apps.reject(app => {
        return app.get('isInPipeline') || pipelineAppIds.includes(app.get('id')) || app.get('isNew');
      });

      if (pipelineOwnerId) {
        result = result.filterBy('owner.id', pipelineOwnerId);
      }

      return result;
    }),
    occasionallyRefetchApps: (0, _object.observer)('query', function () {
      if ((0, _utils.isPresent)(this.query)) {
        if (_ember.default.testing) {
          this.refetchAllApps();
        } else {
          _runloop.run.throttle(this, this.refetchAllApps, 10000);
        }
      }
    }),

    refetchAllApps() {
      if (!this.isLoadingApps) {
        this.store.findAll('app').then(apps => this.set('apps', apps));
      }
    },

    appSuggestions: (0, _object.computed)('availableApps.@each.name', 'pipeline.name', 'stage', function () {
      const stage = this.stage;
      const pipelineName = this.get('pipeline.name');
      const regex = STAGE_REGEXES[stage];
      return this.availableApps.filter(app => {
        const name = app.get('name');
        const isProductionApp = stage === 'production' && name === pipelineName;

        if (isProductionApp) {
          return true;
        }

        const matchesPipeline = name.includes(pipelineName);
        const matchesStage = name.match(regex);
        return matchesPipeline && matchesStage;
      });
    }),
    actions: {
      addCoupling(app) {
        const copy = this.store.peekRecord('app', app.get('id'));
        this.attrs.addCoupling(copy);
      },

      toggleNewAppPanel(searchTerm) {
        this.toggleNewAppPanel(searchTerm);
      }

    }
  });

  _exports.default = _default;
});