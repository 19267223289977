define("dashboard/validations/authorization", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    description: (0, _validators.validatePresence)({
      presence: true,
      message: ''
    }),
    expiresIn: (0, _validators.validateNumber)({
      integer: true,
      allowBlank: true,
      positive: true
    })
  };
  _exports.default = _default;
});