define("dashboard/templates/components/legacy-oauth-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "I7rvUuzQ",
    "block": "[[[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-auto\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[\"Warning\",\"warning-badge-16\",16,\"malibu-fill-gradient-orange mr2\"]],null],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"f5 b lh-title\"],[12],[1,\"Legacy OAuth connection\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"f5 lh-copy mt--1\"],[12],[1,\"You must reconnect this \"],[1,[52,[30,0,[\"isApp\"]],\"app\",\"pipeline\"]],[1,\" to use the Heroku GitHub app\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"noPipelineOwner\"]],[[[6,[39,2],null,[[\"title\"],[\"Assign an owner to this pipeline before upgrading to Heroku GitHub app connection\"]],[[\"default\"],[[[[1,\"      \"],[10,\"button\"],[14,0,\"hk-button-sm--disabled-primary\"],[12],[1,\"Reconnect\"],[13],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"default\",\"buttonType\",\"size\",\"onClick\"],[\"Reconnect\",\"warning\",\"small\",[28,[37,4],[[30,0],[30,0,[\"reconnect\"]]],null]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"if\",\"x-tooltip\",\"hk-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/legacy-oauth-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});