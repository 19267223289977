define("dashboard/components/app-json-config-var", ["exports", "@ember/object", "@ember/object/computed", "@ember/component"], function (_exports, _object, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'tbody',
    classNames: ['app-json-config-row'],
    isEditing: true,
    isRemovable: (0, _computed.not)('configVar.isEmpty'),
    setParent: (0, _object.observer)('parentConfigVars.[]', 'configVar.key', function () {
      const parentConfigVars = this.parentConfigVars || [];
      const parent = parentConfigVars.findBy('key', this.get('configVar.key'));
      this.set('configVar.parent', parent);
    }),
    types: [{
      type: 'inherit',
      label: 'Copy at build time'
    }, {
      type: 'copy',
      label: 'Copy now'
    }, {
      type: 'literal',
      label: 'Set value'
    }, {
      type: 'generator',
      label: 'Generate secret'
    }],
    inheritPlaceholder: (0, _object.computed)('app.name', function () {
      return `will copy from ${this.get('app.name')}`;
    }),
    actions: {
      remove() {
        this.action(this.configVar);
      }

    }
  });

  _exports.default = _default;
});