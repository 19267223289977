define("dashboard/templates/components/metrics/chart-controls/process-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "u8ARbmXD",
    "block": "[[[6,[39,0],null,[[\"class\",\"route\",\"models\"],[\"metrics__process-switcher__item-link\",\"app.metrics.type\",[28,[37,1],[[30,0,[\"app\",\"name\"]],[30,0,[\"process\",\"type\"]]],null]]],[[\"default\"],[[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[10,1],[14,0,\"metrics__process-switcher__item-header\"],[15,\"title\",[29,[[30,0,[\"process\",\"type\"]]]]],[12],[1,[30,0,[\"process\",\"type\"]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"metrics__process-switcher__item-content\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"process\"],[[30,0,[\"process\"]]]]]],[1,\"\\n      \"],[10,1],[14,0,\"metrics__process-switcher__item-content-quantity\"],[12],[1,\"\\n        \"],[1,[30,0,[\"process\",\"quantity\"]]],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"process\",\"quantity\"]],\"dyno\",\"dynos\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showIcon\"]],[[[1,\"    \"],[8,[39,5],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"caret-16\",\"fill-purple caret-icon\",16]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"link-to\",\"array\",\"process-icon\",\"pluralize-word\",\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/process-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});