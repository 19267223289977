define("dashboard/routes/app/logs", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app"], function (_exports, _rsvp, _service, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model() {
      const {
        app
      } = this.modelFor('app');
      const formations = app.hasMany('formations').reload();
      return (0, _rsvp.hash)({
        app,
        formations
      });
    },

    afterModel() {
      this.analytics.logEvent('App Logs Page', 'Viewed');
    }

  });

  _exports.default = _default;
});