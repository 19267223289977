define("dashboard/mixins/production-check", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed"], function (_exports, _mixin, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    passed: (0, _computed.equal)('status', 'passed'),
    warning: (0, _computed.equal)('status', 'warning'),
    failed: (0, _computed.equal)('status', 'failed'),
    skipped: (0, _computed.equal)('status', 'skipped'),
    message: (0, _object.computed)('status', 'warningMessage', 'failedMessage', 'skippedMessage', function () {
      return this.get(`${this.status}Message`) || '';
    })
  });

  _exports.default = _default;
});