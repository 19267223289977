define("dashboard/controllers/pipelines/pipeline/tests", ["exports", "@ember/controller", "@ember/object", "dashboard/config/environment"], function (_exports, _controller, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['selectedFilterBranch'],
    selectedFilterBranch: null,
    githubIntegrationDisabled: _environment.default.githubIntegrationDisabled,

    get githubIntegrationReenabled() {
      return _environment.default.githubIntegrationReenabled;
    },

    // this is bananas..
    // ..but it's to deal with an apparent bug in Ember
    // which is that `queryParams` are provided to the `model` in a { object }
    // but the actual `queryParams` computed property isn't updated until
    // _after_ the page is rendered. this causes the page to initially show
    // all tests and then a second-or-two later it rerenders with just the subset
    //
    // these two hacks hide this issue by first using the value fed into the model
    realFakeBranch: (0, _object.computed)('selectedFilterBranch', 'model.selectedFilterBranch', function () {
      return this.selectedFilterBranch || this.get('model.selectedFilterBranch');
    }),
    // and then replacing that model value with the `selectedFilterBranch`
    // computed property once it has been populated by Ember.
    // without this second hack the `realFakeBranch` value never updates
    // and causes the UI to become incorrect
    emberHacks101: (0, _object.observer)('selectedFilterBranch', function () {
      this.set('realFakeBranch', this.selectedFilterBranch);
    })
  });

  _exports.default = _default;
});