define("dashboard/components/datastore/postgres/charts", ["exports", "@ember/component", "@ember/object", "dashboard/mixins/metrics/timeframe-options", "ember-concurrency", "dashboard/config/environment", "dashboard/utils/metrics/datastore-types"], function (_exports, _component, _object, _timeframeOptions, _emberConcurrency, _environment, _datastoreTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const datastoreMetricsPollDuration = _environment.default.datastoreMetricsPollDuration;

  var _default = _component.default.extend(_timeframeOptions.default, {
    beforeHours: 0,
    afterHours: 2,
    currentTimeframe: (0, _object.computed)('beforeHours', 'afterHours', 'allDatastoreOptions', function () {
      const timeframe = this.get('allDatastoreOptions').find(option => {
        return option.beforeHours === this.get('beforeHours') && option.afterHours === this.get('afterHours');
      });
      return timeframe || this.get('defaultTimeframe');
    }),
    pollMetrics: (0, _emberConcurrency.task)(function* () {
      while (true) {
        this.fetchDatastoreMetricsTask.perform(_datastoreTypes.default.postgres);

        if (!datastoreMetricsPollDuration) {
          return;
        }

        yield (0, _emberConcurrency.timeout)(datastoreMetricsPollDuration);
      }
    }).drop().on('didReceiveAttrs'),
    connections: (0, _object.computed)('datastoreMetrics.ioConnections', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.datastoreMetrics.get('ioConnections');
    }),
    commits: (0, _object.computed)('datastoreMetrics.commits', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.datastoreMetrics.get('commits');
    }),
    indexCacheHitRate: (0, _object.computed)('datastoreMetrics.indexCacheHitRate', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.datastoreMetrics.get('indexCacheHitRate');
    }),
    tableCacheHitRate: (0, _object.computed)('datastoreMetrics.tableCacheHitRate', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.datastoreMetrics.get('tableCacheHitRate');
    }),
    actions: {
      updateTimeframe(beforeHours, afterHours) {
        this.get('updateTimeframe')(beforeHours, afterHours, _datastoreTypes.default.postgres);
      }

    }
  });

  _exports.default = _default;
});