define("dashboard/components/app-json-buildpack-list", ["exports", "@ember/service", "@ember/component", "@ember/utils", "@ember/object"], function (_exports, _service, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'table',
    classNames: ['table', 'editable-list'],
    store: (0, _service.inject)(),
    lastBuildpack: (0, _object.computed)('buildpacks.[]', function () {
      if ((0, _utils.isPresent)(this.get('buildpacks.lastObject'))) {
        return this.get('buildpacks.lastObject');
      } else {
        const emptyBuildpack = this.store.createRecord('buildpack-installation', {
          ordinal: 0
        });
        return emptyBuildpack;
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      const emptyBuildpack = this.store.createRecord('buildpack-installation', {
        ordinal: this.get('buildpacks.length')
      });
      this.buildpacks.pushObject(emptyBuildpack);
    },

    willDestroyElement() {
      this.buildpacks.filterBy('isNew').invoke('deleteRecord');

      this._super(...arguments);
    },

    addEmptyBuildpack() {
      const buildpacks = this.buildpacks;
      const emptyBuildpack = this.store.createRecord('buildpack-installation', {
        ordinal: this.get('buildpacks.length')
      });
      buildpacks.pushObject(emptyBuildpack);
    },

    actions: {
      checkBuildpacks() {
        if ((0, _utils.isPresent)(this.get('lastBuildpack.name'))) {
          this.addEmptyBuildpack();
        }
      },

      removeBuildpack(buildpack) {
        this.buildpacks.removeObject(buildpack);
        this.store.deleteRecord(buildpack);
      }

    }
  });

  _exports.default = _default;
});