define("dashboard/components/metrics/charts/visualizations/ruby-free-slots", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/aggregate-chart"], function (_exports, _computed, _aggregateChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aggregateChart.default.extend({
    tagName: 'metrics-ruby-objects-chart',
    'data-test-target': 'metrics-ruby-chart',
    title: 'Ruby: Free Memory Slots',
    devCenterName: 'language-runtime-metrics-ruby#free-memory-slots',
    isBeta: true,
    maxData: (0, _computed.alias)('data.freeSlotsMax'),
    avgData: (0, _computed.alias)('data.freeSlotsAvg'),
    minData: (0, _computed.alias)('data.freeSlotsMin')
  });

  _exports.default = _default;
});