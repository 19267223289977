define("dashboard/services/reporter", ["exports", "@ember/object", "@ember/service", "@ember/utils", "ember", "ember-concurrency", "dashboard/utils/errors"], function (_exports, _object, _service, _utils, _ember, _emberConcurrency, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals Rollbar */

  /* eslint-disable no-console */

  /**
   * A class for reporting errors and events to an external service which tracks
   * them.
   *
   * @class Dashboard.Reporter
   * @extends Ember.Object
   */
  var _default = _service.default.extend({
    current: (0, _service.inject)(),

    /**
     * Called when the reporter is instantiated. Configures Rollbar and ensures
     * that `Ember.onerror` calls `#error`.
     *
     * @method init
     * @private
     */
    init() {
      this._super(...arguments);

      if (!(_ember.default.onerror && _ember.default.onerror.wrappedForTesting)) {
        this._originalOnError = _ember.default.onerror;
        _ember.default.onerror = this.error.bind(this);
      }
    },

    /**
     * Track Ember route transitions through Rollbar's telemetry feature. Adds
     * a navigation event if route transitions successfully. If the route
     * transition is unsuccessful, the transition is tracked as an error
     * "event" so it shows up in the telemetry for the error in rollbar.
     *
     * External function to be called by the Ember Router in app/router.js.
     * @param transition {Transition} A transition from the Ember Router's willTransition hook.
     * @param oldRouteName {string} The name of the route being transitioned away from, e.g. the current route.
     * @param newRouteName {string} The name of the route being transitioned to.
     * @return {Promsie} a promise that resolves when the task instance completes
     */
    trackTransition(transition, oldRouteName, newRouteName) {
      return this.get('_trackTransitionTask').perform(transition, oldRouteName, newRouteName, this);
    },

    _trackTransitionTask: (0, _emberConcurrency.task)({
      currentNavigation: null,

      *perform(transition, oldRouteName, newRouteName, reporter) {
        try {
          const currentNavigation = {
            from: oldRouteName,
            to: newRouteName
          }; // set the currentNavigation here so the `error` function below
          // can use it.

          this.set('currentNavigation', currentNavigation); // wait for the transition to complete successfully
          // before adding it to our Rollbar telemetry.

          yield transition.followRedirects();

          reporter._captureNavigationTelemetry(currentNavigation);
        } catch (_ignoredRoutingError) {// route errors will be handled by the `error` function of the reporter Service below.
          // the `error` function below is actually called before we get to this `.catch`
          // due to the Ember.Router calling Ember.onerror before rejecting a transition's promise.
          // we don't need to re-handle them here.
        } finally {
          this.set('currentNavigation', null);
        }
      } // drop any "new" transitions that happen while we wait for followRedirects()
      // to fulfill or reject. followRedirects will catch route aborts, which reject
      // transitions.


    }).drop(),

    // create a navigation telemetry item in rollbar.
    // This represents the happy path when the user has completed a route transition
    // without any errors.
    // Hopefully this proves helpful to trying to retrace a user's step to the point
    // where they got to an error.
    _captureNavigationTelemetry(_ref) {
      let {
        from,
        to
      } = _ref;
      this.rollbar.client.telemeter.captureNavigation(from, to);
    },

    // create a telemetry item for failed transitions.
    // this represents the sad path when loading a route causes a user
    // to see the generic warning that something is broken.
    // this will be the item that appears right before the error in the rollbar telemetry.
    _captureAttemptedTransitionTelmetry() {
      // due to the way transition promises seem to work (Ember.onerror is called
      // before transition promises reject), our error handler will be called
      // while the task is still running.
      if (this.get('_trackTransitionTask.isRunning')) {
        // an error ocurred while transitioning between two routes.
        // This means a link was clicked or transitionTo was called,
        // but somewhere in the beforeModel, model, afterModel, or internals
        // of the Ember Router some error happened.
        const {
          from,
          to
        } = this.get('_trackTransitionTask.last.currentNavigation'); // log this as an error so we can differentiate it from normal navigation
        // telemetry events

        try {
          this.rollbar.captureEvent({
            type: 'failed_route_transition',
            message: `Failed attempted route transition from ${from} to ${to}`
          }, 'error');
        } catch (_ignoredError) {// if we are in a transition, we don't want to cause a new error to get
          // reported, otherwise we'll end up right back here trying to add it to the
          // telemetry but causing an infinite loop because followRedirects will
          // continue to follow the transition to the new error route.
          // in case rollbar fails to load or errors, let's prevent infinite loops
          // by just ignoring the error and letting error reporter continue to do its
          // thing.
        }
      }
    },

    willDestroy() {
      this._super(...arguments);

      _ember.default.onerror = this._originalOnError;
    },

    trackUser(account) {
      this.rollbar.configure({
        payload: {
          person: {
            id: account.get('id')
          }
        }
      });
    },

    /**
     * Report an error.
     *
     * If it was an ajax call:
     * - report as *info* for 4xx
     * - report as *error* for any other status
     *
     * Otherwise pass on the error directly
     *
     * @method error
     * @param {Error} err the error to report
     */
    error(err) {
      // if err.,twoFactorNeededTransition means that the error ocurrred during a route transition
      // and was caught by the `error` action in app/routes/app.js.
      // The protected/error route knows how to handle these kind of errors, so in that case, we do nothing.
      // This code is for errors that happen outside of route transitions, e.g. anything that goes to Ember.onerror,
      // such as any actions that are handled in components, network requests not made during route transitions, etc.
      if ((0, _errors.isTwoFactorRequiredError)(err)) {
        if (!err.twoFactorNeededTransition && !err.handledByTwoFactorComponent) {
          this.current.set('twoFactorRequiredError', err);
          this.current.set('showTwoFactor', true);
        }
      }

      console.error(err);
      const statusCode = this.getStatusCode(err);
      const rollbar = this.rollbar; // ignore cancelled requests

      const isIgnoredCode = statusCode === 0;

      if (isIgnoredCode) {
        return;
      }

      this._captureAttemptedTransitionTelmetry();

      err = this.scrubError(err);

      if ((0, _utils.isPresent)(statusCode)) {
        const message = this.xhrErrorMessage(err, statusCode);

        if (statusCode >= 400 && statusCode < 500) {
          // 4xx, log as info
          rollbar.info(message, {
            jqXHR: err
          });
        } else {
          // 5xx, log as error
          rollbar.error(message, {
            jqXHR: err
          });
        }
      } else {
        // send regular Errors straight to Rollbar
        rollbar.error(...arguments);
      }
    },

    getStatusCode(err) {
      let code = err.status; // status is reserved word

      if (err.isAdapterError) {
        // this is an Ember Data AdapterError, need to dig for the status code
        const error = err.errors[0] || {};
        code = parseInt(error.status); // yep it's a string

        if (isNaN(code)) {
          code = null;
        }
      }

      return code;
    },

    scrubError(err) {
      if (err.message) {
        let msg = err.message; // scrub path for github calls in AdapterErrors so we don't expose repo details

        msg = msg.replace(/(api.github.com\/[\w]*\/)([\S]*)/, '$1**SCRUBBED**'); // scrub messages that contain the word `password`

        if (msg.match(/password/i)) {
          msg = "Scrubbed because it contained the word 'password'";
        }

        err.message = msg;
      }

      return err;
    },

    // Figure out what message to use for the given `err`, which can be an
    // EmberData AdapterError or a plain jqXHR.
    xhrErrorMessage(err, statusCode) {
      // AdapterErrors will have a message set
      let message = err.message;

      if (message) {
        // AdapterError messages can contain the full endpoint path, including
        // the unique ids, so we'll generalize the uuids to something like `/apps/:id`
        const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
        message = message.replace(uuidRegex, ':id');
      } else {
        // the regular jqXHR can have a few forms
        const baseMessage = `AJAX (${statusCode})`;

        if (err.responseJSON && err.responseJSON.id) {
          message = `${baseMessage}: ${err.responseJSON.id}`;
        } else if (err.errorTitleSubject) {
          message = `${baseMessage}: ${err.errorTitleSubject}`;
        } else {
          message = baseMessage;
        }
      }

      return message;
    },

    /**
     * The underlying Rollbar instance used to report errors and info. If the
     * Rollbar library isn't loaded, falls back to no-op functionality.
     *
     * @property rollbar
     * @private
     */
    rollbar: (0, _object.computed)(function () {
      if (typeof Rollbar === 'undefined') {
        return {
          error(error) {
            console.error(error);
          },

          info() {
            console.info.apply(window, arguments);
          },

          warning() {
            console.warning.apply(window, arguments);
          },

          configure() {},

          captureEvent() {},

          telemeter: {
            captureNavigation() {}

          }
        };
      } else {
        return Rollbar;
      }
    })
  });

  _exports.default = _default;
});