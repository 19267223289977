define("dashboard/templates/app/deploy/heroku-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Pc+FsikP",
    "block": "[[[6,[39,0],null,[[\"title\",\"description\"],[\"Deploy your Docker-based app\",\"Heroku Container Registry allows you to deploy your Docker-based app to Heroku. <br/><a href='https://devcenter.heroku.com/articles/container-registry-and-runtime' class='hk-link'>Learn more</a>\"]],[[\"default\"],[[[[1,\"  \"],[10,\"h4\"],[12],[1,\"Install the Heroku CLI\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Download and install the \"],[10,3],[14,6,\"https://devcenter.heroku.com/articles/heroku-command-line\"],[14,0,\"hk-link\"],[12],[1,\"Heroku CLI\"],[13],[1,\".\"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"If you haven't already, log in to your Heroku account and follow the prompts to create a new SSH public key.\"],[13],[1,\"\\n\\n  \"],[10,\"pre\"],[12],[1,\"$ heroku login\"],[13],[1,\"\\n\\n  \"],[10,\"h4\"],[12],[1,\"Log in to Container Registry\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"You must have Docker set up locally to continue. You should see output when you run this command.\"],[13],[1,\"\\n  \"],[10,\"pre\"],[12],[1,\"$ docker ps\"],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,\"Now you can sign into Container Registry.\"],[13],[1,\"\\n  \"],[10,\"pre\"],[12],[1,\"$ heroku container:login\"],[13],[1,\"\\n\\n  \"],[10,\"h4\"],[12],[1,\"Push your Docker-based app\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Build the Dockerfile in the current directory and push the Docker image.\"],[13],[1,\"\\n  \"],[10,\"pre\"],[12],[1,\"$ heroku container:push web\"],[13],[1,\"\\n\\n  \"],[10,\"h4\"],[12],[1,\"Deploy the changes\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Release the newly pushed images to deploy your app.\"],[13],[1,\"\\n  \"],[10,\"pre\"],[12],[1,\"$ heroku container:release web\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"list-group-item-purple2\"]]",
    "moduleName": "dashboard/templates/app/deploy/heroku-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});