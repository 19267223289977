define("dashboard/components/org/resources-totals", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    sum,
    mapBy
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['bg-dark-gray', 'w-100', 'flex pv2 ph1', 'limit-width'],
    resourceItems: readOnly('organization.resourceItems'),
    appCount: readOnly('resourceItems.length'),
    dynoUnits: mapBy('resourceItems', 'dynoUnits'),
    dynoUnitsSum: sum('dynoUnits'),
    addonsTotal: mapBy('resourceItems', 'addonsTotal'),
    addonsTotalSum: sum('addonsTotal')
  });

  _exports.default = _default;
});