define("dashboard/helpers/subtract", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.subtract = subtract;

  /**
   * This helper can be used to decrement a number from a handelbars template
   * Usage
   * {{subtract initialValue valueToDecrementBy}}
   */
  function subtract(params) {
    const formattedStartValue = Number(params[0]);
    const formattedDecrement = Number(params[1]);
    return formattedStartValue - formattedDecrement;
  }

  var _default = (0, _helper.helper)(subtract);

  _exports.default = _default;
});