define("dashboard/components/confirm-sms-number", ["exports", "@ember/component", "@ember/service", "ember-concurrency"], function (_exports, _component, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    current: (0, _service.inject)(),
    api: (0, _service.inject)(),
    smsNumber: null,
    confirmationCode: null,
    password: null,
    error: null,
    confirmCode: (0, _emberConcurrency.task)(function* () {
      const api = this.api;
      const account = this.get('current.account');
      const confirmationCode = this.confirmationCode;
      const data = {
        confirmation_code: confirmationCode
      };

      try {
        yield api.post('/users/~/sms-number/actions/confirm', data);
      } catch (error) {
        this.set('error', error);
        return;
      }

      try {
        yield account.reload();
      } catch (error) {// It’s OK to ignore this error.
        // The account will get reloaded in due course.
      }

      this.onConfirmCode();
    }).drop(),
    resendConfirmation: (0, _emberConcurrency.task)(function* () {
      const api = this.api;
      const smsNumber = this.smsNumber;
      const password = this.password;
      const data = {
        sms_number: smsNumber,
        password
      };

      try {
        yield api.patch('/account', data);
      } catch (error) {
        this.set('error', error);
      }
    }).drop()
  });

  _exports.default = _default;
});