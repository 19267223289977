define("dashboard/utils/metrics/aggregate-values", ["exports", "@ember/object", "d3"], function (_exports, _object, _d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maxDatum = maxDatum;
  _exports.maxValue = maxValue;
  _exports.meanValue = meanValue;
  _exports.medianValue = medianValue;
  _exports.minValue = minValue;

  function maxDatum(key) {
    return (0, _object.computed)(`${key}.[]`, function () {
      const array = this.get(key) || [];
      const sortedArray = array.sortBy('1');
      return sortedArray.get('lastObject');
    });
  }

  function maxValue(key) {
    return (0, _object.computed)(`${key}.[]`, function () {
      const array = this.get(key) || [];
      const values = array.mapBy('1');
      return (0, _d.max)(values);
    });
  }

  function meanValue(key) {
    return (0, _object.computed)(`${key}.[]`, function () {
      const values = this.get(key).mapBy('1');
      return (0, _d.mean)(values);
    });
  }

  function medianValue(key) {
    return (0, _object.computed)(`${key}.[]`, function () {
      const values = this.get(key).mapBy('1');
      return (0, _d.median)(values);
    });
  }

  function minValue(key) {
    return (0, _object.computed)(`${key}.[]`, function () {
      const values = this.get(key).mapBy('1');
      return (0, _d.min)(values);
    });
  }
});