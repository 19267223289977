define("dashboard/components/datastore/kafka/topic-row", ["exports", "@ember/component", "@ember/object", "moment"], function (_exports, _component, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'kafka-topic-row',
    classNames: ['mv2'],
    humanizedRetentionTime: (0, _object.computed)('topic.retention_time_ms', function () {
      if (this.topic.retention_time_ms) {
        return _moment.default.duration(this.topic.retention_time_ms).humanize();
      } else {
        return 'automatic';
      }
    }),
    topicStatus: (0, _object.computed)('topic.status', function () {
      return this.topic.status.toUpperCase();
    })
  });

  _exports.default = _default;
});