define("dashboard/adapters/identity-provider-certificate", ["exports", "dashboard/adapters/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.identity-providers',
    dsErrorMonitor: (0, _service.inject)(),

    handleResponse(status, headers, payload, requestData) {
      if (this.isInvalid(status, headers, payload)) {
        payload = {
          errors: [{
            status: '422',
            source: {
              pointer: '/data/attributes/body'
            },
            attribute: 'body',
            detail: payload.message
          }]
        };
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForFindRecord(id, modelName, snapshot) {
      const idpId = snapshot.belongsTo('identityProvider').id;
      return `${this.host}/identity-providers/${idpId}/certificates/${id}`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const idpId = snapshot.belongsTo('identityProvider').id;
      return `${this.host}/identity-providers/${idpId}/certificates/${id}`;
    },

    urlForCreateRecord(modelName, snapshot) {
      const idpId = snapshot.belongsTo('identityProvider').id;
      return `${this.host}/identity-providers/${idpId}/certificates`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const idpId = snapshot.belongsTo('identityProvider').id;
      return `${this.host}/identity-providers/${idpId}/certificates/${id}`;
    }

  });

  _exports.default = _default;
});