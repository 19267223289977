define("dashboard/templates/components/efp-rapid-upgrade-table-pipeline-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ITblSmhV",
    "block": "[[[10,\"tr\"],[14,0,\"dt-row bg-light-silver h--48 bb b--light-gray\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"dtc f3 normal near-black\"],[14,\"colspan\",\"5\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"pipeline-16\",16,\"malibu-fill-gradient-purple ml2 mr1\"]],null],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@name\"],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/efp-rapid-upgrade-table-pipeline-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});