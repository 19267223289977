define("dashboard/controllers/app", ["exports", "@ember/service", "@ember/object", "@ember/controller", "dashboard/mixins/metrics/metrics-query-params", "dashboard/mixins/formations", "dashboard/mixins/preferences"], function (_exports, _service, _object, _controller, _metricsQueryParams, _formations, _preferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    and,
    bool,
    equal,
    not,
    or,
    readOnly
  } = _object.computed;

  var _default = _controller.default.extend(_metricsQueryParams.default, _formations.default, _preferences.default, {
    applicationController: (0, _controller.inject)('application'),
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    app: alias('model.app'),
    formations: alias('app.formations'),
    appPermissions: alias('model.appPermissions'),
    team: readOnly('model.app.team'),
    coupling: readOnly('model.app.pipelineCoupling'),
    pipeline: readOnly('coupling.pipeline'),
    queryParams: [{
      webConsole: {
        as: 'web-console'
      }
    }],
    webConsole: null,
    transferRequests: (0, _object.computed)(function () {
      return [];
    }),
    pendingTransferRequests: (0, _object.computed)('transferRequests.@each.state', function () {
      return this.transferRequests.filterBy('state', 'pending');
    }),
    incomingTransferRequests: (0, _object.computed)('pendingTransferRequests.[]', 'current.account.email', function () {
      return this.pendingTransferRequests.filterBy('recipient.email', this.get('current.account.email'));
    }),
    outgoingTransferRequests: (0, _object.computed)('pendingTransferRequests.[]', 'current.account.email', function () {
      return this.pendingTransferRequests.rejectBy('recipient.email', this.get('current.account.email'));
    }),
    _currentPath: readOnly('applicationController.currentPath'),
    isLockedPage: equal('_currentPath', 'protected.app.locked'),
    isNotLockedPage: not('isLockedPage'),
    isSchedulerRoute: equal('_currentPath', 'protected.app.scheduler'),
    isDisplayingConsole: false,
    _shouldLinkToMetricsPaywall: or('isFreeTier', 'hasNoDynos'),
    shouldLinkToMetricsType: not('_shouldLinkToMetricsPaywall'),
    canViewSettingsPage: or('appPermissions.canRenameApp', 'appPermissions.canTransferApp', 'appPermissions.canDeleteApp', 'appPermissions.canManageDomains', 'appPermissions.canViewConfigVars'),
    // action bar helpers/properties
    hasActionBar: alias('isNotLockedPage'),
    canViewDeployPage: bool('appPermissions.canGitRepo'),
    notInPipeline: not('pipeline'),
    showAddToPipeline: and('notInPipeline', 'canViewDeployPage'),
    notPrivateSpaceShieldApp: not('app.isPrivateSpaceShieldApp'),
    canUseAppConsole: and('appPermissions.canViewAppConsole', 'notPrivateSpaceShieldApp'),
    hasMoreAppActions: or('appPermissions.canViewLogs', 'appPermissions.canManageWebhooks', 'appPermissions.canScalePs', 'canUseAppConsole'),
    hasMorePopover: or('hasMoreAppActions', 'showAddToPipeline'),

    coordinateAcceptTransfer(appTransfer) {
      const currentAppName = this.get('app.name');
      const transferAppName = appTransfer.get('app.name');
      return appTransfer.accept().then(() => {
        // If on the app I'm accepting, just refresh route
        // Otherwise, transition to newly owned app.
        if (transferAppName === currentAppName) {
          this.send('refreshRoute');
        } else {
          this.transitionToRoute('app', transferAppName);
        }
      });
    },

    actions: {
      displayConsoleModal() {
        this.analytics.logEvent('App Console', 'Opened');
        this.set('webConsole', this.get('app.name'));
      },

      setFocusOnConsole() {
        $('.remote-terminal-command-input').focus();
      },

      onDismissConsole() {
        this.set('webConsole', null);
      },

      displayProductionCheckModal() {
        if (this.get('appPermissions.canScalePs')) {
          this.analytics.logEvent('Production Check', 'Viewed');
          this.set('isProductionCheckModalVisible', true);
        }
      },

      closeProductionCheckModal() {
        this.set('isProductionCheckModalVisible', false);
      },

      promptRestartDynos() {
        this.set('isRestartConfirmationModalVisible', true);
      },

      goBackToRoute(routeArgs) {
        this.transitionToRoute(...routeArgs);
      },

      acceptTransfer(transfer) {
        this.analytics.logEvent('App Transfer', 'Accepted');
        this.set('isAcceptModalVisible', false);
        return this.coordinateAcceptTransfer(transfer);
      },

      declineTransfer(transfer) {
        this.analytics.logEvent('App Transfer', 'Declined');
        return transfer.decline();
      },

      cancelTransfer(transfer) {
        return transfer.destroyRecord().then(() => {
          this.analytics.logEvent('App Transfer', 'Canceled');
          this.transferRequests.removeObject(transfer);
        });
      }

    }
  });

  _exports.default = _default;
});