define("dashboard/serializers/metrics/language-specific-memory", ["exports", "@ember/polyfills", "dashboard/serializers/metrics"], function (_exports, _polyfills, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend({
    normalizeResponse(store, primaryModelClass, payload) {
      const unpackedData = {};
      Object.keys(payload.data).forEach(key => {
        (0, _polyfills.merge)(unpackedData, payload.data[key]);
      });
      payload.data = unpackedData;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});