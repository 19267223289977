define("dashboard/components/app/web-hook-deliveries/meta", ["exports", "@ember/component", "@ember/service", "ember-concurrency"], function (_exports, _component, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.loadPayload.perform();
    },

    loadPayload: (0, _emberConcurrency.task)(function* () {
      // we only want to force a reload of the model if we don't already have the Payload
      if (!this.get('delivery.lastAttempt.request.body')) {
        return yield this.store.findRecord('app/webhook-delivery', this.get('delivery.id'), {
          reload: true
        });
      }
    })
  });

  _exports.default = _default;
});