define("dashboard/mixins/adapters/authorization-headers-mixin", ["exports", "@ember/service", "@ember/object/mixin", "@ember/object", "@ember/utils"], function (_exports, _service, _mixin, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    session: (0, _service.inject)(),
    // Returns all necessary authorization headers, including Heroku sudo headers.
    authorizationHeaders: (0, _object.computed)('session.bearerToken', 'sudoHeaders', function () {
      const headers = this.sudoHeaders;
      const bearerToken = this.get('session.bearerToken');

      if (bearerToken) {
        headers['Authorization'] = `Bearer ${bearerToken}`;
      }

      return headers;
    }),
    // Returns necessary sudo headers when assuming identity.
    sudoHeaders: (0, _object.computed)('session.isAssumed', 'session.sudoUser', 'session.sudoReason', function () {
      const sudoUser = this.get('session.sudoUser');
      const sudoReason = this.get('session.sudoReason');
      const isAssumed = this.get('session.isAssumed');
      const headers = {};

      if (isAssumed && (0, _utils.isPresent)(sudoUser) && (0, _utils.isPresent)(sudoReason)) {
        headers['X-Heroku-Sudo'] = true;
        headers['X-Heroku-Sudo-User'] = sudoUser;
        headers['X-Heroku-Sudo-Reason'] = sudoReason;
      }

      return headers;
    })
  });

  _exports.default = _default;
});