define("dashboard/adapters/pipeline/collaborator", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.pipeline-collaborators',

    urlForCreateRecord() {
      return `${this.host}/ephemeral-app-collaborators`;
    },

    urlForUpdateRecord(id) {
      return `${this.host}/ephemeral-app-collaborators/${id}`;
    },

    urlForDeleteRecord(id) {
      return `${this.host}/ephemeral-app-collaborators/${id}`;
    }

  });

  _exports.default = _default;
});