define("dashboard/adapters/recipient", ["exports", "@ember/object", "dashboard/adapters/application", "dashboard/config/environment"], function (_exports, _object, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    updateMethod: 'PUT',
    headers: {},

    urlForCreateRecord(modelName, snapshot) {
      return `${_environment.default.telexUrl}/apps/${snapshot.belongsTo('app').id}/recipients`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const url = `${this.urlForCreateRecord(modelName, snapshot)}/${id}`;
      const urlSuffix = (0, _object.get)(snapshot, 'adapterOptions.urlSuffix');
      return urlSuffix ? url + urlSuffix : url;
    },

    urlForDeleteRecord() {
      return this.urlForUpdateRecord(...arguments);
    }

  });

  _exports.default = _default;
});