define("dashboard/templates/components/third-party-connection-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y10mORa4",
    "block": "[[[10,0],[14,0,\"fw6 f7 gray ttu tracked pt--3\"],[12],[1,[30,0,[\"listTitle\"]]],[13],[1,\"\\n\"],[18,1,[[30,0,[\"sortedConnectionItems\"]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "dashboard/templates/components/third-party-connection-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});