define("dashboard/components/metrics/charts/connected-chart-hover/config-group", ["exports", "dashboard/components/metrics/charts/connected-chart-hover/activity-group"], function (_exports, _activityGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activityGroup.default.extend({});

  _exports.default = _default;
});