define("dashboard/routes/pipelines/pipeline/tests/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/utils"], function (_exports, _route, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    // this beforeModel is not getting called on initial page load
    beforeModel(transition) {
      const {
        selectedFilterBranch
      } = transition.to.queryParams;
      const model = this.modelFor('pipelines.pipeline.tests');
      let testRuns;

      if (selectedFilterBranch) {
        testRuns = (0, _object.get)(model, 'testRuns').filterBy('commitBranch', selectedFilterBranch);
      } else {
        testRuns = (0, _object.get)(model, 'testRuns');
      }

      const latestTest = (0, _object.get)(testRuns, 'firstObject.number');

      if (model.pipeline.get('isCiEnabled') && (0, _utils.isPresent)(latestTest)) {
        this.transitionTo('pipelines.pipeline.tests.test-run', latestTest);
      }
    }

  });

  _exports.default = _default;
});