define("dashboard/utils/duration-converters", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatMomentDuration1Hour = formatMomentDuration1Hour;
  _exports.formatMomentDuration24Hours = formatMomentDuration24Hours;
  _exports.formatMomentDuration24HoursLocalized = formatMomentDuration24HoursLocalized;
  _exports.goDurationToMomentDuration = goDurationToMomentDuration;
  _exports.goDurationToMomentDurationString = goDurationToMomentDurationString;
  _exports.momentDurationStringToGoDuration = momentDurationStringToGoDuration;
  _exports.momentDurationToGoDuration = momentDurationToGoDuration;
  _exports.utcOffsetToLocalOffset = utcOffsetToLocalOffset;

  function goDurationToMomentDuration(goDuration) {
    let hours, minutes, seconds;

    if (goDuration) {
      const indexOfHours = goDuration.indexOf('h');
      const indexOfMinutes = goDuration.indexOf('m');
      const indexOfSeconds = goDuration.indexOf('s');

      if (indexOfHours !== -1) {
        hours = goDuration.substring(0, indexOfHours);
      }

      if (indexOfMinutes !== -1) {
        minutes = goDuration.substring(indexOfHours + 1, indexOfMinutes);
      }

      if (indexOfSeconds !== -1) {
        seconds = goDuration.substring(indexOfMinutes + 1, indexOfSeconds);
      }
    }

    return _moment.default.duration({
      hours,
      minutes,
      seconds
    });
  }

  function goDurationToMomentDurationString(goDuration) {
    return goDurationToMomentDuration(goDuration).toString();
  }

  function momentDurationToGoDuration(momentDuration) {
    const hours = Math.floor(momentDuration.asHours());
    const minutes = momentDuration.minutes();
    const seconds = momentDuration.seconds();
    return `${hours}h${minutes}m${seconds}s`;
  }

  function momentDurationStringToGoDuration(momentDurationString) {
    return momentDurationToGoDuration(_moment.default.duration(momentDurationString));
  }

  function formatMomentDuration1Hour(duration) {
    return `:${duration.format('mm')}`;
  }

  function formatMomentDuration24Hours(duration) {
    const cloned = duration.clone();
    const period = duration.asHours() >= 12 ? 'PM' : 'AM';

    if (duration.asHours() < 1) {
      cloned.add(12, 'hours');
    } else if (duration.asHours() >= 13) {
      cloned.subtract(12, 'hours');
    }

    return `${cloned.format('HH:mm', {
      trim: false
    })} ${period}`;
  }
  /*
   * We use durations to represent time offsets. In order to convert a duration
   * representing a time offset from UTC to Local time, we must convert the duration
   * to a normal moment object and back.
   */


  function utcOffsetToLocalOffset(offset) {
    const utcOffset = (0, _moment.default)().utc().startOf('day').add(offset.hours(), 'hours').add(offset.minutes(), 'minutes');
    const localOffset = utcOffset.local();
    const hours = localOffset.hours();
    const minutes = localOffset.minutes();
    return _moment.default.duration({
      hours,
      minutes
    });
  }

  function formatMomentDuration24HoursLocalized(duration) {
    const local = utcOffsetToLocalOffset(duration);
    return formatMomentDuration24Hours(local);
  }
});