define("dashboard/components/app-item-favorite-tray/metrics/response-time-chart", ["exports", "dashboard/components/metrics/charts/visualizations/response-time", "dashboard/utils/metrics/latest-non-zero-value", "dashboard/templates/components/app-item-favorite-tray/metrics/favorite-chart", "@ember/object"], function (_exports, _responseTime, _latestNonZeroValue, _favoriteChart, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _responseTime.default.extend({
    layout: _favoriteChart.default,
    classNames: ['metrics__summary-row__item'],
    attributeBindings: ['title'],
    title: 'Your latest 95th percentile response time',
    label: 'resp<span class="hidden-xs">onse</span> time',
    chartTitle: 'Response time over past 24hrs',
    latestValue: (0, _latestNonZeroValue.default)('data.service95Data', false),
    displayLatestValue: (0, _object.computed)('latestValue', function () {
      const latestValue = this.latestValue;
      return latestValue >= 1000 ? Math.floor(latestValue / 1000) : latestValue;
    }),
    displayUnit: (0, _object.computed)('latestValue', function () {
      const latestValue = this.latestValue;
      return latestValue >= 1000 ? 's' : 'ms';
    }),
    timeSeriesP50: (0, _object.computed)(function () {
      const timeSeries = this._super(...arguments);

      timeSeries.set('color', 'purple-002');
      return timeSeries;
    }),
    timeSeriesP95: (0, _object.computed)(function () {
      const timeSeries = this._super(...arguments);

      timeSeries.set('color', 'purple-001');
      return timeSeries;
    }),
    chartTimeSeries: (0, _object.computed)('timeSeriesP50', 'timeSeriesP95', function () {
      return [this.timeSeriesP50, this.timeSeriesP95];
    }),

    click() {
      this.logClick('response-time');
    }

  });

  _exports.default = _default;
});