define("dashboard/components/test-node-overflow-button", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    iconFill: (0, _object.computed)('options.nodeSelectedInDropdown', function () {
      return this.get('options.nodeSelectedInDropdown') ? 'fill-purple ml1 nudge-down--1' : 'fill-gray ml1 nudge-down--1';
    })
  });

  _exports.default = _default;
});