define("dashboard/components/org/addons-allowlist-settings", ["exports", "@ember/object/computed", "@ember/utils", "@ember/service", "@ember/component", "@ember/object", "dashboard/utils/custom-computed", "moment"], function (_exports, _computed, _utils, _service, _component, _object, _customComputed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    classNames: ['addons-allowlist-settings'],
    addonsControls: (0, _computed.alias)('org.preference.addonsControls'),
    isDisabling: false,
    isEnabling: false,
    organization: (0, _computed.alias)('org'),
    allowedAddons: (0, _computed.alias)('organization.allowedAddons'),
    orgAddonsByService: (0, _customComputed.groupBy)('orgAddons', 'addon_service.id'),
    addonServiceExceptions: (0, _customComputed.setComplementBy)('addonServices', 'id', 'allowedAddons', 'addonService.id'),
    allowableAddonServicesList: (0, _customComputed.setComplementBy)('addonServices', 'id', 'allowedAddons', 'addonService.id'),
    allowlistExceptions: (0, _object.computed)('addonServiceExceptions.[]', function () {
      return this.addonServiceExceptions.map(addonService => {
        return this.buildAddonServiceException(addonService);
      }).map(addonService => {
        return this.withApps(addonService);
      }).filter(addonService => {
        return this.hasApps(addonService);
      }).sortBy('apps.length').reverse();
    }),
    hasAllowedAddonsList: (0, _computed.bool)('allowedAddons.length'),
    hasAllowlistExceptions: (0, _computed.bool)('allowlistExceptions.length'),

    buildAddonServiceException(addonService) {
      return _object.default.create({
        apps: [],
        id: addonService.get('id'),
        name: addonService.get('name'),
        humanName: addonService.get('humanName')
      });
    },

    withApps(addonService) {
      const addonsByService = this.orgAddonsByService;
      const addons = addonsByService[addonService.get('id')] || [];
      addonService.set('apps', addons.map(addon => {
        return _object.default.create({
          name: addon.get('app.name'),
          createdAt: (0, _moment.default)(addon.get('createdAt')).format('LL')
        });
      }));
      return addonService;
    },

    hasApps(addonService) {
      return !(0, _utils.isEmpty)(addonService.get('apps'));
    },

    allowedAddonServicesListArray: (0, _object.computed)('allowableAddonServicesList.[]', function () {
      return this.allowableAddonServicesList.toArray();
    }),

    _addAllowlistedAddon(addonService) {
      const allowlistedAddon = this.store.createRecord('team/allowed-addon', {
        team: this.org,
        addonService
      });
      allowlistedAddon.save();
    },

    actions: {
      deleteAllowedAddon(allowlistedAddon) {
        allowlistedAddon.destroyRecord();
      },

      quickAdd(addonService) {
        this._addAllowlistedAddon(addonService);
      },

      addAllowedlistAddonById(addonServiceId) {
        this.store.findRecord('addon-service', addonServiceId).then(addonService => {
          this._addAllowlistedAddon(addonService);
        });
      },

      closeAllowlistingModal() {
        this.set('isDisabling', false);
        this.set('isEnabling', false);
      },

      enableAddonsControls() {
        this.set('org.preference.addonsControls', true);
        this.get('org.preference.content').save().then(() => {
          this.send('closeAllowlistingModal');
        });
      },

      disableAddonsControls() {
        this.set('org.preference.addonsControls', false);
        this.get('org.preference.content').save().then(() => {
          this.send('closeAllowlistingModal');
        });
      },

      clickElementsLink() {
        window.open('https://elements.heroku.com/addons');
      }

    }
  });

  _exports.default = _default;
});