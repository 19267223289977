define("dashboard/models/status/update", ["exports", "@ember/utils", "@ember/object", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _utils, _object, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    resolvedIncident: (0, _relationships.belongsTo)('status/resolved-incident'),
    unresolvedIncident: (0, _relationships.belongsTo)('status/unresolved-incident'),
    incident: (0, _object.computed)('resolvedIncident.content', 'unresolvedIncident.content', function () {
      if ((0, _utils.isPresent)(this.get('resolvedIncident.content'))) {
        return this.get('resolvedIncident.content');
      } else {
        return this.get('unresolvedIncident.content');
      }
    }),
    createdAt: (0, _attr.default)('date'),
    updateType: (0, _attr.default)('string')
  });

  _exports.default = _default;
});