define("dashboard/models/metrics/dyno-errors", ["exports", "@ember/object", "lodash-es/sum", "dashboard/models/metrics/errors-base"], function (_exports, _object, _sum, _errorsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the DynoErrors model
   */
  var _default = _errorsBase.default.extend({
    memoryErrorCount: (0, _object.computed)('rawData', function () {
      const dynoErrorData = this.rawData || {};
      return Object.entries(dynoErrorData).reduce(function (total, _ref) {
        let [key, data] = _ref;
        const memoryCodes = ['R14', 'R15', 'R151'];

        if (memoryCodes.includes(key)) {
          const values = data.mapBy('1');
          total += (0, _sum.default)(values);
        }

        return total;
      }, 0);
    })
  });

  _exports.default = _default;
});