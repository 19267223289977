define("dashboard/adapters/nps-survey", ["exports", "dashboard/adapters/particleboard", "@ember/object"], function (_exports, _particleboard, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _particleboard.default.extend({
    updateMethod: 'PATCH',

    // This is to make updating records PATCH requests vs PUT.
    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      const id = snapshot.id;
      const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord'); // we support a one-off specification of the `updateMethod` via the
      // built-in `adapterOptions` functionality from ember-data
      // If it's not defined, we fallback to the adapter-wide method

      const updateMethod = (0, _object.get)(snapshot, 'adapterOptions.updateMethod') || this.updateMethod;
      return this.ajax(url, updateMethod, {
        data
      });
    },

    urlForCreateRecord() {
      return `${this.host}/nps`;
    },

    urlForUpdateRecord(id) {
      return `${this.host}/nps/${id}`;
    }

  });

  _exports.default = _default;
});