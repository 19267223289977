define("dashboard/templates/protected/rapid-upgrade", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GEgzgsEB",
    "block": "[[[8,[39,0],null,[[\"@name\"],[\"top-nav\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@apps\",\"@addons\",\"@couplings\",\"@isPersonal\",\"@cardPresent\"],[[30,1,[\"apps\"]],[30,1,[\"addons\"]],[30,1,[\"couplings\"]],true,[30,1,[\"paymentMethod\",\"cardPresent\"]]]],null],[1,\"\\n\\n\"]],[\"@model\"],false,[\"send-to-outlet\",\"crumb-trail\",\"efp-rapid-upgrade-table\"]]",
    "moduleName": "dashboard/templates/protected/rapid-upgrade.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});