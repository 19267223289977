define("dashboard/controllers/enterprise-account/overview/settings", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object", "@ember/service"], function (_exports, _controller, _computed, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: {
      isConfiguringSSO: 'sso-setup'
    },
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    isConfiguringSSO: false,
    logSSOactivity: (0, _object.observer)('isConfiguringSSO', function () {
      if (!this.isConfiguringSSO) {
        return;
      }

      this.analytics.logEvent('Enterprise Account SSO Modal', 'Viewed');
    }),
    enterpriseAccount: (0, _computed.alias)('model.enterpriseAccount'),
    hasIdentityProvider: (0, _computed.notEmpty)('enterpriseAccount.identityProvider.content'),
    hasManagePermission: (0, _computed.readOnly)('current.enterprisePermissions.canManageSettings'),
    mfaRequired: (0, _computed.reads)('enterpriseAccount.mfaRequired.mfaRequired'),
    userMfaEnabled: (0, _computed.reads)('current.enterpriseAccountMember.twoFactorAuthentication'),
    showEnableMfaBanner: (0, _computed.not)('userMfaEnabled'),
    mfaEnforcementIsEnabled: (0, _computed.readOnly)('accountFeatures.mfaRequired.enabled'),
    mfaEnforcementIsDisabled: (0, _computed.not)('mfaEnforcementIsEnabled'),
    newName: (0, _computed.reads)('enterpriseAccount.name'),
    saveDetailsButtonIsDisabled: (0, _object.computed)('newName', function () {
      return this.newName === this.enterpriseAccount.name;
    }),

    toggleMfaRequired() {
      return this.enterpriseAccount.mfaRequired.then(mfaRequired => {
        mfaRequired.set('mfaRequired', !mfaRequired.mfaRequired);
        return mfaRequired.save();
      });
    },

    actions: {
      enableMFA() {
        this.toggleMfaRequired();
      },

      transitionToAccount() {
        this.transitionToRoute('account');
      }

    }
  });

  _exports.default = _default;
});