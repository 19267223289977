define("dashboard/components/space-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders the malibu-icon for a space, with some good defaults baked in.
   *
   *     {{space-icon shield=true}}
   *
   * Renders:
   *
   *     <svg>...</svg>
   *
   * @class Dashboard.SpaceIconComponent
   * @extends Ember.Component
   *
   * @param {boolean} shield - set to true to use the shielded variant
   * @param {number} size
   * @param {string} color - a purple color
   * @param {string} iconClass - set to override the default malibu purple
   */
  var _default = _component.default.extend({
    tagName: '',
    shield: false,
    size: 16,
    class: '',
    color: 'purple',
    name: (0, _object.computed)('shield', 'size', function () {
      const shield = this.shield;
      const size = quantize(this.size);
      const parts = ['space', size];

      if (shield) {
        parts.splice(1, 0, 'shielded');
      }

      return parts.join('-');
    }),
    title: (0, _object.computed)('shield', function () {
      if (this.shield) {
        return 'Shield Private Space';
      } else {
        return 'Private Space';
      }
    }),
    iconClass: (0, _object.computed)('color', function () {
      const color = this.color;
      return `malibu-fill-gradient-${color}`;
    })
  });

  _exports.default = _default;

  function quantize(size) {
    size = Number(size);

    if (size <= 16) {
      return 16;
    } else {
      return 28;
    }
  }
});