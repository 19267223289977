define("dashboard/components/github/authorize-button", ["exports", "@ember/service", "jquery", "@ember/component", "dashboard/config/environment", "ember-concurrency", "@ember/object/computed"], function (_exports, _service, _jquery, _component, _environment, _emberConcurrency, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    torii: (0, _service.inject)(),
    errorMonitor: (0, _service.inject)(),
    buttonClass: 'btn btn-primary btn-github',
    buttonText: 'Connect to GitHub',
    disableWhen: false,
    disabledClass: (0, _computed.or)('connect.isRunning', 'disabled'),
    connect: (0, _emberConcurrency.task)(function* (event) {
      if (event) {
        event.preventDefault();
      }

      const torii = this.torii;
      let data;

      try {
        data = yield torii.open('github-oauth2');
      } catch (e) {
        this.errorMonitor.createErrorContainer({
          title: "Couldn't connect to GitHub",
          content: [e]
        });
        throw e;
      }

      yield _jquery.default.ajax({
        withHerokuAuthorization: true,
        url: `${_environment.default.particleboardUrl}/github/auth/authorize?code=${data.authorizationCode}`
      });
      this.authorized();
    }).drop()
  });

  _exports.default = _default;
});