define("dashboard/components/team/member-panel/notices", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    permissionsDocsLink: (0, _object.computed)('panel.team.isEnterpriseOrg', function () {
      if (this.get('panel.team.isEnterpriseOrg')) {
        return 'https://devcenter.heroku.com/articles/enterprise-team-permissions';
      } else {
        return 'https://devcenter.heroku.com/articles/team-permissions';
      }
    })
  });

  _exports.default = _default;
});