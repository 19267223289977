define("dashboard/components/app/settings/config-vars-list", ["exports", "@ember/application", "@ember/component", "@ember/object", "@ember/runloop", "dashboard/mixins/purple-list", "dashboard/models/config-var", "@ember/service"], function (_exports, _application, _component, _object, _runloop, _purpleList, _configVar, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    empty
  } = _object.computed;

  var _default = _component.default.extend(_purpleList.default, {
    analytics: (0, _service.inject)(),
    classNames: ['config-vars-list'],
    configVarsEmpty: empty('configVars'),

    createNewItem() {
      return _configVar.default.create((0, _application.getOwner)(this).ownerInjection(), {
        app: this.app,
        value: ''
      });
    },

    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', this, this._createNew);
    },

    _createNew() {
      this.send('new');
    },

    _refreshBuildpacks(key) {
      if (key === 'BUILDPACK_URL') {
        this.buildpackInstallations.reload();
      }
    },

    titleForDelete: (0, _object.computed)('item.key', function () {
      const name = this.get('item.key');
      return `Delete ${name}`;
    }),
    actions: {
      new() {
        const newConfigVar = this.createNewItem();
        this.set('newConfigVar', newConfigVar);
        this.set('newConfigVar.errorIsVisible', false);
      },

      edit(item) {
        this.set('item', item);
        this.set('currentlyEditingValue', item.get('value'));
        this.send('toggleModal');
      },

      updateExistingConfigVar() {
        const configVar = this.item; // Create configVar to be updated

        const toBeUpdated = _object.default.create({
          app: this.app,
          key: configVar.get('key'),
          value: this.currentlyEditingValue
        });

        return this.app.saveConfigVars(toBeUpdated).then(() => {
          configVar.set('value', this.currentlyEditingValue);
          configVar.set('isMostRecent', true);
          this.set('item', null);
          this.analytics.logEvent('App Config Var', 'Updated');
          this.toggleProperty('isAdding');

          this._refreshBuildpacks(toBeUpdated.get('key'));
        });
      },

      saveNewConfigVar() {
        const newConfigVar = this.newConfigVar;
        return this.app.saveConfigVars(newConfigVar).then(() => {
          const allConfigVars = this.configVars;
          const matchingConfigVar = allConfigVars.findBy('key', newConfigVar.get('key'));

          if (matchingConfigVar) {
            allConfigVars.removeObject(matchingConfigVar);
            allConfigVars.addObject(newConfigVar);
          } else {
            allConfigVars.addObject(newConfigVar);
          }

          this._refreshBuildpacks(newConfigVar.get('key'));

          newConfigVar.set('isMostRecent', true);
          this.analytics.logEvent('App Config Var', 'Created');
          this.send('new');
        });
      },

      delete() {
        const configVar = this.item; // Create configVar object to be deleted

        const toBeDeleted = _object.default.create({
          key: configVar.get('key'),
          value: null
        });

        return this.app.saveConfigVars(toBeDeleted).then(() => {
          this.configVars.removeObject(configVar);
          this.set('item', null);
          this.analytics.logEvent('App Config Var', 'Deleted');

          this._refreshBuildpacks(toBeDeleted.get('key'));

          this.send('toggleDeleteConfirmModal');
        });
      },

      toggleModal() {
        this.set('item.errorIsVisible', false);
        this.toggleProperty('isAdding');
      }

    }
  });

  _exports.default = _default;
});