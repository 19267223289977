define("dashboard/models/recipient", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    email: (0, _model.attr)('string'),
    token: (0, _model.attr)('string'),
    verified: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    refresh: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    template: (0, _model.attr)('string'),
    isActive: (0, _computed.alias)('verified'),

    // attempts a verification of the recipient
    verify() {
      // we hit this special url only during a verify
      const adapterOptions = {
        urlSuffix: '/verify'
      };
      return this.save({
        adapterOptions
      }).then(() => {
        // we infer that the verification was successful
        // and this is the only way to force the model
        // into a non-dirty state with verified=true
        this.store.push({
          data: {
            type: 'recipient',
            id: this.id,
            attributes: {
              verified: true
            }
          }
        });
      });
    },

    refreshToken(_ref) {
      let {
        template
      } = _ref;
      const adapterOptions = {
        updateMethod: 'PATCH'
      };
      this.setProperties({
        refresh: true,
        template
      });
      return this.save({
        adapterOptions
      }).finally(() => {
        this.set('refresh', false);
      });
    }

  });

  _exports.default = _default;
});