define("dashboard/components/metrics/charts/line-chart-elements/graph", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/charts/registered-chart-element", "d3"], function (_exports, _computed, _object, _registeredChartElement, _d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const createDisplayUnit = function (unit) {
    return (0, _object.computed)(unit, 'unit', function () {
      switch (this.get(unit)) {
        case 'memory':
          return 'MB';

        case 'time':
          return 'ms';

        case 'seconds':
          return 's';

        case 'rps':
          return 'rps';

        case 'rpm':
          return 'rpm';

        case 'requests':
          return 'reqs';

        case 'percentage':
          return '%';

        case 'threads':
          return 'threads';

        default:
          return this.unit || '';
      }
    });
  };
  /**
   * a graph that renders a continuous line (area-graph, graph-trend-line, etc.)
   * not intended to be used directly
   *
   */


  var _default = _registeredChartElement.default.extend({
    hasCurrentValue: (0, _computed.notEmpty)('rawDisplayValue'),

    /**
     * shows a unit alongside display value if needed
     */
    displayUnit: createDisplayUnit('unit'),
    secondaryDisplayUnit: createDisplayUnit('secondaryUnit'),
    hasSecondaryValues: _object.computed.notEmpty('secondaryUnit'),
    color: (0, _object.computed)('graphColor', 'defaultColor', function () {
      if (this.graphColor) {
        return this.graphColor;
      } else {
        return this.defaultColor;
      }
    }),

    /**
     * the last datum we have in our data array
     */
    _latestDatum: (0, _computed.readOnly)('data.lastObject'),

    /**
     * add an extra data point which just repeats the last value
     *
     * we do this so that the latest value we actually have data for shows up
     * with a horizontal line. Otherwise, the step-before interpolation will
     * render a vertical line
     *
     */
    _extendedData: (0, _object.computed)('data.[]', '_latestDatum', 'rollupDuration', function () {
      const dupedData = [...(this.data ?? [])];
      const latestDatum = this._latestDatum;

      if (latestDatum) {
        const dupedTime = latestDatum[0] + this.rollupDuration;
        const dupedDatum = [dupedTime, latestDatum[1]];
        dupedData.push(dupedDatum);
      }

      return dupedData || [];
    }),
    _lineData: (0, _computed.reads)('_extendedData'),
    dLine: (0, _object.computed)('_lineData.[]', 'timeScale', 'valueScale', 'xFn', 'yFn', function () {
      const d3Line = (0, _d.line)().x(this.xFn.bind(this)).y(this.yFn.bind(this)).curve(_d.curveStepAfter);
      return d3Line(this._lineData);
    }),
    yAxisScale: (0, _object.computed)('useAlternateScale', 'altValueScale', 'valueScale', function () {
      return this.useAlternateScale ? this.altValueScale : this.valueScale;
    }),

    /**
     * callback the determines the y-value in pixels of the graph
     * at a given datum ([x,y])
     *
     */
    yFn(datum) {
      const shouldNegate = this.negated && this.chartHasOpposingTimeSeries;
      const value = shouldNegate ? -datum[1] : datum[1];
      return Math.round(this.yAxisScale(value));
    }

  });

  _exports.default = _default;
});