define("dashboard/templates/components/permission-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uHVSgHQz",
    "block": "[[[41,[30,0,[\"model\",\"selected\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@name\",\"@iconClass\",\"@title\",\"@size\"],[\"success-mark-16\",\"malibu-fill-gradient-green nudge-up--1\",[30,0,[\"iconTitle\"]],16]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[\"delete-16\",16,\"malibu-fill-gradient-red\",[30,0,[\"iconTitle\"]]]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/permission-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});