define("dashboard/components/display-formation-list", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'display-formation-list',
    classNames: ['display-formation-list', 'hk-hide-bb-last-row']
  });

  _exports.default = _default;
});