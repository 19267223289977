define("dashboard/components/app/datastore/add-topics", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // If compaction is being turned *on*, retention remains enabled
    // only if the mixed content policy is supported. If it's being
    // turned *off*, retention is always turned on.
    compactionRadioValue: (0, _object.computed)('topic.compaction', {
      get() {
        return this.get('topic.compaction');
      },

      set(key, value) {
        if (value) {
          const supportsMixed = this.get('topic.capabilities.supports_mixed_cleanup_policy');
          this.set('topic.retention', supportsMixed);
        } else {
          this.set('topic.retention', true);
        }

        this.set('topic.compaction', value);
        return value;
      }

    }),
    isRetentionRadioDisabled: (0, _object.computed)('topic.sharedCluster', 'topic.compaction', 'topic.retention', function () {
      return this.get('topic.sharedCluster') || !this.get('topic.capabilities.supports_mixed_cleanup_policy') || !this.get('topic.compaction');
    }),
    retentionRadioValue: (0, _object.computed)('topic.retention', {
      get() {
        return this.get('topic.retention');
      },

      set(key, value) {
        if (value) {
          this.set('retentionInputValue', this.get('topic.limits.minimum_retention_ms') / 3600000);
        } else {
          this.set('retentionInputValue', null);
        }

        this.set('topic.retention', value);
        return value;
      }

    }),
    retentionInputValue: (0, _object.computed)('topic.retention_time_hrs', 'topic.retention', {
      get() {
        if (this.get('topic.retention')) {
          return this.get('topic.retention_time_hrs');
        } else {
          return null;
        }
      },

      set(key, value) {
        this.set('topic.retention_time_hrs', value);
        return value;
      }

    }),
    actions: {
      cancel() {
        this.attrs.onCancel();
      }

    }
  });

  _exports.default = _default;
});