define("dashboard/routes/team/space/network", ["exports", "rsvp", "@ember/routing/route"], function (_exports, _rsvp, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      const {
        team
      } = this.modelFor('team');
      const space = this.modelFor('team.space');
      const requests = {
        space,
        peeringInfo: {},
        peerings: [],
        vpnConnections: []
      };

      if (team.currentPermissions.canManageSpaces) {
        // These requests will all 403 if user doesn't have manage space capability.
        requests.peeringInfo = this.store.queryRecord('space/peeringInfo', {
          spaceId: space.get('id')
        }).catch(() => {});
        requests.peerings = this.store.query('space/peering', {
          spaceId: space.get('id')
        }).catch(() => []);
        requests.vpnConnections = space.get('vpnConnections').catch(() => []);
      }

      return (0, _rsvp.hash)(requests);
    }

  });

  _exports.default = _default;
});