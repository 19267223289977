define("dashboard/controllers/pipelines/pipeline/tests/test-run", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['node'],
    node: Infinity,
    testRun: (0, _computed.readOnly)('model.testRun'),
    pipeline: (0, _computed.readOnly)('model.pipeline'),
    testNodes: (0, _computed.readOnly)('testRun.testNodes'),
    githubRepo: (0, _computed.readOnly)('pipeline.githubRepository.repository.name'),
    githubBranch: (0, _computed.readOnly)('testRun.commitBranch'),
    githubCommit: (0, _computed.readOnly)('testRun.commitSha'),
    repoUrl: (0, _object.computed)('githubRepo', function () {
      return `http://github.com/${this.githubRepo}`;
    }),
    branchUrl: (0, _object.computed)('repoUrl', 'githubBranch', function () {
      return `${this.repoUrl}/tree/${this.githubBranch}`;
    }),
    commitUrl: (0, _object.computed)('repoUrl', 'githubCommit', function () {
      return `${this.repoUrl}/blob/${this.githubCommit}/`;
    }),
    dynoIcon: (0, _object.computed)('testRun.dynoSize', function () {
      const dynoName = this.get('testRun.dynoSize');

      if (dynoName === 'standard-1x') {
        return 'dyno-1x';
      } else if (dynoName === 'standard-2x') {
        return 'dyno-2x';
      } else if (dynoName === 'performance-l') {
        return 'dyno-pl';
      } else {
        return 'dyno-pm';
      }
    })
  });

  _exports.default = _default;
});