define("dashboard/components/purple-list-action-cell", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'td',
    classNames: 'action-cell',
    showEditOrRemove: (0, _computed.or)('canEdit', 'canRemove'),
    actions: {
      edit(model) {
        this.editAction(model);
      },

      delete(model) {
        this.confirmDeleteAction(model);
      }

    }
  });

  _exports.default = _default;
});