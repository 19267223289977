define("dashboard/adapters/metrics/monitor", ["exports", "dashboard/adapters/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.metricsApiUrl,

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('appId');
      const type = snapshot.record.get('processType');
      return `${this.host}/apps/${appId}/formation/${type}/monitors`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      return `${this.urlForCreateRecord(modelName, snapshot)}/${id}`;
    },

    findRecord() {
      return null;
    },

    updateRecord(store, type, snapshot) {
      const data = {};
      const serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      const url = this.urlForUpdateRecord(snapshot.id, type.modelName, snapshot);
      return this.ajax(url, 'PATCH', {
        data,
        dataType: 'text'
      });
    }

  });

  _exports.default = _default;
});