define("dashboard/serializers/log-session", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      createdAt: {
        serialize: false
      },
      logplexUrl: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      }
    },

    serialize(snapshot, options) {
      const serialized = this._super(snapshot, options);

      if (snapshot.record.get('dyno')) {
        serialized['dyno'] = snapshot.record.get('dyno');
      }

      if (snapshot.record.get('lines')) {
        serialized['lines'] = snapshot.record.get('lines');
      }

      if (snapshot.record.get('source')) {
        serialized['source'] = snapshot.record.get('source');
      }

      if (snapshot.record.get('tail')) {
        serialized['tail'] = true;
      }

      return serialized;
    }

  });

  _exports.default = _default;
});