define("dashboard/templates/components/confirm-sms-number", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uOaedtU+",
    "block": "[[[11,\"form\"],[24,1,\"confirm-sms\"],[4,[38,0],[[30,0],[28,[37,1],[[30,0,[\"confirmCode\"]]],null]],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-body text-center\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Confirm your phone number (\"],[1,[30,0,[\"smsNumber\"]]],[1,\")\"],[13],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      You should receive an SMS shortly with a confirmation code. Enter the code below to complete setup. Didn't receive this code?\\n      \"],[10,\"button\"],[15,\"onclick\",[28,[37,1],[[30,0,[\"resendConfirmation\"]]],null]],[15,\"disabled\",[28,[37,2],[[30,0,[\"confirmCode\",\"isRunning\"]],[30,0,[\"resendConfirmation\",\"isRunning\"]]],null]],[14,0,\"btn-link resend-link\"],[14,4,\"button\"],[12],[1,\"\\n        Resend\\n      \"],[13],[1,\".\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"sms-confirmation-code\"],[12],[1,\"Enter 6-digit SMS Verification Code\"],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"form-control center-block\"],[24,1,\"sms-confirmation-code\"]],[[\"@value\"],[[30,0,[\"confirmationCode\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"error\"]],[[[1,\"      \"],[10,2],[14,0,\"red\"],[12],[1,[30,0,[\"error\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n\"],[1,\"  \"],[18,1,null],[1,\"\\n\\n  \"],[1,[28,[35,6],null,[[\"form\",\"onClick\",\"default\"],[\"confirm-sms\",[28,[37,0],[[30,0],[28,[37,1],[[30,0,[\"confirmCode\"]]],null]],null],\"Confirm Phone Number\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"perform\",\"or\",\"input\",\"if\",\"yield\",\"hk-button\"]]",
    "moduleName": "dashboard/templates/components/confirm-sms-number.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});