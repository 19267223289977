define("dashboard/templates/components/lazy-loading-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FGNK8lZ5",
    "block": "[[[41,[30,0,[\"pending\"]],[[[1,\"  \"],[10,0],[14,0,\"lazy-loading-spinner h4 flex items-center justify-center\"],[12],[1,\"\\n    \"],[1,[34,1]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"loading-spinner\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/lazy-loading-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});