define("dashboard/models/pipeline", ["exports", "rsvp", "@ember/object", "@ember/array", "@ember/utils", "@ember-data/model", "@ember/object/computed", "@ember/service", "dashboard/utils/github-page-fetcher", "dashboard/utils/debounce-promise", "dashboard/mixins/web-socket-model"], function (_exports, _rsvp, _object, _array, _utils, _model, _computed, _service, _githubPageFetcher, _debouncePromise, _webSocketModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_webSocketModel.default, {
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),
    socketIdentifier: (0, _object.computed)('id', function () {
      return `pipelines/${this.id}`;
    }),
    name: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    owner: (0, _model.attr)(),
    collaboratorsEnabled: (0, _model.attr)('boolean'),
    collaboratorSync: (0, _model.attr)('boolean'),
    collaboratorPermissions: (0, _model.attr)(),
    ownerID: (0, _computed.alias)('owner.id'),
    hasOwnerID: (0, _computed.notEmpty)('ownerID'),
    ownerType: (0, _computed.alias)('owner.type'),
    hasOwnerType: (0, _computed.notEmpty)('ownerType'),
    ownerUserType: (0, _computed.equal)('ownerType', 'user'),
    ownerTeamType: (0, _computed.equal)('ownerType', 'team'),
    isUserPipeline: (0, _computed.and)('ownerUserType', 'hasOwner'),
    isTeamPipeline: (0, _computed.and)('ownerTeamType', 'hasOwner'),
    hasOwner: (0, _computed.and)('hasOwnerID', 'hasOwnerType'),
    doesNotHaveOwner: (0, _computed.not)('hasOwner'),
    isUserOwner: (0, _object.computed)('ownerID', 'current.account.id', function () {
      return this.get('ownerID') === this.get('current.account.id');
    }),
    isTeamAdmin: (0, _computed.equal)('team.role', 'admin'),
    isTeamMember: (0, _computed.equal)('team.role', 'member'),
    collaborators: (0, _model.hasMany)('pipeline/collaborator'),
    latestDeployments: (0, _model.hasMany)('release'),
    latestReleases: (0, _model.hasMany)('release'),
    latestBuilds: (0, _model.hasMany)('build'),
    testRuns: (0, _model.hasMany)('test-run'),
    pipelineCouplings: (0, _model.hasMany)('pipeline-coupling'),
    githubAppLinks: (0, _model.hasMany)('github-app-link'),
    githubRepository: (0, _model.belongsTo)('pipeline/github-repository'),
    connectedRepository: (0, _model.belongsTo)('repositories-api-github-repo'),
    connectedPullRequests: (0, _model.hasMany)('repositories-api-pull-request'),
    connectedBranches: (0, _model.hasMany)('repositories-api-github-branch'),
    connectedServices: (0, _model.belongsTo)('pipeline-services-config'),
    team: (0, _model.belongsTo)('team'),
    reviewAppConfig: (0, _model.belongsTo)('review-app-config'),
    unsortedReviewApps: (0, _model.hasMany)('review-app'),
    reviewAppSorting: ['updatedAt:desc'],
    reviewApps: (0, _computed.sort)('unsortedReviewApps', 'reviewAppSorting'),
    configVars: (0, _model.hasMany)('pipeline-stage-config-var'),
    testConfigVars: (0, _object.computed)('configVars.[]', function () {
      const configVars = this.configVars?.isDestroying ? [] : this.configVars;
      return configVars.filterBy('stage', 'test');
    }),
    reviewConfigVars: (0, _object.computed)('configVars.[]', function () {
      const configVars = this.configVars?.isDestroying ? [] : this.configVars;
      return configVars.filterBy('stage', 'review');
    }),
    canCreateReviewApps: (0, _computed.or)('isUserOwner', 'isTeamAdmin', 'isTeamMember'),
    canDeleteReviewApps: (0, _computed.or)('isUserOwner', 'isTeamAdmin', 'isTeamMember'),
    permissionNames: ['view', 'deploy', 'operate', 'manage'],
    repo: (0, _computed.readOnly)('githubAppLinks.firstObject.repo'),
    isGithubAppEnabled: (0, _computed.bool)('team.isGithubAppEnabled'),
    repositoryName: (0, _object.computed)('githubRepository.repository.name', 'connectedRepository.name', function () {
      if (this.isGithubAppEnabled) {
        return this.get('connectedRepository.name');
      } else {
        return this.get('githubRepository.repository.name');
      }
    }),
    repositoryFullName: (0, _object.computed)('githubRepository.repository.fullName', 'connectedRepository.fullName', function () {
      if (this.isGithubAppEnabled) {
        return this.get('connectedRepository.fullName');
      } else {
        return this.get('githubRepository.repository.name');
      }
    }),
    repositoryId: (0, _object.computed)('githubRepository.repository.id', 'connectedRepository.id', function () {
      if (this.isGithubAppEnabled) {
        return this.get('connectedRepository.id');
      } else {
        return this.get('githubRepository.repository.id');
      }
    }),
    repositoryUrl: (0, _object.computed)('githubRepository.webUrl', 'connectedRepository.htmlUrl', function () {
      if (this.isGithubAppEnabled) {
        return this.get('connectedRepository.htmlUrl');
      } else {
        return this.get('githubRepository.webUrl');
      }
    }),
    isConnectedToRepository: (0, _object.computed)('githubRepository.content', 'connectedRepository.content', 'isGithubAppEnabled', function () {
      if (this.isGithubAppEnabled) {
        return (0, _utils.isPresent)(this.get('connectedRepository.content'));
      } else {
        return (0, _utils.isPresent)(this.get('githubRepository.content'));
      }
    }),
    githubAppInstallation: (0, _object.computed)('team.isEnterpriseTeam', 'team.githubAppInstallation', 'team.enterprise.githubAppInstallation', 'isGithubAppEnabled', function () {
      if (this.isGithubAppEnabled) {
        if (this.isTeamPipeline) {
          return this.get('team.isEnterpriseTeam') ? this.get('team.enterprise.githubAppInstallation') : this.get('team.githubAppInstallation');
        } else if (this.isUserPipeline) {
          return this.get('current.account.githubAppInstallation');
        } else {
          // If the pipeline is not owned by a user or team, then it hasn't been
          // assigned ownership. These legacy pipelines are not supported by
          // GitHub App Tokens.
          return null;
        }
      }
    }),
    hasGithubAppInstalled: (0, _computed.notEmpty)('githubAppInstallation.id'),
    hasNewReviewAppsEnabled: (0, _computed.notEmpty)('reviewAppConfig.id'),
    hasKolkrabbiReviewAppsEnabled: (0, _computed.readOnly)('hasReviewAppParent'),
    hasReviewAppsEnabled: (0, _computed.or)('hasKolkrabbiReviewAppsEnabled', 'hasNewReviewAppsEnabled'),
    hasReviewAppsDisabled: (0, _computed.not)('hasReviewAppsEnabled'),
    isFetchingPullRequests: (0, _computed.readOnly)('pullRequests.isFetching'),
    hasMorePullRequests: (0, _computed.readOnly)('pullRequests.hasMore'),
    reviewCouplings: (0, _object.computed)('pipelineCouplings.[]', function () {
      const couplings = this.pipelineCouplings?.isDestroying ? [] : this.pipelineCouplings;
      return couplings.filterBy('isReview');
    }),
    nonReviewCouplings: (0, _computed.filterBy)('pipelineCouplings', 'isNotReview'),
    developmentCouplings: (0, _computed.filterBy)('pipelineCouplings', 'isDevelopment'),
    stagingCouplings: (0, _computed.filterBy)('pipelineCouplings', 'isStaging'),
    productionCouplings: (0, _computed.filterBy)('pipelineCouplings', 'isProduction'),
    durableCouplings: (0, _computed.filterBy)('pipelineCouplings', 'isDurable'),
    hasCouplings: (0, _computed.notEmpty)('pipelineCouplings'),
    apps: (0, _computed.mapBy)('pipelineCouplings', 'app'),
    reviewCouplingApps: (0, _computed.mapBy)('reviewCouplings', 'app'),
    nonReviewCouplingApps: (0, _computed.mapBy)('nonReviewCouplings', 'app'),
    developmentCouplingApps: (0, _computed.mapBy)('developmentCouplings', 'app'),
    stagingCouplingApps: (0, _computed.mapBy)('stagingCouplings', 'app'),
    productionCouplingApps: (0, _computed.mapBy)('productionCouplings', 'app'),
    durableApps: (0, _computed.mapBy)('durableCouplings', 'app'),
    reviewAppParents: (0, _object.computed)('githubAppLinks.[]', function () {
      const reviewAppParents = this.githubAppLinks?.isDestroying ? [] : this.githubAppLinks;
      return reviewAppParents.filterBy('isParentLink');
    }),
    reviewAppParent: (0, _computed.alias)('reviewAppParents.firstObject'),
    hasReviewAppParent: (0, _computed.bool)('reviewAppParent'),
    isOwned: (0, _computed.bool)('owner'),
    hasFavoriteApps: (0, _object.computed)('apps.@each.isFavorite', function () {
      return this.apps.isAny('isFavorite');
    }),
    doesNotHaveFavoriteApps: (0, _computed.not)('hasFavoriteApps'),
    isFavorite: (0, _computed.bool)('favorite'),
    isNotFavorite: (0, _computed.not)('isFavorite'),
    isPipeline: true,
    pullRequests: (0, _object.computed)(function () {
      return [];
    }),
    openPullRequests: (0, _computed.filterBy)('pullRequests', 'isOpen', true),
    kolkrabbiReviewApps: (0, _object.computed)(function () {
      return [];
    }),
    nameIsEmpty: (0, _computed.empty)('name'),
    // TODO convert this to use ember-cp-validations
    validationMessage: (0, _object.computed)('name', function () {
      // JSON Schema v3: /^[a-z][a-z0-9-]{3,30}$/
      const name = this.name;
      const nameIsEmpty = this.nameIsEmpty;
      const nameIsNotEmpty = !nameIsEmpty;
      let validationMessage; // invalid format cases

      const nameStartsWithAlpha = /^[a-z]/.test(name);
      const nameIsAlphaNumeric = /^[a-z0-9-]*$/.test(name); // set the validation message as appropriate

      if (name.length < 3) {
        validationMessage = 'is too short (minimum is 3 characters).';
      } else if (name.length > 30) {
        validationMessage = 'is too long (maximum is 30 characters).';
      } else if (!nameStartsWithAlpha) {
        validationMessage = 'must start with a lowercase letter.';
      } else if (!nameIsAlphaNumeric) {
        validationMessage = 'should only contain lowercase letters, numbers, and dashes.';
      }

      if (nameIsNotEmpty && validationMessage) {
        return validationMessage;
      } else {
        return null;
      }
    }),
    nameIsValid: (0, _computed.not)('nameIsInvalid'),
    nameIsInvalid: (0, _computed.notEmpty)('validationMessage'),

    hasCollaboratorPermission(name) {
      return this.collaboratorPermissions.includes(name);
    },

    collaboratorPermissionsByName: (0, _object.computed)('collaboratorPermissions.[]', 'permissionNames', function () {
      return this.permissionNames.reduce((memo, permission) => {
        memo[permission] = this.hasCollaboratorPermission(permission);
        return memo;
      }, {});
    }),

    saveGithubRepository(repository, isGithubAppEnabled) {
      if (isGithubAppEnabled) {
        if (!repository) {
          return (0, _rsvp.resolve)();
        }

        return repository.save({
          adapterOptions: {
            linkedResourceType: 'pipeline'
          }
        }).then(() => {
          this.set('connectedRepository', repository);
        }).catch(e => {
          this.pipeline.set('connectedRepository', null);
          this.get('reporter.rollbar').info('Failed to connect pipeline', {
            error: e
          });
        });
      } else {
        if (!repository) {
          return (0, _rsvp.resolve)();
        }

        const githubRepository = this.store.createRecord('pipeline/githubRepository', {
          repository: parseInt(repository.id),
          pipeline: this
        });
        this.set('githubRepository', githubRepository);

        if (githubRepository.get('hasDirtyAttributes') || githubRepository.get('isNew')) {
          return githubRepository.save();
        } else {
          return (0, _rsvp.resolve)(githubRepository);
        }
      }
    },

    // TODO: don't do this
    addCoupling(coupling) {
      this.trigger('didAddCoupling', this, coupling);
    },

    // With repos api flow, we use the connectedPullRequests relationship
    // With our old github flow, we manually fetch the PRs
    // Both results are returned and set on the `pullRequests` property
    // Setting the `pullRequests` property is just the simplest interface for
    // supporting both our old & new github flow
    // Once we are exclusively using repos api, we should stop setting `pullRequests`,
    // and any spots relying on `pipeline.pullRequests` should be updated to
    // `pipeline.connectedPullRequests`
    fetchPullRequests: (0, _debouncePromise.default)(function () {
      let repo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('reviewAppParent.repo');

      if (this.isGithubAppEnabled) {
        return this.get('connectedPullRequests').then(prs => {
          if ((0, _array.isArray)(prs)) {
            this.set('pullRequests', prs);
          }
        });
      } else {
        const paginatedPRs = _githubPageFetcher.default.create({
          store: this.store,
          repo
        });

        const fetch = paginatedPRs.fetchPage();
        return fetch.then(prs => {
          if ((0, _array.isArray)(prs)) {
            this.set('pullRequests', prs);
          }
        });
      }
    }),
    fetchGithubReviewApps: (0, _debouncePromise.default)(function () {
      return this.reviewAppParent.hasMany('kolkrabbiApps').reload().then(reviewCouplingApps => {
        return this.mergeReviewApps(reviewCouplingApps);
      }, () => {});
    }),

    mergeReviewApps(reviewCouplingApps) {
      const reviewApps = this.kolkrabbiReviewApps.toArray();
      reviewCouplingApps.forEach(reviewApp => {
        const existingReviewApp = reviewApps.findBy('pullRequest.id', reviewApp.get('pullRequest.id'));

        if ((0, _utils.isPresent)(existingReviewApp)) {
          reviewApps.removeObject(existingReviewApp);
        }

        reviewApps.pushObject(reviewApp);
      });
      this.set('kolkrabbiReviewApps', reviewApps);
      return reviewApps;
    },

    deleteGithubReviewApp(githubReviewApp) {
      const appId = githubReviewApp.get('app.id');
      return githubReviewApp.destroyRecord().then(() => {
        this.kolkrabbiReviewApps.removeObject(githubReviewApp);
        const coupling = this.pipelineCouplings.findBy('app.id', appId);

        if (coupling) {
          coupling.unloadRecord();
        }

        return githubReviewApp;
      });
    },

    isCiEnabled: (0, _object.computed)('githubRepository.ci', 'connectedServices.ci', function () {
      if (this.isGithubAppEnabled) {
        return this.get('connectedServices.ci');
      } else {
        return this.get('githubRepository.ci');
      }
    }),

    // due to how pipeline couplings are manually setup on the app model,
    // when we delete the pipeline we need to manually remove the couplings from
    // the app so that the app stops thinking it is in a pipeline
    deletePipeline() {
      const apps = this.apps;
      return this.destroyRecord().then(() => {
        apps.forEach(app => {
          app.set('pipelineCoupling', null);
        });
      });
    }

  });

  _exports.default = _default;
});