define("dashboard/components/generic-banner", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    notEmpty
  } = _object.computed;

  var _default = _component.default.extend({
    'data-test-target': 'generic-banner',
    classNames: ['generic-banner', 'relative', 'flex', 'items-center', 'bg-white', 'shadow-outer-3', 'bb', 'b--silver', 'pv2', 'ph4', 'mb4', 'z-2', 'clearfix'],
    iconName: 'info-badge-16',
    iconClass: 'malibu-fill-gradient-dark-gray',
    iconSize: '16',
    iconTitle: '',
    devCenterLink: '',
    // placeholder
    hasDevCenterLink: notEmpty('devCenterLink'),
    hasIconTitle: notEmpty('iconTitle'),
    messageBannerText: (0, _object.computed)(function () {
      throw new Error('messageBannerText must be passed into {{generic-banner ...}}');
    }),
    hasPrimaryButton: notEmpty('didConfirmBanner'),
    hasDismissButton: notEmpty('didDismissBanner'),
    confirmText: 'Confirm',
    dismissText: 'Dismiss'
  });

  _exports.default = _default;
});