define("dashboard/components/account/billing/declined-invoices-form", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    selectedInvoices: (0, _computed.filterBy)('invoices', 'isSelected', true),
    selectedTotals: (0, _computed.mapBy)('selectedInvoices', 'total'),
    selectedTotal: (0, _computed.sum)('selectedTotals'),
    invoicesWithFailedAttempt: (0, _computed.filterBy)('invoices', 'attemptedPaymentFailed', true),
    actions: {
      closeVerifyModal() {
        this.set('showVerify', false);
      },

      verifyAccount() {
        this.set('showVerify', true);
      }

    }
  });

  _exports.default = _default;
});