define("dashboard/components/datastore/kafka/overview", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "ember-concurrency", "ember-inflector", "dashboard/config/environment", "dashboard/utils/metrics/datastore-types", "dashboard/models/metrics/datastores/kafka"], function (_exports, _component, _service, _object, _computed, _emberConcurrency, _emberInflector, _environment, _datastoreTypes, _kafka) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const datastoreMetricsPollDuration = _environment.default.datastoreMetricsPollDuration;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    datastoreHealthStatus: (0, _object.computed)('datastore.state.status', 'datastore.state.healthy?', function () {
      return this.get('datastore.state.status') || (this.datastore.get('state.healthy?') ? 'green' : 'red');
    }),
    // lol 'health class'
    datastoreHealthClass: (0, _object.computed)('datastoreHealthStatus', function () {
      const healthClassMap = {
        green: 'malibu-fill-gradient-green',
        yellow: 'malibu-fill-gradient-orange',
        red: 'malibu-fill-gradient-red'
      };
      return healthClassMap[this.datastoreHealthStatus];
    }),
    datastoreHealthIcon: (0, _object.computed)('datastoreHealthStatus', function () {
      const healthIconMap = {
        green: 'success-badge-16',
        yellow: 'warning-badge-16',
        red: 'error-badge-16'
      };
      return healthIconMap[this.datastoreHealthStatus];
    }),
    datastoreDegradedWarning: (0, _object.computed)('datastore.state.healthy?', 'datastore.state.degraded_brokers', 'datastore.state.degraded_topics', function () {
      const degradedBrokers = this.get('datastore.state.degraded_brokers.length');
      const degradedTopics = this.get('datastore.state.degraded_topics.length');
      const isOrAre = degradedBrokers > 1 ? 'are' : 'is';

      if (!this.datastore.get('state.healthy?') || degradedBrokers || degradedTopics) {
        return `There ${isOrAre} ${degradedBrokers} recovering ${(0, _emberInflector.pluralize)(degradedBrokers, 'broker', {
          withoutCount: true
        })} and ${degradedTopics} recovering ${(0, _emberInflector.pluralize)(degradedTopics, 'topic', {
          withoutCount: true
        })}.`;
      } else {
        return null;
      }
    }),
    pollMetrics: (0, _emberConcurrency.task)(function* () {
      while (true) {
        this.fetchDatastoreMetricsTask.perform(_datastoreTypes.default.kafka);

        if (!datastoreMetricsPollDuration) {
          return;
        }

        yield (0, _emberConcurrency.timeout)(datastoreMetricsPollDuration);
      }
    }).drop().on('didReceiveAttrs'),
    topics: (0, _computed.readOnly)('datastore.topicDetails.topics'),
    topicSaveSuccessful: null,
    updatingTopic: (0, _object.computed)('topics.[]', 'topic', function () {
      if (this.isAdding) {
        return null;
      } else {
        return this.topics.objectAt(this.index - 1);
      }
    }),
    currentTopic: (0, _object.computed)('topics.[]', 'index', function () {
      if (this.index > 0) {
        return this.topics.objectAt(this.index - 1);
      } else {
        return null;
      }
    }),
    currentTopicName: (0, _object.computed)('currentTopic', function () {
      const topicName = this.currentTopic.name.replace(/\./g, '_');
      return `${topicName}_max`;
    }),
    // TODO: DRY these up
    currentTopicDataSize: (0, _object.computed)('currentTopicName', 'datastoreMetrics.dynamicTopicData', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return '';
      }

      const topicKey = `${_kafka.PER_TOPIC_DATA_SIZE_PREFIX}_${this.currentTopicName}`;
      return `dynamicTopicData.${topicKey}`;
    }),
    currentTopicMessages: (0, _object.computed)('currentTopicName', 'datastoreMetrics.dynamicTopicData', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return '';
      }

      const topicKey = `${_kafka.PER_TOPIC_MESSAGE_PREFIX}_${this.currentTopicName}`;
      return `dynamicTopicData.${topicKey}`;
    }),
    currentTopicBytesIn: (0, _object.computed)('currentTopicName', 'datastoreMetrics.dynamicTopicData', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return '';
      }

      const topicKey = `${_kafka.PER_TOPIC_BYTES_IN_PREFIX}_${this.currentTopicName}`;
      return `dynamicTopicData.${topicKey}`;
    }),
    currentTopicBytesOut: (0, _object.computed)('currentTopicName', 'datastoreMetrics.dynamicTopicData', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return '';
      }

      const topicKey = `${_kafka.PER_TOPIC_BYTES_OUT_PREFIX}_${this.currentTopicName}`;
      return `dynamicTopicData.${topicKey}`;
    }),
    // TODO: Also DRY these up?
    dataSize: (0, _object.computed)('index', 'datastoreMetrics.ioDataSizeBytes', 'currentTopicDataSize', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.index > 0 ? this.datastoreMetrics.get(this.currentTopicDataSize) : this.datastoreMetrics.get('ioDataSizeBytes');
    }),
    messages: (0, _object.computed)('index', 'datastoreMetrics.ioMessages', 'currentTopicMessages', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.index > 0 ? this.datastoreMetrics.get(this.currentTopicMessages) : this.datastoreMetrics.get('ioMessages');
    }),
    bytesIn: (0, _object.computed)('index', 'datastoreMetrics.ioBytesIn', 'currentTopicBytesIn', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.index > 0 ? this.datastoreMetrics.get(this.currentTopicBytesIn) : this.datastoreMetrics.get('ioBytesInBytes');
    }),
    bytesOut: (0, _object.computed)('index', 'datastoreMetrics.ioBytesOut', 'currentTopicBytesOut', 'fetchDatastoreMetricsTask.isRunning', function () {
      if (this.fetchDatastoreMetricsTask?.isRunning) {
        return [];
      }

      return this.index > 0 ? this.datastoreMetrics.get(this.currentTopicBytesOut) : this.datastoreMetrics.get('ioBytesOutBytes');
    }),
    indexObserver: (0, _object.observer)('index', function () {
      this.get('fetchDatastoreMetricsTask').perform(_datastoreTypes.default.kafka);
    }),
    actions: {
      updateTimeframe(beforeHours, afterHours) {
        this.analytics.logEvent('Kafka Chart Resolution', 'Changed', {
          beforeHours,
          afterHours
        });
        this.get('updateTimeframe')(beforeHours, afterHours, _datastoreTypes.default.kafka);
      },

      openAddTopic() {
        this.analytics.logEvent('Kafka Add Topic', 'Clicked');
        this.get('openAddTopic')();
      },

      async createTopic(newTopic, dismiss) {
        this.analytics.logEvent('Kafka Create Topic', 'Clicked');
        await this.datastore.createTopic(newTopic);
        dismiss();
        this.set('topicSaveSuccessful', 'create');
      },

      async updateTopic(topic, dismiss) {
        this.analytics.logEvent('Kafka Update Topic', 'Clicked');
        await this.datastore.updateTopic(topic);
        dismiss();
        this.set('topicSaveSuccessful', 'update');
      },

      async destroyTopic(topic, dismiss) {
        this.analytics.logEvent('Kafka Destroy Topic', 'Clicked');
        await this.datastore.destroyTopic(topic);
        dismiss();
        this.get('setIndex')(0);
      }

    }
  });

  _exports.default = _default;
});