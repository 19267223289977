define("dashboard/components/pipeline/auto-deploy-modal", ["exports", "@ember/service", "@ember/component"], function (_exports, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // No wrapper tag
    tagName: '',
    githubToken: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    safeSet(key, value) {
      if (this.isDestroyed) {
        return;
      }

      return this.set(key, value);
    },

    actions: {
      hideAutoDeployModal() {
        this.hideAutoDeployModal();
      },

      toggleAutoDeploy(callback) {
        const appLink = this.githubAppLink;
        appLink.toggleProperty('autoDeploy');
        const promise = this.get('githubAppLink.content').save();

        if (appLink.get('autoDeploy') && this.onChangedAutoDeploy) {
          this.onChangedAutoDeploy(appLink);
        }

        if (typeof callback === 'function') {
          callback(promise);
        }
      },

      authorizedGitHub() {
        this.authorizedGitHub();
      }

    }
  });

  _exports.default = _default;
});