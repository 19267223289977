define("dashboard/routes/team/switchable/spaces/index", ["exports", "rsvp", "@ember/service", "@ember/object", "dashboard/routes/team/base"], function (_exports, _rsvp, _service, _object, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    analytics: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),

    beforeModel() {
      // kick out orgs that don't have access to this feature
      if (!this.modelFor('team').team.get('canManageSpaces')) {
        this.transitionTo('/404');
      }
    },

    afterModel(model) {
      const analyticsNoun = model.team.analyticsNoun;
      this.analytics.logEvent(`${analyticsNoun} Spaces List Page`, 'Viewed');

      this._super(model);
    },

    model() {
      const {
        team
      } = this.modelFor('team');
      return (0, _rsvp.hash)({
        team,
        spaces: team.get('spaces')
      });
    },

    renderTemplate(controller, model) {
      this.set('breadcrumbs.trail', {
        team: (0, _object.get)(model, 'team'),
        enterpriseAccount: (0, _object.get)(model, 'team.enterprise')
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav',
        into: 'protected'
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});