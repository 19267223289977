define("dashboard/adapters/account", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/adapters/application", "dashboard/mixins/password-encoding"], function (_exports, _object, _computed, _service, _application, _passwordEncoding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_passwordEncoding.default, {
    version: '3.dashboard',
    current: (0, _service.inject)(),
    dsErrorMonitor: (0, _service.inject)(),
    password: (0, _computed.alias)('current.account.password'),
    twoFactorCode: (0, _computed.alias)('current.account.twoFactorCode'),
    headers: (0, _object.computed)('password', 'twoFactorCode', function () {
      const headers = this._super();

      if (this.get('twoFactorCode')) {
        headers['Heroku-Two-Factor-Code'] = this.get('twoFactorCode');
        this.set('twoFactorCode', null);
      } else {
        delete headers['Heroku-Two-Factor-Code'];
      }

      return headers;
    }),

    buildURL() {
      return `${this.get('host')}/account`;
    },

    _isPasswordLeakedError(status, payload) {
      return status === 422 && payload && payload.id === 'password_leaked';
    },

    handleResponse(status, headers, payload, requestData) {
      if (status === 403) {
        // tl;dr when errorTitleSubject is set it is used
        //
        // "errorTitleSubject" is only used when a status is invalid
        // and 403 is not normally considered invalid -- UNTIL NOW
        requestData.jqXHR.errorTitleSubject = 'Account';
      } // doing this here to suppress red error bubbles so we can render the
      // message next to the password fields if the reason for the error
      // is a password leak.
      // Otherwise, show a red bubble


      if (this._isPasswordLeakedError(status, payload)) {
        requestData.jqXHR.errorIsHandled = true;
      }

      return this._super(...arguments);
    },

    // unfortunately, we have to overwrite this to prevent the PlatformAPIMixin from
    // reporting the XHR the error reporter service :(
    isInvalid(status, _headers, payload) {
      if (this._isPasswordLeakedError(status, payload)) {
        return false;
      }

      return this._super(...arguments);
    },

    updateRecord(store, type, snapshot) {
      return this._super(...arguments).catch(error => {
        const didLeakPassword = error.errors && error.errors.any(_ref => {
          let {
            id
          } = _ref;
          return id === 'password_leaked';
        });

        if (didLeakPassword) {
          // hack: set didLeakPassword on the account model so the red error bubble won't show.
          snapshot.record.set('didLeakPassword');
        }

        throw error;
      }).finally(() => {
        snapshot.record.set('password', null);
      });
    },

    deleteRecord(store, type, snapshot) {
      // We pass `Heroku-Password-Base64` as a header here because DELETE /account does
      // NOT accept body params
      const id = snapshot.id;
      const password = this.decodePassword(snapshot.record.get('password'));
      const headers = {
        'Heroku-Password-Base64': btoa(password)
      };
      return this.ajax(this.buildURL(type.modelName, id, snapshot, 'deleteRecord'), 'DELETE', {
        headers
      });
    }

  });

  _exports.default = _default;
});