define("dashboard/utils/open-telemetry", ["@opentelemetry/exporter-trace-otlp-http", "@opentelemetry/sdk-trace-web", "@opentelemetry/resources", "@opentelemetry/semantic-conventions", "@opentelemetry/instrumentation", "@opentelemetry/auto-instrumentations-web", "dashboard/config/environment", "dashboard/utils/localstorage"], function (_exporterTraceOtlpHttp, _sdkTraceWeb, _resources, _semanticConventions, _instrumentation, _autoInstrumentationsWeb, _environment, _localstorage) {
  "use strict";

  function getAuthToken() {
    const authData = _localstorage.default.getItem('ember_simple_auth-session');

    try {
      const authDataObj = JSON.parse(authData);
      return authDataObj.authenticated.access_token;
    } catch (error) {
      return '';
    }
  }

  function initializeOtel() {
    // The exporter is responsible for sending traces from the browser to the collector
    const exporter = new _exporterTraceOtlpHttp.OTLPTraceExporter({
      url: _environment.default.OTEL_EXPORTER_OTLP_ENDPOINT,
      headers: {
        Authorization: `Bearer ${getAuthToken()}`
      }
    }); // The TracerProvider is the core library for creating traces

    const provider = new _sdkTraceWeb.WebTracerProvider({
      resource: new _resources.Resource({
        [_semanticConventions.SemanticResourceAttributes.SERVICE_NAME]: 'dashboard'
      })
    }); // The processor sorts through data as it comes in, before it is sent to the exporter

    provider.addSpanProcessor(new _sdkTraceWeb.BatchSpanProcessor(exporter)); // A context manager allows OTel to keep the context of function calls across async functions
    // ensuring you don't have disconnected traces

    emberAutoImportDynamic("@opentelemetry/context-zone").then(_ref => {
      let {
        ZoneContextManager
      } = _ref;
      provider.register({
        contextManager: new ZoneContextManager()
      });
    });
    (0, _instrumentation.registerInstrumentations)({
      instrumentations: [(0, _autoInstrumentationsWeb.getWebAutoInstrumentations)()]
    });
  } // Make sure we don't initialize OTel in test mode


  if (_environment.default.environment !== 'test') {
    initializeOtel();
  }
});