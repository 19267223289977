define("dashboard/components/range-slider", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/string", "@ember/component", "@ember/object", "jquery"], function (_exports, _computed, _runloop, _string, _component, _object, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'range-slider',
    classNameBindings: ['disabled', 'isDown'],
    attributeBindings: ['touchAction:touch-action'],
    touchAction: 'none',
    valueIndicatorStyle: (0, _object.computed)('_persistedValueX', function () {
      const x = this._persistedValueX;
      return (0, _string.htmlSafe)(`transform: translate(${x}px, 0px)`);
    }),
    min: 0,
    max: 100,
    step: 1,
    // if the persisted value remains below the min
    // (e.g. due to a lag in whatever mechanism enforces the value of persisted)
    // while we are in the disabled state
    // then we use the persistedValue as theendstart for "pre-min"
    preMinValueEnd: (0, _object.computed)('valueMin', 'persistedValue', 'disabled', function () {
      const persisted = this.persistedValue;
      const min = this.valueMin;

      if (this.disabled) {
        return Math.min(persisted, min);
      } else {
        return min;
      }
    }),
    headroomStartValue: (0, _computed.readOnly)('preMinValueEnd'),
    isMulti: (0, _object.computed)('valueMin', 'valueMax', function () {
      return Number.isInteger(this.valueMin) && Number.isInteger(this.valueMax);
    }),
    stepRange: (0, _object.computed)('step', 'min', 'max', function () {
      const range = this.max - this.min;
      const steps = range / this.step;
      return Array(steps).fill();
    }),
    indicatorIsHidden: (0, _computed.or)('_hasHandleCoveringIndicator', '_persistedIsMin'),
    _persistedIsMin: (0, _object.computed)('persistedValue', 'min', function () {
      return this.min === this.persistedValue;
    }),
    _hasHandleCoveringIndicator: (0, _object.computed)('value', 'valueMin', 'valueMax', 'persistedValue', 'disabled', 'isMulti', function () {
      const handlesAreVisible = !this.disabled;

      if (handlesAreVisible) {
        const persisted = this.persistedValue;
        const isMulti = this.isMulti;

        if (isMulti) {
          const minIsOnTop = this.valueMin === persisted;
          const maxIsOnTop = this.valueMax === persisted;
          return minIsOnTop || maxIsOnTop;
        } else {
          return this.value === persisted;
        }
      }
    }),
    pixelRatio: (0, _object.computed)('width', 'min', 'max', function () {
      return this.width / (this.max - this.min);
    }),
    _valueX: (0, _object.computed)('pixelRatio', 'value', function () {
      return this.pixelRatio * this.value;
    }),
    _persistedValueX: (0, _object.computed)('pixelRatio', 'persistedValue', function () {
      return this.pixelRatio * this.persistedValue;
    }),

    didRenderElement() {
      this._setDimensions();

      this._setupPointerEvents();

      this._onResize = () => this._setDimensions();

      (0, _jquery.default)(window).on('resize', this._onResize);
    },

    willDestroyElement() {
      this._teardownPointerEvents();

      (0, _jquery.default)(window).off('resize', this._onResize);

      this._super(...arguments);
    },

    _setDimensions() {
      (0, _runloop.run)(this, function () {
        this.set('width', (0, _jquery.default)(this.element).width());
        this.set('offsetLeft', (0, _jquery.default)(this.element).offset().left);
      });
    },

    _setupPointerEvents() {
      const $userSelectTarget = (0, _jquery.default)('body');
      (0, _jquery.default)(this.element).on('pointerdown', event => {
        if (!this.disabled) {
          this.set('isDown', true);
          const pointerId = (0, _object.get)(event, 'originalEvent.pointerId');

          if (pointerId) {
            this.set('pointerId', pointerId); // allow the pointer to trigger events even when it leaves
            // the component's bounding box
            // e.g. a user holds down the slider handle,
            // and drags the pointer directly down and the horizontally
            // to the right

            (0, _jquery.default)(this.element)[0].setPointerCapture(pointerId);
          } // make it so text won't highlight if the cursor
          // leaves the boundaries of the slider itself


          $userSelectTarget.css('user-select', 'none');

          this._sliderMoved(event);
        }
      });
      (0, _jquery.default)(this.element).on('pointerup', () => {
        this.set('isDown', false); // reset user-select

        $userSelectTarget.css('user-select', '');
      });
      (0, _jquery.default)(this.element).on('pointermove', event => {
        if (this.isDown === true) {
          this._sliderMoved(event);
        }
      });
    },

    _sliderMoved(event) {
      const width = this.width;
      let x = event.pageX - this.offsetLeft;

      if (x < 0) {
        x = 0;
      } else if (x > width) {
        x = width;
      }

      this.set('cursorX', x);

      this._updateStep();
    },

    _teardownPointerEvents() {
      (0, _jquery.default)(this.element).off('pointerdown pointermove pointerup');
    },

    _updateStep() {
      const x = this.cursorX;
      const ratio = x / this.width;
      const rawValue = ratio * this.step * (this.max - this.min);
      const valueAtCursor = Math.round(rawValue);

      if (this.isMulti) {
        const minValue = this.valueMin;
        const maxValue = this.valueMax;
        const lastValue = this.lastValueAtCursor;
        const minDiff = Math.abs(valueAtCursor - minValue);
        const maxDiff = Math.abs(valueAtCursor - maxValue); // here we are determining which handle should be used
        // at the given cursor location

        let minIsClosest;

        if (maxDiff === minDiff) {
          // in a tie-breaker we prefer the handle that was closer to
          // the cursor previously
          //
          // e.g. we were previously using the min handle, we arrive
          // at the central point between the two cursors, we will then
          // continue to use the min handle
          minIsClosest = lastValue < valueAtCursor;
        } else {
          minIsClosest = maxDiff >= minDiff;
        }

        if (minIsClosest) {
          this.onSelectedValueMin(valueAtCursor);
        } else {
          this.onSelectedValueMax(valueAtCursor);
        }

        this.set('lastValueAtCursor', valueAtCursor);
      } else {
        this.onSelectedValue(valueAtCursor);
      }
    }

  });

  _exports.default = _default;
});