define("dashboard/serializers/metrics/datastores/kafka", ["exports", "dashboard/serializers/metrics"], function (_exports, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const metricsData = payload.reduce((acc, item) => {
        const strippedKeyName = item.name.replace(/\./g, '_');
        acc[strippedKeyName] = item.measurements;
        return acc;
      }, {}); // 72 Hour and 7 Day timeframe steps need minute conversions to make the charts happy

      let step;

      if (payload.queryParams.step === '1h' || payload.queryParams.step === '2h') {
        step = parseInt(payload.queryParams.step.slice(0, payload.queryParams.step.length - 1)) * 60;
      } else {
        step = parseInt(payload.queryParams.step.slice(0, payload.queryParams.step.length - 1));
      }

      payload = {
        id: payload.id,
        data: metricsData,
        start_time: payload.queryParams.startTime.toISOString(),
        end_time: payload.queryParams.endTime.toISOString(),
        step
      };
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });

  _exports.default = _default;
});