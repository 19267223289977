define("dashboard/mixins/adapters/refresh-quick-jump-hooks", ["exports", "@ember/object/mixin", "dashboard/mixins/refresh-quick-jump"], function (_exports, _mixin, _refreshQuickJump) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_refreshQuickJump.default, {
    createRecord() {
      const result = this._super(...arguments);

      this.refreshQuickJump();
      return result;
    },

    updateRecord() {
      const result = this._super(...arguments);

      this.refreshQuickJump();
      return result;
    },

    deleteRecord() {
      const result = this._super(...arguments);

      this.refreshQuickJump();
      return result;
    }

  });

  _exports.default = _default;
});