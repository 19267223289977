define("dashboard/models/app-services-config", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    autoDeploy: (0, _model.attr)('boolean'),
    branch: (0, _model.attr)('string'),
    waitForCi: (0, _model.attr)('boolean'),
    pullRequest: (0, _model.attr)(),
    repo: (0, _model.attr)('string'),
    webUrl: (0, _object.computed)('repo', function () {
      return `https://github.com/${this.repo}`;
    })
  });

  _exports.default = _default;
});