define("dashboard/models/enterprise-account/member", ["exports", "@ember/object/computed", "@ember/object", "ember-data/attr", "ember-data/model", "ember-data/relationships", "@ember/object/proxy", "@ember/object/promise-proxy-mixin"], function (_exports, _computed, _object, _attr, _model, _relationships, _proxy, _promiseProxyMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const IdentityProviderOwnerProxy = _proxy.default.extend(_promiseProxyMixin.default, {
    type: (0, _computed.readOnly)('content.constructor.modelName')
  });

  var _default = _model.default.extend({
    user: (0, _attr.default)(),
    permissions: (0, _attr.default)(),
    enterpriseAccountName: (0, _attr.default)(),
    enterpriseAccount: (0, _relationships.belongsTo)('enterprise-account'),
    userId: (0, _attr.default)(),
    userEmail: (0, _attr.default)(),
    identityProvider: (0, _attr.default)(),
    twoFactorAuthentication: (0, _attr.default)('boolean'),
    email: (0, _object.computed)('user.email', function () {
      return this.get('user.email');
    }),
    canManage: (0, _object.computed)('permissions.[]', function () {
      return this.hasPermission('manage');
    }),
    canCreate: (0, _object.computed)('permissions.[]', function () {
      return this.hasPermission('create');
    }),
    canBill: (0, _object.computed)('permissions.[]', function () {
      return this.hasPermission('billing');
    }),
    permits: (0, _object.computed)('permissions.[]', 'enterpriseAccount.permissionNames', function () {
      return this.get('enterpriseAccount.permissionNames').map(name => {
        return _object.default.create({
          name,
          enabled: this.hasPermission(name),
          disabled: name === 'view'
        });
      });
    }),
    enabledPermits: (0, _computed.filterBy)('permits', 'enabled', true),
    isFederatedAgainstAccount: (0, _object.computed)('enterpriseAccount.id', 'identityProvider.owner.{type,id}', function () {
      return this.get('identityProvider.owner.type') === 'enterprise-account' && this.get('identityProvider.owner.id') === this.get('enterpriseAccount.id');
    }),

    hasPermission(name) {
      return this.permissions.includes(name);
    },

    identityProviderOwner: (0, _object.computed)('identityProvider.owner.{type,id}', function () {
      const owner = this.get('identityProvider.owner');

      if (!owner) {
        return null;
      }

      const {
        type,
        id
      } = owner;
      const store = this.get('store');
      const promise = store.findRecord(type, id);
      return IdentityProviderOwnerProxy.create({
        promise
      });
    })
  });

  _exports.default = _default;
});