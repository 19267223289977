define("dashboard/components/net-promoter-score/thank-you", ["exports", "@ember/component", "@ember/runloop", "ember-concurrency", "dashboard/config/environment"], function (_exports, _component, _runloop, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'nps-survey-thank-you',
    secondsUntilClose: _environment.default.environment === 'test' ? 0 : 5,
    countdownTask: (0, _emberConcurrency.task)(function* () {
      while (true) {
        if (this.secondsUntilClose === 0) {
          (0, _runloop.next)(this, this.hideSurvey);
          return;
        }

        yield (0, _emberConcurrency.timeout)(1000);
        this.decrementProperty('secondsUntilClose');
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      this.countdownTask.perform();
    }

  });

  _exports.default = _default;
});