define("dashboard/services/user-preferences", ["exports", "@ember/object", "@ember/service"], function (_exports, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    userPreferenceRecord: (0, _object.computed)(function () {
      return null;
    }),

    // Load prefs from API. Should be called by a high level route (protected)
    // so that more specific routes already have it loaded.
    fetch() {
      return this.store.queryRecord('user-preference', {}).then(prefs => {
        this.set('userPreferenceRecord', prefs);
      });
    }

  });

  _exports.default = _default;
});