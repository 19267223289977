define("dashboard/routes/enterprise-account/overview", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    breadcrumbs: (0, _service.inject)(),
    queryParams: {
      createTeam: {
        as: 'create-team',
        replace: true
      }
    },

    model() {
      return this.modelFor('enterprise-account');
    },

    renderTemplate(controller, model) {
      this.set('breadcrumbs.trail', {
        enterpriseAccount: model.enterpriseAccount
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav',
        into: 'protected'
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});