define("dashboard/models/metrics/space-availability", ["exports", "dashboard/models/metrics", "@ember/object/computed", "ember-data/relationships"], function (_exports, _metrics, _computed, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend({
    degradations: (0, _relationships.hasMany)('metrics/space-degradation'),
    tcpDegradations: (0, _computed.filterBy)('degradations', 'type', 'tcp-availability'),
    dnsDegradations: (0, _computed.filterBy)('degradations', 'type', 'dns-availability'),
    tlsDegradations: (0, _computed.filterBy)('degradations', 'type', 'tls-handshake-availability'),
    httpDegradations: (0, _computed.filterBy)('degradations', 'type', 'http-roundtrip-availability'),
    activeTcpDegradations: (0, _computed.filterBy)('tcpDegradations', 'isActive'),
    activeDnsDegradations: (0, _computed.filterBy)('dnsDegradations', 'isActive'),
    activeTlsDegradations: (0, _computed.filterBy)('tlsDegradations', 'isActive'),
    activeHttpDegradations: (0, _computed.filterBy)('httpDegradations', 'isActive'),
    currentTcpHealth: (0, _computed.empty)('activeTcpDegradations'),
    currentDnsHealth: (0, _computed.empty)('activeDnsDegradations'),
    currentTlsHealth: (0, _computed.empty)('activeTlsDegradations'),
    currentHttpHealth: (0, _computed.empty)('activeHttpDegradations')
  });

  _exports.default = _default;
});