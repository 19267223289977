define("dashboard/components/getting-started-item", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    tagName: 'li',

    logEvent(noun, action) {
      this.analytics.logEvent(noun, action);
    }

  });

  _exports.default = _default;
});