define("dashboard/serializers/favorite", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot
    /* , options*/
    ) {
      return {
        type: snapshot.attr('type'),
        resource_id: snapshot.attr('resourceId')
      };
    }

  });

  _exports.default = _default;
});