define("dashboard/components/async-button", ["exports", "@ember/object", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _object, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let AsyncButton = (_dec = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), (_class = class AsyncButton extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "reset", void 0);

      _defineProperty(this, "usePendingSpinner", void 0);

      this.reset = this.args.reset ?? false;
      this.usePendingSpinner = this.args.usePendingSpinner ?? false;
    }

    get isDisabled() {
      return this.args.disableWhen || this.actionTask.isRunning || this.actionTask.isQueued;
    }

    get isPending() {
      const {
        state
      } = this.actionTask;
      return state === 'queued' || state === 'running';
    }

    get showSpinner() {
      const {
        usePendingSpinner
      } = this.args;
      return this.isPending && usePendingSpinner;
    } // eslint-disable-next-line complexity


    get buttonText() {
      const {
        state,
        last
      } = this.actionTask;
      const {
        pending,
        rejected,
        fulfilled,
        default: defaultText
      } = this.args;
      let text;

      if (this.isPending) {
        text = pending;
      } else if (last?.isError && state === 'idle') {
        text = rejected;
      } else if (last?.isSuccessful && state === 'idle') {
        text = fulfilled;
      } else {
        text = defaultText;
      }

      return text ?? defaultText ?? '';
    }

    *actionTask() {
      const {
        action: actionArg
      } = this.args;
      yield actionArg();
    }

    *clickTask() {
      const {
        reset
      } = this.args;
      yield this.actionTask.perform();

      if (reset) {
        // with @reset set to true the  `actionTask.last` is used in determining
        // `buttonText`. By resetting the state here after it is finished being
        // performed, `last` will always be null and will show the default text.
        this.actionTask.cancelAll({
          resetState: true
        });
      }
    }

    click(e) {
      e.preventDefault();
      this.clickTask.perform();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "actionTask", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "actionTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "clickTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = AsyncButton;
});