define("dashboard/templates/components/purple-list-action-cell", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IyzwV/hu",
    "block": "[[[41,[30,0,[\"isSlidePanel\"]],[[[41,[30,0,[\"canRemove\"]],[[[41,[30,0,[\"removeButtonText\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-default btn-danger btn-xs \"],[4,[38,1],[[30,0],\"delete\",[30,0,[\"model\"]]],null],[12],[1,\"\\n          \"],[1,[30,0,[\"removeButtonText\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,2],[[24,0,\"mr3\"],[4,[38,1],[[30,0],\"edit\",[30,0,[\"model\"]]],null]],[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[\"Edit\",\"edit-16\",\"20\",\"malibu-fill-gradient-purple\"]],null],[1,\"\\n\"]],[]]]],[]],null]],[]],[[[41,[30,0,[\"showEditOrRemove\"]],[[[41,[30,0,[\"canEdit\"]],[[[1,\"      \"],[8,[39,2],[[24,0,\"pr3\"],[4,[38,1],[[30,0],\"edit\",[30,0,[\"model\"]]],null]],[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[\"Edit\",\"edit-16\",\"20\",\"malibu-fill-gradient-purple\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"canRemove\"]],[[[41,[30,0,[\"removeButtonText\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-default btn-danger btn-xs \"],[4,[38,1],[[30,0],\"delete\",[30,0,[\"model\"]]],null],[12],[1,\"\\n          \"],[1,[30,0,[\"removeButtonText\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,2],[[24,0,\"mr2\"],[4,[38,1],[[30,0],\"delete\",[30,0,[\"model\"]]],null]],[[\"@title\",\"@name\",\"@size\",\"@iconClass\"],[\"Delete\",\"delete-16\",\"16\",\"fill-gray hover-fill-red\"]],null],[1,\"\\n\"]],[]]]],[]],null]],[]],null]],[]]]],[],false,[\"if\",\"action\",\"malibu-icon-button\"]]",
    "moduleName": "dashboard/templates/components/purple-list-action-cell.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});