define("dashboard/components/datastore/postgres/config", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    logStatementOptions: {
      all: 'all statements',
      ddl: 'schema changes',
      mod: 'schema or data changes',
      none: 'no statements'
    },
    logStatement: (0, _object.computed)('config', 'config.logStatement', function () {
      return this.logStatementOptions[this.config.get('logStatement')];
    })
  });

  _exports.default = _default;
});