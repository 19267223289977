define("dashboard/utils/prompt-task", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An encapsulated task designed to prompt for user input.
   *
   * Declare a prompt task like this:
   *
   *     confirm: promptTask()
   *
   * Modify its concurrency properties just like any other task:
   *
   *     confirm: promptTask().drop()
   *
   * Use it in another task like this:
   *
   *     parentTask: task(function * () {
   *       const confirmed = yield this.confirm.perform();
   *
   *       if (confirmed) {
   *         // ...
   *       }
   *     })
   *
   * Or in an async function like this:
   *
   *     async someAction() {
   *       const confirmed = await this.confirm.perform();
   *
   *       if (confirmed) {
   *         // ...
   *       }
   *     }
   *
   * Or in a regular function like this:
   *
   *     someAction() {
   *       this.confirm.perform().then((confirmed) => {
   *         if (confirmed) {
   *           // ...
   *         } else {
   *           // ...
   *         }
   *       });
   *     }
   *
   * Interact with it in a template like this:
   *
   *     {{#if confirm.isRunning}}
   *       <button onclick={{action (mut confirm.last.answer) true}}>OK</button>
   *       <button onclick={{action (mut confirm.last.answer) false}}>Cancel</button>
   *     {{/if}}
   *
   * Or like this:
   *
   *     <form onsubmit={{action (mut promptScoops.last.answer) value="target.scoops.value"}}>
   *       <label for="scoops">How many scoops?</label>
   *       <input type="number" name="scoops">
   *       <button type="submit">
   *     </form>
   *
   * As soon as a task instance’s `answer` property is set to something other
   * than `undefined`, it will complete and return the value of `answer`.
   */
  var _default = () => {
    return new _emberConcurrency.task({
      answer: undefined,

      *perform() {
        yield (0, _emberConcurrency.waitForProperty)(this, 'answer', a => a !== undefined);
        return this.answer;
      }

    });
  };

  _exports.default = _default;
});