define("dashboard/services/spaces", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/array"], function (_exports, _service, _object, _computed, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    pipelineOwner: (0, _service.inject)(),
    store: (0, _service.inject)(),

    init() {
      const regions = this.store.peekAll('region');
      const spaces = this.store.peekAll('space');
      this.setProperties({
        regions,
        spaces
      });

      this._super(...arguments);
    },

    spaces: (0, _array.A)(),
    regions: (0, _array.A)(),
    runtimesSorting: ['name:desc'],
    organization: (0, _computed.readOnly)('pipelineOwner.organization'),
    canManageSpaces: (0, _computed.notEmpty)('orgSpaces'),
    sortedCommonRuntimes: (0, _computed.sort)('_commonRuntimes', 'runtimesSorting'),
    orgSpaces: (0, _object.computed)('organization.spaces.[]', function () {
      return this.get('organization.spaces') || (0, _array.A)();
    }),
    orgSpaceOptions: (0, _computed.map)('orgSpaces', function (_ref) {
      let {
        isReady,
        name,
        region,
        state,
        id
      } = _ref;
      const locale = region.get('locale');
      const labelName = `${name}, ${locale}`;
      let label = labelName;

      if (!isReady) {
        label += ` (${state})`;
      }

      return _object.default.create({
        name: labelName,
        label,
        id,
        disabled: !isReady
      });
    }),
    orgHasNoSpaces: (0, _computed.empty)('orgSpaces'),
    orgSpacesAreLoaded: (0, _computed.or)('orgSpaces.isFulfilled', 'orgHasNoSpaces'),
    _commonRuntimes: (0, _computed.filter)('regions', function (_ref2) {
      let {
        name
      } = _ref2;
      return (0, _utils.isEqual)(name, 'us') || (0, _utils.isEqual)(name, 'eu');
    })
  });

  _exports.default = _default;
});