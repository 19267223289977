define("dashboard/mirage-factories/github-file", ["exports", "ember-data-github/mirage-factories/github-file"], function (_exports, _githubFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _githubFile.default;
    }
  });
});