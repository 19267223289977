define("dashboard/components/metrics/charts/visualizations/garbage-collections-chart", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart", "@ember/object/computed", "dashboard/utils/metrics/has-non-zero-values"], function (_exports, _object, _wrappedLineChart, _computed, _hasNonZeroValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    chartTitle: 'Aggregate Garbage Collections',
    unit: null,
    insufficientDataTitle: 'Insufficient Data',
    valueFormat: '0,0',
    boundaryMultiplier: 1.3,
    isShowingCollections: true,
    isShowingYoung: true,
    isShowingOld: true,
    hasYoungGenData: (0, _hasNonZeroValues.default)('collectionsDataYoung'),
    hasOldGenData: (0, _hasNonZeroValues.default)('collectionsDataOld'),
    hasBothYoungAndOld: (0, _computed.and)('hasYoungGenData', 'hasOldGenData'),
    timeSeriesCollections: (0, _object.computed)('collectionsData', 'isShowingCollections', function () {
      return _object.default.create({
        name: 'Collections',
        color: 'blue-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 0,
        data: this.collectionsData,
        isVisible: this.isShowingCollections,
        key: 'Collections'
      });
    }),
    timeSeriesTotal: (0, _object.computed)('collectionsDataTotal', function () {
      return _object.default.create({
        name: 'Total',
        type: 'metrics/charts/line-chart-elements/line-graph',
        index: 0,
        data: this.collectionsDataTotal,
        hideLine: true
      });
    }),
    timeSeriesYoung: (0, _object.computed)('collectionsDataYoung', 'isShowingYoung', function () {
      return _object.default.create({
        name: 'Young Gen',
        color: 'blue-001',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 1,
        data: this.collectionsDataYoung,
        isVisible: this.isShowingYoung,
        key: 'Young'
      });
    }),
    timeSeriesOld: (0, _object.computed)('collectionsDataOld', 'isShowingOld', function () {
      return _object.default.create({
        name: 'Old Gen',
        color: 'blue-003',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 2,
        data: this.collectionsDataOld,
        isVisible: this.isShowingOld,
        key: 'Old'
      });
    }),
    chartTimeSeries: (0, _object.computed)('timeSeriesYoung', 'timeSeriesOld', 'timeSeriesTotal', 'timeSeriesCollections', 'hasBothYoungAndOld', function () {
      if (this.hasSplitGenerations) {
        const timeSeries = [this.timeSeriesYoung, this.timeSeriesOld];

        if (this.hasBothYoungAndOld) {
          timeSeries.push(this.timeSeriesTotal);
        }

        return timeSeries;
      } else {
        return [this.timeSeriesCollections];
      }
    })
  });

  _exports.default = _default;
});