define("dashboard/serializers/review-app", ["exports", "dashboard/serializers/application", "@ember/object"], function (_exports, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      if (hash.app) {
        relationships.app = {
          data: {
            id: hash.app.id,
            type: 'app'
          }
        };
      }

      if (hash.app_setup) {
        const appSetupId = hash.app_setup.id;
        relationships.app_setup = {
          data: {
            id: appSetupId,
            type: 'app-setup'
          }
        };
      }

      if (hash.pipeline) {
        relationships.pipeline = {
          data: {
            id: hash.pipeline.id,
            type: 'pipeline'
          }
        };
      }

      return relationships;
    },

    normalize(typeClass) {
      let hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const appSetupId = (0, _object.get)(hash, 'app_setup.id');

      if (appSetupId) {
        hash.links = {
          appSetup: `/app-setups/${appSetupId}`
        };
      }

      hash.forkRepoId = (0, _object.get)(hash, 'fork_repo.id');
      return this._super.apply(this, arguments);
    },

    serialize(_ref) {
      let {
        record: {
          sha,
          sourceBlobUrl
        }
      } = _ref;

      const json = this._super(...arguments);

      if (sourceBlobUrl) {
        /**
         * Rappari expects this url format when creating a new review app
         */
        json.source_blob = {
          url: sourceBlobUrl,
          version: sha
        };
        delete json.sha;
        delete json.source_blob_url;
      }

      return json;
    }

  });

  _exports.default = _default;
});