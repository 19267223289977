define("dashboard/models/enterprise-account/usage", ["exports", "ember-data/attr", "ember-data/model"], function (_exports, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    addons: (0, _attr.default)('number'),
    connect: (0, _attr.default)('number'),
    date: (0, _attr.default)('date'),
    dynos: (0, _attr.default)('number'),
    name: (0, _attr.default)(),
    partner: (0, _attr.default)('number'),
    space: (0, _attr.default)('number'),
    teams: (0, _attr.default)()
  });

  _exports.default = _default;
});