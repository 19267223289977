define("dashboard/serializers/github-branch", ["exports", "ember-data-github/serializers/github"], function (_exports, _github) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _github.default.extend({
    normalize(typeClass, hash) {
      const repo = hash.commit.url.replace('https://api.github.com/repos/', '').replace(/\/commits\/.+/, ''); // Manually set the id since GitHub doesn't send us one.

      hash.id = `${repo}/branches/${hash.name}`;
      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});