define("dashboard/components/enter-sms-number", ["exports", "@ember/component", "@ember/object/computed", "ember-concurrency", "@ember/service"], function (_exports, _component, _computed, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    smsNumber: null,
    newPhoneNumber: null,
    password: null,
    defaultNumber: '+1 123 444-5555',
    placeholder: (0, _computed.or)('smsNumber', 'defaultNumber'),
    error: null,
    api: (0, _service.inject)(),
    saveNumber: (0, _emberConcurrency.task)(function* () {
      const api = this.api;
      const smsNumber = this.newPhoneNumber;
      const password = this.password;
      const data = {
        sms_number: smsNumber,
        password
      };

      try {
        yield api.patch('/account', data);
      } catch (error) {
        this.set('error', error);
        return;
      }

      this.onSubmitNumber(smsNumber);
    }).drop()
  });

  _exports.default = _default;
});