define("dashboard/components/pipeline/review-app-error", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['review-app-error', 'red', 'pa2', 'ma2', 'mt0', 'f6', 'bg-lightest-red', 'br1'],
    currentRelease: (0, _computed.readOnly)('reviewApp.currentRelease')
  });

  _exports.default = _default;
});