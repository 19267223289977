define("dashboard/templates/components/stack-deprecation-warning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "r/92n18X",
    "block": "[[[41,[30,0,[\"showStackDeprecationWarning\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"hk-message--danger flex items-center \",[30,0,[\"contextClasses\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"ph1\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"failure-ring-28\",28,\"malibu-fill-gradient-red\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"lh-copy\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"f4 b lh-copy\"],[12],[1,\"\\n        \"],[1,[30,0,[\"stackDeprecationTitle\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[1,[30,0,[\"stackDeprecationMessage\"]]],[1,\"\\n        \"],[8,[39,2],[[16,6,[30,0,[\"stackDeprecation\",\"documentationUrl\"]]]],[[\"@fillClass\",\"@withPrelude\"],[\"fill-red\",false]],[[\"default\"],[[[[1,\"\\n          Visit here to learn more\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-icon\",\"dev-center-link\"]]",
    "moduleName": "dashboard/templates/components/stack-deprecation-warning.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});