define("dashboard/components/metrics/language-metrics-setup", ["exports", "@ember/component", "dashboard/templates/components/metrics/language-metrics-setup"], function (_exports, _component, _languageMetricsSetup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _languageMetricsSetup.default,
    'data-test-target': 'language-metrics-setup'
  });

  _exports.default = _default;
});