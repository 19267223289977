define("dashboard/routes/protected/release", ["exports", "@ember/service", "@ember/routing/route"], function (_exports, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    current: (0, _service.inject)(),
    session: (0, _service.inject)(),

    beforeModel() {
      const isAssumed = this.get('session.isAssumed');

      if (!this.get('current.account.isHerokai' || !isAssumed)) {
        this.transitionTo('apps');
      }

      if (isAssumed) {
        this.send('release');
      }
    }

  });

  _exports.default = _default;
});