define("dashboard/utils/custom-computed", ["exports", "@ember/utils", "@ember/object"], function (_exports, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.belongsTo = belongsTo;
  _exports.setComplementBy = _exports.min = _exports.max = _exports.limitBy = _exports.limit = _exports.groupBy = _exports.fuzzyFilterBy = _exports.findBy = _exports.equalTo = _exports.dynamicFilterBy = void 0;

  /* globals requireModule */

  /**
   * a custom computed property that accepts the name of a computed property
   * that should be an array defined for the object.
   *
   * Is more resilient than Ember's default Ember.computed.max (for mysterious reasons)
   *
   */
  const max = function (arrayName) {
    return (0, _object.computed)(`${arrayName}.[]`, function () {
      const maxValue = Math.max(...this.get(arrayName));
      return (0, _utils.isPresent)(maxValue) ? maxValue : -Infinity;
    });
  };

  _exports.max = max;

  const min = function (arrayName) {
    return (0, _object.computed)(`${arrayName}.[]`, function () {
      const minValue = Math.min(...this.get(arrayName));
      return (0, _utils.isPresent)(minValue) ? minValue : -Infinity;
    });
  };
  /**
   * Returns a filtered collection where the `valueKey` of the colleciton's
   * objects match the value of our `filterKey` with our custom filter regex
   * applied to it
   */


  _exports.min = min;

  const fuzzyFilterBy = function (arrayName, valueKey, filterKey) {
    return (0, _object.computed)(`${arrayName}.[]`, valueKey, filterKey, function () {
      const filterText = (this.get(filterKey) || '').replace(/[^a-z0-9-]+/gi, ''); // strip invalid chars

      if (filterText.length === 0) {
        return this.get(arrayName);
      }

      const filter = filterText.split('').join('.*');
      const regex = new RegExp(filter, 'i');
      return this.get(arrayName).filter(function (item) {
        return regex.test(item.get(valueKey));
      });
    });
  };
  /**
   * Returns a filtered collection where the `valueKey` of the colleciton's
   * objects match the value of our `filterKey`.
   */


  _exports.fuzzyFilterBy = fuzzyFilterBy;

  const dynamicFilterBy = function (arrayName, valueKey, filterKey) {
    return (0, _object.computed)(`${arrayName}.@each.${valueKey}`, valueKey, filterKey, function () {
      const filterText = this.get(filterKey);
      return this.get(arrayName).filterBy(valueKey, filterText);
    });
  };
  /**
   * Find an item in a collection where this.get('<attributeKey>') is the first item in `arrayName`
   * that equals this.get('<valueKey>').
   */


  _exports.dynamicFilterBy = dynamicFilterBy;

  const findBy = function (arrayName, attributeKey, valueKey) {
    return (0, _object.computed)(`${arrayName}.[]`, attributeKey, valueKey, function () {
      const value = this.get(valueKey);
      const collection = this.get(arrayName) || [];
      return collection.findBy(attributeKey, value);
    });
  };

  _exports.findBy = findBy;

  const limit = function (arrayName, count) {
    return (0, _object.computed)(`${arrayName}.[]`, function () {
      return this.get(arrayName).slice(0, count);
    });
  };

  _exports.limit = limit;

  const limitBy = function (arrayName, countProperty) {
    return (0, _object.computed)(`${arrayName}.[]`, countProperty, function () {
      const count = this.get(countProperty);

      if (count) {
        return this.get(arrayName).slice(0, count);
      } else {
        // return everything if the countPropery is falsy
        return this.get(arrayName);
      }
    });
  };
  /**
   * returns the Complement of two sets on a specific attribute. That
   * is, returns items from SetB where the attr is not present in any item of SetA.
   * This is equivilent to an OUTER JOIN in SQL.
   *
   * Example:
   *
   *   SetA         | SetB
   *   -------------+---------------
   *   { id: 1 }    | { id: 1 }
   *   { id: 2 }    |
   *                | { id: 3 } <-
   *   { id: 4 }    |
   *                | { id: 5 } <-
   *
   *   setComplementBy('SetA', 'id', 'SetB', 'id')
   *   # => { id: 3 }, { id: 5 }
   */


  _exports.limitBy = limitBy;

  const setComplementBy = function (setAName, attrA, setBName, attrB) {
    return (0, _object.computed)(`${setAName}.[]`, `${setBName}.[]`, function () {
      return this.get(setAName).filter(obj => {
        return !this.get(setBName).mapBy(attrB).includes(obj.get(attrA));
      });
    });
  };

  _exports.setComplementBy = setComplementBy;

  const groupBy = function (arrayName, attr) {
    return (0, _object.computed)(`${arrayName}.[]`, attr, function () {
      return this.get(arrayName).reduce((memo, item) => {
        const value = item.get(attr);

        if (!memo[value]) {
          memo[value] = [];
        }

        memo[value].pushObject(item);
        return memo;
      }, {});
    });
  };

  _exports.groupBy = groupBy;

  const equalTo = function (attrA, attrB) {
    return (0, _object.computed)(attrA, attrB, function () {
      return this.get(attrA) === this.get(attrB);
    });
  };
  /*
   * This whole thing is a gross but necessary hack.
   *
   * By convention the Heroku API responses can include nested references to
   * associated types, e.g. {app: {id: '123'}, pipeline: {id: '123'}}
   * rest-model sets each property on the model instance which overrides any
   * existing association property. This is not desirable as we often set up
   * actual instances of an associated model class.
   *
   * This intercepts the setter so that it will only set if:
   *   - there is currently no value
   *   - OR the association's ID is different to the existing value
   *   - OR we're setting another model instance
   * If these conditions are not met, we just set the properties on the existing
   * value
   *
   */


  _exports.equalTo = equalTo;

  function belongsTo(name) {
    let {
      module
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      module: name
    };
    let klass;
    const privateName = `__${name}`;
    return (0, _object.computed)(privateName, {
      get() {
        return this.get(privateName);
      },

      set(key, newValue) {
        // We need to load the association's model here to avoid a circular
        // dependency if we have a two way association.
        klass = klass || requireModule(`dashboard/models/${module}`).default;
        const currentValue = this.get(privateName);
        const isCurrentlyUnset = (0, _utils.isNone)(currentValue);
        const newId = (0, _object.get)(newValue, 'id');
        const idIsChanged = this.get(`${name}.id`) !== newId;
        const isSettingAppInstance = newValue instanceof klass;
        const shouldSet = isCurrentlyUnset || idIsChanged || isSettingAppInstance;

        if (shouldSet) {
          return this.set(privateName, newValue);
        } else {
          const newProperties = Object.keys(newValue).reduce((memo, objectKey) => {
            const value = (0, _object.get)(newValue, objectKey);

            if (value !== null) {
              memo[objectKey] = value;
            }

            return memo;
          }, {});
          currentValue.setProperties(newProperties);
          return currentValue;
        }
      }

    });
  }
});