define("dashboard/adapters/repositories-api-pull-request", ["exports", "dashboard/adapters/application", "dashboard/mixins/load-more-records"], function (_exports, _application, _loadMoreRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_loadMoreRecords.default, {
    version: '3.repositories-api',

    urlForQuery(query) {
      const url = `${this.host}/pipelines/${query.pipelineId}/repo/pull-requests`;
      delete query.pipelineId;
      return url;
    },

    query(store, type, query) {
      const pipelineId = query.pipelineId;
      return this._super(...arguments).then(payload => {
        if (payload.hasMore) {
          query.pipelineId = pipelineId;
          this.loadMoreRecords.perform('repositories-api-pull-request', query);
        }

        payload = payload.data.map(record => {
          record.pipeline = {
            id: pipelineId
          };
          return record;
        });
        return payload;
      });
    }

  });

  _exports.default = _default;
});