define("dashboard/templates/components/confirm/with-validation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4I0jlz4Q",
    "block": "[[[10,2],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"input\"],[14,0,\"hk-input w-100\"],[14,\"autocapitalize\",\"off\"],[14,\"autocorrect\",\"off\"],[14,\"autocomplete\",\"off\"],[14,\"spellcheck\",\"false\"],[15,\"oninput\",[28,[37,1],[[30,0],\"validate\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"text\"],[12],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"action\"]]",
    "moduleName": "dashboard/templates/components/confirm/with-validation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});