define("dashboard/mixins/password-encoding", ["exports", "@ember/object", "@ember/object/mixin"], function (_exports, _object, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A mixin to be used for models that require passwords confirmations
   * to be present on certain requests
   *
   */
  var _default = _mixin.default.create({
    /**
     * hack to properly format a string UTF-8 to be consumed
     * by our backend in a readable format
     *
     * see: http://stackoverflow.com/a/5396742/289814 for a great
     * explanation of how this works
     */
    decodedPassword: (0, _object.computed)('password', function () {
      return this.decodePassword(this.password);
    }),

    decodePassword(password) {
      return password ? unescape(encodeURIComponent(password)) : '';
    }

  });

  _exports.default = _default;
});