define("dashboard/templates/components/app/collaborator-confirm-delete-slide-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mdvbqSeU",
    "block": "[[[1,[28,[35,0],null,[[\"onClick\",\"buttonType\",\"class\",\"default\"],[[28,[37,1],[[30,0],\"isDeletingConfirm\"],null],\"danger\",\"w-100\",\"Delete Collaborator\"]]]]],[],false,[\"hk-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/app/collaborator-confirm-delete-slide-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});