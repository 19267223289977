define("dashboard/serializers/team/collaborator", ["exports", "dashboard/serializers/collaborator"], function (_exports, _collaborator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _collaborator.default.extend({
    serialize(snapshot) {
      const serialized = this._super(...arguments);

      if (snapshot.record.get('app.permissionNames')) {
        const permissions = snapshot.record.get('permits');

        if (permissions) {
          serialized['permissions'] = permissions.filterBy('enabled').mapBy('name');
        }
      }

      return serialized;
    }

  });

  _exports.default = _default;
});