define("dashboard/models/shogun/postgres-datastore", ["exports", "ember-data/attr", "ember-data/model", "@ember/service", "ember-data/relationships", "dashboard/utils/metrics/time-calculators", "dashboard/mixins/metrics/timeframe-options"], function (_exports, _attr, _model, _service, _relationships, _timeCalculators, _timeframeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timeframeOptions.default, {
    browserTime: (0, _service.inject)(),
    api: (0, _service.inject)(),
    addon: (0, _relationships.belongsTo)('addon'),
    connections: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    info: (0, _attr.default)(),
    dataSize: (0, _attr.default)('string'),
    maintenance: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    numTables: (0, _attr.default)('number'),
    pgVersion: (0, _attr.default)('string'),
    plan: (0, _attr.default)('string'),
    region: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    rollback: (0, _attr.default)('string'),
    rows: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    tables: (0, _attr.default)('number'),
    config: (0, _relationships.belongsTo)('shogun/postgres-config'),
    credentials: (0, _relationships.belongsTo)('shogun/postgres-credentials'),
    backups: (0, _relationships.hasMany)('shogun/postgres-backup', {
      inverse: 'datastore'
    }),

    queryMetricsData(modelName, timeframeBegin, timeframeEnd) {
      const specifiedTimeframe = this.allDatastoreOptions.find(option => {
        return option.beforeHours === timeframeEnd && option.afterHours === timeframeBegin;
      });
      const timeframe = specifiedTimeframe || this.allDatastoreOptions.findBy('isDefault', true);
      const startTime = (0, _timeCalculators.calculateStartTime)(this.get('browserTime.currentTime'), timeframe.afterHours, timeframe.step);
      const endTime = (0, _timeCalculators.calculateEndTime)(this.get('browserTime.currentTime'), timeframe.beforeHours, timeframe.step);
      let step; // 72 Hour and 7 Day timeframe need hour conversions to make metaas happy

      if (timeframe.name === 'past72Hours' || timeframe.name === 'past7days') {
        step = `${timeframe.step / 60}h`;
      } else {
        step = `${timeframe.step}m`;
      }

      const query = {
        startTime,
        endTime,
        step,
        addonId: this.addon.get('id')
      };
      return this.store.queryRecord(modelName, query);
    },

    async fork(plan) {
      const addon = await this.get('addon');
      const app = await addon.get('app');
      const data = {
        config: {
          fork: addon.name
        },
        plan
      };
      return this.api.post(`/apps/${app.id}/addons`, data);
    },

    async reset() {
      const adapter = this.store.adapterFor('shogun/kafka-topic');
      return this.api.request(`/client/v11/databases/${this.id}/reset`, {
        adapter,
        type: 'PUT',
        contentType: 'application/json; charset=utf-8'
      });
    },

    async destroy() {
      if (!this.api.isDestroying) {
        const addon = await this.get('addon');
        const app = await addon.get('app');
        return this.api.delete(`/apps/${app.id}/addons/${addon.id}`);
      }
    },

    createCredential(name) {
      const addonId = this.get('addon.id');
      const adapter = this.store.adapterFor('shogun/postgres-credentials');
      const data = {
        name
      };
      return this.api.request(`/postgres/v0/databases/${addonId}/credentials`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    }

  });

  _exports.default = _default;
});