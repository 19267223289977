define("dashboard/components/metrics/charts/chart-header/chart-summary-row-item", ["exports", "@ember/component", "@ember/object", "numeral", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _object, _numeral, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const formattedNumber = function (key) {
    return (0, _object.computed)('numberFormat', key, function () {
      const format = this.numberFormat;
      const raw = this.get(key);
      return (0, _numeral.default)(raw).format(format);
    });
  };

  var _default = _component.default.extend({
    classNames: ['metrics__summary-row__item'],
    'data-test-target': (0, _computed.readOnly)('testTarget'),
    primaryValueIsCritical: (0, _object.computed)('primaryValue', 'criticalLevel', 'routingHealth', 'routingHealthProvided', function () {
      return this.primaryValue >= this.criticalLevel || this.routingHealthProvided && !this.routingHealth;
    }),
    primaryValueIsWarning: (0, _object.computed)('primaryValue', 'warningLevel', function () {
      return this.primaryValue >= this.warningLevel;
    }),
    currentLevelClass: (0, _object.computed)('primaryValueIsCritical', 'primaryValueIsWarning', function () {
      let valueClass = 'metrics__summary-row__value';

      if (this.primaryValueIsCritical) {
        valueClass += '--danger';
      } else if (this.primaryValueIsWarning) {
        valueClass += '--warning';
      }

      return valueClass;
    }),
    primaryValueFormatted: formattedNumber('primaryValue'),
    secondaryValueFormatted: formattedNumber('secondaryValue'),
    routingHealthNotProvided: (0, _object.computed)('routingHealth', function () {
      return (0, _utils.isEmpty)(this.routingHealth);
    }),
    routingHealthProvided: (0, _computed.not)('routingHealthNotProvided'),
    routingHealthFormatted: (0, _object.computed)('routingHealth', function () {
      return this.routingHealth ? 'Healthy' : 'Degraded';
    })
  });

  _exports.default = _default;
});