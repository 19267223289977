define("dashboard/adapters/app", ["exports", "@ember/polyfills", "dashboard/adapters/application", "dashboard/mixins/adapters/refresh-quick-jump-hooks", "dashboard/utils/errors", "ember-concurrency", "dashboard/mixins/load-more-records"], function (_exports, _polyfills, _application, _refreshQuickJumpHooks, _errors, _emberConcurrency, _loadMoreRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_RANGE = {
    attribute: 'name',
    max: 1000
  };
  const COLLABORATOR_REQUESTS_MAX = 10;

  var _default = _application.default.extend(_refreshQuickJumpHooks.default, {
    version: '3.process-tier',
    range: DEFAULT_RANGE,

    urlForUpdateRecord(id, modelName, snapshot) {
      let url = this._super(...arguments);

      if (snapshot.adapterOptions && snapshot.adapterOptions.teamAppUpdate) {
        const baseUrl = this.buildURL();
        url = `${baseUrl}/teams/apps/${id}`;
      }

      if (snapshot.adapterOptions && snapshot.adapterOptions.acm) {
        url += '/acm';
      }

      return url;
    },

    urlForFindRecord(id) {
      const baseUrl = this.buildURL();
      return `${baseUrl}/teams/apps/${id}`;
    },

    urlForQueryRecord(query) {
      const appName = query.appName;
      delete query.appName;
      const baseUrl = this.buildURL();
      return `${baseUrl}/teams/apps/${appName}`;
    },

    _queryRange(query) {
      if (query.range) {
        const range = query.range;
        delete query.range;
        this.set('range', range);
      }
    },

    urlForQuery(query) {
      const host = this.host;
      let url;

      if (query.personalOnly) {
        delete query['personalOnly'];
        url = `${host}/users/~/apps`;
      } else if (query.team) {
        const team = query.team;
        delete query['team'];
        url = `${host}/teams/${team}/apps`;
      } else {
        url = this._super(...arguments);
      }

      this._queryRange(query);

      return url;
    },

    findRecord() {
      this.set('range', null);
      return this._super(...arguments);
    },

    queryRecord() {
      this.set('range', null);
      return this._super(...arguments);
    },

    findAll() {
      this.set('range', DEFAULT_RANGE);
      return this._super(...arguments);
    },

    query() {
      this.set('range', DEFAULT_RANGE);
      return this._super(...arguments);
    },

    findBelongsTo(store, snapshot, url, relationship) {
      if (relationship.type === 'config-vars') {
        return this._super(...arguments).then(payload => {
          return (0, _polyfills.merge)({
            id: `config-vars-for-app-${snapshot.id}`
          }, payload);
        });
      }

      return this._super(...arguments);
    },

    findHasMany(store, snapshot, url, relationship) {
      if (relationship.key === 'collaborators' || relationship.key === 'teamAppCollaborators') {
        return this.loadAllCollaborators.perform(snapshot, relationship.key);
      } // Unfortunately, API returns features that contain non-unique IDs. They are,
      // however, unique within each org, so we are creating a composite key here
      // based on the org ID and the feature ID


      return this._super(...arguments).then(payload => {
        if (/features/.test(url)) {
          (payload || []).forEach(feature => {
            feature.id = `${feature.id}/${snapshot.id}`;
          });
        } // The Platform API does not return app information like it does for SSL
        // endpoints.
        // Remove me when this changes in API.
        // https://devcenter.heroku.com/articles/platform-api-reference#sni-endpoint-list


        if (relationship.type === 'sni-endpoint') {
          (payload || []).forEach(sniEndpoint => {
            sniEndpoint.app = {
              id: snapshot.id,
              type: 'app'
            };
          });
        }

        if (relationship.key === 'connectedBranches') {
          if (payload.hasMore) {
            this.loadMoreBranches.perform(snapshot);
          }

          payload = payload.data;
        }

        return payload;
      });
    },

    loadMoreBranches: (0, _loadMoreRecords.loadMoreRelatedRecords)('repositories-api-github-branch', 'appId'),
    loadAllCollaborators: (0, _emberConcurrency.task)(function* (snapshot, relationshipKey) {
      let modelName;

      if (relationshipKey === 'collaborators') {
        modelName = 'collaborator';
      } else {
        modelName = 'team/collaborator';
      }

      const store = this.store;
      const type = store.modelFor(modelName);
      const adapter = store.adapterFor(modelName);
      const app = snapshot.record.id;
      const results = [];
      let range = 'email ..; max=1000';
      let count = 0;

      while (range && count < COLLABORATOR_REQUESTS_MAX) {
        const query = {
          app,
          range
        };
        const page = yield adapter.query(store, type, query);
        count += 1;
        range = page.meta.nextRange;
        results.push(...page);
      }

      return results;
    }),

    urlForCreateRecord() {
      return `${this.host}/teams/apps`;
    },

    handleResponse(status, headers, payload, requestData) {
      if (this.isInvalid(status, headers, payload)) {
        payload.errors = (0, _errors.toJSONAPIErrors)(payload, status);
      } // Are you tired of real status codes blowing up your ember-data? Come on
      // down to Real Fake Statuses! Your 401s are now 204s.
      // We return a 204 to ember-data so it doesnt blow up Dashboard,
      // which allows us to passthrough and let ajaxMonitor handle the real 401.
      // ajaxMonitor interprets this real 401 and spawns a reauth modal


      if (status === 401) {
        status = 204;
        delete payload.errors;
      }

      if (payload && typeof payload === 'object') {
        const nextRange = this.getHeaderValue('Next-Range', headers);
        payload.meta = {
          nextRange,
          status
        };
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});