define("dashboard/adapters/metrics/rest-adapter", ["exports", "dashboard/adapters/metrics"], function (_exports, _metrics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a base-adapter for metrics data structures that are RESTful
   *
   * not intended to be used directly
   */
  var _default = _metrics.default.extend({
    urlForUpdateRecord(id, modelName, snapshot) {
      const {
        appId,
        processType
      } = snapshot.attributes();
      const baseUrl = this.urlForQuery({
        appId,
        processType
      });
      return `${baseUrl}/${id}`;
    },

    urlForCreateRecord(modelName, snapshot) {
      const {
        appId,
        processType
      } = snapshot.attributes();
      return this.urlForQuery({
        appId,
        processType
      });
    },

    updateRecord(store, type, snapshot) {
      const {
        id,
        modelName
      } = snapshot;
      const url = this.urlForUpdateRecord(id, modelName, snapshot);
      const data = JSON.stringify(this.serialize(snapshot));
      return this.makeRequest({
        method: 'PATCH',
        contentType: 'application/json',
        dataType: 'json',
        url,
        data
      });
    },

    createRecord(store, type, snapshot) {
      const {
        modelName
      } = snapshot;
      const url = this.urlForCreateRecord(modelName, snapshot);
      const data = JSON.stringify(this.serialize(snapshot));
      return this.makeRequest({
        method: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        url,
        data
      });
    }

  });

  _exports.default = _default;
});