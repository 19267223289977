define("dashboard/components/app/activity-release-item", ["exports", "@ember/object", "@ember/utils", "@ember/service", "@ember/string", "ember", "dashboard/components/app/activity-item", "dashboard/mixins/uses-config-var-description", "dashboard/templates/components/app/activity-item"], function (_exports, _object, _utils, _service, _string, _ember, _activityItem, _usesConfigVarDescription, _activityItem2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    readOnly,
    alias,
    equal,
    match,
    notEmpty
  } = _object.computed;

  var _default = _activityItem.default.extend(_usesConfigVarDescription.default, {
    layout: _activityItem2.default,
    analytics: (0, _service.inject)(),
    configVarsByGroup: readOnly('item.configVarsByGroup'),
    status: alias('item.status'),
    hasReleaseLogOutput: notEmpty('item.output_stream_url'),
    deployDescription: (0, _object.computed)('item.commit', 'status', function () {
      let deploy = this.get('item.commit');
      let succeeded = 'Deployed';
      deploy = _ember.default.Handlebars.Utils.escapeExpression(deploy);

      if (deploy) {
        succeeded += ` <code>${deploy}</code>`;
      }

      const statusMessages = {
        succeeded,
        pending: `<span class="test-info">Deploying</span> <code>${deploy}</code>`,
        failed: `<span class="text-danger">Deployment failed</span> <code>${deploy}</code>`
      };
      let description = '';

      if (!(0, _utils.isBlank)(this.status)) {
        description = statusMessages[this.status] || '';
      } else {
        description = statusMessages.succeeded;
      }

      return (0, _string.htmlSafe)(description);
    }),
    diffLink: (0, _object.computed)('app.isContainerBuild', 'githubLink.repo', 'isDeploy', 'previousRelease.commit', 'item.commit', function () {
      if (this.app.isContainerBuild) {
        // Docker builds do not have info about the github commits needed to show a diff.
        return null;
      }

      const repo = this.get('githubLink.repo');
      const hasDiff = this.isDeploy && (0, _utils.isPresent)(repo) && (0, _utils.isPresent)(this.get('previousRelease.commit'));

      if (hasDiff) {
        return `https://github.com/${repo}/compare/${this.get('previousRelease.commit')}...${this.get('item.commit')}`;
      }
    }),
    canViewDiff: (0, _object.computed)('appPermissions.canGitRepo', 'diffLink', function () {
      if (this.get('appPermissions.canGitRepo')) {
        return (0, _utils.isPresent)(this.diffLink);
      } else {
        return false;
      }
    }),
    previousRelease: (0, _object.computed)('releases.[]', function () {
      const currentIndex = this.releases.indexOf(this.item);
      return this.releases.slice([currentIndex + 1]).findBy('isDeploy', true);
    }),
    descriptionContent: (0, _object.computed)('item.description', function () {
      const description = this.get('item.description');

      if (this.isConfigVar) {
        return this.configVarDescription;
      } else if (this.isDeploy) {
        return this.deployDescription;
      } else {
        return description;
      }
    }),
    isConfigVar: match('item.description', /^(Set|Remove) .* config/),
    isDeploy: match('item.description', /^deploy/i),
    isPending: equal('status', 'pending'),
    displayVersion: (0, _object.computed)('item.version', function () {
      return `v${this.get('item.version')}`;
    }),
    releaseFailed: equal('status', 'failed'),
    itemCanBeRolledBack: (0, _object.computed)('item.canBeRolledBack', 'status', 'latestRelease', 'appPermissions.canManageReleases', function () {
      if ((0, _utils.isPresent)(this.get('item.canBeRolledBack'))) {
        return this.get('item.canBeRolledBack');
      }

      const status = this.status;

      if (status === 'pending' || status === 'failed') {
        return false;
      }

      if (this.get('appPermissions.canManageReleases')) {
        const latest = this.latestRelease;
        return latest !== this.item;
      } else {
        return false;
      }
    }),
    canBeRolledBack: and('allowRollbacks', 'itemCanBeRolledBack'),
    deleteModalTitle: (0, _object.computed)('displayVersion', function () {
      return `Rollback to ${this.displayVersion}`;
    }),
    actions: {
      rollback() {
        return this.item.rollback().then(() => {
          this.analytics.logEvent('App', 'Rolled Back'); // trigger a refresh in the route

          this.onRefresh();
        });
      }

    }
  });

  _exports.default = _default;
});