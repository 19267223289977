define("dashboard/routes/account/applications/edit-client", ["exports", "dashboard/routes/account/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    model(params) {
      return this.store.findRecord('client', params.id);
    },

    deactivate() {
      const clients = this.store.peekAll('client'); // don't show private clients that were loaded by visiting a client
      // directly

      clients.filterBy('isPrivate', true).forEach(client => {
        client.unloadRecord();
      }); // remove unpersisted changes

      clients.filterBy('hasDirtyAttributes', true).forEach(client => {
        client.rollbackAttributes();
      });
    }

  });

  _exports.default = _default;
});