define("dashboard/mixins/web-socket-model", ["exports", "@ember/array", "@ember/application", "@ember/object", "@ember/utils", "@ember/object/mixin", "@ember/service"], function (_exports, _array, _application, _object, _utils, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SocketWrapper = void 0;

  // Room object
  // This basically maps to a Phoenix.js channel object
  const SocketWrapper = _object.default.extend({
    webSocket: (0, _service.inject)(),
    channel: null,

    connect() {
      if (this.isDestroyedOrDestroying()) {
        return;
      }

      this.joinRoom();
    },

    disconnect() {
      this.leaveRoom();
      this.channel = null;
    },

    on(eventName, callback) {
      return this.channel.on(eventName, (event, room) => {
        this.handleEvent(event, room, callback);
      });
    },

    handleEvent(event, room, callback) {
      if (this.isDestroyedOrDestroying()) {
        return;
      }

      callback(event, room);
    },

    off(eventName, ref) {
      return this.channel.off(eventName, ref);
    },

    joinRoom() {
      if (this.isDestroyedOrDestroying()) {
        return;
      }

      this.channel = this.webSocket.join(this.room).channel;
    },

    leaveRoom() {
      if (this.isDestroyedOrDestroying()) {
        return;
      }

      this.channel.leave();
    },

    isDestroyedOrDestroying() {
      return this.isDestroyed || this.isDestroying || this.model.isDestroyed || this.model.isDestroying;
    },

    room: (0, _object.computed)('model.socketIdentifier', 'subResource', function () {
      let room = this.get('model.socketIdentifier');
      const subResource = this.subResource;

      if (subResource) {
        room = `${room}/${subResource}`;
      }

      return room;
    })
  });

  _exports.SocketWrapper = SocketWrapper;

  var _default = _mixin.default.create({
    subscriptions: (0, _object.computed)(function () {
      return (0, _array.A)();
    }),

    subscribeToUpdates() {
      let subResource = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const ownerInjection = (0, _application.getOwner)(this).ownerInjection();
      const subscription = SocketWrapper.create(ownerInjection, {
        subResource,
        model: this
      }); // using the ownerInjection causes the merged property to
      // be undefined rather than null. Since unsubscribeFromUpdates
      // explicitly checks for subResource of against null by default
      // this is needed to preserve null here.

      subscription.subResource = subResource;
      subscription.connect();
      this.subscriptions.pushObject(subscription);
      return subscription;
    },

    disconnect() {
      const subscription = this.get('subscriptions.firstObject');

      if ((0, _utils.isPresent)(subscription)) {
        subscription.disconnect();
      }

      return this;
    },

    unsubscribeFromUpdates() {
      let subResource = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const subscriptions = this.subscriptions.filterBy('subResource', subResource);
      subscriptions.invoke('leaveRoom');
      subscriptions.forEach(sub => {
        this.subscriptions.removeObject(sub);
      });
      return this;
    }

  });

  _exports.default = _default;
});