define("dashboard/models/state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Fetch a list of states and their ISO-3166 alpha-2 (2-letter abbreviation)
   * codes. This list will need to be periodically updated from the ISO-3166
   * spec (it rarely changes): https://en.wikipedia.org/wiki/ISO_3166-2:US
   */
  class State {
    static all() {
      return [{
        name: 'Alabama',
        abbrev: 'AL'
      }, {
        name: 'Alaska',
        abbrev: 'AK'
      }, {
        name: 'Arizona',
        abbrev: 'AZ'
      }, {
        name: 'Arkansas',
        abbrev: 'AR'
      }, {
        name: 'California',
        abbrev: 'CA'
      }, {
        name: 'Colorado',
        abbrev: 'CO'
      }, {
        name: 'Connecticut',
        abbrev: 'CT'
      }, {
        name: 'Delaware',
        abbrev: 'DE'
      }, {
        name: 'District of Columbia',
        abbrev: 'DC'
      }, {
        name: 'Florida',
        abbrev: 'FL'
      }, {
        name: 'Georgia',
        abbrev: 'GA'
      }, {
        name: 'Hawaii',
        abbrev: 'HI'
      }, {
        name: 'Idaho',
        abbrev: 'ID'
      }, {
        name: 'Illinois',
        abbrev: 'IL'
      }, {
        name: 'Indiana',
        abbrev: 'IN'
      }, {
        name: 'Iowa',
        abbrev: 'IA'
      }, {
        name: 'Kansas',
        abbrev: 'KS'
      }, {
        name: 'Kentucky',
        abbrev: 'KY'
      }, {
        name: 'Louisiana',
        abbrev: 'LA'
      }, {
        name: 'Maine',
        abbrev: 'ME'
      }, {
        name: 'Maryland',
        abbrev: 'MD'
      }, {
        name: 'Massachusetts',
        abbrev: 'MA'
      }, {
        name: 'Michigan',
        abbrev: 'MI'
      }, {
        name: 'Minnesota',
        abbrev: 'MN'
      }, {
        name: 'Mississippi',
        abbrev: 'MS'
      }, {
        name: 'Missouri',
        abbrev: 'MO'
      }, {
        name: 'Montana',
        abbrev: 'MT'
      }, {
        name: 'Nebraska',
        abbrev: 'NE'
      }, {
        name: 'Nevada',
        abbrev: 'NV'
      }, {
        name: 'New Hampshire',
        abbrev: 'NH'
      }, {
        name: 'New Jersey',
        abbrev: 'NJ'
      }, {
        name: 'New Mexico',
        abbrev: 'NM'
      }, {
        name: 'New York',
        abbrev: 'NY'
      }, {
        name: 'North Carolina',
        abbrev: 'NC'
      }, {
        name: 'North Dakota',
        abbrev: 'ND'
      }, {
        name: 'Ohio',
        abbrev: 'OH'
      }, {
        name: 'Oklahoma',
        abbrev: 'OK'
      }, {
        name: 'Oregon',
        abbrev: 'OR'
      }, {
        name: 'Pennsylvania',
        abbrev: 'PA'
      }, {
        name: 'Rhode Island',
        abbrev: 'RI'
      }, {
        name: 'South Carolina',
        abbrev: 'SC'
      }, {
        name: 'South Dakota',
        abbrev: 'SD'
      }, {
        name: 'Tennessee',
        abbrev: 'TN'
      }, {
        name: 'Texas',
        abbrev: 'TX'
      }, {
        name: 'Utah',
        abbrev: 'UT'
      }, {
        name: 'Vermont',
        abbrev: 'VT'
      }, {
        name: 'Virginia',
        abbrev: 'VA'
      }, {
        name: 'Washington',
        abbrev: 'WA'
      }, {
        name: 'West Virginia',
        abbrev: 'WV'
      }, {
        name: 'Wisconsin',
        abbrev: 'WI'
      }, {
        name: 'Wyoming',
        abbrev: 'WY'
      }, {
        name: 'American Samoa',
        abbrev: 'AS'
      }, {
        name: 'Guam',
        abbrev: 'GU'
      }, {
        name: 'Northern Mariana Islands',
        abbrev: 'MP'
      }, {
        name: 'Puerto Rico',
        abbrev: 'PR'
      }, {
        name: 'United States Minor Outlying Islands',
        abbrev: 'UM'
      }, {
        name: 'Virgin Islands, U.S.',
        abbrev: 'VI'
      }, {
        name: 'Armed Forces Europe',
        abbrev: 'AE'
      }, {
        name: 'Armed Forces America',
        abbrev: 'AA'
      }, {
        name: 'Armed Forces Pacific',
        abbrev: 'AP'
      }];
    }

  }

  _exports.default = State;
});