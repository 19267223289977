define("dashboard/controllers/apps/index", ["exports", "@ember/object", "@ember/controller", "@ember/service", "dashboard/mixins/preferences", "moment", "dashboard/helpers/array-group-by"], function (_exports, _object, _controller, _service, _preferences, _moment, _arrayGroupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    union,
    sort,
    gt,
    alias,
    and,
    filterBy,
    readOnly,
    equal,
    empty,
    or,
    not
  } = _object.computed;

  var _default = _controller.default.extend(_preferences.default, {
    queryParams: ['filter', 'page', {
      pageSize: 'page-size'
    }],
    page: 1,
    pageSize: 20,
    filter: '',
    totalPages: (0, _object.computed)('filteredResults.length', 'pageSize', function () {
      const total = this.filteredResults.length;
      const pageSize = this.pageSize;
      return Math.ceil(total / pageSize);
    }),
    protectedController: (0, _controller.inject)('protected'),
    current: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    favoritesService: (0, _service.inject)('favorites'),
    title: 'Apps',
    apps: alias('model.apps'),
    teams: alias('model.teams'),
    team: alias('model.team'),
    currentSpace: null,
    notInSpace: empty('currentSpace'),
    spaceName: (0, _object.computed)('currentSpace', function () {
      return this.get('currentSpace.name') || null;
    }),
    favorites: alias('favoritesService.favorites'),
    hasFavorites: alias('favoritesService.hasFavorites'),
    hasFavoritesInFilter: gt('sortedFavorites.length', 0),
    isShowingFavoritesSection: and('hasFavorites', 'hasFavoritesInFilter'),
    appsOnDeprecatedStacks: filterBy('apps', 'showStackDeprecationWarning'),
    appsOnDeprecatedStacksGroupedByStack: (0, _object.computed)('appsOnDeprecatedStacks.@each.stack.name', function () {
      // The groups are sorted by stack name, so that older stacks are listed first.
      return (0, _arrayGroupBy.default)(this.appsOnDeprecatedStacks, 'stack.name').sortBy('value');
    }),
    context: (0, _object.computed)('team', function () {
      return this.team ? 'team' : 'personal';
    }),
    // Apply favorites to apps
    appsWithFavorites: (0, _object.computed)('apps.[]', 'favorites.[]', function () {
      return this.favoritesService.applyAppFavorites(this.apps || []);
    }),
    // All pipelines for the current list of apps
    pipelines: (0, _object.computed)('appsWithFavorites.@each.{isInPipeline,pipelineCoupling}', function () {
      const pipelineApps = this.appsWithFavorites.filterBy('isInPipeline'); // We filter out null values here, because pipeline DELETEs don't actually
      // delete pipeline couplings on the front end (even though the backend does)
      // TODO: Manually remove pipeline couplings on pipeline delete

      return pipelineApps.map(pipelineApp => {
        return pipelineApp.get('pipelineCoupling.pipeline.content');
      }).filter(Boolean);
    }),
    // Apply favorites to pipelines
    pipelinesWithFavorites: (0, _object.computed)('pipelines.[]', 'favorites.[]', function () {
      return this.favoritesService.applyPipelineFavorites(this.pipelines || []);
    }),

    /*
     * An array of pipelines where the pipeline or at least one app within the pipeline is a favorite
     */
    pipelineFavorites: (0, _object.computed)('pipelinesWithFavorites.@each.{isFavorite,hasFavoriteApps}', function () {
      return this.pipelinesWithFavorites.filter(pipeline => {
        return pipeline.get('isFavorite') || pipeline.get('hasFavoriteApps');
      });
    }),

    /*
     * An array of apps which are not in a pipeline and are favorites
     */
    appFavorites: (0, _object.computed)('appsWithFavorites.@each.{isInPipeline,isFavorite}', function () {
      return this.appsWithFavorites.filter(app => {
        return !app.get('isInPipeline') && app.get('isFavorite');
      });
    }),

    /*
     * An array of pipelines that are not favorites and where no app is a favorite
     */
    pipelineNonFavorites: (0, _object.computed)('pipelinesWithFavorites.@each.isNotFavorite', function () {
      return this.pipelinesWithFavorites.filter(pipeline => {
        return pipeline.get('isNotFavorite') && pipeline.get('doesNotHaveFavoriteApps');
      });
    }),

    /*
     * An array of apps which are not in a pipeline and are not a favorite
     */
    appNonFavorites: (0, _object.computed)('appsWithFavorites.@each.{isInPipeline,isFavorite}', function () {
      return this.appsWithFavorites.filter(app => {
        return !app.get('isInPipeline') && !app.get('isFavorite');
      });
    }),
    sorting: ['name:asc'],
    combinedFavorites: union('pipelineFavorites', 'appFavorites'),
    sortedFavorites: sort('combinedFavorites', 'sorting'),
    combinedNonFavorites: union('pipelineNonFavorites', 'appNonFavorites'),
    sortedCombinedNonFavorites: sort('combinedNonFavorites', 'sorting'),
    isNotEnterpriseOrg: readOnly('team.isNotEnterpriseOrg'),
    teamName: (0, _object.computed)('team.name', function () {
      // this fctn exists because `params=organization.name`
      // renders out `"undefined"` rather than nothing :\
      // which yields link-to: `/new?org=undefined`
      return this.get('team.name') || 'personal-apps';
    }),
    isPersonalApps: empty('team.id'),
    hasNoTeam: empty('team'),
    isNotTeam: or('hasNoTeam', 'isPersonalApps'),
    isTeam: not('isNotTeam'),
    isAdminRole: equal('team.role', 'admin'),
    teamIsRecent: (0, _object.computed)('team.createdAt', function () {
      const createdAt = this.get('team.createdAt');
      const aMonthAgo = (0, _moment.default)().subtract(1, 'month');
      return (0, _moment.default)(createdAt).isAfter(aMonthAgo);
    }),
    personalAccountIsRecent: (0, _object.computed)('current.account.createdAt', function () {
      const createdAt = this.get('current.account.createdAt');
      const aMonthAgo = (0, _moment.default)().subtract(1, 'month');
      return (0, _moment.default)(createdAt).isAfter(aMonthAgo);
    }),
    teamFirstRunDismissed: (0, _object.computed)('team.id', 'dashboardUserPreferences.teamFirstRunDismissals.[]', function () {
      const teamId = this.get('team.id');
      const dismissals = this.get('dashboardUserPreferences.teamFirstRunDismissals');
      return dismissals.includes(teamId);
    }),
    personalFirstRunDismissed: alias('dashboardUserPreferences.hasDismissedPersonalFirstRun'),
    teamFirstRunNotDismissed: not('teamFirstRunDismissed'),
    personalFirstRunNotDismissed: not('personalFirstRunDismissed'),
    shouldShowPersonalFirstRun: and('isPersonalApps', 'notInSpace', 'personalFirstRunNotDismissed', 'personalAccountIsRecent'),
    shouldShowTeamFirstRun: and('isTeam', 'isAdminRole', 'notInSpace', 'teamFirstRunNotDismissed', 'teamIsRecent'),

    setFilter(value) {
      this.set('filter', value);
      this.set('page', 1);
    },

    allItems: (0, _object.computed)('sortedFavorites.[]', 'sortedCombinedNonFavorites.[]', function () {
      return [...this.sortedFavorites, ...this.sortedCombinedNonFavorites];
    }),
    filteredResults: (0, _object.computed)('filter', 'allItems.@each.name', function () {
      const filter = (this.filter || '').toLowerCase();
      return this.fuzzyFilterOn(this.allItems, 'name', filter);
    }),
    results: (0, _object.computed)('page', 'pageSize', 'filteredResults.@each.name', function () {
      const startIndex = (this.page - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      const result = this.filteredResults.slice(startIndex, endIndex);
      return result;
    }),
    actions: {
      dismissTeamFirstRun() {
        this.analytics.logEvent('Team First Run', 'Dismissed');
        const teamId = this.get('team.id');
        const userPrefs = this.dashboardUserPreferences;
        const maxSize = 100;
        let dismissals = userPrefs.teamFirstRunDismissals;
        dismissals.pushObject(teamId);

        if (dismissals.length > maxSize) {
          dismissals = dismissals.slice(dismissals.length - maxSize);
        }

        userPrefs.set('teamFirstRunDismissals', dismissals);
        userPrefs.save();
      },

      dismissPersonalFirstRun() {
        this.analytics.logEvent('Personal First Run', 'Dismissed');
        const userPrefs = this.dashboardUserPreferences;
        userPrefs.set('hasDismissedPersonalFirstRun', true);
        userPrefs.save();
      }

    },

    fuzzyFilterOn(array, key, filterText) {
      filterText = filterText.replace(/[^a-z0-9-]+/gi, '');

      if (filterText.length === 0) {
        return array;
      }

      const filter = filterText.split('').join('.*');
      const regex = new RegExp(filter, 'i');
      return array.filter(item => regex.test(item.get(key)));
    }

  });

  _exports.default = _default;
});