define("dashboard/components/pie-chart", ["exports", "@ember/component", "@ember/object", "@ember/string"], function (_exports, _component, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    style: (0, _object.computed)('percent', function () {
      return (0, _string.htmlSafe)(`stroke-dasharray: ${this.percent} 100`);
    })
  });

  _exports.default = _default;
});