define("dashboard/components/app/deploy/github/wait-for-ci-toggle", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['pt2'],
    isSavingWaitForCi: false,
    actions: {
      toggleWaitForCi() {
        const githubAppLink = this.githubAppLink;
        githubAppLink.toggleProperty('waitForCi');
        this.set('isSavingWaitForCi', true);
        githubAppLink.save().then(() => this.set('isSavingWaitForCi', false));
      }

    }
  });

  _exports.default = _default;
});