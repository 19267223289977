define("dashboard/templates/components/metrics/charts/row-chart-elements/indicator-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vTD9oIK/",
    "block": "[[[10,\"g\"],[14,0,\"row-content\"],[14,\"transform\",\"translate(0, 5)\"],[12],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"indicators\"]]],null]],null],null,[[[1,\"    \"],[46,[30,0,[\"indicatorComponent\"]],null,[[\"time\",\"value\",\"models\",\"icon\",\"level\",\"timeScale\",\"rowWidth\",\"minTime\",\"maxTime\",\"rollupDuration\"],[[30,1,[\"time\"]],[30,1,[\"count\"]],[30,1,[\"models\"]],[30,0,[\"indicatorIcon\"]],[30,0,[\"level\"]],[30,0,[\"timeScale\"]],[30,0,[\"width\"]],[30,0,[\"minTime\"]],[30,0,[\"maxTime\"]],[30,0,[\"rollupDuration\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n  \"],[11,3],[24,0,\"hk-link metrics__graph-row__label\"],[16,6,[30,0,[\"url\"]]],[4,[38,3],[[30,0],\"trackAndGoToDevCenterUrl\"],null],[12],[1,\"\\n    \"],[10,\"text\"],[14,\"x\",\"-5\"],[14,\"y\",\"6\"],[14,\"text-anchor\",\"end\"],[12],[1,\"\\n      \"],[1,[30,0,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"g\"],[14,0,\"metrics__graph-row__explanation\"],[12],[1,\"\\n    \"],[10,\"text\"],[14,0,\"knockout\"],[14,\"y\",\"6\"],[12],[1,\"\\n      \"],[1,[30,0,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"text\"],[14,0,\"errorLabel\"],[14,\"y\",\"6\"],[12],[1,\"\\n      \"],[1,[30,0,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\\n\"],[10,\"line\"],[14,0,\"metrics__graph-row__separator\"],[15,\"y1\",[29,[[30,0,[\"height\"]]]]],[15,\"y2\",[29,[[30,0,[\"height\"]]]]],[14,\"x1\",\"0\"],[15,\"x2\",[29,[[30,0,[\"width\"]]]]],[12],[13],[1,\"\\n\"]],[\"indicator\"],false,[\"each\",\"-track-array\",\"component\",\"action\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/row-chart-elements/indicator-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});