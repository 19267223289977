define("dashboard/components/copyable-input", ["exports", "@ember/component/text-field", "@ember/service", "@ember/runloop", "@ember/utils", "jquery"], function (_exports, _textField, _service, _runloop, _utils, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    useDefaultClassNames: true,
    classNameBindings: ['useDefaultClassNames:form-control'],
    attributeBindings: ['data-test-target'],
    readonly: true,
    analyticsEvent: '',
    analyticsCategory: '',
    analytics: (0, _service.inject)(),

    focusIn() {
      // This needs to run a bit later in order to work in safari:
      // https://stackoverflow.com/a/6201757
      _runloop.run.next(() => {
        if (this.element === document.activeElement) {
          (0, _jquery.default)(this.element).select();
        }
      });

      const event = this.analyticsEvent;
      const category = this.analyticsCategory;

      if ((0, _utils.isPresent)(event) && (0, _utils.isPresent)(category)) {
        this.analytics.logEvent('Copyable Input', 'Selected', {
          category,
          event
        });
      }
    }

  });

  _exports.default = _default;
});