define("dashboard/templates/components/account/bounce-notice", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "H97+PN0E",
    "block": "[[[41,[30,0,[\"bounces\",\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"flex-ns br1 bg-lightest-red red ba b--light-red measure pa2 mb6\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"warning-ring-28\",\"fill-red flex-shrink-0 mr2\",28,\"Warning\"]],null],[1,\"\\n    \"],[10,0],[14,0,\"mr2-ns\"],[12],[1,\"\\n      \"],[10,\"h5\"],[14,0,\"f4 ma0 red lh-copy\"],[12],[1,\"We can’t send email to this address\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"f5 lh-copy mb2\"],[12],[1,\"Heroku isn’t sending email to \"],[10,\"b\"],[12],[1,[30,0,[\"email\"]]],[13],[1,\" because a previous\\n        email attempt bounced. We can reattempt sending to this email or\\n        you can change your email address.\"],[13],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"default\",\"onClick\",\"buttonType\",\"class\"],[[28,[37,3],[\"Reattempt sending to \",[30,0,[\"email\"]]],null],[28,[37,4],[[30,0,[\"removeBounces\"]]],null],\"secondary\",\"w-100 truncate\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-icon\",\"hk-button\",\"concat\",\"perform\"]]",
    "moduleName": "dashboard/templates/components/account/bounce-notice.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});