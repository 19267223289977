define("dashboard/components/team/member-panel/confirm-delete", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    onCancel: () => {},
    onConfirm: () => {}
  });

  _exports.default = _default;
});