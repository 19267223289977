define("dashboard/components/team/usage/export-usage", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Element
    tagName: '',
    // --------------------------------------------------------------------------
    // Services
    current: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Arguments
    team: null,
    // --------------------------------------------------------------------------
    // Computed Props
    enterpriseAccount: (0, _computed.readOnly)('team.enterprise'),
    canExportUsage: (0, _computed.or)('team.isAdmin', 'current.enterprisePermissions.canViewUsage'),
    teamUsageExportDescription: (0, _object.computed)(function () {
      return `Download usage data for this <strong>${this.get('team.name')}</strong> team.`;
    })
  });

  _exports.default = _default;
});