define("dashboard/adapters/pipeline-services-config", ["exports", "dashboard/adapters/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.kolkrabbiUrl,

    handleResponse(status, headers, payload, requestData) {
      // 404 for this is normal.
      // This override prevents ember-data from raising an error in the console and
      // failing the test suite.
      if (status === 404) {
        status = 204;
        payload = null;
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const pipelineId = snapshot.belongsTo('pipeline', {
        id: true
      });
      return `${this.host}/pipelines/${pipelineId}/repository`;
    },

    urlForQueryRecord(query) {
      return `${this.host}/pipelines/${query.pipelineId}/repository`;
    }

  });

  _exports.default = _default;
});