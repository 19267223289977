define("dashboard/routes/route", ["@ember/routing/route", "@ember/service"], function (_route, _service) {
  "use strict";

  _route.default.reopen({
    current: (0, _service.inject)(),

    beforeModel(transition) {
      this._super.apply(this, arguments);

      this.checkDelinquent(transition);
    },

    checkDelinquent(transition) {
      const isDelinquent = this.get('current.account.isDelinquent');
      const ignoreDelinquent = this.ignoreDelinquent;

      if (isDelinquent && !ignoreDelinquent && transition) {
        transition.abort();
        this.transitionTo('account.billing.pay-balance');
      }
    }

  });
});