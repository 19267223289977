define("dashboard/serializers/config-vars", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      const configVarsHash = {};
      configVarsHash['variables'] = resourceHash;
      configVarsHash['id'] = resourceHash.id;
      delete resourceHash.id;
      return this._super(modelClass, configVarsHash);
    }

  });

  _exports.default = _default;
});