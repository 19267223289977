define("dashboard/templates/components/pipeline/pr-details-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VV4h7mgS",
    "block": "[[[18,1,[[28,[37,1],null,[[\"avatar\",\"link\",\"title\",\"username\"],[[50,\"gravatar-icon\",0,null,[[\"src\",\"size\",\"alt\",\"title\"],[[30,0,[\"user\",\"avatarUrl\"]],16,[28,[37,3],[[30,0,[\"user\",\"email\"]],[30,0,[\"user\",\"username\"]]],null],[28,[37,3],[[30,0,[\"user\",\"email\"]],[30,0,[\"user\",\"username\"]]],null]]]],[50,\"target-blank-link\",0,null,[[\"href\",\"text\",\"class\",\"title\"],[[30,0,[\"pullRequest\",\"htmlUrl\"]],[28,[37,4],[\"#\",[30,0,[\"pullRequest\",\"number\"]]],null],\"hk-link\",[30,0,[\"linkTitleAttribute\"]]]]],[30,0,[\"pullRequest\",\"title\"]],[30,0,[\"user\",\"username\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"or\",\"concat\"]]",
    "moduleName": "dashboard/templates/components/pipeline/pr-details-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});