define("dashboard/templates/components/third-party-authorizations-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RMXFN2Pu",
    "block": "[[[11,\"tr\"],[24,0,\"bt b--silver\"],[17,1],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"party-icon\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\"],[\"service-github-28\",36]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[10,2],[14,0,\"pt2\"],[12],[1,\"\\n      \"],[10,\"b\"],[12],[1,\"GitHub App\"],[13],[1,\"\\n      Connected as \\n      \"],[10,3],[15,6,[30,2,[\"htmlUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link underline\"],[12],[1,\"\\n        @\"],[1,[30,2,[\"login\"]]],[1,\"\\n      \"],[13],[1,\"\\n      (\"],[1,[30,2,[\"name\"]]],[1,\") for\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"githubAppInstallations\"]]],null]],null],null,[[[1,\"        \"],[10,3],[15,6,[29,[\"https://github.com/\",[30,3,[\"github_account_name\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link underline\"],[12],[1,\"\\n          \"],[1,[30,3,[\"github_account_name\"]]],[1,\"\\n        \"],[13],[41,[51,[28,[37,4],[[33,5],[30,4]],null]],[[[1,\",\"]],[]],null],[1,\"\\n\"]],[3,4]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@authorizedUser\",\"installation\",\"index\"],false,[\"malibu-icon\",\"each\",\"-track-array\",\"unless\",\"eq\",\"lastElementIndex\"]]",
    "moduleName": "dashboard/templates/components/third-party-authorizations-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});