define("dashboard/controllers/app/app-json", ["exports", "@ember/utils", "@ember/controller", "@ember/object/computed"], function (_exports, _utils, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['autodeploy', 'app_name', 'return_to'],
    autodeploy: true,
    app_name: null,
    return_to: null,
    githubAppLink: (0, _computed.readOnly)('model.app.githubAppLink.content'),

    enablePRDeploys() {
      this.set('githubAppLink.pullRequests.auto_deploy', true);
      return this.githubAppLink.save();
    },

    actions: {
      saveManifestAndTransition(attributes) {
        attributes.app = this.get('model.app');
        const appManifest = this.store.createRecord('repositories-api-app-manifest', attributes);
        return appManifest.save().then(result => {
          return this.autodeploy ? this.enablePRDeploys() : result;
        }).then(() => {
          const path = this.return_to;

          if ((0, _utils.isPresent)(path)) {
            this.transitionToRoute(path);
          } else {
            this.transitionToRoute('app.deploy.github', this.get('model.app.name'));
          }
        });
      }

    }
  });

  _exports.default = _default;
});