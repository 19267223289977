define("dashboard/templates/components/scheduler/form-block", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oYoZqg0r",
    "block": "[[[10,0],[14,0,\"bb b--silver pa2 near-black\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"icon\"]],\"malibu-fill-gradient-purple nudge-up--2 mr1\",16]],null],[1,\"\\n  \"],[1,[30,0,[\"name\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"pa2 gray\"],[12],[1,\"\\n  \"],[1,[30,0,[\"description\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"ph2 pb2\"],[12],[1,\"\\n  \"],[46,[30,0,[\"formComponentName\"]],null,[[\"app\",\"job\"],[[30,0,[\"app\"]],[30,0,[\"job\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"component\"]]",
    "moduleName": "dashboard/templates/components/scheduler/form-block.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});