define("dashboard/components/pipeline/app-list-favorite", ["exports", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['hk-hide-bb-last-row', 'mb2'],
    current: (0, _service.inject)(),
    apps: (0, _computed.readOnly)('pipeline.apps'),
    appsSorting: ['name'],
    // We call a function on the app model, and PromiseObjects don't like that
    // so we pull the actual app model out of the PromiseObject
    appsContent: (0, _computed.mapBy)('apps', 'content'),
    filteredApps: (0, _computed.filter)('appsContent', function (app) {
      const favoriteOrProduction = app.get('isFavorite') || app.get('pipelineCoupling.isProduction');
      const spaceName = this.spaceName;
      const isOwner = app.get('ownerId') === this.get('current.accountId');
      const isAdmin = app.get('team.isAdmin');
      const isJoined = app.get('joined');
      const hasPermission = isOwner || isAdmin || isJoined;

      if (!hasPermission) {
        return false;
      }

      if (spaceName) {
        const isSpaceApp = app.get('spaceName') === spaceName;
        return isSpaceApp && favoriteOrProduction;
      } else {
        return favoriteOrProduction;
      }
    }),
    appsToDisplay: (0, _computed.sort)('filteredApps', 'appsSorting')
  });

  _exports.default = _default;
});