define("dashboard/templates/components/identity-provider/certificate-expiry-date", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "toRS7Dke",
    "block": "[[[1,[30,0,[\"expireLabel\"]]],[1,\" \"],[10,\"time\"],[15,\"datetime\",[29,[[28,[37,0],[[30,0,[\"expiresAt\"]]],null]]]],[12],[1,[28,[35,0],[[30,0,[\"expiresAt\"]],\"MMMM DD, YYYY\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"includeFromNow\"]],[[[1,\"  (\"],[1,[30,0,[\"fromNow\"]]],[1,\")\\n\"]],[]],null]],[],false,[\"moment-format\",\"if\"]]",
    "moduleName": "dashboard/templates/components/identity-provider/certificate-expiry-date.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});