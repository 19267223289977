define("dashboard/mixins/validating-model", ["exports", "@ember/object", "@ember/object/mixin", "@ember/object/computed", "@ember/utils", "ember"], function (_exports, _object, _mixin, _computed, _utils, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    requiredProperties: [],

    init() {
      this._super(...arguments);
      /**
       * A list of the properties that are missing
       *
       * @property missingProperties
       * @type {Array}
       */


      this._defineMissingProperties();
    },

    isMissingProperty: (0, _computed.notEmpty)('missingProperties'),
    isNotMissingProperty: (0, _computed.not)('isMissingProperty'),
    isNotDuplicated: (0, _computed.not)('isDuplicated'),
    isValid: (0, _computed.and)('isNotMissingProperty', 'isNotDuplicated'),
    isNotValid: (0, _computed.not)('isValid'),

    getMissingProperties() {
      return this.requiredProperties.filter(function (prop) {
        return (0, _utils.isBlank)(this.get(prop));
      }.bind(this));
    },

    _defineMissingProperties() {
      const args = this.requiredProperties.concat(this.getMissingProperties);

      const missingProperties = _object.computed.apply(_ember.default, args);

      (0, _object.defineProperty)(this, 'missingProperties', missingProperties);
    }

  });

  _exports.default = _default;
});