define("dashboard/templates/components/route-outlets/team/spaces/usage-alert", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/nU1gV7Z",
    "block": "[[[1,[28,[35,0],null,[[\"alertType\",\"classNames\",\"message\"],[\"warning\",\"text-center alert--full-width alert--above-footer\",\"For more information on Heroku Private Spaces, see the <a href='https://devcenter.heroku.com/articles/private-spaces' target='_blank'>Dev Center Article</a> and the <a href='https://elements.heroku.com/addons/heroku-private-spaces' target='_blank'>Add-ons Catalog entry</a>.\"]]]],[1,\"\\n\"]],[],false,[\"flash-status\"]]",
    "moduleName": "dashboard/templates/components/route-outlets/team/spaces/usage-alert.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});