define("dashboard/adapters/space/inbound-ruleset", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    updateMethod: 'PUT',

    urlForFindRecord(_id, _modelName, snapshot) {
      return this.urlForRecord(snapshot);
    },

    urlForUpdateRecord(_id, _modelName, snapshot) {
      return this.urlForRecord(snapshot);
    },

    urlForRecord(snapshot) {
      const spaceId = snapshot.record.get('space.id');
      return `${this.host}/spaces/${spaceId}/inbound-ruleset`;
    }

  });

  _exports.default = _default;
});