define("dashboard/components/app/web-hook-deliveries/list-item", ["exports", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    analytics: (0, _service.inject)(),
    canExpandRow: (0, _computed.notEmpty)('delivery.lastAttempt.status'),
    actions: {
      toggleExpandDelivery() {
        if (this.canExpandRow) {
          this.toggleProperty('expandDelivery');
          this.analytics.logEvent('Webhook Delivery', 'Toggled', {
            opened: this.expandDelivery
          });
        }
      }

    }
  });

  _exports.default = _default;
});