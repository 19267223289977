define("dashboard/templates/components/metrics/chart-controls", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GXDhB0qm",
    "block": "[[[1,[28,[35,0],null,[[\"app\",\"formations\",\"type\"],[[30,0,[\"app\"]],[30,0,[\"app\",\"formations\"]],[30,0,[\"currentFormation\",\"type\"]]]]]],[1,\"\\n\\n\"],[10,0],[14,\"role\",\"group\"],[14,0,\"hk-button-group flex mv2\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"app\",\"updateTimeframe\",\"beforeHours\",\"afterHours\",\"process\"],[[30,0,[\"app\"]],[30,0,[\"updateTimeframe\"]],[30,0,[\"beforeHours\"]],[30,0,[\"afterHours\"]],[30,0,[\"currentFormation\"]]]]]],[1,\"\\n\\n  \"],[8,[39,2],[[24,0,\"metrics__view-controls__button inline-flex items-center btn btn-default flex\"],[4,[38,3],[[30,0],[28,[37,4],[[30,0,[\"showingSlidePanel\"]]],null],true],null]],[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"configure-16\",\"malibu-fill-gradient-purple\",16,\"Show Metrics Preferences\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"showingSlidePanel\"]],[[[1,\"    \"],[1,[28,[35,6],null,[[\"app\",\"chartLayout\",\"throughputResolution\",\"isAutoRefreshing\",\"isShowingSetupWarning\",\"timezone\",\"toggleLayoutConfig\",\"toggleAutoRefresh\",\"toggleThroughputResolution\",\"updateTimezone\",\"close\"],[[30,0,[\"app\"]],[30,0,[\"chartLayout\"]],[30,0,[\"throughputResolution\"]],[30,0,[\"isAutoRefreshing\"]],[30,0,[\"isShowingSetupWarning\"]],[30,0,[\"timezone\"]],[30,0,[\"toggleLayoutConfig\"]],[30,0,[\"toggleAutoRefresh\"]],[30,0,[\"toggleThroughputResolution\"]],[30,0,[\"updateTimezone\"]],[28,[37,3],[[30,0],\"closePanel\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"metrics/chart-controls/process-switcher\",\"metrics/chart-controls/timeframe-selector\",\"malibu-icon-button\",\"action\",\"mut\",\"if\",\"metrics/chart-controls/preferences\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});