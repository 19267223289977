define("dashboard/templates/components/addon-action-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3Niin+xt",
    "block": "[[[11,3],[16,6,[30,0,[\"url\"]]],[24,0,\"btn-link addon-action-link red\"],[16,6,[30,0,[\"url\"]]],[4,[38,0],[[30,0],\"visitLink\"],null],[12],[1,[30,0,[\"label\"]]],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "dashboard/templates/components/addon-action-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});