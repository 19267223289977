define("dashboard/components/enterprise-usage/license-details", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/helpers/currency-format", "numeral"], function (_exports, _component, _object, _computed, _currencyFormat, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    isCurrency: (0, _computed.bool)('license.isCurrency'),
    quota: (0, _computed.readOnly)('license.quota'),
    label: (0, _computed.readOnly)('license.label'),
    value: (0, _object.computed)('quota', 'isCurrency', function () {
      if (this.isCurrency) {
        return (0, _currencyFormat.currency)(this.quota, {
          unit: 'cents'
        });
      } else {
        return (0, _numeral.default)(this.quota).format('0,0');
      }
    })
  });

  _exports.default = _default;
});