define("dashboard/components/metrics/charts/visualizations/memory-paging", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/aggregate-chart"], function (_exports, _computed, _aggregateChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aggregateChart.default.extend({
    tagName: 'metrics-memory-paging-chart',
    title: 'Swap Activity',
    devCenterName: 'metrics',
    timeSeriesName: 'Pgin',
    timeSeriesNameNegated: 'Pgout',
    maxData: (0, _computed.alias)('data.pagesInMax'),
    avgData: (0, _computed.alias)('data.pagesInMean'),
    maxDataNegated: (0, _computed.alias)('data.pagesOutMax'),
    avgDataNegated: (0, _computed.alias)('data.pagesOutMean')
  });

  _exports.default = _default;
});