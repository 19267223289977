define("dashboard/components/pipeline/new-form", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _component, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    bool,
    filterBy,
    notEmpty,
    or,
    not,
    equal
  } = _object.computed;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    account: alias('current.account'),
    hasGitHubRepository: bool('githubRepository'),
    hasGithubUser: bool('githubUser'),
    couplingsBeingSaved: filterBy('pipeline.couplings', 'isSaving', true),
    isSavingAnyCoupling: notEmpty('couplingsBeingSaved'),
    isSavingRepository: (0, _object.computed)('pipeline.githubRepository.isSaving', 'pipeline.connectedRepository.isSaving', 'ownerHasGithubAppEnabled', function () {
      const repoKey = this.get('ownerHasGithubAppEnabled') ? 'connectedRepository' : 'githubRepository';
      return this.get(`pipeline.${repoKey}.isSaving`);
    }),
    pipelineInfoDescription: (0, _object.computed)(function () {
      return 'Choose a name for your pipeline.';
    }),
    createButtonDisabled: or('pipeline.isSaving', 'isSavingAnyCoupling', 'isSavingRepository', 'pipeline.nameIsInvalid', 'pipeline.nameIsEmpty'),
    nameIsInvalid: or('pipeline.nameIsInvalid', 'pipeline.nameIsEmpty'),
    nameIsValid: not('nameIsInvalid'),
    availableStages: (0, _object.computed)(function () {
      return [{
        name: 'development'
      }, {
        name: 'staging'
      }, {
        name: 'production'
      }];
    }),
    pipeline: (0, _object.computed)(function () {
      return this.store.createRecord('pipeline');
    }),
    inputClass: (0, _object.computed)('nameIsValid', 'pipeline.nameIsInvalid', function () {
      if (this.get('pipeline.nameIsInvalid')) {
        return 'hk-input w-100 br--left z-1 b--red hk-focus-ring--red';
      } else if (this.nameIsValid) {
        return 'hk-input w-100 br--left z-1 b--green hk-focus-ring--green';
      } else {
        return 'hk-input w-100';
      }
    }),
    userName: (0, _object.computed)('account', function () {
      const accountName = this.get('account.name');
      const accountEmail = this.get('account.email');
      return (0, _utils.isPresent)(accountName) ? `${accountName} (${accountEmail})` : accountEmail;
    }),
    defaultOrg: (0, _object.computed)(function () {
      const userPreferences = this.store.peekRecord('user-preferences', '~');
      return userPreferences.get('defaultOrganization');
    }),
    ownerId: (0, _object.computed)('defaultOrg', 'selectableOrgs', 'selectedOrgParam', 'account', function () {
      let defaultOwner = this.selectedOrgParam || this.defaultOrg;
      defaultOwner = this.selectableOrgs.filterBy('name', defaultOwner)[0] || this.account;
      return defaultOwner.get('id');
    }),
    pipelineOwner: (0, _object.computed)('ownerId', 'selectableOrgs.[]', function () {
      return this.selectableOrgs.filterBy('id', this.ownerId)[0];
    }),
    // This makes the assumption that the only "user" could be assigned ownership to
    // is the own personal account. This will need to change if we eventually let
    // users create pipelines in behalf other personal accounts.
    ownerType: (0, _object.computed)('ownerId', 'account', function () {
      return this.ownerId === this.get('account.id') ? 'user' : 'team';
    }),
    selectableOrgs: (0, _object.computed)('orgs.@each.role', 'orgs.@each.name', function () {
      if ((0, _utils.isEmpty)(this.orgs)) {
        return [];
      }

      return this.orgs.filter(org => {
        const role = org.get('role');
        return role === 'member' || role === 'admin';
      }).uniqBy('name');
    }),
    ownerHasGithubAppEnabled: alias('pipelineOwner.isGithubAppEnabled'),
    isAutoAddingMembers: equal('ownerType', 'team'),
    isNotAutoAddingMembers: not('isAutoAddingMembers'),
    autoAddMemberPermissions: (0, _object.computed)(function () {
      return [{
        name: 'view',
        value: true,
        title: 'Users can see pull request, CI tests, and can open Review Apps'
      }, {
        name: 'deploy',
        value: true,
        title: 'Users can create and delete Review Apps, edit config vars and see build info'
      }, {
        name: 'operate',
        value: true,
        title: 'Users can create and delete Review Apps, create CI apps, and edit config vars'
      }, {
        name: 'manage',
        value: false,
        title: 'Users can enable CI and Review Apps, and create CI apps and Review Apps'
      }];
    }),
    formattedAutoAddMemberPermissions: (0, _object.computed)('autoAddMemberPermissions.@each.value', 'pipelineOwner.isEnterpriseTeam', function () {
      if (this.pipelineOwner && this.pipelineOwner.get('isEnterpriseTeam')) {
        return this.autoAddMemberPermissions.filterBy('value').map(permission => {
          return permission.name;
        });
      } else {
        return [];
      }
    }),

    willDestroy() {
      const pipeline = this.pipeline;

      if (pipeline.get('isNew')) {
        pipeline.unloadRecord();
      }

      this._super(...arguments);
    },

    actions: {
      submit(ownerId, ownerType) {
        const pipeline = this.pipeline;

        if (ownerId && ownerType) {
          pipeline.set('owner', {
            id: ownerId,
            type: ownerType
          });
        }

        pipeline.set('collaboratorSync', this.isAutoAddingMembers);
        pipeline.set('collaboratorsEnabled', true);

        if (this.isAutoAddingMembers) {
          pipeline.set('collaboratorPermissions', this.formattedAutoAddMemberPermissions);
        }

        const githubRepository = this.ownerHasGithubAppEnabled ? pipeline.get('connectedRepository.content') : this.githubRepository;
        this.onSubmit(pipeline, githubRepository, this.ownerHasGithubAppEnabled);
      },

      onGithubAuth() {
        this.onGithubAuth();
      },

      onRepoLink(repository) {
        if (this.ownerHasGithubAppEnabled) {
          this.pipeline.set('connectedRepository', repository);
        } else {
          this.set('githubRepository', repository);
        }
      },

      onRepoUnlink(ownerId) {
        this.ownerId = ownerId;

        if (this.ownerHasGithubAppEnabled && this.get('pipeline.connectedRepository.content')) {
          const repo = this.get('pipeline.connectedRepository');
          repo.set('disconnectedResourceId', this.get('pipeline.id'));
          repo.set('pipeline', null);
          this.analytics.logEvent('Github Apps Pipeline', 'Disconnected', {
            pipelineId: this.get('pipeline.id')
          });
          return repo.content.save({
            adapterOptions: {
              linkedResourceType: 'pipeline'
            }
          });
        } else {
          this.set('githubRepository', null);
        }
      },

      toggleAutoAddingMembers() {
        this.set('pipeline.ephemeralCollaboratorSync', !this.isAutoAddingMembers);
      }

    }
  });

  _exports.default = _default;
});