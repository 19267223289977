define("dashboard/services/web-socket", ["exports", "dashboard/utils/localstorage", "@ember/service", "@ember/object"], function (_exports, _localstorage, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.backoffInterval = backoffInterval;
  _exports.reconnectRetryRange = _exports.default = void 0;

  var _default = _service.default.extend({
    session: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    _phoenixAdapter: (0, _service.inject)('phoenix-adapter'),
    _socket: (0, _object.computed)('accountFeatures.all', function () {
      return this._phoenixAdapter;
    }),
    _socketParams: (0, _object.computed)('session.bearerToken', 'session.isAssumed', 'session.sudoData', function () {
      const params = {
        token: this.session.bearerToken,
        client_tab_id: getTabID()
      };

      if (this.session.isAssumed) {
        const sudoData = this.session.sudoData;
        params.token = sudoData.sudoToken;
        params.heroku_sudo = true;
        params.heroku_sudo_reason = sudoData.sudoReason;
        params.heroku_sudo_user = sudoData.user_id;
      }

      return params;
    }),

    connect() {
      this._socket.connect({
        reconnectAfterMs: tries => backoffInterval(tries, reconnectRetryRange),
        // using a function so if properties update in the service
        // they get updated in the phoenix adapter
        params: () => {
          return this.isDestroyed ? {} : this._socketParams;
        }
      });
    },

    disconnect() {
      this._socket.disconnect();
    },

    join(room, params) {
      if (!room) {
        return;
      }

      this.connect();
      return this._socket.join(room, params);
    }

  }); // generate a per-tab ID so we can check if the user has
  // multiple tabs open
  // https://github.com/heroku/particleboard/blob/cc5cf78b630c5c3582959aa6535fe5bab7328772/lib/particleboard_web/channels/user_socket.ex#L40
  // By using sessionStorage, this will be unique to each tab/window.


  _exports.default = _default;

  function getTabID() {
    const TAB_ID_KEY = 'user-socket-tab-id';

    if (!_localstorage.default.getSessionItem(TAB_ID_KEY)) {
      const ID = generateID(36);
      const tabID = `dashboard-${ID}`;

      _localstorage.default.setSessionItem(TAB_ID_KEY, tabID);
    }

    return _localstorage.default.getSessionItem(TAB_ID_KEY);
  } // dec2hex :: Integer -> String
  // i.e. 0-255 -> '00'-'ff'


  function dec2hex(dec) {
    return dec < 10 ? `0${String(dec)}` : dec.toString(16);
  } // generateId :: Integer -> String


  function generateID(len) {
    const crypto = window.crypto || window.msCrypto;
    const arr = new Uint8Array((len || 40) / 2);
    crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
  }

  function randomBetween(left, right) {
    const min = Math.min(left, right);
    const max = Math.max(left, right);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const reconnectRetryRange = [10, 50, 100, 500, 1000, 2000, 4000, 6000, 8000, 10000, 15000, 30000];
  _exports.reconnectRetryRange = reconnectRetryRange;

  function backoffInterval(tries, range) {
    const prev = range[(tries - 1) % range.length];
    const next = range[tries % range.length];
    return randomBetween(prev, next);
  }
});