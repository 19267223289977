define("dashboard/models/repositories-api-github-oauth-token", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    account: (0, _model.belongsTo)('account'),
    repositoriesApiGithubUser: (0, _model.belongsTo)('repositories-api-github-user'),
    githubUserId: (0, _model.attr)('number'),
    code: (0, _model.attr)('string'),
    hasToken: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});