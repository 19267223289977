define("dashboard/serializers/team/license", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.injectPeriodIntoGroupedLicenses = injectPeriodIntoGroupedLicenses;
  _exports.injectTeamId = injectTeamId;
  _exports.injectTeamIdIntoGroupedLicenses = injectTeamIdIntoGroupedLicenses;
  _exports.injectTeamIdIntoPayload = injectTeamIdIntoPayload;
  _exports.keyForLicense = keyForLicense;
  _exports.massageGroupedLicenses = massageGroupedLicenses;
  _exports.normalizeLicenseCollections = normalizeLicenseCollections;

  var _default = _application.default.extend({
    extractId(modelClass, payload) {
      return keyForLicense(payload);
    },

    normalizeArrayResponse(store, primaryModelClass, payload) {
      if (payload && payload[0] && payload[0].licenses) {
        payload = normalizeLicenseCollections(payload);
      }

      return this._super(store, primaryModelClass, payload);
    }

  });

  _exports.default = _default;

  function normalizeLicenseCollections(payload) {
    return payload.mapBy('licenses').reduce((a, b) => a.concat(b));
  }

  function injectTeamId(payload, teamId) {
    return Object.assign({}, payload, {
      team: teamId
    });
  }

  function injectTeamIdIntoPayload(payload, teamId) {
    return payload.map(object => injectTeamId(object, teamId));
  }

  function injectTeamIdIntoGroupedLicenses(groups, teamId) {
    return groups.map(group => {
      const licenses = injectTeamIdIntoPayload(group.licenses, teamId);
      return Object.assign({}, group, {
        licenses
      });
    });
  }

  function injectPeriodIntoGroupedLicenses(groups) {
    return groups.map(group => {
      const {
        period
      } = group;
      const licenses = group.licenses.map(license => Object.assign({}, license, {
        period
      }));
      return Object.assign({}, group, {
        licenses
      });
    });
  }

  function massageGroupedLicenses(groups, teamId) {
    groups = injectTeamIdIntoGroupedLicenses(groups, teamId);
    groups = injectPeriodIntoGroupedLicenses(groups);
    return groups;
  }

  const KEY_PROPS = ['team', 'code', 'qty', 'consumed', 'start_date', 'end_date', 'period'];

  function keyForLicense(license) {
    return KEY_PROPS.map(prop => license[prop]).filter(_ => _).join('-');
  }
});