define("dashboard/controllers/team/switchable/settings", ["exports", "rsvp", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/runloop", "dashboard/models/role", "moment"], function (_exports, _rsvp, _controller, _object, _computed, _service, _runloop, _role, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: {
      isConfiguringSSO: 'sso-setup'
    },
    accountFeatures: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    title: 'Settings',
    team: (0, _computed.alias)('model.team'),
    teamPermissions: (0, _computed.readOnly)('current.teamPermissions'),
    // TODO: eventually most of these should use `canView...` instead of `canManage...`
    // but first we'll need to create read-only views for each thing. Currently we
    // only have UI for the fully editable versions of things.
    showTeamRename: (0, _computed.bool)('teamPermissions.canManageTeam'),
    showDeleteTeam: (0, _computed.bool)('teamPermissions.canManageTeam'),
    showIdentityProvider: (0, _computed.and)('team.hasFederatedIdentityFlag', 'teamPermissions.canManageIdp'),
    showDefaultRole: (0, _computed.and)('team.isEnterpriseTeam', 'teamPermissions.canManagePreferences'),
    showPaidResourceAuthorization: (0, _computed.and)('team.isNotEnterpriseTeam', 'teamPermissions.canManagePipelines'),
    showAddonControls: (0, _computed.and)('team.canManageAddonControls', 'teamPermissions.canManageAllowedAddonsList'),
    show3rdPartyAuth: (0, _computed.and)('team.canDisableThirdPartyOAuth', 'teamPermissions.canManagePreferences'),
    showBulkAppTransfer: (0, _computed.bool)('teamPermissions.canCreateApps'),
    identityProvider: (0, _object.computed)('team.hasFederatedIdentityFlag', 'team.identityProvider.isSettled', function () {
      if (this.get('team.hasFederatedIdentityFlag') && this.get('team.identityProvider.isSettled')) {
        return this.get('team.identityProvider.content') || this.store.createRecord('identity-provider', {
          owner: this.team,
          enabled: true
        });
      }
    }),
    hasOrgViewerFlag: (0, _computed.readOnly)('team.hasOrgViewerFlag'),
    isEditingOrgName: false,
    isConfiguringSSO: false,
    memberRoles: (0, _object.computed)('hasOrgViewerFlag', function () {
      if (this.hasOrgViewerFlag) {
        return [_role.default.create({
          type: 'admin'
        }), _role.default.create({
          type: 'member'
        }), _role.default.create({
          type: 'viewer'
        })];
      } else {
        return [_role.default.create({
          type: 'admin'
        }), _role.default.create({
          type: 'member'
        })];
      }
    }),
    name: (0, _computed.alias)('team.name'),
    needsToConfirmRename: false,
    addonControlsDescription: (0, _object.computed)(function () {
      const description = `Control which add-on services can be installed to apps within this team.
      <a href='https://devcenter.heroku.com/articles/addon-controls' class='hk-link' target='_blank'>Learn More</a>`;
      return description;
    }),
    paidResourceAuthorizationDescription: (0, _object.computed)(function () {
      const description = `Heroku requires authorization for paid resources used by Review Apps and Heroku CI.
      <a href='https://help.heroku.com/RHLWN53U/why-am-i-being-asked-for-consent-to-additional-charges' class='hk-link' target='_blank'>Learn More</a>`;
      return description;
    }),
    authorizePaidResourcesButtonText: (0, _object.computed)('team.name', function () {
      return `Accept for "${this.team.name}" Pipelines`;
    }),
    pipelineCostConsentAtFormatted: (0, _object.computed)('team.pipelineCostConsentAt', function () {
      return (0, _moment.default)(this.team.pipelineCostConsentAt).format('h:mma MMMM D, YYYY');
    }),
    thirdPartyOAuthDescription: 'Third party applications are permitted to access the Heroku Platform API using credentials of users in this Heroku Team.',
    bulkAppTransferDescription: (0, _object.computed)('team.name', function () {
      return `Transfer multiple apps to <b>${this.get('team.name')}</b>`;
    }),

    trackRenameAndTransition() {
      const analyticsNoun = this.team.analyticsNoun;
      this.analytics.logEvent(analyticsNoun, 'Renamed');
      this.transitionToRoute('team.switchable.settings', this.get('team.name'));
    },

    actions: {
      rename(newName) {
        const team = this.team;
        team.set('newName', newName);

        if (this.identityProvider && !this.get('identityProvider.isNew')) {
          this.toggleProperty('needsToConfirmRename');
          return (0, _rsvp.reject)();
        } else {
          return team.save().then(() => {
            this.trackRenameAndTransition();
          });
        }
      },

      delete() {
        const enterpriseAccountName = this.get('team.enterpriseAccount.name');
        const analyticsNoun = this.team.analyticsNoun;
        this.analytics.logEvent(analyticsNoun, 'Deleted');
        return this.team.destroyRecord().then(() => {
          this.analytics.logEvent('Team', 'Deleted');

          if (enterpriseAccountName) {
            this.transitionToRoute('enterprise-account.overview', enterpriseAccountName);
          } else {
            this.transitionToRoute('protected');
          }

          (0, _runloop.next)(() => {
            this.flashMessages.success('Your team was deleted successfully', {
              title: 'Success'
            });
          });
        });
      },

      closeConfirmModal() {
        this.toggleProperty('needsToConfirmRename');
      },

      confirmRename() {
        return this.team.save().then(() => {
          this.trackRenameAndTransition();
          this.toggleProperty('needsToConfirmRename');
          this.set('isEditingOrgName', false);
        });
      },

      signalConsentForPipelinesCharges() {
        this.team.set('pipelineCostConsent', true);

        try {
          this.team.save().then(() => {
            this.flashMessages.success('Heroku will charge the pipeline owner for dynos and add-ons used for Review Apps and Heroku CI', {
              title: 'Additional charges authorized'
            });
          });
        } catch (error) {
          this.team.set('pipelineCostConsent', false);
        }
      }

    }
  });

  _exports.default = _default;
});