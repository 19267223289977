define("dashboard/templates/components/pop-over", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4SIR6eF4",
    "block": "[[[41,[30,0,[\"tooltipTitle\"]],[[[6,[39,1],null,[[\"title\",\"tooltipOptions\"],[[30,0,[\"tooltipTitle\"]],[30,0,[\"tooltipOptions\"]]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"toggle\",\"buttonContent\",\"buttonLabel\",\"buttonExpandedLabel\",\"buttonClass\",\"buttonDisabled\",\"buttonExpanded\",\"buttonComponent\",\"buttonComponentOptions\",\"overrideDefaultClass\",\"caretClass\",\"hasCaret\"],[[28,[37,3],[[30,0],\"toggle\"],null],[30,0,[\"buttonContent\"]],[30,0,[\"buttonLabel\"]],[30,0,[\"buttonExpandedLabel\"]],[30,0,[\"buttonClass\"]],[30,0,[\"buttonDisabled\"]],[30,0,[\"isShowingPopover\"]],[30,0,[\"buttonComponent\"]],[30,0,[\"buttonComponentOptions\"]],[30,0,[\"overrideDefaultClass\"]],[30,0,[\"caretClass\"]],[30,0,[\"hasCaret\"]]]]]],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"toggle\",\"buttonContent\",\"buttonLabel\",\"buttonExpandedLabel\",\"buttonClass\",\"buttonDisabled\",\"buttonExpanded\",\"buttonComponent\",\"buttonComponentOptions\",\"overrideDefaultClass\",\"caretClass\",\"hasCaret\"],[[28,[37,3],[[30,0],\"toggle\"],null],[30,0,[\"buttonContent\"]],[30,0,[\"buttonLabel\"]],[30,0,[\"buttonExpandedLabel\"]],[30,0,[\"buttonClass\"]],[30,0,[\"buttonDisabled\"]],[30,0,[\"isShowingPopover\"]],[30,0,[\"buttonComponent\"]],[30,0,[\"buttonComponentOptions\"]],[30,0,[\"overrideDefaultClass\"]],[30,0,[\"caretClass\"]],[30,0,[\"hasCaret\"]]]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"isShowingPopover\"]],[[[1,\"  \"],[10,0],[15,0,[30,0,[\"menuClassNames\"]]],[14,\"role\",\"list\"],[12],[18,1,null],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"x-tooltip\",\"pop-over-button\",\"action\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/pop-over.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});