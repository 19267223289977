define("dashboard/templates/components/addon-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SkAaZyJ0",
    "block": "[[[41,[30,0,[\"hasActions\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"addonActions\"]]],null]],null],null,[[[6,[39,3],null,[[\"addon\",\"app\"],[[30,0,[\"addon\"]],[30,0,[\"app\"]]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,4],null,[[\"addonAction\",\"addon\",\"webUrlForApp\",\"webUrlTarget\"],[[30,1],[30,0,[\"addon\"]],[30,2,[\"webUrlForApp\"]],[30,2,[\"webUrlTarget\"]]]]]],[1,\"\\n\"]],[2]]]]]],[1]],null],[1,\"  \"],[10,\"li\"],[14,0,\"divider\"],[12],[13],[1,\"\\n\"]],[]],null]],[\"addonAction\",\"result\"],false,[\"if\",\"each\",\"-track-array\",\"app/addons-sso-link\",\"addon-action-item\"]]",
    "moduleName": "dashboard/templates/components/addon-actions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});