define("dashboard/components/app/web-hooks/form", ["exports", "@ember/component", "@ember/object", "@ember/service", "dashboard/validations/webhook", "ember-changeset-validations", "ember-changeset", "@ember/object/computed"], function (_exports, _component, _object, _service, _webhook, _emberChangesetValidations, _emberChangeset, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    isLevelSynced: (0, _computed.equal)('changeset.level', 'sync'),

    init() {
      this._super(...arguments);

      const model = this.webhook;
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_webhook.default), _webhook.default, {
        skipValidate: true
      });
    },

    includeCheckboxes: (0, _object.computed)('includeTypes.[]', 'changeset.include.[]', function () {
      const includeTypes = this.includeTypes;
      const webhookIncludes = this.get('changeset.include');
      const includeCheckboxes = [];
      includeTypes.forEach(include => {
        const includeName = include.get('name');
        const isSelected = webhookIncludes.includes(includeName);

        const checkbox = _object.default.create({
          name: includeName,
          isSelected,
          description: include.get('description'),
          isBeta: !include.get('isGa')
        });

        includeCheckboxes.push(checkbox);
      });
      return includeCheckboxes;
    }),
    actions: {
      includeChecked(include) {
        const includeName = include.get('name'); // welcome to 👣 🔫 territory
        // make a copy so that when we set back onto changeset.include
        // it makes the model dirty in ember data's eyes
        // Without this any attempts to revert a changed record will do
        // nothing and you will get a not fun surprise

        const webhookIncludes = [...this.get('changeset.include')];

        if (webhookIncludes.includes(includeName)) {
          this.set('changeset.include', webhookIncludes.removeObject(includeName));
        } else {
          this.set('changeset.include', webhookIncludes.addObject(includeName));
        }
      },

      levelChanged(isChecked) {
        const level = isChecked ? 'sync' : 'notify';
        this.set('changeset.level', level);
      },

      save() {
        const changeset = this.changeset;
        return changeset.validate().then(() => {
          if (changeset.get('isValid')) {
            return changeset.save().then(() => {
              this.onSuccess();
            });
          }
        });
      },

      editSecret() {
        this.set('originalSecret', this.get('webhook.secret'));
        this.set('isEditingSecret', true);
        this.analytics.logEvent('Webhook Secret', 'Edited');
      },

      cancelEditSecret() {
        this.set('isEditingSecret', false);
        this.set('changeset.secret', this.originalSecret);
        this.analytics.logEvent('Webhook Secret', 'Edit Cancelled');
      }

    }
  });

  _exports.default = _default;
});