define("dashboard/serializers/space/member", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      const serialized = this._super(...arguments);

      const permissions = snapshot.record.get('editingPermissions');

      if (permissions) {
        serialized['permissions'] = permissions.map(permission => {
          return {
            name: permission.get('name')
          };
        });
      }

      return serialized;
    },

    normalize(typeClass, hash) {
      hash.id = hash.user.id;

      if (hash.space.id) {
        hash.space.type = 'space';
      }

      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});