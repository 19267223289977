define("dashboard/controllers/pipelines/pipeline/settings", ["exports", "@ember/object", "@ember/controller", "@ember/object/computed", "@ember/service", "ember-cp-validations", "@ember/application", "dashboard/config/environment"], function (_exports, _object, _controller, _computed, _service, _emberCpValidations, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PullRequestPatternValidations = (0, _emberCpValidations.buildValidations)({
    baseName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 5,
      max: 20
    })]
  });

  var _default = _controller.default.extend({
    githubToken: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    pipelineOwner: (0, _service.inject)(),
    githubIntegrationDisabled: _environment.default.githubIntegrationDisabled,
    isConnectedToRepositoryViaPersonalOauth: (0, _computed.notEmpty)('model.pipeline.githubRepository.id'),
    isConnectedToRepositoryViaGithubApp: (0, _computed.notEmpty)('model.pipeline.connectedRepository.id'),
    isConnectedToRepository: (0, _computed.or)('isConnectedToRepositoryViaPersonalOauth', 'isConnectedToRepositoryViaGithubApp'),
    githubTokenPresent: (0, _computed.or)('githubToken.isPresent', 'model.pipeline.hasGithubAppInstalled'),
    canManageRepository: (0, _computed.and)('isConnectedToRepository', 'githubTokenPresent'),
    showingDisabledWithConfigVars: (0, _computed.not)('reviewAppSettingsEnabled'),
    isCiEnabled: (0, _computed.alias)('model.pipeline.isCiEnabled'),
    canEnableCI: (0, _computed.reads)('canManageRepository'),
    canManageCi: (0, _computed.or)('isCiEnabled', 'canEnableCI'),
    isGithubAppEnabled: (0, _computed.readOnly)('model.pipeline.isGithubAppEnabled'),
    isEligibleForGithubAppInstallation: (0, _computed.and)('model.pipeline.isTeamPipeline', 'isGithubAppEnabled'),
    hasNewReviewAppsEnabled: (0, _computed.notEmpty)('model.pipeline.reviewAppConfig.id'),
    reviewAppSettingsEnabled: (0, _computed.or)('hasNewReviewAppsEnabled', 'canManageRepository'),
    reviewAppSettingsModifiable: (0, _computed.readOnly)('pipelineOwner.canModifyReviewAppSettings'),
    isPipelineConnectedToGitHubRepo: (0, _computed.notEmpty)('model.pipeline.githubRepository.id'),
    githubTitle: (0, _object.computed)('isPipelineConnectedToGitHubRepo', function () {
      if (this.get('isPipelineConnectedToGitHubRepo')) {
        return `Pipeline connected to GitHub`;
      } else {
        return `Connect to GitHub`;
      }
    }),
    hasReviewAppParents: (0, _computed.notEmpty)('model.pipeline.reviewAppParents'),
    hasReviewApps: (0, _computed.notEmpty)('model.pipeline.reviewCouplingApps'),
    showWarnings: (0, _computed.or)('hasReviewAppParents', 'hasReviewApps'),
    reviewAppCount: (0, _computed.reads)('model.pipeline.reviewCouplingApps.length'),
    reviewAppConfig: (0, _computed.readOnly)('model.pipeline.reviewAppConfig'),
    isShowingURLPatternModal: false,
    pipelineNameIsAvailable: true,
    // sane default
    // TODO better naming than `selectedPullRequestPattern.selectedPullRequestPattern`
    selectedPullRequestPattern: (0, _object.computed)('reviewAppConfig.baseName', 'reviewAppConfig.pullRequestPattern', function () {
      const SelectedPattern = _object.default.extend(PullRequestPatternValidations);

      return SelectedPattern.create((0, _application.getOwner)(this).ownerInjection(), {
        baseName: this.get('reviewAppConfig.baseName'),
        pullRequestPattern: this.get('reviewAppConfig.pullRequestPattern')
      });
    }),
    isPullRequestPatternValid: (0, _object.computed)('selectedPullRequestPattern.pullRequestPattern', 'selectedPullRequestPattern.baseName', 'selectedPullRequestPattern.validations.attrs.baseName.isValid', function () {
      const isRandom = this.selectedPullRequestPattern.get('pullRequestPattern') === 'random';
      return isRandom || this.selectedPullRequestPattern.get('validations.attrs.baseName.isValid');
    }),
    isPullRequestPatternInvalid: (0, _computed.not)('isPullRequestPatternValid'),
    actions: {
      revealURLPatternModal() {
        this.set('isShowingURLPatternModal', true);
      },

      async saveURLPatternChanges(dismiss) {
        if (this.get('selectedPullRequestPattern.pullRequestPattern') === 'predictable') {
          const replacementBaseName = this.selectedPullRequestPattern.baseName || this.model.pipeline.name;
          this.set('reviewAppConfig.baseName', replacementBaseName);
        } else if (this.get('selectedPullRequestPattern.pullRequestPattern') === 'random') {
          this.set('reviewAppConfig.baseName', null);
        }

        try {
          await this.get('reviewAppConfig.content').save();
          dismiss();
        } catch (e) {
          this.get('reviewAppConfig.content').rollbackAttributes();
        }
      },

      dismissURLPatternModal() {
        // hide this modal
        this.set('isShowingURLPatternModal', false); // revert settings

        this.set('selectedPullRequestPattern.pullRequestPattern', this.get('reviewAppConfig.pullRequestPattern'));
        this.set('selectedPullRequestPattern.baseName', this.get('reviewAppConfig.baseName'));
      }

    }
  });

  _exports.default = _default;
});