define("dashboard/models/metrics/errors-base", ["exports", "@ember/polyfills", "@ember/object", "@ember/service", "dashboard/models/metrics", "dashboard/utils/error-manifest", "d3"], function (_exports, _polyfills, _object, _service, _metrics, _errorManifest, _d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a base class for dyno/router errors
   *
   * not to be used directly
   */
  var _default = _metrics.default.extend({
    reporter: (0, _service.inject)(),

    /**
     * describes whether or not this object has any data
     */
    hasData: (0, _object.computed)('rawData', function () {
      return Object.keys(this.rawData).length > 0;
    }),

    /**
     * constructs an enhanced collection of Objects that
     * containing metadata with the error name, error-level, and data
     */
    errorObjects: (0, _object.computed)('rawData', function () {
      const data = this.rawData;
      const manifest = _errorManifest.default;
      return Object.entries(data).map(_ref => {
        let [key, value] = _ref;
        let metaData = manifest.findBy('name', key);

        if (!metaData) {
          this.reporter.error(`Warning: unknown error key found: ${key}`);
          metaData = {
            name: key
          };
          return undefined;
        }

        return (0, _polyfills.merge)({ ...metaData
        }, {
          data: value
        });
      }, {
        manifest,
        data
      }).filter(Boolean);
    }),

    /**
     * `errorObjects` that are critical-level
     */
    criticalObjects: (0, _object.computed)('errorObjects.[]', function () {
      return this.errorObjects.filterBy('level', 'critical');
    }),

    /**
     * the total number of critical errors
     */
    criticalErrorCount: (0, _object.computed)('criticalObjects.[]', function () {
      return this.criticalObjects.reduce(function (total, object) {
        return total += (0, _d.sum)(object.data.mapBy('1'));
      }, 0);
    })
  });

  _exports.default = _default;
});