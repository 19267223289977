define("dashboard/models/recovery-code", ["exports", "@ember-data/model", "dashboard/mixins/ds-error-monitoring-model", "dashboard/mixins/password-encoding"], function (_exports, _model, _dsErrorMonitoringModel, _passwordEncoding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_passwordEncoding.default, _dsErrorMonitoringModel.default, {
    codes: (0, _model.attr)(),
    password: null,

    saveWithPassword(password) {
      return this.save({
        adapterOptions: {
          password: this.decodePassword(password)
        }
      });
    }

  });

  _exports.default = _default;
});