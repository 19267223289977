define("dashboard/templates/components/metrics/chart-controls/timeframe-selector/button-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TsHhFZ8G",
    "block": "[[[10,0],[14,0,\"metrics__time-picker__btn-content\"],[12],[1,\"\\n  \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"caret-16\",16,\"fill-purple\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/timeframe-selector/button-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});