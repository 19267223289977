define("dashboard/templates/components/metrics/charts/connected-chart-hover/formation-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kBCfDnpY",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"filteredHorizontalScaleEvents\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"model\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"verticalScaleModels\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"model\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"eventModels\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,4],null,[[\"swatchColor\",\"isHidingValue\",\"title\",\"description\"],[\"activity\",true,[30,3,[\"title\"]],[30,3,[\"description\"]]]]]],[1,\"\\n\"]],[3]],null]],[\"model\",\"model\",\"model\"],false,[\"each\",\"-track-array\",\"metrics/charts/connected-chart-hover/item/horizontal-scale\",\"metrics/charts/connected-chart-hover/item/vertical-scale\",\"metrics/charts/connected-chart-hover/item\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/connected-chart-hover/formation-group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});