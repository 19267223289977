define("dashboard/templates/components/pipeline/app-release", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "MhxGNbU6",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[41,[30,0,[\"release\",\"isPending\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"size\",\"class\"],[\"small\",\"flex-shrink-0 nudge-up--1 mr2\"]]]],[1,\"\\n    \"],[10,1],[14,0,\"flex-auto f5 dark-gray\"],[12],[1,\"\\n      Releasing \"],[10,1],[14,0,\"hk-badge ttn\"],[12],[1,\"v\"],[1,[30,0,[\"release\",\"version\"]]],[13],[1,\" •\\n      \"],[10,\"code\"],[12],[1,[30,0,[\"releaseCommand\"]]],[13],[1,\" is running.\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isShowingFailedStatus\"]],[[[1,\"    \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"failure-ring-16\",\"flex-shrink-0 malibu-fill-gradient-red mr1\",16]],null],[1,\"\\n    \"],[10,1],[14,0,\"flex-auto f5 red\"],[12],[1,\"\\n      Release \"],[10,1],[14,0,\"hk-badge bg-red ttn\"],[12],[1,\"v\"],[1,[30,0,[\"release\",\"version\"]]],[13],[1,\" failed •\\n      \"],[10,\"code\"],[12],[1,[30,0,[\"releaseCommand\"]]],[13],[1,\" exited with an error.\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"default\",\"size\",\"buttonType\",\"onClick\"],[\"Retry\",\"small\",\"danger\",[28,[37,4],[[30,0],\"retry\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"release\",\"isSuccess\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"success-ring-16\",\"flex-shrink-0 malibu-fill-gradient-green mr1\",16,\"Success\"]],null],[1,\"\\n    \"],[10,1],[14,0,\"flex-auto f5 green\"],[12],[1,\"\\n      Released \"],[10,1],[14,0,\"hk-badge bg-green ttn\"],[12],[1,\"v\"],[1,[30,0,[\"release\",\"version\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"releaseCommand\"]],[[[1,\"        • \"],[10,\"code\"],[12],[1,[30,0,[\"releaseCommand\"]]],[13],[1,\" completed successfully.\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"malibu-loading-spinner\",\"malibu-icon\",\"hk-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/pipeline/app-release.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});