define("dashboard/models/payment-method", ["exports", "@ember/object/computed", "@ember/object", "@ember-data/model", "rsvp"], function (_exports, _computed, _object, _model, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    address_1: (0, _model.attr)(),
    address_2: (0, _model.attr)(),
    city: (0, _model.attr)(),
    country: (0, _model.attr)(),
    first_name: (0, _model.attr)(),
    last_name: (0, _model.attr)(),
    postal_code: (0, _model.attr)(),
    state: (0, _model.attr)(),
    other: (0, _model.attr)(),
    card_last4: (0, _model.attr)(),
    card_type: (0, _model.attr)(),
    expiration_month: (0, _model.attr)(),
    expiration_year: (0, _model.attr)(),
    nonce: (0, _model.attr)(),
    deviceData: (0, _model.attr)(),
    copyableAttrs: ['address_1', 'address_2', 'city', 'country', 'expiration_month', 'expiration_year', 'first_name', 'last_name', 'postal_code', 'state', 'other'],

    copy() {
      const store = this.store;
      const copiedAttrs = this.copyableAttrs.reduce((memo, attribute) => {
        memo[attribute] = this.get(attribute);
        return memo;
      }, {});
      return store.createRecord(this.constructor.modelName, copiedAttrs);
    },

    copy_as_nonce() {
      return this.store.createRecord(this.constructor.modelName, {
        nonce: this.get('nonce')
      });
    },

    cardPresent: (0, _computed.bool)('card_last4'),
    name: (0, _object.computed)('card_type', 'card_last4', function () {
      return `${this.card_type} card ending in ${this.card_last4}`;
    }),

    getClientToken() {
      return new _rsvp.Promise((resolve, reject) => {
        this.store.adapterFor('payment-method').getClientToken().then(_ref => {
          let {
            token
          } = _ref;
          return resolve(token);
        }, reject);
      });
    },

    analyticsNoun: 'Account Payment Method'
  });

  _exports.default = _default;
});