define("dashboard/utils/metrics/metrics-data-operators", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeRateOfKey = changeRateOfKey;
  _exports.dataAverage = dataAverage;
  _exports.dataDifference = dataDifference;
  _exports.dataLogarithm = dataLogarithm;
  _exports.dataPercentage = dataPercentage;
  _exports.dataQuotient = dataQuotient;
  _exports.dataRatio = dataRatio;
  _exports.dataSum = dataSum;
  _exports.formatData = formatData;

  /*
   * here we are forcing any metrics data to look like it is
   * "missing" if the value is 0 and it is the last data point
   * we do this because there is a deficiency in the metrics-api
   * that makes it so it there is no differentiation between
   * "missing" data and a true "0" value
   */
  function formatData(serviceData) {
    return (0, _object.computed)(serviceData, function () {
      const data = this.get(serviceData);
      data.forEach(function (el, index) {
        if (el[1] === null && index < data.length - 1) {
          el[1] = 0;
        }
      });
      return data;
    });
  }

  function dataLogarithm(dataProperty) {
    return (0, _object.computed)(dataProperty, function () {
      const data = this.get(dataProperty);
      return this.createNewMetric().map(function (_ref, index) {
        let [time] = _ref;
        const datum = data[index] || [];
        const value = datum[1];
        let logarithm;

        if (value > 0) {
          logarithm = Math.log10(value);
        } else {
          logarithm = value;
        }

        return [time, logarithm];
      });
    });
  }

  function dataQuotient(sumProperty, countProperty) {
    return (0, _object.computed)(sumProperty, countProperty, function () {
      const [sum, count] = [this.get(sumProperty), this.get(countProperty)];
      return this.createNewMetric().map(function (_ref2, index) {
        let [time] = _ref2;
        const sumDatum = sum[index] || [];
        const sumVal = sumDatum[1];
        const countDatum = count[index] || [];
        const countVal = countDatum[1];
        const quotient = countVal ? sumVal / countVal : null;
        const perc = Number.isFinite(quotient) ? quotient : null;
        return [time, perc];
      });
    });
  }

  function dataAverage(firstProperty, secondProperty) {
    return (0, _object.computed)(firstProperty, secondProperty, function () {
      const [firstData, secondData] = [this.get(firstProperty), this.get(secondProperty)];
      return this.createNewMetric().map(function (_ref3, index) {
        let [time] = _ref3;
        const firstDatum = firstData[index] || [];
        const firstVal = firstDatum[1];
        const secondDatum = secondData[index] || [];
        const secondVal = secondDatum[1];
        const sum = firstVal + secondVal;
        return [time, sum / 2];
      });
    });
  }

  function dataPercentage(countProperty, totalProperty) {
    return (0, _object.computed)(countProperty, totalProperty, function () {
      const [countData, totalData] = [this.get(countProperty), this.get(totalProperty)];
      return this.createNewMetric().map(function (_ref4, index) {
        let [time] = _ref4;
        const countDatum = countData[index] || [];
        const countVal = countDatum[1];
        const totalDatum = totalData[index] || [];
        const totalVal = totalDatum[1];
        const ratio = totalVal ? countVal / totalVal : null;
        const perc = Number.isFinite(ratio) ? 100 * ratio : null;
        return [time, perc];
      });
    });
  }

  function dataRatio(countProperty, divisorProperty) {
    return (0, _object.computed)(countProperty, function () {
      const countData = this.get(countProperty);
      const divisorVal = this.get(divisorProperty);
      return this.createNewMetric().map(function (_ref5, index) {
        let [time] = _ref5;
        const countDatum = countData[index] || [];
        const countVal = countDatum[1];
        const ratio = countVal / divisorVal;
        const perc = Number.isFinite(ratio) ? ratio : null;
        return [time, perc];
      });
    });
  }

  function dataSum(firstProperty, secondProperty) {
    return (0, _object.computed)(firstProperty, secondProperty, function () {
      const [firstData, secondData] = [this.get(firstProperty), this.get(secondProperty)];
      return this.createNewMetric().map(function (_ref6, index) {
        let [time] = _ref6;
        const firstDatum = firstData[index] || [];
        const firstVal = firstDatum[1];
        const secondDatum = secondData[index] || [];
        const secondVal = secondDatum[1];
        const sum = firstVal + secondVal;
        return [time, sum];
      });
    });
  }

  function dataDifference(minuend, subtrahend) {
    return (0, _object.computed)(minuend, subtrahend, function () {
      const [minuendData, subtrahendData] = [this.get(minuend), this.get(subtrahend)];
      return this.createNewMetric().map(function (_ref7, index) {
        let [time] = _ref7;
        const minuendDatum = minuendData[index] || [];
        const minuendVal = minuendDatum[1];
        const subtrahendDatum = subtrahendData[index] || [];
        const subtrahendVal = subtrahendDatum[1];
        const difference = minuendVal - subtrahendVal;
        return [time, difference];
      });
    });
  }

  const sumOfValues = function (values) {
    return values.reduce((sum, val) => {
      return sum += val;
    }, 0);
  };
  /**
   * a computed property that measures the change rate of
   * data for a passed in key
   *
   * returns a percentage value of increase e.g. 105 (% increase)
   */


  function changeRateOfKey(key, currentDataKey, pastDataKey) {
    return (0, _object.computed)(currentDataKey, pastDataKey, function () {
      const currentData = this.get(currentDataKey);
      const pastData = this.get(pastDataKey); // the API does not necessarily return these keys

      if (currentData && currentData[key] && pastData && pastData[key]) {
        const points = currentData[key].length;
        const values = currentData[key].mapBy('1');
        const mean = sumOfValues(values) / points;
        const valuesPast = pastData[key].mapBy('1');
        const meanPast = sumOfValues(valuesPast) / points;
        return 100 * Math.floor(mean / meanPast - 1);
      }
    });
  }
});