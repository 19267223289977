define("dashboard/templates/team/space/access", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Qb5PL32D",
    "block": "[[[10,0],[14,0,\"limit-width\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"message-banner clearfix\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"lock-locked-16\",19,\"malibu-fill-gradient-purple\"]],null],[1,\"\\n    \"],[10,2],[14,0,\"message-banner-text\"],[12],[1,\"\\n      Team members can only create apps in Private Spaces if they are granted the \\\"app creation\\\" permission.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"space-access-section limit-width\"],[12],[1,\"\\n\"],[41,[30,0,[\"teamMembers\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"class\",\"collaborators\",\"header\",\"isAdmin\"],[\"space-org-member-list\",[30,0,[\"spaceTeamMembers\"]],\"Team Members\",[30,0,[\"canManageSpaces\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"class\",\"collaborators\",\"header\",\"permissions\"],[\"space-org-admin-list\",[30,0,[\"teamAdmins\"]],\"Team Admins\",[30,0,[\"adminSpacePermissions\"]]]]]],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"if\",\"space/access/members-list\",\"static-collaborator-list\"]]",
    "moduleName": "dashboard/templates/team/space/access.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});