define("dashboard/components/metrics/charts/axes-elements/axis-date", ["exports", "@ember/object", "dashboard/components/metrics/charts/axes-elements/axis-element", "moment"], function (_exports, _object, _axisElement, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the x-axis {{axis-element}} for time that displays the date
   */
  var _default = _axisElement.default.extend({
    classNames: 'date',
    tickPadding: 24,
    ticksInterval: 1,
    innerTickSize: 0,
    outerTickSize: 0,
    tickValues: (0, _object.computed)('minTime', 'maxTime', 'timezone', 'isPreview', 'dateLabelCutoff', 'dateLabelInterval', 'step', function () {
      const timezone = this.get('timezone') || 'UTC';
      const minLocal = (0, _moment.default)(this.get('minTime')).tz(timezone);
      const maxLocal = (0, _moment.default)(this.get('maxTime')).tz(timezone);
      const dates = [];
      let initialOffset = 1; // Only include the first label if it comes before the cutoff hour.
      // Also, prevent showing the first label on alerting configuration
      // preview charts to avoid date labels from cluttering.

      if (minLocal.hours() < this.get('dateLabelCutoff') && !this.get('isPreview')) {
        dates.push(minLocal); // If we show this initial label, then we must skip the next label on
        // the Past 7 days timeframe setting.

        if (this.get('step') > 60) {
          initialOffset = 2;
        }
      } // Add a label for every midnight that occurs between the min and max times
      // NOTE: moments are mutable; calling any of the manipulation methods will
      // change the original moment object, thus we must use clone().


      let date = minLocal.clone().startOf('day').add(initialOffset, 'days');

      while (date.toDate() < maxLocal.toDate()) {
        dates.push(date);
        date = date.clone().add(this.get('dateLabelInterval'), 'days');
      }

      return dates;
    }),

    formatFn(d) {
      const tz = this.get('timezone') || 'UTC';

      const m = _moment.default.utc(d).tz(tz);

      const displayTimezone = m.format('z');
      return `${d.format('ddd Do')} ${displayTimezone}`;
    }

  });

  _exports.default = _default;
});