define("dashboard/components/metrics/charts/visualizations/node-eventloop-latency", ["exports", "dashboard/components/metrics/charts/visualizations/latency-chart", "@ember/object/computed"], function (_exports, _latencyChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _latencyChart.default.extend({
    tagName: 'metrics-node-eventloop-latency-chart',
    'data-test-target': 'metrics-node-chart',
    title: 'Node.js: Event Loop Latency',
    devCenterName: 'language-runtime-metrics-nodejs#event-loop-latency',
    testTarget: 'node-eventloop-latency',
    isBeta: true,
    p50Data: (0, _computed.alias)('data.eventloopLatency50'),
    p95Data: (0, _computed.alias)('data.eventloopLatency95'),
    p99Data: (0, _computed.alias)('data.eventloopLatency99'),
    maxData: (0, _computed.alias)('data.eventloopLatencyMax')
  });

  _exports.default = _default;
});