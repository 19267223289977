define("dashboard/routes/app/release", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app"], function (_exports, _rsvp, _service, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model(params) {
      const {
        app
      } = this.modelFor('app');
      return (0, _rsvp.hash)({
        app,
        release: this.store.queryRecord('release', {
          app: app.get('name'),
          releaseId: params['release_id']
        })
      });
    },

    afterModel() {
      this.analytics.logEvent('App Release Log Page', 'Viewed');
    }

  });

  _exports.default = _default;
});