define("dashboard/templates/components/scheduler/forms/job-command", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "f6K3EJIk",
    "block": "[[[6,[39,0],null,[[\"class\",\"changeset\",\"property\"],[\"flex mb2\",[30,0,[\"job\"]],\"command\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"flex items-center w3 justify-center overflow-hidden bg-near-white br1 br--left bl bt bb b--light-gray shadow-outer-3 center dark-gray\"],[12],[1,\"\\n    $\\n  \"],[13],[1,\"\\n  \"],[1,[28,[30,1,[\"field\"]],null,[[\"class\",\"placeholder\"],[\"flex-auto br--right z-1 code\",\"e.g. node billing_processing.js\"]]]],[1,\"\\n\"]],[1]]]]],[6,[39,0],null,[[\"class\",\"changeset\",\"property\",\"fieldType\",\"id\"],[\"flex items-stretch\",[30,0,[\"job\"]],\"dynoSize\",\"hk-select\",\"dyno-size-select\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"flex items-center ph2 justify-center overflow-hidden bg-near-white br1 br--left bl bt bb b--light-gray shadow-outer-3\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"dynoIconName\"]],\"malibu-fill-gradient-purple\",16]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[6,[30,2,[\"field\"]],null,[[\"class\",\"disabled\",\"onChange\"],[\"br--right flex-auto z-1\",[30,0,[\"onlyOneSizeOption\"]],[28,[37,2],[[30,0],\"setDynoSize\"],null]]],[[\"default\"],[[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"sizes\"]]],null]],null],null,[[[6,[30,3,[\"option\"]],null,[[\"value\"],[[30,4,[\"size\"]]]],[[\"default\"],[[[[1,\"        \"],[1,[30,4,[\"size\"]]],[1,\"\\n\"]],[]]]]]],[4]],null]],[3]]]]]],[2]]]]]],[\"group\",\"group\",\"xs\",\"size\"],false,[\"hk-form-group\",\"malibu-icon\",\"action\",\"each\",\"-track-array\"]]",
    "moduleName": "dashboard/templates/components/scheduler/forms/job-command.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});