define("dashboard/templates/components/datastore/postgres/overview", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "q3rNgPSh",
    "block": "[[[8,[39,0],null,[[\"@datastore\"],[[30,0,[\"datastore\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isNotHobbyOrBasic\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@datastore\",\"@timezone\",\"@beforeHours\",\"@afterHours\",\"@currentTimeframe\",\"@datastoreMetrics\",\"@fetchDatastoreMetricsTask\",\"@updateTimeframe\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,3],null,[[\"@datastore\",\"@addon\"],[[30,0,[\"datastore\"]],[30,0,[\"addon\"]]]],null],[1,\"\\n\"]],[\"@datastore\",\"@timezone\",\"@beforeHours\",\"@afterHours\",\"@currentTimeframe\",\"@datastoreMetrics\",\"@fetchDatastoreMetricsTask\",\"@updateTimeframe\"],false,[\"datastore/postgres/health\",\"if\",\"datastore/postgres/charts\",\"datastore/postgres/utilization\"]]",
    "moduleName": "dashboard/templates/components/datastore/postgres/overview.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});