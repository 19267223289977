define("dashboard/routes/team/space/access", ["exports", "rsvp", "@ember/routing/route", "dashboard/models/space-permission"], function (_exports, _rsvp, _route, _spacePermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      const {
        team
      } = this.modelFor('team');
      const space = this.modelFor('team.space');
      const permissions = [_spacePermission.default.create({
        name: 'create_apps',
        description: 'Create apps in a space'
      })];
      return (0, _rsvp.hash)({
        spaceMembers: space.get('members').reload(),
        permissions,
        team
      });
    },

    afterModel(model) {
      this.setSpaceMemberPermissions(model.spaceMembers, model.permissions);
    },

    // Attach the collection of available permissions to each Space member so the UI
    // can display the complete set of available permissions combined with those already
    // selected for each collaborator
    setSpaceMemberPermissions(spaceMembers, availablePermissions) {
      spaceMembers.forEach(member => {
        member.set('availablePermissions', availablePermissions);
      });
    }

  });

  _exports.default = _default;
});