define("dashboard/components/app/overview-metrics", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    _webProcess: (0, _object.computed)('app.formations.[]', function () {
      const formations = this.get('app.formations') || [];
      return formations.findBy('type', 'web');
    }),
    _webProcessMonitors: (0, _computed.alias)('_webProcess.monitors'),
    _canSeeAlerts: (0, _computed.and)('app.isEligibleForAlerting', 'app.formations.isFulfilled', '_webProcessMonitors.isFulfilled'),
    alerts: (0, _object.computed)('_canSeeAlerts', '_webProcess.alerts.[]', function () {
      return this._canSeeAlerts ? this.get('_webProcess.alerts') : [];
    })
  });

  _exports.default = _default;
});