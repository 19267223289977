define("dashboard/components/metrics/charts/connected-chart-hover/incident-group", ["exports", "@ember/object/computed", "@ember/utils", "@ember/object", "dashboard/components/metrics/charts/connected-chart-hover/activity-group"], function (_exports, _computed, _utils, _object, _activityGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activityGroup.default.extend({
    models: (0, _computed.reads)('graph.models'),
    updatesAtSelectedTime: (0, _computed.filter)('modelsAtSelectedTime', function (model) {
      return (0, _utils.isPresent)(model.get('updateType'));
    }),
    incidentsAtSelectedTime: (0, _object.computed)('models.[]', 'maxTime', 'selectedRollupTime', 'rollupDuration', function () {
      const selectedTime = this.selectedRollupTime;
      let incidents = [];

      if (selectedTime) {
        const defaultEndTime = this.get('maxTime');
        const rollupDuration = this.rollupDuration;
        incidents = this.models.filter(function (model) {
          const start = model.get('startedAt') - rollupDuration;
          const end = model.get('endedAt') || defaultEndTime;
          return selectedTime >= start && selectedTime <= end;
        });
      }

      return incidents;
    }),
    updateItems: (0, _computed.map)('updatesAtSelectedTime', function (update) {
      const id = update.get('incident.id');
      const title = `${update.get('incident.title')}: ${update.get('updateType').capitalize()}`;
      const description = `Heroku Status #${id}`;
      const createdAt = update.get('createdAt');
      return _object.default.create({
        id,
        title,
        description,
        createdAt
      });
    }),
    incidentItems: (0, _object.computed)('updateItems', 'incidentsAtSelectedTime', function () {
      const updateItems = this.updateItems;
      const incidents = this.incidentsAtSelectedTime;
      return incidents.reduce(function (items, incident) {
        const id = incident.get('id'); // Only show an incident card if there are no updates in this roll-up already

        if ((0, _utils.isEmpty)(updateItems.filterBy('id', id))) {
          const title = incident.get('title');
          const description = `Heroku Status #${id}`;
          const createdAt = incident.get('createdAt');
          items.push(_object.default.create({
            id,
            title,
            description,
            createdAt
          }));
        }

        return items;
      }, []);
    }),
    items: (0, _computed.union)('updateItems', 'incidentItems'),
    itemSortingKey: ['id'],
    sortedItems: (0, _computed.sort)('items', 'itemSortingKey'),
    isVisible: (0, _computed.gt)('items.length', 0)
  });

  _exports.default = _default;
});