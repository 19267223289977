define("dashboard/models/invoice-graph-point", ["exports", "@ember/object", "@ember/object/computed", "@ember/string", "dashboard/helpers/currency-format", "numeral"], function (_exports, _object, _computed, _string, _currencyFormat, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    model: null,
    // invoice or orgnization-license
    date: (0, _computed.oneWay)('model.date'),
    total: (0, _computed.oneWay)('model.total'),
    quota: (0, _computed.oneWay)('model.quota'),
    currentUsage: (0, _computed.oneWay)('model.currentUsage'),
    plotIsCurrency: false,
    // boolean
    attributeToPlot: null,
    // 'total' or 'currentUsage'
    maxYValue: null,
    quotaLimitAttribute: null,
    // null or 'quota'
    calcHeight: (0, _object.computed)('maxYValue', '_amount', function () {
      const total = this._amount;
      const relativeYScale = total / this.maxYValue * 60;
      return new _string.htmlSafe(`height: ${relativeYScale}px`);
    }),
    quotaCalcHeight: (0, _object.computed)('maxYValue', 'quotaLimitAttribute', 'quota', function () {
      const attr = this.quotaLimitAttribute;

      if (!attr) {
        return null;
      }

      const quota = this.get(attr);
      const relativeYLimit = quota / this.maxYValue * 60;
      return new _string.htmlSafe(`bottom: ${relativeYLimit}px`);
    }),
    isOverLimit: (0, _object.computed)('quotaLimitAttribute', 'quota', '_amount', function () {
      const amount = this._amount;
      const attr = this.quotaLimitAttribute;

      if (!attr) {
        return false;
      }

      const quota = this.get(attr);
      return amount > quota;
    }),
    value: (0, _object.computed)('_amount', function () {
      return this.format(this._amount);
    }),
    // If the quotatLimit attribute is not set (from the template), then there is
    // this graph point does not have a limit and should return 0.
    quotaLimit: (0, _object.computed)('quotaLimitAttribute', 'quota', function () {
      const attr = this.quotaLimitAttribute; // 'quota' or null

      if (!attr) {
        return 0;
      }

      const quota = this.get(attr);
      return this.format(quota);
    }),
    _amount: (0, _object.computed)('attributeToPlot', 'total', 'currentUsage', function () {
      const attribute = this.attributeToPlot;
      return this.get(attribute);
    }),
    usageLabel: (0, _object.computed)('value', 'quotaLimit', function () {
      if (this.quotaLimit) {
        return `${this.value} of ${this.quotaLimit}`;
      }

      return `${this.value}`;
    }),

    format(amount) {
      if (this.plotIsCurrency) {
        return (0, _currencyFormat.currency)(amount, {
          unit: 'cents'
        });
      } else {
        return (0, _numeral.default)(amount).format('0,0.00');
      }
    }

  });

  _exports.default = _default;
});