define("dashboard/templates/components/app/addons-quickadd-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DNcCLyFm",
    "block": "[[[10,\"img\"],[15,\"src\",[29,[\"https://addons.heroku.com/addons/\",[30,0,[\"item\",\"name\"]],\"/icons/catalog.png\"]]],[14,0,\"addon-icon\"],[14,\"height\",\"28px\"],[14,\"width\",\"28px\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n\"],[10,1],[14,0,\"addon-typeahead-title\"],[12],[1,[30,0,[\"item\",\"humanName\"]]],[13],[1,\"\\n\"],[10,1],[14,0,\"addon-typeahead-description\"],[12],[1,[30,0,[\"item\",\"description\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"isNotProvisionable\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"warning-badge-28\",\"malibu-fill-gradient-orange\",22,\"Not provisionable\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/app/addons-quickadd-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});