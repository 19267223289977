define("dashboard/templates/components/two-factor/prompt", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+QpErw0P",
    "block": "[[[41,[30,0,[\"twoFactorRequired\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"form-group \",[52,[30,0,[\"twoFactorUnfulfilled\"]],\"has-error\"]]]],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"two-factor-token\"],[12],[1,\"\\n      Two Factor Code\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],[[24,1,\"two-factor-token\"],[24,0,\"form-control\"],[24,\"placeholder\",\"One Time Password or YubiKey\"],[24,\"autocomplete\",\"off\"]],[[\"@value\"],[[30,0,[\"twoFactorToken\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"input\"]]",
    "moduleName": "dashboard/templates/components/two-factor/prompt.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});