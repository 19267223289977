define("dashboard/components/metrics/charts/visualizations/response-time", ["exports", "dashboard/components/metrics/charts/visualizations/latency-chart", "@ember/object/computed", "dashboard/utils/metrics/time-calculators"], function (_exports, _latencyChart, _computed, _timeCalculators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _latencyChart.default.extend({
    tagName: 'metrics-response-time-chart',
    title: 'Response Time',
    devCenterName: 'metrics#response-time',
    insufficientDataTitle: 'No Web Traffic',
    alertSummaryType: 'latency',
    visibleAlerts: (0, _timeCalculators.filterPeriodWithinTimeframe)('alerts', 'startedAt', 'endedAt'),
    filteredAlerts: (0, _computed.filterBy)('visibleAlerts', 'isLatency'),
    p50Data: (0, _computed.alias)('data.service50Data'),
    p95Data: (0, _computed.alias)('data.service95Data'),
    p99Data: (0, _computed.alias)('data.service99Data'),
    maxData: (0, _computed.alias)('data.serviceMaxData')
  });

  _exports.default = _default;
});