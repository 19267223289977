define("dashboard/templates/components/metrics/language-metrics-discovery", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5NkAvIU4",
    "block": "[[[10,0],[14,0,\"flex flex-wrap items-center\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"metrics-16\",\"malibu-fill-gradient-purple\",18]],null],[1,\"\\n  \"],[10,0],[14,0,\"mr2 dark-gray nudge-right--4\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"devCenterUrl\"]]],[14,\"target\",\"_blank\"],[14,0,\"hk-link ml1 flex items-center no-underline\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"underline\"],[12],[1,[30,0,[\"actionText\"]]],[13],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"nav-go-16\",\"fill-blue ml--3\",16]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,1],[[4,[38,2],[[30,0],\"dismissalAction\"],null]],[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Dismiss\",\"delete-16\",\"fill-gray\",16]],null],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"malibu-icon-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/metrics/language-metrics-discovery.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});