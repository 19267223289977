define("dashboard/services/favicon", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_ICON = 'favicon';

  var _default = _service.default.extend({
    icon: DEFAULT_ICON,
    updateFavicon: (0, _object.observer)('icon', function () {
      const oldLink = document.querySelector('link[rel="shortcut icon"]');
      const newLink = document.createElement('link');
      newLink.rel = 'shortcut icon';
      newLink.href = `https://www.herokucdn.com/favicons/${this.icon}.ico`;

      if (oldLink) {
        document.head.removeChild(oldLink);
      }

      document.head.appendChild(newLink);
    }),

    reset() {
      this.set('icon', DEFAULT_ICON);
    }

  });

  _exports.default = _default;
});