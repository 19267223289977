define("dashboard/components/metrics/charts/chart-header/chart-summary-row", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['metrics__summary-row']
  });

  _exports.default = _default;
});