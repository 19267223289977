define("dashboard/templates/components/dyno-tier-size-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "R4UM2AP2",
    "block": "[[[10,0],[14,0,\"sr-only\"],[12],[1,\"\\n  \"],[1,[30,0,[\"options\",\"currentSize\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[35,0],null,[[\"name\"],[[30,0,[\"options\",\"sizeIconName\"]]]]]],[1,\"\\n\"],[41,[51,[30,0,[\"options\",\"cannotChangeSizes\"]]],[[[1,\"  \"],[10,1],[12],[1,\"\\n    \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"caret-16\",\"fill-purple\",16]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"dyno-size-icon\",\"unless\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/dyno-tier-size-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});