define("dashboard/controllers/team/switchable/usage", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    title: 'Usage',
    team: (0, _computed.alias)('model.team')
  });

  _exports.default = _default;
});