define("dashboard/components/autogrow-textarea", ["exports", "jquery", "@ember/component/text-area"], function (_exports, _jquery, _textArea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders an autogrowable textarea.
   *
   *     {{autogrow-textarea value='foo'}}
   *
   * @class Dashboard.AutogrowTextarea
   * @extends Ember.TextArea
   */
  var _default = _textArea.default.extend({
    attributeBindings: ['resize', 'rows', 'wrap'],
    resize: 'false',
    rows: '1',
    wrap: 'off',

    didRenderElement() {
      (0, _jquery.default)(this.element).textareaAutoSize();
    }

  });

  _exports.default = _default;
});