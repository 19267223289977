define("dashboard/controllers/enterprise-account/overview/usage", ["exports", "@ember/controller", "@ember/object/computed", "moment"], function (_exports, _controller, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    enterpriseAccount: (0, _computed.alias)('model.enterpriseAccount'),
    currentLicenses: (0, _computed.alias)('model.currentLicenses'),
    historicalLicenseUsage: (0, _computed.alias)('model.historicalLicenseUsage'),

    get currentPeriod() {
      return _moment.default.utc().format('YYYY-MM');
    },

    currentLicenseUsage: (0, _computed.filter)('historicalLicenseUsage', function (licenseUsage) {
      return licenseUsage.month === this.currentPeriod;
    })
  });

  _exports.default = _default;
});