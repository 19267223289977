define("dashboard/components/app-item-favorite-tray", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['app-item-favorite-tray', 'flex', 'f5', 'pb2', 'ph4', 'bg-white', 'bb', 'b--light-gray'],
    'data-test-target': 'app-item-favorite-tray',
    store: (0, _service.inject)(),
    isShowingMetrics: readOnly('app.metricsEnabled'),
    emberDataApp: (0, _object.computed)('app', function () {
      return this.store.findRecord('app', this.get('app.id'));
    }),

    didInsertElement() {
      this._super(...arguments);

      const app = this.app;
      const shouldFetch = !app.get('dynoCount');

      if (shouldFetch) {
        app.decorateWithDynoData();
      }
    }

  });

  _exports.default = _default;
});