define("dashboard/routes/app/settings", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app"], function (_exports, _rsvp, _service, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model() {
      const {
        app,
        appPermissions
      } = this.modelFor('app');
      let teamMembers = [];
      const team = app.get('team');

      if (app.get('isOwnedByOrg') && team.get('role') !== 'collaborator') {
        teamMembers = team.get('members');
      }

      const {
        teams
      } = this.modelFor('protected'); // The `.reload()` calls here ensure fresh data and also
      // clear the cached ember data relationship errors so that
      // another request is made even if a 2fa request initially fails
      // and this transition is retried

      return (0, _rsvp.hash)({
        app,
        appPermissions,
        team,
        collaborators: app.get('collaborators').reload(),
        teamMembers,
        // https://github.com/emberjs/data/issues/1913
        buildpackInstallations: app.hasMany('buildpackInstallations').reload(),
        configVars: app.belongsTo('configVars').reload(),
        formations: app.hasMany('formations').reload(),
        teams
      });
    },

    afterModel() {
      this.analytics.logEvent('App Settings Page', 'Viewed');
    }

  });

  _exports.default = _default;
});