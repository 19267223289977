define("dashboard/templates/components/app-list-pipeline-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9+QwnEiY",
    "block": "[[[10,0],[14,0,\"flex items-center flex-auto pv2 limit-width\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@name\",\"@title\",\"@iconClass\",\"@size\"],[\"pipeline-28\",\"Pipeline\",\"malibu-fill-gradient-purple w3 mr1\",28]],null],[1,\"\\n\\n    \"],[10,1],[14,0,\"f3 near-black pipeline-name\"],[12],[1,[30,0,[\"pipeline\",\"name\"]]],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"omitAppsDropDown\"]]],[[[1,\"      \"],[10,0],[14,0,\"app-count f5 gray\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"pipeline\",\"showTitle\",\"spaceName\"],[[30,0,[\"pipeline\"]],false,[30,0,[\"spaceName\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"mh3 items-center flex-l dn\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@isEnterpriseTeam\",\"@hasFreeDynos\",\"@hasFreeData\"],[[30,0,[\"isEnterpriseTeam\"]],[30,0,[\"hasAppsWithFreeDynos\"]],[30,0,[\"hasAppsWithFreeData\"]]]],[[\"separator\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasAppsWithDeprecatedStack\"]],[[[1,\"            \"],[10,1],[14,0,\"gray db-l dn mh1\"],[12],[1,\"•\"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"hasAppsWithDeprecatedStack\"]],[[[6,[39,5],null,[[\"title\"],[[30,0,[\"tooltipTitle\"]]]],[[\"default\"],[[[[1,\"        \"],[10,1],[14,0,\"link red flex items-center\"],[12],[1,\"\\n          \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"info-badge-16\",\"mr1 malibu-fill-gradient-red\",16]],null],[1,\"\\n          \"],[1,[30,0,[\"oldestStackDeprecation\",\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,6],null,[[\"isVisible\",\"item\"],[[30,0,[\"showFavoriteItem\"]],[30,0,[\"pipeline\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"unless\",\"pipeline/apps-dropdown\",\"efp-app-list-free-resources-warning\",\"if\",\"x-tooltip\",\"favorite-item\"]]",
    "moduleName": "dashboard/templates/components/app-list-pipeline-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});