define("dashboard/mixins/adapters/session-invalidating-mixin", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    // When the response from a request to the Heroku API is a 401 status, we
    // assume the user's auth has expired and send them through the reauth flow.
    // There are some exceptions to this rule, as specified in this function.
    ensureResponseAuthorized(status, headers, payload) {
      const IGNORE_REGEXES = [/invalid_two_factor_code/, /invalid_password/, /Admin access to repository required/, /unauthorized-github/];
      let ignoreError;

      if (payload) {
        const payloadId = payload.id || null;
        const payloadMessage = payload.message || null;
        ignoreError = IGNORE_REGEXES.some(function (regex) {
          return regex.test(payloadId) || regex.test(payloadMessage);
        });
      }

      if (status === 401 && this.get('session.isAuthenticated') && !ignoreError) {
        this.session.invalidate();
      }
    }

  });

  _exports.default = _default;
});