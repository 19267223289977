define("dashboard/serializers/enterprise-account", ["exports", "dashboard/serializers/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships(modelClass, resourceHash) {
      const enterpriseAccountName = resourceHash.name;
      const enterpriseAccountId = resourceHash.id;
      const identityProvider = resourceHash.identity_provider;
      const result = {};
      result.members = {
        links: {
          related: `/enterprise-accounts/${enterpriseAccountName}/members`
        }
      };
      result.currentLicenses = {
        links: {
          related: `/enterprise-accounts/${enterpriseAccountId}/current_licenses`
        }
      };
      result.historicalLicenseUsage = {
        links: {
          related: `/enterprise-accounts/${enterpriseAccountId}/license_usage`
        }
      };
      result.teams = {
        links: {
          related: `/enterprise-accounts/${enterpriseAccountName}/teams`
        }
      };
      result.auditTrailArchives = {
        links: {
          related: `/enterprise-accounts/${enterpriseAccountId}/archives`
        }
      };
      result.githubAppInstallation = {
        links: {
          related: `/enterprise-accounts/${enterpriseAccountId}/github/installation`
        }
      };
      result.preference = {
        links: {
          related: `${_environment.default.particleboardUrl}/enterprise-accounts/${enterpriseAccountId}/preferences`
        }
      };
      result.mfaRequired = {
        links: {
          related: `${_environment.default.platformApiUrl}/enterprise-accounts/${enterpriseAccountId}/preferences`
        }
      };
      result.githubAppSettings = {
        links: {
          related: `/enterprise-accounts/${enterpriseAccountId}/github/settings`
        }
      };

      if (identityProvider) {
        result.identityProvider = {
          data: {
            id: identityProvider.id,
            type: 'identity-provider'
          }
        };
      }

      return result;
    }

  });

  _exports.default = _default;
});