define("dashboard/models/app-preference", ["exports", "@ember-data/model", "@ember/object/computed", "@ember/object"], function (_exports, _model, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    preferences: (0, _model.attr)(),
    metricsChartOrder: (0, _computed.alias)('preferences.metrics-chart-order'),
    collapsedMetricsCharts: (0, _computed.alias)('preferences.collapsed-metrics-charts'),
    metricsSetUpRuby: (0, _computed.alias)('preferences.ruby-metrics-set-up'),
    metricsSetUpNode: (0, _computed.alias)('preferences.node-metrics-set-up'),
    metricsSetUpGo: (0, _computed.alias)('preferences.go-metrics-set-up'),
    metricsSetUpJava: (0, _computed.alias)('preferences.java-metrics-set-up'),
    latestProcessViewed: (0, _object.computed)('preferences.latest-viewed-process-metrics', {
      get() {
        return this.get('preferences.latest-viewed-process-metrics') || 'web';
      },

      set(_, value) {
        this.set('preferences.latest-viewed-process-metrics', value);
      }

    })
  });

  _exports.default = _default;
});