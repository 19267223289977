define("dashboard/components/pipeline/apps-dropdown", ["exports", "@ember/object", "@ember/object/computed", "@ember/component"], function (_exports, _object, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    showTitle: true,
    withPoint: true,
    menuClass: '',
    appsCount: (0, _object.computed)('developmentCouplingApps.[]', 'stagingCouplingApps.[]', 'productionCouplingApps.[]', 'reviewApps.[]', function () {
      return this.get('developmentCouplingApps.length') + this.get('stagingCouplingApps.length') + this.get('productionCouplingApps.length') + this.get('reviewApps.length');
    }),
    buttonContent: (0, _object.computed)('appsCount', function () {
      const count = this.appsCount;
      return `${count} ${count === 1 ? 'app' : 'apps'}`;
    }),
    developmentCouplingApps: (0, _object.computed)('pipeline.developmentCouplingApps.[]', 'spaceName', function () {
      if (this.spaceName) {
        return this.get('pipeline.developmentCouplingApps').filterBy('space.name', this.spaceName).sortBy('name');
      }

      return this.get('pipeline.developmentCouplingApps').sortBy('name');
    }),
    stagingCouplingApps: (0, _object.computed)('pipeline.stagingCouplingApps.[]', 'spaceName', function () {
      if (this.spaceName) {
        return this.get('pipeline.stagingCouplingApps').filterBy('space.name', this.spaceName).sortBy('name');
      }

      return this.get('pipeline.stagingCouplingApps').sortBy('name');
    }),
    productionCouplingApps: (0, _object.computed)('pipeline.productionCouplingApps.[]', 'spaceName', function () {
      if (this.spaceName) {
        return this.get('pipeline.productionCouplingApps').filterBy('space.name', this.spaceName).sortBy('name');
      }

      return this.get('pipeline.productionCouplingApps').sortBy('name');
    }),
    legacyReviewApps: (0, _computed.readOnly)('pipeline.reviewCouplingApps'),
    newReviewApps: (0, _computed.mapBy)('pipeline.reviewApps', 'app'),
    hasNewReviewAppsEnabled: (0, _computed.readOnly)('pipeline.hasNewReviewAppsEnabled'),
    reviewApps: (0, _object.computed)('spaceName', 'hasNewReviewAppsEnabled', 'legacyReviewApps.[]', 'newReviewApps.[]', function () {
      let reviewApps = this.hasNewReviewAppsEnabled ? this.newReviewApps : this.legacyReviewApps;

      if (this.spaceName) {
        reviewApps = reviewApps.filterBy('space.name');
      }

      return reviewApps.sortBy('name');
    }),
    actions: {
      showReviewApps() {
        this.toggleProperty('isShowingReviewApps');
      }

    }
  });

  _exports.default = _default;
});