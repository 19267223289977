define("dashboard/routes/app/metrics/type", ["exports", "@ember/utils", "@ember/service", "dashboard/routes/basic-app", "dashboard/mixins/preferences", "rsvp"], function (_exports, _utils, _service, _basicApp, _preferences, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend(_preferences.default, {
    analytics: (0, _service.inject)(),
    metrics: (0, _service.inject)(),

    get fullDocumentTitle() {
      const {
        app
      } = this.modelFor('app');
      return `${app.get('name')} · Metrics | Heroku`;
    },

    beforeModel() {
      const {
        app
      } = this.modelFor('app');
      this.set('app', app);
      const params = this.paramsFor('app.metrics.type');
      const appPreferences = this.appPreferences;

      if (appPreferences && appPreferences.get('latestProcessViewed') !== params.type) {
        appPreferences.set('latestProcessViewed', params.type);
        appPreferences.save();
      }

      return app.get('formations').then(formations => {
        const formation = formations.findBy('type', params.type);

        if ((0, _utils.isEmpty)(formation) || !app.get('metricsEnabled')) {
          this.replaceWith('app.metrics.index');
        }
      });
    },

    model(params) {
      const {
        app,
        appPermissions
      } = this.modelFor('app'); // Space on app model is just an attr(), rather than a relationship. We must fetch the space record using the id.

      const spaceAttr = app.get('space');
      const space = spaceAttr ? this.store.findRecord('space', spaceAttr.id).catch(() => {
        return {
          cannotAccess: true
        };
      }) : null;
      return (0, _rsvp.hash)({
        metricsLoaded: this.metrics.setup(app, space, params.type),
        app,
        appPermissions,
        space,
        type: params.type
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      if (!controller.isRefreshing) {
        controller.set('isRefreshing', true);

        controller._startTimer();
      }

      this.metrics.updateTimeframe(controller.beforeHours, controller.afterHours);
      this.metrics.start();
    },

    deactivate() {
      this._stopTimer();

      this.metrics.stop();

      this._super(...arguments);
    },

    _stopTimer() {
      this.controllerFor('app/metrics/type')._stopTimer();
    },

    actions: {
      error(error) {
        if (error.status === 503 || error.statusText === 'timeout') {
          return true;
        } else {
          this.intermediateTransitionTo('protected.error', error);
        }
      }

    }
  });

  _exports.default = _default;
});