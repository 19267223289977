define("dashboard/templates/components/confirm-delete-addon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ogzks9BQ",
    "block": "[[[10,0],[14,0,\"addons-delete-confirm\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mb2\"],[12],[1,\"\\n    Enter your app's name (\"],[10,\"code\"],[12],[1,[30,0,[\"app\",\"name\"]]],[13],[1,\") to confirm you want to permanently delete \"],[10,\"b\"],[12],[1,[30,0,[\"addon\",\"addonService\",\"humanName\"]]],[13],[1,\" and associated data:\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"addons-delete-confirm__body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"type\",\"class\",\"value\"],[\"text\",\"form-control center-block confirm-addon-delete-app\",[30,0,[\"appNameConfirmation\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"input\"]]",
    "moduleName": "dashboard/templates/components/confirm-delete-addon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});