define("dashboard/components/datastore/combined-datastore-chart-summary", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/chart-header/chart-summary", "@ember/object"], function (_exports, _computed, _chartSummary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _chartSummary.default.extend({
    primaryUnit: readOnly('data.unit'),
    numberFormat: readOnly('data.format'),
    hasSufficientData: true,
    inMaxValue: (0, _object.computed)(function () {
      return _object.default.create({
        title: `Maximum`,
        label: 'max (in)',
        primaryValue: this.get('data.inMax') < 1 ? '0' : this.get('data.inMax')
      });
    }),
    inMinValue: (0, _object.computed)(function () {
      return _object.default.create({
        title: `Minimum`,
        label: 'min (in)',
        primaryValue: this.get('data.inMin') < 1 ? '0' : this.get('data.inMin')
      });
    }),
    outMaxValue: (0, _object.computed)(function () {
      return _object.default.create({
        title: `Maximum`,
        label: 'max (out)',
        primaryValue: this.get('data.outMax') < 1 ? '0' : this.get('data.outMax')
      });
    }),
    outMinValue: (0, _object.computed)(function () {
      return _object.default.create({
        title: `Minimum`,
        label: 'min (out)',
        primaryValue: this.get('data.outMin') < 1 ? '0' : this.get('data.outMin')
      });
    }),
    chartSummaryItems: (0, _computed.collect)('inMinValue', 'inMaxValue', 'outMinValue', 'outMaxValue')
  });

  _exports.default = _default;
});