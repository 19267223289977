define("dashboard/controllers/new-app", ["exports", "@ember/controller", "dashboard/mixins/new-app"], function (_exports, _controller, _newApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_newApp.default, {
    actions: {
      /**
       * saves a standard non-template application
       */
      save() {
        const app = this.app;
        const pipeline = this.selectedPipelineModel;

        if (this.selectedOrg) {
          app.set('team', this.selectedOrg);
        } // If theres a space selected, set it, otherwise set the region to the
        // selected region


        if (this.selectedSpace && this.spaceNameParam) {
          app.set('space', this.get('selectedSpace.name'));
        } else {
          app.set('region', this.selectedRegion);
        }

        app.save().then(savedApp => {
          this.analytics.logEvent('App', 'Created'); // Should the app delete/rollback if pipeline creation/coupling fails?

          this._savePipeline(pipeline).then(pl => {
            return this._coupleAppToPipeline(pl, app);
          }).then(() => {
            this.transitionToRoute('app.deploy', savedApp.get('name'));
          });
        }, reason => {
          if (/verify your account/i.test(reason.errors[0].detail)) {
            this.requestVerification();
          }
        });
      }

    }
  });

  _exports.default = _default;
});