define("dashboard/models/review-app", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _model, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATUS = void 0;
  const PENDING = 'pending';
  const CREATING = 'creating';
  const CREATED = 'created';
  const DELETED = 'deleted';
  const DELETING = 'deleting';
  const ERRORED = 'errored';
  const STATUS = {
    PENDING,
    CREATING,
    CREATED,
    DELETED,
    DELETING,
    ERRORED
  };
  _exports.STATUS = STATUS;
  const DEFAULT_ERROR_MESSAGE = 'There was an unknown problem with this review app';

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    appSetup: (0, _model.belongsTo)('app-setup'),
    branch: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    errorMessage: (0, _object.computed)('message', 'isErrored', 'appSetup.failureMessage', function () {
      if (!this.isErrored) {
        return;
      }

      return this.message || this.get('appSetup.failureMessage') || DEFAULT_ERROR_MESSAGE;
    }),
    errorStatus: (0, _model.attr)('string'),
    hasApp: (0, _computed.bool)('app.createdAt'),
    hasAppSetup: (0, _computed.bool)('appSetup.id'),
    message: (0, _model.attr)('string'),
    pipeline: (0, _model.belongsTo)('pipeline'),
    sha: (0, _model.attr)('string'),
    sourceBlobUrl: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    forkRepoId: (0, _model.attr)('number'),
    prNumber: (0, _model.attr)('number'),
    currentRelease: (0, _computed.readOnly)('app.currentRelease'),
    currentBuild: (0, _computed.readOnly)('app.currentBuild'),
    isPending: (0, _computed.equal)('status', STATUS.PENDING),
    isNotPending: (0, _computed.not)('isPending'),
    isCreating: (0, _computed.equal)('status', STATUS.CREATING),
    isCreated: (0, _computed.equal)('status', STATUS.CREATED),
    isNotCreated: (0, _computed.not)('isCreated'),
    isDeleting: (0, _computed.equal)('status', STATUS.DELETING),
    statusIsDeleted: (0, _computed.equal)('status', STATUS.DELETED),
    isErrored: (0, _computed.equal)('status', STATUS.ERRORED),
    isErroredPending: (0, _computed.equal)('errorStatus', STATUS.PENDING),
    isErroredCreating: (0, _computed.equal)('errorStatus', STATUS.CREATING),
    isErroredCreated: (0, _computed.equal)('errorStatus', STATUS.CREATED),
    isErroredDeleting: (0, _computed.equal)('errorStatus', STATUS.DELETING),
    isCompleted: (0, _computed.or)('isErrored', 'statusIsDeleted', 'isCreated'),
    // Any review apps without a PR should come after those with a PR so that
    // they are displayed first in the review stage
    prNumberSortKey: (0, _object.computed)('pullRequestNumber', function () {
      return this.pullRequestNumber || Infinity;
    }),
    pullRequestNumber: (0, _computed.readOnly)('pullRequest.number'),
    pullRequest: (0, _object.computed)('pipeline.pullRequests.@each.number', 'prNumber', function () {
      const pullReqs = this.get('pipeline.pullRequests');

      if ((0, _utils.isEmpty)(pullReqs)) {
        return;
      }

      return pullReqs.findBy('number', this.prNumber);
    })
  });

  _exports.default = _default;
});