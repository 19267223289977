define("dashboard/components/process-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: ['aria-hidden', 'title'],
    iconName: (0, _object.computed)('process.size', 'process.app.space', 'process.app.space.shield', 'process.name', 'process.processTier', // eslint-disable-next-line complexity
    function () {
      const size = this.get('process.size');
      let name = '';

      if (/free$/i.test(size)) {
        name = 'dyno-free';
      } else if (/eco/i.test(size)) {
        name = 'dyno-eco';
      } else if (/hobby/i.test(size)) {
        name = 'dyno-hobby';
      } else if (/basic/i.test(size)) {
        name = 'dyno-basic';
      } else if (/1x$/i.test(size)) {
        name = 'dyno-1x';
      } else if (/2x$/i.test(size)) {
        name = 'dyno-2x';
      } else if (/px$/i.test(size)) {
        name = 'dyno-px';
      } else if (/P.+\-S$/i.test(size)) {
        name = 'dyno-ps';
      } else if (/(P|S).+\-M$/i.test(size)) {
        name = 'dyno-pm';
      } else if (/(P|S).+\-L$/i.test(size)) {
        name = 'dyno-pl';
      } // special icons for space/shield processes


      const process = this.get('process');

      if (process) {
        if (process.name?.includes('Shield') || process.processTier === 'shield') {
          name = name.replace('dyno', 'dyno-shield');
        } else if (process.name?.includes('Private') || process.processTier === 'private') {
          name = name.replace('dyno', 'dyno-space');
        }
      }

      if (name) {
        return `${name}`;
      }
    })
  });

  _exports.default = _default;
});