define("dashboard/mixins/load-more-records", ["exports", "@ember/object/mixin", "ember-concurrency", "@ember/array"], function (_exports, _mixin, _emberConcurrency, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadMoreRelatedRecords = loadMoreRelatedRecords;

  function loadMoreRelatedRecords(modelName, idKey) {
    return (0, _emberConcurrency.task)(function* (snapshot) {
      yield this.store.query(modelName, {
        [idKey]: snapshot.record.id,
        page: 2
      });
    });
  }

  var _default = _mixin.default.create({
    handleResponse(status, headers, payload, requestData) {
      if ((0, _array.isArray)(payload)) {
        payload = {
          data: payload,
          hasMore: status === 206
        };
      }

      return this._super(status, headers, payload, requestData);
    },

    loadMoreRecords: (0, _emberConcurrency.task)(function* (modelName, query) {
      query.page += 1;
      yield this.store.query(modelName, query);
    })
  });

  _exports.default = _default;
});