define("dashboard/components/confirm/confirmable-action", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bool
  } = _object.computed;

  var _default = _component.default.extend({
    // making this a span ensures that subsequent insertion of the modal's anchor
    // into the DOM doesn't cause mis-alignment of adjacent elements
    tagName: 'span',
    classNames: ['confirmable-action'],
    isModalVisible: false,
    confirmationValueInput: null,
    // if no buttonText is provided, then we don't show the button in the template
    hasButton: bool('buttonText'),
    modalActionButtonClassNames: (0, _object.computed)(function () {
      return 'hk-button--danger-primary';
    }),
    modalCancelButtonClassNames: (0, _object.computed)(function () {
      return 'hk-button--secondary mr2';
    }),
    buttonTestTarget: (0, _object.computed)(function () {
      return 'confirmable-button';
    }),
    confirmationDoesNotMatch: (0, _object.computed)('confirmationValueInput', 'confirmationTargetValue', function () {
      // if the component was invoked with a value in confirmationTargetValue then
      // we want to validate that value against the value in the confirmation modal's
      // input before allowing the modal to be submitted
      if ((0, _utils.isPresent)(this.confirmationTargetValue)) {
        return this.confirmationValueInput !== this.confirmationTargetValue;
      } else {
        // otherwise nothing to validate against, so assume the modal can be submitted
        return false;
      }
    }),
    actions: {
      toggleConfirmModal() {
        this.set('confirmationValueInput', null);
        this.toggleProperty('isModalVisible');
      },

      // this action is optionally invoked by the child component
      // that component passes in the current value of its input which we can use
      // in the confirmationDoesNotMatch computed property to validate the form
      validate(value) {
        this.set('confirmationValueInput', value);
      }

    }
  });

  _exports.default = _default;
});