define("dashboard/templates/team/switchable/billing/invoice-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FN4g7ja2",
    "block": "[[[10,\"tr\"],[14,0,\"invoice-row\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"invoice-title\"],[12],[1,[30,0,[\"invoice\",\"title\"]]],[13],[1,\"\\n  \"],[10,\"td\"],[15,0,[29,[[52,[30,0,[\"invoice\",\"overDynoUnitQuota\"]],\"over-quota\"],\" invoice-dyno-usage text-right\"]]],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"invoice\",\"dynoUnitUsage\"]]],[[\"format\"],[\"0,0.00\"]]]],[1,\"\\n    \"],[10,\"small\"],[12],[1,\"\\n      of \"],[1,[28,[35,1],[[30,0,[\"invoice\",\"dynoUnitQuota\"]]],[[\"format\"],[\"0,0.00\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[15,0,[29,[[52,[30,0,[\"invoice\",\"overAddonsQuota\"]],\"over-quota\"],\" invoice-addon-total text-right\"]]],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"invoice\",\"addonUsage\"]]],[[\"unit\"],[\"cents\"]]]],[1,\"\\n    \"],[10,\"small\"],[12],[1,\"\\n      of \"],[1,[28,[35,2],[[30,0,[\"invoice\",\"addonQuota\"]]],[[\"unit\"],[\"cents\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"number-helper\",\"currency-format\"]]",
    "moduleName": "dashboard/templates/team/switchable/billing/invoice-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});