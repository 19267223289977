define("dashboard/services/favorites", ["exports", "@ember/object", "@ember/service"], function (_exports, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    gt
  } = _object.computed;
  /**
   * Your favorite service!
   */

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    favorites: (0, _object.computed)(function () {
      return this.store.findAll('favorite');
    }),
    hasFavorites: gt('favorites.length', 0),

    // Returns a favorite for the given type and ID if it is a favorite.
    favoriteFor(type, id) {
      return this._favoritesByTypeAndId[`${type}:${id}`];
    },

    // Sets a `favorite` instance on each favorite app item.
    applyAppFavorites(items) {
      return this._applyFavoritesForType('app', items);
    },

    // Sets a `favorite` instance on each favorite pipeline item.
    applyPipelineFavorites(items) {
      return this._applyFavoritesForType('pipeline', items);
    },

    // Adds favorites to each favorited item of the given type.
    _applyFavoritesForType(type, items) {
      items.forEach(item => {
        const favorite = this.favoriteFor(type, item.get('id'));
        item.set('favorite', favorite);
      });
      return items;
    },

    // Generates a hash for quick lookup of a favorite object by type and resource ID.
    // The hash is keyed by a String in the form of `${type}:${resourceId}`.
    _favoritesByTypeAndId: (0, _object.computed)('favorites.[]', function () {
      const favorites = this.favorites;
      return favorites.reduce((lookup, favorite) => {
        lookup[`${favorite.get('type')}:${favorite.get('resourceId')}`] = favorite;
        return lookup;
      }, {});
    })
  });

  _exports.default = _default;
});