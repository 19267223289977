define("dashboard/components/metrics/charts/line-chart-elements/horizontal-line", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: 'line',
    classNames: ['metrics__timeseries-chart__line'],
    attributeBindings: ['x1', 'x2', 'y1', 'y2'],
    x1: 0,
    x2: readOnly('width'),
    y1: readOnly('height'),
    y2: readOnly('height'),
    height: (0, _object.computed)('yAxisScale', 'value', function () {
      return this.yAxisScale(this.value);
    }),
    yAxisScale: (0, _object.computed)('useAlternateScale', 'altValueScale', 'valueScale', function () {
      return this.useAlternateScale ? this.altValueScale : this.valueScale;
    })
  });

  _exports.default = _default;
});