define("dashboard/templates/components/app/settings/buildpack-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QddMaM6A",
    "block": "[[[10,0],[14,0,\"form-group u-margin-Bn\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"Enter Buildpack URL\"],[13],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"type\",\"inputClass\",\"value\",\"placeholder\",\"model\",\"validatingKey\",\"errorIsVisible\"],[\"text\",\"form-control\",[30,0,[\"item\",\"name\"]],\"e.g. heroku/nodejs or https://github.com/heroku/heroku-buildpack-ruby\",[30,0,[\"item\"]],\"name\",[30,0,[\"item\",\"errorIsVisible\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Or select from our officially supported buildpacks\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"modal-buildpacks\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"officialBuildpacks\"]]],null]],null],null,[[[1,\"        \"],[11,3],[24,6,\"#\"],[16,0,[29,[\"hk-link supported-buildpack \",[52,[30,1,[\"isSelected\"]],\"selected\"]]]],[4,[38,4],[[30,0],\"buildpackButtonClicked\",[30,1,[\"shorthandName\"]]],null],[12],[1,\"\\n          \"],[10,0],[14,0,\"language-icon\"],[12],[1,\"\\n            \"],[1,[28,[35,5],null,[[\"language\",\"size\",\"iconClass\"],[[30,1,[\"languageName\"]],\"28\",\"malibu-fill-gradient-purple\"]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[1,[30,1,[\"languageName\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"buildpack\"],false,[\"cp-validating-input\",\"each\",\"-track-array\",\"if\",\"action\",\"buildpack-icon\"]]",
    "moduleName": "dashboard/templates/components/app/settings/buildpack-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});