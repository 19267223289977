define("dashboard/templates/components/install-github-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3zGLTZ+/",
    "block": "[[[1,\"  \"],[11,\"button\"],[16,0,[30,0,[\"buttonClass\"]]],[16,\"disabled\",[30,0,[\"disabledClass\"]]],[24,4,\"button\"],[4,[38,0],[[30,0],\"performConnectTask\",[30,0,[\"herokuResourceType\"]],[30,0,[\"herokuResourceId\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"connect\",\"isIdle\"]],[[[1,\"      \"],[10,1],[14,0,\"mr1\"],[12],[1,[30,0,[\"buttonText\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"buttonIcon\"]],[[[1,\"        \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[28,[37,3],[[30,0,[\"buttonIcon\"]],\"-16\"],null],\"fill-white\",16]],null],[1,\"\\n\"]],[]],null]],[]],[[[1,\"      \"],[1,[34,4]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[],false,[\"action\",\"if\",\"malibu-icon\",\"concat\",\"malibu-loading-spinner\"]]",
    "moduleName": "dashboard/templates/components/install-github-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});