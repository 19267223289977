define("dashboard/mixins/pipeline-ownership", ["exports", "@ember/array", "@ember/object/mixin", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/utils", "dashboard/models/pipeline-owner"], function (_exports, _array, _mixin, _object, _service, _computed, _utils, _pipelineOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    accountFeatures: (0, _service.inject)(),
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    cachedTeams: (0, _object.computed)(function () {
      return this.store.peekAll('team');
    }),
    apps: (0, _computed.reads)('pipeline.apps'),
    teams: (0, _computed.reads)('cachedTeams'),
    pipelineOwnerId: (0, _computed.readOnly)('pipeline.owner.id'),
    pipelineOwnerType: (0, _computed.readOnly)('pipeline.owner.type'),
    enterpriseAccounts: (0, _object.computed)(function () {
      return this.store.peekAll('enterprise-account');
    }),
    groupByEnterpriseAccounts: (0, _computed.gt)('enterpriseAccounts.length', 1),
    currentOwner: (0, _object.computed)('pipelineOwnerId', 'pipelineOwnerType', function () {
      const id = this.pipelineOwnerId;
      const type = this.pipelineOwnerType;

      if ((0, _utils.isNone)(id) || (0, _utils.isNone)(type)) {
        return;
      }

      return this.buildOwner({
        type,
        id
      });
    }),
    allTeamOwners: (0, _object.computed)('teams', function () {
      return this.teams.filterBy('canManagePipelines').map(team => {
        return this.buildOwner({
          type: 'team',
          enterpriseAccountId: team.get('enterprise.id'),
          id: team.get('id'),
          email: team.get('email'),
          name: team.get('name'),
          role: team.get('role')
        });
      });
    }),
    currentUserOwner: (0, _object.computed)('current.account.id', 'current.account.email', function () {
      return this.buildOwner({
        type: 'user',
        id: this.get('current.account.id'),
        email: this.get('current.account.email'),
        name: this.get('current.account.name')
      });
    }),
    owners: (0, _object.computed)('currentOwner', 'allTeamOwners', 'currentUserOwner', function () {
      return (0, _array.A)([...this.allTeamOwners, this.currentOwner, this.currentUserOwner]).compact().uniqBy('id');
    }),
    noPipelineOwner: (0, _computed.empty)('pipelineOwnerId'),
    pipelineOwner: (0, _object.computed)('owners.@each.id', 'pipelineOwnerId', function () {
      return this.owners.findBy('id', this.pipelineOwnerId);
    }),

    buildOwner() {
      let {
        type = 'user',
        enterpriseAccountId,
        id,
        name,
        email,
        role
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      const owner = _pipelineOwner.default.create({
        type,
        enterpriseAccountId,
        id,
        name,
        email,
        role
      });

      if (type === 'user') {
        const account = this.get('current.account');

        if (id === (0, _object.get)(account, 'id')) {
          owner.setProperties({
            email: (0, _object.get)(account, 'email'),
            name: (0, _object.get)(account, 'name')
          });
        }
      }

      return owner;
    }

  });

  _exports.default = _default;
});