define("dashboard/components/metrics/charts/visualizations/wrapped-row-chart", ["exports", "@ember/component", "dashboard/mixins/metrics/current-timeframe", "@ember/object/computed", "@ember/object", "dashboard/templates/components/metrics/charts/visualizations/wrapped-row-chart"], function (_exports, _component, _currentTimeframe, _computed, _object, _wrappedRowChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_currentTimeframe.default, {
    layout: _wrappedRowChart.default,
    classNameBindings: [':metrics__wrapped-chart', ':metrics__events-chart', 'isReady'],
    currentTimeframe: (0, _computed.readOnly)('timeframe'),
    rowEvents: [],
    errorEvents: [],
    chartContentData: (0, _object.computed)('rowEvents', 'errorEvents', function () {
      const rowEvents = this.rowEvents;
      const errorEvents = this.errorEvents;
      return _object.default.create({
        rowEvents,
        errorEvents
      });
    }),
    isNotRefreshingData: (0, _computed.not)('isRefreshingData'),
    isShowingLoadingState: (0, _computed.and)('isLoadingData', 'isNotRefreshingData')
  });

  _exports.default = _default;
});