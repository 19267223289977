define("dashboard/models/user-preferences", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    timezone: (0, _model.attr)('string'),
    defaultOrganization: (0, _model.attr)('string'),
    dismissedPipelinesGithubBanners: (0, _model.attr)(),

    update(key, value) {
      this.set(key, value);
      this.save();
    }

  });

  _exports.default = _default;
});