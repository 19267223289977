define("dashboard/components/list-group-item-purple2", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    classNames: 'list-group-item',
    enableItem: true,
    showDevCenterLink: (0, _computed.notEmpty)('devCenterUrl'),
    disabledTitle: (0, _object.computed)('title', function () {
      return `${this.title} management is not available`;
    })
  });

  _exports.default = _default;
});