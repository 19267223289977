define("dashboard/components/github-linker", ["exports", "@ember/service", "@ember/component", "@ember/object", "dashboard/config/environment"], function (_exports, _service, _component, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    githubClientId
  } = _environment.default;
  const {
    mapBy,
    oneWay,
    bool,
    sort
  } = _object.computed;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    classNames: 'github-linker',
    user: null,
    repos: (0, _object.computed)(function () {
      return [];
    }),
    orgs: (0, _object.computed)(function () {
      return [];
    }),
    searchTerm: '',
    selectedOwnerLogin: oneWay('user.login'),
    selectedOwner: (0, _object.computed)('owners.@each.login', 'selectedOwnerLogin', function () {
      return this.owners.findBy('login', this.selectedOwnerLogin);
    }),
    orgSorting: ['login'],
    sortedOrgs: sort('orgs', 'orgSorting'),
    owners: (0, _object.computed)('user', 'sortedOrgs.[]', function () {
      return [this.user].concat(this.sortedOrgs).compact();
    }),
    ownerLogins: mapBy('owners', 'login'),
    hasTooManyOrgs: bool('orgs.meta.hasMore'),
    thirdPartyUrl: (0, _object.computed)(function () {
      return `https://github.com/settings/connections/applications/${githubClientId}`;
    }),
    queryParams: (0, _object.computed)('selectedOwnerLogin', 'searchTerm', 'pipeline.id', function () {
      const query = {
        q: `fork:true user:${this.selectedOwnerLogin} ${this.searchTerm}`
      };

      if (this.get('pipeline.id')) {
        query.pipelineId = this.get('pipeline.id');
      }

      if (this.get('pipeline.isGithubAppEnabled')) {
        query.isGithubAppEnabled = true;
      }

      return query;
    }),
    actions: {
      search() {
        this.set('repos', []);
        this.set('lastSearchTerm', null);
        this.store.query('repositories-api-github-repo', this.queryParams).then(repos => {
          const lastSearchTerm = `${this.selectedOwnerLogin}/${this.searchTerm}`;
          this.set('lastSearchTerm', lastSearchTerm);
          this.set('repos', repos.toArray());
        });
      }

    }
  });

  _exports.default = _default;
});