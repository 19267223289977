define("dashboard/routes/login", ["exports", "@ember/service", "@ember/routing/route", "dashboard/config/environment", "dashboard/utils/oauth"], function (_exports, _service, _route, _environment, _oauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    location: (0, _service.inject)(),

    afterModel()
    /* transition*/
    {
      const redirectBase = _environment.default.appHost;
      const callbackPath = '/auth/heroku/callback';
      const callbackURL = `${redirectBase}${callbackPath}`;
      const state = (0, _oauth.getState)();
      const params = {
        redirect_uri: callbackURL,
        state
      };
      const encodedParams = Object.entries(params).map(_ref => {
        let [key, value] = _ref;
        return `${key}=${encodeURIComponent(value)}`;
      }).join('&');
      const loginUrl = `${_environment.default.loginUrl}/login?${encodedParams}`;
      this.location.assign(loginUrl);
    }

  });

  _exports.default = _default;
});