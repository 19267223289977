define("dashboard/components/draggable-target", ["exports", "@ember/component", "@ember/utils"], function (_exports, _component, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['draggable-target'],
    classNameBindings: ['isDraggedOver:is-dragged-over'],

    dragLeave(ev) {
      ev.preventDefault();
      this.set('isDraggedOver', false);
    },

    dragOver(ev) {
      ev.preventDefault();
      this.set('isDraggedOver', true);
    },

    drop(ev) {
      const dataTransfer = ev.dataTransfer;
      const data = (0, _utils.isPresent)(dataTransfer) ? dataTransfer.getData('text/data') : null;
      this.attrs.onDrop(data);
      this.set('isDraggedOver', false);
    }

  });

  _exports.default = _default;
});