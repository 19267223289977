define("dashboard/mixins/document-title", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A mixin for routes that need to set `document.title`. The route must
   * implement its own `documentTitle` property.
   *
   *     Dashboard.FooRoute = Ember.Route.extend(
   *       Dashboard.DocumentTitle, {
   *
   *       documentTitle: function() {
   *         return 'Foo'; // "Foo | Heroku"
   *       }.property().volatile()
   *     });
   *
   * Routes with a "." in their route name will have the final part of the route
   * appended to the document title:
   *
   *     // "app.logs"
   *     Dashboard.AppLogsRoute = Ember.Route.extend(
   *       Dashboard.DocumentTitle, {
   *
   *       documentTitle: function() {
   *         return 'App'; // "App · Logs | Heroku"
   *       }.property().volatile()
   *     });
   *
   * Be very careful with using computed properties in `documentTitle`. It is
   * typically best to make this property volatile, so that it is computed for
   * every transition.
   *
   * @class Dashboard.DocumentTitle
   *
   * example: "Org Name · Section | Heroku"
   * example: "App Name · Section | Heroku"
   * example: "Personal Apps | Heroku"
   * example: "Create a New App | Heroku"
   *
   */
  var _default = _mixin.default.create({
    documentTitleSeparator: ' · ',
    actions: {
      /**
       * sets document title on the most deeply nested route
       * after a full transition. The `titleIsSet` property
       * will be reset at the very last instantiation of this
       * event via the ProtectedRoute.
       *
       * Returns true so that the event can continue to propagate
       *
       */
      didTransition() {
        const protectedController = this.controllerFor('protected');

        if (!protectedController.get('titleIsSet')) {
          this.setDocumentTitle();
          protectedController.set('titleIsSet', true);
        }

        return true;
      }

    },

    setDocumentTitle() {
      document.title = this.fullDocumentTitle;
    },

    get fullDocumentTitle() {
      let title;

      try {
        title = this.documentTitle;
      } catch {// unable to fetch title, this can occur when ember tries to access the property
        // early in setup but the owner has not be set on the class,
        // ie: getOwner(this) returns false
        // and an owner is needed for some titles in looking up this.modelFor
      }

      if (!title) {
        return 'Heroku';
      }

      if (/\./.test(this.routeName) && !/\.index$/.test(this.routeName)) {
        title += this.documentTitleSeparator;
        title += this.routeName.split('.').pop().capitalize();
      } // GitHub requires special casing from the default


      title = title.replace('Github', 'GitHub');
      return `${title} | Heroku`;
    }

  });

  _exports.default = _default;
});