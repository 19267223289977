define("dashboard/serializers/domain", ["exports", "@ember-data/serializer/rest", "dashboard/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      cname: {
        serialize: false
      },
      createdAt: {
        serialize: false
      },
      kind: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      },
      app: {
        serialize: false,
        deserialize: 'records'
      },
      acmStatus: {
        seralize: false
      },
      acmStatusReason: {
        seralize: false
      },
      sniEndpoint: {
        serialize: 'ids',
        deserialize: 'records',
        key: 'sni_endpoint'
      }
    },

    extractRelationships(modelClass, resourceHash) {
      const {
        sniEndpoint
      } = resourceHash;

      const relationships = this._super(...arguments);

      if (sniEndpoint && sniEndpoint.id) {
        relationships.sniEndpoint = {
          data: {
            id: sniEndpoint.id,
            type: 'sni-endpoint'
          }
        };
      }

      return relationships;
    }

  });

  _exports.default = _default;
});