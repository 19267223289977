define("dashboard/routes/account/billing/remove-credit-card", ["exports", "dashboard/routes/account/base", "@ember/service"], function (_exports, _base, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    store: (0, _service.inject)(),

    async model() {
      const {
        paymentMethod
      } = this.modelFor('account.billing'); // We need to unload payment-method apps in case someone
      // switched a paid app back to free/eco and immediately tries to remove cc.
      // If we don't unload, ember data hangs onto the payment-method app in the
      // store even if the findAll doesn't return that record.

      this.store.unloadAll('payment-method/app');
      const paidApps = await this.store.findAll('payment-method/app', {
        reload: true
      });
      return {
        paymentMethod,
        paidApps
      };
    },

    afterModel() {
      const {
        unverifyPaymentMethodRequest
      } = this.modelFor('account.billing');

      if (unverifyPaymentMethodRequest) {
        // already in progress; we can bounce them to the correct route
        if (unverifyPaymentMethodRequest.get('waitingForUserInput')) {
          this.transitionTo('account.billing.confirm-credit-card-removal');
        } else {
          // other conditions that should go back to billing:
          // unverifyPaymentMethodRequest.get('started')
          // unverifyPaymentMethodRequest.get('finished')
          // unverifyPaymentMethodRequest.get('error')
          // unverifyPaymentMethodRequest.get('processing')
          this.transitionTo('account.billing');
        }
      }
    },

    actions: {
      cancel() {
        this.transitionTo('account.billing');
      }

    }
  });

  _exports.default = _default;
});