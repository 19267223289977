define("dashboard/models/kolkrabbi/github-app-deploy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    build: attr(),
    branch: attr('string'),
    app: belongsTo('app')
  });

  _exports.default = _default;
});