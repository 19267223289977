define("dashboard/components/third-party-connection-list-item", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex', 'flex-wrap', 'justify-between', 'items-center', 'pv2'],
    classNameBindings: ['isFirst:bn:bt', 'isFirst:bn:b--silver'],
    analytics: (0, _service.inject)(),
    identiconType: (0, _object.computed)('herokuResourceType', function () {
      return this.herokuResourceType === 'enterprise-account' ? 'account' : '';
    }),
    hasGithubAppInstalled: (0, _computed.bool)('githubAppInstallation.id'),
    actions: {
      logEvent(noun, action) {
        this.analytics.logEvent(noun, action);
      }

    }
  });

  _exports.default = _default;
});