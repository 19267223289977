define("dashboard/components/metrics/charts/connected-chart-hover/formation-group", ["exports", "@ember/utils", "dashboard/components/metrics/charts/connected-chart-hover/activity-group", "@ember/object", "@ember/object/computed"], function (_exports, _utils, _activityGroup, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activityGroup.default.extend({
    _eventsAtSelectedTime: (0, _computed.filter)('modelsAtSelectedTime', function (model) {
      return (0, _utils.isPresent)(model.get('restartType'));
    }),
    eventModels: (0, _computed.map)('_eventsAtSelectedTime', function (model) {
      const restartType = model.get('restartType');
      const count = model.get('count');
      const description = 'User Initiated';
      let title;

      switch (restartType) {
        case 'scale-created':
          title = 'Autoscaling Enabled';
          break;

        case 'scale-updated':
          title = 'Autoscaling Settings Updated';
          break;

        case 'scale-enabled':
          title = 'Autoscaling Enabled';
          break;

        case 'scale-disabled':
          title = 'Autoscaling Disabled';
          break;
      }

      if (count > 1) {
        title += ` x${count}`;
      }

      return _object.default.create({
        title,
        description
      });
    }),
    horizontalScaleModels: (0, _computed.filterBy)('modelsAtSelectedTime', 'isHorizontalScale'),
    verticalScaleModels: (0, _computed.filterBy)('modelsAtSelectedTime', 'isVerticalScale'),

    /*
     * Filter out any autoscale events that are followed by another autoscale
     * event being scaled in the same direction
     */
    firstScaleEvent: (0, _computed.filter)('horizontalScaleModels', (_, index, models) => (0, _utils.isEmpty)(models.get(index - 1))),
    manualScaleEvents: (0, _computed.filterBy)('horizontalScaleModels', 'isAutoscaleEvent', false),
    directionChangeEvents: (0, _computed.filter)('horizontalScaleModels', function (model, index, models) {
      const previousModel = models.get(index - 1);
      return (0, _utils.isPresent)(previousModel) && model.get('scaleDirection') !== previousModel.get('scaleDirection');
    }),
    scaleEventsPrecededByManualEvents: (0, _computed.filter)('horizontalScaleModels', function (_, index, models) {
      const previousModel = models.get(index - 1);
      return (0, _utils.isPresent)(previousModel) && previousModel.get('isAutoscaleEvent') === false;
    }),
    filteredHorizontalScaleEvents: (0, _computed.union)('firstScaleEvent', 'manualScaleEvents', 'directionChangeEvents', 'scaleEventsPrecededByManualEvents')
  });

  _exports.default = _default;
});