define("dashboard/templates/components/enterprise-usage/active-licenses", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "naosCcg4",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"plans\"]]],null]],null],null,[[[1,\"  \"],[10,2],[12],[1,[30,1,[\"formattedStartDate\"]]],[1,\" — \"],[1,[30,1,[\"formattedEndDate\"]]],[13],[1,\"\\n  \"],[10,\"dl\"],[14,0,\"dl-horizontal org-plan\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"licenses\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,2],null,[[\"license\",\"class\"],[[30,2],\"mb2 mb0-ns\"]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]],[[[1,\"  \"],[10,2],[12],[1,\"\\n    License data is not available for this plan. Check your contract for\\n    details or contact your account manager for more information.\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"plan\",\"license\"],false,[\"each\",\"-track-array\",\"enterprise-usage/license-details\"]]",
    "moduleName": "dashboard/templates/components/enterprise-usage/active-licenses.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});