define("dashboard/templates/components/dev-center-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IDbrm/N2",
    "block": "[[[11,3],[16,6,[30,1]],[24,\"target\",\"_blank\"],[24,\"rel\",\"noopener noreferrer\"],[24,0,\"hk-link no-underline\"],[24,\"title\",\"(opens in a new window)\"],[16,\"onclick\",[28,[37,0],[[30,0],\"trackDevCenterClick\"],null]],[17,2],[12],[1,\"\\n  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"docs-16\",[29,[[28,[37,2],[[30,3],\"fill-blue\"],null],\" no-underline\"]],14]],null],[1,\"\\n\\n  \"],[10,1],[14,0,\"underline\"],[12],[41,[48,[30,4]],[[[1,[52,[30,0,[\"withPrelude\"]],\"Dev Center: \"]],[18,4,null]],[]],[[[1,\"Dev Center\"]],[]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@href\",\"&attrs\",\"@fillClass\",\"&default\"],false,[\"action\",\"malibu-icon\",\"or\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/dev-center-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});