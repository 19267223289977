define("dashboard/templates/account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BA2wn5wt",
    "block": "[[[6,[39,0],null,[[\"class\",\"aria-label\"],[\"account-nav\",\"Account Navigation Menu\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"link\",\"name\",\"iconName\"],[\"account.index\",\"Account\",\"user-28\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"link\",\"name\",\"iconName\",\"current-when\",\"class\"],[\"account.applications\",\"Applications\",\"configure-28\",\"account.applications account.applications.new-client account.applications.edit-client\",\"account-application-nav\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"link\",\"name\",\"iconName\"],[\"account.billing\",\"Billing\",\"billing-28\"]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"sub-nav\",\"sub-nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "dashboard/templates/account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});