define("dashboard/components/metrics/charts/line-chart-elements/overlayed-area-graph", ["exports", "dashboard/components/metrics/charts/line-chart-elements/area-graph"], function (_exports, _areaGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a {{area-graph}} that is overlayed on top of the contents of the {{timeseries-chart}}
   * content
   *
   */
  var _default = _areaGraph.default.extend({
    // area bottom Y location
    // at the bottom when the minimum value of the chart is 0
    areaBottomYFn() {
      const [min, max] = this.get('valueScale.domain')();
      const ratio = max / (max - min);
      return this.get('chartInnerHeight') * (1 - ratio);
    }

  });

  _exports.default = _default;
});