define("dashboard/components/space/access/members-list", ["exports", "@ember/component", "dashboard/mixins/purple-list"], function (_exports, _component, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_purpleList.default, {
    classNames: ['collaborator-table'],
    actions: {
      save() {
        const collaborator = this.item;
        return collaborator.save().then(() => {
          this.toggleProperty('isAdding');
        });
      },

      toggleModal() {
        if (this.isAdding) {
          this.item.rollbackPermissions();
        }

        this._super();
      },

      // Handle downstream PermissionToggle selection.
      selectPermission(permToSelect) {
        const perms = this.get('item.editingPermissions').toArray();
        const hasPerm = perms.findBy('name', permToSelect.get('name'));

        if (!hasPerm) {
          this.item.set('editingPermissions', perms.addObject(permToSelect));
        }
      },

      // Handle downstream PermissionToggle deselection.
      deselectPermission(permToRemove) {
        const perms = this.get('item.editingPermissions').toArray();
        const existingPerm = perms.findBy('name', permToRemove.get('name'));

        if (existingPerm) {
          this.item.set('editingPermissions', perms.removeObject(existingPerm));
        }
      }

    }
  });

  _exports.default = _default;
});