define("dashboard/components/metrics/charts/zone-elements/missing-data-zones", ["exports", "@ember/utils", "@ember/object", "@ember/object/computed", "@ember/component", "moment", "lodash-es/range"], function (_exports, _utils, _object, _computed, _component, _moment, _range) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'g',
    _graphDataSets: (0, _computed.mapBy)('graphs', 'data'),
    _graphDataSetsWithoutBlanks: (0, _computed.filter)('_graphDataSets', function (dataSet) {
      return (0, _utils.isPresent)(dataSet);
    }),
    isEdgeOfTime: (0, _object.computed)('maxTimeExtended', 'rollupDuration', function () {
      const beginningOfRollupDuration = (0, _moment.default)().subtract(this.rollupDuration.asMinutes(), 'minutes');
      const endOfRollupDuration = (0, _moment.default)().add(this.rollupDuration.asMinutes(), 'minutes');
      return (0, _moment.default)(this.maxTimeExtended).isBetween(beginningOfRollupDuration, endOfRollupDuration);
    }),
    timeframes: (0, _object.computed)('_graphDataSetsWithoutBlanks', 'rollupDuration', function () {
      const graphDataSets = this._graphDataSetsWithoutBlanks || [];
      const dataPoints = graphDataSets[0] ? graphDataSets[0].length : 0;
      const rollupDuration = this.rollupDuration; // tuples of [startTime, endTime]'s where there was no data present for all graphs

      const emptyTimeframes = [];
      (0, _range.default)(dataPoints).forEach(function (index) {
        let pointInTimeHasData = false;
        let time;
        graphDataSets.forEach(function (data) {
          const datum = data[index];

          if (datum) {
            const value = datum[1];
            time = datum[0];
            const isEmpty = value === null || Number.isNaN(value);

            if (!isEmpty) {
              pointInTimeHasData = true; // break out of this since we already know there is a data point

              return;
            }
          }
        });

        if (!pointInTimeHasData) {
          const end = time + rollupDuration;
          const previousTimeframe = emptyTimeframes.findBy('1', time);

          if (previousTimeframe) {
            previousTimeframe[1] += rollupDuration;
          } else {
            emptyTimeframes.addObject([time, end]);
          }
        }
      });
      return emptyTimeframes;
    })
  });

  _exports.default = _default;
});