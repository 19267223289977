define("dashboard/utils/endpoint-match-domains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createMatcherFromSplitDomain = createMatcherFromSplitDomain;
  _exports.default = matchDomains;
  _exports.findMatchingCerts = findMatchingCerts;
  _exports.includesWildcard = includesWildcard;
  _exports.splitDomains = splitDomains;

  function splitDomain(domain) {
    return [domain.substring(0, 1), domain.substring(1)];
  }

  function splitDomains(domains) {
    return domains.map(domain => {
      return splitDomain(domain);
    });
  }

  function createMatcherFromSplitDomain(_ref) {
    let [firstChar, rest] = _ref;
    let matchWildcard = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const matcherContents = [];

    if (matchWildcard) {
      matcherContents.push('^\\*');
    } else if (firstChar === '*') {
      matcherContents.push('^[\\w\\-]+');
    } else {
      matcherContents.push(firstChar);
    }

    const escapedRest = rest.replace(/\./g, '\\.');
    matcherContents.push(escapedRest);
    return new RegExp(matcherContents.join(''));
  }

  function includesWildcard(domains) {
    if (domains.some(domain => domain[0] === '*')) {
      return true;
    }

    return false;
  }

  function findMatchingCerts(domain, certs) {
    return certs.reduce((acc, cert) => {
      const [first] = splitDomain(domain); // This is so that we can customize the behavior of the matcher creator in cases where the
      // domain we want to match on starts with a wildcard

      const incomingDomainIsWildcard = first === '*';
      const parsedCertDomains = splitDomains(cert.sslCert.cert_domains);
      const matchers = parsedCertDomains.map(split => createMatcherFromSplitDomain(split, incomingDomainIsWildcard));

      if (matchers.some(matcher => matcher.test(domain))) {
        acc.push(cert);
      }

      return acc;
    }, []);
  }

  function matchDomains(certDomains, appDomains) {
    const parsedCertDomains = splitDomains(certDomains);
    const matchers = parsedCertDomains.map(split => createMatcherFromSplitDomain(split));

    if (includesWildcard(parsedCertDomains)) {
      const matchedDomains = appDomains.reduce((acc, appDomain) => {
        if (matchers.some(matcher => matcher.test(appDomain))) {
          acc.push(appDomain);
        }

        return acc;
      }, []);
      return matchedDomains;
    } else {
      return certDomains.filter(domain => appDomains.includes(domain));
    }
  }
});