define("dashboard/routes/enterprise-account/overview/index", ["exports", "@ember/service", "@ember/routing/route", "dashboard/mixins/document-title", "rsvp"], function (_exports, _service, _route, _documentTitle, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    queryParams: {
      showAll: {
        replace: false
      },
      filter: {
        replace: true
      },
      page: {
        replace: false
      }
    },
    analytics: (0, _service.inject)(),

    get documentTitle() {
      const name = this.modelFor('enterprise-account').enterpriseAccount.get('name');
      return `${name}${this.documentTitleSeparator}Overview`;
    },

    model() {
      const {
        enterpriseAccount,
        members,
        teams
      } = this.modelFor('enterprise-account');
      return (0, _rsvp.hash)({
        enterpriseAccount,
        members,
        teams,
        preferences: enterpriseAccount.preference.catch(() => null)
      });
    },

    afterModel() {
      this._super();

      this.analytics.logEvent('Enterprise Account Overview Page', 'Viewed');
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          filter: ''
        });
      }
    }

  });

  _exports.default = _default;
});