define("dashboard/models/pipeline-stage-config-var", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    stage: (0, _model.attr)('string'),
    key: (0, _model.attr)(),
    value: (0, _model.attr)({
      defaultValue: ''
    }),
    pipeline: (0, _model.belongsTo)('pipeline')
  });

  _exports.default = _default;
});