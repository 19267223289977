define("dashboard/components/scheduler/form-block", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['shadow-outer-1', 'br1']
  });

  _exports.default = _default;
});