define("dashboard/serializers/github-app-installation", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      team: {
        serialize: false
      },
      enterpriseAccount: {
        serialize: false
      },
      currentUserAccount: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});