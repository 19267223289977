define("dashboard/routes/pipelines/pipeline/access", ["exports", "@ember/routing/route", "rsvp"], function (_exports, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    queryParams: {
      user: {
        replace: true
      }
    },

    beforeModel() {
      const {
        pipeline
      } = this.modelFor('pipelines.pipeline');

      if (!pipeline.get('collaboratorsEnabled')) {
        return this.transitionTo('pipelines.pipeline');
      }
    },

    model() {
      const {
        pipeline
      } = this.modelFor('pipelines.pipeline');
      const collaborators = pipeline.get('collaborators');
      let teamMembers;

      if (pipeline.get('team.content')) {
        teamMembers = pipeline.get('team.content').hasMany('members').reload();
      }

      const permissions = this.store.findAll('team-permission').then(perms => {
        return perms.sortBy('permissiveness');
      });
      return _rsvp.default.hash({
        pipeline,
        collaborators,
        permissions,
        teamMembers
      });
    }

  });

  _exports.default = _default;
});