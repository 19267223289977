define("dashboard/models/production-check/stack", ["exports", "dashboard/mixins/production-check", "@ember/object"], function (_exports, _productionCheck, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    // The target stack when using the "Upgrade to the latest stack" feature.
    latestStack: 'heroku-22',
    // Stacks not in this list will be treated as outdated (ie: the upgrade button displayed).
    preferredStacks: ['heroku-22', 'container'],
    title: (0, _object.computed)('app.buildStack.name', function () {
      return `${this.get('app.buildStack.name').capitalize()} Stack`;
    }),
    devCenterURL: 'https://devcenter.heroku.com/articles/upgrading-to-the-latest-stack',
    skippedMessage: 'This application uses a custom stack.',
    warningMessage: (0, _object.computed)('latestStack', function () {
      return `<a class='hk-link' target='_blank' href=\"${this.devCenterURL}\">Upgrade to the ${this.latestStack} stack</a> for access to the latest packages and longest support window.`;
    }),
    status: (0, _object.computed)('app.buildStack.name', function () {
      const stackName = this.get('app.buildStack.name');

      if (stackName === 'container') {
        return 'skipped';
      } else if (this.preferredStacks.includes(stackName)) {
        return 'passed';
      }

      return 'warning';
    })
  });

  _exports.default = _default;
});