define("dashboard/routes/app/app-json", ["exports", "rsvp", "dashboard/routes/basic-app"], function (_exports, _rsvp, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    model() {
      const {
        app,
        appPermissions
      } = this.modelFor('app');
      const stacks = this.store.findAll('stack');
      return (0, _rsvp.hash)({
        app,
        stacks,
        parentConfigVars: this.fetchConfigVars(appPermissions, app),
        addons: this.fetchAddons(appPermissions, app.get('id'))
      });
    },

    fetchAddons(appPermissions, appId) {
      return this._ifCanViewResources(appPermissions, () => {
        return this.store.query('addon', {
          appNameOrId: appId
        });
      });
    },

    fetchConfigVars(appPermissions, app) {
      return this._ifCanViewResources(appPermissions, () => {
        return app.belongsTo('configVars').reload().then(configVars => {
          return configVars.get('listOfVariables');
        });
      });
    },

    _ifCanViewResources(appPermissions, query) {
      if (appPermissions.get('canViewResources')) {
        return query();
      } else {
        return (0, _rsvp.resolve)([]);
      }
    }

  });

  _exports.default = _default;
});