define("dashboard/components/enterprise-account/create-team", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    not,
    readOnly
  } = _object.computed;

  var _default = _component.default.extend({
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    team: (0, _object.computed)(function () {
      return this.store.createRecord('team');
    }),
    createTeamIsSuccessful: false,
    userName: readOnly('current.account.name'),
    shortUserName: (0, _object.computed)('userName', function () {
      const name = this.get('userName');

      if (name && name.length) {
        return name.split(' ')[0];
      } else {
        return false;
      }
    }),
    teamNameIsAvailable: false,
    teamNameIsValidAndAvailable: and('team.nameIsValid', 'teamNameIsAvailable'),
    createButtonIsDisabled: not('teamNameIsValidAndAvailable'),

    willDestroyElement() {
      const team = this.team;

      if (team.get('isNew')) {
        team.unloadRecord();
      }

      this._super(...arguments);
    },

    didInsertElement() {
      this.analytics.logEvent('Enterprise Account Create Team Modal', 'Viewed');
    },

    actions: {
      createTeam() {
        const team = this.team;
        const analyticsNoun = team.analyticsNoun;
        team.set('enterpriseAccount', this.enterpriseAccount);
        this.analytics.logEvent(analyticsNoun, 'Created');
        const pendingSave = team.save();
        pendingSave.then(() => {
          this.set('createTeamIsSuccessful', true);
        });
        return pendingSave;
      },

      invitePeople() {
        const team = this.team;
        this.onManageAccess(team);
        this.onClose();
      },

      transferApps() {
        const teamName = this.get('team.name');
        this.onTransferApps(teamName);
        this.onClose();
      },

      viewTeam() {
        const teamName = this.get('team.name');
        this.onViewTeam(teamName);
        this.onClose();
      }

    }
  });

  _exports.default = _default;
});