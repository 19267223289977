define("dashboard/adapters/addon-region-capability", ["exports", "@ember/polyfills", "@ember/object", "dashboard/adapters/application"], function (_exports, _polyfills, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    headers: (0, _object.computed)(function () {
      const headers = this._super(); // always request 1000, instead of the default 200


      const additionalHeaders = {
        Range: 'id ..; max=1000;'
      };
      return (0, _polyfills.merge)(headers, additionalHeaders);
    }),

    urlForQuery(query) {
      const path = `${this.host}/regions/${query.regionNameOrId}/addon-region-capabilities`;
      delete query.regionNameOrId; // I don't want this to actually be in a query lol

      return path;
    }

  });

  _exports.default = _default;
});