define("dashboard/models/account", ["exports", "@ember/object/computed", "dashboard/mixins/ds-error-monitoring-model", "@ember-data/model", "@ember/object", "moment"], function (_exports, _computed, _dsErrorMonitoringModel, _model, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_dsErrorMonitoringModel.default, {
    githubUser: (0, _model.belongsTo)('kolkrabbi/github-user'),
    reposApiGithubToken: (0, _model.belongsTo)('repositories-api-github-oauth-token'),
    githubAppInstallation: (0, _model.belongsTo)('github-app-installation'),
    allowTracking: (0, _model.attr)('boolean'),
    email: (0, _model.attr)('string'),
    federated: (0, _model.attr)('boolean'),
    name: (0, _model.attr)('string'),
    newPassword: (0, _model.attr)('string'),
    password: (0, _model.attr)('string'),
    smsNumber: (0, _model.attr)('string'),
    twoFactorAuthentication: (0, _model.attr)('boolean'),
    vaasEnrolled: (0, _model.attr)('boolean'),
    vaasMfa: (0, _model.attr)('boolean'),
    verified: (0, _model.attr)('boolean'),
    delinquentAt: (0, _model.attr)('date'),
    createdAt: (0, _model.attr)('date'),
    acknowledgedMsa: (0, _model.attr)('boolean'),
    italianCustomerTerms: (0, _model.attr)('string'),
    italianPartnerTerms: (0, _model.attr)('string'),
    acknowledgedMsaAt: (0, _model.attr)('date'),
    mfaEnabled: (0, _model.attr)('boolean'),
    mfaExperience: (0, _model.attr)('string'),
    ecoDynosEnabled: (0, _model.attr)('boolean'),
    ecoDynosShutdownAt: (0, _model.attr)('date'),
    ecoShutdownDate: (0, _object.computed)('ecoDynosShutdownAt', function () {
      // The shutdown takes place at midnight GMT on the first of the month. Depending on the
      // users timezone, the shutdown date may come back from API as the last day of the previous
      // month. We want to guarantee the date shown will be the first of the month, so we add a day
      // to the shutdown date and reset to the beginning of the month.
      if (this.ecoDynosShutdownAt) {
        return (0, _moment.default)(this.ecoDynosShutdownAt).add(1, 'day').startOf('month');
      }
    }),
    // Treat either having `ecoDynosEnabled === true` or the existence of a
    // shutdown date in the future as the user having an ongoing subscription.
    // If `ecoDynosShutdownAt` exists and is in the future, then the user is
    // currently in a pending cancellation state.
    timeRemainingOnEco: (0, _object.computed)('ecoDynosShutdownAt', function () {
      return this.ecoDynosShutdownAt && (0, _moment.default)(this.ecoDynosShutdownAt).isAfter();
    }),
    subscribedToEco: (0, _object.computed)('ecoDynosEnabled', 'timeRemainingOnEco', function () {
      return this.ecoDynosEnabled || this.timeRemainingOnEco;
    }),
    pendingCancellationToEco: (0, _object.computed)('ecoDynosEnabled', 'ecoDynosShutdownAt', function () {
      return !this.get('ecoDynosEnabled') && this.get('ecoDynosShutdownAt');
    }),
    ecoBillingStartDate: (0, _moment.default)(),
    ecoRenewalDate: (0, _object.computed)('subscribedToEco', 'firstDayOfEcoBilling', function () {
      if (this.subscribedToEco) {
        return (0, _moment.default)().add(1, 'month').startOf('month');
      }
    }),
    pipelineCostConsent: (0, _model.attr)('boolean'),
    pipelineCostConsentAt: (0, _model.attr)('date'),
    errorTitleSubject: 'Account',

    becameInvalid() {
      // if we are updating the password, we do NOT want this to be reported to
      // a red error bubble necessarily. If the user's password was leaked,
      // we render the errors in the app/components/account/password-update component
      // instead of in the red error bubble.
      // This is an unfortunate hack that exists because even though we mark the XHR as suppressErrors or
      // errorIsHandled, this hook will still be called and the error reported, ending up
      // in the red bubble.
      // The app/components/account/password-update component and the app/adapters/account
      // are the only files that should be reading or `set`'ing this property.
      if (!this.didLeakPassword) {
        return this._super(...arguments);
      }
    },

    vaasMfaExperience: (0, _computed.equal)('mfaExperience', 'vaas'),
    isHerokai: (0, _computed.match)('email', /@heroku\.com$/),
    isSalesforce: (0, _computed.match)('email', /@salesforce\.com$/),
    isOhana: (0, _computed.or)('isHerokai', 'isSalesforce'),
    unfederated: (0, _computed.not)('federated'),
    isDelinquent: (0, _computed.bool)('delinquentAt')
  });

  _exports.default = _default;
});