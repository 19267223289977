define("dashboard/adapters/sni-endpoint", ["exports", "dashboard/adapters/application", "dashboard/mixins/adapters/range-aware-adapter"], function (_exports, _application, _rangeAwareAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rangeAwareAdapter.default, {
    version: 3,
    range: {
      attribute: 'id',
      max: 1000
    },

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/sni-endpoints`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/sni-endpoints/${id}`;
    },

    urlForUpdateRecord() {
      return this.urlForDeleteRecord(...arguments);
    },

    urlForFindRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/sni-endpoints/${id}`;
    }

  });

  _exports.default = _default;
});