define("dashboard/models/metrics/alert", ["exports", "@ember/object", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _object, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    empty,
    readOnly
  } = _object.computed;

  var _default = _model.default.extend({
    startedAt: (0, _attr.default)('date'),
    createdAt: alias('startedAt'),
    endedAt: (0, _attr.default)('date'),
    isActive: empty('endedAt'),
    monitor: (0, _relationships.belongsTo)('metrics/monitor'),
    formation: (0, _relationships.belongsTo)('formation'),
    isLatency: readOnly('monitor.isLatency'),
    isErrorRate: readOnly('monitor.isErrorRate'),
    appId: readOnly('monitor.appId'),
    monitorValue: readOnly('monitor.value')
  });

  _exports.default = _default;
});