define("dashboard/controllers/pipelines/pipeline/tests/index", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@ember/object/computed", "dashboard/config/environment"], function (_exports, _controller, _service, _object, _computed, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    githubIntegrationDisabled: _environment.default.githubIntegrationDisabled,
    pipeline: (0, _computed.alias)('model.pipeline'),
    hasNoGithubAppInstalled: (0, _computed.not)('pipeline.hasGithubAppInstalled'),
    connectedRepository: (0, _object.computed)('pipeline.isGithubAppEnabled', 'pipeline.githubRepository', 'pipeline.connectedRepository', function () {
      let connectedRepo;

      if (this.pipeline.isGithubAppEnabled) {
        connectedRepo = this.get('model.pipeline.connectedRepository');
      } else {
        connectedRepo = this.get('model.pipeline.githubRepository');
      }

      return connectedRepo;
    })
  });

  _exports.default = _default;
});