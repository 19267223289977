define("dashboard/mixins/fetch-github-branches", ["exports", "@ember/object/mixin", "@ember/service"], function (_exports, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    store: (0, _service.inject)(),

    fetchBranches(repo) {
      let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      let per_page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
      return this.store.query('github-branch', {
        repo,
        per_page,
        page
      }).then(branches => {
        const next = branches.get('meta.next');

        if (next) {
          return this.fetchBranches(repo, next.page, next.per_page);
        }

        return this.store.peekAll('github-branch').filterBy('repo', repo);
      });
    }

  });

  _exports.default = _default;
});