define("dashboard/adapters/kolkrabbi/github-app-deploy", ["exports", "dashboard/adapters/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.kolkrabbiUrl,

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.belongsTo('app').id;
      return `${this.host}/apps/${appId}/github/push`;
    }

  });

  _exports.default = _default;
});