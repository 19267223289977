define("dashboard/components/metrics/charts/connected-chart-hover/restart-group", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/charts/connected-chart-hover/activity-group"], function (_exports, _computed, _object, _activityGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activityGroup.default.extend({
    models: (0, _computed.reads)('modelsAtSelectedTime'),
    _restartEventsAtSelectedTime: (0, _computed.filterBy)('models', 'restartType', 'restart'),
    _relocateEventsAtSelectedTime: (0, _computed.filterBy)('models', 'restartType', 'relocate'),
    _cyclingEventsAtSelectedTime: (0, _computed.filterBy)('models', 'restartType', 'cycling'),
    items: (0, _object.computed)('_restartEventsAtSelectedTime.length', '_relocateEventsAtSelectedTime.length', '_cyclingEventsAtSelectedTime.length', function () {
      const restartEvents = this.get('_restartEventsAtSelectedTime.length');
      const relocateEvents = this.get('_relocateEventsAtSelectedTime.length');
      const cyclingEvents = this.get('_cyclingEventsAtSelectedTime.length');
      const items = [];

      if (restartEvents > 0) {
        let title = 'Dyno Restart';
        const description = 'User Initiated';

        if (restartEvents > 1) {
          title += ` x${restartEvents}`;
        }

        items.push(_object.default.create({
          title,
          description
        }));
      }

      if (relocateEvents > 0) {
        let title = 'Dyno Restart';
        const description = 'Platform Initiated';

        if (relocateEvents > 1) {
          title += ` x${relocateEvents}`;
        }

        items.push(_object.default.create({
          title,
          description
        }));
      }

      if (cyclingEvents > 0) {
        let title = 'Dyno Restart';
        const description = 'Daily Restart';

        if (cyclingEvents > 1) {
          title += ` x${cyclingEvents}`;
        }

        items.push(_object.default.create({
          title,
          description
        }));
      }

      return items;
    })
  });

  _exports.default = _default;
});