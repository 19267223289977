define("dashboard/components/metrics/app-guidance/memory-errors", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/app-guidance/item", "dashboard/utils/pluralize"], function (_exports, _computed, _object, _item, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    tagName: 'metrics-app-guidance-memory-errors',
    title: 'Memory quota exceeded',
    description: (0, _object.computed)('errorCount', function () {
      const count = this.errorCount;
      const verb = (0, _pluralize.default)(count, 'has', 'have');
      const directObject = (0, _pluralize.default)(count, 'error', 'errors');
      return `There ${verb} been ${count} memory ${directObject} in the current time period.`;
    }),
    links: (0, _object.computed)('app.isEligibleForRubyMetrics', 'app.isEligibleForJvmMetrics', 'app.isEligibleForNodeMetrics', 'app.isEligibleForPhpMetrics', function () {
      return [{
        href: 'https://devcenter.heroku.com/articles/ruby-memory-use',
        text: 'Optimize Memory Usage (Ruby)',
        isVisible: this.get('app.isEligibleForRubyMetrics')
      }, {
        href: 'https://devcenter.heroku.com/articles/java-memory-issues',
        text: 'Optimize Memory Usage (Java)',
        isVisible: this.get('app.isEligibleForJvmMetrics')
      }, {
        href: 'https://devcenter.heroku.com/articles/node-concurrency',
        text: 'Optimize Concurrency (Node)',
        isVisible: this.get('app.isEligibleForNodeMetrics')
      }, {
        href: 'https://devcenter.heroku.com/articles/php-concurrency',
        text: 'Optimize Concurrency (PHP)',
        isVisible: this.get('app.isEligibleForPhpMetrics')
      }, {
        href: 'https://devcenter.heroku.com/articles/optimizing-dyno-usage#basic-methodology-for-optimizing-memory',
        text: 'Optimize Memory Usage',
        isVisible: true
      }];
    }),
    devCenterLinks: (0, _computed.filterBy)('links', 'isVisible')
  });

  _exports.default = _default;
});