define("dashboard/components/metrics/charts/visualizations/ruby-heap-objects", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/aggregate-chart"], function (_exports, _computed, _aggregateChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aggregateChart.default.extend({
    tagName: 'metrics-ruby-objects-chart',
    'data-test-target': 'metrics-ruby-chart',
    title: 'Ruby: Heap Objects',
    devCenterName: 'language-runtime-metrics-ruby#heap-object-count',
    isLogarithmicChart: true,
    isBeta: true,
    timeSeriesName: 'Alloc',
    timeSeriesNameNegated: 'Freed',
    maxData: (0, _computed.alias)('data.allocatedObjectsMaxLog'),
    avgData: (0, _computed.alias)('data.allocatedObjectsAvgLog'),
    maxDataNegated: (0, _computed.alias)('data.freedObjectsMaxLog'),
    avgDataNegated: (0, _computed.alias)('data.freedObjectsAvgLog')
  });

  _exports.default = _default;
});