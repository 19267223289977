define("dashboard/templates/components/scheduler/forms/job-schedule", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8KDrFei3",
    "block": "[[[6,[39,0],null,[[\"value\",\"class\",\"id\",\"onChange\"],[[30,0,[\"frequency\"]],\"hk-select flex-auto\",\"scheduling-frequency-select\",[28,[37,1],[[30,0],\"selectFrequency\"],null]]],[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"frequencyOptions\"]]],null]],null],null,[[[6,[30,1,[\"option\"]],null,[[\"value\"],[[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"      \"],[1,[30,2,[\"displayName\"]]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]],[1,\"\\n\"],[41,[30,0,[\"offsetOptions\"]],[[[1,\"  \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"value\",\"class\",\"id\",\"onChange\"],[[30,0,[\"offset\"]],[30,0,[\"offsetDropdownClassNames\"]],\"scheduling-offset-select\",[28,[37,1],[[30,0],\"selectOffset\"],null]]],[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"offsetOptions\"]]],null]],null],null,[[[6,[30,3,[\"option\"]],null,[[\"value\"],[[30,4,[\"value\"]]]],[[\"default\"],[[[[1,\"          \"],[1,[30,4,[\"displayName\"]]],[1,\"\\n\"]],[]]]]]],[4]],null]],[3]]]]],[41,[30,0,[\"using24Hours\"]],[[[1,\"      \"],[10,0],[14,0,\"flex items-center ph2 justify-center overflow-hidden bg-near-white br1 br--right br bt bb b--light-gray shadow-outer-3 ttu f6\"],[12],[1,\"\\n        UTC\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"xs\",\"option\",\"xs\",\"option\"],false,[\"x-select\",\"action\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "dashboard/templates/components/scheduler/forms/job-schedule.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});