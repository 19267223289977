define("dashboard/components/datastore/datastore-chart-summary", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/chart-header/chart-summary", "@ember/object"], function (_exports, _computed, _chartSummary, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _chartSummary.default.extend({
    primaryUnit: readOnly('data.unit'),
    numberFormat: readOnly('data.format'),
    hasSufficientData: true,
    maxValue: (0, _object.computed)(function () {
      return _object.default.create({
        title: `Maximum`,
        label: 'max',
        primaryValue: this.get('data.max') < 0.01 ? '0' : this.get('data.max')
      });
    }),
    minValue: (0, _object.computed)(function () {
      return _object.default.create({
        title: `Minimum`,
        label: 'min',
        primaryValue: this.get('data.min') < 0.01 ? '0' : this.get('data.min')
      });
    }),
    chartSummaryItems: (0, _computed.collect)('minValue', 'maxValue')
  });

  _exports.default = _default;
});