define("dashboard/components/account/billing-usage", ["exports", "@ember/component", "@ember/object/computed", "moment", "@ember/object"], function (_exports, _component, _computed, _moment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['table-responsive', 'current-usage'],
    // only hide if credits is null or undefined
    hideCredits: (0, _computed.none)('credits'),
    _creditBalances: (0, _computed.mapBy)('credits', 'balance'),
    creditsTotal: (0, _computed.sum)('_creditBalances'),
    ecoShutdownDateFormatted: (0, _object.computed)('ecoShutdownDate', function () {
      if (this.ecoShutdownDate) {
        return (0, _moment.default)(this.ecoShutdownDate).format('MMMM D, YYYY');
      }
    }),
    ecoRenewalDateFormatted: (0, _object.computed)('ecoRenewalDate', function () {
      if (this.ecoRenewalDate) {
        return (0, _moment.default)(this.ecoRenewalDate).format('MMMM D, YYYY');
      }
    })
  });

  _exports.default = _default;
});