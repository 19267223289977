define("dashboard/components/pipeline/any-stage", ["exports", "@ember/runloop", "dashboard/config/environment", "@ember/service", "@ember/component", "@ember/utils", "@ember/object", "@ember/object/computed"], function (_exports, _runloop, _environment, _service, _component, _utils, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    classNames: ['stage', 'flex', 'flex-column'],
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    pipelineOwner: (0, _service.inject)(),
    account: (0, _computed.alias)('current.account'),
    canAddCouplings: (0, _computed.not)('isReviewStage'),
    organization: (0, _computed.readOnly)('pipelineOwner.organization'),
    hasApps: (0, _computed.gt)('apps.length', 0),
    isReviewStage: (0, _computed.equal)('stage', 'review'),
    isStagingStage: (0, _computed.equal)('stage', 'staging'),
    isProdStage: (0, _computed.equal)('stage', 'production'),
    sorting: ['name'],
    sortedApps: (0, _computed.sort)('apps', 'sorting'),
    isAddingAppCoupling: false,
    isShowingNewAppPanel: false,
    userName: (0, _object.computed)('account', function () {
      const accountName = this.get('account.name');
      const accountEmail = this.get('account.email');
      return (0, _utils.isPresent)(accountName) ? `${accountName} (${accountEmail})` : accountEmail;
    }),
    stageName: (0, _object.computed)('stage', function () {
      return this.stage.capitalize();
    }),
    stageClass: (0, _object.computed)('stage', function () {
      return `pipeline-${this.stage}`;
    }),
    emptyMessage: (0, _object.computed)('stage', function () {
      if (this.isStagingStage) {
        return `Staging apps can be used to preview code changes and features before being deployed to production.`;
      } else if (this.isProdStage) {
        return `Production apps run your customer facing code. We recommend promoting your code from a staging app that has been tested.`;
      } else {
        return `There are no ${this.stage} apps`;
      }
    }),
    canCreateApp: (0, _object.computed)('organization', function () {
      const organization = this.organization; // TODO: Only return this case when all pipelines have an owner

      if (this.get('pipeline.hasOwner')) {
        const isPipelineOwner = this.get('pipeline.ownerID') === this.get('current.account.id');
        return organization ? organization.get('canCreateApp') : isPipelineOwner;
      } else {
        return true;
      }
    }),

    _syncGithubAppLink(app, pipeline) {
      // Please coding gods, forgiveth me for I have sinned
      // There is a disconnect between creating the coupling, and the
      // github app link being available, so we force a delay
      // to let the backend catch up
      if (pipeline.get('isConnectedToRepository')) {
        if (_environment.default.environment !== 'test') {
          (0, _runloop.later)(() => {
            this.store.queryRecord('githubAppLink', {
              app: app.get('id')
            });
          }, 2000);
        } else {
          this.store.queryRecord('githubAppLink', {
            app: app.get('id')
          });
        }
      }
    },

    actions: {
      toggleAppCoupler() {
        this.toggleProperty('isAddingAppCoupling');
      },

      toggleNewAppPanel(searchTerm) {
        this.toggleProperty('isShowingNewAppPanel');
        this.set('potentialAppName', searchTerm);
      },

      addCoupling(app) {
        const pipeline = this.pipeline;
        const stage = this.stage;

        if (pipeline.get('isConnectedToRepository')) {
          app.set('hasImplicitGithubAppLink', true);
        }

        const coupling = this.store.createRecord('pipelineCoupling', {
          stage,
          pipeline,
          app
        });
        this.set('isAddingAppCoupling', false);
        coupling.save().then(() => {
          pipeline.addCoupling(coupling);

          this._syncGithubAppLink(app, pipeline);

          this.analytics.logEvent('Pipeline Coupling', 'Created', {
            status: 'succeeded'
          });
        }).catch(() => {
          this.analytics.logEvent('Pipeline Coupling', 'Created', {
            status: 'failed'
          });
          return pipeline.get('pipelineCouplings').removeObject(coupling);
        });
      }

    }
  });

  _exports.default = _default;
});