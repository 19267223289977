define("dashboard/templates/components/metrics/process-monitor-configuration/preview-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aOMWYp7c",
    "block": "[[[1,[28,[35,0],null,[[\"isShowingHeader\",\"isShowingLegend\",\"isNotSelectable\",\"unit\",\"isPreview\",\"timeSeriesData\",\"maxDisplayValue\",\"timezone\",\"chartContentData\",\"isShowingLoadingState\"],[false,false,true,[30,0,[\"chartUnit\"]],true,[28,[37,1],[[30,0,[\"graphData\"]]],null],[30,0,[\"maxValueGraph\"]],[30,0,[\"timezone\"]],[28,[37,2],null,[[\"chartTimeSeries\",\"alertPreviews\",\"threshold\"],[[30,0,[\"chartTimeSeries\"]],[30,0,[\"alertPreviews\"]],[30,0,[\"monitor\",\"value\"]]]]],[28,[37,3],[[30,0,[\"throughput\",\"isPending\"]],[30,0,[\"latency\",\"isPending\"]]],null]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"metrics__monitor-preview-chart__summary alert alert-info\"],[12],[1,\"\\n  With the selected settings, we estimate that there would have been \"],[41,[30,0,[\"wouldHaveAlerts\"]],[[[1,\"roughly \"],[1,[30,0,[\"alertPreviews\",\"length\"]]]],[]],[[[1,\"no\"]],[]]],[1,\" \"],[1,[28,[35,5],[[30,0,[\"alertPreviews\",\"length\"]],\"alert\",\"alerts\"],null]],[1,\" triggered in the last 24 hours.\\n\"],[41,[30,0,[\"wouldHaveAlerts\"]],[[[1,\"    This is an estimation, you may not receive this many alerts depending on the sensitivity you've set.\\n    \"],[10,1],[12],[1,\"Consider raising the threshold or decreasing the sensitivity to avoid noisy alerts.\"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"metrics/charts/timeseries-chart\",\"array\",\"hash\",\"or\",\"if\",\"pluralize-word\"]]",
    "moduleName": "dashboard/templates/components/metrics/process-monitor-configuration/preview-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});