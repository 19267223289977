define("dashboard/templates/components/metrics/charts/row-chart-elements/indicator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HYapOOJr",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"durations\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"start\",\"end\",\"indicatorLevel\",\"indicatorTime\",\"pixelsPerMs\",\"maxTimeExtended\",\"minTime\"],[[30,1,[\"startedAt\"]],[30,1,[\"endedAt\"]],[30,0,[\"level\"]],[30,0,[\"time\"]],[30,0,[\"pixelsPerMs\"]],[30,0,[\"maxTimeExtended\"]],[30,0,[\"minTime\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"isShowingIcon\"]],[[[41,[30,0,[\"icon\"]],[[[1,\"    \"],[1,[28,[35,4],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"rect\"],[15,\"width\",[29,[[30,0,[\"width\"]]]]],[15,\"height\",[29,[[30,0,[\"height\"]]]]],[15,\"opacity\",[29,[[30,0,[\"opacity\"]]]]],[12],[13],[1,\"\\n\"]],[]]]],[]],null]],[\"duration\"],false,[\"each\",\"-track-array\",\"metrics/charts/row-chart-elements/indicator-duration-line\",\"if\",\"svg-jar\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/row-chart-elements/indicator.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});