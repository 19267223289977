define("dashboard/templates/components/favorite-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "uzQKJKI6",
    "block": "[[[41,[30,0,[\"isFavorite\"]],[[[1,\"  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[52,[30,0,[\"isHovering\"]],\"favorite-remove-16\",\"favorite-16\"],[52,[30,0,[\"isHovering\"]],\"fill-gray\",\"malibu-fill-gradient-orange\"],16]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"favorite-option-16\",[52,[30,0,[\"isHovering\"]],\"malibu-fill-gradient-orange\",\"fill-gray\"],16]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/favorite-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});