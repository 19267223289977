define("dashboard/serializers/enterprise-account/license-usage", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keyForLicense = keyForLicense;
  _exports.normalizeLicenseCollections = normalizeLicenseCollections;

  var _default = _application.default.extend({
    extractId(modelClass, payload) {
      return keyForLicense(payload);
    },

    normalizeArrayResponse(store, primaryModelClass, payload) {
      if (payload && payload[0] && payload[0].licenses) {
        payload = normalizeLicenseCollections(payload);
      }

      return this._super(store, primaryModelClass, payload);
    }

  });

  _exports.default = _default;

  function normalizeLicenseCollections(payload) {
    return payload.mapBy('licenses').reduce((a, b) => a.concat(b));
  }

  const KEY_PROPS = ['enterprise_account_id', 'license_type', 'license_quantity', 'usage', 'month'];

  function keyForLicense(license) {
    return KEY_PROPS.map(prop => license[prop]).filter(_ => _).join('-');
  }
});