define("dashboard/serializers/metrics/space-availability", ["exports", "dashboard/serializers/application", "ember-data", "ember", "@ember/utils"], function (_exports, _application, _emberData, _ember, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      degradations: {
        deserialize: 'records'
      }
    },

    normalize(typeClass, hash) {
      if (!(0, _utils.isEmpty)(hash)) {
        hash = {
          id: _ember.default.generateGuid(),
          degradations: hash.map(item => {
            item.id = _ember.default.generateGuid();
            return item;
          })
        };
      }

      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});