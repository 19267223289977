define("dashboard/controllers/app/deploy/heroku-git", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    app: (0, _computed.alias)('model.app'),
    emptyRepo: (0, _computed.empty)('app.slugSize')
  });

  _exports.default = _default;
});