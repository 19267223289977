define("dashboard/routes/app/datastore/credentials", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _route.default {
    async model() {
      const {
        datastore
      } = this.modelFor('app.datastore');
      const credentials = await datastore.belongsTo('credentials').reload();
      const addon = this.modelFor('app.datastore').addon;
      await addon.fetchAttachments(this.store);
      const addonAttachments = await addon.attachments;
      return {
        datastore,
        credentials,
        addon,
        addonAttachments
      };
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.get('loadAvailableApps').perform();

      if (!model.credentials.get('credentialsList').isEvery('state', 'active')) {
        controller.get('pollCredentialsTask').perform();
      }
    }

  }

  _exports.default = _default;
});