define("dashboard/controllers/app/datastore/settings", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    addon: (0, _computed.readOnly)('model.addon'),
    queryParams: ['config', 'destroy', 'fork', 'reset'],
    config: null,
    destroy: null,
    fork: null,
    reset: null,
    actions: {
      closeUpdateConfig() {
        this.set('config', null);
      },

      closeDestroy() {
        this.set('destroy', null);
      },

      closeFork() {
        this.set('fork', null);
      },

      closeReset() {
        this.set('reset', null);
      }

    }
  });

  _exports.default = _default;
});