define("dashboard/adapters/repositories-api-commit-status", ["exports", "dashboard/adapters/application", "dashboard/mixins/load-more-records"], function (_exports, _application, _loadMoreRecords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_loadMoreRecords.default, {
    version: '3.repositories-api',

    urlForQuery(query) {
      const url = `${this.host}/pipelines/${query.pipelineId}/repo/statuses`;
      delete query.pipelineId;
      return url;
    }

  });

  _exports.default = _default;
});