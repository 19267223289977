define("dashboard/controllers/protected/assume", ["exports", "@ember/service", "rsvp", "@ember/object/computed", "@ember/controller", "dashboard/config/environment", "dashboard/utils/create-sudo-token"], function (_exports, _service, _rsvp, _computed, _controller, _environment, _createSudoToken) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: ['email', 'reason'],
    session: (0, _service.inject)(),
    fieldsPopulated: (0, _computed.and)('email', 'code', 'reason'),
    fieldsNotPopulated: (0, _computed.not)('fieldsPopulated'),
    hasUnpopulatedError: (0, _computed.and)('hasSubmitted', 'fieldsNotPopulated'),
    actions: {
      closeAssumeIdentity() {
        this.set('hasSubmitted', false);
        this.set('apiErrorMessage', null);
        this.transitionToRoute('apps');
      },

      assumeIdentity() {
        this.set('hasSubmitted', true);
        this.set('apiErrorMessage', null);

        if (this.hasUnpopulatedError) {
          return (0, _rsvp.reject)();
        }

        let accessToken;
        const email = this.email;
        const code = this.code;
        const reason = this.reason;
        const pendingCreate = (0, _createSudoToken.default)(`dashboard sudo: ${email}`, code, reason);
        return pendingCreate.then(token => {
          accessToken = token;
          return getUserAccount(email, token, reason);
        }).then(account => {
          this.session.setSudoData(accessToken, reason, account.id);
          this.send('reset');
        }).catch(_ref => {
          let {
            responseJSON: {
              message
            }
          } = _ref;
          this.set('code', null);
          this.set('hasSubmitted', false);
          this.set('apiErrorMessage', message);
        });
      }

    }
  });

  _exports.default = _default;

  function getUserAccount(email, accessToken, reason) {
    return $.ajax({
      method: 'get',
      url: `${_environment.default.platformApiUrl}/account`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-Origin': window.location.origin,
        'X-Heroku-Sudo': true,
        'X-Heroku-Sudo-User': email,
        'X-Heroku-Sudo-Reason': reason,
        Accept: 'application/vnd.heroku+json; version=3'
      }
    });
  }
});