define("dashboard/routes/app/resources", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app"], function (_exports, _rsvp, _service, _basicApp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model(_ref) {
      let {
        justInstalledAddonServiceId
      } = _ref;
      const {
        app,
        appPermissions
      } = this.modelFor('app');
      const appId = app.get('id');
      return _rsvp.default.hash({
        app,
        appPermissions,
        team: app.get('team'),
        addons: this.fetchAddons(appPermissions, appId),
        attachments: this.fetchAttachments(appPermissions, appId),
        justInstalledAddonService: this.fetchJustInstalledAddonService(justInstalledAddonServiceId),
        formations: app.get('formations'),
        paymentMethod: this.store.queryRecord('payment-method', {})
      });
    },

    afterModel() {
      this._super.apply(this, arguments);

      this.analytics.logEvent('App Resources Page', 'Viewed');
    },

    fetchJustInstalledAddonService(justInstalledAddonServiceId) {
      if (justInstalledAddonServiceId) {
        return this.store.find('addon-service', justInstalledAddonServiceId).catch(() => {
          return null;
        }); // swallow errors if there are any
      } else {
        return null;
      }
    },

    fetchAddons(appPermissions, appId) {
      return this._ifCanViewResources(appPermissions, () => {
        return this.store.query('addon', {
          appNameOrId: appId
        });
      });
    },

    fetchAttachments(appPermissions, appId) {
      return this._ifCanViewResources(appPermissions, () => {
        return this.store.query('attachment', {
          appNameOrId: appId
        }).then(appAttachments => {
          const data = appAttachments.toArray().map(a => {
            return {
              id: a.id,
              type: 'attachment'
            };
          });
          this.store.push({
            data: {
              id: appId,
              type: 'app',
              relationships: {
                attachments: {
                  data
                }
              }
            }
          });
          return appAttachments;
        });
      });
    },

    _ifCanViewResources(appPermissions, query) {
      if (appPermissions.get('canViewResources')) {
        return query();
      } else {
        return (0, _rsvp.resolve)([]);
      }
    },

    _ifCanScalePs(appPermissions, query) {
      if (appPermissions.get('canScalePs')) {
        return query();
      } else {
        return (0, _rsvp.resolve)([]);
      }
    },

    resetController(controller, isExiting
    /* , transition */
    ) {
      if (isExiting) {
        controller.set('justInstalledAddonServiceId', null);
      }
    },

    actions: {
      transitionToProvisionModal(name) {
        this.transitionTo('app.resources.new', {
          queryParams: {
            addonService: name,
            addonPlan: null
          }
        });
      },

      refreshResourcesRoute() {
        this.refresh();
      },

      triggerUpdateAttachments() {
        this.controllerFor('app.resources').notifyPropertyChange('attachments');
      },

      // Ember-data Formations
      reloadFormations(appId) {
        const app = this.store.peekRecord('app', appId);
        app.hasMany('formations').reload();
        app.belongsTo('appState').reload();
      }

    }
  });

  _exports.default = _default;
});