define("dashboard/templates/components/app/confirm-delete-domain-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0lL/DqE5",
    "block": "[[[1,\"Are you sure you want to remove \"],[10,\"strong\"],[12],[1,[30,0,[\"item\",\"hostname\"]]],[13],[1,\"?\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/app/confirm-delete-domain-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});