define("dashboard/mixins/chart-dimension-tracker", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/object/mixin", "@ember/object", "jquery"], function (_exports, _computed, _runloop, _mixin, _object, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * mixin exclusively for the {{timeseries-chart}} component
   * that isolates all of the logic surrounding width and height tracking
   */
  var _default = _mixin.default.create({
    _defaultHeight: 150,
    _graphHeights: (0, _computed.mapBy)('graphs', 'height'),
    _graphsHeight: (0, _computed.sum)('_graphHeights'),

    /**
     * the height of the chart that is either the sum of its children heights
     *
     * or a predefined default height
     *
     */
    height: (0, _object.computed)('_graphsHeight', '_defaultHeight', 'padding', function () {
      const graphsHeight = this._graphsHeight;

      if (graphsHeight > 0) {
        return graphsHeight + this.get('padding.top') + this.get('padding.bottom');
      } else {
        return this._defaultHeight;
      }
    }),

    /**
     * space to be reserved for chart axes
     *
     */
    padding: (0, _object.computed)(function () {
      return _object.default.create({
        top: 35,
        right: 10,
        bottom: 5,
        left: 50
      });
    }),
    innerWidth: (0, _object.computed)('width', 'padding.left', 'padding.right', function () {
      return this.width - this.get('padding.left') - this.get('padding.right');
    }),
    innerCenterX: (0, _object.computed)('innerWidth', function () {
      return this.innerWidth / 2;
    }),

    /**
     * the height of the chart exluding the vertical padding
     */
    innerHeight: (0, _object.computed)('height', 'padding.top', 'padding.bottom', function () {
      return this.height - this.get('padding.top') - this.get('padding.bottom');
    }),
    innerCenterY: (0, _object.computed)('innerHeight', function () {
      return this.innerHeight / 2;
    }),
    innerTransform: (0, _object.computed)('padding.left', 'padding.top', function () {
      return `translate(${this.get('padding.left')}.5, ${this.get('padding.top')}.5)`;
    }),
    chartDimensions: (0, _object.computed)('padding.right', 'padding.left', 'padding.top', 'padding.bottom', 'innerHeight', 'innerWidth', 'innerTransform', function () {
      return _object.default.create({
        padding: this.padding,
        innerHeight: this.innerHeight,
        innerWidth: this.innerWidth,
        innerTransform: this.innerTransform,
        width: this.width
      });
    }),

    /**
     * responsively determines the width of the chart based on the current width of
     * the svg inside of it.
     */
    recalculateDimensions() {
      if ((0, _jquery.default)(this.element)) {
        const $svg = (0, _jquery.default)(this.element).find('.metrics__chart__image');
        const width = $svg.innerWidth();
        this.set('width', width);
      }
    },

    didRenderElement() {
      (0, _runloop.next)(() => {
        (0, _runloop.scheduleOnce)('afterRender', this, this.recalculateDimensions);
      });
    }

  });

  _exports.default = _default;
});