define("dashboard/adapters/team/preference", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForUpdateRecord(id) {
      return `${this.host}/teams/${id}/preferences`;
    }

  });

  _exports.default = _default;
});