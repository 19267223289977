define("dashboard/routes/team/switchable/resources", ["exports", "@ember/service", "dashboard/routes/team/base"], function (_exports, _service, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    analytics: (0, _service.inject)(),

    model() {
      return this.modelFor('team');
    },

    afterModel(model) {
      const analyticsNoun = model.team.analyticsNoun;
      this.analytics.logEvent(`${analyticsNoun} Resources Page`, 'Viewed');
      return this._super(model);
    }

  });

  _exports.default = _default;
});