define("dashboard/templates/components/identity-provider/login-info", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qtr0exLz",
    "block": "[[[10,0],[14,0,\"flex flex-column mt3 pt3 f5 bt b--silver\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"b mb2\"],[12],[1,\"\\n    Login methods\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"dark-gray flex items-start\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Dashboard\",\"network-16\",\"malibu-fill-gradient-dark-gray flex-none\",16]],null],[1,\"\\n    \"],[10,1],[14,0,\"ml2\"],[12],[1,\"Direct login via \"],[10,3],[14,0,\"hk-link\"],[15,6,[29,[\"https://sso.heroku.com/saml/\",[30,0,[\"identityProvider\",\"name\"]],\"/init\"]]],[12],[1,\"https://sso.heroku.com/saml/\"],[1,[30,0,[\"identityProvider\",\"name\"]]],[1,\"/init\"],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"dark-gray flex items-start mt1\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Dashboard\",\"network-16\",\"malibu-fill-gradient-dark-gray flex-none\",16]],null],[1,\"\\n    \"],[10,1],[14,0,\"ml2\"],[12],[1,\"Entering the SSO name at \"],[10,3],[14,6,\"https://sso.heroku.com/login\"],[14,0,\"hk-link\"],[12],[1,\"https://sso.heroku.com/login\"],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"dark-gray flex items-start mt1\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Dashboard\",\"console-16\",\"malibu-fill-gradient-dark-gray flex-none\",16]],null],[1,\"\\n    \"],[10,1],[14,0,\"ml2\"],[12],[1,\"Using the CLI \"],[10,1],[14,0,\"hk-badge--code\"],[12],[1,\"heroku login\"],[13],[1,\" command, selecting the \\\"Log in via SSO\\\" option, and entering the \"],[10,1],[14,0,\"dib\"],[12],[1,\"SSO name.\"],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/identity-provider/login-info.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});