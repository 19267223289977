define("dashboard/models/team/resource-item", ["exports", "@ember/object", "@ember/object/computed", "ember-data/model", "ember-data/attr"], function (_exports, _object, _computed, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    addons: (0, _attr.default)(),
    formation: (0, _attr.default)(),
    total: (0, _computed.alias)('addonsTotal'),
    addonCosts: (0, _computed.mapBy)('addons', 'cost'),
    addonsTotal: (0, _computed.sum)('addonCosts'),
    dynoUnitCounts: (0, _computed.mapBy)('formation', 'units'),
    dynoUnits: (0, _computed.sum)('dynoUnitCounts'),
    hasSpend: (0, _object.computed)('addonCosts', 'dynoUnits', function () {
      const {
        addonsTotal,
        dynoUnits
      } = this;
      return addonsTotal > 0 || dynoUnits > 0;
    }),
    activeDynos: (0, _computed.filter)('formation.[]', function (item) {
      return item.quantity > 0;
    })
  });

  _exports.default = _default;
});