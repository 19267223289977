define("dashboard/adapters/build", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    range: {
      attribute: 'created_at',
      max: 10,
      ascending: false
    },
    version: '3.pipelines',

    // Used for reloading record with manual deploys
    urlForFindRecord(id, modelName, snapshot) {
      const baseUrl = this.buildURL();
      const app = snapshot.record.get('app.id');
      const build = snapshot.record.get('id');
      return `${baseUrl}/apps/${app}/builds/${build}`;
    },

    urlForQueryRecord(query) {
      const baseUrl = this.buildURL();
      const app = query.app;
      const buildId = query.buildId;
      delete query.app;
      delete query.buildId;
      return `${baseUrl}/apps/${app}/builds/${buildId}`;
    },

    urlForQuery(query) {
      const url = `${this.host}/apps/${query.app}/builds`;
      delete query.app;
      return url;
    },

    query(store, type, query) {
      if (query.latest) {
        this.set('range', {
          attribute: 'created_at',
          ascending: false,
          max: 1
        });
      } else {
        this.set('range', {
          attribute: 'created_at',
          ascending: false
        });
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});