define("dashboard/components/metrics/charts/row-chart-elements/indicator-row", ["exports", "@ember/service", "@ember/object", "dashboard/components/metrics/charts/registered-chart-element", "dashboard/templates/components/metrics/charts/row-chart-elements/indicator-row"], function (_exports, _service, _object, _registeredChartElement, _indicatorRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _registeredChartElement.default.extend({
    layout: _indicatorRow.default,
    tagName: 'g',
    classNames: ['metrics__graph-row'],
    attributeBindings: ['transform'],
    analytics: (0, _service.inject)(),
    height: 20,
    chartContextGroupComponentName: (0, _object.computed)(function () {
      return 'metrics/charts/connected-chart-hover/group';
    }),
    y: (0, _object.computed)('previousSibling.bottomY', function () {
      return this.get('previousSibling.bottomY') || 0;
    }),
    bottomY: (0, _object.computed)('y', 'height', function () {
      return this.y + this.height;
    }),
    transform: (0, _object.computed)('y', function () {
      return `translate(0,${this.y})`;
    }),
    color: readOnly('level'),
    target: (0, _object.computed)('url', function () {
      const url = this.url;
      const isRelative = /^\//.test(url);
      return isRelative ? '_self' : '_blank';
    }),
    indicatorComponent: 'metrics/charts/row-chart-elements/indicator',
    actions: {
      trackAndGoToDevCenterUrl() {
        const devCenterUrl = this.url;
        this.analytics.logEvent('DevCenter Link', 'Clicked', {
          href: devCenterUrl,
          via: 'events-chart'
        });
        window.open(devCenterUrl);
      }

    }
  });

  _exports.default = _default;
});