define("dashboard/components/metrics/charts/chart-content/line-chart", ["exports", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'g',
    classNames: ['chart-content'],
    attributeBindings: ['chartDimensions.innerTransform:transform'],
    primaryAxisTimeSeries: (0, _computed.filterBy)('chartContentData.chartTimeSeries', 'useAlternateScale', undefined),
    visibleTimeSeries: (0, _computed.filterBy)('primaryAxisTimeSeries', 'isVisible'),
    maxTimeExtended: (0, _object.computed)('maxTime', 'rollupDuration', function () {
      return this.maxTime + this.rollupDuration;
    }),
    actions: {
      toggleVisibility(key) {
        // Prevent the last visible timeseries from being turned off and always
        // allow a timeseries to be re-enabled
        const graph = this.get('graphs').findBy('key', key);

        if (this.get('visibleTimeSeries.length') > 1 || !graph.get('isVisible')) {
          graph.component.toggleProperty(`isShowing${key}`);
        }
      }

    }
  });

  _exports.default = _default;
});