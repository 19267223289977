define("dashboard/components/restart-dynos", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    actions: {
      restartDynos() {
        const app = this.app;
        return app.restartDynos().then(() => {
          this.analytics.logEvent('App Dynos', 'Restarted');
          this.set('isRestartConfirmationModalVisible', false);
        });
      }

    }
  });

  _exports.default = _default;
});