define("dashboard/helpers/number-helper", ["exports", "@ember/component/helper", "numeral"], function (_exports, _helper, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A helper for displaying numerical values.
   *
   * @class Ember.Helper.helper.number-helper
   */
  var _default = (0, _helper.helper)(function (_ref, namedArgs) {
    let [value] = _ref;
    value = value || 0; // safety check

    const format = namedArgs.format || '0,0[.][00000000]';
    return (0, _numeral.default)(value).format(format);
  });

  _exports.default = _default;
});