define("dashboard/models/production-check/custom-maintenance-page", ["exports", "dashboard/mixins/production-check", "@ember/object"], function (_exports, _productionCheck, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    devCenterURL: 'https://devcenter.heroku.com/articles/error-pages#customize-pages',
    title: 'Custom Maintenance Pages',
    warningMessage: "Configure customized error and maintenance pages to match your app's branding.",
    configVars: (0, _object.computed)('app.configVars.variables', function () {
      return this.app.get('configVars.variables') || {};
    }),
    status: (0, _object.computed)('configVars', function () {
      if (this.configVars.ERROR_PAGE_URL && this.configVars.MAINTENANCE_PAGE_URL) {
        return 'passed';
      }

      return 'warning';
    })
  });

  _exports.default = _default;
});