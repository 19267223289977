define("dashboard/components/metrics/charts/connected-chart-hover", ["exports", "@ember/string", "@ember/object", "@ember/component", "jquery"], function (_exports, _string, _object, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * an alternate version of the chart context that has support for flexible content
   *
   */
  var _default = _component.default.extend({
    tagName: 'metrics-magic-chart-context',
    isContext: true,
    classNameBindings: [':metrics__magic-chart-context', '_side'],
    attributeBindings: ['style'],

    /**
     * a collection of metrics/graph-chart-context/group children
     */

    /**
     * the side the component should be pinned to
     *
     * by default it is the right side but will change to the left
     * if this component would bleed outside of the chart
     *
     */
    _side: (0, _object.computed)('selectedTimeX', 'width', 'chartDimensions.width', function () {
      const x = this.selectedTimeX;
      const width = this.width;
      const chartWidth = this.get('chartDimensions.width');
      const naturalRight = x + width;
      const wouldBeOutsideOfChart = naturalRight > chartWidth;

      if (wouldBeOutsideOfChart) {
        return 'left';
      } else {
        return 'right';
      }
    }),
    _left: (0, _object.computed)('_side', 'width', 'selectedTimeX', function () {
      return this._side === 'right' ? this.selectedTimeX : this.selectedTimeX - this.width;
    }),

    /**
     * sets the location of the box using jQuery so that we can avoid vendor prefixing
     * of `transform`
     */
    style: (0, _object.computed)('_left', 'chartDimensions.padding.top', function () {
      const x = this._left;
      const y = this.get('chartDimensions.padding.top');
      const style = `transform: translate3d(${x}px, ${y}px, 0px)`;
      return (0, _string.htmlSafe)(style);
    }),

    onResize() {
      this.set('width', (0, _jquery.default)(this.element).width());
    },

    actions: {
      onResize(el) {
        this.onResize(el);
      }

    }
  });

  _exports.default = _default;
});