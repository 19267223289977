define("dashboard/mixins/component-with-online-status", ["exports", "@ember/service", "@ember/runloop", "jquery", "@ember/object/mixin", "@ember/object/evented", "@ember/object"], function (_exports, _service, _runloop, _jquery, _mixin, _evented, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    isOffline: (0, _object.computed)(function () {
      return false;
    }),
    errorMonitor: (0, _service.inject)(),
    // Why don't we use navigator.onLine?
    //
    // It doesn't work right! We've received many support tickets
    // from customers across various browsers reporting that
    // they've encountered the offline message despite actually
    // being connected to the net and Dashboard otherwise working.
    attachOnlineStatusCheck: (0, _evented.on)('didInsertElement', function () {
      (0, _jquery.default)(document).ajaxError((event, jqxhr
      /* , settings, thrownError*/
      ) => {
        const hasNoStatus = jqxhr.status === 0; // we may have ajax requests that have timeouts on them
        // if that timeout is exceeded we will reach this area of the code
        // we *cannot* infer that a request that timed out failed because the
        // user was offline

        const wasNotTimeout = jqxhr.statusText !== 'timeout'; // it is impossible to differentiate between a response with bad cors headers
        // and an offline browser failure
        // because they both return statusCode of 0
        //
        // therefore, we allow an ajax request to ignore this state
        // if it is a some what common occurance that invalid
        // cors response headers are returned

        const shouldIgnoreOfflineStatus = jqxhr.shouldIgnoreOfflineStatus;

        if (hasNoStatus && wasNotTimeout && !shouldIgnoreOfflineStatus) {
          if (!this.isDestroyed) {
            this.set('isOffline', true); // give the error monitor the opportunity to actually create the errors
            // then remove them because we want to prioritize the "offline status" error
            // message

            (0, _runloop.next)(this, function () {
              this.errorMonitor.wipe();
            });
          }
        }
      });
      (0, _jquery.default)(document).ajaxSuccess(() => {
        if (!this.isDestroyed) {
          (0, _runloop.run)(() => this.set('isOffline', false));
        }
      });
    })
  });

  _exports.default = _default;
});