define("dashboard/components/metrics/chart-controls/refresh-toggle", ["exports", "@ember/component", "@ember/object", "dashboard/mixins/component-with-online-status"], function (_exports, _component, _object, _componentWithOnlineStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    not,
    or
  } = _object.computed;

  var _default = _component.default.extend(_componentWithOnlineStatus.default, {
    tagName: 'metrics-refresh-toggle',
    title: (0, _object.computed)('isOffline', function () {
      if (this.isOffline) {
        return 'You are not connected to the internet.';
      }
    }),
    refreshButtonDisabled: or('isAutoRefreshing', 'isOffline'),
    refreshTitle: (0, _object.computed)('isOffline', function () {
      if (!this.isOffline) {
        return 'Automatically refresh data';
      }
    }),
    isPaused: not('isAutoRefreshing'),
    pauseButtonDisabled: or('isPaused', 'isOffline'),
    pauseTitle: (0, _object.computed)('isOffline', function () {
      if (!this.isOffline) {
        return 'Pause automatic refresh';
      }
    }),
    pauseIconClass: (0, _object.computed)('pauseButtonDisabled', function () {
      if (this.pauseButtonDisabled) {
        return `fill-white nudge-up--1 pe-none`;
      } else {
        return `malibu-fill-gradient-purple nudge-up--1 pe-none`;
      }
    }),
    playIconClass: (0, _object.computed)('pauseButtonDisabled', function () {
      if (this.pauseButtonDisabled) {
        return `malibu-fill-gradient-purple nudge-up--1 pe-none`;
      } else {
        return `fill-white nudge-up--1 pe-none`;
      }
    }),
    actions: {
      startRefresh() {
        this.toggleAutoRefresh(true);
      },

      pauseRefresh() {
        this.toggleAutoRefresh(false);
      }

    }
  });

  _exports.default = _default;
});