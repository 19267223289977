define("dashboard/components/enterprise-account/manage-third-party-connection", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    githubAppInstallation: (0, _computed.readOnly)('enterpriseAccount.githubAppInstallation'),
    hasGithubAppInstalled: (0, _computed.notEmpty)('githubAppInstallation.content'),
    hasAdminAccess: (0, _computed.readOnly)('enterpriseAccount.currentPermissions.canManageGitProviders'),
    appInstallationType: (0, _object.computed)(function () {
      return 'enterprise-account';
    }),
    pendingInstallationContextId: (0, _object.computed)('enterpriseAccount', function () {
      return this.enterpriseAccount.id;
    })
  });

  _exports.default = _default;
});