define("dashboard/components/datastore/data-size-chart", ["exports", "dashboard/components/datastore/datastore-chart", "@ember/object", "dashboard/utils/metrics/unit-converters"], function (_exports, _datastoreChart, _object, _unitConverters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _datastoreChart.default.extend({
    classNames: ['metrics__data-size-chart'],
    unit: (0, _object.computed)('dataConversion', function () {
      return this.dataConversion['key'];
    }),
    dataConversion: (0, _unitConverters.getScalingBytesConversion)('data'),
    scaledData: (0, _object.computed)('dataConversion', function () {
      const conversionUnit = this.dataConversion['conversion'];
      return this.data.map(_ref => {
        let [timestamp, bytes] = _ref;
        return [timestamp, bytes / conversionUnit];
      });
    }),
    datastoreTimeSeries: (0, _object.computed)('scaledData', function () {
      return _object.default.create({
        name: this.title,
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/datastore-area-graph',
        index: 0,
        data: this.scaledData,
        isVisible: true,
        key: 'Avg',
        hideLine: false
      });
    }),
    timeSeriesData: (0, _object.computed)('datastoreTimeSeries', function () {
      return [this.datastoreTimeSeries.get('data')];
    }),
    chartContentData: (0, _object.computed)('datastoreTimeSeries', function () {
      return _object.default.create({
        chartTimeSeries: [this.datastoreTimeSeries]
      });
    }),
    summaryData: (0, _object.computed)('datastoreTimeSeries', function () {
      return _object.default.create({
        min: this.get('minMinValue'),
        max: this.get('maxMaxValue'),
        unit: this.get('unit'),
        format: this.get('valueFormat')
      });
    })
  });

  _exports.default = _default;
});