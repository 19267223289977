define("dashboard/components/enterprise-account/member", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-cp-validations", "@ember/service", "ember-concurrency"], function (_exports, _component, _object, _computed, _emberCpValidations, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('format', {
      type: 'email',
      debounce: 400
    }), (0, _emberCpValidations.validator)('computed-exclusion', {
      in: 'memberEmails',
      message: 'Already added'
    })]
  });

  var _default = _component.default.extend(Validations, {
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    api: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    tagName: '',
    member: null,
    memberEmails: [],
    isConfirmingDelete: false,
    enterpriseAccount: null,
    email: (0, _computed.oneWay)('member.userEmail'),
    permissionNames: (0, _computed.readOnly)('enterpriseAccount.permissionNames'),
    permissionDescriptions: (0, _computed.readOnly)('enterpriseAccount.permissionDescriptions'),
    enterpriseAccountId: (0, _computed.readOnly)('enterpriseAccount.id'),
    permits: (0, _object.computed)('member', 'permissionNames.[]', 'member.permissons.[]', function () {
      const member = this.member;
      return this.permissionNames.map(name => {
        return _object.default.create({
          name,
          description: this.permissionDescriptions[name],
          enabled: member && member.hasPermission(name) || name === 'view',
          disabled: name === 'view'
        });
      });
    }),
    enabledPermits: (0, _computed.filterBy)('permits', 'enabled', true),
    permissions: (0, _computed.mapBy)('enabledPermits', 'name'),
    isPristine: (0, _object.computed)('permissions.[]', 'member.permissions.[]', function () {
      const permissions = this.permissions;
      const memberPermissions = this.get('member.permissions') || [];
      return permissions.every(p => memberPermissions.includes(p)) && memberPermissions.every(p => permissions.includes(p));
    }),
    isCurrentUser: (0, _object.computed)('current.account', 'email', function () {
      return this.email === this.current.account.email;
    }),
    saveUser: (0, _emberConcurrency.task)(function* (event) {
      if (event) {
        event.preventDefault();
      }

      const store = this.store;
      const member = this.member || store.createRecord('enterprise-account/member', {
        user: this.email,
        enterpriseAccount: this.enterpriseAccount
      });
      member.set('permissions', this.permissions);
      yield member.save();

      if (this.isCurrentUser) {
        // The current user's permissions are on the EA as well as on their
        // EA member record (which is what's being updated here). So, if the
        // user updated their own permissions, we need to reload the EA
        // so that it reflects the changes.
        yield this.enterpriseAccount.reload();
      }

      this.close();
    }).drop(),
    sendMfaRequestToken: (0, _emberConcurrency.task)(function* () {
      this.analytics.logEvent('Enterprise Account SSO', 'Sent MFA Recovery Token Request');
      const userId = this.member.userId;
      const eaId = this.enterpriseAccountId;
      const api = this.api;
      yield api.post(`/enterprise-accounts/${eaId}/members/${userId}/actions/temp_token`);
    }),
    actions: {
      startDelete() {
        this.set('isConfirmingDelete', true);
      },

      cancelDelete() {
        this.set('isConfirmingDelete', false);
      },

      sendMfaRequest() {
        this.sendMfaRequestToken.perform();
      },

      async removeUser(member) {
        const enterpriseAccount = member.enterpriseAccount.content;

        try {
          await member.destroyRecord();
        } catch (err) {
          member.rollbackAttributes();
          throw err;
        }

        await enterpriseAccount.hasMany('members').reload();
        this.close();
      }

    }
  });

  _exports.default = _default;
});