define("dashboard/models/pipeline-owner", ["exports", "@ember/object", "@ember/utils", "@ember/object/computed"], function (_exports, _object, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    isEnterpriseTeam: (0, _computed.notEmpty)('enterpriseAccountId'),
    isTeam: (0, _computed.equal)('type', 'team'),
    isUser: (0, _computed.equal)('type', 'user'),
    concealed: (0, _object.computed)('isTeam', 'email', 'name', function () {
      if (this.isTeam) {
        return (0, _utils.isBlank)(this.name);
      } else {
        return (0, _utils.isBlank)(this.email);
      }
    }),
    label: (0, _object.computed)('isTeam', 'email', 'name', function () {
      const name = this.name;

      if (this.isTeam) {
        return (0, _utils.isPresent)(name) ? name : 'unknown team or organization';
      } else {
        const email = this.email;

        if ((0, _utils.isPresent)(name)) {
          return (0, _utils.isPresent)(email) ? `${name} (${email})` : name;
        } else {
          return (0, _utils.isPresent)(email) ? email : 'unknown user';
        }
      }
    })
  });

  _exports.default = _default;
});