define("dashboard/components/scheduler/empty-state", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'header',
    classNames: ['purple-box', 'mb3'],
    'data-test-target': 'jobs-container'
  });

  _exports.default = _default;
});