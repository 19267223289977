define("dashboard/utils/base-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = baseURL;

  /* globals purl */

  /**
   * Strips everything after the domain from a URL.
   *
   * For example, it turns this:
   *
   *   https://dashboard.heroku.com/account/settings
   *
   * into this:
   *
   *   https://dashboard.heroku.com
   */
  function baseURL(url) {
    return url ? purl(url).attr('base') : '';
  }
});