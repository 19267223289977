define("dashboard/templates/components/space-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xqTlCLfk",
    "block": "[[[41,[30,0,[\"space\",\"isAllocating\"]],[[[1,\"  \"],[10,0],[14,0,\"db pv2\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-baseline limit-width\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"mr2\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"shield\",\"size\",\"class\"],[[30,0,[\"space\",\"shield\"]],28,\"malibu-fill-gradient-gray\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"f4 gray\"],[12],[10,\"b\"],[12],[1,[30,0,[\"space\",\"name\"]]],[13],[1,\" will be available \"],[1,[30,0,[\"timeRemaining\"]]],[1,\"...\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[6,[39,2],null,[[\"class\",\"route\",\"models\"],[\"db link pv2 cursor-hand hover-bg-near-white dark-gray bg-white ember-view\",\"team.space\",[28,[37,3],[[30,0,[\"space\",\"team\",\"name\"]],[30,0,[\"space\",\"name\"]]],null]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"flex items-baseline limit-width\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"mr2\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"shield\",\"size\"],[[30,0,[\"space\",\"shield\"]],28]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"f3 near-black\"],[12],[1,[30,0,[\"space\",\"name\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]]]],[],false,[\"if\",\"space-icon\",\"link-to\",\"array\"]]",
    "moduleName": "dashboard/templates/components/space-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});