define("dashboard/components/account/billing-history", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sort
  } = _object.computed;

  var _default = _component.default.extend({
    truncateAt: 8,
    invoiceSortKey: ['periodStart:desc'],
    sortedInvoices: sort('invoices', 'invoiceSortKey'),
    allShown: (0, _object.computed)('sortedInvoices.[]', 'truncateAt', function () {
      return this.get('sortedInvoices.length') <= this.truncateAt;
    }),
    firstGroup: (0, _object.computed)('sortedInvoices.[]', 'truncateAt', function () {
      return this.sortedInvoices.slice(0, this.truncateAt);
    }),
    remainingGroup: (0, _object.computed)('sortedInvoices.[]', 'truncateAt', function () {
      return this.sortedInvoices.slice(this.truncateAt);
    }),
    truncated: (0, _object.computed)('sortedInvoices.[]', 'truncateAt', function () {
      return this.get('sortedInvoices.length') > this.truncateAt;
    }),
    actions: {
      showAll() {
        this.set('allShown', true);
      },

      showLess() {
        this.set('allShown', false);
      }

    }
  });

  _exports.default = _default;
});