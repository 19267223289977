define("dashboard/components/payment-method/form", ["exports", "@ember/component", "dashboard/models/country", "dashboard/models/state", "@ember/object", "@ember/object/computed", "ember-changeset", "ember-changeset-validations", "@ember/service", "ember-concurrency", "@ember/string", "dashboard/validations/payment-method", "ember-data"], function (_exports, _component, _country, _state, _object, _computed, _emberChangeset, _emberChangesetValidations, _service, _emberConcurrency, _string, _paymentMethod, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  
  
  
  
  
  Looking to learn how the component works and how to test or add to the Braintree helpers?
  Check out https://github.com/heroku/dashboard/blob/master/docs/credit-card-forms.md
  SORRY: This comment is intentionally huge in an effort to help making the in-depth guide a bit easier to find.
  
  
  
  
  
  */
  const {
    AdapterError
  } = _emberData.default;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    reporter: (0, _service.inject)(),
    current: (0, _service.inject)(),
    flashMessages: (0, _service.inject)('flash-messages'),
    containerId: 'payment-form-panel',
    slidePanelTitle: (0, _object.computed)('title', function () {
      return this.title || 'Enter Your Payment Information';
    }),
    countries: (0, _object.computed)(function () {
      return _country.default.all();
    }),
    states: (0, _object.computed)(function () {
      return _state.default.all();
    }),
    vatCountries: (0, _object.computed)(function () {
      return _country.default.vatApprovedCountryCodes();
    }),
    changeset: (0, _object.computed)('paymentMethod', function () {
      const country = this.paymentMethod.country || 'US';

      const changeset = this._generateChangeset(this.paymentMethod, _paymentMethod.paymentMethodModelValidations);

      changeset.set('country', country);
      return changeset;
    }),
    shouldShowItalianTerms: (0, _object.computed)('changeset.country', function () {
      return this.changeset.get('country') === 'IT';
    }),
    canSubmit: (0, _object.computed)('shouldShowItalianTerms', 'acceptedItalianCustomerTerms', 'acceptedItalianPartnerTerms', function () {
      if (!this.shouldShowItalianTerms) {
        return true;
      }

      return this.acceptedItalianCustomerTerms && this.acceptedItalianPartnerTerms;
    }),
    cannotSubmit: (0, _computed.not)('canSubmit'),
    hostedFieldsChangeset: (0, _object.computed)(function () {
      return this._generateChangeset({}, _paymentMethod.hostedFieldValidations);
    }),

    _generateChangeset(model, validations) {
      return new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validations), validations, {
        skipValidation: true
      });
    },

    paymentGateway: (0, _service.inject)('payment-gateway'),
    fields: (0, _computed.readOnly)('paymentGateway.braintreeFields'),

    init() {
      this._super(...arguments);

      this.paymentGateway.preload(!!this.useTeamEndpoint);
    },

    _initializeHostedFieldsTask: (0, _emberConcurrency.task)(function* () {
      yield this.paymentGateway.initializeHostedFields();
    }).restartable(),
    keyAsc: ['key:asc'],
    invalidCardFieldsSorted: (0, _computed.sort)('changesetErrors', 'keyAsc'),
    hasInvalidCardFields: (0, _computed.gt)('invalidCardFields.length', 0),
    invalidCardFieldsMessage: (0, _object.computed)('invalidCardFieldsSorted', function () {
      return this.invalidCardFieldsSorted.map(error => {
        return this._displayLabelForChangesetField(error.key);
      }).join(', ');
    }),

    _displayLabelForChangesetField(fieldName) {
      return fieldName === 'cvv' ? 'CVV' : fieldName.underscore().split(/_/).map(str => (0, _string.capitalize)(str)).join(' ');
    },

    save(paymentMethod) {
      return paymentMethod.save();
    },

    _submitCardTask: (0, _emberConcurrency.task)(function* () {
      this.set('didValidate', false);
      this.set('genericErrorMessage', '');
      this.set('changesetErrors', []);
      const {
        analyticsNoun
      } = this.paymentMethod; // run client side validations

      this.analytics.logEvent(`${analyticsNoun} Save Details`, 'Clicked');

      try {
        yield this.hostedFieldsChangeset.validate();
        yield this.changeset.validate();

        if (this.changeset.isValid && this.hostedFieldsChangeset.isValid) {
          // tokenize the credit card inputs and then send to API if successful.
          try {
            const {
              nonce,
              details
            } = yield this.paymentGateway.tokenize({
              postalCode: this.changeset.get('postal_code')
            });
            const deviceData = yield this.paymentGateway.deviceData();
            this.changeset.set('nonce', nonce);
            this.changeset.set('deviceData', deviceData);
            yield this.changeset.execute();
            yield this.save(this.paymentMethod); // need to set this for because the team credit card doesn't actually
            // save until later, but we need to display the card_type and last_4

            this.paymentMethod.set('card_type', details.cardType);
            this.paymentMethod.set('card_last4', details.lastFour);
            yield this._closeForm(true);
          } catch (error) {
            this.reporter.error(error);

            this._generateChangesetErrors(error);
          }
        }
      } finally {
        // copy the errors so that forms don't immediately invalidate and show
        // up in the form. We want the errors displayed to update only when the
        // form is submitted.
        const modelChangesetErrors = this.changeset.errors || [];
        const hostedFieldChangesetErrors = this.hostedFieldsChangeset.errors || [];
        this.set('changesetErrors', modelChangesetErrors.concat(hostedFieldChangesetErrors));
        this.set('didValidate', true); // Scroll the panel container to the top if there are errors so that the
        // user knows what's up.

        if (this.changesetErrors && this.changesetErrors.length > 0) {
          document.getElementById(this.containerId).scrollTop = 0;
        }
      }
    }).drop(),

    _generateChangesetErrors(error) {
      if (error instanceof AdapterError) {
        this._showGenericErrorMessage(error.errors.firstObject.detail);

        return;
      }

      switch (error.code) {
        case 'HOSTED_FIELDS_FIELDS_EMPTY':
          {
            ['cardNumber', 'cvv', 'expirationDate'].forEach(field => {
              this.hostedFieldsChangeset.pushErrors(field);
            });
            break;
          }

        case 'HOSTED_FIELDS_FIELDS_INVALID':
          {
            error.invalidFieldKeys.forEach(field => {
              if (field === 'number') {
                field = 'cardNumber';
              }

              this.hostedFieldsChangeset.pushErrors(field, 'Invalid');
            });
            break;
          }

        case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
          {
            this._showGenericErrorMessage('A network error occurred while trying to reach the payment gateway. Please try again.');

            break;
          }

        case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
        case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
        case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
        default:
          {
            this._showGenericErrorMessage('An error occurred trying to add your card. Please try again.');
          }
      }
    },

    _showGenericErrorMessage(message) {
      this.set('genericErrorMessage', message);
    },

    async _closeForm(wasVerified) {
      this.set('changesetErrors', []);
      this.set('didValidate', false);
      this.notifyPropertyChange('changeset');
      await this.paymentGateway.teardownHostedFields();

      if (wasVerified && typeof this.onVerified === 'function') {
        await this.onVerified();
      }

      this.close();
    },

    actions: {
      closeSlidePanel() {
        return this._closeForm();
      },

      onSlidePanelReady() {
        this._initializeHostedFieldsTask.perform();
      },

      async submitCard() {
        this._submitCardTask.perform();

        await (0, _emberConcurrency.waitForProperty)(this, 'didValidate', true);
        const currentAccount = this.current.account;
        const italianPartnerTerms = this.acceptedItalianPartnerTerms ? 'affirmatively_accepted' : 'non_applicable';
        const italianCustomerTerms = this.acceptedItalianCustomerTerms ? 'affirmatively_accepted' : 'non_applicable';
        currentAccount.set('italianCustomerTerms', italianCustomerTerms);
        currentAccount.set('italianPartnerTerms', italianPartnerTerms);
      },

      setChangesetValue(key, value) {
        this.changeset.set(key, value);
      }

    }
  });

  _exports.default = _default;
});