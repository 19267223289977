define("dashboard/models/unverify-payment-method-request", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    state: (0, _model.attr)(),
    invoices: (0, _model.hasMany)('invoice'),
    started: (0, _computed.equal)('state', 'started'),
    waitingForUserInput: (0, _computed.equal)('state', 'waiting_for_user_input'),
    processing: (0, _computed.equal)('state', 'processing'),
    finished: (0, _computed.equal)('state', 'finished'),
    error: (0, _computed.equal)('state', 'error'),

    confirm() {
      const store = this.store;
      const adapter = store.adapterFor('unverify-payment-method-request');
      return adapter.confirm().then(payload => {
        const {
          state
        } = payload;
        this.set('state', state);
        return this;
      });
    }

  });

  _exports.default = _default;
});