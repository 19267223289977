define("dashboard/templates/components/org/set-default-org-role", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YL/HSsZu",
    "block": "[[[10,\"form\"],[14,0,\"form flex w-50\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"value\",\"onChange\",\"class\"],[[30,0,[\"role\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"role\"]]],null]],null],\"form-control member-role flex-auto mr3\"]],[[\"default\"],[[[[1,\"    \"],[10,\"option\"],[12],[1,\"Select a default role\"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"memberRoles\"]]],null]],null],null,[[[6,[30,1,[\"option\"]],null,[[\"value\"],[[30,2,[\"type\"]]]],[[\"default\"],[[[[1,\"        \"],[1,[30,2,[\"type\"]]],[1,\"\\n\"]],[]]]]]],[2]],null]],[1]]]]],[13],[1,\"\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\"],[10,2],[12],[1,\"\\n  \"],[8,[39,5],[[16,0,[52,[30,0,[\"buttonIsDisabled\"]],\"hk-button--disabled-primary\",\"hk-button--primary\"]]],[[\"@action\",\"@default\",\"@disableWhen\",\"@reset\",\"@usePendingSpinner\"],[[28,[37,1],[[30,0],\"saveDefaultRole\"],null],\"Set Default Role\",[30,0,[\"buttonIsDisabled\"]],true,true]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"xs\",\"option\"],false,[\"x-select\",\"action\",\"mut\",\"each\",\"-track-array\",\"async-button\",\"if\"]]",
    "moduleName": "dashboard/templates/components/org/set-default-org-role.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});