define("dashboard/components/team/member-panel/button", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    indicateExtraSteps: false,
    buttonLabelForCreate: (0, _object.computed)('role', 'team.canManuallyInviteNewMembers', 'indicateExtraSteps', function () {
      let result;

      if (this.role === 'collaborator') {
        result = 'Add collaborator';
      } else if (this.get('team.canManuallyInviteNewMembers')) {
        result = 'Invite user';
      } else {
        result = 'Add user';
      }

      if (this.indicateExtraSteps) {
        result += '…';
      }

      return result;
    }),
    buttonLabelForUpdate: (0, _object.computed)('role', 'member.role', 'indicateExtraSteps', function () {
      const oldRole = this.get('member.role');
      const newRole = this.get('role');
      let result = 'Save changes';

      if (newRole !== oldRole) {
        result = 'Change role';
      }

      if (this.indicateExtraSteps) {
        result += '…';
      }

      return result;
    })
  });

  _exports.default = _default;
});