define("dashboard/components/metrics/charts/visualizations/memory-chart", ["exports", "@ember/object", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart"], function (_exports, _object, _computed, _wrappedLineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    unit: 'memory',
    insufficientDataTitle: 'Insufficient Data',
    boundaryMultiplier: 1.1,
    enableQuotaLine: true,
    noData: (0, _computed.not)('data'),
    noDynoMemory: (0, _computed.not)('dynoMemory'),
    isLoadingData: (0, _computed.or)('noData', 'noDynoMemory'),
    is10PercentOfQuota: (0, _computed.gte)('dynoMemory.maxMemoryTotalMaxValuePercentage', 10),
    isShowingQuota: (0, _computed.and)('enableQuotaLine', 'is10PercentOfQuota'),
    timeSeriesQuota: (0, _object.computed)('dynoMemory.maxPercentageUsedData', 'dynoMemory.memoryQuotaMax', 'isShowingQuota', function () {
      return _object.default.create({
        name: 'Mem Quota',
        type: 'metrics/charts/line-chart-elements/quota-line-graph',
        extraData: this.get('dynoMemory.maxPercentageUsedData'),
        index: 0,
        data: this.get('dynoMemory.memoryQuotaMax'),
        isVisible: this.isShowingQuota,
        key: 'Quota'
      });
    })
  });

  _exports.default = _default;
});