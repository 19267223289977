define("dashboard/templates/components/app/datastore/credential-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EL69W9yh",
    "block": "[[[10,\"tr\"],[14,0,\"hover-bg-near-white\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"],[24,0,\"mr1\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"app-16\",16,\"fill-purple nudge-up--1\"]],null],[1,\"\\n    \"],[10,1],[12],[1,[30,0,[\"credentialAttachmentsLength\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"canEdit\",\"canRemove\",\"model\",\"editAction\"],[true,false,[30,1],[28,[37,2],[[30,2],[30,1,[\"name\"]]],null]]]]],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@status\"],[[30,1,[\"state\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@credential\",\"@viewCredential\"],false,[\"malibu-icon\",\"purple-list-action-cell\",\"fn\",\"app/datastore/credential-status-badge\"]]",
    "moduleName": "dashboard/templates/components/app/datastore/credential-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});