define("dashboard/mixins/save-config-vars", ["exports", "@ember/runloop", "rsvp", "@ember/object/mixin", "jquery", "dashboard/config/environment"], function (_exports, _runloop, _rsvp, _mixin, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    saveConfigVars(configVar) {
      const appId = this.id;
      const key = configVar.get('key');
      const value = configVar.get('value'); // Build final data

      const data = {
        [key]: value
      }; // Not sure why async-button doesn't like jquery ajax promises...

      return new _rsvp.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: `${_environment.default.platformApiUrl}/apps/${appId}/config-vars`,
          method: 'PATCH',
          contentType: 'application/json',
          headers: {
            Accept: 'application/vnd.heroku+json; version=3'
          },
          withHerokuAuthorization: true,
          data: JSON.stringify(data)
        }).then(() => {
          (0, _runloop.run)(() => {
            resolve();
          });
        }, error => {
          reject(error);
        });
      });
    }

  });

  _exports.default = _default;
});