define("dashboard/serializers/pipeline-promotion-target", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    extractRelationships(modelClass, hash) {
      const relationships = {};
      relationships.app = {
        data: {
          id: hash.app.id,
          type: 'app'
        }
      };
      relationships.pipelinePromotion = {
        data: {
          id: hash.pipeline_promotion.id,
          type: 'pipeline-promotion'
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});