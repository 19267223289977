define("dashboard/components/name-validator", ["exports", "@ember/component", "@ember/object", "ember-concurrency"], function (_exports, _component, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    not,
    empty,
    alias,
    oneWay
  } = _object.computed;

  var _default = _component.default.extend({
    showingLoadingState: oneWay('checkNameAvailabilityTask.isRunning'),
    verifyImmediately: false,
    notShowingLoadingState: not('showingLoadingState'),
    name: alias('model.name'),
    nameIsValid: alias('model.nameIsValid'),
    nameIsNotValid: not('nameIsValid'),
    nameIsEmpty: empty('model.name'),
    nameIsValidAndAvailable: and('nameIsAvailable', 'nameIsValid'),
    nameIsNotValidAndAvailable: not('nameIsValidAndAvailable'),
    showingAvailabilitySuccess: and('nameIsValidAndAvailable', 'notShowingLoadingState'),
    validationMessage: alias('model.validationMessage'),
    showingNameError: (0, _object.computed)('nameIsNotValidAndAvailable', 'nameIsEmpty', 'showingLoadingState', function () {
      if (this.nameIsEmpty || this.showingLoadingState) {
        return false;
      } else {
        return this.nameIsNotValidAndAvailable;
      }
    }),
    inputClasses: (0, _object.computed)('showingNameError', 'showingAvailabilitySuccess', 'showingLoadingState', function () {
      if (this.showingNameError) {
        return 'hk-input w-100 br--left z-1 b--red hk-focus-ring--red';
      } else if (this.showingAvailabilitySuccess) {
        return 'hk-input w-100 br--left z-1 b--green hk-focus-ring--green';
      } else if (this.showingLoadingState) {
        return 'hk-input w-100 br--left z-1';
      } else {
        return 'hk-input w-100';
      }
    }),
    // Decouples the component from the model, allowing us to pass a custom
    // availability function for models without checkNameAvailability
    checkNameAvailabilityAction: (0, _object.computed)('model', function () {
      return this.model.checkNameAvailability;
    }),
    checkNameAvailabilityTask: (0, _emberConcurrency.task)(function* () {
      if (this.nameIsEmpty) {
        return;
      }

      yield (0, _emberConcurrency.timeout)(500);
      return yield this._checkNameAvailability();
    }).restartable(),

    // Note: we need to define functions this way in order for
    // debounce calls to work
    _checkNameAvailability() {
      return this.checkNameAvailabilityAction().then(isAvailable => {
        this.set('nameIsAvailable', isAvailable);
      });
    },

    didReceiveAttrs() {
      if (this.verifyImmediately) {
        this.send('verifyNameIsAvailable');
      }
    },

    actions: {
      verifyNameIsAvailable() {
        this.checkNameAvailabilityTask.perform();
      }

    }
  });

  _exports.default = _default;
});