define("dashboard/adapters/particleboard", ["exports", "@ember-data/adapter/rest", "dashboard/mixins/adapters/heroku-data-adapter-mixin", "dashboard/config/environment"], function (_exports, _rest, _herokuDataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Particleboard Adapter
  var _default = _rest.default.extend(_herokuDataAdapterMixin.default, {
    host: _environment.default.particleboardUrl
  });

  _exports.default = _default;
});