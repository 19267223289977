define("dashboard/components/app/settings/general-info", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _computed, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'app-general-info',
    accountFeatures: (0, _service.inject)(),
    stackCheck: (0, _computed.alias)('app.stackCheck'),
    shouldUpgradeStack: (0, _computed.alias)('stackCheck.warning'),
    canUpgradeStack: (0, _computed.and)('hasUpgradeStackAppPermissions', 'shouldUpgradeStack'),
    githubRepositoryName: (0, _object.computed)('app.githubAppLink.repo', 'app.connectedRepository.fullName', 'app.team.isGithubAppEnabled', function () {
      if (this.get('app.team.isGithubAppEnabled')) {
        return this.get('app.connectedRepository.fullName');
      } else {
        return this.get('app.githubAppLink.repo');
      }
    }),
    actions: {
      showConfirmUpgrade() {
        this.onShowConfirmUpgrade();
      },

      cancelStackUpgrade() {
        return this.onCancelStackUpgrade();
      }

    }
  });

  _exports.default = _default;
});