define("dashboard/components/eco-button-deploy-subscribe-banner", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-empty-glimmer-component-classes
  class EcoButtonDeploySubscribeBannerComponent extends _component.default {}

  _exports.default = EcoButtonDeploySubscribeBannerComponent;
});