define("dashboard/components/app/datastore/topic-slide-panel", ["exports", "@ember/component", "@ember/object", "@ember/application", "dashboard/models/kafka-topic.eo"], function (_exports, _component, _object, _application, _kafkaTopic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    isStaticReplicationPlan: (0, _object.computed)('datastore.limits.minimum_replication', 'datastore.limits.maximum_replication', function () {
      return this.datastore.get('limits.minimum_replication') === this.datastore.get('limits.maximum_replication');
    }),
    topic: (0, _object.computed)('updatingTopic', function () {
      if (this.updatingTopic) {
        const hasRetentionTime = !!this.updatingTopic.retention_time_ms;
        return _kafkaTopic.default.create((0, _application.getOwner)(this).ownerInjection(), {
          datastore: this.datastore,
          name: this.updatingTopic.name,
          partition_count: this.updatingTopic.partitions,
          replication_factor: this.updatingTopic.replication_factor,
          compaction: this.updatingTopic.compaction,
          retention: this.updatingTopic.retention_enabled,
          retention_time_hrs: hasRetentionTime ? this.updatingTopic.retention_time_ms / 3600000 : null,
          isUpdatingTopic: true
        });
      } else {
        return _kafkaTopic.default.create((0, _application.getOwner)(this).ownerInjection(), {
          datastore: this.datastore,
          name: '',
          partition_count: null,
          replication_factor: this.datastore.get('limits.minimum_replication'),
          compaction: false,
          retention: true,
          retention_time_hrs: this.datastore.get('limits.minimum_retention_ms') / 3600000
        });
      }
    })
  });

  _exports.default = _default;
});