define("dashboard/components/metrics/formation-summary", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    and,
    equal,
    or
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['formation-summary', 'flex', 'items-center', 'flex-wrap', 'mr2'],
    _processTypeSupportsAutoscaling: alias('process.sizeObject.canAutoscale'),
    isWeb: equal('process.type', 'web'),
    canAccessAutoscaling: and('_processTypeSupportsAutoscaling', 'isWeb'),
    isLoading: or('process.isPending', 'monitorsAreLoading')
  });

  _exports.default = _default;
});