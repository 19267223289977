define("dashboard/components/enterprise-account/member-manager", ["exports", "@ember/component", "@ember/service", "dashboard/utils/custom-computed", "@ember/object/computed", "@ember/object", "ember-concurrency"], function (_exports, _component, _service, _customComputed, _computed, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    // --------------------------------------------------------------------------
    // Arguments
    enterpriseAccount: null,
    user: null,
    email: '',
    permissions: null,
    sso: null,
    mfa: null,
    page: 1,
    pageSize: 40,
    // --------------------------------------------------------------------------
    // Services
    current: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Computed Properties
    permissionsArray: (0, _object.computed)('permissions.[]', function () {
      return this.permissions && this.permissions.length && this.permissions.split(',') || [];
    }),
    addUser: (0, _computed.equal)('user', 'add'),
    member: (0, _customComputed.findBy)('enterpriseAccount.members', 'userEmail', 'user'),
    memberEmails: (0, _computed.mapBy)('enterpriseAccount.members', 'userEmail'),
    total: (0, _computed.readOnly)('query.lastSuccessful.value.total'),
    totalPages: (0, _object.computed)('total', 'pageSize', function () {
      return Math.ceil(this.total / this.pageSize);
    }),
    // --------------------------------------------------------------------------
    // Tasks
    query: (0, _emberConcurrency.task)(function* () {
      const {
        enterpriseAccount,
        page,
        email,
        sso,
        mfa,
        pageSize,
        permissionsArray: permissions
      } = this; // TODO: Only rerun this query if the params above have changed OR some kind
      // of "force" flag is passed (for cases where we know the underlying dataset
      // has changed such as member added/removed).

      let members = yield enterpriseAccount.members;
      members = members.filterBy('isNew', false);
      members = fuzzyFilterOn(members, 'userEmail', email);
      members = members.sortBy('userEmail');

      if (permissions) {
        members = members.filter(member => permissions.every(p => member.permissions.includes(p)));
      }

      if (sso === 'enabled') {
        members = members.filterBy('identityProvider');
      } else if (sso === 'disabled') {
        members = members.rejectBy('identityProvider');
      }

      if (mfa === 'enabled') {
        members = members.filterBy('twoFactorAuthentication');
      } else if (mfa === 'disabled') {
        members = members.rejectBy('twoFactorAuthentication');
      }

      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const items = members.slice(startIndex, endIndex);
      const total = members.length;
      return {
        items,
        page,
        total
      };
    }).restartable().on('didReceiveAttrs'),

    // --------------------------------------------------------------------------
    // Actions
    setEmailFilter(email) {
      this.setProperties({
        email,
        page: 1
      });
    },

    onDismiss() {
      this.set('user', null);
    },

    togglePermissionsFilter(perm) {
      const permissionsArray = [...this.permissionsArray];

      if (permissionsArray.includes(perm)) {
        permissionsArray.removeObject(perm);
      } else {
        permissionsArray.addObject(perm);
      }

      const permissions = permissionsArray.join(',');
      this.setProperties({
        permissions,
        page: 1
      });
    },

    setSSOFilter(sso) {
      if (sso === this.sso) {
        sso = null;
      }

      this.setProperties({
        sso,
        page: 1
      });
    },

    setMFAFilter(mfa) {
      if (mfa === this.mfa) {
        mfa = null;
      }

      this.setProperties({
        mfa,
        page: 1
      });
    },

    clearPermissionsFilter() {
      this.setProperties({
        permissions: '',
        page: 1
      });
    },

    clearAllFilters() {
      this.setProperties({
        email: '',
        permissions: '',
        sso: null,
        mfa: null,
        page: 1
      });
    }

  });

  _exports.default = _default;

  function fuzzyFilterOn(array, key, filterText) {
    filterText = filterText.replace(/[^a-z0-9-]+/gi, '');

    if (filterText.length === 0) {
      return array;
    }

    const filter = filterText.split('').join('.*');
    const regex = new RegExp(filter, 'i');
    return array.filter(item => regex.test(item.get(key)));
  }
});