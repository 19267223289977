define("dashboard/templates/team/switchable/usage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8tItwamr",
    "block": "[[[10,\"ul\"],[14,0,\"list-group list-group-lg\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"description\"],[\"Plan\",\"This Team's currently active licenses.\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,1],null,[[\"organizationLicenses\"],[[30,0,[\"model\",\"plans\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"team\",\"isEnterpriseTeam\"]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"team\"],[[30,0,[\"team\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"description\"],[\"Current Usage\",\"This Team's total usage figures for the current month and projected usage.<br /><br />Add-on usage that exceeds monthly quotas will be auto-paid from from available General add-on credits. <a href='https://devcenter.heroku.com/articles/heroku-enterprise#add-on-credit-overages-for-restricted-add-ons' class='hk-link' target='_blank'>Learn more</a>.\"]],[[\"default\"],[[[[6,[39,4],null,[[\"currentLicenses\"],[[30,0,[\"model\",\"currentUsage\"]]]],[[\"default\"],[[[],[]]]]]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"title\",\"description\"],[\"Usage History\",\"An overview of dyno units and add-on service costs for this Team.<br /><br />For usage dating back further than one year, please <a href='https://help.heroku.com/' class='hk-link' target='_blank'>contact support</a>.\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,5],null,[[\"monthlyLicenses\"],[[30,0,[\"model\",\"monthlyLicenses\"]]]]]],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"list-group-item-purple2\",\"enterprise-usage/active-licenses\",\"if\",\"team/usage/export-usage\",\"enterprise-usage/current-usage\",\"enterprise-usage/history\"]]",
    "moduleName": "dashboard/templates/team/switchable/usage.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});