define("dashboard/models/attachment", ["exports", "@ember/object", "@ember-data/model", "dashboard/config/environment", "@ember/object/computed"], function (_exports, _object, _model, _environment, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    addon: (0, _model.belongsTo)('addon'),
    configVars: (0, _model.attr)(),
    embeddedAddon: (0, _model.attr)(),
    addonAttachments: (0, _computed.alias)('addon.attachments'),
    name: (0, _model.attr)('string'),
    webUrl: (0, _model.attr)('string'),
    namespace: (0, _model.attr)('string'),
    slug: (0, _object.computed)('name', function () {
      return this.name.replace(/_/g, '-').toLowerCase();
    }),
    addonServiceName: (0, _computed.alias)('addonService.name'),
    addonServiceHumanName: (0, _computed.alias)('addonService.humanName'),
    instanceName: (0, _computed.readOnly)('addon.name'),
    isAttached: (0, _object.computed)('addon.content', 'app.id', 'isNotFirstInstanceOnThisApp', function () {
      if (this.isNotFirstInstanceOnThisApp) {
        return true;
      } else if (this.get('addon.content')) {
        // Using the belongsTo API so we don't trigger the network request
        // since this addon could belong to an app that the user doesn't have access to.
        return this.get('addon.content').belongsTo('app').id() !== this.get('app.id');
      }
    }),
    allAssociatedApps: (0, _computed.alias)('addon.allAssociatedApps'),
    allOtherAssociatedApps: (0, _computed.filter)('allAssociatedApps', function (app) {
      // without the current app included
      return app.get('id') !== this.get('app.id');
    }),
    allOtherAssociatedAppNames: (0, _computed.mapBy)('allOtherAssociatedApps', 'name'),
    linkOutUrl: (0, _object.computed)('webUrl', 'addon.webUrl', function () {
      return this.webUrl || this.get('addon.webUrl');
    }),
    attachmentIdentifier: (0, _object.computed)('attachmentName', function () {
      return this.attachmentName ? ` :: ${this.attachmentName}` : '';
    }),
    attachmentName: (0, _object.computed)('addonServiceName', 'slug', function () {
      return this.slug.replace(this.addonServiceName, '').replace(/-/g, '').capitalize();
    }),
    addonServiceDescription: (0, _object.computed)('name', 'addonServiceHumanName', 'attachmentIdentifier', function () {
      if (this.addonServiceHumanName) {
        // why this??
        return this.addonServiceHumanName + this.attachmentIdentifier;
      } else {
        return this.name;
      }
    }),
    addonService: (0, _computed.alias)('addon.addon_service'),
    addonServicePlan: (0, _computed.alias)('addon.addonServicePlan'),
    isPostgres: (0, _computed.alias)('addon.isPostgres'),
    isLogging: (0, _object.computed)('name', function () {
      const loggingAddons = new Set(_environment.default.loggingAddons);
      return loggingAddons.has(this.name.toLowerCase());
    }),
    priceInCents: (0, _computed.alias)('addon.priceInCents'),
    isDirty: (0, _computed.alias)('addon.hasDirtyAttributes'),
    isNotDirty: (0, _computed.not)('addon.hasDirtyAttributes'),

    revert() {
      return this.addon.then(addon => {
        return addon.rollbackAttributes();
      });
    }

  });

  _exports.default = _default;
});