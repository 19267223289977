define("dashboard/templates/components/net-promoter-score/rate-your-experience", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rnxfUyS7",
    "block": "[[[1,[28,[35,0],null,[[\"selectedRating\",\"transitionToNextStep\"],[[30,0,[\"selectedRating\"]],[28,[37,1],[[30,0],[30,0,[\"transitionToNextStep\"]]],null]]]]],[1,\"\\n\"],[10,2],[14,0,\"mb0 gray f5 mb2\"],[12],[1,\"By participating in this survey, you agree to the \"],[10,3],[14,6,\"https://www.salesforce.com/company/privacy/\"],[14,0,\"hk-link\"],[14,\"target\",\"_blank\"],[12],[1,\"Privacy Statement\"],[13],[1,\". This survey is anonymous and none of your personal information will be shared or associated with these results.\\n\"],[13],[1,\"\\n\"]],[],false,[\"net-promoter-score/rating\",\"action\"]]",
    "moduleName": "dashboard/templates/components/net-promoter-score/rate-your-experience.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});