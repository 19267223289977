define("dashboard/templates/components/pagination-links", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IhmczAhN",
    "block": "[[[41,[28,[37,1],[[30,0,[\"totalPages\"]],1],null],[[[41,[30,0,[\"previousPageItem\"]],[[[41,[30,0,[\"previousPageItem\",\"exists\"]],[[[1,\"      \"],[11,3],[24,0,\"mh1 hk-button--tertiary\"],[16,6,[30,0,[\"previousPageItem\",\"url\"]]],[4,[38,2],[[30,0],\"updatePage\",[30,0,[\"previousPageItem\",\"page\"]]],null],[12],[1,\"\\n        \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"direction-left-16\",\"mr1 malibu-fill-gradient-purple\",16]],null],[1,\"\\n        \"],[1,[30,0,[\"previousText\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"pageItems\"]]],null]],null],null,[[[41,[30,1,[\"isPage\"]],[[[1,\"      \"],[11,3],[16,6,[30,1,[\"url\"]]],[16,0,[29,[\"w3 mh--3 hk-button--tertiary\",[52,[30,1,[\"current\"]],\" bg-lightest-purple\"]]]],[4,[38,2],[[30,0],\"updatePage\",[30,1,[\"page\"]]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"page\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"isGap\"]],[[[1,\"      \"],[10,0],[14,0,\"w3 mh--3 tc pv1 gray fw6\"],[12],[1,\"...\"],[13],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"nextPageItem\"]],[[[41,[30,0,[\"nextPageItem\",\"exists\"]],[[[1,\"      \"],[11,3],[16,6,[30,0,[\"nextPageItem\",\"url\"]]],[24,0,\"mh1 hk-button--tertiary\"],[4,[38,2],[[30,0],\"updatePage\",[30,0,[\"nextPageItem\",\"page\"]]],null],[12],[1,\"\\n        \"],[1,[30,0,[\"nextText\"]]],[1,\"\\n        \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"direction-right-16\",\"ml1 malibu-fill-gradient-purple\",16]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]],null],[1,\"\\n\"]],[\"pageItem\"],false,[\"if\",\"gt\",\"action\",\"malibu-icon\",\"each\",\"-track-array\"]]",
    "moduleName": "dashboard/templates/components/pagination-links.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});