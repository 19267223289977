define("dashboard/components/hk-input-with-validation-status", ["exports", "@heroku/ember-hk-components/components/hk-input-with-validation-status"], function (_exports, _hkInputWithValidationStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hkInputWithValidationStatus.default;
    }
  });
});