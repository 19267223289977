define("dashboard/routes/logout", ["exports", "@ember/service", "@ember/routing/route", "dashboard/config/environment"], function (_exports, _service, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const HerokuIdentity = _environment.default['heroku-identity'];

  var _default = _route.default.extend({
    location: (0, _service.inject)(),
    session: (0, _service.inject)(),

    beforeModel() {
      this.controllerFor('application').set('userRequestedLogout', true);

      if (this.get('session.isAuthenticated')) {
        // user is logged in, let simple auth kill the session and handle
        // redirection.
        this.session.invalidate();
      } else {
        // user has no dashboard session; send them to id.heroku.com so the
        // session can be killed there as well.
        this.location.assign(HerokuIdentity.logoutUrl);
      }
    }

  });

  _exports.default = _default;
});