define("dashboard/templates/components/metrics/chart-controls/throughput-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qwQAHHwn",
    "block": "[[[10,0],[14,0,\"hk-button-group metrics__view-controls__toggle-buttons\"],[14,\"role\",\"group\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"title\",\"buttonType\",\"onClick\",\"default\"],[\"RPS\",[52,[30,0,[\"usingRps\"]],\"primary\",\"secondary\"],[28,[37,2],[[30,0],\"setResolution\",\"rps\"],null],\"RPS\"]]]],[1,\"\\n\\n  \"],[1,[28,[35,0],null,[[\"title\",\"buttonType\",\"onClick\",\"default\"],[\"RPM\",[52,[30,0,[\"usingRpm\"]],\"primary\",\"secondary\"],[28,[37,2],[[30,0],\"setResolution\",\"rpm\"],null],\"RPM\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"hk-button\",\"if\",\"action\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/throughput-toggle.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});