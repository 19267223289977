define("dashboard/models/addon-service", ["exports", "@ember/object", "dashboard/config/environment", "@ember-data/model"], function (_exports, _object, _environment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    or,
    not,
    and,
    equal,
    alias,
    bool
  } = _object.computed;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    humanName: (0, _model.attr)('string'),
    slug: alias('name'),
    // because the name is not the name; it's the slug!
    // human_name alias can be removed after AllowlistedAddon is converted to ember data
    // for Integration: OrgAddonsAllowlistSettingsComponent
    human_name: alias('humanName'),
    cliPluginName: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    supportsMultipleInstallations: (0, _model.attr)('boolean'),
    supportsSharing: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    addonServicePlans: (0, _model.hasMany)('addon-service-plan'),
    addonRegionCapabilities: (0, _model.hasMany)('addon-region-capability'),
    // supports this region at all
    currentRegion: null,
    currentRegionIsSupported: null,
    currentRegionIsNotSupported: not('currentRegionIsSupported'),
    // allowlist
    allowlistIsEnabled: null,
    isNotOnAllowlist: null,
    allowlistIsEnabledAndNotOnAllowlist: and('allowlistIsEnabled', 'isNotOnAllowlist'),
    isNotProvisionable: or('currentRegionIsNotSupported', 'allowlistIsEnabledAndNotOnAllowlist'),
    isPostgres: equal('name', _environment.default.postgresAddonName),
    isKafka: equal('name', _environment.default.kafkaAddonName),
    isRedis: equal('name', _environment.default.redisAddonName),
    isHerokuScheduler: equal('name', 'scheduler'),
    deprecatedAddons: [_object.default.create({
      name: 'postmark',
      text: 'Action Required: This add-on is shutting down 6/30/17.'
    }), _object.default.create({
      name: 'stormpath',
      text: 'Action Required: This add-on is shutting down 8/17/17.'
    })],
    deprecation: (0, _object.computed)('deprecatedAddons', 'name', function () {
      return this.deprecatedAddons.findBy('name', this.name);
    }),
    isDeprecated: bool('deprecation'),
    deprecationText: (0, _object.computed)('deprecation', function () {
      return this.get('deprecation.text');
    }),
    marketplaceUrl: (0, _object.computed)('name', function () {
      return `https://elements.heroku.com/addons/${this.name}`;
    }),
    devcenterUrl: (0, _object.computed)('name', function () {
      return `https://devcenter.heroku.com/articles/${this.name}`;
    }),
    defaultPrivateSpaceServicePlan: (0, _object.computed)('addonServicePlans', function () {
      return this.addonServicePlans.findBy('spaceDefault', true) || this.defaultServicePlan;
    }),
    defaultServicePlan: (0, _object.computed)('addonServicePlans', function () {
      return this.addonServicePlans.findBy('default', true) || this.get('addonServicePlans.firstObject');
    }),
    sortedAddonServicePlans: (0, _object.computed)('addonServicePlans.[]', 'addonServicePlans.isFulfilled', function () {
      if (this.addonServicePlans) {
        return this.addonServicePlans.sortBy('priceIsContract', 'priceInCents');
      } else {
        return null;
      }
    }),

    fetchServicePlans(store) {
      return store.query('addon-service-plan', {
        addonServiceNameOrId: this.id
      });
    }

  });

  _exports.default = _default;
});