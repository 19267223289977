define("dashboard/components/app/developer-experience-banners-container", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "@ember/object", "@ember/utils", "dashboard/mixins/preferences", "dashboard/utils/banner-not-dismissed"], function (_exports, _component, _service, _computed, _object, _utils, _preferences, _bannerNotDismissed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_preferences.default, {
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    nonEnterpriseTeamsWhereUserIsAdmin: null,
    'data-test-target': 'developer-experience-banner-container',

    init() {
      this._super(...arguments);

      this.set('originalDismissals', this.dashboardUserPreferences.get('developerExperienceBannerDismissals'));
      this.nonEnterpriseTeamsWhereUserIsAdmin = this.store.peekAll('team').filter(team => !team.isEnterprise && team.isAdmin);
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.canAutoDismissBanners && this.shouldAutoDismissTeamBanner) {
        this.updateUserPreference('developerExperienceBannerDismissals', [...this.originalDismissals, 'teamBanner']);
      }
    },

    /*
     * In the developer experience banner flow, we need to differentiate between a person dismissing a banner
     * because they are uninterested and them already having the product advertised (i.e. pipeline or team).
     * As a result, in the didReceiveAttributes, we use shouldAutoDismissTeamBanner to automatically dismiss the team banner
     * if the user is already an admin of a team with apps or the current app is part of a team already.
     */
    shouldAutoDismissTeamBanner: (0, _object.computed)('nonEnterpriseTeamsWhereUserIsAdmin.[]', 'appNotOwnedByTeam', 'hasNotDismissedTeamBanner', function () {
      return (!this.appNotOwnedByTeam || (0, _utils.isPresent)(this.nonEnterpriseTeamsWhereUserIsAdmin) || !this.appHasFewCollaborators) && this.hasNotDismissedTeamBanner;
    }),
    currentDismissals: (0, _computed.alias)('dashboardUserPreferences.developerExperienceBannerDismissals'),
    dismissalChanges: (0, _computed.setDiff)('currentDismissals', 'originalDismissals'),
    hasNotDismissedABannerDuringCurrentSession: (0, _computed.empty)('dismissalChanges'),
    isAppOwner: (0, _object.computed)('app.ownerId', 'current.accountId', function () {
      return this.get('current.accountId') === this.get('app.ownerId');
    }),
    meetsBasicContainerRequirements: (0, _computed.and)('hasBeenOnPlatformForAtLeastThreeMonths', 'isAppOwner', 'hasNotDismissedABannerDuringCurrentSession'),
    hasNotDismissedPipelineBanner: (0, _bannerNotDismissed.default)('developerExperienceBannerDismissals', 'pipelineBanner'),
    appNotInPipeline: (0, _computed.equal)('app.isInPipeline', false),
    showPipelineBanner: (0, _computed.and)('meetsBasicContainerRequirements', 'hasNotDismissedPipelineBanner', 'appHasDeploys', 'appNotInPipeline'),
    hasNotDismissedTeamBanner: (0, _bannerNotDismissed.default)('developerExperienceBannerDismissals', 'teamBanner'),
    appHasFewCollaborators: (0, _computed.lt)('app.collaborators.length', 5),
    appNotOwnedByTeam: (0, _computed.not)('app.isOwnedByTeam'),
    isNotAdminForTeamsWithApps: (0, _computed.empty)('nonEnterpriseTeamsWhereUserIsAdmin'),
    areTeamBannerSpecificRequirementsFulfilled: (0, _computed.and)('meetsBasicContainerRequirements', 'hasNotDismissedTeamBanner', 'appHasFewCollaborators', 'appNotOwnedByTeam', 'isNotAdminForTeamsWithApps'),
    appInPipeline: (0, _computed.not)('appNotInPipeline'),
    hasDismissedPipelineBanner: (0, _computed.not)('hasNotDismissedPipelineBanner'),
    showTeamBannerBecausePipeline: (0, _computed.and)('areTeamBannerSpecificRequirementsFulfilled', 'appInPipeline', 'hasNotDismissedPipelineBanner'),
    showTeamBannerBecauseDismissals: (0, _computed.and)('areTeamBannerSpecificRequirementsFulfilled', 'hasDismissedPipelineBanner'),
    showTeamBanner: (0, _computed.or)('showTeamBannerBecausePipeline', 'showTeamBannerBecauseDismissals'),
    canAutoDismissBanners: (0, _computed.or)('appInPipeline', 'hasDismissedPipelineBanner')
  });

  _exports.default = _default;
});