define("dashboard/components/datastore/postgres/fork-database-slide-panel", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-concurrency"], function (_exports, _component, _object, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * TODO: update to glimmer component
   */
  var _default = _component.default.extend({
    forkSuccessfull: false,
    standardPlans: (0, _computed.filter)('plans', function (plan) {
      return plan.name.includes('standard');
    }),
    premiumPlans: (0, _computed.filter)('plans', function (plan) {
      return plan.name.includes('premium');
    }),
    publicPlans: (0, _object.computed)('standardPlans', 'premiumPlans', function () {
      return [{
        label: 'Standard Tier',
        values: this.standardPlans
      }, {
        label: 'Premium Tier',
        values: this.premiumPlans
      }];
    }),
    privatePlans: (0, _object.computed)('plans', function () {
      return [{
        label: 'Private Tier',
        values: this.plans.filter(plan => plan.name.includes('private'))
      }];
    }),
    shieldPlans: (0, _object.computed)('plans', function () {
      return [{
        label: 'Shield Tier',
        values: this.plans.filter(plan => plan.name.includes('shield'))
      }];
    }),
    eligablePlans: (0, _object.computed)('plans', 'datastore', function () {
      const currentPlan = this.datastore.plan.toLowerCase();

      if (currentPlan.includes('private')) {
        return this.privatePlans;
      } else if (currentPlan.includes('shield')) {
        return this.shieldPlans;
      } else {
        return this.publicPlans;
      }
    }),
    selectedPlan: (0, _object.computed)('eligablePlans', function () {
      return this.eligablePlans[0].values[0].name;
    }),
    forkDatabase: (0, _emberConcurrency.task)(function* () {
      yield this.datastore.fork(this.selectedPlan);
      this.set('forkSuccessfull', true);
    }),
    actions: {
      selectPlan(event) {
        this.set('selectedPlan', event.target.value);
      }

    }
  });

  _exports.default = _default;
});