define("dashboard/components/pipeline/promote-modal", ["exports", "@ember/object/computed", "@ember/component", "@ember/utils", "@ember/object", "@ember/service"], function (_exports, _computed, _component, _utils, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    equal,
    gt,
    readOnly,
    filterBy
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['promotion-app-picker'],
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    sourceStage: readOnly('promotionSource.pipelineCoupling.stage'),
    targetStage: readOnly('allowedPromotionTargets.firstObject.pipelineCoupling.stage'),
    hasManyPromotionTargets: gt('allowedPromotionTargets.length', 1),
    noMorePromotionTargets: equal('remainingPromotionTargets.length', 0),
    pipelinePromotion: (0, _object.computed)(function () {
      const promotion = this.store.createRecord('pipeline-promotion', {
        pipeline: this.pipeline,
        source: this.sourceApp
      });
      return promotion;
    }).readOnly(),
    newPromotionTargets: filterBy('pipelinePromotion.promotionTargets', 'isNew'),
    remainingPromotionTargets: (0, _object.computed)('allowedPromotionTargets.[]', 'pipelinePromotion.targets.[]', function () {
      const promotionTargets = this.get('pipelinePromotion.targets');
      return this.allowedPromotionTargets.filter(app => {
        return !promotionTargets.includes(app.get('content'));
      });
    }),

    init() {
      this._super(...arguments);

      this._setDefaultPromotionTargets();
    },

    willDestroyElement() {
      this.newPromotionTargets.invoke('unloadRecord');

      this._super(...arguments);
    },

    // Set all apps as promotion targets by default
    _setDefaultPromotionTargets() {
      const apps = this.allowedPromotionTargets;
      apps.forEach(app => this.addPromotionTarget(app.get('content')));
    },

    addPromotionTarget(app) {
      const pipelinePromotion = this.pipelinePromotion;
      const promotionTarget = this.store.createRecord('pipeline-promotion-target', {
        pipelinePromotion,
        app
      });
      pipelinePromotion.addTarget(promotionTarget);
    },

    multiFactorIsFulfilled: (0, _computed.not)('multiFactorIsUnfulfilled'),
    multiFactorIsUnfulfilled: (0, _object.computed)('multiFactorIsRequired', 'multiFactorCode', function () {
      return this.multiFactorIsRequired && (0, _utils.isEmpty)(this.multiFactorCode);
    }),
    actions: {
      selectPromotionTarget(app) {
        if ((0, _utils.isPresent)(app) && (0, _utils.isPresent)(app.get('content'))) {
          this.analytics.logEvent('Pipeline Promotion Target', 'Added');
          this.addPromotionTarget(app.get('content'));
        }
      },

      removePromotionTarget(coupling) {
        this.analytics.logEvent('Pipeline Promotion Target', 'Removed');
        const promotionTarget = coupling.get('app.promotionTarget.content');
        this.pipelinePromotion.removeTarget(promotionTarget);
      },

      async promote() {
        const promotion = this.pipelinePromotion;

        try {
          await promotion.save();
          this.newPromotionTargets.invoke('destroyRecord');
          this.sourceApp.set('currentPromotion', promotion);
          this.onClose();
          this.onPromotion(promotion);
          this.analytics.logEvent('Pipeline', 'Promotion', {
            status: 'succeeded'
          });
        } catch (reason) {
          this.analytics.logEvent('Pipeline', 'Promotion', {
            status: 'failed'
          });
          throw reason;
        }
      }

    }
  });

  _exports.default = _default;
});