define("dashboard/serializers/repositories-api-github-repo", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload.items, id, requestType);
    },

    // delete response for API is {}, causing issues for ember data
    normalizeDeleteRecordResponse() {
      return this._super(null);
    },

    serialize(snapshot) {
      return {
        repo_url: snapshot.record.htmlUrl
      };
    }

  });

  _exports.default = _default;
});