define("dashboard/serializers/unverify-payment-method-request", ["exports", "@ember-data/serializer/rest", "dashboard/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      invoices: {
        embedded: 'always'
      }
    },

    extractRelationships(modelClass, resourceHash) {
      if (resourceHash.invoices) {
        resourceHash.invoices.forEach(invoice => {
          invoice.type = 'invoice';
        });
      }

      return this._super(modelClass, resourceHash);
    }

  });

  _exports.default = _default;
});