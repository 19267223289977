define("dashboard/templates/enterprise-account/overview/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Bqie6Cs/",
    "block": "[[[1,[28,[35,0],null,[[\"class\",\"enterpriseAccount\",\"enterpriseAccountMember\",\"enterpriseAccountPreferences\"],[\"mb5\",[30,0,[\"model\",\"enterpriseAccount\"]],[30,0,[\"enterpriseAccountMember\"]],[30,0,[\"model\",\"preferences\"]]]]]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"pb6 subnav-toolbar\"],[12],[1,\"\\n\"],[6,[39,1],null,[[\"teams\",\"page\",\"filter\"],[[30,0,[\"activelySelectedTeamList\"]],[30,0,[\"page\"]],[30,0,[\"filter\"]]]],[[\"default\"],[[[[41,[30,0,[\"canManageTeams\"]],[[[1,\"      \"],[10,0],[14,0,\"hk-button-group\"],[12],[1,\"\\n        \"],[11,\"button\"],[16,0,[29,[\"hk-button--secondary \",[52,[51,[30,0,[\"showAll\"]]],\"bg-lightest-purple\"]]]],[4,[38,4],[[30,0],\"toggleShowAll\",false],null],[12],[1,\"\\n          My teams\\n        \"],[13],[1,\"\\n        \"],[11,\"button\"],[16,0,[29,[\"hk-button--secondary \",[52,[30,0,[\"showAll\"]],\"bg-lightest-purple\"]]]],[4,[38,4],[[30,0],\"toggleShowAll\",true],null],[12],[1,\"\\n          All teams\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[13],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"enterprise-account/welcome-banner\",\"team-list\",\"if\",\"unless\",\"action\",\"component\",\"-outlet\"]]",
    "moduleName": "dashboard/templates/enterprise-account/overview/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});