define("dashboard/routes/app/webhooks/edit", ["exports", "dashboard/routes/basic-app", "rsvp", "@ember/service"], function (_exports, _basicApp, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend({
    analytics: (0, _service.inject)(),

    model(params) {
      const {
        app
      } = this.modelFor('app');
      return (0, _rsvp.hash)({
        webhook: this.store.queryRecord('app.webhook', {
          appName: app.get('name'),
          webHookName: params.webhook_name
        }),
        includeTypes: app.hasMany('webhookIncludes').reload()
      });
    },

    afterModel() {
      this.analytics.logEvent('Webhook Edit Page', 'Viewed');
    }

  });

  _exports.default = _default;
});