define("dashboard/adapters/shogun/postgres-backup", ["exports", "dashboard/adapters/shogun", "dashboard/config/environment", "@ember/service"], function (_exports, _shogun, _environment, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shogun.default.extend({
    store: (0, _service.inject)(),
    yobukoHost: _environment.default.yobukoUrl,

    pgHost(addonId) {
      const addon = this.store.peekRecord('addon', addonId);
      return addon.isHobby ? this.yobukoHost : this.host;
    },

    urlForCreateRecord(modelName, snapshot) {
      const addonId = snapshot.record.get('datastore.addon.id');
      return `${this.pgHost(addonId)}/client/v11/databases/${addonId}/backups`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.record.get('datastore.addon.app.id');
      const addonId = snapshot.record.get('datastore.addon.id');
      const backupId = snapshot.record.get('id');
      return `${this.pgHost(addonId)}/client/v11/apps/${appId}/transfers/${backupId}`;
    },

    generateDownloadUrl(appId, addonId, backupId) {
      return this.ajax(`${this.pgHost(addonId)}/client/v11/apps/${appId}/transfers/${backupId}/actions/public-url`, 'POST');
    }

  });

  _exports.default = _default;
});