define("dashboard/templates/components/team/member-panel/actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jd/2IID7",
    "block": "[[[41,[30,0,[\"member\"]],[[[1,\"  \"],[10,0],[14,0,\"bt b--silver mt4 pt4\"],[12],[1,\"\\n\"],[41,[30,0,[\"team\",\"hasEnterpriseAccount\"]],[[[1,\"      \"],[6,[39,1],null,[[\"tagName\",\"class\",\"query\",\"route\",\"model\"],[\"button\",\"hk-button--secondary w-100 mb3\",[28,[37,2],null,[[\"filter\"],[[30,0,[\"member\",\"email\"]]]]],\"enterprise-account.overview.access\",[30,0,[\"team\",\"enterpriseAccount\",\"name\"]]]],[[\"default\"],[[[[1,\"View account level permissions\"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"onClick\",\"buttonType\",\"class\",\"default\"],[[28,[37,4],[[30,0],[30,0,[\"onDelete\"]]],null],\"danger\",\"w-100\",\"Remove user from this team…\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"-hash\",\"hk-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/team/member-panel/actions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});