define("dashboard/adapters/github-app-link", ["exports", "dashboard/adapters/application", "dashboard/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.kolkrabbiUrl,

    handleResponse(status, headers, payload, requestData) {
      // A 404 on this endpoint just means the app isn't connected to GitHub.
      // This override prevents ember-data from raising an error in the console
      // and failing the test suite.
      if (status === 404) {
        status = 204;
        payload = null;
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForQueryRecord(query) {
      if (query.app) {
        const app = query.app;
        delete query.app;
        return `${_environment.default.kolkrabbiUrl}/apps/${app}/github`;
      } else {
        return this._super(...arguments);
      }
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${this.host}/apps/${appId}/github`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${this.host}/apps/${appId}/github`;
    },

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${this.host}/apps/${appId}/github`;
    }

  });

  _exports.default = _default;
});