define("dashboard/mixins/auth-details", ["exports", "@ember/object/mixin", "@ember/object/computed"], function (_exports, _mixin, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Common helpers relating to authentication.
   *
   * @class AuthDetails
   * @extends Ember.Mixin
   */
  var _default = _mixin.default.create({
    // Does this member/account login via Heroku Identity?
    unfederated: (0, _computed.not)('federated')
  });

  _exports.default = _default;
});