define("dashboard/templates/components/pipeline/app-build-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rDT3Oilx",
    "block": "[[[41,[30,0,[\"currentBuild\",\"isPending\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"size\",\"class\"],[\"small\",\"ml--4 mr1 nudge-up--1\"]]]],[1,\"\\n  \"],[10,1],[14,0,\"flex-auto f6 gray ml1 pl--1\"],[12],[1,\"\\n    Building review app •\\n    \"],[11,3],[24,6,\"#\"],[24,0,\"hk-link no-underline underline-hover\"],[4,[38,2],[[30,0],\"showBuildModal\"],null],[12],[1,\"\\n      View log\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isShowingFailedStatus\"]],[[[1,\"  \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"failure-ring-16\",\"flex-shrink-0 malibu-fill-gradient-red mr1\",16]],null],[1,\"\\n  \"],[10,1],[14,0,\"flex-auto f5 red\"],[12],[1,\"\\n    Latest build failed •\\n    \"],[11,3],[24,6,\"#\"],[24,0,\"hk-link red\"],[4,[38,2],[[30,0],\"showBuildModal\"],null],[12],[1,\"\\n      View log\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isShowingBuildModal\"]],[[[1,\"  \"],[1,[28,[35,4],null,[[\"app\",\"currentBuild\",\"hideBuildModal\"],[[30,0,[\"app\"]],[30,0,[\"currentBuild\"]],[28,[37,2],[[30,0],\"hideBuildModal\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-loading-spinner\",\"action\",\"malibu-icon\",\"pipeline/build-modal\"]]",
    "moduleName": "dashboard/templates/components/pipeline/app-build-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});