define("dashboard/routes/pipelines/pipeline/index/review-app", ["exports", "rsvp", "@ember/routing/route", "dashboard/models/kolkrabbi/app-setup", "@ember/application"], function (_exports, _rsvp, _route, _appSetup, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model(params) {
      const {
        pipeline
      } = this.modelFor('pipelines.pipeline.index');
      const app = pipeline.get('reviewAppParent');

      const appSetup = _appSetup.default.create((0, _application.getOwner)(this).ownerInjection(), {
        prAppId: params['review_app_id']
      }).fetch();

      return _rsvp.default.hash({
        app,
        appSetup,
        build: this.fetchBuild(appSetup)
      });
    },

    fetchBuild(appSetup) {
      return appSetup.then(aS => {
        if (!aS.app || !aS.build) {
          return {};
        }

        const appId = aS.app.id;
        const buildId = aS.build.id;
        return this.get('store').queryRecord('build', {
          app: appId,
          buildId
        });
      });
    },

    afterModel(model) {
      if (model.appSetup) {
        model.appSetup.fetchStatus(5000);
      }
    },

    deactivate() {
      const appSetup = this.get('controller.appSetup');

      if (appSetup && appSetup.cancelPolling) {
        appSetup.cancelPolling();
      }
    }

  });

  _exports.default = _default;
});