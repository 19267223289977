define("dashboard/models/organization-plan", ["exports", "@ember/object", "@ember/object/computed", "moment"], function (_exports, _object, _computed, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    licenses: [],
    startDates: (0, _computed.mapBy)('licenses', 'startDate'),
    startDate: (0, _computed.readOnly)('startDates.firstObject'),
    endDates: (0, _computed.mapBy)('licenses', 'endDate'),
    endDate: (0, _computed.readOnly)('endDates.firstObject'),
    formattedStartDate: (0, _object.computed)('startDate', function () {
      return this.formatDate(this.startDate);
    }),
    formattedEndDate: (0, _object.computed)('endDate', function () {
      return this.formatDate(this.endDate);
    }),

    formatDate(date) {
      return _moment.default.utc(date, 'YYYY-MM-DD').format('MMMM Do, YYYY');
    }

  });

  _exports.default = _default;
});