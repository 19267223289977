define("dashboard/components/purple-list-row", ["exports", "@ember/component", "@ember/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ANIMATION_DELAY = 2000;

  var _default = _component.default.extend({
    tagName: 'tr',
    classNameBindings: ['item.isMostRecent:new-list-item'],

    didRender() {
      // If this is the most recent item, set a debounce to remove that property
      // so that we only show this item's animation once
      if (this.get('item.isMostRecent')) {
        _runloop.run.debounce(this, function () {
          if (!this.isDestroyed) {
            this.set('item.isMostRecent', false);
          }
        }, ANIMATION_DELAY);
      }
    },

    actions: {
      edit(item) {
        this.editAction(item);
      },

      confirmDelete(item) {
        this.confirmDeleteAction(item);
      }

    }
  });

  _exports.default = _default;
});