define("dashboard/serializers/status/resolved-incident", ["exports", "dashboard/serializers/application", "dashboard/mixins/serializers/incident"], function (_exports, _application, _incident) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_incident.default, {
    normalize(typeClass, hash) {
      hash = this.transformIncidentHash(hash);
      return this._super(typeClass, hash);
    }

  });

  _exports.default = _default;
});