define("dashboard/adapters/github/user", ["exports", "dashboard/adapters/application", "@ember/service"], function (_exports, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    reporter: (0, _service.inject)(),

    async findRecord(_store, _modelClass, id) {
      let payload;

      try {
        payload = await this._fetchFromPublicGithub(id);
      } catch (e) {
        // Fetching GitHub profile data occasionally fails (https://rollbar.com/Heroku-3/dashboard-h.c/items/44817/)
        // with a 403, so we'll just return nothing in that case
        payload = {
          id,
          data: null
        };
      }

      return payload;
    },

    _legacyAjax(options) {
      const defaults = {
        dataType: 'text',

        beforeSend(xhr) {
          xhr.suppressErrors = true;
          xhr.global = false;
        }

      };
      const overrides = Object.assign({}, defaults, options);
      return new Promise((resolve, reject) => {
        $.ajax(overrides).then(resolve).fail((jqXHR, status, text) => {
          const rejection = this.handleResponse(jqXHR.status, jqXHR.getAllResponseHeaders(), this.parseErrorResponse(jqXHR.responseText) || text, {
            url: overrides.url,
            method: options.type || 'GET'
          });
          reject(rejection);
        });
      });
    },

    _fetchFromPublicGithub(id) {
      const publicUrl = `https://api.github.com/user/${id}`;
      return this._legacyAjax({
        url: publicUrl,
        dataType: 'json',

        beforeSend(xhr) {
          xhr.suppressErrors = true;
          xhr.global = false;
        },

        headers: {
          accept: 'application/vnd.github.v3.raw'
        }
      });
    }

  });

  _exports.default = _default;
});