define("dashboard/components/assume-identity", ["exports", "@ember/runloop", "@ember/component", "jquery"], function (_exports, _runloop, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    didInsertElement() {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        return this.setFocus();
      });
    },

    // Set focus on the code field if email already exists
    setFocus() {
      if (this.email && this.reason) {
        (0, _jquery.default)(this.element).find('#code').focus();
      } else if (this.email) {
        (0, _jquery.default)(this.element).find('#reason').focus();
      } else {
        (0, _jquery.default)(this.element).find('#email').focus();
      }
    }

  });

  _exports.default = _default;
});