define("dashboard/components/third-party-authorizations", ["exports", "@ember/component", "rsvp", "@ember/service", "@ember/object/computed", "@ember/object"], function (_exports, _component, _rsvp, _service, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    hasAuthorizations: (0, _computed.or)('hasGithubOauthUser', 'hasGithubAppUsers'),
    hasGithubOauthUser: (0, _computed.notEmpty)('githubUser'),
    hasGithubAppUsers: (0, _computed.notEmpty)('authorizedUsers'),
    current: (0, _service.inject)(),
    githubToken: (0, _service.inject)(),
    actions: {
      reloadGitHubSession() {
        return _rsvp.default.all([this.githubToken.fetch(), this.get('current.account').belongsTo('githubUser').reload()]);
      },

      remove(authorization, modalVisibilityKey) {
        if (authorization.destroyRecord) {
          // ember-data
          const pendingDelete = authorization.destroyRecord();
          return pendingDelete.then(() => {
            (0, _object.set)(this, modalVisibilityKey, false);
          });
        } else {
          // rest-model (gross)
          const pendingDelete = authorization.delete();
          return pendingDelete.then(() => {
            authorization.set('created_at', null);
            (0, _object.set)(this, modalVisibilityKey, false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});