define("dashboard/controllers/buildpack-registry/connect-github", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALID_URL_REGEXES = ['https:\\/\\/[^\\/]+\\.heroku\\.com', 'http:\\/\\/localhost:\\d+', 'https:\\/\\/partner-portal-ui-staging\\.herokuapp\\.com'];

  var _default = _controller.default.extend({
    location: (0, _service.inject)(),
    queryParams: ['sendTo'],
    sendTo: null,
    actions: {
      onGithubAuth() {
        const sendTo = this.sendTo;

        if (this.validUrlRegex().test(sendTo)) {
          this.location.replace(sendTo);
        } else {
          this.replaceRoute('account.applications');
        }
      }

    },

    validUrlRegex() {
      const validUrls = VALID_URL_REGEXES.join('|');
      return new RegExp(`^(${validUrls})(\\/|\\?).*`);
    }

  });

  _exports.default = _default;
});