define("dashboard/components/account/applications/manage-third-party-connection", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _service, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    current: (0, _service.inject)(),
    currentUserEmail: (0, _computed.readOnly)('current.account.email'),
    onlineTeams: (0, _computed.filter)('teams', function (team) {
      return team.isEnterpriseOrg === false && (0, _utils.isEmpty)(team.enterpriseAccount);
    }),
    onlineTeamsWithAdminAccess: (0, _computed.filterBy)('onlineTeams', 'isAdmin', true),
    enterpriseAccountsWithAdminAccess: (0, _computed.filter)('enterpriseAccounts.@each.currentPermissions', function (enterpriseAccount) {
      return enterpriseAccount.currentPermissions.canManageGitProviders;
    })
  });

  _exports.default = _default;
});