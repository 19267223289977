define("dashboard/components/account/two-factor/recommended-app", ["exports", "@ember/component", "@ember/object", "purl"], function (_exports, _component, _object, _purl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['purple-box'],
    helpLinkText: (0, _object.computed)('helpURL', function () {
      return (0, _purl.default)(this.helpURL).attr('host');
    })
  });

  _exports.default = _default;
});