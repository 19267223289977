define("dashboard/components/account/profile-name", ["exports", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'profile-name',
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    name: (0, _computed.alias)('current.account.name'),
    actions: {
      save(newName) {
        const account = this.get('current.account');
        account.set('name', newName);
        return account.save().then(() => {
          this.analytics.logEvent('Account Name', 'Changed');
        });
      }

    }
  });

  _exports.default = _default;
});