define("dashboard/components/configure-sms-recovery", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "dashboard/mixins/multi-step-flow"], function (_exports, _component, _computed, _service, _multiStepFlow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  Step 1: Enter phone number
   *  Step 2: Confirm code
   */
  var _default = _component.default.extend(_multiStepFlow.default, {
    current: (0, _service.inject)(),
    password: null,
    smsNumber: null,
    hasPassword: (0, _computed.notEmpty)('password'),
    needsPassword: (0, _computed.not)('hasPassword'),
    actions: {
      setPassword(password) {
        this.set('password', password);
      },

      transitionToStepTwo(smsNumber) {
        this.set('smsNumber', smsNumber);
        this.transitionToStep(2);
      },

      close() {
        this.attrs.onCloseModal();
      }

    }
  });

  _exports.default = _default;
});