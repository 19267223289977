define("dashboard/adapters/dyno", ["exports", "@ember/service", "dashboard/adapters/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    dsErrorMonitor: (0, _service.inject)(),

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/dynos`;
    },

    handleResponse(status, headers, payload, requestData) {
      if (this._ifCannotRunAboveLimit(status, payload)) {
        // We actually do want this 422 to be flashed when creating a dyno for the
        // app console because this error is due to the app being on the free
        // tier with another dyno already running. Letting this fall though to
        // super would lead to it being suppressed in the platform-api mixin.
        return new Error('Dyno limit reached.');
      }

      return this._super(status, headers, payload, requestData);
    },

    _ifCannotRunAboveLimit(status, payload) {
      return status === 422 && payload.id === 'cannot_run_above_limit';
    }

  });

  _exports.default = _default;
});