define("dashboard/templates/components/app/overview-formation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ffueGIm1",
    "block": "[[[10,0],[14,0,\"flex-ns items-center pv2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex dark-gray mr2\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mr1\"],[12],[1,\"Dyno formation\"],[13],[1,\"\\n\"],[41,[30,0,[\"team\",\"isEnterpriseOrg\"]],[[[6,[39,1],null,[[\"class\",\"title\"],[\"hk-badge\",\"Estimated monthly dyno units\"]],[[\"default\"],[[[[1,\"        \"],[1,[30,0,[\"totalDynoUnits\"]]],[1,\" \"],[1,[28,[35,2],[[30,0,[\"totalDynoUnits\"]],\"unit\"],null]],[1,\"/month\\n\"]],[]]]]]],[]],[[[6,[39,1],null,[[\"class\",\"title\"],[\"hk-badge\",\"Estimated monthly cost\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,3],[[30,0,[\"formationsTotalPrice\"]]],null]],[1,\"/month\\n\"]],[]]]]]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n\\n\"],[6,[39,4],null,[[\"classNames\",\"route\"],[\"flex items-center hk-link no-underline f6 mv1 mv0-ns\",\"app.resources\"]],[[\"default\"],[[[[1,\"    \"],[10,1],[14,0,\"underline\"],[12],[1,\"Configure Dynos\"],[13],[1,\"\\n    \"],[8,[39,5],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"nav-go-16\",\"fill-blue ml--3 nudge-down--1\",16]],null],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"formations\"]],[[[1,\"  \"],[10,0],[14,0,\"purple-box\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasKnownTier\"]],[[[1,\"      \"],[10,0],[14,0,\"formation-tier\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"This app is using \"],[10,\"b\"],[12],[1,[30,0,[\"currentTier\",\"displayName\"]]],[13],[1,\" dynos\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[28,[35,6],null,[[\"formations\"],[[30,0,[\"sortedFormations\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,7]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"x-tooltip\",\"pluralize-word\",\"currency-format\",\"link-to\",\"malibu-icon\",\"display-formation-list\",\"app/formations-empty-state-no-processes\"]]",
    "moduleName": "dashboard/templates/components/app/overview-formation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});