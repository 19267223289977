define("dashboard/models/dyno", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    attach: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    attachUrl: (0, _model.attr)('string'),
    command: (0, _model.attr)('string'),
    env: (0, _model.attr)({
      defaultValue: () => {
        return {
          TERM: 'xterm-256color'
        };
      }
    })
  });

  _exports.default = _default;
});