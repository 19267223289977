define("dashboard/templates/components/metrics/charts/chart-header/alert-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sRyFTGvU",
    "block": "[[[10,0],[14,0,\"flex flex-wrap items-center\"],[12],[1,\"\\n\"],[41,[30,0,[\"activeAlertPresent\"]],[[[1,\"    \"],[10,0],[14,0,\"metrics__alert-summary__active-alert\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"alert-badge-16\",\"malibu-fill-gradient-red nudge-up--2 nudge-left--2 anim-pulse\",14]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"svg\"],[14,\"height\",\"10\"],[14,\"width\",\"10\"],[15,0,[29,[\"status-icon__alert \",[30,0,[\"statusIconClass\"]],\" nudge-up--1 mr1\"]]],[12],[1,\"\\n      \"],[10,\"circle\"],[14,\"cx\",\"5\"],[14,\"cy\",\"5\"],[14,\"r\",\"5\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[6,[39,2],null,[[\"title\"],[[30,0,[\"description\"]]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"dark-gray mr2\"],[12],[1,[30,0,[\"monitoringText\"]]],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"],[11,3],[24,0,\"hk-link no-underline flex items-center\"],[4,[38,3],[[30,0],\"openAlertingConfig\"],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"underline\"],[12],[1,[30,0,[\"alertAction\"]]],[1,\" Alert\"],[13],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"nav-go-16\",\"ml--3 fill-blue\",16]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"malibu-icon\",\"x-tooltip\",\"action\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/chart-header/alert-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});