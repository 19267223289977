define("dashboard/components/app-state-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    equal
  } = _object.computed;
  const STATE_TITLES = {
    unknown: 'Fetching app state',
    awake: 'App is awake',
    sleeping: 'App is asleep',
    empty: 'No web dynos present',
    default: 'No web dynos present'
  };
  const STATE_NAMES = {
    unknown: 'app-28',
    awake: 'app-28',
    sleeping: 'app-sleeping-28',
    empty: 'app-28',
    default: 'app-28'
  };
  const CLASS_NAMES = {
    unknown: 'v-mid malibu-fill-gradient-gray',
    awake: 'v-mid malibu-fill-gradient-purple',
    sleeping: 'v-mid malibu-fill-gradient-purple o-60',
    empty: 'v-mid malibu-fill-gradient-purple o-60',
    default: 'v-mid malibu-fill-gradient-purple o-60'
  };

  var _default = _component.default.extend({
    'data-test-target': 'app-state-icon',
    tagName: 'div',
    classNames: ['relative'],
    isSmall: equal('size', 'small'),
    isAlerting: readOnly('app.appState.alerting'),
    iconSize: (0, _object.computed)('isSmall', function () {
      if (this.isSmall) {
        return 16;
      } else {
        return 28;
      }
    }),
    alertIconSize: (0, _object.computed)('isSmall', function () {
      if (this.isSmall) {
        return 10;
      } else {
        return 14;
      }
    }),
    appState: (0, _object.computed)('app.state', function () {
      return this.get('app.state') || 'default';
    }),
    iconClass: (0, _object.computed)('appState', function () {
      const className = CLASS_NAMES[this.appState];
      return className;
    }),
    iconName: (0, _object.computed)('appState', function () {
      const className = STATE_NAMES[this.appState];
      return className;
    }),
    iconTitle: (0, _object.computed)('appState', 'isAlerting', function () {
      const title = STATE_TITLES[this.appState];

      if (this.isAlerting) {
        return `${title} and has alerts`;
      } else {
        return title;
      }
    })
  });

  _exports.default = _default;
});