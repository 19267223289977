define("dashboard/templates/components/metrics/app-guidance/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "d5STqe37",
    "block": "[[[10,1],[14,0,\"dark-gray\"],[12],[1,[30,0,[\"title\"]]],[1,\":\"],[13],[1,\" \"],[10,1],[14,0,\"gray\"],[12],[2,[30,0,[\"description\"]]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"devCenterLinks\"]],[[[1,\"  \"],[10,\"ul\"],[14,0,\"metrics__app-guidance__help-list\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"devCenterLinks\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"metrics__app-guidance__help-list__item dib mr2\"],[12],[1,\"\\n        \"],[8,[39,3],[[24,0,\"gray hover-dark-gray\"],[24,\"title\",\"Learn more at the Heroku Dev Center\"],[16,6,[30,1,[\"href\"]]],[4,[38,4],[[30,0],\"clickDevCenterLink\",[30,1,[\"href\"]]],null]],[[\"@withPrelude\"],[true]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"text\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"link\"],false,[\"if\",\"each\",\"-track-array\",\"dev-center-link\",\"action\"]]",
    "moduleName": "dashboard/templates/components/metrics/app-guidance/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});