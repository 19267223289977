define("dashboard/templates/components/pipeline/auto-deploy-modal/title-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NRqjUUr1",
    "block": "[[[1,\"Configure automatic deployment for \\n\"],[8,[39,0],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"App\",\"app-16\",\"malibu-fill-gradient-purple\",16]],null],[1,\"\\n\"],[1,[30,0,[\"name\"]]]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/auto-deploy-modal/title-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});