define("dashboard/templates/components/account/billing/declined-invoice-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "etB3pB0D",
    "block": "[[[10,\"td\"],[12],[1,[28,[35,0],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"invoice\",\"isSelected\"]]]]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"invoice\"],[[30,0,[\"invoice\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"text-right\"],[12],[1,[28,[35,2],[[30,0,[\"invoice\",\"total\"]]],[[\"unit\"],[\"cents\"]]]],[13],[1,\"\\n\"],[10,\"td\"],[15,0,[29,[\"text-right invoice-state \",[30,0,[\"invoice\",\"stateClass\"]]]]],[12],[1,\"\\n  \"],[1,[30,0,[\"statusText\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"input\",\"invoice-link\",\"currency-format\"]]",
    "moduleName": "dashboard/templates/components/account/billing/declined-invoice-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});