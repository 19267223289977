define("dashboard/templates/components/metrics/charts/line-chart-elements/area-graph", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZhNk7PC2",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[41,[51,[30,0,[\"hideLine\"]]],[[[1,\"    \"],[10,\"path\"],[15,0,[29,[\"metrics__line \",[30,0,[\"lineClass\"]]]]],[15,\"d\",[29,[[30,0,[\"dLine\"]]]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"path\"],[15,0,[29,[\"metrics__area \",[30,0,[\"areaClass\"]]]]],[15,\"d\",[29,[[30,0,[\"dArea\"]]]]],[12],[13],[1,\"\\n\\n  \"],[1,[28,[35,2],null,[[\"selectedTimeChartX\",\"graph\"],[[30,0,[\"selectedTimeChartX\"]],[30,0]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"unless\",\"metrics/charts/line-chart-elements/selected-time-graph-point\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/line-chart-elements/area-graph.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});