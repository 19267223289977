define("dashboard/components/confirm-disable-review-apps-modal", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    reviewAppCount: (0, _computed.reads)('reviewApps.length')
  });

  _exports.default = _default;
});