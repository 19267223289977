define("dashboard/templates/components/sub-nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4u3HifA4",
    "block": "[[[10,0],[14,0,\"limit-width\"],[14,\"role\",\"list\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "dashboard/templates/components/sub-nav.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});