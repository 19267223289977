define("dashboard/utils/metrics/datastore-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    kafka: 'metrics/datastores/kafka',
    redis: 'metrics/datastores/redis',
    postgres: 'metrics/datastores/postgres'
  };
  _exports.default = _default;
});