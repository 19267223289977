define("dashboard/models/app-transfer", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    app: (0, _model.attr)(),
    createdAt: (0, _model.attr)('date'),
    owner: (0, _model.attr)(),
    recipient: (0, _model.attr)(),
    state: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    isSelected: false,
    transferComplete: (0, _computed.not)('isNew'),

    accept() {
      this.set('state', 'accepted');
      return this.save();
    },

    decline() {
      this.set('state', 'declined');
      return this.save();
    }

  });

  _exports.default = _default;
});