define("dashboard/adapters/shogun/kafka-topic", ["exports", "dashboard/adapters/shogun/kafka-datastore"], function (_exports, _kafkaDatastore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _kafkaDatastore.default.extend();

  _exports.default = _default;
});