define("dashboard/components/enterprise-account/member/fields", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    current: (0, _service.inject)(),
    tagName: '',
    isDisabled: false,
    hasManagePermission: (0, _computed.bool)('current.enterprisePermissions.canManageSettings'),
    hasTwoFactorAuthentication: (0, _computed.bool)('member.twoFactorAuthentication'),
    onDelete: () => {},
    sendTokenRequest: () => {},
    hasMfaLegacyExperience: (0, _object.computed)(function () {
      return this.current.account.mfaExperience === 'legacy';
    }),
    canSendMfaRecovery: (0, _object.computed)('hasManagePermission', 'hasTwoFactorAuthentication', 'hasMfaLegacyExperience', function () {
      return this.hasManagePermission && this.hasTwoFactorAuthentication && !this.hasMfaLegacyExperience;
    }),
    hasProvidedValidEmail: (0, _object.computed)('member', 'validations.{isDirty,isValidating,isValid}', function () {
      if (this.member) {
        return true;
      }

      return this.get('validations.isDirty') && !this.get('validations.isValidating') && this.get('validations.isValid');
    }),
    disabledClass: (0, _object.computed)('hasProvidedValidEmail', function () {
      return this.hasProvidedValidEmail ? '' : 'bg-light-silver';
    }),
    actions: {
      sendMfaTokenRequest() {
        this.sendTokenRequest();
        this.set('isDisabled', true);
      }

    }
  });

  _exports.default = _default;
});