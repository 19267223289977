define("dashboard/components/context-switcher-toggle", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex nowrap items-center no-select cursor-hand'],
    contextText: (0, _object.computed)('team.name', 'enterpriseAccount.name', function () {
      return this.get('enterpriseAccount.name') || this.get('team.name') || 'Personal';
    }),
    actions: {
      toggleDropdown(event) {
        event.stopPropagation();
        this.toggleDropdown();
      }

    }
  });

  _exports.default = _default;
});