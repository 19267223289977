define("dashboard/controllers/pipelines/pipeline/index", ["exports", "@ember/controller", "@ember/service", "dashboard/mixins/preferences", "@ember/object", "dashboard/helpers/array-group-by", "dashboard/config/environment"], function (_exports, _controller, _service, _preferences, _object, _arrayGroupBy, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    filterBy
  } = _object.computed;

  var _default = _controller.default.extend(_preferences.default, {
    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),
    githubToken: (0, _service.inject)(),
    githubIntegrationDisabled: _environment.default.githubIntegrationDisabled,
    githubIntegrationReenabled: _environment.default.githubIntegrationReenabled,
    apps: alias('model.pipeline.apps'),
    appsOnDeprecatedStacks: filterBy('apps', 'showStackDeprecationWarning'),
    appsOnDeprecatedStacksGroupedByStack: (0, _object.computed)('appsOnDeprecatedStacks.@each.stack.name', function () {
      // The groups are sorted by stack name, so that older stacks are listed first.
      return (0, _arrayGroupBy.default)(this.appsOnDeprecatedStacks, 'stack.name').sortBy('value');
    }),
    pipelineDurableApps: (0, _object.computed)('model.pipeline.durableApps.[]', function () {
      return this.get('model.pipeline.isDestroyed') ? [] : this.get('model.pipeline.durableApps');
    }),
    actions: {
      didConfirmCiInterest() {
        this.analytics.logEvent('Heroku CI Ad Banner', 'Accepted');
        this.transitionToRoute('pipelines.pipeline.tests.index');
      },

      didDismissCiInterest() {
        this.analytics.logEvent('Heroku CI Ad Banner', 'Rejected');
        const pipelineId = this.model.pipeline?.id;
        const userPrefs = this.dashboardUserPreferences;
        const bannerPreferences = userPrefs.get('pipelineCIAdBannerDismissals') || [];
        bannerPreferences.pushObject(pipelineId);
        userPrefs.set('pipelineCIAdBannerDismissals', bannerPreferences);
        userPrefs.save();
      }

    }
  });

  _exports.default = _default;
});