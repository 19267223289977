define("dashboard/adapters/invoice-address", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    updateMethod: 'PUT',

    urlForQueryRecord() {
      return `${this.host}/account/invoice-address`;
    },

    urlForUpdateRecord() {
      return this.urlForQueryRecord();
    }

  });

  _exports.default = _default;
});