define("dashboard/templates/components/metrics/charts/zone-elements/zone", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "I11l9qaS",
    "block": "[[[41,[30,0,[\"isShowingIcon\"]],[[[1,\"  \"],[10,\"line\"],[14,\"x1\",\"0\"],[14,\"x2\",\"0\"],[14,\"y1\",\"0\"],[15,\"y2\",[29,[[30,0,[\"height\"]]]]],[12],[13],[1,\"\\n  \"],[10,\"g\"],[15,\"transform\",[29,[\"translate(-4, \",[30,0,[\"iconGroupOffset\"]],\")\"]]],[12],[1,\"\\n    \"],[10,\"title\"],[12],[1,\" Alert Started \"],[13],[1,\"\\n    \"],[1,[28,[35,1],[\"alert-diamond\"],[[\"class\"],[\"metrics__timeseries-chart__zone__icon\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[10,\"rect\"],[14,0,\"metrics__timeseries-chart__zone__overlay\"],[15,\"width\",[29,[[30,0,[\"width\"]]]]],[15,\"height\",[29,[[30,0,[\"height\"]]]]],[12],[13],[1,\"\\n\"]],[],false,[\"if\",\"svg-jar\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/zone-elements/zone.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});