define("dashboard/templates/components/route-outlets/apps/new-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dt76z7oZ",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,1,[\"team\"]]],null],[30,1,[\"team\",\"canCreateApp\"]]],null],[[[6,[39,3],null,[[\"buttonContent\",\"hasCaret\",\"menuClass\",\"buttonClass\",\"caretClass\"],[\"New\",true,\"dropdown-menu-right\",\"hk-button--secondary\",\"ml--2 mr1\"]],[[\"default\"],[[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,4],null,[[\"class\",\"query\",\"route\"],[\"hk-dropdown-item new-app\",[28,[37,5],null,[[\"orgNameParam\",\"spaceNameParam\"],[[30,1,[\"teamName\"]],[30,1,[\"spaceName\"]]]]],\"new-app\"]],[[\"default\"],[[[[1,\"        \"],[8,[39,6],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"app-16\",\"fill-gray\",16]],null],[1,\"\\n        Create new app\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"li\"],[14,0,\"hk-dropdown-divider\"],[12],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,4],null,[[\"class\",\"query\",\"route\"],[\"hk-dropdown-item new-pipeline\",[28,[37,5],null,[[\"orgNameParam\"],[[30,1,[\"teamName\"]]]]],\"pipelines.new\"]],[[\"default\"],[[[[1,\"        \"],[8,[39,6],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"pipeline-16\",\"fill-gray\",16]],null],[1,\"\\n        Create new pipeline\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]]],[]],[[[6,[39,7],null,[[\"title\"],[\"You don't have access to create a new app\"]],[[\"default\"],[[[[1,\"    \"],[10,\"button\"],[14,0,\"new-app hk-button--disabled-primary\"],[12],[1,\"Create new app\"],[13],[1,\"\\n\"]],[]]]]]],[]]]],[\"@controller\"],false,[\"if\",\"or\",\"not\",\"drop-down\",\"link-to\",\"-hash\",\"malibu-icon\",\"x-tooltip\"]]",
    "moduleName": "dashboard/templates/components/route-outlets/apps/new-dropdown.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});