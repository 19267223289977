define("dashboard/templates/components/addons-post-install-alert", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vz95uOEX",
    "block": "[[[10,0],[14,0,\"ba br1 bg-lightest-blue blue b--light-blue pa2 mb4\"],[12],[1,\"\\n  The add-on \"],[10,\"code\"],[12],[1,[30,0,[\"addonService\",\"name\"]]],[13],[1,\" has been installed.\\n  Check out the documentation in its \"],[6,[39,0],null,[[\"devcenterUrl\",\"whereFrom\",\"class\"],[[30,0,[\"addonService\",\"devcenterUrl\"]],\"post provision alert\",\"blue\"]],[[\"default\"],[[[[1,\"Dev Center\"]],[]]]]],[1,\" article to get started.\\n\"],[13],[1,\"\\n\"]],[],false,[\"app/addons-devcenter-link\"]]",
    "moduleName": "dashboard/templates/components/addons-post-install-alert.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});