define("dashboard/routes/team/switchable/usage", ["exports", "rsvp", "@ember/service", "dashboard/routes/team/base", "moment"], function (_exports, _rsvp, _service, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),

    model() {
      const store = this.store;
      const {
        team
      } = this.modelFor('team');
      return (0, _rsvp.hash)({
        team,
        plans: store.query('team/license', {
          team: team.get('id'),
          dyno_units: true
        }),
        currentUsage: store.query('team/license', {
          team: team.get('id'),
          dyno_units: true,
          combined: true
        }),
        monthlyLicenses: this.getMonthlyLicenses(team.get('id'))
      });
    },

    afterModel(model) {
      const analyticsNoun = model.team.analyticsNoun;
      this.analytics.logEvent(`${analyticsNoun} Usage Page`, 'Viewed');
      return model.team.enterprise;
    },

    getMonthlyLicenses(teamId) {
      const store = this.store;
      const startDate = (0, _moment.default)().subtract(1, 'years').format('YYYY-MM-DD');
      const endDate = (0, _moment.default)().add(1, 'month').format('YYYY-MM-DD');
      const options = {
        team: teamId,
        grouped: true,
        combined: true,
        dyno_units: true,
        start_date: startDate,
        end_date: endDate
      };
      return store.query('team/license', options).catch(() => []);
    }

  });

  _exports.default = _default;
});