define("dashboard/components/identity-provider/config", ["exports", "@ember/component", "dashboard/config/environment", "jquery", "ember-changeset", "dashboard/validations/identity-provider", "dashboard/validations/identity-provider-certificate", "ember-concurrency", "@ember/object", "@ember/object/computed", "@ember/debug", "@ember/service"], function (_exports, _component, _environment, _jquery, _emberChangeset, _identityProvider, _identityProviderCertificate, _emberConcurrency, _object, _computed, _debug, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint require-yield: off */
  const SSO_HOST = `https://${_environment.default.ssoHost}`;

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Element
    tagName: '',
    // --------------------------------------------------------------------------
    // Args
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),

    /**
     * @type {IdentityProviderOwner}
     */
    owner: null,

    /**
     * @type {boolean}
     */
    renderInPlace: false,

    /**
     * @type {?function}
     */
    onSave: null,

    /**
     * @type {?function}
     */
    onCancel: null,
    // --------------------------------------------------------------------------
    // Internal State
    certificateChangesets: null,
    identityProvider: null,
    sortingKeys: ['expiresAt', 'name'],
    // --------------------------------------------------------------------------
    // Computed Properties
    atMaxCertificates: (0, _computed.gte)('certificateChangesets.length', _environment.default.ssoMaxCertificates),
    persistedCertificates: (0, _computed.readOnly)('identityProvider.persistedCertificates'),
    sortedCertificates: (0, _computed.sort)('persistedCertificates', 'sortingKeys'),
    hasRequiredAttrs: (0, _computed.readOnly)('isValid'),
    isPristine: (0, _computed.and)('changeset.isPristine', 'certificatesArePristine'),
    isValid: (0, _computed.and)('changeset.isValid', 'certificatesAreValid'),
    mustConfirmDisable: (0, _computed.readOnly)('willBecomeDisabled'),
    canSubmitConfiguration: (0, _computed.and)('hasRequiredAttrs', 'hasConfirmedChanges'),
    ssoFormIsBusy: (0, _computed.or)('submitConfiguration.isRunning', 'uploadSsoMetadata.isRunning'),
    certificatesArePristine: (0, _object.computed)('certificateChangesets.@each.isPristine', function () {
      return this.certificateChangesets.reduce((isPristine, certChangeset) => isPristine && certChangeset.isPristine, true);
    }),
    certificatesAreValid: (0, _object.computed)('certificateChangesets.@each.isValid', function () {
      return this.certificateChangesets.reduce((isValid, certChangeset) => isValid && certChangeset.isValid, true);
    }),
    hasConfirmedChanges: (0, _object.computed)('mustConfirmDisable', 'hasConfirmedDisable', function () {
      return this.mustConfirmDisable ? this.hasConfirmedDisable : true;
    }),
    ssoMetadataEndpoint: (0, _object.computed)('identityProvider.name', function () {
      return `${SSO_HOST}/saml/${this.get('identityProvider.name')}/identity-provider-metadata`;
    }),
    ssoToggleDisabled: (0, _object.computed)('ssoFormIsBusy', 'isValid', 'changeset.enabled', function () {
      if (this.get('changeset.enabled')) {
        // One can always disable when in an enabled state.
        return false;
      }

      return this.get('ssoFormIsBusy') || !this.get('isValid');
    }),
    changeset: (0, _object.computed)('identityProvider', function () {
      const identityProvider = this.identityProvider;
      const result = new _emberChangeset.default(identityProvider, _identityProvider.validator, _identityProvider.default);
      result.validate();
      return result;
    }),
    willBecomeEnabled: (0, _object.computed)('{identityProvider,changeset}.enabled', function () {
      return !this.get('identityProvider.enabled') && this.get('changeset.enabled');
    }),
    willBecomeDisabled: (0, _object.computed)('{identityProvider,changeset}.enabled', function () {
      return this.get('identityProvider.enabled') && !this.get('changeset.enabled');
    }),
    submitButtonLabel: (0, _object.computed)('willBecomeEnabled', 'willBecomeDisabled', function () {
      if (this.willBecomeEnabled) {
        return 'Save & enable SSO';
      }

      if (this.willBecomeDisabled) {
        return 'Save & disable SSO';
      }

      return 'Save';
    }),

    // --------------------------------------------------------------------------
    // Lifecycle
    didReceiveAttrs() {
      this._super(...arguments);

      const owner = this.owner;
      (false && !(owner) && (0, _debug.assert)('owner must be passed to {{identity-provider/config ...}}', owner));
      let identityProvider = owner.get('identityProvider.content');

      if (!identityProvider) {
        identityProvider = this.store.createRecord('identity-provider', {
          enabled: false
        });
      }

      this.set('identityProvider', identityProvider);
      const certificateChangesets = this.sortedCertificates.map(cert => {
        return this.createCertificateChangeset(cert);
      });
      this.set('certificateChangesets', certificateChangesets); // We always want to start with one certificate in the list.

      if (this.certificateChangesets.length === 0) {
        this.addCertificate();
      }
    },

    // --------------------------------------------------------------------------
    // Tasks
    validateAll: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      this.certificateChangesets.forEach(changeset => {
        tasks.push(this.validateChangeset.perform(changeset));
      });
      tasks.push(this.validateChangeset.perform(this.changeset));
      yield (0, _emberConcurrency.all)(tasks);
    }),
    validateChangeset: (0, _emberConcurrency.task)(function* (changeset) {
      yield changeset.validate();
    }),
    submitConfiguration: (0, _emberConcurrency.task)(function* () {
      const identityProvider = this.identityProvider;
      const changeset = this.changeset;
      const owner = this.owner;
      const modelName = owner.constructor.modelName;

      if (this.willBecomeEnabled) {
        if (modelName === 'team') {
          this.analytics.logEvent('Enterprise Team SSO', 'Enabled');
        } else if (modelName === 'enterprise-account') {
          this.analytics.logEvent('Enterprise Account SSO', 'Enabled');
        }
      }

      if (this.willBecomeDisabled) {
        if (modelName === 'team') {
          this.analytics.logEvent('Enterprise Team SSO', 'Disabled');
        } else if (modelName === 'enterprise-account') {
          this.analytics.logEvent('Enterprise Account SSO', 'Disabled');
        }
      }

      yield this.validateAll.perform();

      if (this.isValid) {
        yield this.maybeCreateIdentityProvider.perform(identityProvider);
        const originalCertificates = identityProvider.certificates.toArray();
        const newCertificates = this.certificateChangesets.mapBy('_content');

        try {
          changeset.set('certificatesChanged', undefined); // Don't write this to underlying model.

          changeset.execute();
          this.certificateChangesets.invoke('execute');
          identityProvider.set('certificates', newCertificates);
          yield identityProvider.save();
        } catch (error) {
          identityProvider.rollbackAttributes();
          identityProvider.set('certificates', originalCertificates); // Rollback only the persisted certificates. We don't want to
          // do this on new certificates because rollbackAttributes will remove
          // them from the store and cause weird problems since we still have
          // changesets referencing them.

          newCertificates.rejectBy('isNew').invoke('rollbackAttributes');
          throw error;
        }

        const action = this.onSave;

        if (action) {
          action();
        }
      }
    }),
    maybeCreateIdentityProvider: (0, _emberConcurrency.task)(function* (identityProvider) {
      // We need to create a disabled IdP before we can update it with a list
      // of certifates and enable it. So we'll save the record without the
      // certificates if we're dealing with a new one, and then follow that up
      // with an update for the certificates below.
      if (identityProvider.isNew) {
        identityProvider.set('owner', this.owner);
        yield identityProvider.save();
      }
    }),
    uploadSsoMetadata: (0, _emberConcurrency.task)(function* (fileList) {
      const file = fileList && fileList[0];

      if (!file) {
        return;
      }

      const data = new FormData();
      const url = this.ssoMetadataEndpoint;
      const method = 'POST';
      const processData = false;
      const contentType = false;
      const options = {
        url,
        method,
        data,
        processData,
        contentType
      };
      data.append('file', file);

      try {
        const metadata = yield _jquery.default.ajax(options);
        this.set('changeset.entityId', metadata.entity_id);
        this.set('changeset.ssoTargetUrl', metadata.sso_target_url);
        const identityProvider = this.identityProvider;
        const body = metadata.certificate;
        const certificate = this.createCertificate({
          body,
          identityProvider
        });
        const certificateChangeset = this.createCertificateChangeset(certificate);
        this.set('certificateChangesets', [certificateChangeset]);
      } catch (xhr) {
        xhr.errorTitle = 'Could not parse metadata file';
        this.errorMonitor.parseXHR(xhr);
      }
    }),
    abandonConfiguration: (0, _emberConcurrency.task)(function* () {
      const identityProvider = this.identityProvider;

      if (identityProvider && identityProvider.isNew) {
        identityProvider.rollbackAttributes();
      }

      const action = this.onCancel;

      if (action) {
        action();
      }
    }),

    addCertificate() {
      const certificate = this.createCertificate();
      const certificateChangeset = this.createCertificateChangeset(certificate);
      const certificateChangesets = [...this.certificateChangesets, certificateChangeset];
      this.set('certificateChangesets', certificateChangesets); // Force changeset to dirty for save button state.

      this.changeset.set('certificatesChanged', true);
    },

    createCertificate() {
      let attrs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.store.createRecord('identity-provider-certificate', attrs);
    },

    createCertificateChangeset(certificate) {
      const changeset = new _emberChangeset.default(certificate, _identityProviderCertificate.validator, _identityProviderCertificate.default);
      changeset.validate();
      return changeset;
    },

    removeCertificate(certificateChangeset) {
      const certificateChangesets = this.certificateChangesets.filter(cert => cert !== certificateChangeset);
      this.set('certificateChangesets', certificateChangesets);
      const certificate = certificateChangeset.get('_content');
      certificate.rollbackAttributes(); // Force changeset to dirty for save button state.

      this.changeset.set('certificatesChanged', true);
    }

  });

  _exports.default = _default;
});