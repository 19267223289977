define("dashboard/templates/pipelines/pipeline/tests", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NxWo+DXP",
    "block": "[[[8,[39,0],null,[[\"@githubIntegrationDisabled\"],[[30,0,[\"githubIntegrationDisabled\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"],[8,[39,1],null,[[\"@githubIntegrationReenabled\",\"@pipeline\"],[[30,0,[\"githubIntegrationReenabled\"]],[30,0,[\"model\",\"pipeline\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"testRuns\"]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"pipeline\",\"platformUserPreferences\",\"authorizedGitHub\"],[[30,0,[\"model\",\"pipeline\"]],[30,0,[\"model\",\"userPreferences\"]],[28,[37,4],[\"authorizedGitHub\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"ci-panel\"],[12],[1,\"\\n  \"],[1,[28,[35,5],null,[[\"pipeline\",\"selectedFilterBranch\",\"testRuns\",\"createTestRun\"],[[30,0,[\"model\",\"pipeline\"]],[30,0,[\"realFakeBranch\"]],[30,0,[\"model\",\"testRuns\"]],[28,[37,4],[\"createTestRun\"],null]]]]],[1,\"\\n\\n  \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"github-integration-disabled-banner\",\"github-integration-reenabled-banner\",\"if\",\"pipeline/github-banner\",\"route-action\",\"ci/tests-menu\",\"component\",\"-outlet\"]]",
    "moduleName": "dashboard/templates/pipelines/pipeline/tests.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});