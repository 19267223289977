define("dashboard/components/app/deploy/github/disconnect-confirmation", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    hasReviewApps: (0, _computed.notEmpty)('reviewApps'),
    reviewAppCount: (0, _computed.alias)('reviewApps.length'),
    spawnsReviewApps: (0, _computed.alias)('githubAppLink.pullRequests.enabled'),
    autoDeploys: (0, _computed.alias)('githubAppLink.autoDeploy'),
    autoDeployBranch: (0, _computed.alias)('githubAppLink.branch')
  });

  _exports.default = _default;
});