define("dashboard/components/pipeline/apps-to-transfer", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    hasApps: (0, _computed.notEmpty)('transferableApps'),
    stageName: (0, _object.computed)('transferableApps.[]', function () {
      const appStage = this.get('transferableApps.firstObject.pipelineCoupling.stage');
      return appStage;
    }),
    iconTitle: (0, _object.computed)('stageName', function () {
      return this.stageName === 'review' ? 'Review app' : 'App';
    }),
    iconName: (0, _object.computed)('stageName', function () {
      if (this.stageName === 'review') {
        return `app-review-16`;
      } else {
        return `app-16`;
      }
    })
  });

  _exports.default = _default;
});