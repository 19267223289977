define("dashboard/serializers/shogun/private-link", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.generateID = generateID;

  var _default = _application.default.extend({
    extractId(_modelClass, resourceHash) {
      return generateID(resourceHash.addon.id);
    },

    serialize(snapshot) {
      const requestData = {
        allowed_accounts: snapshot.record.get('allowedAccounts')
      };
      return requestData;
    }

  });

  _exports.default = _default;

  function generateID(addonId) {
    return `private-link:${addonId}`;
  }
});