define("dashboard/serializers/identity-provider", ["exports", "dashboard/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      name: {
        serialize: false
      },
      slug: {
        serialize: false
      },
      herokuEntityId: {
        serialize: false
      },
      herokuStartUrl: {
        serialize: false
      },
      herokuAcsUrl: {
        serialize: false
      },
      createdAt: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      },
      certificates: {
        serialize: 'records',
        deserialize: 'records'
      }
    },

    serializeBelongsTo(snapshot, json, relationship) {
      if (relationship.key === 'owner') {
        this.serializeOwner(snapshot, json);
      } else {
        this._super(...arguments);
      }
    },

    serializeOwner(snapshot, json) {
      if (snapshot.id) {
        return; // do not serialize owner for existing records
      }

      const owner = snapshot.belongsTo('owner');
      const type = owner.modelName;
      const id = owner.id;
      const name = owner.attr('name');
      json.owner = {
        type,
        id,
        name
      };
    }

  });

  _exports.default = _default;
});