define("dashboard/templates/components/production-check", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "37gDzAqI",
    "block": "[[[6,[39,0],null,[[\"onClose\",\"containerClass\",\"title\"],[[30,0,[\"onClose\"]],\"production-check\",\"Production Check\"]],[[\"default\"],[[[[41,[51,[30,0,[\"fetchProductionCheckData\",\"isRunning\"]]],[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"localChecks\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,4],null,[[\"check\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"      \"],[10,0],[14,0,\"production-check-loading flex items-center justify-center\"],[12],[1,\"\\n        \"],[1,[34,5]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[\"localCheck\"],false,[\"hk-modal\",\"unless\",\"each\",\"-track-array\",\"production-check-item\",\"malibu-loading-spinner\"]]",
    "moduleName": "dashboard/templates/components/production-check.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});