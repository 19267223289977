define("dashboard/helpers/addon-price-format", ["exports", "@ember/component/helper", "dashboard/helpers/currency-format"], function (_exports, _helper, _currencyFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addonPriceFormat = addonPriceFormat;
  _exports.default = void 0;

  /**
   * A helper for displaying addon price values.
   *
   * - Render an addon price value (renders "$1,000.00"):
   *
   *   ```handlebars
   *   {{addon-price-format addon}}
   *   ```
   *
   * - Append a suffix onto the price (will not display for Free / Contract items)
   *
   *   ```handlebars
   *   {{addon-price-format addon suffix='/mo'}}
   *   ```
   *
   * @class Ember.Handlebars.helpers.addon-price-format
   */
  function addonPriceFormat(value, options) {
    // depending on how this helper is invoked value with either be an array or a singleton
    value = value instanceof Array ? value[0] : value;

    if (value.get('priceIsContract')) {
      return 'Contract';
    }

    return (0, _currencyFormat.currency)(value.get('priceInCents'), {
      unit: 'cents',
      showFree: true,
      suffix: options.suffix
    });
  }

  var _default = (0, _helper.helper)(addonPriceFormat);

  _exports.default = _default;
});