define("dashboard/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "dashboard/config/environment", "jquery"], function (_exports, _oauth2PasswordGrant, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    refreshAccessTokens: true,
    serverTokenEndpoint: `${_environment.default.loginUrl}/login/token`,
    serverTokenRevocationEndpoint: `${_environment.default.loginUrl}/login/revoke`,

    makeRequest(url, data, headers) {
      if (data.grant_type === 'refresh_token') {
        // front-end auth only accepts JSON, not application/x-www-form-urlencoded
        return _jquery.default.ajax({
          url,
          method: 'POST',
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } else if (data.grant_type === 'password') {
        // we are technically doing a authorization_code based flow,
        // not password grant.
        const body = {
          grant_type: 'authorization_code',
          code: data.password
        };
        return this._super(url, body, headers);
      }

      return this._super(url, data, headers);
    }

  });

  _exports.default = _default;
});