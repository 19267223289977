define("dashboard/templates/components/app/activity-feed", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "R8qRcQuC",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"visibleItems\"]]],null]],null],null,[[[41,[30,1,[\"isRelease\"]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"item\",\"app\",\"appPermissions\",\"githubLink\",\"releases\",\"latestRelease\",\"allowRollbacks\",\"onRefresh\"],[[30,1],[30,0,[\"app\"]],[30,0,[\"appPermissions\"]],[30,0,[\"githubLink\"]],[30,0,[\"arrangedReleases\"]],[30,0,[\"latestRelease\"]],[30,0,[\"allowRollbacks\"]],[30,0,[\"onRefresh\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"item\",\"app\",\"appPermissions\"],[[30,1],[30,0,[\"app\"]],[30,0,[\"appPermissions\"]]]]]],[1,\"\\n\"]],[]]]],[1]],null]],[\"item\"],false,[\"each\",\"-track-array\",\"if\",\"app/activity-release-item\",\"app/activity-build-item\"]]",
    "moduleName": "dashboard/templates/components/app/activity-feed.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});