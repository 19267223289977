define("dashboard/templates/components/stencil/spaces/spaces-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "G2xWe4sx",
    "block": "[[[1,[28,[35,0],null,[[\"opacity\"],[100]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[80]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[60]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[40]]]],[1,\"\\n\"],[1,[28,[35,0],null,[[\"opacity\"],[30]]]],[1,\"\\n\"]],[],false,[\"stencil/spaces/spaces-list-item\"]]",
    "moduleName": "dashboard/templates/components/stencil/spaces/spaces-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});