define("dashboard/routes/pipelines/pipeline/index/app-setup", ["exports", "@ember/routing/route", "rsvp", "ember-concurrency", "ember"], function (_exports, _route, _rsvp, _emberConcurrency, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isTesting
  } = _ember.default;

  var _default = _route.default.extend({
    model(_ref) {
      let {
        app_id
      } = _ref;
      return this.store.queryRecord('review-app', {
        app_id
      }).then(reviewApp => {
        const {
          app,
          appSetup
        } = reviewApp.getProperties(['app', 'appSetup']);
        const build = this.fetchBuild(reviewApp);
        return _rsvp.default.hash({
          appSetup: this._appSetupPromise(appSetup, app),
          build
        });
      });
    },

    afterModel(_ref2) {
      let {
        appSetup,
        build
      } = _ref2;
      appSetup.set('build', build);

      if (appSetup.get('isDoneOrFailed') || isTesting) {
        return;
      }

      this.pollAppSetupTask.perform(appSetup);
    },

    fetchBuild(reviewApp) {
      if (!reviewApp.get('app.currentBuild.id')) {
        return {};
      }

      const appId = reviewApp.get('app.id');
      const buildId = reviewApp.get('app.currentBuild.id');
      return this.store.queryRecord('build', {
        app: appId,
        buildId
      });
    },

    _appSetupPromise(appSetupPromise, app) {
      return appSetupPromise.then(appSetup => {
        appSetup.setProperties({
          app
        });
        return appSetup;
      });
    },

    pollAppSetupTask: (0, _emberConcurrency.task)(function* (appSetup) {
      appSetup = yield appSetup.reload();

      if (appSetup.get('isDoneOrFailed')) {
        return this.pollAppSetupTask.cancelAll();
      }

      yield (0, _emberConcurrency.timeout)(1000);
      this.pollAppSetupTask.perform(appSetup);
    }).keepLatest()
  });

  _exports.default = _default;
});