define("dashboard/models/key", ["exports", "ember-cp-validations", "dashboard/mixins/ds-error-monitoring-model", "@ember-data/model"], function (_exports, _emberCpValidations, _dsErrorMonitoringModel, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    publicKey: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, _dsErrorMonitoringModel.default, {
    comment: (0, _model.attr)('string'),
    publicKey: (0, _model.attr)('string')
  });

  _exports.default = _default;
});