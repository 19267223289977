define("dashboard/adapters/json-api", ["exports", "@ember-data/adapter/json-api", "dashboard/mixins/adapters/heroku-data-adapter-mixin", "@ember/object", "dashboard/config/environment"], function (_exports, _jsonApi, _herokuDataAdapterMixin, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_herokuDataAdapterMixin.default, {
    host: _environment.default.particleboardUrl,
    headers: (0, _object.computed)(function () {
      return {
        'Content-Type': 'application/vnd.api+json'
      };
    })
  });

  _exports.default = _default;
});