define("dashboard/controllers/app/access", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    readOnly,
    bool,
    filterBy,
    map
  } = _object.computed;

  var _default = _controller.default.extend({
    appCollaborators: alias('model.appCollaborators'),
    orgCollaborators: alias('model.orgCollaborators'),
    app: alias('model.app'),
    appPermissions: alias('model.appPermissions'),
    permissions: alias('model.permissions'),
    team: readOnly('model.team'),
    isOrgApp: bool('app.isOwnedByOrg'),
    // filter out org manager emails that aren't real "users".
    nonManagers: filterBy('appCollaborators', 'isOrgManager', false),

    /**
     * If it's an org, filter out the occasional
     * errant admins that appear, since we can't edit these.
     */
    collaborators: (0, _object.computed)('isOrgApp', 'nonManagers.[]', function () {
      if (this.isOrgApp) {
        return this.nonManagers.rejectBy('role', 'admin');
      } else {
        return this.nonManagers;
      }
    }),
    orgAdmins: filterBy('orgCollaborators', 'role', 'admin'),
    orgAdminPermissions: map('permissions', function (perm) {
      const newPerm = perm.copy();
      newPerm.set('selected', true);
      return newPerm;
    }),
    adminsHeader: 'Team Admins',
    actions: {
      lock() {
        this.app.lock();
      },

      unlock() {
        this.app.unlock();
      }

    }
  });

  _exports.default = _default;
});