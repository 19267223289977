define("dashboard/components/app-item-stack", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    tooltipTitle: (0, _object.computed)('app.stackDeprecation.name', 'app.stackDeprecation.stackStatus', function () {
      return `The ${this.app.stackDeprecation.name} stack is ${this.app.stackDeprecation.stackStatus}`;
    })
  });

  _exports.default = _default;
});