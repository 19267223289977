define("dashboard/components/team/first-run", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    and,
    gt
  } = _object.computed;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    classNames: ['first-run', 'relative', 'flex', 'flex-column', 'items-center'],
    hideSteps: true,
    stepsAreHidden: and('isMinimized', 'hideSteps'),
    hasApps: gt('team.apps.length', 0),

    logEvent(noun, action) {
      this.analytics.logEvent(noun, action);
    },

    actions: {
      toggleSteps() {
        this.toggleProperty('hideSteps');
      },

      dismissBanner() {
        this.onDismiss();
      }

    }
  });

  _exports.default = _default;
});