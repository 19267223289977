define("dashboard/templates/components/new/template-addons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cN5fWRj6",
    "block": "[[[10,0],[14,0,\"f5 b dark-gray mb1\"],[12],[1,\"Resources\"],[13],[1,\"\\n\"],[10,0],[14,0,\"f5 dark-gray mb2\"],[12],[1,\"These resources get provisioned when the app deploys. Heroku resources are prorated to the second. You only pay for the resources you use.\"],[13],[1,\"\\n\"],[41,[30,0,[\"showSubscribeToEcoBanner\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"mb4\"]],[[\"@dismissAction\",\"@onClickAction\"],[[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"hideEcoBanner\"]]],null],true],null],[28,[37,2],[[30,0],\"transitionToAccountBilling\"],null]]],null],[1,\"\\n\"]],[]],null],[10,\"ul\"],[14,0,\"list-unstyled\"],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table addons-table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"br1 shadow-outer-1 hk-hide-bb-last-row\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"definedFormationList\"]]],null]],null],null,[[[1,\"          \"],[8,[39,6],null,[[\"@size\",\"@type\",\"@isSubscribedToEco\"],[[30,1,[\"size\"]],[30,1,[\"type\"]],[30,0,[\"isSubscribedToEco\"]]]],null],[1,\"\\n\"]],[1]],null],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"addons\"]]],null]],null],null,[[[1,\"          \"],[1,[28,[35,7],null,[[\"addon\",\"shouldShowActionsList\",\"showAddonServiceAndPrice\",\"showAttachments\",\"isViewable\",\"isWebHooks\",\"priceSuffix\"],[[30,2],false,true,false,true,true,\"/month\"]]]],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"mv4 bb b--light-silver\"],[12],[13],[1,\"\\n\"]],[\"formationInfo\",\"addon\"],false,[\"if\",\"eco-button-deploy-subscribe-banner\",\"action\",\"mut\",\"each\",\"-track-array\",\"app/formation-list-item\",\"app/addons-list-item\"]]",
    "moduleName": "dashboard/templates/components/new/template-addons.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});