define("dashboard/components/org/member-confirm-delete-modal", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isFederated: false,
    submitDisabled: false,
    confirmationText: (0, _object.computed)('isInvitation', function () {
      let text = 'Are you sure you want to remove ';

      if (this.isInvitation) {
        text = 'Are you sure you want to revoke the invitation for ';
      }

      return text;
    }),
    actions: {
      confirmEmailAddress(inputText) {
        this.set('submitDisabled', inputText !== this.email);
      }

    }
  });

  _exports.default = _default;
});