define("dashboard/components/metrics/alerting-configuration-item", ["exports", "@ember/object/computed", "@ember/component", "@ember/object"], function (_exports, _computed, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'metrics-alerting-configuration-item',
    classNameBindings: [':metrics__alerting-configuration__item', 'isOpen', 'isActive', 'isActivePersisted'],
    isOpen: (0, _object.computed)('openPanelName', 'name', function () {
      return this.openPanelName === this.name;
    }),
    isClosed: (0, _computed.not)('isOpen'),
    isActive: (0, _computed.alias)('model.isActive'),
    // this represents the *persisted* value of `isActive`
    isActivePersisted: (0, _object.computed)('isActive', 'model.isNew', 'model.hasDirtyAttributes', function () {
      let isActive = this.isActive && !this.get('model.isNew'); // this block is checking if the the `active` value has changed
      // if it has, we use the *original* value as the "persisted" value

      if (this.get('model.hasDirtyAttributes')) {
        const activeChanged = this.model.changedAttributes().isActive;

        if (activeChanged) {
          // we've now determined that this is the persisted value
          // of `active` and override the value
          isActive = activeChanged[0];
        }
      }

      return isActive;
    }),
    activeState: (0, _object.computed)('isActivePersisted', function () {
      if (this.isActivePersisted) {
        return 'Active';
      } else {
        return 'Inactive';
      }
    }),
    actions: {
      onHeaderClick() {
        if (this.isOpen) {
          this.onCloseAlertingSection();

          if (!this.get('model.isNew')) {
            this.model.rollbackAttributes();
          }
        } else {
          this.onOpenAlertingSection(this.name);
        }
      }

    }
  });

  _exports.default = _default;
});