define("dashboard/models/metrics/router-status", ["exports", "@ember/object", "dashboard/models/metrics", "dashboard/utils/metrics/metrics-data-operators"], function (_exports, _object, _metrics, _metricsDataOperators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * combines counts from HTTP status codes into a new data set of
   * [x,y] pairs from codes that are within the minimum and
   * maximum range provided
   *
   */
  const combinedStatusMetric = function (minimum, maximum) {
    return (0, _object.computed)('rawData', function () {
      const data = this.rawData;
      const keys = Object.keys(data); // keys for chosen range

      const keysFiltered = keys.filter(function (key) {
        const code = parseInt(key, 10);
        return code >= minimum && code < maximum;
      });

      if (keysFiltered.length > 0) {
        const metric = this.createNewMetric();
        const combinedData = metric.map(function (datum, index) {
          const time = datum[0];
          const total = keysFiltered.reduce(function (sum, key) {
            const value = data[key][index];

            if (value && value[1]) {
              return sum + value[1];
            } else {
              return sum;
            }
          }, null);
          return [time, total];
        });
        return combinedData;
      } else {
        return [];
      }
    });
  };
  /**
   * transforms the values in a set of data to a
   * per/rate normalised format based on the step duration
   *
   * i.e. 60 requests at one data point with 1m rollup is .1rpm
   */


  const normalizedData = function (key, rate) {
    return (0, _object.computed)(key, 'stepDuration', function () {
      const data = this.get(key);
      const step = this.stepDuration;

      if (data) {
        const normalized = this.get(key).map(datum => {
          let stepRate;

          switch (rate) {
            case 's':
              stepRate = step.asSeconds();
              break;

            case 'm':
              stepRate = step.asMinutes();
              break;
          }

          if (datum[1]) {
            return [datum[0], datum[1] / stepRate];
          } else {
            return [datum[0], null];
          }
        });
        return normalized;
      }
    });
  };
  /**
   * the RouterStatus model
   */


  var _default = _metrics.default.extend({
    // this is the combined counts for all status codes
    dataAll: combinedStatusMetric(0, 1000),
    data2xx: combinedStatusMetric(200, 300),
    data3xx: combinedStatusMetric(300, 400),
    data4xx: combinedStatusMetric(400, 500),
    data5xx: combinedStatusMetric(500, 600),
    data2xxTo4xx: combinedStatusMetric(200, 500),
    _rpsAllRaw: normalizedData('dataAll', 's'),
    _rps2xxRaw: normalizedData('data2xx', 's'),
    _rps3xxRaw: normalizedData('data3xx', 's'),
    _rps4xxRaw: normalizedData('data4xx', 's'),
    _rps5xxRaw: normalizedData('data5xx', 's'),
    rpsAll: (0, _metricsDataOperators.formatData)('_rpsAllRaw'),
    rps2xx: (0, _metricsDataOperators.formatData)('_rps2xxRaw'),
    rps3xx: (0, _metricsDataOperators.formatData)('_rps3xxRaw'),
    rps4xx: (0, _metricsDataOperators.formatData)('_rps4xxRaw'),
    rps5xx: (0, _metricsDataOperators.formatData)('_rps5xxRaw'),
    _rpmAllRaw: normalizedData('dataAll', 'm'),
    _rpm2xxRaw: normalizedData('data2xx', 'm'),
    _rpm3xxRaw: normalizedData('data3xx', 'm'),
    _rpm4xxRaw: normalizedData('data4xx', 'm'),
    _rpm5xxRaw: normalizedData('data5xx', 'm'),
    _rpm2xxTo4xxRaw: normalizedData('data2xxTo4xx', 'm'),
    rpmAll: (0, _metricsDataOperators.formatData)('_rpmAllRaw'),
    rpm2xx: (0, _metricsDataOperators.formatData)('_rpm2xxRaw'),
    rpm3xx: (0, _metricsDataOperators.formatData)('_rpm3xxRaw'),
    rpm4xx: (0, _metricsDataOperators.formatData)('_rpm4xxRaw'),
    rpm5xx: (0, _metricsDataOperators.formatData)('_rpm5xxRaw'),
    rpm2xxTo4xx: (0, _metricsDataOperators.formatData)('_rpm2xxTo4xxRaw'),
    errorRateData: (0, _object.computed)('rpmAll.[]', 'rpm5xx.[]', function () {
      const data = this.createNewMetric();
      const dataAll = this.rpmAll;
      const data5xx = this.rpm5xx;
      data.forEach(function (datum, index) {
        const datum5xx = data5xx[index] || [];
        const datumAll = dataAll[index] || [];
        datum[1] = datum5xx[1] / datumAll[1] || 0;
      });
      return data;
    })
  }).reopenClass({
    legacyKey: 'throughput'
  });

  _exports.default = _default;
});