define("dashboard/models/buildpack-installation", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, {
    ordinal: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    app: (0, _model.belongsTo)('app')
  });

  _exports.default = _default;
});