define("dashboard/templates/components/account/applications/client-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UTyCqk1A",
    "block": "[[[6,[39,0],null,[[\"changeset\",\"property\"],[[30,0,[\"changeset\"]],\"name\"]],[[\"default\"],[[[[6,[30,1,[\"label\"]],null,null,[[\"default\"],[[[[1,\"    Name\\n    \"],[10,1],[14,0,\"br1 ph1 ml1 bg-gray f6 white\"],[12],[1,\"Required\"],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"],[1,[28,[30,1,[\"field\"]],null,[[\"placeholder\",\"class\",\"autofocus\",\"autocomplete\"],[\"New API client\",\"w-100 mb1\",true,\"off\"]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"changeset\",\"property\"],[[30,0,[\"changeset\"]],\"redirectUri\"]],[[\"default\"],[[[[6,[30,2,[\"label\"]],null,null,[[\"default\"],[[[[1,\"    OAuth callback URL\\n    \"],[10,1],[14,0,\"br1 ph1 ml1 bg-gray f6 white\"],[12],[1,\"Required\"],[13],[1,\"\\n\"]],[]]]]],[1,\"  \"],[1,[28,[30,2,[\"field\"]],null,[[\"placeholder\",\"class\",\"autofocus\",\"autocomplete\"],[\"https://example.com/auth/callback\",\"w-100 mb1\",true,\"off\"]]]],[1,\"\\n\"]],[2]]]]]],[\"group\",\"group\"],false,[\"hk-form-group\"]]",
    "moduleName": "dashboard/templates/components/account/applications/client-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});