define("dashboard/models/pipeline-services-config", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    pipeline: (0, _model.belongsTo)('pipeline'),
    ci: (0, _model.attr)('boolean'),
    reviewApps: (0, _model.attr)('boolean'),
    // Review Apps 2.0
    automaticReviewApps: (0, _model.attr)('boolean'),
    organization: (0, _model.attr)()
  });

  _exports.default = _default;
});