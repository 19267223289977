define("dashboard/serializers/app-transfer", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      app: {
        serialize: false
      },
      createdAt: {
        serialize: false
      },
      owner: {
        serialize: false
      },
      recipient: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      }
    },

    serialize(snapshot) {
      const recipientUser = snapshot.record.get('recipientUser');
      const recipientTeam = snapshot.record.get('recipientTeam');
      const state = snapshot.record.get('state');
      const recipient = {};

      if (recipientUser) {
        recipient['recipient_user'] = recipientUser;
      }

      if (recipientTeam) {
        recipient['recipient_team'] = recipientTeam;
        recipient['silent'] = true; // don't send an email for confirmation

        recipient['auto_upgrade'] = true; // automatically ugrades free dynos to hobby tier
      } // If the record isn't new, and we're setting the state,
      // just send the state params, otherwise record is new
      // and we want to send all the options


      if (state && !snapshot.record.get('isNew')) {
        return {
          state
        };
      } else {
        return recipient;
      }
    },

    extractRelationships(modelClass, resourceHash) {
      const relationships = this._super(...arguments);

      relationships.app = {
        data: {
          type: 'app',
          id: resourceHash['app']['id']
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});