define("dashboard/utils/ansi-stylize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ansiStylize = ansiStylize;
  const ANSI_STYLE_CODE_REGEX = /\033\[(\d+(;\d+){0,})m/g;
  const ANSI_STYLE_CODES = {
    1: 'b',
    // ANSI increased intensity (bold)
    3: 'i',
    // ANSI italic
    4: 'underline',
    // ANSI underline
    9: 'strike',
    // ANSI Crossed-out
    22: 'normal',
    // ANSI normal intensity (not bold)
    23: 'fs-normal',
    // ANSI not italic
    24: 'no-underline',
    // ANSI not underlined
    29: 'no-underline',
    // ANSI not crossed out
    30: 'black',
    // ANSI Black foreground
    31: 'red',
    // ANSI red foreground
    32: 'green',
    // ANSI green foreground
    33: 'gold',
    // ANSI yellow foreground; purple3 does not have yellow
    34: 'dark-blue',
    // ANSI blue foreground
    35: 'purple',
    // ANSI magenta foreground
    36: 'blue',
    // ANSI cyan foreground; purple3 does not have cyan
    37: 'gray',
    // ANSI white foreground; white is difficult to see on light gray background
    39: 'near-black' // ANSI default foreground color

  };
  const ANSI_STYLE_CANCEL_CODE = '0'; // This utility converts ANSI formatting codes from terminal output to HTML
  // styled with purple3 equivalent classes. This works in a similar way to
  // various ansi2html utilities (like https://github.com/pycontribs/ansi2html).
  // This utility supports only the ANSI color codes that align with purple3.
  // Other ANSI codes (background color, blink, invisible, negative, etc.) are
  // stripped away.

  function ansiStylize(text) {
    let openSpans = 0;
    text = text.replace(ANSI_STYLE_CODE_REGEX, (_match, capture) => {
      let result = '';

      for (const code of capture.split(';')) {
        if (ANSI_STYLE_CANCEL_CODE === code) {
          result += closeSpans(openSpans);
          openSpans = 0;
        } else {
          if (Object.hasOwn(ANSI_STYLE_CODES, code)) {
            result += `<span class="${ANSI_STYLE_CODES[code]}">`;
            openSpans++;
          }
        }
      }

      return result;
    });
    text += closeSpans(openSpans);
    return text;
  }

  function closeSpans(n) {
    return Array(n).fill().map(() => '</span>').join('');
  }
});