define("dashboard/models/identity-provider", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _model, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * https://github.com/heroku/api/blob/master/schema/variants/3.identity-providers/platform-api-reference.md#identity-provider
   */
  const REQUIRED_ATTRS = ['entityId', 'ssoTargetUrl'];

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string'),
    entityId: (0, _model.attr)('string'),
    sloTargetUrl: (0, _model.attr)('string'),
    ssoTargetUrl: (0, _model.attr)('string'),
    herokuEntityId: (0, _model.attr)('string'),
    herokuStartUrl: (0, _model.attr)('string'),
    herokuAcsUrl: (0, _model.attr)('string'),
    enabled: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    owner: (0, _model.belongsTo)('identity-provider-owner', {
      polymorphic: true,
      inverse: 'identityProvider'
    }),
    certificates: (0, _model.hasMany)('identity-provider-certificate'),
    persistedCertificates: (0, _computed.filterBy)('certificates', 'isNew', false),
    ownerRole: (0, _computed.readOnly)('owner.role'),
    hasRequiredAttrs: (0, _object.computed)(...REQUIRED_ATTRS, 'persistedCertificates.[]', function () {
      const hasRequired = REQUIRED_ATTRS.every(key => !(0, _utils.isEmpty)(this.get(key)));
      return hasRequired && this.persistedCertificates.every(cert => !(0, _utils.isEmpty)(cert.body));
    })
  });

  _exports.default = _default;
});