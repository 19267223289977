define("dashboard/adapters/team/member", ["exports", "dashboard/adapters/application", "dashboard/mixins/adapters/range-aware-adapter"], function (_exports, _application, _rangeAwareAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // stopgap solution until https://github.com/heroku/api/pull/11757 is merged
  // make sure the team is always present on the payload so the serializer doesn't error
  function addTeam(snapshot, result) {
    return { ...result,
      team: {
        id: snapshot.belongsTo('team').id
      }
    };
  }

  var _default = _application.default.extend(_rangeAwareAdapter.default, {
    updateMethod: 'PUT',
    createMethod: 'PUT',

    async createRecord(store, type, snapshot) {
      return addTeam(snapshot, await this._super(...arguments));
    },

    async updateRecord(store, type, snapshot) {
      return addTeam(snapshot, await this._super(...arguments));
    },

    async deleteRecord(store, type, snapshot) {
      return addTeam(snapshot, await this._super(store, type, snapshot));
    },

    urlForCreateRecord(modelName, snapshot) {
      const teamId = snapshot.record.get('team.id');
      return `${this.host}/teams/${teamId}/members`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const teamId = snapshot.record.get('team.id');
      const memberId = snapshot.record.get('email');
      return `${this.host}/teams/${teamId}/members/${memberId}`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      return this.urlForCreateRecord(modelName, snapshot);
    },

    urlForQuery(query) {
      const teamId = query.teamId;

      if (query.range) {
        this.set('range', query.range);
      }

      delete query.range;
      delete query.teamId;
      return `${this.host}/teams/${teamId}/members`;
    },

    query(store, type, query) {
      const {
        teamId
      } = query;
      return this._super(store, type, query).then(data => {
        if (teamId) {
          data.forEach(member => member.team = {
            id: teamId
          });
        }

        return data;
      });
    },

    range: {
      attribute: 'email',
      max: 1000
    }
  });

  _exports.default = _default;
});