define("dashboard/components/addons/manage-attachments", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "dashboard/utils/pluralize", "ember-concurrency"], function (_exports, _component, _service, _object, _computed, _pluralize, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    attachments: (0, _computed.readOnly)('addon.attachments'),
    isAttaching: false,
    isLastAttachment: (0, _computed.equal)('attachments.length', 1),
    attachmentsNoun: (0, _object.computed)('attachments.[]', function () {
      const length = this.get('attachments.length') || 0;
      return (0, _pluralize.default)(length, 'attachment', 'attachments');
    }),
    attachmentsToCurrentApp: (0, _object.computed)('attachments.@each.app', 'currentApp.id', function () {
      const attachments = this.attachments || [];
      return attachments.filterBy('app.id', this.get('currentApp.id'));
    }),
    otherAttachments: (0, _object.computed)('attachments.@each.app', 'currentApp.id', function () {
      const attachments = this.attachments || [];
      return attachments.rejectBy('app.id', this.get('currentApp.id'));
    }),
    apps: (0, _object.computed)('loadAvailableApps.lastSuccessful.value', function () {
      const availableApps = this.get('loadAvailableApps.lastSuccessful.value') || [];
      return availableApps.toArray();
    }),

    attachToAnotherApp() {
      this.toggleProperty('isAttaching');
      this.loadAvailableApps.perform();
    },

    loadAvailableApps: (0, _emberConcurrency.task)(function* () {
      const app = this.get('currentApp');
      const isOrgApp = app.get('isOwnedByOrg');

      if (isOrgApp) {
        const team = app.get('team');
        return yield this.store.query('app', {
          team: team.get('id')
        });
      } else {
        return yield this.store.query('app', {
          personalOnly: true
        });
      }
    }).restartable(),
    actions: {
      selectApp(app) {
        const createAttachment = this.store.createRecord('attachment', {
          addon: this.get('addon'),
          app
        });
        createAttachment.save().then(() => {
          this.toggleProperty('isAttaching');
          this.analytics.logEvent('Add-on', 'Attached');
        });
      }

    }
  });

  _exports.default = _default;
});