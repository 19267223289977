define("dashboard/models/team", ["exports", "jquery", "@ember/object", "rsvp", "dashboard/models/identity-provider-owner", "@ember-data/model", "ember-cp-validations", "@ember/object/computed", "dashboard/config/environment", "dashboard/models/team-permissions"], function (_exports, _jquery, _object, _rsvp, _identityProviderOwner, _model, _emberCpValidations, _computed, _environment, _teamPermissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 30,

      message(type) {
        if (type === 'tooShort') {
          return 'is too short (minimum is 4 characters).';
        }

        if (type === 'tooLong') {
          return 'is too long (maximum is 30 characters).';
        }
      }

    }), // Ordering matters here for which is the dominant error we display
    (0, _emberCpValidations.validator)('format', {
      regex: /^[a-z]/,
      message: 'must start with a lowercase letter.'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[a-z0-9-]*$/,
      message: 'should only contain lowercase letters, numbers, and dashes.'
    })]
  });

  var _default = _identityProviderOwner.default.extend(Validations, {
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    apps: (0, _model.hasMany)('app'),
    features: (0, _model.hasMany)('team/feature'),
    invitations: (0, _model.hasMany)('team/invitation'),
    invoices: (0, _model.hasMany)('team/invoice'),
    members: (0, _model.hasMany)('team/member'),
    allowedAddons: (0, _model.hasMany)('team/allowed-addon'),
    spaces: (0, _model.hasMany)('space'),
    permissions: (0, _model.hasMany)('team/permission'),
    preference: (0, _model.belongsTo)('team/preference'),
    resourceItems: (0, _model.hasMany)('team/resource-item'),
    licenses: (0, _model.hasMany)('team/license'),
    combinedLicenses: (0, _model.hasMany)('team/license'),
    groupedLicenses: (0, _model.hasMany)('team/license'),
    githubAppInstallation: (0, _model.belongsTo)('github-app-installation'),
    pipelineCostConsent: (0, _model.attr)('boolean'),
    pipelineCostConsentAt: (0, _model.attr)('date'),
    pipelineCostConsentUserEmail: (0, _model.attr)('string'),
    default: (0, _model.attr)('boolean'),
    membershipLimit: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    role: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    analyticsNoun: (0, _object.computed)('isEnterpriseTeam', function () {
      return `${this.isEnterpriseTeam ? 'Enterprise' : 'Online'} Team`;
    }),
    enterpriseAccount: (0, _model.attr)(),
    enterprise: (0, _model.belongsTo)('enterprise-account'),
    memberEmails: (0, _computed.mapBy)('members', 'email'),
    appPermissionNames: (0, _object.computed)(function () {
      return ['view', 'deploy', 'operate', 'manage'];
    }),
    roles: (0, _object.computed)('isEnterpriseOrg', function () {
      const result = ['admin', 'member'];

      if (this.isEnterpriseOrg) {
        result.push('viewer');
      }

      result.push('collaborator');
      return result;
    }),
    canDisableThirdPartyOAuth: (0, _object.computed)('features.isPending', function () {
      return this._checkFeatureIsEnabled('allow-preference-disable-third-party-oauth');
    }),
    canManageAddonControls: (0, _computed.or)('isEnterpriseOrg', 'hasTeamAddonsControlsFlag'),
    canManageSpaces: (0, _computed.or)('hasOrgManageSpacesFlag', 'isEnterpriseTeam'),
    // this does not look at the user's actual permissions
    canManuallyInviteNewMembers: (0, _object.computed)('features', 'features.isPending', function () {
      return this._checkFeatureIsEnabled('team-invite-acceptance');
    }),
    hasOrgManageSpacesFlag: (0, _object.computed)('features', 'features.isPending', function () {
      return this._checkFeatureIsEnabled('org-manage-spaces');
    }),
    hasFederatedIdentityFlag: (0, _object.computed)('features', 'features.isPending', function () {
      return this._checkFeatureIsEnabled('federated-identity');
    }),
    hasTeamAddonsControlsFlag: (0, _object.computed)('features', 'features.isPending', function () {
      return this._checkFeatureIsEnabled('teams-addons-controls');
    }),
    hasOrgViewerFlag: (0, _object.computed)('features', 'features.isPending', function () {
      return this._checkFeatureIsEnabled('org-viewer');
    }),
    hasOrgAccessControlsFlag: (0, _object.computed)('features', 'features.isPending', function () {
      return this._checkFeatureIsEnabled('org-access-controls');
    }),
    allowPlaybookUrlInThresholdAlerts: (0, _object.computed)('features', 'features.isPending', function () {
      return this._checkFeatureIsEnabled('alerts-playbook-url');
    }),
    hasRole: (0, _computed.bool)('role'),
    isAdmin: (0, _computed.equal)('role', 'admin'),
    isMember: (0, _computed.equal)('role', 'member'),
    isViewer: (0, _computed.equal)('role', 'viewer'),
    isCollaborator: (0, _computed.equal)('role', 'collaborator'),
    canCreateApp: (0, _computed.bool)('currentPermissions.canCreateApps'),
    canCreateSpace: (0, _computed.bool)('currentPermissions.canManageSpaces'),
    canTransferSpaces: (0, _computed.bool)('currentPermissions.canManageSpaces'),
    // this looks at the user's actual permissions
    canManagePipelines: (0, _computed.bool)('currentPermissions.canManagePipelines'),
    isEnterpriseOrg: (0, _computed.equal)('type', 'enterprise'),
    isNotEnterpriseOrg: (0, _computed.not)('isEnterpriseOrg'),
    isEnterpriseTeam: (0, _computed.alias)('isEnterpriseOrg'),
    isNotEnterpriseTeam: (0, _computed.not)('isEnterpriseTeam'),
    isTeam: (0, _computed.equal)('type', 'team'),
    hasEnterpriseAccount: (0, _computed.bool)('enterprise.name'),
    isGithubAppEnabled: (0, _object.computed)('enterprise.githubAppSettings.canInstallGithubApp', function () {
      const canInstallGithubApp = this.get('enterprise.githubAppSettings.canInstallGithubApp');
      const selectedTeamIds = this.get('enterprise.githubAppSettings.selectedTeamIds');
      const isAllTeamsSelected = this.get('enterprise.githubAppSettings.allTeamsSelected');
      const isGithubAppEnabledGlobally = canInstallGithubApp && isAllTeamsSelected;
      const isGithubAppEnabledLocally = canInstallGithubApp && selectedTeamIds.includes(this.get('id'));
      return !!(isGithubAppEnabledGlobally || isGithubAppEnabledLocally);
    }),

    _checkFeatureIsEnabled(featureName) {
      const feature = this.features.findBy('name', featureName);
      return !!(feature && feature.get('enabled'));
    },

    nameIsValid: (0, _computed.alias)('validations.isValid'),
    nameIsNotValid: (0, _computed.not)('nameIsValid'),
    validationMessage: (0, _object.computed)('validations.messages', function () {
      return this.get('validations.messages.firstObject');
    }),
    currentPermissions: (0, _object.computed)('role', 'enterprise', function () {
      const team = this;
      const enterpriseAccount = this.get('enterprise');
      return _teamPermissions.default.create({
        team,
        enterpriseAccount
      });
    }),

    checkNameAvailability() {
      if (this.get('validations.isInvalid')) {
        return new _rsvp.default.Promise(resolve => {
          resolve(false);
        });
      } // We always want this promise to resolve


      return new _rsvp.default.Promise(resolve => {
        _jquery.default.ajax({
          method: 'GET',
          url: `${_environment.default.platformApiUrl}/teams/${this.name}`,
          headers: {
            // this endpoint has to be called without an auth token
            Accept: 'application/vnd.heroku+json; version=3'
          }
        }).then(() => {
          resolve(false);
        }, xhr => {
          // if 404, name IS available
          // otherwise, name is NOT available
          resolve(xhr.status === 404);
        });
      });
    }

  });

  _exports.default = _default;
});