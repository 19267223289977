define("dashboard/components/scheduler/forms/job-command", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/utils/custom-computed"], function (_exports, _component, _object, _computed, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    sizes: (0, _computed.alias)('app.currentTier.sizes'),
    onlyOneSizeOption: (0, _computed.equal)('sizes.length', 1),
    selectedSizeName: (0, _computed.alias)('job.dynoSize'),
    selectedSize: (0, _customComputed.findBy)('sizes', 'size', 'selectedSizeName'),
    dynoIconName: (0, _object.computed)('selectedSize.dynomiteIconName', function () {
      return this.get('selectedSize.dynomiteIconName') || 'app-16';
    }),
    actions: {
      setDynoSize(size) {
        this.job.set('dynoSize', size);
      }

    }
  });

  _exports.default = _default;
});