define("dashboard/components/app/collaborators-list", ["exports", "rsvp", "@ember/component", "@ember/object", "@ember/service", "dashboard/mixins/purple-list"], function (_exports, _rsvp, _component, _object, _service, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    and,
    bool,
    readOnly,
    filterBy
  } = _object.computed;

  var _default = _component.default.extend(_purpleList.default, {
    classNames: ['collaborator-list', 'limit-width'],
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    router: (0, _service.inject)(),
    glostick: (0, _service.inject)(),
    isDeleting: false,
    canAdd: readOnly('appPermissions.canAddDirectMember'),
    isOrgApp: bool('app.isOwnedByOrg'),
    canEdit: and('appPermissions.canManageAppPermissions', 'team.hasOrgAccessControlsFlag'),
    canRemoveDirectMember: alias('appPermissions.canRemoveDirectMember'),
    heading: (0, _object.computed)('isOrgApp', function () {
      if (this.isOrgApp) {
        return 'App Members';
      } else {
        return 'Collaborators';
      }
    }),
    collaboratorType: (0, _object.computed)('isOrgApp', function () {
      if (this.isOrgApp) {
        return 'member';
      } else {
        return 'collaborator';
      }
    }),
    // Sort collaborators by email
    sortedCollaborators: (0, _object.computed)('collaborators.[]', function () {
      return this.collaborators.sortBy('email');
    }),
    savedSortedCollaborators: filterBy('sortedCollaborators', 'hasDirtyAttributes', false),

    isCurrentUser(email) {
      return email === this.get('current.account.email');
    },

    // Redirect the user if they remove themselves.
    afterDeleteItem(collaborator, userEmail) {
      this._super(collaborator);

      if (this.isCurrentUser(userEmail)) {
        const team = this.get('app.team.content');

        if (team) {
          team.reload().catch(() => team.unloadRecord());
        }

        this.glostick.refreshQuickJump(); // At this point, there's no easy way to know if the user still has access
        // to the team as they may have just removed themselves from the only app
        // they were a collaborator on. We'll just go to the apps list to avoid
        // seeing a 404 when trying to go back to the team.

        this.router.transitionTo('apps');
      }
    },

    createNewItem() {
      let collaborator;
      const app = this.app;

      if (this.isOrgApp) {
        collaborator = this.store.createRecord('team/collaborator', {
          app,
          permissions: this.defaultPermissions
        });
      } else {
        collaborator = this.store.createRecord('collaborator', {
          app
        });
      }

      collaborator.set('availablePermissions', this.availablePermissions);
      return collaborator;
    },

    defaultPermissions: (0, _object.computed)('team.hasOrgAccessControlsFlag', function () {
      return this.get('team.hasOrgAccessControlsFlag') ? [{
        name: 'view'
      }] : [{
        name: 'view'
      }, {
        name: 'deploy'
      }, {
        name: 'operate'
      }];
    }),
    titleForEdit: (0, _object.computed)('item.isNew', function () {
      const noun = this.get('item.isNew') ? 'New' : 'Edit';
      return `${noun} ${this.collaboratorType}`;
    }),
    titleForDelete: (0, _object.computed)('item.user.name', function () {
      const email = this.get('item.user.email');
      return `Delete <small>(${email})</small>`;
    }),
    isNewMember: (0, _object.computed)('item.isNew', function () {
      return this.get('item.isNew');
    }),
    disableSaveButton: (0, _object.computed)('isEditing', 'canEdit', 'team', function () {
      if (this.isEditing && !this.canEdit) {
        // disable save button when we're editing and it's a team app and they dont have the orgAccessControlsFlag
        return true;
      } else if (this.isEditing && !this.team) {
        // disable save button if we are editing a collaborator on a non team app
        return true;
      }

      return false;
    }),
    actions: {
      toggleModal() {
        this._super();

        if (this.isEditing) {
          this.set('isEditing', false);
        }
      },

      edit(item) {
        this._super(item);

        this.set('isEditing', true);
      },

      isDeletingConfirm() {
        this.set('isDeleting', true);
      },

      isDeletingCancel() {
        this.set('isDeleting', false);
      },

      save() {
        const collaborator = this.item;

        this._super(...arguments);

        if (collaborator.get('validations.isValid')) {
          return collaborator.save().then(() => {
            this.set('item', null);
            this.toggleProperty('isAdding');
          }, () => {// Catch unsuccessful save, do nothing, let errorMonitor handle
          });
        } else {
          this.set('item.errorIsVisible', true);
          return (0, _rsvp.reject)();
        }
      },

      delete() {
        const collaborator = this.item;
        const userEmail = collaborator.get('user.email');
        return collaborator.destroyRecord().then(() => {
          this.collaborators.removeObject(collaborator);
          this.afterDeleteItem(collaborator, userEmail);
          this.send('toggleDeleteConfirmModal');
          this.send('toggleModal');
        });
      },

      closeSidePanel() {
        if (this.get('item.isNew')) {
          this.item.unloadRecord();
        } else {
          this.item.rollbackPermissions();
        }

        this.send('toggleModal');
      },

      // Handle downstream PermissionToggle selection.
      selectPermission(permToSelect) {
        const perms = this.get('item.editingPermissions').toArray();
        const hasPerm = perms.findBy('name', permToSelect.get('name'));

        if (!hasPerm) {
          this.item.set('editingPermissions', perms.addObject(permToSelect));
        }
      },

      // Handle downstream PermissionToggle deselection.
      deselectPermission(permToRemove) {
        const perms = this.get('item.editingPermissions').toArray();
        const existingPerm = perms.findBy('name', permToRemove.get('name'));

        if (existingPerm) {
          this.item.set('editingPermissions', perms.removeObject(existingPerm));
        }
      }

    }
  });

  _exports.default = _default;
});