define("dashboard/components/org/third-party-oauth-toggle", ["exports", "jquery", "@ember/component", "@ember/object"], function (_exports, _jquery, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    gt,
    not
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['third-party-oauth-settings'],
    thirdPartyOAuthDisabled: alias('org.preference.disableThirdPartyOauth'),
    thirdPartyOAuthEnabled: not('thirdPartyOAuthDisabled'),
    modalDisplayed: false,
    modalButtonText: (0, _object.computed)('thirdPartyOAuthEnabled', function () {
      return `${this.thirdPartyOAuthEnabled ? 'Enable' : 'Disable'} Third-Party OAuth`;
    }),
    explicitOrgLabel: (0, _object.computed)('org.isEnterpriseOrg', 'org.name', function () {
      const label = `Team <code>${this.get('org.name')}</code>`;
      return this.get('org.isEnterpriseOrg') ? `Heroku Enterprise ${label}` : `Heroku ${label}`;
    }),
    toggleAction: (0, _object.computed)('thirdPartyOAuthEnabled', function () {
      return this.thirdPartyOAuthEnabled ? 'enabling' : 'disabling';
    }),
    toggleActionPerformed: (0, _object.computed)('thirdPartyOAuthEnabled', function () {
      return this.thirdPartyOAuthEnabled ? 'enabled' : 'disabled';
    }),
    hasAllowedAddonsList: gt('org.allowedAddons.length', 0),
    showAddonsWarning: (0, _object.computed)('hasAllowedAddonsList', 'thirdPartyOAuthEnabled', function () {
      return this.hasAllowedAddonsList && this.thirdPartyOAuthEnabled === false;
    }),
    actions: {
      closeAndScrollToAllowedAddonsList() {
        this.send('revertToggleThirdPartyOAuth');
        (0, _jquery.default)('#allowlist-table')[0].scrollIntoView();
      },

      hideModal() {
        this.set('modalDisplayed', false);
      },

      revertToggleThirdPartyOAuth() {
        this.toggleProperty('thirdPartyOAuthEnabled');
        this.send('hideModal');
      },

      confirmToggleThirdPartyOAuth() {
        this.set('org.preference.disableThirdPartyOauth', !this.thirdPartyOAuthEnabled);
        return this.get('org.preference.content').save().then(() => {
          this.send('hideModal');
        });
      }

    }
  });

  _exports.default = _default;
});