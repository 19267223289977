define("dashboard/routes/protected/assume", ["exports", "@ember/service", "@ember/routing/route"], function (_exports, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    current: (0, _service.inject)(),

    beforeModel() {
      // kick them out if they can't assume
      if (!this.get('current.account.isHerokai')) {
        this.transitionTo('apps');
      }
    }

  });

  _exports.default = _default;
});