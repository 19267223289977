define("dashboard/models/team/member", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "dashboard/mixins/auth-details", "ember-cp-validations", "dashboard/mixins/ds-error-monitoring-model", "@ember/object", "@ember/object/computed", "ember-concurrency", "@ember/object/proxy", "@ember/object/promise-proxy-mixin"], function (_exports, _model, _attr, _relationships, _authDetails, _emberCpValidations, _dsErrorMonitoringModel, _object, _computed, _emberConcurrency, _proxy, _promiseProxyMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    isDuplicated: (0, _emberCpValidations.validator)('exclusion', {
      in: [true],
      message: 'Already added'
    })
  });

  const IdentityProviderOwnerProxy = _proxy.default.extend(_promiseProxyMixin.default, {
    type: (0, _computed.readOnly)('content.constructor.modelName')
  });

  var _default = _model.default.extend(_authDetails.default, _dsErrorMonitoringModel.default, Validations, {
    apps: (0, _relationships.hasMany)('app'),
    team: (0, _relationships.belongsTo)('team'),
    user: (0, _attr.default)(),
    email: (0, _attr.default)('string'),
    federated: (0, _attr.default)('boolean'),
    role: (0, _attr.default)('string'),
    identityProvider: (0, _attr.default)(),
    twoFactorAuthentication: (0, _attr.default)('boolean'),
    unfederated: (0, _computed.not)('federated'),
    isAdmin: (0, _computed.equal)('role', 'admin'),
    isCollaborator: (0, _computed.equal)('role', 'collaborator'),
    isFederatedAgainstAccount: (0, _object.computed)('team.enterprise.id', 'identityProvider.owner.{type,id}', function () {
      return this.get('identityProvider.owner.type') === 'enterprise-account' && this.get('identityProvider.owner.id') === this.get('team.enterprise.id');
    }),
    isFederatedAgainstTeam: (0, _object.computed)('team.id', 'identityProvider.owner.{type,id}', function () {
      return this.get('identityProvider.owner.type') === 'team' && this.get('identityProvider.owner.id') === this.get('team.id');
    }),
    identityProviderOwner: (0, _object.computed)('identityProvider.owner.{type,id}', function () {
      const owner = this.get('identityProvider.owner');

      if (!owner) {
        return null;
      }

      const {
        type,
        id
      } = owner;
      const store = this.get('store');
      const promise = store.findRecord(type, id);
      return IdentityProviderOwnerProxy.create({
        promise
      });
    }),

    updateCollaborators(orgApps) {
      return this.updateCollaboratorsTask.perform(orgApps);
    },

    updateCollaboratorsTask: (0, _emberConcurrency.task)(function* (orgApps) {
      const memberApps = yield this.apps;
      const selectedApps = this.selectedApps;
      const selectedOrgApps = orgApps.filter(orgApp => selectedApps.includes(orgApp));
      const appsToAdd = selectedOrgApps.filter(app => memberApps.filterBy('name', app.get('name')).length === 0);
      const appsToDelete = memberApps.filter(app => !selectedOrgApps.includes(app));
      yield (0, _emberConcurrency.all)([...this._createAppCollaborators(appsToAdd, memberApps), ...this._deleteAppCollaborators(appsToDelete, memberApps)]);
    }),

    _createAppCollaborators(appsToAdd, memberApps) {
      return appsToAdd.map(app => {
        memberApps.pushObject(app);
        return this.store.createRecord('collaborator', {
          app,
          user: {
            email: this.email
          }
        }).save();
      });
    },

    _deleteAppCollaborators(appsToDelete, memberApps) {
      const email = this.email;
      return appsToDelete.map(app => app.get('collaborators').then(collaborators => collaborators.findBy('email', email)).then(collaborator => collaborator.destroyRecord()).then(() => memberApps.removeObject(app)));
    }

  });

  _exports.default = _default;
});