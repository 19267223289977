define("dashboard/templates/components/enterprise-account/usage-export-options", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ztBSabn8",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,\"input\"],[14,1,\"export-mode-monthly\"],[14,3,\"export-mode-monthly\"],[15,\"onclick\",[28,[37,0],[[30,0],[28,[37,1],[[30,0,[\"mode\"]]],null],\"monthly\"],null]],[15,\"checked\",[28,[37,2],[[30,0,[\"mode\"]],\"monthly\"],null]],[14,4,\"radio\"],[12],[13],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"export-mode-monthly\"],[14,0,\"hk-label normal\"],[12],[1,\"\\n\"],[41,[30,0,[\"isTeamExport\"]],[[[1,\"      Monthly team usage\\n\"]],[]],[[[1,\"      Monthly usage\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,2],[[30,0,[\"mode\"]],\"monthly\"],null],[[[1,\"    \"],[1,[28,[35,4],null,[[\"enterpriseAccount\",\"team\",\"displayLabel\"],[[30,0,[\"enterpriseAccount\"]],[30,0,[\"team\"]],false]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"bt b--silver mt3 pt3\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,1,\"export-mode-daily\"],[14,3,\"export-mode-daily\"],[15,\"onclick\",[28,[37,0],[[30,0],[28,[37,1],[[30,0,[\"mode\"]]],null],\"daily\"],null]],[15,\"checked\",[28,[37,2],[[30,0,[\"mode\"]],\"daily\"],null]],[14,4,\"radio\"],[12],[13],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"export-mode-daily\"],[14,0,\"hk-label normal\"],[12],[1,\"\\n\"],[41,[30,0,[\"isTeamExport\"]],[[[1,\"      Daily team usage over a custom time period (up to 31 days)\\n\"]],[]],[[[1,\"      Daily usage over a custom time period (up to 31 days)\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,2],[[30,0,[\"mode\"]],\"daily\"],null],[[[1,\"    \"],[1,[28,[35,5],null,[[\"enterpriseAccount\",\"team\"],[[30,0,[\"enterpriseAccount\"]],[30,0,[\"team\"]]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"action\",\"mut\",\"eq\",\"if\",\"enterprise-account/monthly-usage-export-form\",\"enterprise-account/daily-usage-export-form\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/usage-export-options.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});