define("dashboard/components/new/pipeline-select", ["exports", "rsvp", "@ember/component", "@ember/object", "@ember/service", "dashboard/helpers/lazy-property", "dashboard/utils/infinite-query"], function (_exports, _rsvp, _component, _object, _service, _lazyProperty, _infiniteQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _component.default.extend({
    query: '',
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    account: alias('current.account'),
    flashMessages: (0, _service.inject)(),
    classNames: ['pipeline-selector'],
    model: (0, _lazyProperty.default)(function () {
      return this._loadEverything();
    }),

    _loadEverything() {
      if (this.showAllPipelines) {
        return (0, _rsvp.hash)({
          pipelines: this.store.findAll('pipeline')
        });
      } else {
        return (0, _rsvp.hash)({
          pipelines: this.store.findAll('pipeline'),
          couplings: this._loadPipelineCouplings(),
          apps: this._loadApps()
        });
      }
    },

    _loadPipelineCouplings() {
      if (this.selectedOrg) {
        return (0, _infiniteQuery.default)(this.store, 'pipeline-coupling', {
          team: this.get('selectedOrg.id')
        }, {}, this.flashMessages);
      } else {
        return (0, _infiniteQuery.default)(this.store, 'pipeline-coupling', {
          personalOnly: true
        }, {}, this.flashMessages);
      }
    },

    _loadApps() {
      if (this.selectedOrg) {
        return this.store.query('app', {
          team: this.get('selectedOrg.id')
        }).then(apps => apps.toArray());
      } else {
        return this.store.query('app', {
          personalOnly: true
        }).then(apps => apps.toArray());
      }
    },

    // Forces `apps` lazyProperty to reload on selectedOrg change
    selectedOrgObserver: (0, _object.observer)('selectedOrg', function () {
      this.set('model', (0, _lazyProperty.default)(() => this._loadEverything()));
      this.notifyPropertyChange('model.apps');
    }),
    buttonContent: (0, _object.computed)('selectedPipeline', function () {
      const selection = this.selectedPipeline;

      if (selection === null) {
        return `Choose a pipeline`;
      } else if (selection === 'create') {
        return `Create new pipeline`;
      } else {
        return `${this.get('selectedPipeline.name')}`;
      }
    }),
    filteredPipelines: (0, _object.computed)('query', 'taggedPipelines.[]', function () {
      const pipelines = this.taggedPipelines;
      const query = this.query;

      if (query) {
        return pipelines.filter(pipeline => {
          return pipeline.get('name').toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      } else {
        return pipelines;
      }
    }),
    // These following properties are temporary stolen logic
    // from apps.index controller. This will all go away once we have
    // the endpoint that returns pipelines for a given owner
    relevantPipelines: (0, _object.computed)('model.apps.@each.{isInPipeline,pipelineCoupling}', 'showAllPipelines', 'isLoadingModel', function () {
      if (this.showAllPipelines) {
        return this.get('model.pipelines');
      }

      if (this.isLoadingModel) {
        return;
      }

      const apps = this.get('model.apps') || [];
      const pipelineApps = apps.filterBy('isInPipeline'); // We filter out null values here, because pipeline DELETEs don't actually
      // delete pipeline couplings on the front end (even though the backend does)
      // TODO: Manually remove pipeline couplings on pipeline delete

      return pipelineApps.map(pipelineApp => {
        return pipelineApp.get('pipelineCoupling.pipeline.content');
      }).filter(Boolean).uniqBy('id');
    }),
    // tags pipelines with isSelectionDisabled
    // if they belong to other owners
    taggedPipelines: (0, _object.computed)('model.pipelines', 'relevantPipelines.[]', 'selectedOrg', 'account.id', 'isLoadingModel', function () {
      if (this.isLoadingModel) {
        return;
      }

      const allPipelines = this.get('model.pipelines') || [];
      const relevantPipelines = this.relevantPipelines || [];
      return relevantPipelines.map(pipeline => {
        const isPersonal = !this.selectedOrg;
        const fullPipeline = allPipelines.findBy('id', pipeline.id);

        if (fullPipeline.get('isUserPipeline') && isPersonal) {
          // If personal owner selected, pipeline has an owner, and user is not the
          // owner, then disable the pipeline selection
          const isOwner = fullPipeline.get('ownerID') === this.get('account.id');

          if (!isOwner) {
            pipeline.set('isSelectionDisabled', true);
          }
        } else if (fullPipeline.get('isTeamPipeline') && !isPersonal) {
          // If team owner selected, pipeline owner is a user, or another team than what
          // user has selected, then disable the pipeline selection
          const isPipelineOwnerUser = fullPipeline.get('ownerUserType');
          const isSameTeamOwner = fullPipeline.get('ownerID') === this.get('selectedOrg.id');

          if (!isSameTeamOwner || isPipelineOwnerUser) {
            pipeline.set('isSelectionDisabled', true);
          }
        }

        return pipeline;
      });
    }),
    actions: {
      showPipelineSelectDropdown() {
        if (this.isShowingPipelineSelectDropdown) {
          this.makeSelection(null);
        }

        this.toggleProperty('isShowingPipelineSelectDropdown');
      },

      closePipelineSelectDropdown() {
        this.set('isShowingPipelineSelectDropdown', false);
      },

      makeSelection(selection) {
        if (selection === null && this.hidePipelineConfig) {
          this.hidePipelineConfig();
        }

        this.makeSelection(selection);
        this.set('isShowingPipelineSelectDropdown', false);
      }

    }
  });

  _exports.default = _default;
});