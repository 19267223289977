define("dashboard/components/favorite-chart", ["exports", "dashboard/components/metrics/charts/timeseries-chart"], function (_exports, _timeseriesChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a minimal {{timeseries-chart}} that is compact
   */
  var _default = _timeseriesChart.default.extend({
    classNames: ['metrics__chart--minimal'],
    attributeBindings: ['title'],
    height: 28,
    padding: {
      top: 0,
      right: 0,
      bottom: 1,
      left: 0
    },
    isShowingHeader: false,
    isNotSelectable: true,
    hideValues: true,
    isShowingLegend: false,
    isHidingXAxisLabels: true,
    isHidingChartBorder: true,
    reserveWhiteSpaceForChart: false
  });

  _exports.default = _default;
});