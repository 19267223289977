define("dashboard/templates/components/metrics/charts/line-chart-elements/activity-event-marker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "08zLmiTH",
    "block": "[[[10,\"line\"],[14,\"x1\",\"0\"],[14,\"x2\",\"0\"],[14,\"y1\",\"0\"],[15,\"y2\",[29,[[30,0,[\"height\"]]]]],[14,0,\"metrics__chart__event-indicator__gradient\"],[12],[13],[1,\"\\n\\n\"],[10,\"g\"],[14,0,\"metrics__chart__event__indicator__label\"],[15,\"transform\",[30,0,[\"textGroupTransform\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isShowingRect\"]],[[[1,\"    \"],[10,\"rect\"],[14,0,\"metrics__chart__event-indicator__label__background\"],[15,\"x\",[29,[[30,0,[\"rectX\"]]]]],[14,\"y\",\"2\"],[15,\"height\",[29,[[30,0,[\"rectHeight\"]]]]],[15,\"width\",[29,[[30,0,[\"rectWidth\"]]]]],[14,\"rx\",\"2\"],[14,\"ry\",\"2\"],[12],[13],[1,\"\\n    \"],[10,\"rect\"],[14,0,\"metrics__chart__event-indicator__label__text-background\"],[15,\"x\",[29,[[30,0,[\"rectX\"]]]]],[14,\"y\",\"2\"],[15,\"height\",[29,[[30,0,[\"rectHeight\"]]]]],[15,\"width\",[29,[[30,0,[\"rectWidth\"]]]]],[14,\"rx\",\"2\"],[14,\"ry\",\"2\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"text\"],[14,0,\"metrics__chart__event-indicator__label__text code\"],[14,\"y\",\"3\"],[12],[1,\"\\n    \"],[1,[30,0,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/line-chart-elements/activity-event-marker.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});