define("dashboard/serializers/team/resource-item", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      // After this goes live we'll update particleboard to send the right things and
      // remove this.
      hash.formation.forEach(formation => {
        formation.size = formation.name;
      });
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});