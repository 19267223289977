define("dashboard/components/net-promoter-score/rating", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    ratings: (0, _object.computed)(function () {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    }),
    actions: {
      selectRating(rating) {
        this.set('selectedRating', rating);
        this.get('transitionToNextStep')();
      }

    }
  });

  _exports.default = _default;
});