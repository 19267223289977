define("dashboard/utils/create-sudo-token", ["exports", "rsvp", "dashboard/config/environment", "jquery"], function (_exports, _rsvp, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createSudoToken;

  function createSudoToken(description, code, reason) {
    const promise = new _rsvp.Promise((resolve, reject) => {
      return _jquery.default.ajax({
        method: 'post',
        withHerokuAuthorization: true,
        url: `${_environment.default.platformApiUrl}/oauth/authorizations`,
        headers: {
          'X-Origin': window.location.origin,
          'Heroku-Two-Factor-Code': code,
          'X-Heroku-Sudo': true,
          'X-Heroku-Sudo-Reason': reason,
          Accept: 'application/vnd.heroku+json; version=3'
        },
        data: {
          description,
          skip_two_factor: true
        }
      }).then(response => {
        return resolve(response['access_token'].token);
      }).fail(reject);
    });
    return promise;
  }
});