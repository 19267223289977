define("dashboard/templates/components/production-check-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TmyeZjEi",
    "block": "[[[10,0],[14,0,\"check-header\"],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"label \",[30,0,[\"labelClass\"]]]]],[12],[1,[34,0]],[13],[1,\"\\n  \"],[10,1],[15,0,[29,[\"title \",[30,0,[\"titleClass\"]]]]],[12],[1,[30,0,[\"check\",\"title\"]]],[13],[1,\"\\n\\n  \"],[10,3],[15,6,[30,0,[\"check\",\"devCenterURL\"]]],[14,\"target\",\"_blank\"],[14,0,\"hk-link dev-center\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Learn more on Heroku Dev Center\",\"docs-16\",\"malibu-fill-gradient-gray\",16]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"check\",\"message\"]],[[[1,\"  \"],[10,0],[14,0,\"check-message\"],[12],[1,\"\\n    \"],[2,[30,0,[\"check\",\"message\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"status\",\"malibu-icon\",\"if\"]]",
    "moduleName": "dashboard/templates/components/production-check-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});