define("dashboard/models/otp-secret", ["exports", "@ember/object", "dashboard/utils/parse-query", "dashboard/mixins/ds-error-monitoring-model", "dashboard/mixins/password-encoding", "@ember-data/model"], function (_exports, _object, _parseQuery, _dsErrorMonitoringModel, _passwordEncoding, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_passwordEncoding.default, _dsErrorMonitoringModel.default, {
    url: (0, _model.attr)({
      defaultValue: ''
    }),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    otp: (0, _object.computed)('url', function () {
      const queryParams = (0, _parseQuery.default)(this.url);
      return queryParams.secret;
    }),

    saveWithPassword(password) {
      return this.save({
        adapterOptions: {
          password: this.decodePassword(password)
        }
      });
    }

  });

  _exports.default = _default;
});