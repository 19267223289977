define("dashboard/serializers/shogun/postgres-config", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(_typeClass, hash) {
      hash.log_connections = hash.log_connections.value;
      hash.log_lock_waits = hash.log_lock_waits.value;
      hash.log_min_duration_statement = hash.log_min_duration_statement.value;
      hash.log_statement = hash.log_statement.value;
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});