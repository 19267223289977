define("dashboard/mixins/metrics/metrics-query-params", ["exports", "@ember/object/computed", "@ember/object", "@ember/object/mixin", "dashboard/mixins/metrics/timeframe-options", "dashboard/utils/custom-computed", "dashboard/mixins/preferences"], function (_exports, _computed, _object, _mixin, _timeframeOptions, _customComputed, _preferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_timeframeOptions.default, _preferences.default, {
    latestTimeframeName: (0, _computed.alias)('dashboardUserPreferences.latestViewedTimeframeMetrics'),
    latestTimeframe: (0, _customComputed.findBy)('allOptions', 'name', 'latestTimeframeName'),
    latestTimeframeValues: (0, _object.computed)('latestTimeframe.beforeHours', 'latestTimeframe.afterHours', function () {
      return {
        ending: `${this.get('latestTimeframe.beforeHours')}-hours-ago`,
        starting: `${this.get('latestTimeframe.afterHours')}-hours-ago`
      };
    }),
    metricsQueryParams: (0, _object.computed)('latestTimeframe', function () {
      return this.latestTimeframeValues;
    })
  });

  _exports.default = _default;
});