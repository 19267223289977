define("dashboard/controllers/app/webhooks/new", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed"], function (_exports, _controller, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    queryParams: {
      success: {
        replace: true
      }
    },
    success: null,
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    webhook: (0, _computed.alias)('model.webhook'),
    actions: {
      finishCreate() {
        this.analytics.logEvent('Webhook', 'Created');
        this.set('success', true);
      },

      cancelCreate() {
        this.webhook.destroyRecord().then(() => {
          this.router.transitionTo('app.webhooks');
        });
      },

      closePanel() {
        this.router.transitionTo('app.webhooks');
      },

      viewDeliveries() {
        this.router.transitionTo('app.webhook-deliveries', this.get('webhook.name'));
      }

    }
  });

  _exports.default = _default;
});