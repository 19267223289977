define("dashboard/adapters/job", ["exports", "dashboard/adapters/json-api", "dashboard/config/environment"], function (_exports, _jsonApi, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${_environment.default.particleboardUrl}/apps/${appId}/jobs`;
    },

    urlForUpdateRecord(job_id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${_environment.default.particleboardUrl}/apps/${appId}/jobs/${job_id}`;
    },

    urlForDeleteRecord(job_id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app', {
        id: true
      });
      return `${_environment.default.particleboardUrl}/apps/${appId}/jobs/${job_id}`;
    }

  });

  _exports.default = _default;
});