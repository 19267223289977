define("dashboard/components/app-json-config-var-table", ["exports", "@ember/utils", "@ember/runloop", "@ember/object", "@ember/object/computed", "@ember/component", "dashboard/models/app-json-config-var"], function (_exports, _utils, _runloop, _object, _computed, _component, _appJsonConfigVar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isHidingHelp: (0, _computed.empty)('varsToWarnAbout'),
    keysToWarnAbout: (0, _computed.mapBy)('varsToWarnAbout', 'key'),
    lastConfigVar: (0, _computed.alias)('configVars.lastObject'),
    lastConfigVarIsNotEmpty: (0, _computed.alias)('configVars.lastObject.isNotEmpty'),
    varsToWarnAbout: (0, _object.computed)('configVars.@each.hasLiteralValue', 'configVars.@each.hasKey', function () {
      return this.configVars.filter(function (configVar) {
        return configVar.get('hasLiteralValue') && configVar.get('hasKey');
      });
    }),
    keysWarningText: (0, _object.computed)('keysToWarnAbout.[]', function () {
      const keys = this.keysToWarnAbout;
      const initial = keys.slice(0, -1);
      const last = keys[keys.length - 1];
      return initial.length ? `${initial.join(', ')} and ${last}` : last;
    }),

    addEmptyConfigVar() {
      this.configVars.pushObject(_appJsonConfigVar.default.create());
    },

    addAnotherConfigVar() {
      (0, _runloop.next)(this, function () {
        const lastConfigVar = this.lastConfigVar;

        if (this.configVars && ((0, _utils.isNone)(lastConfigVar) || this.lastConfigVarIsNotEmpty)) {
          this.addEmptyConfigVar();
        }
      });
    },

    _configVarObserver: (0, _object.observer)('lastConfigVarIsNotEmpty', function () {
      this.addAnotherConfigVar();
    }),

    init() {
      this._super(...arguments);

      this.addAnotherConfigVar();
    },

    actions: {
      removeConfigVar(configVar) {
        this.configVars.removeObject(configVar);
      }

    }
  });

  _exports.default = _default;
});