define("dashboard/components/pipeline/app-release", ["exports", "@ember/runloop", "ember", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/helpers/truncate", "moment"], function (_exports, _runloop, _ember, _component, _object, _computed, _service, _truncate, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex'],
    isVisible: (0, _computed.and)('isShowing', 'hasRelease'),
    store: (0, _service.inject)(),
    hasRelease: (0, _computed.notEmpty)('release'),
    isSuccessful: (0, _computed.reads)('release.isSuccess'),
    isUnsuccessful: (0, _computed.not)('isSuccessful'),
    modifiedAt: (0, _computed.or)('release.updatedAt', 'release.createdAt'),
    // Watch for changes to the release so we can update the visiblity state
    releaseChanged: (0, _object.observer)('release.status', 'release.id', function () {
      (0, _runloop.once)(this, 'setVisibility');
    }).on('init'),

    // Always show unsuccessful releases (pending, errored). Show succeeded
    // releases only for a few seconds after success.
    setVisibility() {
      if (this.isUnsuccessful) {
        this.set('isShowing', true);
        return;
      }

      const modified = (0, _moment.default)(this.modifiedAt);
      const recently = (0, _moment.default)().subtract(10, 'seconds');
      const isReleaseNew = modified.isAfter(recently);

      if (isReleaseNew) {
        this.set('isShowing', true);

        if (!_ember.default.testing) {
          // TODO - base visible off of current time or use task
          (0, _runloop.later)(this, this.hideRelease(this.get('release.id')), 10000);
        }
      } else {
        this.set('isShowing', false);
      }
    },

    // Hide a specific release, but only if it's still the displayed release.
    hideRelease(releaseId) {
      return () => {
        if (releaseId === this.get('release.id') && !this.isDestroyed) {
          this.set('isShowing', false);
        }
      };
    },

    /*
     * We're only interested in showing pending, failed, or recently
     * succeeded release statuses.
     */
    isShowing: false,
    releaseCommand: (0, _computed.reads)('release.slug.processTypes.release'),
    newReviewAppsNotEnabled: (0, _computed.not)('pipeline.hasNewReviewAppsEnabled'),
    isShowingFailedStatus: (0, _computed.and)('release.isFailed', 'newReviewAppsNotEnabled'),
    actions: {
      retry() {
        const lastVersion = this.get('release.version');
        const lastDescription = this.get('release.description');
        const truncatedDescription = (0, _truncate.truncate)([lastDescription, 40, '...']);
        return this.store.createRecord('release', {
          app: this.get('release.app'),
          description: `Retry v${lastVersion}: ${truncatedDescription}`,
          slug: this.get('release.slug')
        }).save();
      }

    }
  });

  _exports.default = _default;
});