define("dashboard/models/kafka-topic.eo", ["exports", "@ember/object", "ember-cp-validations", "@ember/object/computed"], function (_exports, _object, _emberCpValidations, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
       The retention/compaction relationship is somewhat complicated--
       there are essentially three different types of add-ons:
        * old single-tenant
          * compaction enabled => retention disabled
          * compaction disabled => retention required
        * new single-tenant with support for mixed retention/compaction
          * compaction enabled => retention supported, not required
          * compaction disabled => retention required
        * multi-tenant (with mixed support, retention always required)
          * compaction enabled => retention required
          * compaction disabled => retention required
       To support all these with a minimum of dynamic interface
       customization (which can confuse users, especially if they have
       multiple add-ons with different capabilities), we do the following:
        * always show the retention time form element
        * above the element, show a radio button toggle to enable
          or disable retention
        * when retention is disabled, disable and clear the form input
        * always force retention to 'enabled' for multi-tenant add-ons
        * force retention to 'disabled' for single-tenant add-ons that
          do *not* support mixed retention when compaction is enabled
        * force retention to 'enabled' when compaction is disabled
    */
  const KafkaTopicValidations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 254
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[a-zA-Z0-9\.\-\_]*$/,
      message: 'Only alphanumeric characters, periods, underscores, and hyphens.'
    })],
    replication_factor: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      gte: (0, _computed.readOnly)('model.limits.minimum_replication'),
      lte: (0, _computed.readOnly)('model.limits.maximum_replication'),
      integer: true,
      allowString: true
    })],
    partition_count: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      gte: 1,
      lte: (0, _computed.readOnly)('model.limits.max_number_of_partitions_per_topic'),
      integer: true,
      allowString: true,
      message: (0, _object.computed)('lte', function () {
        return `Topics may have between 1 and ${this.lte} partitions`;
      })
    })],
    retention_time_hrs: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: (0, _object.computed)('model.isRetentionRequired', function () {
        return !this.get('model.isRetentionRequired');
      })
    }), (0, _emberCpValidations.validator)('number', {
      integer: true,
      allowString: true,
      gte: (0, _object.computed)('model.limits.minimum_retention_ms', function () {
        return this.get('model.limits.minimum_retention_ms') / 3600000;
      }),
      lte: (0, _object.computed)('model.limits.maximum_retention_ms', function () {
        return this.get('model.limits.maximum_retention_ms') / 3600000;
      }),
      message: (0, _object.computed)('gte', 'lte', function () {
        return `Specify a retention time between ${this.gte} and ${this.lte} hours`;
      }),
      disabled: (0, _object.computed)('model.isRetentionRequired', 'model.isRetentionSupported', function () {
        return !this.get('model.isRetentionRequired') && !this.get('model.isRetentionSupported');
      })
    })]
  });

  var _default = _object.default.extend(KafkaTopicValidations, {
    limits: (0, _computed.readOnly)('datastore.limits'),
    capabilities: (0, _computed.readOnly)('datastore.capabilities'),
    defaults: (0, _computed.readOnly)('datastore.defaults'),
    sharedCluster: (0, _computed.readOnly)('datastore.sharedCluster'),
    isRetentionRequired: (0, _object.computed)('retention', 'compaction', 'sharedCluster', function () {
      // Always require retention when multi-tenant
      // or always require retention when compaction is disabled
      return this.get('sharedCluster') || !this.get('compaction');
    }),
    isRetentionSupported: (0, _object.computed)('isRetentionRequired', 'capabilities.supports_mixed_cleanup_policy', function () {
      if (this.isRetentionRequired) {
        return true;
      } else {
        return !this.get('sharedCluster') && this.get('compaction') && this.get('capabilities.supports_mixed_cleanup_policy');
      }
    })
  });

  _exports.default = _default;
});