define("dashboard/components/metrics/charts/row-chart-elements/event-indicator-row", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/charts/row-chart-elements/indicator-row", "dashboard/utils/rolledup-array"], function (_exports, _computed, _object, _indicatorRow, _rolledupArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a {{registered-chart-element}} that expects a somewhat rigid dataset and displays
   * values at a given time stacked on top of each other
   */
  var _default = _indicatorRow.default.extend({
    classNames: ['metrics__graph-row--stacked'],
    indicators: (0, _computed.readOnly)('rolledupModels'),
    processedModels: (0, _computed.map)('models', function (model) {
      if (model.get('precedesTimeframe')) {
        const precedingRollupTimestamp = this.minTime - this.rollupDuration;
        model.set('startedAt', precedingRollupTimestamp);
        model.set('precedesTimeframe', true);
      }

      return model;
    }),
    rolledupModels: (0, _rolledupArray.default)('processedModels', 'rollupDuration'),
    hasSufficientData: (0, _computed.gt)('rolledupModels.length', 0),
    selectedDatum: (0, _object.computed)('rolledupModels.[]', 'selectedRollupTime', function () {
      return this.rolledupModels.findBy('time', this.selectedRollupTime);
    })
  });

  _exports.default = _default;
});