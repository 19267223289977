define("dashboard/routes/protected/not-found", ["exports", "@ember/service", "@ember/routing/route", "dashboard/mixins/document-title"], function (_exports, _service, _route, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    reporter: (0, _service.inject)(),

    beforeModel(transition) {
      this._super.apply(this, arguments);

      const path = transition.to.queryParams.path;
      window.history.replaceState(null, null, path);
    },

    model(params) {
      return params.path;
    },

    afterModel(path) {
      this.get('reporter.rollbar').info('NotFound', {
        path
      });
    },

    documentTitle: 'Page not found',

    get fullDocumentTitle() {
      return `${this.documentTitle} | Heroku`;
    }

  });

  _exports.default = _default;
});