define("dashboard/templates/components/getting-started-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wIoz3Mqb",
    "block": "[[[10,3],[15,6,[30,0,[\"url\"]]],[15,0,[29,[\"hk-link db pv1br1 dark-gray hover-near-black hover-bg-lightest-silver tc \",[52,[51,[30,0,[\"isInDropdown\"]]],\"ph2\"]]]],[14,\"target\",\"_blank\"],[15,\"onclick\",[28,[37,1],[[30,0],[30,0,[\"logEvent\"]],[28,[37,2],[\"Dev Center language link: \",[30,0,[\"title\"]]],null],\"Clicked\"],null]],[12],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"language\",\"size\",\"iconClass\"],[[30,0,[\"icon\"]],\"28\",[30,0,[\"iconClass\"]]]]]],[1,\"\\n  \"],[41,[30,0,[\"title\"]],[[[10,1],[14,0,\"db f5 lh-copy pt--3\"],[12],[1,[30,0,[\"title\"]]],[13]],[]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"unless\",\"action\",\"concat\",\"buildpack-icon\",\"if\"]]",
    "moduleName": "dashboard/templates/components/getting-started-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});