define("dashboard/components/app/addons-provision-non-provisionable", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isRegionRestricted: (0, _computed.alias)('addonService.currentRegionIsNotSupported'),
    isAllowlistRestricted: (0, _computed.alias)('addonService.allowlistIsEnabledAndNotOnAllowlist'),
    regionName: (0, _computed.alias)('addonService.currentRegion.name'),
    organizationName: (0, _computed.alias)('app.team.name'),
    marketplaceUrl: (0, _computed.alias)('addonService.marketplaceUrl')
  });

  _exports.default = _default;
});