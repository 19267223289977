define("dashboard/controllers/team/space/apps", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "dashboard/utils/custom-computed", "dashboard/controllers/apps/index"], function (_exports, _computed, _object, _service, _customComputed, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _index.default.extend({
    currentSpace: (0, _computed.readOnly)('model.space'),
    spaceName: (0, _computed.readOnly)('model.space.name'),
    favoritesService: (0, _service.inject)('favorites'),
    favorites: (0, _computed.readOnly)('favoritesService.favorites'),
    apps: (0, _customComputed.dynamicFilterBy)('model.apps', 'spaceName', 'spaceName'),
    appSortAttribute: ['name:asc'],
    sortedApps: (0, _computed.sort)('apps', 'appSortAttribute'),
    appsWithFavorites: (0, _object.computed)('sortedApps.[]', 'favorites.[]', function () {
      const apps = this.sortedApps;
      return this.favoritesService.applyAppFavorites(apps);
    }),
    appFavorites: (0, _computed.filterBy)('appsWithFavorites', 'isFavorite', true),
    appNonFavorites: (0, _computed.filterBy)('appsWithFavorites', 'isFavorite', false)
  });

  _exports.default = _default;
});