define("dashboard/components/app/formation-editor", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _computed, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed; // this is a virtual Formation/Process object
  // that mirrors the main process's size, but has its
  // own quantity that is reached with the quantityKey

  const virtualProcess = function (quantityKey) {
    return (0, _object.computed)('process.size', quantityKey, function () {
      return this.store.createRecord('formation', {
        size: this.get('process.size'),
        quantity: this.get(quantityKey)
      });
    });
  };

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    classNames: ['editor', 'anim-fade-in'],
    formationScaleLimit: (0, _object.computed)('process.sizeObject', 'dynoLimit', function () {
      return this.get(`dynoLimit.${this.get('process.sizeObject.size')}`) || this.get('process.sizeObject.scale');
    }),

    /**
     * we're doing something somewhat strange here due to the fact
     * that the calculation of a given dyno size/quantity's cost is
     * tightly coupled to the Formation class's instance logic
     *
     * we create two "virtual" processes that match the size/quantity
     * of the min/max dyno range values
     *
     * this allows us to easily calculate cost/price with these objects
     */
    minPrice: readOnly('_minProcess.price'),
    maxPrice: readOnly('_maxProcess.price'),
    minUnits: readOnly('_minProcess.dynoUnits'),
    maxUnits: readOnly('_maxProcess.dynoUnits'),
    _minProcess: virtualProcess('autoscaleMonitor.minQuantity'),
    _maxProcess: virtualProcess('autoscaleMonitor.maxQuantity'),
    monitorIsDirty: readOnly('autoscaleMonitor.hasDirtyAttributes'),
    isWebDyno: (0, _computed.equal)('process.type', 'web'),
    showAutoscaleEditor: (0, _computed.and)('autoscaleMonitor.isActive', 'isWebDyno'),

    _dynoQuantityIsValid(value) {
      return Number.isInteger(value) && value <= this.formationScaleLimit && value >= 0;
    },

    _maxQuantityIsValid(value) {
      return Number.isInteger(value) && value > this.get('autoscaleMonitor.minQuantity') && value <= this.formationScaleLimit;
    },

    _minQuantityIsValid(value) {
      return Number.isInteger(value) && value < this.get('autoscaleMonitor.maxQuantity') && value > 0;
    },

    actions: {
      monitorValueChanged(event) {
        const min = this.latencyValueMin;
        const max = this.latencyValueMax;
        let value = parseInt(event.target.value); // here we enforce the min and max values
        // in the case that they are respectively below
        // or above the allowable values

        value = value < min ? min : value;
        value = value > max ? max : value;
        this.set('autoscaleMonitor.value', value);
        event.target.value = this.get('autoscaleMonitor.value');
      },

      updateDynoQuantity(event) {
        const quantityValue = parseInt(event.target.value);

        if (this._dynoQuantityIsValid(quantityValue)) {
          this.set('process.quantity', quantityValue);
        }
      },

      updateMinDynoQuantity(event) {
        const minValue = parseInt(event.target.value);

        if (this._minQuantityIsValid(minValue)) {
          this.set('autoscaleMonitor.minQuantity', minValue);
        }
      },

      updateMaxDynoQuantity(event) {
        const maxValue = parseInt(event.target.value);

        if (this._maxQuantityIsValid(maxValue)) {
          this.set('autoscaleMonitor.maxQuantity', maxValue);
        }
      },

      validateDynoQuantity(event) {
        const quantityValue = parseInt(event.target.value);

        if (!this._dynoQuantityIsValid(quantityValue)) {
          event.target.value = this.get('process.quantity');
        }
      },

      validateMaxQuantity(event) {
        const maxValue = parseInt(event.target.value);

        if (!this._maxQuantityIsValid(maxValue)) {
          event.target.value = this.get('autoscaleMonitor.maxQuantity');
        }
      },

      validateMinQuantity(event) {
        const minValue = parseInt(event.target.value);

        if (!this._minQuantityIsValid(minValue)) {
          event.target.value = this.get('autoscaleMonitor.minQuantity');
        }
      }

    }
  });

  _exports.default = _default;
});