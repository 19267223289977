define("dashboard/validations/webhook", ["exports", "ember-changeset-validations/validators", "purl"], function (_exports, _validators, _purl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [function (key, newValue, oldValue, changes, content) {
      // we don't want users to edit the name to a blank value
      // but blank is ok on create, however if they _do_ enter a name
      // we want to validate it
      const shouldValidateValue = newValue && newValue.length > 0;

      if (!content.get('isNew') || shouldValidateValue) {
        return (0, _validators.validatePresence)(true)(...arguments) && (0, _validators.validateLength)({
          min: 3
        })(...arguments);
      }

      return true;
    }],
    url: [function (key, newValue) {
      const value = (0, _purl.default)(newValue); // first enforce that the url is https

      if (value.attr('protocol') !== 'https') {
        return 'URL must use https';
      } else {
        return (0, _validators.validateFormat)({
          type: 'url',
          message: 'Must be a valid URL'
        })(...arguments);
      }
    }],
    include: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});