define("dashboard/templates/components/info-toggletip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yR1pawHp",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[24,0,\"bg-transparent hk-focus-ring--blue:focus cursor-hand br1 ba0 b--none pv0 ph--1 info-toggletip\"],[16,\"aria-expanded\",[29,[[30,0,[\"toggletipClicked\"]]]]],[17,1],[4,[38,0],[\"click\",[30,0,[\"clickToggletip\"]]],null],[4,[38,1],[[28,[37,2],[[30,0,[\"registerElement\"]],\"reference\"],null]],null],[4,[38,3],[[28,[37,2],[[30,0,[\"_cleanupToggletipsContainer\"]]],null]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"clip\"],[14,\"aria-live\",\"polite\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"toggletipClicked\"]]],[[[1,\"      More info\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[40,[[[1,\"    \"],[10,0],[14,\"role\",\"status\"],[14,0,\"z-max\"],[12],[1,\"\\n\"],[41,[30,0,[\"toggletipClicked\"]],[[[1,\"      \"],[11,0],[24,0,\"z-max\"],[4,[38,1],[[28,[37,2],[[30,0,[\"registerElement\"]],\"popper\"],null]],null],[4,[38,1],[[28,[37,7],[[30,0,[\"createToggletip\"]]],null]],null],[4,[38,3],[[28,[37,2],[[30,0,[\"_cleanupPopper\"]]],null]],null],[12],[1,\"\\n          \"],[10,0],[14,0,\"toggletip__inner white normal tc f6 pv--3 mw5 w-100 z-2\"],[12],[1,\"\\n            \"],[18,2,null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"clip\"],[12],[1,\"Click this button to hide this expanded definition.\"],[13],[1,\"\\n          \"],[10,0],[14,\"data-popper-arrow\",\"true\"],[14,0,\"toggletip__arrow\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"toggletipsContainer\"]],null],[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[8,[39,9],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"malibuIconName\"]],[30,0,[\"malibuIconClass\"]],[30,0,[\"malibuIconSize\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"on\",\"did-insert\",\"fn\",\"will-destroy\",\"unless\",\"in-element\",\"if\",\"perform\",\"yield\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/info-toggletip.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});