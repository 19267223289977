define("dashboard/components/sub-nav", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['nav', 'nav-tabs', 'sub-nav'],
    tagName: 'nav',
    attributeBindings: ['aria-label'],
    'aria-label': 'Sub-navigation menu'
  });

  _exports.default = _default;
});