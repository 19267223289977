define("dashboard/models/metrics/datastores/postgres", ["exports", "dashboard/models/metrics", "@ember/object"], function (_exports, _metrics, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;

  var _default = _metrics.default.extend({
    ioConnections: rawDataWithDefault('num_connections_max'),
    rawCommitData: rawDataWithDefault('transaction_activity_commit_max'),
    // The actual data we care about is the change in these numbers
    commits: (0, _object.computed)('rawCommitData', function () {
      const commits = this.rawCommitData;
      let max = 0;
      return commits.map((_ref, idx) => {
        let [time, commit] = _ref;
        let prev;
        let curr;

        if (idx === 0) {
          curr = commits[1][1];
          prev = commits[0][1];
        } else {
          curr = commit;
          prev = commits[idx - 1][1];
        }

        max = Math.max(max, prev);
        let delta;

        if (prev === 0 || curr === 0) {
          delta = 0;
        } else if (curr >= prev) {
          delta = curr - prev;
        } else {
          delta = curr + max - prev;
        }

        return [time, delta];
      });
    }),
    rawIndexCacheHitRate: rawDataWithDefault('index_cache_hit_rate_max'),
    rawTableCacheHitRate: rawDataWithDefault('table_cache_hit_rate_max'),
    tableCacheHitRate: (0, _object.computed)('rawTableCacheHitRate', function () {
      return this.rawTableCacheHitRate.map(_ref2 => {
        let [time, hit] = _ref2;
        return [time, Math.round(hit * 100)];
      });
    }),
    indexCacheHitRate: (0, _object.computed)('rawIndexCacheHitRate', function () {
      return this.rawIndexCacheHitRate.map(_ref3 => {
        let [time, hit] = _ref3;
        return [time, Math.round(hit * 100)];
      });
    })
  });

  _exports.default = _default;
});