define("dashboard/components/permission-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    iconTitle: (0, _object.computed)('model.name', 'model.selected', function () {
      return this.model.selected ? `${this.model.name} permission given` : `${this.model.name} permission denied`;
    })
  });

  _exports.default = _default;
});