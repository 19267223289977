define("dashboard/templates/components/stencil/overview/content-box", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RweBQcKO",
    "block": "[[[10,0],[14,0,\"header flex items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"stencil\"],[12],[1,\"\\n    lorem ipsum lorem ipsum\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"stencil\"],[12],[1,\"\\n    lorem ipsum lorem\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"purple-box stencil-box h4\"],[12],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/stencil/overview/content-box.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});