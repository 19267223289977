define("dashboard/templates/components/enter-sms-number", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hZl7eS4o",
    "block": "[[[11,\"form\"],[24,1,\"enter-sms\"],[4,[38,0],[[30,0],[28,[37,1],[[30,0,[\"saveNumber\"]]],null]],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-body text-center\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"\\n\"],[41,[30,0,[\"smsNumber\"]],[[[1,\"        Update your phone number (\"],[1,[30,0,[\"smsNumber\"]]],[1,\")\\n\"]],[]],[[[1,\"        Add a phone number to recover your account via SMS\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,2],[12],[1,\"\\n      \"],[41,[30,0,[\"smsNumber\"]],[[[1,\"Update\"]],[]],[[[1,\"Add\"]],[]]],[1,\" your phone number and\\n      we'll be able to send you a one time code by SMS that you'll be able to\\n      enter as a recovery code, should you need to recover your account.\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"sms-phone-number\"],[12],[1,\"Enter Phone Number\"],[13],[1,\"\\n      \"],[8,[39,3],[[24,0,\"form-control center-block\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,\"disabled\",[30,0,[\"saveNumber\",\"isRunning\"]]],[24,1,\"sms-phone-number\"],[24,4,\"tel\"]],[[\"@value\"],[[30,0,[\"newPhoneNumber\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"error\"]],[[[1,\"      \"],[10,2],[14,0,\"red\"],[12],[1,[30,0,[\"error\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n\"],[1,\"  \"],[18,1,null],[1,\"\\n\\n  \"],[1,[28,[35,5],null,[[\"form\",\"onClick\",\"default\"],[\"enter-sms\",[28,[37,0],[[30,0],[28,[37,1],[[30,0,[\"saveNumber\"]]],null]],null],\"Send a Verification SMS\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"action\",\"perform\",\"if\",\"input\",\"yield\",\"hk-button\"]]",
    "moduleName": "dashboard/templates/components/enter-sms-number.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});