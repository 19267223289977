define("dashboard/mixins/multi-step-flow", ["exports", "@ember/object/mixin", "@ember/object/computed"], function (_exports, _mixin, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    currentStep: 1,
    showStepOne: (0, _computed.equal)('currentStep', 1),
    showStepTwo: (0, _computed.equal)('currentStep', 2),
    showStepThree: (0, _computed.equal)('currentStep', 3),
    showStepFour: (0, _computed.equal)('currentStep', 4),
    showStepFive: (0, _computed.equal)('currentStep', 5),
    showStepSix: (0, _computed.equal)('currentStep', 6),

    transitionToStep(step) {
      this.set('currentStep', step);
    },

    transitionToNextStep() {
      this.transitionToStep(this.currentStep + 1);
    },

    actions: {
      transitionToStep(nextStep) {
        this.transitionToStep(nextStep);
      }

    }
  });

  _exports.default = _default;
});