define("dashboard/components/enterprise-account/monthly-usage-export-form", ["exports", "@ember/component", "moment", "@ember/object", "@ember/object/computed", "@ember/service", "dashboard/config/environment", "jquery"], function (_exports, _component, _moment, _object, _computed, _service, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Services
    analytics: (0, _service.inject)(),
    session: (0, _service.inject)(),
    store: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Arguments
    enterpriseAccount: null,
    team: null,
    displayLabel: true,
    // --------------------------------------------------------------------------
    // State
    startDate: null,
    endDate: null,
    // --------------------------------------------------------------------------
    // Computed Properties
    bearerToken: (0, _computed.readOnly)('session.data.authenticated.access_token'),
    isTeamExport: (0, _computed.notEmpty)('team'),
    itemCreatedAt: (0, _computed.or)('team.createdAt', 'oneYearAgo'),
    itemId: (0, _computed.or)('team.id', 'enterpriseAccount.id'),
    enterpriseAccountId: (0, _computed.readOnly)('enterpriseAccount.id'),
    teamId: (0, _computed.readOnly)('team.id'),
    oneYearAgo: (0, _object.computed)(function () {
      return _moment.default.utc().subtract(1, 'year');
    }),
    usageDownloadUrl: (0, _object.computed)('isTeamExport', 'enterpriseAccountId', 'teamId', function () {
      if (this.isTeamExport) {
        return `${_environment.default.particleboardUrl}/teams/${this.teamId}/usage/monthly`;
      } else {
        return `${_environment.default.particleboardUrl}/enterprise-accounts/${this.enterpriseAccountId}/usage/monthly`;
      }
    }),
    dateOptions: (0, _object.computed)('itemCreatedAt', function () {
      const cursor = _moment.default.utc().startOf('month');

      const endDate = _moment.default.utc(this.itemCreatedAt).startOf('month');

      const result = [];

      while (cursor.isSameOrAfter(endDate) && result.length < 13) {
        const value = cursor.format('YYYY-MM');
        let label = cursor.format('MMMM YYYY');

        if (result.length === 0) {
          label += ' (current period)';
        }

        result.pushObject({
          value,
          label
        });
        cursor.subtract(1, 'month');
      }

      return result;
    }),

    selectDates(startDate) {
      // Start and end dates are the same to get one month.
      this.set('startDate', startDate);
      this.set('endDate', startDate);
    },

    actions: {
      willDownloadUsage() {
        if (this.isTeamExport) {
          const analyticsNoun = this.team.analyticsNoun;
          this.analytics.logEvent(`${analyticsNoun} Monthly Usage`, 'Downloaded');
        } else {
          this.analytics.logEvent('Enterprise Account Monthly Usage', 'Downloaded');
        }

        const form = (0, _jquery.default)(this.element).find('form');
        return form.submit();
      }

    }
  });

  _exports.default = _default;
});