define("dashboard/components/metrics/charts/connected-chart-hover/item/config-var", ["exports", "dashboard/components/metrics/charts/connected-chart-hover/item", "dashboard/mixins/uses-config-var-description", "@ember/object/computed"], function (_exports, _item, _usesConfigVarDescription, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend(_usesConfigVarDescription.default, {
    userEmail: (0, _computed.readOnly)('model.user.email'),
    configVarsByGroup: (0, _computed.readOnly)('model.configVarsByGroup'),
    title: (0, _computed.readOnly)('configVarDescription'),
    description: (0, _computed.readOnly)('model.user.email'),
    isVisible: true
  });

  _exports.default = _default;
});