define("dashboard/adapters/github-pr", ["exports", "ember-data-github/adapters/github-pull"], function (_exports, _githubPull) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _githubPull.default.extend({
    handleResponse(status, headers, payload) {
      const link = headers.Link || '';
      const hasMore = link.indexOf('rel="next"') !== -1;
      payload.meta = {
        hasMore
      };
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});