define("dashboard/serializers/team/payment-method", ["exports", "dashboard/serializers/payment-method"], function (_exports, _paymentMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paymentMethod.default.extend({
    attrs: {
      card_last4: {
        serialize: false
      },
      card_type: {
        serialize: false
      },
      team: {
        serialize: false
      }
    },

    // TeamPaymentMethod does not have an ID that comes from the
    // API, so we use the team it was created with.
    normalizeSingleResponse() {
      const doc = this._super(...arguments);

      const {
        data
      } = doc; // Added by TeamPaymentAdapter

      data.id = data.attributes.team;
      return doc;
    }

  });

  _exports.default = _default;
});