define("dashboard/components/app-item-favorite-tray/metrics", ["exports", "@ember/component", "@ember/object", "@ember/service", "dashboard/mixins/metrics/metrics-query-params", "numeral", "dashboard/utils/metrics/data-fetch", "@ember/object/computed"], function (_exports, _component, _object, _service, _metricsQueryParams, _numeral, _dataFetch, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_metricsQueryParams.default, {
    classNameBindings: [':metrics__summary-row', ':metrics__summary-row--favorite', ':items-end', 'hasEnoughData::not-enough-data'],
    process: (0, _object.computed)('app.formations.[]', function () {
      const formations = this.get('app.formations') || [];
      return formations.findBy('type', 'web');
    }),
    isWeb: (0, _computed.alias)('process.isWeb'),
    dynoErrors: (0, _dataFetch.queryProcessData)('metrics/dyno-errors'),
    routerErrors: (0, _dataFetch.queryProcessData)('metrics/router-errors'),
    latency: (0, _dataFetch.queryProcessData)('metrics/router-latency', {}, 'process.isWeb'),
    dynoMemory: (0, _dataFetch.queryProcessData)('metrics/dyno-memory'),
    throughput: (0, _dataFetch.queryProcessData)('metrics/router-status', {}, 'process.isWeb'),
    _hasEnoughThroughputData: (0, _computed.gt)('throughput.dataAll.length', 1),
    _hasEnoughResponseData: (0, _computed.gt)('latency.service95Data.length', 1),
    hasEnoughData: (0, _computed.or)('_hasEnoughResponseData', '_hasEnoughThroughputData'),
    activeAlerts: (0, _computed.filterBy)('alerts', 'isActive', true),
    _hasActiveAlerts: (0, _computed.notEmpty)('activeAlerts'),
    isShowingActiveAlerts: (0, _computed.and)('_hasActiveAlerts', 'overviewPage'),
    analytics: (0, _service.inject)(),
    isVisible: (0, _computed.reads)('isLoaded'),
    numberOfErrors: (0, _object.computed)('dynoErrors.criticalErrorCount', 'routerErrors.criticalErrorCount', function () {
      return this.get('dynoErrors.criticalErrorCount') + this.get('routerErrors.criticalErrorCount');
    }),
    numberOfErrorsDisplay: (0, _object.computed)('numberOfErrors', function () {
      return (0, _numeral.default)(this.numberOfErrors).format('0,0.a');
    }),
    beforeHours: 0,
    afterHours: 24,
    actions: {
      logClick(chartType) {
        this.analytics.logMetricsEvent('Favorite Tray Chart', 'Clicked', {
          chartType
        });
      }

    }
  });

  _exports.default = _default;
});