define("dashboard/templates/components/pipeline/deploy-modal/title-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wUzrQzHB",
    "block": "[[[1,\"Deploy\\n\"],[8,[39,0],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"app-16\",\"malibu-fill-gradient-purple\",16,\"App\"]],null],[1,\"\\n\"],[1,[30,0,[\"app\",\"name\"]]]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/deploy-modal/title-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});