define("dashboard/templates/components/enterprise-account/member/confirm-delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "O9Ku+Ofv",
    "block": "[[[10,2],[14,0,\"ma0 pv3 ph4 red f5 lh-copy\"],[12],[1,\"\\n  Removing this user will disassociate them from all\\n  \"],[10,\"b\"],[12],[1,\"Apps\"],[13],[1,\", \"],[10,\"b\"],[12],[1,\"Pipelines\"],[13],[1,\", \"],[10,\"b\"],[12],[1,\"Spaces\"],[13],[1,\", \"],[10,\"b\"],[12],[1,\"Add-ons\"],[13],[1,\",\\n  \"],[10,\"b\"],[12],[1,\"Teams\"],[13],[1,\" and all other objects in this Enterprise Account.\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"flex pa4 pt0\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"onClick\",\"buttonType\",\"class\",\"default\"],[[28,[37,1],[[30,0],[30,0,[\"onCancel\"]]],null],\"secondary\",\"w-50 mr3\",\"Cancel\"]]]],[1,\"\\n\\n  \"],[1,[28,[35,0],null,[[\"onClick\",\"buttonType\",\"class\",\"default\"],[[28,[37,1],[[30,0],[30,0,[\"onConfirm\"]]],null],\"danger\",\"w-50\",\"Confirm removal\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"hk-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/member/confirm-delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});