define("dashboard/controllers/account/two-factor", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bool,
    not,
    alias
  } = _object.computed;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    otpSecret: alias('model.otpSecret'),
    credentials: (0, _object.computed)(function () {
      return _object.default.create({
        email: this.get('current.account.email'),
        password: null
      });
    }),
    hasPassword: bool('credentials.password'),
    needsPassword: not('hasPassword'),
    actions: {
      getPassword() {
        this.set('showPassword', true);
      },

      setPassword(password) {
        this.otpSecret.saveWithPassword(password).then(() => {
          this.set('credentials.password', password);
          this.send('hideSetPassword');
        });
      },

      hideSetPassword() {
        this.set('showPassword', false);
      },

      verify() {
        const account = this.get('current.account');
        account.setProperties({
          twoFactorAuthentication: true,
          mfaEnabled: true,
          password: this.get('credentials.password'),
          twoFactorCode: this.verificationCode
        });
        return account.save().then(() => {
          this.set('showRecoveryConfig', true);
        });
      },

      closeRecoveryConfig() {
        this.set('showRecoveryConfig', false);
      },

      recoveryConfigCompleted() {
        this.send('closeRecoveryConfig');
        this.transitionToRoute('account.index');
      }

    }
  });

  _exports.default = _default;
});