define("dashboard/templates/enterprise-account/overview/access", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Xa93NlL/",
    "block": "[[[1,[28,[35,0],null,[[\"enterpriseAccount\",\"user\",\"email\",\"permissions\",\"sso\",\"mfa\",\"page\"],[[30,0,[\"model\",\"enterpriseAccount\"]],[30,0,[\"user\"]],[30,0,[\"email\"]],[30,0,[\"permissions\"]],[30,0,[\"sso\"]],[30,0,[\"mfa\"]],[30,0,[\"page\"]]]]]],[1,\"\\n\"]],[],false,[\"enterprise-account/member-manager\"]]",
    "moduleName": "dashboard/templates/enterprise-account/overview/access.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});