define("dashboard/components/metrics/charts/visualizations/node-gc-pause", ["exports", "dashboard/components/metrics/charts/visualizations/gc-time-chart", "@ember/object/computed"], function (_exports, _gcTimeChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _gcTimeChart.default.extend({
    tagName: 'metrics-node-gc-chart',
    'data-test-target': 'metrics-node-chart',
    language: 'Node.js',
    devCenterName: 'language-runtime-metrics-nodejs#aggregate-time-spent-in-garbage-collection',
    isBeta: true,
    testTarget: 'node-gc-pause',
    hasSplitGenerations: true,
    lengthDataYoung: (0, _computed.alias)('data.nodePauseTimeYoung'),
    lengthDataOld: (0, _computed.alias)('data.nodePauseTimeOld'),
    lengthDataTotal: (0, _computed.alias)('data.nodePauseTimeTotal'),
    ratioDataYoung: (0, _computed.alias)('data.nodePauseTimeYoungRatio'),
    ratioDataOld: (0, _computed.alias)('data.nodePauseTimeOldRatio'),
    ratioDataTotal: (0, _computed.alias)('data.nodePauseTimeTotalRatio')
  });

  _exports.default = _default;
});