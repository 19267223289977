define("dashboard/components/info-tooltip", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/mixins/tooltipable"], function (_exports, _component, _object, _computed, _tooltipable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_tooltipable.default, {
    tagName: 'span',
    classNames: ['info-tooltip-component'],
    defaultIcon: 'info-badge-16',
    icon: (0, _computed.alias)('defaultIcon'),
    defaultIconClass: 'fill-gray cursor-pointer',
    iconClass: (0, _computed.alias)('defaultIconClass'),
    defaultSize: '16',
    size: (0, _computed.alias)('defaultSize'),
    attributeBindings: ['title', 'data-placement', 'title:aria-label'],
    tooltipOptions: (0, _object.computed)(function () {
      // setting the container to body makes the tooltip
      // more resilient when in special layouts
      return {
        container: 'body'
      };
    })
  });

  _exports.default = _default;
});