define("dashboard/routes/enterprise-account/overview/usage", ["exports", "@ember/routing/route", "@ember/service", "dashboard/mixins/document-title", "rsvp", "@ember/runloop"], function (_exports, _route, _service, _documentTitle, _rsvp, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),

    get documentTitle() {
      return this.modelFor('enterprise-account').enterpriseAccount.get('name');
    },

    model() {
      const {
        enterpriseAccount
      } = this.modelFor('enterprise-account');
      const currentLicenses = enterpriseAccount.hasMany('currentLicenses').reload().catch(() => []);
      const historicalLicenseUsage = enterpriseAccount.hasMany('historicalLicenseUsage').reload().catch(() => []);
      return (0, _rsvp.hash)({
        enterpriseAccount,
        currentLicenses,
        historicalLicenseUsage
      });
    },

    beforeModel(transition) {
      if (!this.get('current.enterprisePermissions.canViewUsage')) {
        const name = transition.params['enterprise-account'].name;
        this.transitionTo('enterprise-account.overview', name);
      }
    },

    actions: {
      didTransition() {
        _runloop.run.next(() => {
          this.analytics.logEvent('Enterprise Account Usage Page', 'Viewed');
        });

        return true;
      }

    }
  });

  _exports.default = _default;
});