define("dashboard/adapters/metrics/datastores/postgres", ["exports", "dashboard/adapters/metrics", "moment", "dashboard/config/environment"], function (_exports, _metrics, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend({
    host: _environment.default.dataUrl,

    queryRecord(store, type, query) {
      return this._super(...arguments).then(response => {
        response.queryParams = query;
        return response;
      });
    },

    urlForQuery(rawQuery) {
      const addonId = rawQuery.addonId;
      const query = this.normalizeQuery({
        start: rawQuery.startTime,
        end: rawQuery.endTime,
        step: rawQuery.step,
        filter: 'max'
      }); // encoded string of params
      // eslint-disable-next-line ember/no-jquery

      const param = $.param(query);
      return `${this.host}/metrics/${addonId}/postgres?${param}`;
    },

    normalizeQuery(query) {
      query = { ...query
      }; // convert start_time and end_time if present

      if (query.start) {
        query.start = (0, _moment.default)(query.start).toISOString();
      }

      if (query.end) {
        query.end = (0, _moment.default)(query.end).toISOString();
      } // convert all keys to underscored


      const underscoredQuery = Object.entries(query).reduce(function (underscored, _ref) {
        let [key, value] = _ref;
        // eslint-disable-next-line ember/no-string-prototype-extensions
        underscored[key.underscore()] = value;
        return underscored;
      }, {});
      return underscoredQuery;
    }

  });

  _exports.default = _default;
});