define("dashboard/templates/components/app/activity-release-rollback-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xmwYksm3",
    "block": "[[[10,0],[14,0,\"flex flex-column dark-gray lh-copy bt b--light-silver pa2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"f5\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"b\"],[12],[1,[30,0,[\"displayVersion\"]]],[13],[1,\" · \"],[1,[28,[35,0],null,[[\"datetime\"],[[30,0,[\"item\",\"createdAt\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"f5\"],[12],[1,\"\\n    \"],[10,1],[12],[1,[30,0,[\"userName\"]]],[1,\":\"],[13],[1,\"\\n    \"],[10,1],[12],[1,[30,0,[\"descriptionContent\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"time-ago\"]]",
    "moduleName": "dashboard/templates/components/app/activity-release-rollback-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});