define("dashboard/controllers/protected/terms-of-service", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed", "@ember/object"], function (_exports, _controller, _service, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    queryParams: ['showItalianTerms'],
    acceptedItalianCustomerTerms: (0, _object.computed)('current.account.italianCustomerTerms', function () {
      return this.current.account.italianCustomerTerms === 'affirmatively_accepted';
    }),
    acceptedItalianPartnerTerms: (0, _object.computed)('current.account.italianPartnerTerms', function () {
      return this.current.account.italianPartnerTerms === 'affirmatively_accepted';
    }),
    acceptButtonText: (0, _object.computed)('acceptedTerms', function () {
      return this.acceptedTerms ? 'Accepted' : 'Accept';
    }),
    acceptedAt: (0, _computed.readOnly)('current.account.acknowledgedMsaAt'),
    acceptedTerms: (0, _computed.bool)('current.account.acknowledgedMsa'),
    italianChecked: (0, _computed.bool)('showItalianTerms'),
    canSubmit: (0, _object.computed)('showItalianTerms', 'acceptedItalianCustomerTerms', 'acceptedItalianPartnerTerms', function () {
      if (this.acceptedTerms) {
        return false;
      }

      if (!this.showItalianTerms) {
        return true;
      }

      return this.acceptedItalianCustomerTerms && this.acceptedItalianPartnerTerms;
    }),
    cannotSubmit: (0, _computed.not)('canSubmit'),
    actions: {
      acceptTerms() {
        const currentAccount = this.current.account;
        const italianPartnerTerms = this.acceptedItalianPartnerTerms ? 'affirmatively_accepted' : 'non_applicable';
        const italianCustomerTerms = this.acceptedItalianCustomerTerms ? 'affirmatively_accepted' : 'non_applicable';
        currentAccount.set('acknowledgedMsa', true);
        currentAccount.set('italianCustomerTerms', italianCustomerTerms);
        currentAccount.set('italianPartnerTerms', italianPartnerTerms);
        currentAccount.save();
        this.transitionToRoute('protected');
      },

      toggleItalianTerms() {
        if (this.showItalianTerms === 'true') {
          this.set('showItalianTerms', null);
        } else {
          this.set('showItalianTerms', 'true');
        }
      }

    }
  });

  _exports.default = _default;
});