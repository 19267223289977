define("dashboard/components/range-slider/handle", ["exports", "@ember/string", "@ember/component", "@ember/object"], function (_exports, _string, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'range-slider-handle',
    attributeBindings: ['style', 'value'],
    _valueX: (0, _object.computed)('pixelRatio', 'value', function () {
      return this.pixelRatio * this.value;
    }),
    style: (0, _object.computed)('_valueX', function () {
      const x = this._valueX;
      return (0, _string.htmlSafe)(`transform: translate(${x}px, 0px)`);
    })
  });

  _exports.default = _default;
});