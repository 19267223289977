define("dashboard/components/app/formations-list-item-actions", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    equal,
    lt,
    and,
    or
  } = _object.computed;

  var _default = _component.default.extend({
    classNames: ['actions'],
    autoscalingButtonIsDisabled: lt('numberOfDynos', 1),
    isWeb: equal('process.type', 'web'),
    monitorsAreLoading: alias('process.monitors.isPending'),
    enableAutoscalingTitle: (0, _object.computed)('autoscalingButtonIsDisabled', function () {
      if (this.autoscalingButtonIsDisabled) {
        return 'Your process must have at least one dyno running to enable autoscaling.';
      }
    }),
    currentTierIsFree: equal('currentTier.name', 'free'),
    currentTierIsEco: equal('currentTier.name', 'eco'),
    shouldDisplayFreeDynosTooltip: alias('currentTierIsFree'),
    shouldDisplayEcoDynosTooltip: and('currentTierIsEco', 'isOwner', 'notSubscribedToEco'),
    shouldDisplayATooltip: or('shouldDisplayFreeDynosTooltip', 'shouldDisplayEcoDynosTooltip'),
    tooltipMessage: (0, _object.computed)('shouldDisplayFreeDynosTooltip', 'isOwner', function () {
      if (this.shouldDisplayFreeDynosTooltip && !this.isOwner) {
        return 'To scale your dynos ask the app owner to subscribe to Eco or you can upgrade to another paid plan';
      } else {
        return 'To scale your dynos, subscribe to Eco or upgrade to another paid plan';
      }
    }),
    actions: {
      edit() {
        this.set('isEditing', true);
        this.editAction();
      },

      cancel() {
        this.set('isEditing', false);
        this.cancelAction();
      },

      disabledAutoscaling() {
        this.disabledAutoscalingAction();
        this.send('edit');
      },

      enabledAutoscaling() {
        this.enabledAutoscalingAction();
        this.send('edit');
      }

    }
  });

  _exports.default = _default;
});