define("dashboard/routes/app/index", ["exports", "@ember/array", "@ember/service", "rsvp", "dashboard/routes/basic-app", "dashboard/models/dyno-tier", "dashboard/mixins/app-activity-websocket"], function (_exports, _array, _service, _rsvp, _basicApp, _dynoTier, _appActivityWebsocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend(_appActivityWebsocket.default, {
    analytics: (0, _service.inject)(),
    realTimeUpdater: (0, _service.inject)(),

    model() {
      const {
        app,
        appPermissions
      } = this.modelFor('app'); // The builds and releases use `store.query` so that we load more than the
      // default of 10 that's set in their adapters.

      return _rsvp.default.hash({
        app,
        appPermissions,
        team: app.get('team'),
        dynoTiers: _dynoTier.default.all(),
        formations: app.get('formations'),
        releases: this.store.query('release', {
          app: app.get('id')
        }).then(() => app.get('releases')),
        builds: this.store.query('build', {
          app: app.get('id')
        }).then(() => app.get('builds'))
      });
    },

    afterModel(model, transition) {
      this.analytics.logEvent('App Overview Page', 'Viewed');
      this.set('previousTransition', transition);
    },

    actions: {
      onAddonLoadError(error, skipProtectedError) {
        const errors = error.errors ? error.errors : [error.responseJSON || {}]; // either rest-model style or ember-data style

        const isTwoFactorError = (0, _array.makeArray)(errors).findBy('id', 'two_factor');

        if (isTwoFactorError) {
          // for some reason, the `model` for the `app` route gets set to the
          // rejected XHR upon calling transition.retry() in the protected error
          // route unless we call transition.abort(); here. ¯\_(ツ)_/¯
          // Store the app so the two-factor component can render it.
          error.app = this.modelFor('app').app;
          error.twoFactorRequired = true; // Store the transition on the error so we can retry it later.

          error.twoFactorNeededTransition = this.previousTransition;
        }

        if (!skipProtectedError) {
          this.intermediateTransitionTo('protected.error', error);
        }
      }

    }
  });

  _exports.default = _default;
});