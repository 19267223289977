define("dashboard/templates/pipelines/pipeline/index/app-setup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gbrYY0tk",
    "block": "[[[1,[28,[35,0],null,[[\"appSetup\",\"build\",\"hideAppSetupModal\"],[[30,0,[\"model\",\"appSetup\"]],[30,0,[\"model\",\"build\"]],[28,[37,1],[\"transitionToPipeline\"],null]]]]],[1,\"\\n\"]],[],false,[\"pipeline/review-app-modal\",\"route-action\"]]",
    "moduleName": "dashboard/templates/pipelines/pipeline/index/app-setup.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});