define("dashboard/routes/account/billing/pay-balance", ["exports", "rsvp", "dashboard/routes/account/base"], function (_exports, _rsvp, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    ignoreDelinquent: true,

    model() {
      return _rsvp.default.hash({
        paymentMethod: this.store.queryRecord('payment-method', {}),
        invoices: this.store.query('invoice', {})
      });
    },

    actions: {
      accountVerified() {
        this.controller.send('pay');
      }

    }
  });

  _exports.default = _default;
});