define("dashboard/components/app/settings/sni-endpoints-list-item", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    classNames: ['shadow-outer-1', 'br1', 'pa2', 'mv2', 'flex', 'items-center', 'f5', 'dark-gray'],
    classNameBindings: ['hasWarning:hk-message--warning', 'hasError:hk-message--danger'],
    hasWarning: (0, _object.computed)('endpoint.{isExpired,willExpireSoon}', function () {
      return this.endpoint.willExpireSoon && !this.endpoint.isExpired;
    }),
    hasError: (0, _object.computed)('endpoint.isExpired', function () {
      return this.endpoint.isExpired;
    }),
    endpointStatusIcon: (0, _object.computed)('hasWarning', 'hasError', function () {
      if (this.hasWarning) {
        return 'cert-warning';
      } else if (this.hasError) {
        return 'cert-error';
      } else {
        return 'cert-success';
      }
    })
  });

  _exports.default = _default;
});