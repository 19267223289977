define("dashboard/routes/org-invoice", ["exports", "@ember/service", "@ember/routing/route"], function (_exports, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    invoiceManager: (0, _service.inject)('invoice-manager'),

    redirect(params) {
      const month = params.month;
      const year = params.year;
      const org = params.org;
      this.invoiceManager.populate(year, month);
      this.replaceWith('team.switchable.billing', org);
    }

  });

  _exports.default = _default;
});