define("dashboard/models/metric", ["exports", "@ember/object/computed", "@ember/object", "dashboard/utils/custom-computed"], function (_exports, _computed, _object, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the base class for a specific set of Heroku metrics
   *
   * DEPRECATED
   *
   */
  var _default = _object.default.extend({
    points: (0, _computed.alias)('data.points'),
    _times: (0, _computed.mapBy)('points', 'time'),
    maxTime: (0, _customComputed.max)('_times'),
    minTime: (0, _customComputed.min)('_times'),
    hasData: (0, _computed.gt)('points.length', 0),
    hasSufficientData: (0, _computed.alias)('hasData'),
    hasInsufficientData: (0, _computed.not)('hasSufficientData'),

    /**
     * returns an array of 2D arrays of [x,y] (time, key value at time)
     * from a given array of points that contain a time
     * and a key in each of those items
     */
    _pluckKey(points, key) {
      return points.map(function (point) {
        return [point.time, point[key]];
      });
    }

  });

  _exports.default = _default;
});