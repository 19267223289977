define("dashboard/templates/components/ci/tests-menu/filter-button-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RUUlkQc1",
    "block": "[[[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"git-branch-16\",16,\"fill-gray ml0 mr1\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"hasSelectedBranch\"]],[[[1,\"  \"],[10,\"code\"],[12],[1,[30,0,[\"selectedBranch\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[12],[1,\"All branches\"],[13],[1,\"\\n\"]],[]]]],[],false,[\"malibu-icon\",\"if\"]]",
    "moduleName": "dashboard/templates/components/ci/tests-menu/filter-button-content.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});