define("dashboard/components/pipeline/pull-request", ["exports", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    classNames: ['pt1 pb1 ph1'],
    dataTestTarget: 'pipeline/pull-request',
    attributeBindings: ['dataTestTarget:data-test-target'],
    canCreateReviewApps: (0, _computed.reads)('pipeline.canCreateReviewApps'),
    isDisabled: (0, _object.computed)('isDuplicate', 'pullRequest.commitRepoId', function () {
      return this.isDuplicate || !this.pullRequest.commitRepoId;
    }),
    disabledTitle: (0, _object.computed)('isDuplicate', 'pullRequest.commitRepoId', 'canCreateReviewApps', function () {
      if (this.canCreateReviewApps) {
        if (this.isDuplicate) {
          return 'This pull request is a duplicate of a newer pull request.';
        } else if (!this.pullRequest.commitRepoId) {
          return 'This pull request is from a GitHub repository you either do not have access to or has been deleted. Please ask the owner to give you access to the GitHub repository or restore the repository.';
        }
      }
    }),
    actions: {
      createReviewApp() {
        return this.createReviewApp(this.pullRequest);
      }

    }
  });

  _exports.default = _default;
});