define("dashboard/templates/components/datastore/nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Zlo61xeR",
    "block": "[[[8,[39,0],[[24,0,\"datastore-nav\"]],[[\"@aria-label\"],[\"Datastore Navigation Menu\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@link\",\"@name\",\"@iconName\"],[\"app.datastore.index\",\"Overview\",\"overview-28\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isPostgresDatastore\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@addon\",\"@datastore\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isKafkaDatastore\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@addon\",\"@datastore\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isRedisDatastore\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@link\",\"@name\",\"@iconName\"],[\"app.datastore.settings\",\"Settings\",\"settings-28\"]],null],[1,\"\\n  \"]],[]],null]],[]]]],[]]]],[]]]]]],[\"@addon\",\"@datastore\"],false,[\"sub-nav\",\"sub-nav-item\",\"if\",\"datastore/postgres/nav\",\"datastore/kafka/nav\"]]",
    "moduleName": "dashboard/templates/components/datastore/nav.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});