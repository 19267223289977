define("dashboard/mixins/metrics/go-charts", ["exports", "@ember/object/mixin", "@ember/object", "@ember/object/computed", "dashboard/utils/metrics/receiving-language-data", "dashboard/utils/metrics/generate-chart-properties"], function (_exports, _mixin, _object, _computed, _receivingLanguageData, _generateChartProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    _receivingGoData: (0, _receivingLanguageData.default)('Go', 'go-metrics-set-up'),
    _cannotFetchGoMetrics: (0, _computed.not)('_receivingGoData'),
    _hasGoMetricsEnabled: (0, _computed.and)('app.hasRuntimeMetricsEnabled', 'app.hasGoMetricsEnabled'),
    needsToSetupGo: (0, _computed.and)('app.isEligibleForGoMetrics', '_hasGoMetricsEnabled', '_cannotFetchGoMetrics'),
    goMetricsAreSetUp: (0, _computed.and)('_hasGoMetricsEnabled', '_receivingGoData'),
    goGcPauseChart: (0, _generateChartProperties.default)('go-gc-pause', 'languageMemory'),
    goHeapMemoryChart: (0, _generateChartProperties.default)('go-heap-memory', 'languageMemory'),
    goStackMemoryChart: (0, _generateChartProperties.default)('go-stack-memory', 'languageMemory'),
    goObjectsChart: (0, _generateChartProperties.default)('go-heap-objects', 'languageMemory'),
    goRoutineCountChart: (0, _generateChartProperties.default)('go-routine-count', 'languageMemory'),
    goGcCollectionsChart: (0, _generateChartProperties.default)('go-gc-collections', 'languageMemory'),
    goChartsToShow: (0, _object.computed)('goGcPauseChart', 'goHeapMemoryChart', 'goStackMemoryChart', 'goObjectsChart', 'goRoutineCountChart', 'goGcCollectionsChart', function () {
      return [this.goGcPauseChart, this.goHeapMemoryChart, this.goStackMemoryChart, this.goObjectsChart, this.goRoutineCountChart, this.goGcCollectionsChart];
    })
  });

  _exports.default = _default;
});