define("dashboard/routes/account", ["exports", "dashboard/routes/account/base", "@ember/service"], function (_exports, _base, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    breadcrumbs: (0, _service.inject)(),

    renderTemplate() {
      this.set('breadcrumbs.trail', {
        location: 'Manage Account'
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav'
      });

      this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});