define("dashboard/components/ci/test-node-tab", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ICONS = {
    cancelled: 'failure-ring-16',
    succeeded: 'success-ring-16',
    failed: 'error-badge-16',
    errored: 'failure-badge-16'
  };
  const ICON_CLASS_NAMES = {
    cancelled: 'malibu-fill-gradient-dark-gray',
    succeeded: 'malibu-fill-gradient-green',
    failed: 'malibu-fill-gradient-red',
    errored: 'malibu-fill-gradient-red'
  };

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    index: (0, _computed.readOnly)('testNode.index'),
    tagName: '',
    buttonClass: (0, _object.computed)('isInDropdown', 'isSelected', function () {
      const CLASS_NAMES = {
        dropdown: {
          default: ['w-100', 'ph2', 'pv1', 'bg-white', 'bn', 'gray', 'hover-bg-lightest-silver'],
          selected: ['purple', 'active-tab']
        },
        mainTab: {
          default: ['flex-auto', 'pv2', 'ph3', 'outline-0', 'bt-0', 'br-0', 'bl-0', 'bb', 'bg-lightest-silver', 'b--silver', 'gray', 'hover-gray', 'hover-bg-white'],
          selected: ['bg-white', 'purple', 'b--white', 'active-tab', 'hover-purple']
        }
      };
      const key = this.isInDropdown ? 'dropdown' : 'mainTab';
      let buttonClasses = CLASS_NAMES[key]['default'];

      if (this.isSelected) {
        buttonClasses = buttonClasses.concat(CLASS_NAMES[key]['selected']);
      }

      return buttonClasses.join(' ');
    }),
    statusIconName: (0, _object.computed)('testNode.status', function () {
      return ICONS[this.get('testNode.status')];
    }),
    statusIconClass: (0, _object.computed)('testNode.status', function () {
      return `${ICON_CLASS_NAMES[this.get('testNode.status')]} nudge-up--1`;
    }),
    statusIconTitle: (0, _computed.readOnly)('testNode.status'),
    actions: {
      selectTestNode() {
        this.analytics.logEvent('Test Node Tab', 'Clicked');
        this.attrs.updateSelectedNode(this.testNode);
      }

    }
  });

  _exports.default = _default;
});