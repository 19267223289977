define("dashboard/components/dyno-tier-picker", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/service", "@ember/object/computed"], function (_exports, _component, _object, _utils, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['dyno-tier-picker'],
    classNameBindings: ['isNotEditing::is-editing'],
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),
    notSubscribedToEco: (0, _computed.not)('subscribedToEco'),
    // We are intentionally not including 'currentTier.isNotFree' as one of the
    // CP keys so that the free option doesn't immediately disappear after
    // the customer selects something else.
    primaryTiers: (0, _object.computed)('tiers.[]', 'team', 'subscribedToEco', function () {
      let tiers = this.tiers; // Free/Eco are not available for team applications.

      if ((0, _utils.isPresent)(this.team)) {
        tiers = tiers.filterBy('isEco', false); // Exception for if the current tier is already on free.

        if (this.get('currentTier.isNotFree')) {
          tiers = tiers.filterBy('isFree', false);
        }
      } // Swap hobby for basic when the basic plan is available.


      tiers = tiers.filterBy('isHobby', false); // Once free dynos are released, hide them entirely.

      tiers = tiers.filterBy('isFree', false); // Don't show private or shield dynos in the picker.

      return tiers.filterBy('isPrivate', false).filterBy('isShield', false);
    }),
    smallScreenSizeTiers: (0, _object.computed)('primaryTiers', 'subscribedToEco', 'currentTier', function () {
      let tiers = this.primaryTiers; // Only show the eco tier on small screen sizes if we are subscribed, or if we are already on eco.

      if (!this.subscribedToEco && !this.currentTier?.isEco) {
        tiers = tiers.filterBy('isEco', false);
      }

      return tiers;
    }),
    cannotChange: (0, _computed.not)('canChange'),
    webProcess: (0, _object.computed)('app.formations.[]', function () {
      const formations = this.get('app.formations') || [];
      return formations.findBy('type', 'web');
    }),
    monitors: (0, _computed.alias)('webProcess.monitors'),
    _activeMonitors: (0, _computed.filterBy)('monitors', 'isActive', true),
    isShowingDowngradeWarning: (0, _computed.notEmpty)('_activeMonitors'),

    didRenderElement() {
      this.analytics.logEvent('Dyno Tier Picker', 'Viewed');
    },

    actions: {
      selectTier(tier) {
        this.set('currentTier', tier);
      },

      trackPricingClick() {
        this.analytics.logEvent('Pricing Page Link', 'Clicked');
      }

    }
  });

  _exports.default = _default;
});