define("dashboard/components/app/datastore/new-credential-slide-panel", ["exports", "@glimmer/component", "ember-cp-validations", "@ember/object", "@ember/application", "@ember/array"], function (_exports, _component, _emberCpValidations, _object, _application, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 50
    }), (0, _emberCpValidations.validator)('exclusion', {
      in: (0, _object.computed)('model.existingCredentialNames', function () {
        const invalidNames = (0, _array.A)(this.get('model.existingCredentialNames'));
        invalidNames.push('none', 'public');
        return invalidNames;
      })
    }), (0, _emberCpValidations.validator)('inline', {
      validate(value) {
        return value.endsWith('-rotating') ? 'Name cannot end with "-rotating"' : true;
      }

    })]
  });

  const Credential = _object.default.extend(Validations, {
    name: null
  });

  class _default extends _component.default {
    constructor() {
      super(...arguments);
      this.newCredential = Credential.create((0, _application.getOwner)(this).ownerInjection(), {
        name: '',
        existingCredentialNames: this.args.existingCredentialNames
      });
    }

  }

  _exports.default = _default;
});