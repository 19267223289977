define("dashboard/components/file-drop", ["exports", "dashboard/components/draggable-target"], function (_exports, _draggableTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    The file-drop component extends the draggable-target component to override how data is pulled out of the event to pass along to the consuming component.
  
    This is because reading files dropped into the browser differs significantly
    from normal drag/drop behavior that occurs _within_ the browser.
  **/
  var _default = _draggableTarget.default.extend({
    classNames: ['file-drop'],

    drop(ev) {
      // needed to prevent browser from trying to download the file
      ev.preventDefault();
      this.attrs.onDrop(ev.dataTransfer);
      this.set('isDraggedOver', false);
    }

  });

  _exports.default = _default;
});