define("dashboard/templates/components/app-state-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "F2sYF1KZ",
    "block": "[[[41,[30,0,[\"isAlerting\"]],[[[1,\"  \"],[10,0],[14,0,\"absolute top-0 right-0 flex br-100 ba bw1 b--white bg-white z-2\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@size\",\"@name\",\"@iconClass\"],[[30,0,[\"alertIconSize\"]],\"alert-badge-16\",\"malibu-fill-gradient-red\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[8,[39,1],null,[[\"@size\",\"@name\",\"@iconClass\",\"@title\"],[[30,0,[\"iconSize\"]],[30,0,[\"iconName\"]],[30,0,[\"iconClass\"]],[30,0,[\"iconTitle\"]]]],null],[1,\"\\n\"]],[],false,[\"if\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/app-state-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});