define("dashboard/serializers/test-node", ["exports", "@ember/utils", "dashboard/serializers/application"], function (_exports, _utils, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: pushPayload is probably worth moving up to the ApplicationSerializer
  // Since it exists also in the pipeline serializer
  var _default = _application.default.extend({
    // Altered version of RESTSerializer's pushPayload
    // This allows us to manually push test-node payloads from the API into the store
    pushPayload(store, payload) {
      let documentHash = {};
      const type = store.modelFor('test-node');

      if ((0, _utils.typeOf)(payload) === 'object') {
        documentHash = this.normalize(type, payload);
      } else if (Array.isArray(payload)) {
        const ret = new Array(payload.length);

        for (let i = 0; i < payload.length; i++) {
          const data = payload[i];
          ret[i] = this.normalize(type, data);
        }

        documentHash.data = ret;
      }

      store.push(documentHash);
    },

    extractRelationships(modelClass, hash) {
      const relationships = this._super(...arguments);

      relationships.testRun = {
        data: {
          id: hash.test_run.id,
          type: 'test-run'
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});