define("dashboard/routes/account/applications", ["exports", "rsvp", "@ember/service", "dashboard/config/environment", "dashboard/routes/account/base"], function (_exports, _rsvp, _service, _environment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    analytics: (0, _service.inject)(),
    api: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    async model() {
      this.analytics.logEvent('Account Applications Page', 'Viewed');
      const isFederated = this.get('current.account.federated');
      const accountData = {
        authorizedUsers: this.fetchAuthorizedGithubUsers(),
        authorizations: this.store.findAll('authorization'),
        clients: isFederated ? [] : this.store.findAll('client')
      };
      return _rsvp.default.hash(accountData);
    },

    async fetchAuthorizedGithubUsers() {
      const adapter = this.store.adapterFor('repositories-api-github-user');
      let authorizedUserData = [];

      if (_environment.default.githubAppMultiUserAPIsAreActive === 'true') {
        authorizedUserData = await this.api.request(`/account/github/authorizations`, {
          adapter,
          headers: adapter.get('headers'),
          type: 'GET',
          contentType: 'application/json; charset=utf-8'
        });
      }

      const authorizedUsers = [];

      for (const authorizedGithubUser of authorizedUserData) {
        const githubUserRecord = await this.store.findRecord('repositories-api-github-user', authorizedGithubUser.github_user_id);
        githubUserRecord.installations = authorizedGithubUser.github_installations;
        authorizedUsers.push(githubUserRecord);
      }

      return authorizedUsers;
    }

  });

  _exports.default = _default;
});