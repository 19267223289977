define("dashboard/mixins/models/metrics/node-memory", ["exports", "@ember/object/mixin", "@ember/object", "dashboard/utils/metrics/unit-converters", "dashboard/models/metrics", "dashboard/utils/metrics/has-any-measured-data", "dashboard/utils/metrics/metrics-data-operators", "dashboard/utils/metrics/aggregate-values"], function (_exports, _mixin, _object, _unitConverters, _metrics, _hasAnyMeasuredData, _metricsDataOperators, _aggregateValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    rawDataWithDefault
  } = _metrics.default;

  var _default = _mixin.default.create({
    testDataKeysNode: (0, _object.computed)(function () {
      return ['nodePauseTimeYoungInNs', 'nodePauseTimeOldInNs', 'eventloopUsagePercentMin', 'eventloopUsagePercentMax', 'eventloopUsagePercentCount', 'eventloopUsagePercentSum', 'nodeCollectionsYoung', 'nodeCollectionsOld'];
    }),
    hasAnyMeasuredDataNode: (0, _hasAnyMeasuredData.default)('testDataKeysNode'),
    nodeCollectionsOld: rawDataWithDefault('c:node.gc.old.collections'),
    nodeCollectionsYoung: rawDataWithDefault('c:node.gc.young.collections'),
    nodeCollectionsTotal: (0, _metricsDataOperators.dataSum)('nodeCollectionsOld', 'nodeCollectionsYoung'),
    nodePauseTimeYoungInNs: rawDataWithDefault('c:node.gc.young.pause.ns'),
    nodePauseTimeYoung: (0, _unitConverters.convertToMs)('nodePauseTimeYoungInNs'),
    nodePauseTimeYoungRatio: (0, _metricsDataOperators.dataRatio)('nodePauseTimeYoungInNs', 'stepInNs'),
    nodePauseTimeOldInNs: rawDataWithDefault('c:node.gc.old.pause.ns'),
    nodePauseTimeOld: (0, _unitConverters.convertToMs)('nodePauseTimeOldInNs'),
    nodePauseTimeOldRatio: (0, _metricsDataOperators.dataRatio)('nodePauseTimeOldInNs', 'stepInNs'),
    nodePauseTimeTotal: (0, _metricsDataOperators.dataSum)('nodePauseTimeYoung', 'nodePauseTimeOld'),
    nodePauseTimeTotalRatio: (0, _metricsDataOperators.dataAverage)('nodePauseTimeYoungRatio', 'nodePauseTimeOldRatio'),
    eventloopUsageRatioMin: rawDataWithDefault('g:node.eventloop.usage.percent_min'),
    eventloopUsageRatioMax: rawDataWithDefault('g:node.eventloop.usage.percent_max'),
    eventloopUsageRatioCount: rawDataWithDefault('g:node.eventloop.usage.percent_count'),
    eventloopUsageRatioSum: rawDataWithDefault('g:node.eventloop.usage.percent_sum'),
    eventloopUsageRatioAvg: (0, _metricsDataOperators.dataQuotient)('eventloopUsageRatioSum', 'eventloopUsageRatioCount'),
    eventloopUsagePercentMin: (0, _unitConverters.convertRatioToPercentage)('eventloopUsageRatioMin'),
    eventloopUsagePercentMax: (0, _unitConverters.convertRatioToPercentage)('eventloopUsageRatioMax'),
    eventloopUsagePercentAvg: (0, _unitConverters.convertRatioToPercentage)('eventloopUsageRatioAvg'),
    _eventloopLatency50Raw: rawDataWithDefault('g:node.eventloop.delay.ms.median_max'),
    _eventloopLatency95Raw: rawDataWithDefault('g:node.eventloop.delay.ms.p95_max'),
    _eventloopLatency99Raw: rawDataWithDefault('g:node.eventloop.delay.ms.p99_max'),
    _eventloopLatencyMaxRaw: rawDataWithDefault('g:node.eventloop.delay.ms.max_max'),
    eventloopLatency50: (0, _metricsDataOperators.formatData)('_eventloopLatency50Raw'),
    eventloopLatency95: (0, _metricsDataOperators.formatData)('_eventloopLatency95Raw'),
    eventloopLatency99: (0, _metricsDataOperators.formatData)('_eventloopLatency99Raw'),
    eventloopLatencyMax: (0, _metricsDataOperators.formatData)('_eventloopLatencyMaxRaw'),
    medianP50: (0, _aggregateValues.medianValue)('eventloopLatency50'),
    minP95: (0, _aggregateValues.minValue)('eventloopLatency95'),
    maxP95: (0, _aggregateValues.maxValue)('eventloopLatency95')
  });

  _exports.default = _default;
});