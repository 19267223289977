define("dashboard/templates/components/app/datastore/credentials-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ISBQPJGd",
    "block": "[[[10,\"table\"],[14,0,\"w-auto table static-list purple-list\"],[12],[1,\"\\n  \"],[10,\"caption\"],[14,0,\"clip\"],[12],[1,\"Credentials List\"],[13],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"      \"],[8,[39,2],null,[[\"@credential\",\"@addonAttachments\",\"@viewCredential\"],[[30,2],[30,3],[30,4]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@credentials\",\"credential\",\"@addonAttachments\",\"@viewCredential\"],false,[\"each\",\"-track-array\",\"app/datastore/credential-row\"]]",
    "moduleName": "dashboard/templates/components/app/datastore/credentials-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});