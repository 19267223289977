define("dashboard/components/pipeline/kolkrabbi-pull-request", ["exports", "@ember/service", "@ember/component", "@ember/object", "moment", "@ember/object/computed"], function (_exports, _service, _component, _object, _moment, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'li',
    classNames: ['apps-item', 'list-group-item-link', 'pipeline-app', 'pr-app-pending', 'pt1 pb1 ph1'],
    dataTestTarget: 'pipeline/kolkrabbi-pull-request',
    attributeBindings: ['dataTestTarget:data-test-target'],
    store: (0, _service.inject)(),
    clock: (0, _service.inject)(),
    parent: (0, _computed.readOnly)('pipeline.reviewAppParent'),
    isSaving: (0, _computed.or)('prApp.isSaving', 'isCreatingApp'),
    foundPrApp: (0, _object.computed)('pipeline.kolkrabbiReviewApps.@each.number', 'pullRequest.number', function () {
      const reviewApps = this.get('pipeline.kolkrabbiReviewApps');
      return reviewApps.findBy('number', this.get('pullRequest.number'));
    }),
    newPrApp: (0, _object.computed)('pullRequest.title', 'pullRequest.number', 'parent', function () {
      return {
        pullRequest: {
          title: this.get('pullRequest.title'),
          number: this.get('pullRequest.number')
        },
        appSetup: {
          status: 'pending'
        },
        parentAppId: this.get('parent.app.id')
      };
    }),
    prApp: (0, _computed.or)('foundPrApp', 'newPrApp'),
    prAppFailed: (0, _computed.equal)('prApp.appSetup.status', 'failed'),
    prAppFailureMessage: (0, _computed.reads)('prApp.appSetup.failure_message'),
    autoDeploy: (0, _computed.reads)('parent.pull_requests.auto_deploy'),
    createdAtUnix: (0, _object.computed)('pullRequest.created_at', function () {
      return (0, _moment.default)(this.get('pullRequest.created_at')).unix();
    }),
    isNewPr: (0, _object.computed)('createdAtUnix', function () {
      const buildTime = 20;
      return this.createdAtUnix + buildTime > this.get('clock.unix');
    }),
    isProbablyDeploying: (0, _computed.and)('autoDeploy', 'isNewPr'),
    actions: {
      createReviewApp() {
        const prApp = this.store.createRecord('kolkrabbi/app', this.newPrApp);
        return prApp.save().then(() => {
          this.get('pipeline.kolkrabbiReviewApps').removeObject(this.foundPrApp);
          this.get('pipeline.kolkrabbiReviewApps').pushObject(prApp);
          this.set('isCreatingApp', true);
        }, () => this.pipeline.fetchGithubReviewApps()).then(appSetup => {
          this.set('appSetup', appSetup);
        });
      }

    }
  });

  _exports.default = _default;
});