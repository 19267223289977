define("dashboard/controllers/app/settings", ["exports", "@ember/object/computed", "@ember/object", "@ember/controller", "@ember/service", "dashboard/mixins/refresh-quick-jump", "ember-concurrency", "dashboard/utils/infinite-query"], function (_exports, _computed, _object, _controller, _service, _refreshQuickJump, _emberConcurrency, _infiniteQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _controller.default.extend(_refreshQuickJump.default, {
    analytics: (0, _service.inject)(),
    favoritesService: (0, _service.inject)('favorites'),
    store: (0, _service.inject)(),
    appController: (0, _controller.inject)('app'),
    configVars: (0, _object.computed)(function () {
      return {};
    }),
    app: alias('model.app'),
    appPermissions: alias('model.appPermissions'),
    cannotRenameApp: (0, _computed.not)('appPermissions.canRenameApp'),
    team: alias('model.team'),
    appCanUseSni: (0, _object.computed)('app.${isNotFreeTier,hasProcessTier}', 'app.isOwnedByOrg', function () {
      if (this.get('app.isOwnedByOrg')) {
        // assume all org apps have access to manage SSL
        return true;
      } else if (this.get('app.isNotFreeTier') && this.get('app.hasProcessTier')) {
        // personal apps that have dynos and are not on the free tier
        return true;
      }

      return false;
    }),
    customDomains: (0, _computed.filterBy)('domains', 'isCustom'),
    failedDomains: (0, _computed.filterBy)('customDomains', 'acmStatus', 'failed'),
    failingDomains: (0, _computed.filterBy)('customDomains', 'acmStatus', 'failing'),
    failedOrFailingDomains: (0, _computed.union)('failedDomains', 'failingDomains'),
    isShowingConfirmUpgrade: false,
    transferSectionDescription: (0, _object.computed)('model.team', function () {
      let link;

      if (this.get('model.team')) {
        link = 'https://devcenter.heroku.com/articles/create-manage-org#remove-apps';
      } else {
        link = 'https://devcenter.heroku.com/articles/transferring-apps';
      }

      return `Transfer this app to your personal account or a team you are a member of. <a href='${link}' class='hk-link' target='blank'>Learn more</a>`;
    }),
    isNotSpaceApp: (0, _computed.empty)('app.space.id'),
    transferSectionEnabled: (0, _computed.and)('isNotSpaceApp', 'appPermissions.canTransferApp'),
    transferSectionDisabledMessage: (0, _object.computed)('isSpaceApp', 'appPermissions.canTransferApp', function () {
      let message = '';

      if (!this.isNotSpaceApp) {
        message = 'Transferring apps is not supported in Spaces.';
      } else if (!this.get('appPermissions.canTransferApp')) {
        message = this.lackOfPermissionMessage('transfer');
      }

      return message;
    }),
    deletionLackOfPermissionMessage: (0, _object.computed)('team', function () {
      return this.lackOfPermissionMessage('delete');
    }),
    renameLackOfPermissionMessage: (0, _object.computed)('team', function () {
      return this.lackOfPermissionMessage('rename');
    }),
    loadAllDomains: (0, _emberConcurrency.task)(function* () {
      const app = this.get('app');
      const domains = yield (0, _infiniteQuery.default)(this.store, 'domain', {
        appId: app.get('id')
      }, {}, this.flashMessages);
      this.set('domains', domains);
    }),

    lackOfPermissionMessage(action) {
      if (this.team) {
        return `This app is owned by ${this.get('team.name')}. Only an admin or a user with manage permissions on this app can ${action} it.`;
      }

      return `Only the owner, ${this.get('app.owner.email')}, can ${action} this app.`;
    },

    async deleteApp() {
      // We are deleting the app here, so we need to stash its data up front
      // so that we can use it after the record has been destroyed
      const app = this.app;
      const appId = app.id;
      const appIsOwnedByTeam = app.isOwnedByTeam;
      const appHasSpace = app.space;
      const teamName = app.get('team.name');
      const spaceName = app.get('space.name');
      const pendingDelete = app.destroyRecord();
      return pendingDelete.then(() => {
        const favorite = this.favoritesService.favoriteFor('app', appId);

        if (favorite) {
          favorite.destroyRecord().catch(() => {});
        }

        this.analytics.logEvent('App', 'Deleted');

        if (appIsOwnedByTeam) {
          if (appHasSpace) {
            this.transitionToRoute('team.space', teamName, spaceName);
          } else {
            this.transitionToRoute('team.switchable', teamName);
          }
        } else {
          this.transitionToRoute('apps');
        }
      });
    },

    actions: {
      save(newName) {
        const app = this.app;
        app.set('newName', newName);
        return app.save().then(() => {
          this.analytics.logEvent('App', 'Renamed'); // We need to refresh the list of domains associated with the app after it is renamed

          this.get('app.domains').reload();
          this.replaceRoute('app.settings', app.get('name'));
        });
      },

      async delete() {
        const pipelineCoupling = this.get('app.pipelineCoupling');
        const couplingContent = this.get('app.pipelineCoupling.content');

        if (couplingContent) {
          const pipeline = pipelineCoupling.get('pipeline');
          const couplingLink = couplingContent.get('githubAppLink.content');

          try {
            await couplingContent.destroyRecord();
            this.analytics.logEvent('Pipeline Coupling', 'Deleted', {
              status: 'succeeded'
            });

            if (pipeline.get('reviewAppParent') === couplingLink) {
              couplingLink.unloadRecord();
            }
          } catch (error) {
            this.analytics.logEvent('Pipeline Coupling', 'Deleted', {
              status: 'failed'
            });
            throw error;
          }
        }

        await this.deleteApp();
      },

      loadConfigVars() {
        return this.app.belongsTo('configVars').reload().then(configVars => {
          this.set('configVars', configVars);
        });
      },

      showConfirmUpgrade() {
        this.set('isShowingConfirmUpgrade', true);
      },

      hideConfirmUpgrade() {
        this.set('isShowingConfirmUpgrade', false);
      },

      upgradeAppStack() {
        const pendingUpgrade = this.app.upgradeToLatestStack();
        return pendingUpgrade.then(() => {
          this.analytics.logEvent('App Stack', 'Upgraded');
          this.send('hideConfirmUpgrade');
        });
      },

      cancelAppStackUpgrade() {
        const pendingCancellation = this.app.cancelStackUpgrade();
        return pendingCancellation.then(() => {
          this.analytics.logEvent('App Stack Upgrade', 'Cancelled');
        });
      }

    }
  });

  _exports.default = _default;
});