define("dashboard/components/space/network/peering-info", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    dynoCidrBlocks: (0, _object.computed)('peeringInfo.dynoCidrBlocks.[]', function () {
      if (this.get('peeringInfo.dynoCidrBlocks.[]')) {
        return this.get('peeringInfo.dynoCidrBlocks.[]').map(function (block) {
          return block;
        }).join(', ');
      }
    })
  });

  _exports.default = _default;
});