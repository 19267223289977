define("dashboard/components/app/settings/transfer-ownership", ["exports", "@ember/object/computed", "rsvp", "@ember/component", "@ember/object", "@ember/service", "@ember/utils", "dashboard/helpers/lazy-property", "dashboard/mixins/pipeline-ownership"], function (_exports, _computed, _rsvp, _component, _object, _service, _utils, _lazyProperty, _pipelineOwnership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_pipelineOwnership.default, {
    'data-test-target': 'transfer-ownership',
    accountFeatures: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    current: (0, _service.inject)(),
    account: (0, _computed.alias)('current.account'),
    pipeline: (0, _lazyProperty.default)(function () {
      const pipelinePromise = this.get('app.pipelineCoupling.pipeline');

      if ((0, _utils.isNone)(pipelinePromise)) {
        return;
      }

      return pipelinePromise.then(pipeline => pipeline.reload());
    }),
    disabled: (0, _object.computed)('recipientIdentifier', 'persistedIdentifier', 'isLoadingPipeline', function () {
      const recipientIdentifier = this.recipientIdentifier;
      const persistedIdentifier = this.persistedIdentifier;
      const isLoadingPipeline = this.isLoadingPipeline;
      return (0, _utils.isEmpty)(recipientIdentifier) || isLoadingPipeline || recipientIdentifier === persistedIdentifier;
    }),
    recipient: (0, _computed.oneWay)('ownerOption'),
    recipientIdentifier: (0, _object.computed)('recipient', 'persistedIdentifier', function () {
      return this.get('recipient.value') || this.persistedIdentifier;
    }),
    appIsPersonal: (0, _computed.not)('app.isOwnedByTeam'),
    isShowingOwnedPipelineWarning: (0, _object.computed)('disabled', 'pipeline.owner.id', 'persistedIdentifier', function () {
      return !this.disabled && (0, _utils.isPresent)(this.get('pipeline.owner.id')) && this.get('pipeline.owner.id') !== this.recipientIdentifier;
    }),
    persistedIdentifier: (0, _computed.reads)('app.owner.id'),
    persistedLabel: (0, _object.computed)('appIsPersonal', 'app.owner.email', 'app.team.name', 'account.{email,name}', function () {
      if (!this.appIsPersonal) {
        return this.get('app.team.name');
      } else {
        const ownerEmail = this.get('app.owner.email');
        const accountEmail = this.get('account.email');
        const accountName = this.get('account.name');

        if (ownerEmail === accountEmail && (0, _utils.isPresent)(accountName)) {
          return `${accountName} (${accountEmail})`;
        } else {
          return ownerEmail;
        }
      }
    }),
    ownerTier: (0, _object.computed)('app.processTier', function () {
      // TODO EFP what to default to? eco or basic? Does it matter?
      return this.get('app.processTier') || 'eco';
    }),
    recipientTier: (0, _object.computed)('recipientIsTeam', 'recipientIsEnterpriseTeam', 'ownerTier', function () {
      if (this.recipientIsTeam) {
        return 'basic';
      } else if (this.recipientIsEnterpriseTeam) {
        return 'production';
      } else {
        // Personal/team to personal should stay the same tier.
        return this.ownerTier;
      }
    }),
    recipientTeam: (0, _object.computed)('recipientIdentifier', function () {
      return this.teams.findBy('id', this.recipientIdentifier);
    }),
    collabUsers: (0, _computed.mapBy)('collaborators', 'user'),
    memberUsers: (0, _computed.mapBy)('teamMembers', 'user'),
    users: (0, _computed.union)('memberUsers', 'collabUsers'),
    recipientUser: (0, _object.computed)('recipientIdentifier', 'users.@each.id', function () {
      return this.users.findBy('id', this.recipientIdentifier);
    }),
    recipientIsEnterpriseTeam: (0, _computed.bool)('recipientTeam.isEnterpriseTeam'),
    recipientIsTeam: (0, _object.computed)('recipientTeam', function () {
      return this.recipientTeam && !this.get('recipientTeam.isEnterpriseTeam');
    }),
    hasFormations: (0, _computed.gt)('app.formations.length', 0),
    dynoTierNeedsUpdate: (0, _object.computed)('ownerTier', 'recipientTier', function () {
      const currentTier = this.ownerTier;
      const recipientTier = this.recipientTier;

      if (currentTier.match(/free|eco/) && recipientTier.match(/hobby|basic|production/)) {
        return true;
      } else if (currentTier.match(/hobby|basic/) && recipientTier === 'production') {
        return true;
      } else {
        return false;
      }
    }),
    needsUpdate: (0, _computed.and)('hasFormations', 'dynoTierNeedsUpdate'),
    teamType: (0, _object.computed)('recipientIsEnterpriseTeam', 'recipientTeam', function () {
      return 'team';
    }),
    pendingTransferRequests: (0, _object.computed)('transferRequests.@each.state', 'app.id', function () {
      return this.transferRequests.filterBy('app.id', this.get('app.id')).filterBy('state', 'pending');
    }),
    pendingTransfer: (0, _computed.alias)('pendingTransferRequests.firstObject'),
    showCancel: (0, _computed.equal)('pendingTransfer.state', 'pending'),
    selectContent: (0, _object.computed)('ownerOption', 'personnelOptions', 'teamOptions', 'enterpriseTeamOptions', 'app.isOwnedByTeam', 'enterpriseAccounts', 'groupByEnterpriseAccounts', function () {
      const personnelOptions = this.personnelOptions;
      const teamOptions = this.teamOptions;
      const enterpriseTeamOptions = this.enterpriseTeamOptions;
      const personalLabel = this.get('app.isOwnedByTeam') ? 'Team members' : 'Collaborators'; // massage our data into a format we can consume in our select

      const selectContent = [];
      selectContent.push({
        groupName: 'Current owner',
        options: [this.ownerOption]
      });

      if ((0, _utils.isPresent)(personnelOptions)) {
        selectContent.push({
          groupName: personalLabel,
          options: personnelOptions
        });
      }

      if (this.groupByEnterpriseAccounts) {
        // Group enterprise teams under an enterprise account heading.
        const enterpriseAccounts = this.enterpriseAccounts;
        enterpriseAccounts.forEach(account => {
          const teams = enterpriseTeamOptions.filterBy('enterpriseAccountId', account.id);

          if ((0, _utils.isPresent)(teams)) {
            selectContent.push({
              groupName: account.name,
              options: teams
            });
          }
        }); // Legacy enterprise teams, which have no account associated with them.

        const legacyTeams = enterpriseTeamOptions.filterBy('enterpriseAccountId', null);

        if ((0, _utils.isPresent)(legacyTeams)) {
          selectContent.push({
            groupName: 'Enterprise teams',
            options: legacyTeams
          });
        }
      } else if ((0, _utils.isPresent)(enterpriseTeamOptions)) {
        // Group all enterprise teams under one generic heading.
        selectContent.push({
          groupName: 'Enterprise teams',
          options: enterpriseTeamOptions
        });
      }

      if ((0, _utils.isPresent)(teamOptions)) {
        selectContent.push({
          groupName: 'Teams',
          options: teamOptions
        });
      }

      return selectContent;
    }),
    ownerOption: (0, _object.computed)('persistedIdentifier', 'persistedLabel', function () {
      return {
        value: this.persistedIdentifier,
        label: this.persistedLabel
      };
    }),
    personnelOptions: (0, _object.computed)('transferableUsers.[]', function () {
      return this.transferableUsers.map(user => {
        return {
          value: (0, _object.get)(user, 'id'),
          label: (0, _object.get)(user, 'email')
        };
      });
    }),
    teamOptions: (0, _object.computed)('transferableTeams.@each.isEnterpriseTeam', function () {
      return this.transferableTeams.rejectBy('isEnterpriseTeam').map(team => {
        return {
          value: team.get('id'),
          label: team.get('name')
        };
      });
    }),
    enterpriseTeamOptions: (0, _object.computed)('transferableTeams.@each.isEnterpriseTeam', function () {
      return this.transferableTeams.filterBy('isEnterpriseTeam').map(team => {
        return {
          value: team.get('id'),
          label: team.get('name'),
          enterpriseAccountId: team.get('enterprise.id') || null
        };
      });
    }),
    transferableTeams: (0, _object.computed)('teams.@each.canCreateApp', 'app.team.name', function () {
      return this.teams.filterBy('canCreateApp', true).rejectBy('id', this.get('app.team.id')).sortBy('name');
    }),
    transferableUsers: (0, _object.computed)('collaborators.@each.user', 'app.owner.email', 'app.isOwnedByTeam', 'teamMembers.[]', function () {
      if (this.get('app.isOwnedByTeam') && this.canTransferApp) {
        return this.teamMembers.rejectBy('role', 'collaborator').sortBy('email').mapBy('user');
      } else {
        return this.collaborators.rejectBy('email', this.get('app.owner.email')).mapBy('user');
      }
    }),

    // App transfers from personal to team or viceversa
    teamAppTransfer() {
      const recipientTeamName = this.get('recipientTeam.name');
      const recipientEmail = recipientTeamName ? `${recipientTeamName}@herokumanager.com` : this.get('recipientUser.email');
      const newTier = this.recipientTier;
      const needsToBeUpdated = this.needsUpdate;
      const dynoTypeUpdated = needsToBeUpdated ? this.app.updateDynoTier(newTier) : (0, _rsvp.resolve)();
      return dynoTypeUpdated.then(() => this.app.transferToTeam(recipientEmail)).then(() => {
        this.analytics.logEvent('App', 'Transferred'); // refresh the app route so we have the proper permissions in this new context

        this.refreshRoute();
      });
    },

    // app transfers between personal accounts
    personalAppTransfer() {
      const newTransfer = this.store.createRecord('app-transfer', {
        app: {
          id: this.get('app.id'),
          name: this.get('app.name')
        },
        state: 'pending',
        recipient: {
          email: this.get('recipientUser.email')
        },
        recipientUser: this.get('recipientUser.email')
      });
      return newTransfer.save({
        adapterOptions: {
          appId: this.get('app.id')
        }
      }).then(() => {
        this.analytics.logEvent('Personal App', 'Transferred');
        this.transferRequests.addObject(newTransfer);
        this.closeConfirmationModal();
      });
    },

    closeConfirmationModal() {
      this.set('confirmingTransfer', false);
    },

    actions: {
      createTransfer() {
        const team = this.recipientTeam;

        if (team || this.get('app.isOwnedByTeam')) {
          return this.teamAppTransfer();
        } else {
          return this.personalAppTransfer();
        }
      },

      cancelTransfer() {
        const transfer = this.pendingTransfer;
        transfer.destroyRecord().then(() => {
          this.analytics.logEvent('App Transfer', 'Canceled');
          this.transferRequests.removeObject(transfer);
        });
      },

      openConfirmModal() {
        return (0, _rsvp.resolve)(this.set('confirmingTransfer', true));
      }

    }
  });

  _exports.default = _default;
});