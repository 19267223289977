define("dashboard/models/test-run", ["exports", "@ember-data/model", "dashboard/utils/pluralize", "@ember/object", "@ember/object/computed", "@ember/utils", "moment", "dashboard/mixins/ds-error-monitoring-model"], function (_exports, _model, _pluralize, _object, _computed, _utils, _moment, _dsErrorMonitoringModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATE_ORDER = _exports.STATES = void 0;
  const BUILDING = 'building';
  const CANCELLED = 'cancelled';
  const CREATING = 'creating';
  const ERRORED = 'errored';
  const FAILED = 'failed';
  const PENDING = 'pending';
  const RUNNING = 'running';
  const SUCCEEDED = 'succeeded';
  const STATES = {
    BUILDING,
    CANCELLED,
    CREATING,
    ERRORED,
    FAILED,
    PENDING,
    RUNNING,
    SUCCEEDED
  };
  _exports.STATES = STATES;
  const STATE_ORDER = {
    [PENDING]: 0,
    [CREATING]: 1,
    [BUILDING]: 2,
    [RUNNING]: 3,
    [SUCCEEDED]: Infinity,
    [FAILED]: Infinity,
    [ERRORED]: Infinity,
    [CANCELLED]: Infinity
  };
  _exports.STATE_ORDER = STATE_ORDER;

  var _default = _model.default.extend(_dsErrorMonitoringModel.default, {
    appSetupId: (0, _model.attr)('string'),
    pipelineId: (0, _model.attr)('string'),
    userId: (0, _model.attr)('string'),
    clearCache: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    createdAt: (0, _model.attr)('date'),
    commitBranch: (0, _model.attr)('string'),
    commitMessage: (0, _model.attr)('string'),
    commitSha: (0, _model.attr)('string'),
    debug: (0, _model.attr)('boolean'),
    actorEmail: (0, _model.attr)('string'),
    number: (0, _model.attr)('number'),
    organization: (0, _model.attr)('string'),
    sourceBlobUrl: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    warningMessage: (0, _model.attr)('string'),
    dynoSize: (0, _model.attr)('string'),
    testCases: (0, _model.hasMany)(),
    testNodes: (0, _model.hasMany)(),
    pipeline: (0, _model.belongsTo)(),
    // This is temporary until the back end can return the testCount again (i.e.
    // when we have proper TAP output parsing)
    testCount: (0, _computed.reads)('testCases.length'),
    updatedAt: (0, _model.attr)('date'),
    isBuilding: (0, _computed.equal)('status', BUILDING),
    isCancelled: (0, _computed.equal)('status', CANCELLED),
    isCreating: (0, _computed.equal)('status', CREATING),
    isErrored: (0, _computed.equal)('status', ERRORED),
    isFailed: (0, _computed.equal)('status', FAILED),
    isPending: (0, _computed.equal)('status', PENDING),
    isRunning: (0, _computed.equal)('status', RUNNING),
    isSucceeded: (0, _computed.equal)('status', SUCCEEDED),
    isFailedOrErrored: (0, _computed.or)('isFailed', 'isErrored'),
    isFinished: (0, _computed.or)('isSucceeded', 'isFailed', 'isErrored', 'isCancelled'),
    isNotFinished: (0, _computed.not)('isFinished'),
    hasTestCases: (0, _computed.notEmpty)('testCases'),
    hasNoTestCases: (0, _computed.not)('hasTestCases'),
    completedTestCount: (0, _computed.readOnly)('testCases.length'),
    passedTests: (0, _computed.filterBy)('testCases', 'passed'),
    failedTests: (0, _computed.filterBy)('testCases', 'failed'),
    hasFailedTests: (0, _computed.notEmpty)('failedTests'),
    passedTestCount: (0, _computed.readOnly)('passedTests.length'),
    failedTestCount: (0, _computed.readOnly)('failedTests.length'),
    firstTestNode: (0, _computed.readOnly)('testNodes.firstObject'),
    displayPassedTestCount: (0, _object.computed)('passedTestCount', function () {
      const count = this.passedTestCount;

      if (this.passedTestCount) {
        return `(${count} ${(0, _pluralize.default)(count, 'test', 'tests')})`;
      }
    }),
    displayFailedTestCount: (0, _object.computed)('failedTestCount', function () {
      const count = this.failedTestCount;

      if (this.failedTestCount) {
        return `(${count} ${(0, _pluralize.default)(count, 'test', 'tests')})`;
      }
    }),
    createdDate: (0, _object.computed)('createdAt', function () {
      return (0, _moment.default)(this.createdAt).calendar(null, {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'dddd'
      });
    }),
    shortSha: (0, _object.computed)('commitSha', function () {
      return (this.commitSha || '').slice(0, 7);
    }),
    commitMessageSubject: (0, _object.computed)('commitMessage', function () {
      return this.commitMessage.split('\n')[0];
    }),
    // Take the second and subsequent lines of the commit message and convert
    // consecutive newlines to single newlines
    commitMessageBody: (0, _object.computed)('commitMessage', function () {
      return this.commitMessage.split('\n').reject(_utils.isEmpty).slice(1).join('\n');
    }),
    hasNodeMessages: (0, _object.computed)('testNodes.@each.message', function () {
      return this.testNodes.any(node => node.get('hasMessage'));
    }),
    hasMessages: (0, _computed.or)('message', 'hasNodeMessages', 'hasFailedTests', 'failedInBuilding', 'warningMessage')
  });

  _exports.default = _default;
});