define("dashboard/templates/components/account/profile-email", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Lo8YIJ1n",
    "block": "[[[1,[28,[35,0],null,[[\"label\",\"value\",\"name\",\"placeholder\",\"inputId\",\"isDisabled\",\"isEditing\",\"onSave\",\"onEdit\",\"autocomplete\"],[\"Email Address\",[30,0,[\"email\"]],\"new-e-mail\",\"Your email address\",\"new-e-mail\",[30,0,[\"current\",\"account\",\"federated\"]],[30,0,[\"isEditingEmail\"]],[28,[37,1],[[30,0],\"showConfirm\"],null],[28,[37,1],[[30,0],\"edit\"],null],\"off\"]]]],[1,\"\\n\"],[41,[30,0,[\"emailDidChange\"]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"alertType\",\"class\",\"icon\",\"message\"],[\"success\",\"mb3\",\"success-ring\",\"A confirmation link has been sent to your new email address\\n    and you must click on the link to complete the address change. A\\n    notice has also been sent to your old email address.\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[6,[39,4],null,[[\"isModalVisible\",\"modalTitle\",\"modalActionButtonText\",\"modalActionButtonClassNames\",\"onConfirm\"],[[30,0,[\"showConfirmationModal\"]],\"Update Profile\",\"Update Profile\",\"btn btn-primary\",[28,[37,1],[[30,0],\"confirmSave\"],null]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,5],null,[[\"prompt\",\"password\"],[\"To update your profile, enter your current password:\",[30,0,[\"password\"]]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"hk-inline-edit\",\"action\",\"if\",\"hk-message\",\"confirm/confirmable-action\",\"confirm/with-password\"]]",
    "moduleName": "dashboard/templates/components/account/profile-email.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});