define("dashboard/models/github-commit-status", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    updatedAt: (0, _model.attr)('date'),
    state: (0, _model.attr)(),
    targetUrl: (0, _model.attr)(),
    description: (0, _model.attr)(),
    context: (0, _model.attr)(),
    repo: (0, _model.attr)(),
    sha: (0, _model.attr)(),

    // TODO: this doesn't handle branches
    matchesRef(ref) {
      return this.sha.startsWith(ref);
    }

  });

  _exports.default = _default;
});