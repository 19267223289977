define("dashboard/serializers/enterprise-account-preference-mfa", ["exports", "@ember-data/serializer/rest", "@ember-data/serializer/json", "uuid"], function (_exports, _rest, _json, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _json.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      enterpriseAccount: {
        serialize: false
      }
    },

    normalizeFindBelongsToResponse(_store, _primaryModelClass, payload) {
      payload.id = (0, _uuid.v4)();
      payload.mfaRequired = payload['mfa-required'];
      return this._super(...arguments);
    },

    normalizeUpdateRecordResponse(_store, _primaryModelClass, payload) {
      payload.mfaRequired = payload['mfa-required'];
      return this._super(...arguments);
    },

    serialize(snapshot) {
      const payload = {};
      payload['mfa-required'] = snapshot.record.mfaRequired;
      return payload;
    }

  });

  _exports.default = _default;
});