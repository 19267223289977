define("dashboard/serializers/collaborator", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.extractUserID = extractUserID;
  _exports.generateID = generateID;

  var _default = _application.default.extend({
    serialize(snapshot) {
      const collaborator = snapshot.record;
      return {
        user: collaborator.get('email')
      };
    },

    extractId(modelClass, resourceHash) {
      const id = this._super(modelClass, resourceHash);

      return generateID(id, resourceHash.app.id);
    },

    extractRelationships(modelClass, resourceHash) {
      const relationships = this._super(...arguments);

      relationships.app = {
        data: {
          type: 'app',
          id: resourceHash['app']['id']
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
  const SEPARATOR = '___COLLABORATOR_JOIN_KEY___';

  function generateID(userID, appID) {
    return `${userID}${SEPARATOR}${appID}`;
  }

  function extractUserID(id) {
    return id.split(SEPARATOR)[0];
  }
});