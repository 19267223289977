define("dashboard/models/sni-endpoint", ["exports", "@ember/object", "@ember-data/model", "dashboard/utils/moment-diff", "@ember/service", "dashboard/utils/endpoint-match-domains"], function (_exports, _object, _model, _momentDiff, _service, _endpointMatchDomains) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _model.default.extend({
    certificateChain: (0, _model.attr)('string'),
    cname: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    sslCert: (0, _model.attr)(),
    warnings: (0, _model.attr)(),
    createdAt: (0, _model.attr)('date'),
    updatedAt: (0, _model.attr)('date'),
    expiresAt: readOnly('sslCert.expires_at'),
    type: (0, _object.computed)('sslCert.cert_domains', function () {
      if ((0, _endpointMatchDomains.includesWildcard)((0, _endpointMatchDomains.splitDomains)(this.sslCert.cert_domains))) {
        return 'Wildcard';
      }

      return 'SAN';
    }),
    isExpired: (0, _object.computed)('expiresAt', function () {
      return (0, _momentDiff.default)(this.sslCert.expires_at, Date.now()) <= 0;
    }),
    willExpireSoon: (0, _object.computed)('expiresAt', function () {
      if (!this.isExpired) {
        return (0, _momentDiff.default)(this.sslCert.expires_at, Date.now()) <= 30;
      }

      return false;
    }),
    status: (0, _object.computed)('expiresAt', function () {
      if (this.isExpired) {
        return 1;
      } else if (this.willExpireSoon) {
        return 2;
      } else {
        return 3;
      }
    }),
    app: (0, _model.belongsTo)('app'),
    domains: (0, _model.hasMany)('domain'),
    commonName: readOnly('sslCert.cert_domains.firstObject'),
    analytics: (0, _service.inject)(),

    logEvent(eventName) {
      this.analytics.logEvent('SNI Cert', eventName);
    }

  });

  _exports.default = _default;
});