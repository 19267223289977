define("dashboard/controllers/protected/error", ["exports", "@ember/controller", "dashboard/utils/errors"], function (_exports, _controller, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    get errorStatus() {
      return (0, _errors.errorStatus)(this.model);
    }

  });

  _exports.default = _default;
});