define("dashboard/templates/app/access", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rku57Mq4",
    "block": "[[[41,[30,0,[\"isOrgApp\"]],[[[1,\"  \"],[10,0],[14,0,\"limit-width lock-status row\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[52,[30,0,[\"app\",\"locked\"]],\"lock-locked-28\",\"lock-unlocked-28\"],\"malibu-fill-gradient-purple\",30]],null],[1,\"\\n\\n\"],[41,[30,0,[\"app\",\"locked\"]],[[[1,\"      \"],[10,2],[12],[10,\"strong\"],[12],[1,\"This app is locked.\"],[13],[13],[1,\"\\n      \"],[10,2],[12],[1,\"Only admins and existing collaborators may invite new users to this app.\"],[13],[1,\"\\n\"],[41,[30,0,[\"appPermissions\",\"canManageAppLocks\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"hk-button--secondary\"],[16,\"disabled\",[30,0,[\"app\",\"isSaving\"]]],[4,[38,2],[[30,0],\"unlock\"],null],[12],[1,\"Unlock App\"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"      \"],[10,2],[12],[10,\"strong\"],[12],[1,\"This app is unlocked.\"],[13],[13],[1,\"\\n      \"],[10,2],[12],[1,\"All team members can join this app.\"],[13],[1,\"\\n\"],[41,[30,0,[\"appPermissions\",\"canManageAppLocks\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"hk-button--secondary\"],[16,\"disabled\",[30,0,[\"app\",\"isSaving\"]]],[4,[38,2],[[30,0],\"lock\"],null],[12],[1,\"Lock App\"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,3],null,[[\"app\",\"team\",\"collaborators\",\"availablePermissions\",\"appPermissions\"],[[30,0,[\"app\"]],[30,0,[\"team\"]],[30,0,[\"collaborators\"]],[30,0,[\"permissions\"]],[30,0,[\"appPermissions\"]]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"limit-width\"],[12],[1,\"\\n\"],[41,[30,0,[\"orgAdmins\"]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"collaborators\",\"header\",\"permissions\",\"isAppList\"],[[30,0,[\"orgAdmins\"]],[30,0,[\"adminsHeader\"]],[30,0,[\"orgAdminPermissions\"]],true]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"malibu-icon\",\"action\",\"app/collaborators-list\",\"static-collaborator-list\"]]",
    "moduleName": "dashboard/templates/app/access.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});