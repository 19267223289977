define("dashboard/adapters/enterprise-account/audit-trail-archive", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord(id, modelName, snapshot) {
      const enterpriseAccountId = snapshot.record.get('enterpriseAccount.id');
      const year = snapshot.record.get('year');
      const month = parseInt(snapshot.record.get('month')); // can't have leading 0

      return `${this.host}/enterprise-accounts/${enterpriseAccountId}/archives/${year}/${month}`;
    },

    findRecord(store, type, id, snapshot) {
      const enterpriseAccountId = snapshot.record.get('enterpriseAccount.id');
      return this._super(...arguments).then(result => {
        result.enterprise_account_id = enterpriseAccountId;
        return result;
      });
    }

  });

  _exports.default = _default;
});