define("dashboard/mixins/save-bulk-formation", ["exports", "@ember/runloop", "@ember/object/mixin", "jquery", "rsvp", "dashboard/config/environment", "dashboard/mixins/adapters/authorization-headers-mixin"], function (_exports, _runloop, _mixin, _jquery, _rsvp, _environment, _authorizationHeadersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_authorizationHeadersMixin.default, {
    saveBulkFormation() {
      const appId = this.id;
      const formationsPayload = this.formations.map(formation => {
        const {
          quantity,
          type,
          size,
          id
        } = formation;
        return {
          id,
          quantity,
          type,
          size
        };
      });
      const data = {
        updates: formationsPayload
      }; // Not sure why async-button doesn't like jquery ajax promises...

      return new _rsvp.default.Promise((resolve, reject) => {
        const headers = Object.assign({}, this.sudoHeaders);
        headers['Accept'] = 'application/vnd.heroku+json; version=3';

        _jquery.default.ajax({
          url: `${_environment.default.platformApiUrl}/apps/${appId}/formation`,
          method: 'PATCH',
          contentType: 'application/json',
          headers,
          withHerokuAuthorization: true,
          data: JSON.stringify(data)
        }).then(payload => {
          (0, _runloop.run)(() => {
            const store = this.store;
            const modelClass = store.modelFor('formation');
            const serializer = store.serializerFor('formation');
            const normalized = serializer.normalizeResponse(store, modelClass, payload, null, 'findHasMany');
            store.push(normalized);
            resolve();
          });
        }, error => {
          reject(error);
        });
      });
    }

  });

  _exports.default = _default;
});