define("dashboard/templates/components/app/addons-empty-state-no-access", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "z1sppx9w",
    "block": "[[[6,[39,0],null,[[\"class\"],[\"mb5\"]],[[\"default\"],[[[[6,[30,1,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"    You don't have access to view this app's add-ons\\n\"]],[]]]]],[6,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"    Ask the app owner for the \"],[10,\"code\"],[12],[1,\"deploy\"],[13],[1,\" or \"],[10,\"code\"],[12],[1,\"operate\"],[13],[1,\" permission.\\n\"]],[]]]]]],[1]]]]]],[\"well\"],false,[\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/app/addons-empty-state-no-access.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});