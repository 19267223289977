define("dashboard/templates/components/team/member-panel/notices", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RF2KCR3D",
    "block": "[[[41,[30,0,[\"panel\",\"willUpgradeFromCollaborator\"]],[[[1,\"  \"],[10,2],[15,0,[29,[\"ma0 orange f5 lh-copy \",[30,0,[\"class\"]]]]],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"panel\",\"role\"]],\"admin\"],null],[[[1,\"      Changing the role of this collaborator will add them to this team as an \"],[10,\"b\"],[12],[1,\"admin\"],[13],[1,\".\\n      Admins have the highest level of permissions.\\n      Please visit \"],[10,3],[15,6,[29,[[30,0,[\"permissionsDocsLink\"]]]]],[14,0,\"hk-link orange\"],[14,\"target\",\"_blank\"],[12],[1,\"this chart\"],[13],[1,\" for details.\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"panel\",\"role\"]],\"member\"],null],[[[1,\"      Changing the role of this collaborator will add them to this team as a \"],[10,\"b\"],[12],[1,\"member\"],[13],[1,\".\\n      Members have more access controls than collaborators.\\n      Please visit \"],[10,3],[15,6,[29,[[30,0,[\"permissionsDocsLink\"]]]]],[14,0,\"hk-link orange\"],[14,\"target\",\"_blank\"],[12],[1,\"this chart\"],[13],[1,\" for details.\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"panel\",\"role\"]],\"viewer\"],null],[[[1,\"      Changing the role of this collaborator will add them to this team as a \"],[10,\"b\"],[12],[1,\"viewer\"],[13],[1,\".\\n      Viewers have more access controls than collaborators.\\n      Please visit \"],[10,3],[15,6,[29,[[30,0,[\"permissionsDocsLink\"]]]]],[14,0,\"hk-link orange\"],[14,\"target\",\"_blank\"],[12],[1,\"this chart\"],[13],[1,\" for details.\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"panel\",\"willDowngradeToCollaborator\"]],[[[1,\"  \"],[10,2],[15,0,[29,[\"ma0 orange f5 lh-copy \",[30,0,[\"class\"]]]]],[12],[1,\"\\n    This user will lose their existing permissions in this team and only have\\n    access to the apps specified above.\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"eq\"]]",
    "moduleName": "dashboard/templates/components/team/member-panel/notices.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});