define("dashboard/templates/components/account/authorizations-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mMgwD4ib",
    "block": "[[[41,[30,0,[\"hasAuthorizedApps\"]],[[[1,\"  \"],[10,0],[14,0,\"group-header\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Registered Authorized Applications\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"form\"],[12],[1,\"\\n    \"],[10,\"table\"],[14,0,\"table editable-list purple-list authorizations-list-table\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"authorizedApps\"]]],null]],null],null,[[[1,\"          \"],[1,[28,[35,3],null,[[\"authorization\",\"confirmDeleteAction\"],[[30,1],[28,[37,4],[[30,0],\"confirmDelete\"],null]]]]],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"headline\",\"body\"],[\"There are no authorized applications yet\",\"Applications that you authorize to use the Heroku API on your behalf will appear here.\"]]]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"isDeleting\"]],[[[6,[39,6],null,[[\"onClose\",\"title\",\"hasCancelButton\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"cancelButtonClassNames\"],[[28,[37,4],[[30,0],\"toggleDeleteConfirmModal\"],null],\"Delete Authorization\",true,[28,[37,4],[[30,0],\"delete\"],null],\"Delete Authorization\",\"btn btn-danger\",\"btn btn-default\"]],[[\"default\"],[[[[1,\"    \"],[10,2],[12],[1,\"Are you sure you want to remove the authorization for \"],[10,\"strong\"],[12],[1,[30,0,[\"item\",\"client\",\"name\"]]],[13],[1,\"?\"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[\"authorizedApp\"],false,[\"if\",\"each\",\"-track-array\",\"account/authorizations-list-item\",\"action\",\"hk-well\",\"hk-modal\"]]",
    "moduleName": "dashboard/templates/components/account/authorizations-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});