define("dashboard/components/addon-icon", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;
  /**
   * Renders an `<img>` tag for a given addon name.
   *
   *     {{addon-icon name='heroku-postgresql'}}
   *
   * Renders:
   *
   *     <img src='https://addons.heroku.com/addons/heroku-postgresql/icons/catalog.png' class='addon-icon' height='28px' width='28px'>
   *
   * @class Dashboard.AddonIconComponent
   * @extends Ember.Component
   *
   * @param {boolean} tooltip - set to true to show a tooltip using the title attribute
   */

  var _default = _component.default.extend({
    tagName: 'img',
    classNames: 'addon-icon',
    attributeBindings: ['src', 'height', 'width', 'title', 'role', 'aria-hidden'],
    height: alias('size'),
    width: alias('size'),
    size: 28,
    src: (0, _object.computed)('name', function () {
      return `https://addons.heroku.com/addons/${this.name}/icons/catalog.png`;
    })
  });

  _exports.default = _default;
});