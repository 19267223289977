define("dashboard/components/metrics/charts/visualizations/jvm-nonheap-memory", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/memory-chart", "dashboard/utils/metrics/has-non-zero-values"], function (_exports, _object, _memoryChart, _hasNonZeroValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _memoryChart.default.extend({
    'data-test-target': 'metrics-java-chart',
    summaryComponent: 'metrics/charts/chart-header/java-memory-summary',
    title: 'JVM: Non-Heap Memory Usage',
    devCenterName: 'language-runtime-metrics-jvm#non-heap-memory-usage',
    testTarget: 'nonheap-memory-usage',
    summaryData: (0, _object.computed)('data.currentNonheapTotal', 'data.maxNonheapUsed', 'data.meanNonheapMaxValue', function () {
      return _object.default.create({
        memoryType: 'non-heap',
        currentTotal: this.get('data.currentNonheapTotal'),
        maxUsed: this.get('data.maxNonheapUsed'),
        averageUsed: this.get('data.meanNonheapMaxValue')
      });
    }),
    hasDirectData: (0, _hasNonZeroValues.default)('data.bufferPoolDirect'),
    hasMappedData: (0, _hasNonZeroValues.default)('data.bufferPoolMapped'),
    isShowingMax: true,
    canSeeDirect: true,
    canSeeMapped: true,
    timeSeriesMax: (0, _object.computed)('data.nonheapMax', 'isShowingMax', function () {
      return _object.default.create({
        name: 'Max Nonheap',
        color: 'purple-003',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 3,
        data: this.get('data.nonheapMax'),
        isVisible: this.isShowingMax,
        key: 'Max'
      });
    }),
    timeSeriesDirect: (0, _object.computed)('data.bufferPoolDirect', 'canSeeDirect', function () {
      return _object.default.create({
        name: 'Buffer Pool Direct',
        color: 'purple-002',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 2,
        data: this.get('data.bufferPoolDirect'),
        isVisible: this.canSeeDirect,
        key: 'Direct'
      });
    }),
    timeSeriesMapped: (0, _object.computed)('data.bufferPoolMapped', 'canSeeMapped', function () {
      return _object.default.create({
        name: 'Buffer Pool Mapped',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 1,
        data: this.get('data.bufferPoolMapped'),
        isVisible: this.canSeeMapped,
        key: 'Mapped'
      });
    }),
    chartTimeSeries: (0, _object.computed)('timeSeriesQuota', 'timeSeriesMax', 'timeSeriesDirect', 'timeSeriesMapped', 'hasDirectData', 'hasMappedData', function () {
      const timeSeries = [this.timeSeriesQuota, this.timeSeriesMax];

      if (this.hasDirectData) {
        timeSeries.push(this.timeSeriesDirect);
      }

      if (this.hasMappedData) {
        timeSeries.push(this.timeSeriesMapped);
      }

      return timeSeries;
    })
  });

  _exports.default = _default;
});