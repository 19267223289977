define("dashboard/components/account/two-factor/recovery-config-modal", ["exports", "@ember/component", "@ember/object", "@ember/service", "dashboard/mixins/multi-step-flow"], function (_exports, _component, _object, _service, _multiStepFlow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  Step 1: 2FA enabled banner
   *  Step 2: Enter phone number
   *  Step 3: Confirm code
   *  Step 4: Download codes
   */
  var _default = _component.default.extend(_multiStepFlow.default, {
    current: (0, _service.inject)(),
    stepTitles: {
      1: 'Two-Factor Authentication Enabled',
      2: 'Add a Phone Number',
      3: 'Add a Phone Number',
      4: 'Two-Factor Recovery Codes'
    },
    smsNumber: null,
    account: null,
    password: null,
    title: (0, _object.computed)('stepTitles', 'currentStep', function () {
      return this.stepTitles[this.currentStep];
    }),

    teardown() {
      this.transitionToStep(1);
      this.transitionToRoute('account.index');
    },

    actions: {
      transitionToStepThree(smsNumber) {
        this.set('smsNumber', smsNumber);
        this.transitionToStep(3);
      },

      transitionToStepFour() {
        this.transitionToStep(4);
      },

      close() {
        this.attrs.onRecoveryConfigCompleted();
      }

    }
  });

  _exports.default = _default;
});