define("dashboard/components/malibu-loading-spinner", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['dib'],
    iconSize: (0, _object.computed)('size', function () {
      if (this.size === 'small') {
        return 16;
      } else {
        return 28;
      }
    }),
    iconName: (0, _object.computed)('size', function () {
      if (this.size === 'small') {
        return 'loading-16';
      } else {
        return 'loading-28';
      }
    })
  });

  _exports.default = _default;
});