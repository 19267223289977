define("dashboard/adapters/release", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.pipelines',
    range: {
      attribute: 'version',
      max: 10,
      ascending: false
    },

    urlForQuery(query) {
      const url = `${this.host}/apps/${query.app}/releases`;
      delete query.app;
      return url;
    },

    query(store, type, query) {
      if (query.latest) {
        this.set('range', {
          attribute: 'version',
          ascending: false,
          max: 1
        });
      } else {
        this.set('range', {
          attribute: 'version',
          ascending: false
        });
      }

      delete query.latest;
      return this._super(...arguments);
    },

    urlForQueryRecord(query) {
      const url = `${this.host}/apps/${query.app}/releases/${query.releaseId}`;
      delete query.app;
      delete query.releaseId;
      return url;
    },

    urlForCreateRecord(modelName, snapshot) {
      const record = snapshot.record;
      const appIdentifier = record.get('app.id') || record.get('app.name');
      const host = this.host;
      return `${host}/apps/${appIdentifier}/releases`;
    }

  });

  _exports.default = _default;
});