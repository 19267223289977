define("dashboard/components/metrics/chart-controls/throughput-toggle", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'metrics-throughput-toggle',
    classNames: ['flex'],
    'data-test-target': 'metrics-throughput-toggle',
    usingRpm: (0, _computed.equal)('throughputResolution', 'rpm'),
    usingRps: (0, _computed.equal)('throughputResolution', 'rps'),
    actions: {
      setResolution(resolution) {
        if (resolution !== this.throughputResolution) {
          this.toggleThroughputResolution(resolution);
        }
      }

    }
  });

  _exports.default = _default;
});