define("dashboard/components/metrics/charts/axes-elements/axis-value", ["exports", "@ember/object", "dashboard/components/metrics/charts/axes-elements/axis-element", "numeral"], function (_exports, _object, _axisElement, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * y-axis {{axis-element}} for {{timeseries-chart}} values
   */
  var _default = _axisElement.default.extend({
    classNames: ['chart-axis--value'],
    tickPadding: 5,

    /**
     * suggested number of ticks
     * https://github.com/d3/d3-axis
     */
    numTicks: (0, _object.computed)('isLogarithmic', function () {
      return this.get('isLogarithmic') ? 5 : 6;
    }),
    tickValues: (0, _object.computed)('unit', 'maxDisplayValue', function () {
      let values;

      if (this.unit === 'memory') {
        const max = this.get('maxDisplayValue');
        const tickSpaceAdjustment = 2.25; // the rate of MB to display with

        let mbUnit = 2; // guarantees that we will have 3-5 ticks for any max value; cap at 2 GB

        while (mbUnit * 2 < max / tickSpaceAdjustment && mbUnit < 2048) {
          mbUnit *= 2;
        }

        const units = Math.floor(max / mbUnit) + 1;
        values = [];

        for (let i = 0; i < units; i++) {
          values.push(i * mbUnit);
        }
      }

      return values;
    }),

    formatFn(value) {
      let max = this.get('maxDisplayValue');

      if (this.get('chartHasOpposingTimeSeries')) {
        value = Math.abs(value);
      }

      if (this.get('isLogarithmic')) {
        if (value > 0) {
          value = Math.pow(10, value);
        }

        if (max > 0) {
          max = Math.pow(10, max);
        }
      }

      return this._formatValue(max, value);
    },

    _formatValue(max, value) {
      const unit = this.unit;

      switch (unit) {
        case 'time':
          return this._formatTime(max, value);

        case 'memory':
          return this._formatMemory(max, value);

        case 'percentage':
          return `${(0, _numeral.default)(value).format('0,0[.]0')}%`;

        case 'seconds':
          return this._formatSeconds(max, value);

        case 'integer':
          return this._formatNumber(max, value);

        case 'rps':
          return `${this._formatNumber(max, value)} rps`;

        case 'rpm':
          return `${this._formatNumber(max, value)} rpm`;

        default:
          if (!this.get('hideValues')) {
            return this._formatNumber(max, value);
          }

      }
    },

    /*
     * by using the max for comparison rather than the value, we ensure that
     * all time labels on one axis will be using the same format
     */
    _formatTime(max, value) {
      if (max < 1000) {
        return `${(0, _numeral.default)(value).format('0,0')} ms`;
      } else {
        // max >= 1000
        return this._formatSeconds(max, value / 1000);
      }
    },

    _formatSeconds(max, value) {
      if (max < 4000) {
        return `${(0, _numeral.default)(value).format('0,0.0')} s`;
      } else {
        // max >= 4000
        return `${(0, _numeral.default)(value).format('0,0')} s`;
      }
    },

    _formatMemory(max, value) {
      if (max >= 1024) {
        return `${(0, _numeral.default)(value / 1024).format('0,0[.]0')} GB`;
      } else {
        // max < 1024
        return `${Math.round(value)} MB`;
      }
    },

    // Adjust number formatting based on the max value
    _formatNumber(max, value) {
      if (max >= 5000) {
        return (0, _numeral.default)(value).format('0a');
      } else if (max >= 5.0 || max === 0) {
        return (0, _numeral.default)(value).format('0,0');
      } else if (max >= 0.5) {
        return (0, _numeral.default)(value).format('0.0');
      } else if (max >= 0.05) {
        return (0, _numeral.default)(value).format('0.00');
      } else if (max >= 0.005) {
        return (0, _numeral.default)(value).format('0.000');
      } else if (max >= 0.0005) {
        return (0, _numeral.default)(value).format('0.0000');
      } else {
        // max < 0.0005
        return (0, _numeral.default)(value).format('0.00000');
      }
    }

  });

  _exports.default = _default;
});