define("dashboard/mixins/metrics/chart-time-state", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/object", "@ember/utils", "d3", "dashboard/mixins/metrics/current-timeframe", "dashboard/utils/metrics/data-refresh"], function (_exports, _computed, _mixin, _object, _utils, _d, _currentTimeframe, _dataRefresh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * time-focused {{timeseries-chart}} logic
   *
   */
  var _default = _mixin.default.create(_currentTimeframe.default, {
    /**
     * a d3 time-scale object
     */
    timeScale: (0, _object.computed)('innerWidth', 'minTime', 'maxTime', 'rollupDuration', function () {
      const startTime = this.minTime;
      const endTime = this.maxTime + this.rollupDuration;
      return (0, _d.scaleUtc)().domain([startTime, endTime]).rangeRound([0, this.innerWidth]);
    }),
    maxTime: (0, _computed.reads)('currentEndTime'),
    minTime: (0, _computed.reads)('currentStartTime'),
    updateTimesFromTimeSeriesData: (0, _dataRefresh.updateTimeCalculations)('timeSeriesData', 'currentEndTime', 'currentStartTime'),
    updateTimesFromEventData: (0, _dataRefresh.updateTimeCalculations)('eventData', 'currentEndTime', 'currentStartTime'),
    rollupDuration: (0, _computed.reads)('currentStepDuration'),

    /**
     * the exact time at the location of the cursor
     */
    cursorTimeLocal: (0, _object.computed)('cursorChartXLocal', 'timeScale', function () {
      const timeScale = this.timeScale;
      const x = this.cursorChartXLocal;

      if (timeScale && x) {
        return timeScale.invert(x);
      }
    }),
    selectedRollupTime: (0, _computed.readOnly)('currentSelectedRollupTime'),
    latestRollupTime: (0, _computed.readOnly)('currentLatestRollupTime'),
    nextLatestRollupTime: (0, _computed.readOnly)('currentNextLatestRollupTime'),
    hasSelectedTime: (0, _computed.notEmpty)('selectedTime'),
    selectedTimeChartX: (0, _object.computed)('selectedTime', 'timeScale', function () {
      const time = this.selectedTime;

      if ((0, _utils.isPresent)(time)) {
        return this.timeScale(time);
      }
    }),

    /**
     * the x location of the selected time
     *
     * includes the width of the y axis labels
     */
    selectedTimeX: (0, _object.computed)('selectedTimeChartX', 'padding.left', function () {
      const chartX = this.selectedTimeChartX;

      if ((0, _utils.isPresent)(chartX)) {
        return chartX + this.get('padding.left');
      }
    })
  });

  _exports.default = _default;
});