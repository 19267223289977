define("dashboard/routes/new-team", ["exports", "@ember/routing/route", "@ember/service", "dashboard/mixins/document-title"], function (_exports, _route, _service, _documentTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),
    documentTitle: 'Create New Team',

    model() {
      return this.store.createRecord('team', {
        name: ''
      });
    },

    afterModel() {
      this.analytics.logEvent('New Team Page', 'Viewed');
    },

    renderTemplate() {
      this.set('breadcrumbs.trail', {
        location: 'Create New Team'
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav'
      });

      this._super(...arguments);
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('paymentMethod', this.store.createRecord('team/payment-method'));
    },

    deactivate() {
      this.store.peekAll('team').filterBy('isNew', true).forEach(newTeam => {
        newTeam.destroyRecord();
      });
    }

  });

  _exports.default = _default;
});