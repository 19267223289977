define("dashboard/components/account/billing/declined-invoice-row", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    invoice: null,
    tagName: 'tr',
    classNames: ['invoice-row'],
    statusText: (0, _object.computed)('invoice.stateString', 'invoice.attemptedPaymentFailed', function () {
      if (this.get('invoice.attemptedPaymentFailed')) {
        return 'Payment attempt failed';
      }

      return this.get('invoice.stateString');
    }),

    init() {
      this._super(...arguments);

      this.set('invoice.isSelected', true);
    }

  });

  _exports.default = _default;
});