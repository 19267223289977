define("dashboard/components/labs/list-item-header", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['pa2', 'f6', 'gray', 'bb', 'b--light-gray', 'ttu', 'flex', 'flex-row']
  });

  _exports.default = _default;
});