define("dashboard/components/metrics/charts/row-chart-elements/error-indicator-row", ["exports", "@ember/object", "@ember/object/computed", "dashboard/components/metrics/charts/row-chart-elements/indicator-row"], function (_exports, _object, _computed, _indicatorRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _indicatorRow.default.extend({
    classNames: ['metrics__graph-row--tuples'],

    /**
     * data with values greater that 0
     */
    _presentData: (0, _computed.filter)('data', datum => datum[1] > 0),
    indicators: (0, _computed.map)('_presentData', function (datum) {
      return _object.default.create({
        time: datum[0],
        count: datum[1]
      });
    }),
    displayValue: (0, _object.computed)('selectedValue', function () {
      return `x${this.selectedValue}`;
    }),
    indicatorComponent: 'metrics/charts/row-chart-elements/error-indicator'
  });

  _exports.default = _default;
});