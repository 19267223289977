define("dashboard/templates/components/x-identicon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DXVs8c5T",
    "block": "[[[41,[28,[37,1],[[30,0,[\"type\"]],\"account\"],null],[[[1,\"  \"],[10,0],[15,0,[29,[\"x-identicon x-identicon-account x-identicon--\",[30,0,[\"size\"]],\" inline-flex flex-shrink-0 items-center justify-center w2 h2 br1 \",[30,0,[\"colorGradient\"]],\" \",[30,0,[\"classNames\"]]]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"white f5 fw6\"],[12],[1,[28,[35,2],[[30,0,[\"name\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[\"x-identicon x-identicon--\",[30,0,[\"size\"]],\" inline-flex flex-shrink-0 items-center justify-center w2 h2 ba br-100 bg-white-80 b--light-purple \",[30,0,[\"classNames\"]]]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"purple f5 fw6\"],[12],[1,[28,[35,2],[[30,0,[\"name\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"eq\",\"abbreviation\"]]",
    "moduleName": "dashboard/templates/components/x-identicon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});