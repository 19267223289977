define("dashboard/mixins/formations-sort", ["exports", "@ember/object/mixin", "@ember/utils", "@ember/object/computed"], function (_exports, _mixin, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    sortedFormations: (0, _computed.sort)('formations', function (a, b) {
      // 'web' first, then alphabetical
      const aType = a.get('type');
      const bType = b.get('type');

      if (aType === 'web') {
        return -1;
      } else if (bType === 'web') {
        return 1;
      } else {
        return (0, _utils.compare)(aType, bType);
      }
    })
  });

  _exports.default = _default;
});