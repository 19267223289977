define("dashboard/models/shogun/kafka-datastore", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "@ember/service", "dashboard/utils/metrics/time-calculators", "dashboard/mixins/metrics/timeframe-options"], function (_exports, _attr, _model, _relationships, _service, _timeCalculators, _timeframeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_timeframeOptions.default, {
    browserTime: (0, _service.inject)(),
    api: (0, _service.inject)(),
    addonId: (0, _attr.default)('string'),
    state: (0, _attr.default)(),
    topics: (0, _attr.default)(),
    limits: (0, _attr.default)(),
    capabilities: (0, _attr.default)(),
    defaults: (0, _attr.default)(),
    dataSize: (0, _attr.default)('number'),
    sharedCluster: (0, _attr.default)('boolean'),
    customerEncryptionKey: (0, _attr.default)('string'),
    topicDetails: (0, _relationships.belongsTo)('shogun/kafka-topic'),

    queryMetricsData(modelName, timeframeBegin, timeframeEnd) {
      const specifiedTimeframe = this.allDatastoreOptions.find(option => {
        return option.beforeHours === timeframeEnd && option.afterHours === timeframeBegin;
      });
      const timeframe = specifiedTimeframe || this.allDatastoreOptions.findBy('isDefault', true);
      const startTime = (0, _timeCalculators.calculateStartTime)(this.get('browserTime.currentTime'), timeframe.afterHours, timeframe.step);
      const endTime = (0, _timeCalculators.calculateEndTime)(this.get('browserTime.currentTime'), timeframe.beforeHours, timeframe.step);
      let step; // 72 Hour and 7 Day timeframe need hour conversions to make metaas happy

      if (timeframe.name === 'past72Hours' || timeframe.name === 'past7days') {
        step = `${timeframe.step / 60}h`;
      } else {
        step = `${timeframe.step}m`;
      }

      const query = {
        startTime,
        endTime,
        step,
        addonId: this.addonId
      };
      return this.store.queryRecord(modelName, query);
    },

    // These API actions dont fit cleanly into ember-data -- they are also async
    // So I went back to basics and put them here using the API service
    createTopic(newTopic) {
      const retentionTimeMs = newTopic.retention_time_hrs * 3600000;
      const data = {
        topic: {
          compaction: newTopic.compaction,
          name: newTopic.name,
          partition_count: parseInt(newTopic.partition_count),
          replication_factor: parseInt(newTopic.replication_factor)
        }
      };

      if (newTopic.retention_time_hrs) {
        data.topic.retention_time_ms = retentionTimeMs;
      }

      const addonId = this.addonId;
      const adapter = this.store.adapterFor('shogun/kafka-topic');
      return this.api.request(`/data/kafka/v0/clusters/${addonId}/topics`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    },

    updateTopic(topic) {
      const adapter = this.store.adapterFor('shogun/kafka-topic');
      const addonId = this.addonId;
      const topicName = topic.name;
      const retentionTimeMs = topic.retention_time_hrs * 3600000;
      const data = {
        topic: {
          compaction: topic.compaction,
          name: topicName,
          replication_factor: parseInt(topic.replication_factor),
          retention_time_ms: retentionTimeMs
        }
      };

      if (topic.retention_time_hrs) {
        data.topic.retention_time_ms = retentionTimeMs;
      }

      return this.api.request(`/data/kafka/v0/clusters/${addonId}/topics/${topicName}`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'PUT',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    },

    destroyTopic(topic) {
      const adapter = this.store.adapterFor('shogun/kafka-topic');
      const addonId = this.addonId;
      const topicName = topic.name;
      return this.api.request(`/data/kafka/v0/clusters/${addonId}/topics/${topicName}`, {
        adapter,
        headers: adapter.get('headers'),
        type: 'DELETE',
        contentType: 'application/json; charset=utf-8'
      });
    }

  });

  _exports.default = _default;
});