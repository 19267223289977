define("dashboard/serializers/review-app-config", ["exports", "dashboard/serializers/application", "@ember/polyfills"], function (_exports, _application, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(_) {
      let hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (hash) {
        hash.id = hash.pipeline.id;
      }

      return this._super(...arguments);
    },

    extractRelationships(_, hash) {
      const relationships = this._super(...arguments);

      relationships.pipeline = {
        data: {
          id: hash.pipeline.id,
          type: 'pipeline'
        }
      };

      if (hash.deploy_target) {
        relationships.deployTarget = {
          data: {
            id: hash.deploy_target.id,
            type: hash.deploy_target.type
          }
        };
      }

      return relationships;
    },

    serializePolymorphicType(snapshot, json) {
      const deployTarget = snapshot.record.get('deployTarget');
      const type = deployTarget.get('modelType');
      json.deploy_target = {};
      json.deploy_target.id = deployTarget.get('id');
      json.deploy_target.type = type;
      delete json.deployTarget;
    },

    serialize(snapshot) {
      const repo = snapshot.attr('repoName');

      const payload = this._super(...arguments);

      if (payload.stale_days === null) {
        delete payload.stale_days;
      }

      if (payload.deploy_target === null || !payload.deploy_target.type) {
        delete payload.deploy_target;
      }

      return (0, _polyfills.merge)(payload, {
        repo
      });
    }

  });

  _exports.default = _default;
});