define("dashboard/adapters/team/collaborator", ["exports", "@ember/service", "dashboard/adapters/application", "dashboard/serializers/collaborator", "dashboard/mixins/adapters/range-aware-adapter"], function (_exports, _service, _application, _collaborator, _rangeAwareAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rangeAwareAdapter.default, {
    range: {
      attribute: 'email',
      max: 1000
    },
    dsErrorMonitor: (0, _service.inject)(),

    urlForCreateRecord(modelName, snapshot) {
      // If there is an app relationship, we are explicitly creating a collaborator
      // If there is no app relationship, we are implicitly doing it via join app.
      let appName;

      if (snapshot.record.get('app.name')) {
        appName = snapshot.record.get('app.name');
      } else {
        appName = snapshot.record.get('appName');
      }

      return `${this.host}/teams/apps/${appName}/collaborators`;
    },

    urlForUpdateRecord(id, modelName, snapshot) {
      const appName = snapshot.record.get('app.name');
      const email = snapshot.record.get('email');
      return `${this.host}/teams/apps/${appName}/collaborators/${email}`;
    },

    urlForQueryRecord(query) {
      const url = `${this.host}/teams/apps/${query.app}/collaborators/${query.userId}`;
      delete query.app;
      delete query.userId;
      return url;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app').id;
      const userId = (0, _collaborator.extractUserID)(snapshot.id);
      return `${this.host}/teams/apps/${appId}/collaborators/${userId}`;
    },

    urlForQuery(query) {
      const {
        app
      } = query;
      delete query.app;
      return `${this.host}/teams/apps/${app}/collaborators`;
    }

  });

  _exports.default = _default;
});