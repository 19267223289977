define("dashboard/serializers/shogun/kafka-datastore", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.generateID = generateID;

  var _default = _application.default.extend({
    extractId(_modelClass, resourceHash) {
      return generateID(resourceHash.addon_id, resourceHash.name);
    },

    normalize(typeClass, hash) {
      const addonId = hash.addon_id;
      hash.links = {
        topicDetails: `/data/kafka/v0/clusters/${addonId}/topics`
      };
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;

  function generateID(addonId, name) {
    return `${addonId}:${name}`;
  }
});