define("dashboard/adapters/shogun/postgres-config", ["exports", "dashboard/adapters/shogun"], function (_exports, _shogun) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _shogun.default.extend({
    urlForFindRecord(id) {
      return `${this.host}/postgres/v0/databases/${id}/config`;
    },

    urlForUpdateRecord(id) {
      return this.urlForFindRecord(id);
    }

  });

  _exports.default = _default;
});