define("dashboard/templates/components/efp-app-list-free-resources-warning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Qu00lZDV",
    "block": "[[[41,[30,0,[\"showWarning\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@title\"],[[30,0,[\"tooltipMessage\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[11,1],[24,0,\"orange\"],[17,1],[12],[1,\"\\n      \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"warning-badge-16\",16,\"malibu-fill-gradient-orange nudge-up--1\"]],null],[1,\"\\n      \"],[1,[30,0,[\"warningMessage\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"&separator\"],false,[\"if\",\"x-tooltip\",\"malibu-icon\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/efp-app-list-free-resources-warning.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});