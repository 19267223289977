define("dashboard/components/app/settings/buildpack-installations-list", ["exports", "@ember/object/computed", "@ember/service", "@ember/component", "@ember/object", "dashboard/mixins/purple-list"], function (_exports, _computed, _service, _component, _object, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_purpleList.default, {
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    classNames: ['buildpack-installations-list'],
    hasChanged: false,
    _persistedBuildpackInstallations: (0, _computed.filterBy)('buildpackInstallations', 'pending', undefined),
    _buildpackSortingKey: ['ordinal:asc'],
    orderedBuildpackInstallations: (0, _computed.sort)('_persistedBuildpackInstallations', '_buildpackSortingKey'),
    hasAnyBuildpackInstallations: (0, _computed.gt)('orderedBuildpackInstallations.length', 0),
    hasMultipleBuildpackInstallations: (0, _computed.gt)('orderedBuildpackInstallations.length', 1),
    buildpackUrlConfigValue: (0, _object.computed)('configVars', function () {
      const configVars = this.get('configVars.listOfVariables') || [];
      const configVar = configVars.find(cv => {
        return cv.get('key') === 'BUILDPACK_URL';
      });
      return configVar && configVar.get('value');
    }),
    names: (0, _object.computed)('buildpackInstallations.[]', function () {
      const buildpackInstallations = this.buildpackInstallations;
      const names = buildpackInstallations.map(function (installation) {
        return installation.get('name');
      });
      return names;
    }),
    hasAnyDuplicates: (0, _object.computed)('names', function () {
      const names = this.names;

      if (names.uniq().length !== names.length) {
        return true;
      } else {
        return false;
      }
    }),

    createNewItem() {
      const newItem = this.store.createRecord('buildpack-installation', {
        app: this.app,
        pending: true
      });
      return newItem;
    },

    titleForDelete: (0, _object.computed)('item.name', function () {
      const buildpack = this.get('item.name');
      return `Delete <small>(${buildpack})</small>`;
    }),

    unloadItem() {
      const item = this.item;
      this.store.unloadRecord(item);
      this.set('item', null);
    },

    actions: {
      rollbackRecord() {
        this.unloadItem();
      },

      save() {
        const app = this.app;
        const buildpackInstallations = app.get('buildpackInstallations');
        const pendingSave = app.updateBuildpackInstallations(buildpackInstallations);

        this._super(...arguments);

        return pendingSave.then(() => {
          this.analytics.logEvent('Buildpack', 'Added');
          this.unloadItem();
          this.toggleProperty('isAdding');
          this.set('hasChanged', true);
        });
      },

      delete() {
        const toBeDeleted = this.item;
        const app = this.app;
        const buildpackInstallations = app.get('buildpackInstallations');
        const pendingSave = app.updateBuildpackInstallations(buildpackInstallations, toBeDeleted);

        this._super(...arguments);

        return pendingSave.then(() => {
          this.analytics.logEvent('Buildpack', 'Deleted');
          this.set('item', null);
          this.send('toggleDeleteConfirmModal');
          this.set('hasChanged', true); // If the last buildpack was deleted but there's a `BUILDPACK_URL` config
          // var set, we want to make sure that one shows up.

          this.get('app').hasMany('buildpackInstallations').reload();
        });
      },

      reorderBuildpacks(buildpackInstallations) {
        let hasReordered;
        buildpackInstallations.forEach((buildpackInstallation, index) => {
          if (buildpackInstallation.get('ordinal') !== index) {
            buildpackInstallation.set('ordinal', index);
            hasReordered = true;
          }
        });

        if (hasReordered) {
          this.set('hasChanged', true);
          this.app.updateBuildpackInstallations(buildpackInstallations);
          this.analytics.logEvent('Buildpack', 'Reordered');
        }
      }

    }
  });

  _exports.default = _default;
});