define("dashboard/components/enterprise-account/daily-usage-export-form", ["exports", "@ember/component", "moment", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/string", "dashboard/config/environment", "jquery"], function (_exports, _component, _moment, _object, _computed, _service, _string, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MonthYearFormat = 'YYYY-MM';
  const DayFormat = 'DD';
  const DisplayDateFormat = 'MMMM YYYY';

  const EarliestUsageDate = _moment.default.utc('2019-01-01');

  var _default = _component.default.extend({
    // --------------------------------------------------------------------------
    // Services
    analytics: (0, _service.inject)(),
    session: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Arguments
    enterpriseAccount: null,
    team: null,
    // --------------------------------------------------------------------------
    // State
    selectedStartMonthYear: null,
    selectedStartDay: null,
    selectedEndMonthYear: null,
    selectedEndDay: null,
    // --------------------------------------------------------------------------
    // Computed Properties
    bearerToken: (0, _computed.readOnly)('session.data.authenticated.access_token'),
    isTeamExport: (0, _computed.notEmpty)('team'),
    itemCreatedAt: (0, _computed.or)('team.createdAt', 'enterpriseAccount.createdAt'),
    itemId: (0, _computed.or)('team.id', 'enterpriseAccount.id'),
    enterpriseAccountId: (0, _computed.readOnly)('enterpriseAccount.id'),
    teamId: (0, _computed.readOnly)('team.id'),
    usageDownloadUrl: (0, _object.computed)('isTeamExport', 'enterpriseAccountId', 'teamId', function () {
      if (this.isTeamExport) {
        return `${_environment.default.particleboardUrl}/teams/${this.teamId}/usage/daily`;
      } else {
        return `${_environment.default.particleboardUrl}/enterprise-accounts/${this.enterpriseAccountId}/usage/daily`;
      }
    }),
    usageStartingFromDescription: (0, _object.computed)('minStartDate', function () {
      const minStartDate = this.minStartDate;

      if (minStartDate.isAfter(EarliestUsageDate)) {
        return (0, _string.htmlSafe)('for the past <strong>6 months</strong>');
      } else {
        return (0, _string.htmlSafe)('starting <strong>January 1, 2019</strong>');
      }
    }),
    startMonthOptions: (0, _object.computed)('minStartDate', function () {
      const startOfMinStartDate = this.minStartDate.clone().startOf('month');

      const startOfMonth = _moment.default.utc().startOf('month');

      const cursor = _moment.default.max(startOfMonth, startOfMinStartDate.clone());

      const result = [];

      while (cursor.isSameOrAfter(startOfMinStartDate)) {
        const value = cursor.format(MonthYearFormat);
        const label = cursor.format(DisplayDateFormat);
        result.pushObject({
          value,
          label
        });
        cursor.subtract(1, 'month');
      }

      return result;
    }),
    startDayOptions: (0, _object.computed)('selectedStartMonthYear', 'minStartDate', 'maxStartDate', function () {
      const selected = this.selectedStartDate;
      const min = this.minStartDate;
      const max = this.maxStartDate;
      return this.dayOptions(selected, min, max);
    }),
    endMonthOptions: (0, _object.computed)('selectedStartDate', 'maxEndDate', function () {
      const selectedStartDate = this.selectedStartDate;
      const endDate = this.maxEndDate;
      const cursor = endDate.clone();
      const result = [];
      const firstOfSelectedStartDateMonth = selectedStartDate.clone().startOf('month');

      while (cursor.isSameOrAfter(firstOfSelectedStartDateMonth)) {
        const value = cursor.format(MonthYearFormat);
        const label = cursor.format(DisplayDateFormat);
        result.pushObject({
          value,
          label
        });
        cursor.subtract(1, 'month');
      }

      return result;
    }),
    endDayOptions: (0, _object.computed)('selectedEndMonthYear', 'minEndDate', 'maxEndDate', function () {
      const selected = this.selectedEndDate;
      const min = this.minEndDate;
      const max = this.maxEndDate;
      return this.dayOptions(selected, min, max);
    }),
    selectedStartDate: (0, _object.computed)('selectedStartMonthYear', 'selectedStartDay', function () {
      return this.dateFrom(this.selectedStartMonthYear, this.selectedStartDay);
    }),
    selectedEndDate: (0, _object.computed)('selectedEndMonthYear', 'selectedEndDay', function () {
      return this.dateFrom(this.selectedEndMonthYear, this.selectedEndDay);
    }),
    minStartDate: (0, _object.computed)(function () {
      const sixMonthsAgo = this.today().subtract(6, 'months');
      return _moment.default.max(sixMonthsAgo, EarliestUsageDate);
    }),
    maxStartDate: (0, _object.computed)(function () {
      return this.today();
    }),
    minEndDate: (0, _object.computed)('selectedStartDate', function () {
      return this.selectedStartDate;
    }),
    maxEndDate: (0, _object.computed)('selectedStartDate', function () {
      const selectedStartDate = this.selectedStartDate; // 31 days is the max allowed range.

      const maxEndDate = selectedStartDate.clone().add(31, 'days');
      return _moment.default.min(maxEndDate, this.today());
    }),
    startDateFormInput: (0, _object.computed)('selectedStartMonthYear', 'selectedStartDay', function () {
      return `${this.selectedStartMonthYear}-${this.selectedStartDay}`;
    }),
    endDateFormInput: (0, _object.computed)('selectedEndMonthYear', 'selectedEndDay', function () {
      return `${this.selectedEndMonthYear}-${this.selectedEndDay}`;
    }),

    // --------------------------------------------------------------------------
    // Lifecycle
    init() {
      this._super(...arguments); // Initialize start and end dates to current month


      const today = this.today();
      this.set('selectedStartMonthYear', today.format(MonthYearFormat));
      this.set('selectedStartDay', '01');
      this.set('selectedEndMonthYear', today.format(MonthYearFormat));
      this.set('selectedEndDay', today.format(DayFormat));
    },

    // --------------------------------------------------------------------------
    // Helpers
    dayOptions(selected, min, max) {
      let from = 1;
      let to = selected.daysInMonth(); // Prevent going back before the min date

      if (selected.month() === min.month() && selected.year() === min.year()) {
        from = min.date();
      } // Prevent going past the max date


      if (selected.month() === max.month() && selected.year() === max.year()) {
        to = max.date();
      }

      const length = to - from + 1;
      return Array(length).fill().map((_, i) => {
        const label = i + from;
        const value = label < 10 ? `0${label}` : `${label}`;
        return {
          label,
          value
        };
      });
    },

    today() {
      return _moment.default.utc().startOf('day');
    },

    dateFrom(monthYear, day) {
      let date = _moment.default.utc(`${monthYear}-${day}`);

      if (!date.isValid()) {
        // Selected day could be out of range for new month/year, like when
        // October 31 is selected and the month is changed to September, which
        // only has 30 days. Set to last day of the selected month in that case.
        date = _moment.default.utc(`${monthYear}-01`).endOf('month');
      }

      return date;
    },

    dateInRange(date, min, max) {
      if (date.isAfter(max)) {
        return max;
      } else if (date.isBefore(min)) {
        return min;
      } else {
        return date;
      }
    },

    resetEndDate() {
      // Reset end date if it's out of range due to a start date change.
      this.setValidEndDate(this.selectedEndDate);
    },

    setValidStartDate(date) {
      const validDate = this.dateInRange(date, this.minStartDate, this.maxStartDate);
      this.set('selectedStartMonthYear', validDate.format(MonthYearFormat));
      this.set('selectedStartDay', validDate.format(DayFormat));
    },

    setValidEndDate(date) {
      const validDate = this.dateInRange(date, this.minEndDate, this.maxEndDate);
      this.set('selectedEndMonthYear', validDate.format(MonthYearFormat));
      this.set('selectedEndDay', validDate.format(DayFormat));
    },

    actions: {
      selectStartMonthYear(startMonthYear) {
        const startDate = this.dateFrom(startMonthYear, this.selectedStartDay);
        this.setValidStartDate(startDate); // Make sure end date is also within new bounds

        this.resetEndDate();
      },

      selectStartDay(startDay) {
        const startDate = this.dateFrom(this.selectedStartMonthYear, startDay);
        this.setValidStartDate(startDate); // Make sure end date is also within new bounds

        this.resetEndDate();
      },

      selectEndMonthYear(endMonthYear) {
        const endDate = this.dateFrom(endMonthYear, this.selectedEndDay);
        this.setValidEndDate(endDate);
      },

      selectEndDay(endDay) {
        const endDate = this.dateFrom(this.selectedEndMonthYear, endDay);
        this.setValidEndDate(endDate);
      },

      willDownloadUsage() {
        if (this.isTeamExport) {
          const analyticsNoun = this.team.analyticsNoun;
          this.analytics.logEvent(`${analyticsNoun} Daily Usage`, 'Downloaded');
        } else {
          this.analytics.logEvent('Enterprise Account Daily Usage', 'Downloaded');
        }

        const form = (0, _jquery.default)(this.element).find('form');
        return form.submit();
      }

    }
  });

  _exports.default = _default;
});