define("dashboard/adapters/authorization", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'oauth',

    urlForUpdateRecord(id, modelName, snapshot) {
      let url = this._super(...arguments);

      if (snapshot.adapterOptions && snapshot.adapterOptions.regenerate) {
        url += '/actions/regenerate-tokens';
      }

      return url;
    },

    urlForQueryRecord(query, modelName) {
      return this.urlForFindRecord(query.name, modelName);
    }

  });

  _exports.default = _default;
});