define("dashboard/templates/components/stencil/overview/app-overview", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "27bZtzdG",
    "block": "[[[10,0],[14,1,\"overview-page\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"overview-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"limit-width\"],[12],[1,\"\\n      \"],[1,[34,0]],[1,\"\\n      \"],[1,[34,0]],[1,\"\\n      \"],[1,[34,0]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"overview-activity\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center pb2 mb1\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"stencil\"],[12],[1,\"\\n        lorem ipsum lorem ipsum\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"stencil\"],[12],[1,\"\\n        lorem ipsum lorem\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[100]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[90]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[80]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[70]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[60]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[50]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[40]]]],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"opacity\"],[30]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"stencil/overview/content-box\",\"stencil/app/activity-item\"]]",
    "moduleName": "dashboard/templates/components/stencil/overview/app-overview.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});