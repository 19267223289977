define("dashboard/components/scheduler/forms/job-schedule", ["exports", "@ember/component", "@ember/object", "moment", "@ember/object/computed", "dashboard/utils/duration-converters"], function (_exports, _component, _object, _moment, _computed, _durationConverters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const generateOffsets = function (duration, offset) {
    const options = [];

    const start = _moment.default.duration();

    const end = start.clone().add(1, duration);
    const formatFn = duration === 'hour' ? _durationConverters.formatMomentDuration1Hour : _durationConverters.formatMomentDuration24Hours;

    while (start < end) {
      const option = {
        value: start.minutes() + start.hours() * 60,
        displayName: formatFn(start)
      };
      options.push(option);
      start.add(offset, 'minutes');
    }

    return options;
  };

  var _default = _component.default.extend({
    'data-test-target': 'job-schedule-form',
    classNames: ['flex'],
    frequency: (0, _computed.alias)('job.every'),
    offset: (0, _computed.alias)('job.at'),
    frequencyOptions: [{
      value: 10,
      displayName: 'Every 10 minutes'
    }, {
      value: 60,
      displayName: 'Every hour at...'
    }, {
      value: 1440,
      displayName: 'Every day at...'
    }],
    offsetOptions: (0, _object.computed)('frequency', function () {
      switch (this.frequency) {
        case 60:
          return generateOffsets('hour', 10);

        case 1440:
          return generateOffsets('day', 30);
      }
    }),
    using24Hours: (0, _computed.equal)('frequency', 1440),
    offsetDropdownClassNames: (0, _object.computed)('using24Hours', function () {
      let classNames = 'hk-select ml2 flex-auto z-1';

      if (this.using24Hours) {
        classNames += ' br--left';
      }

      return classNames;
    }),
    actions: {
      selectFrequency(frequency) {
        switch (frequency) {
          case 10:
            this.set('offset', null);
            break;

          case 60:
            this.set('offset', 0);
            break;

          case 1440:
            this.set('offset', 0);
            break;
        }

        this.set('frequency', frequency);
      },

      selectOffset(offset) {
        this.set('offset', offset);
      }

    }
  });

  _exports.default = _default;
});