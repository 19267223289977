define("dashboard/components/efp-rapid-upgrade-banner", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfpRapidUpgradeBannerComponent = (_class = class EfpRapidUpgradeBannerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "isCollapsed", _descriptor2, this);

      _defineProperty(this, "features", [{
        icon: 'dynomite-eco-16',
        iconClass: 'malibu-fill-gradient-purple',
        title: 'New Eco Dynos Plan',
        description: 'Run apps using your monthly pool of 1000 Eco dyno hours. Dynos sleep during inactivity to conserve your dyno hours.',
        buttonText: 'Subscribe to Eco for $5',
        hasEcoSubscriptionButton: true
      }, {
        icon: 'datastore-16',
        iconClass: 'malibu-fill-gradient-dark-gray',
        title: 'New Low-Cost Data Plans',
        description: 'New Mini plans for Heroku Postgres (10K rows, $5/month) and Heroku Data for Redis® (25 MB, $3/month).',
        buttonText: 'Learn More',
        buttonLink: 'https://blog.heroku.com/new-low-cost-plans'
      }, {
        icon: 'app-review-16',
        iconClass: 'malibu-fill-gradient-dark-gray',
        title: 'Review Additional Costs',
        description: 'Dynos and data used for Review Apps and Scheduler jobs count towards your usage and consume dyno hours.',
        buttonText: 'Learn More',
        buttonLink: 'https://devcenter.heroku.com/articles/usage-and-billing'
      }, {
        icon: 'service-github-16',
        iconClass: 'malibu-fill-gradient-dark-gray',
        title: 'Heroku for Github Students',
        description: 'Check if you qualify for our new Student program to get credits for your Heroku usage.',
        buttonText: 'View Requirements',
        buttonLink: 'https://www.heroku.com/github-students'
      }]);
    }

    collapseBanner() {
      this.isCollapsed = true;
    }

    expandBanner() {
      this.isCollapsed = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "collapseBanner", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "collapseBanner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expandBanner", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "expandBanner"), _class.prototype)), _class);
  _exports.default = EfpRapidUpgradeBannerComponent;
});