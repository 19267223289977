define("dashboard/templates/components/org/member-confirm-delete-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zvrB2l+p",
    "block": "[[[10,0],[14,0,\"delete-member-modal\"],[12],[1,\"\\n\"],[41,[30,0,[\"isFederated\"]],[[[1,\"  \"],[10,2],[12],[1,\"\\n    Enter the email address \"],[10,\"strong\"],[12],[1,[30,0,[\"email\"]]],[13],[1,\" below to confirm\\n    you want to remove this user from the Heroku team:\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"value\",\"key-up\",\"classNames\",\"autofocus\"],[[30,0,[\"confirmDeleteMember\"]],[28,[37,2],[[30,0],\"confirmEmailAddress\"],null],\"form-control\",true]]]],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Because this user is logged in via SSO, the email must be deleted from\\n    the identity provider as well to fully disable access. \"],[10,\"strong\"],[12],[1,\"We suggest\\n    you do this now\"],[13],[1,\".\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,2],[14,0,\"confirm\"],[12],[1,\"\\n    \"],[1,[30,0,[\"confirmationText\"]]],[1,\" \"],[10,\"strong\"],[12],[1,[30,0,[\"email\"]]],[13],[1,\"?\\n  \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"if\",\"input\",\"action\"]]",
    "moduleName": "dashboard/templates/components/org/member-confirm-delete-modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});