define("dashboard/adapters/collaborator", ["exports", "@ember/service", "dashboard/adapters/application", "dashboard/serializers/collaborator", "dashboard/mixins/adapters/range-aware-adapter"], function (_exports, _service, _application, _collaborator, _rangeAwareAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rangeAwareAdapter.default, {
    range: {
      attribute: 'email',
      max: 1000
    },
    dsErrorMonitor: (0, _service.inject)(),

    urlForCreateRecord(modelName, snapshot) {
      const appId = snapshot.record.get('app.id');
      return `${this.host}/apps/${appId}/collaborators`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const appId = snapshot.belongsTo('app').id;
      const userId = (0, _collaborator.extractUserID)(snapshot.id);
      return `${this.host}/apps/${appId}/collaborators/${userId}`;
    },

    urlForQuery(query) {
      const {
        app
      } = query;
      delete query.app;
      return `${this.host}/apps/${app}/collaborators`;
    }

  });

  _exports.default = _default;
});