define("dashboard/controllers/app/resources/attachments", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    actions: {
      deleteAttachment(attachment) {
        this.set('attachment', attachment);
        this.toggleProperty('isShowingDeleteAttachmentConfirm');
      },

      confirmDeleteAttachment(attachment, dismiss) {
        return attachment.destroyRecord().catch(() => {
          attachment.rollbackAttributes();
        }).finally(() => {
          dismiss();
        });
      },

      closeSlidePanel() {
        this.set('isShowingDeleteAttachmentConfirm', false);
        this.transitionToRoute('app.resources');
      }

    }
  });

  _exports.default = _default;
});