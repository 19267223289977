define("dashboard/routes/team", ["exports", "rsvp", "@ember/routing/route", "dashboard/mixins/document-title", "@ember/service"], function (_exports, _rsvp, _route, _documentTitle, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    breadcrumbs: (0, _service.inject)(),
    queryParams: {
      bulkAppTransfer: {
        as: 'bulk-app-transfer',
        replace: true
      }
    },

    // Get the model from the list we already have
    model(params) {
      const {
        teams
      } = this.modelFor('protected');
      return (0, _rsvp.hash)({
        teams,
        team: this.store.queryRecord('team', {
          name: params['team_name']
        })
      });
    },

    afterModel(model) {
      if (model.team) {
        // Block on feature flags because they are needed to determine routing.
        return (0, _rsvp.all)([model.team.get('features')]);
      } else {
        throw {
          status: 404
        };
      }
    },

    get documentTitle() {
      return this.modelFor('team').team.get('name');
    },

    renderTemplate(controller, model) {
      let enterpriseAccount;

      if (model.team.role !== 'collaborator') {
        enterpriseAccount = model.team.enterprise;
      }

      this.set('breadcrumbs.trail', {
        team: model.team,
        enterpriseAccount
      });
      this.render('breadcrumbs', {
        outlet: 'header-nav',
        into: 'protected'
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});