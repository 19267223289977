define("dashboard/templates/components/app/formation-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VYU1jMvc",
    "block": "[[[10,0],[14,0,\"process-description\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"name\"],[12],[1,[30,0,[\"process\",\"type\"]]],[13],[1,\"\\n  \"],[10,\"code\"],[14,0,\"command\"],[15,\"title\",[30,0,[\"process\",\"command\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"process\",\"command\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"isNotEditing\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"process-usage\",[52,[30,0,[\"isScaledToZero\"]],\" process--off\"]]]],[12],[1,\"\\n      \"],[10,1],[15,0,[29,[[52,[30,0,[\"hasChangedQuantity\"]],\"value--dirty\"],\" u-margin-Rs\"]]],[12],[1,\"\\n        \"],[1,[30,0,[\"process\",\"quantity\"]]],[1,\" \"],[10,1],[14,0,\"suffix\"],[12],[1,[28,[35,1],[[30,0,[\"process\",\"quantity\"]],\"dyno\",\"dynos\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"isEnterpriseOrg\"]],[[[1,\"        \"],[10,1],[15,0,[29,[[52,[30,0,[\"hasChangedQuantity\"]],\"value--dirty\"]]]],[12],[1,\"\\n          \"],[1,[28,[35,2],[[30,0,[\"process\",\"dynoUnits\"]]],null]],[1,\" \"],[10,1],[14,0,\"suffix\"],[12],[1,[28,[35,1],[[30,0,[\"process\",\"dynoUnits\"]],\"unit\",\"units\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[15,0,[29,[[52,[30,0,[\"hasChangedQuantity\"]],\"value--dirty\"]]]],[12],[1,\"\\n          \"],[1,[28,[35,3],[[30,0,[\"process\",\"price\"]]],null]],[1,\" \"],[10,1],[14,0,\"suffix\"],[12],[1,\"/mo\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"pluralize-word\",\"number-helper\",\"currency-format\"]]",
    "moduleName": "dashboard/templates/components/app/formation-details.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});