define("dashboard/routes/account/applications/new-client", ["exports", "dashboard/routes/account/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    queryParams: {
      success: {
        replace: true
      }
    },

    model() {
      return this.store.createRecord('client');
    },

    deactivate() {
      this.store.peekAll('client').filterBy('isNew', true).forEach(newClient => {
        newClient.unloadRecord();
      });
    }

  });

  _exports.default = _default;
});