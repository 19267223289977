define("dashboard/components/metrics/charts/visualizations/ruby-pool-usage", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/visualizations/aggregate-chart", "@ember/object", "dashboard/utils/metrics/has-non-zero-values"], function (_exports, _computed, _aggregateChart, _object, _hasNonZeroValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _aggregateChart.default.extend({
    tagName: 'metrics-ruby-puma-pool-usage-chart',
    'data-test-target': 'metrics-ruby-chart',
    title: 'Ruby: Puma Pool Usage',
    devCenterName: 'language-runtime-metrics-ruby#puma-pool-usage',
    isBeta: true,
    unit: 'percentage',
    secondaryUnit: 'threads',
    secondaryValueFormat: '0[.]0a',
    hasExtraData: true,
    valueFormat: '0.[00]',
    timeSeriesName: 'Usage',
    defaultMaxValue: 4,
    isShowingSpawned: true,
    timeSeriesSpawned: (0, _object.computed)('data.pumaPoolThreadsSpawnedPerc', 'data.pumaPoolThreadsSpawned', 'isShowingMaxThreads', function () {
      return _object.default.create({
        name: 'Spawned',
        color: 'info-light',
        type: 'metrics/charts/line-chart-elements/line-graph',
        index: 0,
        data: this.get('data.pumaPoolThreadsSpawnedPerc'),
        extraData: this.get('data.pumaPoolThreadsSpawned'),
        isVisible: this.isShowingSpawned,
        key: 'Spawned'
      });
    }),
    maxData: (0, _computed.alias)('data.pumaPoolUsageMaxPerc'),
    avgData: (0, _computed.alias)('data.pumaPoolUsageAvgPerc'),
    minData: (0, _computed.alias)('data.pumaPoolUsageMinPerc'),
    maxDataExtra: (0, _computed.alias)('data.pumaPoolUsageMax'),
    avgDataExtra: (0, _computed.alias)('data.pumaPoolUsageAvg'),
    minDataExtra: (0, _computed.alias)('data.pumaPoolUsageMin'),
    hasMinData: (0, _hasNonZeroValues.default)('data.pumaPoolUsageMin'),
    chartTimeSeries: (0, _object.computed)('timeSeriesMax', 'timeSeriesAvg', 'timeSeriesMin', 'timeSeriesSpawned', 'hasMinData', function () {
      const timeSeries = [this.timeSeriesSpawned, this.timeSeriesMax, this.timeSeriesAvg];

      if (this.hasMinData) {
        timeSeries.push(this.timeSeriesMin);
      }

      return timeSeries;
    })
  });

  _exports.default = _default;
});