define("dashboard/services/payment-gateway", ["exports", "@ember/service", "ember-concurrency", "@ember/object", "@ember/object/computed", "dashboard/services/payment-gateway/braintree-gateway", "dashboard/services/payment-gateway/hosted-field-states", "@ember/application", "@ember/runloop", "dashboard/services/payment-gateway/input-styles"], function (_exports, _service, _emberConcurrency, _object, _computed, _braintreeGateway, _hostedFieldStates, _application, _runloop, _inputStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BraintreeSupportedCardTypes = _exports.BRAINTREE_HOSTED_FIELD_IDS = void 0;
  const DOM_ID_PREFIX = 'hosted-fields';
  const CC_NUMBER = 'number';
  const CC_CVV = 'cvv';
  const CC_EXPIRATION_DATE = 'expirationDate';
  const CC_FIELDS = [CC_NUMBER, CC_CVV, CC_EXPIRATION_DATE];

  function braintreeHostedFieldID(fieldName) {
    return `${DOM_ID_PREFIX}-${fieldName}`;
  }

  const BRAINTREE_HOSTED_FIELD_IDS = {
    number: braintreeHostedFieldID(CC_NUMBER),
    cvv: braintreeHostedFieldID(CC_CVV),
    expirationDate: braintreeHostedFieldID(CC_EXPIRATION_DATE)
  }; // https://braintree.github.io/braintree-web/current/module-braintree-web_hosted-fields.html#~field
  // Our supported card types: MasterCard, Visa, American Express, Discover, Diners, JCB, Elo & Union Pay
  // Note: the docs say the correct form is `mastercard`, but the actual type in the
  // response from Braintree is `master-card`, so we're including both here to cover all cases.

  _exports.BRAINTREE_HOSTED_FIELD_IDS = BRAINTREE_HOSTED_FIELD_IDS;
  const BraintreeSupportedCardTypes = ['visa', 'mastercard', 'master-card', 'american-express', 'discover', 'diners-club', 'jcb', 'union-pay', 'elo'];
  _exports.BraintreeSupportedCardTypes = BraintreeSupportedCardTypes;

  function braintreeHostedField(fieldName, placeholder) {
    const fieldID = BRAINTREE_HOSTED_FIELD_IDS[fieldName];
    return {
      selector: `#${fieldID}`,
      placeholder
    };
  }

  function generateFieldInformation(fieldName) {
    const id = BRAINTREE_HOSTED_FIELD_IDS[fieldName];
    const state = fieldName === CC_NUMBER ? new _hostedFieldStates.CreditCardNumberHostedFieldState({}) : new _hostedFieldStates.HostedFieldState({});
    return {
      id,
      state,
      fieldName,
      changesetKey: fieldName
    };
  }

  function generateDefaultFields() {
    return CC_FIELDS.reduce((acc, fieldName) => {
      acc[fieldName] = generateFieldInformation(fieldName);
      return acc;
    }, {});
  }

  function braintreeInitOptions() {
    const options = {
      number: braintreeHostedField('number', '4444 5555 6666 7777'),
      cvv: braintreeHostedField('cvv', '123'),
      expirationDate: braintreeHostedField('expirationDate', '10/2019')
    }; // See `supportedCardBrands` section here:
    // https://braintree.github.io/braintree-web/current/module-braintree-web_hosted-fields.html#~field
    // We're only specifying the ones we know we want to be enabled. The one's that
    // are not specified will default to what's set in the Braintree admin, and the
    // remaining card types (like Maestro) are all currently disabled.

    options.number.supportedCardBrands = BraintreeSupportedCardTypes.reduce((supportedTypes, ccType) => {
      supportedTypes[ccType] = true;
      return supportedTypes;
    }, {});
    return options;
  }
  /**
   * A Service that communicates to Braintree via a Gateway (a wrapper for Braintree)
   * This service wraps the state of the Braintree Gateway so that the payment-method/form
   * component can easily use its state and communicate with Braintree.
   *
   * Initializing a Braintree HostedField (the iframes that handle sensitive card
   * input) takes a few steps:
   *
   * `gateway.preload()` - Load any dependencies the gateway may require for runtime.
   * `gateway.initializeHostedForms()` Initialize HostedFields with the Braintree Web library
   * `gateway.tokenize()` - Get the payment method nonce (or throw an error if card is invalid)
   * `gateway.deviceData()` - Get the device data for Kount
   *
   * Check out docs/credit-card-forms.md in the top of this repo for more context.
   */


  var _default = _service.default.extend({
    Gateway: _braintreeGateway.default,
    braintreeFields: (0, _object.computed)(generateDefaultFields),
    _didNotRun: (0, _computed.equal)('_initializeHostedFields.performCount', 0),
    _renderedFields: (0, _object.computed)(function () {
      return [];
    }),
    _gateway: (0, _object.computed)('Gateway', function () {
      const owner = (0, _application.getOwner)(this);
      return this.Gateway.create(owner.ownerInjection());
    }),
    isLoading: true,

    initializeHostedFields() {
      return this._initializeHostedFields.perform();
    },

    teardownHostedFields() {
      this.set('isLoading', true);
      this.set('_renderedFields', []);
      this.set('braintreeFields', generateDefaultFields());

      this._initializeHostedFields.cancelAll();

      return this._gateway.teardownHostedFields();
    },

    /**
     * Tokenize the Hosted Field inputs. Returns a promise that resolves with
     * a payment nonce (string token).
     * https://braintree.github.io/braintree-web/current/HostedFields.html#tokenize
     */
    tokenize(options) {
      return this._tokenize.perform(options);
    },

    /**
     * Get DeviceData for Kount integration.
     */
    async deviceData() {
      try {
        return await this._gateway.deviceData();
      } catch (error) {
        // if kount is disabled on our Braintree account, we should not send device data
        // and still be able to add the form.
        // The only reason kount would be disabled on our Braintree account is if we,
        // Heroku, disabled it. Check in with Vault if Kount is not enabled.
        // This error actually occurs without any network requests; the Client Token
        // we get back from API/Braintree will tell the Braintree JS libraries
        // whether Kount is enabled or not.
        if (error && error.code === 'DATA_COLLECTOR_KOUNT_NOT_ENABLED') {
          return null;
        }

        throw error;
      }
    },

    /**
     * Tell the Gateway to preload any of its dependencies
     */
    preload() {
      return this._gateway.preload(...arguments);
    },

    /**
     * Tell the PaymentGateway Service that a field has rendered. When all the
     * fields (cardNumber, cvv, expirationDate) are marked as rendered, then
     * this service can initialize hostedFields. All fields must be rendered _before_
     * rendering hostedFields as the BraintreeWeb library needs real DOM to render
     * its inputs into.
     *
     * @param {String} fieldName 'cardNumber' | 'cvv' | 'expirationDate'
     */
    didRenderField(fieldName) {
      const renderedFields = this.get('_renderedFields');
      this.set('_renderedFields', renderedFields.concat(fieldName));
    },

    didRenderAllFields: (0, _object.computed)('_renderedFields', function () {
      const rendered = this._renderedFields;

      for (const field of CC_FIELDS) {
        if (rendered.indexOf(field) === -1) {
          return false;
        }
      }

      return true;
    }),
    _tokenize: (0, _emberConcurrency.task)(function* (options) {
      try {
        const {
          nonce,
          details
        } = yield this._gateway.tokenize(options);
        return {
          nonce,
          details
        };
      } catch (e) {
        switch (e.code) {
          case 'HOSTED_FIELDS_FIELDS_EMPTY':
            this._handleHostedFieldsFieldsEmpty();

            break;

          case 'HOSTED_FIELDS_FIELDS_INVALID':
            this._handleHostedFieldsFieldsInvalid(e);

            break;

          default:
            break;
        }

        throw e;
      }
    }),

    // generate a fake braintree event for `isEmpty: true` to reset the
    // input state.
    _handleHostedFieldsFieldsEmpty() {
      CC_FIELDS.forEach(field => {
        this._updateState({
          emittedBy: field,
          fields: {
            [field]: {
              isEmpty: true,
              isValid: false,
              isPotentiallyValid: false
            }
          }
        });
      });
    },

    _handleHostedFieldsFieldsInvalid(error) {
      error.invalidFieldKeys.forEach(field => {
        this._updateState({
          emittedBy: field,
          fields: {
            [field]: {
              isValid: false,
              isPotentiallyValid: false
            }
          }
        });
      });
    },

    _initializeHostedFields: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('isLoading', true);

        if (!this.didRenderAllFields) {
          yield (0, _emberConcurrency.waitForProperty)(this, 'didRenderAllFields', true);
        }

        yield this._gateway.initializeHostedFields({
          styles: _inputStyles.default,
          fields: braintreeInitOptions()
        });
        const performUpdateStateTask = (0, _runloop.bind)(this, event => {
          this._updateStateTask.perform(event);
        }); // listen for events from braintree.

        this._gateway.on('empty', performUpdateStateTask);

        this._gateway.on('notEmpty', performUpdateStateTask);

        this._gateway.on('validityChange', performUpdateStateTask);

        this._gateway.on('blur', (0, _runloop.bind)(this, event => {
          this._updateStateTask.perform(event, {
            isFocused: false
          });
        }));

        this._gateway.on('focus', (0, _runloop.bind)(this, event => {
          this._updateStateTask.perform(event, {
            isFocused: true,
            wasEntered: true
          });
        }));

        this._gateway.on('cardTypeChange', performUpdateStateTask);
      } catch (error) {
        this.reporter.error(error);
        throw error;
      } finally {
        this.set('isLoading', false);
      }
    }).restartable(),
    _updateStateTask: (0, _emberConcurrency.task)(function* (event, extraProps) {
      yield;

      this._updateState(event, extraProps);
    }),

    _updateState(event) {
      let extraProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const emittedBy = event.emittedBy;
      const oldState = this.get(`braintreeFields.${emittedBy}.state`);
      const newState = oldState.update(event, extraProps);
      this.set(`braintreeFields.${emittedBy}.state`, newState);
    }

  });

  _exports.default = _default;
});