define("dashboard/components/net-promoter-score/why-textarea", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    'data-test-target': 'nps-survey-why-textarea',
    isHighRating: null,
    placeholderText: (0, _object.computed)('isHighRating', function () {
      if (this.isHighRating) {
        return 'We are happy that we are able to provide value. Tell us more.';
      } else {
        return 'We want to improve Heroku to fit your needs. Tell us more.';
      }
    }),
    titleText: (0, _object.computed)('isHighRating', function () {
      if (this.isHighRating) {
        return 'What do you value most about Heroku?';
      } else {
        return 'What is the primary thing we could change that would cause you to improve your score?';
      }
    })
  });

  _exports.default = _default;
});