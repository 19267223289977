define("dashboard/templates/components/org/resources-totals", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cHRi0oSk",
    "block": "[[[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"app-28\",\"fill-light-gray mt1\",24]],null],[1,\"\\n\"],[10,\"dl\"],[14,0,\"flex-auto ph2 ma0\"],[12],[1,\"\\n  \"],[10,\"dt\"],[14,0,\"light-gray ttu b\"],[12],[1,\"Total Apps\"],[13],[1,\"\\n  \"],[10,\"dd\"],[14,0,\"white db code\"],[12],[1,[30,0,[\"appCount\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"dl\"],[14,0,\"ph2 ma0 tr\"],[12],[1,\"\\n  \"],[10,\"dt\"],[14,0,\"light-gray ttu b \"],[12],[1,\"Est Dyno Units\"],[13],[1,\"\\n  \"],[10,\"dd\"],[14,0,\"white db code\"],[12],[1,[30,0,[\"dynoUnitsSum\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"dl\"],[14,0,\"pl2 ma0 tr\"],[12],[1,\"\\n  \"],[10,\"dt\"],[14,0,\"light-gray ttu b\"],[12],[1,\"Est Addon Totals \"],[13],[1,\"\\n  \"],[10,\"dd\"],[14,0,\"white code\"],[12],[1,[28,[35,1],[[30,0,[\"addonsTotalSum\"]]],[[\"unit\"],[\"cents\"]]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"malibu-icon\",\"currency-format\"]]",
    "moduleName": "dashboard/templates/components/org/resources-totals.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});