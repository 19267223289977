define("dashboard/templates/components/net-promoter-score/thank-you", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "soA/2uCr",
    "block": "[[[41,[30,0,[\"isHighRating\"]],[[[6,[39,1],null,null,[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"flex items-center justify-center h5\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"tc gray\"],[12],[1,\"\\n        \"],[10,2],[14,0,\"b\"],[12],[1,\"Thank you for your responses.\"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"The Heroku Team <3\"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"Closing window in \"],[1,[30,0,[\"secondsUntilClose\"]]],[1,\"...\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],[[[1,\"  \"],[10,0],[14,0,\"flex items-center justify-center h5\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tc gray\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"b\"],[12],[1,\"Thank you for your responses.\"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"The Heroku Team <3\"],[13],[1,\"\\n      \"],[10,2],[14,0,\"f6\"],[12],[1,\"Closing window in \"],[1,[30,0,[\"secondsUntilClose\"]]],[1,\"...\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"animated-confetti\"]]",
    "moduleName": "dashboard/templates/components/net-promoter-score/thank-you.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});