define("dashboard/models/user-preference", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _model, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    preferences: (0, _model.attr)(),
    chartLayout: (0, _object.computed)('preferences.chart-layout', {
      get() {
        return this.get('preferences.chart-layout') || 'vertical';
      },

      set(_, value) {
        this.set('preferences.chart-layout', value);
      }

    }),
    throughputResolution: (0, _object.computed)('preferences.throughput-resolution', {
      get() {
        return this.get('preferences.throughput-resolution') || 'rps';
      },

      set(_, value) {
        this.set('preferences.throughput-resolution', value);
      }

    }),
    isAutorefreshingMetrics: (0, _object.computed)('preferences.is-auto-refreshing-metrics', {
      get() {
        const refreshPref = this.get('preferences.is-auto-refreshing-metrics');
        return (0, _utils.isPresent)(refreshPref) ? refreshPref : true;
      },

      set(_, value) {
        this.set('preferences.is-auto-refreshing-metrics', value);
      }

    }),
    hasDismissedLanguageDiscovery: (0, _computed.alias)('preferences.has-dismissed-language-discovery'),
    latestViewedTimeframeMetrics: (0, _object.computed)('preferences.latest-viewed-timeframe-metrics', {
      get() {
        return this.get('preferences.latest-viewed-timeframe-metrics') || 'past24hours';
      },

      set(_, value) {
        this.set('preferences.latest-viewed-timeframe-metrics', value);
      }

    }),
    pipelineCIAdBannerDismissals: (0, _object.computed)('preferences.pipeline-ci-ad-banner-dismissals', {
      get() {
        return this.get('preferences.pipeline-ci-ad-banner-dismissals') || [];
      },

      set(_, value) {
        this.set('preferences.pipeline-ci-ad-banner-dismissals', value);
      }

    }),
    teamFirstRunDismissals: (0, _object.computed)('preferences.team-first-run-dismissals', {
      get() {
        return this.get('preferences.team-first-run-dismissals') || [];
      },

      set(_, value) {
        this.set('preferences.team-first-run-dismissals', value);
        return value;
      }

    }),
    hasDismissedPersonalFirstRun: (0, _computed.alias)('preferences.has-dismissed-personal-first-run'),
    npsSurveyDismissal: (0, _computed.alias)('preferences.nps-survey-dismissal'),
    hasDismissedPipelinePermissionsBanner: (0, _computed.alias)('preferences.has-dismissed-pipeline-permissions-banner'),
    developerExperienceBannerDismissals: (0, _object.computed)('preferences.developer-experience-banner-dismissals', {
      get() {
        return this.get('preferences.developer-experience-banner-dismissals') || [];
      },

      set(_, value) {
        this.set('preferences.developer-experience-banner-dismissals', value);
        return value;
      }

    }),
    addonBannerDismissals: (0, _object.computed)('preferences.addons-banner-dismissals', {
      get() {
        return this.get('preferences.addons-banner-dismissals') || [];
      },

      set(_, value) {
        this.set('preferences.addons-banner-dismissals', value);
        return value;
      }

    }),
    isEligibleForAddonsBannerFlow: (0, _object.computed)('preferences.is-eligible-for-addons-banner-flow', {
      get() {
        const bannerFlowEligibility = this.get('preferences.is-eligible-for-addons-banner-flow');
        return (0, _utils.isPresent)(bannerFlowEligibility) ? bannerFlowEligibility : true;
      },

      set(_, value) {
        this.set('preferences.is-eligible-for-addons-banner-flow', value);
      }

    })
  });

  _exports.default = _default;
});