define("dashboard/components/datastore/datastore-chart", ["exports", "dashboard/components/metrics/charts/timeseries-chart", "@ember/object/computed", "@ember/array", "@ember/utils"], function (_exports, _timeseriesChart, _computed, _array, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Datastore chart base class
  // Generally just want to extend from this component
  var _default = _timeseriesChart.default.extend({
    classNames: ['metrics__datastore-chart'],
    headerContentComponent: 'datastore/datastore-chart-summary',
    minValues: (0, _computed.map)('timeSeriesData', function (dataPoints) {
      if ((0, _utils.isEmpty)(dataPoints)) {
        return 0;
      }

      return dataPoints.reduce((acc, dataPoint) => {
        if ((0, _array.isArray)(acc)) {
          acc = acc[1];
        }

        return Math.min(acc, dataPoint[1]);
      });
    }),
    summaryMinValues: (0, _computed.map)('timeSeriesData', function (dataPoints) {
      // We want to exclude null values from this selection of minimum values for use in
      // data summaries. Null represents a dead zone, this prevents us from showing 0 as the
      // minimum whenever there is a dead zone.
      if ((0, _utils.isEmpty)(dataPoints)) {
        return 0;
      }

      return dataPoints.reduce((acc, dataPoint) => {
        if ((0, _array.isArray)(acc)) {
          acc = acc[1];
        }

        if (dataPoint[1] === null) {
          return acc;
        }

        return Math.min(acc, dataPoint[1]);
      });
    }),
    isShowingConnectedChartHover: true,
    isShowingHeader: true,
    isCompactLayout: false,
    defaultMinValue: (0, _computed.readOnly)('minValues.firstObject'),
    summaryMinValue: (0, _computed.readOnly)('summaryMinValues.firstObject'),
    currentTimeframe: (0, _computed.readOnly)('timeframe')
  });

  _exports.default = _default;
});