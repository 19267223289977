define("dashboard/models/release", ["exports", "jquery", "rsvp", "@ember/object", "@ember/object/computed", "@ember-data/model", "dashboard/config/environment"], function (_exports, _jquery, _rsvp, _object, _computed, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    isRelease: true,
    addonPlanNames: (0, _model.attr)(),
    app: (0, _model.belongsTo)('app'),
    createdAt: (0, _model.attr)('date'),
    current: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    outputStreamUrl: (0, _model.attr)('string'),
    output_stream_url: (0, _computed.alias)('outputStreamUrl'),
    slug: (0, _model.belongsTo)('slug'),
    hasSlug: (0, _model.attr)('boolean'),
    status: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('string'),
    user: (0, _model.attr)(),
    version: (0, _model.attr)('number'),
    isConfigChange: (0, _computed.match)('description', /config vars$/),
    isDeploy: (0, _computed.match)('description', /Deploy/),
    isRollback: (0, _computed.match)('description', /Rollback/),
    isPromotion: (0, _computed.match)('description', /Promote/),
    isSuccess: (0, _computed.equal)('status', 'succeeded'),
    isPending: (0, _computed.equal)('status', 'pending'),
    isFailed: (0, _computed.equal)('status', 'failed'),
    isNotSuccess: (0, _computed.not)('isSuccess'),
    isCompleted: (0, _computed.equal)('status', 'completed'),
    hasSucceeded: (0, _computed.or)('isSucceded', 'isCompleted'),
    releaseStatusIsTerminal: (0, _computed.or)('isFailed', 'isSuccess', 'isCompleted'),
    codeWasReleased: (0, _object.computed)('isDeploy', 'isRollback', 'isPromotion', 'isSuccess', function () {
      return (this.isDeploy || this.isRollback || this.isPromotion) && this.isSuccess;
    }),
    commit: (0, _object.computed)('description', function () {
      const descriptionMatch = this.description.match(/Deploy[ed]* (.+)/);
      return descriptionMatch && descriptionMatch.pop();
    }),
    isModified: (0, _object.computed)('createdAt', 'updatedAt', function () {
      return this.createdAt !== this.updatedAt;
    }),
    // Normal releases are always successful and have matching timestamps.
    // Modified records and unsuccessful releases indicate there is a release
    // command to run.
    hasReleaseCommand: (0, _computed.or)('isNotSuccess', 'isModified'),
    // configVarsByGroup
    //
    // transform the raw `description` string into a JS object
    // where the _key_ is either `Set` or `Remove`
    // and the _value_ is a comma-delimited string of Variables
    configVarsByGroup: (0, _object.computed)('description', function () {
      const description = this.description; // There are explicitly four cases for Config Variable descriptions:

      const setCase = description.match(/Set (.+) config/);
      const addCase = description.match(/Add (.+) config/);
      const removeCase = description.match(/Remove (.+) config/);
      const setAndRemoveCase = description.match(/Set (.+) config vars?, remove (.+) config/);
      const removeAndSetCase = description.match(/Remove (.+) config vars?, set (.+) config/);

      if (setAndRemoveCase) {
        // e.g. Set NODE_ENV, ROLLBAR_SECRET, ETC config vars, remove SOMETHING_ELSE config var
        return {
          set: setAndRemoveCase[1],
          remove: setAndRemoveCase[2]
        };
      } else if (removeAndSetCase) {
        // e.g. Remove NODE_ENV, ROLLBAR_SECRET, ETC config vars, set SOMETHING_ELSE config var
        return {
          set: removeAndSetCase[2],
          remove: removeAndSetCase[1]
        };
      } else if (setCase) {
        // e.g. Set NODE_ENV, ROLLBAR_SECRET, ETC config vars
        return {
          set: setCase[1]
        };
      } else if (addCase) {
        // e.g. Add NODE_ENV, ROLLBAR_SECRET, ETC config vars
        // (this is a legacy formatting of this message)
        return {
          add: addCase[1]
        };
      } else if (removeCase) {
        // e.g. Remove NODE_ENV, ROLLBAR_SECRET, ETC config vars
        return {
          remove: removeCase[1]
        };
      }
    }),
    descriptionWithVersion: (0, _object.computed)('description', 'version', function () {
      return `${this.description} (v${this.version})`;
    }),

    rollback() {
      const appId = this.get('app.id');
      const data = {
        release: this.id
      };
      return new _rsvp.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: `${_environment.default.platformApiUrl}/apps/${appId}/releases`,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Accept: 'application/vnd.heroku+json; version=3'
          },
          withHerokuAuthorization: true,
          data: JSON.stringify(data)
        }).then(payload => {
          resolve(payload);
        }, error => {
          reject(error);
        });
      });
    }

  });

  _exports.default = _default;
});