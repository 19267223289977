define("dashboard/templates/components/process-stepper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "owHz0s5c",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"steps\"]]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[29,[\"process-stepper__step\\n    \",[52,[28,[37,3],[[30,0,[\"activeStep\"]],[30,2]],null],\"process-stepper__step--active\"],\"\\n    \",[52,[28,[37,4],[[30,2],[30,0,[\"activeStep\"]]],null],\"process-stepper__step--done\"]]]],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"step\",\"index\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"lt\"]]",
    "moduleName": "dashboard/templates/components/process-stepper.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});