define("dashboard/serializers/app/webhook", ["exports", "dashboard/serializers/application", "ember-data", "@ember/utils"], function (_exports, _application, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      app: {
        serialize: false,
        deserialize: 'records'
      }
    },

    normalizeSaveResponse(store, primaryModelClass, payload) {
      // if the response from the server does not include a secret then clear out the value
      // in the store since we don't want to have the value around any more
      if (!payload.secret) {
        payload.secret = null;
      }

      return this._super(...arguments);
    },

    serialize(snapshot, options) {
      const payload = this._super(snapshot, options); // we only want to serialize the secret if the webhook is new
      // or the secret changed during editing


      if (!snapshot.record.get('isNew') && !snapshot.changedAttributes()['secret']) {
        delete payload.secret;
      } // we don't want to serialize these fields if they are blank


      if (!(0, _utils.isPresent)(snapshot.record.get('name'))) {
        delete payload.name;
      }

      if (!(0, _utils.isPresent)(snapshot.record.get('secret'))) {
        delete payload.secret;
      }

      return payload;
    }

  });

  _exports.default = _default;
});