define("dashboard/helpers/lazy-property", ["exports", "rsvp", "@ember/object"], function (_exports, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * A decorator which returns a lazy loader computed property.
   * Executes the given loaderFn when it's first needed and replaces the property
   * with the result. For convenience, isLoading${propertyName} is set to true
   * until the promise completes. The loader function will only be called once,
   * subsequent calls return null until the property is resolved.
   *
   * e.g.
   *   lazyLoad(function() {
   *     return App.all();
   *   })
   *
   * @function lazyProperty - A computed property which lazily loads a value when it is first needed
   * @callback loaderFn - The callback is evaluated and the property is replaced with the result once it's resolved
   * @param defaultValue - An optional function or value to return by default until the property is resolved
   * @returns {Ember.computed} - A computed property to add to an Ember.Object
   */
  function lazyProperty(loaderFn) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};

    if (typeof defaultValue === 'function') {
      defaultValue = defaultValue();
    }

    return (0, _object.computed)(function (propertyName) {
      const loadingProperty = `isLoading${propertyName.capitalize()}`;

      if (this.get(loadingProperty)) {
        return defaultValue;
      } // eslint-disable-next-line ember/no-side-effects


      this.set(loadingProperty, true);
      (0, _rsvp.resolve)(loaderFn.call(this)).then(value => {
        this.set(propertyName, value);

        if (typeof this.trigger === 'function') {
          this.trigger(`didLoad${propertyName.capitalize()}`, value);
        }
      }).finally(() => this.set(loadingProperty, false));
      return defaultValue;
    });
  }

  var _default = lazyProperty;
  _exports.default = _default;
});