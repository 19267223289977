define("dashboard/templates/components/pipeline/app-setup-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WYad9D1x",
    "block": "[[[41,[30,0,[\"reviewApp\",\"isPending\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"size\",\"class\"],[\"small\",\"ml--4 mr1 nudge-up--1\"]]]],[1,\"\\n  \"],[10,1],[14,0,\"flex-auto f6 mid-gray ml1 pl--3\"],[12],[1,\"\\n    Preparing review app\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"reviewApp\",\"isCreating\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"size\",\"class\"],[\"small\",\"ml--4 mr1 nudge-up--1\"]]]],[1,\"\\n  \"],[10,1],[14,0,\"flex-auto f6 mid-gray ml1 pl--3\"],[12],[1,\"\\n    Creating review app\\n\"],[41,[30,0,[\"reviewApp\",\"hasApp\"]],[[[1,\"      • \"],[6,[39,2],null,[[\"class\",\"route\",\"model\"],[\"hk-link no-underline underline-hover\",\"pipelines.pipeline.index.app-setup\",[30,0,[\"reviewApp\",\"app\",\"id\"]]]],[[\"default\"],[[[[1,\"View log\"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isShowingFailedStatus\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"failure-ring-16\",[52,[30,0,[\"reviewApp\",\"isErroredPending\"]],\"flex-shrink-0 malibu-fill-gradient-gray mr1\",\"flex-shrink-0 malibu-fill-gradient-red mr1\"],16,\"Failed\"]],null],[1,\"\\n  \"],[10,1],[15,0,[52,[30,0,[\"reviewApp\",\"isErroredPending\"]],\"flex-auto f5 dark-gray\",\"flex-auto f5 red\"]],[12],[1,\"\\n    \"],[1,[30,0,[\"reviewApp\",\"errorMessage\"]]],[1,\"\\n\"],[41,[30,0,[\"reviewApp\",\"hasAppSetup\"]],[[[1,\"      •\\n\"],[6,[39,2],null,[[\"class\",\"route\",\"models\"],[\"link red underline\",\"pipelines.pipeline.index.app-setup\",[28,[37,4],[[30,0,[\"pipeline\",\"id\"]],[30,0,[\"reviewApp\",\"app\",\"id\"]]],null]]],[[\"default\"],[[[[1,\"          View log\\n\"]],[]]]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-loading-spinner\",\"link-to\",\"malibu-icon\",\"array\"]]",
    "moduleName": "dashboard/templates/components/pipeline/app-setup-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});