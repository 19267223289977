define("dashboard/models/space/vpn-connection", ["exports", "ember-data/relationships", "ember-data/attr", "ember-data/model"], function (_exports, _relationships, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    space: (0, _relationships.belongsTo)('space'),
    ikeVersion: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    publicIp: (0, _attr.default)('string'),
    routableCidrs: (0, _attr.default)(''),
    // ['192.168.100.14/24']
    spaceCidrBlock: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    statusMessage: (0, _attr.default)('string'),

    /** tunnels is an array of objects that look like this
      [
        {
          "last_status_change": "2016-10-25T22:09:05Z",
          "outside_ip_address": "52.44.146.197",
          "status": "UP",
          "status_message": "status message"
        }
      ],
    **/
    tunnels: (0, _attr.default)('')
  });

  _exports.default = _default;
});