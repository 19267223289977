define("dashboard/templates/components/app/settings/sni-endpoints-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "k+t2awBH",
    "block": "[[[10,0],[14,0,\"w-33 flex items-center\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"endpointStatusIcon\"]]],[[\"class\"],[\"mr2\"]]]],[1,\"\\n  \"],[10,1],[14,0,\"truncate\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"endpoint\",\"displayName\"]],[30,0,[\"endpoint\",\"name\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"w-20\"],[12],[1,\"\\n  \"],[1,[30,0,[\"endpoint\",\"type\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"w-25\"],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"endpoint\",\"sslCert\",\"expires_at\"]],\"MMMM DD, YYYY\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"w-10\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"hasWarning\"]],[30,0,[\"hasError\"]]],null],[[[1,\"    \"],[1,[28,[35,4],null,[[\"buttonType\",\"size\",\"text\",\"onClick\"],[[52,[30,0,[\"hasWarning\"]],\"warning\",\"danger\"],\"small\",\"Update\",[28,[37,5],[[30,0],[30,0,[\"openDetailPanel\"]],[30,0,[\"endpoint\"]]],null]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n\"],[10,0],[12],[1,\"\\n  \"],[8,[39,6],[[24,0,\"flex items-center justify-center bn ph--2 bg-transparent hover-bg-transparent nr--2\"],[4,[38,5],[[30,0],[30,0,[\"openDetailPanel\"]],[30,0,[\"endpoint\"]]],null]],[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"settings-16\",\"ma1 nudge-up--1 malibu-fill-gradient-dark-gray\",16,\"Edit endpoint details\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"svg-jar\",\"or\",\"moment-format\",\"if\",\"hk-button\",\"action\",\"malibu-icon-button\"]]",
    "moduleName": "dashboard/templates/components/app/settings/sni-endpoints-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});