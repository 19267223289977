define("dashboard/routes/app/scheduler", ["exports", "rsvp", "@ember/service", "dashboard/routes/basic-app", "dashboard/mixins/feature-check-route"], function (_exports, _rsvp, _service, _basicApp, _featureCheckRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend(_featureCheckRoute.default, {
    analytics: (0, _service.inject)(),

    model() {
      const {
        app
      } = this.modelFor('app');
      const formations = app.get('formations');
      const jobs = app.get('jobs');
      return _rsvp.default.hash({
        app,
        formations,
        jobs
      });
    },

    renderTemplate() {
      this.render('app/scheduler/header', {
        outlet: 'header-nav',
        into: 'protected'
      });
      this.render('app.scheduler', {
        into: 'protected'
      });

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});