define("dashboard/templates/components/radio-cell-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RTaeVXuG",
    "block": "[[[10,0],[14,0,\"pa3 dark-gray\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"value\",\"classNames\",\"radioClass\",\"groupValue\"],[[30,0,[\"value\"]],\"flex items-center ma0 cursor-pointer\",\"nudge-up--2 cursor-pointer\",[30,0,[\"selectedValue\"]]]],[[\"default\"],[[[[1,\"    \"],[18,1,[\"title\"]],[1,\"\\n\"]],[]]]]],[1,\"\\n  \"],[18,1,[\"top\"]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"bg-lightest-silver pv2 ph3 gray\"],[12],[1,\"\\n  \"],[18,1,[\"bottom\"]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"radio-button\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/radio-cell-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});