define("dashboard/components/metrics/charts/connected-chart-hover/item/vertical-scale", ["exports", "@ember/object/computed", "@ember/object", "dashboard/components/metrics/charts/connected-chart-hover/item"], function (_exports, _computed, _object, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    isAutoScaleActor: (0, _computed.alias)('model.isAutoScaleActor'),
    swatchColor: 'activity',
    userEmail: (0, _computed.readOnly)('model.userEmail'),
    description: (0, _object.computed)('userEmail', 'model.isAutoscaleEvent', function () {
      return this.get('model.isAutoscaleEvent') ? 'User Initiated' : this.userEmail;
    }),
    title: (0, _object.computed)('model.size', 'model.previousSize', function () {
      return `Scaled from ${this.get('model.previousSize')} to ${this.get('model.size')}`;
    })
  });

  _exports.default = _default;
});