define("dashboard/controllers/enterprise-account/overview/access", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    // --------------------------------------------------------------------------
    // Controller Config
    queryParams: ['user', 'email', 'permissions', 'sso', 'mfa', 'page'],
    // --------------------------------------------------------------------------
    // Services
    accountFeatures: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // State
    user: null,
    email: '',
    permissions: '',
    sso: null,
    mfa: null,
    page: 1
  });

  _exports.default = _default;
});