define("dashboard/components/metrics/app-guidance/timeout-errors", ["exports", "@ember/object", "dashboard/components/metrics/app-guidance/item", "dashboard/utils/pluralize"], function (_exports, _object, _item, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _item.default.extend({
    tagName: 'metrics-app-guidance-timeout-errors',
    title: 'Request Timeouts',
    description: (0, _object.computed)('numberOfTimeouts', function () {
      const num = this.numberOfTimeouts;
      return `There ${(0, _pluralize.default)(num, 'has', 'have')} been ${num} timeout ${(0, _pluralize.default)(num, 'error', 'errors')} in the current period. Try investigating slow requests, adding more dynos or running more processes per dyno.`;
    }),
    devCenterLinks: (0, _object.computed)(function () {
      const links = [{
        href: 'https://devcenter.heroku.com/articles/request-timeout',
        text: 'Troubleshooting Request Timeouts'
      }, {
        href: 'https://devcenter.heroku.com/articles/optimizing-dyno-usage',
        text: 'Optimizing Dyno Usage'
      }];
      return links;
    })
  });

  _exports.default = _default;
});