define("dashboard/adapters/repositories-api-archive-link", ["exports", "dashboard/adapters/application", "@ember/service", "dashboard/config/environment"], function (_exports, _application, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.repositories-api',
    accountFeatures: (0, _service.inject)('account-features'),

    urlForQueryRecord(query) {
      const repoNameWithOwner = query.repoNameWithOwner;
      const ref = query.ref;
      const pipelineId = query.pipelineId;
      const hasKolkrabbiGithubRepoLink = query.hasKolkrabbiGithubRepoLink;
      const isGithubAppEnabled = query.isGithubAppEnabled;
      delete query['repoNameWithOwner'];
      delete query['ref'];
      delete query['pipelineId'];
      delete query['hasKolkrabbiGithubRepoLink'];
      delete query['isGithubAppEnabled'];

      if (isGithubAppEnabled && !hasKolkrabbiGithubRepoLink) {
        if (pipelineId) {
          return `${this.host}/pipelines/${pipelineId}/repo/archives/${ref}`;
        } else {
          return `${this.host}/repos/${repoNameWithOwner}/archives/${ref}`;
        }
      } else {
        return `${_environment.default.kolkrabbiUrl}/github/repos/${repoNameWithOwner}/tarball/${ref}`;
      }
    }

  });

  _exports.default = _default;
});