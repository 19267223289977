define("dashboard/models/space-permission", ["exports", "dashboard/mixins/permissions", "@ember/object"], function (_exports, _permissions, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_permissions.default, {});

  _exports.default = _default;
});