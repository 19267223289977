define("dashboard/templates/components/app-item-stack", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hzAIBJfU",
    "block": "[[[41,[30,0,[\"app\",\"isPendingStackUpgrade\"]],[[[6,[39,1],null,[[\"class\",\"route\",\"model\"],[\"hk-link\",\"app.settings\",[30,0,[\"app\",\"name\"]]]],[[\"default\"],[[[[1,\"    awaiting \"],[1,[30,0,[\"app\",\"buildStack\",\"name\"]]],[1,\" deployment\\n\"]],[]]]]],[1,\"  \"],[10,1],[14,0,\"ph1 gray db-l dn\"],[12],[1,\"•\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"app\",\"showStackDeprecationWarning\"]],[[[6,[39,2],null,[[\"title\"],[[30,0,[\"tooltipTitle\"]]]],[[\"default\"],[[[[6,[39,1],null,[[\"class\",\"route\",\"model\"],[\"link red flex items-center\",\"app.settings\",[30,0,[\"app\",\"name\"]]]],[[\"default\"],[[[[1,\"      \"],[8,[39,3],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"info-badge-16\",\"mr1 malibu-fill-gradient-red\",16]],null],[1,\"\\n      \"],[1,[30,0,[\"app\",\"stack\",\"name\"]]],[1,\"\\n\"]],[]]]]]],[]]]]]],[]],[[[6,[39,1],null,[[\"class\",\"route\",\"model\"],[\"link dark-gray\",\"app.settings\",[30,0,[\"app\",\"name\"]]]],[[\"default\"],[[[[1,\"    \"],[1,[30,0,[\"app\",\"stack\",\"name\"]]],[1,\"\\n\"]],[]]]]]],[]]]],[],false,[\"if\",\"link-to\",\"x-tooltip\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/app-item-stack.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});