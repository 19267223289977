define("dashboard/components/app/formation-range-slider", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    attributeBindings: ['disabledChanged:disabled'],
    classNames: ['formation-range-slider'],
    min: 0,
    max: 100,
    valueMin: null,
    valueMax: null,
    persistedValue: (0, _computed.readOnly)('process.originalQuantity'),
    actions: {
      selectedValue(value) {
        this.set('process.quantity', value);
      },

      selectedValueMin(value) {
        // Can't autoscale below 1
        value = Math.max(value, 1);
        this.set('autoscaleMonitor.minQuantity', value);
      },

      selectedValueMax(value) {
        // Can't set maximum autoscale above customer limit
        const autoscaleLimit = this.max;
        value = Math.min(value, autoscaleLimit);
        this.set('autoscaleMonitor.maxQuantity', value);
      }

    }
  });

  _exports.default = _default;
});