define("dashboard/templates/components/app/formation-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "A/dY2OkY",
    "block": "[[[10,0],[14,0,\"bb b--silver ph2 pv1 flex items-center\"],[14,\"role\",\"listitem\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"pa1\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[[30,0,[\"dynoSize\",\"dynomiteIconName\"]],28,\"malibu-fill-gradient-purple\"]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"truncate\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"dark-gray\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"nudge-up--1 ml3 dn dib-ns flex-auto\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"dark-gray f5 dn dib-ns mr2 truncate\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"dark-gray f5 dn dib-ns mr2 truncate\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-row items-center\"],[12],[1,\"\\n\"],[41,[30,0,[\"isFree\"]],[[[1,\"        \"],[10,0],[14,0,\"pa1 nudge-up--2\"],[12],[1,\"\\n          \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"warning-badge-16\",16,\"malibu-fill-gradient-orange\"]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"f4 fw7 orange ml1\"],[12],[1,[30,0,[\"dynoSize\",\"name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"dark-gray\"],[12],[1,[30,0,[\"dynoSize\",\"name\"]]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[52,[30,0,[\"isFree\"]],\"b\"],\" dark-gray f5 dn dib-ns mr2 truncate w5 tr\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isEco\"]],[[[41,[30,0,[\"isSubscribedToEco\"]],[[[1,\"        Subscribed\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"f6 fw7 orange ml1\"],[12],[1,\"Not Subscribed\"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"      \"],[10,1],[12],[1,[28,[35,2],[[30,0,[\"dynoSize\",\"price\"]]],[[\"showFree\",\"suffix\"],[true,\"/month\"]]]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@type\"],false,[\"malibu-icon\",\"if\",\"currency-format\"]]",
    "moduleName": "dashboard/templates/components/app/formation-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});