define("dashboard/templates/components/new/pipeline-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TOdTGpls",
    "block": "[[[18,1,null],[1,\"\\n\\n\"],[6,[39,1],null,[[\"buttonContent\",\"buttonClass\",\"classNames\",\"menuClass\"],[[30,0,[\"buttonContent\"]],\"hk-select pl5 w-100 tl z-1\",\"w-100\",\"pipeline-selection\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"li\"],[14,0,\"filter-menu\"],[12],[1,\"\\n    \"],[8,[39,2],[[24,0,\"hk-input w-100\"],[24,\"placeholder\",\"Filter pipelines\"]],[[\"@value\"],[[30,0,[\"query\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"li\"],[14,0,\"bt b--light-silver mv1\"],[12],[13],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"filteredPipelines\",\"isLoadingModel\",\"makeSelection\",\"closeDropdown\"],[[30,0,[\"filteredPipelines\"]],[30,0,[\"isLoadingModel\"]],[28,[37,4],[[30,0],\"makeSelection\"],null],[28,[37,4],[[30,0],\"closePipelineSelectDropdown\"],null]]]]],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"yield\",\"drop-down\",\"input\",\"new/pipeline-select-dropdown\",\"action\"]]",
    "moduleName": "dashboard/templates/components/new/pipeline-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});