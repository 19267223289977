define("dashboard/mixins/serializers/decamelized-keys", ["exports", "@ember/string", "@ember/object/mixin"], function (_exports, _string, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    keyForAttribute(attr) {
      return (0, _string.decamelize)(attr);
    }

  });

  _exports.default = _default;
});