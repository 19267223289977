define("dashboard/serializers/feature", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      const feature = snapshot.record; // we only want to post the enabled property

      const payload = {
        enabled: feature.get('enabled')
      };
      return payload;
    }

  });

  _exports.default = _default;
});