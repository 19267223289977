define("dashboard/mixins/adapters/status-api", ["exports", "@ember/object", "@ember/object/mixin", "dashboard/config/environment"], function (_exports, _object, _mixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    version: 'v4',
    namespace: (0, _object.computed)('version', function () {
      const version = this.version;
      return `api/${version}`;
    }),
    host: _environment.default.statusApiUrl
  });

  _exports.default = _default;
});