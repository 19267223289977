define("dashboard/templates/components/toggletip/boundary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dgdGSEBJ",
    "block": "[[[11,0],[24,1,\"toggletip-root-boundary\"],[24,0,\"fixed top--glostick\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "dashboard/templates/components/toggletip/boundary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});