define("dashboard/serializers/team/invitation", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      if (resourceHash['user']) {
        resourceHash['email'] = resourceHash['user']['email'];
        resourceHash['name'] = resourceHash['user']['name'];
      }

      return this._super(...arguments);
    },

    extractRelationships(modelClass, resourceHash) {
      const relationships = this._super(...arguments);

      relationships.team = {
        data: {
          type: 'team',
          id: resourceHash['team']['id']
        }
      };
      return relationships;
    }

  });

  _exports.default = _default;
});