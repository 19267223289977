define("dashboard/templates/components/app/activity-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "N4gs68g+",
    "block": "[[[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"name\"]],\"fill-dark-gray\",28]],null],[1,\"\\n\\n\"],[41,[30,0,[\"badgeName\"]],[[[1,\"  \"],[10,0],[14,0,\"absolute bottom-0 right-0 bg-white br-100 flex items-center\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"badgeName\"]],[30,0,[\"badgeIconClass\"]],13]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"malibu-icon\",\"if\"]]",
    "moduleName": "dashboard/templates/components/app/activity-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});