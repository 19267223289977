define("dashboard/serializers/addon", ["exports", "@ember-data/serializer/rest", "dashboard/serializers/application", "@ember/application"], function (_exports, _rest, _application, _application2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      addonService: {
        deserialize: 'records'
      }
    },

    normalize(modelClass, resourceHash) {
      resourceHash.embedded_addon_service_plan = resourceHash.plan;
      resourceHash.addonServicePlan = resourceHash.plan.id;
      resourceHash.plan = resourceHash.plan.name;
      resourceHash.app = resourceHash.app.id; // This makes `addon.attachments` async relationship work

      const jsonApiDoc = this._super.apply(this, arguments);

      const adapter = (0, _application2.getOwner)(this).lookup('adapter:attachment');
      const link = adapter.urlForQuery({
        addonId: resourceHash.id
      });
      jsonApiDoc.data.relationships.attachments = {
        links: {
          related: link
        }
      };
      return jsonApiDoc;
    },

    serialize(snapshot) {
      const serialized = {
        plan: snapshot.record.get('addonServicePlan.name')
      }; // for addons that are nonPeerableWithinPrivateSpace:
      // new records must be PUT with the confirm attribute
      // existing records must be PATCH with NO confirm attribute

      if (snapshot.record.get('isNew')) {
        serialized['confirm'] = snapshot.record.get('confirm');
      }

      return serialized;
    }

  });

  _exports.default = _default;
});