define("dashboard/controllers/team/space/access", ["exports", "@ember/object", "@ember/controller", "dashboard/models/space-permission"], function (_exports, _object, _controller, _spacePermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    filter,
    filterBy,
    readOnly
  } = _object.computed;

  var _default = _controller.default.extend({
    team: readOnly('model.team'),
    teamAdmins: filterBy('team.members', 'role', 'admin'),
    teamMembers: filterBy('team.members', 'role', 'member'),
    spaceMembers: alias('model.spaceMembers'),
    teamPermissions: readOnly('team.currentPermissions'),
    canManageSpaces: readOnly('teamPermissions.canManageSpaces'),
    adminSpacePermissions: (0, _object.computed)(function () {
      return [_spacePermission.default.create({
        name: 'app creation',
        description: 'Create apps in a space.',
        selected: true
      })];
    }),
    spaceTeamMembers: filter('spaceMembers', function (member) {
      return !!this.teamMembers.find(teamMember => member.get('user.email') === teamMember.get('email'));
    })
  });

  _exports.default = _default;
});