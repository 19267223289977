define("dashboard/models/kolkrabbi/app-setup", ["exports", "@ember/object", "dashboard/mixins/app-setup", "@ember/service", "ember-concurrency", "dashboard/config/environment", "@ember/utils", "rsvp"], function (_exports, _object, _appSetup, _service, _emberConcurrency, _environment, _utils, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_appSetup.default, {
    store: (0, _service.inject)(),
    api: (0, _service.inject)(),
    idFetches: 0,

    fetch() {
      const api = this.api;
      const prAppId = this.prAppId;
      return api.request(`/pull-requests/${prAppId}/app-setup`, {
        type: 'GET',
        adapter: this.store.adapterFor('kolkrabbi')
      }).then(response => {
        Object.keys(response).forEach(property => {
          this.set(property, response[property]);
        });
        return this;
      }).catch(() => this);
    },

    fetchStatusTask: (0, _emberConcurrency.task)(function* (interval) {
      while (true) {
        if (_environment.default.environment === 'test') {
          return;
        }

        yield this.fetch();

        if (this.isDoneOrFailed) {
          return this.status;
        }

        yield (0, _emberConcurrency.timeout)(interval);
      }
    }),
    fetchIdTask: (0, _emberConcurrency.task)(function* () {
      while (true) {
        if (_environment.default.environment === 'test') {
          return;
        } // After 5 fetches, reject, this indicates the app-setup doesnt exist


        if (this.idFetches >= 5) {
          this.set('idFetches', 0);
          return _rsvp.default.reject();
        }

        this.incrementProperty('idFetches');
        yield this.fetch();

        if ((0, _utils.isPresent)(this.id)) {
          return this.id;
        }

        yield (0, _emberConcurrency.timeout)(5000);
      }
    }),

    fetchStatus(interval) {
      this.fetchStatusTask.perform(interval);
      return this;
    },

    cancelPolling() {
      this.fetchIdTask.cancelAll();
      this.fetchStatusTask.cancelAll();
    }

  });

  _exports.default = _default;
});