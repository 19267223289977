define("dashboard/templates/components/stencil/addons/addons-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iT17bbku",
    "block": "[[[10,0],[15,0,[29,[\"flex items-center center bb b--light-silver pv2 ph2 o-\",[30,0,[\"opacity\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"br3 bg-light-silver\"],[14,5,\"min-width:28px;min-height:28px;\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"stencil w5 ml4\"],[12],[1,\"Add-on Name\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"stencil w4\"],[12],[1,\"Free\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"stencil w1 ml6\"],[12],[1,\"**\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/stencil/addons/addons-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});