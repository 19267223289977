define("dashboard/templates/components/efp-formations-list-dyno-tier-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ypZZHbwJ",
    "block": "[[[10,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@title\"],[[30,0,[\"tooltipTitle\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@size\",\"@iconClass\"],[\"warning-badge-16\",16,\"malibu-fill-gradient-orange nudge-up--1\"]],null],[1,\"\\n    \"],[10,1],[14,0,\"fw7 orange f4 nudge-right--2\"],[12],[1,[30,1]],[1,\" Dynos\"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\\n\"]],[\"@currentTierName\"],false,[\"x-tooltip\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/efp-formations-list-dyno-tier-name.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});