define("dashboard/components/metrics/charts/line-chart-elements/activity-event-markers", ["exports", "@ember/object/computed", "@ember/component"], function (_exports, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'g',

    /**
     * arranges events so they are initially drawn with the latest event
     * being drawn last
     */
    indicatorSortKey: ['time'],
    orderedActivityEvents: (0, _computed.sort)('activityEvents', 'indicatorSortKey')
  });

  _exports.default = _default;
});