define("dashboard/serializers/repositories-api-github-compare", ["exports", "ember-data-github/serializers/github-compare"], function (_exports, _githubCompare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _githubCompare.default.extend();

  _exports.default = _default;
});