define("dashboard/routes/team/switchable/rapid-upgrade", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "dashboard/utils/infinite-query"], function (_exports, _route, _rsvp, _service, _infiniteQuery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RapidUpgradeRoute = (_class = class RapidUpgradeRoute extends _route.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor2, this);
    }

    model() {
      const team = this.modelFor('team').team;
      const teamQuery = {
        team: team.get('id')
      };
      return (0, _rsvp.hash)({
        apps: (0, _infiniteQuery.default)(this.store, 'app', teamQuery, {}, this.flashMessages),
        addons: (0, _infiniteQuery.default)(this.store, 'addon', {
          teamId: team.get('id'),
          slug: ['heroku-postgresql', 'heroku-redis']
        }, {}, this.flashMessages),
        couplings: (0, _infiniteQuery.default)(this.store, 'pipeline-coupling', teamQuery, {}, this.flashMessages)
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = RapidUpgradeRoute;
});