define("dashboard/templates/components/datastore/chart-switcher-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4RcCy/uy",
    "block": "[[[1,\"\\n\"],[6,[39,0],null,[[\"class\",\"query\"],[\"metrics__process-switcher__item-link\",[28,[37,1],null,[[\"index\"],[[30,0,[\"options\",\"index\"]]]]]]],[[\"default\"],[[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[10,1],[14,0,\"metrics__process-switcher__item-header\"],[14,\"title\",\"\"],[12],[1,[30,0,[\"options\",\"name\"]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"metrics__process-switcher__item-content\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"metrics__process-switcher__item-content-quantity\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[28,[37,4],[[30,0,[\"options\",\"name\"]],\"I/O\"],null]],null],[[[1,\"          Topic\\n\"]],[]],[[[1,\"          General\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"  \\n\"],[41,[30,0,[\"options\",\"showIcon\"]],[[[1,\"    \"],[8,[39,5],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"caret-16\",\"fill-purple caret-icon\",16]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"link-to\",\"-hash\",\"if\",\"not\",\"eq\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/datastore/chart-switcher-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});