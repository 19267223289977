define("dashboard/models/app/webhook-delivery", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "@ember/object"], function (_exports, _model, _attr, _relationships, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    event: (0, _attr.default)(),
    numAttempts: (0, _attr.default)('number'),
    nextAttemptAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    lastAttempt: (0, _attr.default)(),
    status: (0, _attr.default)('string'),
    webhook: (0, _relationships.belongsTo)('app/webhook'),
    meta: (0, _object.computed)('status', 'lastAttempt.{completed_in,error_class,code}', function () {
      const status = this.status;

      switch (status) {
        case 'pending':
        case 'scheduled':
          return 'In Queue';

        case 'succeeded':
          return `Completed in ${this.get('lastAttempt.completed_in')} seconds`;

        case 'failed':
        case 'retrying':
          if (this.get('lastAttempt.error_class')) {
            return this.get('lastAttempt.error_description');
          } else if (this.get('lastAttempt.code')) {
            return this.get('lastAttempt.code_description');
          } else {
            return 'Failed';
          }

      }
    })
  });

  _exports.default = _default;
});