define("dashboard/templates/components/stencil/app/process-switcher", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "KK/tg5hS",
    "block": "[[[10,0],[14,0,\"flex items-center pv1 pl2 pr4\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"stencil w5\"],[12],[1,\"lorem ipsum\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"flex items-center pv1 pl2 pr4\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"br-100 bg-light-silver\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"stencil w5 ml2\"],[12],[1,\"lorem ipsum\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/stencil/app/process-switcher.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});