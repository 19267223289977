define("dashboard/components/metrics/app-guidance/item", ["exports", "@ember/object", "@ember/component", "@ember/service", "dashboard/templates/components/metrics/app-guidance/item"], function (_exports, _object, _component, _service, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _item.default,
    classNameBindings: [':metrics__app-guidance__item', ':ph3', ':pv2'],
    analytics: (0, _service.inject)(),
    devCenterLinks: (0, _object.computed)('devCenterLink', 'linkText', function () {
      return [{
        href: this.devCenterLink,
        text: this.devCenterLinkText
      }];
    }),
    actions: {
      clickDevCenterLink(href) {
        this.analytics.logEvent('DevCenter Link', 'Clicked', {
          href,
          via: 'app-guidance'
        }); // must do this manually because this action eats the <a> click

        window.open(href, '_blank');
      }

    }
  });

  _exports.default = _default;
});