define("dashboard/services/payment-gateway/hosted-field-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HostedFieldState = _exports.CreditCardNumberHostedFieldState = void 0;

  /**
   * This file contains JavaScript classes that represent the Braintree
   * HostedField State objects (https://braintree.github.io/braintree-web/current/HostedFields.html#~stateObject).
   * These classes wrap the HostedFieldState objects in an API that we control. While Braintree is unlikely to change their API
   * anytime soon, this should hopefully help us make changes in as few places
   * as possible.
   *
   * Why are these not Ember Objects? These wrappers should be *read only*, as in
   * you shouldn't be able to mutate them. This is to help prevent complexity
   * and to help ensure that when a HostedFieldState is updated, the entire
   * tree re-renders because the objects are different instances.
   *
   * In addition, the `update` function also ensures the `isFocused` and `wasEntered`
   * states are carried over whenever we receive an event. This allows us to keep
   * the state consistent between when the user focuses and blurs a field.
   *
   * Usage:
   *
   * new HostedFieldState({
   *   isValid: true,
   *   isPotentiallyVAlid: true,
   *   isEmpty: false,
   *   isFocused: false,
   *   // custom field for our use. Used to show the correct class (if you exit the field but it's empty, show error. don't show error on initial render.)
   *   wasEntered: false
   * })
   */
  class HostedFieldState {
    constructor(_ref) {
      let {
        isValid,
        isPotentiallyValid,
        isEmpty,
        isFocused,
        wasEntered
      } = _ref;
      this.__isEmpty = isEmpty;
      this.__isValid = isValid;
      this.__isFocused = isFocused;
      this.__wasEntered = wasEntered;
      this.__isPotentiallyValid = isPotentiallyValid;
      this.__wasEntered = wasEntered;
    }

    get isEmpty() {
      return this.__isEmpty;
    }

    get isValid() {
      return this.__isValid;
    }

    get isPotentiallyValid() {
      return this.__isPotentiallyValid;
    }

    get isFocused() {
      return this.__isFocused;
    }

    get wasEntered() {
      return this.__wasEntered;
    }

    get isEligibleForSubmission() {
      return this.isValid;
    }
    /**
     * Create a new HostedFieldState, merging the properties of `event` and `extraProps`
     * with the current state of `isFocused`  and `wasEntered`.
     * @param {Object} event a Braintree HostedFieldState object https://braintree.github.io/braintree-web/current/HostedFields.html#~stateObject
     * @param {*} extraProps
     */


    update(event, extraProps) {
      const {
        emittedBy,
        fields
      } = event;
      const {
        isFocused,
        wasEntered
      } = this;
      const fieldData = fields[emittedBy];
      const newData = Object.assign({
        isFocused,
        wasEntered
      }, fieldData, extraProps);
      return new this.constructor(newData);
    }

  }
  /**
   * Like HostedFieldState but handles the cardType for the `cardNumber` field.
   */


  _exports.HostedFieldState = HostedFieldState;

  class CreditCardNumberHostedFieldState extends HostedFieldState {
    constructor(opts) {
      super(opts);
      this.__cards = opts.cards;
    }

    get cardType() {
      if (this.isEmpty || !this.__cards || this.__cards.length === 0) {
        return null;
      } else {
        const cards = this.__cards;
        return cards && cards[0].type;
      }
    }

    get friendlyCardType() {
      if (this.isEmpty || !this.__cards || this.__cards.length === 0) {
        return null;
      } else {
        const cards = this.__cards;
        return cards && cards[0].niceType;
      }
    }

    update(event) {
      let extraProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const {
        cards
      } = event;
      extraProps.cards = cards;
      return super.update(event, extraProps);
    }

  }

  _exports.CreditCardNumberHostedFieldState = CreditCardNumberHostedFieldState;
});