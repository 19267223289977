define("dashboard/components/pipeline/transfer-ownership", ["exports", "@ember/service", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "dashboard/mixins/pipeline-ownership"], function (_exports, _service, _component, _object, _computed, _utils, _pipelineOwnership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_pipelineOwnership.default, {
    current: (0, _service.inject)(),
    classNames: ['pipeline-transfer'],
    selectedOwnerId: (0, _computed.oneWay)('pipelineOwnerId'),
    teamOwners: (0, _computed.filterBy)('owners', 'isTeam'),
    userOwners: (0, _computed.filterBy)('owners', 'isUser'),
    otherOwners: (0, _object.computed)('owners', function () {
      return this.owners.rejectBy('id', this.pipelineOwnerId);
    }),
    otherTeamOwners: (0, _computed.filterBy)('otherOwners', 'isTeam'),
    otherUserOwners: (0, _computed.filterBy)('otherOwners', 'isUser'),
    selectableTeamOwners: (0, _object.computed)('otherTeamOwners.@each.role', function () {
      return this.otherTeamOwners.filter(owner => {
        const role = (0, _object.get)(owner, 'role');
        return role === 'admin' || role === 'member';
      });
    }),
    selectableUserOwners: (0, _object.computed)('otherUserOwners.@each.id', 'current.account.{id,verified}', function () {
      const accountVerified = this.get('current.account.verified');
      const accountId = this.get('current.account.id');
      const selfOwner = this.otherUserOwners.findBy('id', accountId);
      return selfOwner && accountVerified ? [selfOwner] : [];
    }),
    selectableOwners: (0, _computed.union)('selectableTeamOwners', 'selectableUserOwners'),
    selectableOwnerIds: (0, _computed.mapBy)('selectableOwners', 'id'),
    selectedOwner: (0, _object.computed)('owners.@each.id', 'selectedOwnerId', function () {
      return this.owners.findBy('id', this.selectedOwnerId);
    }),
    teamOptions: (0, _object.computed)('otherTeamOwners.@each.isEnterpriseTeam', function () {
      const owners = this.otherTeamOwners.rejectBy('isEnterpriseTeam');
      return this.buildOwnerOptions(owners);
    }),
    enterpriseTeamOptions: (0, _object.computed)('otherTeamOwners.@each.isEnterpriseTeam', function () {
      const owners = this.otherTeamOwners.filterBy('isEnterpriseTeam');
      return this.buildOwnerOptions(owners);
    }),
    userOptions: (0, _object.computed)('otherUserOwners.@each.id', function () {
      return this.buildOwnerOptions(this.otherUserOwners);
    }),
    noPipelineOwner: (0, _computed.empty)('pipelineOwner'),
    selectedOwnerIsTeam: (0, _computed.readOnly)('selectedOwner.isTeam'),
    pipelineOwnerIsTeam: (0, _computed.readOnly)('pipelineOwner.isTeam'),
    ownerNotChanged: (0, _object.computed)('selectedOwnerId', 'pipelineOwnerId', function () {
      const selectedOwnerId = this.selectedOwnerId;
      const pipelineOwnerId = this.pipelineOwnerId; // This is required because both properties can be either null or undefined
      // so a straight === check is unreliable

      if ((0, _utils.isNone)(selectedOwnerId) && (0, _utils.isNone)(pipelineOwnerId)) {
        return true;
      }

      return selectedOwnerId === pipelineOwnerId;
    }),
    // TODO: Once all the teams are migrated to Enterprise Accounts, we should
    // stop checking by team type, but for enterprise account ids instead
    isEnterpriseAccount: (0, _object.computed)('teams.@each.id', 'noPipelineOwner', function () {
      if (this.noPipelineOwner || !this.pipelineOwnerIsTeam) {
        return false;
      } // Pipeline is owned by a team


      const pipelineOwnerID = this.pipelineOwnerId;
      const enterpriseTeamsIDs = this.teams.filterBy('isEnterpriseOrg').mapBy('id');
      return enterpriseTeamsIDs.includes(pipelineOwnerID);
    }),
    hasEnterpriseAccountApps: (0, _object.computed)('teams.@each.id', 'apps.@each.owner', function () {
      const enterpriseTeamsIDs = this.teams.filterBy('isEnterpriseOrg').mapBy('id');
      const apps = this.apps;
      return (0, _utils.isPresent)(apps.filter(app => enterpriseTeamsIDs.includes(app.get('owner.id'))));
    }),
    canSetOwnerToNone: (0, _computed.bool)('isEnterpriseAccount'),
    canChangeOwner: (0, _object.computed)('current.account.id', 'pipelineOwnerType', 'pipelineOwnerId', 'pipelineOwner', 'teamOwners.@each.{id,role}', function () {
      const id = this.pipelineOwnerId;
      const type = this.pipelineOwnerType;

      if (!id) {
        return true;
      }

      if (type === 'user') {
        return id === this.get('current.account.id');
      } else {
        const team = this.teamOwners.findBy('id', id);

        if (team) {
          const role = (0, _object.get)(team, 'role');
          return role === 'admin' || role === 'member';
        }
      }

      return false;
    }),
    cannotChangeOwner: (0, _computed.not)('canChangeOwner'),
    showWhoCanChangeOwner: (0, _computed.and)('cannotChangeOwner', 'persistedOwnerRevelealed'),
    unsetText: (0, _object.computed)('pipeline.name', function () {
      return `Unset ownership of ${this.get('pipeline.name')}`;
    }),
    concealedUserOwners: (0, _object.computed)('userOwners.@each.email', function () {
      return this.userOwners.filter(owner => (0, _utils.isEmpty)(owner.get('email')));
    }),
    concealedTeamOwners: (0, _object.computed)('teamOwners.@each.name', function () {
      return this.teamOwners.filter(owner => (0, _utils.isEmpty)(owner.get('name')));
    }),
    concealedOwners: (0, _computed.union)('concealedUserOwners', 'concealedTeamOwners'),
    concealedOwnerIds: (0, _computed.mapBy)('concealedOwners', 'id'),
    hasConcealedOwners: (0, _computed.notEmpty)('concealedOwners'),
    persistedOwnerConcealed: (0, _object.computed)('concealedOwnerIds', 'pipelineOwnerId', function () {
      return this.concealedOwnerIds.includes(this.pipelineOwnerId);
    }),
    persistedOwnerRevealed: (0, _computed.not)('persistedOwnerConcealed'),

    buildOwnerOptions(owners) {
      return owners.map(owner => {
        const id = (0, _object.get)(owner, 'id');
        const label = (0, _object.get)(owner, 'label');
        const enterpriseAccountId = (0, _object.get)(owner, 'enterpriseAccountId');
        const disabled = !this.selectableOwnerIds.includes(id);
        return {
          id,
          label,
          disabled,
          enterpriseAccountId
        };
      });
    },

    isUnsettingOwner: (0, _object.computed)('selectedOwnerId', 'hasEnterpriseAccountApps', function () {
      return !this.selectedOwnerId && this.hasEnterpriseAccountApps;
    }),
    selectOptionGroups: (0, _object.computed)('pipelineOwner', 'userOptions', 'teamOptions', 'enterpriseTeamOptions', 'enterpriseAccounts', 'groupByEnterpriseAccounts', function () {
      const groups = [];
      const pipelineOwner = this.pipelineOwner;
      const userOptions = this.userOptions;
      const teamOptions = this.teamOptions;
      const enterpriseTeamOptions = this.enterpriseTeamOptions; // Current owner group options.

      let ownerOption;

      if ((0, _utils.isPresent)(pipelineOwner)) {
        ownerOption = {
          label: pipelineOwner.label,
          id: pipelineOwner.id
        };
      } else {
        ownerOption = {
          label: 'None',
          id: ''
        };
      }

      groups.push({
        label: 'Current owner',
        options: [ownerOption]
      }); // Enterprise account and team options.

      if (this.groupByEnterpriseAccounts) {
        // Group enterprise teams under an enterprise account heading.
        const enterpriseAccounts = this.enterpriseAccounts;
        enterpriseAccounts.forEach(account => {
          const teams = enterpriseTeamOptions.filterBy('enterpriseAccountId', account.id);

          if ((0, _utils.isPresent)(teams)) {
            groups.push({
              label: account.name,
              options: teams
            });
          }
        }); // Legacy enterprise teams, which have no account associated with them.

        const legacyTeams = enterpriseTeamOptions.rejectBy('enterpriseAccountId');

        if ((0, _utils.isPresent)(legacyTeams)) {
          groups.push({
            label: 'Enterprise teams',
            options: legacyTeams
          });
        }
      } else if ((0, _utils.isPresent)(enterpriseTeamOptions)) {
        // Group all enterprise teams under one generic heading.
        groups.push({
          label: 'Enterprise teams',
          options: enterpriseTeamOptions
        });
      }

      if ((0, _utils.isPresent)(teamOptions)) {
        groups.push({
          label: 'Teams',
          options: teamOptions
        });
      } // User group options.


      if ((0, _utils.isPresent)(userOptions)) {
        groups.push({
          label: 'Users',
          options: userOptions
        });
      }

      return groups;
    }),
    actions: {
      startTransfer() {
        if (this.isUnsettingOwner) {
          this.set('unsettingOwner', true);
        } else {
          this.set('confirmingTransfer', true);
        }
      },

      unsetOwner() {
        return this.onUnsetOwner().then(() => {
          this.set('unsettingOwner', false);
        });
      },

      updateOwner() {
        return this.onUpdateOwner({
          id: this.get('selectedOwner.id'),
          type: this.get('selectedOwner.type')
        });
      }

    }
  });

  _exports.default = _default;
});