define("dashboard/models/space/member", ["exports", "@ember/object", "@ember/object/computed", "@ember/utils", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _object, _computed, _utils, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    space: (0, _relationships.belongsTo)('space'),
    permissions: (0, _attr.default)(),
    user: (0, _attr.default)(),
    editingPermissions: (0, _computed.oneWay)('permissions'),
    // Merge model permissions and availablePermissions together with a
    // `selected` boolean attribute for use in downstream PermissionToggle.
    toggleablePermissions: (0, _object.computed)('editingPermissions.[]', 'availablePermissions.[]', function () {
      if ((0, _utils.isPresent)(this.availablePermissions)) {
        return this.availablePermissions.map(perm => {
          const isSelected = this.editingPermissions.isAny('name', perm.get('name'));
          const newPerm = perm.copy();
          newPerm.set('selected', isSelected);
          this.humanize(newPerm);
          return newPerm;
        });
      }
    }),

    humanize(perm) {
      if (perm.name === 'create_apps') {
        perm.set('name', 'app creation');
      }
    },

    rollbackPermissions() {
      this.set('editingPermissions', this.permissions);
    }

  });

  _exports.default = _default;
});