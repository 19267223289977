define("dashboard/utils/github-page-fetcher", ["exports", "@ember/array/proxy", "@ember/object"], function (_exports, _proxy, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bool
  } = _object.computed;

  var _default = _proxy.default.extend({
    page: 0,
    perPage: 100,
    content: (0, _object.computed)(function () {
      return [];
    }),
    isFetching: bool('fetchPromise'),

    fetchPage() {
      this.incrementProperty('page');
      const {
        repo,
        page,
        perPage: per_page
      } = this;
      const fetch = this.store.query('github-pr', {
        repo,
        page,
        per_page
      });
      this.set('fetchPromise', fetch);
      return fetch.then(results => {
        this.pushObjects(results.toArray());
        this.set('meta', results.meta);
        return this;
      }).finally(() => this.set('fetchPromise', null));
    }

  });

  _exports.default = _default;
});