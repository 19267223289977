define("dashboard/templates/components/malibu-icon-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QjaJ/e+w",
    "block": "[[[11,\"button\"],[24,0,\"bg-transparent hk-focus-ring--blue:focus cursor-hand br1 ba0 b--none pa--1\"],[16,\"title\",[30,1]],[24,4,\"button\"],[17,2],[12],[1,\"\\n  \"],[10,1],[14,0,\"clip\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,3],[30,4],[30,5]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"&attrs\",\"@name\",\"@iconClass\",\"@size\"],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/malibu-icon-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});