define("dashboard/components/app/addons-devcenter-link", ["exports", "@ember/service", "@ember/component"], function (_exports, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    analytics: (0, _service.inject)(),
    devcenterUrl: null,
    whereFrom: null,
    // might look like 'provision modal' or 'actions list'
    actions: {
      trackAndGoToDevcenterUrl() {
        this.analytics.logEvent('DevCenter Link', 'Clicked', {
          via: 'addons'
        });
        window.open(this.devcenterUrl);
      }

    }
  });

  _exports.default = _default;
});