define("dashboard/utils/parse-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stringToObject;

  function stringToObject(input) {
    if (!input) {
      return {};
    }

    const qs = input.split('?')[1];
    const parts = qs ? qs.split('&') : [];
    const keyValuePairs = {};
    parts.forEach(part => {
      const [key, value] = part.split('=');
      keyValuePairs[key] = value;
    });
    return keyValuePairs;
  }
});