define("dashboard/utils/ajax-setup", ["exports", "jquery", "@ember/array"], function (_exports, _jquery, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRequesterHeader = addRequesterHeader;
  _exports.default = ajaxSetup;
  _exports.needsRequesterHeader = needsRequesterHeader;
  const REQUESTER_DOMAINS = (0, _array.A)([/(http|https):\/\/api.heroku.com/]);

  function ajaxSetup() {
    _jquery.default.ajaxPrefilter(addRequesterHeader);
  }

  function addRequesterHeader(options) {
    options.headers = options.headers || {};

    if (needsRequesterHeader(options.url)) {
      options.headers['X-Heroku-Requester'] = 'dashboard';
    }
  }

  function needsRequesterHeader(url) {
    return REQUESTER_DOMAINS.find(domain => domain.test(url));
  }
});