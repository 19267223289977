define("dashboard/components/app/addons-provision", ["exports", "@ember/service", "@ember/component", "@ember/object/computed", "@ember/object"], function (_exports, _service, _component, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),
    isNew: true,
    onClose: null,
    onSave: null,
    addon: null,
    app: null,
    // Tracks the plan that has been selected in the list.
    // Can also be provided to the component to contol the initial plan selected.
    currentlySelectedPlan: null,
    // Tracks whether or not they've agreed to allow a non-peerable addon plan within a private space.
    confirmNotAutoNetworked: false,
    marketplaceUrl: (0, _computed.alias)('addon.addon_service.marketplaceUrl'),
    // This could be a plan name that is expired and not in the list anymore
    currentPlanName: (0, _computed.alias)('addon.addonServicePlan.name'),
    isPrivateSpaceApp: (0, _computed.alias)('addon.isPrivateSpaceApp'),
    // This will be the default plan name if the current one is expired
    currentValidDefaultPlanName: (0, _computed.alias)('addon.currentValidPlanOrDefaultPlan.name'),
    availableServicePlans: (0, _computed.alias)('addon.availableServicePlans'),
    availableServicePlansForUpdate: (0, _computed.alias)('addon.availableServicePlansForUpdate'),
    selectedPlan: (0, _computed.or)('currentlySelectedPlan', 'currentValidDefaultPlanName'),
    saveIsDisabled: (0, _computed.or)('planHasNotChanged', 'needsToConsentNonPeerableWithinPrivateSpace'),
    needsToConsentNonPeerableWithinPrivateSpace: (0, _object.computed)('nonPeerableWithinPrivateSpace', 'confirmNotAutoNetworked', function () {
      return this.nonPeerableWithinPrivateSpace && !this.confirmNotAutoNetworked;
    }),
    nonPeerableWithinPrivateSpace: (0, _object.computed)('selectedPlan', 'isPrivateSpaceApp', function () {
      return this.isPrivateSpaceApp && !this.addon.planIsPeerableWithinPrivateSpace(this.selectedPlan);
    }),
    planHasNotChanged: (0, _object.computed)('isNew', 'selectedPlan', 'currentPlanName', function () {
      return !this.isNew && this.selectedPlan === this.currentPlanName;
    }),
    availableServicePlansComplete: (0, _object.computed)('availableServicePlans.[]', 'availableServicePlansForUpdate.[]', 'isNew', function () {
      if (this.isNew) {
        return this.availableServicePlans;
      } else {
        return this.availableServicePlansForUpdate;
      }
    }),
    actions: {
      setPlan(planName) {
        this.set('currentlySelectedPlan', planName);
      },

      saveAddon() {
        this.addon.setAddonServicePlanByName(this.selectedPlan);
        this.addon.set('confirmNotAutoNetworked', this.confirmNotAutoNetworked);
        this.onSave();
      }

    }
  });

  _exports.default = _default;
});