define("dashboard/templates/components/org/org-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GUpy3Y6L",
    "block": "[[[6,[39,0],null,[[\"title\"],[\"Team Name\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"value\",\"label\",\"id\",\"onSave\",\"isEditing\",\"maxlength\"],[[30,0,[\"renameableModel\",\"name\"]],\"Team Name\",\"org-name-input\",[28,[37,2],[[30,0],[30,0,[\"saveRename\"]]],null],[30,0,[\"isEditingOrgName\"]],30]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"list-group-item-purple2\",\"hk-inline-edit\",\"action\"]]",
    "moduleName": "dashboard/templates/components/org/org-name.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});