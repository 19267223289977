define("dashboard/components/metrics/alerting-configuration", ["exports", "@ember/service", "@ember/component", "@ember/polyfills", "@ember/object", "@ember/object/computed"], function (_exports, _service, _component, _polyfills, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const existingOrNewMonitor = function (name) {
    return (0, _object.computed)('alertingMonitors.[]', 'app.id', function () {
      const existingMonitor = this.alertingMonitors.findBy('name', name);

      if (existingMonitor) {
        return existingMonitor;
      } else {
        const properties = (0, _polyfills.merge)({
          name,
          actionType: 'alert',
          op: 'GREATER_OR_EQUAL',
          processType: 'web',
          appId: this.get('app.id')
        });
        return this.store.createRecord('metrics/monitor', properties);
      }
    });
  };

  var _default = _component.default.extend({
    tagName: 'metrics-alerting-configuration',
    monitors: (0, _computed.alias)('process.monitors'),
    alertingMonitors: (0, _computed.filterBy)('monitors', 'isAlertingType'),
    store: (0, _service.inject)(),
    _isWebAndEligibleForAlerting: (0, _computed.and)('process.isWeb', 'app.isEligibleForAlerting'),
    recipients: (0, _object.computed)('app.recipients', '_isWebAndEligibleForAlerting', function () {
      return this._isWebAndEligibleForAlerting ? this.get('app.recipients') : [];
    }),
    recipientsKey: ['verified:desc'],
    sortedRecipients: (0, _computed.sort)('recipients', 'recipientsKey'),
    recipientsWithAppIds: (0, _object.computed)('sortedRecipients.[]', 'app.id', function () {
      const recipients = this.sortedRecipients;
      recipients.forEach(recipient => recipient.appId = this.get('app.id'));
      return recipients.toArray();
    }),
    errorRateMonitor: existingOrNewMonitor('ERROR_RATE'),
    latencyMonitor: existingOrNewMonitor('LATENCY'),
    isNotWeb: (0, _computed.not)('process.isWeb'),
    canNotConfigureAlerts: (0, _computed.not)('canConfigureAlerts'),
    buttonDisabled: (0, _computed.or)('isNotWeb', 'canNotConfigureAlerts'),
    buttonTitle: (0, _object.computed)('isNotWeb', 'canNotConfigureAlerts', function () {
      if (this.isNotWeb) {
        return 'Alerts are only available on your web dyno.';
      } else if (this.canNotConfigureAlerts) {
        return 'Alerts can only be configured by app members with the Operate permission.';
      } else {
        return 'Monitor your web dyno with Alerts.';
      }
    }),
    actions: {
      open() {
        this.set('isOpened', true);
      },

      close() {
        this.set('openPanelName', null);
        this.set('isOpened', false);
      },

      openedItem(name) {
        this.set('openPanelName', name);
      },

      closedItem() {
        this.set('openPanelName', null);
      }

    }
  });

  _exports.default = _default;
});