define("dashboard/templates/components/app/banner-feature-flow-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8NBAKDpw",
    "block": "[[[41,[30,0,[\"canEnterBannerFeature\"]],[[[41,[30,0,[\"shouldRenderDevContainer\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"app\",\"hasBeenOnPlatformForAtLeastThreeMonths\",\"appHasDeploys\"],[[30,0,[\"app\"]],[30,0,[\"hasBeenOnPlatformForAtLeastThreeMonths\"]],[30,0,[\"appHasDeploys\"]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"shouldRenderAddonContainer\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"app\",\"hasBeenOnPlatformForAtLeastThreeMonths\",\"appHasDeploys\",\"canView\"],[[30,0,[\"app\"]],[30,0,[\"hasBeenOnPlatformForAtLeastThreeMonths\"]],[30,0,[\"appHasDeploys\"]],[30,0,[\"canView\"]]]]]],[1,\"\\n  \"]],[]],null]],[]]]],[]],null],[1,\"\\n\"]],[],false,[\"if\",\"app/developer-experience-banners-container\",\"app/addons-banners-container\"]]",
    "moduleName": "dashboard/templates/components/app/banner-feature-flow-container.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});