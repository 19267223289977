define("dashboard/templates/components/table/check-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NzbvbcjD",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[[52,[30,1],\"confirm-16\",\"delete-16\"],[28,[37,2],[[30,2],16],null],[29,[\"malibu-fill-gradient-\",[52,[30,1],\"green\",\"red\"]]],[52,[30,1],\"Yes\",\"No\"]]],null],[1,\"\\n\"]],[\"@enabled\",\"@size\"],false,[\"malibu-icon\",\"if\",\"or\"]]",
    "moduleName": "dashboard/templates/components/table/check-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});