define("dashboard/templates/components/org/addons-allowlist-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HXfemnUx",
    "block": "[[[10,0],[14,0,\"bb b--silver pa2 flex items-center\"],[14,\"role\",\"listitem\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"name\",\"class\",\"role\"],[[30,0,[\"item\",\"addonService\",\"name\"]],\"dib br1 mr4 flex-shrink-0 self-start\",\"presentation\"]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex flex-auto flex-shrink-0\"],[12],[1,\"\\n    \"],[1,[30,0,[\"item\",\"addonService\",\"human_name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"mr2\"],[12],[1,\"\\n    \"],[11,3],[24,6,\"#\"],[24,0,\"hk-button-sm--tertiary\"],[4,[38,1],[[30,0],\"toggleInstalls\"],null],[12],[1,\"\\n      \"],[1,[52,[30,0,[\"appsListVisible\"]],\"Hide\",\"Details\"]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"mr2\"],[12],[1,\"\\n    \"],[8,[39,3],[[4,[38,1],[[30,0],\"removeFromAllowlist\"],null]],[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[\"remove-ring-16\",\"16\",\"fill-red\",[29,[\"Remove \",[30,0,[\"item\",\"addonService\",\"human_name\"]],\" from the allowed add-ons list\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[[52,[51,[30,0,[\"appsListVisible\"]]],\"dn\"],\" pa2 bb b--silver dark-gray\"]]],[12],[1,\"\\n  Allowed by \"],[10,\"strong\"],[12],[1,[30,0,[\"addedBy\"]]],[13],[1,\" on \"],[1,[30,0,[\"addedAt\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"addon-icon\",\"action\",\"if\",\"malibu-icon-button\",\"unless\"]]",
    "moduleName": "dashboard/templates/components/org/addons-allowlist-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});