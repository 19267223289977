define("dashboard/controllers/app/resources/new", ["exports", "rsvp", "@ember/object", "@ember/controller", "@ember/service"], function (_exports, _rsvp, _object, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    bool,
    and,
    alias
  } = _object.computed;

  var _default = _controller.default.extend({
    queryParams: {
      addonService: {
        refreshModel: true
      },
      addonPlan: {
        refreshModel: false
      }
    },
    appResourcesController: (0, _controller.inject)('app.resources'),
    analytics: (0, _service.inject)(),
    attachment: alias('model.attachment'),
    addon: alias('attachment.addon'),
    app: alias('attachment.app'),
    isInASpace: bool('app.space'),
    // watch out! addonService in this context is really addonServiceName
    // the model is attachment.addonService
    // we could short-term alias this one addonServiceModel but I don't like that so much
    isProvisionable: (0, _object.computed)('hasAttachment', 'attachment.addonService', function () {
      return !this.get('attachment.addonService.isNotProvisionable');
    }),
    hasAttachment: bool('attachment'),
    hasAttachmentAndIsProvisionable: and('hasAttachment', 'isProvisionable'),
    actions: {
      // save a new addon, not for editing one
      saveAddon() {
        const attachment = this.attachment;
        const app = attachment.get('addon.app');

        if (!this.get('attachment.addon.isValid')) {
          return (0, _rsvp.reject)();
        } // follow ember-async convention


        return attachment.get('addon').then(addon => {
          return addon.save().then(savedAddon => {
            savedAddon.set('app', app); // re-attach the full app object that was clobbered during save

            savedAddon.set('confirmNotAutoNetworked', false); // clear out this value

            this.analytics.logEvent('Add-on', 'Provisioned'); // return to parent route

            this.send('refreshResourcesRoute');
            const justInstalledAddonServiceId = savedAddon.get('addonService.id');
            this.transitionToRoute('app.resources', {
              queryParams: {
                justInstalledAddonServiceId
              }
            }); // remove zombie attachment record
            // TODO: wait until save to create attachment

            attachment.unloadRecord();
          });
        });
      },

      closeAddonModal() {
        // remove zombie attachment record
        // TODO: wait until save to create attachment
        if (this.attachment) {
          this.attachment.unloadRecord();
        }

        this.transitionToRoute('app.resources');
      }

    }
  });

  _exports.default = _default;
});