define("dashboard/components/apps-list", ["exports", "@ember/component", "@ember/object", "dashboard/utils/pluralize"], function (_exports, _component, _object, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['apps-list'],
    buttonContent: (0, _object.computed)('apps.[]', function () {
      const length = this.get('apps.length');
      const noun = (0, _pluralize.default)(length, 'app', 'apps');
      return `${length} ${noun}`;
    })
  });

  _exports.default = _default;
});