define("dashboard/components/requires-password", ["exports", "rsvp", "@ember/application", "@ember/component", "dashboard/models/password-check"], function (_exports, _rsvp, _application, _component, _passwordCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   *  Designed for use within a modal. Displays a password prompt and then
   *  triggers an action when the password has been entered. The calling context
   *  passes in a needsPassword boolean which can be flipped to make the component
   *  render the block content.
   *
   *  {{#requires-password action="someAction" needsPassword=someBoolean}}
   *    Content to show when the password has been entered
   *  {{/requires-password}}
   *
   *  @class Dashboard.RequiresPasswordComponent
   *  @extends Ember.Component
   *  @param {Action} action - the action to trigger when the password is entered
   *  @param {Bool} needsPassword - a boolean which the calling context can flip when the password prompt can be hidden
   *  @param {Bool} checkPassword - when true, the component will make an API call to confirm that the supplied password is correct before triggering the action
   *  @param {String} email - required for password checking only if checkPassword is true
   *  @param {String} labelText - text for the form <label>
   *  @param {String} buttonText - text for the form <button>
   *  @param {Action} onCancel - action to take on cancel
   */
  var _default = _component.default.extend({
    confirmPassword: false,
    needsPassword: true,
    checkPassword: false,
    labelText: 'Enter your password',
    buttonText: 'Confirm Password',

    performPasswordCheck() {
      if (!this.checkPassword) {
        return _rsvp.Promise.resolve(true);
      }

      return _passwordCheck.default.create((0, _application.getOwner)(this).ownerInjection(), {
        email: this.email,
        password: this.password
      }).check();
    },

    actions: {
      setPassword() {
        return this.performPasswordCheck().then(isCorrect => {
          if (isCorrect) {
            return this.action(this.password);
          }
        });
      },

      cancel() {
        this.attrs.onCancel();
      }

    }
  });

  _exports.default = _default;
});