define("dashboard/components/metrics/charts/timeseries-chart", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/debug", "@ember/component", "@ember/object", "dashboard/mixins/chart-dimension-tracker", "dashboard/mixins/metrics/chart-time-state", "dashboard/mixins/metrics/controllable-chart", "d3", "@ember/string", "dashboard/templates/components/metrics/charts/timeseries-chart", "ember-concurrency"], function (_exports, _computed, _runloop, _debug, _component, _object, _chartDimensionTracker, _chartTimeState, _controllableChart, _d, _string, _timeseriesChart, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * a d3 value scale function
   * see: https://github.com/mbostock/d3/wiki/Quantitative-Scales#linear-scales
   */
  const createLinearScale = function (maxValueKey, minValueKey) {
    return (0, _object.computed)('innerHeight', '_niceValueScale', 'chartHasOpposingTimeSeries', maxValueKey, minValueKey, function () {
      const maxValue = this.get(maxValueKey);
      const minValue = this.get(minValueKey);
      const lowerBound = this.get('chartHasOpposingTimeSeries') ? -maxValue : minValue;
      const domain = [maxValue, lowerBound];
      const scale = (0, _d.scaleLinear)().domain(domain).rangeRound([0, this.get('innerHeight')]);

      if (this.get('_niceValueScale')) {
        scale.nice(4);
      }

      return scale;
    });
  }; // Given an array of arrays, map each one to its' min/max value.


  const extractValues = function (dataset, type) {
    return (0, _computed.map)(dataset, function (dataPoints) {
      return dataPoints.reduce((val, dataPoint) => {
        if (type === 'max') {
          return Math.max(val, dataPoint[1]);
        } else {
          return Math.min(val, dataPoint[1]);
        }
      }, 0);
    });
  };
  /**
   * a fault tolerant visual display that overrides the passed in
   * `defaultMaxValue` if this chart detects a graph that contains a higher value
   */


  const calculateBoundary = function (valueKey, boundaryType) {
    return (0, _object.computed)('defaultMaxValue', 'defaultMinValue', valueKey, 'boundaryMultiplier', function () {
      const multiplier = this.get('boundaryMultiplier') || 1;

      if (boundaryType === 'max') {
        return Math.max(this.get('defaultMaxValue'), this.get(valueKey)) * multiplier;
      } else {
        return Math.min(this.get('defaultMinValue'), this.get(valueKey)) * multiplier;
      }
    });
  };
  /**
   * a {{timeseries-chart}} contains a collection of graphs
   *
   * it must be given a `title` and array of times for the given data via `times`
   *
   * alertSummaryType: Type of alert based on this data
   * alternateMaxValue: Default max value for the second y axis of a chart with a secondary axis
   * alternateUnit: For charts with a second axis, the units for the y value
   * boundaryMultiplier: Value used to adjust amount of space between max value and top of chart
   * chartHasOpposingTimeSeries: Indicates a chart with some dataset that is displayed upside down relative to some other dataset
   * defaultMaxValue: Default max value for the y axis
   * hasAlternateAxis: Indicates chart has a second y axis
   * isLogarithmic: Indicates this chart is logarithmic, which changes axis value calculations
   * secondaryUnit: Supplemental unit type to display in chart legend
   */


  var _default = _component.default.extend(_controllableChart.default, _chartTimeState.default, _chartDimensionTracker.default, {
    layout: _timeseriesChart.default,
    tagName: 'timeseries-chart',
    classNames: ['metrics__chart', 'w-100'],
    chartContentComponent: 'metrics/charts/chart-content/line-chart',
    insufficientDataTitle: 'Insufficient Data',
    isShowingHeader: true,
    isShowingLegend: true,
    isShowingConnectedChartHover: (0, _computed.and)('isShowingLegend', 'isRowChart'),

    /**
     * a collection of {{registered-chart-element}}-based objects that are reported by the
     * objects themselves
     */
    graphs: (0, _object.computed)(function () {
      return [];
    }),
    devCenterUrl: (0, _object.computed)('devCenterName', function () {
      return `https://devcenter.heroku.com/articles/${this.get('devCenterName')}`;
    }),
    timeSeriesData: (0, _object.computed)(function () {
      if (!this.get('isRowChart')) {
        throw new Error(`you must pass timeSeriesData in to ${(0, _debug.inspect)(this)}`);
      }
    }),
    defaultMaxValue: null,
    defaultMinValue: 0,
    maxValues: extractValues('timeSeriesData', 'max'),
    maxMaxValue: (0, _computed.max)('maxValues'),
    maxDisplayValue: calculateBoundary('maxMaxValue', 'max'),
    minValues: extractValues('timeSeriesData', 'min'),
    minMinValue: (0, _computed.min)('minValues'),
    minDisplayValue: calculateBoundary('minMinValue', 'min'),
    alternateMinDisplayValue: 0,

    /**
     * flag to use d3's `nice` or not for the valueScale
     *
     * see: https://github.com/mbostock/d3/wiki/Quantitative-Scales#linear_nice
     */
    _isMemoryChart: (0, _computed.equal)('unit', 'memory'),
    _niceValueScale: (0, _computed.not)('_isMemoryChart'),
    valueScale: createLinearScale('maxDisplayValue', 'minDisplayValue'),
    altValueScale: createLinearScale('alternateMaxValue', 'alternateMinDisplayValue'),
    hasInsufficientData: (0, _object.computed)('graphs.@each.hasSufficientData', function () {
      return this.get('graphs').filterBy('hasSufficientData').length === 0;
    }),
    showingInsufficientData: (0, _computed.and)('hasInsufficientData', 'isNotLoadingData'),
    isNotLoadingData: (0, _computed.not)('isShowingLoadingState'),
    chartIsReady: (0, _computed.and)('width', 'isNotLoadingData'),
    _graphsToRemove: (0, _object.computed)(function () {
      return [];
    }),

    _unregister() {
      this.get('graphs').removeObjects(this.get('_graphsToRemove'));
      this.set('_graphsToRemove', []);
    },

    _isStandardLayout: (0, _computed.not)('isCompactLayout'),
    chartIsHidden: (0, _computed.and)('_isStandardLayout', 'chartIsCollapsed'),
    reserveWhiteSpaceForChart: true,
    isRowChart: (0, _object.computed)('chartKey', function () {
      const chartKey = this.chartKey;
      return chartKey === 'events' || chartKey === 'routing-health';
    }),
    legendItems: (0, _object.computed)('graphs.[]', function () {
      return this.graphs;
    }),
    currentTimeframe: (0, _object.computed)('timeframe', function () {
      return this.get('timeframe') || {
        name: 'past24hours',
        // Unique identifying name
        beforeHours: 0,
        // End of the timeframe, as defined by number of hours before current moment in time
        afterHours: 24,
        // Start of the timeframe, as defined by number of hours before current moment in time
        step: 10 // Resolution of chart data, in minutes

      };
    }),
    _hideChartStyles: (0, _object.computed)(function () {
      return (0, _string.htmlSafe)(`
        top: -9999px;
        left: -999px;
        visibility: hidden;
      `);
    }),
    recalculateDimensionsTask: (0, _emberConcurrency.task)(function* () {
      yield new Promise(resolve => {
        requestAnimationFrame(resolve);
      });
      (0, _runloop.scheduleOnce)('afterRender', this, this.recalculateDimensions);
    }).restartable(),
    actions: {
      toggleCollapseChart() {
        this.toggleProperty('chartIsCollapsed');
        this.get('onToggleCollapseChart')(this.get('chartIsCollapsed'), this.get('chartKey'));
      },

      // Register a child graph onto our internal array.
      // We provide this method so that the child graph doesn't have access to the
      // underlying array and to avoid re-render warnings.
      registerChartElement(chartElement) {
        this.get('graphs').addObject(chartElement);
      },

      // Remove child graphs from our internal tracking array
      // at the end of the run loop.
      unregisterChartElement(chartElement) {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this.get('_graphsToRemove').push(chartElement);
          (0, _runloop.once)(this, this._unregister);
        }
      }

    }
  });

  _exports.default = _default;
});