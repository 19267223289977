define("dashboard/controllers/team/switchable/access", ["exports", "@ember/controller", "dashboard/utils/custom-computed", "rsvp", "@ember/object", "@ember/service", "@ember/object/computed", "ember-concurrency"], function (_exports, _controller, _customComputed, _rsvp, _object, _service, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    current: (0, _service.inject)(),
    glostick: (0, _service.inject)(),
    queryParams: ['user', 'filter'],
    user: null,
    addUser: (0, _computed.equal)('user', 'add'),
    filter: '',
    member: (0, _customComputed.findBy)('model.members', 'email', 'user'),
    team: (0, _computed.alias)('model.team'),
    identityProvider: (0, _object.computed)('team.hasFederatedIdentityFlag', 'team.isAdmin', 'team.identityProvider.content', 'team.enterprise.identityProvider.content', function () {
      if (this.get('team.hasFederatedIdentityFlag') && this.get('team.isAdmin')) {
        return this.get('team.identityProvider.content') || this.get('team.enterprise.identityProvider.content');
      }
    }),
    title: 'Access',
    teamHasApps: (0, _computed.notEmpty)('model.apps'),
    role: (0, _object.computed)('user', function () {
      if (this.user === 'collaborator') {
        return 'collaborator';
      } else {
        return null;
      }
    }),
    itemToDelete: null,
    collaborators: (0, _computed.filterBy)('model.members', 'isCollaborator', true),
    members: (0, _computed.filterBy)('model.members', 'isCollaborator', false),
    sortKey: ['email:asc'],
    membersAndInvitations: (0, _computed.union)('members', 'team.invitations'),
    loadedMembersAndInvitations: (0, _computed.filterBy)('membersAndInvitations', 'id'),
    loadedMembers: (0, _computed.filterBy)('team.members', 'id'),
    filteredMembers: (0, _customComputed.fuzzyFilterBy)('loadedMembers', 'email', 'filter'),
    sortedMembers: (0, _computed.sort)('filteredMembers', 'sortKey'),
    loadedInvitations: (0, _computed.filterBy)('team.invitations', 'id'),
    filteredInvitations: (0, _customComputed.fuzzyFilterBy)('loadedInvitations', 'email', 'filter'),
    sortedInvitations: (0, _computed.sort)('filteredInvitations', 'sortKey'),
    enterpriseAccountManagers: (0, _computed.filterBy)('current.enterpriseAccountMembers', 'canManage'),
    enterpriseAccountManagerEmails: (0, _computed.mapBy)('enterpriseAccountManagers', 'userEmail'),
    emptyFilter: (0, _computed.empty)('filter'),
    hasManagers: (0, _computed.notEmpty)('enterpriseAccountManagerEmails'),
    showManagers: (0, _computed.and)('emptyFilter', 'hasManagers'),
    canEditMembers: (0, _computed.bool)('current.teamPermissions.canManageUsers'),
    currentAccountIsMember: (0, _object.computed)('current.account.email', 'team.members.@each.email', function () {
      const email = this.get('current.account.email');
      const members = this.get('team.members');
      return Boolean(members.findBy('email', email));
    }),
    displayMemberPanel: (0, _computed.and)('canEditMembers', 'user'),
    deleteItem: (0, _emberConcurrency.task)(function* (item) {
      const memberEmail = item?.email;
      yield item.destroyRecord();
      this.set('itemToDelete', null);
      this.didDeleteMember(memberEmail);
    }).drop(),

    didSaveMember() {
      this.set('user', null);
    },

    didDeleteMember(memberEmail) {
      this.set('user', null);

      if (memberEmail === this.get('current.account.email')) {
        this.didDeleteSelf();
      }
    },

    didDeleteSelf() {
      this.team.unloadRecord();
      this.glostick.refreshQuickJump();
      this.transitionToRoute('/');
    },

    openEnterpriseSalesWindow() {
      window.open('https://www.heroku.com/contact?c=7013A000000mKrsQAE#sales', '_blank');
      return (0, _rsvp.resolve)();
    }

  });

  _exports.default = _default;
});