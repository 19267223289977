define("dashboard/serializers/addon-service-plan", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(modelClass, resourceHash) {
      resourceHash['addonService'] = resourceHash['addon_service']['id'];
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});