define("dashboard/adapters/team/invitation", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    createMethod: 'PUT',

    urlForCreateRecord(modelName, snapshot) {
      const teamId = snapshot.record.get('team.id');
      return `${this.host}/teams/${teamId}/invitations`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const teamId = snapshot.record.get('team.id');
      const invitationId = snapshot.id;
      return `${this.host}/teams/${teamId}/invitations/${invitationId}`;
    }

  });

  _exports.default = _default;
});