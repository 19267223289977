define("dashboard/components/metrics/charts/axes-elements/axes-container", ["exports", "@ember/object", "@ember/component"], function (_exports, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const timeframeAdjustments = {
    past2Hours: {
      chartExtension: 1,
      dateLabelCutoff: 23,
      ticksInterval: 5,
      timeLabelInterval: 0.5,
      dateLabelInterval: 1
    },
    past24Hours: {
      chartExtension: 10,
      dateLabelCutoff: 21,
      ticksInterval: 60,
      timeLabelInterval: 3,
      dateLabelInterval: 1
    },
    '48To24HoursAgo': {
      chartExtension: 10,
      dateLabelCutoff: 21,
      ticksInterval: 60,
      timeLabelInterval: 3,
      dateLabelInterval: 1
    },
    '72To48HoursAgo': {
      chartExtension: 10,
      dateLabelCutoff: 21,
      ticksInterval: 60,
      timeLabelInterval: 3,
      dateLabelInterval: 1
    },
    past72Hours: {
      chartExtension: 1,
      dateLabelCutoff: 16,
      ticksInterval: 1,
      timeLabelInterval: 6,
      dateLabelInterval: 1
    },
    past7days: {
      chartExtension: 2,
      dateLabelCutoff: 12,
      ticksInterval: 2,
      timeLabelInterval: 12,
      dateLabelInterval: 2
    }
  };

  const findAdjustedValue = function (valueKey) {
    return (0, _object.computed)('timeframeName', function () {
      const adjustmentValues = timeframeAdjustments[this.timeframeName] || timeframeAdjustments['past24Hours'];
      return adjustmentValues[valueKey];
    });
  };
  /**
   * a collection of {{axis-element}} components
   */


  var _default = _component.default.extend({
    tagName: 'g',
    classNames: ['metrics__chart-axes'],
    yAxisOffset: (0, _object.computed)('chartDimensions.width', 'chartDimensions.padding.right', function () {
      return this.get('chartDimensions.width') - this.get('chartDimensions.padding.right');
    }),
    chartExtension: findAdjustedValue('chartExtension'),
    timeframeTimeLabelInterval: findAdjustedValue('timeLabelInterval'),
    timeframeTicksInterval: findAdjustedValue('ticksInterval'),
    dateLabelCutoff: findAdjustedValue('dateLabelCutoff'),
    dateLabelInterval: findAdjustedValue('dateLabelInterval')
  });

  _exports.default = _default;
});