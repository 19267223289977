define("dashboard/components/ci/filter-branches-dropdown", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    branchFindQuery: '',
    isNewTestRun: false,
    filteredBranches: (0, _object.computed)('branches.[]', 'branchFindQuery', function () {
      const regex = new RegExp(this.branchFindQuery.split('').join('.*'));
      return this.branches.filter(branch => {
        return regex.test(branch);
      });
    }),
    actions: {
      selectBranch(branch) {
        this.set('selectedBranch', branch);
      }

    }
  });

  _exports.default = _default;
});