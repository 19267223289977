define("dashboard/templates/components/enterprise-account/audit-trail-archives", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/VCE96jH",
    "block": "[[[10,\"form\"],[14,1,\"audit-trail-export-form\"],[14,\"role\",\"form\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex pt1\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"onChange\",\"class\"],[[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"selectedArchive\"]]],null]],null],\"flex-auto mr2\"]],[[\"default\"],[[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"sortedArchives\"]]],null]],null],null,[[[1,\"        \"],[6,[30,1,[\"option\"]],null,[[\"value\"],[[30,2]]],[[\"default\"],[[[[1,[30,2,[\"displayName\"]]]],[]]]]],[1,\"\\n\"]],[2]],null]],[1]]]]],[1,\"    \"],[1,[28,[35,5],null,[[\"type\",\"form\",\"onClick\",\"default\"],[\"submit\",\"audit-trail-export-form\",[28,[37,1],[[30,0],\"submit\"],null],\"Export as JSON\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"iframe\"],[14,1,\"audit-trail-download\"],[14,0,\"dn\"],[12],[13],[1,\"\\n\"]],[\"xs\",\"archive\"],false,[\"hk-select\",\"action\",\"mut\",\"each\",\"-track-array\",\"hk-button\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/audit-trail-archives.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});