define("dashboard/components/github/repos-api-authorize-button", ["exports", "dashboard/components/github/authorize-button", "ember-concurrency", "@ember/service", "dashboard/config/environment", "@ember/object"], function (_exports, _authorizeButton, _emberConcurrency, _service, _environment, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _authorizeButton.default.extend({
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    router: (0, _service.inject)(),
    buttonClass: 'hk-button-sm--primary',
    buttonText: 'Install',
    connect: (0, _object.computed)(function () {
      return this.authorizeGithub;
    }),
    authorizeGithub: (0, _emberConcurrency.task)(function* () {
      const torii = this.torii;
      let data;

      try {
        // While we transition from GitHub Personal OAuth tokens to GitHub App
        // tokens, we will manually replace GitHub ID for users flagged into
        // `dashboard-repositories-api` going through this flow
        _environment.default.torii.providers['github-oauth2'].apiKey = _environment.default.reposAPIgithubClientId;
        data = yield torii.open('github-oauth2');
      } catch (e) {
        this.errorMonitor.createErrorContainer({
          title: "Couldn't connect to GitHub",
          content: [e]
        });
        throw e;
      } finally {
        _environment.default.torii.providers['github-oauth2'].apiKey = _environment.default.githubClientId;
      }

      yield this.store.createRecord('repositories-api-github-oauth-token', {
        code: data.authorizationCode
      }).save();
      return this.get('onAuthorize')();
    }).drop(),
    actions: {
      performConnectTask() {
        return this.get('connect').perform();
      }

    }
  });

  _exports.default = _default;
});