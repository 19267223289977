define("dashboard/templates/components/ci/test-node", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fMXs2Vqm",
    "block": "[[[1,[28,[35,0],null,[[\"title\",\"index\",\"classNames\",\"streamUrl\",\"isWaiting\",\"isRunning\",\"isCancelled\",\"isSucceeded\",\"isFailed\",\"didNotRun\",\"cancelledStatus\",\"waitingStatus\",\"runningStatus\",\"succeededStatus\",\"failedStatus\",\"didNotRunStatus\"],[\"Test setup\",[52,[30,0,[\"hasMultipleTestNodes\"]],[30,0,[\"index\"]],[27]],\"ci-view--setup\",[30,0,[\"testNode\",\"setupStreamUrl\"]],[28,[37,2],[[30,0,[\"testNode\",\"status\"]],\"before\",\"building\"],null],[30,0,[\"testNode\",\"isBuilding\"]],[30,0,[\"testNode\",\"isCancelled\"]],[28,[37,3],[[30,0,[\"testNode\",\"isRunning\"]],[30,0,[\"testNode\",\"isSucceeded\"]],[30,0,[\"testNode\",\"isFailed\"]]],null],[30,0,[\"testNode\",\"failedInBuilding\"]],[28,[37,3],[[30,0,[\"testNode\",\"failedInPending\"]],[30,0,[\"testNode\",\"failedInCreating\"]]],null],\"was cancelled\",\"is pending\",\"is running\",\"succeeded\",\"errored\",\"did not run\"]]]],[1,\"\\n\\n\"],[1,[28,[35,0],null,[[\"title\",\"index\",\"classNames\",\"streamUrl\",\"isWaiting\",\"isRunning\",\"isCancelled\",\"isSucceeded\",\"isFailed\",\"didNotRun\",\"cancelledStatus\",\"waitingStatus\",\"runningStatus\",\"succeededStatus\",\"failedStatus\",\"didNotRunStatus\",\"outputSize\"],[\"Tests\",[52,[30,0,[\"hasMultipleTestNodes\"]],[30,0,[\"index\"]],[27]],\"ci-view--output\",[30,0,[\"testNode\",\"outputStreamUrl\"]],[28,[37,2],[[30,0,[\"testNode\",\"status\"]],\"before\",\"running\"],null],[30,0,[\"testNode\",\"isRunning\"]],[30,0,[\"testNode\",\"isCancelled\"]],[30,0,[\"testNode\",\"isSucceeded\"]],[30,0,[\"testNode\",\"isFailed\"]],[30,0,[\"testNode\",\"failedSetup\"]],\"were cancelled\",\"are pending\",\"are running\",\"succeeded\",\"failed\",\"did not run\",\"full\"]]]],[1,\"\\n\"]],[],false,[\"ci/test-module\",\"if\",\"is-test-state\",\"or\"]]",
    "moduleName": "dashboard/templates/components/ci/test-node.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});