define("dashboard/services/breadcrumbs", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    trail: (0, _object.computed)(function () {
      return null;
    })
  });

  _exports.default = _default;
});