define("dashboard/routes/team/switchable/spaces/new", ["exports", "rsvp", "@ember/service", "dashboard/routes/team/base"], function (_exports, _rsvp, _service, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    analytics: (0, _service.inject)(),

    model() {
      const {
        regions
      } = this.modelFor('protected');
      const {
        team
      } = this.modelFor('team');
      return (0, _rsvp.hash)({
        regions: regions.filterBy('privateCapable'),
        space: this.store.createRecord('space', {
          team
        }),
        defaultRegion: regions.findBy('name', 'virginia')
      });
    },

    afterModel(model) {
      const {
        team
      } = this.modelFor('team');
      this.analytics.logEvent(`${team.analyticsNoun} New Page`, 'Viewed');
      model.space.set('region', model.defaultRegion);
    },

    deactivate() {
      this.store.peekAll('space').filterBy('isNew', true).forEach(newSpace => {
        newSpace.destroyRecord();
      });
    }

  });

  _exports.default = _default;
});