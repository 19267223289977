define("dashboard/components/dyno-size-icon", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays a dyno size icon.
   * @class DynoTierIcon
   * @extends Ember.Component
   * @param name String A dyno size ('1X', 'Standard-2x', etc.).
   */
  var _default = _component.default.extend({
    classNames: ['flex', 'relative', 'dyno-size-icon'],
    'data-test-target': (0, _computed.readOnly)('iconName'),
    iconName: (0, _object.computed)('name', function () {
      const name = this.get('name');

      if ((0, _utils.isPresent)(name)) {
        return `dyno-${name.toLowerCase()}`;
      } else {
        return `dyno-placeholder`;
      }
    }),
    svgName: (0, _object.computed)('iconName', function () {
      const iconName = this.get('iconName');
      return `/images/svgs/object/${iconName.toLowerCase()}.svg`;
    })
  });

  _exports.default = _default;
});