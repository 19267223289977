define("dashboard/components/review-apps-quick-config", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "@ember/service"], function (_exports, _component, _computed, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['pipeline-app', 'pr-app', 'pt1', 'pb1', 'ph1'],
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    pipeline: (0, _computed.readOnly)('reviewAppConfig.pipeline'),
    showUsageInfo: (0, _object.computed)('pipeline.isOwned', 'pipeline.isUserPipeline', 'pipeline.isUserOwner', function () {
      return this.get('pipeline.isUserPipeline') ? this.get('pipeline.isUserOwner') : this.get('pipeline.isOwned');
    }),
    usageTeamName: (0, _computed.readOnly)('pipeline.team.name'),
    usageName: (0, _object.computed)('current.account.name', 'pipeline.team.name', 'pipeline.isUserPipeline', function () {
      const userName = this.get('current.account.name') || this.get('current.account.email');
      return this.get('pipeline.isUserPipeline') ? userName : this.get('pipeline.team.name');
    }),
    actions: {
      updateBooleanReviewAppConfigProperty(property) {
        const reviewAppConfig = this.get('reviewAppConfig.content');
        reviewAppConfig.toggleProperty(property);
        return reviewAppConfig.save();
      },

      saveUpdatedReviewApp() {
        const reviewAppConfig = this.get('reviewAppConfig.content');
        return reviewAppConfig.save();
      }

    }
  });

  _exports.default = _default;
});