define("dashboard/components/dyno-tier-icon", ["exports", "@ember/object", "@ember/object/computed", "@ember/component"], function (_exports, _object, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Map process type to the tier icon, for those that can't be guessed.
  const dynoSizeTierIcons = {
    'Standard-1X': 'production',
    'Standard-2X': 'production',
    'Performance-M': 'performance',
    'Performance-L': 'performance',
    'Private-S': 'production',
    'Private-M': 'production',
    'Private-L': 'production'
  };
  /**
   * Displays a dyno tier icon.
   * @class DynoTierIcon
   * @extends Ember.Component
   * @param name String A dyno size or dyno tier name.
   */

  var _default = _component.default.extend({
    classNames: ['inline-flex'],
    'data-test-target': (0, _computed.readOnly)('iconName'),
    iconName: (0, _object.computed)('name', function () {
      const name = this.name;
      const tier = dynoSizeTierIcons[name] || name.toLowerCase();
      return `dyno-tier-${tier}`;
    })
  });

  _exports.default = _default;
});