define("dashboard/adapters/review-app", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.review-apps',

    urlForQueryRecord(_ref) {
      let {
        app_id
      } = _ref;
      const baseUrl = this.buildURL();
      return `${baseUrl}/apps/${app_id}/review-app`;
    }

  });

  _exports.default = _default;
});