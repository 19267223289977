define("dashboard/components/pipeline/review-app-or-pr", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    typeOfApp: (0, _computed.reads)('appOrPr.constructor.modelName'),
    isNewReviewApp: (0, _computed.equal)('typeOfApp', 'review-app')
  });

  _exports.default = _default;
});