define("dashboard/components/app/addons-banners-container", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed", "dashboard/mixins/preferences", "dashboard/config/environment", "ember-concurrency", "dashboard/utils/banner-not-dismissed"], function (_exports, _component, _object, _service, _computed, _preferences, _environment, _emberConcurrency, _bannerNotDismissed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_NUMBER_OF_INSTALLED_ADDONS = 3;

  var _default = _component.default.extend(_preferences.default, {
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    'data-test-target': 'addons-banners-container',

    init() {
      this._super(...arguments);

      this._setOriginalDismissals();

      this.loadInstalledAddons.perform();
    },

    appHasNoInstalledAddons: (0, _computed.empty)('installedAddons'),
    isUnderMaxInstalls: (0, _computed.lte)('installedAddons.length', MAX_NUMBER_OF_INSTALLED_ADDONS),
    isOverMaxInstalls: (0, _computed.not)('isUnderMaxInstalls'),
    canEnterAddonsFlow: (0, _computed.alias)('dashboardUserPreferences.isEligibleForAddonsBannerFlow'),
    shouldRemoveEligibility: (0, _computed.and)('canEnterAddonsFlow', 'isOverMaxInstalls'),
    currentDismissals: (0, _computed.alias)('dashboardUserPreferences.addonBannerDismissals'),
    dismissalChanges: (0, _computed.setDiff)('currentDismissals', 'originalDismissals'),
    userDidNotDismiss: (0, _computed.empty)('dismissalChanges'),
    hasNotDismissedIntroToElementsBanner: (0, _bannerNotDismissed.default)('addonBannerDismissals', 'introToElementsBanner'),
    hasNotDismissedPostgresBanner: (0, _bannerNotDismissed.default)('addonBannerDismissals', 'postgresBanner'),
    hasNotDismissedLoggingBanner: (0, _bannerNotDismissed.default)('addonBannerDismissals', 'loggingBanner'),
    hasNotDismissedSecurityBanner: (0, _bannerNotDismissed.default)('addonBannerDismissals', 'securityBanner'),
    hasNotDismissedAllBanners: (0, _computed.or)('hasNotDismissedIntroToElementsBanner', 'hasNotDismissedPostgresBanner', 'hasNotDismissedLoggingBanner', 'hasNotDismissedSecurityBanner'),
    shouldDismissIntroBanner: (0, _computed.and)('introBannerRequirementsFulfilled', 'hasNotDismissedIntroToElementsBanner'),
    shouldDismissPostgresBanner: (0, _computed.and)('postgresBannerRequirementsFulfilled', 'hasNotDismissedPostgresBanner'),
    shouldDismissLoggingBanner: (0, _computed.and)('loggingBannerRequirementsFulfilled', 'hasNotDismissedLoggingBanner'),
    shouldDismissSecurityBanner: (0, _computed.and)('securityBannerRequirementsFulfilled', 'hasNotDismissedSecurityBanner'),
    introBannerRequirementsFulfilled: (0, _computed.not)('canViewIntroBanner'),
    postgresBannerRequirementsFulfilled: (0, _computed.not)('canViewPostgresBanner'),
    loggingBannerRequirementsFulfilled: (0, _computed.not)('canViewLoggingBanner'),
    securityBannerRequirementsFulfilled: (0, _computed.not)('canViewSecurityBanner'),
    canShowNewBanner: (0, _computed.and)('hasNotDismissedAllBanners', 'userDidNotDismiss'),
    hasAccountRequirements: (0, _computed.and)('hasBeenOnPlatformForAtLeastThreeMonths', 'canView', 'canEnterAddonsFlow'),
    hasAppRequirements: (0, _computed.and)('appHasDeploys', 'installedAddonsQueryFulfilled'),
    defaultBannerRequirements: (0, _computed.and)('canShowNewBanner', 'hasAccountRequirements', 'hasAppRequirements'),
    showIntroToElementsBanner: (0, _computed.and)('defaultBannerRequirements', 'canViewIntroBanner'),
    showPostgresBanner: (0, _computed.and)('defaultBannerRequirements', 'canViewPostgresBanner'),
    showLoggingBanner: (0, _computed.and)('defaultBannerRequirements', 'canViewLoggingBanner'),
    showSecurityBanner: (0, _computed.and)('defaultBannerRequirements', 'canViewSecurityBanner'),
    installedAddons: (0, _object.computed)('loadInstalledAddons.last.value', function () {
      return this.get('loadInstalledAddons.last.value') || [];
    }),
    installedAddonsQueryFulfilled: (0, _computed.bool)('loadInstalledAddons.last.isSuccessful'),
    loggingAddons: (0, _object.computed)('config.loggingAddons', function () {
      const loggingAddonsSet = new Set(_environment.default.loggingAddons);
      return loggingAddonsSet;
    }),
    securityAddons: (0, _object.computed)('config.securityAddons', function () {
      const securityAddonsSet = new Set(_environment.default.securityAddons);
      return securityAddonsSet;
    }),
    loadInstalledAddons: (0, _emberConcurrency.task)(function* () {
      if (!this.canView) {
        return [];
      }

      const appId = this.app.get('id');
      return yield this.store.query('addon', {
        appNameOrId: appId
      }).catch(() => {});
    }),
    isPostgresInstalled: (0, _object.computed)('installedAddons.@each.providerDescription', function () {
      return this.installedAddons.find(installedAddon => installedAddon.providerDescription === 'Heroku Postgres');
    }),
    canViewPostgresBanner: (0, _object.computed)('isPostgresInstalled', 'hasNotDismissedPostgresBanner', function () {
      return !this.isPostgresInstalled && this.hasNotDismissedPostgresBanner;
    }),
    canViewIntroBanner: (0, _object.computed)('appHasNoInstalledAddons', 'hasNotDismissedIntroToElementsBanner', function () {
      return this.appHasNoInstalledAddons && this.hasNotDismissedIntroToElementsBanner;
    }),
    canViewSecurityBanner: (0, _object.computed)('installedAddons.[]', 'hasNotDismissedSecurityBanner', function () {
      const hasNoSecurityAddons = !this.installedAddons.find(installedAddon => {
        return this._isSecurityAddon(installedAddon.providerName);
      });
      return hasNoSecurityAddons && this.hasNotDismissedSecurityBanner;
    }),
    canViewLoggingBanner: (0, _object.computed)('installedAddons.[]', 'hasNotDismissedLoggingBanner', function () {
      const hasNoLoggingAddons = !this.installedAddons.find(installedAddon => {
        return this._isLoggingAddon(installedAddon.providerName);
      });
      return hasNoLoggingAddons && this.hasNotDismissedLoggingBanner;
    }),
    shouldDismissFullfilledBanners: (0, _computed.and)('installedAddonsQueryFulfilled', 'canEnterAddonsFlow'),
    dismissFulfilledBannersObserver: (0, _object.observer)('userDidNotDismiss', 'installedAddonsQueryFulfilled', 'installedAddons', function () {
      if (!this.shouldDismissFullfilledBanners) {
        return;
      }

      if (this.shouldRemoveEligibility) {
        this.updateUserPreference('isEligibleForAddonsBannerFlow', false);
        return;
      }

      const newDismissals = [];

      if (this.shouldDismissIntroBanner) {
        newDismissals.push('introToElementsBanner');
      }

      if (this.shouldDismissPostgresBanner) {
        newDismissals.push('postgresBanner');
      }

      if (this.shouldDismissLoggingBanner) {
        newDismissals.push('loggingBanner');
      }

      if (this.shouldDismissSecurityBanner) {
        newDismissals.push('securityBanner');
      }

      if (newDismissals.length !== 0) {
        this._addDismissalsToUserPreferences(newDismissals);

        this._setOriginalDismissals();
      }
    }).on('didReceiveAttrs'),

    _setOriginalDismissals() {
      this.set('originalDismissals', this.dashboardUserPreferences.get('addonBannerDismissals'));
    },

    _addDismissalsToUserPreferences(newDismissals) {
      const userPref = this.dashboardUserPreferences;
      const currentUserPreferencesValue = userPref.get('addonBannerDismissals');
      this.updateUserPreference('addonBannerDismissals', [...currentUserPreferencesValue, ...newDismissals]);
    },

    _isSecurityAddon(addonName) {
      return this.securityAddons.has(addonName.toLowerCase());
    },

    _isLoggingAddon(addonName) {
      return this.loggingAddons.has(addonName.toLowerCase());
    }

  });

  _exports.default = _default;
});