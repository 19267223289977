define("dashboard/templates/components/display-formation-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "B63AyXtK",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"formations\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"flex pa3 items-center bb b--light-silver\"],[12],[1,\"\\n\"],[41,[30,1,[\"dynoCanScale\"]],[[[1,\"      \"],[10,0],[14,0,\"mr3\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"name\",\"title\"],[[30,1,[\"size\"]],[30,1,[\"size\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"mr3 flex-auto\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex flex-row w-90\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"mr2\"],[12],[1,[30,1,[\"type\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"mid-gray f5 code truncate\"],[12],[1,[30,1,[\"command\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,1,[\"dynoCanScale\"]],[[[1,\"        \"],[10,\"progress\"],[14,\"max\",\"100\"],[15,2,[30,1,[\"quantity\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[51,[30,1,[\"dynoCanScale\"]]],[[[1,\"      \"],[10,1],[14,0,\"text-uppercase b f5\"],[12],[1,\"\\n\"],[41,[30,1,[\"quantity\"]],[[[1,\"          \"],[10,1],[14,0,\"green\"],[12],[1,\"On\"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"light-gray\"],[12],[1,\"Off\"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"purple\"],[12],[1,[30,1,[\"quantity\"]]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[1]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"headline\"],[\"No Formations\"]]]],[1,\"\\n\"]],[]]]],[\"process\"],false,[\"each\",\"-track-array\",\"if\",\"dyno-size-icon\",\"unless\",\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/display-formation-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});