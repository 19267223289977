define("dashboard/serializers/enterprise-account/license", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keyForLicense = keyForLicense;
  const KEY_PROPS = ['license_type', 'license_quantity', 'start_date', 'end_date'];

  var _default = _application.default.extend({
    extractId(modelClass, payload) {
      return keyForLicense(payload);
    }

  });

  _exports.default = _default;

  function keyForLicense(license) {
    return KEY_PROPS.map(prop => license[prop]).filter(_ => _).join('-');
  }
});