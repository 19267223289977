define("dashboard/components/team/addons-allowlist-quickadd-item", ["exports", "dashboard/components/purple-typeahead-item"], function (_exports, _purpleTypeaheadItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _purpleTypeaheadItem.default.extend({
    classNames: ['addon-typeahead-cell']
  });

  _exports.default = _default;
});