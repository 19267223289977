define("dashboard/components/metrics/chart-controls/timezone-selector", ["exports", "@ember/component", "@ember/object", "@ember/service", "moment"], function (_exports, _component, _object, _service, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    timezone: 'UTC',
    timezones: (0, _object.computed)(function () {
      const americaAndEurope = [];

      const timezoneNames = _moment.default.tz.names().filter(function (tz) {
        const isNotUSA = tz.indexOf('US/') === -1;
        const isNotEurope = tz.indexOf('Europe/') === -1;

        if (!(isNotUSA && isNotEurope)) {
          americaAndEurope.unshift(tz);
          return false;
        }

        return true;
      });

      timezoneNames.unshift(...americaAndEurope);
      return timezoneNames;
    }),
    actions: {
      changeTimezone(timezone) {
        this.updateTimezone(timezone);
        this.analytics.logMetricsEvent('Timezone', 'Changed', {
          timezone
        });
      }

    }
  });

  _exports.default = _default;
});