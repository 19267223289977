define("dashboard/components/ci/tests-menu-item", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ICONS = {
    cancelled: 'failure-ring-16',
    succeeded: 'success-ring-16',
    failed: 'error-badge-16',
    errored: 'failure-badge-16'
  };
  const CLASS_NAMES = {
    cancelled: 'fill-gray',
    succeeded: 'malibu-fill-gradient-green',
    failed: 'malibu-fill-gradient-red',
    errored: 'malibu-fill-gradient-red'
  };

  var _default = _component.default.extend({
    classNames: ['ci-menu--item'],
    statusIconName: (0, _object.computed)('testRun.status', function () {
      return ICONS[this.get('testRun.status')];
    }),
    statusIconClass: (0, _object.computed)('testRun.status', function () {
      return CLASS_NAMES[this.get('testRun.status')];
    })
  });

  _exports.default = _default;
});