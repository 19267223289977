define("dashboard/templates/components/enterprise-account/member-manager/search-filter-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VWhRo0Rk",
    "block": "[[[10,\"button\"],[15,0,[29,[\"hk-dropdown-item w-100 pr0 tl \",[52,[30,0,[\"selected\"]],\"bg-lightest-purple\"]]]],[15,\"onclick\",[28,[37,1],[[30,0],[30,0,[\"onClick\"]]],null]],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex w-100 relative items-center\"],[12],[1,\"\\n\"],[41,[30,0,[\"selected\"]],[[[1,\"      \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"confirm-16\",\"absolute fill-purple\",14]],null],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,1],[15,0,[29,[\"ml4 \",[52,[30,0,[\"selected\"]],\"purple\"]]]],[12],[18,1,null],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"action\",\"malibu-icon\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/member-manager/search-filter-option.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});