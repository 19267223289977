define("dashboard/models/space/peering", ["exports", "@ember/object/computed", "@ember/object", "ember-data/relationships", "ember-data/attr", "ember-data/model"], function (_exports, _computed, _object, _relationships, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    space: (0, _relationships.belongsTo)('space'),
    awsVpcId: (0, _attr.default)('string'),
    cidrBlock: (0, _attr.default)('string'),
    cidrBlocks: (0, _attr.default)(),
    expires: (0, _attr.default)('string'),
    pcxId: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    awsAccountId: (0, _attr.default)('string'),
    awsRegion: (0, _attr.default)('string'),
    peeringStatus: (0, _object.computed)('status', function () {
      if (this.isPending) {
        return 'pending';
      } else {
        return this.status;
      }
    }),
    isPending: (0, _computed.equal)('status', 'pending-acceptance'),
    isActive: (0, _computed.equal)('status', 'active'),
    isExpired: (0, _computed.equal)('status', 'expired')
  });

  _exports.default = _default;
});