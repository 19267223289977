define("dashboard/components/x-flash-messages", ["exports", "@ember/service", "@ember/component", "@ember/object/computed"], function (_exports, _service, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flash-messages'],
    classNameBindings: ['rolledDown:rolled-down'],
    flashMessages: (0, _service.inject)(),
    notEmpty: (0, _computed.gt)('flashMessages.queue.length', 0),
    rolledDown: (0, _computed.or)('notEmpty', 'hasSocketMessage')
  });

  _exports.default = _default;
});