define("dashboard/components/app/web-hook/status", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATE_ICONS = {
    error: {
      icon: 'failure-badge-16',
      iconClass: 'fill-red',
      textClass: 'red'
    },
    failed: {
      icon: 'failure-badge-16',
      iconClass: 'fill-red',
      textClass: 'red'
    },
    pending: {
      icon: 'time-16',
      iconClass: 'fill-gray',
      textClass: 'gray'
    },
    succeeded: {
      icon: 'success-badge-16',
      iconClass: 'fill-green',
      textClass: 'dark-gray'
    },
    scheduled: {
      icon: 'time-16',
      iconClass: 'fill-gray',
      textClass: 'gray'
    },
    retrying: {
      icon: 'warning-badge-16',
      iconClass: 'malibu-fill-gradient-orange',
      textClass: 'orange'
    }
  };

  var _default = _component.default.extend({
    icon: (0, _object.computed)('status', function () {
      return STATE_ICONS[this.status];
    }),
    iconName: (0, _computed.reads)('icon.icon'),
    iconClass: (0, _computed.reads)('icon.iconClass'),
    textClass: (0, _computed.reads)('icon.textClass')
  });

  _exports.default = _default;
});