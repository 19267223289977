define("dashboard/adapters/kolkrabbi/github-org", ["exports", "dashboard/adapters/application", "dashboard/config/environment", "ember-data", "@ember/service"], function (_exports, _application, _environment, _emberData, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    AdapterError
  } = _emberData.default;

  var _default = _application.default.extend({
    host: _environment.default.kolkrabbiUrl,
    dsErrorMonitor: (0, _service.inject)(),

    handleResponse(status, headers, payload, requestData) {
      if (status === 401 || status === 403) {
        requestData.jqXHR.errorIsHandled = true;
        return new AdapterError();
      }

      if (status === 206) {
        payload.meta = {
          hasMore: true
        };
      }

      return this._super(status, headers, payload, requestData);
    },

    urlForQuery() {
      return `${this.host}/github/orgs`;
    }

  });

  _exports.default = _default;
});