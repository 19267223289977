define("dashboard/helpers/array-group-by", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Groups a collection of objects by the property provided in the name param
   *
   * e.g.
   *   groupBy([
   *     {id: 1, name: 'foos'},
   *     {id: 2, name: 'foos'},
   *     {id: 3, name: 'bars'}
   *   ], 'name');
   *
   *   // returns
   *   [
   *     {
   *       value: 'foos',
   *       items: [
   *         {id: 1, name: 'foos'},
   *         {id: 2, name: 'foos'},
   *       ]
   *     },
   *     {
   *       value: 'bars',
   *       items: [{id: 3, name: 'bars'}]
   *     }
   *   ]
   *
   * @param {array} array - the collection of objects to group
   * @param {string} name - the object property to group by
   * @returns {array} - an array of group objects
   */
  function groupBy(array, name) {
    return array.reduce((groups, item) => {
      const value = item.get(name);
      const group = groups.findBy('value', value);

      if ((0, _utils.isPresent)(group)) {
        group.get('items').pushObject(item);
      } else {
        groups.pushObject(_object.default.create({
          value,
          items: [item]
        }));
      }

      return groups;
    }, []);
  }

  var _default = groupBy;
  _exports.default = _default;
});