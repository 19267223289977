define("dashboard/components/app/activity-build-item", ["exports", "@ember/object", "@ember/string", "dashboard/components/app/activity-item", "dashboard/templates/components/app/activity-item"], function (_exports, _object, _string, _activityItem, _activityItem2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly
  } = _object.computed;

  var _default = _activityItem.default.extend({
    layout: _activityItem2.default,
    descriptionContent: (0, _object.computed)('item.status', 'item.description', function () {
      const labelType = {
        succeeded: 'success',
        failed: 'danger'
      }[this.get('item.status')] || '';
      let description = this.get('item.description');

      if (this.isPending) {
        description = 'Build in progress';
      }

      return (0, _string.htmlSafe)(`<span class="text-${labelType}">${description}</span>`);
    }),
    isPending: readOnly('item.isPending')
  });

  _exports.default = _default;
});