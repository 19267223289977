define("dashboard/components/ci/new-test-run", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/service", "ember-concurrency"], function (_exports, _component, _object, _computed, _utils, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    store: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    selectedBranch: null,
    observeBranch: (0, _object.observer)('selectedBranch', 'isShowing', function () {
      this.fetchBranch.perform();
    }).on('didReceiveAttrs'),
    fetchBranch: (0, _emberConcurrency.task)(function* () {
      if (!this.isShowing) {
        return null;
      }

      let repo;

      if (this.get('pipeline.isGithubAppEnabled')) {
        repo = yield this.get('pipeline.connectedRepository.name');
      } else {
        repo = yield this.get('pipeline.githubRepository.repository.name');
      }

      const branch = this.selectedBranch;

      if ((0, _utils.isNone)(repo) || (0, _utils.isNone)(branch)) {
        return null;
      }

      if (this.get('pipeline.isGithubAppEnabled')) {
        return yield this.store.queryRecord('repositories-api-github-branch', {
          pipelineId: this.get('pipeline.id'),
          branch
        });
      } else {
        return yield this.store.findRecord('githubBranch', `${repo}/branches/${branch}`);
      }
    }).drop(),
    selectedGitHubBranch: (0, _computed.readOnly)('fetchBranch.last.value'),
    validBranch: (0, _computed.and)('fetchBranch.isIdle', 'fetchBranch.last.isSuccessful'),
    invalidBranch: (0, _computed.not)('validBranch')
  });

  _exports.default = _default;
});