define("dashboard/templates/components/app/settings/config-vars-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0PYHUpCk",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"value\",\"class\"],[[30,0,[\"configVar\",\"key\"]],\"config-var-key monospace\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"value\",\"class\"],[[30,0,[\"configVar\",\"value\"]],\"config-var-value monospace\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,2],null,[[\"canEdit\",\"canRemove\",\"model\",\"editAction\",\"confirmDeleteAction\"],[true,true,[30,0,[\"configVar\"]],[28,[37,3],[[30,0],\"edit\"],null],[28,[37,3],[[30,0],\"confirmDelete\"],null]]]]],[1,\"\\n\"]],[],false,[\"copyable-input\",\"copyable-textarea\",\"purple-list-action-cell\",\"action\"]]",
    "moduleName": "dashboard/templates/components/app/settings/config-vars-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});