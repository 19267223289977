define("dashboard/templates/components/identity-provider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bnjidO81",
    "block": "[[[41,[28,[37,1],[[30,0,[\"state\"]],\"loading\"],null],[[[1,\"  \"],[1,[34,2]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"state\"]],\"not installed\"],null],[[[1,\"  \"],[1,[28,[35,3],null,[[\"accountIdentityProvider\",\"account\",\"owner\",\"enterpriseAccountMember\",\"beginSetup\"],[[30,0,[\"accountIdentityProvider\"]],[30,0,[\"account\"]],[30,0,[\"owner\"]],[30,0,[\"enterpriseAccountMember\"]],[28,[37,4],[[30,0,[\"beginSetup\"]]],null]]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"state\"]],\"can migrate from team\"],null],[[[1,\"  \"],[1,[28,[35,5],null,[[\"owner\",\"teamIdentityProviders\",\"migrateIdentityProviderToAccount\",\"beginSetup\"],[[30,0,[\"owner\"]],[30,0,[\"teamIdentityProviders\"]],[28,[37,4],[[30,0,[\"migrateIdentityProviderToAccount\"]]],null],[28,[37,4],[[30,0,[\"beginSetup\"]]],null]]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"state\"]],\"configured\"],null],[[[6,[39,6],null,[[\"identityProvider\",\"isConfiguring\"],[[30,0,[\"identityProvider\"]],[30,0,[\"isConfiguring\"]]]],[[\"default\"],[[[[41,[28,[37,7],[[30,0,[\"identityProvider\",\"enabled\"]],[28,[37,8],[[30,0,[\"isLoading\"]]],null]],null],[[[1,\"      \"],[1,[28,[35,9],null,[[\"identityProvider\"],[[30,0,[\"identityProvider\"]]]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[]],null]],[]]]],[]]]],[]]],[1,\"\\n\"],[41,[30,0,[\"showConfigPanel\"]],[[[1,\"  \"],[1,[28,[35,10],null,[[\"owner\",\"renderInPlace\",\"onSave\",\"onCancel\"],[[30,0,[\"owner\"]],[30,0,[\"renderModalInPlace\"]],[28,[37,11],[[30,0],[28,[37,12],[[30,0,[\"isConfiguring\"]]],null],false],null],[28,[37,11],[[30,0],[28,[37,12],[[30,0,[\"isConfiguring\"]]],null],false],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"eq\",\"identity-provider/state-loading\",\"identity-provider/state-not-installed\",\"perform\",\"identity-provider/state-can-migrate\",\"identity-provider/state-configured\",\"and\",\"not\",\"identity-provider/login-info\",\"identity-provider/config\",\"action\",\"mut\"]]",
    "moduleName": "dashboard/templates/components/identity-provider.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});