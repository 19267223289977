define("dashboard/templates/components/metrics/charts/line-chart-elements/quota-line-graph", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NuYSCclv",
    "block": "[[[41,[30,0,[\"isVisible\"]],[[[1,\"  \"],[10,\"defs\"],[12],[1,\"\\n    \"],[10,\"linearGradient\"],[15,1,[29,[[30,0,[\"gradientId\"]]]]],[14,\"x1\",\"0%\"],[14,\"y1\",\"0%\"],[14,\"x2\",\"100.5%\"],[14,\"y2\",\"0%\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"stops\"]]],null]],null],null,[[[1,\"        \"],[10,\"stop\"],[15,\"offset\",[29,[[30,1,[\"offset\"]]]]],[15,\"stop-color\",[29,[[30,1,[\"color\"]]]]],[15,\"stop-opacity\",[29,[[30,1,[\"opacity\"]]]]],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"path\"],[14,0,\"metrics__line metrics__line--dashed\"],[15,\"d\",[29,[[30,0,[\"dLine\"]]]]],[15,\"stroke\",[29,[[30,0,[\"strokeUrl\"]]]]],[12],[13],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"selectedTimeChartX\",\"graph\"],[[30,0,[\"selectedTimeChartX\"]],[30,0]]]]],[1,\"\\n\"]],[]],null]],[\"aStop\"],false,[\"if\",\"each\",\"-track-array\",\"metrics/charts/line-chart-elements/selected-time-graph-point\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/line-chart-elements/quota-line-graph.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});