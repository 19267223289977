define("dashboard/components/first-run", ["exports", "@ember/component", "@ember/service", "@ember/object/computed"], function (_exports, _component, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    isMinimized: false,
    onDismiss: () => {},
    classNames: ['first-run', 'relative', 'flex', 'flex-column'],
    hideStepsWhenMinimized: true,
    stepsAreHidden: (0, _computed.and)('isMinimized', 'hideStepsWhenMinimized'),

    logEvent(noun, action) {
      this.analytics.logEvent(noun, action);
    },

    actions: {
      toggleSteps() {
        this.toggleProperty('hideStepsWhenMinimized');
      },

      dismissBanner() {
        this.onDismiss();
      }

    }
  });

  _exports.default = _default;
});