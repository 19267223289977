define("dashboard/routes/enterprise-account/overview/settings", ["exports", "@ember/routing/route", "dashboard/mixins/document-title", "rsvp", "@ember/service", "@ember/runloop"], function (_exports, _route, _documentTitle, _rsvp, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),

    get documentTitle() {
      return this.modelFor('enterprise-account').enterpriseAccount.get('name');
    },

    model() {
      const {
        enterpriseAccount
      } = this.modelFor('enterprise-account'); // It’s possible for the archives endpoint to return a 404 for some enterprise accounts.
      // In this case, it’s fine for us to soft-fail and simply not display audit trail section.

      const auditTrailArchives = enterpriseAccount.get('auditTrailArchives').catch(() => []);
      const githubAppSettings = enterpriseAccount.get('githubAppSettings').catch(() => {});
      return (0, _rsvp.hash)({
        enterpriseAccount,
        githubAppSettings,
        auditTrailArchives
      });
    },

    afterModel(_ref) {
      let {
        enterpriseAccount
      } = _ref;
      return preloadIdentityProviders(enterpriseAccount);
    },

    beforeModel(transition) {
      if (!this.get('current.enterprisePermissions.canManageSettings')) {
        const name = transition.params['enterprise-account'].name;
        this.transitionTo('enterprise-account.overview', name);
      }
    },

    actions: {
      saveName(enterpriseAccount, newName) {
        this.analytics.logEvent('Enterprise Account', 'Renamed');
        enterpriseAccount.set('name', newName);
        return enterpriseAccount.save().then(() => {
          this.replaceWith('enterprise-account.overview.settings', newName);
        }).catch(() => {
          enterpriseAccount.rollbackAttributes();
          return (0, _rsvp.reject)();
        });
      },

      cancelName(enterpriseAccount) {
        const controller = this.controller;
        controller.set('newName', enterpriseAccount.name);
      },

      didTransition() {
        _runloop.run.next(() => {
          this.analytics.logEvent('Enterprise Account Settings Page', 'Viewed');
        });

        return true;
      }

    }
  });

  _exports.default = _default;

  function preloadIdentityProviders(enterpriseAccount) {
    return enterpriseAccount.get('identityProvider').then(identityProvider => {
      if (!identityProvider) {
        return preloadTeamIdentityProviders(enterpriseAccount);
      }
    });
  }

  function preloadTeamIdentityProviders(enterpriseAccount) {
    return enterpriseAccount.get('teams').then(teams => _rsvp.default.all(teams.mapBy('identityProvider')));
  }
});