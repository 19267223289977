define("dashboard/templates/components/account/two-factor/recommended-app", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z7zeBBqC",
    "block": "[[[10,0],[14,0,\"meta\"],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[29,[\"/images/static/2fa-\",[30,0,[\"icon\"]],\".png\"]]],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n  \"],[10,\"h5\"],[12],[1,[30,0,[\"name\"]]],[13],[1,\"\\n  \"],[10,3],[15,6,[29,[[30,0,[\"helpURL\"]]]]],[14,0,\"hk-link\"],[14,\"target\",\"_blank\"],[12],[1,[30,0,[\"helpLinkText\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"download\"],[12],[1,\"\\n  \"],[10,3],[15,6,[29,[[30,0,[\"appStoreURL\"]]]]],[14,0,\"hk-link download-badge app-store\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/images/2fa-ios-button.png\"],[14,\"title\",\"Download on the App Store\"],[14,\"alt\",\"Download on the App Store\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,3],[15,6,[29,[[30,0,[\"playStoreURL\"]]]]],[14,0,\"hk-link download-badge google-play\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/images/2fa-android-button.png\"],[14,\"title\",\"Get it from Google Play\"],[14,\"alt\",\"Get it from Google Play\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/account/two-factor/recommended-app.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});