define("dashboard/components/ci/test-messages", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['ci-view--module', 'ci-view--messages'],
    isVisible: (0, _computed.bool)('testRun.hasMessages'),
    testNodes: (0, _computed.readOnly)('testRun.testNodes'),
    messages: (0, _object.computed)('testRun.message', 'testNodes.@each.message', function () {
      const messageOwners = this.testNodes.toArray().concat(this.testRun);
      return messageOwners.mapBy('message').uniq().compact();
    }),
    actions: {
      changeNode(index, anchor) {
        this.set('indexSelectedTab', index);
        const outputLocation = $(anchor).offset().top;
        const glostickHeight = $('.glostick').height();
        $(window).scrollTop(outputLocation - glostickHeight);
      }

    }
  });

  _exports.default = _default;
});