define("dashboard/components/region-flag", ["exports", "@ember/component", "@ember/utils", "@ember/object"], function (_exports, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EU = {
    name: ['eu'],
    className: 'europe'
  };
  const US = {
    name: ['United States', 'us', 'virginia', 'oregon', 'california'],
    className: 'united-states'
  };
  const JAPAN = {
    name: ['Japan', 'tokyo'],
    className: 'japan'
  };
  const GERMANY = {
    name: ['Germany', 'frankfurt'],
    className: 'germany'
  };
  const AUSTRALIA = {
    name: ['Australia', 'sydney'],
    className: 'australia'
  };
  const IRELAND = {
    name: ['Ireland', 'dublin'],
    className: 'ireland'
  };
  const SINGAPORE = {
    name: ['Singapore', 'singapore'],
    className: 'singapore'
  };

  var _default = _component.default.extend({
    classNames: ['inline-flex'],
    regionIcon: (0, _object.computed)('region', function () {
      const regionName = this.region;
      const regions = [EU, US, JAPAN, GERMANY, AUSTRALIA, IRELAND, SINGAPORE];
      const foundRegion = regions.find(function (region) {
        return region.name.includes(regionName);
      });

      if ((0, _utils.isPresent)(foundRegion)) {
        return `region-${foundRegion.className}`;
      } else {
        return 'region-generic';
      }
    })
  });

  _exports.default = _default;
});