define("dashboard/components/app/datastore/credential-row", ["exports", "@glimmer/component", "dashboard/utils/pluralize"], function (_exports, _component, _pluralize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get credentialAttachmentsLength() {
      const addonAttachments = this.args.addonAttachments;
      const credentialName = this.args.credential.name;
      let length;

      if (this.args.credential.name === 'default') {
        const defaultAttachments = addonAttachments.filterBy('namespace', null);
        const credentialAttachments = addonAttachments.filterBy('namespace', `credential:${credentialName}`);
        length = [...defaultAttachments, ...credentialAttachments].get('length');
      } else {
        length = addonAttachments.filterBy('namespace', `credential:${credentialName}`).get('length');
      }

      const noun = (0, _pluralize.default)(length, 'app', 'apps');
      return `${length} ${noun}`;
    }

  }

  _exports.default = _default;
});