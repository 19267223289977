define("dashboard/routes/pipelines/pipeline/settings", ["exports", "@ember/service", "@ember/routing/route", "rsvp"], function (_exports, _service, _route, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)('account-features'),

    model() {
      const {
        pipeline
      } = this.modelFor('pipelines.pipeline');
      const githubOrgs = this.store.query('kolkrabbi/github-org', {}).catch(() => []);
      const teams = this.store.peekAll('team');
      const pipelineCouplings = pipeline.get('pipelineCouplings');
      const reviewAppConfig = pipeline.get('reviewAppConfig');
      const githubRepository = pipeline.get('githubRepository').catch(() => {});
      return _rsvp.default.hash({
        pipeline,
        pipelineCouplings,
        reviewAppConfig,
        githubOrgs,
        teams,
        githubRepository
      });
    },

    updatePipeline(pipeline, props) {
      pipeline.setProperties(props);
      return pipeline.save().then(() => {
        this.analytics.logEvent('Pipeline', 'Changed', {
          status: 'succeeded'
        });
        this.send('pipelineUpdated');
      }).catch(error => {
        this.analytics.logEvent('Pipeline', 'Changed', {
          status: 'failed'
        });
        pipeline.rollbackAttributes();
        throw error;
      });
    },

    actions: {
      onRepoLink(repository) {
        const params = {
          pipeline: this.get('currentModel.pipeline'),
          repository: parseInt(repository.id, 10)
        };
        const repo = this.store.createRecord('pipeline/github-repository', params);
        return repo.save().then(() => {
          this.analytics.logEvent('GitHub Pipeline', 'Linked');
          this.get('currentModel.pipeline.githubRepository').reload();
        }).catch(() => {
          repo.unloadRecord();
        });
      },

      onRepoUnlink(githubRepository) {
        return githubRepository.destroyRecord().then(() => {
          this.analytics.logEvent('GitHub Pipeline', 'Unlinked');
        });
      },

      onCreateConfigVar(_ref) {
        let {
          key,
          value,
          stage
        } = _ref;
        const pipeline = this.get('currentModel.pipeline'); // We use the {pipeline}-{key}-{stage} triplet here to provide a unique ID for the record
        // since the API doesn't have any concept of IDs and we don't want to confuse ember data
        // when the user has config vars of the same name for both review apps and CI

        const params = {
          id: `${pipeline.id}-${key}-${stage}`,
          key,
          value,
          stage,
          pipeline
        };
        const cv = this.store.createRecord('pipeline-stage-config-var', params);
        return cv.save().catch(err => {
          cv.unloadRecord();
          throw err;
        });
      },

      onUpdateConfigVar(configVar) {
        return configVar.save();
      },

      onDeleteConfigVar(configVar) {
        return configVar.destroyRecord();
      },

      deletePipeline(pipeline) {
        const teamName = pipeline.get('team.name');
        const promise = pipeline.deletePipeline();
        return promise.then(() => {
          this.analytics.logEvent('Pipeline', 'Deleted', {
            status: 'succeeded'
          });

          if (teamName) {
            this.transitionTo('team.switchable', teamName);
          } else {
            this.transitionTo('apps');
          }
        }).catch(err => {
          this.analytics.logEvent('Pipeline', 'Deleted', {
            status: 'failed'
          });
          throw err;
        });
      },

      onUpdateName(pipeline, name) {
        return this.updatePipeline(pipeline, {
          name
        });
      },

      onUpdateOwner(pipeline, newOwner) {
        return this.store.createRecord('pipeline-transfer', {
          pipeline,
          newOwner
        }).save().then(transfer => {
          pipeline.set('owner', transfer.get('newOwner'));

          if (newOwner.type === 'team') {
            pipeline.set('team', this.store.peekRecord('team', newOwner.id));
          } else {
            pipeline.set('team', null);
          }

          if (pipeline.get('isCiEnabled')) {
            const hasReposApi = pipeline.get('isGithubAppEnabled');
            const servicesConfig = hasReposApi ? pipeline.get('connectedServices') : pipeline.get('githubRepository');
            servicesConfig.set('ci', false);
          }

          return transfer;
        });
      },

      onUnsetOwner(pipeline) {
        return this.store.createRecord('pipeline-transfer', {
          pipeline,
          newOwner: {}
        }).save().then(transfer => {
          pipeline.setProperties({
            owner: null,
            team: null
          });

          if (pipeline.get('isCiEnabled')) {
            pipeline.get('githubRepository').set('ci', false);
          }

          return transfer;
        });
      },

      fetchStageConfigVars(stage) {
        const pipeline = this.get('currentModel.pipeline'); // The pipeline config vars API does not include the associated pipeline
        // in it's payload, so we must manually set it after fetching.

        return this.store.query('pipeline-stage-config-var', {
          pipeline: pipeline.get('id'),
          stage
        }).then(cvs => cvs.map(cv => cv.setProperties({
          pipeline,
          stage
        })));
      },

      enableReviewApps(pipeline) {
        let repoName;

        if (pipeline.get('isGithubAppEnabled')) {
          repoName = pipeline.get('connectedRepository.fullName');
        } else {
          repoName = pipeline.get('githubRepository.name');
        }

        const appConfig = this.store.createRecord('review-app-config', {
          repoName,
          pipeline
        });
        return appConfig.save().catch(err => {
          appConfig.unloadRecord();
          throw err;
        });
      },

      redirectToLegacyReviewAppsFlow() {
        this.analytics.logEvent('Review Apps Legacy Enable', 'Clicked');
        this.transitionTo('pipelines.pipeline.index.review-apps');
      },

      async onDisableReviewApps(pipeline) {
        const reviewAppConfig = await pipeline.get('reviewAppConfig');
        return reviewAppConfig.destroyRecord();
      },

      async onUpdateReviewAppConfig(pipeline) {
        let props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        const reviewAppConfig = await pipeline.get('reviewAppConfig');
        reviewAppConfig.setProperties(props);
        return reviewAppConfig.save().catch(() => {
          reviewAppConfig.rollbackAttributes();
        });
      }

    }
  });

  _exports.default = _default;
});