define("dashboard/components/payment-method/stripe-field-error", ["exports", "@glimmer/component", "@ember/object/internals"], function (_exports, _component, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StripeFieldErrorComponent extends _component.default {
    get errorsListId() {
      return `hk-form-field-errors-list-${(0, _internals.guidFor)(this)}`;
    }

    get errorMessage() {
      return this.args.errorMessage;
    } // Conform to shape of changeset errors that hk-validation-errors-list is expecting


    get validationErrors() {
      if (this.errorMessage) {
        return {
          validation: [this.errorMessage]
        };
      }

      return null;
    }

  }

  _exports.default = StripeFieldErrorComponent;
});