define("dashboard/templates/components/pipeline/apps-dropdown-stage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oUFTKdkE",
    "block": "[[[41,[30,0,[\"apps\"]],[[[1,\"  \"],[41,[30,0,[\"stage\"]],[[[10,\"li\"],[14,0,\"dropdown-heading\"],[12],[10,\"h6\"],[12],[1,[30,0,[\"stage\"]]],[13],[13]],[]],null],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"apps\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"route\",\"model\"],[\"app\",[30,1,[\"name\"]]]],[[\"default\"],[[[[1,\"        \"],[8,[39,4],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[\"app-16\",16,\"v-mid malibu-fill-gradient-purple\",[30,1,[\"name\"]]]],null],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"],[41,[30,1,[\"space\"]],[[[1,\"          \"],[1,[28,[35,5],null,[[\"shield\",\"size\",\"class\"],[[30,1,[\"space\",\"shield\"]],16,\"ml1\"]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[1]],null]],[]],null]],[\"app\"],false,[\"if\",\"each\",\"-track-array\",\"link-to\",\"malibu-icon\",\"space-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/apps-dropdown-stage.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});