define("dashboard/templates/components/send-to-outlet", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iH5ai/j4",
    "block": "[[[41,[30,0,[\"element\"]],[[[40,[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"element\"]]]],[]],null]],[\"&default\"],false,[\"if\",\"in-element\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/send-to-outlet.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});