define("dashboard/components/metrics/charts/connected-chart-hover/routing-health-group", ["exports", "@ember/object/computed", "dashboard/components/metrics/charts/connected-chart-hover/activity-group", "@ember/object", "moment"], function (_exports, _computed, _activityGroup, _object, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activityGroup.default.extend({
    models: (0, _computed.reads)('graph.models'),
    items: (0, _object.computed)('models.[]', 'maxTime', 'selectedRollupTime', 'rollupDuration', function () {
      const selectedTime = this.selectedRollupTime;
      let items = [];

      if (selectedTime) {
        const defaultEndTime = this.get('maxTime');
        const rollupDuration = this.rollupDuration;
        const models = this.models || [];
        items = models.filter(function (model) {
          const start = model.get('startedAt') - rollupDuration;
          const end = model.get('endedAt') || defaultEndTime;
          return selectedTime > start && selectedTime < end;
        });
      }

      return items;
    }),
    filteredItems: (0, _object.computed)('items', function () {
      const unique = [];
      this.items.forEach(function (item) {
        if (unique.filterBy('type', item.get('type')).length === 0) {
          unique.push(item);
        }
      });
      return unique;
    }),
    processedItems: (0, _computed.map)('filteredItems', function (item) {
      const endedAt = item.isActive ? (0, _moment.default)(this.maxTime) : (0, _moment.default)(item.endedAt);
      const durationFormatted = (0, _moment.default)(item.startedAt).from(endedAt, true);
      const durationModifier = item.precedesTimeframe ? 'over' : 'less than';
      item.set('title', `${item.get('typeLabel')} Degraded`);
      item.set('description', `${durationModifier} ${durationFormatted}`);
      return item;
    }),
    isVisible: (0, _computed.gt)('filteredItems.length', 0)
  });

  _exports.default = _default;
});