define("dashboard/adapters/app/state", ["exports", "dashboard/adapters/particleboard", "ember-concurrency", "lodash-es/chunk"], function (_exports, _particleboard, _emberConcurrency, _chunk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _particleboard.default.extend({
    coalesceFindRequests: true,

    pathForType() {
      return 'app-states';
    },

    findRecord(store, type, id) {
      return this.findMany(store, type, [id]).then(_ref => {
        let [result] = _ref;
        return result;
      });
    },

    _fetchRecords: (0, _emberConcurrency.task)(function* (url, ids) {
      const data = {
        apps: ids.join(',')
      };
      const result = yield this.ajax(url, 'POST', {
        data
      });
      return result;
    }).enqueue() // allow up to 6 requests concurrently, which gives us
    // maxConcurrency * this._findManyMaxReqequestSize = 30 apps we
    // can fetch at a time while spreading the load over many particleboard
    // web dynos via HTTP2.
    .maxConcurrency(6),
    _findManyMaxRequestSize: 5,

    groupRecordsForFindMany(_store, snapshots) {
      // fan out requests so that multiple particleboard web dynos
      // can handle the request, rather than one web dyno handling
      // all of the app state requests for a potentially large apps list
      return (0, _chunk.default)(snapshots, this._findManyMaxRequestSize);
    },

    findMany(_store, type, ids) {
      const url = this.buildURL(type.modelName);
      return this._fetchRecords.perform(url, ids);
    }

  });

  _exports.default = _default;
});