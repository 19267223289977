define("dashboard/serializers/team/preference", ["exports", "@ember/string", "dashboard/serializers/application"], function (_exports, _string, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    keyForAttribute(attr) {
      return (0, _string.dasherize)(attr);
    },

    // manually assign key to update response which does not include one
    normalizeUpdateRecordResponse(store, primaryModelClass, payload, id) {
      payload.id = id;
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});