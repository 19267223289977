define("dashboard/models/account-quota", ["exports", "@ember/object", "@ember-data/model"], function (_exports, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    sort
  } = _object.computed;

  var _default = _model.default.extend({
    accountQuota: (0, _model.attr)('string'),
    quotaUsed: (0, _model.attr)('string'),
    forceIdleUntil: (0, _model.attr)('date'),
    apps: (0, _model.attr)(),
    appSortKey: ['quota_used:desc'],
    sortedApps: sort('apps', 'appSortKey'),
    percentUsed: (0, _object.computed)('accountQuota', 'quotaUsed', function () {
      const percentUsed = this.quotaUsed / this.accountQuota * 100;
      return percentUsed.toFixed(2);
    }),
    quotaRemaining: (0, _object.computed)('accountQuota', 'quotaUsed', function () {
      const quotaRemaining = this.accountQuota - this.quotaUsed;
      return quotaRemaining > 0 ? quotaRemaining : 0;
    })
  });

  _exports.default = _default;
});