define("dashboard/components/metrics/charts/line-chart-elements/quota-line-graph", ["exports", "@ember/object/computed", "@ember/utils", "@ember/string", "@ember/object", "dashboard/components/metrics/charts/line-chart-elements/line-graph"], function (_exports, _computed, _utils, _string, _object, _lineGraph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const criticalPerc = 100;

  var _default = _lineGraph.default.extend({
    name: 'Mem Quota',
    _percData: (0, _computed.alias)('extraData.[]'),
    _hasPercData: (0, _computed.notEmpty)('_percData'),
    stops: (0, _object.computed)('data.[]', '_percData.[]', '_hasPercData', function () {
      const data = this.data;
      const percData = this._percData; // eslint-disable-next-line no-unused-vars

      return data.map((_ref, index) => {
        let [time, perc] = _ref;
        let color = '#CFCFCF';
        let opacity = 100;
        const offset = (0, _string.htmlSafe)(index / data.length);

        if (this._hasPercData) {
          const percDatum = percData[index];

          if (percDatum[1] >= criticalPerc) {
            color = '#D64242';
          }
        } // we make the very end of the data transparent
        // see below _lineData note


        const isLast = index === data.length - 1; // if the perc is undefined, we should hide the line at this point rather
        // than drop the line suddently down to the baseline

        const isUnknown = Number.isNaN(perc); // we also hide a section if the previous data point was unknown to prevent
        // a rapid vertical line from appearing

        const prevIsUnknown = index > 0 && Number.isNaN(data[index - 1][1]);

        if (isLast || isUnknown || prevIsUnknown) {
          opacity = 0;
        }

        opacity = (0, _string.htmlSafe)(opacity);
        color = (0, _string.htmlSafe)(color);
        return {
          offset,
          color,
          opacity
        };
      });
    }),
    // this is a hacky way of ensuring our line is never straight
    // we add a data point that is guaranteed to make the line non-straight
    // but we use the above `stops` implementation to make that portion of the line
    // transparent
    //
    // a line that is completely straight, will appear invisible with a gradient
    // because ¯\_(ツ)_/¯
    // see: http://stackoverflow.com/questions/13223636/svg-gradient-for-perfectly-horizontal-path
    //
    _lineData: (0, _object.computed)('_extendedData.[]', function () {
      const data = [...this._extendedData];

      if ((0, _utils.isPresent)(data)) {
        const lastIndex = data.length - 1;
        data[lastIndex][1] = data[lastIndex][1] + 1000;
      }

      return data;
    }),
    _selectedPercentageValue: (0, _object.computed)('selectedRollupTime', '_percData.[]', function () {
      const selectedTime = this.get('selectedRollupTime');
      const percData = this._percData || [];

      if (selectedTime) {
        // we have a fallback here in case there is no percentage data at the selected time
        const selectedDatum = percData.findBy('0', selectedTime) || [null, null];
        return selectedDatum[1];
      }
    }),
    defaultColor: (0, _object.computed)('_selectedPercentageValue', function () {
      const value = this._selectedPercentageValue;

      if (value >= criticalPerc) {
        return 'critical-threshold';
      } else {
        return 'threshold';
      }
    }),
    gradientId: 'quota-line-gradient',
    // we need to access the window url because of ember-cli's current use
    // of <base> tag in our index.html
    // this should be fixed in ember-cli 2.7 but there is a non-trivial upgrade path
    // see: http://emberjs.com/blog/2016/04/28/baseURL.html
    //
    // we watch the startTime to ensure that changes to timeframe will also cause
    // this url to update because query parameters will affect what this url looks like
    strokeUrl: (0, _object.computed)('gradientId', 'minTime', function () {
      const url = window.location.href;
      return (0, _string.htmlSafe)(`url(${url}#${this.gradientId})`);
    })
  });

  _exports.default = _default;
});