define("dashboard/components/metrics/formation-charts", ["exports", "@ember/component", "dashboard/mixins/metrics/charts", "dashboard/mixins/preferences", "dashboard/mixins/metrics/timeframe-options", "@ember/object/computed", "@ember/service", "@ember/object"], function (_exports, _component, _charts, _preferences, _timeframeOptions, _computed, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_charts.default, _preferences.default, _timeframeOptions.default, {
    tagName: null,
    analytics: (0, _service.inject)(),
    process: (0, _computed.alias)('formation'),
    isCompactLayout: (0, _computed.equal)('chartLayout', 'compact'),
    deployReleases: (0, _computed.filterBy)('app.releases', 'isDeploy'),
    configChangeReleases: (0, _computed.filterBy)('app.releases', 'isConfigChange'),
    generalDynoDataFulfilled: (0, _computed.and)('dynoLoad.isFulfilled', 'dynoMemory.isFulfilled', 'dynoErrors.isFulfilled', 'routerErrors.isFulfilled', 'formationChanges.isFulfilled', 'restarts.isFulfilled', 'resolvedIncidents.isFulfilled', 'unresolvedIncidents.isFulfilled', 'app.releases.isFulfilled', 'process.alerts.isFulfilled', 'languageMemory.isFulfilled'),
    webDynoDataFulfilled: (0, _computed.and)('throughput.isFulfilled', 'latency.isFulfilled'),
    setStopRefreshingData: (0, _object.observer)('generalDynoDataFulfilled', 'webDynoDataFulfilled', 'formation.isWeb', function () {
      let allDataIsFulfilled = this.get('generalDynoDataFulfilled');

      if (this.get('formation.isWeb')) {
        allDataIsFulfilled = allDataIsFulfilled && this.get('webDynoDataFulfilled');
      }

      if (allDataIsFulfilled) {
        this.chartDataFulfilled();
      }
    }),
    dynoLoad: (0, _computed.readOnly)('refreshingDatasets.dynoLoad'),
    dynoMemory: (0, _computed.readOnly)('refreshingDatasets.dynoMemory'),
    throughput: (0, _computed.readOnly)('refreshingDatasets.throughput'),
    languageMemory: (0, _computed.readOnly)('refreshingDatasets.languageMemory'),
    languageMemoryTest: (0, _computed.readOnly)('refreshingDatasets.languageMemoryTest'),
    formationChanges: (0, _computed.readOnly)('refreshingDatasets.formationChanges'),
    restarts: (0, _computed.readOnly)('refreshingDatasets.restarts'),
    dynoErrors: (0, _computed.readOnly)('refreshingDatasets.dynoErrors'),
    routerErrors: (0, _computed.readOnly)('refreshingDatasets.routerErrors'),
    latency: (0, _computed.readOnly)('refreshingDatasets.latency'),
    alerts: (0, _computed.readOnly)('refreshingDatasets.alerts'),
    routingHealth: (0, _computed.readOnly)('refreshingDatasets.routingHealth'),
    currentRoutingHealth: (0, _computed.readOnly)('refreshingDatasets.currentRoutingHealth'),
    resolvedIncidents: (0, _computed.readOnly)('refreshingDatasets.resolvedIncidents'),
    unresolvedIncidents: (0, _computed.readOnly)('refreshingDatasets.unresolvedIncidents'),
    eventsChartIsCollapsed: (0, _object.computed)('collapsedCharts.[]', function () {
      return this.findChartCollapsedState('events');
    }),
    routingHealthChartIsCollapsed: (0, _object.computed)('collapsedCharts.[]', function () {
      return this.findChartCollapsedState('routing-health');
    }),
    currentTimeframe: (0, _object.computed)('beforeHours', 'afterHours', 'allOptions', function () {
      const timeframe = this.get('allOptions').find(option => {
        return option.beforeHours === this.get('beforeHours') && option.afterHours === this.get('afterHours');
      });
      return timeframe || this.get('defaultTimeframe');
    }),
    actions: {
      reorderCharts(charts) {
        const newChartOrder = charts.mapBy('chartKey');
        const appPreferences = this.get('appPreferences');
        appPreferences.set('metricsChartOrder', newChartOrder);
        appPreferences.save();
        this.get('analytics').logMetricsEvent('Chart Order', 'Updated', {
          order: newChartOrder
        });
      },

      updateChartCollapsePreference(isCollapsed, chartKey) {
        const appPreferences = this.get('appPreferences');
        const savedCollapsedCharts = appPreferences.get('collapsedMetricsCharts') || [];
        const collapsedCharts = savedCollapsedCharts.reject(ele => ele === chartKey);

        if (isCollapsed) {
          collapsedCharts.push(chartKey);
        }

        appPreferences.set('collapsedMetricsCharts', collapsedCharts);
        appPreferences.save();
        this.get('analytics').logMetricsEvent('Chart Collapsibility', 'Toggled', {
          collapsed: isCollapsed,
          chartName: chartKey
        });
      },

      updateSelectedTime(time) {
        this.set('selectedTime', time);
      },

      dismissLanguageDiscovery() {
        this.updateUserPreferences('hasDismissedLanguageDiscovery', false);
      }

    }
  });

  _exports.default = _default;
});