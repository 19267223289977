define("dashboard/templates/components/team/enterprise-no-account-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5zIHdPCK",
    "block": "[[[41,[30,0,[\"showBanner\"]],[[[1,\"  \"],[10,0],[15,0,[29,[[30,0,[\"class\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"z-1 bg-gradient-secondary br2 pa3 pa4-ns flex flex-column flex-row-ns limit-width anim-fade-in enterprise-no-account-banner\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"w-100 w-auto-ns flex-auto\"],[12],[1,\"\\n        \"],[10,2],[14,0,\"f3 f2-ns white mb1\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"anim-fade-in\"],[12],[1,\"Enterprise Accounts are released to general availability!\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,2],[14,0,\"f5 white ma0\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"anim-fade-in\"],[12],[1,\"We need more information from you to set up your Enterprise Account. Please contact us by creating a support ticket.\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"mt2 mt0-ns w-100 w-auto-ns\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex ml5 items-center justify-end\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"mr3 nowrap\"],[12],[1,\"\\n            \"],[10,3],[15,6,[30,0,[\"learnMoreLink\"]]],[14,\"target\",\"_blank\"],[15,\"onClick\",[28,[37,1],[[30,0],[30,0,[\"learnMoreAboutEnterpriseAccounts\"]]],null]],[14,0,\"hk-focus-ring b f5 white no-underline underline-hover\"],[12],[1,\"\\n              Learn more\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\\n          \"],[10,0],[12],[1,\"\\n            \"],[10,3],[14,6,\"https://help.heroku.com/enterprise\"],[14,0,\"hk-button--secondary pointer\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n              Contact us\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"action\"]]",
    "moduleName": "dashboard/templates/components/team/enterprise-no-account-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});