define("dashboard/components/info-toggletip", ["exports", "@glimmer/component", "@popperjs/core", "ember-concurrency-decorators", "@glimmer/tracking", "@ember/object", "dashboard/config/environment"], function (_exports, _component, _core, _emberConcurrencyDecorators, _tracking, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "malibuIconName", 'info-badge-28');

      _defineProperty(this, "malibuIconClass", 'malibu-fill-gradient-gray nudge-up--1 darken-on-hover');

      _defineProperty(this, "malibuIconSize", '14');

      _defineProperty(this, "elements", {});

      _initializerDefineProperty(this, "toggletipClicked", _descriptor, this);
    }

    registerElement(name, element) {
      this.elements[name] = element;
    }

    get toggletipsContainer() {
      if (!this._toggletipsContainer) {
        this._toggletipsContainer = document.createElement('div');
        const rootElement = document.querySelector(_environment.default.APP.rootElement) || document.body;
        rootElement.insertBefore(this._toggletipsContainer, rootElement.firstElementChild);
      }

      return this._toggletipsContainer;
    }

    clickToggletip() {
      this.toggletipClicked = !this.toggletipClicked;
    }

    *createToggletip() {
      if (!this._popper) {
        const {
          reference,
          popper
        } = this.elements;
        this._popper = yield (0, _core.createPopper)(reference, popper, {
          strategy: 'fixed',
          placement: 'top',
          elementReference: 'reference',
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, 5]
            }
          }, {
            name: 'flip',
            options: {
              boundary: document.querySelector('#toggletip-root-boundary')
            }
          }]
        });
      }
    }

    _cleanupPopper() {
      if (this._popper) {
        this._popper.destroy();

        this._popper = null;
      }
    }

    _cleanupToggletipsContainer() {
      if (this.toggletipsContainer) {
        this.toggletipsContainer.remove();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "toggletipClicked", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "registerElement", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "registerElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clickToggletip", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clickToggletip"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createToggletip", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createToggletip"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_cleanupPopper", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_cleanupPopper"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_cleanupToggletipsContainer", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_cleanupToggletipsContainer"), _class2.prototype)), _class2);

  _exports.default = _class;
});