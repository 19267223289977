define("dashboard/helpers/map-by", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mapBy = mapBy;

  function mapBy(_ref) {
    let [array, key] = _ref;
    return (array || []).mapBy(key);
  }

  var _default = (0, _helper.helper)(mapBy);

  _exports.default = _default;
});