define("dashboard/components/addons-attachments", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/utils/pluralize", "ember"], function (_exports, _component, _object, _computed, _pluralize, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    escapeExpression
  } = _ember.default.Handlebars.Utils;

  var _default = _component.default.extend({
    appNames: (0, _computed.mapBy)('apps', 'name'),
    dropdownClass: 'hk-dropdown',
    singleAttachment: (0, _computed.equal)('attachments.length', 1),
    attachmentsNoun: (0, _object.computed)('attachments.[]', function () {
      return (0, _pluralize.default)(this.get('attachments.length'), 'attachment', 'attachments');
    }),
    buttonContent: (0, _object.computed)('attachments.[]', function () {
      const firstAttachment = this.get('attachments.firstObject');
      const safeName = escapeExpression(firstAttachment.name);
      const length = this.get('attachments.length');
      return this.get('singleAttachment') ? `<span>Attached as <span class="monospace">${safeName}</span></span>` : `${length} ${this.get('attachmentsNoun')}`;
    }),
    attachmentsToCurrentApp: (0, _object.computed)('attachments.@each.app', 'currentApp.id', function () {
      return this.attachments.filterBy('app.id', this.currentApp.id);
    }),
    otherAttachments: (0, _object.computed)('attachments.@each.app', 'currentApp.id', function () {
      return this.attachments.rejectBy('app.id', this.currentApp.id);
    })
  });

  _exports.default = _default;
});