define("dashboard/templates/components/metrics/charts/connected-chart-hover/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "W549dP1E",
    "block": "[[[10,0],[14,0,\"metrics__magic-chart-context__item__icon\"],[12],[1,\"\\n\"],[41,[30,0,[\"userEmail\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"email\",\"size\"],[[30,0,[\"userEmail\"]],16]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"icon\"]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[\"metrics__magic-chart-context__item__swatch metrics__color--\",[30,0,[\"swatchColor\"]],\"--background\"]]],[12],[13],[1,\"\\n  \"]],[]]]],[]]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"metrics__magic-chart-context__item__content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"metrics__magic-chart-context__item__header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"metrics__magic-chart-context__item__title\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isHidingValue\"]]],[[[1,\"      \"],[10,0],[14,0,\"metrics__magic-chart-context__item__value\"],[12],[1,\"\\n        \"],[1,[30,0,[\"graph\",\"displayValue\"]]],[10,1],[14,0,\"metrics__magic-chart-context__item__unit\"],[12],[1,[30,0,[\"graph\",\"displayUnit\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isHidingDescription\"]]],[[[1,\"    \"],[10,0],[14,0,\"metrics__magic-chart-context__item__description\"],[12],[1,\"\\n      \"],[1,[30,0,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"if\",\"gravatar-icon\",\"svg-jar\",\"unless\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/connected-chart-hover/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});