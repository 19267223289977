define("dashboard/templates/components/stencil/apps/metrics-autoscaling-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UXD83xEZ",
    "block": "[[[10,0],[15,0,[29,[\"flex items-center o-\",[30,0,[\"opacity\"]]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"stencil w5\"],[12],[1,\"Autoscaling Summary\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "dashboard/templates/components/stencil/apps/metrics-autoscaling-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});