define("dashboard/controllers/team/switchable/billing", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object"], function (_exports, _computed, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    title: 'Billing',
    invoicesSorter: ['periodStart'],
    invoices: (0, _computed.readOnly)('model.invoices'),
    sortedInvoices: (0, _computed.sort)('invoices', 'invoicesSorter'),
    currentInvoice: (0, _object.computed)('sortedInvoices', function () {
      return this.get('sortedInvoices.lastObject');
    }),
    previousInvoices: (0, _object.computed)('sortedInvoices', function () {
      return this.sortedInvoices.removeObject(this.currentInvoice);
    })
  });

  _exports.default = _default;
});