define("dashboard/controllers/app/metrics", ["exports", "@ember/controller", "@ember/object/computed", "dashboard/mixins/preferences", "@ember/service"], function (_exports, _controller, _computed, _preferences, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_preferences.default, {
    current: (0, _service.inject)(),
    isOhana: (0, _computed.readOnly)('current.account.isOhana'),
    app: (0, _computed.readOnly)('model.app.app'),
    formations: (0, _computed.readOnly)('app.formations'),
    cardPresent: (0, _computed.readOnly)('model.paymentMethod.cardPresent'),
    allowUpgrade: (0, _computed.or)('isOhana', 'cardPresent')
  });

  _exports.default = _default;
});