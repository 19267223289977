define("dashboard/adapters/team/permission", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForDeleteRecord() {
      return this.urlForUpdateRecord(...arguments);
    },

    urlForCreateRecord(modelName, _ref) {
      let {
        record
      } = _ref;
      const type = record.get('type');

      if (type === 'app') {
        const appName = record.get('app.name');
        return `${this.host}/teams/apps/${appName}/collaborators`;
      }
    },

    urlForUpdateRecord(id, modelName, _ref2) {
      let {
        record
      } = _ref2;
      const appName = record.get('name');
      const email = record.get('user.email');
      return `${this.host}/teams/apps/${appName}/collaborators/${email}`;
    }

  });

  _exports.default = _default;
});