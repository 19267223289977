define("dashboard/utils/addon-services-fetcher", ["exports", "rsvp", "@ember/object"], function (_exports, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // returns a promise of an array of addon-services
  // each one knows whether it is on the allowlist in the given team or not
  // TODO: inject store, don't pass it in. (but there is no container?)
  var _default = _object.default.reopenClass({
    // eslint-disable-next-line no-unused-vars
    fetch(_ref) {
      let {
        store,
        app,
        team
      } = _ref;
      const currentRegion = app.get('region');
      const addonServicesPromise = store.findAll('addon-service');
      const addonRegionCapabilitiesForCurrentRegionPromise = store.query('addon-region-capability', {
        regionNameOrId: currentRegion.get('id')
      });
      let teamPreferencesPromise;
      let allowedAddonsPromise;

      if (team && team.get('isEnterpriseOrg')) {
        teamPreferencesPromise = team.get('preference');
        allowedAddonsPromise = store.peekRecord('team', team.get('id')).get('allowedAddons');
      }

      return (0, _rsvp.hash)({
        addonServices: addonServicesPromise,
        teamPreferences: teamPreferencesPromise,
        addonRegionCapabilitiesForCurrentRegion: addonRegionCapabilitiesForCurrentRegionPromise,
        allowedAddons: allowedAddonsPromise
      }).then(data => {
        this.resetValues(data.addonServices);

        if (data.teamPreferences) {
          const allowlistIsEnabled = data.teamPreferences.get('addonsControls');
          this.markAllowlistIsEnabled(data.addonServices, allowlistIsEnabled);
          this.markAllowlistedAddonServices(data.addonServices, team, allowlistIsEnabled, data.allowedAddons);
        }

        this.markCurrentRegion(data.addonServices, currentRegion);
        this.markCurrentRegionIsSupported(data.addonServices, currentRegion, data.addonRegionCapabilitiesForCurrentRegion);
        return data.addonServices;
      });
    },

    resetValues(addonServices) {
      addonServices.forEach(addonService => {
        addonService.setProperties({
          allowlistIsEnabled: undefined,
          isNotOnAllowlist: undefined,
          currentRegion: undefined,
          currentRegionIsSupported: undefined
        });
      });
    },

    markAllowlistIsEnabled(addonServices, allowlistIsEnabled) {
      addonServices.forEach(addonService => {
        addonService.set('allowlistIsEnabled', allowlistIsEnabled);
      });
    },

    markAllowlistedAddonServices(addonServices, team, allowlistIsEnabled, allowedAddons) {
      if (team && allowlistIsEnabled && allowedAddons) {
        const allowlistedAddonServicesIds = allowedAddons.mapBy('addonService.id');
        addonServices.forEach(addonService => {
          if (!allowlistedAddonServicesIds.includes(addonService.get('id'))) {
            addonService.set('isNotOnAllowlist', true);
          } else {
            addonService.set('isNotOnAllowlist', false);
          }
        });
      }
    },

    markCurrentRegion(addonServices, currentRegion) {
      addonServices.forEach(addonService => {
        addonService.set('currentRegion', currentRegion);
      });
    },

    markCurrentRegionIsSupported(addonServices, currentRegion, addonRegionCapabilitiesForCurrentRegion) {
      addonServices.forEach(addonService => {
        const capabilityForServiceForCurrentRegion = addonRegionCapabilitiesForCurrentRegion.findBy('addonService.id', addonService.get('id'));

        if (capabilityForServiceForCurrentRegion) {
          addonService.set('currentRegionIsSupported', true);
        } else {
          addonService.set('currentRegionIsSupported', false);
        }
      });
    }

  });

  _exports.default = _default;
});