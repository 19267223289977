define("dashboard/components/metrics/pay-wall", ["exports", "@ember/object/computed", "@ember/component", "dashboard/mixins/dyno-upgrade", "@ember/service", "@ember/object", "@ember/string"], function (_exports, _computed, _component, _dynoUpgrade, _service, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_dynoUpgrade.default, {
    accountFeatures: (0, _service.inject)('account-features'),
    current: (0, _service.inject)(),
    hasFormations: (0, _computed.gte)('formations.length', 1),
    disallowUpgrade: (0, _computed.not)('allowUpgrade'),
    paywallTierName: (0, _object.computed)('app.processTier', function () {
      return (0, _string.capitalize)(this.app.processTier);
    }),
    actions: {
      upgradeDyno() {
        const formations = this.formations;
        return this.upgradeDyno('basic', 'metrics-paywall').then(() => {
          this.get('transitionToType')(formations);
        });
      }

    }
  });

  _exports.default = _default;
});