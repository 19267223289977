define("dashboard/components/app/web-hooks/list-item", ["exports", "@ember/component", "@ember/object", "@ember/string", "@ember/service"], function (_exports, _component, _object, _string, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    analytics: (0, _service.inject)(),
    router: (0, _service.inject)(),
    showConfirmDelete: false,
    deleteModalTitle: (0, _object.computed)('wh.url', function () {
      return (0, _string.htmlSafe)(`Delete <small class="gray">(${this.get('wh.name')})</small>`);
    }),
    includeToolTip: (0, _object.computed)('wh.include.[]', function () {
      return this.get('wh.include').join('<br />');
    }),
    includeTooltipOptions: (0, _object.computed)(function () {
      return {
        html: true
      };
    }),
    actions: {
      showEditPanel() {
        this.router.transitionTo('app.webhooks.edit', this.get('wh.name'));
      },

      delete(webhook) {
        return webhook.destroyRecord().then(() => {
          this.analytics.logEvent('Webhook', 'Deleted');
        });
      }

    }
  });

  _exports.default = _default;
});