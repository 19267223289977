define("dashboard/models/space/log-drain", ["exports", "ember-data/model", "dashboard/mixins/ds-error-monitoring-model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _dsErrorMonitoringModel, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_dsErrorMonitoringModel.default, {
    space: (0, _relationships.belongsTo)('space'),
    url: (0, _attr.default)('string')
  });

  _exports.default = _default;
});