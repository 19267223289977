define("dashboard/components/metrics/app-guidance/active-alerts", ["exports", "@ember/object", "dashboard/components/metrics/app-guidance/item", "moment"], function (_exports, _object, _item, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias,
    filterBy,
    notEmpty,
    sort
  } = _object.computed;

  var _default = _item.default.extend({
    tagName: 'metrics-app-guidance-active-alerts',
    title: 'Active Alerts',
    alertSortKey: ['startedAt:desc'],
    orderedActiveAlerts: sort('activeAlerts', 'alertSortKey'),
    activeLatencyAlerts: filterBy('orderedActiveAlerts', 'isLatency', true),
    activeErrorAlerts: filterBy('orderedActiveAlerts', 'isErrorRate', true),
    latencyHasActiveAlert: notEmpty('activeLatencyAlerts'),
    errorHasActiveAlert: notEmpty('activeErrorAlerts'),
    latestLatency: alias('activeLatencyAlerts.firstObject'),
    latestLatencyThreshold: alias('latestLatency.monitorValue'),
    latestLatencyDuration: (0, _object.computed)('latestLatency.startedAt', function () {
      return _moment.default.duration(_moment.default.now() - (0, _moment.default)(this.get('latestLatency.startedAt'))).humanize();
    }),
    latestError: alias('activeErrorAlerts.firstObject'),
    latestErrorThreshold: alias('latestError.monitorValue'),
    latestErrorDuration: (0, _object.computed)('latestError.startedAt', function () {
      return _moment.default.duration(_moment.default.now() - (0, _moment.default)(this.get('latestError.startedAt'))).humanize();
    }),
    alertDescriptions: (0, _object.computed)('errorHasActiveAlert', 'latencyHasActiveAlert', 'latestLatencyThreshold', 'latestErrorThreshold', function () {
      return [{
        text: `Response times have been above your ${this.latestLatencyThreshold}ms threshold for ${this.latestLatencyDuration}.`,
        hasActiveAlert: this.latencyHasActiveAlert
      }, {
        text: `The percentage of failed requests has been above your ${this.latestErrorThreshold * 100}% threshold for ${this.latestErrorDuration}.`,
        hasActiveAlert: this.errorHasActiveAlert
      }];
    }),
    activeAlertDescriptions: filterBy('alertDescriptions', 'hasActiveAlert')
  });

  _exports.default = _default;
});