define("dashboard/components/account/billing/invoice-row", ["exports", "@ember/object/computed", "@ember/component"], function (_exports, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'tr',
    classNames: ['invoice-row'],
    invoice: null,
    title: (0, _computed.readOnly)('invoice.title'),
    displayTitle: (0, _computed.or)('label', 'title')
  });

  _exports.default = _default;
});