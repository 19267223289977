define("dashboard/mixins/metrics/selected-time-data", ["exports", "@ember/object/mixin", "dashboard/utils/metrics/data-at-given-time", "numeral", "@ember/object", "@ember/utils", "dashboard/utils/metrics/aggregate-values"], function (_exports, _mixin, _dataAtGivenTime, _numeral, _object, _utils, _aggregateValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Default to the latest non zero time value if there is no rollup selected.
  const selectRawDisplayValue = function (selectedValue, latestValue, nextLatestValue) {
    return (0, _object.computed)('hasSelectedTime', selectedValue, latestValue, nextLatestValue, function () {
      return this.get('hasSelectedTime') ? this.get(selectedValue) : this.get(latestValue) ? this.get(latestValue) : this.get(nextLatestValue);
    });
  };

  const computeDisplayValue = function (rawDisplayValue, valueFormat) {
    return (0, _object.computed)(rawDisplayValue, valueFormat, 'smallNumberFormat', 'smallNumberThreshold', 'isLogarithmic', function () {
      let rawValue = this.get(rawDisplayValue);

      if (this.get('isLogarithmic') && rawValue > 0) {
        rawValue = Math.pow(10, rawValue);
      }

      const useSmallFormat = this.smallNumberFormat && rawValue > 0 && rawValue < this.smallNumberThreshold; // undefined represents the mouse being off the charts while null represents a zero value

      const rawValueIsUndefined = (0, _utils.typeOf)(rawValue) === 'undefined';
      const format = this.get(valueFormat) || '0,0';
      const formattedRawValue = format ? (0, _numeral.default)(rawValue).format(format) : rawValue;
      let displayValue = '0';

      if ((0, _utils.isPresent)(rawValue)) {
        displayValue = useSmallFormat ? this.smallNumberFormat : formattedRawValue;
      } else if (rawValueIsUndefined) {
        displayValue = '--';
      }

      return displayValue;
    });
  };

  var _default = _mixin.default.create({
    selectedDatum: (0, _dataAtGivenTime.datumAtGivenTime)('data', 'selectedRollupTime'),
    selectedValue: (0, _dataAtGivenTime.valueAtGivenTime)('data', 'selectedRollupTime'),
    latestValue: (0, _dataAtGivenTime.valueAtGivenTime)('data', 'latestRollupTime'),
    nextLatestValue: (0, _dataAtGivenTime.valueAtGivenTime)('data', 'nextLatestRollupTime'),
    rawDisplayValue: selectRawDisplayValue('selectedValue', 'latestValue', 'nextLatestValue'),

    /**
     * the display values to show for a certain value for a point in time
     */
    displayValue: computeDisplayValue('rawDisplayValue', 'valueFormat'),
    secondaryDisplayValue: computeDisplayValue('rawSecondaryDisplayValue', 'secondaryValueFormat'),
    selectedSecondaryValue: (0, _dataAtGivenTime.valueAtGivenTime)('extraData', 'selectedRollupTime'),
    latestSecondaryValue: (0, _dataAtGivenTime.valueAtGivenTime)('extraData', 'latestRollupTime'),
    nextLatestSecondaryValue: (0, _dataAtGivenTime.valueAtGivenTime)('extraData', 'nextLatestRollupTime'),
    rawSecondaryDisplayValue: selectRawDisplayValue('selectedSecondaryValue', 'latestSecondaryValue', 'nextLatestSecondaryValue'),

    /**
     * the minimum value that must be present for this chart to be
     * considered having a sufficient amount of data
     */
    minSufficientValue: 0,

    /**
     * specifies whether this chart has sufficient data
     */
    hasSufficientData: (0, _object.computed)('data.[]', function () {
      const data = this.data || [];
      const legitDatum = data.find(datum => {
        const value = datum[1];
        const hasValue = (0, _utils.isPresent)(datum[1]);
        const isAboveMin = value >= this.minSufficientValue;
        return hasValue && isAboveMin;
      });
      return (0, _utils.isPresent)(legitDatum);
    }),

    /**
     * the actual maximum value in the set of data
     */
    maxValue: (0, _aggregateValues.maxValue)('data')
  });

  _exports.default = _default;
});