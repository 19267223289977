define("dashboard/components/space-item", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/runloop", "dashboard/config/environment", "moment"], function (_exports, _component, _object, _computed, _runloop, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['bb', 'b--light-silver'],
    'data-test-target': 'space-item',
    estimatedMinutesToCreate: 8,
    creationStartedAt: (0, _object.computed)('space.createdAt', function () {
      return (0, _moment.default)(this.get('space.createdAt'));
    }),
    completionEstimatedAt: (0, _object.computed)('creationStartedAt', function () {
      return this.creationStartedAt.add(this.estimatedMinutesToCreate, 'minutes');
    }),
    creationMinutesRemaining: (0, _object.computed)('completionEstimatedAt', 'currentTime', function () {
      return this.completionEstimatedAt.diff(this.currentTime) / 60000;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.updateReadyStatus();
    },

    updateReadyStatus() {
      if (!this.get('space.isReady') && !_environment.default.DISABLE_TIMEOUTS) {
        this.space.reload();
        this.set('currentTime', (0, _moment.default)());
        this.set('updateReadyStatusEvent', _runloop.run.later(this, this.updateReadyStatus, 10000));
      }
    },

    willDestroyElement() {
      _runloop.run.cancel(this.updateReadyStatusEvent);

      this._super(...arguments);
    },

    currentTime: (0, _object.computed)(function () {
      return (0, _moment.default)();
    }),
    completionTimePassed: (0, _computed.lte)('creationMinutesRemaining', 0),
    timeRemaining: (0, _object.computed)('completionEstimatedAt', 'completionTimePassed', function () {
      return this.completionTimePassed ? 'any moment now' : this.completionEstimatedAt.fromNow();
    })
  });

  _exports.default = _default;
});