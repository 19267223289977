define("dashboard/models/enterprise-permissions", ["exports", "@ember/object", "@ember/object/computed"], function (_exports, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    // --------------------------------------------------------------------------
    // Args
    enterpriseAccount: null,
    // --------------------------------------------------------------------------
    // Computed props
    // The matrix of permissions that Dashboard should use as the source of truth
    // for what the given user can do.
    //
    // This matrix should mirror this dev center article:
    // https://devcenter.heroku.com/articles/enterprise-account-permissions
    canViewTeams: (0, _computed.or)('hasView', 'hasBilling', 'hasManage', 'hasCreate'),
    canViewAccess: (0, _computed.or)('hasView', 'hasBilling', 'hasManage', 'hasCreate'),
    canViewUsage: (0, _computed.bool)('hasBilling'),
    canManageTeams: (0, _computed.bool)('hasManage'),
    canManageAccess: (0, _computed.bool)('hasManage'),
    canManageSettings: (0, _computed.bool)('hasManage'),
    canCreateTeams: (0, _computed.and)('hasCreate', 'isNotTrial'),
    canManageGitProviders: (0, _computed.bool)('hasManage'),
    isTrial: (0, _computed.readOnly)('enterpriseAccount.trial'),
    isNotTrial: (0, _computed.not)('isTrial'),
    hasView: (0, _computed.readOnly)('enterpriseAccount.hasView'),
    hasBilling: (0, _computed.readOnly)('enterpriseAccount.hasBilling'),
    hasManage: (0, _computed.readOnly)('enterpriseAccount.hasManage'),
    hasCreate: (0, _computed.readOnly)('enterpriseAccount.hasCreate')
  });

  _exports.default = _default;
});