define("dashboard/templates/components/requires-password", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dCbZWhUh",
    "block": "[[[41,[30,0,[\"needsPassword\"]],[[[1,\"  \"],[11,\"form\"],[24,1,\"require-password-form\"],[4,[38,1],[[30,0],\"setPassword\"],[[\"on\"],[\"submit\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-body text-center\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-group password-prompt-form\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,\"for\",\"requires-password-input\"],[12],[1,[30,0,[\"labelText\"]]],[13],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"type\",\"value\",\"class\",\"id\",\"autofocus\",\"placeholder\"],[\"password\",[30,0,[\"password\"]],\"form-control center-block\",\"requires-password-input\",true,\"Enter your password\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n     \"],[1,[28,[35,3],null,[[\"onClick\",\"default\",\"buttonType\"],[[28,[37,1],[[30,0],\"cancel\"],null],\"Cancel\",\"tertiary\"]]]],[1,\"\\n     \"],[1,[28,[35,3],null,[[\"type\",\"form\",\"onClick\",\"default\"],[\"submit\",\"require-password-form\",[28,[37,1],[[30,0],\"setPassword\"],null],[30,0,[\"buttonText\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"action\",\"input\",\"hk-button\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/requires-password.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});