define("dashboard/components/account/ssh-keys", ["exports", "@ember/service", "@ember/component", "@ember/object", "dashboard/mixins/purple-list"], function (_exports, _service, _component, _object, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    filterBy
  } = _object.computed;

  var _default = _component.default.extend(_purpleList.default, {
    classNames: ['ssh-keys'],
    store: (0, _service.inject)(),

    createNewItem() {
      return this.store.createRecord('key');
    },

    titleForDelete: (0, _object.computed)('item.local', function () {
      const name = this.get('item.local');
      return `Delete <small>(${name})</small>`;
    }),
    visibleKeys: filterBy('keys', 'hasDirtyAttributes', false),
    actions: {
      save() {
        const key = this.item;

        this._super(...arguments);

        return key.save().then(() => {
          this.set('item', null);
          this.toggleProperty('isAdding');
        });
      },

      unloadKey() {
        this.store.unloadRecord(this.item);
      },

      delete() {
        const key = this.item;
        return key.destroyRecord().then(() => {
          this.keys.removeObject(key);
          this.send('toggleDeleteConfirmModal');
        });
      }

    }
  });

  _exports.default = _default;
});