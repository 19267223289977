define("dashboard/components/app/deploy/github/manual-deploy", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/runloop", "ember-concurrency", "dashboard/config/environment"], function (_exports, _component, _object, _computed, _service, _runloop, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEPLOY_POLL_DELAY = 1500;

  var _default = _component.default.extend({
    analytics: (0, _service.inject)(),
    store: (0, _service.inject)(),
    appLink: (0, _computed.readOnly)('app.services'),
    githubBranches: (0, _computed.readOnly)('app.branches'),
    isBuilding: (0, _computed.equal)('currentBuild.status', 'pending'),
    isReleasing: (0, _computed.equal)('currentRelease.status', 'pending'),
    isShowingBuild: (0, _computed.not)('currentBuild.hasSucceeded'),
    isShowingRelease: (0, _computed.not)('currentRelease.hasSucceeded'),
    isDeployingGithub: (0, _computed.readOnly)('deployBranchTask.isRunning'),
    unboundBranch: (0, _object.computed)('appLink.branch', 'githubBranches.@each.name', function () {
      const branches = this.githubBranches || [];
      const defaultBranch = this.get('appLink.branch');

      if (branches.length < 100) {
        // Look for the linked branch, then master, then just use the first one.
        const foundBranch = branches.findBy('name', defaultBranch) || branches.findBy('name', 'master') || branches.get('firstObject');
        return foundBranch ? foundBranch.get('name') : null;
      }

      return defaultBranch || 'master';
    }),
    currentBuildCommit: (0, _object.computed)('currentBuild.sourceBlob.version', function () {
      const version = (0, _object.get)(this.currentBuild, 'sourceBlob.version');

      if (version) {
        return version.slice(0, 8);
      }
    }),

    willDestroyElement() {
      const {
        buildPoller,
        releasePoller
      } = this;

      if (buildPoller) {
        _runloop.run.cancel(buildPoller);
      }

      if (releasePoller) {
        _runloop.run.cancel(releasePoller);
      }

      this._super(...arguments);
    },

    checkGithubBuildTask: (0, _emberConcurrency.task)(function* (build) {
      while (true) {
        yield build.reload();

        if (build.get('buildStatusIsTerminal')) {
          this.analytics.logEvent('GitHub Build', build.get('status'));
          return yield build;
        }

        if (_environment.default.environment === 'test') {
          return;
        }

        yield (0, _emberConcurrency.timeout)(DEPLOY_POLL_DELAY);
      }
    }),
    checkGithubReleaseTask: (0, _emberConcurrency.task)(function* () {
      while (true) {
        const releases = yield this.store.query('release', {
          app: this.app.get('id'),
          latest: true
        });
        const release = releases.get('firstObject');
        this.set('currentRelease', release);

        if (release.get('releaseStatusIsTerminal')) {
          this.analytics.logEvent('GitHub Release', release.get('status'));
          return yield release;
        }

        if (_environment.default.environment === 'test') {
          return;
        }

        yield (0, _emberConcurrency.timeout)(DEPLOY_POLL_DELAY);
      }
    }),
    deployBranchTask: (0, _emberConcurrency.task)(function* () {
      const deploy = this.store.createRecord('kolkrabbi/github-app-deploy', {
        app: this.app,
        branch: this.unboundBranch
      }); // reset release

      this.set('currentRelease', null);
      this.set('isShowingRelease', (0, _computed.not)('currentRelease.hasSucceeded')); // reset build

      this.set('currentBuild', null); // reset other things

      this.set('currentDeploy', deploy);

      try {
        yield deploy.save();
      } catch (e) {
        deploy.destroyRecord();
        return;
      }

      this.analytics.logEvent('App', 'Manually Deployed');
      const build = yield this.store.queryRecord('build', {
        buildId: deploy.get('build.id'),
        app: this.app.get('id')
      });
      this.set('currentBuild', build);
      this.set('currentBuildOutputStreamUrl', build.get('outputStreamUrl'));
      yield this.checkGithubBuildTask.perform(build);
      yield this.checkGithubReleaseTask.perform();
      this.set('isShowingRelease', false);
    }),
    actions: {
      deployBranch() {
        this.deployBranchTask.perform();
      },

      showLog(name) {
        this.toggleProperty(`isShowing${name.capitalize()}`);
      }

    }
  });

  _exports.default = _default;
});