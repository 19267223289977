define("dashboard/models/metrics/router-errors", ["exports", "@ember/utils", "lodash-es/sum", "@ember/object", "dashboard/models/metrics/errors-base"], function (_exports, _utils, _sum, _object, _errorsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the RouterErors model
   *
   */
  var _default = _errorsBase.default.extend({
    numberOfTimeouts: (0, _object.computed)('rawData', function () {
      const timeoutCodes = ['H12', 'H19'];
      return timeoutCodes.reduce((total, code) => {
        const routerErrorData = this.rawData;
        const data = (0, _utils.isPresent)(routerErrorData) ? routerErrorData[code] : [];

        if (data) {
          total += (0, _sum.default)(data.mapBy('1'));
        }

        return total;
      }, 0);
    })
  });

  _exports.default = _default;
});