define("dashboard/templates/components/identity-provider/certificate-list-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HXoXDJOf",
    "block": "[[[10,0],[14,0,\"mb1 flex justify-between items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"w-30 pr1 truncate\"],[12],[1,\"\\n\"],[41,[30,0,[\"isExpired\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"failure-ring-16\",\"malibu-fill-gradient-red nudge-up--1\",16,\"Expired certificate\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,1],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"success-ring-16\",\"malibu-fill-gradient-green nudge-up--1\",16,\"Valid certificate\"]],null],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"certificate\",\"name\"]],[[[1,\"      \"],[1,[30,0,[\"certificate\",\"name\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"gray\"],[12],[1,\"Untitled certificate\"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"w-30\"],[12],[1,\"\\n    Added \"],[1,[28,[35,2],[[30,0,[\"certificate\",\"createdAt\"]],\"MMMM D, YYYY\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"w-30\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"certificate\"],[[30,0,[\"certificate\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"w-10 tr\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,0,\"bn bg-transparent\"],[4,[38,5],[[30,0],[30,0,[\"onEdit\"]],[30,0,[\"certificate\"]]],null]],[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"edit-modify-16\",\"malibu-fill-gradient-purple\",16,\"Edit this certificate\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"malibu-icon\",\"moment-format\",\"identity-provider/certificate-expiry-date\",\"malibu-icon-button\",\"action\"]]",
    "moduleName": "dashboard/templates/components/identity-provider/certificate-list-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});