define("dashboard/templates/components/app/rollback-confirmation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0fBfyLV0",
    "block": "[[[10,0],[14,0,\"ph3\"],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex flex-column mb4 mt2 lh-copy\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"f4 dark-gray tc mb2\"],[12],[1,\"\\n      Confirm the result of rolling back to v\"],[1,[30,0,[\"release\",\"version\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"f5 dark-gray tc mb4\"],[12],[1,\"\\n      Rolling back to a previous release will \"],[10,1],[14,0,\"b\"],[12],[1,\"restore the code from that release\"],[13],[1,\" as well as \"],[10,1],[14,0,\"b\"],[12],[1,\"remove any config var changes\"],[13],[1,\" made since that release.\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"bg-lightest-orange inline-flex ba b--light-orange br1 pa2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"f5 orange\"],[12],[1,\"\\n        Changes made to external resources will not be rolled back. Add-ons that set config vars may stop working if a release with a config var change is rolled back.\\n        \"],[10,3],[14,6,\"https://devcenter.heroku.com/articles/releases#rollback\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link orange\"],[12],[1,\"Learn more\"],[13],[1,\".\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"f5 b dark-gray pl2 pb2\"],[12],[1,\"\\n    \"],[1,[30,0,[\"releasesToBeRolledBack\",\"length\"]]],[1,\" \"],[1,[28,[35,0],[[30,0,[\"releasesToBeRolledBack\",\"length\"]],\"release\",\"releases\"],null]],[1,\" below will be rolled back\\n  \"],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"releasesToBeRolledBack\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],null,[[\"item\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\"],false,[\"pluralize-word\",\"each\",\"-track-array\",\"app/activity-release-rollback-item\"]]",
    "moduleName": "dashboard/templates/components/app/rollback-confirmation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});