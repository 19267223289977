define("dashboard/services/ajax-monitor", ["exports", "jquery", "@ember/object/evented", "@ember/service", "@ember/runloop", "dashboard/config/environment"], function (_exports, _jquery, _evented, _service, _runloop, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_evented.default, {
    init() {
      this._super(...arguments);

      (0, _jquery.default)(document).ajaxSend(this.onSend.bind(this));
      (0, _jquery.default)(document).ajaxError(this.onError.bind(this));
    },

    errorMonitor: (0, _service.inject)(),
    session: (0, _service.inject)(),
    location: (0, _service.inject)(),

    /* Attach the HTTP method to the jqXHR object
     * to be consumed by the ErrorMonitor
     */
    onSend(event, jqXHR, settings) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      jqXHR.method = settings.type; // Add Heroku authorization headers if requested.

      if (settings.withHerokuAuthorization) {
        delete settings.withHerokuAuthorization;
        const bearerToken = this.session.bearerToken;

        if (bearerToken) {
          jqXHR.setRequestHeader('Authorization', `Bearer ${bearerToken}`);
        }
      }
    },

    onError(event, jqXHR) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      this.checkAPIMaintenance(jqXHR);
      const IGNORE_REGEXES = [/invalid_two_factor_code/, /invalid_password/, /Admin access to repository required/, /unauthorized-github/, /Bad credentials/];
      const hasIgnoredErrorMessage = IGNORE_REGEXES.some(function (regex) {
        return regex.test(jqXHR.responseText);
      });
      const {
        ignoreUnauthorized
      } = jqXHR;
      const ignoreError = ignoreUnauthorized || hasIgnoredErrorMessage;
      const status = jqXHR.status;

      if (status === 401 && !ignoreError) {
        this.trigger('unauthorized');
      } else if (status !== 404) {
        // give the application the opportunity to handle this error
        // specifically, we need to ensure assignErrors is able to execute before this run block
        _runloop.run.later(this, function () {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }

          this.errorMonitor.parseXHR(jqXHR);
        });
      }
    },

    checkAPIMaintenance(jqXHR) {
      if (jqXHR.getResponseHeader('X-Heroku-Maintenance') === 'true') {
        this.location.assign(_environment.default.maintenancePageURL);
      }
    }

  });

  _exports.default = _default;
});