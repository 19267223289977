define("dashboard/models/dyno-limit", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _model.default.extend({
    app: (0, _model.belongsTo)('app'),
    Free: (0, _model.attr)('number'),
    Hobby: (0, _model.attr)('number'),
    Eco: (0, _model.attr)('number'),
    Basic: (0, _model.attr)('number'),
    'Performance-L': (0, _model.attr)('number'),
    'Performance-M': (0, _model.attr)('number'),
    'Private-L': (0, _model.attr)('number'),
    'Private-M': (0, _model.attr)('number'),
    'Private-S': (0, _model.attr)('number'),
    'Standard-1x': (0, _model.attr)('number'),
    'Standard-2x': (0, _model.attr)('number'),
    'Standard-1X': alias('Standard-1x'),
    'Standard-2X': alias('Standard-2x')
  });

  _exports.default = _default;
});