define("dashboard/models/enterprise-account-preference", ["exports", "@ember-data/model", "@ember/object/computed"], function (_exports, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    enterpriseAccount: (0, _model.belongsTo)('enterprise-account'),
    preferences: (0, _model.attr)(),
    welcomeBannerState: (0, _computed.alias)('preferences.welcome-banner-state')
  });

  _exports.default = _default;
});