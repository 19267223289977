define("dashboard/models/domain", ["exports", "@ember/object/computed", "@ember/object", "ember-cp-validations", "dashboard/mixins/ds-error-monitoring-model", "@ember-data/model", "@ember/utils"], function (_exports, _computed, _object, _emberCpValidations, _dsErrorMonitoringModel, _model, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    hostname: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(Validations, _dsErrorMonitoringModel.default, {
    cname: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    hostname: (0, _model.attr)('string'),
    kind: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    acmStatus: (0, _model.attr)('string'),
    acmStatusReason: (0, _model.attr)('string'),
    app: (0, _model.belongsTo)('app'),
    sniEndpoint: (0, _model.belongsTo)('sni-endpoint'),
    isHeroku: (0, _computed.equal)('kind', 'heroku'),
    isCustom: (0, _computed.equal)('kind', 'custom'),
    isWildcard: (0, _computed.match)('hostname', /^\*/),
    errorTitleSubject: (0, _object.computed)('hostname', function () {
      return `Domain "${this.hostname}"`;
    }),
    humanAcmStatus: (0, _object.computed)('acmStatus', function () {
      switch (this.get('acmStatus')) {
        case 'ok':
        case 'cert issued':
          return 'Ok';

        case 'failing':
          return 'Failing';

        case 'failed':
          return 'Failed';

        default:
          return 'Pending';
      }
    }),
    // api gives us boring titles like `ok` and `in-progress`
    // that we want to make more human friendly here
    humanAcmStatusReason: (0, _object.computed)('acmStatus', 'acmStatusReason', function () {
      const acmStatusReason = this.get('acmStatusReason'); // `cert issued` and `ok` are both 👌

      if (acmStatusReason === 'cert issued') {
        return 'Ok';
      }

      const humanAcmStatus = this.get('acmStatus') || 'waiting';
      const capitalizedHumanAcmStatus = humanAcmStatus.split('-').map(word => {
        return word.capitalize();
      }).join(' ');
      return (0, _utils.isPresent)(acmStatusReason) ? acmStatusReason : capitalizedHumanAcmStatus;
    })
  });

  _exports.default = _default;
});