define("dashboard/templates/components/github-commit-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jabOrqoD",
    "block": "[[[41,[28,[37,1],[[30,0,[\"hasStatuses\"]],[30,0,[\"ref\"]]],null],[[[6,[39,2],null,[[\"buttonComponent\",\"buttonComponentOptions\",\"buttonClass\",\"tooltipTitle\"],[\"pipeline/commit-status-icon\",[28,[37,3],null,[[\"state\"],[[30,0,[\"compositeStatus\"]]]]],\"btn-link\",[30,0,[\"summarySubHeading\"]]]],[[\"default\"],[[[[1,\"    \"],[10,\"li\"],[14,0,\"dropdown-header\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"ci-check-summary\"],[12],[1,\"\\n\\n\"],[41,[28,[37,4],[[28,[37,5],[[30,0,[\"compositeStatus\"]],\"error\"],null],[28,[37,5],[[30,0,[\"compositeStatus\"]],\"failure\"],null]],null],[[[1,\"          \"],[10,\"b\"],[14,0,\"text-danger\"],[12],[1,\"Some checks have failed\"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,5],[[30,0,[\"compositeStatus\"]],\"pending\"],null],[[[1,\"          \"],[10,\"b\"],[12],[1,\"Checks are running\"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,5],[[30,0,[\"compositeStatus\"]],\"success\"],null],[[[1,\"          \"],[10,\"b\"],[14,0,\"text-success\"],[12],[1,\"All checks have passed\"],[13],[1,\"\\n        \"]],[]],null]],[]]]],[]]],[1,\"\\n        \"],[10,1],[12],[1,[30,0,[\"summarySubHeading\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"latestCommitStatuses\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,1,[\"targetUrl\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link\"],[12],[1,\"\\n          \"],[1,[28,[35,8],null,[[\"options\"],[[28,[37,3],null,[[\"state\"],[[30,1,[\"state\"]]]]]]]]],[1,\" \"],[1,[30,1,[\"context\"]]],[1,\" \"],[10,\"small\"],[12],[1,\"— \"],[1,[30,1,[\"description\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null]],[]]]]]],[]],null]],[\"status\"],false,[\"if\",\"and\",\"drop-down\",\"hash\",\"or\",\"eq\",\"each\",\"-track-array\",\"pipeline/commit-status-icon\"]]",
    "moduleName": "dashboard/templates/components/github-commit-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});