define("dashboard/components/pipeline/review-stage", ["exports", "@ember/service", "@ember/component", "@ember/object", "@ember/object/computed", "jquery"], function (_exports, _service, _component, _object, _computed, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['stage', 'flex', 'flex-column'],
    store: (0, _service.inject)(),
    githubToken: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    account: (0, _computed.alias)('current.account'),
    canCreateAppsFromBranches: (0, _computed.and)('pipeline.hasNewReviewAppsEnabled', 'pipeline.canCreateReviewApps'),
    canEnableReviewApps: (0, _computed.alias)('hasNoKolkrabbiReviewAppsEnabled'),
    hasAnyNonReviewApps: (0, _computed.notEmpty)('pipeline.nonReviewCouplingApps'),
    hasNoKolkrabbiReviewAppsEnabled: (0, _computed.not)('hasReviewAppParent'),
    hasReviewAppsEnabled: (0, _computed.or)('pipeline.hasNewReviewAppsEnabled', 'pipeline.hasReviewAppParent'),
    hasReviewAppParent: (0, _computed.alias)('pipeline.hasReviewAppParent'),
    hasAutomaticReviewAppsEnabled: (0, _computed.or)('pipeline.reviewAppConfig.hasAutomaticReviewApps', 'reviewAppParent.pullRequests.auto_deploy'),
    isCreatingReviewAppFromBranch: false,
    openReviewAppsConfig: false,
    reviewAppParent: (0, _computed.reads)('pipeline.reviewAppParent'),
    sorting: ['prNumberSortKey:desc', 'name'],
    sortedApps: (0, _computed.sort)('apps', 'sorting'),
    isShowingNewReviewAppsEnableModal: false,
    isShowingMigrationBanner: (0, _object.computed)('pipeline.hasReviewAppParent', 'pipeline.hasNewReviewAppsEnabled', function () {
      return this.get('pipeline.hasReviewAppParent') && !this.get('pipeline.hasNewReviewAppsEnabled');
    }),
    repositoryName: (0, _object.computed)('pipeline.repositoryFullName', 'reviewAppParent.repo', function () {
      return this.get('pipeline.repositoryFullName') || this.get('reviewAppParent.repo');
    }),
    repositoryUrl: (0, _object.computed)('pipeline.repositoryUrl', 'reviewAppParent.webUrl', function () {
      return this.get('pipeline.repositoryUrl') || this.get('reviewAppParent.webUrl');
    }),
    parentAppTooltip: (0, _object.computed)(function () {
      return `Parent app: ${this.get('reviewAppParent.app.name')}`;
    }),
    parentAppOwner: (0, _object.computed)(function () {
      return `Chargeable usage invoiced to ${this.get('reviewAppParent.app.owner.email')}`;
    }),
    parentAppOrgOwner: (0, _object.computed)('reviewAppParent.app.team.name', function () {
      return `Chargeable usage invoiced to the "${this.get('reviewAppParent.app.team.name')}" team`;
    }),
    reviewAppConfig: (0, _object.computed)('pipeline.reviewAppConfig.id', function () {
      let reviewAppConfig;

      if (this.get('pipeline.reviewAppConfig.id')) {
        reviewAppConfig = this.get('pipeline.reviewAppConfig');
      } else {
        reviewAppConfig = this.store.createRecord('review-app-config');
      }

      return reviewAppConfig;
    }),
    reviewAppsDevCenterUrl: (0, _object.computed)(function () {
      return 'https://devcenter.heroku.com/articles/review-apps-new';
    }),
    actions: {
      toggleCreateReviewAppFromBranch() {
        (0, _jquery.default)(this.element).find('ul.pipeline-review.app-list').scrollTop(0);
        this.toggleProperty('isCreatingReviewAppFromBranch');
        this.set('openReviewAppsConfig', false);
      },

      toggleReviewAppConfig() {
        (0, _jquery.default)(this.element).find('ul.pipeline-review.app-list').scrollTop(0);
        this.toggleProperty('openReviewAppsConfig');
        this.set('isCreatingReviewAppFromBranch', false);
      },

      createReviewApp(reviewApp) {
        return this.createReviewApp(reviewApp).then(() => {
          this.toggleProperty('isCreatingReviewAppFromBranch');
        });
      },

      authorizedGitHub() {
        this.authorizedGitHub({
          transitionTarget: 'pipelines.pipeline.index.review-apps'
        });
      },

      enableNewReviewApps(state) {
        this.set('isShowingReviewAppConfigModal', true);
        this.analytics.logEvent('Review Apps New Banner', 'Clicked', {
          state
        });
      },

      closeReviewAppConfigModal() {
        this.set('isShowingReviewAppConfigModal', false);
        this.analytics.logEvent('Review Apps New Slide Panel', 'Closed');
      }

    }
  });

  _exports.default = _default;
});