define("dashboard/services/api", ["exports", "@ember/service", "jquery", "rsvp", "@ember/object", "@ember/runloop"], function (_exports, _service, _jquery, _rsvp, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.normalizeError = normalizeError;

  var _default = _service.default.extend({
    session: (0, _service.inject)(),
    store: (0, _service.inject)(),

    /**
     * Returns the application adapter.
     *
     * @property adapter
     * @type {ApplicationAdapter}
     */
    adapter: (0, _object.computed)(function () {
      const store = (0, _object.get)(this, 'store');
      return store.adapterFor('application');
    }),

    /**
     * Performs an API request.
     *
     * @method request
     * @param {string} path - request path
     * @param {Object} options - request options
     * @param {?Object} options.adapter - override the default (application) adapter
     * @return {Promise<any>}
     */
    request(path) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const adapter = options.adapter || (0, _object.get)(this, 'adapter');
      delete options.adapter;
      const host = adapter.get('host');
      const url = host + path;
      const normalHeaders = adapter.get('headers');
      const authHeaders = adapter.get('authorizationHeaders'); // copy additional headers

      const headers = Object.assign({}, normalHeaders, authHeaders, options.headers || {}); // prevent overwriting default headers

      delete options.headers;
      const finalOptions = Object.assign({}, {
        headers
      }, options);
      return new _rsvp.Promise((resolve, reject) => {
        _jquery.default.ajax(url, finalOptions).done(data => (0, _runloop.join)(null, resolve, data)).fail(jqXHR => (0, _runloop.join)(null, reject, normalizeError(jqXHR)));
      });
    },

    /**
     * Performs a GET to the given path.
     *
     * Overrides Ember.Object’s `get`, which seems like a terrible idea but
     * somehow I can’t resist it.
     *
     * @method get
     * @param {String} path
     * @returns {Promise<any>}
     */
    get(path) {
      return this.request(path, {
        type: 'GET'
      });
    },

    /**
     * Performs a POST to the given path.
     *
     * @method post
     * @param {String} path
     * @param {Object} data
     * @returns {Promise<any>}
     */
    post(path, data) {
      return this.request(path, {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    },

    /**
     * Performs a PATCH to the given path.
     *
     * @method patch
     * @param {string} path
     * @param {Object} data
     * @returns {Promise<any>}
     */
    patch(path, data) {
      return this.request(path, {
        type: 'PATCH',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(data)
      });
    },

    /**
     * Performs a PUT to the given path.
     *
     * @method put
     * @param {string} path
     * @param {object} data
     * @returns {Promise}
     */
    put(path, data) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const requestOptions = {
        type: 'PUT',
        contentType: 'application/json; charset=utf-9',
        data: JSON.stringify(data),
        ...options
      };
      return this.request(path, requestOptions);
    },

    /**
     * Performs a DELETE to the given path.
     *
     * @method delete
     * @param {string} path
     * @returns {Promise}
     */
    delete(path) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const requestOptions = {
        type: 'DELETE',
        ...options
      };
      return this.request(path, requestOptions);
    }

  });

  _exports.default = _default;

  function normalizeError(original) {
    const message = (0, _object.get)(original, 'responseJSON.message');

    if (message) {
      const error = new Error(message);
      error.original = original;
      return error;
    } else {
      return original;
    }
  }
});