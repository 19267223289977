define("dashboard/models/pending-github-installation", ["exports", "@ember-data/model", "@ember/object/computed"], function (_exports, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    accountId: (0, _model.attr)('string'),
    accountType: (0, _model.attr)('string'),
    redirectPath: (0, _model.attr)('string'),
    herokuResourceId: (0, _computed.alias)('accountId'),
    herokuResourceType: (0, _computed.alias)('accountType')
  });

  _exports.default = _default;
});