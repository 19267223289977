define("dashboard/templates/components/validating-textarea", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zPNoKDXu",
    "block": "[[[1,[28,[35,0],null,[[\"class\",\"placeholder\",\"value\",\"readonly\",\"disabled\",\"validatingKey\",\"key-press\"],[[28,[37,1],[[30,0,[\"inputClass\"]],\" \",[52,[33,3],\"has-error\"],\" \"],null],[30,0,[\"placeholder\"]],[30,0,[\"value\"]],[30,0,[\"isReadOnly\"]],[30,0,[\"disabled\"]],[30,0,[\"validatingKey\"]],\"setErrorIsVisible\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showError\"]],[[[1,\"  \"],[10,0],[14,0,\"field-error-message\"],[12],[1,[30,0,[\"errorMessage\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"autogrow-textarea\",\"concat\",\"if\",\"showError\"]]",
    "moduleName": "dashboard/templates/components/validating-textarea.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});