define("dashboard/templates/components/github-app-placement-picker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DHqsOSGD",
    "block": "[[[10,0],[14,0,\"ph3\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"placement\",\"herokuResourceType\",\"installationId\",\"createGithubAppInstallation\"],[[30,0,[\"current\",\"account\"]],\"user\",[30,0,[\"installationId\"]],[30,0,[\"createGithubAppInstallation\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"enterpriseAccounts\"]],[[[6,[39,2],null,[[\"connectionItems\",\"listTitle\"],[[30,0,[\"enterpriseAccounts\"]],\"Enterprise Accounts\"]],[[\"default\"],[[[[42,[28,[37,4],[[28,[37,4],[[30,1]],null]],null],null,[[[1,\"      \"],[1,[28,[35,0],null,[[\"placement\",\"currentUserEmail\",\"herokuResourceType\",\"installationId\",\"createGithubAppInstallation\"],[[30,2],[30,0,[\"current\",\"account\",\"email\"]],\"enterprise-account\",[30,0,[\"installationId\"]],[30,0,[\"createGithubAppInstallation\"]]]]]],[1,\"\\n\"]],[2]],null]],[1]]]]]],[]],null],[41,[30,0,[\"onlineTeams\"]],[[[6,[39,2],null,[[\"connectionItems\",\"listTitle\"],[[30,0,[\"onlineTeams\"]],\"Teams\"]],[[\"default\"],[[[[42,[28,[37,4],[[28,[37,4],[[30,3]],null]],null],null,[[[1,\"        \"],[1,[28,[35,0],null,[[\"placement\",\"currentUserEmail\",\"herokuResourceType\",\"installationId\",\"createGithubAppInstallation\"],[[30,4],[30,0,[\"current\",\"account\",\"email\"]],\"team\",[30,0,[\"installationId\"]],[30,0,[\"createGithubAppInstallation\"]]]]]],[1,\"\\n\"]],[4]],null]],[3]]]]]],[]],null]],[\"sortedConnectionListItems\",\"enterpriseAccount\",\"sortedConnectionListItems\",\"team\"],false,[\"github-app-placement-option\",\"if\",\"third-party-connection-list\",\"each\",\"-track-array\"]]",
    "moduleName": "dashboard/templates/components/github-app-placement-picker.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});