define("dashboard/models/repositories-api-commit-status", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    pipeline: (0, _model.belongsTo)('pipeline'),
    context: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    sha: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    updatedAt: (0, _model.attr)('date'),
    targetUrl: (0, _model.attr)('string'),
    repo: (0, _model.attr)('string')
  });

  _exports.default = _default;
});