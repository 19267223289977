define("dashboard/templates/components/enterprise-account/member/button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "L8N92MFf",
    "block": "[[[41,[30,0,[\"member\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"type\",\"buttonType\",\"isPending\",\"disabled\",\"onClick\",\"class\",\"default\"],[\"submit\",\"primary\",[30,0,[\"isPending\"]],[28,[37,2],[[30,0,[\"isPristine\"]],[30,0,[\"isPending\"]]],null],[30,0,[\"onClick\"]],[30,0,[\"class\"]],\"Save changes\"]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"type\",\"buttonType\",\"isPending\",\"disabled\",\"onClick\",\"class\",\"default\"],[\"submit\",\"primary\",[30,0,[\"isPending\"]],[28,[37,2],[[30,0,[\"validations\",\"isInvalid\"]],[30,0,[\"validations\",\"isValidating\"]],[30,0,[\"isPending\"]]],null],[30,0,[\"onClick\"]],[30,0,[\"class\"]],\"Add user\"]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"hk-button\",\"or\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/member/button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});