define("dashboard/templates/components/target-blank-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xi137eI5",
    "block": "[[[1,[30,0,[\"text\"]]]],[],false,[]]",
    "moduleName": "dashboard/templates/components/target-blank-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});