define("dashboard/adapters/shogun", ["exports", "dashboard/adapters/application", "dashboard/config/environment", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _application, _environment, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _environment.default.shogunUrl,
    session: (0, _service.inject)(),
    bearerToken: (0, _computed.readOnly)('session.bearerToken'),
    headers: (0, _object.computed)(function () {
      return {
        Authorization: `Bearer ${this.bearerToken}`
      };
    })
  });

  _exports.default = _default;
});