define("dashboard/mixins/formations", ["exports", "@ember/object/mixin", "@ember/object", "dashboard/models/dyno-tier"], function (_exports, _mixin, _object, _dynoTier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    readOnly,
    bool,
    equal,
    gt,
    not,
    notEmpty
  } = _object.computed;

  var _default = _mixin.default.create({
    dynoTiers: _dynoTier.default.all(),
    isFreeTier: equal('processTier', 'free'),
    isNotFreeTier: not('isFreeTier'),
    isEcoTier: equal('processTier', 'eco'),
    isHobbyTier: equal('processTier', 'hobby'),
    isBasicTier: equal('processTier', 'basic'),
    isPrivateTier: equal('processTier', 'private'),
    isShieldTier: equal('processTier', 'shield'),
    isNotPrivateTier: not('isPrivateTier'),
    isNotShieldTier: not('isShieldTier'),
    hasKnownTier: bool('currentTier'),
    currentTierName: (0, _object.computed)('processTier', 'isPrivateSpaceShieldApp', 'isPrivateSpaceApp', function () {
      if (this.processTier) {
        return this.processTier;
      } else if (this.isPrivateSpaceShieldApp) {
        return 'shield';
      } else if (this.isPrivateSpaceApp) {
        return 'private';
      } else {
        return 'production';
      }
    }),
    currentTier: (0, _object.computed)('dynoTiers.@each.name', 'currentTierName', function () {
      return this.dynoTiers.findBy('name', this.currentTierName);
    }),
    processTier: readOnly('formations.firstObject.processTier'),
    hasProcessTier: notEmpty('processTier'),
    hasAnyDynos: gt('formations.length', 0),
    hasNoDynos: not('hasAnyDynos')
  });

  _exports.default = _default;
});