define("dashboard/components/efp-rapid-upgrade-table", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "rsvp"], function (_exports, _component, _tracking, _object, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EfpRapidUpgradeTableComponent = (_class = class EfpRapidUpgradeTableComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "current", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      _initializerDefineProperty(this, "flashMessages", _descriptor4, this);

      _initializerDefineProperty(this, "accountFeatures", _descriptor5, this);

      _initializerDefineProperty(this, "addonForEdit", _descriptor6, this);

      _initializerDefineProperty(this, "addonPlan", _descriptor7, this);

      _initializerDefineProperty(this, "isEditingAddon", _descriptor8, this);

      _initializerDefineProperty(this, "filter", _descriptor9, this);

      _initializerDefineProperty(this, "showEcoSubscribeModal", _descriptor10, this);

      _defineProperty(this, "apps", this.args.apps);

      _defineProperty(this, "addons", this.args.addons);
    }

    get subscribedToEco() {
      return this.current.account.subscribedToEco;
    }

    get isNotSubscribedToEco() {
      return !this.subscribedToEco;
    }

    get allAppsWithFreeResources() {
      return this.apps.filter(app => app.get('hasFreeHerokuDataAddons') || app.get('hasFreeDynos') && this.isNotSubscribedToEco);
    }

    get appsWithFreeResources() {
      return this.allAppsWithFreeResources.filter(app => !app.isInPipeline);
    }

    get pipelinesWithFreeResources() {
      const pipelineAppsWithFreeResources = this.allAppsWithFreeResources.filter(app => app.isInPipeline);
      const pipelines = new Set(pipelineAppsWithFreeResources.map(pipelineApp => {
        return pipelineApp.get('pipelineCoupling.pipeline.content');
      }));
      return [...pipelines];
    }

    get appsAndPipelinesWithFreeResources() {
      return [...this.pipelinesWithFreeResources, ...this.appsWithFreeResources].sortBy('name');
    }

    get filteredPipelinesAndAppsWithFreeResources() {
      const filterText = (this.filter || '').toLowerCase().replace(/[^a-z0-9-]+/gi, '');

      if (filterText.length === 0) {
        return this.appsAndPipelinesWithFreeResources;
      }

      const filter = filterText.split('').join('.*');
      const regex = new RegExp(filter, 'i');
      return this.appsAndPipelinesWithFreeResources.filter(item => regex.test(item.get('name')));
    }

    get hasItemsToUpgrade() {
      return this.appsAndPipelinesWithFreeResources.length > 0;
    }

    get shouldDisplayEcoSubscribeButton() {
      return this.hasItemsToUpgrade && this.args.isPersonal && this.isNotSubscribedToEco;
    }

    get shouldDisplayBanner() {
      return this.hasItemsToUpgrade && this.args.isPersonal;
    }

    ecoSubscribeButtonAction() {
      if (this.args.cardPresent || this.current.account.isOhana) {
        this.showEcoSubscribeModal = true;
      } else {
        this.router.transitionTo('account.billing');
      }
    }

    async subscribeToEco() {
      (0, _object.set)(this, 'current.account.ecoDynosEnabled', true);

      try {
        await this.current.account.save().then(() => {
          const toastMessage = 'Heroku automatically converted all your free dynos to Eco.';
          this.flashMessages.success(toastMessage, {
            title: 'Successfully subscribed to Eco'
          });
        });
      } catch (error) {
        (0, _object.set)(this, 'current.account.ecoDynosEnabled', false);
      } finally {
        this.showEcoSubscribeModal = false;
      }
    }

    saveAddon() {
      const addon = this.addonForEdit;
      const app = addon.app;

      if (!addon.isValid) {
        return (0, _rsvp.reject)();
      }

      return addon.save().then(() => {
        this.analytics.logEvent('Add-on', 'Updated');
        addon.set('app', app); // re-attach the full app object that was clobbered during save

        addon.set('confirmNotAutoNetworked', false); // clear out this value

        addon.reload();
        this.closeEditAddonModal();
        const toastMessage = "Your database won't be marked for deletion.";
        const toastTitle = 'Database successfully upgraded';
        this.flashMessages.success(toastMessage, {
          title: toastTitle
        });
      });
    }

    async editAddon(addon, newPlan) {
      this.addonForEdit = addon;
      this.addonPlan = newPlan; // Ensure this stuff is loaded so the contents of the modal doesn't go wild.

      await addon.addonService;
      await addon.addonServicePlans;
      this.isEditingAddon = true;
    }

    closeEditAddonModal() {
      this.addonForEdit = null;
      this.addonPlan = null;
      this.isEditingAddon = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "current", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accountFeatures", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addonForEdit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "addonPlan", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isEditingAddon", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showEcoSubscribeModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "ecoSubscribeButtonAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "ecoSubscribeButtonAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "subscribeToEco", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "subscribeToEco"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAddon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveAddon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editAddon", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editAddon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeEditAddonModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeEditAddonModal"), _class.prototype)), _class);
  _exports.default = EfpRapidUpgradeTableComponent;
});