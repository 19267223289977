define("dashboard/adapters/repositories-api-github-compare", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3.repositories-api',

    urlForQueryRecord(query) {
      const {
        pipeline,
        base,
        head
      } = query;
      delete query.pipeline;
      delete query.base;
      delete query.head;
      return `${this.host}/pipelines/${pipeline}/repo/compare?base=${encodeURIComponent(base)}&head=${encodeURIComponent(head)}`;
    }

  });

  _exports.default = _default;
});