define("dashboard/models/pipeline/collaborator", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "@ember/object", "@ember/object/computed"], function (_exports, _attr, _model, _relationships, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    pipeline: (0, _relationships.belongsTo)('pipeline'),
    user: (0, _attr.default)(),
    permissions: (0, _attr.default)(),
    email: (0, _object.computed)('user.email', function () {
      return this.get('user.email');
    }),
    permits: (0, _object.computed)('permissions.[]', 'pipeline.permissionNames', 'pipeline.permissionNames.[]', function () {
      const permNames = this.get('pipeline.permissionNames') || [];
      return permNames.map(name => {
        return _object.default.create({
          name,
          enabled: this.hasPermission(name),
          disabled: name === 'view'
        });
      });
    }),
    permitsByName: (0, _object.computed)('permits.[]', function () {
      return this.permits.reduce((memo, permission) => {
        const {
          name
        } = permission;
        memo[name] = permission;
        return memo;
      }, {});
    }),
    enabledPermits: (0, _computed.filterBy)('permits', 'enabled', true),

    hasPermission(name) {
      return this.permissions.includes(name);
    }

  });

  _exports.default = _default;
});