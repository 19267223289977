define("dashboard/models/metrics/language-specific-memory", ["exports", "dashboard/models/metrics", "dashboard/mixins/models/metrics/java-memory", "dashboard/mixins/models/metrics/go-memory", "dashboard/mixins/models/metrics/ruby-memory", "dashboard/mixins/models/metrics/node-memory"], function (_exports, _metrics, _javaMemory, _goMemory, _rubyMemory, _nodeMemory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _metrics.default.extend(_javaMemory.default, _goMemory.default, _rubyMemory.default, _nodeMemory.default);

  _exports.default = _default;
});