define("dashboard/templates/components/app/deploy/github/disconnect-confirmation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8SQjiQMF",
    "block": "[[[6,[39,0],null,[[\"title\",\"primaryAction\",\"primaryActionTitle\",\"primaryActionButtonClassNames\",\"secondaryActionTitle\",\"secondaryAction\",\"onClose\"],[\"Disconnect from GitHub\",[30,1],\"Disconnect\",\"btn btn-danger\",\"Cancel\",[30,2],[30,2]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"app-github-disconnect\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[10,\"strong\"],[12],[1,\"\\n        Disconnect this app from the connected GitHub repository?\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"app-github-disconnect-warnings\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasReviewApps\"]],[[[6,[39,2],null,[[\"alertType\"],[\"warning\"]],[[\"default\"],[[[[1,\"          All existing review apps\\n          (\"],[1,[30,0,[\"reviewAppCount\"]]],[1,\" \"],[1,[28,[35,3],[[30,0,[\"reviewAppCount\"]],\"app\",\"apps\"],null]],[1,\")\\n          will be \"],[10,\"b\"],[12],[1,\"deleted\"],[13],[1,\".\\n\"]],[]]]]]],[]],null],[41,[30,0,[\"spawnsReviewApps\"]],[[[6,[39,2],null,[[\"alertType\"],[\"warning\"]],[[\"default\"],[[[[1,\"          This app will no longer create new review apps.\\n\"]],[]]]]]],[]],null],[41,[30,0,[\"autoDeploys\"]],[[[6,[39,2],null,[[\"alertType\"],[\"warning\"]],[[\"default\"],[[[[1,\"          This app will no longer automatically deploy from\\n          \"],[10,\"b\"],[12],[1,[30,0,[\"autoDeployBranch\"]]],[13],[1,\".\\n\"]],[]]]]]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@unlink\",\"@onClose\"],false,[\"hk-modal\",\"if\",\"flash-status\",\"pluralize-word\"]]",
    "moduleName": "dashboard/templates/components/app/deploy/github/disconnect-confirmation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});