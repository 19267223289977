define("dashboard/mixins/app-activity-websocket", ["exports", "@ember/object/mixin", "@ember/service"], function (_exports, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    realTimeUpdater: (0, _service.inject)(),

    activate() {
      this._super();

      this.subscribeToBuilds();
      this.subscribeToReleases();
    },

    deactivate() {
      const modelHash = this.modelFor('app');
      const app = modelHash && modelHash.app;

      if (app) {
        this.realTimeUpdater.unsubscribeFromModel(app, 'builds');
        this.realTimeUpdater.unsubscribeFromModel(app, 'releases');
      }

      this._super();
    },

    subscribeToBuilds() {
      const app = this.modelFor('app').app;
      this.realTimeUpdater.subscribeToModel(app, 'builds', {
        type: 'create'
      });
      this.realTimeUpdater.subscribeToModel(app, 'builds', {
        type: 'update'
      });
    },

    subscribeToReleases() {
      const app = this.modelFor('app').app;
      this.realTimeUpdater.subscribeToModel(app, 'releases', {
        type: 'create'
      });
      this.realTimeUpdater.subscribeToModel(app, 'releases', {
        type: 'update'
      });
    }

  });

  _exports.default = _default;
});