define("dashboard/components/datastore/postgres/config-slide-panel", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    logStatementOptions: [{
      value: 'all',
      label: 'all statements'
    }, {
      value: 'ddl',
      label: 'schema changes'
    }, {
      value: 'mod',
      label: 'schema or data changes'
    }, {
      value: 'none',
      label: 'no statements'
    }],
    actions: {
      updateConfig() {
        this.config.save().then(() => this.closeUpdateConfig());
      },

      cancelUpdateConfig() {
        this.config.rollbackAttributes();
        this.closeUpdateConfig();
      },

      selectChange(key, value) {
        this.config.set(key, value);
      }

    }
  });

  _exports.default = _default;
});