define("dashboard/adapters/attachment", ["exports", "@ember/polyfills", "@ember/object", "dashboard/adapters/application", "dashboard/utils/errors"], function (_exports, _polyfills, _object, _application, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    headers: (0, _object.computed)(function () {
      const headers = this._super();

      const additionalHeaders = {
        'X-Heroku-Legacy-Ids': true,
        'Accept-Inclusion': 'config_vars'
      };
      return (0, _polyfills.merge)(headers, additionalHeaders);
    }),

    urlForQuery(query) {
      if (query.appNameOrId) {
        const path = `${this.host}/apps/${query.appNameOrId}/addon-attachments`;
        delete query.appNameOrId;
        return path;
      } else if (query.addonId) {
        const path = `${this.host}/addons/${query.addonId}/addon-attachments`;
        delete query.addonId;
        return path;
      } else {
        throw new Error('no appropriate query parameter was passed to query()');
      }
    },

    urlForCreateRecord() {
      return `${this.host}/addon-attachments`;
    },

    urlForDeleteRecord(id) {
      return `${this.host}/addon-attachments/${id}`;
    },

    normalizeErrorResponse(status, headers, payload) {
      return (0, _errors.toJSONAPIErrors)(payload, status);
    }

  });

  _exports.default = _default;
});