define("dashboard/serializers/sni-endpoint", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      const sniEndpoint = snapshot.record;
      const privateKey = sniEndpoint.get('privateKey');
      const payload = {
        display_name: sniEndpoint.get('displayName')
      }; // we only want to send the cert chain if the private key is also present, otherwise
      // we assume the user is only updating metadata about the cert rather than the cert itself

      if (privateKey) {
        Object.assign(payload, {
          certificate_chain: sniEndpoint.get('certificateChain'),
          private_key: sniEndpoint.get('privateKey')
        });
      }

      return payload;
    },

    extractRelationships(modelClass, resourceHash) {
      const {
        app
      } = resourceHash;

      const relationships = this._super(...arguments);

      if (app) {
        relationships.app = {
          data: {
            id: app.id,
            type: 'app'
          }
        };
      }

      return relationships;
    }

  });

  _exports.default = _default;
});