define("dashboard/adapters/account/feature", ["exports", "dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    createRecord(store, type, snapshot) {
      const name = snapshot.attr('name');
      const enabled = snapshot.attr('enabled');
      const url = this.urlForUpdateRecord(name, type.modelName);
      const options = {
        data: {
          enabled
        }
      };
      return this.ajax(url, 'PATCH', options);
    }

  });

  _exports.default = _default;
});