define("dashboard/adapters/team/allowed-addon", ["exports", "@ember/service", "dashboard/adapters/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    version: '3',
    dsErrorMonitor: (0, _service.inject)(),

    urlForCreateRecord(modelName, snapshot) {
      const teamId = snapshot.belongsTo('team', {
        id: true
      });
      return `${this.host}/teams/${teamId}/allowed-addon-services`;
    },

    urlForDeleteRecord(id, modelName, snapshot) {
      const teamId = snapshot.belongsTo('team', {
        id: true
      });
      return `${this.host}/teams/${teamId}/allowed-addon-services/${id}`;
    },

    handleResponse(status, headers, payload, requestData) {
      if (status >= 400 && status < 500) {
        // don't flash error
        requestData.jqXHR.errorIsHandled = true;
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});