define("dashboard/templates/components/enterprise-usage/progress", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/fdZxxKv",
    "block": "[[[10,0],[14,0,\"f4 mid-gray b mb3\"],[12],[1,\"\\n  \"],[1,[30,0,[\"title\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"f4 gray mb2\"],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[\"b \",[52,[30,0,[\"isOverQuota\"]],\"red\"]]]],[12],[1,\"\\n    \"],[1,[30,0,[\"formattedValue\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"hasMax\"]],[[[1,\"    of \"],[10,1],[12],[1,[30,0,[\"formattedMax\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[30,0,[\"usageVerb\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[\"progress-actual \",[52,[30,0,[\"isOverQuota\"]],\"over-quota\"]]]],[12],[1,\"\\n  \"],[10,\"progress\"],[15,\"max\",[30,0,[\"max\"]]],[15,2,[30,0,[\"value\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasMax\"]],[[[1,\"  \"],[10,0],[14,0,\"progress-allowance flex items-center mv2\"],[12],[1,\"\\n    \"],[10,\"progress\"],[15,\"max\",[30,0,[\"daysInMonth\"]]],[15,2,[30,0,[\"currentDayOfTheMonth\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"f5 gray flex justify-between overflow-hidden\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"You're \"],[1,[30,0,[\"aboveOrBelow\"]]],[1,\" expected usage for this month so far\"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"Resets in \"],[1,[30,0,[\"remainingDaysInMonth\"]]],[1,\" days\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "dashboard/templates/components/enterprise-usage/progress.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});