define("dashboard/services/browser-time", ["exports", "jquery", "rsvp", "@ember/service", "moment", "dashboard/config/environment"], function (_exports, _jquery, _rsvp, _service, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    _timeHost: _environment.default.metricsApiUrl,

    findBrowserOffset() {
      const url = `${this._timeHost}/_timestamp`;
      const options = {
        url,
        timeout: 1000,
        beforeSend: xhr => {
          xhr.suppressErrors = true;
        }
      };
      return new _rsvp.Promise(resolve => {
        _jquery.default.ajax(options).then(_ref => {
          let {
            timestamp
          } = _ref;

          const offset = this.currentBrowserTime() - _moment.default.unix(timestamp);

          if (!this.isDestroyed) {
            this.set('offset', offset);
          }

          resolve(timestamp);
        }).fail(() => {
          // if for whatever reason this endpoint is down
          // or is slow, gracefully rescue and do nothing
          return resolve(null);
        });
      });
    },

    // stubbable function
    currentBrowserTime() {
      return (0, _moment.default)();
    },

    offset: 0,

    get currentTime() {
      return this.currentBrowserTime().subtract(this.offset);
    }

  });

  _exports.default = _default;
});