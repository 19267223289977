define("dashboard/components/eco-subscribe-banner", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EcoSubscribeBannerComponent extends _component.default {
    get buttonDataTestTarget() {
      return this.args.isOwner ? 'subscribe-eco-button' : 'disabled-subscribe-eco-button';
    }

    get isDisabled() {
      return !this.args.isOwner;
    }

  }

  _exports.default = EcoSubscribeBannerComponent;
});