define("dashboard/components/app-item-favorite-tray/metrics/memory-chart", ["exports", "dashboard/components/metrics/charts/visualizations/dyno-memory", "dashboard/utils/metrics/latest-non-zero-value", "dashboard/templates/components/app-item-favorite-tray/metrics/favorite-chart"], function (_exports, _dynoMemory, _latestNonZeroValue, _favoriteChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dynoMemory.default.extend({
    layout: _favoriteChart.default,
    classNames: ['metrics__summary-row__item'],
    layoutName: 'components/app-item-favorite-tray/metrics/favorite-chart',
    attributeBindings: ['title'],
    title: 'Latest memory usage',
    label: 'memory',
    displayUnit: '%',
    chartTitle: 'Memory Usage over past 24hrs',
    displayLatestValue: (0, _latestNonZeroValue.default)('data.maxPercentageUsedData'),

    click() {
      this.logClick('memory');
    }

  });

  _exports.default = _default;
});