define("dashboard/models/production-check/ssl", ["exports", "dashboard/mixins/production-check", "@ember/object", "@ember/object/computed"], function (_exports, _productionCheck, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend(_productionCheck.default, {
    // --------------------------------------------------------------------------
    // Public
    title: (0, _object.computed)('hasSni', 'hasAddon', function () {
      if (!this.hasSni && this.hasAddon) {
        return 'SSL add-on';
      }

      return 'Heroku SSL';
    }),
    status: (0, _object.computed)('hasSni', 'hasSupportedAddon', 'hasDeprecatedAddon', function () {
      if (this.hasSni || this.hasSupportedAddon) {
        return 'passed';
      }

      if (this.hasDeprecatedAddon) {
        return 'failed';
      }

      return 'skipped';
    }),
    failedMessage: (0, _object.computed)('deprecatedAddon.description', function () {
      if (this.deprecatedAddon) {
        return `The ${this.deprecatedAddon.description} add-on is ` + `deprecated. Use the supported SSL add-on: ` + `https://elements.heroku.com/addons/ssl`;
      }
    }),
    skippedMessage: 'Heroku SSL is not enabled.',
    devCenterURL: (0, _object.computed)('hasSni', 'hasAddon', function () {
      if (!this.hasSni && this.hasAddon) {
        return 'https://elements.heroku.com/addons/ssl';
      }

      return 'https://devcenter.heroku.com/articles/ssl';
    }),
    // --------------------------------------------------------------------------
    // Private
    hasSni: (0, _computed.notEmpty)('app.sniEndpoints'),
    hasAddon: (0, _computed.notEmpty)('addons'),
    hasDeprecatedAddon: (0, _computed.notEmpty)('deprecatedAddons'),
    hasSupportedAddon: (0, _computed.notEmpty)('supportedAddons'),
    supportedAddons: (0, _computed.filterBy)('addons', 'name', 'ssl:endpoint'),
    deprecatedAddons: (0, _computed.setDiff)('addons', 'supportedAddons'),
    deprecatedAddon: (0, _computed.readOnly)('deprecatedAddons.firstObject'),
    addons: (0, _computed.readOnly)('sslAddonServicePlans'),
    sslAddonServicePlans: (0, _object.computed)('addonServicePlans.@each.name', function () {
      return this.addonServicePlans.compact().filter(asp => {
        const name = (0, _object.get)(asp, 'name') || '';
        return name.startsWith('ssl:');
      });
    }),
    addonServicePlans: (0, _computed.mapBy)('app.attachments', 'addonServicePlan')
  });

  _exports.default = _default;
});