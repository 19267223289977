define("dashboard/components/space/network/inbound-ruleset-list", ["exports", "@ember/component", "@ember/object", "dashboard/mixins/purple-list"], function (_exports, _component, _object, _purpleList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class StaleRulesetError extends Error {
    constructor() {
      super(...arguments);

      _defineProperty(this, "message", 'The IP ranges were modified by someone else. Here is the latest list:');
    }

  }

  var _default = _component.default.extend(_purpleList.default, {
    error: null,
    newRule: (0, _object.computed)(function () {
      return {
        action: 'allow',
        source: ''
      };
    }),

    newInboundRule() {
      return {
        action: 'allow',
        source: ''
      };
    },

    titleForDelete: (0, _object.computed)('item', function () {
      const name = this.get('item.source');
      return `Delete <small>(${name})</small>`;
    }),
    actions: {
      async saveNewInboundRuleset() {
        this.set('error', null);
        const ruleset = this.ruleset;

        if (!(await checkRulesetIsFresh(ruleset))) {
          this.set('error', new StaleRulesetError());
          this.set('resetAsyncBtn', true);
          return;
        }

        ruleset.addRule(this.newRule);
        return ruleset.save().then(() => {
          this.set('newRule', this.newInboundRule());
          this.set('resetAsyncBtn', true);
        }).catch(() => {
          ruleset.rollbackAttributes();
        });
      },

      async delete() {
        this.set('error', null);
        const ruleset = this.ruleset;

        if (!(await checkRulesetIsFresh(ruleset))) {
          this.set('error', new StaleRulesetError());
          this.send('toggleDeleteConfirmModal');
          return;
        }

        ruleset.set('rules', ruleset.rules.filter(rule => rule.source !== this.item.source));
        return ruleset.save().then(() => {
          this.send('toggleDeleteConfirmModal');
        }).catch(() => {
          ruleset.rules.addObject(this.item);
        });
      }

    }
  });

  _exports.default = _default;

  async function checkRulesetIsFresh(ruleset) {
    const oldState = JSON.stringify(ruleset.rules);
    await ruleset.reload();
    const newState = JSON.stringify(ruleset.rules);
    return newState === oldState;
  }
});