define("dashboard/components/metrics/charts/visualizations/request-throughput", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/wrapped-line-chart", "dashboard/utils/metrics/has-non-zero-values", "@ember/object/computed", "dashboard/utils/metrics/time-calculators"], function (_exports, _object, _wrappedLineChart, _hasNonZeroValues, _computed, _timeCalculators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _wrappedLineChart.default.extend({
    tagName: 'metrics-throughput-chart',
    title: 'Throughput',
    devCenterName: 'metrics#throughput',
    insufficientDataTitle: 'No Web Traffic',
    unit: (0, _computed.readOnly)('throughputResolution'),
    usingRpm: (0, _computed.equal)('unit', 'rpm'),
    boundaryMultiplier: 1.1,
    secondaryUnit: 'requests',
    secondaryValueFormat: '0[.]0a',
    smallNumberFormat: '< 1',
    visibleAlerts: (0, _timeCalculators.filterPeriodWithinTimeframe)('alerts', 'startedAt', 'endedAt'),
    filteredAlerts: (0, _computed.filterBy)('visibleAlerts', 'isErrorRate'),
    alertSummaryType: 'errorRate',
    has3xxData: (0, _hasNonZeroValues.default)('data.rps3xx'),
    has4xxData: (0, _hasNonZeroValues.default)('data.rps4xx'),
    has5xxData: (0, _hasNonZeroValues.default)('data.rps5xx'),
    isShowing2xx: true,
    isShowing3xx: true,
    isShowing4xx: true,
    isShowing5xx: true,
    timeSeries2xx: (0, _object.computed)('data.rps2xx', 'data.rpm2xx', 'usingRpm', 'data.data2xx', 'isShowing2xx', function () {
      return _object.default.create({
        name: '2xx',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 0,
        data: this.usingRpm ? this.get('data.rpm2xx') : this.get('data.rps2xx'),
        extraData: this.get('data.data2xx'),
        isVisible: this.isShowing2xx,
        key: '2xx',
        hideLine: false
      });
    }),
    timeSeries3xx: (0, _object.computed)('data.rps3xx', 'data.rpm3xx', 'usingRpm', 'data.data3xx', 'isShowing3xx', function () {
      return _object.default.create({
        name: '3xx',
        color: 'purple-002',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 1,
        data: this.usingRpm ? this.get('data.rpm3xx') : this.get('data.rps3xx'),
        extraData: this.get('data.data3xx'),
        isVisible: this.isShowing3xx,
        key: '3xx',
        hideLine: false
      });
    }),
    timeSeries4xx: (0, _object.computed)('data.rps4xx', 'data.rpm4xx', 'usingRpm', 'data.data4xx', 'isShowing4xx', function () {
      return _object.default.create({
        name: '4xx',
        color: 'purple-003',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 2,
        data: this.usingRpm ? this.get('data.rpm4xx') : this.get('data.rps4xx'),
        extraData: this.get('data.data4xx'),
        isVisible: this.isShowing4xx,
        key: '4xx',
        hideLine: false
      });
    }),
    timeSeries5xx: (0, _object.computed)('data.rps5xx', 'data.rpm5xx', 'usingRpm', 'data.data5xx', 'isShowing5xx', function () {
      return _object.default.create({
        name: '5xx (Failed)',
        color: 'crit',
        type: 'metrics/charts/line-chart-elements/stacked-area-graph',
        index: 3,
        data: this.usingRpm ? this.get('data.rpm5xx') : this.get('data.rps5xx'),
        extraData: this.get('data.data5xx'),
        isVisible: this.isShowing5xx,
        key: '5xx',
        hideLine: false
      });
    }),
    chartTimeSeries: (0, _object.computed)('timeSeries2xx', 'timeSeries3xx', 'timeSeries4xx', 'timeSeries5xx', 'has3xxData', 'has4xxData', 'has5xxData', function () {
      const timeSeries = [this.timeSeries2xx];

      if (this.has3xxData) {
        timeSeries.push(this.timeSeries3xx);
      }

      if (this.has4xxData) {
        timeSeries.push(this.timeSeries4xx);
      }

      if (this.has5xxData) {
        timeSeries.push(this.timeSeries5xx);
      }

      return timeSeries;
    })
  });

  _exports.default = _default;
});