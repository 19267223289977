define("dashboard/templates/app/app-json", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vBr9a1yy",
    "block": "[[[1,[28,[35,0],null,[[\"app\",\"stacks\",\"addons\",\"parentConfigVars\",\"githubAppLink\",\"predefinedAppName\",\"showAutoDeployMessage\",\"onSave\"],[[30,0,[\"model\",\"app\"]],[30,0,[\"model\",\"stacks\"]],[30,0,[\"model\",\"addons\"]],[30,0,[\"model\",\"parentConfigVars\"]],[30,0,[\"githubAppLink\"]],[30,0,[\"app_name\"]],[30,0,[\"autodeploy\"]],[28,[37,1],[[30,0],\"saveManifestAndTransition\"],null]]]]],[1,\"\\n\"]],[],false,[\"app-manifest-editor\",\"action\"]]",
    "moduleName": "dashboard/templates/app/app-json.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});