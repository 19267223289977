define("dashboard/components/the-footer", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['allow-if-delinquent'],
    copyrightYear: new Date().getFullYear()
  });

  _exports.default = _default;
});