define("dashboard/components/x-tooltip", ["exports", "@ember/component", "@ember/object", "dashboard/mixins/tooltipable"], function (_exports, _component, _object, _tooltipable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_tooltipable.default, {
    tagName: 'span',
    classNames: ['tooltip-component'],
    attributeBindings: ['title', 'data-placement'],
    tooltipOptions: (0, _object.computed)(function () {
      // setting the container to body makes the tooltip
      // more resilient when in special layouts
      return {
        container: 'body'
      };
    }),

    click(e) {
      const href = this.href;

      if (href) {
        e.preventDefault();
        e.stopPropagation();
        window.open(href, '_blank');
      }
    }

  });

  _exports.default = _default;
});