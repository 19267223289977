define("dashboard/components/metrics/process-monitor-configuration/preview-chart", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "moment", "dashboard/utils/metrics/data-fetch"], function (_exports, _computed, _component, _object, _moment, _dataFetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'metrics-monitor-preview-chart',
    maxValueGraph: (0, _object.computed)('monitor.value', 'graphData.[]', function () {
      const graphData = this.graphData || [];
      const defaultMax = this.get('monitor.value') * 1.3;
      const valueMax = Math.max(...graphData.map(ele => ele[1]));
      return Math.max(defaultMax, valueMax);
    }),
    breachingDataPoints: (0, _object.computed)('graphData.[]', 'monitor.value', function () {
      const threshold = this.get('monitor.value');
      const graphData = this.graphData || []; // eslint-disable-next-line no-unused-vars

      return graphData.filter(function (_ref) {
        let [time, value] = _ref;
        return value >= threshold;
      });
    }),
    combinedBreachedPoints: (0, _object.computed)('breachingDataPoints.[]', function () {
      const rollupDuration = _moment.default.duration(10, 'm');

      return this.breachingDataPoints.reduce((points, breachedPoint) => {
        const [time] = breachedPoint;
        const prevPoint = points[points.length - 1] || [];
        const isAdjacent = prevPoint[1] === time;

        if (isAdjacent) {
          prevPoint[1] += rollupDuration;
        } else {
          points.push([time, time + rollupDuration]);
        }

        return points;
      }, []);
    }),
    alertPreviews: (0, _object.computed)('combinedBreachedPoints.[]', 'monitor.period', function () {
      const period = _moment.default.duration(this.get('monitor.period'), 'm').valueOf();

      return this.combinedBreachedPoints.filter(function (_ref2) {
        let [start, end] = _ref2;
        return end - start >= period;
      });
    }),
    wouldHaveAlerts: (0, _computed.notEmpty)('alertPreviews'),
    chartUnit: (0, _object.computed)('monitor.isErrorRate', function () {
      if (this.get('monitor.isErrorRate')) {
        return 'ratio';
      } else {
        return 'time';
      }
    }),
    throughput: (0, _dataFetch.queryProcessData)('metrics/router-status', {}, 'process.isWeb'),
    latency: (0, _dataFetch.queryProcessData)('metrics/router-latency', {}, 'process.isWeb'),
    graphData: (0, _object.computed)('monitor.isErrorRate', 'throughput.errorRateData.[]', 'latency.service95Data.[]', function () {
      return this.get('monitor.isErrorRate') ? this.get('throughput.errorRateData') : this.get('latency.service95Data');
    }),
    chartTimeSeries: (0, _object.computed)('graphData.[]', function () {
      return [_object.default.create({
        color: 'preview',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        data: this.graphData,
        isVisible: true
      })];
    })
  });

  _exports.default = _default;
});