define("dashboard/components/pipeline/manage-github-connection", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "dashboard/utils/custom-computed", "@ember/utils", "@ember/object"], function (_exports, _component, _service, _computed, _customComputed, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    githubToken: (0, _service.inject)('github-token'),
    pipelineRepository: (0, _computed.readOnly)('pipeline.githubRepository'),
    githubOwner: (0, _computed.readOnly)('pipelineRepository.githubOwner'),
    githubUser: (0, _computed.readOnly)('githubToken.user'),
    pipelineStatuses: (0, _computed.readOnly)('pipelineRepository.statuses.[]'),
    gitHubTokenStatus: (0, _customComputed.findBy)('pipelineStatuses', 'token', 'status'),
    isGitHubTokenValid: (0, _object.computed)('gitHubTokenStatus.status', function () {
      if ((0, _utils.isPresent)(this.get('gitHubTokenStatus.status'))) {
        return this.get('gitHubTokenStatus.status');
      }

      return true; // do not flag the token as invalid when in doubt
    }),
    isNotAuthedToGitHub: (0, _computed.not)('githubToken.isPresent'),
    actions: {
      // Toggle the disconnect confirmation
      toggleIsDisconnecting() {
        this.toggleProperty('isShowingDisconnectionConfirmation');
      },

      onRepoUnlink() {
        const promise = this.onRepoUnlink(this.get('pipelineRepository.content'));
        return promise.then(() => {
          this.toggleProperty('isShowingDisconnectionConfirmation');
        });
      }

    }
  });

  _exports.default = _default;
});