define("dashboard/mixins/refresh-quick-jump", ["exports", "@ember/service", "@ember/runloop", "@ember/object/mixin"], function (_exports, _service, _runloop, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    glostick: (0, _service.inject)(),

    refreshQuickJump() {
      (0, _runloop.debounce)(this, this._reloadQuickJumpData, 2000);
    },

    _reloadQuickJumpData() {
      this.glostick.refreshQuickJump();
    }

  });

  _exports.default = _default;
});