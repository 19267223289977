define("dashboard/models/metrics", ["exports", "@ember/utils", "@ember/object", "dashboard/utils/metrics/time-calculators", "moment", "@ember-data/model", "lodash-es/range"], function (_exports, _utils, _object, _timeCalculators, _moment, _model, _range) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * the base Metrics-Api Model
   */
  var _default = _model.default.extend({
    rawData: (0, _model.attr)(),
    startTime: (0, _model.attr)('date'),
    endTime: (0, _model.attr)('date'),
    step: (0, _model.attr)(),
    stepDuration: (0, _object.computed)('step', function () {
      return _moment.default.duration(this.step, 'm');
    }),
    stepInNs: (0, _object.computed)('step', function () {
      return this.step * 60 * Math.pow(10, 9);
    }),

    /**
     * creates a new metrics object to be attached to a new key
     *
     * will produce an array of time-value pairs: [[(time), (value=null)]]
     *
     */
    createNewMetric() {
      const stepDuration = this.stepDuration;
      const startTime = this.startTime;
      const steps = (0, _timeCalculators.calculateNumberOfSteps)(stepDuration, startTime, this.endTime);
      return (0, _range.default)(steps + 1).map(function (index) {
        const time = startTime.valueOf() + index * stepDuration;
        return [time, null];
      });
    }

  }).reopenClass({
    // a computed property that extracts
    // a key from `rawData` and returns a default blank
    // array if it is missing
    rawDataWithDefault(key) {
      return (0, _object.computed)(key, 'rawData', function () {
        const rawData = this.rawData;
        return (0, _utils.isPresent)(rawData) ? rawData[key] || [] : [];
      });
    }

  });

  _exports.default = _default;
});