define("dashboard/components/pipeline/manage-review-apps", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed"], function (_exports, _component, _service, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    spaces: (0, _service.inject)(),
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    cannotManageReviewApps: (0, _computed.not)('canManageReviewApps'),
    destroyStaleAppsDisabled: (0, _computed.not)('reviewAppConfig.destroyStaleApps'),
    disableStaleDaysDropdown: (0, _computed.or)('destroyStaleAppsDisabled', 'reviewAppConfigOptionsDisabled'),
    hasNewReviewAppsEnabled: (0, _computed.reads)('pipeline.hasNewReviewAppsEnabled'),
    reviewAppConfig: (0, _object.computed)('pipeline.reviewAppConfig.id', function () {
      let reviewAppConfig;

      if (this.get('pipeline.reviewAppConfig.id')) {
        reviewAppConfig = this.get('pipeline.reviewAppConfig');
      } else {
        const deployTarget = this.store.peekRecord('region', this.get('spaces.sortedCommonRuntimes.firstObject.id'));
        reviewAppConfig = this.store.createRecord('review-app-config', {
          deployTarget
        });
      }

      return reviewAppConfig;
    }),
    reviewAppConfigOptionsDisabled: (0, _computed.or)('cannotManageReviewApps', 'reviewAppConfig.isSaving'),
    reviewAppsToggleDisabled: (0, _computed.reads)('cannotManageReviewApps'),
    reviewAppsDevCenterUrl: (0, _object.computed)(function () {
      return 'https://devcenter.heroku.com/articles/review-apps-new';
    }),

    disableLegacyReviewApps() {
      this.analytics.logEvent('Review Apps Legacy Leave', 'Confirmed');
      const reviewAppParent = this.get('pipeline.reviewAppParent');
      reviewAppParent.set('pullRequests.enabled', false);
      return reviewAppParent.save().catch(() => {
        this.set('reviewAppParent.pullRequests.enabled', true);
      });
    },

    actions: {
      enableReviewApps() {
        this.set('isShowingReviewAppConfigModal', true);
        this.analytics.logEvent('Review Apps New Enable', 'Clicked');
      },

      disableReviewApps() {
        const pipeline = this.pipeline;

        if (this.hasNewReviewAppsEnabled) {
          this.analytics.logEvent('Review Apps New Disable', 'Confirmed');
          return this.onDisableReviewApps(pipeline).then(() => this.set('isShowingDisableReviewAppsModal', false));
        } else {
          return this.disableLegacyReviewApps().then(() => this.set('isShowingDisableReviewAppsModal', false));
        }
      },

      toggleButtonProperty(name) {
        const pipeline = this.pipeline;
        return this.onUpdateReviewAppConfig(pipeline, {
          [name]: !this.get(`reviewAppConfig.${name}`)
        });
      },

      deployTargetChanged(deployTarget) {
        return this.get('onUpdateReviewAppConfig')(this.get('pipeline'), {
          deployTarget
        });
      },

      saveUpdatedReviewAppConfig() {
        return this.get('onUpdateReviewAppConfig')(this.get('pipeline'));
      },

      closeReviewAppConfigModal() {
        this.set('isShowingReviewAppConfigModal', false);
        this.analytics.logEvent('Review Apps New Slide Panel', 'Closed');
      },

      cancelDisableReviewApps() {
        this.set('isShowingDisableReviewAppsModal', false);
        this.analytics.logEvent('Review Apps New Disable', 'Cancelled');
      }

    }
  });

  _exports.default = _default;
});