define("dashboard/services/session", ["exports", "ember-simple-auth/services/session", "dashboard/utils/localstorage", "@ember/object", "@ember/utils", "@ember/object/computed"], function (_exports, _session, _localstorage, _object, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    sudoDataKey: 'hk-sudo-data',
    preauthDataKey: 'hk-preauth-data',
    accessToken: (0, _computed.readOnly)('data.authenticated.access_token'),
    preauthToken: (0, _computed.readOnly)('preauthData.preauthToken'),
    sudoToken: (0, _computed.readOnly)('sudoData.sudoToken'),
    sudoReason: (0, _computed.readOnly)('sudoData.sudoReason'),
    sudoUser: (0, _computed.readOnly)('sudoData.user_id'),
    userId: (0, _computed.readOnly)('data.authenticated.user_id'),
    // Returns the bearer token to be used for authorized requests. This will be the
    // standard auth token for the currently authed user, unless there's a sudo
    // token or a preauth token, in which case that will be returned.
    bearerToken: (0, _object.computed)('accessToken', 'sudoToken', 'preauthToken', function () {
      const accessToken = this.get('accessToken');
      const specialToken = this.get('sudoToken') || this.get('preauthToken'); // Prefer the specialToken token, if it exists

      return (0, _utils.isPresent)(specialToken) ? specialToken : accessToken;
    }),

    setSudoData(sudoToken, sudoReason, userId) {
      const sudoData = {
        sudoToken,
        user_id: userId,
        sudoReason,
        isAssumed: true
      };

      _localstorage.default.setSessionItem(this.sudoDataKey, JSON.stringify(sudoData));

      this.notifyPropertyChange('sudoData');
    },

    sudoData: (0, _object.computed)(function () {
      const sudoData = _localstorage.default.getSessionItem(this.sudoDataKey);

      return JSON.parse(sudoData) || {};
    }),
    isAssumed: (0, _object.computed)('sudoData.isAssumed', function () {
      return this.get('sudoData.isAssumed');
    }),

    clearSudoData() {
      _localstorage.default.removeSessionItem(this.sudoDataKey);

      this.notifyPropertyChange('sudoData');
    },

    preauthData: (0, _object.computed)('preauthDataKey', function () {
      const preauthData = _localstorage.default.getSessionItem(this.preauthDataKey);

      return JSON.parse(preauthData) || {};
    }),
    isPreauthed: (0, _computed.notEmpty)('preauthToken'),

    setPreauthData(preauthToken) {
      const preauthData = {
        preauthToken
      };

      _localstorage.default.setSessionItem(this.preauthDataKey, JSON.stringify(preauthData));

      this.notifyPropertyChange('preauthData');
    },

    clearPreauthData() {
      _localstorage.default.removeSessionItem(this.preauthDataKey);

      this.notifyPropertyChange('preauthData');
    }

  });

  _exports.default = _default;
});