define("dashboard/services/notifications", ["exports", "@ember/service", "jquery", "dashboard/config/environment", "@ember/object/computed", "@ember/object"], function (_exports, _service, _jquery, _environment, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    session: (0, _service.inject)(),
    unreadCount: 0,
    hasUnreadNotifications: (0, _computed.gt)('unreadCount', 0),

    fetchUnreadCount() {
      const store = this.store;
      const adapter = store.adapterFor('application');
      return new Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: `${_environment.default.telexUrl}/user/notifications/unread-count`,
          headers: adapter.get('authorizationHeaders')
        }).then(data => {
          (0, _object.set)(this, 'unreadCount', data.unread_count);
          resolve(this.unreadCount);
        }).fail(reject);
      });
    },

    fetchAll() {
      return this.store.findAll('notification');
    },

    async markAllUnreadNotificationsAsRead() {
      const response = await fetch(`${_environment.default.telexUrl}/user/notifications/mark-all-as-read`, {
        method: 'PATCH',
        headers: this.store.adapterFor('application').get('authorizationHeaders')
      });

      if (response.ok) {
        await this.fetchAll();
        await this.fetchUnreadCount();
      } else {
        throw response;
      }
    },

    unreadNotificationsObserver: (0, _object.observer)('hasUnreadNotifications', function () {
      if (this.hasUnreadNotifications) {
        (0, _jquery.default)(document).trigger('glostick.addIndicator');
      } else {
        (0, _jquery.default)(document).trigger('glostick.removeIndicator');
      }
    })
  });

  _exports.default = _default;
});