define("dashboard/components/datastore/postgres/utilization", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    connectionsParts: (0, _object.computed)('datastore.connections', function () {
      return this.datastore.connections ? this.datastore.connections.split('/') : [];
    }),
    connectionsUsed: (0, _object.computed)('connectionsParts', function () {
      return this.connectionsParts[0];
    }),
    connectionsAvailable: (0, _object.computed)('connectionsParts', function () {
      return this.connectionsParts[1];
    }),
    dataSizeParts: (0, _object.computed)('datastore.dataSize', function () {
      return this.datastore.dataSize.split(' ');
    }),
    dataSizeValue: (0, _object.computed)('dataSizeParts', function () {
      return this.dataSizeParts[0];
    }),
    dataSizeLabel: (0, _object.computed)('dataSizeParts', function () {
      return this.dataSizeParts[1];
    }),
    rowsParts: (0, _object.computed)('datastore.rows', function () {
      return this.datastore.rows ? /(\d*)\/(\d*) \((.*)\)/.exec(this.datastore.rows) : [];
    }),
    rowsUsed: (0, _object.computed)('rowsParts', function () {
      return this.rowsParts[1];
    }),
    rowsAvailable: (0, _object.computed)('rowsParts', function () {
      return this.rowsParts[2];
    }),
    rowsCompliance: (0, _object.computed)('rowsParts', function () {
      return this.rowsParts[3];
    }),
    rowsStatusClass: (0, _object.computed)('rowsCompliance', function () {
      return this.rowsCompliance === 'In compliance' ? 'malibu-fill-gradient-green' : 'malibu-fill-gradient-red';
    }),
    rowsStatusIcon: (0, _object.computed)('rowsCompliance', function () {
      return this.rowsCompliance === 'In compliance' ? 'success-badge-16' : 'error-badge-16';
    })
  });

  _exports.default = _default;
});