define("dashboard/components/metrics/charts/visualizations/go-heap-memory", ["exports", "@ember/object", "dashboard/components/metrics/charts/visualizations/memory-chart", "@ember/object/computed"], function (_exports, _object, _memoryChart, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _memoryChart.default.extend({
    'data-test-target': 'metrics-go-chart',
    title: 'Go: Heap Memory Usage',
    devCenterName: 'language-runtime-metrics-go#heap-memory-usage',
    isBeta: true,
    isShowingGcGoalMax: true,
    isShowingGcGoalAvg: true,
    isShowingMax: true,
    isShowingAvg: true,
    timeSeriesGcGoalMax: (0, _object.computed)('data.gcGoalMax', 'isShowingGcGoalMax', function () {
      return _object.default.create({
        name: 'GC Goal Max',
        color: 'blue-001',
        type: 'metrics/charts/line-chart-elements/line-graph',
        index: 1,
        data: this.get('data.gcGoalMax'),
        isVisible: this.isShowingGcGoalMax,
        key: 'GcGoalMax',
        hideLine: false
      });
    }),
    timeSeriesGcGoalAvg: (0, _object.computed)('data.gcGoalAvg', 'isShowingGcGoalAvg', function () {
      return _object.default.create({
        name: 'GC Goal Avg',
        color: 'purple-001',
        type: 'metrics/charts/line-chart-elements/line-graph',
        index: 3,
        data: this.get('data.gcGoalAvg'),
        isVisible: this.isShowingGcGoalAvg,
        key: 'GcGoalAvg',
        hideLine: false
      });
    }),
    timeSeriesMax: (0, _object.computed)('data', 'data.heapMaxGo', 'isShowingMax', function () {
      return _object.default.create({
        name: 'Heap Max',
        color: 'blue-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 2,
        data: this.get('data.heapMaxGo'),
        isVisible: this.isShowingMax,
        key: 'Max'
      });
    }),
    timeSeriesAvg: (0, _object.computed)('data', 'data.heapAvgGo', 'isShowingAvg', function () {
      return _object.default.create({
        name: 'Heap Avg',
        color: 'purple-002',
        type: 'metrics/charts/line-chart-elements/overlayed-area-graph',
        index: 4,
        data: this.get('data.heapAvgGo'),
        isVisible: this.isShowingAvg,
        key: 'Avg'
      });
    }),
    chartTimeSeries: (0, _computed.collect)('timeSeriesMax', 'timeSeriesAvg', 'timeSeriesQuota', 'timeSeriesGcGoalMax', 'timeSeriesGcGoalAvg')
  });

  _exports.default = _default;
});