define("dashboard/controllers/account/applications/edit-client", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed", "@ember/object", "ember-changeset"], function (_exports, _controller, _service, _computed, _object, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    flashMessages: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    changeset: (0, _object.computed)('model', function () {
      const changeset = new _emberChangeset.default(this.model);
      return changeset;
    }),
    id: (0, _computed.alias)('model.id'),
    name: (0, _computed.alias)('model.name'),
    redirectUri: (0, _computed.alias)('model.redirectUri'),
    secret: (0, _computed.alias)('model.secret'),
    saveText: 'Update Information',
    actions: {
      delete() {
        const pendingDelete = this.model.destroyRecord();
        return pendingDelete.then(() => {
          this.transitionToRoute('account.applications');
          this.set('showDeleteAPIClientConfirmation', false);
        });
      },

      reset() {
        return this.model.resetToken().then(secret => {
          const changeset = this.changeset;
          changeset.set('secret', secret);
          this.set('showResetAPIClientSecretConfirmation', false);
          this.analytics.logEvent('API Client Secret', 'Reset');
          this.flashMessages.success('API Client Secret has been reset. The previous token has been invalidated.', {
            title: 'API Client Secret Token'
          });
        });
      },

      save(dismissSidePanel) {
        this.changeset.save().then(() => {
          dismissSidePanel();
          this.analytics.logEvent('API Client', 'Updated');
        });
      },

      hideEditClient() {
        this.set('showResetAPIClientSecretConfirmation', false);
        this.set('showDeleteAPIClientConfirmation', false);
        this.changeset.rollback();
        this.transitionToRoute('account.applications');
      }

    }
  });

  _exports.default = _default;
});