define("dashboard/components/metrics/chart-controls/timeframe-option", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    alias
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: 'li',
    classNameBindings: [':metrics__time-picker__menu-item', 'disabled:metrics__time-picker__menu-item--disabled', 'selected:metrics__time-picker__menu-item--selected'],
    attributeBindings: ['disabled'],
    disabled: alias('value.disabled'),
    selected: (0, _object.computed)(function () {
      return this.selectedTimeframe === this.value;
    }),

    click() {
      if (!this.disabled) {
        this.onSelect();
      }
    }

  });

  _exports.default = _default;
});