define("dashboard/templates/components/payment-method/credit-card-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "v/6udmDZ",
    "block": "[[[41,[30,0,[\"cardType\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"cardTypeClass\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],[[52,[30,0,[\"isSmall\"]],\"cc-generic-small\",\"cc-generic\"]],null]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"svg-jar\"]]",
    "moduleName": "dashboard/templates/components/payment-method/credit-card-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});