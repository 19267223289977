define("dashboard/components/app/activity-item", ["exports", "@ember/object", "@ember/component", "@ember/object/computed", "@ember/template"], function (_exports, _object, _component, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex', 'bb', 'pv3', 'b--light-silver', 'activity-item'],
    activityEmail: (0, _computed.readOnly)('item.user.email'),
    isGithubIntegrationUser: (0, _computed.match)('activityEmail', /github\+integration@heroku.com/),
    isAddon: (0, _computed.match)('activityEmail', /addons\.heroku\.com$/),
    isOrg: (0, _computed.match)('activityEmail', /@herokumanager.com$/),
    isAddonOrOrg: (0, _computed.or)('isAddon', 'isOrg'),
    userName: (0, _object.computed)('activityEmail', 'isGithubIntegrationUser', 'isAddonOrOrg', 'githubAppIntegrationText', function () {
      if (this.isAddonOrOrg) {
        return this.activityEmail.split('@')[0];
      } else if (this.isGithubIntegrationUser) {
        return this.githubAppIntegrationText;
      } else {
        return this.activityEmail;
      }
    }),
    githubAppInstallation: (0, _computed.readOnly)('app.githubAppInstallation'),
    githubAppIntegrationText: (0, _object.computed)('githubAppInstallation.herokuResourceType', 'githubAppInstallation.enterpriseAccount.name', 'githubAppInstallation.currentUserAccount', 'githubAppInstallation.team.name', function () {
      let text, name;

      switch (this.get('githubAppInstallation.herokuResourceType')) {
        case 'enterprise-account':
          name = this.get('githubAppInstallation.enterpriseAccount.name');
          text = `Enterprise Account: ${name} <span class="normal">via</span> GitHub`;
          break;

        case 'team':
          name = this.get('githubAppInstallation.team.name');
          text = `Team: ${name} <span class="normal">via</span> GitHub`;
          break;

        case 'user':
          text = 'Personal Account <span class="normal">via</span> GitHub';
          break;
      }

      return (0, _template.htmlSafe)(text);
    })
  });

  _exports.default = _default;
});