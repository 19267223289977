define("dashboard/templates/components/malibu-loading-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ei9mcyt1",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[[30,0,[\"iconName\"]],[30,0,[\"iconSize\"]],\"fill-gray\",\"Loading\"]],null],[1,\"\\n\"]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/malibu-loading-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});