define("dashboard/templates/components/metrics/charts/line-chart-elements/activity-event-markers", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cNDsSExY",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"orderedActivityEvents\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"event\",\"chartInnerHeight\",\"selectedRollupTime\",\"rollupDuration\",\"timeScale\",\"minTime\"],[[30,1],[30,0,[\"chartInnerHeight\"]],[30,0,[\"selectedRollupTime\"]],[30,0,[\"rollupDuration\"]],[30,0,[\"timeScale\"]],[30,0,[\"minTime\"]]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[10,\"g\"],[14,0,\"metrics__chart__event-indicator__top-level\"],[12],[13],[1,\"\\n\"]],[\"event\"],false,[\"each\",\"-track-array\",\"metrics/charts/line-chart-elements/activity-event-marker\"]]",
    "moduleName": "dashboard/templates/components/metrics/charts/line-chart-elements/activity-event-markers.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});