define("dashboard/serializers/space/outbound-ruleset", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize(typeClass, hash) {
      hash.id = hash.space.id;
      hash.space.type = 'space';
      return this._super.apply(this, arguments);
    }

  });

  _exports.default = _default;
});