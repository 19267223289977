define("dashboard/components/app/overview-formation", ["exports", "@ember/component", "dashboard/mixins/formations", "dashboard/mixins/formation-totals", "dashboard/mixins/formations-sort", "dashboard/mixins/set-available-free-hours"], function (_exports, _component, _formations, _formationTotals, _formationsSort, _setAvailableFreeHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_formations.default, _formationTotals.default, _formationsSort.default, _setAvailableFreeHours.default, {});

  _exports.default = _default;
});