define("dashboard/routes/team/switchable/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      return this.modelFor('team');
    },

    redirect(model) {
      const team = model.team;
      const teamName = team.get('name');
      this.replaceWith('team.switchable.apps', teamName);
    }

  });

  _exports.default = _default;
});