define("dashboard/helpers/is-test-state", ["exports", "@ember/component/helper", "dashboard/models/test-run"], function (_exports, _helper, _testRun) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isTestState = isTestState;

  function isTestState(_ref) {
    let [actual, beforeOrAfter, comparison] = _ref;

    if (beforeOrAfter === 'before') {
      return _testRun.STATE_ORDER[actual] < _testRun.STATE_ORDER[comparison];
    } else if (beforeOrAfter === 'after') {
      return _testRun.STATE_ORDER[actual] > _testRun.STATE_ORDER[comparison];
    }

    throw new Error('before or after parameter must be provided');
  }

  var _default = (0, _helper.helper)(isTestState);

  _exports.default = _default;
});