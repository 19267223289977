define("dashboard/templates/components/pipeline/github-button-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wwwN3EPp",
    "block": "[[[8,[39,0],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"service-github-28\",[30,0,[\"iconClass\"]],16]],null],[1,\"\\n\"]],[],false,[\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/github-button-icon.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});