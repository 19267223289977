define("dashboard/components/permission-toggle", ["exports", "@ember/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * PermissionToggle is a permission-specific wrapper around TagToggle.
   *
   * @module PermissionToggle
   * @param model Permission The permission to be toggled
   * @param selectPermission String A parent action to call upon selection
   * @param deselectPermission String A parent action to call upon deselection
   */
  var _default = _component.default.extend({
    value: (0, _computed.readOnly)('model.selected'),
    isView: (0, _computed.equal)('model.name', 'view'),
    locked: (0, _computed.or)('isView', 'isDisabled'),
    helpText: (0, _computed.readOnly)('model.description'),
    name: (0, _object.computed)('model.name', function () {
      return this.get('model.name').replace(/_/g, ' ');
    }),
    actions: {
      toggle() {
        if (this.value) {
          this.deselectPermission(this.model);
        } else {
          this.selectPermission(this.model);
        }
      }

    }
  });

  _exports.default = _default;
});