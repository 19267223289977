define("dashboard/templates/components/pipeline/apps-to-transfer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hJCGy3P6",
    "block": "[[[41,[30,0,[\"hasApps\"]],[[[1,\"  \"],[10,0],[14,0,\"bb b--silver pb2\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ph3 pt3 db f7 fw6 gray ttu tracked\"],[12],[1,[30,0,[\"transferableApps\",\"length\"]]],[1,\" \"],[1,[30,0,[\"stageName\"]]],[1,\" \"],[1,[28,[35,1],[[30,0,[\"transferableApps\",\"length\"]],\"app\",\"apps\"],null]],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"transferableApps\"]]],null]],null],null,[[[1,\"      \"],[10,\"label\"],[14,0,\"ph2 pv1 db ma0\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[[30,0,[\"iconName\"]],16,\"mh1 relative malibu-fill-gradient-purple\",[30,0,[\"iconTitle\"]]]],null],[1,\"\\n        \"],[1,[30,1,[\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"app\"],false,[\"if\",\"pluralize-word\",\"each\",\"-track-array\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/apps-to-transfer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});