define("dashboard/components/app/activity-release-rollback-item", ["exports", "dashboard/components/app/activity-release-item"], function (_exports, _activityReleaseItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activityReleaseItem.default.extend({
    tagName: ''
  });

  _exports.default = _default;
});