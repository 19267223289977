define("dashboard/components/app/datastore/credential-attachment", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _component.default {
    get isDetachingDisabled() {
      const billingAppId = this.args.billingApp.get('id');
      const billingAppAttachmentCount = this.args.credentialAttachments.filter(attachment => {
        return attachment.get('app.id') === billingAppId;
      }).length;
      return this.args.credentialName === 'default' && billingAppAttachmentCount === 1 && this.args.credentialAttachment.get('app.id') === billingAppId;
    }

  }

  _exports.default = _default;
});