define("dashboard/components/dyno-upgrade", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/string"], function (_exports, _service, _computed, _component, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'dyno-upgrade',
    store: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    devCenterName: (0, _computed.reads)('name'),
    primaryActionTitle: (0, _object.computed)('primaryActionName', 'costPerDynoPerMonth', function () {
      const action = this.primaryActionName;
      const cost = `$${this.costPerDynoPerMonth}/Dyno/Month`;
      return (0, _string.htmlSafe)(`${action}<br><span class="dyno-upgrade-pricing">${cost}</span>`);
    }),
    actions: {
      toggleModal() {
        this.toggleProperty('isOpened');
      },

      upgradeDyno() {
        return this.attrs.upgradeDyno();
      }

    }
  });

  _exports.default = _default;
});