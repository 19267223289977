define("dashboard/routes/pipelines/pipeline/tests", ["exports", "@ember/routing/route", "rsvp", "@ember/object", "@ember/service", "@ember/utils", "dashboard/utils/realtime/context"], function (_exports, _route, _rsvp, _object, _service, _utils, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_context.default, {
    githubToken: (0, _service.inject)('github-token'),
    analytics: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),

    model(_ref) {
      let {
        selectedFilterBranch
      } = _ref;
      const {
        pipeline
      } = this.modelFor('pipelines.pipeline');
      const githubToken = this.githubToken.maybeFetch();
      const testRuns = pipeline.get('testRuns');
      const userPreferences = this.modelFor('protected').userPreferences;
      const teams = this.store.peekAll('team');
      const githubRepository = pipeline.get('githubRepository').catch(() => {});
      let connectedServices;

      if (pipeline.get('isGithubAppEnabled')) {
        connectedServices = pipeline.belongsTo('connectedServices').reload().catch(() => {});
      }

      let githubAppInstallation;

      if (pipeline.get('isGithubAppEnabled')) {
        githubAppInstallation = pipeline.get('team.enterprise.githubAppInstallation').catch(() => {});
      }

      return _rsvp.default.hash({
        githubToken,
        pipeline,
        teams,
        testRuns,
        userPreferences,
        selectedFilterBranch,
        githubRepository,
        githubAppInstallation,
        connectedServices
      });
    },

    activate() {
      this._super(...arguments);

      const {
        pipeline
      } = this.modelFor(this.routeName);
      this.realtimeContext.onTestRun(pipeline.id, 'create', this.dataHandler.bind(this));
      this.realtimeContext.onTestRun(pipeline.id, 'update', this.dataHandler.bind(this));
    },

    dataHandler: (0, _object.computed)(function () {
      return _ref2 => {
        let {
          resource,
          data
        } = _ref2;

        // Skip fetching debug test runs. We may change this to show a proper UI
        // for debug runs, but for now we hide them
        if (resource !== 'test-run' || data.debug) {
          return;
        }

        this.store.findRecord('test-run', data.id).then(testRun => {
          this.get('currentModel.testRuns').pushObject(testRun);
        });
      };
    }),

    flashError(testRun) {
      this.flashMessages.danger(`A code archive link could not be fetched from GitHub.
            Please check that your account is connected to GitHub, you
            have acess to the repo and that the
            ${testRun.get('shortSha')} commit is still present.`, {
        title: 'Archive link not found'
      });
    },

    actions: {
      createTestRun(attrs, eventDescription) {
        if ((0, _utils.isPresent)(eventDescription)) {
          // TODO refactor ?
          this.analytics.logEvent(eventDescription, '');
        }

        const {
          pipeline
        } = this.modelFor(this.routeName);
        const testRun = this.store.createRecord('test-run', attrs);
        const isGithubAppEnabled = pipeline.get('isGithubAppEnabled');
        return this.store.queryRecord('repositories-api-archive-link', {
          ref: testRun.get('commitSha'),
          repoNameWithOwner: pipeline.get('githubRepository.name'),
          pipelineId: pipeline.get('id'),
          hasKolkrabbiGithubRepoLink: !pipeline.get('connectedRepository.id') && !!pipeline.get('githubRepository.id'),
          isGithubAppEnabled
        }).then(archive => {
          testRun.set('sourceBlobUrl', archive.get('archive_link'));
          return testRun.save().then(() => {
            this.transitionTo('pipelines.pipeline.tests.test-run', pipeline.get('id'), testRun.get('number'));
            return testRun;
          });
        }, error => {
          if (error.errors.any(e => e.status === '404')) {
            this.flashError(testRun);
          }
        });
      }

    }
  });

  _exports.default = _default;
});