define("dashboard/components/context-switcher/show-more", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "dashboard/utils/custom-computed"], function (_exports, _component, _object, _computed, _customComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    collection: (0, _object.computed)(function () {
      return [];
    }),
    limit: (0, _object.computed)(function () {
      return null;
    }),
    limitProxy: (0, _computed.oneWay)('limit'),
    remaining: (0, _object.computed)('collection.length', 'limitProxy', function () {
      return this.get('collection.length') - this.limitProxy;
    }),
    limitedCollection: (0, _customComputed.limitBy)('collection', 'limitProxy')
  });

  _exports.default = _default;
});